import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ApplicationRef } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
interface IContext {
  data:string;
}

import { exportPDF, Group } from '@progress/kendo-drawing';

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { MovimentosService } from'../business-logic-services/movimentos.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';
import { PaymentForm, PaymentModalComponent, PaymentModalInput } from '../payment-modal/payment-modal.component';
import { resolve } from 'url';


@Component({
  selector: 'app-receitas-details',
  templateUrl: './receitas-details.component.html',
  styleUrls: ['./receitas-details.component.scss']
})
export class ReceitasDetailsComponent implements OnInit {

  @ViewChild('emailAlertRef', { static: false }) emailAlertRef;
  emailModalRef = null;
  emailAlertConfig: any = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('updateMovAlertRef', { static: false }) updateMovAlertRef;
  updateMovModalRef = null;
  updateMovAlertConfig: any = null;

  modalPagOpen = false;
  @ViewChild('paymentModal', { static: false }) paymentModal: PaymentModalComponent;

  isCreate = false;

  transitionController = new TransitionController();
  submittingForm = false;
  loading = false;
  searchable: boolean = true;

  // RECEITA FORM
  cod_condominio = null;
  n_receita = null;
  cod_rubrica = null;
  cod_sub_rubrica = null;
  cod_fornecedor = null;
  tipo_proc = 'E';
  cod_proc = null;
  dt_desp = new Date();
  dt_valor = null;
  dt_pag = null;
  dt_venc = null;
  valor = null;
  valor_pago = null;
  n_documento = null;
  form_pagam = null;
  cod_conta_bancaria = null;
  descricao = null;
  n_doc_pagam = null;
  ref_interna = null;
  nid_movimento = null;
  reparticao = 'PO';

  receitaId = null;
  receitaCod = null;
  receitaDate = null;
  receitaDetails = null;

  rubricasOptsOrig = [];
  rubricasOptsOrc = [];
  rubricasOpts = [];

  contaOptsOrig = [];
  contaOpts = [];

  datePagEnabled = true;

  processamentosOrig = [];
  orcamentoId = null;

  movimentos = [];
  pagamentoParcial = null;
  pagEmFalta = null;
  valorPagoError = false;
  toPayPlaceholder = null;

  registoAct = [];

  // ZONAS TABLE
  zonaListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: true },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'number', sort: null, searchable: false, center: false },
    { key: 'permilagem', name: 'Perm.', type: 'number', sort: null, searchable: false, center: true },
    { key: 'n_fraccoes', name: '#Fracções', type: 'number', sort: null, searchable: false, center: true },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: false, center: false },
  ];
  zonaList: Array<any> = [];

  comp = 'receita';
  initState = null;
  prevState = null;

  movimentosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: true },
    { key: 'dt_valor', name: 'Data Valor', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-width  z-index-0' },
    { key: 'dt_mov', name: 'Data Mov.', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-width z-index-0' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'wide z-index-0' },
    { key: 'banco', name: 'Conta', type: 'text', sort: null, searchable: true, centered: false, class: 'wide z-index-0' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right date-width z-index-0' },
  ];

  movimentosListColPDF = [
    { key: 'dt_valor', name: 'Data Valor', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-width ' },
    { key: 'dt_mov', name: 'Data Mov.', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-width ' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-30 col-align-left' },
    { key: 'banco', name: 'Conta', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right' },
  ];

  isCaixaVertisLoggedIn = this.userSession.isCaixaVertisLoggedIn();

  valorOriginal = null;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public router: Router,
              public message: MessageService,
              public modalService: SuiModalService,
              public location: Location,
              public appRef:ApplicationRef,
              public cdRef:ChangeDetectorRef,
              public appConfig: AppConfigService,
              public userSession: UserSessionService,
              public movimentosService: MovimentosService,
              public appState: AppStateService) { }

  onFornecedorSelected(value) {
    // if (value === 0 || value === '0') {
    //   // // SAVE THIS STATE
    //   // this.setState();

    //   // this.appState.setDescFraccao(this.geralForm.get('nome').value);

    //   this.router.navigate(['entidades/fornecedor', 'criar']);
    // }
  }       

  ngOnInit() {
    // HANDLE APPLICATION STATE
    this.initState = this.appState.getInitState(this.comp);
    if (this.initState) {
      this.cod_condominio = { name: this.initState.state.condominioSelected.cod + ' - ' + this.initState.state.condominioSelected.nome, value: this.initState.state.condominioSelected };
      this.appState.clearInitState(this.comp);

      this.getCondominioDetails();
    }

    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.isCreate = true;
    } else {
      this.receitaId = this.route.snapshot.params.id;
    }

    // GET RUBRICAS
    this.api.getRubricas().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.rubricasOptsOrig = res.data.filter(el => (el.receita === '1')).map(el => {
          return { name: el.nome, value: el.cod };
        });
        this.rubricasOpts = JSON.parse(JSON.stringify(this.rubricasOptsOrig))

      } else {}
    }, err => {
    });

    this.init();
  }

  init() {
    if (this.isCreate) {

    } else {
      this.getReceitaDetails();
    }
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.cod_condominio); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
                } else {
                  return resolve([]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  fornecedorTimer = null;
  fornecedoresLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.cod_fornecedor); });
    }

    clearTimeout(this.fornecedorTimer);
    return new Promise(resolve => {
        if (query) {
          this.fornecedorTimer = setTimeout(() => {
            // FORNECEDOR LIST
            this.api.getAllFornecedores(query).subscribe(res => {
              if (res.success) {
                return resolve(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
                // return resolve([{ name: '--- Criar fornecedor ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; })));
              } else {
                return resolve([]);
                // return resolve([{ name: '--- Criar fornecedor ---', value: '0' }]);
              }
            });
          }, 400);
        } else {
          this.api.getAllFornecedores('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
              // return resolve([{ name: '--- Criar fornecedor ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; })));
            } else {
              return resolve([]);
              // return resolve([{ name: '--- Criar fornecedor ---', value: '0' }]);
            }
          });
        }
    });
  };

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: null });
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngAfterViewInit() {

    this.emailAlertConfig = new TemplateModalConfig<IContext, string, string>(this.emailAlertRef);
    this.emailAlertConfig.closeResult = "closed";
    this.emailAlertConfig.size = 'mini';
    this.emailAlertConfig.transition = 'fade up';
    this.emailAlertConfig.transitionDuration = 400;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.updateMovAlertConfig = new TemplateModalConfig<IContext, string, string>(this.updateMovAlertRef);
    this.updateMovAlertConfig.closeResult = "closed";
    this.updateMovAlertConfig.size = 'mini';
    this.updateMovAlertConfig.transition = 'fade';
    this.updateMovAlertConfig.transitionDuration = 250;
  }

  getCondominioDetails() {
    // PROCESSAMENTOS
    let cod = (this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;

    this.api.getOrcamentoByPeriodoAndAprovado(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        if (!res.data.hasOwnProperty('orcamento')) return;

        this.api.getProcessamentos(cod, res.data.orcamento.periodo, 1, 500, null, null, null, { isLancado: 1 }).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            this.processamentosOrig = res.data;
            this.orcamentoId = (res.orcamento.length > 0) ? res.orcamento[0].id : null;
            
            this.api.getOrcamentoDetails(this.orcamentoId).subscribe(res => {
              if (res.hasOwnProperty('success') && res.success) {
                this.rubricasOptsOrc = [];

                if (res.data && Array.isArray(res.data.rubricas)) {
                  let seenRubricas = '';
                  res.data.rubricas.forEach(el => {
          
                    // SET RUBRICAS LIST OPTIONS
                    if (seenRubricas.indexOf(',' + el.cod_rubrica + ',') === -1) {
                      // ADD RUBRICA TO SEEN STRING
                      seenRubricas += (',' + el.cod_rubrica + ',');
          
                      this.rubricasOptsOrc.push({ name: el.rub_nome, value: el.cod_rubrica });
                    }
                  });
                }

                this.zonaList = [];
                if (res.data && Array.isArray(res.data.zonas)) {
                  res.data.zonas.forEach(el => {
                    this.zonaList.push({
                      nome: el.nome,
                      permilagem: Number(el.permilagem),
                      n_fraccoes: Number(el.n_fraccoes),
                      valor: null,
                    });
                  });
                }

                this.zonaList = [];
                if (res.data && Array.isArray(res.data.zonas)) {
                  res.data.zonas.forEach(el => {
                    let aux = this.linhasDespesasDetails.find(el2 => (el2.cod_zona === el.cod));

                    this.zonaList.push({
                      id: (aux) ? aux.id: null,
                      cod_zona: el.cod,
                      nome: el.nome,
                      permilagem: Number(el.permilagem),
                      n_fraccoes: Number(el.n_fraccoes),
                      valor: (aux) ? Math.round((Number(aux.valor) * 100) / 100) : null,
                      checked: true,
                    });
                  });
                }

                this.valorChanged();

                this.computeTotal();

              }
            }, err => {});

          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
      }
    },
    err => {});

    // CONTAS
    this.api.getCondContasDetails(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.contaOptsOrig =  res.data.map(el => {
          return { name: el.banco, value: el.cod, conta_principal: el.conta_principal, saldo: Number(el.saldo), details: el };
        });
        this.contaOpts = JSON.parse(JSON.stringify(this.contaOptsOrig));
      } else {
        this.utils.apiErrorMsg(res);
        // this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  movimentosTotal = 0;
  linhasDespesasDetails = [];
  dtDespOriginal = null;
  isDeleted = false;
  despInCV = false;
  getReceitaDetails() {
    this.api.getReceitaDetails(this.receitaId).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.receitaDetails = res.data;

        this.isDeleted = (this.receitaDetails.active === '0');

        this.valorOriginal = Number(this.receitaDetails.valor);
        this.dtDespOriginal = this.utils.getDate(this.receitaDetails.dt_desp);

        this.linhasDespesasDetails = res.linhas_despesas;
        this.receitaCod = this.receitaDetails.cod;

        this.movimentosTotal = 0;
        // PRE PROCESS MOVIMENTOS
        this.movimentos = res.movimentos.map(el => {
          el['valor'] = Number(parseFloat(el['valor']).toFixed(2));
          el['dt_valor'] = (el['dt_valor']) ? this.utils.getDate(el['dt_valor']) : null;
          el['dt_mov'] = (el['dt_mov']) ? this.utils.getDate(el['dt_mov']) : null;
          el['checked'] = false;
          try {
            el['obj'] = JSON.parse(el['obj']);
          } catch {
            el['obj'] = null;
          }
          el['receita_id'] = this.receitaDetails.id;

          this.movimentosTotal += el['valor'];

          return el;
        });
        this.movimentos.sort((a,b) => {
          let diff = this.utils.compareDayDates(a.dt_mov,b.dt_mov);
          if (diff !== 0) return diff;
          return a.id - b.id;
        });

        this.despInCV = !!this.movimentos.find(el => !!el.id_caixa_vertis);

        if (this.movimentos.length > 0) {
          this.hasMovimentos = true;
        }

        let condMoradaAux = this.receitaDetails.cond_morada;
        // HANDLE OBJECT MORADA - CONDOMINIO
        try {
          let aux = '';
          let addressObj = JSON.parse(this.receitaDetails.cond_morada);
          Object.keys(addressObj).forEach((key, i) => {
            if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
          });

          condMoradaAux = aux;
          this.receitaDetails.cond_morada = (aux) ? aux.replace(/NEW_LINE/g, ', ') : null;
        } catch(e) {}
        this.receitaDetails.cond_morada_pdf = (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [];

        this.restoreForm('receita');

        this.setPagParcial();

        if (this.receitaDetails.obj) {
          this.registoAct = JSON.parse(this.receitaDetails.obj);
          this.registoAct = this.registoAct.filter(el => (el !== null));
        }

        this.getCondominioDetails();

        this.fetchDone = true;
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  hasMovimentos = false;
  estadoPagamento = null;
  setPagParcial(fromMovimentos=false) {
    this.pagamentoParcial = 0;
    this.pagEmFalta = 0;

    if (!fromMovimentos) {
      this.pagamentoParcial = Number(this.receitaDetails['valor_liquidado']);
    } else {
      this.pagamentoParcial = (-1 * this.movimentosTotal);
    }

    this.pagEmFalta = (this.receitaDetails.valor - this.pagamentoParcial);
    this.toPayPlaceholder = (Math.round(this.pagEmFalta * 100) / 100) + ' € a receber';

    let hasParcial = false;
    if (Array.isArray(this.registoAct)) {
      // let aux = this.registoAct.find(el => (el.msg.indexOf('parcial') !== -1));
      let aux = this.registoAct.find(el => (el && el.hasOwnProperty('msg') && el.msg.indexOf('parcial') !== -1));
      if (aux) hasParcial = true;
    }

    // SET ESTADO DO PAGAMENTO
    if (this.pagamentoParcial >= Number(this.valor) && !hasParcial) {
      this.estadoPagamento = 'PAGO';
    } else if (this.pagamentoParcial < Number(this.valor) && this.pagamentoParcial > 0 || hasParcial) {
      this.estadoPagamento = 'PARCIAL_PAGO';
    } else {
      this.estadoPagamento = 'NAO_PAGO';
    }
  }

  restoreForm(targetForm) {
    switch (targetForm) {
      case 'pagamento':
        this.cod_conta_bancaria = null;
        this.dt_pag = null;
        this.datePagEnabled = true;
        break;
      case 'receita':
        this.cod_condominio = (this.receitaDetails) ? { name: this.receitaDetails.cod_condominio + ' - ' + this.receitaDetails.nome_condominio, value: { cod: this.receitaDetails.cod_condominio, nome: this.receitaDetails.nome_condominio } } : null;
        this.n_receita = (this.receitaDetails) ? this.receitaDetails.n_receita : null;
        this.cod_rubrica = (this.receitaDetails) ? this.receitaDetails.cod_rubrica : null;
        this.cod_sub_rubrica = (this.receitaDetails) ? this.receitaDetails.cod_sub_rubrica : null;
        this.cod_fornecedor = (this.receitaDetails) ? { name: this.receitaDetails.nome_fornecedor, value: { cod: this.receitaDetails.cod_fornecedor, nome: this.receitaDetails.nome_fornecedor } } : null;
        this.tipo_proc = (this.receitaDetails) ? this.receitaDetails.tipo_proc : 'E';
        this.cod_proc = (this.receitaDetails) ? this.receitaDetails.cod_proc : null;
        this.dt_desp = (this.receitaDetails) ? this.utils.getDate(this.receitaDetails.dt_desp) : new Date();
        this.dt_pag = (this.receitaDetails) ? this.utils.getDate(this.receitaDetails.dt_pag) : null;
        this.dt_venc = (this.receitaDetails) ? this.utils.getDate(this.receitaDetails.dt_venc) : null;
        this.valor = (this.receitaDetails) ? Number(this.receitaDetails.valor).toFixed(2) : null;
        this.valor_pago = null;
        this.n_documento = (this.receitaDetails) ? this.receitaDetails.n_documento : null;
        this.form_pagam = (this.receitaDetails) ? this.receitaDetails.form_pagam : null;
        this.cod_conta_bancaria = (this.receitaDetails) ? this.receitaDetails.cod_conta_bancaria : null;
        this.descricao = (this.receitaDetails) ? this.receitaDetails.descricao : null;
        this.n_doc_pagam = (this.receitaDetails) ? this.receitaDetails.n_doc_pagam : null;
        this.ref_interna = (this.receitaDetails) ? this.receitaDetails.ref_interna : null;
        this.nid_movimento = (this.receitaDetails) ? this.receitaDetails.nid_movimento : null;
        this.reparticao = (this.receitaDetails) ? this.receitaDetails.reparticao : 'PO';
        break;
    }
  }

  isFormValid() {
      return (this.cod_condominio && this.cod_rubrica && this.cod_fornecedor && this.dt_desp && this.valor && this.descricao);
  }

  async openPagamentoModal() {
    if (!this.isFormValid()) {
      this.submittingForm = true;
      setTimeout(() => { this.submittingForm = false; }, 2000);
      return;
    }

    this.total = this.utils.cleanDecimalDigits(this.total);
    this.valor = this.utils.cleanDecimalDigits(this.valor);

    if (this.reparticao === 'M' && this.total !== this.valor) {
      this.toastr.error('O valor da receita é diferente do total das repartições pelas zonas.', 'Ajuste Manual Necessário', { timeOut: 4000 });
      return;
    }

    let form_pagam = null;
    let cod_conta_bancaria = null;
    let dt:Date = this.dt_desp ? new Date(this.dt_desp) : null;
    let pagEmFalta = this.utils.cleanDecimalDigits(this.valor - this.movimentosTotal);
    if (this.userSession.isCaixaVertisLoggedIn()) {
      form_pagam = '1';
      dt = new Date();
      dt.setHours(0,0,0,0);
    }

    let form: PaymentForm = {
      form_pagam: form_pagam,
      dt_pag_valor: dt,
      dt_pag: dt,
      cod_conta_bancaria: cod_conta_bancaria,
      n_doc_pagam: '',
      valor_pago: pagEmFalta.toString(),
    }

    let modalInput: PaymentModalInput = {
      form: form,
      maxPagamento: pagEmFalta,
      formPagamDisabled: false,
      dateDisabled: false,
      inCaixaVertis: false,
      dt_default: this.dt_desp,
      valorPlaceholder: pagEmFalta + ' € a receber',
      showNDoc: false,
      showSaldo: false,
      minDate: new Date(this.dt_desp),
      type: 'RECEITA_EXTRA',
      payFunction: this.setPayment
    }

    this.modalPagOpen = true;
    await this.paymentModal.open(modalInput);
    this.modalPagOpen = false;
  }

  dateSelected() {
    if (!this.dt_desp) return;

    if (this.isCreate) {
      let processamento = null;
      processamento = this.processamentosOrig.find(proc => (proc.tipo_proc === 'E'));
  
      if (processamento && processamento.hasOwnProperty('cod')) {
        this.cod_proc = processamento['cod'];
      } else {
        // this.toastr.error('Nenhum processamento disponível esta despesa', 'Ups...!', { timeOut: 4000 });
      }
    }
  }

  prevTab = null;
  tabSelected(tab) {
    if (this.prevTab === 'ACTIVIDADES' && tab === 'RECEITA') { this.restoreForm('receita'); }
    this.prevTab = tab;
  }

  savingPayment = false;
  formSubmitted() {
    return new Promise(async (resolve) => {
      if (!this.isFormValid()) {
        this.submittingForm = true;
        setTimeout(() => { this.submittingForm = false; }, 2000);
        resolve(false);
        return;
      }

      this.total = Math.round(Number(this.total) * 100) / 100;
      this.valor = Math.round(Number(this.valor) * 100) / 100;

      if (this.reparticao === 'M' && this.total !== this.valor) {
        resolve(false);
        return;
      }

    
      let cod_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
      let cod_fornecedor = (this.cod_fornecedor.hasOwnProperty('value')) ? this.cod_fornecedor.value.cod : this.cod_fornecedor;
    

      // CHECK TIPO PAGAMENTO
      let tipoPag = '';
      let conta = this.contaOptsOrig.find(el => (el.value === this.cod_conta_bancaria));
      if (conta) { // PAGAMENTO EM NUMERARIO
        tipoPag = (conta.name === 'CAIXA') ? ' -N' : ' -T';
      }

      // REGISTO DE ACTIVIDADES ---------------------------------------
      let activity = {
        msg: null,
        activity: (this.isCreate) ? 'Criado por ' : 'Actualizado por ',
        user: this.userSession.getUserFullName(),
        date: new Date(),
      };

      this.registoAct = this.receitaDetails && this.receitaDetails.obj ? JSON.parse(this.receitaDetails.obj) : [];
      this.registoAct = this.registoAct.filter(el => (el !== null));

      if (this.isCreate) {
        activity.msg = 'Receita lançada. Data de lançamento: ' + this.utils.getFormatedDate(new Date(this.dt_desp)) + ', Valor: ' + this.valor + ' €.';
        this.registoAct = [activity].concat(this.registoAct);

        if (this.valor_pago) {
          activity = {
            msg: (this.dt_pag === null) ? 'Recebimento parcial efectuado: '  + Number(this.valor_pago).toFixed(2) + ' €, ': 'Recebimento efectuado: '  + Number(this.valor_pago).toFixed(2) + ' €, ',
            activity: (this.isCreate) ? 'Criado por ' : 'Actualizado por ',
            user: this.userSession.getUserFullName(),
            date: new Date(),
          };
          if (conta) activity.msg += 'Conta: ' + conta.name + '.';
          this.registoAct = [activity].concat(this.registoAct);
        }
      } else {
        if (this.valor_pago) {
          activity.msg = (Number(this.valor_pago) < Number(this.valor) && Number(this.valor_pago) > 0) ? 'Recebimento parcial efectuado: '  + Number(this.valor_pago).toFixed(2) + ' €, ': 'Recebimento efectuado: '  + Number(this.valor_pago).toFixed(2) + ' €, ';
          if (conta) activity.msg += 'Conta: ' + conta.name + '.';
        } else {
          activity.msg = 'Receita actualizada. Data de lançamento: ' + this.utils.getFormatedDate(new Date(this.dt_desp)) + ', Valor: ' + this.valor + ' €.';
          // if (this.valorOriginal !== null && Number(this.valorOriginal) !== Number(this.valor)) {
          //   activity.msg += ' Valor da receta modificado: ' + Number(this.valorOriginal) + '€ para ' + Number(this.valor) + '€';
          // }
        }
        this.registoAct = [activity].concat(this.registoAct);
      }
      // ----------------------------------------------------------------    

      let isDespesaDateOk = !this.movimentos.find(el => this.utils.compareDayDates(this.dt_desp, el.dt_mov) > 0);

      if (!this.modalPagOpen && Array.isArray(this.movimentos) && this.movimentos.length === 1 && (this.valorOriginal !== this.valor || ((new Date(this.dtDespOriginal)).getTime() !== (new Date(this.dt_desp)).getTime()))) {
        this.updateMovModalRef = this.modalService
          .open(this.updateMovAlertConfig)
          .onApprove(async () => {
            // UPDATE MOVIMENTO
            let dt_mov = null;
            if ( ((new Date(this.dtDespOriginal)).getTime() !== (new Date(this.dt_desp)).getTime()) ) {
              dt_mov = new Date(this.dt_desp);
            }

            let res = await this.saveReceita(cod_condominio, cod_fornecedor, tipoPag, this.movimentos[0].id, dt_mov);
            resolve(res);
          })
          .onDeny(async (res) => {
            if (res !== 'closed') {
              // DO NOT UPDATE MOVIMENTO
              if (this.valor < this.movimentosTotal) {
                this.toastr.error('Valor da receita inferior ao valor pago até à data. Por favor, actualize primeiro o valor do(s) movimento(s)', 'Alerta', { timeOut: 4000 });
                this.loading = false;
                resolve(false);
                return;
              }
              if (!isDespesaDateOk) {
                this.toastr.error('Data da receita superior à data de pagamento. Por favor, actualize primeiro a data do movimento associado.', 'Alerta', { timeOut: 4000 });
                this.loading = false;
                resolve(false);
                return;
              }

              let res = await this.saveReceita(cod_condominio, cod_fornecedor, tipoPag);
              resolve(res);
            } else {
              this.loading = false;
              resolve(false);
            }
          });
      } else {
        if (!isDespesaDateOk) {
          this.toastr.error('Data da despesa superior à data do(s) movimento(s). Por favor, actualize primeiro a data do(s) movimento(s) associados.', 'Alerta', { timeOut: 4000 });
          resolve(false);
          return;
        }
        let res = await this.saveReceita(cod_condominio, cod_fornecedor, tipoPag);
        resolve(res);
      }
    });
  }

  saveReceita(cod_condominio, cod_fornecedor, tipoPag, movimentoId=null, dtMovimento=null): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.savingPayment) {
        resolve(false);
        return;
      }
      this.savingPayment = true;
  
      this.api.saveReceita(this.zonaList, this.receitaId, this.receitaCod, cod_condominio, this.n_receita, this.cod_rubrica, this.cod_sub_rubrica, cod_fornecedor, this.tipo_proc, this.cod_proc, this.dt_desp, this.dt_valor, this.dt_pag, this.dt_venc, this.valor, this.valor_pago, this.n_documento, this.form_pagam, this.cod_conta_bancaria, this.descricao, this.n_doc_pagam, this.ref_interna, this.nid_movimento, this.reparticao, JSON.stringify(this.registoAct), tipoPag, this.estadoPagamento, movimentoId, dtMovimento).subscribe(async res => {
        let success = res.hasOwnProperty('success') && res['success'];
        if (success) {
          this.location.back();
        } else {
          this.utils.apiErrorMsg(res);
        }
        this.submittingForm = false;
        this.loading = false;
        this.savingPayment = false;
        resolve(success);
        }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.submittingForm = false;
        this.loading = false;
        this.savingPayment = false;
        resolve(false);
      }); 
    });
  }
  
  setPayment: (data:PaymentForm) => Promise<boolean> = (data) => {
    return new Promise(async (resolve) => {
      this.form_pagam = data.form_pagam;
      this.dt_valor = data.dt_pag_valor;
      this.dt_pag = data.dt_pag;
      this.cod_conta_bancaria = data.cod_conta_bancaria;
      this.n_doc_pagam = data.n_doc_pagam;
      this.valor_pago = data.valor_pago;
  
      let res = await this.formSubmitted();
      if (!res) {
        this.form_pagam = null;
        this.dt_valor = null;
        this.dt_pag = null;
        this.cod_conta_bancaria = null;
        this.n_doc_pagam = null;
        this.valor_pago = null;
      }
      resolve(true);
    });
    
  }

  @ViewChild('pdf', { static: false }) pdfController;
  format = 'dd-MM-yyyy';
  locale = 'pt-PT';
  now = new Date();
  fetchDone = false;

  exportPDF() {
    if (this.movimentos.length === 0) {
      this.toastr.warning('Não é possível gerar o comprovativo de pagamento. A receita não tem valores recebidos.', 'Alerta', { timeOut: 4000 });
      return;
    }

    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';
    
    let filename = this.receitaDetails.nome_condominio.replace(/ /g, '_') + '_' + this.receitaDetails.n_receita + '_' + formatDate(this.now, this.format, this.locale);
    filename = filename.replace(/,/g, '');
    
    this.pdfController.saveAs(filename + '.pdf');
  }

  emailInvalid = false;
  hasEmailAddr() {
    if (!this.utils.validateEmail(this.emailAddr)) {
      this.emailInvalid = true;
      this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
    }

    this.submittingFormEmail = true;
    if (!this.emailAddr || this.emailInvalid) {
      setTimeout(() => { this.submittingFormEmail = false; this.emailInvalid = false; }, 4000);
      return;
    }

    this.emailModalRef.approve();
  }

  submittingFormEmail = false;
  sendingEmail = false;
  emailAddr = null;
  emailName = null;
  sendEmail() {
    if (this.movimentos.length === 0) {
      this.toastr.warning('Não é possível gerar o comprovativo de pagamento. A receita não tem valores recebidos.', 'Alerta', { timeOut: 4000 });
      return;
    }

    if (this.sendingEmail) return;

    this.emailAddr = this.receitaDetails.email;
    this.emailName = this.receitaDetails.nome_fornecedor;

    this.emailModalRef = this.modalService.open(this.emailAlertConfig)
      .onApprove(() => {
          this.submittingFormEmail = false; 

          this.sendingEmail = true;
          this.pdfController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {        
            let base64  = dataUri.replace('data:application/pdf;base64,', '');
            base64  = base64.replace(' ', '+');
      
            let from = this.appConfig.company.email;;
            let to = this.emailAddr;
            let subjectMsg = 'Envio de comprovativo de pagamento de receita nº ' + this.receitaDetails.n_receita;
            let bodyMsg = this.getEmailBody();
            let attachment = base64;
            let fromName = 'VERTIS - Gestão Condomínios';
            let toName = (this.emailName && this.receitaDetails.email === this.emailAddr) ? this.emailName : null;
            
            let filename = 'receita_' + formatDate(new Date(), this.format, this.locale) + '.pdf';

            let emailsToSend = [{
              from: from,
              to: to,
              subject: subjectMsg,
              body: bodyMsg,
              attachment: attachment,
              filename: filename,
              fromName: fromName,
              toName: toName,
            }];
            this.api.sendEmailV2(emailsToSend).subscribe(res => {
              this.toastr.success('Comprovativo de pagamento enviado com sucesso.', 'Email Enviado', { timeOut: 4000 });
      
              this.emailAddr = null;
              this.sendingEmail = false;
            }, err => { this.sendingEmail = false; });
            
          });
      })
      .onDeny(() => { 
        this.emailAddr = null;
        this.submittingFormEmail = false;
      });
  }

  getEmailBody() {
    let htmlEmail = '';

        // TEXTO INICIAL
        htmlEmail += '<div style="margin-bottom: 35px;">';
        this.appConfig.receitaBody.forEach(line => {
          if (line) {
            htmlEmail += '<span>' + line + '</span>';
          } else {
            htmlEmail += '<span><br><br></span>';
          }
        });
        htmlEmail += '</div>';

        htmlEmail += this.utils.getEmailFooter();

      return this.utils.setEmailTemplate(htmlEmail);
  }

  reparticaoChanged() {
    this.valorChanged();
  }

  zonaChanged(zona) {
    zona.checked = (zona.valor);

    this.computeTotal();
  }

  total = 0;
  valorDespesa = null;
  valorChanged(fromValor=false) {
    if (this.valor !== null) this.valor = Math.round(this.valor * 100) / 100;

    setTimeout(() => {
      this.zonaList.filter(el => (!el.checked)).forEach(el => { el.valor = null; });

      if (!this.valor) {
        this.zonaList.forEach(el => { el.valor = null; });
      }

      let value = null;
      switch (this.reparticao) {
        case 'P':
        case 'PO':
          let permTotal = 0;
          this.zonaList.filter(el => el.checked).forEach(el => {
            permTotal += el.permilagem;
          });

          this.zonaList.filter(el => el.checked).forEach(el => { 
            el.valor = Math.round(((el.permilagem / permTotal) * this.valor) * 100) / 100;
          });
          break;
        case 'E':
          let totalFraccoes = 0;
          this.zonaList.filter(el => el.checked).forEach(el => { totalFraccoes += el.n_fraccoes; });

          this.zonaList.filter(el => el.checked).forEach(el => {
            el.valor = Math.round(((this.valor / totalFraccoes) * el.n_fraccoes) * 100) / 100;
          });
          break;
        case 'M':
          if (fromValor) {
            let totalFraccoes = 0;
            this.zonaList.filter(el => el.checked).forEach(el => { totalFraccoes += el.n_fraccoes; });
  
            this.zonaList.filter(el => el.checked).forEach(el => {
              el.valor = Math.round(((this.valor / totalFraccoes) * el.n_fraccoes) * 100) / 100;
            });
          }
          break;
      }

      this.zonaList = JSON.parse(JSON.stringify(this.zonaList));

      this.computeTotal();

      if (this.zonaList.length > 1 && this.total !== this.valor) {
        // HANDLE ARREDONDAMENTOS (AUTOMÁTICO)
        let zonaMaxPerm = this.zonaList.filter(el => (el.checked)).reduce(function(a, b) {
          return (a.permilagem >= b.permilagem) ? a : b
        });
        let diff = (this.total - this.valor);
        if (zonaMaxPerm) {
          zonaMaxPerm.valor -= diff;

          zonaMaxPerm.valor = Math.round(zonaMaxPerm.valor * 100) / 100;
        }

        this.total = this.valor;
      }
    }, 1);
  }

  totalComputed = false;
  computeTotal() {
    this.total = 0;
    this.zonaList.forEach(el => {
      this.total += Number(el.valor);
    });
    this.total = Math.round(this.total * 100) / 100;

    this.totalComputed = true;
  }


  async checkMovimento(event, mov) {
    event.preventDefault();
    if (mov.checked) {
      setTimeout(() => {
        mov.checked = false;
      });
      return;
    }

    let canEdit = await this.movimentosService.canChangeMovimento(mov.id);
    if (!canEdit) return;
    
    let isInCVHistory = mov.id_caixa_vertis && mov.dt_mov && this.utils.compareDayDates(mov.dt_mov, new Date()) < 0;
    let isInCvNow = mov.id_caixa_vertis && mov.dt_mov && this.utils.compareDayDates(mov.dt_mov, new Date()) === 0;
    
    if (isInCvNow || isInCVHistory) {
      this.toastr.warning('Movimento registado em caixa vertis.', 'Atenção');
    }
    mov.checked = true;
  }

  movEdit = null;
  async editMovimento(mov) {

    let canEdit = await this.movimentosService.canChangeMovimento(mov.id);
    if (!canEdit) return;
    
    let isInCVHistory = mov.id_caixa_vertis && mov.dt_mov && this.utils.compareDayDates(mov.dt_mov, new Date()) < 0;
    let isInCvNow = mov.id_caixa_vertis && mov.dt_mov && this.utils.compareDayDates(mov.dt_mov, new Date()) === 0;
    
    if (isInCvNow || isInCVHistory) {
      this.toastr.warning('Movimento registado em caixa vertis.', 'Atenção');
    }

    
    this.movEdit = mov;

    let form_pagam = null;
    if (mov.descricao.indexOf('-N') !== -1) form_pagam = '1';
    if (mov.descricao.indexOf('-T') !== -1) form_pagam = '2';

    if (form_pagam === null) {
      let conta = this.contaOpts.find(el => (el.value === mov.nid_conta));
      if (conta) {
        form_pagam = (conta.name.indexOf('CAIXA') !== -1) ? '1' : '2';
      }
    }

    let form: PaymentForm = {
      form_pagam: form_pagam,
      dt_pag_valor: mov.dt_valor ? new Date(mov.dt_valor) : null,
      dt_pag: mov.dt_mov ? new Date(mov.dt_mov) : null,
      cod_conta_bancaria: mov.nid_conta,
      n_doc_pagam: null,
      valor_pago: mov.valor.toString(),
    }

    let maxValor = this.utils.cleanDecimalDigits(parseFloat(this.receitaDetails.valor) - this.movimentosTotal + mov.valor);


    let input: PaymentModalInput = {
      form: form,
      maxPagamento: maxValor,
      formPagamDisabled: false,
      dateDisabled: false,
      inCaixaVertis: isInCVHistory,
      dt_default: this.dt_desp,
      valorPlaceholder: maxValor + ' € a liquidar',
      showNDoc: false,
      showSaldo: true,
      minDate: new Date(this.dt_desp),
      type: 'EDIT_MOVEMENT',
      payFunction: this.saveMovimento
    }


    this.modalPagOpen = true;
    await this.paymentModal.open(input);
    this.modalPagOpen = false;
  }

  saveMovimento: (data:PaymentForm) => Promise<boolean> = (data) => {
    return new Promise((resolve) => {
      // VALIDATE INPUT
      this.submittingForm = true;

      let movDescricao = this.movEdit.descricao.indexOf(' -T') !== -1 || this.movEdit.descricao.indexOf(' -N') !== -1? this.movEdit.descricao.substring(0, this.movEdit.descricao.length - 3) : this.movEdit.descricao;
      if (data.form_pagam === '1' || data.form_pagam === '6') {
        movDescricao += ' -N';
      } else {
        movDescricao += ' -T';
      }



      let despObj = null;
      if (this.receitaDetails.obj) {
        despObj = JSON.parse(this.receitaDetails.obj);  
        let conta = this.contaOptsOrig.find(el => (el.value === data.cod_conta_bancaria)).name;
        despObj = [{
          msg: 'Movimento actualizado. Data Valor: ' + this.utils.getFormatedDate(data.dt_pag_valor) + ', Data de pagamento: ' + this.utils.getFormatedDate(data.dt_pag) + ', Conta: ' + conta + ', Valor: ' + data.valor_pago + ' €.',
          activity: 'Actualizado por ',
          user: this.userSession.getUserFullName(),
          date: new Date(),
        }].concat(despObj);
      }

      this.api.updateReceitasMovimento(this.movEdit.id, data.dt_pag_valor, data.dt_pag, data.valor_pago, data.cod_conta_bancaria, movDescricao, this.receitaId, this.receitaDetails.n_receita, this.receitaDetails.cod_condominio, despObj).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.getReceitaDetails();
          this.submittingForm = false;
          resolve(true);
        } else {
          this.submittingForm = false;
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.submittingForm = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }

  toDelete = [];
  loadingDeleteModal = false;
  deleteMovimentos() {
    let dtMovimento = null;
    let dtMov = null;
    this.movimentos.filter(el => !el.checked).forEach(el => {
      dtMov = new Date(el.dt_mov);

      if (dtMovimento === null || (dtMovimento && dtMov.getTime() > dtMovimento.getTime())) {
        dtMovimento = dtMov;
      }
    });

    this.toDelete = JSON.parse(JSON.stringify(this.movimentos.filter(el => el.checked)));
    this.toDelete.forEach(el => {
      el['id_receita'] = this.receitaDetails.id;
      el['n_receita'] = this.receitaDetails.n_receita;
      el['cod_condominio'] = this.receitaDetails.cod_condominio;
      el['dt_movimento_despesa'] = (dtMovimento) ? this.utils.getFormatedDate(dtMovimento) : null;
    });

    if (this.toDelete.length > 0) {

      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => { this.loadingDeleteModal = false; this.toDelete = []; })
        .onDeny(() => { this.loadingDeleteModal = false; this.toDelete = []; });

    } else {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
    }
  }

  del() {
    this.loadingDeleteModal = true;

    let despObj = (this.receitaDetails.obj) ? JSON.parse(this.receitaDetails.obj) : [];
    this.toDelete.forEach(mov => {
      let movValor = (mov.valor > 0) ? mov.valor : -1 * mov.valor;
      despObj = [{
        msg: 'Movimento removido. Data Valor: ' + (mov.dt_valor? this.utils.getFormatedDate(mov.dt_valor) : '--') + ', Data Mov.: ' + this.utils.getFormatedDate(mov.dt_mov) + ' Conta: ' + mov.banco + ', Valor: ' + movValor + ' €.',
        activity: 'Actualizado por ',
        user: this.userSession.getUserFullName(),
        date: new Date(),
      }].concat(despObj);
    });

    this.toDelete.forEach(mov => {
      mov['desp_obj'] = JSON.stringify(despObj);
      mov['dt_mov'] = this.utils.getFormatedDate(mov.dt_mov);
    });

    this.api.delMovimentos(this.toDelete, 'RECEITAS').subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.registoAct = JSON.parse(JSON.stringify(despObj));
        this.receitaDetails.obj = JSON.stringify(despObj);

        this.toDelete.forEach(del => {
          this.movimentos = this.movimentos.filter(el => (el.id !== del.id));
        });

        this.movimentosTotal = 0;
        this.movimentos.forEach(el => { this.movimentosTotal += el['valor']; });

        // UPDATE VALOR EM FALTA POR PAGAR
        this.pagEmFalta = this.valor + this.movimentosTotal;
        if (this.pagEmFalta < 0) this.pagEmFalta = 0;

        this.setPagParcial(true);

      } else {
        this.utils.apiErrorMsg(res);
      }
      this.alertModalRef.approve();
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingDeleteModal = false;
    });
  }

  goToCondominio() {
    if ( !(this.receitaDetails && this.receitaDetails.hasOwnProperty('classificacao')) ) return;
    
    let classificacao = this.receitaDetails.classificacao;
    let cod = this.receitaDetails.cod_condominio;
    let id = this.receitaDetails.id_condominio;
    let nome = this.receitaDetails.nome_condominio;

    this.router.navigate(['condominios/condominio/geral', id]);

    // Emit signal to breadcrumb component
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${classificacao} - ${cod} - ${nome}` });
  }
}
