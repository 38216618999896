import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { Subscription } from 'rxjs';

import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { UserSessionService } from '../user-session.service';
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppStateService } from '../app-state.service';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  loadingModal = false;

  sideMenuCollapsed = false;
  username: string = null;

  selMenuColor = null;
  selMenuEntry = null;

  @ViewChild('modalTemplate', { static: false }) public modalTemplate;
  modalRef = null;

  @ViewChild('logoutCVAlertRef', { static: false }) logoutCVAlertRef;
  logoutCVModalRef = null;
  logoutCVAlertConfig: any = null;

  @ViewChild('mapaReunioesAlertRef', { static: false }) mapaReunioesAlertRef;
  mapaReunioesModalRef = null;
  mapaReunioesAlertConfig: any = null;

  subsRouter: Subscription = null;
  subsMsg: Subscription = null;

  subLevel: string = null;

  caixaVertisLoggedIn = false;

  usersOpts = [];
  userSelected = null;

  isCaixaClosed = false;

  condoInfoEnabled = false;
  condoInfo = {
    estado: null,
    oneDrive: null,
    bitrix: null,
  }

  constructor(public router: Router,
              public userSession: UserSessionService,
              public modalService: SuiModalService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public appState: AppStateService,
              public api: ApiService,
              public utils: UtilitiesService,
              public message: MessageService) {
    // CHECK IF USER IS LOGGED IN TO CAIXA VERTIS
    if (this.userSession.getUserId() !== null) {
      this.api.isCaixaVertisLoggedIn().subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.status) {
            this.caixaVertisLoggedIn = true;
  
            localStorage.setItem('isCaixaVertisLoggedIn', '1');
          } else {
            this.caixaVertisLoggedIn = false;
            localStorage.setItem('isCaixaVertisLoggedIn', '0');
          }
        }
      }, err => {});
    }

    // CHECK FOR CAIXA LOGGED IN
    this.caixaVertisLoggedIn = this.userSession.isCaixaVertisLoggedIn();

    // LISTEN TO NAVIGATION CHANGES
    this.subsRouter = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.selMenuEntry = this.appConfig.menuArr.find(el => (this.router.url.indexOf(el.route) != -1 ));

        // Reset sub-level
        if (this.router.url.split('/').length < 3) this.subLevel = null;

        this.getCondominioTopBarInfo();
      }
    });

    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'BREADCRUMB_COMP') {
        switch (msg.cmd) {
          case 'SET_SUBLEVEL': this.subLevel = this.utils.capitalizeSentence(msg.subLevel as string); break;
          case 'APPEND_SUBLEVEL':
            if (this.subLevel) {
              this.subLevel += this.utils.capitalizeSentence(msg.subLevel);
            } else {
              this.subLevel = this.utils.capitalizeSentence(msg.subLevel);
            }
            break;
          case 'CAIXA_VERTIS_LOGGED_IN':
            this.caixaVertisLoggedIn = true;
            break;
          case 'CAIXA_VERTIS_LOGGED_OUT':
            if (this.caixaVertisLoggedIn) this.toastr.success('Logout efectuado com sucesso.', 'Caixa Vertis', { timeOut: 4000 });
            this.caixaVertisLoggedIn = false;
            break;
          case 'LOGOUT':
            this.logout();
            break;
          case 'SET_CAIXA_VERTIS_STATE':
            this.isCaixaClosed = msg.value;
            break;
          case 'LOGIN_DONE':
            this.fromLogin = true;
            setTimeout(() => { this.fromLogin = false; }, 5000);
            this.getUserMapaReunioes();
            break;
          case 'GET_TOP_BAR_INFO':
            this.getCondominioTopBarInfo();
            break;
        }
      }
    });
  }

  getCondominioTopBarInfo() {
    let cod = null;
    if (this.appState.globalState.hasOwnProperty('global') && this.appState.globalState['global'].hasOwnProperty('selCondominio') && this.appState.globalState['global']['selCondominio'].hasOwnProperty('cod')) {
      cod = this.appState.globalState['global']['selCondominio']['cod'];
      this.condoInfoEnabled = true;
    } else{
      this.condoInfoEnabled = false;
    }

    if (!cod) return;

    this.api.getCondominioTopBarInfo(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.condoInfo.estado = res.data.estado;
        this.condoInfo.oneDrive = res.data.onedrive_link;
        this.condoInfo.bitrix = res.data.bitrix_link;
      } else {}
    }, err => {});
  }

  openLink(target) {
    switch (target) {
      case 'OneDrive':
        if (this.condoInfo.oneDrive) {
          window.open(this.condoInfo.oneDrive, '_blank');
        } else {
          this.toastr.error('Condomínio selecionado não tem link atribuído para: OneDrive.', 'Alerta', { timeOut: 4000 });
        }
        break;
      case 'Bitrix':
        if (this.condoInfo.bitrix) {
          window.open(this.condoInfo.bitrix, '_blank');
        } else {
          this.toastr.error('Condomínio selecionado não tem link atribuído para: Bitrix24.', 'Alerta', { timeOut: 4000 });
        }
        break;
    }
  }

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem('user_data'));
    if (userData && userData.hasOwnProperty('first_name') && userData.hasOwnProperty('last_name')) {
      if (!userData['last_name']) {
        this.username = `${userData['first_name']}`;
      } else {
        this.username = `${userData['first_name']} ${userData['last_name'][0]}.`;
      }
    }
  }

  featchingUsers = false;
  ngAfterViewInit() {

    // GET ALL APP USERS
    if (!this.featchingUsers) {
      this.featchingUsers = true;

      this.api.getUtilizadores(0, 500).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          // CONVERT 1/0 TO true/false
          this.usersOpts = res.data.filter(el => (el.id !== this.userSession.getUserId() && (el.is_admin === '1' || el.super_admin === '1' || el.utilizador_caixa === '1' || el.utilizador_balcao === '1'))).map(el => {
            return { name: el['first_name'] + ' ' + el['last_name'], value: el['id'] };
          });
        }
        this.usersOpts = this.usersOpts.filter(el => (el.id !== this.userSession.getUserId()));
        this.featchingUsers = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.featchingUsers = false;
      });
    }

    this.logoutCVAlertConfig = new TemplateModalConfig<IContext, string, string>(this.logoutCVAlertRef);
    this.logoutCVAlertConfig.closeResult = "closed";
    this.logoutCVAlertConfig.size = 'tiny';
    this.logoutCVAlertConfig.transition = 'fade';
    this.logoutCVAlertConfig.transitionDuration = 250;

    this.mapaReunioesAlertConfig = new TemplateModalConfig<IContext, string, string>(this.mapaReunioesAlertRef);
    this.mapaReunioesAlertConfig.isClosable = false;
    this.mapaReunioesAlertConfig.closeResult = "closed";
    this.mapaReunioesAlertConfig.size = 'small';
    this.mapaReunioesAlertConfig.transition = 'fade';
    this.mapaReunioesAlertConfig.transitionDuration = 250;

    setTimeout(() => { this.getUserMapaReunioes(); }, 1000);
  }

  ngOnDestroy() {
    if (this.subsRouter) this.subsRouter.unsubscribe();
    if (this.subsMsg) this.subsMsg.unsubscribe();
  }

  menuChanged(ev) {
    if (ev) {
      this.message.sendMessage({ dest: 'CAIXA_VERTIS', cmd: 'HIDE_INPUT' });
    } else {
      this.message.sendMessage({ dest: 'CAIXA_VERTIS', cmd: 'SHOW_INPUT' });
    }
  }

  goToPage(targetPage) {
    switch (targetPage) {
      case 'account': this.router.navigate(['utilizadores/utilizador', this.userSession.getUserId()]); break;
      case 'configs': this.router.navigate(['configuracoes']); break;
      case 'users': this.router.navigate(['utilizadores']); break;
      case 'workers': this.router.navigate(['funcionarios']); break;
      case 'activities': this.router.navigate(['actividades']); break;
      case 'registo-processos': this.router.navigate(['registo-processos']); break;
    }
  }

  openLogoutModal() {
    if (this.logoutCVModalRef !== null) return;

    this.logoutCVModalRef = this.modalService
      .open(this.logoutCVAlertConfig)
      .onApprove(() => { this.loadingModal = false; this.modalFormSubmitted = false; this.userSelected = null; this.logoutCVModalRef = null; })
      .onDeny(() => { this.loadingModal = false; this.modalFormSubmitted = false; this.userSelected = null; this.logoutCVModalRef = null; });
  }

  loggingOut = false;
  loggingOutApp = false;
  logout() {
    if (this.caixaVertisLoggedIn) {
      this.loggingOutApp = true;
      this.openLogoutModal();
      return;
    }

    if (this.loggingOut) return;
    this.loggingOut = true;

    // LOGOUT APP
    this.api.logout().subscribe(res => {
      this.api.unsetAuthHeader();

      // REGISTO ACTIVIDADES API CALL
      let descricao = 'Logout efectuado.';
      this.api.saveRegistoActividade(null, null, null, null, descricao).subscribe(res => {}, err => { });
    }, err => { });

    // LOGOUT CAIXA VERTIS
    this.api.logoutCaixa().subscribe(res => {
      this.userSession.logout();

      this.loggingOut = false;
      this.loggingOutApp = false;

      // REGISTO ACTIVIDADES API CALL
      let descricao = 'Logout Caixa Vertis efectuado.';
      this.api.saveRegistoActividade(null, null, null, null, descricao).subscribe(res => {}, err => { });

    }, err => { this.loggingOut = false; this.loggingOutApp = false; });
  }

  modalFormSubmitted = false;
  checkingCaixaVertis = false;
  logoutCaixa(fullLogout=true) {
    if (!this.caixaVertisLoggedIn) return;

    if (fullLogout) {
      if (this.checkingCaixaVertis) return; 

      if (!this.isCaixaClosed) {
        this.toastr.error('Não é possível efectuar logout. Necessita primeiro de efectuar o fecho de Caixa Vertis.', 'Alerta', { timeOut: 4000 });
      } else {
        this.logoutCaixaVertis(fullLogout);
      }

      // // CHECK IF CAIXA VERTIS IS CLOSED
      // this.checkingCaixaVertis = true;
      // this.api.hasCaixaVertisOpened(true).subscribe(res => {
      //   if (res.hasOwnProperty('success') && res.success && res.hasOwnProperty('data') && res.data.length > 0 && res.data[0]['dt_fecho']) {
      //     this.logoutCaixaVertis(fullLogout);

      //     this.checkingCaixaVertis = false;
      //   } else {
      //     this.toastr.error('Não é possível efectuar logout. Necessita primeiro de efectuar o fecho de Caixa Vertis.', 'Alerta', { timeOut: 4000 });
      //     this.checkingCaixaVertis = false;
      //   }
      // }, err => { });

    } else {
      this.logoutCaixaVertis(fullLogout);
    }
  }

  logoutCaixaVertis(fullLogout) {
    if (!fullLogout) {
      this.modalFormSubmitted = true;
      if (!this.userSelected) {
        setTimeout(() => { this.modalFormSubmitted = false; }, 4000);
        return;
      }
    }

    let nextCaixaVertisUserId = (this.userSelected) ? this.userSelected : null;

    this.loadingModal = true;
    this.api.logoutCaixa(nextCaixaVertisUserId).subscribe(res => {
      if (res['success'] && res.success) {
        if (nextCaixaVertisUserId === null) this.userSession.setCaixaVertisState('logout');
      } else {
        // this.userSession.setCaixaVertisState('logout');
      }

      if (this.loggingOutApp) {
        if (nextCaixaVertisUserId === null) this.logout();
      } else {
        // if (nextCaixaVertisUserId === null) this.toastr.success('Logout efectuado com sucesso.', 'Caixa Vertis', { timeOut: 4000 });
      }

      this.logoutCVModalRef.approve();

      // REGISTO ACTIVIDADES API CALL
      let descricao = 'Logout Caixa Vertis efectuado.';
      this.api.saveRegistoActividade(null, null, null, null, descricao).subscribe(res => {}, err => { });
      this.message.sendMessage({ dest: 'CAIXA_VERTIS', cmd: 'LOGGED_USER', username: null, refresh: true });

    }, err => {
      this.toastr.error('Não foi possível efectuar o logout, por favor, tente mais tarde.', 'Caixa Vertis', { timeOut: 4000 });
      this.logoutCVModalRef.deny();
    });
  }


  // MAPA REUNIOES DO DIA - ALERTA
  fromLogin = false;
  mapaReuniaoListCol = [
    { key: 'condominio', name: 'Condomínio', type: 'text', sort: null, searchable: false, center: false, class: 'col-align-left' },
    { key: 'data_reuniao', name: 'Data/hora', type: 'text', sort: null, searchable: false, center: false, class: 'col-align-right' },
  ];
  mapaReuniaoList = [];
  getUserMapaReunioes() {
    let date = new Date();

    this.api.getMapaReuniaoByUser(date).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        if (res.data.length === 0) return;

        // SET DATASET
        this.mapaReuniaoList = res.data.map(el => {
          el['data_convocatoria'] = new Date(el.data_reuniao);

          return el;
        });
        if (this.mapaReuniaoList.length > 1) {
          this.mapaReuniaoList = this.mapaReuniaoList.sort((a, b) => {
            if ((a.data_convocatoria.getTime()) > (b.data_convocatoria.getTime())) {
              return 1;
            } else {
              return -1;
            }
          });
        }

        // CHECK LAST ALERT PRESENTED
        let presentAlert = false;
        let prevAlertDate: any = localStorage.getItem('mapaReuniaoAlertDate');
        if (prevAlertDate) {
          prevAlertDate = new Date(prevAlertDate);
          prevAlertDate.setHours(0, 0, 0, 0);

          let now = new Date();
          now.setHours(0, 0, 0, 0);

          presentAlert = (now > prevAlertDate);
        } else {
          presentAlert = true;
        }

        if (presentAlert || this.fromLogin) {
          let alertDate = new Date();
          localStorage.setItem('mapaReuniaoAlertDate', alertDate.toString());

          this.mapaReunioesModalRef = this.modalService
            .open(this.mapaReunioesAlertConfig)
        }

      }
    }, err => {});
  }
}
