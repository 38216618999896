import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { CommunicationsService } from '../business-logic-services/communications.service';
import { BusinessLogicService } from '../business-logic.service';
import { RegistoComunicacaoSave } from '../business-model-interfaces/comunicacoes';
import { ReciboDetailed } from '../business-model-interfaces/recibo';
import { ReconciliacaoBancariaEnvioRecibos } from '../business-model-interfaces/reconciliacoes-bancarias';
import { MessageService } from '../message.service';
import { PrintRecibosPdfComponent } from '../print-recibos-pdf/print-recibos-pdf.component';
import { ReciboPdfComponent } from '../recibo-pdf/recibo-pdf.component';
import { UtilitiesService } from '../utilities.service';

interface IContext {
  data:string;
}

interface listCol {
  class,
  type,
  name,
  key,
}
@Component({
  selector: 'app-enviar-recibos-email',
  templateUrl: './enviar-recibos-email.component.html',
  styleUrls: ['./enviar-recibos-email.component.scss']
})
export class EnviarRecibosEmailComponent implements OnInit {

  @ViewChild('alertRef', { static: false }) alertRef;
  modalRef = null;
  alertConfig: any = null;

  
  mainCheck = false;
  printedDocuments = false;

  constructor(
      public modalService: SuiModalService,
      public businessLogic: BusinessLogicService,
      public appConfig: AppConfigService,
      public utils: UtilitiesService,
      public message: MessageService,
      public api: ApiService,
      public toastr: ToastrService,
      public communicationsService: CommunicationsService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.alertConfig = new TemplateModalConfig<IContext, string, string>(this.alertRef);
    this.alertConfig.closeResult = "closed";
    this.alertConfig.isClosable = false;
    this.alertConfig.size = 'small';
    this.alertConfig.transition = 'fade';
    this.alertConfig.transitionDuration = 250;
  }

  listCol: Array<listCol> = [
    { key: 'checked', name: null, type: 'checkbox', class: 'one wide table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'n_recibo', name: 'Nº', type: 'text', class: 'one wide col-centered ' },
    { key: 'nome_condomino', name: 'Condómino', type: 'text', class: '' },
    { key: 'data', name: 'Data', type: 'text', class: 'two wide col-centered ' },
    { key: 'criado_em', name: 'Data Criação', type: 'text', class: 'two wide col-centered ' },
    { key: 'valor', name: 'Liquidado', type: 'text', class: 'two wide col-align-right ' },
  ];
  
  list: Array<ReciboDetailed & ReconciliacaoBancariaEnvioRecibos> = [];
  open(list: Array<ReciboDetailed & ReconciliacaoBancariaEnvioRecibos>):Promise<boolean> {
    return new Promise((resolve) => {
      if (!list || !list.length) {
        resolve(false);
        return;
      }
      this.list = list;
      this.mainCheck = true;
      this.printedDocuments = false;

      setTimeout(() => {
        this.sendEmails();
      });

      if (this.list.findIndex(recibo => recibo.carta === '1') === -1) {
        resolve(true);
        return;
      }

      this.modalRef = this.modalService
        .open(this.alertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => {
          resolve(false);
        });

     
    });
  }

  // updateMainCheckBox() {
  //   this.mainCheck = !this.list.find(el => !el.checked);
  //   this.hasSelection = this.list.findIndex(el => !!el.checked) !== -1;
  // }

  // updateChildrenCheckBox() {
  //   this.list.forEach(el => {
  //     el.checked = this.mainCheck;
  //   })
  //   this.hasSelection = this.mainCheck;
  // }
  approveModal() {
    if (this.modalRef && this.printedDocuments) this.modalRef.approve();
  }

  @ViewChildren('reciboPDF') previewReciboPDF: QueryList<ReciboPdfComponent>;
  recibosToSend:Array<string> = [];

  private sendEmails(): Promise<boolean> {
    return new Promise((resolve) => {
      let req:Array<Promise<string>> = [];
      this.previewReciboPDF.forEach((pdfRecibo, i) => {
        let recibo = this.list[i];
        if (!!recibo.email) req.push(pdfRecibo.getBase64(recibo.id));
      });
    
      if (!req.length) {
        resolve(true);
        return;
      }

      Promise.all(req).then(async res => {
        if (res.find(el => !el)) {
          this.toastr.error(this.appConfig.errMsg.email.unsentEmails.msg, this.appConfig.errMsg.email.unsentEmails.title);
          resolve(false);
          return;
        }

        let emailsToSend = [];
        let recibosByEmail = this.list.filter(el => !!el.email);
        let bodyMsg = this.getEmailBody();
        let registos: Array<RegistoComunicacaoSave> = [];
        res.forEach((el, i) => {
          let recibo = recibosByEmail[i];
          let from = this.appConfig.company.email;
          let to = recibo.email;
          let subjectMsg = 'Envio de recibo nº ' + recibo.n_recibo;
          let attachment = el;
          let fromName = 'VERTIS - Gestão Condomínios';
          let toName = (recibo.pag_nome) ? recibo.pag_nome : '';
          
          let filename = this.utils.getFileNameFormatted('Recibo ' + recibo.n_recibo + ' ' + this.utils.getFormatedDate(new Date()));
          emailsToSend.push({
            from: from,
            to: to,
            subject: subjectMsg,
            body: bodyMsg,
            attachment: attachment,
            filename: filename + '.pdf',
            fromName: fromName,
            toName: toName,
          });
          let aux: RegistoComunicacaoSave = {
            origem: 'RECIBOS',
            cod_condominio: recibo.cod_condominio,
            id_entidade: null,
            id_texto_predefinido: null,
            data_divida: null,
            assunto: subjectMsg,
            corpo: bodyMsg,
            email_list: [{
              cod_fraccao: recibo.cod_fraccao,
              nomeEntidade: toName,
              tipo: 'PROPRIETARIO',
              cod: recibo.cod_pagador,
              emails: [to]
            }],
            attachments: [{
              filename: filename,
              base64: el,
              ext: '.pdf'
            }]
          };
          registos.push(aux);
        })

        let success = await this.communicationsService.saveRegistoComunicacao(registos);
        if (!success) {
          resolve(false);
          return;
        }

        this.api.sendEmailV2(emailsToSend).subscribe(_ => {
          if (emailsToSend.length > 1) {
            this.toastr.success('Foram enviados ' + emailsToSend.length + ' recibos por email com sucesso.', 'Recibos Enviados');
          } else {
            this.toastr.success('Recibo enviado por email com sucesso.', 'Recibo Enviado');
          }
          let recibo = this.list.length? this.list[0] : null;
          if (recibo) {
            let cod_condominio = recibo.cod_condominio;
            let descricao = `Condomínio: ` + cod_condominio + ' - ' + recibo.cond_nome;
            this.api.saveRegistoActividade(cod_condominio, 'ENVIO_RECIBOS', null, 'Envio de Recibos Por Email', descricao).subscribe(res => {}, err => {})
          }
          resolve(true);
        }, err => { 
          this.toastr.error(this.appConfig.errMsg.email.unsentEmails.msg, this.appConfig.errMsg.email.unsentEmails.title);
          resolve(false);
        });
      }).catch(err => {
        this.toastr.error(this.appConfig.errMsg.email.unsentEmails.msg, this.appConfig.errMsg.email.unsentEmails.title);
        resolve(false);
      });
    });
  }

  @ViewChild('printRecibosPDF', { static: false }) printRecibosPDF: PrintRecibosPdfComponent;
  private printDocuments(): Promise<boolean> {
    return new Promise(async(resolve) => {
      let cartas = this.list.filter(el => el.carta === '1');
      if (!cartas.length) {
        this.printedDocuments = true;
        resolve(false);
        return;
      }
      let res = await this.printRecibosPDF.printRecibos(cartas[0].id_reconciliacao);
      this.printedDocuments = true;
      resolve(res);
    });
  }

  private getEmailBody() {
    let htmlEmail = '';

        // TEXTO INICIAL
        htmlEmail += '<div style="margin-bottom: 35px;">';
        this.appConfig.reciboBody.forEach(line => {
          if (line) {
            htmlEmail += '<span>' + line + '</span>';
          } else {
            htmlEmail += '<span><br><br></span>';
          }
        });
        htmlEmail += '</div>';

        htmlEmail += this.utils.getEmailFooter();

      return this.utils.setEmailTemplate(htmlEmail);
  }

}
