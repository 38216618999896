import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent, forkJoin } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, NgFormSelectorWarning } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
import { Location, formatDate } from '@angular/common';
interface IContext {
  data:string;
}

import { exportPDF, Group } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';
import { templateJitUrl } from '@angular/compiler';
import { MapaQuotasPdfComponent } from '../mapa-quotas-pdf/mapa-quotas-pdf.component';


@Component({
  selector: 'app-relatorio-exercicio',
  templateUrl: './relatorio-exercicio.component.html',
  styleUrls: ['./relatorio-exercicio.component.scss']
})
export class RelatorioExercicioComponent implements OnInit {

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('fechoContasAlertRef', { static: false }) fechoContasAlertRef;
  fechoContasModalRef = null;
  fechoContasAlertConfig: any = null;

  @ViewChild('reconcBancariaAlertRef', { static: false }) reconcBancariaAlertRef;
  reconcBancariaModalRef = null;
  reconcBancariaAlertConfig: any = null;

  @ViewChild('recibosDespesasAlertRef', { static: false }) recibosDespesasAlertRef;
  recibosDespesasModalRef = null;
  recibosDespesasAlertConfig: any = null;

  filterCriterio = 'PREDIO';
  filterOrdem = 'ASC';

  @ViewChild('pdf', { static: false }) pdfController;
  @ViewChild('pdfBalancete', { static: false }) pdfBalanceteController;
  @ViewChild('pdfDespesas', { static: false }) pdfDespesasController;
  @ViewChild('pdfMovCaixaBanco', { static: false }) pdfMovCaixaBancoController;
  @ViewChild('pdfContasCorrentes', { static: false }) pdfContasCorrentesController;
  @ViewChild('pdfOrcamentos', { static: false }) pdfOrcamentosController;

  reportDespesasDetailedListColPDF = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'first-report-col' },  // 'ASC', 'DESC'
    { key: 'n_doc', name: 'Lanç.', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered small-report-col' },
    { key: 'data_emis', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered date-report-col-desp' },
    { key: 'data_pag', name: 'Data Pag.', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered date-report-col-desp' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'fornecedor_nome', name: 'Fornecedor', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left big-report-col-fornecedor' },
    { key: 'ref_interna', name: 'Ref. Interna', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered ref-interna-report-col' },
    { key: 'valor', name: 'Valor', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right valor-report-col' },
  ];

  reportJustificacaoOrcListColPDF = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'first-report-col' },  // 'ASC', 'DESC'
    { key: 'rubrica', name: '', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'orcamentado', name: 'Orçamentado', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-right' },
    { key: 'lancado', name: 'Lançado', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-right' },
    { key: 'desvio', name: 'Desvio', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'liquidado', name: 'Liquidado', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'por_liquidar', name: 'Por liquidar', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
  ];

  // GLOBAL VARIABLES ----------------------------------------------------------
  transitionController = new TransitionController();
  tapTransitionController = new TransitionController();
  loading = false;
  loadingModal = false;
  searchable = true;
  submittingForm = false;
  orignalDoc = '0';
  clearEntry = { name: '-- limpar selecção --', value: '-1' };
  format = 'dd-MM-yyyy';

  locale = 'pt-PT';

  selCondominio = null;
  selTipoRelatorio = null;
  selExercicio: any = this.appConfig.exerciciosOpts[0].value;
  selTipoDoc = this.appConfig.tipoDocOpts[0].value;

  reportList: Array<any> = [];
  reportListFromAPI: Array<any> = [];

  total = {
    valor: 0,
    valorParcial: 0,
    valorNegativo: 0,
    valorPositivo: 0,
  }

  justOrcamentoTotal = {
    valorOrcamentado: 0,
    valorLancado: 0,
    valorDesvio: 0,
    valorLiquidado: 0,
    valorPorLiquidar: 0,
  }

  pdfReport = {
    title: null,
    reportType: null,
    startDate: null,
    endDate: null,
    now: new Date(),
  }
  isDetailed = false;
  targetReport = null;
  nextExercicio = null;
  
  saldosIniciais = {
    caixas: [],
    totalCaixas: null,
    bancos: [],
    totalBancos: null,
  }

  saldosFinais = {
    caixas: [],
    totalCaixas: null,
    bancos: [],
    totalBancos: null,
  }

  despesasOrdinarias = {
    rubricas: [],
    totalRubricas: null,
  }

  despesasExtraordinarias = {
    rubricas: [],
    totalRubricas: null,
  }

  receitasExtraordinarias = {
    rubricas: [],
    totalRubricas: null,
  }

  receitas = {
    exerAnterior: { gestaoCorrente: null, fundoReserva: null, seguro: null, penalizacao: null, total: null },
    exerActual: { 
      gestaoCorrente: null,
      fundoReserva: null,
      seguro: null,
      penalizacao: null,

      receitasExtras: null,

      creditosDisponiveis: null,
      creditosUtilizados: null,
      total: null,
    },
    pagAdiantados: {
      gestaoCorrente: null,
      fundoReserva: null,
      seguro: null,
      total: 0,
    }
  }

  mapaQuotasTotal = {
    valorLiquidado: null,
    valorPorLiquidar: null,
  }

  mapaQuotasSegTotal = {
    valorLiquidado: null,
    valorPorLiquidar: null,
  }

  resumoFinanceiro = {
    saldo_final_total: null,
    conta_corrente_total: null,
    divida_fornecedores_total: null,
    divida_fornecedores: []
  }

  exerciciosOpts = [];

  endDateDP = new Date();
  startDateDP = new Date(this.endDateDP.getFullYear(), 0, 1);
  // startDateDP = null;

  // FILTER CONTROL VARIABLES
  condominioSelectDisabled = false;
  exercicioSelectDisabled = false;
  startDateDPDisabled = false;
  endDateDPDisabled = false;
  tipoDocSelectDisabled = false;

  tipoRelatorioExercOpts = [];



  utilizadores = [{ name: "Todos", value: "all" }];
  availableUtilizadores = [{ name: "Todos", value: "all" }];
  selectedUtilizadores = ["all"];

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public route: ActivatedRoute,
              public router: Router,
              public userSession: UserSessionService,
              public api: ApiService,
              public cdRef:ChangeDetectorRef,
              public location: Location,
              public utils: UtilitiesService,
              public appState: AppStateService,
              public appConfig: AppConfigService,
              public message: MessageService) {

    this.tipoRelatorioExercOpts = this.appConfig.tipoRelatorioExercOpts;

    // GET GLOBAL STATE
    let globalState = this.appState.getGlobalState('global');
    if (globalState && globalState.hasOwnProperty('selCondominio')) {
      this.selCondominio = (globalState.selCondominio) ? { name: globalState.selCondominio.cod + ' - ' + globalState.selCondominio.nome, value: globalState.selCondominio, cod: globalState.selCondominio.cod, nome: globalState.selCondominio.nome } : null;

      this.getContasDetails();
    }

    let thisYear = (new Date()).getFullYear();
    let prevYear = thisYear;
    while (prevYear >= 2012) {
      this.exerciciosOpts.push({ value: prevYear.toString(), name: prevYear.toString() },);
      prevYear--;
    }
  }

  saveGlobalState() {
    if (this.selCondominio && this.selCondominio !== '-1') {
      this.appState.saveGlobalState('global', { 
        selCondominio: { id: this.selCondominio.id, cod: this.selCondominio.cod, nome: this.selCondominio.nome, exercicio: this.selCondominio.exercicio },
      });
    } else {
      this.appState.clearGlobalState();
    }
  }

  // SELECT LOOKUPS FUNCTIONS -------------------------------------------------
  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selCondominio); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
                } else {
                  return resolve([this.clearEntry]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
            } else {
              return resolve([this.clearEntry]);
            }
          });
        }
    });
  };
  // -------------------------------------------------------------------------

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngOnInit() { 
    this.animate();
    
    this.api.getUtilizadores().subscribe(res => {
      res.data.forEach(u => {
        this.availableUtilizadores.push({ name: u.first_name + " " + u.last_name, value: u.id });
      });
    });
  }

  ngAfterViewInit() {
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.fechoContasAlertConfig = new TemplateModalConfig<IContext, string, string>(this.fechoContasAlertRef);
    this.fechoContasAlertConfig.isClosable = false;
    this.fechoContasAlertConfig.closeResult = "closed";
    this.fechoContasAlertConfig.size = 'small';
    this.fechoContasAlertConfig.transition = 'fade';
    this.fechoContasAlertConfig.transitionDuration = 250;

    this.reconcBancariaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.reconcBancariaAlertRef);
    this.reconcBancariaAlertConfig.isClosable = true;
    this.reconcBancariaAlertConfig.closeResult = "closed";
    this.reconcBancariaAlertConfig.size = 'small';
    this.reconcBancariaAlertConfig.transition = 'fade';
    this.reconcBancariaAlertConfig.transitionDuration = 250;

    this.recibosDespesasAlertConfig = new TemplateModalConfig<IContext, string, string>(this.recibosDespesasAlertRef);
    this.recibosDespesasAlertConfig.isClosable = true;
    this.recibosDespesasAlertConfig.closeResult = "closed";
    this.recibosDespesasAlertConfig.size = 'small';
    this.recibosDespesasAlertConfig.transition = 'fade';
    this.recibosDespesasAlertConfig.transitionDuration = 250;

    this.assembleiasSelectModalConfig = new TemplateModalConfig<IContext, string, string>(this.assembleiasSelectRef);
    this.assembleiasSelectModalConfig.isClosable = false;
    this.assembleiasSelectModalConfig.closeResult = "closed";
    this.assembleiasSelectModalConfig.size = 'mini';
    this.assembleiasSelectModalConfig.transition = 'fade';
    this.assembleiasSelectModalConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    if (this.fechoContasModalRef) this.fechoContasModalRef.approve();
    if (this.ccSubs) this.ccSubs.unsubscribe();
    if (this.movSubs) this.movSubs.unsubscribe();
    if (this.orcSubs) this.orcSubs.unsubscribe();
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  setFilterState() {
    switch (this.selTipoRelatorio) {
      case '0':  // BALANCETE
        this.condominioSelectDisabled = false;
        this.exercicioSelectDisabled = false;

        this.startDateDPDisabled = false;
        this.endDateDPDisabled = false;

        this.selTipoDoc = this.appConfig.tipoDocOpts[0].value;
        this.tipoDocSelectDisabled = true;
        break;
      case '1':  // LISTAGEM DESPESAS
        this.condominioSelectDisabled = false;
        this.exercicioSelectDisabled = false;

        this.startDateDPDisabled = false;
        this.endDateDPDisabled = false;

        this.tipoDocSelectDisabled = false;
        break;
      case '2':  // JUSTIFICACAO ORCAMENTO
        this.condominioSelectDisabled = false;
        this.exercicioSelectDisabled = false;

        this.execicioChanged();
        this.startDateDPDisabled = true;
        this.endDateDPDisabled = true;

        this.selTipoDoc = this.appConfig.tipoDocOpts[0].value;
        this.tipoDocSelectDisabled = true;
        break;
      case '3':  // RESUMO FINANCEIRO
        this.condominioSelectDisabled = false;
        this.exercicioSelectDisabled = false;

        this.startDateDPDisabled = false;
        this.endDateDPDisabled = false;

        this.selTipoDoc = this.appConfig.tipoDocOpts[0].value;
        this.tipoDocSelectDisabled = true;
        break;
      case '4':  // MAPA QUOTAS
        this.condominioSelectDisabled = false;
        this.exercicioSelectDisabled = false;

        // this.execicioChanged();
        this.startDateDPDisabled = true;
        this.endDateDPDisabled = false;

        this.selTipoDoc = this.appConfig.tipoDocOpts[0].value;
        this.tipoDocSelectDisabled = true;
        break;
      case '5':  // CAIXAS
        this.condominioSelectDisabled = true;
        this.exercicioSelectDisabled = true;

        this.startDateDP = null;
        this.startDateDPDisabled = true;
        this.endDateDPDisabled = false;

        this.tipoDocSelectDisabled = false;
        break;
      case '6':  // FECHO DE CONTAS
        this.condominioSelectDisabled = false;
        this.exercicioSelectDisabled = false;

        let now = new Date();
        this.selExercicio = this.appConfig.exerciciosOpts.find(el => el.value === (Number(now.getFullYear() - 1)).toString()).value;
        this.execicioChanged();

        this.startDateDPDisabled = true;
        this.endDateDPDisabled = true;

        this.selTipoDoc = this.appConfig.tipoDocOpts[0].value;
        this.tipoDocSelectDisabled = true;
        break;
      case '9':
        this.condominioSelectDisabled = true;
        this.exercicioSelectDisabled = true;

        this.startDateDP = null;
        this.startDateDPDisabled = true;
        this.endDateDPDisabled = false;

        this.tipoDocSelectDisabled = false;
        break;
      case '10':
        this.condominioSelectDisabled = true;
        this.exercicioSelectDisabled = true;

        this.startDateDP = null;
        this.startDateDPDisabled = true;
        this.endDateDPDisabled = false;

        this.selTipoDoc = this.appConfig.tipoDocOpts[0].value;
        this.tipoDocSelectDisabled = true;
        break;
      case '11':
        this.condominioSelectDisabled = true;
        this.exercicioSelectDisabled = true;

        this.startDateDPDisabled = false;
        this.endDateDPDisabled = false;


        this.tipoDocSelectDisabled = false;
        break;
    }
  }

  bancListCol = [
    { key: 'banco', name: 'Banco', type: 'text', sort: null, searchable: false, center: false, class: 'col-align-left' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, center: false, class: 'col-align-right' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: false, class: 'col-centered' },
  ];
  bancList: Array<any> = [];
  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'banc':
        (ev.target.checked) ? this.bancList.map(el => el.checked = true ) : this.bancList.map(el => el.checked = false );
        break;
      case 'fecho-contas':
        (ev.target.checked) ? this.fechoContasArr.map(el => el.checked = (!el.disabled)) : this.fechoContasArr.map(el => el.checked = false);
        break;
    }
  }

  IBANCondominio = null;
  getContaPrincipal() {
    return new Promise(resolve => {
      let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : null;
      if (!cod) {
        this.IBANCondominio = null;
        resolve(false);
        return;
      }

      this.api.getContaPrincipal(cod).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.data && res.data.hasOwnProperty('banco') && res.data.banco !== 'CAIXA' && res.data.nib) {
            if (res.data.nib.indexOf('PT50') !== -1) {
              this.IBANCondominio = res.data.nib;
            } else {
              this.IBANCondominio = 'PT50' + res.data.nib;
            }
          }
        }
        resolve(true);
      }, err => {
        this.IBANCondominio = null;
        resolve(false); 
      });
    });
  }

  getContasDetails () {
    let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : null;
    if (!cod)  {
      this.bancList = [];
      return;
    }

    this.api.getCondContasDetails(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.bancList = res.data.map(el => {
          el['checked'] = true;
          el.saldo = Number(el.saldo);

          return el;
        });

      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  condominioSelected() {
    if (this.selCondominio && this.selCondominio === '-1') { this.selCondominio = null; }
  }

  getReport() {
      if ((!this.selCondominio && (this.selTipoRelatorio !== '5' && this.selTipoRelatorio !== '9' && this.selTipoRelatorio !== '10' && this.selTipoRelatorio !== '11')) || !this.selTipoRelatorio) {
        this.submittingForm = true; setTimeout(() => { this.submittingForm = false; }, 4000);
        this.toastr.error('Por favor, verifique todos os campos assinalados.', 'Formulário Incompleto', { timeOut: 4000 }); 
        return;
      }
  
      switch (this.selTipoRelatorio) {
        case '0':  // BALANCETE
          this.targetReport = 'balancete';
          this.getBalancete();
          break;
        case '1':  // LISTAGEM DESPESAS
          this.targetReport = 'despesas';
          this.getListagemDespesas();
          break;
        case '2':  // JUSTIFICACAO ORCAMENTO
          this.targetReport = 'justificacao-orcamento';
          this.getJustificacaoOrcamento();
          break;
        case '3':  // RESUMO FINANCEIRO
          this.targetReport = 'resumo-financeiro';
          this.getResumoFinanceiro();
          break;
        case '4':  // MAPA QUOTAS
          this.targetReport = 'mapa-quotas';
          this.getMapaQuotas();
          break;
        case '5':  // CAIXAS
          this.targetReport = 'caixas';
          this.getCaixas();
          break;
        case '6':  // FECHO DE CONTAS
          this.targetReport = 'fecho-contas';
          this.getFechoContas();
          break;
        case '7':  // RELATORIO DE ASSEMBLEIA
          this.targetReport = 'relatorio-assembleia';
          this.getRelatorioAssembleia();
          break;
        case '8':  // LISTA DE PRESENÇAS
          this.targetReport = 'lista-presencas-assembleia';
          this.getListaPresencasAssembleia();
          break;
        case '9':  // RECONCILIAÇÃO BANCÁRIA
          this.targetReport = 'reconciliacao-bancaria';
          this.openReconcBancariaModal();
          break;
        case '10':  // RECIBOS ANULADOS
          this.targetReport = 'recibos-anulados';
          this.getRecibosAnulados();
          break;
        case '11':  // Listagem Recibos Despesas
          this.targetReport = 'recibos-despesas';
          this.openRecibosDespesasModal();
          break;
    }
  }

  exportDoc() {
    let filename = this.pdfReport.title.replace(/ /g, '_') + '_'
      + formatDate(this.pdfReport.startDate, this.format, this.locale) + '_'
      + formatDate(this.pdfReport.endDate, this.format, this.locale) + '_'
      + this.pdfReport['reportType'].replace(/ /g, '_');
    filename = filename.replace(/,/g, '');

    if (!this.tipoDocSelectDisabled && this.selTipoDoc === 'csv') {
      let excelData = [];
      let worksheet: XLSX.WorkSheet = null;

      switch (this.selTipoRelatorio) {
        case '1':  // LISTAGEM DESPESAS
          excelData = [{ rubrica_nome: 'Rubrica', n_doc: 'Lanç.', data_emis: 'Data', data_pag: 'Data Pag.', descricao: 'Descrição', fornecedor_nome: 'Fornecedor', ref_interna: 'Ref. Interna', valor: 'Valor [€]' }];

          this.total.valor = 0;
          this.reportListFromAPI.forEach(desp => {
            excelData.push({
              rubrica_nome: desp.rubrica_nome,
              n_doc: desp.n_doc,
              data_emis: desp.data_emis,
              data_pag: desp.data_pag,
              descricao: desp.descricao,
              fornecedor_nome: desp.fornecedor_nome,
              ref_interna: desp.ref_interna,
              valor: Number(desp.valor).toString().replace('.', ','),
            });

            this.total.valor += Number(desp.valor);
          });
          excelData.push({ rubrica_nome: null, n_doc: null, data_emis: null, data_pag: null, descricao: null, fornecedor_nome: null, ref_interna: null, valor: null });
          excelData.push({ rubrica_nome: 'Total', n_doc: null, data_emis: null, data_pag: null, descricao: null, fornecedor_nome: null, ref_interna: null, valor: (Math.round(this.total.valor * 100) / 100).toString().replace('.', ',') });

          worksheet = XLSX.utils.json_to_sheet(excelData, { header:['rubrica_nome', 'n_doc', 'data_emis', 'data_pag', 'descricao', 'fornecedor_nome', 'ref_interna', 'valor'], skipHeader: true });
          worksheet['!cols'] = this.utils.fitToColumn(excelData);
          break;
        case '5':  // CAIXAS
          // ADD HEADER
          excelData = [{condominio: 'Condomínio', saldo: 'Saldo [€]' }];
          this.reportList.forEach(el => {
            excelData.push({
              condominio: el.condominio,
              saldo: el.saldo.toString().replace('.', ','),
            });
          });
          excelData.push({ condominio: null, saldo: null });
          excelData.push({ condominio: 'Total positivo', saldo: (Math.round(this.total.valorPositivo * 100) / 100).toString().replace('.', ',') });
          excelData.push({ condominio: 'Total negativo', saldo: (Math.round(this.total.valorNegativo * 100) / 100).toString().replace('.', ',') });
          excelData.push({ condominio: 'Total', saldo: (Math.round(this.total.valor * 100) / 100).toString().replace('.', ',') });

          worksheet = XLSX.utils.json_to_sheet(excelData, { header:['condominio', 'saldo'], skipHeader: true });
          worksheet['!cols'] = this.utils.fitToColumn(excelData);
          break;
      }

      let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      let data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});

      FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
    } else {
      setTimeout(() => {
        this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
        this.pdfController.forceProxy = true;
        this.pdfController.proxyTarget = '_blank';
        this.pdfController.saveAs(filename + '.pdf');
      }, 1);
    }
  }

  getCaixas(endDateIn=null) {
    return new Promise(resolve => {
      let endDate = (endDateIn) ? endDateIn : this.endDateDP;

      // SET REPORT HEADER DATA
      this.pdfReport['title'] = 'Relatório de Caixas';
      this.pdfReport['reportType'] = '';
      this.pdfReport['endDate'] = endDate;
      this.pdfReport['now'] = new Date();
      this.isDetailed = true;
  
      this.loading = true;
  
      let now = new Date();
      now.setHours(0,0,0,0);
      endDate.setHours(0,0,0,0);
  
      let isOptimized = (endDate.getTime() >= now.getTime());
  
      this.api.getRelExercCaixas(endDate, isOptimized).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.reportList = res.data.caixas;
  
          // COMPUTE TOTAL
          this.total.valor = 0;
          this.total.valorPositivo = 0;
          this.total.valorNegativo = 0;
          this.reportList.forEach(el => {
            el['condominio'] = el.cod_condominio + ' - ' + el.nome;
            el.saldo = Number(el.saldo);
  
            this.total.valorPositivo += (el.saldo > 0) ? el.saldo : 0;
            this.total.valorNegativo += (el.saldo < 0) ? el.saldo : 0;
  
            this.total.valor += el.saldo;
          });
  
          this.loading = false;
  
          if (this) setTimeout(() => { this.exportDoc(); }, 1);
  
          // REGISTO ACTIVIDADES API CALL
          let titulo = 'Relatório de Caixas Visualizado';
          this.api.saveRegistoActividade('', null, null, titulo, null).subscribe(res => {}, err => { });
        } else {
          this.loading = false;
          this.utils.apiErrorMsg(res);
        }
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      });
    });
  }

  getBalancete(startDateIn=null, endDateIn=null) {
    return new Promise(resolve => {
      let startDate = (startDateIn) ? startDateIn : this.startDateDP;
      let endDate = (endDateIn) ? endDateIn : this.endDateDP;
  
      let exercicio = null;
      if (this.selExercicio) {
        exercicio = this.selExercicio;
      } else {
        exercicio = endDate.getFullYear().toString();
      }
  
      // SET REPORT HEADER DATA
      this.pdfReport['title'] = this.selCondominio.nome;
      this.pdfReport['reportType'] = 'Balancete' + ' ' + exercicio;
      this.pdfReport['startDate'] = startDate;
      this.pdfReport['endDate'] = endDate;
      this.pdfReport['now'] = new Date();
      this.nextExercicio = Number(exercicio) + 1;
      this.isDetailed = true;
  
      if (!startDateIn && !endDateIn) {
        this.loading = true;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      }

      forkJoin([
        this.api.getRelExercBalanceteV2(this.selCondominio.cod, 'despesas_extra', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(this.selCondominio.cod, 'despesas_ordinarias', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(this.selCondominio.cod, 'exerc_actual', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(this.selCondominio.cod, 'exerc_anterior', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(this.selCondominio.cod, 'saldos_finais', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(this.selCondominio.cod, 'saldos_iniciais', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(this.selCondominio.cod, 'receitas_extra', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(this.selCondominio.cod, 'pag_adiantados', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(this.selCondominio.cod, 'mov_despesas_extra', startDate, endDate, exercicio),
      ]).subscribe(res => {

        // START - UPDATE ON DESPESAS EXTRA COMPUTATIONS - TESTING (REMOVE TO FALLBACK TO PREV VERSION - AND BACKEND!)
        let auxMov = null;
        res[0].data.forEach(el => {
          auxMov = res[8].data.filter(it => (it.descricao.indexOf('D ' + el.n_despesa + ' ') !== -1 || it.descricao.indexOf('D ' + el.n_despesa + '-') !== -1));
          el.valor_liquidado = (auxMov) ? auxMov.map(elem => -1 * Number(elem.valor)).reduce((a, b) => a + b, 0) : 0;
        });
        let rubricasAux = [...new Set(res[0].data.map(el => el.cod_rubrica))];
        let auxDespesasExtra = [];
        let aux = null;

        rubricasAux.forEach(el => {
          aux = res[0].data.filter(it => it.cod_rubrica === el);
          if (aux) {
            auxDespesasExtra.push({
              valor_total: aux.map(it => it.valor_liquidado).reduce((a, b) => a + b, 0),
              valor_liquidado: aux[0].valor_liquidado,
              descricao: aux[0].descricao,
              cod_rubrica: aux[0].cod_rubrica,
              rubrica: aux[0].rubrica,
              dt_desp: aux[0].dt_desp,
              dt_pag: aux[0].dt_pag,
            });
          }
        });
        res[0].data = auxDespesasExtra.filter(el => el.valor_liquidado >= 0);
        // END - UPDATE ON DESPESAS EXTRA COMPUTATIONS - TESTING

        if (res[0].hasOwnProperty('success') && res[0].success) {
          let data = {
            despesas_extra: res[0].data,
            despesas_ordinarias: res[1].data,
            exerc_actual: res[2].data,
            exerc_anterior: res[3].data,
            saldos_finais: res[4].data,
            saldos_iniciais: res[5].data,
            receitas_extra: res[6].data.filter(it => this.utils.getDate(it.dt_pag).getTime() <= endDate.getTime()),
            pag_adiantados: res[7].data,
            movimentos: res[8].data,
          }
  
          let exerc_anterior_aux = [
            { valor_total: 0, tipo_proc: 'F' },
            { valor_total: 0, tipo_proc: 'O' },
            { valor_total: 0, tipo_proc: 'S' },
            { valor_total: 0, tipo_proc: 'P' },
          ];
          data.exerc_anterior.forEach(el => {
            if (el.tipo_proc === 'F') {
              exerc_anterior_aux[0].valor_total += Number(el.valor_total);
            }
  
            if (el.tipo_proc === 'O' || el.tipo_proc === 'E') {
              exerc_anterior_aux[1].valor_total += Number(el.valor_total);
            }
  
            if (el.tipo_proc === 'S') {
              exerc_anterior_aux[2].valor_total += Number(el.valor_total);
            }

            if (el.tipo_proc === 'P') {
              exerc_anterior_aux[3].valor_total += Number(el.valor_total);
            }
          });
          data.exerc_anterior = exerc_anterior_aux;
  
          let auxDtInicio = (res[5].data_inicio) ? this.utils.getDate(res[5].data_inicio) : null;
          if (auxDtInicio && auxDtInicio.getTime() > this.pdfReport['startDate'].getTime()) this.pdfReport['startDate'] = auxDtInicio;
  
          this.generateBalanceteReportObj(data, res[5].fromThisYear);
  
          this.loading = false;
  
          // REGISTO ACTIVIDADES API CALL
          if (!startDateIn && !endDateIn) {
            let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
            let nome = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;
            let titulo = 'Balancete Visualizado';
            let descricao = 'Condomínio: ' + cod + ' - ' + nome;
            this.api.saveRegistoActividade(cod, null, null, titulo, descricao).subscribe(res => {}, err => { });
    
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          }

          resolve(true);
        } else {
          this.loading = false;
          this.utils.apiErrorMsg(res[0]);

          if (!startDateIn && !endDateIn) { this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' }); }
          resolve(false);
        }
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
  
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(false)
      });
    });
  }

  generateBalanceteReportObj(data, siFromThisYear) {
    // SALDOS INICIAS
    if (data.saldos_iniciais) {
      this.saldosIniciais.caixas = data.saldos_iniciais.filter(el => (el.banco.trim() === 'CAIXA' && Number(el.valor_total) !== 0));
      this.saldosIniciais.bancos = data.saldos_iniciais.filter(el => (el.banco.trim() !== 'CAIXA' && Number(el.valor_total) !== 0));

      // COMPUTE TOTALS
      this.saldosIniciais.totalCaixas = 0;
      this.saldosIniciais.caixas.forEach(el => {
        this.saldosIniciais.totalCaixas += Number(el.valor_total);
      });
      this.saldosIniciais.totalCaixas = Math.round(Number(this.saldosIniciais.totalCaixas) * 100) / 100;

      this.saldosIniciais.totalBancos = 0;
      this.saldosIniciais.bancos.forEach(el => {
        this.saldosIniciais.totalBancos += Number(el.valor_total);
      });
      this.saldosIniciais.totalBancos = Math.round(Number(this.saldosIniciais.totalBancos) * 100) / 100;
    }

    // SALDOS FINAIS
    if (data.saldos_finais) {
      this.saldosFinais.caixas = data.saldos_finais.filter(el => (el.banco.trim() === 'CAIXA' && Number(el.valor_total) !== 0));
      this.saldosFinais.bancos = data.saldos_finais.filter(el => (el.banco.trim() !== 'CAIXA' && Number(el.valor_total) !== 0));

      // COMPUTE TOTALS
      this.saldosFinais.totalCaixas = 0;
      this.saldosFinais.caixas.forEach(el => {
        this.saldosFinais.totalCaixas += Number(el.valor_total);
      });
      this.saldosFinais.totalCaixas = Math.round(Number(this.saldosFinais.totalCaixas) * 100) / 100;

      this.saldosFinais.totalBancos = 0;
      this.saldosFinais.bancos.forEach(el => {
        this.saldosFinais.totalBancos += Number(el.valor_total);
      });
      this.saldosFinais.totalBancos = Math.round(Number(this.saldosFinais.totalBancos) * 100) / 100;
    }

    // DESPESAS ORDINARIAS
    if (data.despesas_ordinarias) {
      this.despesasOrdinarias.rubricas = data.despesas_ordinarias;


// // START - UPDATE ON DESPESAS EXTRA COMPUTATIONS
// let auxMov = null;
// this.despesasOrdinarias.rubricas.forEach(el => {
//   auxMov = data.movimentos.filter(it => it.nid_rubrica === el.cod_rubrica);
//   el.valor_total = (auxMov) ? auxMov.map(elem => -1 * Number(elem.valor)).reduce((a, b) => a + b, 0) : 0;
// });
// // END - UPDATE ON DESPESAS EXTRA COMPUTATIONS


      // COMPUTE TOTALS
      this.despesasOrdinarias.totalRubricas = 0;
      this.despesasOrdinarias.rubricas.forEach(el => {
        this.despesasOrdinarias.totalRubricas += Number(el.valor_total);
      });

      this.despesasOrdinarias.totalRubricas = Math.round(Number(this.despesasOrdinarias.totalRubricas) * 100) / 100;
    }

    // DESPESAS EXTRAORDINARIAS
    if (data.despesas_extra) {
      this.despesasExtraordinarias.rubricas = data.despesas_extra;

      // COMPUTE TOTALS
      this.despesasExtraordinarias.totalRubricas = 0;
      this.despesasExtraordinarias.rubricas.forEach(el => {
        this.despesasExtraordinarias.totalRubricas += Number(el.valor_total);
      });
      this.despesasExtraordinarias.totalRubricas = Math.round(Number(this.despesasExtraordinarias.totalRubricas) * 100) / 100;

      this.despesasExtraordinarias.rubricas = this.despesasExtraordinarias.rubricas.filter(el => el.valor_total > 0);
    }

    // RECEITAS - EXERCICIO ANTERIOR
    if (data.exerc_anterior && data.exerc_anterior.length > 0) {
      this.receitas.exerAnterior.gestaoCorrente = Number(data.exerc_anterior.find(el => (el.tipo_proc === 'O')).valor_total);
      this.receitas.exerAnterior.fundoReserva = Number(data.exerc_anterior.find(el => (el.tipo_proc === 'F')).valor_total);
      this.receitas.exerAnterior.seguro = (data.exerc_anterior.find(el => (el.tipo_proc === 'S'))) ? Number(data.exerc_anterior.find(el => (el.tipo_proc === 'S')).valor_total) : null;
      this.receitas.exerAnterior.penalizacao = (data.exerc_anterior.find(el => (el.tipo_proc === 'P'))) ? Number(data.exerc_anterior.find(el => (el.tipo_proc === 'P')).valor_total) : null;
      this.receitas.exerAnterior.total = 0;
      Object.keys(this.receitas.exerAnterior).forEach(key => { if (key !== 'total') this.receitas.exerAnterior.total += this.receitas.exerAnterior[key]; });
    } else {
      this.receitas.exerAnterior.gestaoCorrente = 0;
      this.receitas.exerAnterior.fundoReserva = 0;
      this.receitas.exerAnterior.seguro = 0;
      this.receitas.exerAnterior.penalizacao = 0;
      this.receitas.exerAnterior.total = 0;
    }

    // RECEITAS - EXERCICIO ACTUAL
    if (data.exerc_actual) {
      this.receitas.exerActual.gestaoCorrente = Number(data.exerc_actual.gc[0].valor_total);
      this.receitas.exerActual.fundoReserva = Number(data.exerc_actual.fr[0].valor_total);
      this.receitas.exerActual.seguro = Number(data.exerc_actual.seg[0].valor_total);
      this.receitas.exerActual.penalizacao = Number(data.exerc_actual.penalizacao[0].valor_total);
      this.receitas.exerActual.creditosDisponiveis = Number(data.exerc_actual.cred_disponivel[0].valor_total);
      this.receitas.exerActual.creditosUtilizados = -1 * Number(data.exerc_actual.cred_utilizado[0].valor_total);
      this.receitas.exerActual.total = 0;
      Object.keys(this.receitas.exerActual).forEach(key => { if (key !== 'total' && key !== 'receitasExtras') this.receitas.exerActual.total += this.receitas.exerActual[key]; });

      // RECEITAS EXTRAS / PENALIZAÇÔES / ETC
      this.receitas.exerActual.receitasExtras = [];
      data.exerc_actual.extra.forEach(el => {
        this.receitas.exerActual.receitasExtras.push({ descricao: el.descricao, valor: Number(el.valor_total) });

        this.receitas.exerActual.total += Number(el.valor_total);
      });
    }

    // PAGAMENTOS POSTERIORES
    if (data.pag_adiantados) {
      this.receitas.pagAdiantados.gestaoCorrente = Number(data.pag_adiantados.gc[0].valor_total);
      this.receitas.pagAdiantados.fundoReserva = Number(data.pag_adiantados.fr[0].valor_total);
      this.receitas.pagAdiantados.seguro = Number(data.pag_adiantados.seg[0].valor_total);
      this.receitas.pagAdiantados.total = this.receitas.pagAdiantados.gestaoCorrente + this.receitas.pagAdiantados.fundoReserva + this.receitas.pagAdiantados.seguro;
    }

    // RECEITAS EXTRAORDINARIAS
    if (data.receitas_extra) {
      this.receitasExtraordinarias.rubricas = data.receitas_extra;

      // COMPUTE TOTALS
      this.receitasExtraordinarias.totalRubricas = 0;
      this.receitasExtraordinarias.rubricas.forEach(el => {
        this.receitasExtraordinarias.totalRubricas += Number(el.valor_total);
      });
      this.receitasExtraordinarias.totalRubricas = Math.round(Number(this.receitasExtraordinarias.totalRubricas) * 100) / 100;
    }

    // CHECK BALANCETE TOTALS AND LAUNCH ALERT IF NEEDED
    let totalSaldoFinal = Number(this.saldosFinais.totalCaixas) + Number(this.saldosFinais.totalBancos);
    let totalAux = 0;
    if (this.receitasExtraordinarias.rubricas.length === 0) {
      totalAux = (this.saldosIniciais.totalCaixas + this.saldosIniciais.totalBancos) + ((this.receitas.exerAnterior.total + this.receitas.exerActual.total + this.receitas.pagAdiantados.total) - (this.despesasOrdinarias.totalRubricas + this.despesasExtraordinarias.totalRubricas));
    }
    if (this.receitasExtraordinarias.rubricas.length > 0) {
       totalAux = (this.saldosIniciais.totalCaixas + this.saldosIniciais.totalBancos) + ((this.receitas.exerAnterior.total + this.receitas.exerActual.total + this.receitas.pagAdiantados.total + this.receitasExtraordinarias.totalRubricas) - (this.despesasOrdinarias.totalRubricas + this.despesasExtraordinarias.totalRubricas));
    }

    if ((Math.round(totalSaldoFinal * 100) / 100) !== (Math.round(totalAux * 100) / 100)) this.toastr.error('Saldo a transitar diferente do saldo total em CAIXA e BANCO.', 'Alerta', { timeOut: 0, extendedTimeOut: 0, tapToDismiss: true })

    if (this.selTipoRelatorio !== '6') setTimeout(() => { this.exportDoc(); }, 1);
  }

  getListagemDespesas(startDateIn=null, endDateIn=null) {
    return new Promise(resolve => {
      let startDate = (startDateIn) ? startDateIn : this.startDateDP;
      let endDate = (endDateIn) ? endDateIn : this.endDateDP;
  
      let exercicio = null;
      if (this.selExercicio) {
        exercicio = this.selExercicio;
      } else {
        exercicio = endDate.getFullYear().toString();
      }
  
      // SET REPORT HEADER DATA
      this.pdfReport['title'] = this.selCondominio.nome;
      this.pdfReport['reportType'] = 'Listagem de Despesas';
      this.pdfReport['startDate'] = startDate;
      this.pdfReport['endDate'] = endDate;
      this.pdfReport['now'] = new Date();
      this.isDetailed = true;
  
      if (!startDateIn && !endDateIn) {
        this.loading = true;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      }
  
      this.api.getRelExercListagemDespesas(this.selCondominio.cod, startDate, endDate, exercicio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.reportListFromAPI = res.data;
  
          this.generateDespesasReportListObj(res.data);
  
          this.loading = false;
  
          if (!startDateIn && !endDateIn) {
            // REGISTO ACTIVIDADES API CALL
            let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
            let nome = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;
            let titulo = 'Relatório de Despesas Visualizado';
            let descricao = 'Condomínio: ' + cod + ' - ' + nome;
            this.api.saveRegistoActividade(cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          }

          resolve(true);
        } else {
          this.loading = false;
          this.utils.apiErrorMsg(res);

          if (!startDateIn && !endDateIn) this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(false);
        }
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
  
        if (!startDateIn && !endDateIn) this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(false);
      });
    });
  }

  despPagParcialListColPDF = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'first-report-col' },
    { key: 'n_doc', name: 'Lanç.', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered small-report-col' },
    { key: 'data_emis', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered date-report-col' },
    { key: 'data_pag', name: 'Data Pag.', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered date-report-col' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left big-report-col-descricao' },
    { key: 'fornecedor_nome', name: 'Fornecedor', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left big-report-col-fornecedor' },
    { key: 'valor', name: 'Valor por liquidar', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right valor-report-col' },
  ];
  despPagParcialList = [];
  generateDespesasReportListObj(data) {
    this.despPagParcialList = [];
    this.reportList = [];

    this.total['valor'] = 0;
    this.total['valorParcial'] = 0;

    let valor_total_rubrica = 0;
    let valor_total_a_data_rubrica = 0;

    let prevRubrica = null;

    let dtPag = null;

    data.forEach((el, i) => {

      let data_pag = (el['data_pag']) ? this.utils.getDate(el['data_pag']) : null;
      let valor_liquidado = (el['valor_liquidado']) ? Math.round(Number(el['valor_liquidado']) * 100) / 100 : null;

      if (el.movimentos && Array.isArray(el.movimentos)) {
        data_pag = el.movimentos.reduce((dt_pag, mov) => {
          let dt_mov = this.utils.getDate(mov.dt_mov)
          if (!dt_pag || this.utils.compareDayDates(dt_pag, dt_mov) < 0) {
            dt_pag = dt_mov;
          }
          return dt_pag;
        }, null);
      }

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        n_doc: (el['n_doc']) ? el['n_doc'] : null,
        data_emis: (el['data_emis']) ? this.utils.getDate(el['data_emis']) : null,
        data_pag: data_pag,
        descricao: (el['descricao']) ? el['descricao'] : null,
        fornecedor_nome: (el['fornecedor_nome']) ? el['fornecedor_nome'] : null,
        ref_interna: (el['ref_interna']) ? el['ref_interna'] : null,
        valor: (el['valor']) ? Math.round(Number(el['valor']) * 100) / 100 : null,
        valor_liquidado: valor_liquidado,
        parcialmente_pago: false,
      }

      if (aux['valor_liquidado'] > 0 && aux['valor_liquidado'] < aux['valor']) {
        aux['parcialmente_pago'] = true;

        // this.total['valorParcial'] += aux['valor_liquidado'];
        this.total['valorParcial'] += (aux['valor'] - aux['valor_liquidado']);
      }

      // TABLE ROW TOTAL AND SEPARATOR
      if (prevRubrica !== el['rubrica_cod']) {
        if (i > 0) {
          this.reportList.push({ label: 'Subtotal', valor: valor_total_rubrica, separator: false, separatorCond: false, total: true });
          this.reportList.push({ label: 'Subtotal pago até à data', valor: valor_total_a_data_rubrica, separator: false, separatorCond: false, total: true, subTotal: true });
        }

        // RUBRICA SEPARATOR
        this.reportList.push({ label: `${el['rubrica_nome']}`, separator: true, separatorCond: false, total: false });
        valor_total_rubrica = 0;
        valor_total_a_data_rubrica = 0;
      }
      prevRubrica = el['rubrica_cod'];
      // valor_total_rubrica += aux['valor'];
      // if (el['data_pag']) valor_total_a_data_rubrica += aux['valor'];

      valor_total_rubrica += aux['valor'];
      dtPag = this.utils.getDate(el['data_pag']);

      // if (el['data_pag'] && dtPag.getTime() <= this.pdfReport['endDate'].getTime()) valor_total_a_data_rubrica += aux['valor_liquidado'];
      if (el.movimentos && Array.isArray(el.movimentos)) {
        el.movimentos.filter(mov => this.utils.compareDayDates(this.utils.getDate(mov.dt_mov), this.pdfReport['endDate']) <= 0).forEach(mov => {
          valor_total_a_data_rubrica += -1 * parseFloat(mov.valor);
        });
        valor_total_a_data_rubrica = this.utils.cleanDecimalDigits(valor_total_a_data_rubrica);
      }

      if (prevRubrica && prevRubrica !== el['rubrica_cod']) {
        // ENTITY SEPARATOR
        this.reportList.push({ label: 'Subtotal', valor: valor_total_rubrica, separator: false, separatorCond: false, total: true });
        this.reportList.push({ label: 'Subtotal pago até à data', valor: valor_total_a_data_rubrica, separator: false, separatorCond: false, total: true, subTotal: true });
        
        this.reportList.push({ label: `${el['rubrica_nome']}`, separator: true, separatorCond: true, total: false });
        valor_total_rubrica = 0;
        valor_total_a_data_rubrica = 0;
      }

      this.reportList.push(aux);

      this.total['valor'] += aux.valor;
    });
    if (this.reportList.length > 0) {
      this.reportList.push({ label: 'Subtotal', valor: valor_total_rubrica, separator: false, separatorCond: false, total: true });
      this.reportList.push({ label: 'Subtotal pago até à data', valor: valor_total_a_data_rubrica, separator: false, separatorCond: false, total: true, subTotal: true });
    }

    this.despPagParcialList = this.reportList.filter(el => (el.hasOwnProperty('parcialmente_pago') && el.parcialmente_pago));

    if (this.selTipoRelatorio !== '6') setTimeout(() => { this.exportDoc(); }, 1);
  }

  getJustificacaoOrcamento() {
    let startDate = this.startDateDP;
    let endDate = this.endDateDP;

    let exercicio = null;
    if (this.selExercicio) {
      exercicio = this.selExercicio;
    } else {
      exercicio = endDate.getFullYear().toString();
    }

    // SET REPORT HEADER DATA
    this.pdfReport['title'] = this.selCondominio.nome;
    this.pdfReport['reportType'] = 'Justificação do Orçamento de ' + exercicio;
    this.pdfReport['startDate'] = startDate;
    this.pdfReport['endDate'] = endDate;
    this.pdfReport['now'] = new Date();
    this.isDetailed = true;

    this.loading = true;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.api.getRelExercJustificacaoOrcV2(this.selCondominio.cod, startDate, endDate, exercicio).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.generateJustificacaoOrcReportListObj(res.data);

        this.loading = false;

        // REGISTO ACTIVIDADES API CALL
        let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
        let nome = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;
        let titulo = 'Relatório de Justificação de Orçamento Visualizado';
        let descricao = 'Condomínio: ' + cod + ' - ' + nome;
        this.api.saveRegistoActividade(cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

      } else {
        this.loading = false;
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.loading = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  reportListReceita = [];
  totalDespesa = null;
  totalReceita = null;
  generateJustificacaoOrcReportListObj(data) {
    if (!data) {
      this.toastr.warning('Não é possível gerar o relatório pretendido. O condomínio selecionado não tem orçamento disponível.', 'Alerta', { timeOut: 4000 });
      return;
    }

    // HANDLE API OBJECT
    let lancadoAux = null;
    let liquidadoAux = null;
    data.orcamentado.forEach(el => {
      lancadoAux = data.lancado.find(it => (it.rub_cod === el.rub_cod));
      liquidadoAux = data.liquidado.find(it => (it.rub_cod === el.rub_cod));

      el['valor_total_lancado'] = (lancadoAux) ? Number(lancadoAux.valor_total) : 0;
      el['valor_total_liquidado'] = (liquidadoAux) ? Number(liquidadoAux.valor_total) : 0;
    });

    this.reportList = [];
    this.reportListReceita = [];

    this.total['valor'] = 0;

    this.justOrcamentoTotal['valorOrcamentado'] = 0;
    this.justOrcamentoTotal['valorLancado'] = 0;
    this.justOrcamentoTotal['valorDesvio'] = 0;
    this.justOrcamentoTotal['valorLiquidado'] = 0;
    this.justOrcamentoTotal['valorPorLiquidar'] = 0;

    let desp_valor_total_orcamentado = 0;
    let desp_valor_total_lancado = 0;
    let desp_valor_total_desvio = 0;
    let desp_valor_total_liquidado = 0;
    let desp_valor_total_por_liquidar = 0;

    let rec_valor_total_orcamentado = 0;
    let rec_valor_total_lancado = 0;
    let rec_valor_total_desvio = 0;
    let rec_valor_total_liquidado = 0;
    let rec_valor_total_por_liquidar = 0;

    let prevRubrica = null;

    data.orcamentado.forEach((el, i) => {

      let aux = {
        checked: false,
        separator: false,
        total: false,
        rubrica: el.rub_nome,
        orcamentado: Number(el.valor_total),
        lancado: Number(el.valor_total_lancado),
        liquidado: Number(el.valor_total_liquidado),
        desvio: null,
        porLiquidar: null,
      }
      aux.desvio = aux.orcamentado - aux.lancado;
      aux.porLiquidar = aux.lancado - aux.liquidado;

      prevRubrica = el['rub_cod'];
      // valor_total_orcamentado += aux['orcamentado'];
      // valor_total_lancado += aux['lancado'];
      // valor_total_desvio += aux['desvio'];
      // valor_total_liquidado += aux['liquidado'];
      // valor_total_por_liquidar += aux['porLiquidar'];

      if (aux['orcamentado'] > 0) {
        this.reportList.push(aux);

        desp_valor_total_orcamentado += aux['orcamentado'];
        desp_valor_total_lancado += aux['lancado'];
        desp_valor_total_desvio += aux['desvio'];
        desp_valor_total_liquidado += aux['liquidado'];
        desp_valor_total_por_liquidar += aux['porLiquidar'];

        this.justOrcamentoTotal['valorOrcamentado'] += aux['orcamentado'];
        this.justOrcamentoTotal['valorLancado'] += aux['lancado'];
        this.justOrcamentoTotal['valorDesvio'] += aux['desvio'];
        this.justOrcamentoTotal['valorLiquidado'] += aux['liquidado'];
        this.justOrcamentoTotal['valorPorLiquidar'] += aux['porLiquidar'];
      } else {
        this.reportListReceita.push(aux);

        rec_valor_total_orcamentado -= aux['orcamentado'];
        rec_valor_total_lancado -= aux['lancado'];
        rec_valor_total_desvio -= aux['desvio'];
        rec_valor_total_liquidado -= aux['liquidado'];
        rec_valor_total_por_liquidar -= aux['porLiquidar'];

        this.justOrcamentoTotal['valorOrcamentado'] += aux['orcamentado'];
        this.justOrcamentoTotal['valorLancado'] += aux['lancado'];
        this.justOrcamentoTotal['valorDesvio'] += aux['desvio'];
        this.justOrcamentoTotal['valorLiquidado'] += aux['liquidado'];
        this.justOrcamentoTotal['valorPorLiquidar'] += aux['porLiquidar'];
      }
    });

    if (this.reportList.length > 0) {
      this.reportList.push({ 
        label: 'Total',

        totalOrcamentado: desp_valor_total_orcamentado,
        totalLancado: desp_valor_total_lancado,
        totalDesvio: desp_valor_total_desvio,
        totalLiquidado: desp_valor_total_liquidado,
        totalPorLiquidar: desp_valor_total_por_liquidar,

        separator: false, 
        total: true 
      });
      this.totalDespesa = this.reportList[this.reportList.length - 1];
    }

    if (this.reportListReceita.length > 0) {
      this.reportListReceita.push({ 
        label: 'Total',

        totalOrcamentado: rec_valor_total_orcamentado,
        totalLancado: rec_valor_total_lancado,
        totalDesvio: rec_valor_total_desvio,
        totalLiquidado: rec_valor_total_liquidado,
        totalPorLiquidar: rec_valor_total_por_liquidar,

        separator: false, 
        total: true 
      });

      this.totalReceita = this.reportListReceita[this.reportListReceita.length - 1];
    }

    setTimeout(() => { this.exportDoc(); }, 1);
  }

  getResumoFinanceiro() {
    // let startDate = new Date(Number(this.selExercicio), 0, 1);
    // let endDate = new Date(Number(this.selExercicio), 11, 31);

    let startDate = this.startDateDP;
    let endDate = this.endDateDP;

    let exercicio = null;
    if (this.selExercicio) {
      exercicio = this.selExercicio;
    } else {
      exercicio = endDate.getFullYear().toString();
    }

    // SET REPORT HEADER DATA
    this.pdfReport['title'] = this.selCondominio.nome;
    this.pdfReport['reportType'] = 'Resumo Financeiro';
    this.pdfReport['startDate'] = startDate;
    this.pdfReport['endDate'] = endDate;
    this.pdfReport['now'] = new Date();
    this.nextExercicio = Number(exercicio) + 1;
    this.isDetailed = true;

    this.loading = true;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    forkJoin([
      this.api.getRelExercResumoFinanceiro(this.selCondominio.cod, startDate, endDate, exercicio),
      this.api.getContaCorrenteResumo('fraccoes', this.selCondominio.cod, null, null, new Date()),
      this.api.getRelExercListagemDespesas(this.selCondominio.cod, startDate, endDate, exercicio, true),
    ]).subscribe(res => {
      if ((res[0].hasOwnProperty('success') && res[0].success) && (res[1].hasOwnProperty('success') && res[1].success)) {
        this.generateResumoFinanceiroReportObj(res);

        this.loading = false;

        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });

// REGISTO ACTIVIDADES API CALL
let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
let nome = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;
let titulo = 'Relatório de Resumo Financeiro Visualizado';
let descricao = 'Condomínio: ' + cod + ' - ' + nome;
this.api.saveRegistoActividade(cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

      } else {
        this.loading = false;
        this.utils.apiErrorMsg(res[0]);

        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }
    }, err => {
      this.loading = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  generateResumoFinanceiroReportObj(res) {
    this.resumoFinanceiro.divida_fornecedores_total = 0;
    this.resumoFinanceiro.divida_fornecedores = [];

    this.resumoFinanceiro.saldo_final_total = 0;
    res[0].data.saldos.forEach(el => {
      this.resumoFinanceiro.saldo_final_total += Number(el.valor_total);
    });

    this.resumoFinanceiro.conta_corrente_total = 0;
    res[1].data.forEach(el => {
      this.resumoFinanceiro.conta_corrente_total += Number(el.saldo);
    });

    // HANDLE DIVIDAS A FORNECEDORES
    let prevRubrica = (res[2].data.length > 0) ? res[2].data[0].fornecedor_nome + ' - ' + res[2].data[0].rubrica_nome : null;
    let dividaAux = { rubrica_nome: null, valor_total: 0 };

    res[2].data.forEach((el, i) => {
      if (prevRubrica !== el.fornecedor_nome + ' - ' + el.rubrica_nome) {

        this.resumoFinanceiro.divida_fornecedores.push(dividaAux);

        dividaAux = { rubrica_nome: null, valor_total: 0 };
        if (i < res[2].data.length - 1) prevRubrica = res[2].data[i+1].fornecedor_nome + ' - ' + res[2].data[i+1].rubrica_nome;
      }

      dividaAux.rubrica_nome = el.fornecedor_nome + ' - ' + el.rubrica_nome;
      dividaAux.valor_total += (Number(el.valor) - Number(el.valor_liquidado));
      this.resumoFinanceiro.divida_fornecedores_total += (Number(el.valor) - Number(el.valor_liquidado));
    });
    if (this.resumoFinanceiro.divida_fornecedores.length > 0) this.resumoFinanceiro.divida_fornecedores.push(dividaAux);

    setTimeout(() => { this.exportDoc(); }, 1);
  }

  
  @ViewChild('mapaQuotasPdf', { static: false }) mapaQuotasPdf: MapaQuotasPdfComponent;
  async getMapaQuotas() {
    // let startDate = new Date(Number(this.selExercicio), 0, 1);
    // let endDate = new Date(Number(this.selExercicio), 11, 31);
    this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => {
        setTimeout(async () => { 
          let economico:'E'|'O' = this.orignalDoc === '0'? 'E' : 'O';
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
          let mapaQuotas = await this.mapaQuotasPdf.getBase64(this.selCondominio.cod, this.endDateDP, economico, false);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          let filename = 'Mapa_Quotas_' + this.utils.getFormatedDate(this.endDateDP);
          let contentType = this.utils.getBase64Prefix('.pdf');
          if (mapaQuotas.economico) {
            this.utils.downloadFile(contentType + mapaQuotas.economico, filename);
          } else {
            this.utils.downloadFile(contentType + mapaQuotas.original, filename);
          }

          let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
          let nome = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;
          let titulo = 'Mapa de Quotas Visualizado';
          let descricao = 'Condomínio: ' + cod + ' - ' + nome;
          this.api.saveRegistoActividade(cod, null, null, titulo, descricao).subscribe(res => {}, err => { });
        }, 1);
      })
      .onDeny(() => { });
      
    
    // let startDate = this.startDateDP;
    // let endDate = this.endDateDP;

    // let exercicio = null;
    // if (exercicio) {
    //   exercicio = this.selExercicio;
    // } else {
    //   exercicio = endDate.getFullYear().toString();
    // }

    // // SET REPORT HEADER DATA
    // this.pdfReport['title'] = this.selCondominio.nome;
    // this.pdfReport['reportType'] = 'Mapa de Quotas ' + exercicio + ' e Conta Corrente (Resumo)';

    // let auxEndDate = new Date(Number(this.selExercicio), 11, 31);
    // let now = new Date();
    // if (auxEndDate.getTime() > now.getTime()) {
    //   auxEndDate = now;
    // }

    // // this.pdfReport['endDate'] = auxEndDate;
    // this.pdfReport['endDate'] = endDate;
    // this.pdfReport['now'] = new Date();
    // this.nextExercicio = Number(exercicio) + 1;
    // this.isDetailed = true;

    // this.loading = true;

    

    // this.api.getFraccoes(this.selCondominio.cod).subscribe(res => {
    //   if (res.hasOwnProperty('success') && res.success) {
    //     let req = [this.api.getRelExercMapaQuotas(this.selCondominio.cod, null, startDate, endDate, exercicio)];

    //     res.data.forEach(el => {
    //       req.push(this.api.getContaCorrenteResumo('condominos', this.selCondominio.cod, [el.cod], null, endDate, '0'));
    //     });

    //     this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    //     forkJoin(req).subscribe(res => {
    //       if ((res[0].hasOwnProperty('success') && res[0].success)) {

    //       // if ((res[0].hasOwnProperty('success') && res[0].success) && (res[1].hasOwnProperty('success') && res[1].success)) {
    //         if (res[0].data.mapa_quotas.length === 0) {
    //           this.toastr.warning('Nenhum resultado disponível para gerar relatório.', 'Informação', { timeOut: 4000 });
    //           this.loading = false;
              
    //           this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    //           return;
    //         } 
    //         this.generateMapaQuotasReportObj(res);
    
    //         this.loading = false;

    //         this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });

    //         // REGISTO ACTIVIDADES API CALL
    //         let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
    //         let nome = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;
    //         let titulo = 'Mapa de Quotas Visualizado';
    //         let descricao = 'Condomínio: ' + cod + ' - ' + nome;
    //         this.api.saveRegistoActividade(cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

    //       } else {
    //         this.loading = false;
    //         this.utils.apiErrorMsg(res[0]);

    //         this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    //       }
    //     }, err => {
    //       this.loading = false;
    //       this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

    //       this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    //     });
    //   }
    // }, err => {
    //   this.loading = false;
    //   this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

    //   this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    // });
  }

  setPaymentStatus (el) {
    let today = new Date();

    if (Math.round(el.debito * 100) / 100 === 0) {  // PAGO
      return 'L';
    } else if (Math.round(el.debito * 100) / 100 === Math.round(el.quota * 100) / 100) {  // NAO PAGO
      // if (Number(el.ano) < today.getFullYear() || el.mes < today.getMonth() + 1 || (el.mes === today.getMonth() + 1 && today.getDate() > 8)) {
      if (Number(el.ano) < this.endDateDP.getFullYear() || el.mes < this.endDateDP.getMonth() + 1 || (el.mes === this.endDateDP.getMonth() + 1 && this.endDateDP.getDate() > 8)) {
        return 'NL';
      }
    } else {  // PARCIALMENTE PAGO
      return 'PL';
    }

    return null;
  }

  reportListSeg = [];
  generateMapaQuotasReportObj(res) {
    this.reportList = [];
    this.reportListSeg = [];

    let prevFraccao = res[0].data.mapa_quotas[0].fraccao;

    let prevFraccaoSeg = (res[0].data.mapa_quotas_seg.length > 0) ? res[0].data.mapa_quotas_seg[0].fraccao : null;
    let aux = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0 };
    let auxSeg = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0 };
    let hasSeg = false;

    let contasCorrente = res.slice(1);

    let contaCorrente = contasCorrente.find(item => (Array.isArray(item.data) && item.data.length > 0 && item.data[0].cod_fraccao === res[0].data.mapa_quotas[0].cod_fraccao));
    if (contaCorrente) {
      contaCorrente.data.forEach(item => {
        aux['porLiquidar'] += (item.hasOwnProperty('saldo')) ? (Number(item.saldo) * 100) / 100 : 0;
      });
    }

    res[0].data.mapa_quotas.forEach((el, i) => {
      el.quota = Number(el.quota);
      el.debito = Number(el.debito);
      el.mes = Number(el.mes);

      if (prevFraccao !== el.fraccao) {
        this.reportList.push(aux);
        aux = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0 };

        let contaCorrente = contasCorrente.find(item => (Array.isArray(item.data) && item.data.length > 0 && item.data[0].cod_fraccao === el.cod_fraccao));
        if (contaCorrente) {
          contaCorrente.data.forEach(item => {
            aux['porLiquidar'] += (item.hasOwnProperty('saldo')) ? (Number(item.saldo) * 100) / 100 : 0;
          });
        }
      }

      switch (el.mes) {
        case 1: aux['jan'] = this.setPaymentStatus(el); break;
        case 2: aux['fev'] = this.setPaymentStatus(el); break;
        case 3: aux['mar'] = this.setPaymentStatus(el); break;
        case 4: aux['abr'] = this.setPaymentStatus(el); break;
        case 5: aux['mai'] = this.setPaymentStatus(el); break;
        case 6: aux['jun'] = this.setPaymentStatus(el); break;
        case 7: aux['jul'] = this.setPaymentStatus(el); break;
        case 8: aux['ago'] = this.setPaymentStatus(el); break;
        case 9: aux['set'] = this.setPaymentStatus(el); break;
        case 10: aux['out'] = this.setPaymentStatus(el); break;
        case 11: aux['nov'] = this.setPaymentStatus(el); break;
        case 12: aux['dez'] = this.setPaymentStatus(el); break;
      }

      aux['fraccao_descricao'] = el.fraccao;
      aux['quota'] = Number(el.quota);

// aux['porLiquidar'] += (el.hasOwnProperty('debito')) ? (Number(el.debito) * 100) / 100 : 0;

      prevFraccao = el.fraccao;
    });
    this.reportList.push(aux);

    // COMPUTE TOTAL
    this.mapaQuotasTotal['valorPorLiquidar'] = 0;
    this.reportList.forEach(el => {
      this.mapaQuotasTotal['valorPorLiquidar'] += el.porLiquidar;
    });

    res[0].data.mapa_quotas_seg.forEach(el => {
      hasSeg = true;

      el.quota = Number(el.quota);
      el.debito = Number(el.debito);
      el.mes = Number(el.mes);

      if (prevFraccaoSeg !== el.fraccao) {
        this.reportListSeg.push(auxSeg);
        auxSeg = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0 };
      }

      switch (el.mes) {
        case 1: auxSeg['jan'] = this.setPaymentStatus(el); break;
        case 2: auxSeg['fev'] = this.setPaymentStatus(el); break;
        case 3: auxSeg['mar'] = this.setPaymentStatus(el); break;
        case 4: auxSeg['abr'] = this.setPaymentStatus(el); break;
        case 5: auxSeg['mai'] = this.setPaymentStatus(el); break;
        case 6: auxSeg['jun'] = this.setPaymentStatus(el); break;
        case 7: auxSeg['jul'] = this.setPaymentStatus(el); break;
        case 8: auxSeg['ago'] = this.setPaymentStatus(el); break;
        case 9: auxSeg['set'] = this.setPaymentStatus(el); break;
        case 10: auxSeg['out'] = this.setPaymentStatus(el); break;
        case 11: auxSeg['nov'] = this.setPaymentStatus(el); break;
        case 12: auxSeg['dez'] = this.setPaymentStatus(el); break;
      }

      auxSeg['fraccao_descricao'] = el.fraccao;
      auxSeg['quota'] = Number(el.quota);

      auxSeg['porLiquidar'] += (el.hasOwnProperty('debito')) ? (Number(el.debito) * 100) / 100 : 0;

      prevFraccaoSeg = el.fraccao;
    });
    if (hasSeg) this.reportListSeg.push(auxSeg);

    // COMPUTE TOTAL
    this.mapaQuotasSegTotal['valorPorLiquidar'] = 0;
    this.reportListSeg.forEach(el => {
      this.mapaQuotasSegTotal['valorPorLiquidar'] += el.porLiquidar;
    });

    this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => {
        setTimeout(() => { this.exportDoc(); }, 1);
      })
      .onDeny(() => { });
  }

  execicioChanged() {
    if (this.selExercicio) {
      this.startDateDP = new Date(Number(this.selExercicio), 0, 1);
      this.endDateDP = new Date(Number(this.selExercicio), 11, 31);

      let now = new Date();
      if (this.endDateDP.getTime() > now.getTime()) this.endDateDP = now;
    }
  }

  dateIntervalChanged() {
    if (this.selTipoRelatorio !== '6') {
      let startYear = (this.startDateDP) ? this.startDateDP.getFullYear() : null;
      let endYear = (this.endDateDP) ? this.endDateDP.getFullYear() : null;
  
      if (startYear && endYear && startYear === endYear) {
        this.selExercicio = endYear.toString();
      } else {
        this.selExercicio = null;
      }
    } else {

    }
  }

  // RELATORIO FECHO DE CONTAS - VARIABLES
  exercicioBalancete = null;
  startDateBalancete = null;
  endDateBalancete = null;

  exercicioListDesp = null;
  startDateListDesp = null;
  endDateListDesp = null;

  exercicioMovFin = null;
  startDateMovFin = null;
  endDateMovFin = null;

  exercicioContaCorrente = null;
  startDateContaCorrente = null;
  endDateContaCorrente = null;

  exercicioOrcamento = null;
  startDateOrcamento = null;
  endDateOrcamento = null;

  // SCRIPT - CHECK BALANCETES ------------------------------------------------
  scriptEnabled = false;
  exercicioScript = 2021;
  async checkBalancetes() {
    let activo = 1;
    let construcao = 0;
    let inactivo = 0;
    let angariacao = 0;

    let output = [];

    this.api.getCondominiums(0, 1000, null, 'cod', 'ASC', activo, construcao, inactivo, angariacao).subscribe(async res => {
      if (res.hasOwnProperty('success') && res.success) {
        // LOOP THROUGH EACH CONDOMINIO AND COMPUTE BALANCETE
        let data = null;
        let resp = null;
        let diffConds = []
        for (let i = 0; i < res.data.length; i++) {

          console.log(`--------------- ${i + 1} / ${res.data.length} ---------------`);
          console.log(`Cond: ${res.data[i].cod}`);

          data = await this.getBalanceteScript(res.data[i].cod);

          resp = this.generateBalanceteReportObjScript(data);

          output.push({
            codCondominio: res.data[i].cod,
            saldoMovimentos: resp.totalMov,
            saldoDespesasReceitas: resp.totalDespRec,
            diff: resp.totalMov - resp.totalDespRec,
          });

          console.log(`Diff: ${resp.totalMov - resp.totalDespRec}`);
          if ((resp.totalMov - resp.totalDespRec) != 0) {
            diffConds.push(res.data[i].cod);
          }
        }

        console.log('Output: ');
        console.log(JSON.stringify(output));
        console.log(JSON.stringify(diffConds));

      } else {
      }
    }, err => {});
  }

  getBalanceteScript(codCondominio) {
    return new Promise((resolve, reject) => {
      let startDate = new Date(this.exercicioScript, 0, 1);
      let endDate = new Date(this.exercicioScript, 11, 31);

      let exercicio = this.exercicioScript;
  
      // SET REPORT HEADER DATA
      this.nextExercicio = Number(exercicio) + 1;
      this.isDetailed = true;

      forkJoin([
        this.api.getRelExercBalanceteV2(codCondominio, 'despesas_extra', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(codCondominio, 'despesas_ordinarias', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(codCondominio, 'exerc_actual', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(codCondominio, 'exerc_anterior', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(codCondominio, 'saldos_finais', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(codCondominio, 'saldos_iniciais', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(codCondominio, 'receitas_extra', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(codCondominio, 'pag_adiantados', startDate, endDate, exercicio),
        this.api.getRelExercBalanceteV2(codCondominio, 'mov_despesas_extra', startDate, endDate, exercicio),
      ]).subscribe(res => {
        if (res[0].hasOwnProperty('success') && res[0].success) {

          // START - UPDATE ON DESPESAS EXTRA COMPUTATIONS - TESTING (REMOVE TO FALLBACK TO PREV VERSION - AND BACKEND!)
          let auxMov = null;
          res[0].data.forEach(el => {
            auxMov = res[8].data.filter(it => (it.descricao.indexOf('D ' + el.n_despesa + ' ') !== -1 || it.descricao.indexOf('D ' + el.n_despesa + '-') !== -1));
            el.valor_liquidado = (auxMov) ? auxMov.map(elem => -1 * Number(elem.valor)).reduce((a, b) => a + b, 0) : 0;
          });
          let rubricasAux = [...new Set(res[0].data.map(el => el.cod_rubrica))];
          let auxDespesasExtra = [];
          let aux = null;

          rubricasAux.forEach(el => {
            aux = res[0].data.filter(it => it.cod_rubrica === el);
            if (aux) {
              auxDespesasExtra.push({
                valor_total: aux.map(it => it.valor_liquidado).reduce((a, b) => a + b, 0),
                valor_liquidado: aux[0].valor_liquidado,
                descricao: aux[0].descricao,
                cod_rubrica: aux[0].cod_rubrica,
                rubrica: aux[0].rubrica,
                dt_desp: aux[0].dt_desp,
                dt_pag: aux[0].dt_pag,
              });
            }
          });
          res[0].data = auxDespesasExtra.filter(el => el.valor_liquidado >= 0);
          // END - UPDATE ON DESPESAS EXTRA COMPUTATIONS - TESTING

          let data = {
            despesas_extra: res[0].data,
            despesas_ordinarias: res[1].data,
            exerc_actual: res[2].data,
            exerc_anterior: res[3].data,
            saldos_finais: res[4].data,
            saldos_iniciais: res[5].data,
            receitas_extra: res[6].data.filter(it => this.utils.getDate(it.dt_pag).getTime() <= endDate.getTime()),
            pag_adiantados: res[7].data,
            movimentos: res[8].data,
          }
  
          let exerc_anterior_aux = [
            { valor_total: 0, tipo_proc: 'F' },
            { valor_total: 0, tipo_proc: 'O' },
            { valor_total: 0, tipo_proc: 'S' },
          ];
          data.exerc_anterior.forEach(el => {
            if (el.tipo_proc === 'F') {
              exerc_anterior_aux[0].valor_total += Number(el.valor_total);
            }
  
            if (el.tipo_proc === 'O' || el.tipo_proc === 'E') {
              exerc_anterior_aux[1].valor_total += Number(el.valor_total);
            }
  
            if (el.tipo_proc === 'S') {
              exerc_anterior_aux[2].valor_total += Number(el.valor_total);
            }
          });
          data.exerc_anterior = exerc_anterior_aux;

          resolve(data);
        }
      }, err => {});
    });
  }

  generateBalanceteReportObjScript(data) {
    // SALDOS INICIAS
    if (data.saldos_iniciais) {
      this.saldosIniciais.caixas = data.saldos_iniciais.filter(el => (el.banco.trim() === 'CAIXA' && Number(el.valor_total) !== 0));
      this.saldosIniciais.bancos = data.saldos_iniciais.filter(el => (el.banco.trim() !== 'CAIXA' && Number(el.valor_total) !== 0));

      // COMPUTE TOTALS
      this.saldosIniciais.totalCaixas = 0;
      this.saldosIniciais.caixas.forEach(el => {
        this.saldosIniciais.totalCaixas += Number(el.valor_total);
      });
      this.saldosIniciais.totalCaixas = Math.round(Number(this.saldosIniciais.totalCaixas) * 100) / 100;

      this.saldosIniciais.totalBancos = 0;
      this.saldosIniciais.bancos.forEach(el => {
        this.saldosIniciais.totalBancos += Number(el.valor_total);
      });
      this.saldosIniciais.totalBancos = Math.round(Number(this.saldosIniciais.totalBancos) * 100) / 100;
    }

    // SALDOS FINAIS
    if (data.saldos_finais) {
      this.saldosFinais.caixas = data.saldos_finais.filter(el => (el.banco.trim() === 'CAIXA' && Number(el.valor_total) !== 0));
      this.saldosFinais.bancos = data.saldos_finais.filter(el => (el.banco.trim() !== 'CAIXA' && Number(el.valor_total) !== 0));

      // COMPUTE TOTALS
      this.saldosFinais.totalCaixas = 0;
      this.saldosFinais.caixas.forEach(el => {
        this.saldosFinais.totalCaixas += Number(el.valor_total);
      });
      this.saldosFinais.totalCaixas = Math.round(Number(this.saldosFinais.totalCaixas) * 100) / 100;

      this.saldosFinais.totalBancos = 0;
      this.saldosFinais.bancos.forEach(el => {
        this.saldosFinais.totalBancos += Number(el.valor_total);
      });
      this.saldosFinais.totalBancos = Math.round(Number(this.saldosFinais.totalBancos) * 100) / 100;
    }

    // DESPESAS ORDINARIAS
    if (data.despesas_ordinarias) {
      this.despesasOrdinarias.rubricas = data.despesas_ordinarias;

// // START - UPDATE ON DESPESAS EXTRA COMPUTATIONS
// let auxMov = null;
// this.despesasOrdinarias.rubricas.forEach(el => {
//   auxMov = data.movimentos.filter(it => it.nid_rubrica === el.cod_rubrica);
//   el.valor_total = (auxMov) ? auxMov.map(elem => -1 * Number(elem.valor)).reduce((a, b) => a + b, 0) : 0;
// });
// // END - UPDATE ON DESPESAS EXTRA COMPUTATIONS

      // COMPUTE TOTALS
      this.despesasOrdinarias.totalRubricas = 0;
      this.despesasOrdinarias.rubricas.forEach(el => {
        this.despesasOrdinarias.totalRubricas += Number(el.valor_total);
      });
      this.despesasOrdinarias.totalRubricas = Math.round(Number(this.despesasOrdinarias.totalRubricas) * 100) / 100;
    }

    // DESPESAS EXTRAORDINARIAS
    if (data.despesas_extra) {
      this.despesasExtraordinarias.rubricas = data.despesas_extra;

      // COMPUTE TOTALS
      this.despesasExtraordinarias.totalRubricas = 0;
      this.despesasExtraordinarias.rubricas.forEach(el => {
        this.despesasExtraordinarias.totalRubricas += Number(el.valor_total);
      });
      this.despesasExtraordinarias.totalRubricas = Math.round(Number(this.despesasExtraordinarias.totalRubricas) * 100) / 100;

      this.despesasExtraordinarias.rubricas = this.despesasExtraordinarias.rubricas.filter(el => el.valor_total > 0);
    }

    // RECEITAS - EXERCICIO ANTERIOR
    if (data.exerc_anterior && data.exerc_anterior.length > 0) {
      this.receitas.exerAnterior.gestaoCorrente = Number(data.exerc_anterior.find(el => (el.tipo_proc === 'O')).valor_total);
      this.receitas.exerAnterior.fundoReserva = Number(data.exerc_anterior.find(el => (el.tipo_proc === 'F')).valor_total);
      this.receitas.exerAnterior.seguro = (data.exerc_anterior.find(el => (el.tipo_proc === 'S'))) ? Number(data.exerc_anterior.find(el => (el.tipo_proc === 'S')).valor_total) : null;
      this.receitas.exerAnterior.total = 0;
      Object.keys(this.receitas.exerAnterior).forEach(key => { if (key !== 'total') this.receitas.exerAnterior.total += this.receitas.exerAnterior[key]; });
    } else {
      this.receitas.exerAnterior.gestaoCorrente = 0;
      this.receitas.exerAnterior.fundoReserva = 0;
    }

    // RECEITAS - EXERCICIO ACTUAL
    if (data.exerc_actual) {
      this.receitas.exerActual.gestaoCorrente = Number(data.exerc_actual.gc[0].valor_total);
      this.receitas.exerActual.fundoReserva = Number(data.exerc_actual.fr[0].valor_total);
      this.receitas.exerActual.seguro = Number(data.exerc_actual.seg[0].valor_total);
      this.receitas.exerActual.creditosDisponiveis = Number(data.exerc_actual.cred_disponivel[0].valor_total);
      this.receitas.exerActual.creditosUtilizados = -1 * Number(data.exerc_actual.cred_utilizado[0].valor_total);
      this.receitas.exerActual.total = 0;
      Object.keys(this.receitas.exerActual).forEach(key => { if (key !== 'total' && key !== 'receitasExtras') this.receitas.exerActual.total += this.receitas.exerActual[key]; });

      // RECEITAS EXTRAS / PENALIZAÇÔES / ETC
      this.receitas.exerActual.receitasExtras = [];
      data.exerc_actual.extra.forEach(el => {
        this.receitas.exerActual.receitasExtras.push({ descricao: el.descricao, valor: Number(el.valor_total) });

        this.receitas.exerActual.total += Number(el.valor_total);
      });
    }

    // PAGAMENTOS POSTERIORES
    if (data.pag_adiantados) {
      this.receitas.pagAdiantados.gestaoCorrente = Number(data.pag_adiantados.gc[0].valor_total);
      this.receitas.pagAdiantados.fundoReserva = Number(data.pag_adiantados.fr[0].valor_total);
      this.receitas.pagAdiantados.seguro = Number(data.pag_adiantados.seg[0].valor_total);
      this.receitas.pagAdiantados.total = this.receitas.pagAdiantados.gestaoCorrente + this.receitas.pagAdiantados.fundoReserva + this.receitas.pagAdiantados.seguro;
    }

    // RECEITAS EXTRAORDINARIAS
    if (data.receitas_extra) {
      this.receitasExtraordinarias.rubricas = data.receitas_extra;

      // COMPUTE TOTALS
      this.receitasExtraordinarias.totalRubricas = 0;
      this.receitasExtraordinarias.rubricas.forEach(el => {
        this.receitasExtraordinarias.totalRubricas += Number(el.valor_total);
      });
      this.receitasExtraordinarias.totalRubricas = Math.round(Number(this.receitasExtraordinarias.totalRubricas) * 100) / 100;
    }

    // CHECK BALANCETE TOTALS AND LAUNCH ALERT IF NEEDED
    let totalSaldoFinal = Number(this.saldosFinais.totalCaixas) + Number(this.saldosFinais.totalBancos);
    let totalAux = 0;
    if (this.receitasExtraordinarias.rubricas.length === 0) {
      totalAux = (this.saldosIniciais.totalCaixas + this.saldosIniciais.totalBancos) + ((this.receitas.exerAnterior.total + this.receitas.exerActual.total + this.receitas.pagAdiantados.total) - (this.despesasOrdinarias.totalRubricas + this.despesasExtraordinarias.totalRubricas));
    }
    if (this.receitasExtraordinarias.rubricas.length > 0) {
       totalAux = (this.saldosIniciais.totalCaixas + this.saldosIniciais.totalBancos) + ((this.receitas.exerAnterior.total + this.receitas.exerActual.total + this.receitas.pagAdiantados.total + this.receitasExtraordinarias.totalRubricas) - (this.despesasOrdinarias.totalRubricas + this.despesasExtraordinarias.totalRubricas));
    }

    return { totalMov: (Math.round(totalSaldoFinal * 100) / 100), totalDespRec: (Math.round(totalAux * 100) / 100) };
  }

  // START - FECHO DE CONTAS --------------------------------------------------
  fechoContasArrOrig = [
    { label: 'RELATORIO_ASSEMBLEIA', name: 'Relatório de Assembleia', checked: true, disabled: false },
    { label: 'LISTA_PRESENCAS', name: 'Lista de Presenças', checked: true, disabled: false },
    { label: 'LISTA_PAG_N_IDENTIFICADOS', name: 'Listagem de Pagamentos não Identificados', checked: true, disabled: false },
    { label: 'BALANCETE', name: 'Balancete', checked: true, disabled: false },
    { label: 'LISTA_DESPESAS', name: 'Listagem de despesas', checked: true, disabled: false },
    { label: 'MOV_CAIXA', name: 'Movimentos CAIXA', checked: false, disabled: true },
    { label: 'MOV_BANCOS', name: 'Movimento Bancos', checked: true, hasSubsMenu: true, disabled: false },
    { label: 'CONTA_CORRENTE_RESUMIDA', name: 'Conta Corrente Resumida', checked: true, disabled: false },
    { label: 'ORCAMENTO', name: 'Orçamentos', checked: true, hasSubsMenu: true, disabled: false },
  ];
  fechoContasArr = [];
  fraccoesList = [];

  getAvailableReports(codCondominio) {
    return new Promise(resolve => {
      let req = [
        this.api.getOrcamentosByCondominio(codCondominio, Number(this.selExercicio) + 1),
        this.api.getContasByCondominio(codCondominio, this.selExercicio),
        this.api.getFraccoes(codCondominio),
      ];
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      forkJoin(req).subscribe(
        res => {
          // ORCAMENTOS
          let orcamentos = [];
          if (res[0].hasOwnProperty('success') && res[0].success && res[0].data.length > 0) orcamentos = res[0].data;

          // MOVIMENTOS CAIXA E BANCOS
          let caixa = null;
          let bancos = [];
          if (res[1].hasOwnProperty('success') && res[1].success && res[1].data.length > 0) {
            caixa = res[1].data.find(it => (it.banco === 'CAIXA' && it.has_mov));
            bancos = res[1].data.filter(it => (it.banco !== 'CAIXA' && it.has_mov));
          }

          this.fechoContasArr = [];
          this.fechoContasArrOrig.forEach(el => {
            if (el.label !== 'ORCAMENTO' && el.label !== 'MOV_CAIXA' && el.label !== 'MOV_BANCOS') { el.checked = true; }

            this.fechoContasArr.push(el);

            if (el.label === 'ORCAMENTO') {
              if (orcamentos.length > 0) {
                orcamentos.forEach(it => {
                  this.fechoContasArr.push({ label: 'ORCAMENTO', name: 'Orçamento nº ' + it.cod + ((it.val_lancado === '1') ? ' (valores lançados)' : ''), checked: true, disabled: false, entry: it });
                });
              } else {
                this.fechoContasArr.push({ label: 'ORCAMENTO', name: 'Nenhum resultado', checked: false, disabled: true });
              }
            }

            if (el.label === 'MOV_CAIXA' && caixa) {
              this.fechoContasArr[this.fechoContasArr.length - 1].checked = true;
              this.fechoContasArr[this.fechoContasArr.length - 1].disabled = false;
              this.fechoContasArr[this.fechoContasArr.length - 1]['entry'] = caixa;
            }

            if (el.label === 'MOV_BANCOS') {
              if (bancos.length > 0) {
                bancos.forEach(it => {
                  this.fechoContasArr.push({ label: 'MOV_BANCOS', name: it.banco, checked: true, disabled: false, entry: it });
                });
              } else {
                this.fechoContasArr.push({ label: 'MOV_BANCOS', name: 'Nenhum resultado', checked: false, disabled: true });
              }
            }

          });

          this.fraccoesList = [];
          let aux = null;
          res[2].data.forEach(el => {
            aux = {
              checked: true,
              cod_fraccao: el.cod,
              zona_nome: el.zona_nome,
              fraccao: el.cod + ' - ' + el.nome,
              condomino_nome: el.condomino_nome,
            };
            this.fraccoesList.push(aux);
          });

          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(true);
        },
        err => {
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          resolve(false);
        }
      );
    });
  }

  async getFechoContas() {
    let codCondominio = (this.selCondominio.hasOwnProperty('cod') && this.selCondominio.cod) ? this.selCondominio.cod : null;

    let resp = await this.getAvailableReports(codCondominio);

    if (resp) {
      this.fechoContasModalRef = this.modalService
      .open(this.fechoContasAlertConfig)
      .onApprove(() => { this.loadingFechoContas = false; })
      .onDeny(() => { this.loadingFechoContas = false; });
    }
  }

  balanceteTitle = null;
  despesasTitle = null;
  contasCorrentesTitle = null;
  orcamentoTitle = null;
  loadingFechoContas = false;
  movimentosContas = [];
  orcamentos = [];
  exportFechoContas = false;
  async genFechoContasReport() {
    this.loadingFechoContas = true;

    let promises = [];
    this.movimentosContas = [];
    this.contasCorrentes = [];
    this.orcamentos = [];

    // GET ASSEMBLEIAS (RELATORIO DE ASSEMBLEIA / LISTA DE PRESENÇAS)
    let exportRelatorioAssembleia = this.fechoContasArr.find(el => (el.label === 'RELATORIO_ASSEMBLEIA' && el.checked));
    let exportListaPresencas = this.fechoContasArr.find(el => (el.label === 'LISTA_PRESENCAS' && el.checked));
    if (exportRelatorioAssembleia || exportListaPresencas) {
      let codCondominio = (this.selCondominio.hasOwnProperty('cod') && this.selCondominio.cod) ? this.selCondominio.cod : null;
      let assembleiaList: any = await this.getAssembleias(codCondominio, Number(this.selExercicio) + 1);
      if (assembleiaList) {
        this.assembleiaOpts = assembleiaList.assembleias.filter(el => !el.num_ata).map(el => { return { name: `Assembleia do dia ${el.dt}`, value: el.id }; });

        let temp = null;
        if (this.assembleiaOpts.length > 1) {
          this.loadingFechoContas = false;
          temp = await this.presentAssembleiasSelecModal();
          if (!temp) return;
        } else {
          if (this.assembleiaOpts.length) temp = this.assembleiaOpts[0].value;
        }

        this.selectedAssembleia = (assembleiaList.assembleias.length) ? assembleiaList.assembleias.find(el => el.id === temp) : null;
        if (this.selectedAssembleia) {
          temp = assembleiaList.assembleias.find(el => el.num_ata);
          if (temp) this.selectedAssembleia.num_ata = Number(temp.num_ata) + 1;

          // INPUT PARA RELATORIO DE ASSEMBLEIA
          this.inputRelatorioAssembleia = {
            nomeCondominio: (this.selCondominio && this.selCondominio.nome) ? this.selCondominio.nome : '',
            nomeColaborador: ((this.selectedAssembleia.first_name) ? this.selectedAssembleia.first_name : '') + ' ' + ((this.selectedAssembleia.last_name) ? this.selectedAssembleia.last_name : ''),
            numAta: this.selectedAssembleia.num_ata,
            data: (this.selectedAssembleia.dt) ? this.utils.getDayOfWeek(this.utils.getDate(this.selectedAssembleia.dt)) + ', ' + this.selectedAssembleia.dt : '',
            hora: this.selectedAssembleia.hora,
          };

          // INPUT PARA REGISTO DE PRESENÇAS
          this.inputListaPresencasAssembleia = {
            codCondominio: (this.selCondominio && this.selCondominio.cod) ? this.selCondominio.cod : '',
            nomeCondominio: (this.selCondominio && this.selCondominio.nome) ? this.selCondominio.nome : '',
            fraccaoList: (assembleiaList.fraccoes.length) ? assembleiaList.fraccoes : [],
            permTotal: assembleiaList.fraccoes.map(el => (el.permilagem) ? Number(el.permilagem) : 0).reduce((a, b) => a + b, 0),
            data: this.selectedAssembleia.dt,
          };
        }
      }
    }

    this.loadingFechoContas = true;

    // GET BALANCETE
    let exportBalancete = null;
    this.balanceteTitle = null;
    if (this.fechoContasArr.find(el => (el.label === 'BALANCETE' && el.checked))) {
      let startDate = new Date(Number(this.selExercicio), 0, 1);
      let endDate = new Date(Number(this.selExercicio), 11, 31);

      exportBalancete = await this.getBalancete(startDate, endDate);

      this.balanceteTitle = 'Balancete ' + this.selExercicio;
    }

    // LISTAGEM DE DESPESAS
    let exportDespesas = null;
    if (this.fechoContasArr.find(el => (el.label === 'LISTA_DESPESAS' && el.checked))) {
      let startDate = new Date(Number(this.selExercicio), 0, 1);
      let endDate = new Date(Number(this.selExercicio), 11, 31);

      exportDespesas = await this.getListagemDespesas(startDate, endDate);
    }

    // LISTAGEM DE PAGAMENTOS NÃO IDENTIFICADOS
    let exportPagNaoIdentificado = null;
    if (this.fechoContasArr.find(el => (el.label === 'LISTA_PAG_N_IDENTIFICADOS' && el.checked))) {
      let codCondominio = (this.selCondominio.hasOwnProperty('cod') && this.selCondominio.cod) ? this.selCondominio.cod : null;
      exportPagNaoIdentificado = await this.getListagemPagNaoIdentificados(codCondominio, this.endDateDP);
      if (Array.isArray(exportPagNaoIdentificado) && exportPagNaoIdentificado.length) {
        // INPUT PARA PAGAMENTOS NAO IDENTIFICADOS
        this.inputPagNaoIdentificados = {
          codCondominio: (this.selCondominio && this.selCondominio.cod) ? this.selCondominio.cod : '',
          nomeCondominio: (this.selCondominio && this.selCondominio.nome) ? this.selCondominio.nome : '',
          endDate: this.endDateDP,
          tableData: (exportPagNaoIdentificado.length) ? exportPagNaoIdentificado : [],
          totalParcial: (exportPagNaoIdentificado.length) ? exportPagNaoIdentificado.map(el => Number(el.a_liquidar)).reduce((a, b) => a + b, 0) : 0,
          total: (exportPagNaoIdentificado.length) ? exportPagNaoIdentificado.map(el => Number(el.valor)).reduce((a, b) => a + b, 0) : 0,
        };
      }
    }

    // MOVIMENTOS CAIXA
    let caixa = this.fechoContasArr.find(el => (el.label === 'MOV_CAIXA' && el.checked));
    if (caixa) {
      let startDate = new Date(Number(this.selExercicio), 0, 1);
      let endDate = new Date(Number(this.selExercicio), 11, 31);
      let movimentos: any = await this.getMovimentos(caixa.entry.cod, startDate, endDate);
      let aux = {
        conta: caixa.entry,
        movimentos: movimentos.mov,
        total: movimentos.total,
      }
      this.movimentosContas.push(aux);
    }

    // MOVIMENTOS BANCOS COM MOVIMENTOS
    let bancos = this.fechoContasArr.filter(el => (el.label === 'MOV_BANCOS' && el.checked && el.hasOwnProperty('entry')));
    if (bancos.length > 0) {
      let aux = null;
      let startDate = new Date(Number(this.selExercicio), 0, 1);
      let endDate = new Date(Number(this.selExercicio), 11, 31);
      let movimentos: any = null;
      for (let i = 0; i < bancos.length; i++) {
        movimentos = await this.getMovimentos(bancos[i].entry.cod, startDate, endDate);
        aux = {
          conta: bancos[i].entry,
          movimentos: movimentos.mov,
          total: movimentos.total,
        }
        this.movimentosContas.push(aux);  
      }
    }

    // CONTAS CORRENTES RESUMIDA
    if (this.fechoContasArr.find(el => (el.label === 'CONTA_CORRENTE_RESUMIDA' && el.checked))) {
      let endDate = new Date(Number(this.selExercicio), 11, 31);

      await this.getContasCorrentes(endDate);
    }

    // ORÇAMENTO PARA ANO N+1
    let orcamentos = this.fechoContasArr.filter(el => (el.label === 'ORCAMENTO' && el.checked && el.hasOwnProperty('entry')));
    if (orcamentos.length > 0) {
      for (let i = 0; i < orcamentos.length; i++) {
        let aux = await this.getOrcamento(orcamentos[i].entry.id);
        if (aux) {
          this.orcamentos.push(aux);
        }
      }
    }

    if (this.fechoContasModalRef) {
      this.exportFechoContas = true;
      
      this.despesasTitle = 'Listagem de Despesas';
      this.contasCorrentesTitle = 'Conta Corrente - Resumido';
      this.orcamentoTitle = 'Orçamento ' + (Number(this.selExercicio) + 1) + ' - Rubricas do Orçamento e Listagem de Quotas';

      let promisesDocApoio = [];
      setTimeout(() => {
        if (exportRelatorioAssembleia) promisesDocApoio.push(this.relatorioAssembleia.export());

        if (exportListaPresencas) promisesDocApoio.push(this.listaPresencasAssembleia.export());

        if (Array.isArray(exportPagNaoIdentificado) && exportPagNaoIdentificado.length) promisesDocApoio.push(this.pagNaoIdentificados.export());

        if (exportBalancete) promises.push(this.pdfBalanceteController.export());

        if (exportDespesas) promises.push(this.pdfDespesasController.export());

        if (this.movimentosContas.length > 0) promises.push(this.pdfMovCaixaBancoController.export());

        if (this.contasCorrentes.length > 0) promises.push(this.pdfContasCorrentesController.export());

        if (this.orcamentos.length > 0) promises.push(this.pdfOrcamentosController.export());

        if (promises.length || promisesDocApoio.length) {
  
if (promisesDocApoio.length) {
  Promise.all(promisesDocApoio).then(async groups => {
    const rootGroup = new Group({ pdf: { multiPage: true } });
    groups.forEach((group) => {
        rootGroup.append(...group.children);
    });

    return exportPDF(rootGroup, { paperSize: 'A4' });
  }).then(async dataUri => {
    saveAs(dataUri, 'Relatório Fecho de Contas ' + this.selExercicio + '_doc_apoio.pdf',{
      proxyURL: this.appConfig.fileProxyUrl,
      forceProxy: true,
      proxyTarget: '_blank',
    });
    setTimeout(() => { 
      this.exportFechoContas = false;
      this.fechoContasModalRef.approve();
    }, 1000);
  });
}          

if (promises.length) {
  Promise.all(promises).then(async groups => {
    const rootGroup = new Group({ pdf: { multiPage: true } });
    groups.forEach((group) => {
        rootGroup.append(...group.children);
    });

    return exportPDF(rootGroup, { paperSize: 'A4' });
  }).then(async dataUri => {
    saveAs(dataUri, 'Relatório Fecho de Contas ' + this.selExercicio + '.pdf',{
      proxyURL: this.appConfig.fileProxyUrl,
      forceProxy: true,
      proxyTarget: '_blank',
    });
    setTimeout(() => { 
      this.exportFechoContas = false;
      this.fechoContasModalRef.approve();
    }, 1000);
  });
}

        } else {
          this.toastr.warning('Nenhum dado disponível para apresentação no relatório de fecho de contas.', 'Relatório de Fecho de Contas', { timeOut: 4000 }); 
          this.exportFechoContas = false;
          this.loadingFechoContas = false;
        }
      }, 1000);

    }

    let reconciliacaoBancaria = this.fechoContasArr.find(el => (el.label === 'MOV_CAIXA' && el.checked));
  }

  // ORCAMENTOS - METHODS AND VARIABLES
  simulacaoListToPrint = [];
  rubricasOrcListToPrint = [];
  rubricasOrcTotais = {
    valor: 0,
  };
  zonasOrcListToPrint = [];
  zonasOrcTotais = {
    orc: 0,
    fr: 0,
    seg: 0,
    quota: 0
  };
  orcSubs = null;
  
  isVistaMensalOrcamento = true;
  isVistaMensalSimulacao = true;
  repProporcionalFR = null;
  selCobrancaFR = null;
  selCobrancaOrcamento = null;
  
  orcFraccoesSeg = [];
  cobrancaFR = [{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true }];
  cobrancaOrcamento = [{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true }];
  premioSeg = null
  reparticaoSeg = null;
  premilagemSeg = null;
  segMonths = [{ month: 'janeiro', active: false },{ month: 'fevereiro', active: false },{ month: 'marco', active: false },{ month: 'abril', active: false },{ month: 'maio', active: false },{ month: 'junho', active: false }, { month: 'julho', active: false }, { month: 'agosto', active: false },{ month: 'setembro', active: false },{ month: 'outubro', active: false },{ month: 'novembro', active: false },{ month: 'dezembro', active: false }];
  orcZonas = [];
  zonas = [];
  orcamentoId = null;
  orcamento = null;
  fraccoes = [];
  orcFraccoes = [];
  orcFraccoesFR = [];
  orcRubricas = [];
  orcamentoCod = null;
  rubricasListCol = [];
  rubricasList = [];
  orcamentoObj = null;
  orcamentoDataTable = null;
  rubricasTotais = { total: 0 };
  simulacaoList = null;
  fraccoesListCol = null;
  simulacaoTotais = {
    permilagem: 0,
    orcamento: 0,
    fundoReserva: 0,
    seguro: 0,
    total: 0,
  }
  firstCallSim = true;
  initOrc() {
    this.simulacaoListToPrint = [];
    this.rubricasOrcListToPrint = [];
    this.rubricasOrcTotais = {
      valor: 0,
    };
    this.zonasOrcListToPrint = [];
    this.zonasOrcTotais = {
      orc: 0,
      fr: 0,
      seg: 0,
      quota: 0
    };
    this.orcSubs = null;
    
    this.isVistaMensalOrcamento = true;
    this.isVistaMensalSimulacao = true;
    this.repProporcionalFR = null;
    this.selCobrancaFR = null;
    this.selCobrancaOrcamento = null;
    
    this.orcFraccoesSeg = [];
    this.cobrancaFR = [{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true }];
    this.cobrancaOrcamento = [{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true }];
    this.premioSeg = null
    this.reparticaoSeg = null;
    this.premilagemSeg = null;
    this.segMonths = [{ month: 'janeiro', active: false },{ month: 'fevereiro', active: false },{ month: 'marco', active: false },{ month: 'abril', active: false },{ month: 'maio', active: false },{ month: 'junho', active: false }, { month: 'julho', active: false }, { month: 'agosto', active: false },{ month: 'setembro', active: false },{ month: 'outubro', active: false },{ month: 'novembro', active: false },{ month: 'dezembro', active: false }];
    this.orcZonas = [];
    this.zonas = [];
    this.orcamentoId = null;
    this.orcamento = null;
    this.fraccoes = [];
    this.orcFraccoes = [];
    this.orcFraccoesFR = [];
    this.orcRubricas = [];
    this.orcamentoCod = null;
    this.rubricasListCol = [];
    this.rubricasList = [];
    this.orcamentoObj = null;
    this.orcamentoDataTable = null;
    this.rubricasTotais = { total: 0 };
    this.simulacaoList = null;
    this.fraccoesListCol = null;
    this.simulacaoTotais = {
      permilagem: 0,
      orcamento: 0,
      fundoReserva: 0,
      seguro: 0,
      total: 0,
    }
    this.firstCallSim = true;
  }

  getOrcamento(orcamentoId) {
    return new Promise(resolve => {
      this.orcSubs = this.api.getOrcamentoDetailsREVIEW(orcamentoId).subscribe(async res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.initOrc();

          // SET TIPO FCR
          let repFR = null;
          if (Array.isArray(res.data.zonas) && res.data.zonas.length > 0) {
            res.data.zonas.forEach(zona => {
              if (repFR === null) {
                repFR = zona.reparticao_fr;
              }
  
              if (repFR !== null && repFR !== zona.reparticao_fr) {
                repFR = false;
              }
            });
  
            this.repProporcionalFR = (repFR && (repFR === 'E' || repFR === 'P' || repFR === 'PF')) ? repFR : 'M';
          }
  
          this.selCobrancaFR = '0';
          this.selCobrancaOrcamento = '0';
          this.appConfig.periodicityOpts.forEach(el => {
            if (el.model.length === this.cobrancaOrcamento.length && el.model.every((value, index) => { return value === this.cobrancaOrcamento[index].sel; })) {
              this.selCobrancaOrcamento = el.value;
            }
            if (el.model.length === this.cobrancaFR.length && el.model.every((value, index) => { return value === this.cobrancaFR[index].sel; })) {
              this.selCobrancaFR = el.value;
            }
          });
  
          this.api.getActSegCond(res.data.orcamento[0].cod_condominio).subscribe(async seg => {
            if (seg.hasOwnProperty('success') && seg.success) {

              this.premioSeg = Number(seg.data.premio);
              this.reparticaoSeg = seg.data.pagamento;
              this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });  

              this.premilagemSeg = 0;
              this.orcFraccoesSeg = [];
              res.data.fraccoes_fr.forEach(el => {
                if (el.paga_seg_colect === '1') this.premilagemSeg = this.premilagemSeg + Number(el.permilagem);

                this.orcFraccoesSeg.push({
                  cod_fraccao: el.cod,
                  paga_seg_colect: el.paga_seg_colect,
                  permilagem: Number(el.permilagem),
                  quota_seg_anual: 0,
                })
              });

              if (this.reparticaoSeg === 'P') {
                this.orcFraccoesSeg.forEach(el => {
                  if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round(((this.premioSeg * el.permilagem) / this.premilagemSeg) * 100) / 100;
                });
              }

              if (this.reparticaoSeg === 'E') {
                let nFraccoes = this.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
                this.orcFraccoesSeg.forEach(el => {
                  if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round((this.premioSeg / nFraccoes) * 100) / 100;
                });
              }

              await this.handleOrcDetailsObjFromApi(res);

              this.generateQuotasList();
              this.generateRubricasOrcList();

              resolve({ 
                startDate: new Date(res.data.orcamento[0].dt_inicio),
                endDate: new Date(res.data.orcamento[0].dt_fim),
                simulacaoListToPrint: JSON.parse(JSON.stringify(this.simulacaoListToPrint)),
                zonasOrcListToPrint: JSON.parse(JSON.stringify(this.zonasOrcListToPrint)),
                zonasOrcTotais: JSON.parse(JSON.stringify(this.zonasOrcTotais)),
                rubricasOrcTotais: JSON.parse(JSON.stringify(this.rubricasOrcTotais)),
                simulacaoTotaisPDF: JSON.parse(JSON.stringify(this.simulacaoTotaisPDF)),
                rubricasOrcListToPrint: JSON.parse(JSON.stringify(this.rubricasOrcListToPrint)),
              });
            }
          }, err => {});  
        }
      }, err => {
        resolve(false);
      });
    });
  }
  async handleOrcDetailsObjFromApi(res) {
    if (res.data.zonas.length === 0) {
      await this.setInitRubricasList(res.data.orcamento[0].cod_condominio);
      this.orcZonas = this.zonas;
    } else {
      this.orcZonas = res.data.zonas;
      this.zonas = this.orcZonas;
    }

    if (this.orcamentoId && this.fraccoes.length === 0) {
      await this.setInitFraccoesList(res.data.orcamento[0].cod_condominio);
    }

    this.orcFraccoes = res.data.fraccoes;
    this.orcFraccoesFR = res.data.fraccoes_fr;

    this.orcRubricas = res.data.rubricas;
    this.orcamento = (Array.isArray(res.data.orcamento)) ? res.data.orcamento[0] : null;

    this.orcamentoId = this.orcamento.id;
    this.orcamentoCod = this.orcamento.cod;

    // RUBRICA TABLE SECTION
    this.rubricasListCol = [];
    this.orcZonas.forEach(el => {
      this.rubricasListCol.push({
        key: 'null',
        name: el.nome,
        type: 'number',
        sort: null,
        searchable: false,
        data: el
      });
    });

    let auxRubricas = [];
    this.orcRubricas.forEach(el => {
      if (!auxRubricas.find(it => el.rub_cod === it.rub_cod)) auxRubricas.push(el); 
    });

    this.rubricasList = [];
    auxRubricas.forEach(el => {
      let newRubrica = {
        id: (el.hasOwnProperty('id') && el.id) ? el.id : null,
        cod: el.cod,
        nome: el.rub_nome,
        reparticao: (el.reparticao === 'P' || el.reparticao === 'E') ? el.reparticao : 'M',
        tipo: (el.tipo) ? el.tipo : null,
        cod_rubrica: el.cod_rubrica,
        valor: 0,
        checked: false,
        centered: true,
        total: 0,

        despesa: (el.despesa === '1'),
        receita: (el.receita === '1'),
      };

      this.zonas.forEach((el, i) => {
        newRubrica['valor-zona-' + i] = 0;
        newRubrica['cod-zona-' + i] = el.cod;
        newRubrica['perm-zona-' + i] = el.permilagem;
      });

      let aux = this.orcRubricas.filter(it => it.rub_cod === el.rub_cod);
      aux.forEach(it => {
        if (it) {
          for (let i = 0; i < this.zonas.length ; i++) {
            if (it.cod_zona === newRubrica['cod-zona-' + i]) {
              newRubrica['valor-zona-' + i] = Number(it.valor);
              break;
            }
          }
          newRubrica.valor += Number(it.valor);
        }
      });

      this.rubricasList.push(newRubrica);
    });

    this.rubricasTotais.total = 0;
    this.rubricasListCol.filter(el => (el.data !== null)).forEach((it, i) => {
      this.rubricasTotais['total-zona-' + i] = 0;
      this.rubricasList.forEach(el => {
        this.rubricasTotais['total-zona-' + i] += Number(el['valor-zona-' + i]);
      });
      this.rubricasTotais.total += Number(this.rubricasTotais['total-zona-' + i]);
    });

    // ORCAMENTO TABLE SECTION ----------------------------------------------
    if (Array.isArray(res.data.orcamento) && res.data.orcamento.length > 0) {
      this.orcamentoObj = res.data;

      // ALWAYS USE THE FIRST ZONE PERIODICITY (TODO - ADD THIS TO ORCAMENTO)
      if (this.orcamentoObj.zonas.length > 0) {
        this.cobrancaOrcamento = Array.prototype.map.call(this.orcamentoObj.zonas[0].meses_orc, (el) => { return { sel: (el === 'S') } });
        this.cobrancaFR = Array.prototype.map.call(this.orcamentoObj.zonas[0].meses_fr, (el) => { return { sel: (el === 'S') } });
      }

      let aux = null;
      aux = this.appConfig.periodicityOpts.find(el => (JSON.stringify(el.model) === JSON.stringify(this.cobrancaOrcamento)));
      if (aux) this.selCobrancaOrcamento = aux.value;

      aux = this.appConfig.periodicityOpts.find(el => (JSON.stringify(el.model) === JSON.stringify(this.cobrancaFR)));
      if (aux) this.selCobrancaFR = aux.value;

      this.getOrcamentoDatatable(this.orcamentoObj.zonas, this.orcamentoObj.fraccoes, this.orcamentoObj.fraccoes_fr);
    }
  }
  getOrcamentoDatatable(zonas, fraccoes, fraccoes_fr) {
    // SET ORCAMENTO DATA TABLE
    this.orcamentoDataTable = [];

    zonas.forEach((zona, i) => {
      // GET REPARTICAO INDICATION STRING
      let seenRep = '';
      this.rubricasList.forEach(rubrica => {
        if (seenRep.indexOf(rubrica.reparticao) === -1 && rubrica['valor-zona-' + i] !== 0) {
          // ADD RUBRICA TO SEEN STRING
          seenRep += (rubrica.reparticao + '/');
        }
      });

      if (seenRep) seenRep = seenRep.slice(0, -1);

      // HANDLE ORCAMENTO TABLE
      let auxObj = {
        cod: zona.cod,
        nome: zona.nome,
        perm: Number(zona.permilagem),
        fr_reparticao: zona.pagamento_fr,
        fr_valor: 0,
        orc_reparticao: seenRep,
        orc_valor: 0,
        valor: null,
      };

      auxObj.orc_valor += this.rubricasTotais['total-zona-' + i];

      // if (this.editRubricas) {

      //   let seenRubricas = '';
      //   fraccoes.filter(el => (el.cod_zona === zona.cod)).forEach((fraccao, i) => {

      //     if (seenRubricas.indexOf(fraccao.cod) === -1) {
      //       seenRubricas += fraccao.cod + '/';
      //       auxObj.fr_valor += Number(fraccao.quota_fr);
      //     }

      //   });
      // } else {
        fraccoes_fr.filter(el => (el.cod_zona === zona.cod)).forEach(fraccao => {
          auxObj.fr_valor += Number(fraccao.valor);
        });
      // }

      auxObj.valor = auxObj.orc_valor + auxObj.fr_valor;

      this.orcamentoDataTable.push(auxObj);
    });

    // this.computeOrcamentoTotal();

    this.getSimulacaoDatatable();
  }
  getSimulacaoDatatable() {
    this.simulacaoList = this.getSimObject();

    if (this.isVistaMensalSimulacao) this.vistaMensalToggle('simulacao');
    this.computeSimulacaoTotal();
  }
  vistaMensalToggle(targetList) {
    // TODO: SHOW ALERT IF MESES COBRANCA NOT DEFINED!!!
    let nMesesFR = this.cobrancaFR.filter(el => (el.sel)).length;
    let nMesesOrcamento = this.cobrancaOrcamento.filter(el => (el.sel)).length;

    switch (targetList) {
      case 'simulacao':
        if (this.simulacaoList.length === 0) return;

        if (this.isVistaMensalSimulacao) {
          this.simulacaoList.forEach(el => {
            el.quota_orc = Math.round((el.quota_orc_anual / nMesesOrcamento) * 100) / 100;
            el.quota_fr = Math.round((el.quota_fr_anual / nMesesOrcamento) * 100) / 100;
            el.quota_seg = Math.round((el.quota_seg_anual / nMesesOrcamento) * 100) / 100;
            el.total = Math.round((el.quota_orc + el.quota_fr + el.quota_seg) * 100) / 100;
          });
        } else {
          this.simulacaoList.forEach(el => {
            // el.quota_orc = Math.round((el.quota_orc * nMesesOrcamento) * 100) / 100;
            // el.quota_fr = Math.round((el.quota_fr * nMesesOrcamento) * 100) / 100;
            // el.quota_seg = Math.round((el.quota_seg * nMesesOrcamento) * 100) / 100;
            // el.total = Math.round((el.quota_orc + el.quota_fr + el.quota_seg) * 100) / 100;

            el.quota_orc = (el.quota_orc_anual);
            el.quota_fr = (el.quota_fr_anual);
            // el.quota_seg = this.fraccoes.find(it => (it.cod === el.cod)).seg_premio_anual;
            el.quota_seg = (el.quota_seg_anual);
            el.total = (el.quota_orc + el.quota_fr + el.quota_seg);
          });
        }

        this.computeSimulacaoTotal();
        break;
    }
  }
  computeSimulacaoTotal() {
    this.simulacaoTotais.permilagem = 0;
    this.simulacaoTotais.orcamento = 0;
    this.simulacaoTotais.fundoReserva = 0;
    this.simulacaoTotais.seguro = 0;
    this.simulacaoTotais.total = 0;

    this.simulacaoList.forEach(fraccao => {
      this.simulacaoTotais.permilagem += fraccao.permilagem;
      this.simulacaoTotais.fundoReserva += fraccao.quota_fr;
      this.simulacaoTotais.orcamento += fraccao.quota_orc;
      this.simulacaoTotais.seguro += fraccao.quota_seg;
      this.simulacaoTotais.total += fraccao.total;
    });
  }
  getSimObject() {
    let simulation = [];
    let seenRubricas = '';

    this.orcamentoObj.fraccoes.sort((a, b) => { if ( a.cod !== null  && b.cod !== null && (a.cod > b.cod || a.cod.length > b.cod.length) ) { return 1; } else { return -1; } }).forEach(fraccao => {
      if (seenRubricas.indexOf(fraccao.cod) === -1) {
        seenRubricas += fraccao.cod + '/';

        let fraccaoFR = this.orcamentoObj.fraccoes_fr.find(el => (el.cod_fraccao === fraccao.cod_fraccao));
        // if (this.editRubricas) {
        //   let aux = this.orcamentoObj.fraccoes.find(el => (el.cod_fraccao === fraccao.cod_fraccao));
        //   fraccaoFR['valor'] = aux['quota_fr'];
        // }

        let zona = this.zonas.find(el => (el.cod === fraccao.cod_zona));

        simulation.push({
          id: fraccao.id,
          fraccao_id: fraccao.fraccao_id,
          cod: fraccao.cod,
          cod_zona: fraccao.cod_zona,
          cod_proprietario: fraccao.cod_proprietario,
          nome: fraccao.nome,
          zona_nome: (zona) ? zona.nome : null,
          permilagem: Number(fraccao.permilagem),

          quota_fr: (fraccaoFR) ? Number(fraccaoFR['valor']) : 0,
          quota_orc: Number(fraccao.valor),
          quota_seg: 0,
        });
      } else {
        let index = simulation.findIndex(it => (it.fraccao_id === fraccao.fraccao_id));
        if (index >= 0) {
          simulation[index].quota_orc += Number(fraccao.valor);
          simulation[index].total += Number(fraccao.valor);
        }
      }
    });

    simulation.forEach(el => {
      el.quota_seg = (this.orcFraccoesSeg.find(it => (it.cod_fraccao === el.cod && it.paga_seg_colect === '1'))) ? this.orcFraccoesSeg.find(it => (it.cod_fraccao === el.cod && it.paga_seg_colect === '1')).quota_seg_anual : 0;
      el.total = el.quota_orc + el.quota_fr + el.quota_seg;

      el.quota_fr_anual = el.quota_fr;
      el.quota_orc_anual = el.quota_orc;
      el.quota_seg_anual = el.quota_seg;
    });

    // CHECK IF ALL FRACCOES ARE AVAILABLE
    let hasAllFraccoes = true;
    for (let i = 0; i < this.orcamentoObj.fraccoes_fr.length; i++) {
      if (!this.orcamentoObj.fraccoes.find(el => (el.cod === this.orcamentoObj.fraccoes_fr[i].cod_fraccao))) {
        hasAllFraccoes = false;
        break;
      } 
    }


    // if (this.firstCallSim && !hasAllFraccoes) {
    //   this.firstCallSim = false;

    //   this.action(this.rubricasList[0], 'rubricas-edit', null, false);
    //   setTimeout(() => { 
    //     this.formSubmitted('rubricas-add', '', false, true);
    //   }, 1);
    // }

    return simulation.sort((a, b) => {
      if ( a.cod !== null && b.cod !== null && (a.cod > b.cod || a.cod.length > b.cod.length) ) {
        return 1;
      } else {
        return -1;
      }
    });
  }
  async setInitRubricasList(codCondominio) {
    return new Promise((resolve, reject) => {

      this.api.getCondZonasDetails(codCondominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          // CREATE RUBRICAS TABLE COLUMN OBJECT
          this.zonas = res.data.sort((a, b) => {
            if (Number(a.permilagem) < Number(b.permilagem)) {
              return 1;
            } else {
              return -1;
            }
          });
  
          this.rubricasListCol = [];
          this.zonas.forEach(el => {
            el['checked'] = true;
  
            this.rubricasListCol.push({
              key: 'null',
              name: el.nome,
              type: 'number',
              sort: null,
              searchable: false,
              data: el
            });
          });
  
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });

    });
  }
  async setInitFraccoesList(codCondominio) {
    return new Promise((resolve, reject) => {
      this.api.getOrcCondFraccoesDetails(codCondominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.fraccoes = res.data;
  
          this.fraccoesListCol = [];
          this.fraccoes.forEach(el => {
            this.fraccoesListCol.push({
              key: 'null',
              name: el.nome,
              type: 'number',
              sort: null,
              searchable: false,
              data: el
            });
          });

          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(true);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(true);
      });

    });
  }

  simulacaoTotaisPDF = {
    permilagem: 0,
    orcamento: 0,
    fundoReserva: 0,
    seguro: 0,
    total: 0
  }
  generateQuotasList() {
    let nMesesFR = this.cobrancaFR.filter(el => (el.sel)).length;
    let nMesesORC = this.cobrancaOrcamento.filter(el => (el.sel)).length;

    this.simulacaoListToPrint = [];

    let prevZona = null;
    let valor_total_zona_perm = 0;
    let valor_total_zona_orc = 0; 
    let valor_total_zona_fr = 0; 
    let valor_total_zona_seg = 0; 
    let valor_total_zona_total = 0;

    this.simulacaoTotaisPDF = {
      permilagem: 0,
      orcamento: 0,
      fundoReserva: 0,
      seguro: 0,
      total: 0
    }

    let simulacaoList = JSON.parse(JSON.stringify(this.simulacaoList));
    simulacaoList.sort((a, b) => {
      if (a.cod_zona < b.cod_zona) {
        return -1;
      } else {
        return 1;
      }
    });

    simulacaoList.forEach((el, i) => {
      el['separator'] = false;
      el['subTotal'] = false;

      // TABLE ROW TOTAL AND SEPARATOR
      if (prevZona !== el['cod_zona']) {
        if (i > 0) {
          this.simulacaoListToPrint.push({ label: 'Subtotal', perm: valor_total_zona_perm, orc: valor_total_zona_orc, fr: valor_total_zona_fr, seg: valor_total_zona_seg, quota: valor_total_zona_total, separator: false, subTotal: true });
        }
        this.simulacaoListToPrint.push({ label: el.zona_nome, separator: true, subTotal: false });
        valor_total_zona_perm = 0;
        valor_total_zona_orc = 0; 
        valor_total_zona_fr = 0; 
        valor_total_zona_seg = 0; 
        valor_total_zona_total = 0;
      }
      prevZona = el['cod_zona'];
      valor_total_zona_perm += Number(el.permilagem);
      valor_total_zona_orc += Number(el.quota_orc);
      valor_total_zona_fr += Number(el.quota_fr);
      valor_total_zona_seg += Number(el.quota_seg);
      valor_total_zona_total += Number(el.total);

      this.simulacaoListToPrint.push(el);
    });

    if (this.simulacaoListToPrint.length > 0) {
      this.simulacaoListToPrint.push({ label: 'Subtotal', perm: valor_total_zona_perm, orc: valor_total_zona_orc, fr: valor_total_zona_fr, seg: valor_total_zona_seg, quota: valor_total_zona_total, separator: false, subTotal: true });

      this.simulacaoListToPrint.forEach(el => {
        if (el.hasOwnProperty('label') && !el.separator) {
          el.orc = (this.isVistaMensalSimulacao) ? el.orc : el.orc / nMesesORC; 
          el.fr = (this.isVistaMensalSimulacao) ? el.fr : el.fr / nMesesORC; 
          el.quota = (this.isVistaMensalSimulacao) ? el.quota : el.quota / nMesesORC;

          this.simulacaoTotaisPDF.permilagem += el.perm;
          this.simulacaoTotaisPDF.orcamento += el.orc;
          this.simulacaoTotaisPDF.fundoReserva += el.fr;
          this.simulacaoTotaisPDF.seguro += el.seg;
          this.simulacaoTotaisPDF.total += el.quota;
        } else {
          el.quota_orc = (this.isVistaMensalSimulacao) ? el.quota_orc : el.quota_orc / nMesesORC; 
          el.quota_fr = (this.isVistaMensalSimulacao) ? el.quota_fr : el.quota_fr / nMesesORC; 
          el.total = (this.isVistaMensalSimulacao) ? el.total : el.total / nMesesORC;
        }
      });

    }
  }
  generateRubricasOrcList() {
    let nMesesFR = this.cobrancaFR.filter(el => (el.sel)).length;
    let nMesesORC = this.cobrancaOrcamento.filter(el => (el.sel)).length;

    // RUBRICAS DO ORCAMENTO
    this.rubricasOrcListToPrint = [];
    let auxRubrica = {
      zona_nome: null,
      rubrica_nome: null,
      valor: null,
    };
    this.rubricasOrcTotais.valor = 0;

    this.zonas.forEach((zona, i) => {
      this.rubricasList.filter(el => (el['cod-zona-' + i] === zona.cod)).forEach(rubrica => {
        auxRubrica = {
          zona_nome: zona.nome,
          rubrica_nome: rubrica['nome'],
          valor: rubrica['valor-zona-' + i],
        };
        if (Number(auxRubrica.valor !== 0)) this.rubricasOrcListToPrint.push(auxRubrica);
      });
    });

    this.rubricasOrcListToPrint.forEach(el => {
      this.rubricasOrcTotais.valor += el.valor;
    });

    let prevZona = null;
    let valor_total_zona = 0;

    let auxArray = [];
    this.rubricasOrcListToPrint.forEach((el, i) => {
      el['separator'] = false;
      el['subTotal'] = false;

      // TABLE ROW TOTAL AND SEPARATOR
      if (prevZona !== el['zona_nome']) {
        if (i > 0) {
          auxArray.push({ label: 'Subtotal', valor: valor_total_zona, separator: false, subTotal: true });
        }
        auxArray.push({ label: el.zona_nome, separator: true, subTotal: false });
        valor_total_zona = 0;
      }
      prevZona = el['zona_nome'];
      valor_total_zona += Number(el.valor);

      auxArray.push(el);
    });
    if (auxArray.length > 0) {
      auxArray.push({ label: 'Subtotal', valor: valor_total_zona, separator: false, subTotal: true });
    }
    this.rubricasOrcListToPrint = auxArray;

    // RESUMO ORCAMENTO - ZONAS
    this.zonasOrcListToPrint = [];

    prevZona = null;
    let prevZonaNome = null;
    let valor_total_zona_orc = 0; 
    let valor_total_zona_fr = 0; 
    let valor_total_zona_seg = 0; 
    let valor_total_zona_total = 0;

    let aux = {};

    this.zonasOrcTotais = {
      orc: 0,
      fr: 0,
      seg: 0,
      quota: 0
    };

    this.orcamentoDataTable.forEach(el => {
      valor_total_zona_orc = el.orc_valor; 
      valor_total_zona_fr = el.fr_valor;
      
      valor_total_zona_seg = 0;

      this.simulacaoList.filter(it => it.cod_zona === el.cod).forEach(el => {
        valor_total_zona_seg += Number(el.quota_seg_anual);
      });

      valor_total_zona_total = el.valor + valor_total_zona_seg;

      aux = {
        zona_nome: el.nome,
        orc: (!this.isVistaMensalOrcamento) ? valor_total_zona_orc * nMesesORC : valor_total_zona_orc,
        fr: (!this.isVistaMensalOrcamento) ? valor_total_zona_fr * nMesesORC : valor_total_zona_fr,
        seg: valor_total_zona_seg,
        quota: (!this.isVistaMensalOrcamento) ? valor_total_zona_total * nMesesORC : valor_total_zona_total,
      }

      this.zonasOrcListToPrint.push(aux);

      this.zonasOrcTotais.orc += Number(valor_total_zona_orc); 
      this.zonasOrcTotais.fr += Number(valor_total_zona_fr); 
      this.zonasOrcTotais.seg += Number(valor_total_zona_seg); 
      this.zonasOrcTotais.quota += Number(valor_total_zona_total);
    });

    if (this.zonasOrcListToPrint.length > 0) {
      this.zonasOrcTotais.orc = (!this.isVistaMensalOrcamento) ? this.zonasOrcTotais.orc * nMesesORC : this.zonasOrcTotais.orc; 
      this.zonasOrcTotais.fr = (!this.isVistaMensalOrcamento) ? this.zonasOrcTotais.fr * nMesesORC : this.zonasOrcTotais.fr; 
      this.zonasOrcTotais.seg = (!this.isVistaMensalOrcamento) ? this.zonasOrcTotais.seg * nMesesORC : this.zonasOrcTotais.seg; 
      this.zonasOrcTotais.quota = (!this.isVistaMensalOrcamento) ? this.zonasOrcTotais.quota * nMesesORC : this.zonasOrcTotais.quota;
    }
    
  }


  // CONTAS CORRENTES - METHODS AND VARIABLES
  contaCorrenteReportListColPDF = [
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left padding-left' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right valor-col' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right valor-col' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right valor-col' },
  ];
  contasCorrentes = [];
  totalContaCorrente = {
    debito: 0,
    credito: 0,
    saldo: 0,
  }
  ccSubs = null;
  getContasCorrentes(endDate) {
    return new Promise(resolve => {
      let req = [];
      this.fraccoesList.forEach(fraccao => {
        req.push(this.api.getContaCorrenteResumo('condominos', this.selCondominio.cod, [fraccao.cod_fraccao], null, endDate, 0));
      });
    
      this.ccSubs = forkJoin(req).subscribe(res => {
        if (res[0].hasOwnProperty('success') && res[0].success) {
          let contasCorrentes = [];
          res.forEach(el => { contasCorrentes = contasCorrentes.concat(el.data); });
  
          let datasetAux = [];
          contasCorrentes.forEach(elem => {
            if ( (Number(elem.debito) === 0 && Number(elem.saldo) === 0) ) {

              let entries = contasCorrentes.filter(elem2 => (elem.cod_fraccao === elem2.cod_fraccao));
              if (entries && entries.length === 1) {
                datasetAux.push(elem);
              }
              
            } else {
              datasetAux.push(elem);
            }
          });
          this.generateReportResmuseListObj(JSON.parse(JSON.stringify(datasetAux)), 'condominos');

          resolve(true);
        }
      }, err => {
        resolve([]);
      });
    });
  }
  generateReportResmuseListObj(data, tipoAgrupamento=null) {
    this.contasCorrentes = [];

    this.totalContaCorrente['debito'] = 0;
    this.totalContaCorrente['credito'] = 0;
    this.totalContaCorrente['saldo'] = 0;    

    let debito_total_zona = 0;
    let credito_total_zona = 0;
    let saldo_total_zona = 0;

    let prevZona = null;

    let continu = true;

    data.forEach((el, i) => {
      continu = true;

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        fraccao: (el['cod_fraccao'] && el['fraccao_nome']) ? el['cod_fraccao'] + ' - ' + el['fraccao_nome'] : null,
        debito: null,
        credito: null,
        saldo: null,
        cod_fraccao: el['cod_fraccao'],
      }

      aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
      aux['saldo'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
      aux['credito'] = aux.debito - aux.saldo;

      if (tipoAgrupamento === 'condominos') {
        let isActProp = (this.fraccoesList.find(it => (it.condomino_nome === el['condomino_nome'] && it.cod_fraccao === el['cod_fraccao']))) ? true : false;

        if (data.filter(it => it.cod_fraccao === el['cod_fraccao']).length >= 2) {
          if (!isActProp && aux['saldo'] === 0) continu = true;

          aux['fraccao'] += (' / ' + el['condomino_nome'] + ((!isActProp) ? '*' : ''));
        } else {
          aux['fraccao'] += (' / ' + el['condomino_nome']);
        }

      }

      if (continu) {
        // TABLE ROW TOTAL AND SEPARATOR
        if (prevZona !== el['zona_cod'] && el['zona_cod'] !== null && prevZona !== null) {
          if (i > 0) {
            this.contasCorrentes.push({ label: 'Total da zona', debito: debito_total_zona, credito: credito_total_zona, saldo: saldo_total_zona, separator: false, separatorCond: true, total: true });
          }

          // ZONA SEPARATOR
          this.contasCorrentes.push({ label: `${el['zona_nome']}`, separator: true, separatorCond: false, total: false });
          debito_total_zona = 0;
          credito_total_zona = 0;
          saldo_total_zona = 0;
        }
        prevZona = el['zona_cod'];

        debito_total_zona += aux.debito;
        credito_total_zona += aux.credito;
        saldo_total_zona += aux.saldo;

        this.contasCorrentes.push(aux);

        this.totalContaCorrente['debito'] += aux.debito;
        this.totalContaCorrente['credito'] += aux.credito;
        this.totalContaCorrente['saldo'] += aux.saldo;
      }

    });
    if (this.contasCorrentes.length > 0) {
      this.contasCorrentes.push({ label: 'Total da zona', debito: debito_total_zona, credito: credito_total_zona, saldo: saldo_total_zona, separator: false, separatorCond: false, total: true });
    }

    let aux = this.contasCorrentes.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1);
    let auxSet = [...new Set(aux.map(el => el.cod_fraccao))];
    auxSet.forEach(cod_fraccao => {
      let len2 = this.contasCorrentes.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') === -1 && el.cod_fraccao === cod_fraccao).length;
      if (!len2) {
        let aux1 = this.contasCorrentes.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1 && el.cod_fraccao === cod_fraccao);
        aux1.forEach((el, i) => {
          if (i > 0) this.contasCorrentes = this.contasCorrentes.filter(it => it.fraccao !== el.fraccao);
        });
      } else {
        let aux = this.contasCorrentes.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1 && el.cod_fraccao === cod_fraccao);
        aux.forEach(el => {
          this.contasCorrentes = this.contasCorrentes.filter(it => it.fraccao !== el.fraccao);
        });
      }
    });

    // FILTER AND ORDER PREV CONDOMINO
    this.contasCorrentes.forEach(el => {
      if (el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1) {
        let fraccoesAux = this.contasCorrentes.filter(it => (it.cod_fraccao === el.cod_fraccao));
        if (fraccoesAux .length < 2) el.fraccao = el.fraccao.replace('*', '');
      }
    });
    this.contasCorrentes = this.contasCorrentes.filter(el => !(el.saldo === 0 && el.fraccao && el.fraccao.indexOf('*') !== -1));
  }

  // MOVIMENTOS CAIXA E BANCOS - METHODS AND VARIABLES
  reportListColPDF = [
    { key: 'dt_mov', name: 'Data', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered mov-date-report-col' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'mov-saldo-col col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'number', sort: null, searchable: true, centered: false, class: 'mov-saldo-col col-align-right' },
  ];
  movSubs = null;
  getMovimentos(codConta, startDate, endDate) {
    return new Promise(resolve => {
      let req = [
        this.api.getMovimentos(codConta, startDate, endDate, null, null, null, null, 'ASC'),
        this.api.getSaldoMovimentos(codConta, (endDate) ? endDate : null),
      ];
      this.movSubs = forkJoin(req).subscribe(res => {
        let movimentosList = [];

        let totalVisibleList = 0;
        if (res[0].data) {
          res[0].data.forEach((el, i) => {
            totalVisibleList += Number(el.valor);

            el['dt_mov'] = (el['dt_mov']) ? this.utils.getDate(el['dt_mov']) : null;

            movimentosList.push(el);
          })
        }

        let valorTotal = Number(res[1].data.saldo);

        movimentosList = [{
          dt_mov: null,
          descricao: 'SALDO INICIAL',
          valor: valorTotal - totalVisibleList,
          saldo: valorTotal - totalVisibleList,
        }].concat(movimentosList);

        let total = 0;
        movimentosList.forEach(el => {
          total += Number(el.valor);

          el['saldo'] = total;
        });

        resolve({ total: total, mov: movimentosList });
      },
      err => {
        resolve([]);
      });
    });
  }
  // END - FECHO DE CONTAS ----------------------------------------------------

  // START - RELATORIOS DE ASSEMBLEIA --------------------------------------------------------------
  selectedAssembleia = null;
  inputRelatorioAssembleia = null;
  inputListaPresencasAssembleia = null;


  @ViewChild('assembleiasSelectRef', { static: false }) assembleiasSelectRef;
  assembleiasSelectModal = null;
  assembleiasSelectModalConfig: any = null;

  assembleiaOpts = [];
  assembleiaSel = null;

  presentAssembleiasSelecModal() {
    return new Promise(resolve => {
      this.assembleiasSelectModal = this.modalService
        .open(this.assembleiasSelectModalConfig)
        .onApprove(() => {
          resolve(this.assembleiaSel);
        })
        .onDeny(() => { 
          resolve(false);
        });
    });
  }

  @ViewChild('relatorioAssembleia', { static: false }) relatorioAssembleia;
  getRelatorioAssembleia() {
    this.relatorioAssembleia.generatePdf();
  }

  getAssembleias(codCondominio, exercicio) {
    return new Promise((resolve, reject) => {
      let req = [
        this.api.getAssembleiasList(codCondominio, exercicio),
        this.api.getFraccoesCircular(codCondominio),
      ];
      forkJoin(req).subscribe(res => {
        if (res[0].hasOwnProperty('success')) {
          let temp = null;

          let assembleias = res[0].data.sort((a, b) => {
            let dtA = this.utils.getDate(a.dt);
            let dtB = this.utils.getDate(b.dt);

            return dtB.getTime() - dtA.getTime();
          });

          let fraccoes = [];
          res[1].data = res[1].data.sort((a, b) => {
            return a.cod.length - b.cod.length || a.cod.localeCompare(b.cod);
          });
          res[1].data.forEach(el => {
            temp = {
              checked: false,
              cod_condominio: el.cod_condominio,
              cod_fraccao: el.cod,
              zona: el.zona_nome,
              cod_zona: el.cod_zona,
              fraccao: el.cod + ' - ' + el.fraccao_nome,
              proprietario: el.nome_proprietario,
              cod_proprietario: el.cod_proprietario,
              inquilino: el.nome_inquilino,
              morada_inquilino: (el.morada_inquilino) ? el.morada_inquilino.split(/NEW_LINE/g) : null,
              email_proprietario: el.email_proprietario,
              email_proprietario_2: el.email_proprietario_2,
              email_inquilino: el.email_inquilino,
              permilagem: Number(el.permilagem),
              por_carta: (el.por_carta === '1'),
              por_email: (el.por_email === '1'),
              codEntidade: el.codEntidade,
            }
            fraccoes.push(temp);
          });

          resolve({
            assembleias: assembleias,
            fraccoes: fraccoes,
          });
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
  }

  @ViewChild('listaPresencasAssembleia', { static: false }) listaPresencasAssembleia;
  getListaPresencasAssembleia() {
    this.listaPresencasAssembleia.generatePdf();
  }

 
  // END - RELATORIOS DE ASSEMBLEIA ----------------------------------------------------------------

  // START - PAGAMENTOS NÃO IDENTIFICADOS ----------------------------------------------------------
  inputPagNaoIdentificados = null;

  @ViewChild('pagNaoIdentificados', { static: false }) pagNaoIdentificados;
  getListagemPagNaoIdentificados(codCondominio, endDate) {
    return new Promise((resolve, reject) => {
      this.api.getPagNaoIdentificados(codCondominio, endDate).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          let temp = res.data.map(el => {
            el['dt_desp'] = (el['dt_desp']) ? this.utils.getDate(el['dt_desp']) : null;
            el['dt_pag'] = (el['dt_pag']) ? this.utils.getDate(el['dt_pag']) : null;
            el['valor'] = (el['valor']) ? Number(el['valor']) : null;
            el['checked'] = false;
            el['a_liquidar'] = (el['from_gecond'] === '1' && el['dt_pag']) ? 0 : el['valor'] - el['valor_liquidado'];
            el['status'] = (el['a_liquidar'] === 0) ? true : false;

            return el;
          });

          resolve(temp);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
  }
  // END - PAGAMENTOS NÃO IDENTIFICADOS ----------------------------------------------------------

  selectRelatorio(row) {
    if (row.disabled) return;

    row.checked = !row.checked;
  }

  // START - CHECK ORCAMENTOS VARIABLES AND METHODS -----------------------------------------------
  loadingCheckOrcamento = false;
  checkOrcamentos() {
    // 1 - GET LIST OF ALL ACTIVE CONDOMINIOS
    let orcamentos = [];
    let outputArr = [];
    this.loadingCheckOrcamento = true;
    this.api.getAllActiveCondominios().subscribe(async res => {
      // 2 - LOOP THROUGH EACH CONDOMINIO AND CHECK IF BUDGET IS OK
      let tempOrc = null;
      let tempOrcDetails = null;
      let tempOutput = null;
      for (let i = 0; i < res.data.length; i++) {
        console.log(`---------- ${i + 1} / ${res.data.length} ----------`);

        tempOrc = await this.getOrcamentoIdByCondominio(res.data[i].cod, Number(this.selExercicio) + 1);
        if (tempOrc) {
          tempOrcDetails = await this.getOrcamento(tempOrc.id);
          if (tempOrcDetails) orcamentos.push(tempOrcDetails);
        } else {
          tempOrcDetails = null;
        }

        let error = (tempOrcDetails) ? tempOrcDetails.simulacaoListToPrint.filter(el => el.hasOwnProperty('cod') && el.cod).length*0.01*12 : 0;

        tempOutput = {
          codCondominio: res.data[i].cod,
          nomeCondominio: res.data[i].nome,
          numOrcamento: tempOrc.cod,
          anoOrcamento: tempOrc.periodo,

          valorORCAno: (tempOrcDetails) ? tempOrcDetails.zonasOrcTotais.orc : 0,
          valorFCRAno: (tempOrcDetails) ? tempOrcDetails.zonasOrcTotais.fr : 0,
          valorSEGAno: (tempOrcDetails) ? tempOrcDetails.zonasOrcTotais.seg : 0,
          valorTotalAno: (tempOrcDetails) ? tempOrcDetails.zonasOrcTotais.quota : 0,

          valorORCMes: (tempOrcDetails) ? tempOrcDetails.simulacaoTotaisPDF.orcamento : 0,
          valorFCRMes: (tempOrcDetails) ? tempOrcDetails.simulacaoTotaisPDF.fundoReserva : 0,
          valorSEGMes: (tempOrcDetails) ? tempOrcDetails.simulacaoTotaisPDF.seguro : 0,
          valorTotalMes: (tempOrcDetails) ? tempOrcDetails.simulacaoTotaisPDF.total : 0,
        }

        tempOutput['erroORC'] = Math.round((tempOutput.valorORCAno - 12*tempOutput.valorORCMes) * 100) / 100;
        tempOutput['erroFCR'] = Math.round((tempOutput.valorFCRAno - 12*tempOutput.valorFCRMes) * 100) / 100;
        tempOutput['erroSEG'] = Math.round((tempOutput.valorSEGAno - 12*tempOutput.valorSEGMes) * 100) / 100;
        tempOutput['erroTotal'] = Math.round((tempOutput.valorTotalAno - 12*tempOutput.valorTotalMes) * 100) / 100;
        tempOutput['estado'] = (Math.abs(tempOutput['erroORC']) > error || Math.abs(tempOutput['erroFCR']) > error || Math.abs(tempOutput['erroSEG']) > error || Math.abs(tempOutput['erroTotal']) > error) ? 'ERROR' : 'OK';

        outputArr.push(tempOutput);
      }

      // CONSOLE LOG FEEDBACK
      console.log(`OUTPUT -> #ERROR: ${outputArr.filter(el => el.estado === 'ERROR').length} - #OK: ${outputArr.filter(el => el.estado === 'OK').length}`);
      console.log(JSON.stringify(outputArr));

      this.loadingCheckOrcamento = false;
    }, err => {
      this.loadingCheckOrcamento = false;
    });
  }
  
  getOrcamentoIdByCondominio(codCondominio, exercicio) {
    return new Promise((resolve, reject) => {
      this.api.getOrcamentosByCondominio(codCondominio, exercicio).subscribe(res => {
        resolve((res.data.length) ? { id: res.data[0].id, cod: res.data[0].cod, periodo: res.data[0].periodo } : false);
      }, err => {
        resolve(false);
      });
    });
  }

  codOrcamentoDev = null;
  errorDev = null;
  async fixOrcamentos() {
    if (!(this.codOrcamentoDev && this.errorDev)) return;

    let toFixList = [{
      codOrcamento: this.codOrcamentoDev,
      error: Number(this.errorDev),  // ERROR > 0: FCR -> ORC, ERROR < 0: ORC -> FCR
    }];
    for (let i = 0; i < toFixList.length; i++) {
      console.log(`---------- FIXING BUGET ${i + 1} / ${toFixList.length} ----------`);
      let res = await this.fixFraccaoOrcamento(toFixList[i]);
      console.log((res) ? ':: OK' : ':: ERROR');
    }
  }

  fixFraccaoOrcamento(toFix) {
    return new Promise((resolve, reject) => {
      // 1 - GET DATA FROM orc_fundo_reserva_fraccoes AND orc_fraccoes
      let req = [
        this.api.getFraccoesORC(toFix.codOrcamento),
        this.api.getFraccoesFCR(toFix.codOrcamento),
      ];
      forkJoin(req).subscribe(res => {
        // ORC
        let orcArr = [];
        if (res[0].success) {
          let temp = [...new Set(res[0].data.map(el => el.cod_fraccao))];
          temp.forEach(el => {
            orcArr.push({
              codFraccao: el,
              rubricas: res[0].data.filter(it => it.cod_fraccao === el).map(it => { let temp = { id: it.id, valor: Number(it.valor), toUpdate: false }; return temp; }).sort((a, b) => { return b - a; }),
              id: null,
            });
          });
        }
        console.log('ORC: ', orcArr);
  
        // FCR
        let fcrArr = [];
        if (res[1].success) {
          fcrArr = res[1].data.map(el => {
            let temp = {
              id: el.id,
              codFraccao: el.cod_fraccao,
              valor: Number(el.valor),
              toUpdate: false
            };
  
            return temp;
          });
        }
        console.log('FCR: ', fcrArr);
  
        if (orcArr.length && fcrArr.length && orcArr.length === fcrArr.length) {
          // COMPUTE NEW VALUES
          let valPerFraccao = toFix.error / fcrArr.length;
          orcArr.forEach((frac, i) => {
            let newFcrValue = fcrArr[i].valor - valPerFraccao;
            // let newFcrValue = (toFix.error > 0) ? fcrArr[i].valor - valPerFraccao : fcrArr[i].valor + valPerFraccao;
            if (newFcrValue >= 0) {
              fcrArr[i].toUpdate = true;
              fcrArr[i].valor = newFcrValue;
            }
            for (let n = 0; n < frac.rubricas.length; n++) {
              let newOrcValue = frac.rubricas[n].valor + valPerFraccao;
              // let newOrcValue = (toFix.error > 0) ? frac.rubricas[n].valor + valPerFraccao : frac.rubricas[n].valor - valPerFraccao;
              if (newOrcValue >= 0) {
                frac.toUpdate = true;
                frac.valor = newOrcValue;
                frac.id = frac.rubricas[n].id;
                break;
              }
            }
          });
  
          // CHECK IF ARRAY IS OK TO UPDATE THIS BUDGET
          let toUpdateOrc = orcArr.filter(el => el.toUpdate);
          let toUpdateFrc = fcrArr.filter(el => el.toUpdate);
          if (toUpdateOrc.length === toUpdateFrc.length) {
            let toUpdate = fcrArr.map(el => {
              let aux = toUpdateOrc.find(it => it.codFraccao === el.codFraccao);
              (aux && aux.rubricas.find(it => it.toUpdate)) ? aux['valor'] = aux.rubricas.find(it => it.toUpdate).valor : null;

              let temp = {
                orcId: (aux) ? aux.id : null,
                orcValor: (aux) ? aux.valor : null,

                fcrId: el.id,
                fcrValor: el.valor,
              }
              return temp;
            });

            // console.log(toUpdate);
            // resolve(true);
            // return;

            this.api.fixOrcamento(toUpdate).subscribe(res => {
              console.log(':: RESPONSE');
              console.log(res);
              resolve(true);
            }, err => {
              console.error(err);
              resolve(false);
            });
          } else {
            console.error(`:: Invalid toUpdateOrc and toUpdateFrc length.`);
            resolve(false);
          }
        } else {
          console.error(`:: Invalid orcArr and fcrArr length.`);
          resolve(false);
        }
      }, err => {
        console.error(err);
        resolve(false);
      });
    });
  }
  // END - CHECK ORCAMENTOS VARIABLES AND METHODS -------------------------------------------------

  @ViewChild('reconciliacaoBancaria', { static: false }) reconciliacaoBancaria;
  getReconciliacaoBancaria(): void {
  
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.loading = true;
    this.cdRef.detectChanges();
    if (this.selTipoDoc === 'csv') {
      this.reconciliacaoBancaria.generateXLS().then(_ => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        
        this.loading = false;
        this.filterCriterio = 'PREDIO';
        this.filterOrdem = 'ASC';
      }).catch(err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        
        this.loading = false;
        this.filterCriterio = 'PREDIO';
        this.filterOrdem = 'ASC';
      });
    } else {
      this.reconciliacaoBancaria.generatePdf().then(_ => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        
        this.filterCriterio = 'PREDIO';
        this.filterOrdem = 'ASC';
        this.loading = false;
      }).catch(err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.loading = false;
        this.filterCriterio = 'PREDIO';
        this.filterOrdem = 'ASC';
      });
    }
  }


  openReconcBancariaModal(): void {
    this.reconcBancariaModalRef = this.modalService
      .open(this.reconcBancariaAlertConfig)
      .onApprove(() => {
        this.getReconciliacaoBancaria();
      })
      .onDeny(() => {
        this.filterCriterio = 'PREDIO';
        this.filterOrdem = 'ASC';
      });
  }

  @ViewChild('recibosAnulados', { static: false }) recibosAnulados;
  getRecibosAnulados(): void {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.loading = true;
    this.recibosAnulados.generatePdf().then(_ => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.loading = false;
      
      this.recibosAnulados.clearData();
    }).catch(_ => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.loading = false;

      setTimeout(() => {this.recibosAnulados.clearData();});
    });
  }

  openRecibosDespesasModal(): void {
    this.recibosDespesasModalRef = this.modalService
      .open(this.recibosDespesasAlertConfig)
      .onApprove(() => {
        this.getListRecibosDespesas();
      })
      .onDeny(() => {
        this.utilizadores = [{name: 'Todos', value: 'all'}];
        this.selectedUtilizadores = ['all'];
      });
  }
  
  @ViewChild('listagemRecibos', { static: false }) listagemRecibos;
  @ViewChild('listagemDespesas', { static: false }) listagemDespesas;

  getListRecibosDespesas(): void {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.loading = true;

    let promiseData = 
      [this.listagemRecibos.updateData(),
      this.listagemDespesas.updateData()];

    Promise.all(promiseData).then(_ => {

      let promises = 
        [this.listagemRecibos.export(),
        this.listagemDespesas.export()];

      Promise.all(promises).then(async groups => {
        const rootGroup = new Group({ pdf: { multiPage: true } });
        groups.forEach((group) => {
            rootGroup.append(...group.children);
        });
        
        return exportPDF(rootGroup, { paperSize: 'A4' });
      }).then(async dataUri => {
        saveAs(dataUri, 'Relatório Listagem Recibos e Despesas.pdf',{
          proxyURL: this.appConfig.fileProxyUrl,
          forceProxy: true,
          proxyTarget: '_blank',
        });

        this.listagemRecibos.clearData();
        this.listagemDespesas.clearData();
        
        this.utilizadores = [{name: 'Todos', value: 'all'}];
        this.selectedUtilizadores = ['all'];
        this.utilizadores = this.availableUtilizadores;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.loading = false;
      }).catch(err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.loading = false;
      });
    }).catch(err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.loading = false;
    });
  }

  changedUtilizadores(): void {
    if (this.selectedUtilizadores.find(el => el === 'all')) {
      this.utilizadores = [{name: 'Todos', value: 'all'}];
      this.selectedUtilizadores = ['all'];
    } else {
      this.utilizadores = this.availableUtilizadores;
    }
      
  }
}


