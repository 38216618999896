import { Component, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { AppConfigService } from '../app-config.service';
import { ProcessamentosService } from '../business-logic-services/processamentos.service';
import { IContext } from '../business-model-interfaces/application';
import { DelType } from '../business-model-interfaces/processamentos';
import { Recibo } from '../business-model-interfaces/recibo';

@Component({
  selector: 'app-processamento-delete-recibos',
  templateUrl: './processamento-delete-recibos.component.html',
  styleUrls: ['./processamento-delete-recibos.component.scss']
})
export class ProcessamentoDeleteRecibosComponent implements OnInit {

  recibosToDelete:Array<Recibo> = [];
  procsToDelete:Array<{id, cod}> = [];

  constructor(
    public processamentos: ProcessamentosService,
    public modalService: SuiModalService,
    public appConfig: AppConfigService,
  ) { }

  ngOnInit() {
  }

  
  ngAfterViewInit() {
    this.modalConfig = new TemplateModalConfig<IContext, string, string>(this.alertRef);
    this.modalConfig.closeResult = "closed";
    this.modalConfig.size = 'normal';
    this.modalConfig.transition = 'fade';
    this.modalConfig.transitionDuration = 250;
  }

  @ViewChild('alertRef', { static: false }) alertRef;
  modalRef = null;
  modalConfig: any = null;
  
  loadingModal = false;
  delType: DelType = 'DELETE';
  msg:string = null;
  deleteNow: boolean = null;
  
  open(procs:Array<{id, cod}>, delType:DelType = 'DELETE', msg=null, deleteNow = true): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.procsToDelete = procs;
      this.delType = delType; 
      this.msg = msg; 
      this.deleteNow = deleteNow;
      try {
        this.recibosToDelete = await this.processamentos.getRecibos(procs.map(el => el.cod));
      } catch (err) {
        resolve(false);
        return;
      }

      this.modalConfig.size = (this.recibosToDelete.length > 0 || this.delType === 'REVERT') ? 'normal' : 'mini';
      this.modalRef = this.modalService
        .open(this.modalConfig)
        .onApprove(() => { 
          resolve(true) 
        })
        .onDeny(() => { 
          resolve(false)
         });
    });
  }

  async delete() {
    if (!this.deleteNow) {
      this.modalRef.approve();
      return;
    }
    this.loadingModal = true;
    let success = await this.processamentos.delete(this.procsToDelete.map(el => el.id), this.delType);
    this.loadingModal = false;
    if (success) {
      this.modalRef.approve();
    }
  }

}
