import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener, destroyPlatform  } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { TransitionController, Transition, TransitionDirection, SuiSelectModule } from "ng2-semantic-ui";
import { fromEvent, forkJoin, Subscription } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
import { AnexoUpload, contentType, fileExt } from '../business-model-interfaces/anexo';


import { UnsavedChangesModalComponent } from '../unsaved-changes-modal/unsaved-changes-modal.component';
interface IContext {
  data:string;
}

interface datas_lancamento {
  data:Date, 
  form_pagamento:string, 
  cod_conta_bancaria:string,
  forma_agendamento:'L'|'LP'
}

interface despesa_agendamento {
  active:'0'|'1',
  from_gecond:'0'|'1',
  valor,
  descricao:string,
  valor_liquidado,
  valor_por_liquidar,
  movimentos:Array<any>,
  obj,
  nome_fornecedor:string,
  id,
  cod,
  n_despesa,
  dt_pag:string,
  dt_desp:string,
  dt_desp_orig:string,
  form_pagam,
  form_pagam_agendamento
  cod_conta_bancaria,
  cod_conta_bancaria_agendamento,
  dt_pag_agendamento,
}

interface AgendDate {
    cod:string,
    dt_desp: Date,
    dt_desp_orig: Date,
    obj: string,
    descricao: string,
    dt_pag: Date,
    dt_pag_agendamento: Date,
    paymentMethods: Array<string>,
    isLancado: boolean,
    status: string,
    checked: boolean,
    id: string,
    n_despesa: string,
    monthDesc: string,
    movimentos: Array<any>,
    autoPay: boolean,
    cod_conta_bancaria: string,
    cod_conta_bancaria_agendamento: string,
    form_pagam: string,
    form_pagam_agendamento: string,
    aberta: boolean,
    valor: number,
    valor_por_liquidar: number,
    valor_liquidado: number,
    removed:boolean
}

import { exportPDF, Group } from '@progress/kendo-drawing';

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';

import { EditPayAgendamentoComponent } from '../edit-pay-agendamento/edit-pay-agendamento.component';
import { agendamentosSaveDespesaReq } from '../api-requests';
import { PreventLeaving } from '../prevent-leaving';
import { SuiSelect } from 'ng2-semantic-ui/dist';
import { PaymentModalComponent, PaymentForm, PaymentModalInput } from '../payment-modal/payment-modal.component';
import { Movimento } from '../business-model-interfaces/movimento';
import { BusinessLogicService } from '../business-logic.service';
import { MovimentosService } from '../business-logic-services/movimentos.service';
import { File } from '../business-model-interfaces/file';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { DespesasService } from '../business-logic-services/despesas.service';

@Component({
  selector: 'app-despesa-v2',
  templateUrl: './despesa-v2.component.html',
  styleUrls: ['./despesa-v2.component.scss']
})
export class DespesaV2Component implements OnInit, PreventLeaving {

  hasGoneBack = false;

  fetchDone = false;

  // TODO: SAVE IN DATABASE ---------------------------------------------------
  vertisCustos = {
    coefNEnvelope: 0.5,
    coefNFolhas: 0.2,
    custoFixo: 0.75,
  };
  correioMaterialPeso = [
    { type: 'PAPEL_NORMAL', label: 'Papel 75g', peso: 4.66 },  // GRAMAS
    { type: 'ENVELOPE', label: 'Envelope DL', peso: 6.00 },  // GRAMAS
  ];
  correioTabelaPrecos = [
    { 
      type: 'CORREIO_NORMAL',
      label: 'Correio Normal',
      priceArr: [
        { intInf: 0, intSup: 20, price: 0.53 },
        { intInf: 21, intSup: 50, price: 0.70 },
        { intInf: 51, intSup: 100, price: 0.85 },
        { intInf: 101, intSup: 500, price: 1.50 },
      ] 
    },
    { 
      type: 'CORREIO_REGISTADO',
      label: 'Correio Registado',
      priceArr: [
        { intInf: 0, intSup: 20, price: 2.20 },
        { intInf: 21, intSup: 50, price: 2.30 },
        { intInf: 51, intSup: 100, price: 2.55 },
      ] 
    },
    { 
      type: 'AVISO_RECECAO',
      label: 'Aviso Receção',
      priceArr: [
        { intInf: 0, intSup: 0, price: 1.15 },
      ] 
    },
    { 
      type: 'CARTAS_COBRANCA',
      label: 'Cartas de Cobrança',
      priceArr: [
        { type: 'CORREIO_NORMAL', label: 'Correio Normal' , price: 3.50 },
        { type: 'CORREIO_REGISTADO', label: 'Correio Registado' , price: 5.75 },
      ] 
    },
  ];
  correspRubricas = [{ name: 'Correio Normal', value: '98'}, { name: 'Correio Registado', value: '42'}, { name: 'Correio Simples', value: '47'}];
  correspFornecedores = [{ name: 'Ctt', value: {name: 'Ctt', value: { nome: 'Ctt', cod: '16' }}}, {name: 'Correspondência', value: {name: 'Correspondência', value: { nome: 'Correspondência', cod: '9' }}}];
  // END - TODO: SAVE IN DATABASE ---------------------------------------------

  @ViewChild('calcCorreioAlertRef', { static: false }) calcCorreioAlertRef;
  calcCorreioModalRef = null;
  calcCorreioAlertConfig: any = null;

  @ViewChild('condominiosAlertRef', { static: false }) condominiosAlertRef;
  condominiosModalRef = null;
  condominiosAlertConfig: any = null;

  @ViewChild('emailAlertRef', { static: false }) emailAlertRef;
  emailModalRef = null;
  emailAlertConfig: any = null;

  @ViewChild('agendamentoChangesAlertRef', { static: false }) agendamentoChangesAlertRef;
  agendamentoChangesModalRef = null;
  agendamentoChangesAlertConfig: any = null;

  @ViewChild('copyAgendAlertRef', { static: false }) copyAgendAlertRef;
  copyAgendModalRef = null;
  copyAgendAlertConfig: any = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('valorCttAlertRef', { static: false }) valorCttAlertRef;
  valorCttModalRef = null;
  valorCttAlertConfig: any = null;

  @ViewChild('updateMovAlertRef', { static: false }) updateMovAlertRef;
  updateMovModalRef = null;
  updateMovAlertConfig: any = null;

  @ViewChild('updateValorAgendAlertRef', { static: false }) updateValorAgendAlertRef;
  updateValorAgendModalRef = null;
  updateValorAgendAlertConfig: any = null;

  @ViewChild('changeDateAlertRef', { static: false }) changeDateAlertRef;
  changeDateModalRef = null;
  changeDateAlertConfig: TemplateModalConfig<IContext, string, string> = null;

  transitionController = new TransitionController();
  submittingModalForm = false;
  submittingForm = false;
  loading = false;
  loadingModal = false;
  searchable: boolean = true;

  condominioCod = null;

  condominioListToggle = true;

  comp = 'despesa';
  initState = null;
  prevState = null;

  // DESPESA FORM
  cod_condominio = null;
  dt_estado_agendamento:Date = null;
  estado_agendamento:'0'|'1' = null;
  n_despesa = null;
  exercicio = null;
  cod_rubrica = null;
  cod_sub_rubrica = null;
  cod_fornecedor = null;
  tipo_proc = 'E';
  cod_proc = null;
  dt_desp = new Date();
  dt_pag_valor = null;
  dt_pag = null;
  data_pagamento_despesa = null;
  dt_venc = null;
  valor = null;
  valor_pago = null;
  n_documento = null;
  form_pagam = null;
  cod_conta_bancaria = null;
  descricao = null;
  n_doc_pagam = null;
  ref_interna = null;
  nid_movimento = null;
  orcamentada = null;
  reparticao = 'PO';
  reparticaoDist = [];

  despesaId = null;
  despesaCod = null;
  despesaDate = null;
  despesaDetails = null;

  rubricasOptsOrig = [];
  rubricasOptsOrc = [];
  rubricasOpts = [];

  contaOptsOrig = [];
  contaOpts = [];
  
  processamentoOpts = [ 
    { name: 'Orçamento', value: 'O' },
    { name: 'Fundo Reserva', value: 'F' },
    { name: 'Seguro', value: 'S' },
    { name: 'Extraordinário', value: 'E' },
  ];
  
  reparticaoOpts = [ 
    { name: 'Proporcional Orçamento', value: 'PO' },
    { name: 'Proporcional', value: 'P' },
    { name: 'Equitativo', value: 'E' },
    { name: 'Manual', value: 'M' },
  ];

  forma_agendamento:'L'|'LP' = 'L';
  dataInicioPagamento = null;
  dt_valor = null;
  datas_valor:Array<{data:Date, valor:string}> = [];
  datas_lancamento:Array<datas_lancamento> = [];

  processamentosOrig = [];
  orcamentoId = null;

  isCreate = false;
  isCreateLote = false;
  isCreateAgendamento = false;
  isCreateCorrespondencia = false;

  // movimentos = [];
  pagamentoParcial = null;
  pagEmFalta = null;

  id_caixa = null;

  registoAct = [];

  // ZONAS TABLE
  zonaListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: true },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'number', sort: null, searchable: false, center: false },
    { key: 'permilagem', name: 'Perm.', type: 'number', sort: null, searchable: false, center: true },
    { key: 'n_fraccoes', name: '#Fracções', type: 'number', sort: null, searchable: false, center: true },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: false, center: false },
  ];
  zonaList: Array<any> = [];

  @ViewChild('condTableSearch', { static: false }) condTableSearch: ElementRef;
  condominioListCol = [
    { key: 'cod', name: 'Código', type: 'text', sort: null, searchable: true, class: 'two wide' },
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, class: 'thirteen wide' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class: 'table-checkbox-column one wide' },  // 'ASC', 'DESC'
  ];
  condominiosListOrig = [];  
  condominiosList = [];
  condSearching = false;

  months = ['Jan.','Fev.','Mar.','Abr.','Mai.','Jun.','Jul.','Ago.','Set.','Out.','Nov.','Dez.'];
  cobrancaFR = [false,false,false,false,false,false,false,false,false,false,false,false];
  cobrancaOrcamento = [false,false,false,false,false,false,false,false,false,false,false,false];
  selCobrancaFR = null;
  selCobrancaOrcamento = null;

  // startDateAgendamento = null;
  // endDateAgendamento = null;

  startDateAgendamento = new Date((new Date()).getFullYear(), 0, 30);
  endDateAgendamento = new Date((new Date()).getFullYear(), 11, 30);

  periodicityOpts = [];

  calcManualMode = false;
  computedValue = null;
  custoVertis = null;
  custoCtt = null;
  warnedDiffCttValue = false;

  tipoCorreio = null;
  avisoRececaoDisabled = false;
  avisoRececao = false;
  nEnvelopes = null;
  nFolhasPorEnvelope = null;
  nFolhas = null;

  isDetailsAgendamento = false;
  isFromAgendamento = false;
  valorPagoAgendamento = 0;
  valorTotalAgendamento = 0;
  valorPagoExercAtualAgendamento = 0;
  valorTotalExercAtualAgendamento = 0;

  codCondominio = null;
  nAgendamento = null;
  agendamentoDetails: Array<despesa_agendamento> = [];

  now = new Date();
  today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());

  movimentosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: true },
    { key: 'index_mov', name: 'Nº', type: 'number', sort: null, searchable: true, centered: true, class: 'col-centered one wide z-index-0' },
    { key: 'dt_valor', name: 'Data Valor', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-width z-index-0' },
    { key: 'dt_mov', name: 'Data Mov.', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-width z-index-0' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'wide z-index-0' },
    { key: 'banco', name: 'Conta', type: 'text', sort: null, searchable: true, centered: false, class: 'wide z-index-0' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right two wide z-index-0' },
  ];

  movimentosListColPDF = [
    { key: 'dt_valor', name: 'Data Valor', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-width' },
    { key: 'dt_mov', name: 'Data Mov.', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-width' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-30 col-align-left' },
    { key: 'banco', name: 'Conta', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right' },
  ];

  isCaixaVertisLoggedIn = this.userSession.isCaixaVertisLoggedIn();

  @ViewChild('condominoTableSearch', { static: false }) condominoTableSearch: ElementRef;
  
  initAgendState = null;
  
  subsMsg = null;
  exit = false;
  componentType = 'DESPESA';
  @ViewChild('unsavedChangesModal', { static: false }) unsavedChangesModal: UnsavedChangesModalComponent;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public router: Router,
              public message: MessageService,
              public modalService: SuiModalService,
              public location: Location,
              public cdRef:ChangeDetectorRef,
              public appConfig: AppConfigService,
              public userSession: UserSessionService,
              public businessLogic: BusinessLogicService,
              public movimentosService: MovimentosService,
              public despesasService: DespesasService,
              public appState: AppStateService) {
        this.subsMsg = this.message.getMessage().subscribe(async msg => {
          if (msg.dest === 'CAIXA_VERTIS' || msg.dest === 'DESPESA') {
            switch (msg.cmd) {
              case 'UNSAVED_ALERT':
                let res = await this.unsavedChangesModal.openModal();
                if (res === 'CONFIRM') {
                  this.exit = true;
                  this.router.navigateByUrl(msg.url);
                }
                break;
              }
          }
        });

    this.periodicityOpts = this.appConfig.periodicityOpts.filter(el => (el.value !== '0'));
  }

  warnBeforeLeave():boolean {
    return !this.exit && this.isEditing;
  }

  valorOriginal = null;

  onFornecedorSelected(value) {
    // if (value === 0 || value === '0') {
    //   // SAVE THIS STATE
    //   this.setState();

    //   this.router.navigate(['entidades/fornecedor', 'criar']);
    // }
  }

  setState() {
    this.appState.setPrevState(this.comp, {
      cod_condominio: this.cod_condominio,
      tipo_proc: this.tipo_proc,
      cod_rubrica: this.cod_rubrica,
      descricao: this.descricao,
      startDateAgendamento: this.startDateAgendamento,
      endDateAgendamento: this.endDateAgendamento,
      periodicitySel: this.periodicitySel,
      dt_desp: this.dt_desp,
      estadoPagamento: this.estadoPagamento,
      ref_interna: this.ref_interna,
      n_documento: this.n_documento,
      reparticao: this.reparticao,
      valor: this.valor,
      zonaList: this.zonaList,
    });
  }

  ngOnInit() {
    // HANDLE APPLICATION STATE
    this.initState = this.appState.getInitState(this.comp);
    if (this.initState) {
      this.cod_condominio = { name: this.initState.state.condominioSelected.cod + ' - ' + this.initState.state.condominioSelected.nome, value: this.initState.state.condominioSelected };
      this.appState.clearInitState(this.comp);
      this.getCondominioDetails();
    } else {
      this.prevState = this.appState.getPrevState(this.comp);

      if (this.prevState) {
        this.cod_condominio = (this.prevState.state.cod_condominio) ? { name: this.prevState.state.cod_condominio.cod + ' - ' + this.prevState.state.cod_condominio.nome, value: { cod: this.prevState.state.cod_condominio.cod, nome: this.prevState.state.cod_condominio.nome } } : null;
        this.tipo_proc = (this.prevState.state.tipo_proc) ? this.prevState.state.tipo_proc : null;
        this.cod_rubrica = (this.prevState.state.cod_rubrica) ? this.prevState.state.cod_rubrica : null;
        this.descricao = (this.prevState.state.descricao) ? this.prevState.state.descricao : null;
        this.startDateAgendamento = (this.prevState.state.startDateAgendamento) ? this.prevState.state.startDateAgendamento : null;
        this.endDateAgendamento = (this.prevState.state.endDateAgendamento) ? this.prevState.state.endDateAgendamento : null;
        this.periodicitySel = (this.prevState.state.periodicitySel) ? this.prevState.state.periodicitySel : null;
        this.dt_desp = (this.prevState.state.dt_desp) ? this.prevState.state.dt_desp : new Date();
        this.estadoPagamento = (this.prevState.state.estadoPagamento) ? this.prevState.state.estadoPagamento : null;
        this.ref_interna = (this.prevState.state.ref_interna) ? this.prevState.state.ref_interna : null;
        this.n_documento = (this.prevState.state.n_documento) ? this.prevState.state.n_documento : null;
        this.reparticao = (this.prevState.state.reparticao) ? this.prevState.state.reparticao : 'PO';
        this.valor = (this.prevState.state.valor) ? Math.round(Number(this.prevState.state.valor) * 100) / 100 : null;
        this.zonaList = (this.prevState.state.zonaList) ? this.prevState.state.zonaList : [];

        this.computeTotal();
        this.appState.clearPrevState(this.comp);
      }
    }

    this.animate();

    if (this.router.url.indexOf('/agendamento') !== -1) {
      this.isDetailsAgendamento = true;  // IS AGENDAMENTO DETAILS

      this.codCondominio = this.route.snapshot.params.cod_condominio;

      this.nAgendamento = this.route.snapshot.params.n_agendamento;

      //TODO retirar quando se revir a repartição manual
      this.reparticaoOpts = this.reparticaoOpts.filter(el => el.value !== 'M');
    } else {
      if (this.route.snapshot.params.id === 'criar') {
        this.isCreate = true;

        // CHECK FOR INITIAL STATE
        this.initAgendState = this.appState.getDespState();
        this.appState.clearDespState();

        if (this.initAgendState && this.initAgendState.condominio) {
          this.cod_condominio = this.initAgendState.condominio;
          this.tipo_proc = this.initAgendState.tipoProc;
          this.cod_rubrica = this.initAgendState.rubrica;
          this.descricao = this.initAgendState.descricao;
          this.cod_fornecedor = this.initAgendState.fornecedor;
          this.valor = this.initAgendState.valor;
          this.reparticao = this.initAgendState.reparticao;

          this.getCondominioDetails();
        }

      } else if (this.route.snapshot.params.id === 'criar-lote') {
        this.isCreate = true;
        this.isCreateLote = true;
        this.tipo_proc = 'E';
      } else if (this.route.snapshot.params.id === 'criar-agendamento') {
        this.isCreate = true;
        this.isCreateAgendamento = true;
        this.dt_estado_agendamento = new Date(this.now.getTime());
        this.estado_agendamento = '1';
        this.tipo_proc = 'O';
        this.periodicitySel = '1';

        // CHECK FOR INITIAL STATE
        this.initAgendState = this.appState.getAgendState();
        this.appState.clearAgendState();

        if (this.initAgendState && this.initAgendState.condominio) {
          this.cod_condominio = this.initAgendState.condominio;
          this.tipo_proc = this.initAgendState.tipoProc;
          this.cod_rubrica = this.initAgendState.rubrica;
          this.descricao = this.initAgendState.descricao;
          this.cod_fornecedor = this.initAgendState.fornecedor;
          this.valor = this.initAgendState.valor;
          this.reparticao = this.initAgendState.reparticao;

          let day = this.initAgendState.dtDesp.getDate();
          this.startDateAgendamento = new Date(this.startDateAgendamento.setDate(day));
          this.endDateAgendamento = new Date(this.endDateAgendamento.setDate(day));

          this.getCondominioDetails();
        }

      } else if (this.route.snapshot.params.id === 'criar-correspondencia') {
        this.isCreate = true;
        this.isCreateCorrespondencia = true;
        this.isDespesaCtt = true;
        this.tipo_proc = 'E';
      } else {
        this.despesaId = this.route.snapshot.params.id;
      }
    }

    this.init();
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  getCondominiosList() {
    this.api.getAllCondominios('NULL', true).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.condominiosListOrig = res.data.map(el => {
          el['checked'] = true;
          return el;
        });
        this.condominiosList = this.condominiosListOrig;
      }
    });
  }

  condominiosTimer = null;
  selCondominio = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.cod_condominio); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
                } else {
                  return resolve([]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  fornecedorTimer = null;
  fornecedoresLookup = async (query: string, initial?) => {
    if (initial != undefined) {
        return new Promise(resolve => { return resolve(this.cod_fornecedor)});
    }

    clearTimeout(this.fornecedorTimer);
    return new Promise(resolve => {
        if (query) {
          this.fornecedorTimer = setTimeout(() => {
            // FORNECEDOR LIST
            this.api.getAllFornecedores(query).subscribe(res => {
              if (res.success) {
                return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
                // return resolve([{ name: '--- Criar fornecedor ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; })));
              } else {
                return resolve([]);
                // return resolve([{ name: '--- Criar fornecedor ---', value: '0' }]);
              }
            });
          }, 400);
        } else {
          this.api.getAllFornecedores('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
              // return resolve([{ name: '--- Criar fornecedor ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; })));
            } else {
              return resolve([]);
              // return resolve([{ name: '--- Criar fornecedor ---', value: '0' }]);
            }
          });
        }
    });
  };

  ngOnDestroy() {
    if (this.calcCorreioModalRef) this.calcCorreioModalRef.deny();
    if (this.valorCttModalRef) this.valorCttModalRef.deny();
    if (this.subsMsg) this.subsMsg.unsubscribe();

    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: null });
  }

  ngAfterViewChecked() { 
    this.cdRef.detectChanges(); 
  }

  ngAfterViewInit() {
    this.calcCorreioAlertConfig = new TemplateModalConfig<IContext, string, string>(this.calcCorreioAlertRef);
    this.calcCorreioAlertConfig.closeResult = "closed";
    this.calcCorreioAlertConfig.size = 'small';
    this.calcCorreioAlertConfig.transition = 'fade up';
    this.calcCorreioAlertConfig.transitionDuration = 400;

    this.condominiosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.condominiosAlertRef);
    this.condominiosAlertConfig.closeResult = "closed";
    this.condominiosAlertConfig.size = 'normal';
    this.condominiosAlertConfig.transition = 'fade up';
    this.condominiosAlertConfig.transitionDuration = 400;

    this.copyAgendAlertConfig = new TemplateModalConfig<IContext, string, string>(this.copyAgendAlertRef);
    this.copyAgendAlertConfig.closeResult = "closed";
    this.copyAgendAlertConfig.size = 'small';
    this.copyAgendAlertConfig.transition = 'fade up';
    this.copyAgendAlertConfig.transitionDuration = 400;

    this.emailAlertConfig = new TemplateModalConfig<IContext, string, string>(this.emailAlertRef);
    this.emailAlertConfig.closeResult = "closed";
    this.emailAlertConfig.size = 'mini';
    this.emailAlertConfig.transition = 'fade up';
    this.emailAlertConfig.transitionDuration = 400;

    this.agendamentoChangesAlertConfig = new TemplateModalConfig<IContext, string, string>(this.agendamentoChangesAlertRef);
    this.agendamentoChangesAlertConfig.closeResult = "closed";
    this.agendamentoChangesAlertConfig.size = 'normal';
    this.agendamentoChangesAlertConfig.transition = 'fade up';
    this.agendamentoChangesAlertConfig.transitionDuration = 400;

    this.loginCaixaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.loginCaixaAlertRef);
    this.loginCaixaAlertConfig.closeResult = "closed";
    this.loginCaixaAlertConfig.size = 'mini';
    this.loginCaixaAlertConfig.transition = 'fade';
    this.loginCaixaAlertConfig.transitionDuration = 250;

    this.alredyLoggedAlertConfig = new TemplateModalConfig<IContext, string, string>(this.alredyLoggedAlertRef);
    this.alredyLoggedAlertConfig.closeResult = "closed";
    this.alredyLoggedAlertConfig.size = 'mini';
    this.alredyLoggedAlertConfig.transition = 'fade';
    this.alredyLoggedAlertConfig.transitionDuration = 250;

    this.condominosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.condominosAlertRef);
    this.condominosAlertConfig.isClosable = false;
    this.condominosAlertConfig.closeResult = "closed";
    this.condominosAlertConfig.size = 'tiny';
    this.condominosAlertConfig.transition = 'fade';
    this.condominosAlertConfig.transitionDuration = 250;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.valorCttAlertConfig = new TemplateModalConfig<IContext, string, string>(this.valorCttAlertRef);
    this.valorCttAlertConfig.closeResult = "closed";
    this.valorCttAlertConfig.size = 'mini';
    this.valorCttAlertConfig.transition = 'fade';
    this.valorCttAlertConfig.transitionDuration = 250;

    this.updateMovAlertConfig = new TemplateModalConfig<IContext, string, string>(this.updateMovAlertRef);
    this.updateMovAlertConfig.closeResult = "closed";
    this.updateMovAlertConfig.size = 'small';
    this.updateMovAlertConfig.transition = 'fade';
    this.updateMovAlertConfig.transitionDuration = 250;

    this.updateValorAgendAlertConfig = new TemplateModalConfig<IContext, string, string>(this.updateValorAgendAlertRef);
    this.updateValorAgendAlertConfig.closeResult = "closed";
    this.updateValorAgendAlertConfig.size = 'mini';
    this.updateValorAgendAlertConfig.transition = 'fade';
    this.updateValorAgendAlertConfig.transitionDuration = 250;

    this.changeDateAlertConfig = new TemplateModalConfig<IContext, string, string>(this.changeDateAlertRef);
    this.changeDateAlertConfig.closeResult = "closed";
    this.changeDateAlertConfig.size = 'small';
    this.changeDateAlertConfig.transition = 'fade';
    this.changeDateAlertConfig.transitionDuration = 250;

    this.changeCVAlertConfig = new TemplateModalConfig<IContext, string, string>(this.changeCVAlertRef);
    this.changeCVAlertConfig.closeResult = "closed";
    this.changeCVAlertConfig.size = 'small';
    this.changeCVAlertConfig.transition = 'fade';
    this.changeCVAlertConfig.transitionDuration = 250;

    this.monthsWithoutProcAlertConfig = new TemplateModalConfig<IContext, string, string>(this.monthsWithoutProcAlertRef);
    this.monthsWithoutProcAlertConfig.closeResult = "closed";
    this.monthsWithoutProcAlertConfig.size = 'small';
    this.monthsWithoutProcAlertConfig.transition = 'fade';
    this.monthsWithoutProcAlertConfig.transitionDuration = 250;
    this.changeCVAlertConfig.transitionDuration = 250;

    this.valorLimpezaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.valorLimpezAlertRef);
    this.valorLimpezaAlertConfig.closeResult = "closed";
    this.valorLimpezaAlertConfig.size = 'mini';
    this.valorLimpezaAlertConfig.transition = 'fade';
    this.valorLimpezaAlertConfig.transitionDuration = 250;

  }

  tableSearch(keyword, target=null) {
    keyword = keyword.toLowerCase().trim();

    if (target === 'CONDOMINOS') {

      if (keyword) {
        this.condSearching = true;

        // SAVE SELECTION STATE
        this.condominosList.forEach(cond => {
          let aux = this.condominosListOrig.find(el => (el.cod === cond.cod));
          if (aux) aux.ckecked = cond.checked;
        });

        this.condominosList = this.condominosListOrig.filter(el => el.nome.toLowerCase().indexOf(keyword) !== -1);
        this.condSearching = false;
      } else {
        this.condominosList = this.condominosListOrig;
      }

    } else {
      if (keyword) {
        this.condSearching = true;
  
        // SAVE SELECTION STATE
        this.condominiosList.forEach(cond => {
          let aux = this.condominiosListOrig.find(el => (el.cod === cond.cod));
          if (aux) aux.ckecked = cond.checked;
        });
  
        this.condominiosList = this.utils.tableSearch(keyword, this.condominioListCol, this.condominiosListOrig);
        this.condSearching = false;
      } else {
        this.condominiosList = this.condominiosListOrig;
      }
    }
  }

  init() {
    
    // GET RUBRICAS
    this.api.getRubricas().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        if (this.isCreateCorrespondencia || this.isDespesaCtt) { // TODO: MARTELO!! 
          this.rubricasOptsOrig = this.correspRubricas;
        } else {
          this.rubricasOptsOrig = res.data.map(el => {
            return { name: el.nome, value: el.cod };
          });
        }
        this.rubricasOpts = JSON.parse(JSON.stringify(this.rubricasOptsOrig))

      } else {}
    }, err => {
    });
    if (this.isDetailsAgendamento) {
      this.getAgendDespesaDetails();
    } else {
      if (this.isCreate) {
        if (this.isCreateLote) {
          this.getCondominiosList();
        }
      } else {
        this.getDespesaDetails();
      }
    }
  }

  fetchingOrcamento = false;
  getOrcamento() {
    if (this.isCreateAgendamento || this.isDetailsAgendamento) {
      this.rubricasOptsOrc = JSON.parse(JSON.stringify(this.rubricasOptsOrig));
      
      this.tipoProcSelected();
      return;
    }
    
    if (!this.cod_condominio || !this.dt_desp) return;
    
    let cod = (this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
    let exercicio = this.dt_desp.getFullYear();
    
    this.fetchingOrcamento = true;
    this.api.getOrcamentoByPeriodoAndAprovado(cod, true, exercicio).subscribe(res => {
      this.fetchingOrcamento = false;
      if (res.hasOwnProperty('success') && res.success) {

        let periodo = null;
        if (!res.data.orcamento) {
          this.rubricasOptsOrc = JSON.parse(JSON.stringify(this.rubricasOptsOrig));
          this.fetchingOrcamento = false;
          this.tipoProcSelected();
          periodo = exercicio;
        } else {
          periodo = res.data.orcamento.periodo;
        }

        this.api.getProcessamentos(cod, periodo, 1, 500, null, null, null, { isLancado: 1 }).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            this.processamentosOrig = res.data;
            this.orcamentoId = (res.orcamento.length > 0) ? res.orcamento[0].id : null;
            
            if (this.orcamentoId === null) {

              this.api.getCondFraccoes(cod).subscribe(res => {
                if (res.hasOwnProperty('success') && res.success) {

                  this.condominosList = [];
                  this.condominosListOrig = [];

                  if (res.data && Array.isArray(res.data)) {
                    res.data.forEach((el, i) => {
                      // SET CONDOMINOS LIST OPTIONS
                      if (!this.condominosListOrig.find(it => (it.nome === el.nome_proprietario))) {
                        this.condominosListOrig.push({ nome: el.nome_proprietario, condominoCod: el.cod_proprietario, fraccao: el.cod + ' - ' + el.nome + ' - ', value: i, checked: false });
                      }
                    });
                    this.condominosList = JSON.parse(JSON.stringify(this.condominosListOrig));
                  }

                }
              }, err => {});

            } else {
              this.api.getOrcamentoDetails(this.orcamentoId).subscribe(res => {
                if (res.hasOwnProperty('success') && res.success) {
                  this.rubricasOptsOrc = [];
  
                  if (res.data && Array.isArray(res.data.rubricas)) {
                    let seenRubricas = '';
                    res.data.rubricas.forEach(el => {
  
                      // SET RUBRICAS LIST OPTIONS
                      if (seenRubricas.indexOf(',' + el.cod_rubrica + ',') === -1) {
                        // ADD RUBRICA TO SEEN STRING
                        seenRubricas += (',' + el.cod_rubrica + ',');
  
                        this.rubricasOptsOrc.push({ name: el.rub_nome, value: el.cod_rubrica });
                      }
                      
                    });
                  }
                  this.rubricasOptsOrc = this.rubricasOptsOrc.filter(el => (el.hasOwnProperty('name') && el.name));
  
                  this.condominosList = [];
                  this.condominosListOrig = [];
                  if (res.data && Array.isArray(res.data.fraccoes)) {
                    res.data.fraccoes.forEach((el, i) => {
                      // SET CONDOMINOS LIST OPTIONS
                      if (!this.condominosListOrig.find(it => (it.nome === el.nome_proprietario))) {
                        this.condominosListOrig.push({ nome: el.nome_proprietario, condominoCod: el.cod_proprietario, fraccao: el.cod + ' - ' + el.nome + ' - ', value: i, checked: false });
                      }
                    });
                    this.condominosList = JSON.parse(JSON.stringify(this.condominosListOrig));
                  }
  
                  if (this.isDespesaCtt) this.getRegistoCttDetails();
  
                  this.zonaList = [];
                  if (res.data && Array.isArray(res.data.zonas)) {
                    res.data.zonas.forEach(el => {
                      let aux = this.linhasDespesasDetails.find(el2 => (el2.cod_zona === el.cod));
  
                      this.zonaList.push({
                        id: (aux) ? aux.id: null,
                        cod_zona: el.cod,
                        nome: el.nome,
                        permilagem: Number(el.permilagem),
                        n_fraccoes: Number(el.n_fraccoes),
                        valor: (aux) ? Math.round((Number(aux.valor) * 100)) / 100 : null,
                        checked: true,
                      });
                    });
                  }
  
                  this.valorChanged();
  
                  this.computeTotal();

                  this.fetchingOrcamento = false;
                  this.tipoProcSelected();
                }
              }, err => {});
            }
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
      }
    },
    err => {this.fetchingOrcamento = false;});
  }


  fetchingContas = false;
  getCondominioDetails() {
    // PROCESSAMENTOS
    let cod = (this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;

    // TODO: REVIEW THIS SERVICE THAT IS NOT REQUIRED!!!!
    this.getOrcamento();

    // CONTAS
    this.fetchingContas = true;
    this.api.getCondContasDetails(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.contaOptsOrig =  res.data.map(el => {
          return { name: el.banco, value: el.cod, conta_principal: el.conta_principal, saldo: Number(el.saldo), details: el };
        });
        this.contaOpts = JSON.parse(JSON.stringify(this.contaOptsOrig));
        this.appState.openPaymentModal = false;
        this.appState.goToMovimentos = false;
        this.fetchingContas = false;
      } else {
        this.fetchingContas = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
    }, err => {
      this.fetchingContas = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  dateSelected() {
    if (!this.dt_desp) return;

    this.getOrcamento();

    if (this.isCreate) {
      let processamento = null;
      if (this.tipo_proc !== 'E') {
        processamento = this.processamentosOrig.find(proc => (proc.ano == this.dt_desp.getFullYear() && proc.mes == this.dt_desp.getMonth() && proc.tipo_proc === this.tipo_proc));
      } else {
        processamento = this.processamentosOrig.find(proc => (proc.tipo_proc === 'E'));
      }
  
      if (processamento && processamento.hasOwnProperty('cod')) {
        this.cod_proc = processamento['cod'];
      } else {
        // REMOVER IF - MARTELO
        // if (!this.isCreateLote) this.toastr.error('Nenhum processamento disponível esta despesa', 'Ups...!', { timeOut: 4000 });
      }
    }
  }

  reparticaoSelected() {
    if (this.reparticao === 'M') {
      // SHOW TABLE
    } else {
      // HIDE TABLE
    }
  }


  setPayment: (data:PaymentForm) => Promise<boolean> = (data) => {
    return new Promise(async (resolve) => {
      this.loadingModal = true;
  
      this.form_pagam = data.form_pagam;
      this.dt_pag_valor = data.dt_pag_valor;
      this.dt_pag = data.dt_pag;
      this.data_pagamento_despesa = (this.valor === data.valor_pago) ? data.dt_pag : null;
      this.cod_conta_bancaria = data.cod_conta_bancaria;
      this.n_documento = data.n_doc_pagam;
      this.valor_pago = Math.round(parseFloat(data.valor_pago) * 100) / 100;
  
      let success = false;
      if (this.isCreateLote) {
        success = await this.formSubmittedLote();
      } else {
        success = await this.formSubmitted(true);
      }
      if (!success) {
        this.form_pagam = null;
        this.dt_pag_valor = null;
        this.dt_pag = null;
        this.data_pagamento_despesa = null;
        this.cod_conta_bancaria = null;
        this.n_documento = null;
        this.valor_pago = null;
      }
      this.loadingModal = false;
      resolve(success);
    })
  }

  saving = false;
  async formSubmittedLote():Promise<boolean> {
    return new Promise(async (resolve) => {
      if (this.saving) {
        resolve(false);
        return;
      }

      this.submittingForm = true;
      let inputValid = await this.validateInput();
      if (!inputValid) {
        resolve(false);
        return;
      }
  
      this.loadingModal = true;
  
      let cod_fornecedor = this.cod_fornecedor? (this.cod_fornecedor.hasOwnProperty('value') ? this.cod_fornecedor.value.cod : this.cod_fornecedor.cod) : null;
  
      this.exercicio = this.dt_desp.getFullYear();
      this.orcamentada = (this.tipo_proc === 'O') ? '1' : '0';

      this.registoAct = this.despesaDetails? JSON.parse(this.despesaDetails.obj) : [];
  
      // REGISTO DE ACTIVIDADES ---------------------------------------
      let activity = {
        msg: null,
        activity: (this.isCreate) ? 'Criado por ' : 'Actualizado por ',
        user: this.userSession.getUserFullName(),
        date: new Date(),
      };
  
      if (this.isCreate) {
        activity.msg = 'Despesa lançada. Data de lançamento: ' + this.utils.getFormatedDate(new Date(this.dt_desp)) + ', Valor: ' + this.valor + ' €.';
        this.registoAct = [activity].concat(this.registoAct);
  
      } else {
        activity.msg = 'Despesa actualizada. Data de lançamento: ' + this.utils.getFormatedDate(new Date(this.dt_desp)) + ', Valor: ' + this.valor + ' €.';
        this.registoAct = [activity].concat(this.registoAct);
      }
      // ----------------------------------------------------------------    
  
      let tipoPag = ' -N';  // TODO: REVIEW FOR PAGAMENTO EM CONTA PRINCIPAL
  
      let condominio_list = this.condominiosList.filter(el => (el.checked));
      let tipoDespesa = (this.isDespesaCtt) ? 'CTT' : null;
  
      // COMPUTE REPARTICAO PELAS ZONAS
      condominio_list.forEach(el => {
        this.zonaList = el.zonas.map(el => {
          return {
            id: null,
            cod_zona: el.cod,
            nome: el.nome,
            permilagem: Number(el.permilagem),
            n_fraccoes: Number(el.n_fraccoes),
            valor: null,
            checked: true,
          }
        });
  
        switch (this.reparticao) {
          case 'P':
          case 'PO':
            let permTotal = 0;
            this.zonaList.filter(el => el.checked).forEach(el => {
              permTotal += el.permilagem;
            });
  
            this.zonaList.filter(el => el.checked).forEach(el => { 
              el.valor = Math.round(((el.permilagem / permTotal) * this.valor) * 100) / 100;
            });
            break;
          case 'M':
          case 'E':
            let totalFraccoes = 0;
            this.zonaList.filter(el => el.checked).forEach(el => { totalFraccoes += el.n_fraccoes; });
  
            this.zonaList.filter(el => el.checked).forEach(el => {
              el.valor = Math.round(((this.valor / totalFraccoes) * el.n_fraccoes) * 100) / 100;
            });
            break;
        }
  
        el['linhas_despesa'] = JSON.parse(JSON.stringify(this.zonaList));
      });
  
      this.saving = true;
      this.api.saveDespesas(condominio_list, this.cod_rubrica, cod_fornecedor, this.tipo_proc, this.dt_desp, this.dt_pag_valor, this.dt_pag, this.dt_venc, this.valor, this.valor_pago, this.n_documento, this.form_pagam, this.cod_conta_bancaria, this.descricao, this.n_doc_pagam, this.ref_interna, this.nid_movimento, this.reparticao, JSON.stringify(this.registoAct), tipoDespesa, tipoPag).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (this.condominiosModalRef) this.condominiosModalRef.approve();
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }

        this.submittingForm = false;
        this.loadingModal = false;
        this.saving = false;
          
        }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.submittingForm = false;
        this.loadingModal = false;
        this.saving = false;
        resolve(false);
      });
    });
    
  }

  agendDatesToRemove = [];
  toRemoveAgendDate(desp:AgendDate, action='ADD') {
    if (action === 'UNDO') {
      this.agendDatesToRemove = this.agendDatesToRemove.filter(el => (el !== desp));
      desp.removed = false;
      this.agendDates.push(desp);
      if (this.agendDates.length > 1) {
        this.agendDates.sort((a, b) => {
          return a.dt_desp.getTime() - b.dt_desp.getTime();
        });
      }
    } else if (action === 'UNDO_V2') {
      this.agendDatesRemovidas = this.agendDatesRemovidas.filter(el => (el !== desp));
      desp.removed = false;
      this.agendDates.push(desp);
      if (this.agendDates.length > 1) {
        this.agendDates.sort((a, b) => {
          return a.dt_desp.getTime() - b.dt_desp.getTime();
        });
      }
    } else {
      this.agendDates = this.agendDates.filter(el => (el !== desp));

      if (this.isCreateAgendamento) {
        desp.removed = true;
        this.agendDatesToRemove.push(desp);
        if (this.agendDatesToRemove.length > 1) {
          this.agendDatesToRemove.sort((a, b) => {
            return a.dt_desp.getTime() - b.dt_desp.getTime();
          });
        }
      } else {
        desp.removed = true;
        this.agendDatesRemovidas.push(desp);
        if (this.agendDatesRemovidas.length > 1) {
          this.agendDatesRemovidas.sort((a, b) => {
            return a.dt_desp.getTime() - b.dt_desp.getTime();
          });
        }
      }

    }
    this.updateDatesConfigurationsUI();
    this.checkIfIsEditing();
  }

  prevTab = null;
  tabSelected(tab) {
    this.prevTab = tab;
  }

  presentAlertConfirmation() {
    return new Promise((resolve, reject) => {
      this.updateValorAgendModalRef = this.modalService
        .open(this.updateValorAgendAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => {
          resolve(false);
        });
    });
  }

  registoActividades(target, action, cod_condominio, nome_condominio, n_agendamento, nome_fornecedor) {
    switch (target) {
      case 'agendamento':
        // REGISTO DE ACTIVIDADE - CREATE
        let titulo = '';

        if (action === 'create') titulo = 'Agendamento Criado';
        if (action === 'update') titulo = 'Agendamento Actualizado';

        let descricao = `Condomínio: ${cod_condominio} - ${nome_condominio}, Nº agendamento:  ${n_agendamento}, Fornecedor: ${nome_fornecedor}.`;
        this.api.saveRegistoActividade(cod_condominio, null, null, titulo, descricao).subscribe(res => {}, err => { });  
        break;
    }
  }

  loadingAgend = false;
  async formSubmittedAgendamento() {

    if (this.saving) return;
    
    this.submittingForm = true;


    let inputValid = await this.validateInput();
    if (!inputValid) {
      return;
    }


    this.total = Math.round(Number(this.total) * 100) / 100;
    this.valor = Math.round(Number(this.valor) * 100) / 100;

    this.agendDatesRemovidas.forEach(el => {
      let prevDespesa = this.agendamentoDetails.find(prev => prev.id === el.id);
      el.movimentos = [];
      el.valor_liquidado = 0;
      if (prevDespesa) {
        el.valor = parseFloat(prevDespesa.valor);
        el.cod_conta_bancaria_agendamento = prevDespesa.cod_conta_bancaria_agendamento;
        el.form_pagam_agendamento = prevDespesa.form_pagam_agendamento;
      }
    });

    
    if (this.isDetailsAgendamento) {
      let res = await this.presentChangesModal();
      if (!res) return;
    }
    
    let allDespesas = this.agendDates.concat(this.agendDatesRemovidas);
    
    let res = this.setRegistoActividadeAgendamento(allDespesas, this.registoAct);
    allDespesas = res.despesas;
    this.registoAct = res.regAct;

    let despesas:Array<agendamentosSaveDespesaReq> = [];

    allDespesas.forEach(el => {
      let forma = this.getDespFormLancamento(el.id, el.dt_desp);
      let despesa: agendamentosSaveDespesaReq = {
        id:el.id,
        descricao: (this.periodicitySel === '1' ? this.utils.getMonthDesc(el.dt_desp) : '') + this.descricao,
        movimentos: el.movimentos,
        dt_desp: this.utils.getFormatedDate(el.dt_desp),
        obj:el.obj,
        valor: el.valor.toString(),
        valor_liquidado: el.valor_liquidado ? el.valor_liquidado.toString() : '0',
        linhas_despesa: this.getLinhasDespesa(el.valor),
        cod_conta_bancaria_agendamento: forma.cod_conta_bancaria,
        form_pagam_agendamento: forma.form_pagamento,
        dt_pag_agendamento: null,
        removed: el.removed,
      }
      if (this.updateValorLimpeza) {
        despesa['valor_limpeza'] = this.valorLimpeza;
      }
      if (forma.forma_agendamento === 'LP') {
        despesa['dt_pag_agendamento'] = el.dt_pag_agendamento? this.utils.getFormatedDate(el.dt_pag_agendamento) : this.utils.getFormatedDate(el.dt_desp);
      }

      despesas.push(despesa);
    });

    let reparticao_dist = [];
    if (this.reparticao === 'M') reparticao_dist = this.getLinhasDespesa(this.actualValor);


    let datas_valor = this.getDatasValorToUpload();
    let datas_lancamento = this.getDatasLancamentoToUpload();

    
    let cod_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
    let cod_fornecedor = (this.cod_fornecedor && this.cod_fornecedor.hasOwnProperty('cod')) ? this.cod_fornecedor.cod : this.cod_fornecedor.value.cod;
    let nome_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('nome')) ? this.cod_condominio.nome : this.cod_condominio.value.nome;


    let id = null;
    let n_agendamento = null;

    if (this.despesaDetails) {
      id = this.despesaDetails.id;
      n_agendamento = this.despesaDetails.n_agendamento;
    }

    let nome_fornecedor = '';
    if (this.cod_fornecedor.hasOwnProperty('name')) {
      nome_fornecedor = this.cod_fornecedor.name;
    } else if (this.cod_fornecedor.hasOwnProperty('nome')) {
      nome_fornecedor = this.cod_fornecedor.nome;
    }

    this.loadingAgend = true;
    this.saving = true;
    this.api.saveAgendamento(id, cod_condominio, n_agendamento, despesas,this.tipo_proc, this.reparticao, this.cod_rubrica, this.descricao, this.utils.getFormatedDate(this.startDateAgendamento), this.utils.getFormatedDate(this.endDateAgendamento), this.periodicitySel, cod_fornecedor, this.forma_agendamento, JSON.stringify(reparticao_dist), JSON.stringify(datas_valor), JSON.stringify(datas_lancamento), JSON.stringify(this.registoAct), this.dt_estado_agendamento, this.estado_agendamento).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        // REGISTO ACTIVIDADES - UPDATE AGENDAMENTO
        this.registoActividades('agendamento', this.isCreateAgendamento? 'create' :'update', cod_condominio, nome_condominio, res.n_agendamento, nome_fornecedor);

        if (!this.hasGoneBack) { 
          if (this.initAgendState && this.initAgendState.condominio) {
            this.router.navigate(['lancamentos/despesa']);
          } else {
            if (this.isCreateAgendamento) {
              this.goToAgendamento(res.n_agendamento);
            } else {
              this.init();
              document.getElementsByClassName('page-container')[0].scrollTo({
                top: 0,
              });
            }
          }
        }
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.submittingForm = false;
      this.loadingAgend = false;
      this.saving = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.submittingForm = false;
      this.loadingAgend = false;
      this.saving = false;
    });
  }

  getDatasLancamentoToUpload() {
    let datas_lancamento:Array<{data:Date, form_pagamento:string, cod_conta_bancaria:string,forma_agendamento:'L'|'LP'}> = [];
    this.datas_lancamento.forEach(el => {
      datas_lancamento.push({ data: new Date(el.data), form_pagamento: el.form_pagamento, cod_conta_bancaria: el.cod_conta_bancaria, forma_agendamento: el.forma_agendamento});
    });
    if (this.dataInicioPagamento) {
      datas_lancamento = datas_lancamento.filter(el => this.utils.compareDayDates(el.data, this.dataInicioPagamento) < 0);
      datas_lancamento.sort((a,b) => this.utils.compareDayDates(a.data, b.data));
      datas_lancamento.forEach(el => el.data = this.utils.getFormatedDate(el.data));
      datas_lancamento.push({data: this.utils.getFormatedDate(this.dataInicioPagamento), form_pagamento: this.form_pagam, cod_conta_bancaria: this.cod_conta_bancaria, forma_agendamento: this.forma_agendamento});
    } else {
      datas_lancamento.sort((a,b) => this.utils.compareDayDates(a.data, b.data));
    }
    return datas_lancamento;
  }

  getDatasValorToUpload(): Array<{data,valor}> {
    let datas_valor:Array<{data,valor}> = [];
    this.datas_valor.forEach(el => {
      datas_valor.push({ data: new Date(el.data), valor: el.valor});
    });

    //Atualizar datas_valor e remover todas as datas para a frente de dt_valor
    datas_valor = datas_valor.filter(el => this.utils.compareDayDates(el.data, this.dt_valor) < 0);
    datas_valor.sort((a,b) => this.utils.compareDayDates(a.data, b.data));
    datas_valor.forEach(el => el.data = this.utils.getFormatedDate(el.data));
    datas_valor.push({data: this.utils.getFormatedDate(this.dt_valor), valor: this.valor.toString()});
    return datas_valor;
  }


  validateInput():Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!this.isFormValid()) {
        resolve(false);
        return;
      }
    
    
      let monthsWithoutProc = await this.hasProcessamentoForEach();
    
      if (monthsWithoutProc.length > 0) {
        this.submittingForm = false;
    
        
        // let currExerc = null;
        // // monthsWithoutProc.forEach(el => {
        // //   if (months === '') {
        // //     months += this.utils.getMonthDescByIndex(el.month);
        // //   } else {
        // //     months += (', ' + this.utils.getMonthDescByIndex(el.month));
        // //   }
        // //   if (currExerc && currExerc !== el.year) {
        // //     months += ' de ' + currExerc;
        // //   }
        // //   currExerc = el.year;
        // // });
        // // months += ' de ' + currExerc;
    
        // switch (this.tipo_proc) {
        //   case 'O': months = "Não existe(m) processamento(s) de 'Orçamento' para o(s) mes(es) de " + months + '.'; break;
        //   case 'F': months = "Não existe(m) processamento(s) de 'Fundo de Reserva' para o(s) mes(es) de " + months + '.'; break;
        // }
        let months:{msgs:Array<{msg:string, year:string}>, total:number} = {msgs: [], total: monthsWithoutProc.length};
        monthsWithoutProc.forEach(el => {
          let entry = months.msgs.find(month => month.year === el.year);
          if (!entry) {
            months.msgs.push({msg: this.utils.getMonthDescByIndex(el.month), year: el.year});
          } else {
            entry.msg += ', ' + this.utils.getMonthDescByIndex(el.month);
          }
        });
        // months = monthsWithoutProc.map(el => this.utils.getMonthDescExtensoByIndex(el.month));

        let approve = await this.presentMonthsWithoutProcModal(months);

        resolve(approve);
        return;
      }
      resolve(true);
    });
  }


  @ViewChild('monthsWithoutProcAlertRef', { static: false }) monthsWithoutProcAlertRef;
  monthsWithoutProcModalRef = null;
  monthsWithoutProcAlertConfig: any = null;

  tipoProcExtenso:string = '';
  monthsWithoutProc:{msgs:Array<{msg:string, year:string}>, total:number} = null;
  presentMonthsWithoutProcModal(months:{msgs:Array<{msg:string, year:string}>, total:number}): Promise<boolean> {
    this.monthsWithoutProc = months;
    let proc = this.appConfig.processamentosOpts.find(el => el.value === this.tipo_proc);
    if (proc) this.tipoProcExtenso = proc.name;
    return new Promise((resolve) => {
      this.monthsWithoutProcModalRef = this.modalService
      .open(this.monthsWithoutProcAlertConfig)
      .onApprove(() => {
        resolve(true);
      })
      .onDeny(() => {
        resolve(false);
      });
    });  
  }


  alteracoesAgendamento:Array<{label, prev, atual}> = [];
  alteracoesNewDates:{inicio, fim} = null;
  alteracoesCanceledDates:Array<string> = [];
  alteracoesAdicionadasDates:Array<string> = [];
  presentChangesModal(): Promise<boolean> {
    return new Promise((resolve) => {
      let changes = this.getAgendamentoChanges();
      let changedAgendDates = this.agendDatesChanged();

      // if (!changedAgendDates.agendadas.length && !changedAgendDates.removidas.length && !changedAgendDates.adicionadas.length && !changes.length) {
      //   resolve(true);
      //   return;
      // }

      this.alteracoesAgendamento = [];
      this.alteracoesNewDates = {inicio: null, fim:null}
      this.alteracoesCanceledDates = [];
      this.alteracoesAdicionadasDates = [];

      if (changes.length) {
        if (changes.find(el => el === 'ESTADO')) {
          let prevOpt = this.appConfig.agendamentoEstadoOpts.find(el => el.value === this.despesaDetails.estado);
          let actualOpt = this.appConfig.agendamentoEstadoOpts.find(el => el.value === this.estado_agendamento);
          let prevName = '';
          let actualName = '';
          if (prevOpt) prevName = prevOpt.name;
          if (actualOpt) actualName = actualOpt.name;
          this.alteracoesAgendamento.push({label: 'Estado', prev:  prevName + ' (' + this.despesaDetails.dt_estado + ')', atual: actualName + ' (' + this.utils.getFormatedDate(this.dt_estado_agendamento) + ')' });
        }
        if (changes.find(el => el === 'DESCRICAO')) {
          this.alteracoesAgendamento.push({label: 'Descrição', prev: this.despesaDetails.descricao, atual: this.descricao});
        }
        if (changes.find(el => el === 'PROCESSAMENTO')) {
          this.alteracoesAgendamento.push({label: 'Processamento', atual: this.processamentoOpts.find(el => el.value === this.tipo_proc).name, prev: this.processamentoOpts.find(el => el.value === this.despesaDetails.tipo_proc).name});
        } 
        if (changes.find(el => el === 'REPARTICAO')) {
          let atual = this.reparticaoOpts.find(el => el.value === this.reparticao).name;
          let prev =this.reparticaoOpts.find(el => el.value === this.despesaDetails.reparticao).name;

          this.alteracoesAgendamento.push({label: 'Repartição', prev: prev, atual: atual});
        }
        if (changes.find(el => el === 'RUBRICA')) {
          let atual = this.rubricasOptsOrig.find(el => el.value === this.cod_rubrica).name;
          let prev =this.rubricasOptsOrig.find(el => el.value === this.despesaDetails.cod_rubrica).name;

          this.alteracoesAgendamento.push({label: 'Rúbrica', prev: prev, atual: atual});
        }
        if (changes.find(el => el === 'VALOR')) {
          let prev = JSON.parse(this.despesaDetails.datas_valor);
          let changePrev = null;
          if (prev && prev.length) {
            changePrev = prev[prev.length - 1].valor + ' € (Início: ' + prev[prev.length - 1].data + ')';
          }
          let changeAtual = this.valor + ' € (Início: ' + this.utils.getFormatedDate(this.dt_valor) + ')';
          this.alteracoesAgendamento.push({label: 'Valor', prev: changePrev, atual: changeAtual});
        }
        if (changes.find(el => el === 'FORMA_LANCAMENTO')) {
          let prev = JSON.parse(this.despesaDetails.datas_lancamento);
          let changePrev = null;
          if (prev && prev.length) {
            let forma = this.appConfig.pagamentoOtps.find(el => el.value === prev[prev.length - 1].form_pagamento);
            let conta = this.contaOptsOrig.find(el => (el.value === prev[prev.length - 1].cod_conta_bancaria));
            changePrev = (prev[prev.length - 1].forma_agendamento === 'LP' ? 'Lançar e Agendar Pagamento' : 'Lançar Despesas');
            if (forma && conta) {
              changePrev += ' - ' + forma.name + ' · ' + conta.name;
            }
            changePrev += ' (Início: ' + this.utils.getFormatedDate(prev[prev.length - 1].data) + ')';
          }
          let forma = this.appConfig.pagamentoOtps.find(el => el.value === this.form_pagam);
          let conta = this.contaOptsOrig.find(el => (el.value === this.cod_conta_bancaria));
          let changeAtual = (this.forma_agendamento === 'LP' ? 'Lançar e Agendar Pagamento' : 'Lançar Despesas');
          if (forma && conta) {
            changeAtual += ' - ' + forma.name + ' · ' + conta.name;
          }
          changeAtual += ' (Início: ' + this.utils.getFormatedDate(this.dataInicioPagamento) + ')';
          this.alteracoesAgendamento.push({label: 'Lançamento', prev: changePrev, atual: changeAtual});
        }
      }

      if (changedAgendDates.agendadas.length || changedAgendDates.removidas.length || changedAgendDates.adicionadas.length) {
        changedAgendDates.removidas.forEach(prevDate => {
           this.alteracoesCanceledDates.push(this.utils.getFormatedDate(prevDate.dt_desp));
        })

        changedAgendDates.adicionadas.forEach(prevDate => {
           this.alteracoesAdicionadasDates.push(this.utils.getFormatedDate(prevDate.dt_desp));
        })

        if (changedAgendDates.agendadas.length) {
          this.alteracoesNewDates.inicio = changedAgendDates.agendadas[0].dt_desp;
          if (changedAgendDates.agendadas.length > 1) {
            this.alteracoesNewDates.fim = changedAgendDates.agendadas[changedAgendDates.agendadas.length - 1].dt_desp;
          }
        }
      }

      this.agendamentoChangesModalRef = this.modalService
        .open(this.agendamentoChangesAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => {
          resolve(false);
        });
    });
  }

  getLinhasDespesa(valor) {
    let totalFraccoes = 0;
    let linhas_despesas = JSON.parse(JSON.stringify(this.zonaList));
    switch (this.reparticao) {
      case 'P':
      case 'PO':
        let permTotal = 0;
        linhas_despesas.filter(el => el.checked).forEach(el => {
          permTotal += el.permilagem;
        });

        linhas_despesas.filter(el => el.checked).forEach(el => { 
          el.valor = Math.round(((el.permilagem / permTotal) * valor) * 100) / 100;
        });
        break;
      case 'E':
        totalFraccoes = 0;
        linhas_despesas.filter(el => el.checked).forEach(el => { totalFraccoes += el.n_fraccoes; });

        linhas_despesas.filter(el => el.checked).forEach(el => {
          el.valor = Math.round(((valor / totalFraccoes) * el.n_fraccoes) * 100) / 100;
        });
        break;
      case 'M':
          totalFraccoes = 0;
          linhas_despesas = linhas_despesas.filter(el => el.checked);
          linhas_despesas.forEach(el => {
            el.valor = Number(el.valor);
          });
        break;
    }
    return linhas_despesas.map(el => {
      return {
        cod_zona: el.cod_zona,
        valor: el.valor,
      }
    });
  }
  
  despChanged(el:AgendDate, prevDesp:despesa_agendamento):boolean {
    if (!!el.removed !== (prevDesp.active === '0')) return true;

    if (this.utils.compareDayDates(this.utils.getDate(prevDesp.dt_desp), el.dt_desp) != 0) return true;
    if (prevDesp.valor != el.valor) return true;

    //Se ambos não tiverem o mesmo valor, foi alterado
    if (el.form_pagam_agendamento !== prevDesp.form_pagam_agendamento) return true;
    if (el.cod_conta_bancaria_agendamento !== prevDesp.cod_conta_bancaria_agendamento) return true;

    //Se ambos não tiverem o mesmo valor, foi alterado
    if (!!el.dt_pag_agendamento !== !!prevDesp.dt_pag_agendamento) return true;
    //Se têm o mesmo valor e não é nulo, compara-se
    if (!!el.dt_pag_agendamento && this.utils.compareDayDates(el.dt_pag_agendamento, this.utils.getDate(prevDesp.dt_pag_agendamento)) != 0) return true;
    
    return false;
  }

  setRegistoActividadeAgendamento(despesas:Array<AgendDate>, regActInitial,isCopy=false):{despesas, regAct} {
    despesas.forEach(el => {
      let registoAct = el.obj? JSON.parse(el.obj) : [];
      let prevDesp = el['id'] ? this.despesaAgendDetails.find(prev => prev.id === el.id) : null;

      //Movs Changes
      if (prevDesp) {
        prevDesp.movimentos.forEach(prevMov => {
          let actualMov = el.movimentos.find(mov => mov.id === prevMov.id);
          if (!actualMov) {
            let activity = {
              msg: 'Movimento removido. Data de pagamento: ' + prevMov['dt_mov'] + ', Conta: ' + prevMov['banco'] + ', Valor: ' + Math.abs(Number(prevMov['valor'])).toFixed(2) + ' €.',
              movObj: { 
                nid_mov: prevMov['id'], 
                index_mov: parseInt(prevMov['index_mov']) 
              },
              activity: 'Atualizado por ',
              user: this.userSession.getUserFullName(),
              date: new Date(),
              from_agendamento: '1'
            }
            registoAct = [activity].concat(registoAct);
          }
        });
      }

      if (!prevDesp || this.despChanged(el, prevDesp)) {
        let activity = {
          msg: null,
          activity: !prevDesp? 'Criado por' : 'Actualizado por ',
          user: this.userSession.getUserFullName(),
          date: new Date(),
          from_agendamento: '1'
        };


        if (el.removed && (!prevDesp || prevDesp.active === '1')) {
          activity.msg = 'Despesa removida.';
          registoAct = [activity].concat(registoAct);
        } else {
          let forma = this.getDespFormLancamento(el.id, el.dt_desp);
          let valor = this.getDespValue(el, el.dt_desp);
  
          let conta = this.contaOptsOrig.find(el => el.value === forma.cod_conta_bancaria);
          let form_pagam = this.appConfig.pagamentoOtps.find(el => el.value === forma.form_pagamento);
          let obj = {date: el.dt_desp, valor: valor};
          if (conta) obj['conta'] = conta.name
          if (form_pagam) obj['form_pagam'] = form_pagam.name
          if (forma.forma_agendamento === 'LP') {
            activity.msg = this.utils.getRegActividadeMsg(!prevDesp? 'DESPESA_LANCADA_AUTOPAY' : 'DESPESA_UPDATE_AUTOPAY', obj);
          } else {
            activity.msg = this.utils.getRegActividadeMsg(!prevDesp? 'DESPESA_LANCADA' : 'DESPESA_UPDATE', obj);
          }
          
          registoAct = [activity].concat(registoAct);
  
          if (prevDesp) {
            prevDesp.movimentos.forEach(prevMov => {
              let actualMov = el.movimentos.find(mov => mov.id === prevMov.id);
              if (actualMov && parseFloat(actualMov.valor) != parseFloat(prevMov.valor)) {
                let activity = {
                  msg: 'Movimento actualizado. Data de pagamento: ' + prevMov['banco'] + ', Conta: ' + prevMov['banco'] + ', Valor: ' + Math.abs(Number(actualMov.valor)).toFixed(2) + ' €.',
                  activity: 'Atualizado por ',
                  user: this.userSession.getUserFullName(),
                  date: new Date(),
                  from_agendamento: '1',
                }
                registoAct = [activity].concat(registoAct);
              }
            });
          }
        }

      }
      el.obj = JSON.stringify(registoAct);
    });


    if (this.isCreateAgendamento || isCopy) {
      let n_agendamento = this.despesaDetails? this.despesaDetails.n_agendamento : null; 
      let activity = {
        title: 'Agendamento criado',
        reg: [],
        activity: 'Actualizado por ',
        user: this.userSession.getUserFullName(),
        date: new Date(),
      };

      if (isCopy && n_agendamento) {
        activity['copiedFromAgend'] = n_agendamento;
      }

      activity.reg.push({label: 'Descrição', value: this.descricao});
      activity.reg.push({label: 'Processamento', value: this.processamentoOpts.find(el => el.value === this.tipo_proc).name});
      activity.reg.push({label: 'Repartição', value: this.reparticaoOpts.find(el => el.value === this.reparticao).name});
      activity.reg.push({label: 'Rúbrica', value: this.rubricasOptsOrig.find(el => el.value === this.cod_rubrica).name});
      activity.reg.push({label: 'Valor', value: this.valor + ' € (Início: ' + this.utils.getFormatedDate(this.dt_valor) + ')'});
      
      let forma = this.appConfig.pagamentoOtps.find(el => el.value === this.form_pagam);
      let conta = this.contaOptsOrig.find(el => (el.value === this.cod_conta_bancaria));
      let value = '';
      value += (this.forma_agendamento === 'LP' ? 'Lançar e Agendar Pagamento' : 'Lançar Despesas');
      if (forma && conta) {
        value += ' - ' + forma.name + ' · ' + conta.name;
      }
      value += ' (Início: ' + this.utils.getFormatedDate(this.dataInicioPagamento) + ')';
      activity.reg.push({label: 'Método de Lançamento', value: value});


      //TODO Obter Datas Agendadas
      // let datasAgend = despesas.filter(el => el.isAgendada);
      let datasAgend = [];
      if (datasAgend.length) {
        value = this.utils.getFormatedDate(datasAgend[0].dt_desp);
        if (datasAgend.length > 1) {
          value += ' até ' + this.utils.getFormatedDate(datasAgend[datasAgend.length - 1].dt_desp);
        }
        activity.reg.push({label: 'Datas Agendadas', value: value});
      }

      regActInitial = [activity];
    } else if (this.isDetailsAgendamento) {
      let updated = false;
      let activity = {
        title: 'Agendamento atualizado',
        reg: [],
        activity: 'Actualizado por ',
        user: this.userSession.getUserFullName(),
        date: new Date(),
      };

      let changes = this.getAgendamentoChanges();

      if (changes.length) updated = true;

      if (changes.find(el => el === 'ESTADO')) {
        activity.reg.push({label: 'Estado', value: this.appConfig.agendamentoEstadoOpts.find(el => el.value === this.estado_agendamento).name + ' (' + this.utils.getFormatedDate(this.dt_estado_agendamento) + ')'});
      } 

      if (changes.find(el => el === 'DESCRICAO')) {
        activity.reg.push({label: 'Descrição', value: this.descricao});
      } 

      if (changes.find(el => el === 'PROCESSAMENTO')) {
        activity.reg.push({label: 'Processamento', value: this.processamentoOpts.find(el => el.value === this.tipo_proc).name});
      } 

      if (changes.find(el => el === 'REPARTICAO')) {
        activity.reg.push({label: 'Repartição', value: this.reparticaoOpts.find(el => el.value === this.reparticao).name});
      }

      if (changes.find(el => el === 'RUBRICA')) {
        activity.reg.push({label: 'Rúbrica', value: this.rubricasOptsOrig.find(el => el.value === this.cod_rubrica).name});
      }

      if (changes.find(el => el === 'VALOR')) {
        activity.reg.push({label: 'Valor', value: this.valor + ' € (Início: ' + this.utils.getFormatedDate(this.dt_valor) + ')'});
      }
      if (changes.find(el => el === 'FORMA_LANCAMENTO')) {
          let forma = this.appConfig.pagamentoOtps.find(el => el.value === this.form_pagam);
          let conta = this.contaOptsOrig.find(el => (el.value === this.cod_conta_bancaria));

          let value = '';
          value += (this.forma_agendamento === 'LP' ? 'Lançar e Agendar Pagamento' : 'Lançar Despesas');
          if (forma && conta) {
            value += ' - ' + forma.name + ' · ' + conta.name;
          }
          value += ' (Início: ' + this.utils.getFormatedDate(this.dataInicioPagamento) + ')';
          
          activity.reg.push({label: 'Método de Lançamento', value: value});
      }

      let dateChanges = this.agendDatesChanged();
      if (dateChanges.agendadas.length || dateChanges.removidas.length || dateChanges.adicionadas.length) {
        updated = true;
        if (dateChanges.agendadas.length) {
          let value = this.utils.getFormatedDate(dateChanges.agendadas[0].dt_desp);
          if (dateChanges.agendadas.length > 1) {
            value += ' até ' + this.utils.getFormatedDate(dateChanges.agendadas[dateChanges.agendadas.length - 1].dt_desp);
          }
          activity.reg.push({label: 'Datas Agendadas', value: value});
        }
        if (dateChanges.removidas.length) {
          let datasCanceladas = dateChanges.removidas.map(el => this.utils.getFormatedDate(el.dt_desp));
          activity.reg.push({label: 'Datas canceladas', value: datasCanceladas.join(' · ')});
        }
        if (dateChanges.adicionadas.length) {
          let datasAdicionadas = dateChanges.adicionadas.map(el => this.utils.getFormatedDate(el.dt_desp));
          activity.reg.push({label: 'Datas adicionadas', value: datasAdicionadas.join(' · ')});
        }
      }
  
      if (updated) {
        regActInitial = [activity].concat(regActInitial);
      }
    }

    return {despesas: despesas, regAct: regActInitial}

  }

  //Array containing field changes
  getAgendamentoChanges(): Array<string> {
    let changes = [];
    if (this.despesaDetails.estado != this.estado_agendamento || this.utils.compareDayDates(this.dt_estado_agendamento, this.utils.getDate(this.despesaDetails.dt_estado)) !== 0) {
      changes.push('ESTADO');
    } 

    if (this.despesaDetails.descricao != this.descricao) {
      changes.push('DESCRICAO');
    } 
    if (this.despesaDetails.tipo_proc != this.tipo_proc) {
      changes.push('PROCESSAMENTO');
    } 

    if (this.despesaDetails.reparticao != this.reparticao) {
      changes.push('REPARTICAO');
    }

    if (this.despesaDetails.cod_rubrica != this.cod_rubrica) {
      changes.push('RUBRICA');
    }
    let prevValor = JSON.parse(this.despesaDetails.datas_valor);
    if (prevValor && prevValor.length) {
      prevValor = prevValor[prevValor.length - 1];
      if (prevValor.valor != this.valor || this.utils.compareDayDates(this.utils.getDate(prevValor.data), this.dt_valor) != 0) {
        changes.push('VALOR');
      }
    }
    //Verificar se o método altera
    let prevLancamento = JSON.parse(this.despesaDetails.datas_lancamento);
    if (prevLancamento && prevLancamento.length) {
      prevLancamento = prevLancamento[prevLancamento.length - 1];
      if (prevLancamento.forma_agendamento != this.forma_agendamento || prevLancamento.form_pagamento != this.form_pagam || prevLancamento.cod_conta_bancaria != this.cod_conta_bancaria || this.utils.compareDayDates(this.utils.getDate(prevLancamento.data), this.dataInicioPagamento) != 0) {
        changes.push('FORMA_LANCAMENTO');
      }
    }
    return changes;
  }

  getDespAtBD(despDate:Date) {
    return this.agendamentoDetails.find(el => this.utils.compareDayDates(this.utils.getDate(el.dt_desp), despDate) == 0)
  }
  
  agendDatesChanged(): {removidas:Array<any>, agendadas:Array<any>, adicionadas:Array<any>} {
    let result = {removidas: [], agendadas: [], adicionadas: []};
  
    this.agendDates.forEach(desp => {
      let prevDesp = this.getDespAtBD(desp.dt_desp);
      if (!prevDesp) {
        result.agendadas.push(desp);
      }
    });

    this.agendDatesRemovidas.forEach(desp => {
      let prevDesp = this.getDespAtBD(desp.dt_desp);
      if (!prevDesp || prevDesp.active != '0') {
        result.removidas.push(desp);
      }
    })


    this.agendamentoDetails.filter(el => el.active == '1' && this.canChangeDespDate(el.id)).forEach(prevDesp => {
      let prevDate = this.utils.getDate(prevDesp.dt_desp);
      if (!this.agendDates.find(el => this.utils.compareDayDates(prevDate, el.dt_desp) == 0) && !result.removidas.find(el => this.utils.compareDayDates(el.dt_desp, prevDate) == 0)) result.removidas.push({...prevDesp, dt_desp: prevDate});
    })

    this.agendamentoDetails.filter(el => el.active == '0').forEach(prev => {
      let isBackAtDespesas = this.agendDates.find(el => this.utils.compareDayDates(el.dt_desp, this.utils.getDate(prev.dt_desp)) == 0);
      if (isBackAtDespesas) result.adicionadas.push(isBackAtDespesas);
    })


    result.agendadas.sort((a,b) => this.utils.compareDayDates(a.dt_desp, b.dt_desp));
    result.removidas.sort((a,b) => this.utils.compareDayDates(a.dt_desp, b.dt_desp));
    result.adicionadas.sort((a,b) => this.utils.compareDayDates(a.dt_desp, b.dt_desp));
    return result;
  }

  presentDataDespesaSuperiorPagamentoModal():Promise<boolean> {
    return new Promise((resolve) => {
      this.changeDateModalRef = this.modalService
      .open(this.changeDateAlertConfig)
      .onApprove(() => {
        resolve(true);
      })
      .onDeny(() => {
        resolve(false);
      });
    });
  }
  

  async formSubmitted(payment=false): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (this.saving) {
        resolve(false);
        return;
      }

      this.submittingForm = true;
      let inputValid = await this.validateInput();
      if (!inputValid) {
        setTimeout(() => { this.submittingForm = false; }, 4000);
        resolve(false);
        return;
      }
      
      if (this.dt_pag && this.utils.compareDayDates(this.dt_desp, this.dt_pag) > 0) {
        let res = await this.presentDataDespesaSuperiorPagamentoModal();
        if (!res) {
          resolve(false);
          return;
        }
        this.dt_desp = new Date(this.dt_pag);
      }

      this.total = Math.round(Number(this.total) * 100) / 100;
      this.valor = Math.round(Number(this.valor) * 100) / 100;

      this.movimentosTotal = 0;
      this.movimentos.forEach(el => { this.movimentosTotal += el['valor']; });

      if (!this.isCreateLote && this.reparticao === 'M' && this.total !== this.valor) {
        this.submittingForm = false;
        resolve(false);
        return;
      }

      if (!this.modalPagOpen) this.loading = true;
  
      let cod_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
      let cod_fornecedor = this.cod_fornecedor? (this.cod_fornecedor.hasOwnProperty('value') ? this.cod_fornecedor.value.cod : this.cod_fornecedor.cod) : null;
  
      this.exercicio = this.dt_desp.getFullYear();
      this.orcamentada = (this.tipo_proc === 'O') ? '1' : '0';

      // CHECK TIPO PAGAMENTO
      let tipoPag = '';
      let conta = this.contaOpts.find(el => (el.value === this.cod_conta_bancaria));
      if (conta) { // PAGAMENTO EM NUMERARIO
        tipoPag = (conta.name === 'CAIXA') ? ' -N' : ' -T';
      }

      this.registoAct = this.despesaDetails? JSON.parse(this.despesaDetails.obj) : [];

      // REGISTO DE ACTIVIDADES ---------------------------------------
      let activity = {
        msg: null,
        activity: (this.isCreate) ? 'Criado por ' : 'Actualizado por ',
        user: this.userSession.getUserFullName(),
        date: new Date(),
      };

      if (this.isCreate) {
        activity.msg = 'Despesa lançada. Data de lançamento: ' + this.utils.getFormatedDate(new Date(this.dt_desp)) + ', Valor: ' + this.valor + ' €.';
        this.registoAct = [activity].concat(this.registoAct);
      } else {
        activity.msg = 'Despesa actualizada. Data de lançamento: ' + this.utils.getFormatedDate(new Date(this.dt_desp)) + ', Valor: ' + this.valor + ' €.';
        this.registoAct = [activity].concat(this.registoAct);
      }
      // ----------------------------------------------------------------

      let pagamentoParcial = Number((this.pagamentoParcial + Number(this.valor_pago)) * 100) / 100;

      

      let tipoDespesa = (this.isDespesaCtt) ? 'CTT' : null;

      if (this.valor < -1 * this.movimentosTotal && (Array.isArray(this.movimentos) && this.movimentos.length > 1)) {
        this.toastr.error('Valor da despesa inferior ao valor pago até à data. Por favor, actualize primeiro o valor do(s) movimento(s)', 'Alerta', { timeOut: 4000 });
        this.loading = false;
        resolve(false);
        return;
      }

      let isDespesaDateOk = !this.movimentos.find(el => this.utils.compareDayDates(this.dt_desp, el.dt_mov) > 0);

      if (Array.isArray(this.movimentos) && this.movimentos.length === 1 && Math.abs(this.movimentos[0].valor) == this.valorOriginal && (this.valorOriginal !== this.valor || ((new Date(this.dtDespOriginal)).getTime() !== (new Date(this.dt_desp)).getTime()))) {
        this.updateMovModalRef = this.modalService
          .open(this.updateMovAlertConfig)
          .onApprove(async () => {
            let dangerChange = this.movimentos[0].id_caixa_vertis && (this.utils.compareDayDates(this.movimentos[0].dt_mov, new Date()) < 0);
            if (dangerChange) {
              if (!this.userSession.isSuperAdmin()) {
                this.toastr.error('Movimento registado em caixa vertis. Por favor contacte o administrador de sistema.', 'Permissão Negada', { timeOut: 4000 });
                this.loading = false;
                this.loadingModal = false;
                resolve(false);
                return;
              } else {
                this.caixaVertisWarnDates = [this.movimentos[0].dt_mov];
                this.changeCVModalRef = this.modalService.open(this.changeCVAlertConfig)
                .onApprove(async () => {
                  this.caixaVertisWarnDates = [];
                  let dt_mov = new Date(this.movimentos[0].dt_mov);
                  this.dt_pag = dt_mov;
                  let res = await this.saveDespesa(cod_condominio, cod_fornecedor, pagamentoParcial, tipoDespesa, tipoPag, payment, this.movimentos[0].id, dt_mov, this.movimentos[0].id_caixa_vertis);
                  resolve(res);
                  return;
                })
                .onDeny(() =>  {
                  this.caixaVertisWarnDates = [];
                  this.loadingModal = false;
                  this.loading = false;
                  resolve(false);
                });
                return;
              }
            }

            // UPDATE MOVIMENTO
            let dt_mov = new Date(this.dt_desp);
            this.dt_pag = dt_mov;
            let res = await this.saveDespesa(cod_condominio, cod_fornecedor, pagamentoParcial, tipoDespesa, tipoPag, payment, this.movimentos[0].id, dt_mov, this.movimentos[0].id_caixa_vertis);
            resolve(res);
          })
          .onDeny(async () => {
            this.movimentosTotal = 0;
            this.movimentos.forEach(el => { this.movimentosTotal += el.valor; });
            // DO NOT UPDATE MOVIMENTO
            if (this.valor < -1 * this.movimentosTotal) {
              this.toastr.error('Valor da despesa inferior ao valor pago até à data. Por favor, actualize primeiro o valor do(s) movimento(s)', 'Alerta', { timeOut: 4000 });
              this.loading = false;
              resolve(false);
              return;
            }
            if (!isDespesaDateOk) {
              this.toastr.error('Data da despesa superior à data de pagamento. Por favor, actualize primeiro a data do movimento associado.', 'Alerta', { timeOut: 4000 });
              this.loading = false;
              resolve(false);
              return;
            }
            let res = await this.saveDespesa(cod_condominio, cod_fornecedor, pagamentoParcial, tipoDespesa, tipoPag, payment);
            resolve(res);
          });
      } else {
        if (!isDespesaDateOk) {
          this.toastr.error('Data da despesa superior à data do(s) movimento(s). Por favor, actualize primeiro a data do(s) movimento(s) associados.', 'Alerta', { timeOut: 4000 });
          this.loading = false;
          this.loadingModal = false;
          resolve(false);
          return;
        }
        let res = await this.saveDespesa(cod_condominio, cod_fornecedor, pagamentoParcial, tipoDespesa, tipoPag, payment);
        resolve(res);
      }
    });
  }

  
  async checkMovimento(event, mov) {
    event.preventDefault();
    if (mov.checked) {
      setTimeout(() => {
        mov.checked = false;
      });
      return;
    }
    let canEdit = await this.movimentosService.canChangeMovimento(mov.id);
    if (!canEdit) return;
    
    let isInCVHistory = mov.id_caixa_vertis && mov.dt_mov && this.utils.compareDayDates(mov.dt_mov, new Date()) < 0;
    let isInCvNow = mov.id_caixa_vertis && mov.dt_mov && this.utils.compareDayDates(mov.dt_mov, new Date()) === 0;
    
    if (isInCvNow || isInCVHistory) {
      this.toastr.warning('Movimento registado em caixa vertis.', 'Atenção');
    }
    mov.checked = true;
  }
  
  openRegCttModal() {
    // if (this.registoCtt && this.registoCtt.hasOwnProperty('condominos_obj') && this.registoCtt.condominos_obj) {
    //   let auxObj = JSON.parse(this.registoCtt.condominos_obj);

    //   this.descricaoLivreCtt = auxObj['descricao'];
    //   this.condominoSelected = auxObj['selectedEntry'];
    //   if (this.condominoSelected === 'DESCRICAO') this.descricaoLivreCttEnabled = true;
    //   auxObj['condList'].forEach(el => {
    //     let aux = this.condominosList.find(it => (it.condominoCod === el));
    //     if (aux) aux['checked'] = true;
    //   });
    // }

    this.condominosModalRef = this.modalService
      .open(this.condominosAlertConfig)
      .onApprove(() => {
        this.loadingModalCondominos = false;
        this.idDespesaTemp = null;
        this.nDespesaTemp = null;
        this.condominosModalRef = null;
        if (!this.hasGoneBack) { this.hasGoneBack = true; this.location.back(); }
    })
    .onDeny(() => { 
      this.loadingModalCondominos = false;
    });

    setTimeout(() => {
      fromEvent(this.condominoTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
        this.tableSearch(val['target']['value'], 'CONDOMINOS');
      });
    }, 500);
  }

  isCtt(): boolean {
    return this.isDespesaCtt /*|| (this.correspRubricas.find(el => el.value == this.cod_rubrica) != null) || (this.correspFornecedores.find(el => el.value == this.cod_fornecedor) != null)*/;
  }

  saveDespesa(cod_condominio, cod_fornecedor, pagamentoParcial, tipoDespesa, tipoPag, payment, movimentoId=null, dtMovimento=null, movimentoIdCaixaVertis=null): Promise<boolean> {
    return new Promise((resolve) => {
      var is_ctt = this.isCtt();
    
      //IF is ctt (create condition based on rubrica & fornecedor)
      if (!this.condominosModalRef && this.isCreate && is_ctt) {
        this.modalFormSubmitted = false;
        
        this.condominosModalRef = this.modalService
            .open(this.condominosAlertConfig)
            .onApprove(async () => {
              let res = await this.saveDespesa(cod_condominio, cod_fornecedor, pagamentoParcial, tipoDespesa, tipoPag, payment, movimentoId, dtMovimento, movimentoIdCaixaVertis);
              resolve(res);
            })
            .onDeny(() => { 
              
              this.condominosModalRef = null;
              this.loadingModalCondominos = false;
              this.loading = false;
              this.loadingModal = false;
              resolve(false);
              return;
            });
            
            setTimeout(() => {
              fromEvent(this.condominoTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
                this.tableSearch(val['target']['value'], 'CONDOMINOS');
              });
            }, 500);
        return;
      }
          
      this.saving = true;
  
      let descricaoCV = '';
      let nome_fornecedor = this.cod_fornecedor ? (this.cod_fornecedor.hasOwnProperty('value') ? this.cod_fornecedor.value.nome : this.cod_fornecedor.nome) : null;
      if (nome_fornecedor) descricaoCV = nome_fornecedor; 
      
      let nome_rubrica = this.rubricasOpts.find(el => (el.value === this.cod_rubrica));
      if (nome_rubrica) descricaoCV = (descricaoCV !== '') ? descricaoCV + ' / ' + nome_rubrica.name : nome_rubrica.name;
      
      descricaoCV = descricaoCV.replace(/\s\s+/g, ' ');
      let conta = this.contaOptsOrig.find(el => (el.value === this.cod_conta_bancaria));
      payment = payment && (conta.name === 'CAIXA');
  
      if (is_ctt) {
        var id_registo_ctt = (this.registoCtt) ? this.registoCtt['id'] : null;
        var nome_condomino = this.getCondominiosDescricaoCtt();
        var cod_condomino = null;
        var conta_simples = '0';
        var correiro_normal = '0';
        var registado = '0';
        var aviso_recepcao = (this.avisoRececao) ? '1' : '0';
        var n_envelope = Number(this.nEnvelopes);
        var n_folhas_env = Number(this.nFolhasPorEnvelope);
        var custo_vertis = this.custoVertis;
        var custo_ctt = this.custoCtt;
        var valor_lancado = Math.round((Number(this.computedValue) * 100)) / 100;
        var data = (this.registoCtt) ? this.registoCtt.data : new Date();
  
        switch (this.tipoCorreio) {
          case 'simples':
            conta_simples = '1';
            break;
          case 'normal':
            correiro_normal = '1';
            break;
          case 'registado':
            registado = '1';
            break;
        }
  
        var condObj = {
          selectedEntry: this.condominoSelected,
          condList: [],
          descricao: this.descricaoLivreCtt,
        };
        this.condominosList.filter(el => (el.checked)).forEach(el => {
          condObj.condList.push(el.condominoCod);
        });
      }

      let despesaFilesPackage: Array<{ action, id, base64, ext, filename }> = [];
      this.despesaFilesOrig.forEach(orig => {
        let file = this.despesaFiles.find(el => el.id === orig.id);
        if (!file) {
          despesaFilesPackage.push({ action: 'REMOVE', id: orig.id, base64: null, ext: null, filename: null });
          return;
        }
        if (file.file !== orig.file) {
          despesaFilesPackage.push({ action: 'UPDATE', id: orig.id, base64: file.file, ext: file.ext, filename: file.filename });
        }
      })
      this.despesaFiles.filter(el => !el.id).forEach(el => {
        despesaFilesPackage.push({action: 'INSERT', id: null, base64: el.file, ext: el.ext, filename: el.filename })
      });


      //TODO Remove in future
      //If limpeza
      let valorLimpeza = null;
      if (this.updateValorLimpeza) {
        valorLimpeza = this.valorLimpeza;
      }
  
      
      this.api.saveDespesa(despesaFilesPackage, this.zonaList, this.despesaId, this.despesaCod, cod_condominio, this.n_despesa, this.cod_rubrica, this.cod_sub_rubrica, cod_fornecedor, this.tipo_proc, this.cod_proc, this.dtDespOriginal, this.dt_desp, this.dt_pag, this.dt_pag_valor, this.dt_venc, this.valor, this.valor_pago, this.n_documento, this.form_pagam, this.cod_conta_bancaria, this.descricao, this.n_doc_pagam, this.ref_interna, this.nid_movimento, this.reparticao, JSON.stringify(this.registoAct), pagamentoParcial, this.estadoPagamento, descricaoCV, payment, is_ctt, tipoDespesa, tipoPag, movimentoId, dtMovimento, movimentoIdCaixaVertis, id_registo_ctt, nome_condomino, cod_condomino, conta_simples, correiro_normal, registado, aviso_recepcao, null, null, n_envelope, n_folhas_env, custo_vertis, custo_ctt, valor_lancado, null, condObj, data, valorLimpeza).subscribe(res => {
        if (res.hasOwnProperty('success') && res['success']) {
          if (this.condominosModalRef) this.condominosModalRef.approve();
          if (!this.hasGoneBack) { this.hasGoneBack = true; this.location.back(); }
          resolve(true);
          } else {
            if (movimentoId && res.status === 'PAYMENT_ACCESS_DENIED') {
              this.valor = Number(this.despesaDetails.valor);
            }
            this.utils.apiErrorMsg(res);
            this.dt_pag = this.despesaDetails? this.utils.getDate(this.despesaDetails.dt_pag) : null;
            resolve(false);
          }
  
          this.pagamentoParcial = Number(pagamentoParcial);
  
          this.submittingForm = false;
          this.loading = false;
          this.saving = false;
          this.loadingModalCondominos = false;
          this.loadingModal = false;
          this.condominosModalRef = null;
          
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.dt_pag = this.despesaDetails? this.utils.getDate(this.despesaDetails.dt_pag) : null;
          this.loadingModalCondominos = false;
          this.condominosModalRef = null;
          this.submittingForm = false;
          this.loadingModal = false;
          this.loading = false;
          this.saving = false;
          resolve(false); 
      });
    })
  }

  estadoPagamento = null;
  setPagParcial(fromMovimentos=false) {
    this.pagamentoParcial = 0;
    this.pagEmFalta = 0;

    if (!fromMovimentos) {
      this.pagamentoParcial = Number(this.despesaDetails['valor_liquidado']);
    } else {
      this.pagamentoParcial = (-1 * this.movimentosTotal);
    }

    let aux =Math.round(this.despesaDetails.valor * 100) / 100
    this.pagEmFalta = Number(aux - this.pagamentoParcial).toFixed(2);

    let hasParcial = false;
    if (Array.isArray(this.registoAct)) {
      let aux = this.registoAct.find(el => (el && el.hasOwnProperty('msg') && el.msg.indexOf('parcial') !== -1));
      if (aux) hasParcial = true;
    }

    // SET ESTADO DO PAGAMENTO
    if (this.pagamentoParcial >= Number(this.valor) && !hasParcial) {
      this.estadoPagamento = 'PAGO';
    } else if (this.pagamentoParcial < Number(this.valor) && this.pagamentoParcial > 0 || hasParcial) {
      this.estadoPagamento = 'PARCIAL_PAGO';
    } else {
      this.estadoPagamento = 'NAO_PAGO';
    }
  }

  hasMovimentos = false;
  dtDespOriginal = null;
  movimentos: Array<Movimento & {banco, despesa_id, checked}> = [];
  movimentosTotal = 0;
  linhasDespesasDetails = [];
  isDeleted = false;
  firstCallAux = true;
  despesaActiveTab = true;
  despInCV = false;
  async getDespesaDetails() {
    if (this.appState.goToMovimentos && !(this.isCreate || this.isDetailsAgendamento)) {
      setTimeout(() => { this.despesaActiveTab = false; }, 1);
    }
    
    this.api.getDespesaDetails(this.despesaId).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.despesaDetails = res.data;
        this.linhasDespesasDetails = res.linhas_despesas;

        this.isLimpezaDespesa = this.despesaDetails.nome_fornecedor.indexOf('Limpeza V') !== -1 && (this.userSession.getUsername() === 'fred' || this.userSession.getUsername() === 'sergio.assuncao');
        this.valorLimpeza = this.despesaDetails['valor_limpeza'];
    
        this.isDeleted = (this.despesaDetails.active === '0');

        if (this.despesaDetails.hasOwnProperty('n_agendamento') && this.despesaDetails.n_agendamento) this.nAgend = this.despesaDetails.n_agendamento;

        this.isFromAgendamento = (this.despesaDetails.hasOwnProperty('n_agendamento') && this.despesaDetails.n_agendamento);

        this.valorOriginal = Number(this.despesaDetails.valor);
        this.dtDespOriginal = this.utils.getDate(this.despesaDetails.dt_desp);

        this.movimentosTotal = 0;
        // PRE PROCESS MOVIMENTOS
        this.movimentos = res.movimentos.map(el => {
          el = this.businessLogic.convertMovimentoType(el);
          el['despesa_id'] = this.despesaDetails.id;
          this.movimentosTotal += el['valor'];
          return el;
        });
        this.despInCV = !!this.movimentos.find(el => !!el.id_caixa_vertis);

        this.movimentos.sort((a, b) => {
          if ( a['id'] < b['id'] ){
            return -1;
          }
          if ( a['id'] > b['id'] ){
            return 1;
          }
          return 0;
        });

        this.hasMovimentos = this.movimentos.length > 0;

        let condMoradaAux = this.despesaDetails.cond_morada;
        // HANDLE OBJECT MORADA - CONDOMINIO
        try {
          let aux = '';
          let addressObj = JSON.parse(this.despesaDetails.cond_morada);
          Object.keys(addressObj).forEach((key, i) => {
            if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
          });

          condMoradaAux = aux;
          this.despesaDetails.cond_morada = (aux) ? aux.replace(/NEW_LINE/g, ', ') : null;
        } catch(e) {}
        this.despesaDetails.cond_morada_pdf = (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [];

        this.despesaCod = this.despesaDetails.cod;

        this.isDespesaCtt = (this.despesaDetails && this.despesaDetails.hasOwnProperty('tipo_despesa') && this.despesaDetails.tipo_despesa === 'CTT');

        if (this.isDespesaCtt) {
          this.rubricasOptsOrig = this.correspRubricas;
          this.rubricasOpts = JSON.parse(JSON.stringify(this.rubricasOptsOrig));
          this.rubricaSelected();
          this.getRegistoCttDetails();
        }

        this.restoreForm('despesa');

        if (this.despesaDetails.obj && this.firstCallAux) {
          this.registoAct = JSON.parse(this.despesaDetails.obj);
          this.registoAct = this.registoAct.filter(el => (el !== null));
          this.firstCallAux = false;
        }

        this.setPagParcial();

        this.getCondominioDetails();

        this.fetchDone = true;

      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });

    try {
      this.despesaFilesOrig = await this.despesasService.getDespesaFiles(this.despesaId);
      this.despesaFiles = JSON.parse(JSON.stringify(this.despesaFilesOrig));
    } catch (err) {
      this.despesaFilesOrig = [];
      this.despesaFiles = [];
    }
  }

  agendMovimentos = [];
  agendDespWithPagParcial = [];
  nAgend = null;
  despesaAgendDetails:Array<despesa_agendamento> = [];
  movimentosSub: Subscription = null;
  getAgendDespesaDetails() {
    this.agendDatesFetched = false;
    this.agendDatesMovsFetched = false;
    this.api.getAgendDespesaDetails(this.codCondominio, this.nAgendamento).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.despesaAgendDetails = res.data.despesas;
        this.despesaDetails = res.data.agendamento;
        this.registoAct = res.data.agendamento.obj? JSON.parse(res.data.agendamento.obj) : [];
        
        this.agendamentoDetails = res.data.despesas;
        this.despesaCod = this.despesaDetails.cod;
        if (this.agendamentoDetails.length > 0) {
          this.valorOriginal = Number(this.agendamentoDetails[0]['valor']);
        }

        this.isLimpezaDespesa = this.despesaDetails.nome_fornecedor.indexOf('Limpeza V') !== -1 && (this.userSession.getUsername() === 'fred' || this.userSession.getUsername() === 'sergio.assuncao');
        let nextDesp = this.despesaAgendDetails.find((el, i) => this.utils.compareDayDates(new Date(el.dt_desp), this.now) === 0 || i === this.despesaAgendDetails.length - 1);
        this.valorLimpeza = nextDesp ? nextDesp['valor_limpeza'] : null;

        this.hasPastInactiveStartDate = this.despesaDetails && this.despesaDetails.estado === '0' && this.utils.compareDayDates(this.utils.getDate(this.despesaDetails.dt_estado), this.now) <= 0; 

        this.restoreForm('despesa');


        this.getCondominioDetails();

        this.agendDatesFetched = true;
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });

    // GET MOVIMENTOS AGENDAMENTO
    this.api.getAgendDespesaMov(this.codCondominio, this.nAgendamento).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.agendMovimentos = res.data;
        this.agendMovimentos.forEach(el => {
          if (el.movimentos.length > 1) {
            this.agendDespWithPagParcial.push({
              id: el.id_despesa,
              n_despesa: el.n_despesa,
              dt_desp: el.dt_desp,
            });
          }
        });
        this.agendDatesMovsFetched = true;
      } else {}
    }, err => {});
  }
  

  restoreForm(targetForm) {
    switch (targetForm) {
      case 'despesa':
        this.valor_pago = null;
        if (this.despesaDetails) {
          this.cod_condominio = { name: this.despesaDetails.cod_condominio + ' - ' + this.despesaDetails.nome_condominio, value: { cod: this.despesaDetails.cod_condominio, nome: this.despesaDetails.nome_condominio } };
          this.n_despesa = this.despesaDetails.n_despesa;
          this.exercicio = this.despesaDetails.exercicio;
          this.cod_rubrica = this.despesaDetails.cod_rubrica;
          this.cod_sub_rubrica = this.despesaDetails.cod_sub_rubrica;
          this.cod_fornecedor = { name: this.despesaDetails.nome_fornecedor, value: { cod: this.despesaDetails.cod_fornecedor, nome: this.despesaDetails.nome_fornecedor } };
          this.tipo_proc = this.despesaDetails.tipo_proc;
          this.cod_proc = this.despesaDetails.cod_proc;
          this.dt_desp = this.utils.getDate(this.despesaDetails.dt_desp);
          this.dt_pag_valor = this.utils.getDate(this.despesaDetails.dt_pag_valor);
          this.dt_pag = this.utils.getDate(this.despesaDetails.dt_pag);
          this.dt_venc = this.utils.getDate(this.despesaDetails.dt_venc);
          this.data_pagamento_despesa = null;
          this.n_documento = this.despesaDetails.n_documento;
          this.form_pagam = this.despesaDetails.form_pagam;
          this.cod_conta_bancaria = this.despesaDetails.cod_conta_bancaria;
          this.descricao = this.despesaDetails.descricao;
          this.n_doc_pagam = this.despesaDetails.n_doc_pagam;
          this.ref_interna = this.despesaDetails.ref_interna;
          this.nid_movimento = this.despesaDetails.nid_movimento;
          this.orcamentada = this.despesaDetails.orcamentada;
          this.reparticao = this.despesaDetails.reparticao;
        } else {
          this.cod_condominio = null;
          this.n_despesa = null;
          this.exercicio = null;
          this.cod_rubrica = null;
          this.cod_sub_rubrica = null;
          this.cod_fornecedor = null;
          this.tipo_proc = 'O';
          this.cod_proc = null;
          this.dt_desp = new Date();
          this.dt_pag = null;
          this.dt_venc = null;
          this.data_pagamento_despesa = null;
          this.n_documento = null;
          this.form_pagam = null;
          this.cod_conta_bancaria =null;
          this.descricao = null;
          this.n_doc_pagam = null;
          this.ref_interna = null;
          this.nid_movimento = null;
          this.orcamentada = null;
          this.reparticao = 'PO';
        }
        
        
        this.tipo_proc = (this.isCreateLote) ? 'E' : this.tipo_proc;
        
        if (this.isDetailsAgendamento) {
          this.dt_estado_agendamento = (this.despesaDetails) ? this.utils.getDate(this.despesaDetails.dt_estado) : null;
          this.estado_agendamento = (this.despesaDetails) ? this.despesaDetails.estado : null;
          this.periodicitySel = (this.despesaDetails) ? this.despesaDetails.periodicidade : null;
          this.startDateAgendamento = (this.despesaDetails) ? this.utils.getDate(this.despesaDetails.start_date) : null;
          this.endDateAgendamento = (this.despesaDetails) ? this.utils.getDate(this.despesaDetails.end_date) : null;
          setTimeout(() => {
            this.periodicitySel = (this.despesaDetails) ? this.despesaDetails.periodicidade : null;
            this.startDateAgendamento = (this.despesaDetails) ? this.utils.getDate(this.despesaDetails.start_date) : null;
            this.endDateAgendamento = (this.despesaDetails) ? this.utils.getDate(this.despesaDetails.end_date) : null;
          });

          this.datas_valor = (this.despesaDetails && this.despesaDetails.datas_valor) ? (JSON.parse(this.despesaDetails.datas_valor) as Array<any>).map(el => {
            el['data'] = this.utils.getDate(el['data']);
            return el;
          }) : [];
          
          this.datas_lancamento = (this.despesaDetails && this.despesaDetails.datas_lancamento) ? (JSON.parse(this.despesaDetails.datas_lancamento) as Array<any>).map(el => {
            el['data'] = this.utils.getDate(el['data']);
            return el;
          }) : [];

          //Sets valor field to last choosen
          this.valor = this.datas_valor.length? this.datas_valor[this.datas_valor.length - 1].valor : null;
          this.dt_valor = this.datas_valor.length? this.datas_valor[this.datas_valor.length - 1].data : null;


          //Sets forma lancamento to last choosen
          if (this.datas_lancamento.length) {
            let lastEntry = this.datas_lancamento[this.datas_lancamento.length - 1];
            this.forma_agendamento = lastEntry.forma_agendamento;
            this.dataInicioPagamento = lastEntry.data;
            this.form_pagam = lastEntry.form_pagamento;
            if (this.contaOptsOrig.length) {
              this.paymentOptChangedFormaLancamento();
            }
            this.cod_conta_bancaria = lastEntry.cod_conta_bancaria;
            setTimeout(() => this.cod_conta_bancaria = lastEntry.cod_conta_bancaria);
          }

          if (this.reparticao === 'M' && this.despesaDetails.reparticao_dist) {
            this.reparticaoDist = JSON.parse(this.despesaDetails.reparticao_dist);
          }

          this.genAgendamento(true,true);
        } else {
          this.valor = (this.despesaDetails) ? Math.round(Number(this.despesaDetails.valor) * 100) / 100 : null;
          this.rubricaSelected();
        }
        break;
    }
    this.checkIfIsEditing();
  }

  isFormValid() {
    if (this.isCreateLote) {
      return (this.cod_rubrica && this.cod_fornecedor && this.dt_desp && this.valor && this.descricao);
    } else if (this.isCreateAgendamento || this.isDetailsAgendamento) {
      let datesValid = this.startDateAgendamento && this.endDateAgendamento && this.utils.compareDayDates(this.startDateAgendamento, this.endDateAgendamento) <= 0 
                    && this.dt_valor;

      if (this.reparticao === 'M' && this.actualValor != this.total) {
        this.toastr.error('O valor do agendamento é diferente do total das repartições pelas zonas.', 'Ajuste Manual Necessário', { timeOut: 4000 });
        return false;
      }

      if (!this.isValid('dt_estado')) return false;
      if ((this.form_pagam == null) !== (this.cod_conta_bancaria == null)) return false;
      if (!this.dataInicioPagamento) return false;
      if (this.forma_agendamento == 'LP' && (!this.form_pagam || this.form_pagam !== '1')) return false;

      if (!this.isValid('agendamento-dates')) {
        this.toastr.error('Não é possível criar agendamentos sem despesas.','Alerta');
        return false;
      }

      return (this.cod_condominio && this.reparticao && this.descricao && this.descricao.trim() !== '' && datesValid && this.periodicitySel && this.cod_rubrica && this.cod_fornecedor && this.valor);
    } else {
      return (this.cod_condominio && this.cod_rubrica && this.cod_fornecedor && this.dt_desp && this.valor && this.descricao);
    }
  }

  isValid(label:string) {
    switch (label) {
      case 'agendamento-dates':
        return this.agendDates.length > 0 || this.agendDatesRemovidas.length > 0;
    
      case 'dt_estado':
        return this.dt_estado_agendamento && (this.utils.compareDayDates(this.dt_estado_agendamento, this.now) >= 0 || (this.despesaDetails && this.estado_agendamento === this.despesaDetails.estado && this.utils.compareDayDates(this.dt_estado_agendamento, this.utils.getDate(this.despesaDetails.dt_estado)) === 0));
    
      default:
        break;
    }
    return false;
  }

  calcManualModeSelected() {
    setTimeout(() => {
      if (!this.calcManualMode) {
        this.computeCorrespValue();
      }
    }, 1);
  }

  hasProcessamento() {
      return new Promise((resolve, reject) => {
        if (this.tipo_proc === 'S' || this.tipo_proc === 'E') {
          resolve(true);
        } else {
          let cod_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
          let exercicio = this.dt_desp.getFullYear();
          let mes = this.dt_desp.getMonth() + 1;
          // CHECK IF PROCESSAMENTO EXISTES
          this.api.isRubricaOrcamentada(cod_condominio, exercicio, mes, this.cod_rubrica).subscribe(res => {
            if (res.hasOwnProperty('success') && res['success']) {
              resolve(true);
            } else {
              resolve(false);
            }
          }, err => { resolve(false) });
        }
      });
  }

  hasProcessamentoForEach():Promise<Array<{month, year}>> {
    let req = [];

    return new Promise(async (resolve, reject) => {
      if (this.tipo_proc === 'S' || this.tipo_proc === 'E') {
        resolve([]);
      } else {
        let monthsWithoutProc:Array<{month, year}> = [];
        let months:Array<{month, year}> = [];
        
        let cod_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
        if (this.isCreateAgendamento || this.isDetailsAgendamento) {
          if (this.agendDates.length > 0) {
            let hasProc = null;
            
            let mes = null;
            let exercicio = null;
  
            for (const el of this.agendDates) {
              exercicio = el.dt_desp.getFullYear();
              mes = el.dt_desp.getMonth() + 1;
  
              months.push({month: mes - 1, year: exercicio});
              req.push(this.api.isRubricaOrcamentada(cod_condominio, exercicio, mes, this.cod_rubrica));
            }
          }
        } else {
          let exercicio = this.dt_desp.getFullYear();
          let mes = this.dt_desp.getMonth() + 1;
          req.push(this.api.isRubricaOrcamentada(cod_condominio, exercicio, mes, this.cod_rubrica));
          months.push({month: mes - 1, year: exercicio});
        }
    
        if (!req.length) {
          resolve([]);
          return;
        }

        // CHECK IF PROCESSAMENTO EXISTES
        forkJoin(req).subscribe(res => {
          res.forEach((el, i) => {
            if (!el.success) {
              monthsWithoutProc.push(months[i]);
            } 
          });
          resolve(monthsWithoutProc);

        }, err => { resolve([]) });
      }
    });
  }

  async lancarLote() {
    if (this.isCreateLote) {
      this.submittingForm = true;
      if (!(await this.validateInput())) {
        setTimeout(() => { this.submittingForm = false; }, 4000);
        return;
      }
      
      setTimeout(() => {
        fromEvent(this.condTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
          this.tableSearch(val['target']['value']);
        });
      }, 500);

      this.condominiosModalRef = this.modalService.open(this.condominiosAlertConfig)
        .onApprove(() => {
          if (!this.hasGoneBack) { this.hasGoneBack = true; this.location.back(); }
        })
        .onDeny(() => {});
    }
  }
  

  modalPagOpen = false;
  @ViewChild('paymentModal', { static: false }) paymentModal: PaymentModalComponent;
  async openPagamentoModal() {
    this.submittingForm = true;
    let inputValid = await this.validateInput();
    if (!inputValid) {
      setTimeout(() => { this.submittingForm = false; }, 4000);
      return;
    }
    
    
    this.total = this.utils.cleanDecimalDigits(this.total);
    this.valor = this.utils.cleanDecimalDigits(this.valor);

    if (this.reparticao === 'M' && this.total !== this.valor) {
      this.toastr.error('O valor da receita é diferente do total das repartições pelas zonas.', 'Ajuste Manual Necessário', { timeOut: 4000 });
      return;
    }

    let form_pagam = null;
    let cod_conta_bancaria = null;
    let dt:Date = this.dt_desp ? new Date(this.dt_desp) : null;
    let pagEmFalta = this.utils.cleanDecimalDigits(this.valor - (-1 * this.movimentosTotal));
    if (this.userSession.isCaixaVertisLoggedIn()) {
      form_pagam = '1';
      dt = new Date();
      dt.setHours(0,0,0,0);
    } else if (this.despesaDetails && this.despesaDetails.form_pagam_agendamento) {
      form_pagam = this.despesaDetails.form_pagam_agendamento;
      cod_conta_bancaria = this.despesaDetails.cod_conta_bancaria_agendamento;
      if (this.despesaDetails.dt_pag_agendamento 
          && this.utils.compareDayDates(this.utils.getDate(this.despesaDetails.dt_pag_agendamento), new Date()) < 0 
          && this.utils.compareDayDates(dt, this.utils.getDate(this.despesaDetails.dt_pag_agendamento)) < 0) {
        dt = new Date(this.despesaDetails.dt_pag_agendamento);
      }
    } else if (this.isDespesaCtt) {
      form_pagam = '1';
      dt = new Date();
      dt.setHours(0,0,0,0);
    }

    let form: PaymentForm = {
      form_pagam: form_pagam,
      cod_conta_bancaria: cod_conta_bancaria,
      dt_pag: new Date(dt),
      dt_pag_valor: new Date(dt),
      n_doc_pagam: this.n_documento,
      valor_pago: pagEmFalta ? pagEmFalta.toString() : '',
    }

    let modalInput: PaymentModalInput = {
      form: form,
      maxPagamento: pagEmFalta,
      dt_default: this.dt_desp,
      formPagamDisabled: this.isCreateLote,
      dateDisabled: false,
      valorPlaceholder: pagEmFalta + ' € a liquidar',
      minDate: this.isCreateLote? new Date(this.dt_desp) : null,
      payFunction: this.setPayment,
      showSaldo: !this.isCreateLote,
      showNDoc: true,
      type: this.isCreateLote ? 'LOTE' : 'PAY_DESPESA',
      inCaixaVertis: false
    }


    
    this.modalPagOpen = true;
    await this.paymentModal.open(modalInput);
    this.modalPagOpen = false;
    return;
  }

  contaOptChangedFormaLancamento() {
    this.updateDatesPaymentInfoUI();
  }


  allDates = true;
  firstCall = true;
  paymentOptChangedFormaLancamento() {
    if (!this.form_pagam) return;

    if (this.form_pagam === '-1') {
      setTimeout(() => {
        this.form_pagam = null;
        this.cod_conta_bancaria = null;
        this.updateDatesPaymentInfoUI();
      }, 1);
      return;
    }

    if (!this.cod_condominio) {
      this.toastr.info('Para configurar o método de pagamento tem de selecionar o condomínio previamente.','Informação');
      setTimeout(() => {
        this.form_pagam = null;
        this.cod_conta_bancaria = null;
        this.forma_agendamento = 'L';
      }, 1);
      return;
    }

    if (this.fetchingContas) {
      this.toastr.info('Por favor aguarde. As contas associadas ao condomínio selecionado estão a ser obtidas.','Informação');
      setTimeout(() => {
        this.form_pagam = null;
        this.forma_agendamento = 'L';
      }, 1);
      return;
    }
    
    if (!this.contaOptsOrig.length) {
      this.toastr.info('Não é possível configurar o método de pagamento. O condomínio selecionado não tem contas associadas.','Informação');
      setTimeout(() => {
        this.form_pagam = null;
        this.cod_conta_bancaria = null;
        this.forma_agendamento = 'L';
      }, 1);
      this.updateDatesPaymentInfoUI();
      return;
    }

    if (this.form_pagam === '1' || this.form_pagam === '6') {
      if (this.form_pagam === '1') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      }

      if (this.form_pagam === '6') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name.indexOf('CX ADM') !== -1 || el.name.indexOf('CAIXA ADM') !== -1));
      }

      if (this.contaOpts.length === 1) {
        this.cod_conta_bancaria = this.contaOpts[0].value;
      } else {
        this.cod_conta_bancaria = null;
      }

      if (!this.contaOpts.length) {
        this.toastr.info('O condomínio selecionado não tem contas definidas para a forma de pagamento selecionada.','Informação');
        setTimeout(() => {
          this.form_pagam = null;
          this.cod_conta_bancaria = null;
          this.updateDatesPaymentInfoUI();
        }, 1);
        return;
      }


    } else {
      this.contaOpts = this.contaOptsOrig.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1 && el.name.indexOf('CX ADM') === -1 && el.name.indexOf('CAIXA ADM') === -1));

      if (!this.contaOpts.length) {
        this.toastr.info('O condomínio selecionado não tem contas definidas para a forma de pagamento selecionada.','Informação');
        setTimeout(() => {
          this.form_pagam = null;
          this.updateDatesPaymentInfoUI();
        }, 1);
        return;
      }

      if (this.contaOpts.length === 1) {
        this.cod_conta_bancaria = null;
        setTimeout(() => {
          this.cod_conta_bancaria = this.contaOpts[0].value;
        });
      } else {
        let aux = this.contaOpts.find(el => (el.details.conta_principal === '1' && el.name !== 'CAIXA'));
        this.cod_conta_bancaria = null;
        setTimeout(() => {
          this.cod_conta_bancaria = aux ? aux.value : null;
        });
      }

    }
    this.updateDatesPaymentInfoUI();
  }

  rowSelectionToggle(target, ev) {
    switch (target) {
      case 'condominios':
        (ev.target.checked) ? this.condominiosList.map(el => el.checked = true ) : this.condominiosList.map(el => el.checked = false );
        break;
      case 'condominos':
        if (this.condominoSelected === 'DESCRICAO') {
          this.condominoListToggle = false;
          return;
        }

        (ev.target.checked) ? this.condominosList.map(el => el.checked = true ) : this.condominosList.map(el => el.checked = false );
        break;
      case 'zonas':
        (ev.target.checked) ? this.zonaList.map(el => el.checked = true ) : this.zonaList.map(el => el.checked = false );

        this.valorChanged();
        break;
      case 'despesas-agendamento': 
        (ev.target.checked) ? this.agendDates.map(el => el.checked = true ) : this.agendDates.map(el => el.checked = false );
        break;
    }
  }

  agendDates:Array<AgendDate> = [];
  agendDatesRemovidas:Array<AgendDate> = [];
  agendDatesAdd = [];
  periodicitySel = null;
  hasAutoPayments = false;
  genAgendamento(force=false, notCreate=false) {

    if (this.isCreateAgendamento) {
      if (this.startDateAgendamento && this.endDateAgendamento && this.periodicitySel) {
        if (this.utils.compareDayDates(this.startDateAgendamento, this.endDateAgendamento) > 0 ) {
          this.endDateAgendamento = new Date(this.startDateAgendamento.getTime());
          setTimeout(() => this.endDateAgendamento = new Date(this.startDateAgendamento.getTime()),1);
        }
        
        // setTimeout(() => {
          this.dt_valor = this.startDateAgendamento;
          this.dataInicioPagamento = this.startDateAgendamento;
        // });
        this.agendDates = [];
        let startDay = this.startDateAgendamento.getDate();
        if (this.startDateAgendamento.getMonth() === 1 && this.utils.isLastDayOfMonth(this.startDateAgendamento)) {
          if (this.endDateAgendamento.getDate() >= 29) {
            startDay = this.endDateAgendamento.getDate();
          } else if (this.endDateAgendamento.getDate() === startDay && this.endDateAgendamento.getMonth() === 1) {
            startDay = 30;
          }
        }
  
        let monthInc = this.periodicityOpts.find(el => (el.value === this.periodicitySel)).monthInc;

        let nextDate = this.startDateAgendamento;

        // START - GENERATE DESPESAS AGENDAMENTO ------------------------------
        let thisMonth = null;
        let thisYear = null;

        

        // let cod_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
        // let cod_fornecedor = (this.cod_fornecedor && this.cod_fornecedor.hasOwnProperty('cod')) ? this.cod_fornecedor.cod : this.cod_fornecedor.value.cod;
        // let nome_fornecedor = this.cod_fornecedor ? (this.cod_fornecedor.hasOwnProperty('value') ? this.cod_fornecedor.value.nome : this.cod_fornecedor.nome) : null;

        while (nextDate.getTime() <= this.endDateAgendamento.getTime()) {
          
          let forma_lancamento = this.getDespFormLancamento(null, nextDate);
          let valor = this.getDespValue(null, nextDate);
          let obj: AgendDate = {
            cod: null,
            dt_desp: nextDate,
            dt_desp_orig: nextDate,
            obj: '[]',
            descricao: this.utils.getMonthDesc(nextDate) + this.descricao,
            dt_pag: null,
            dt_pag_agendamento: forma_lancamento.forma_agendamento === 'LP' ? nextDate : null,
            paymentMethods: this.getPaymentMethodsUI(null,[],nextDate),
            isLancado: false,
            status: null,
            checked: true,
            id: null,
            n_despesa: null,
            monthDesc: this.utils.getMonthDesc(nextDate),
            movimentos: [],
            autoPay: forma_lancamento.forma_agendamento === 'LP',
            cod_conta_bancaria: null,
            cod_conta_bancaria_agendamento: forma_lancamento.cod_conta_bancaria,
            form_pagam: null,
            form_pagam_agendamento: forma_lancamento.form_pagamento,
            aberta: true,
            valor: valor,
            valor_por_liquidar: valor,
            valor_liquidado: 0,
            removed: false,
          }

          this.agendDates.push(obj);
  
          thisMonth = nextDate.getMonth() + monthInc;
          thisYear = nextDate.getFullYear();
  
          if (thisMonth >= 12) {
            thisMonth = 0;
            thisYear += 1;
          }
  
          if (startDay > 28) {
            // CHECK IF DATE IS VALID BEFORE PUSH
            if ((startDay === 29 || startDay === 30 || startDay === 31) && thisMonth === 1) {  // PROBLEMA COM FEVEREIRO
  
              if (startDay === 29 && this.utils.isValidDate(startDay, thisMonth, thisYear)) {
                nextDate = new Date(thisYear, thisMonth, 29);
              } else {
                nextDate = new Date(thisYear, thisMonth, 28);
              }
            } else if (startDay === 31 && (thisMonth === 3 || thisMonth === 5 || thisMonth === 7 || thisMonth === 8 || thisMonth === 10)) {
              nextDate = new Date(thisYear, thisMonth, 30);
            } else {
              nextDate = new Date(thisYear, thisMonth, startDay);
            }
          } else {
            nextDate = new Date((new Date(nextDate)).setMonth(nextDate.getMonth() + monthInc));
          }
        }
        // END - GENERATE DESPESAS AGENDAMENTO ------------------------------
      }

    }

    if (!force && !this.userIsChangingDates) return;

    if (this.isDetailsAgendamento) {
      if (notCreate) {
        this.agendDates = [];
        this.valorTotalAgendamento = 0;
        this.valorPagoAgendamento = 0;
        this.valorTotalExercAtualAgendamento = 0;
        this.valorPagoExercAtualAgendamento = 0;
  
        this.agendamentoDetails.filter(el => (el.active === '1')).forEach(el => {
          let obj = this.getAgendDatesObj(el);
          this.agendDates.push(obj);
        });
  
        this.hasAutoPayments = !!this.agendDates.find(el => el.autoPay);
  
        // if (this.agendamentoDetails.length > 0) {
        //   this.startDateAgendamento = this.utils.getDate(this.agendamentoDetails[0].dt_desp);
        //   this.endDateAgendamento = this.utils.getDate(this.agendamentoDetails[this.agendamentoDetails.length - 1].dt_desp);
  
        //   if (this.agendamentoDetails.length > 1) {
        //     let monthInc = this.utils.getDate(this.agendamentoDetails[1].dt_desp).getMonth() - this.utils.getDate(this.agendamentoDetails[0].dt_desp).getMonth();
        //     let periodicityAux = this.periodicityOpts.find(el => (el.monthInc === monthInc));
  
        //     this.periodicitySel = (periodicityAux) ? periodicityAux.value : null;
        //   }
        // }
        this.agendDatesRemovidas = [];

        this.agendamentoDetails.filter(el => (el.active === '0')).forEach(el => {
          let obj = this.getAgendDatesObj(el);
          this.agendDatesRemovidas.push(obj);
        });

        this.computeTotalsAgendamento();
      } else {
        if (!this.startDateAgendamento || !this.endDateAgendamento) return;
        if (this.utils.compareDayDates(this.startDateAgendamento, this.endDateAgendamento) > 0 ) {
          this.endDateAgendamento = new Date(this.startDateAgendamento.getTime());
          setTimeout(() => this.endDateAgendamento = new Date(this.startDateAgendamento.getTime()),1);
        }
        if (!this.agendamentoDetails.length) return;
        
        this.extendDates();
      }
    }

    this.sortAgendDates();
  }
  
  computeTotalsAgendamento(): void {
    if (!this.isDetailsAgendamento) return;

    this.valorTotalAgendamento = 0;
    this.valorPagoAgendamento = 0;
    this.valorPagoExercAtualAgendamento = 0;
    this.valorTotalExercAtualAgendamento = 0;
    this.agendDates.forEach(el => {
      //Totais
      this.valorTotalAgendamento += el.valor;
      el.movimentos.forEach(mov => {
        this.valorPagoAgendamento += -1 * Number(mov.valor);
      });

      //Exerc Atual
      if (el.dt_desp_orig.getFullYear() === this.now.getFullYear()) {
        this.valorTotalExercAtualAgendamento += el.valor;
        el.movimentos.forEach(mov => {
          this.valorPagoExercAtualAgendamento += -1 * Number(mov.valor);
        });
      }
    }); 
  }

  extendDates() {
    let startDay = this.startDateAgendamento.getDate();
    if (this.startDateAgendamento.getMonth() === 1 && this.utils.isLastDayOfMonth(this.startDateAgendamento)) {
      if (this.endDateAgendamento.getDate() >= 29) {
        startDay = this.endDateAgendamento.getDate();
      } else if (this.endDateAgendamento.getDate() === startDay && this.endDateAgendamento.getMonth() === 1) {
        startDay = 30;
      }
    }
    let nextDate = this.startDateAgendamento;
    let monthInc = this.periodicityOpts.find(el => (el.value === this.periodicitySel)).monthInc;
    
    let newAgendDates:Array<AgendDate> = [];

    // START - GENERATE DESPESAS AGENDAMENTO ------------------------------
    let thisMonth = null;
    let thisYear = null;
    while (this.utils.compareDayDates(nextDate, this.endDateAgendamento) <= 0) {
        let status = null;
        if (this.utils.compareDayDates(nextDate, this.now) < 0) {
          status = 'atrasado';
        }
        let valor = this.getDespValue(null, nextDate);
        let forma_lancamento = this.getDespFormLancamento(null, nextDate);
        let el:despesa_agendamento = {
          cod: null,
          active: '1',
          descricao: this.utils.getMonthDesc(nextDate) + this.descricao,
          from_gecond:'0',
          valor: valor,
          valor_liquidado: '0',
          valor_por_liquidar: valor,
          movimentos:[],
          obj: '[]',
          nome_fornecedor:this.cod_fornecedor.name,
          id: null,
          n_despesa: null,
          dt_pag:null,
          dt_desp:this.utils.getFormatedDate(nextDate),
          dt_desp_orig:this.utils.getFormatedDate(nextDate),
          form_pagam: null,
          cod_conta_bancaria: null,
          form_pagam_agendamento: forma_lancamento.forma_agendamento,
          cod_conta_bancaria_agendamento: forma_lancamento.cod_conta_bancaria,
          dt_pag_agendamento: forma_lancamento.forma_agendamento === 'LP' ? nextDate : null,
        }
        let obj = this.getAgendDatesObj(el);
        newAgendDates.push(obj);
     
      thisMonth = nextDate.getMonth() + monthInc;
      thisYear = nextDate.getFullYear();
      if (thisMonth >= 12) {
        thisMonth = thisMonth % 12;
        thisYear += 1;
      }

      if (startDay > 28) {
        // CHECK IF DATE IS VALID BEFORE PUSH
        if ((startDay === 29 || startDay === 30 || startDay === 31) && thisMonth === 1) {  // PROBLEMA COM FEVEREIRO

          if (this.utils.isValidDate(29, thisMonth, thisYear)) {
            nextDate = new Date(thisYear, thisMonth, 29);
          } else {
            nextDate = new Date(thisYear, thisMonth, 28);
          }
        } else if (startDay === 31 && (thisMonth === 3 || thisMonth === 5 || thisMonth === 7 || thisMonth === 8 || thisMonth === 10)) {
          nextDate = new Date(thisYear, thisMonth, 30);
        } else {
          nextDate = new Date(thisYear, thisMonth, startDay);
        }
      } else {
        nextDate = new Date(thisYear, thisMonth, startDay);
      }
    }

    
    let oldRemoved:Array<AgendDate> = [];
    let oldActive:Array<AgendDate> = [];
    this.agendamentoDetails.forEach(el => {
      if (el.active === '1') {
        if (this.agendDates.findIndex(active => this.utils.compareDayDates(active.dt_desp, this.utils.getDate(el.dt_desp)) === 0) == -1) {
          oldActive.push(this.getAgendDatesObj(el, true));
        }
      } else {
        if (this.agendDatesRemovidas.findIndex(removed => this.utils.compareDayDates(removed.dt_desp, this.utils.getDate(el.dt_desp)) === 0) == -1) {
          oldRemoved.push(this.getAgendDatesObj(el, true));
        }
      }
    })

    let old = oldActive.concat(oldRemoved);
    
    let actual = this.agendDates.concat(this.agendDatesRemovidas);

    let oldDates = actual.concat(old.filter(el => !actual.find(actual => this.utils.compareDayDates(actual.dt_desp, el.dt_desp) === 0)));

    let newDates = [];
    newAgendDates.filter(el => this.canChangeDespDate(el.id)).forEach(el => {
      let old = oldDates.find(old => {
        if (old.dt_desp_orig) {
          return this.utils.compareDayDates(old.dt_desp_orig, el.dt_desp) === 0 || this.utils.compareDayDates(old.dt_desp, el.dt_desp) === 0;
        }
        return this.utils.compareDayDates(old.dt_desp, el.dt_desp) === 0;
      })

      if (old) {
        newDates.push(old);
      } else {
        newDates.push(el)
      }
    })
    
    this.agendamentoDetails.forEach(el => {
      let isAlready = newDates.find(newDate => this.utils.compareDayDates(newDate.dt_desp, this.utils.getDate(el.dt_desp)) === 0);
      if (!isAlready && !this.canChangeDespDate(el.id)) {
        let clone:despesa_agendamento = JSON.parse(JSON.stringify(el));
        if (this.agendDates.find(date => date.id == el.id)) {
          clone.active = '1';
        } else if (this.agendDatesRemovidas.find(date => date.id == el.id)) {
          clone.active = '0';
        }
        let agendObj =this.getAgendDatesObj(clone, true);
        newDates.push(agendObj);
      }
    });

    if (this.estado_agendamento === '0') {
      let prevLength = newDates.length;
      newDates = newDates.filter(el => this.utils.compareDayDates(el.dt_desp, this.dt_estado_agendamento) <= 0);
      if (prevLength !== newDates.length) {
        let msg = 'Algumas despesas não foram geradas.\nO agendamento está configurado para ser inativado a partir de ' + this.utils.getFormatedDate(this.dt_estado_agendamento) + '.';
        if (!this.toastr.findDuplicate(msg, false, false)) {
          this.toastr.info(msg, 'Informação');
        }
      }
    }

    
    this.agendDatesRemovidas = newDates.filter(el => !!el.removed && this.utils.compareDayDates(el.dt_desp, this.startDateAgendamento) >= 0 && this.utils.compareDayDates(el.dt_desp, this.endDateAgendamento) <= 0);
    this.agendDates = newDates.filter(el => !el.removed)

    this.hasAutoPayments = !!this.agendDates.find(el => el.autoPay);


    this.sortAgendDates();
    this.sortRemovedAgendDates();
    this.checkIfIsEditing();
  }


  getAgendDatesObj(el:despesa_agendamento, update=false):AgendDate {
    let autoPay = false;

    let status = null;
    if ((el.from_gecond === '1' && el.dt_pag) || Number(el.valor) === Number(el.valor_liquidado)) {
      status = 'pago';
    }
    if ((el.from_gecond === '0') && (Number(el.valor) !== Number(el.valor_liquidado) && Number(el.valor_liquidado)) > 0) {
      status = 'parcialmente-pago';
    }
    if (((el.from_gecond === '1' && !el.dt_pag) || Number(el.valor_liquidado) === 0) && this.utils.getDate(el.dt_desp).getTime() < this.now.getTime()) {
      status = 'atrasado';
    }
    let isLancado:boolean = this.utils.compareDayDates(this.utils.getDate(el.dt_desp), new Date()) <= 0;
    if (el.dt_pag_agendamento && el.cod_conta_bancaria_agendamento) autoPay = true;
    

    let ret:AgendDate = { 
      cod: el.cod,
      descricao: el.descricao,
      dt_desp: this.utils.getDate(el.dt_desp),
      dt_desp_orig: el.dt_desp_orig? this.utils.getDate(el.dt_desp_orig) : this.utils.getDate(el.dt_desp),
      obj: el.obj,
      dt_pag: this.utils.getDate(el.dt_pag),
      dt_pag_agendamento: this.utils.getDate(el.dt_pag_agendamento),
      paymentMethods: this.getPaymentMethodsUI(el.id, el.movimentos, this.utils.getDate(el.dt_desp)),
      isLancado: isLancado,
      status: status,
      checked: false,
      id: el.id,
      n_despesa: el.n_despesa,
      monthDesc: this.utils.getMonthDesc(this.utils.getDate(el.dt_desp)),
      movimentos: JSON.parse(JSON.stringify(el.movimentos)),
      autoPay: autoPay,
      cod_conta_bancaria: el.cod_conta_bancaria,
      cod_conta_bancaria_agendamento: el.cod_conta_bancaria_agendamento,
      form_pagam: el.form_pagam,
      form_pagam_agendamento: el.form_pagam_agendamento,
      aberta: Number(el.valor_liquidado) < Number(el.valor),
      valor: Math.round(Number(el.valor) * 100) / 100,
      valor_por_liquidar: el.valor_por_liquidar ? Number(el.valor_por_liquidar) : Math.round(Number(el.valor) * 100) / 100,
      valor_liquidado: el.valor_liquidado ? Number(el.valor_liquidado) : 0,
      removed: el.active === '0',
    };

    if (update) {
      ret.valor = this.getDespValue(ret, ret.dt_desp);
      let forma_lancamento = this.getDespFormLancamento(ret.id, ret.dt_desp);
      ret.cod_conta_bancaria_agendamento = forma_lancamento.cod_conta_bancaria;
      ret.form_pagam_agendamento = forma_lancamento.form_pagamento;
      if (forma_lancamento.forma_agendamento === 'LP') {
        ret.autoPay = true;
        if (!ret.dt_pag_agendamento) ret.dt_pag_agendamento = new Date(ret.dt_desp);
      }
    }

    return ret;
  }

  getPaymentMethodsUI(id, movimentos:Array<any>, dt_desp:Date) {
    let paymentMethods = [];
    movimentos.forEach(mov => {
      switch (mov.descricao.substring(mov.descricao.length - 2)) {
        case '-T':
          if (paymentMethods.findIndex(pm => pm === 'TRANSFERENCIA') === -1) {
            paymentMethods.push('TRANSFERENCIA');
          }
          break;
      
        case '-N':
          if (paymentMethods.findIndex(pm => pm === 'NUMERARIO') === -1) {
            paymentMethods.push('NUMERARIO');
          }
          break;
        default:
          break;
      }
    });
    if (!paymentMethods.length) {
      let forma = this.getDespFormLancamento(id, dt_desp);
      if (!forma.form_pagamento) {
        return [];
      }
      if (this.appConfig.isFormaPagamentoCaixa(forma.form_pagamento)) {
        return ['NUMERARIO'];
      } else {
        return ['TRANSFERENCIA'];
      }
    }
    return paymentMethods;
  }

  sortAgendDates() {
    this.agendDates.sort((a,b) => this.utils.compareDayDates(a.dt_desp, b.dt_desp));
  }

  sortRemovedAgendDates() {
    this.agendDatesRemovidas.sort((a,b) => this.utils.compareDayDates(a.dt_desp, b.dt_desp));
  }

  goToDespesa(desp:AgendDate) {
    if (this.isDetailsAgendamento) {
      if (this.isEditing) {
        this.toastr.info('Por favor, submeta a corrente edição do agendamento para visualizar a despesa', 'Informação');
        return;
      } else {
        this.router.navigate(['lancamentos/despesa', desp.id]);
        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `DESPESA / ${desp.n_despesa}` });
      }
    }

  }

  tipoProcSelected() {
    switch (this.tipo_proc) {
      case 'O':
      case 'F':
      case 'S':
        this.rubricasOpts = JSON.parse(JSON.stringify(this.rubricasOptsOrc));
        break;
      case 'E':
        this.rubricasOpts = JSON.parse(JSON.stringify(this.rubricasOptsOrig));
        break;
    }

    if (!this.fetchDone || this.fetchingOrcamento) return;

    let rubrica = this.cod_rubrica && this.rubricasOptsOrig.find(el => el.value === this.cod_rubrica);

    if (!rubrica) {
        this.cod_rubrica = null;
        setTimeout(() => this.cod_rubrica = null);
    } else if (this.despesaDetails && this.despesaDetails.cod_rubrica === this.cod_rubrica && this.despesaDetails.tipo_proc === this.tipo_proc && !this.rubricasOpts.find(el => el.value === this.cod_rubrica)) {
      this.rubricasOpts = [rubrica].concat(this.rubricasOpts);
      this.rubricaSelect.writeValue(rubrica.value);
      if (!this.fetchingOrcamento) {
        let exercicio = this.dt_desp? this.dt_desp.getFullYear() : this.utils.getDate(this.despesaDetails.dt_desp).getFullYear();
        let msg = 'A rubrica selecionada foi prevista para orçamento, no entanto não consta no orçamento aprovado para ' + exercicio + '.';
        if (!this.toastr.findDuplicate(msg, false, false)) {
          this.toastr.warning(msg,'Alerta');
        }
      }
    } else {
      let rubrica = this.rubricasOpts.find(el => el.value === this.cod_rubrica);
      if (rubrica) {
        this.cod_rubrica = rubrica.value;
        setTimeout(() => this.cod_rubrica = rubrica.value);
      } else {
        this.cod_rubrica = null;
        setTimeout(() => this.cod_rubrica = null);
      }
    }

    this.checkIfIsEditing();
  }


  // START - CORRESPONDENCIA METHODS AND VARIABLES ----------------------------
  isDespesaCtt = false;
  openCalculatorModal() {
    if (!(this.isCreateCorrespondencia || this.isDespesaCtt)) return;

    if (this.tipoCorreio === null) this.tipoCorreio = 'simples';

    if (this.nEnvelopes <= 0 && this.nFolhasPorEnvelope <= 0) {
      this.calcManualMode = true;
      setTimeout(() => { this.computedValue = this.valor; }, 1)
    } else {
      this.calcManualMode = false;
    }

    this.calcCorreioModalRef = this.modalService.open(this.calcCorreioAlertConfig)
      .onApprove(() => {
        this.valor = this.computedValue;

        this.valorChanged();

        if (this.calcManualMode) this.calcManualMode = false;
      })
      .onDeny(() => { if (this.calcManualMode) this.calcManualMode = false; });
  }

  computeCorrespValue() {
    // COMPUTE N_FOLHAS
    if (this.nEnvelopes && this.nFolhasPorEnvelope) {
      this.nFolhas = Math.floor(this.nEnvelopes * this.nFolhasPorEnvelope);
    }

    let pesoPorCarta = (this.nFolhasPorEnvelope * this.correioMaterialPeso.find(el => (el.type === 'PAPEL_NORMAL')).peso) + this.correioMaterialPeso.find(el => (el.type === 'ENVELOPE')).peso;
    let custoVertis = (this.vertisCustos.coefNEnvelope * this.nEnvelopes) + (this.vertisCustos.coefNFolhas * this.nFolhas);
    let custoCtt = 0;

    // COMPUTE VALOR DESPESA
    if (this.tipoCorreio && this.nEnvelopes && this.nFolhasPorEnvelope) {
      let tabelaPreco = null;
      let precoPorCarta = null;
      switch (this.tipoCorreio) {
        case 'simples':
          this.avisoRececao = false;
          this.avisoRececaoDisabled = true;

          custoCtt = 0;

          if (this.cod_rubrica !== '47') {
            this.cod_rubrica = '47';
            this.rubricaSelected();
          }

          break;
        case 'normal':
          this.avisoRececao = false;
          this.avisoRececaoDisabled = true;

          tabelaPreco = this.correioTabelaPrecos.find(el => (el.type === 'CORREIO_NORMAL')).priceArr;
          precoPorCarta = tabelaPreco.find(el => (el.intInf < pesoPorCarta && pesoPorCarta <= el.intSup));

          precoPorCarta = (precoPorCarta) ? precoPorCarta.price : tabelaPreco[tabelaPreco.length - 1].price;

          custoVertis = custoVertis + this.vertisCustos.custoFixo;
          custoCtt = this.nEnvelopes * precoPorCarta;

          if (this.cod_rubrica !== '98') {
            this.cod_rubrica = '98';
            this.rubricaSelected();
          }

          break;
        case 'registado':
          this.avisoRececaoDisabled = false;

          tabelaPreco = this.correioTabelaPrecos.find(el => (el.type === 'CORREIO_REGISTADO')).priceArr;
          precoPorCarta = tabelaPreco.find(el => (el.intInf < pesoPorCarta && pesoPorCarta <= el.intSup));
          precoPorCarta = (precoPorCarta) ? precoPorCarta.price : tabelaPreco[tabelaPreco.length - 1].price;

          custoVertis = custoVertis + this.vertisCustos.custoFixo;
          custoCtt = this.nEnvelopes * precoPorCarta;

          if (this.cod_rubrica !== '42') {
            this.cod_rubrica = '42';
            this.rubricaSelected();
          }

          break;
      }

      this.computedValue = custoVertis + custoCtt;

      if (this.avisoRececao) {
        let custoAvisoRecepcao = this.correioTabelaPrecos.find(el => (el.type === 'AVISO_RECECAO'));
        if (custoAvisoRecepcao) this.computedValue = this.computedValue + (this.nEnvelopes * custoAvisoRecepcao.priceArr[0].price);
      }

      this.computedValue = Math.round(this.computedValue * 100) / 100;
      this.custoVertis = Math.round(custoVertis * 100) / 100;
      this.custoCtt = Math.round(custoCtt * 100) / 100;
    } 
  }

  rubricaSelected() {
    this.checkIfIsEditing();
    this.tipoProcSelected();

    if (!(this.isCreateCorrespondencia || this.isDespesaCtt)) return;
    setTimeout(() => {
        switch (this.cod_rubrica) {
          case '98':  // Correio Normal
            this.cod_fornecedor = this.correspFornecedores.find(el => el.value.value.cod == '16').value
            this.tipoCorreio = 'normal';
            break;
          case '42':  // Correio Registado
            this.cod_fornecedor = this.correspFornecedores.find(el => el.value.value.cod == '16').value;
            this.tipoCorreio = 'registado';
            break;
          case '47':  // Correio Simples
            this.cod_fornecedor = this.correspFornecedores.find(el => el.value.value.cod == '9').value;
            this.tipoCorreio = 'simples';
            break;
        }
        if (this.cod_fornecedor == -1) this.cod_fornecedor = null;


        this.computeCorrespValue();

        if (this.isDespesaCtt && this.valor !== null && this.computedValue !== null && this.valor !== this.computedValue && !this.warnedDiffCttValue) {
          this.warnedDiffCttValue = true;
          this.valorCttModalRef = this.modalService
            .open(this.valorCttAlertConfig)
            .onApprove(() => {
              this.valor = this.computedValue;

              this.valorChanged();
            })
            .onDeny(() => {});
        }

    }, 1);
  }
  // END - CORRESPONDENCIA METHODS AND VARIABLES ----------------------------

  despAgendListCol = [
    { key: 'i', name: '', type: 'text', sort: null, searchable: true, class: 'center one wide' },
    { key: 'dt_desp', name: 'Data da despesa', type: 'date', sort: null, searchable: true, class: '' },
    { key: 'dt_pag', name: 'Data de pagamento', type: 'date', sort: null, searchable: true, class: '' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class: 'col-align-right one wide' },
  ];

  copyStartDateAgendamento = null;
  newAgendDates:Array<AgendDate> = [];
  copyAgendamentoOpenModal() {
    if (this.isEditing) {
      this.toastr.info('Por favor, submeta a corrente edição do agendamento para copiá-lo', 'Informação');
      return;
    }


    this.copyAgendModalRef = this.modalService.open(this.copyAgendAlertConfig)
      .onApprove(() => {
      })
      .onDeny(() => { 
      });
  }

  async copyAgendamento() {
    this.submittingModalForm = true; 
    if (!this.copyStartDateAgendamento) {
      setTimeout(() => { this.submittingModalForm = false; }, 4000);
      return;
    }

    let inputValid = await this.validateInput();
    if (!inputValid) {
      setTimeout(() => { this.submittingModalForm = false; }, 4000);
      return;
    }

    this.loadingModal = true;

    this.orcamentada = (this.tipo_proc === 'O') ? '1' : '0';
        
    let res = this.setRegistoActividadeAgendamento(this.newAgendDates, [], true);
    this.newAgendDates = res.despesas;
    let copyRegistoAct = res.regAct;

    let despesas:Array<agendamentosSaveDespesaReq> = [];

    this.newAgendDates.forEach(el => {
      let forma = this.getDespFormLancamento(el.id, el.dt_desp);
      let despesa: agendamentosSaveDespesaReq = {
        id:null,
        descricao:el.descricao,
        movimentos: [],
        dt_desp: this.utils.getFormatedDate(el.dt_desp),
        obj:el.obj,
        valor:el.valor.toString(),
        valor_liquidado: '0',
        linhas_despesa: this.getLinhasDespesa(el.valor),
        cod_conta_bancaria_agendamento: forma.cod_conta_bancaria,
        form_pagam_agendamento: forma.form_pagamento,
        dt_pag_agendamento: null,
        removed: false,
      }
      despesa['valor_limpeza'] = this.valorLimpeza;
      if (forma.forma_agendamento === 'LP') {
        despesa['dt_pag_agendamento'] = this.utils.getFormatedDate(el.dt_desp);
      }

      despesas.push(despesa);
    });
  // ----------------------------------------------------------------

    let datas_valor = this.getDatasValorToUpload();
    let datas_lancamento = this.getDatasLancamentoToUpload();

    let cod_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
    let cod_fornecedor = (this.cod_fornecedor && this.cod_fornecedor.hasOwnProperty('cod')) ? this.cod_fornecedor.cod : this.cod_fornecedor.value.cod;
    let nome_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('nome')) ? this.cod_condominio.nome : this.cod_condominio.value.nome;

    let nome_fornecedor = '';
    if (this.cod_fornecedor.hasOwnProperty('name')) {
      nome_fornecedor = this.cod_fornecedor.name;
    } else if (this.cod_fornecedor.hasOwnProperty('nome')) {
      nome_fornecedor = this.cod_fornecedor.nome;
    }

    let startDate = this.newAgendDates[0].dt_desp;
    let endDate = this.newAgendDates[this.newAgendDates.length - 1].dt_desp;

    let reparticao_dist = [];
    if (this.reparticao === 'M') reparticao_dist = this.getLinhasDespesa(this.actualValor);

    this.api.saveAgendamento(null, cod_condominio, null, despesas, this.tipo_proc, this.reparticao, this.cod_rubrica, this.descricao, this.utils.getFormatedDate(startDate), this.utils.getFormatedDate(endDate), this.periodicitySel, cod_fornecedor, this.forma_agendamento, JSON.stringify(reparticao_dist), JSON.stringify(datas_valor), JSON.stringify(datas_lancamento), JSON.stringify(copyRegistoAct), this.utils.getFormatedDate(this.now), '1').subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        // REGISTO ACTIVIDADES - UPDATE AGENDAMENTO
        this.registoActividades('agendamento', 'create', cod_condominio, nome_condominio, res.n_agendamento, nome_fornecedor);
        this.copyAgendModalRef.approve();

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['lancamentos/agendamento', cod_condominio , res.n_agendamento]);
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.loadingModal = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModal = false;
    });

  }

  computeNewAgendDates() {
    if (!this.copyStartDateAgendamento) {
      this.newAgendDates = [];
      return;
    }

    this.newAgendDates = [];
    let selectedYear = Number(this.copyStartDateAgendamento.getFullYear());
    let fisrtDespYear = Number(this.agendDates[0].dt_desp.getFullYear());
    let yearDiff = selectedYear - fisrtDespYear;

    //Adicionar 1 ano a todas as datas?
    // datas_valor.foreach
    // datas_lancamento.forEach
    // StartDateAgendamento
    // EndDateAgendamento


    let nextYear = null;
    let aux = null;
    this.agendDates.forEach(el => {
      aux = new Date(el.dt_desp);
      nextYear = Number(el.dt_desp.getFullYear()) + yearDiff;

      let newDate = new Date(aux.setFullYear(nextYear));

      let forma_lancamento = this.getDespFormLancamento(null, newDate);

      let newDespesa:despesa_agendamento = {
        cod: null,
        active: '1',
        descricao: this.utils.getMonthDesc(newDate) + this.descricao,
        from_gecond:'0',
        valor: el.valor,
        valor_liquidado: '0',
        valor_por_liquidar: el.valor,
        movimentos:[],
        obj: '[]',
        nome_fornecedor:this.cod_fornecedor.name,
        id: null,
        n_despesa: null,
        dt_pag:null,
        dt_desp:this.utils.getFormatedDate(newDate),
        dt_desp_orig:this.utils.getFormatedDate(newDate),
        form_pagam: null,
        cod_conta_bancaria: null,
        form_pagam_agendamento: forma_lancamento.forma_agendamento,
        cod_conta_bancaria_agendamento: forma_lancamento.cod_conta_bancaria,
        dt_pag_agendamento: forma_lancamento.forma_agendamento === 'LP' ? newDate : null,
      }

      this.newAgendDates.push(this.getAgendDatesObj(newDespesa));
    });
    
  }

  // CAIXA VERTIS FUNTIONS/VARIABLES SECTION ----------------------------------
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode == 13) {
      if (this.loginModalOpened) { this.loginCaixa(); }
    }
  }

  @ViewChild('loginCaixaAlertRef', { static: false }) loginCaixaAlertRef;
  loginCaixaModalRef = null;
  loginCaixaAlertConfig: any = null;

  @ViewChild('alredyLoggedAlertRef', { static: false }) alredyLoggedAlertRef;
  alredyLoggedModalRef = null;
  alredyLoggedAlertConfig: any = null;

  @ViewChild('condominosAlertRef', { static: false }) condominosAlertRef;
  condominosModalRef = null;
  condominosAlertConfig: any = null;

  @ViewChild('changeCVAlertRef', { static: false }) changeCVAlertRef;
  changeCVModalRef = null;
  changeCVAlertConfig: any = null;
  
  @ViewChild('rubricaSelect', { static: false }) rubricaSelect: SuiSelect<any, any>;

  loggedUser = null;
  unauthorized = false;

  username = null;
  password = null;

  loginModalOpened = false;

  condominoListCol = [
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, class: 'thirteen wide' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class: 'table-checkbox-column one wide' },  // 'ASC', 'DESC'
  ];
  condominosListOrig = [];
  condominosList = [];

  loginCaixa(force='0') {
    if (!this.username && !this.password) return;

    this.loadingModal = true;
    this.api.loginCaixa(this.username, this.password, force).subscribe(res => {
      if (res['success'] && res.success) {
        if (res.hasOwnProperty('data') && res.data.hasOwnProperty('id')) {
          this.loggedUser = res.data.first_name + ' ' + res.data.last_name;

          this.loginCaixaModalRef.approve();

          this.alredyLoggedModalRef = this.modalService
            .open(this.alredyLoggedAlertConfig)
            .onApprove(() => { this.loginCaixa('1'); this.loadingModal = false; })
            .onDeny(() => { this.loadingModal = false; });

        } else {
          this.userSession.setCaixaVertisState('login');

          this.loginCaixaModalRef.approve();
          this.toastr.success('Login efectuado com sucesso.', 'Caixa Vertis', { timeOut: 4000 });

          this.username = null;
          this.password = null;
        }

        // REGISTO ACTIVIDADES API CALL
        let descricao = 'Login Caixa Vertis efectuado.';
        this.api.saveRegistoActividade(null, null, null, null, descricao).subscribe(res => {}, err => { });
        
      } else {
        this.utils.apiErrorMsg(res);
        
        this.unauthorized = true;
        this.password = null;
        setTimeout(() => { this.unauthorized = false; }, 4000);
      }
      this.loadingModal = false;
    }, err => {
      this.loadingModal = false;
    });
  }
  // --------------------------------------------------------------------------




  @ViewChild('pdf', { static: false }) pdfController;
  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  exportPDF() {
    if (this.movimentos.length === 0) {
      this.toastr.warning('Não é possível gerar o comprovativo de pagamento. A despesa não tem valores liquidados.', 'Alerta', { timeOut: 4000 });
      return;
    }

    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';
    
    let filename = this.despesaDetails.nome_condominio.replace(/ /g, '_') + '_' + this.despesaDetails.n_despesa + '_' + formatDate(this.now, this.format, this.locale);
    filename = filename.replace(/,/g, '');
    
    this.pdfController.saveAs(filename + '.pdf');
  }

  emailInvalid = false;
  hasEmailAddr() {
    if (!this.utils.validateEmail(this.emailAddr)) {
      this.emailInvalid = true;
      this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
    }

    this.submittingFormEmail = true;
    if (!this.emailAddr || this.emailInvalid) {
      setTimeout(() => { this.submittingFormEmail = false; this.emailInvalid = false; }, 4000);
      return;
    }

    this.emailModalRef.approve();
  }

  sendingEmail = false;
  emailAddr = null;
  emailName = null;
  submittingFormEmail = false;
  sendEmail() {
    if (this.movimentos.length === 0) {
      this.toastr.warning('Não é possível gerar o comprovativo de pagamento. A despesa não tem valores liquidados.', 'Alerta', { timeOut: 4000 });
      return;
    }

    if (this.sendingEmail) return;

    this.emailAddr = this.despesaDetails.email;
    this.emailName = this.despesaDetails.nome_fornecedor;

    this.emailModalRef = this.modalService.open(this.emailAlertConfig)
      .onApprove(() => {
          this.submittingFormEmail = false; 

          this.sendingEmail = true;
          this.pdfController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {        
            let base64  = dataUri.replace('data:application/pdf;base64,', '');
            base64  = base64.replace(' ', '+');
      
            let from = this.appConfig.company.email;
            let to = this.emailAddr;
            let subjectMsg = 'Envio de comprovativo de pagamento de despesa nº ' + this.despesaDetails.n_despesa;
            let bodyMsg = this.getEmailBody();
            let attachment = base64;
            let fromName = 'VERTIS - Gestão Condomínios';
            let toName = (this.emailName && this.despesaDetails.email === this.emailAddr) ? this.emailName : null;
            
            let filename = 'despesa_' + formatDate(new Date(), this.format, this.locale) + '.pdf';

            let emailsToSend = [{
              from: from,
              to: to,
              subject: subjectMsg,
              body: bodyMsg,
              attachment: attachment,
              filename: filename,
              fromName: fromName,
              toName: toName,
            }];
            this.api.sendEmailV2(emailsToSend).subscribe(res => {
              this.toastr.success('Comprovativo de pagamento enviado com sucesso.', 'Email Enviado', { timeOut: 4000 });
      
              this.emailAddr = null;
              this.sendingEmail = false;
            }, err => { this.sendingEmail = false; });

          });
      })
      .onDeny(() => { 
        this.emailAddr = null;
        this.submittingFormEmail = false;
      });
  }

  getEmailBody() {
    let htmlEmail = '';

        // TEXTO INICIAL
        htmlEmail += '<div style="margin-bottom: 35px;">';
        this.appConfig.despesaBody.forEach(line => {
          if (line) {
            htmlEmail += '<span>' + line + '</span>';
          } else {
            htmlEmail += '<span><br><br></span>';
          }
        });
        htmlEmail += '</div>';

        htmlEmail += this.utils.getEmailFooter();

      return this.utils.setEmailTemplate(htmlEmail);
  }

  registoCtt = null;
  getRegistoCttDetails() {
    if (!this.despesaDetails) return;

    this.api.getRegistoCttDetails(this.despesaDetails.id).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.registoCtt = res.data;
        
        this.nEnvelopes = Number(this.registoCtt.n_envelope);
        this.nFolhasPorEnvelope = Number(this.registoCtt.n_folhas_env);

        if (this.registoCtt.conta_simples === '1') {
          this.tipoCorreio = 'simples';
        } else if (this.registoCtt.correiro_normal === '1') {
          this.tipoCorreio = 'normal';
        } else if (this.registoCtt.registado === '1') {
          this.tipoCorreio = 'registado';
        }

        this.avisoRececao = (this.registoCtt.aviso_recepcao === '1') ? true : false;

        this.computeCorrespValue();

        if (this.registoCtt && this.registoCtt.hasOwnProperty('condominos_obj') && this.registoCtt.condominos_obj) {
          let auxObj = JSON.parse(this.registoCtt.condominos_obj);

          this.descricaoLivreCtt = auxObj['descricao'];
          this.condominoSelected = auxObj['selectedEntry'];
          if (this.condominoSelected === 'DESCRICAO') this.descricaoLivreCttEnabled = true;
          auxObj['condList'].forEach(el => {
            let aux = this.condominosList.find(it => (it.condominoCod === el));
            if (aux) aux['checked'] = true;
          });
        }

        if (this.nEnvelopes <= 0 && this.nFolhasPorEnvelope <= 0) {
          this.calcManualMode = true;
          setTimeout(() => { this.computedValue = this.valor; }, 1)
        } else {
          this.calcManualMode = false;
        }

      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }
  
  descricaoCttChanged(ev) {
    this.descricaoLivreCttEnabled = !(!ev || ev === '');
  }

  condominoSelected = null;
  condOptSelected() {
    switch (this.condominoSelected) {
      case 'TODOS':
        this.descricaoLivreCttEnabled = false;
        this.descricaoLivreCtt = null;
        this.condominoListToggle = true;
        this.condominosList.forEach(el => { el.checked = true; });
        break;
      case 'RESTANTES':
        this.descricaoLivreCttEnabled = false;
        this.descricaoLivreCtt = null;
        this.condominoListToggle = false;
        this.condominosList.forEach(el => { el.checked = false; });
        break;
      case 'DESCRICAO':
        this.condominoListToggle = false;
        this.descricaoLivreCttEnabled = true;
        this.condominosList.forEach(el => { el.checked = false; });
        break;
      case 'MANUAL':
        this.descricaoLivreCtt = null;
        this.descricaoLivreCttEnabled = false;
        break;
    }
  }

  condominosOpts = [
    { name: 'Todos os condóminos', value: 'TODOS' },
    { name: 'Restantes condóminos', value: 'RESTANTES' },
    { name: 'Descrição Livre', value: 'DESCRICAO' },
    { name: 'Manual', value: 'MANUAL' },
  ];

  descricaoLivreCtt = null;
  descricaoLivreCttEnabled = false;
  getCondominiosDescricaoCtt() {
    let nome_condomino = null;

    switch (this.condominoSelected) {
      case 'TODOS':
        nome_condomino = this.appConfig.condominosOpts.find(el => (el.value === 'TODOS')).name;
        break;
      case 'RESTANTES':
        nome_condomino = this.appConfig.condominosOpts.find(el => (el.value === 'RESTANTES')).name;
        break;
      case 'DESCRICAO':
        nome_condomino = this.descricaoLivreCtt;
        break;
      case 'MANUAL':
        let aux = '';
        this.condominosList.forEach((el, i) => {
          if (el.checked) {
            if (i > 0) {
              aux += (', ' + el.nome);
            } else {
              aux += el.nome;
            }
          }
        });
        if (aux !== '') {
          if (aux[0] === ',') aux = aux.substr(2);
          nome_condomino = aux;
        }
        break;
    }

    return nome_condomino;
  }

  loadingModalCondominos = false;

  condominioRowSelected(row) {
    if (this.condominoSelected === 'DESCRICAO') return;

    row.checked = !row.checked;

    setTimeout(() => {
      let aux = this.condominosList.filter(el => (el.checked));
      if (aux.length === this.condominosList.length) {
        this.condominoSelected = 'TODOS';
        this.condominoListToggle = true;
      } if (aux.length === 0) {
        this.condominoSelected = null;
      } else {
        this.condominoSelected = 'MANUAL';
      }
    }, 10);
  }

  idDespesaTemp = null;
  nDespesaTemp = null;
  nome_condomino = null;
  cod_condomino = null;
  modalFormSubmitted = false;
  condominoListToggle = false;
  saveRegistoCtt(passThrough=false) {
    if (this.isDespesaCtt && !this.isCreate && !passThrough) {
      // if (this.condominosModalRef) this.condominosModalRef.approve();
      this.formSubmitted();
      return;
    }

    this.modalFormSubmitted = true;
    if ( (this.condominoSelected === null || (this.condominoSelected === 'DESCRICAO' && !this.descricaoLivreCtt) ) && !this.registoCtt ) {
      setTimeout(() => { this.modalFormSubmitted = false; }, 4000);
      return;
    }

    let id = (this.registoCtt) ? this.registoCtt['id'] : null;
    let id_despesa = (this.registoCtt) ? this.registoCtt.id_despesa : this.idDespesaTemp;
    let n_despesa = (this.registoCtt) ? this.registoCtt.n_despesa : this.nDespesaTemp;
    let cod_condominio = (this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;

    // let nome_condomino = (this.registoCtt) ? this.registoCtt.nome_condomino : this.getCondominiosDescricaoCtt();
    let nome_condomino = this.getCondominiosDescricaoCtt();

    let cod_condomino = null;

    let data_despesa = this.dt_desp;
    let descricao = this.descricao;
    let custo_vertis = this.custoVertis;
    let custo_ctt = this.custoCtt;
    let conta_simples = '0';
    let correiro_normal = '0';
    let registado = '0';
    let aviso_recepcao = (this.avisoRececao) ? '1' : '0';
    let id_user = this.userSession.getUserId();
    let n_envelope = Number(this.nEnvelopes);
    let n_folhas_env = Number(this.nFolhasPorEnvelope);
    let valor_lancado = Math.round((Number(this.computedValue) * 100)) / 100;
    let data = (this.registoCtt) ? this.registoCtt.data : new Date();

    switch (this.tipoCorreio) {
      case 'simples':
        conta_simples = '1';
        break;
      case 'normal':
        correiro_normal = '1';
        break;
      case 'registado':
        registado = '1';
        break;
    }

    // HANDLE REGISTO CTT OBJECT
    let condObj = {
      selectedEntry: this.condominoSelected,
      condList: [],
      descricao: this.descricaoLivreCtt,
    };
    this.condominosList.filter(el => (el.checked)).forEach(el => {
      condObj.condList.push(el.condominoCod);
    });

    this.loadingModalCondominos = true;
    this.api.saveRegistoCtt(id, cod_condominio, id_despesa, n_despesa, descricao, nome_condomino, cod_condomino, conta_simples, correiro_normal, registado, aviso_recepcao, data_despesa, id_user, null, null, n_envelope, n_folhas_env, custo_vertis, custo_ctt, valor_lancado, null, condObj, data).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (this.condominosModalRef) this.condominosModalRef.approve();
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.loadingModalCondominos = false;
    }, err => {
      this.loadingModalCondominos = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  reparticaoChanged() {
    this.valorChanged(false, true);
  }

  zonaChanged(zona) {
    zona.checked = (zona.valor);

    this.computeTotal();
  }

  total = 0;
  valorDespesa = null;
  valorChanged(fromValor=false, force=false) {
    if (this.valor !== null) this.valor = Math.round(this.valor * 100) / 100;

    setTimeout(() => {

      if (!this.userIsChangingDates && !force) return;
      this.updateActualValue();
      
      this.zonaList.filter(el => (!el.checked)).forEach(el => { el.valor = null; });
      
      if (!this.valor) {
        this.zonaList.forEach(el => { el.valor = null; });
      }
      
      if (this.isCreateAgendamento || this.isDetailsAgendamento) {
        this.updateDatesValue();
        if (this.reparticao === 'M') {
          this.zonaList.forEach(zona => {
            let dist = this.reparticaoDist.find(el => el.cod_zona === zona.cod_zona);
            zona.valor = dist.valor;
          })
        }
      } else {

        let value = null;
        let totalFraccoes = 0;
        switch (this.reparticao) {
          case 'P':
          case 'PO':
            let permTotal = 0;
            this.zonaList.filter(el => el.checked).forEach(el => {
              permTotal += el.permilagem;
            });

            this.zonaList.filter(el => el.checked).forEach(el => { 
              el.valor = Math.round(((el.permilagem / permTotal) * this.valor) * 100) / 100;
            });
            break;
          case 'E':
            totalFraccoes = 0;
            this.zonaList.filter(el => el.checked).forEach(el => { totalFraccoes += el.n_fraccoes; });

            this.zonaList.filter(el => el.checked).forEach(el => {
              el.valor = Math.round(((this.valor / totalFraccoes) * el.n_fraccoes) * 100) / 100;
            });
            break;
          case 'M':
            if (fromValor) {
              totalFraccoes = 0;
              this.zonaList.filter(el => el.checked).forEach(el => { totalFraccoes += el.n_fraccoes; });
    
              this.zonaList.filter(el => el.checked).forEach(el => {
                el.valor = Math.round(((this.valor / totalFraccoes) * el.n_fraccoes) * 100) / 100;
              });
            }
            break;
        }
        this.zonaList = JSON.parse(JSON.stringify(this.zonaList));
  
      }
      
      this.computeTotal();

      
      // if (this.zonaList.length > 1 && this.total !== this.valor) {
      //   // HANDLE ARREDONDAMENTOS (AUTOMÁTICO)
      //   let zonaMaxPerm = this.zonaList.filter(el => (el.checked)).reduce(function(a, b) {
      //     return (a.permilagem >= b.permilagem) ? a : b
      //   });
      //   let diff = (this.total - this.valor);
      //   if (zonaMaxPerm) {
      //     zonaMaxPerm.valor -= diff;
      
      //     zonaMaxPerm.valor = Math.round(zonaMaxPerm.valor * 100) / 100;
      //   }
      
      //   this.total = this.valor;
      // }
    }, 1);
  }

  totalComputed = false;
  computeTotal() {
    this.total = 0;
    this.zonaList.forEach(el => {
      this.total += Number(el.valor);
    });
    this.total = Math.round(this.total * 100) / 100;

    this.totalComputed = true;
  }

  movEdit: Movimento & {despesa_id:string}= null;
  async editMovimento(mov: Movimento & {despesa_id:string}) {

    let canEdit = await this.movimentosService.canChangeMovimento(mov.id);
    if (!canEdit) return;
    
    let isInCVHistory = mov.id_caixa_vertis && mov.dt_mov && this.utils.compareDayDates(mov.dt_mov, new Date()) < 0;
    let isInCvNow = mov.id_caixa_vertis && mov.dt_mov && this.utils.compareDayDates(mov.dt_mov, new Date()) === 0;
    
    if (isInCvNow || isInCVHistory) {
      this.toastr.warning('Movimento registado em caixa vertis.', 'Atenção');
    }

    this.movEdit = mov;

    let form_pagam = null;
    let cod_conta_bancaria = mov.nid_conta;

    if (mov.descricao.indexOf('-N') !== -1) form_pagam = '1';
    if (mov.descricao.indexOf('-T') !== -1) form_pagam = '2';

    if (form_pagam === null) {
      let conta = this.contaOptsOrig.find(el => (el.value === mov.nid_conta));
      if (conta) {
        form_pagam = (conta.name.indexOf('CAIXA') !== -1) ? '1' : '2';
      }
    }

    let valor = (-1 * mov.valor).toString();
    let maxValor = this.utils.cleanDecimalDigits(parseFloat(this.despesaDetails.valor) + this.movimentosTotal - mov.valor);
    let form: PaymentForm = {
      form_pagam: form_pagam,
      cod_conta_bancaria: cod_conta_bancaria,
      dt_pag_valor: new Date(mov.dt_valor),
      dt_pag: new Date(mov.dt_mov),
      n_doc_pagam: null,
      valor_pago: valor,
    }
    let isInCV = this.movEdit.id_caixa_vertis && this.movEdit.dt_mov && this.utils.compareDayDates(this.movEdit.dt_mov, new Date()) < 0;

    let modalInput: PaymentModalInput = {
      form: form,
      maxPagamento: maxValor,
      dt_default: this.dt_desp,
      formPagamDisabled: false,
      dateDisabled: false,
      valorPlaceholder: maxValor + ' € a liquidar',
      showNDoc: false,
      showSaldo: true,
      payFunction: this.updateMovimento,
      minDate: new Date(this.dt_desp),
      type: 'EDIT_MOVEMENT',
      inCaixaVertis: isInCV
    }
    
    this.modalPagOpen = true;
    await this.paymentModal.open(modalInput);
    this.modalPagOpen = false;
    this.getDespesaDetails();
    return;
  }


  caixaVertisWarnDates: Array<Date> = null;
  updateMovimento: (data:PaymentForm) => Promise<boolean> = (data) => {
    return new Promise(async (resolve) => {
      let dangerChange = this.movEdit.id_caixa_vertis && (this.utils.compareDayDates(this.movEdit.dt_mov, new Date()) < 0);
      if (dangerChange) {
        if (!this.userSession.isSuperAdmin()) {
          this.toastr.error('Movimento registado em caixa vertis. Por favor contacte o administrador de sistema.', 'Permissão Negada', { timeOut: 4000 });
          resolve(false);
          return;
        } else {
          this.caixaVertisWarnDates = [this.movEdit.dt_mov];
          this.changeCVModalRef = this.modalService.open(this.changeCVAlertConfig)
          .onApprove(async () => {
            this.caixaVertisWarnDates = [];
            let res = await this.saveMovimento(data);
            resolve(res);
          })
          .onDeny(() =>  {
            this.caixaVertisWarnDates = [];
            resolve(false);
          });
          return;
        }
      }

      let res = await this.saveMovimento(data);
      resolve(res);
    })
  }

  saveMovimento(data:PaymentForm): Promise<boolean> {
    return new Promise((resolve) => {
      // HANLDE DESCRICAO
      let movDescricao = this.movEdit.descricao.substring(0, this.movEdit.descricao.length - 3);
      if (data.form_pagam === '1' || data.form_pagam === '6') {
        movDescricao += ' -N';
      } else {
        movDescricao += ' -T';
      }
      this.loadingModal = true;

      let despObj = null;
      let conta = this.contaOptsOrig.find(el => (el.value === data.cod_conta_bancaria)).name;

      despObj = this.despesaDetails.obj? JSON.parse(this.despesaDetails.obj) : [];  
      let movValor = (parseFloat(data.valor_pago) > 0) ? parseFloat(data.valor_pago) : -1 * parseFloat(data.valor_pago);
      despObj = [{
        msg: 'Movimento actualizado. Data Valor: ' + this.utils.getFormatedDate(data.dt_pag_valor) + ', Data movimento: ' + this.utils.getFormatedDate(data.dt_pag) + ', Conta: ' + conta + ', Valor: ' + movValor + ' €.',
        activity: 'Actualizado por ',
        movObj: { 
          nid_mov: this.movEdit? this.movEdit.id : null, 
          index_mov: this.movEdit? parseInt(this.movEdit.index_mov) : null 
        },
        user: this.userSession.getUserFullName(),
        date: new Date(),
      }].concat(despObj);

      let descricaoCV = '';
      let nome_fornecedor = this.cod_fornecedor ? (this.cod_fornecedor.hasOwnProperty('value') ? this.cod_fornecedor.value.nome : this.cod_fornecedor.nome) : null;

      if (nome_fornecedor) descricaoCV = nome_fornecedor; 

      let nome_rubrica = this.rubricasOpts.find(el => (el.value === this.cod_rubrica));
      if (nome_rubrica) descricaoCV = (descricaoCV !== '') ? descricaoCV + ' / ' + nome_rubrica.name : nome_rubrica.name;

      descricaoCV = descricaoCV.replace(/\s\s+/g, ' ');
      descricaoCV =  "D " + this.n_despesa + " / " + descricaoCV;

      this.api.updateDespesaMovimento(this.movEdit.id, data.dt_pag_valor, data.dt_pag, -1 * parseFloat(data.valor_pago), data.cod_conta_bancaria, movDescricao, this.despesaId, this.despesaDetails.n_despesa, this.despesaDetails.cod_condominio, despObj, conta, this.userSession.isCaixaVertisLoggedIn() , descricaoCV, this.movEdit.id_caixa_vertis).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {

          this.registoAct = JSON.parse(JSON.stringify(despObj));

          this.despesaDetails.obj = JSON.stringify(this.registoAct);

          this.movimentosTotal = 0;
          let maxDate = null;
          this.movimentos.forEach(el => {
            if (el.id === res.movimento.id) {
              el.valor = Number(parseFloat(res.movimento.valor).toFixed(2));
              el.dt_mov = this.utils.getDate(res.movimento.dt_mov);
              el.dt_valor = this.utils.getDate(res.movimento.dt_valor);
              el.descricao = res.movimento.descricao;
              el.nid_conta = res.movimento.nid_conta;
              el.banco = res.movimento.banco;

              try {
                el.obj = JSON.parse(res.movimento.obj);
              } catch {
                el.obj = null;
              }
              el.despesa_id = this.despesaDetails.id;

              el.checked = false;
            }

            if (!maxDate || this.utils.compareDayDates(maxDate, el.dt_mov) > 0) maxDate = new Date(el.dt_mov);

            this.movimentosTotal += el['valor'];
          });

          this.despInCV = !!this.movimentos.find(el => !!el.id_caixa_vertis);

          this.dt_pag = maxDate;

          this.setPagParcial(true);

          // UPDATE VALOR EM FALTA POR PAGAR
          this.pagEmFalta = this.valor + this.movimentosTotal;
          if (this.pagEmFalta < 0) this.pagEmFalta = 0;
          this.submittingForm = false;
          this.loadingModal = false;  
          resolve(true);
        } else {
          this.submittingForm = false;
          this.loadingModal = false;
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.submittingForm = false;
        this.loadingModal = false;
        resolve(false);
      });
    })
  }

  toDelete = [];
  loadingDeleteModal = false;
  deleteMovimentos() {
    let dtMovimento = null;
    let dtMov = null;
    this.movimentos.filter(el => !el.checked).forEach(el => {
      dtMov = new Date(el.dt_mov);

      if (dtMovimento === null || (dtMovimento && dtMov.getTime() > dtMovimento.getTime())) {
        dtMovimento = dtMov;
      }
    });

    this.toDelete = this.movimentos.filter(el => el.checked);
    this.toDelete.forEach(el => {
      el['id_despesa'] = this.despesaDetails.id;
      el['n_despesa'] = this.despesaDetails.n_despesa;
      el['cod_condominio'] = this.despesaDetails.cod_condominio;
      el['dt_movimento_despesa'] = (dtMovimento) ? this.utils.getFormatedDate(dtMovimento) : null;
    });

    if (this.toDelete.length > 0) {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => { this.loadingDeleteModal = false; this.toDelete = []; this.deleting = false; })
        .onDeny(() => { this.loadingDeleteModal = false; this.toDelete = []; this.deleting = false; });
    } else {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
    }
  }

  deleting = false;
  del(confirmed=false) {
    if (this.deleting) return;

    this.deleting = true;

    this.loadingDeleteModal = true;

    var CantDeleteException = {};
    if (!confirmed) {
      this.caixaVertisWarnDates = [];
      try {
        this.toDelete.forEach(el => {
          let dangerChange = el.id_caixa_vertis && (this.utils.compareDayDates(el.dt_mov, new Date()) < 0);
          if (dangerChange) {
            if (!this.userSession.isSuperAdmin()) {
              this.toastr.error('Movimento registado em caixa vertis. Por favor contacte o administrador de sistema.', 'Permissão Negada', { timeOut: 4000 });
              throw CantDeleteException;
            } else {
              if (!this.caixaVertisWarnDates.find(cv => this.utils.compareDayDates(cv, el.dt_mov) === 0)) {
                this.caixaVertisWarnDates.push(el.dt_mov);
              }
            }
          }
        })
      } catch (err) {
        if (err !== CantDeleteException) throw err;
        return;
      }
      if (this.caixaVertisWarnDates.length) {
        this.changeCVModalRef = this.modalService.open(this.changeCVAlertConfig)
        .onApprove(() => {
          this.deleting = false;
          this.loadingDeleteModal = false;
          this.caixaVertisWarnDates = [];
          this.del(true);
        })
        .onDeny(() =>  {
          this.deleting = false;
          this.loadingDeleteModal = false;
          this.caixaVertisWarnDates = [];
          this.alertModalRef.deny();
        });
        return;
      }
    }

    let despObj = (this.despesaDetails.obj) ? JSON.parse(this.despesaDetails.obj) : [];

    this.toDelete.forEach(mov => { 
      mov['desp_obj'] = JSON.stringify(despObj);
      mov['dt_mov'] = this.utils.getFormatedDate(mov.dt_mov);
    });

    this.api.delMovimentos(this.toDelete, 'DESPESAS').subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        if (res.data && res.data.regAtividade) {
          this.despesaDetails.obj = res.data.regAtividade;
          this.registoAct = JSON.parse(res.data.regAtividade);
        }

        this.toDelete.forEach(del => {
          this.movimentos = this.movimentos.filter(el => (el.id !== del.id));
        });

        this.movimentosTotal = 0;
        let maxDate = null;
        this.movimentos.forEach(el => { 
          this.movimentosTotal += el['valor']; 
          if (!maxDate || this.utils.compareDayDates(maxDate, el.dt_mov) > 0) maxDate = new Date(el.dt_mov);
        });
        this.dt_pag = maxDate;

        this.despInCV = !!this.movimentos.find(el => !!el.id_caixa_vertis);

        // UPDATE VALOR EM FALTA POR PAGAR
        this.pagEmFalta = this.valor + this.movimentosTotal;
        if (this.pagEmFalta < 0) this.pagEmFalta = 0;

        this.setPagParcial(true);
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.alertModalRef.approve();
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingDeleteModal = false;
      this.deleting = false;
    });
  }

  goToAgendamento(nAgend) {
    if (nAgend) {
      let cod = (this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['lancamentos/agendamento',cod , nAgend]);
  
      // Emit signal to breadcrumb component
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `AGENDAMENTO / ${nAgend}` });
    }
  }

  criarAgendamento() {
    if (!this.fetchDone) return;

    let conta = null;
    if (this.movimentos.length > 0) {
      conta = { banco: this.movimentos[0].banco, nid_conta: this.movimentos[0].nid_conta };
    } 

    this.appState.setAgendState(this.cod_condominio, this.tipo_proc, this.cod_rubrica, this.dt_desp, this.descricao, this.cod_fornecedor, this.valor, conta, this.reparticao);

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['lancamentos/despesa', 'criar-agendamento']);
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVO AGENDAMENTO DE DESPESAS` });
  }

  copiarDespesa() {
    if (!this.fetchDone) return;

    this.appState.setDespState(this.cod_condominio, this.tipo_proc, this.cod_rubrica, this.descricao, this.cod_fornecedor, this.valor, this.reparticao);

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['lancamentos/despesa', 'criar']);
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVA DESPESA` });
  }

  goToCondominio() {
    if (this.isCreate || !this.despesaDetails || !this.despesaDetails.id_condominio) return;
    this.router.navigate(['condominios/condominio/geral', this.despesaDetails.id_condominio]);
  }

  hasPastInactiveStartDate = false;
  estadoAgendamentoChanged() {
    if (this.despesaDetails) {
      let prevDate = this.utils.getDate(this.despesaDetails.dt_estado);
      let wasAlreadyPast = this.utils.compareDayDates(prevDate, this.now) < 0;
      let isPastDate = this.utils.compareDayDates(this.dt_estado_agendamento, this.now) < 0;
      let isTodayDate = this.utils.compareDayDates(this.dt_estado_agendamento, this.now) === 0;
      let changedDate = this.utils.compareDayDates(this.dt_estado_agendamento, prevDate) !== 0;

      if (this.estado_agendamento === this.despesaDetails.estado) {
        if ((this.estado_agendamento === '1' && changedDate )
            ||
            (this.estado_agendamento === '0' && changedDate && wasAlreadyPast)) {
          this.dt_estado_agendamento = prevDate;
        }
      } else {
        if ((this.estado_agendamento === '1' && !isTodayDate)  
            || 
            (this.estado_agendamento === '0' && isPastDate)) {
          this.dt_estado_agendamento = new Date(this.now)
        }
      }
    }
    this.genAgendamento();
    this.checkIfIsEditing();
  }

  isEditing = false;
  changedData():boolean {
    if (this.isDetailsAgendamento) {
      if (!this.despesaDetails) return false;
  
      if (this.utils.compareDayDates(this.dt_estado_agendamento, this.utils.getDate(this.despesaDetails.dt_estado)) !== 0) return true;
      if (this.estado_agendamento != this.despesaDetails.estado) return true;
      if (this.tipo_proc != this.despesaDetails.tipo_proc) return true;
      if (this.reparticao != this.despesaDetails.reparticao) return true;
      // if (this.reparticaoDist != this.despesaDetails.reparticaoDist) return true;
      if (this.cod_rubrica != this.despesaDetails.cod_rubrica) return true;
      if (this.descricao != this.despesaDetails.descricao) return true;
      if (this.utils.compareDayDates(this.startDateAgendamento, this.utils.getDate(this.despesaDetails.start_date)) !== 0) return true;
      if (this.utils.compareDayDates(this.endDateAgendamento, this.utils.getDate(this.despesaDetails.end_date)) !== 0) return true;
      if (this.periodicitySel != this.despesaDetails.periodicidade) return true;

      let datas_valor = this.despesaDetails.datas_valor ? (JSON.parse(this.despesaDetails.datas_valor) as Array<any>).map(el => {
        el['data'] = this.utils.getDate(el['data']);
        return el;
      }) : [];

      if (datas_valor.length) {
        if (parseFloat(datas_valor[datas_valor.length - 1].valor) !== parseFloat(this.valor)) return true;
        if (this.utils.compareDayDates(datas_valor[datas_valor.length - 1].data, this.dt_valor) !== 0) return true;
      }
      
      let datas_lancamento = (this.despesaDetails.datas_lancamento) ? (JSON.parse(this.despesaDetails.datas_lancamento) as Array<any>).map(el => {
        el['data'] = this.utils.getDate(el['data']);
        return el;
      }) : [];

      if (datas_lancamento.length) {
        if (!this.dataInicioPagamento || this.utils.compareDayDates(datas_lancamento[datas_lancamento.length - 1].data, this.dataInicioPagamento) !== 0) return true;
        if (datas_lancamento[datas_lancamento.length - 1].cod_conta_bancaria != this.cod_conta_bancaria) return true;
        if (datas_lancamento[datas_lancamento.length - 1].form_pagamento != this.form_pagam) return true;
        if (datas_lancamento[datas_lancamento.length - 1].forma_agendamento != this.forma_agendamento) return true;
      }

      var isChanged = {};
      try {
        this.agendamentoDetails.forEach(prev => {
          let desp:AgendDate = null;
          if (prev.active === '1') {
            desp = this.agendDates.find(el => el.id === prev.id);
            if (!desp) throw isChanged;
          } else {
            desp = this.agendDatesRemovidas.find(el => el.id === prev.id);
            if (!desp) throw isChanged;
          }

          if (desp.valor !== parseFloat(prev.valor)) throw isChanged;
          if (desp.movimentos.length !== prev.movimentos.length) throw isChanged;
          desp.movimentos.forEach(mov => {
            let prevMov = prev.movimentos.find(el => el.id === mov.id);
            if (!prevMov) throw isChanged;
            if (parseFloat(mov.valor) != parseFloat(prevMov.valor)) throw isChanged;
          });
        });

        this.agendDates.concat(this.agendDatesRemovidas).forEach(el => {
          if (!el.id) throw isChanged;
        });
      } catch (e) {
        if (e !== isChanged) throw e;
        return true;
      }
      
      return false;
    }
    return false;
  }

  checkIfIsEditing() {
    this.isEditing = this.changedData();
  }

  @ViewChild('editPayAgendamento', { static: false }) editPayAgendamento: EditPayAgendamentoComponent;
  agendDatesFetched = false;
  agendDatesMovsFetched = false;
  async openEditAgendamentos() {
    if (this.isEditing) {
      this.toastr.info('Por favor, submeta a corrente edição do agendamento para editá-lo', 'Informação');
      return;
    }

    let cod_fornecedor = (this.cod_fornecedor && this.cod_fornecedor.hasOwnProperty('cod')) ? this.cod_fornecedor.cod : this.cod_fornecedor.value.cod;
    let nome_fornecedor = '';
     if (this.cod_fornecedor.hasOwnProperty('name')) {
       nome_fornecedor = this.cod_fornecedor.name;
     } else if (this.cod_fornecedor.hasOwnProperty('nome')) {
       nome_fornecedor = this.cod_fornecedor.nome;
     }

    let agendDatesClone = this.agendDates.map(el => {
      let clone = {...el, fornecedor: {name:nome_fornecedor, value:cod_fornecedor}};
      clone.movimentos = el.movimentos.map(mov => {
        return {...mov, valor: Math.abs(Number(mov.valor))};
      });
      return clone;
    });

    this.cdRef.detectChanges();
    let submitted = await this.editPayAgendamento.openEditAgendamentos(agendDatesClone);
    if (submitted) this.init();
  }

  formaAgendamentoChanged() {
    if (this.forma_agendamento === 'LP') {
      this.form_pagam = '1';
      this.paymentOptChangedFormaLancamento();
      this.checkIfIsEditing();
    } else if (!this.dataInicioPagamento) {
      setTimeout(() => {
        this.form_pagam = null;
        this.cod_conta_bancaria= null;
        this.updateDatesPaymentInfoUI();
      });
    } else {
      this.updateDatesPaymentInfoUI();
    }

  }

  getMinEndDate():Date {
    if (!this.isDetailsAgendamento || !this.agendDates || !this.agendDates.length) return null
    
    let minDate = this.agendDates.reduce((minDate, el) => {
      if (this.utils.compareDayDates(el.dt_desp, minDate) > 0) {
        minDate = el.dt_desp;
      }
      return minDate;
    }, this.agendDates[0].dt_desp);
    return minDate;
  }

  actualValor = null;
  updateActualValue() {
    if (this.dt_valor && this.utils.compareDayDates(this.dt_valor, this.now) <= 0) {
      this.actualValor = this.valor;
    } else {
      this.datas_valor.forEach(el => {
        if (this.utils.compareDayDates(el.data, this.now) <= 0) {
          this.actualValor = el.valor;
        }
      })
    }
  }

    /**
   * Evaluates whether despesa can be programmed to be automaticaly paid, 
   * Which is if and only if there's no movements or was already paid and it was configred.
   * @param id_desp despesa id
   * @returns true if despesa autopay can be changed
   */
  canChangeDespAutoPay(id_desp):boolean {
    if (!id_desp) return true;

    let desp = this.agendamentoDetails.find(el => el.id === id_desp);
    if (!desp) return false;

    let movs:Array<any> = desp.movimentos;
    return (!movs.length)
  }

  /**
   * Evaluates whether despesa date can be changed, which is if and only if there's no movements and the date is within the selected range.
   * @param id_desp despesa id
   * @returns true if despesa date can be changed
   */
  canChangeDespDate(id_desp):boolean {
    if (!id_desp) return true;

    let desp = this.agendamentoDetails.find(el => el.id === id_desp);
    if (!desp || desp.active === '0') return false;

    let movs:Array<any> = desp.movimentos;
    return !movs.length;
  }


  /**
   * Evaluates whether despesa value can be changed, which is if and only if there's no movements or if there's only one made by cashier which paid the despesa entirely. 
   * @param id_desp despesa id
   * @returns true if despesa value can be changed
   */
  canChangeDespValue(id_desp): boolean {
    if (!id_desp) return true;

    let desp = this.agendamentoDetails.find(el => el.id === id_desp);
    if (!desp) return false;

    if (!desp.movimentos.length) return true;
    return desp.valor_liquidado !== desp.valor || !!this.hasCashierMovements(desp);
  }

  /**
   * Evaluates whether despesa payment info can be change. 
   * @param id_desp despesa id
   * @returns true if despesa info can be changed
   */
   canChangePaymentInfo(id_desp): boolean {
    if (!id_desp) return true;

    let desp = this.agendDates.find(el => el.id === id_desp);
    return !desp || desp.aberta;
  }

  hasCashierMovements(desp:despesa_agendamento): boolean {
    let movs = desp.movimentos;
    return movs.length > 0 && !!movs.find(mov => mov.descricao.substr(-2) === '-N');
  }


  updateDatesConfigurationsUI() {
    this.updateDatesValue();
    this.updateDatesPaymentInfoUI();
  }

  dataInicioPagamentoChanged() {
    if (!this.isEditingDataPagamento) return;
    this.updateDatesPaymentInfoUI();
  } 

  isEditingDataPagamento = false;
  updateEditingDataPagamento(isEditing:boolean) {
    this.isEditingDataPagamento = isEditing;
  }

  updateDatesPaymentInfoUI() {
    if (!this.dataInicioPagamento) return;
    let hasAutoPay = false;
    this.agendDates.forEach(el => {
      el.paymentMethods = this.getPaymentMethodsUI(el.id, el.movimentos, el.dt_desp);
      let forma = this.getDespFormLancamento(el.id, el.dt_desp);
      if (forma.forma_agendamento === 'LP') {
        if (!el.dt_pag_agendamento) el.dt_pag_agendamento = new Date(el.dt_desp);
        el.autoPay = true;
        hasAutoPay = true;
      } else {
        el.autoPay = false;
      }
    });
    this.hasAutoPayments = hasAutoPay;
    this.checkIfIsEditing();
  }

  updateDatesValue() {
    if (!this.dt_valor) return;
    this.cdRef.detach();
    this.agendDates.forEach(el => {
        el.valor = this.getDespValue(el, el.dt_desp);
        this.adjustDespMovements(el, el.valor);
        el.status = this.getStatus(el);
    });
    this.cdRef.reattach();
    this.checkIfIsEditing();
  }

  getStatus(despesa: AgendDate):'pago'|'parcialmente-pago'|'atrasado' {
    if (Number(despesa.valor) === Number(despesa.valor_liquidado)) {
      return 'pago'
    }
    if (Number(despesa.valor_liquidado) > 0) {
      return 'parcialmente-pago';
    }
    if (Number(despesa.valor_liquidado) === 0 && despesa.dt_desp.getTime() < this.now.getTime()) {
      return 'atrasado';
    }
    return null;
  }


  /**
   * Given a new value, adjusts movements value, by changing cashier movements.
   * It increases cashier movements value or deletes them according to first.
   * @param movs 
   * @param value 
   */
  adjustDespMovements(desp:AgendDate, value:number):void {

    let desp_bd = this.agendamentoDetails.find(el => el.id === desp.id);

    if (!desp_bd) return;

    desp.movimentos = JSON.parse(JSON.stringify(desp_bd.movimentos));
    
    let cashierMovs = desp.movimentos.filter(el => this.utils.getMovimentoMetodo(el.descricao) === 'NUMERARIO');
    if (!cashierMovs.length) return;

    let cashierMov = cashierMovs[0];

    desp.movimentos = desp.movimentos.filter(el => this.utils.getMovimentoMetodo(el.descricao) === 'TRANSFERENCIA');
    let minValue = desp.movimentos.reduce<number>((acc, el) => acc += parseFloat(el.valor), 0);
    //Despesas have negative signal
    minValue = -minValue;

    let delta = value - minValue;
    if (delta > 0) {
      cashierMov.valor = (-delta).toString();
      desp.movimentos.push(cashierMov);
    }
    desp.movimentos.sort((a,b) => {
      let res = this.utils.compareDayDates(this.utils.getDate(b.dt_mov), this.utils.getDate(a.dt_mov));
      if (res != 0) return res;
      return parseInt(b.id) - parseInt(a.id);
    });
    desp.valor_liquidado = desp.movimentos.reduce((acc, el) => acc += (-1 * parseFloat(el.valor)), 0);
    desp.paymentMethods = this.getPaymentMethodsUI(desp.id, desp.movimentos, desp.dt_desp_orig);
  }


  getMinValue(movimentos:Array<any>): number {
    return movimentos.reduce<number>((acc, el) => {
      if (el.descricao.substring(el.descricao.length - 2) === '-T') {
        acc += Math.abs(parseFloat(el.valor));
      }
      return acc;
    }, 0);
  }

  

  /**
   * Gets despesa value, according to values configurations, making sure it doesn't get lower than bank movement value,
   * If there's one.
   * @param movimentos despesa movements
   * @param date despesa date
   * @returns 
   */
  getDespValue(desp:AgendDate, date:Date):number {
    if (desp && !this.canChangeDespValue(desp.id)) return desp.valor;

    let movimentos = desp? desp.movimentos : [];
    let minValuePossible = this.getMinValue(movimentos);

    if (this.utils.compareDayDates(date, this.dt_valor) >= 0 || (this.datas_valor.length && this.utils.compareDayDates(this.dt_valor, this.datas_valor[0].data) <= 0)) {
      return minValuePossible < this.valor ? this.valor : minValuePossible;
    }

    let i = 0;
    let valor = this.datas_valor.length ? this.datas_valor[0].valor : null;
    while (i < this.datas_valor.length) {
      if (this.utils.compareDayDates(date, this.datas_valor[i].data) < 0) {
        break;
      }
      valor = this.datas_valor[i].valor;
      i++;
    }
    let valor_n = parseFloat(valor);
    return valor_n? (minValuePossible < valor_n ? valor_n : minValuePossible) : null;
  }

  getDespFormLancamento(id_desp, date:Date):{form_pagamento, cod_conta_bancaria, forma_agendamento:'L'|'LP'} {
    if (!this.canChangePaymentInfo(id_desp)) {
      let desp = this.agendamentoDetails.find(el => el.id === id_desp)
      return {
        form_pagamento: desp.form_pagam_agendamento,
        cod_conta_bancaria: desp.cod_conta_bancaria_agendamento,
        forma_agendamento: desp.dt_pag_agendamento? 'LP' : 'L',
      }
    }

    let canBeAutoPay = this.canChangeDespAutoPay(id_desp);
    if (this.dataInicioPagamento && this.utils.compareDayDates(date, this.dataInicioPagamento) >= 0) {
      return {
        form_pagamento: this.form_pagam,
        cod_conta_bancaria: this.cod_conta_bancaria,
        forma_agendamento: canBeAutoPay? this.forma_agendamento : 'L',
      }
    }
    
    let i = 0;
    let datas_lancamento:datas_lancamento = this.datas_lancamento.length? this.datas_lancamento[0] : null;
    while (i < this.datas_lancamento.length) {
      if (this.utils.compareDayDates(date, this.datas_lancamento[i].data) < 0) {
        break;
      }
      datas_lancamento = this.datas_lancamento[i];
      i++;
    }

    if (!canBeAutoPay) datas_lancamento.forma_agendamento = 'L';
    return datas_lancamento;
    
  }


  userIsChangingDates = false;
  userIsEditing(value:boolean) {
    this.userIsChangingDates = value;
  }





  //File Upload
  despesaFiles:Array<File> = [];
  despesaFilesOrig:Array<File> = [];

  @ViewChild('modalDefault', { static: false }) modalDefault: ModalDefaultComponent;
  @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;
  handleFileInput(files: FileList) {

    for (let i = 0; i < files.length; i++) {
      let fileToUpload = files[i];
      let fileToUploadName = fileToUpload['name'].toLowerCase();
      if (fileToUploadName.indexOf('.pdf') === -1 && fileToUploadName.indexOf('.png') === -1 && fileToUploadName.indexOf('.jpeg') === -1 && fileToUploadName.indexOf('.jpg') === -1) {
        this.toastr.error('O ficheiro selecionado não é suportado. Por favor, submeta um ficheiro com extensão uma das seguintes extensões: .pdf, .png, .jpeg, .jpg.' , 'Ups...!', { timeOut: 4000 });
        this.fileInputRef.nativeElement.value = '';
        return;
      }
  
      if (fileToUpload.size > 20971520) {
        this.toastr.error('O ficheiro selecionado é superior ao tamanho máximo suportado (20Mb)' , 'Ups...!', { timeOut: 4000 });
        this.fileInputRef.nativeElement.value = '';
        return;
      }
      let filename = fileToUpload.name.substring(0, fileToUpload.name.lastIndexOf('.'));
      let ext = fileToUpload.name.substring(fileToUpload.name.lastIndexOf('.')) as fileExt;
      let contentType: contentType = null;
  
      switch (ext) {
        case '.pdf':
          contentType = 'application/pdf';
          break;
        case '.png':
          contentType = 'image/png';
          break;
        case '.jpeg':
          contentType = 'image/jpeg';
          break;
        case '.jpg':
          contentType = 'image/jpg';
          break;
        default:
          this.toastr.error(this.appConfig.errMsg.uploadFile.notPossible.msg,this.appConfig.errMsg.uploadFile.notPossible.title);
          return;
      }
      const reader = new FileReader();
      reader.onload = (ev) => {
        let binaryString = ev.target['result'];
  
        let base64File = binaryString as string;
  
        switch (contentType) {
          case 'application/pdf': base64File  = base64File.replace('data:application/pdf;base64,', ''); break
          case 'image/png': base64File = base64File.replace('data:image/png;base64,', ''); break
          case 'image/jpeg': base64File = base64File.replace('data:image/jpeg;base64,', ''); break
          case 'image/jpg': base64File = base64File.replace('data:image/jpg;base64,', ''); break
        }
        if (this.despesaFiles.find(anexo => anexo.file === base64File && anexo.filename === filename)) {
          return;
        }

        //TODO Retirar extensão do nome do ficheiro
        let file: File = {
          id: null,
          filename: filename,
          file: base64File,
          ext: ext,
          id_entidade: null
        }
        this.despesaFiles.push(file);
      };
      reader.readAsDataURL(fileToUpload);
    }

  }

  downloadAnexo(i: number) {
    let anexo = this.despesaFiles[i];
    let contentType = this.utils.getBase64Prefix(anexo.ext);
    this.utils.downloadFile(contentType + anexo.file, anexo.filename);
  }

  async removeAnexo(i: number) {
    if (i < 0 || i >= this.despesaFiles.length) return;
    let anexo = this.despesaFiles[i];
    if (!anexo.filename) return;
    let res = await this.modalDefault.open('Confirmação', 'Tem a certeza que deseja eliminar o ficheiro "' + anexo.filename + anexo.ext + '"', null, null, 'Sim', 'small', null, null, true);
    if (!res) return;
    this.despesaFiles.splice(i,1);
  }

  //Remove in future
  @ViewChild('valorLimpezAlertRef', { static: false }) valorLimpezAlertRef;
  valorLimpezaModalRef = null;
  valorLimpezaAlertConfig: any = null;

  valorLimpezaInput = 0;
  valorLimpeza = 0;
  updateValorLimpeza = false;
  isLimpezaDespesa = false;
  openValorLimpezaModal() {
    if (!this.isLimpezaDespesa) return;
    this.valorLimpezaInput = this.valorLimpeza;
    this.valorLimpezaModalRef = this.modalService
    .open(this.valorLimpezaAlertConfig)
    .onApprove(() => {
      this.valorLimpeza = this.valorLimpezaInput;
      this.updateValorLimpeza = true;
    })
    .onDeny(() => {
    });
  }

}