import { ApplicationInitStatus, ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location, formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
import { AppStateService } from '../app-state.service';
interface IContext {
  data:string;
}

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { UserSessionService } from '../user-session.service';
import { SuiSelect } from 'ng2-semantic-ui/dist';
import { OrcamentoPDF, OrcamentoPdfComponent } from '../orcamento-pdf/orcamento-pdf.component';
import { NavigationService } from '../navigation.service';
import { OrcamentosService } from '../business-logic-services/orcamentos.service';




type guardarAvailableActions = 'GUARDAR'|'GUARDAR_SAIR'|'GUARDAR_CRIAR_NOVA_RAIZ'|'GUARDAR_CRIAR_NOVA_MESMOS_DADOS';

interface guardarOptionEntry {
  checked:boolean,
  default:boolean,
  label:string,
  action:guardarAvailableActions,
}



@Component({
  selector: 'app-orcamento-details',
  templateUrl: './orcamento-details.component.html',
  styleUrls: ['./orcamento-details.component.scss']
})
export class OrcamentoDetailsComponent implements OnInit {

  @ViewChild('pdf', { static: false }) pdfController;

  @ViewChild('condominioSelect', { static: false }) condominioController:SuiSelect<{name:string,value:string}, string>;

  now = new Date();

  transitionController = new TransitionController();
  submittingForm = false;
  submittingModalForm = false;
  loading = false;
  loadingModal = false;
  searchable: boolean = true;
  isCreate = true;
  isCopy = false;
  activeOrcExerc = [];

  // TABS DEFINITION ----------------------------------------------------------
  tabsObjDef: any = [
    { key: 'geral', name: 'Geral', active: true, disabled: false },
    { key: 'rubricas', name: 'Rubricas', active: false, disabled: true },
    { key: 'orcamento', name: 'Orçamento', active: false, disabled: true },
    { key: 'simulacao', name: 'Listagem de Quotas', active: false, disabled: true },
  ];
  selTab: any = null;

  // GLOBAL VARIABLES ---------------------------------------------------------
  minPercentageFR = 10;
  orcamentoId = null;
  orcamentoCod = null;
  orcamento: any = { nome_condominio: null, periodo: null };
  orcZonas: any = null;
  orcFraccoes: any = null;
  orcFraccoesFR: any = null;
  orcRubricas: any = null;
  zonas = [];

  isVistaMensalOrcamento = false;
  isVistaMensalOrcamentoZona = true;
  isVistaMensalSimulacao = true;

  // isVistaMensalOrcamento = false;
  // isVistaMensalOrcamentoZona = false;
  // isVistaMensalSimulacao = false;

  tipoAcertoSel = '1';
  tipoReparticaoZona = '2';
  orcTotalZonaOK = true;
  orcTotalDiff = 0;

  fraccoes = [];
  fraccoesListCol = [
    { key: 'checkbox', name: null, type: 'checkbox', sort: null, searchable: false, data: null },
    { key: 'rubricas', name: 'Rubricas', type: 'text', sort: null, searchable: false, data: null },
  ];
  fraccoesList: Array<any> = [];

  // GERAL TAB VARIABLES ------------------------------------------------------
  geralForm = new FormGroup({
    cod_condominio: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    periodo: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    dt_inicio: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    dt_fim: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    descricao: new FormControl(null, { validators: [Validators.required, Validators.maxLength(50)] }),
  });

  months = ['Jan.','Fev.','Mar.','Abr.','Mai.','Jun.','Jul.','Ago.','Set.','Out.','Nov.','Dez.'];
  cobrancaFR = [{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true }];
  cobrancaOrcamento = [{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true }];

  selCobrancaFR = null;
  selCobrancaOrcamento = null;


  // RUBRICAS TAB VARIABLES ---------------------------------------------------
  rubricasListCol = [];
  rubricasList: Array<any> = [];
  rubricasModoManual: boolean = false;

  @ViewChild('addEntRef', { static: false }) addEntRef;
  addModalRef = null;
  addModalConfig: any = null;
  
  @ViewChild('documentosAlertRef', { static: false }) documentosAlertRef;
  documentosModalRef = null;
  documentosAlertConfig: any = null;

  @ViewChild('fundoReservaAlertRef', { static: false }) fundoReservaAlertRef;
  fundoReservaModalRef = null;
  fundoReservaAlertConfig: any = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('lancarQuotasAlertRef', { static: false }) lancarQuotasAlertRef;
  lancarQuotasModalRef = null;
  lancarQuotasAlertConfig: any = null;

  @ViewChild('calculadoraAlertRef', { static: false }) calculadoraAlertRef;
  calculadoraModalRef = null;
  calculadoraAlertConfig: any = null;

  @ViewChild('copyAlertRef', { static: false }) copyAlertRef;
  copyModalRef = null;
  copyAlertConfig: any = null;

  @ViewChild('deleteAlertFRRef', { static: false }) deleteAlertFRRef;
  alertFRModalRef = null;
  deleteAlertFRConfig: any = null;

  @ViewChild('addRubricaAlertRef', { static: false }) addRubricaAlertRef;
  addRubricaModalRef = null;
  addRubricaAlertConfig: any = null;

  @ViewChild('despesasAlertRef', { static: false }) despesasAlertRef;
  despesasModalRef = null;
  despesasAlertConfig: any = null;

  @ViewChild('permilagemAlertRef', { static: false }) permilagemAlertRef;
  permilagemModalRef = null;
  permilagemAlertConfig: any = null;

  // NEW RUBRICAS FORM
  rubricaAddForm = new FormGroup({
    cod: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    valor: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
  });
  rubricaSelected = null;
  allZonasSelected = true;
  repProporcionalZonas = 'P';
  tipoRubricaDR = 'D';
  repProporcionalFR = 'P';
  selectedTotalFR = 10;
  isPercentageFR = true;
  isFRSetup = false;
  tipoRubrica = 'F';

  rubricasTotais = { total: 0 };

  @ViewChild('editEntRef', { static: false }) editEntRef;
  editModalRef = null;
  editModalConfig: any = null;


  // ORCAMENTO TAB VARIABLES --------------------------------------------------
  orcamentoObj = null;
  orcamentoDataTable: Array<any> = [];
  orcamentoTotais = {
    permilagem: null,
    orc_valor: null,
    fr_valor: null,
    valor: null,
  };
  orcamentoFraccoesTotais = {
    permilagem: 0,
    quota_fr: 0,
    quota_orc: 0,
    total: 0,
  };
  orcamentoListCol = [
    { key: 'null', name: 'Fracção', type: 'text', sort: null, searchable: false, data: null, center: false },
    { key: 'null', name: 'Perm.', type: 'number', sort: null, searchable: false, data: null, center: true },
    { key: 'null', name: 'Orçamento', type: 'number', sort: null, searchable: false, data: null, center: true },
    { key: 'null', name: 'Fundo Reserva', type: 'number', sort: null, searchable: false, data: null, center: true },
    { key: 'null', name: 'Total', type: 'number', sort: null, searchable: false, data: null, center: true },
  ];
  orcamentoList: Array<any> = [];
  orcamentoListOrig: Array<any> = [];
  selectedZona = '';


  // SIMULACAO TAB VARIABLES --------------------------------------------------
  simulacaoListCol = [
    { key: 'null', name: 'Zona', type: 'text', sort: null, searchable: false, data: null, center: false },
    { key: 'null', name: 'Fracção', type: 'text', sort: null, searchable: false, data: null, center: false },
    { key: 'null', name: 'Perm.', type: 'number', sort: null, searchable: false, data: null, center: true },
    { key: 'null', name: 'Orçamento', type: 'number', sort: null, searchable: false, data: null, center: true },
    { key: 'null', name: 'Fundo Reserva', type: 'number', sort: null, searchable: false, data: null, center: true },
    { key: 'null', name: 'Seguro', type: 'number', sort: null, searchable: false, data: null, center: true },
    { key: 'total', name: 'Total', nameAlt: 'Quota', type: 'number', sort: null, searchable: false, data: null, center: true },
  ];
  simulacaoList: Array<any> = [];

  simulacaoTotais = {
    permilagem: 0,
    orcamento: 0,
    fundoReserva: 0,
    seguro: 0,
    total: 0,
  }

  // ACERTO ORCAMENTO VARIABLES
  tipoAcerto = 'FRACCAO';
  totalFraccoes = null;
  totalRubricas = null;
  novoTotalRubricas = null;
  novoTotalFraccao = null;
  prevTotalFraccao = null;
  selectedFraccaoAcerto =  null;
  acertoModalOpened = false;


  // --------------------------------------------------------------------
  // 
  // 2nd VERSION FCR COMPUTATIONS ---------------------------------------
  // --------------------------------------------------------------------

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public cdRef:ChangeDetectorRef,
              public appRef: ApplicationRef,
              public appState: AppStateService,
              public router: Router,
              public navigationService: NavigationService,
              public location: Location,
              public message: MessageService,
              public orcamentos: OrcamentosService,
              public modalService: SuiModalService,
              public userSession: UserSessionService,
              public appConfig: AppConfigService) { 
                // GET GLOBAL STATE
                let globalState = this.appState.getGlobalState('global');
                if (globalState && globalState.hasOwnProperty('selCondominio')) {
                  let cod = (globalState.selCondominio) ? { name: globalState.selCondominio.cod + ' - ' + globalState.selCondominio.nome, value: globalState.selCondominio, cod: globalState.selCondominio.cod, nome: globalState.selCondominio.nome } : null;
                  this.geralForm.patchValue({
                    cod_condominio: cod,
                  });
                }
              }
 

  redirectUrl:string = null
  redirectFragment:string = null
  ngOnInit() {
    let queryParams = { ...this.route.snapshot.queryParams };
    if (queryParams.hasOwnProperty('redirectUrl')) {
      this.redirectUrl = queryParams.redirectUrl;
      delete queryParams.redirectUrl;
      this.router.navigate([], { replaceUrl: true, queryParams: queryParams });
    }
    if (this.redirectUrl) {
      let fragmentPos = this.redirectUrl.lastIndexOf('#');
      if (fragmentPos != -1) {
        this.redirectFragment = this.redirectUrl.substring(fragmentPos + 1);
        this.redirectUrl = this.redirectUrl.substring(0, fragmentPos);
      }
    }
    this.geralForm.get('cod_condominio').valueChanges.subscribe((value) => {
      this.updateDefaultDescricao();
      this.saveGlobalState();
    })
    
    this.animate();

    this.isCreate = (this.route.snapshot.params.id === 'criar');
    this.init();
  }

  saveGlobalState() {
    let cod = this.geralForm.get('cod_condominio').value;
    if (cod) {
      this.appState.saveGlobalState('global', { 
        selCondominio: { id: cod.value.id, cod: cod.value.cod, nome: cod.value.nome, exercicio: cod.value.exercicio },
      });
    } else {
      this.appState.clearGlobalState();
    }
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngAfterViewInit() {
    this.addModalConfig = new TemplateModalConfig<IContext, string, string>(this.addEntRef);
    this.addModalConfig.closeResult = "closed";
    this.addModalConfig.size = 'small';
    this.addModalConfig.transition = 'fade up';
    this.addModalConfig.transitionDuration = 400;

    this.editModalConfig = new TemplateModalConfig<IContext, string, string>(this.editEntRef);
    this.editModalConfig.mustScroll = true;
    this.editModalConfig.closeResult = "closed";
    this.editModalConfig.size = 'small';
    this.editModalConfig.transition = 'fade up';
    this.editModalConfig.transitionDuration = 400;

    this.documentosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.documentosAlertRef);
    this.documentosAlertConfig.closeResult = "closed";
    this.documentosAlertConfig.size = 'small';
    this.documentosAlertConfig.transition = 'fade';
    this.documentosAlertConfig.transitionDuration = 250;

    this.fundoReservaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.fundoReservaAlertRef);
    this.fundoReservaAlertConfig.closeResult = "closed";
    this.fundoReservaAlertConfig.size = 'tiny';
    this.fundoReservaAlertConfig.transition = 'fade';
    this.fundoReservaAlertConfig.transitionDuration = 250;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.lancarQuotasAlertConfig = new TemplateModalConfig<IContext, string, string>(this.lancarQuotasAlertRef);
    this.lancarQuotasAlertConfig.closeResult = "closed";
    this.lancarQuotasAlertConfig.size = 'mini';
    this.lancarQuotasAlertConfig.transition = 'fade';
    this.lancarQuotasAlertConfig.transitionDuration = 250;

    this.calculadoraAlertConfig = new TemplateModalConfig<IContext, string, string>(this.calculadoraAlertRef);
    this.calculadoraAlertConfig.closeResult = "closed";
    this.calculadoraAlertConfig.size = 'tiny';
    this.calculadoraAlertConfig.transition = 'fade';
    this.calculadoraAlertConfig.transitionDuration = 250;

    this.addRubricaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addRubricaAlertRef);
    this.addRubricaAlertConfig.closeResult = "closed";
    this.addRubricaAlertConfig.size = 'mini';
    this.addRubricaAlertConfig.transition = 'fade';
    this.addRubricaAlertConfig.transitionDuration = 250;

    this.copyAlertConfig = new TemplateModalConfig<IContext, string, string>(this.copyAlertRef);
    this.copyAlertConfig.closeResult = "closed";
    this.copyAlertConfig.size = 'mini';
    this.copyAlertConfig.transition = 'fade';
    this.copyAlertConfig.transitionDuration = 250;

    this.deleteAlertFRConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertFRRef);
    this.deleteAlertFRConfig.closeResult = "closed";
    this.deleteAlertFRConfig.size = 'mini';
    this.deleteAlertFRConfig.transition = 'fade';
    this.deleteAlertFRConfig.transitionDuration = 250;

    this.despesasAlertConfig = new TemplateModalConfig<IContext, string, string>(this.despesasAlertRef);
    this.despesasAlertConfig.closeResult = "closed";
    this.despesasAlertConfig.size = 'mini';
    this.despesasAlertConfig.transition = 'fade';
    this.despesasAlertConfig.transitionDuration = 250;

    this.permilagemAlertConfig = new TemplateModalConfig<IContext, string, string>(this.permilagemAlertRef);
    this.permilagemAlertConfig.closeResult = "closed";
    this.permilagemAlertConfig.size = 'mini';
    this.permilagemAlertConfig.transition = 'fade';
    this.permilagemAlertConfig.transitionDuration = 250;

    this.guardarAlertConfig = new TemplateModalConfig<IContext, string, string>(this.guardarAlertRef);
    this.guardarAlertConfig.closeResult = "closed";
    this.guardarAlertConfig.size = 'small';
    this.guardarAlertConfig.transition = 'fade';
    this.guardarAlertConfig.transitionDuration = 250;
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  init() {
    this.submittingForm = false;
    this.loading = false;

    if (!this.isCreate) {
      this.geralForm.reset();
      // NOT NEW ORCAMENTO
      this.orcamentoId = (!this.isCopy) ? this.route.snapshot.params.id : this.orcamentoId;
      this.updateRedirectedUrlInfo();
      this.tabsObjDef[1].disabled = false;
      this.isFRSetup = true;
      this.getDetails();
    } else {
      this.geralForm.patchValue({
        periodo: (new Date()).getFullYear(),
        dt_inicio: new Date((new Date()).getFullYear(), 0, 1),
        dt_fim: new Date((new Date()).getFullYear(), 11, 31),
        descricao: 'Orçamento ' + new Date().getFullYear()
      });

      this.selCobrancaFR = '1';
      this.selCobrancaOrcamento = '1';

      this.cobrancaSelected('orcamento');
      this.cobrancaSelected('fundo-reserva');
      

      let option = this.geralForm.get('cod_condominio').value;
      if (option) {
        this.checkPreviousOrcamento(option); 
        this.getZonasbyCondominios(option); 
        this.getFraccoesbyCondominios(option)
      }
    }
  }

  segMonths = [{ month: 'janeiro', active: false },{ month: 'fevereiro', active: false },{ month: 'marco', active: false },{ month: 'abril', active: false },{ month: 'maio', active: false },{ month: 'junho', active: false }, { month: 'julho', active: false }, { month: 'agosto', active: false },{ month: 'setembro', active: false },{ month: 'outubro', active: false },{ month: 'novembro', active: false },{ month: 'dezembro', active: false }];
  orcFraccoesSeg = [];
  premioSeg = 0;
  premilagemSeg = 0;
  reparticaoSeg = null;
  copyToNextYear = true;
  getDetails() {
    let orcamentoId = (this.isCopy) ? this.orcamentoId: this.route.snapshot.params.id;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getOrcamentoDetailsREVIEW(orcamentoId).subscribe(async res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (res.hasOwnProperty('data') && Array.isArray(res.data.orcamento) && res.data.orcamento.length > 0) {
          this.checkPreviousOrcamento({name: '', value:{cod:res.data.orcamento[0].cod_condominio}}, false);
        }

        // SET TIPO FCR
        let repFR = null;
        if (Array.isArray(res.data.zonas) && res.data.zonas.length > 0) {
          res.data.zonas.forEach(zona => {
            if (repFR === null) {
              repFR = zona.reparticao_fr;
            }

            if (repFR !== null && repFR !== zona.reparticao_fr) {
              repFR = false;
            }
          });

          this.repProporcionalFR = (repFR && (repFR === 'E' || repFR === 'P' || repFR === 'PF')) ? repFR : 'M';
        }

        // await this.handleOrcDetailsObjFromApi(res);

        this.tabsObjDef[2].disabled = false;
        this.tabsObjDef[3].disabled = false;

        this.selCobrancaFR = '0';
        this.selCobrancaOrcamento = '0';
        this.appConfig.periodicityOpts.forEach(el => {
          if (el.model.length === this.cobrancaOrcamento.length && el.model.every((value, index) => { return value === this.cobrancaOrcamento[index].sel; })) {
            this.selCobrancaOrcamento = el.value;
          }
          if (el.model.length === this.cobrancaFR.length && el.model.every((value, index) => { return value === this.cobrancaFR[index].sel; })) {
            this.selCobrancaFR = el.value;
          }
        });

        // COMPUTE QUOTA SEGURO --------------------------------
        if (this.orcFraccoesSeg.length === 0) {
          if (!this.isCopy) {
            if (res.data.orcamento[0].id_act_seg) {
              this.api.getActSegORC(res.data.orcamento[0].id_act_seg).subscribe(async seg => {
                if (seg.hasOwnProperty('success') && seg.success) {
    
                  this.premioSeg = Number(seg.data.premio);
                  this.reparticaoSeg = seg.data.pagamento;
                  this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });

                  this.premilagemSeg = 0;
                  this.orcFraccoesSeg = [];
                  res.data.fraccoes_fr.forEach(el => {
                    if (el.paga_seg_colect === '1') this.premilagemSeg = this.premilagemSeg + Number(el.permilagem);
    
                    this.orcFraccoesSeg.push({
                      cod_fraccao: el.cod,
                      paga_seg_colect: el.paga_seg_colect,
                      permilagem: Number(el.permilagem),
                      quota_seg_anual: 0,
                    })
                  });
    
                  if (this.reparticaoSeg === 'P') {
                    this.orcFraccoesSeg.forEach(el => {
                      if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round(((this.premioSeg * el.permilagem) / this.premilagemSeg) * 100) / 100;
                    });
                  }
    
                  if (this.reparticaoSeg === 'E') {
                    let nFraccoes = this.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
                    this.orcFraccoesSeg.forEach(el => {
                      if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round((this.premioSeg / nFraccoes) * 100) / 100;
                    });
                  }
                  
                  await this.handleOrcDetailsObjFromApi(res);
                }
              }, err => {});
            } else {
              if (res.data.orcamento[0].val_lancado === '0') {
                this.api.getActSegCond(res.data.orcamento[0].cod_condominio).subscribe(async seg => {
                  if (seg.hasOwnProperty('success') && seg.success) {
      
                    this.premioSeg = Number(seg.data.premio);
                    this.reparticaoSeg = seg.data.pagamento;
                    this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });
      
                    this.premilagemSeg = 0;
                    this.orcFraccoesSeg = [];
                    res.data.fraccoes_fr.forEach(el => {
                      if (el.paga_seg_colect === '1') this.premilagemSeg = this.premilagemSeg + Number(el.permilagem);
      
                      this.orcFraccoesSeg.push({
                        cod_fraccao: el.cod,
                        paga_seg_colect: el.paga_seg_colect,
                        permilagem: Number(el.permilagem),
                        quota_seg_anual: 0,
                      })
                    });
      
                    if (this.reparticaoSeg === 'P') {
                      this.orcFraccoesSeg.forEach(el => {
                        if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round(((this.premioSeg * el.permilagem) / this.premilagemSeg) * 100) / 100;
                      });
                    }
      
                    if (this.reparticaoSeg === 'E') {
                      let nFraccoes = this.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
                      this.orcFraccoesSeg.forEach(el => {
                        if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round((this.premioSeg / nFraccoes) * 100) / 100;
                      });
                    }
                    await this.handleOrcDetailsObjFromApi(res);
                  }
                }, err => {});
              } else {
                await this.handleOrcDetailsObjFromApi(res);
              }
            }
          } else {
            this.api.getActSegCond(res.data.orcamento[0].cod_condominio).subscribe(async seg => {
              if (seg.hasOwnProperty('success') && seg.success) {
  
                this.premioSeg = Number(seg.data.premio);
                this.reparticaoSeg = seg.data.pagamento;
                this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });  
  
                this.premilagemSeg = 0;
                this.orcFraccoesSeg = [];
                res.data.fraccoes_fr.forEach(el => {
                  if (el.paga_seg_colect === '1') this.premilagemSeg = this.premilagemSeg + Number(el.permilagem);
  
                  this.orcFraccoesSeg.push({
                    cod_fraccao: el.cod,
                    paga_seg_colect: el.paga_seg_colect,
                    permilagem: Number(el.permilagem),
                    quota_seg_anual: 0,
                  })
                });
  
                if (this.reparticaoSeg === 'P') {
                  this.orcFraccoesSeg.forEach(el => {
                    if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round(((this.premioSeg * el.permilagem) / this.premilagemSeg) * 100) / 100;
                  });
                }
  
                if (this.reparticaoSeg === 'E') {
                  let nFraccoes = this.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
                  this.orcFraccoesSeg.forEach(el => {
                    if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round((this.premioSeg / nFraccoes) * 100) / 100;
                  });
                }
  
                await this.handleOrcDetailsObjFromApi(res);
              }
            }, err => {});
          }
        } else {
          await this.handleOrcDetailsObjFromApi(res);
        }
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  async setInitRubricasList(codCondominio) {
    return new Promise((resolve, reject) => {

      this.api.getCondZonasDetails(codCondominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          // CREATE RUBRICAS TABLE COLUMN OBJECT
          this.zonas = res.data.sort((a, b) => {
            if (Number(a.permilagem) < Number(b.permilagem)) {
              return 1;
            } else {
              return -1;
            }
          });
  
          this.rubricasListCol = [];
          this.zonas.forEach(el => {
            el['checked'] = true;
  
            this.rubricasListCol.push({
              key: 'null',
              name: el.nome,
              type: 'number',
              sort: null,
              searchable: false,
              data: el
            });
          });
  
          this.tabsObjDef[1].disabled = false;
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });

    });
  }

  async setInitFraccoesList(codCondominio) {
    return new Promise((resolve, reject) => {
      this.api.getOrcCondFraccoesDetails(codCondominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.fraccoes = res.data;
  
          this.fraccoesListCol = [];
          this.fraccoes.forEach(el => {
            this.fraccoesListCol.push({
              key: 'null',
              name: el.nome,
              type: 'number',
              sort: null,
              searchable: false,
              data: el
            });
          });

          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });

    });
  }

  firstCall = true;
  async handleOrcDetailsObjFromApi(res) {
    this.geralForm.patchValue({
      descricao: res.data.orcamento[0].descricao,
    });
    if (res.data.zonas.length === 0) {
      await this.setInitRubricasList(res.data.orcamento[0].cod_condominio);
      this.orcZonas = this.zonas;
    } else {
      this.orcZonas = res.data.zonas;
      this.zonas = this.orcZonas;
    }

    if (this.orcamentoId && this.fraccoes.length === 0) {
      await this.setInitFraccoesList(res.data.orcamento[0].cod_condominio);
    }

    this.orcFraccoes = res.data.fraccoes;
    this.orcFraccoesFR = res.data.fraccoes_fr;

    this.orcRubricas = res.data.rubricas;
    this.orcamento = (Array.isArray(res.data.orcamento)) ? res.data.orcamento[0] : null;

    if (this.isCopy) {
      if (!this.firstCall) {
        this.orcamento.periodo = (Number(this.orcamento.periodo)).toString();
      } else {
        this.orcamento.periodo = (Number(this.orcamento.periodo) + (this.copyToNextYear? 1 : 0)).toString();
        
      }
      this.orcamento.val_lancado = null;
      this.firstCall = false;
    }

    if (this.orcamento.data_lancamento) {
      this.orcamento.data_lancamento = this.utils.getDate(this.orcamento.data_lancamento);
    }


    this.orcamentoId = this.orcamento.id;
    this.orcamentoCod = this.orcamento.cod;

    // RUBRICA TABLE SECTION
    this.rubricasListCol = [];
    this.orcZonas.forEach(el => {
      this.rubricasListCol.push({
        key: 'null',
        name: el.nome,
        type: 'number',
        sort: null,
        searchable: false,
        data: el
      });
    });

    let auxRubricas = [];
    this.orcRubricas.forEach(el => {
      if (!auxRubricas.find(it => el.rub_cod === it.rub_cod)) auxRubricas.push(el); 
    });

    this.rubricasList = [];
    auxRubricas.forEach(el => {
      let newRubrica = {
        id: (el.hasOwnProperty('id') && el.id) ? el.id : null,
        cod: el.cod,
        nome: el.rub_nome,
        reparticao: (el.reparticao === 'P' || el.reparticao === 'E') ? el.reparticao : 'M',
        tipo: (el.tipo) ? el.tipo : null,
        cod_rubrica: el.cod_rubrica,
        valor: 0,
        checked: false,
        centered: true,
        total: 0,

        despesa: (el.despesa === '1'),
        receita: (el.receita === '1'),
      };

      this.zonas.forEach((el, i) => {
        newRubrica['valor-zona-' + i] = 0;
        newRubrica['cod-zona-' + i] = el.cod;
        newRubrica['perm-zona-' + i] = el.permilagem;
      });

      let aux = this.orcRubricas.filter(it => it.rub_cod === el.rub_cod);
      aux.forEach(it => {
        if (it) {
          for (let i = 0; i < this.zonas.length ; i++) {
            if (it.cod_zona === newRubrica['cod-zona-' + i]) {
              newRubrica['valor-zona-' + i] = Number(it.valor);
              break;
            }
          }
          newRubrica.valor += Number(it.valor);
        }
      });

      this.rubricasList.push(newRubrica);
    });

    this.computeRubricasTotal();

    // ORCAMENTO TABLE SECTION ----------------------------------------------
    if (Array.isArray(res.data.orcamento) && res.data.orcamento.length > 0) {

      this.orcamentoObj = res.data;

      // ALWAYS USE THE FIRST ZONE PERIODICITY (TODO - ADD THIS TO ORCAMENTO)
      if (this.orcamentoObj.zonas.length > 0) {
        this.cobrancaOrcamento = Array.prototype.map.call(this.orcamentoObj.zonas[0].meses_orc, (el) => { return { sel: (el === 'S') } });
        this.cobrancaFR = Array.prototype.map.call(this.orcamentoObj.zonas[0].meses_fr, (el) => { return { sel: (el === 'S') } });
      }

      let aux = null;
      aux = this.appConfig.periodicityOpts.find(el => (JSON.stringify(el.model) === JSON.stringify(this.cobrancaOrcamento)));
      if (aux) this.selCobrancaOrcamento = aux.value;

      aux = this.appConfig.periodicityOpts.find(el => (JSON.stringify(el.model) === JSON.stringify(this.cobrancaFR)));
      if (aux) this.selCobrancaFR = aux.value;

      this.getOrcamentoDatatable(this.orcamentoObj.zonas, this.orcamentoObj.fraccoes, this.orcamentoObj.fraccoes_fr);
    }

    this.restoreForm('geral');
  }

  getOrcamentoDatatable(zonas, fraccoes, fraccoes_fr) {
    // SET ORCAMENTO DATA TABLE
    this.orcamentoDataTable = [];

    zonas.forEach((zona, i) => {
      // GET REPARTICAO INDICATION STRING
      let seenRep = '';
      this.rubricasList.forEach(rubrica => {
        if (seenRep.indexOf(rubrica.reparticao) === -1 && rubrica['valor-zona-' + i] !== 0) {
          // ADD RUBRICA TO SEEN STRING
          seenRep += (rubrica.reparticao + '/');
        }
      });

      if (seenRep) seenRep = seenRep.slice(0, -1);

      // HANDLE ORCAMENTO TABLE
      let auxObj = {
        cod: zona.cod,
        nome: zona.nome,
        perm: Number(zona.permilagem),
        fr_reparticao: zona.pagamento_fr,
        fr_valor: 0,
        orc_reparticao: seenRep,
        orc_valor: 0,
        valor: null,
      };

      // fraccoes.filter(el => (el.cod_zona === zona.cod)).forEach(fraccao => {
      //   auxObj.orc_valor += Number(fraccao.valor);
      // });

      auxObj.orc_valor += this.rubricasTotais['total-zona-' + i];

      if (this.editRubricas) {
        let seenRubricas = '';
        fraccoes.filter(el => (el.cod_zona === zona.cod)).forEach((fraccao, i) => {

          if (seenRubricas.indexOf(fraccao.cod) === -1) {
            seenRubricas += fraccao.cod + '/';
            auxObj.fr_valor += Number(fraccao.quota_fr);
          }

        });
      } else {
        fraccoes_fr.filter(el => (el.cod_zona === zona.cod)).forEach(fraccao => {
          auxObj.fr_valor += Number(fraccao.valor);
        });
      }

      auxObj.valor = auxObj.orc_valor + auxObj.fr_valor;

      this.orcamentoDataTable.push(auxObj);
    });

    this.computeOrcamentoTotal();

    this.getSimulacaoDatatable();
  }

  getSimulacaoDatatable() {
    this.simulacaoList = this.getSimObject();

    if (this.isVistaMensalSimulacao) this.vistaMensalToggle('simulacao');
    this.computeSimulacaoTotal();
  }

  firstCallSim = true;
  getSimObject() {
    let simulation = [];
    let seenRubricas = '';
    this.orcamentoObj.fraccoes.sort((a, b) => { if ( a.cod !== null  && b.cod !== null && (a.cod > b.cod || a.cod.length > b.cod.length) ) { return 1; } else { return -1; } }).forEach(fraccao => {


      if (seenRubricas.indexOf(fraccao.cod) === -1) {
        seenRubricas += fraccao.cod + '/';

        let fraccaoFR = this.orcamentoObj.fraccoes_fr.find(el => (el.cod_fraccao === fraccao.cod_fraccao));
        if (this.editRubricas) {
          let aux = this.orcamentoObj.fraccoes.find(el => (el.cod_fraccao === fraccao.cod_fraccao));
          if (fraccaoFR) fraccaoFR['valor'] = aux['quota_fr'];
        }

        let zona = this.zonas.find(el => (el.cod === fraccao.cod_zona));

        simulation.push({
          id: fraccao.id,
          fraccao_id: fraccao.fraccao_id,
          cod: fraccao.cod,
          cod_zona: fraccao.cod_zona,
          cod_proprietario: fraccao.cod_proprietario,
          nome: fraccao.nome,
          zona_nome: (zona) ? zona.nome : null,
          permilagem: Number(fraccao.permilagem),

          quota_fr: (fraccaoFR) ? Number(fraccaoFR['valor']) : 0,
          quota_orc: Number(fraccao.valor),
          quota_seg: 0,
        });
      } else {
        let index = simulation.findIndex(it => (it.fraccao_id === fraccao.fraccao_id));
        if (index >= 0) {
          simulation[index].quota_orc += Number(fraccao.valor);
          simulation[index].total += Number(fraccao.valor);
        }
      }
    });

    simulation.forEach(el => {
      el.quota_seg = (this.orcFraccoesSeg.find(it => (it.cod_fraccao === el.cod && it.paga_seg_colect === '1'))) ? this.orcFraccoesSeg.find(it => (it.cod_fraccao === el.cod && it.paga_seg_colect === '1')).quota_seg_anual : 0;
      el.total = el.quota_orc + el.quota_fr + el.quota_seg;

      el.quota_fr_anual = el.quota_fr;
      el.quota_orc_anual = el.quota_orc;
      el.quota_seg_anual = el.quota_seg;
    });

    // CHECK IF ALL FRACCOES ARE AVAILABLE
    let hasAllFraccoes = true;
    for (let i = 0; i < this.orcamentoObj.fraccoes_fr.length; i++) {
      if (!this.orcamentoObj.fraccoes.find(el => (el.cod === this.orcamentoObj.fraccoes_fr[i].cod_fraccao))) {
        hasAllFraccoes = false;
        break;
      } 
    }

// if (this.firstCallSim && !hasAllFraccoes) {
//   this.firstCallSim = false;

//   this.action(this.rubricasList[0], 'rubricas-edit', null, false);
//   setTimeout(() => { 
//     this.formSubmitted('rubricas-add', '', false, true);

//     // TODO: MISSING HEAVEY TESTING
//     this.clearForm('rubricas-add');
//   }, 1);
// }

    return simulation.sort((a, b) => {
      if ( a.cod !== null && b.cod !== null && (a.cod > b.cod || a.cod.length > b.cod.length) ) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  computeSimulacaoTotal() {
    this.simulacaoTotais.permilagem = 0;
    this.simulacaoTotais.orcamento = 0;
    this.simulacaoTotais.fundoReserva = 0;
    this.simulacaoTotais.seguro = 0;
    this.simulacaoTotais.total = 0;

    this.simulacaoList.forEach(fraccao => {
      this.simulacaoTotais.permilagem += fraccao.permilagem;
      this.simulacaoTotais.fundoReserva += fraccao.quota_fr;
      this.simulacaoTotais.orcamento += fraccao.quota_orc;
      this.simulacaoTotais.seguro += fraccao.quota_seg;
      this.simulacaoTotais.total += fraccao.total;
    });
  }

  vistaMensalToggle(targetList) {
    // TODO: SHOW ALERT IF MESES COBRANCA NOT DEFINED!!!
    let nMesesFR = this.cobrancaFR.filter(el => (el.sel)).length;
    let nMesesOrcamento = this.cobrancaOrcamento.filter(el => (el.sel)).length;

    switch (targetList) {
      case 'orcamento-zona':
        if (this.orcamentoList.length === 0) return;

        if (this.isVistaMensalOrcamentoZona) {
          this.orcamentoList.forEach(el => {
            el.quota_orc = Math.round((el.quota_orc / nMesesOrcamento) * 100) / 100;
            el.quota_fr = Math.round((el.quota_fr / nMesesOrcamento) * 100) / 100;
            el.total = Math.round((el.quota_orc + el.quota_fr) * 100) / 100;
          });
        } else {
          this.orcamentoList.forEach(el => {
            el.quota_orc = Math.round((el.quota_orc * nMesesOrcamento) * 100) / 100;
            el.quota_fr = Math.round((el.quota_fr * nMesesOrcamento) * 100) / 100;
            el.total = Math.round((el.quota_orc + el.quota_fr) * 100) / 100;
          });
        }

        this.computeOrcamentoFraccoesTotal();
        break;
      case 'simulacao':
        if (this.simulacaoList.length === 0) return;

        if (this.isVistaMensalSimulacao) {
          this.simulacaoList.forEach(el => {
            el.quota_orc = Math.round((el.quota_orc_anual / nMesesOrcamento) * 100) / 100;
            el.quota_fr = Math.round((el.quota_fr_anual / nMesesOrcamento) * 100) / 100;
            el.quota_seg = Math.round((el.quota_seg_anual / nMesesOrcamento) * 100) / 100;
            el.total = Math.round((el.quota_orc + el.quota_fr + el.quota_seg) * 100) / 100;
          });
        } else {
          this.simulacaoList.forEach(el => {
            // el.quota_orc = Math.round((el.quota_orc * nMesesOrcamento) * 100) / 100;
            // el.quota_fr = Math.round((el.quota_fr * nMesesOrcamento) * 100) / 100;
            // el.quota_seg = Math.round((el.quota_seg * nMesesOrcamento) * 100) / 100;
            // el.total = Math.round((el.quota_orc + el.quota_fr + el.quota_seg) * 100) / 100;

            el.quota_orc = (el.quota_orc_anual);
            el.quota_fr = (el.quota_fr_anual);
            // el.quota_seg = this.fraccoes.find(it => (it.cod === el.cod)).seg_premio_anual;
            el.quota_seg = (el.quota_seg_anual);
            el.total = (el.quota_orc + el.quota_fr + el.quota_seg);
          });
        }

        this.computeSimulacaoTotal();
        break;
    }
  }


  getFormCodCondominio():string {
    let cod_condominio = this.geralForm.get('cod_condominio').value;
    if (!cod_condominio) return null;
    return cod_condominio.hasOwnProperty('value') ? cod_condominio.value.cod : cod_condominio.cod;
  }

  genOrcamentoDatatable(formModalFR=false, fromRubricas=false) {
    let fraccoesAux = [];
    let fraccoesOrc = [];
    if (this.orcamentoObj) {
      fraccoesAux = JSON.parse(JSON.stringify(this.orcamentoObj.fraccoes_fr));
      fraccoesOrc = JSON.parse(JSON.stringify(this.orcamentoObj.fraccoes));
    }

    this.orcamentoObj = { zonas: [], fraccoes: [], fraccoes_fr: fraccoesAux };

    // COMPUTE TOTAL FOR FUNDO RESERVA REPARTITION
    let nTotalFraccoes = 0;
    let totalPermilagemZonas = 0;
    this.zonas.forEach(zona => { 
      nTotalFraccoes += Number(zona.n_fraccoes);
      totalPermilagemZonas += Number(zona.permilagem);
    });

    // CREATE FRACCOES FR OBJECT
    if (fraccoesAux.length === 0) {
      this.fraccoes.forEach(el => {
        let aux = {
          id: null,
          cod: null,
          cod_orcamento: null,
          cod_fraccao: el.cod,
          cod_zona: el.zona_cod,
          valor: 0,
        };
  
        this.orcamentoObj.fraccoes_fr.push(aux);
      });
    }

    this.zonas.forEach((zona, i) => {

      // HANDLE ZONAS
      let zonaTotalPermilagem = 0;
      let auxTotalFR = 0;
      this.rubricasList.filter(el => (el['cod-zona-' + i] === zona.cod)).forEach(rubrica => {
        // if (rubrica['valor-zona-' + i] !== 0) {
          zonaTotalPermilagem += Number(zona.permilagem);
          auxTotalFR += Number(this.rubricasTotais['total-zona-' + i]);
        // }
      });

      let auxZona = {
        id: zona.id,
        cod: zona.cod,
        cod_zona: zona.cod,
        meses_orc: this.utils.getStrMesesV2(this.cobrancaOrcamento),
        meses_fr: this.utils.getStrMesesV2(this.cobrancaFR),
        valor_fr: 0,

        // reparticao_fr: (zona.reparticao_fr) ? zona.reparticao_fr : this.repProporcionalFR,
        reparticao_fr: (!formModalFR) ? zona.reparticao_fr : this.repProporcionalFR,
        pagamento_fr: (!formModalFR) ? zona.reparticao_fr : this.repProporcionalFR,

        nome: zona.nome,
        n_fraccoes: Number(zona.n_fraccoes),
        permilagem: Number(zona.permilagem),
        perm_seg: null,
        n_frac_seg: null,
        saldo_inicial: null,
        saldo_inicial_fr: null,
        permilagem_aux_1: null,
        permilagem_aux_2: null,
        orc_zona_id: (zona.hasOwnProperty('orc_zona_id')) ? zona.orc_zona_id : null,
        cod_condominio: (this.orcamento.hasOwnProperty('cod_condominio') && this.orcamento.cod_condominio) ? this.orcamento.cod_condominio : this.getFormCodCondominio(),
      }

      // SELECT REPARTICAO PROPORCIONAL - MUDAR RUBRICAS E MANTER A PERCENTAGEM DE FUNDO DE RESERVA
      if (this.repProporcionalFR === 'M' && fromRubricas) {
        this.repProporcionalFR = 'P';
      }

      if (this.isPercentageFR) {

        switch (this.repProporcionalFR) {
          case 'P':
            auxZona.valor_fr = this.rubricasTotais.total * (this.selectedTotalFR / 100) * Number(zona.permilagem);
            break;
          case 'E':
            auxZona.valor_fr = ((this.rubricasTotais.total * (this.selectedTotalFR / 100)) / nTotalFraccoes) * Number(zona.n_fraccoes);
            break;
          case 'PF':
            auxZona.valor_fr = (auxTotalFR * (this.selectedTotalFR / 100) * Number(zona.permilagem)) / zonaTotalPermilagem;
            break;
        }

      } else {

        switch (this.repProporcionalFR) {
          case 'P':
            // REVIEW: IF THIS IS DIFF FROM case 'P'
            auxZona.valor_fr = (this.selectedTotalFR * Number(zona.permilagem)) / totalPermilagemZonas;
            break;
          case 'E':
            auxZona.valor_fr = (this.selectedTotalFR / nTotalFraccoes) * Number(zona.n_fraccoes);
            break;
          case 'PF':
            auxZona.valor_fr = (this.selectedTotalFR * Number(zona.permilagem)) / totalPermilagemZonas;
            break;
        }

      }

      this.orcamentoObj.zonas.push(auxZona);
    });

    // HANDLE FRACCOES
    let totalRubricaFracArr = [];
    this.zonas.forEach((zona, i) => {
      let totalRubricaFrac = 0;
      this.rubricasList.forEach(rubrica => {
        totalRubricaFrac += rubrica['valor-zona-' + i];
      });
      totalRubricaFracArr.push({ totalRubZona: totalRubricaFrac, zona_cod: zona.cod, zona_permilagem: Number(zona.permilagem) });
    });

    for (let i = 0; i < this.zonas.length; i++) {
      this.rubricasList.forEach(rubrica => {

        this.fraccoes.forEach(fraccao => {
          if (rubrica['cod-zona-' + i] === fraccao.zona_cod) {
            let auxFraccao = {
              id: fraccao.id,
              fraccao_id: fraccao.id,
              cod: fraccao.cod,
              cod_orcamento: null,
              cod_zona: fraccao.zona_cod,
              cod_rubrica: rubrica.cod_rubrica,
              cod_fraccao: fraccao.cod,
              cod_proprietario: fraccao.cod_proprietario,
              valor: 0,
              nome: fraccao.nome,
              permilagem: fraccao.permilagem,
              quota_orc: 0,
              quota_fr: 0,
              quota_seg: null,
              permilagem_aux_1: null,
              permilagem_aux_3: null,
              orc_fraccao_id: (this.orcFraccoes && Array.isArray(this.orcFraccoes) && this.orcFraccoes.find(el => (el.cod_fraccao === fraccao.cod && el.cod_rubrica === rubrica.cod_rubrica))) ? this.orcFraccoes.find(el => (el.cod_fraccao === fraccao.cod && el.cod_rubrica === rubrica.cod_rubrica)).orc_fraccao_id : null,
              cod_condominio: (this.orcamento.hasOwnProperty('cod_condominio') && this.orcamento.cod_condominio) ? this.orcamento.cod_condominio : this.getFormCodCondominio(),
            }


if (rubrica.reparticao === 'P') {
  auxFraccao.valor = (rubrica['valor-zona-' + i] * Number(fraccao.permilagem)) / totalRubricaFracArr.find(el => (el.zona_cod === fraccao.zona_cod)).zona_permilagem;
}
if (rubrica.reparticao === 'E' || rubrica.reparticao === 'M') {
  auxFraccao.valor = rubrica['valor-zona-' + i] / this.fraccoes.filter(el => el.zona_cod === this.zonas[i].cod).length;  // NAO PARTICIPA NA RUBRICA
}


            if (this.isPercentageFR) {
              switch (this.repProporcionalFR) {
                case 'P':  // GCOND VRSION
                  auxFraccao.quota_fr = this.rubricasTotais.total * (this.selectedTotalFR / 100) * (Number(fraccao.permilagem) / totalPermilagemZonas);
                  auxFraccao.quota_fr = Math.round(auxFraccao.quota_fr * 1000) / 1000;
                  break;
                case 'E':
                  auxFraccao.quota_fr = this.orcamentoObj.zonas[i].valor_fr / Number(this.zonas[i].n_fraccoes);
                  auxFraccao.quota_fr = Math.round(auxFraccao.quota_fr * 1000) / 1000;
                  break;
                case 'PF':
                  auxFraccao.quota_fr = (this.orcamentoObj.zonas[i].valor_fr * Number(fraccao.permilagem)) / totalRubricaFracArr.find(el => (el.zona_cod === fraccao.zona_cod)).zona_permilagem;
                  auxFraccao.quota_fr = Math.round(auxFraccao.quota_fr * 1000) / 1000;
                  break;
                default:  // MANUAL MODE
                  auxFraccao.quota_fr = (fraccoesAux.find(el => (el.cod === fraccao.cod))) ? Number(fraccoesAux.find(el => (el.cod === fraccao.cod)).quota_fr) : 0;
                  auxFraccao.quota_fr = Math.round(auxFraccao.quota_fr * 1000) / 1000;
                  break;
              }
            } else {
              switch (this.repProporcionalFR) {
                case 'P':  // GCOND VRSION
                  auxFraccao.quota_fr = this.selectedTotalFR * (Number(fraccao.permilagem) / totalPermilagemZonas);
                  auxFraccao.quota_fr = Math.round(auxFraccao.quota_fr * 1000) / 1000;
                  break;
                case 'E':
                  auxFraccao.quota_fr = this.orcamentoObj.zonas[i].valor_fr / Number(this.zonas[i].n_fraccoes);
                  auxFraccao.quota_fr = Math.round(auxFraccao.quota_fr * 1000) / 1000;
                  break;
                case 'PF':
                  auxFraccao.quota_fr = (this.orcamentoObj.zonas[i].valor_fr * Number(fraccao.permilagem)) / totalRubricaFracArr.find(el => (el.zona_cod === fraccao.zona_cod)).zona_permilagem;
                  auxFraccao.quota_fr = Math.round(auxFraccao.quota_fr * 1000) / 1000;
                  break;
                default:  // MANUAL MODE
                  auxFraccao.quota_fr = (fraccoesAux.find(el => (el.cod === fraccao.cod))) ? Number(fraccoesAux.find(el => (el.cod === fraccao.cod)).quota_fr) : 0;
                  auxFraccao.quota_fr = Math.round(auxFraccao.quota_fr * 1000) / 1000;
                  break;
              }
            }

            this.orcamentoObj.fraccoes.push(auxFraccao);
          }
        });
      });
    }

                            let seenFraccoes = '';
                            this.orcamentoObj.fraccoes.forEach(fraccao => {
                              if (seenFraccoes.indexOf(fraccao.cod) === -1) {
                                seenFraccoes += fraccao.cod + '/';
                              }
                            });

let fraccoesSet = [... new Set(this.orcamentoObj.fraccoes.map(it => it.cod))];
fraccoesSet.forEach(fraccaoCod => {
  // ORCAMENTO
  let quota_orc = 0;

  if (formModalFR) {


    this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
      if (fraccoesOrc.length === 0) {
        quota_orc = fraccao.valor;
      } else {
        let aux = fraccoesOrc.find(el => (el.orc_fraccao_id === fraccao.orc_fraccao_id));
        fraccao.valor = ( (aux) ? Number(aux.valor) : fraccao.valor );
        quota_orc += ( (aux) ? Number(aux.valor) : fraccao.valor );
      }
    });


    this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
      fraccao.quota_orc = Math.round(quota_orc * 100) / 100;
    });


  } else {
    this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
      // if (fraccoesOrc.length === 0) {
      //   quota_orc = fraccao.valor;
      // } else {
        quota_orc += fraccao.valor;
      // }
    });

    this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
      fraccao.quota_orc = Math.round(quota_orc * 100) / 100;
    });
  }

  // FUNDO RESERVA
  let quota_fr = 0;
  this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
    quota_fr = fraccao.quota_fr;
  });

  this.orcamentoObj.fraccoes_fr.filter(fraccao => (fraccao.cod_fraccao === fraccaoCod)).forEach((fraccao, i) => {
    if (this.orcamentoCod && this.orcamentoCod === fraccao.cod_orcamento) {
      fraccao.valor = Math.round(fraccao.valor * 100) / 100;
    } else {
      fraccao.valor = Math.round(quota_fr * 100) / 100;
    }

    if (formModalFR) fraccao.valor = Math.round(quota_fr * 100) / 100;
  });
});

                            // for (var i = 0; i < seenFraccoes.length; i++) {
                            //   let fraccaoCod = seenFraccoes.charAt(i);

                            //   // ORCAMENTO
                            //   let quota_orc = 0;

                            //   if (formModalFR) {
                            //     // this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
                            //     //   // if (fraccoesOrc.length === 0) {
                            //     //   //   quota_orc = fraccao.valor;
                            //     //   // } else {
                            //     //     let aux = fraccoesOrc.find(el => (el.orc_fraccao_id === fraccao.orc_fraccao_id));
                            //     //     fraccao.valor = ( (aux) ? Number(aux.valor) : fraccao.valor );
                            //     //     quota_orc += ( (aux) ? Number(aux.valor) : fraccao.valor );
                            //     //   // }
                            //     // });

                            //     this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
                            //       fraccao.quota_orc = Math.round(quota_orc * 100) / 100;
                            //     });


                            //   } else {
                            //     this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
                            //       // if (fraccoesOrc.length === 0) {
                            //       //   quota_orc = fraccao.valor;
                            //       // } else {
                            //         quota_orc += fraccao.valor;
                            //       // }
                            //     });
                          
                            //     this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
                            //       fraccao.quota_orc = Math.round(quota_orc * 100) / 100;
                            //     });
                            //   }

                            //   // FUNDO RESERVA
                            //   let quota_fr = 0;
                            //   this.orcamentoObj.fraccoes.filter(fraccao => (fraccao.cod === fraccaoCod)).forEach((fraccao, i) => {
                            //     quota_fr = fraccao.quota_fr;
                            //   });

                            //   this.orcamentoObj.fraccoes_fr.filter(fraccao => (fraccao.cod_fraccao === fraccaoCod)).forEach((fraccao, i) => {
                            //     if (this.orcamentoCod && this.orcamentoCod === fraccao.cod_orcamento) {
                            //       fraccao.valor = Math.round(fraccao.valor * 100) / 100;
                            //     } else {
                            //       fraccao.valor = Math.round(quota_fr * 100) / 100;
                            //     }

                            //     if (formModalFR) fraccao.valor = Math.round(quota_fr * 100) / 100;
                            //   });
                            // }

    this.getOrcamentoDatatable(this.orcamentoObj.zonas, this.orcamentoObj.fraccoes, this.orcamentoObj.fraccoes_fr);

    this.isPercentageFR = true;
  }

  setDates() {
    this.peridicityDisabled = true;
    setTimeout(() => { this.peridicityDisabled = false; }, 10);

    setTimeout(() => {

      let firstMonth = 0;
      let lastMonth = 0;
  
      for (let i = 0; i < 12; i++) {
        if (!this.cobrancaOrcamento[i].sel && !this.cobrancaFR[i].sel) {
          firstMonth = i+1;
        } else {
          break;
        }
      }
  
      for (let i = 0; i < 12; i++) {
        if (this.cobrancaOrcamento[i].sel || this.cobrancaFR[i].sel) {
          lastMonth = i;
        }
      }

      let exericio = this.geralForm.get('periodo').value;
      this.geralForm.patchValue({
        dt_inicio: new Date(exericio, firstMonth, 1),
        dt_fim: (lastMonth === 11) ? new Date(exericio, 11, 31) : new Date(exericio, lastMonth + 1, 0),
      });

    }, 1);
  }

  monthSelected(targetList, i) {
    switch (targetList) {
      case 'orcamento':
        if (this.cobrancaOrcamento[i].sel) {
          this.cobrancaOrcamento[i].sel = false;
        } else {
          this.cobrancaOrcamento[i].sel = true;
        }
        break;
      case 'fundo-reserva':
        if (this.cobrancaFR[i].sel) {
          this.cobrancaFR[i].sel = false;
        } else {
          this.cobrancaFR[i].sel = true;
        }
        break;
    }

    // ADJUSTE DATES
    this.setDates();

    this.genOrcamentoDatatable();
  }

  peridicityDisabled = false;
  setPeriodicity(ev, target) {
    if (this.peridicityDisabled) return;

    setTimeout(() => {

      let startDate = (target === 'START') ? ev : this.geralForm.get('dt_inicio').value;
      let endDate = (target === 'END') ? ev : this.geralForm.get('dt_fim').value;
  
      if (startDate && endDate) {
        let startMonth = startDate.getMonth();
        let endMonth = endDate.getMonth();
  
        this.cobrancaOrcamento.forEach((el, i) => {
          if (i >= startMonth && i <= endMonth) {
            this.cobrancaOrcamento[i].sel = true;
            this.cobrancaFR[i].sel = true;
          } else {
            this.cobrancaOrcamento[i].sel = false;
            this.cobrancaFR[i].sel = false;
          }
        });

        if (this.isVistaMensalSimulacao) this.vistaMensalToggle('simulacao');
      }
    }, 1);
  }

  clearForm(targetForm) {
    switch (targetForm) {
      case 'rubricas-add':
        this.zonas.forEach(el => { el.checked = true; });

        this.rubricaAddForm.reset();
        this.rubricaSelected = null;
        this.allZonasSelected = true;
        this.repProporcionalZonas = 'P';
        this.tipoRubrica = 'F';
        this.tipoRubricaDR = 'D';
        break;
    }
  }

  rubricaSel(rubrica) {
    this.tipoRubrica = (rubrica.value.valFixo) ? 'F' : 'V';
    this.tipoRubricaDR = (rubrica.value.receita) ? 'R' : 'D';
  }

  acertoTypeSelected(ev) {
    this.tipoAcertoSel = ev.value;

    switch (this.tipoAcertoSel) {
      case '0':  // Manual
        // console.log('ACERTO MANUAL SELECTED');
        break;
      case '1':  // Fundo Reserva
        // console.log('ACERTO FUNDO RESERVA SELECTED');
        break;
    }
  }

  restoreForm(targetForm) {
    this.submittingForm = false;

    switch (targetForm) {
      case 'orcamento':
        this.orcamentoList = JSON.parse(JSON.stringify(this.orcamentoListOrig));
        this.computeOrcamentoFraccoesTotal();
        this.orcTotalZonaOK = true;
        this.orcTotalDiff = 0;
        break;
      case 'geral':
        if (!this.isCreate) {
          let dt_inicio = null;
          let dt_fim = null;
          if (this.isCopy) {
            dt_inicio = new Date(this.utils.getDate(this.orcamento.dt_inicio).setFullYear(this.orcamento.periodo));
            dt_fim = new Date(this.utils.getDate(this.orcamento.dt_fim).setFullYear(this.orcamento.periodo));
          } else {
            dt_inicio = this.utils.getDate(this.orcamento.dt_inicio);
            dt_fim = this.utils.getDate(this.orcamento.dt_fim);
          }

          let cod = {name: this.orcamento.cod_condominio + ' - ' + this.orcamento.nome_condominio, value: { cod: this.orcamento.cod_condominio, id: this.orcamento.id_condominio, nome: this.orcamento.nome_condominio }}

          this.geralForm.patchValue({
            cod_condominio: cod,
            periodo: this.orcamento.periodo,
            dt_inicio: dt_inicio,
            dt_fim: dt_fim,
            descricao: this.orcamento.descricao,
          });

          this.updateDefaultDescricao();

          this.selCobrancaFR = '0';
          this.selCobrancaOrcamento = '0';
        } else {

          let auxData = this.geralForm.getRawValue();

          // this.geralForm.patchValue({
          //   cod_condominio: null,
          //   periodo: (new Date()).getFullYear(),
          //   dt_inicio: new Date((new Date()).getFullYear(), 0, 1),
          //   dt_fim: new Date((new Date()).getFullYear(), 11, 31),
          // });

          this.geralForm.reset();
          this.geralForm.patchValue({
            cod_condominio: auxData.cod_condominio,
            periodo: auxData.periodo,
            dt_inicio: new Date(auxData.dt_inicio),
            dt_fim: new Date(auxData.dt_fim),
            descricao: auxData.descricao,
          });

          this.selCobrancaFR = '1';
          this.selCobrancaOrcamento = '1';
        }

        break;
    }
  }

  cobrancaSelected(targetList) {
    let selection = null;
    switch (targetList) {
      case 'orcamento':
        selection = this.appConfig.periodicityOpts.find(el => (el.value === this.selCobrancaOrcamento));
        if (selection && selection.model.length > 0) {
    
          selection.model.forEach((el, i) => {
            this.cobrancaOrcamento[i].sel = el;
          });
        }
        break;
      case 'fundo-reserva':
        selection = this.appConfig.periodicityOpts.find(el => (el.value === this.selCobrancaFR));
        if (selection && selection.model.length > 0) {
    
          selection.model.forEach((el, i) => {
            this.cobrancaFR[i].sel = el;
          });
        }
        break;
    }
  }

  getZonasbyCondominios(selection=null) {
    let codCondominio = (selection.value) ? selection.value.cod : this.orcamento.cod_condominio;

    this.setInitRubricasList(codCondominio);
  }

  getFraccoesbyCondominios(selection=null) {
    let codCondominio = (selection.value) ? selection.value.cod : this.orcamento.cod_condominio;
    this.setInitFraccoesList(codCondominio);
  }

  async tableAction(action, targetList) {
    if (this.orcamento.val_lancado === '1') {
      return;
    }
    switch (targetList) {
      case 'rubricas':
        switch (action) {
          case 'add': this.add(); break;
          case 'delete':
            let toDelete = this.rubricasList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert('delete').then(res => {
                if (res) this.del(toDelete);
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
    }
  }

  editRubricas = false;
  add(edit=false) {
    this.editRubricas = edit;

    if (this.tipoRubrica !== 'V') this.tipoRubrica = 'F';

    if (!edit) {
      this.zonas.forEach(el => { el.checked = true; });
    }

    this.addModalRef = this.modalService.open(this.addModalConfig)
      .onApprove(() => {
        if (!edit) {
          this.addRubricaModalRef = this.modalService
          .open(this.addRubricaAlertConfig)
          .onApprove(() => {
            this.add(); 
          })
          .onDeny(() => {});
        }
        this.editRubricas = false;
      })
      .onDeny(() => { this.clearForm('rubricas-add'); this.editRubricas = false; });
  }

  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'zonas':
        (ev.target.checked) ? this.zonas.map(el => el.checked = true ) : this.zonas.map(el => el.checked = false );
        break;
    }
  }

  frTypeSelected() {
    let FCR = Math.round((((this.orcamentoTotais.valor / this.orcamentoTotais.orc_valor) - 1) * 100) * 100) / 100;

    if (this.tabsObjDef[3].disabled) FCR = 10;

    if (FCR) {
      this.selectedTotalFR = (this.isPercentageFR) ? FCR : 0;
    } else {
      this.selectedTotalFR = (this.isPercentageFR) ? 10 : 0;
    }
  }

  setFundoReserva() {
    this.fundoReservaModalRef = this.modalService
      .open(this.fundoReservaAlertConfig)
      .onApprove(() => {

        if (this.orcamento.val_lancado !== '1') {
    
            this.selectedTotalFR = Number(this.selectedTotalFR);

            this.tabsObjDef[2].disabled = false;
            this.tabsObjDef[3].disabled = false;

            this.genOrcamentoDatatable(true);
            this.isFRSetup = true;

            this.absFCRValueDisabled = false;
        }
      })
      .onDeny(() => { this.absFCRValueDisabled = false; });
  }

  saving = false;
  formSubmittedBtn(targetForm) {
    if (this.saving) return;

    this.formSubmitted(targetForm);
  }

  copyQuota(ev, entry) {
    if (this.tipoAcertoSel === '1') {
      let quota = entry.total;
      this.orcamentoList.forEach(el => {
        if (entry !== el) {
          let diff = quota - el.total;
          el.total = quota;
          if (this.tipoAcertoSel === '1') {  // FUNDO RESERVA
            el.quota_fr = el.quota_fr + diff;
            el.total = quota;
          }
        }
      });
      
      this.computeOrcamentoFraccoesTotal();
      this.orcTotalZonaOK = true;
      this.orcTotalDiff = 0;
    }
  }

  closeModals() {
    if (this.addModalRef) this.addModalRef.approve();
  }

  loadingLancarValores = false;
  fetching = false;
  async formSubmitted(targetForm, targetFlag='', fromManual=false, init=false) {

    let data = this.geralForm.getRawValue();

    if ((this.orcamento && this.orcamento.hasOwnProperty('val_lancado') && this.orcamento.val_lancado === '1') || this.activeOrcExerc.find(el => el.exercicio == data.periodo && el.val_lancado === '1')) {
      this.toastr.error('Orçamento já se encontra com valores lançados.', 'Ups...!', { timeOut: 4000 });
      this.closeModals();
      this.submitting = false;
      return;
    }
    
    switch (targetForm) {
      case 'geral':
      case 'rubricas':
      case 'orcamento-geral':
         // NEW ORCAMENTO (CHECK IF EXERCICIO ALREADY EXISTE)
        
        if (this.rubricasList.length === 0) {
          this.toastr.error('Adicionar rubricas antes de guardar o presente orçamento.', 'Ups...!', { timeOut: 4000 });
          this.submitting = false;
          return;
        }

        if (!data.descricao || data.descricao.trim() === '') {
          this.toastr.error('Introduza uma descrição antes de guardar o presente orçamento.', 'Ups...!', { timeOut: 4000 });
          this.submitting = false;
          return;
        }

        if (data.descricao.length > 50) {
          this.toastr.error('A descrição introduzida excede o tamanho máximo permitido.', 'Ups...!', { timeOut: 4000 });
          this.submitting = false;
          return;
        }


        if (this.rubricasList.length > 0 && !this.isFRSetup) {
          // ASK FOR FUNDO DE RESERVA
          this.fundoReservaModalRef = this.modalService
            .open(this.fundoReservaAlertConfig)
            .onApprove(async () => {

              // this.isPercentageFR = true;

              this.tabsObjDef[2].disabled = false;
              this.tabsObjDef[3].disabled = false;

              this.genOrcamentoDatatable(true);
              this.isFRSetup = true;

              this.saveOrcamento(targetFlag);

              this.absFCRValueDisabled = false;
            })
            .onDeny(() => { this.absFCRValueDisabled = false; });
        } else {

          this.saveOrcamento(targetFlag);
        }
        break;
      case 'rubricas-add':
        if (!fromManual) {

          this.submittingModalForm = true;

          if (!this.rubricaAddForm.valid && !init) return;

          if (this.repProporcionalZonas !== 'P' && this.repProporcionalZonas !== 'E' && !init) {
            this.toastr.error('Escolha o tipo de repartição pretendido para esta rubrica.', 'Alerta', { timeOut: 4000 });
            return;
          }

          let rubIndex = 0;
          if (!init) {
            rubIndex = (this.rubricaAddForm.getRawValue().cod.cod) ? this.rubricasList.findIndex(rub => rub.cod === this.rubricaAddForm.getRawValue().cod.cod) : this.rubricasList.findIndex(rub => rub.cod === this.rubricaAddForm.getRawValue().cod.value.cod);
          }

          // TODO: TEST WITH ORCAMENTO COPY... 
          let editMode = false;
          if (rubIndex !== -1) {

            if ((this.rubricasList[rubIndex].reparticao !== 'M' || this.repProporcionalZonas === 'P' || this.repProporcionalZonas === 'E') && !fromManual) {
              this.rubricasList[rubIndex] = this.getRubricaRowObj(this.rubricasList[rubIndex]);
              this.rubricasList = JSON.parse(JSON.stringify(this.rubricasList));
              editMode = true;
            }

          } else {
            this.rubricasList.push(this.getRubricaRowObj());
          }

          if (this.addModalRef) this.addModalRef.approve();
          this.computeRubricasTotal();

          if (this.editRubricas) {
            this.genOrcamentoDatatable(false, true);
          } else {
            this.genOrcamentoDatatable(true, true);
          }

          // if (!this.isCreate) {
          //   this.genOrcamentoDatatable(true);
          // } else {
          //   this.genOrcamentoDatatable();
          // }

          setTimeout(() => { this.clearForm('rubricas-add'); }, 500);
          this.submittingModalForm = false;

        } else {
          this.genOrcamentoDatatable(false, true);
        }

        break;
      case 'lancar-valores':

        if (targetFlag === '') {
          this.formSubmitted('orcamento-geral', 'lancar-valores');
          return;
        }

        this.lancarQuotasModalRef = this.modalService
          .open(this.lancarQuotasAlertConfig)
          .onApprove(async () => {

            if (this.orcamentoTotais.orc_valor && this.orcamentoTotais.fr_valor) {
              let frRes = null;

              if (this.selectedTotalFR < 10 || this.orcamentoTotais.orc_valor * 0.1 > this.orcamentoTotais.fr_valor + 0.1) { // [TODO]: REMOVE MARTELO 0.1 -> 0.01
                // FR LESS THEN 10%
                frRes = await this.presentAlertFR();
                setTimeout(() => { this.absFCRValueDisabled = false; }, 100);
                if (!frRes) return;
              }
            }

            // CHECK IF PERMILAGEM IS 1000 %
            if (this.orcamentoTotais.hasOwnProperty('permilagem') && (this.orcamentoTotais.permilagem < 999.99 || this.orcamentoTotais.permilagem > 1000.009)) {
              let res = await this.presentPermilagemAlert();

              if (this.isCreate) {
                this.tabsObjDef[2].disabled = true;
                this.tabsObjDef[3].disabled = true;
              }

              if (!res) return;
            }

            // this.loading = true;
            this.loadingLancarValores = true;

            // GENERATE PROCESSAMENTOS, AVISOS AND DESPESAS -----------------------
            let processamentos = [];
            let nOrcPrestacao = 0; let orcObjProcessamento = null; let orcObjAviso = null;
            let nFRPrestacao = 0; let frObjProcessamento = null; let frObjAviso = null;
            let nSegPrestacao = 0; let segObjProcessamento = null; let segObjAviso = null;

            // let totalOrc = (!this.isVistaMensalOrcamentoZona) ? (this.orcamentoTotais.orc_valor * this.cobrancaOrcamento.filter(el => (el.sel)).length) : this.orcamentoTotais.orc_valor;
            // let totalFR = (!this.isVistaMensalOrcamentoZona) ? (this.orcamentoTotais.fr_valor * this.cobrancaFR.filter(el => (el.sel)).length) : this.orcamentoTotais.fr_valor;
            
            let totalOrc = this.orcamentoTotais.orc_valor / this.cobrancaOrcamento.filter(el => (el.sel)).length;
            let totalFR = this.orcamentoTotais.fr_valor / this.cobrancaFR.filter(el => (el.sel)).length;
            let totalSeg = (this.isVistaMensalSimulacao) ? (this.simulacaoTotais.seguro * this.cobrancaOrcamento.filter(el => (el.sel)).length) : this.simulacaoTotais.seguro;

            let nPrestacaoSeg = this.segMonths.filter(el => (el.active)).length;

            let firstMonthIndex = 0;
            this.months.forEach((month, i) => {
              // ADD PROCESSAMENTO ORCAMENTO
              if (this.cobrancaOrcamento[i].sel) {
                if (firstMonthIndex === 0) firstMonthIndex = i;

                nOrcPrestacao++;

                orcObjProcessamento = {
                  mes: i+1,
                  ano: this.orcamento.periodo,
                  cod_condominio: this.orcamento.cod_condominio,
                  exercicio: this.orcamento.periodo,
                  tipo_proc: 'O',
                  data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                  data_venc: null,
                  descricao: i+1 + ' ª Prestação do Orçamento',
                  // descricao: nOrcPrestacao + ' ª Prestação do Orçamento',
                  pluri_anual: '0',
                  nome: null,
                  valor: totalOrc,
                  avisos: [],
                  despesas: [],
                };

                this.simulacaoList.forEach((fraccao, j) => {
                  orcObjAviso = {
                    cod_condominio: this.orcamento.cod_condominio,
                    n_aviso: null,
                    mes: i+1,
                    ano: this.orcamento.periodo,
                    dt_emissao: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                    cod_fraccao: fraccao.cod,
                    cod_zona: fraccao.cod_zona,
                    dt_vencimento: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1+7)),
                    cod_pagador: this.fraccoes.find(el => (el.cod === fraccao.cod)).cod_proprietario,
                    tipo_proc: 'O',
                    cod_proc: null,
                    descricao: i+1 + ' ª Prestação do Orçamento',
                    // descricao: nOrcPrestacao + ' ª Prestação do Orçamento',
                    valor: (this.isVistaMensalSimulacao) ? fraccao.quota_orc : fraccao.quota_orc / this.cobrancaOrcamento.filter(el => (el.sel)).length,
                    debito: (this.isVistaMensalSimulacao) ? fraccao.quota_orc : fraccao.quota_orc / this.cobrancaOrcamento.filter(el => (el.sel)).length,
                    criado_por: this.userSession.getUserId(),
                    criado_em: this.utils.getFormatedDate(new Date()),
                    alterado_por: null,
                    alterado_em: null
                  }
                  if (fraccao.quota_orc > 0) orcObjProcessamento.avisos.push(orcObjAviso);
                });

                processamentos.push(orcObjProcessamento);
              }

              // ADD PROCESSAMENTO FUNDO RESERVA
              if (this.cobrancaFR[i].sel) {
                nFRPrestacao++;

                frObjProcessamento = {
                  mes: i+1,
                  ano: this.orcamento.periodo,
                  cod_condominio: this.orcamento.cod_condominio,
                  exercicio: this.orcamento.periodo,
                  tipo_proc: 'F',
                  data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                  data_venc: null,
                  descricao: i+1 + ' ª Prestação do FCR',
                  // descricao: nFRPrestacao + ' ª Prestação do FCR',
                  pluri_anual: '0',
                  nome: null,
                  valor: totalFR,
                  avisos: [],
                  despesas: [],
                };

                this.simulacaoList.forEach((fraccao, j) => {
                  frObjAviso = {
                    cod_condominio: this.orcamento.cod_condominio,
                    n_aviso: null,
                    mes: i+1,
                    ano: this.orcamento.periodo,
                    dt_emissao: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                    cod_fraccao: fraccao.cod,
                    cod_zona: fraccao.cod_zona,
                    dt_vencimento: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1+7)),
                    cod_pagador: this.fraccoes.find(el => (el.cod === fraccao.cod)).cod_proprietario,
                    tipo_proc: 'F',
                    cod_proc: null,
                    descricao: i+1 + ' ª Prestação do FCR',
                    // descricao: nFRPrestacao + ' ª Prestação do FCR',
                    valor: (this.isVistaMensalSimulacao) ? fraccao.quota_fr : fraccao.quota_fr / this.cobrancaFR.filter(el => (el.sel)).length,
                    debito: (this.isVistaMensalSimulacao) ? fraccao.quota_fr : fraccao.quota_fr / this.cobrancaFR.filter(el => (el.sel)).length,
                    criado_por: this.userSession.getUserId(),
                    criado_em: this.utils.getFormatedDate(new Date()),
                  }
                  if (fraccao.quota_fr > 0) frObjProcessamento.avisos.push(frObjAviso);
                });

                processamentos.push(frObjProcessamento);
              }

              // ADD PROCESSAMENTO SEGURO
              if (this.segMonths[i].active) {
                nSegPrestacao++;

                segObjProcessamento = {
                  mes: i+1,
                  ano: this.orcamento.periodo,
                  cod_condominio: this.orcamento.cod_condominio,
                  exercicio: this.orcamento.periodo,
                  tipo_proc: 'S',
                  data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                  data_venc: null,
                  // descricao: nSegPrestacao + ' ª Prestação do Seguro',
                  descricao: i+1 + ' ª Prestação do Seguro',
                  pluri_anual: '0',
                  nome: null,
                  valor: totalSeg,
                  avisos: [],
                  despesas: [],
                };

                this.simulacaoList.forEach((fraccao, j) => {
                  let quota_seg = (this.isVistaMensalSimulacao) ? (fraccao.quota_seg * this.cobrancaOrcamento.filter(el => (el.sel)).length) / nPrestacaoSeg : fraccao.quota_seg / nPrestacaoSeg;

                  segObjAviso = {
                    cod_condominio: this.orcamento.cod_condominio,
                    n_aviso: null,
                    mes: i+1,
                    ano: this.orcamento.periodo,
                    dt_emissao: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                    cod_fraccao: fraccao.cod,
                    cod_zona: fraccao.cod_zona,
                    dt_vencimento: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1+7)),
                    cod_pagador: this.fraccoes.find(el => (el.cod === fraccao.cod)).cod_proprietario,
                    tipo_proc: 'S',
                    cod_proc: null,
                    descricao: i+1 + ' ª Prestação do Seguro',
                    // descricao: nSegPrestacao + ' ª Prestação do Seguro',

                    valor: Math.round(quota_seg * 100) / 100,
                    debito: Math.round(quota_seg * 100) / 100,

                    criado_por: this.userSession.getUserId(),
                    criado_em: this.utils.getFormatedDate(new Date()),
                  }
                  if (quota_seg > 0) segObjProcessamento.avisos.push(segObjAviso);
                });

                processamentos.push(segObjProcessamento);
              }
            });

            // ADD PROCESSAMENTO EXTRAORDINÁRIO
            let processamentoExtra = {
              mes: firstMonthIndex,
              ano: this.orcamento.periodo,
              cod_condominio: this.orcamento.cod_condominio,
              exercicio: this.orcamento.periodo,
              tipo_proc: 'E',
              data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), 1, 1)),
              data_venc: null,
              descricao: 'Processamento Extraordinário',
              pluri_anual: '0',
              nome: null,
              valor: 0,
              avisos: [],
              despesas: [],
            };
            processamentos.push(processamentoExtra);
            
            // ADD PROCESSAMENTO PENALIZAÇÔES
            let processamentoPenal = {
              mes: firstMonthIndex,
              ano: this.orcamento.periodo,
              cod_condominio: this.orcamento.cod_condominio,
              exercicio: this.orcamento.periodo,
              tipo_proc: 'P',
              data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), 1, 1)),
              data_venc: null,
              descricao: 'Processamento Penalizações',
              pluri_anual: '0',
              nome: null,
              valor: 0,
              avisos: [],
              despesas: [],
            };
            processamentos.push(processamentoPenal);

            let auxApiObject = this.prepareBodyApiObject();

            auxApiObject.orcFraccoes.forEach(el => {
              el.quota_seg = (this.isVistaMensalSimulacao) ? (this.simulacaoList.find(it => (it.cod === el.cod)).quota_seg * this.cobrancaOrcamento.filter(el => (el.sel)).length) : this.simulacaoList.find(it => (it.cod === el.cod)).quota_seg.quota_seg;
              // el.quota_seg = this.simulacaoList.find(it => (it.cod === el.cod)).quota_seg_anual;
              if (el.quota_seg === null) el.quota_seg = 0;
            });

            let idActSeg = null;

            let now = new Date();
            this.api.addProcessamentos(this.orcamentoId, processamentos, auxApiObject.orcFraccoes, idActSeg, now).subscribe(res => {
              if (res.hasOwnProperty('success') && res.success) {
                this.loading = false;
                this.loadingLancarValores = false;
                this.fetching = false;
                this.orcamento.val_lancado = '1';

                this.toastr.success('Processamentos e avisos gerados com sucesso.', 'Orçamento Lançado', { timeOut: 4000 });

                this.orcamento.data_lancamento = now;
                this.orcamento.user_lancamento_nome = this.userSession.getUserFullName();

                // DESPESAS ALERT
                this.presentAlert('despesas').then(res => {

                  this.appState.setInitState('despesa', {
                    condominioSelected: {
                      cod: this.orcamento.cod_condominio,
                      exercicio: this.orcamento.periodo.toString(),
                      id: this.orcamento.id_condominio,
                      nome: this.orcamento.nome_condominio,
                    },
                  });

                  if (res) this.router.navigate(['lancamentos/despesa', 'criar-agendamento']);
                });
              } else {
                this.loading = false;
                this.loadingLancarValores = false;
                this.fetching = false
                this.utils.apiErrorMsg(res);
              }
              this.submitting = false;
            }, err => {
              this.loading = false;
              this.loadingLancarValores = false;
              this.fetching = false
              this.submitting = false;
              this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            });
          })
          .onDeny(() => { this.submitting = false; });
          
        break;
    }
  }

  setFCR() {
    if (this.repProporcionalFR !== 'P' && this.repProporcionalFR !== 'E' && this.repProporcionalFR !== 'PF') {
      this.toastr.error('Escolha o tipo de repartição pretendido para o fundo comum de reserva.', 'Alerta', { timeOut: 4000 });
      return;
    }

    this.fundoReservaModalRef.approve();
  }

  prepareBodyApiObject() {
    let orcZonasAux = [];
    if (this.orcamentoObj && this.orcamentoObj.hasOwnProperty('zonas')) {
      orcZonasAux = this.orcamentoObj.zonas.map(zona => {
        zona['cod_orcamento'] = this.orcamentoCod;
        zona['reparticao_fr'] = this.orcamentoDataTable.find(el => (el.cod === zona.cod)).fr_reparticao;
        zona['orc_zona_id'] = zona.orc_zona_id;
        return zona;
      })
    }

    let orcFraccoesAux = [];
    if (this.orcamentoObj && this.orcamentoObj.hasOwnProperty('zonas')) {
      let nMesesFR = this.cobrancaFR.filter(el => (el.sel)).length;
      let nMesesORC = this.cobrancaOrcamento.filter(el => (el.sel)).length;

      orcFraccoesAux = this.orcamentoObj.fraccoes.map(fraccao => {
        fraccao['cod_orcamento'] = this.orcamentoCod;
        fraccao['orc_fraccao_id'] = fraccao.orc_fraccao_id;
        fraccao['quota_fr'] = 0;
        fraccao['quota_orc'] = 0;
        let auxFraccao = this.simulacaoList.find(frac => (frac.cod === fraccao.cod));
        if (auxFraccao) {
          fraccao['quota_fr'] = auxFraccao.quota_fr;
          fraccao['quota_orc'] = auxFraccao.quota_orc;

          if (this.isVistaMensalSimulacao) {
            fraccao['quota_fr'] = fraccao['quota_fr'] * nMesesFR;
            fraccao['quota_orc'] = fraccao['quota_orc'] * nMesesORC;
          }
        }

        return fraccao;
      })
    }
    
    let orcFraccoesFrAux = this.orcamentoObj.fraccoes_fr;

    let orcRubricasAux = [];
    this.rubricasList.forEach((rubrica, i) => {
      this.zonas.forEach((zona, j) => {
        let aux = null;
        if (this.orcRubricas && Array.isArray(this.orcRubricas)) {
          aux = this.orcRubricas.find(el => (el.cod_rubrica === rubrica.cod_rubrica && el.cod_zona === rubrica['cod-zona-' + j]));
        }

        orcRubricasAux.push({
          id: (aux) ? aux.id : null,
          cod_orcamento: this.orcamentoCod,
          cod_rubrica: rubrica.cod_rubrica,
          cod_zona: zona.cod,
          reparticao: rubrica.reparticao,
          tipo: rubrica.tipo,
          valor: rubrica['valor-zona-' + j],
        });
      });
    });

    return { orcZonas: orcZonasAux, orcRubricas: orcRubricasAux, orcFraccoes: orcFraccoesAux, orcFraccoesFr: orcFraccoesFrAux };
  }

  fetchingOrc = false;
  async saveOrcamento(targetFlag='') {

    this.submittingForm = true; setTimeout(() => { this.submittingForm = false; }, 2000);

    if (this.selectedTotalFR < 10 || this.orcamentoTotais.orc_valor && this.orcamentoTotais.fr_valor) {
      let frRes = null;

      if (this.orcamentoTotais.orc_valor * 0.1 > this.orcamentoTotais.fr_valor + 0.1) {
        // FR LESS THEN 10%
        frRes = await this.presentAlertFR();
        if (!frRes) return;
      }
    }

    // CHECK IF PERMILAGEM IS 1000 %
    if (this.orcamentoTotais.hasOwnProperty('permilagem') && (this.orcamentoTotais.permilagem < 999.99 || this.orcamentoTotais.permilagem > 1000.009)) {
      let res = await this.presentPermilagemAlert();

      if (this.isCreate) {
        this.tabsObjDef[2].disabled = true;
        this.tabsObjDef[3].disabled = true;
      }

      if (!res) return;
    }

    let data = this.geralForm.getRawValue();

    if (targetFlag !== 'lancar-valores') this.loading = true;

    let auxApiObject = this.prepareBodyApiObject();

    if (this.isCopy) {
      this.orcamentoId = null;

      if (auxApiObject.hasOwnProperty('orcFraccoes')) {
        auxApiObject.orcFraccoes.forEach(el => {
          el.orc_fraccao_id = null;
          el.cod_orcamento = null;
        });
      }

      if (auxApiObject.hasOwnProperty('orcFraccoesFr')) {
        auxApiObject.orcFraccoesFr.forEach(el => {
          el.id = null;
          el.cod = null;
          el.cod_orcamento = null;
        });
      }

      if (auxApiObject.hasOwnProperty('orcRubricas')) {
        auxApiObject.orcRubricas.forEach(el => {
          el.id = null;
          el.cod_orcamento = null;
        });
      }

      if (auxApiObject.hasOwnProperty('orcZonas')) {
        auxApiObject.orcZonas.forEach(el => {
          el.orc_zona_id = null;
          el.cod_orcamento = null;
        });
      }
    } else {}

    let cod = this.getFormCodCondominio();
    let wasCreate = !this.orcamentoId;
    this.api.saveOrcamentoREVIEW(this.orcamentoId, data.cod, cod, data.periodo, data.descricao, data.dt_inicio, data.dt_fim, auxApiObject.orcZonas, auxApiObject.orcRubricas, auxApiObject.orcFraccoes, auxApiObject.orcFraccoesFr, this.rubricasToDelete).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        let nextId = res.data.orcamento[0]['id'];
        let subLevel = res.data.orcamento[0]['cod_condominio'] + ' - ' + res.data.orcamento[0]['nome_condominio'];

        this.rubricasToDelete = [];
        
        if (res.data.rubricas && Array.isArray(res.data.rubricas) && res.data.rubricas.length > 0) {
          this.tabsObjDef[2].disabled = false;
          this.tabsObjDef[3].disabled = false;
        }

        if (targetFlag === '' && this.orcFraccoesSeg.length === 0) {
          this.api.getActSegCond(cod).subscribe(async seg => {
            if (seg.hasOwnProperty('success') && seg.success) {

              this.premioSeg = Number(seg.data.premio);
              this.reparticaoSeg = seg.data.pagamento;
              this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });

              this.premilagemSeg = 0;
              this.orcFraccoesSeg = [];
              this.fraccoes.forEach(el => {
                if (el.paga_seg_colect === '1') this.premilagemSeg = this.premilagemSeg + Number(el.permilagem);

                this.orcFraccoesSeg.push({
                  cod_fraccao: el.cod,
                  paga_seg_colect: el.paga_seg_colect,
                  permilagem: Number(el.permilagem),
                  quota_seg_anual: 0,
                })
              });

              if (this.reparticaoSeg === 'P') {
                this.orcFraccoesSeg.forEach(el => {
                  if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round(((this.premioSeg * el.permilagem) / this.premilagemSeg) * 100) / 100;
                });
              }

              if (this.reparticaoSeg === 'E') {
                let nFraccoes = this.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
                this.orcFraccoesSeg.forEach(el => {
                  if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round((this.premioSeg / nFraccoes) * 100) / 100;
                });
              }
              await this.handleOrcDetailsObjFromApi(res);

              this.loading = false;
              this.fetchingOrc = true;
              this.isCreate = false;
              this.isCopy = false;
              this.isFRSetup = true;
            }
          }, err => {});
        } else {
          this.handleOrcDetailsObjFromApi(res);

          this.loading = false;
          this.fetchingOrc = true;
          this.isCreate = false;
          this.isCopy = false;
          this.isFRSetup = true;
        }

        if (targetFlag === 'lancar-valores') { 
          this.formSubmitted('lancar-valores', 'lancar-valores');
          this.guardarOption = null;
        }

        if (this.orcamentoId) {
          // REGISTO ACTIVIDADES API CALL
          let obj = { link: this.orcamentoId, cod: this.orcamento.nome_condominio };
          let titulo = (targetFlag === 'lancar-valores') ? 'Orçamento Lançado' : 'Orçamento Actualizado';
          let descricao = 'Condomínio: ' + this.orcamento.nome_condominio + ', Exercício: ' + data.periodo;
          this.api.saveRegistoActividade(data.cod, 'ORCAMENTO', data.cod, titulo, descricao, obj).subscribe(res => {}, err => { });
        } else {
          let condNome = '';
          if (res.data.orcamento.length > 0) {
            condNome = res.data.orcamento[0].cod_condominio + ' - ' + res.data.orcamento[0].nome_condominio;
          }

          // REGISTO ACTIVIDADES API CALL
          let obj = { link: res.data.orcamento[0].id, cod: condNome };
          let titulo = 'Orçamento Criado';
          let descricao = 'Condomínio: ' + condNome + ', Exercício: ' + data.periodo;
          this.api.saveRegistoActividade(data.cod, 'ORCAMENTO', data.cod, titulo, descricao, obj).subscribe(res => {}, err => { });
        }

        if (wasCreate) {
          this.updateRedirectedUrlInfo();
        }

        switch (this.guardarOption) {
          case 'GUARDAR_SAIR':
            if (this.redirectUrl) {
              this.router.navigate([this.redirectUrl], { fragment: this.redirectFragment });
              return;
            }
            this.router.navigate(['/orcamentos']);
            return;
          case 'GUARDAR_CRIAR_NOVA_MESMOS_DADOS':
            this.orcamentos.copyOrcamento(nextId).then(id => {
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.navigate(['/orcamentos/orcamento', id]);
            }).catch(err => {});
            return;
          case 'GUARDAR_CRIAR_NOVA_RAIZ':
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/orcamentos/orcamento', 'criar']);
            return;
        }
        // BREADCRUMB SIGNAL
        this.location.replaceState('/orcamentos/orcamento/' + nextId);
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: subLevel });
      } else {
        this.loading = false;
        this.fetchingOrc = true;
        this.utils.apiErrorMsg(res);
      }
      this.saving = false;
    }, err => {
      this.fetchingOrc = true;
      this.loading = false;
      this.saving = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  updateRedirectedUrlInfo() {
    if (this.redirectUrl && this.redirectUrl.indexOf('assembleias/assembleias') != -1 && this.redirectFragment.indexOf('chooseorcamento') != -1) {
      this.navigationService.resetModuleData('ASSEMBLEIAS_DETAILS');
      this.navigationService.setData('ASSEMBLEIAS_DETAILS', {selectingOrcamentoID: this.orcamentoId});
    }
  }
  
  getRubricaRowObj(rubPrevState=null) {
    let data = this.rubricaAddForm.getRawValue();

    let newRubrica = {
      id: (rubPrevState) ? rubPrevState.id : null,
      cod: (rubPrevState) ? rubPrevState.cod : data.cod.cod,
      cod_rubrica: (rubPrevState) ? rubPrevState.cod_rubrica : data.cod.cod,
      nome: (rubPrevState) ? rubPrevState.nome : data.cod.nome,

      reparticao: this.repProporcionalZonas,
      tipo: this.tipoRubrica,
      valor: (this.tipoRubricaDR === 'R') ? -1 * data.valor : data.valor,
      checked: false,
      centered: true,

      despesa: (this.tipoRubricaDR === 'D') ? true : false,
      receita: (this.tipoRubricaDR === 'R') ? true : false,
    };

    let totalPermZona = 0;
    this.zonas.forEach((zona, i) => {
      newRubrica['valor-zona-' + i] = 0;
      newRubrica['cod-zona-' + i] = zona.cod;
      newRubrica['perm-zona-' + i] = Number(zona.permilagem);
      if (zona.checked) totalPermZona += Number(zona.permilagem);
    });

    this.rubricasListCol.filter(el => (el.data !== null)).forEach((el, i) => {
      newRubrica['valor-zona-' + i] = 0;

      if (this.zonas[i].checked) {
        switch (newRubrica.reparticao) {
          case 'P':
            // PROPORCIONAL
            newRubrica['valor-zona-' + i] = Math.round(((newRubrica.valor * el.data.permilagem) / totalPermZona ) * 100) / 100;
            break;
          case 'A':
          case 'E':
            // EQUITATIVO
            newRubrica['valor-zona-' + i] = Math.round((newRubrica.valor / this.zonas.filter(el => el.checked).length) * 100) / 100;
            break;
        }
      }
    });

    return newRubrica;
  }

  computeRubricasTotal() {
    this.rubricasTotais.total = 0;
    this.rubricasListCol.filter(el => (el.data !== null)).forEach((it, i) => {
      this.rubricasTotais['total-zona-' + i] = 0;
      this.rubricasList.forEach(el => {
        this.rubricasTotais['total-zona-' + i] += Number(el['valor-zona-' + i]);
      });
      this.rubricasTotais.total += Number(this.rubricasTotais['total-zona-' + i]);
    });
  }

  computeOrcamentoFraccoesTotal(orcBlocked=false) {
    this.orcamentoFraccoesTotais.permilagem = 0;
    this.orcamentoFraccoesTotais.quota_fr = 0;
    if (!orcBlocked) this.orcamentoFraccoesTotais.quota_orc = 0;
    this.orcamentoFraccoesTotais.total = 0;

    this.orcamentoList.forEach(fraccao => {
      this.orcamentoFraccoesTotais.permilagem += Number(fraccao.permilagem);
      this.orcamentoFraccoesTotais.quota_fr += Number(fraccao.quota_fr);
      if (!orcBlocked) this.orcamentoFraccoesTotais.quota_orc += Number(fraccao.quota_orc);
      this.orcamentoFraccoesTotais.total += Number(fraccao.total);
    });
  }

  rubricaInputChanged(row, i) {
    row.reparticao = 'M';

    row.valor = 0;
    this.zonas.forEach((zona, i) => {
      row.valor += Number(row['valor-zona-' + i]);
    });

    this.rubricaAddForm.patchValue({
      cod: { name: row.nome, value: { cod: row.cod, nome: row.nome } },
      valor: Math.round(Number(row.valor) * 100) / 100,
    });

    this.computeRubricasTotal();
  }

  action(sel, targetForm, index=null, showModal=true) {
    switch (targetForm) {
      case 'rubricas-edit':
        if (this.rubricasModoManual) return;

        this.rubricaSelected = { name: sel.nome, value: { cod: sel.cod, nome: sel.nome } };

        this.repProporcionalZonas = sel.reparticao;
        this.tipoRubrica = sel.tipo;
        this.tipoRubricaDR = (sel.receita) ? 'R' : 'D';
        this.allZonasSelected = true;

        this.rubricaAddForm.patchValue({
          cod: this.rubricaSelected,
          valor: (Number(sel.valor) < 0 && this.tipoRubricaDR === 'R') ? -1 * Math.round(Number(sel.valor) * 100) / 100 : Math.round(Number(sel.valor) * 100) / 100,
        });

        this.zonas.forEach((zona, i) => {

          if (sel['valor-zona-' + i] !== 0) {
            zona.checked = true;
          } else {
            zona.checked = false;
            this.allZonasSelected = false;
          }

        });

        if (showModal) this.add(true);
        break;
      case 'orcamento':
        let nMesesOrcamento = this.cobrancaOrcamento.filter(el => (el.sel)).length;

        this.orcamentoList = [];
        let seenRubricas = '';
        this.orcamentoObj.fraccoes.filter(el => (el.cod_zona === sel.cod)).forEach(fraccao => {

          let fraccaoFR = this.orcamentoObj.fraccoes_fr.find(el => (el.cod_fraccao === fraccao.cod_fraccao));

          if (seenRubricas.indexOf(fraccao.cod) === -1) {
            // ADD RUBRICA TO SEEN STRING
            seenRubricas += fraccao.cod + '/';

            this.orcamentoList.push({
              id: fraccao.id,
              fraccao_id: fraccao.fraccao_id,
              cod: fraccao.cod,
              cod_zona: sel.cod,
              nome: fraccao.nome,
              permilagem: Number(fraccao.permilagem),

              quota_fr: (fraccaoFR) ? Number(fraccaoFR.valor) : 0,
              quota_orc: Number(fraccao.valor),
            });

          } else {
            let index = this.orcamentoList.findIndex(it => (it.fraccao_id === fraccao.fraccao_id));
            this.orcamentoList[index].quota_orc += Number(fraccao.valor);
            this.orcamentoList[index].total += Number(fraccao.valor);
          }
        });

        this.orcamentoList.forEach(el => {
          el.quota_orc = Math.round(el.quota_orc * 100) / 100;
          el.quota_fr = Math.round(el.quota_fr * 100) / 100;

          el.total = Math.round( (el.quota_orc + el.quota_fr) * 100) / 100;
        });

        if (this.isVistaMensalOrcamentoZona) this.vistaMensalToggle('orcamento-zona');

        this.computeOrcamentoFraccoesTotal();

        this.selectedZona = (sel.hasOwnProperty('nome')) ? sel.nome : null;
        this.editModalConfig.size = 'normal';

        // KEEP ORIGINAL STATE FOR RESTORE
        this.orcamentoListOrig = JSON.parse(JSON.stringify(this.orcamentoList));

        this.editModalRef = this.modalService.open(this.editModalConfig)
          .onApprove(() => {
            if (this.orcamento.val_lancado !== '1') {
              let nMesesFR = this.cobrancaFR.filter(el => (el.sel)).length;
              let nMesesOrc = this.cobrancaOrcamento.filter(el => (el.sel)).length;
  
              let afectedZona = this.orcamentoObj.zonas.find(zona => (zona.cod === sel.cod));
  
              this.orcamentoList.forEach((fraccao, i) => {
                // UPDATE ZONA
                if (i === 0) afectedZona.valor_fr = 0;
  
                if (afectedZona) {
                  if (this.isVistaMensalOrcamentoZona) {
                    afectedZona.valor_fr += fraccao.quota_fr * nMesesFR;
                    afectedZona.pagamento_fr = 'M';
                    afectedZona.reparticao_fr = 'M';
                  } else {
                    afectedZona.valor_fr += fraccao.quota_fr;
                    afectedZona.pagamento_fr = 'M';
                    afectedZona.reparticao_fr = 'M';
                  }
                }
  
                // UPDATE FRACCAO
                let afectedFraccao = this.orcamentoObj.fraccoes.filter(el => (el.cod === fraccao.cod));
                afectedFraccao.forEach(el => {
                  if (this.isVistaMensalOrcamentoZona) {
                    el.quota_fr = fraccao.quota_fr * nMesesFR;
                    el.quota_orc = fraccao.quota_orc * nMesesOrc;
  
                    if (this.tipoAcertoSel === '0') el.valor = el.quota_orc / afectedFraccao.length;
                  } else {
                    el.quota_fr = fraccao.quota_fr;
                    el.quota_orc = fraccao.quota_orc;
  
                    if (this.tipoAcertoSel === '0') el.valor = el.quota_orc / afectedFraccao.length;
                  }
                });
  
              });
  
              let quota_fr = 0;
              this.orcamentoObj.fraccoes.filter(el => (el.cod_zona === afectedZona.cod)).forEach((fraccao, i) => {
                quota_fr = fraccao.quota_fr;
  
                let fraccaoFR = this.orcamentoObj.fraccoes_fr.find(el => (el.cod_fraccao === fraccao.cod));
                if (fraccaoFR) fraccaoFR.valor = Math.round(quota_fr * 100) / 100;
              });
  
              this.getOrcamentoDatatable(this.orcamentoObj.zonas, this.orcamentoObj.fraccoes, this.orcamentoObj.fraccoes_fr);
            }

            this.editModalConfig.size = 'small';
          })
          .onDeny(() => { 
            this.editModalConfig.size = 'small';
          });
        break;
    }
  }

  submitting = false;
  lancarValores() {
    if (this.submitting) return;

    this.submitting = true;
    this.formSubmitted('lancar-valores');
  }

  computeOrcamentoTotal() {
    this.orcamentoTotais.permilagem = 0;
    this.orcamentoTotais.orc_valor = 0;
    this.orcamentoTotais.fr_valor = 0;
    this.orcamentoTotais.valor = 0;

    this.orcamentoDataTable.forEach(el => {
      this.orcamentoTotais.permilagem += Number(el.perm);
      this.orcamentoTotais.orc_valor += Number(el.orc_valor);
      this.orcamentoTotais.fr_valor += Number(el.fr_valor);
      this.orcamentoTotais.valor += Number(el.valor);
    });
    let FCR = Math.round((((this.orcamentoTotais.valor / this.orcamentoTotais.orc_valor) - 1) * 100) * 100) / 100;

    if (FCR && !this.tabsObjDef[3].disabled) this.selectedTotalFR = FCR;
  }



  tabSelected(tab) {
    if (tab.disabled) {
      this.submittingForm = true;
      setTimeout(() => { this.submittingForm = false; }, 2000);
    } else {

      // if (tab.key === 'orcamento') {
      //   // this.vistaMensalToggle('orcamento');
      //   // setTimeout(() => { this.vistaMensalToggle('orcamento'); }, 100);
      // }

    }
  }

  presentAlert(target) {
    switch (target) {
      case 'delete':
        return new Promise((resolve, reject) => {
          this.alertModalRef = this.modalService
            .open(this.deleteAlertConfig)
            .onApprove(() => resolve(true))
            .onDeny(() => resolve(false));
        });
        break;
      case 'despesas':
        return new Promise((resolve, reject) => {
          this.despesasModalRef = this.modalService
            .open(this.despesasAlertConfig)
            .onApprove(() => { this.loadingModal = false; resolve(true); })
            .onDeny(() => { this.loadingModal = false; resolve(false); });
        });
        break; 
    }
  }

  async presentAlertFR() {
    return new Promise((resolve, reject) => {
      this.alertFRModalRef = this.modalService
        .open(this.deleteAlertFRConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }

  async presentPermilagemAlert() {
    return new Promise((resolve, reject) => {
      this.permilagemModalRef = this.modalService
        .open(this.permilagemAlertConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }

  calcFR = null;
  despORC = null;
  quotaCalcFR = null;
  absFCRValueDisabled = false;
  openFRCalculator() {

    let nMesesORC = this.cobrancaOrcamento.filter(el => (el.sel)).length;
    let fracAux = this.fraccoes.find(el => (el.cod === 'A'));

    let despesaTotal = 0;
    Object.keys(this.rubricasList[0]).forEach((key, i) => {
      if (key.indexOf('cod-zona-') !== -1 && this.rubricasList[0][key] === fracAux.zona_cod) {
        despesaTotal = Number(this.rubricasTotais['total-zona-' + key.match(/\d+/)[0]]);
      }
    });

    // GET TOTAL DESPESAS ORCAMENTO DA 1º FRACCAO - FRACCAO A
    let perm = Number(fracAux.permilagem);
    let permTotal = this.zonas.find(el => (el.cod === fracAux.zona_cod)).permilagem;
    this.despORC = Number(((despesaTotal * (perm / permTotal)) / nMesesORC) * 100) / 100;

    this.calculadoraModalRef = this.modalService
      .open(this.calculadoraAlertConfig)
      .onApprove(() => {
        this.absFCRValueDisabled = true;
        this.isPercentageFR = true;

        this.selectedTotalFR = this.calcFR;
        // this.repProporcionalFR = 'P';  // TODO: REVIEW IF THIS IS CORRECT
        this.repProporcionalFR = 'PF';  // TODO: REVIEW IF THIS IS CORRECT

        this.calcFR = null;
        this.despORC = null;
        this.quotaCalcFR = null;
      })
      .onDeny(() => {
        this.calcFR = null;
        this.despORC = null;
        this.quotaCalcFR = null;
      });
  }

  useComputedFCR() {
    // if (this.rubricasList.find(el => (el.reparticao !== 'P'))) {
    //   this.toastr.warning('Funcionalidade apenas disponível para orçamentos com distribuição de despesas totalmente por permilagem.', 'Aviso', { timeOut: 4000 });
    //   return;
    // }
    this.calculadoraModalRef.approve();
  }

  quotaFRCalcChange(ev) {
    let quotaAux = Number(ev);
    this.calcFR = Math.round((((quotaAux / this.despORC) - 1) * 100) * 100) / 100;
  }

  orcamentoChanged(sel, ev, target, change=false) {
    // CONVERT TO NUMBER TYPE
    if (sel.permilagem) sel.permilagem = Number(sel.permilagem);
    if (sel.quota_fr) sel.quota_fr = Number(sel.quota_fr);
    if (sel.quota_orc) sel.quota_orc = Number(sel.quota_orc);
    if (sel.total) sel.total = Number(sel.total);
    if (ev) ev = Number(ev);

    if (change) {

    } else {
      let diff = 0;
  
      switch (target) {
        case 'orcamento':
          if (this.tipoAcertoSel === '0') {  // MANUAL
            switch (this.tipoReparticaoZona) {
              case '0':  // EQUITATIVO
                if (isNaN(ev)) return;

                diff = ev - sel.quota_orc;
                sel.quota_orc = Number(ev);
                sel.total = sel.quota_orc + sel.quota_fr;

                this.orcamentoList.filter(el => (el.cod !== sel.cod)).forEach(fraccao => {
                  fraccao.quota_orc = (fraccao.quota_orc - (diff / (this.orcamentoList.length - 1)));
                  fraccao.total = (fraccao.quota_orc + fraccao.quota_fr);
                });
                break;
              case '1':  // PROPORCIONAL
                if (isNaN(ev)) return;

                diff = ev - sel.quota_orc;
                sel.quota_orc = Number(ev);
                sel.total = sel.quota_orc + sel.quota_fr;

                let permTotal = 0;
                this.orcamentoList.filter(el => (el.cod !== sel.cod)).forEach(fraccao => { permTotal += fraccao.permilagem; });

                this.orcamentoList.filter(el => (el.cod !== sel.cod)).forEach(fraccao => {
                  fraccao.quota_orc = (fraccao.quota_orc - (diff * (fraccao.permilagem / permTotal)));
                  fraccao.total = (fraccao.quota_orc + fraccao.quota_fr);
                });
                break;
              case '2':  // MANUAL
                if (isNaN(ev)) return;

                sel.total = ev + sel.quota_fr;
                sel.quota_orc = ev;
                break;
            }
          }
          break;
        case 'fundo-reserva':
          if (this.tipoAcertoSel === '0') {  // MANUAL
            switch (this.tipoReparticaoZona) {
              case '0':  // EQUITATIVO
                if (isNaN(ev)) return;

                diff = ev - sel.quota_fr;
                sel.quota_fr = ev;
                sel.total = sel.quota_orc + sel.quota_fr;

                this.orcamentoList.filter(el => (el.cod !== sel.cod)).forEach(fraccao => {
                  fraccao.quota_fr = (fraccao.quota_fr - (diff / (this.orcamentoList.length - 1)));
                  fraccao.total = (fraccao.quota_orc + fraccao.quota_fr);
                });
                break;
              case '1':  // PROPORCIONAL
                if (isNaN(ev)) return;

                diff = ev - sel.quota_fr;
                sel.quota_fr = Number(ev);
                sel.total = sel.quota_orc + sel.quota_fr;

                let permTotal = 0;
                this.orcamentoList.filter(el => (el.cod !== sel.cod)).forEach(fraccao => { permTotal += fraccao.permilagem; });

                this.orcamentoList.filter(el => (el.cod !== sel.cod)).forEach(fraccao => {
                  fraccao.quota_orc = (fraccao.quota_fr - (diff * (fraccao.permilagem / permTotal)));
                  fraccao.total = (fraccao.quota_orc + fraccao.quota_fr);
                });
                break;
              case '2':  // MANUAL
                if (isNaN(ev)) return;

                sel.total = sel.quota_orc + ev;
                sel.quota_fr = ev;
                break;
            }
          }

          if (this.tipoAcertoSel === '1') {  // FUNDO RESERVA
            if (isNaN(ev)) return;

            diff = ev - sel.quota_fr;
            sel.quota_fr = ev;
          }
          break;
        case 'quota-total':
          if (isNaN(ev)) return;

          diff = ev - sel.total;
          sel.total = ev;
          if (this.tipoAcertoSel === '1') {  // FUNDO RESERVA
            sel.quota_fr = sel.quota_fr + diff;
            sel.total = ev;
          }
          break;
      }
  
      sel['edited'] = true;
    }

    if (this.tipoAcertoSel === '0' && this.tipoReparticaoZona === '2') {  // MODO MANUAL MANUAL
      // CHECK TOTALS
      let total = 0;
      this.orcamentoList.forEach(entry => { total += entry.quota_orc; });

      this.orcTotalDiff = total - this.orcamentoFraccoesTotais.quota_orc;
      this.orcTotalZonaOK = (total < this.orcamentoFraccoesTotais.quota_orc + 0.01 && total > this.orcamentoFraccoesTotais.quota_orc - 0.01);

      this.computeOrcamentoFraccoesTotal(true);
    } else {
      this.computeOrcamentoFraccoesTotal();
      this.orcTotalZonaOK = true;
      this.orcTotalDiff = 0;
    }

  }

  rubricasToDelete = [];
  del(toDelete) {
    this.rubricasList = this.rubricasList.filter(rubrica => {
      return (toDelete.findIndex(el => (el.cod === rubrica.cod)) === -1);
    });

    this.rubricasToDelete.push(...toDelete.filter(el => !!el.id));

    this.computeRubricasTotal();
    this.genOrcamentoDatatable();
  }

  tipoRepZonaChanged() {
    switch (this.tipoReparticaoZona) {
      case '0':  // EQUITATIVO
        this.setZonaEquitativa();
        break;
      case '1':  // PROPORCIONAL
        this.setZonaProporcional();
        break;
      case '2':  // MANUAL
        break;
    }
    this.computeOrcamentoFraccoesTotal();
    this.orcTotalZonaOK = true;
    this.orcTotalDiff = 0;
  }

  setZonaEquitativa(orc=true, fr=true) {
    this.orcamentoList.forEach(fraccao => {
      if (orc) fraccao.quota_orc = Math.round((this.orcamentoFraccoesTotais.quota_orc / this.orcamentoList.length) * 100) / 100;
      if (fr) fraccao.quota_fr = Math.round((this.orcamentoFraccoesTotais.quota_fr / this.orcamentoList.length) * 100) / 100;
      fraccao.total = Math.round((fraccao.quota_orc + fraccao.quota_fr) * 100) / 100;
    });
  }

  setZonaProporcional(orc=true, fr=true) {
    let permTotal = 0;
    this.orcamentoList.forEach(fraccao => { permTotal += fraccao.permilagem; });

    this.orcamentoList.forEach(fraccao => {
      if (orc) fraccao.quota_orc = Math.round((this.orcamentoFraccoesTotais.quota_orc * fraccao.permilagem / permTotal) * 100) / 100;
      if (fr) fraccao.quota_fr = Math.round((this.orcamentoFraccoesTotais.quota_fr * fraccao.permilagem / permTotal) * 100) / 100;
      fraccao.total = Math.round((fraccao.quota_orc + fraccao.quota_fr) * 100) / 100;
    });
  }

  prevExercicio = null;
  checkPreviousOrcamento(option:{name:string,value:{cod}}, askCopy=true) {
    if (!option) return;

    this.api.checkPreviousOrcamentoByCondominio(option.value.cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (res.data == null || res.data.id === null) {
          this.api.getActSegCond(option.value.cod).subscribe(async seg => {
            if (seg.hasOwnProperty('success') && seg.success) {
              this.premioSeg = Number(seg.data.premio);
              this.reparticaoSeg = seg.data.pagamento;
              this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });

              this.premilagemSeg = 0;
              this.orcFraccoesSeg = [];
              this.fraccoes.forEach(el => {
                if (el.paga_seg_colect === '1') this.premilagemSeg = this.premilagemSeg + Number(el.permilagem);

                this.orcFraccoesSeg.push({
                  cod_fraccao: el.cod,
                  paga_seg_colect: el.paga_seg_colect,
                  permilagem: Number(el.permilagem),
                  quota_seg_anual: 0,
                })
              });

              if (this.reparticaoSeg === 'P') {
                this.orcFraccoesSeg.forEach(el => {
                  if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round(((this.premioSeg * el.permilagem) / this.premilagemSeg) * 100) / 100;
                });
              }

              if (this.reparticaoSeg === 'E') {
                let nFraccoes = this.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
                this.orcFraccoesSeg.forEach(el => {
                  if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round((this.premioSeg / nFraccoes) * 100) / 100;
                });
              }
            }
          }, err => {});

          return;
        }

        this.prevExercicio = res.data.exercicio;

        if (!askCopy) return;

        this.copyModalRef = this.modalService
          .open(this.copyAlertConfig)
          .onApprove(() => {
            this.isCreate = false;
            this.isCopy = true;

            this.orcamentoId = res.data.id;
            this.init();
          })
          .onDeny(() => {
            this.api.getActSegCond(option.value.cod).subscribe(async seg => {
              if (seg.hasOwnProperty('success') && seg.success) {
                this.premioSeg = Number(seg.data.premio);
                this.reparticaoSeg = seg.data.pagamento;
                this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });

                this.premilagemSeg = 0;
                this.orcFraccoesSeg = [];
                this.fraccoes.forEach(el => {
                  if (el.paga_seg_colect === '1') this.premilagemSeg = this.premilagemSeg + Number(el.permilagem);

                  this.orcFraccoesSeg.push({
                    cod_fraccao: el.cod,
                    paga_seg_colect: el.paga_seg_colect,
                    permilagem: Number(el.permilagem),
                    quota_seg_anual: 0,
                  })
                });

                if (this.reparticaoSeg === 'P') {
                  this.orcFraccoesSeg.forEach(el => {
                    if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round(((this.premioSeg * el.permilagem) / this.premilagemSeg) * 100) / 100;
                  });
                }

                if (this.reparticaoSeg === 'E') {
                  let nFraccoes = this.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
                  this.orcFraccoesSeg.forEach(el => {
                    if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round((this.premioSeg / nFraccoes) * 100) / 100;
                  });
                }
              }
            }, err => {});
          });
      } else {
        // this.utils.apiErrorMsg(res);
      }
    });

    // GET ALL ACTIVE ORCAMENTOS
    this.api.getOrcExerciciosByCondominio(option.value.cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.activeOrcExerc = res.data;
      }
    }, err => {
      // this.utils.apiErrorMsg(res);
    });

  }

  checkOrcamentoTotal() {
    if (!this.orcTotalZonaOK) {
      // Present warning toast
      this.submittingModalForm = true;
      this.toastr.error('O total da despesa orçamento não corresponde ao somatório das despesas das fracções', 'Ajuste Necessário.', { timeOut: 4000 })
        .onHidden.subscribe(() => { this.submittingModalForm = false; });
    } else {
      this.editModalRef.approve();
    }
  }

  // REPORT DOCUMENTS VARIABLES
  docToPrint = { listagemQuotas: true, rubricasOrc: true }
  pdfReport = {
    title: null,
    reportType: null,
    descricao: null,
    exercicio:null,
    startDate: null,
    endDate: null,
    now: new Date(),
  }
  targetReport = null;
  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  simulacaoListToPrint = [];
  rubricasOrcListToPrint = [];
  rubricasOrcTotais = {
    valor: 0,
  };
  zonasOrcListToPrint = [];
  zonasOrcTotais = {
    orc: 0,
    fr: 0,
    seg: 0,
    quota: 0
  };


  @ViewChild('orcamentoPDF', { static: false }) orcamentoPDF:OrcamentoPdfComponent;

  printOrcamentoDocuments() {

    this.documentosModalRef = this.modalService
      .open(this.documentosAlertConfig)
      .onApprove(() => {
        if (!this.docToPrint.listagemQuotas && !this.docToPrint.rubricasOrc) return;

        this.pdfReport.title = this.orcamento.cod_condominio + ' - ' + this.orcamento.nome_condominio;
        this.pdfReport.startDate = this.geralForm.get('dt_inicio').value;
        this.pdfReport.endDate = this.geralForm.get('dt_fim').value;
        this.pdfReport.exercicio = this.geralForm.get('periodo').value;
        this.pdfReport.descricao = this.geralForm.get('descricao').value;

        if (this.docToPrint.listagemQuotas) {
          this.targetReport = 'listagem-quotas';
          this.generateQuotasList();
        }

        if (this.docToPrint.rubricasOrc) {
          this.targetReport = 'rubricas-orcamento';
          this.generateRubricasOrcList();
        }

        if (this.docToPrint.listagemQuotas && this.docToPrint.rubricasOrc) {
          this.targetReport = 'all';
        }

        this.cdRef.detectChanges();


        let reportData:OrcamentoPDF = {
          orcamento: {
            id: null,
            cod: null,
            cod_condominio: null,
            id_act_seg: null,
            descricao: null,
            periodo: null,
            vap_prop: null,
            val_equip: null,
            val_fr_prop: null,
            val_fr_equit: null,
            dt_inicio: null,
            dt_fim: null,
            val_lancado: null,
            meses_orc: null,
            meses_fr: null,
            data_lancamento: null,
            user_lancamento: null,
            active: null,
            nome_condominio:null,
          },
          report: this.pdfReport,
          targetReport:this.targetReport,
          rubricasOrcListToPrint:this.rubricasOrcListToPrint,
          rubricasOrcTotais: this.rubricasOrcTotais,
          zonasOrcListToPrint: this.zonasOrcListToPrint,
          zonasOrcTotais: this.zonasOrcTotais,
          simulacaoListToPrint: this.simulacaoListToPrint,
          simulacaoTotaisPDF: this.simulacaoTotaisPDF,
          cobrancaFR: this.cobrancaFR,
          cobrancaOrcamento: this.cobrancaOrcamento,
          zonas: this.zonas,
          orcFraccoesSeg: this.orcFraccoesSeg,
          fraccoes: this.fraccoes,
          fraccoesListCol: this.fraccoesListCol,
          orcFraccoesFR: this.orcFraccoesFR,
          rubricasList: this.rubricasList,
          orcFraccoes: this.orcFraccoes,
          orcRubricas: this.orcRubricas,
          rubricasListCol: this.rubricasListCol,
          rubricasTotais: this.rubricasTotais,
          orcamentoDataTable: this.orcamentoDataTable,
          orcamentoTotais:this.orcamentoTotais,
          selectedTotalFR:this.selectedTotalFR,
          simulacaoList: this.simulacaoList,
          simulacaoTotais: this.simulacaoTotais
        }

        this.orcamentoPDF.generatePDF(reportData, this.targetReport);
        this.docToPrint = { listagemQuotas: true, rubricasOrc: true };
      })
      .onDeny(() => { this.docToPrint = { listagemQuotas: true, rubricasOrc: true }; });
  }

  exportPDF() {
    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';
    
    let filename = this.pdfReport.title.replace(/ /g, '_') + '_' + formatDate(this.pdfReport.startDate, this.format, this.locale) + '_' + formatDate(this.pdfReport.endDate, this.format, this.locale);
    filename = filename.replace(/,/g, '');

    this.pdfController.saveAs(filename + '_Orçamento_' + this.orcamento.periodo + '.pdf');
  }

  simulacaoTotaisPDF = {
    permilagem: 0,
    orcamento: 0,
    fundoReserva: 0,
    seguro: 0,
    total: 0
  }
  generateQuotasList() {
    let nMesesFR = this.cobrancaFR.filter(el => (el.sel)).length;
    let nMesesORC = this.cobrancaOrcamento.filter(el => (el.sel)).length;

    this.simulacaoListToPrint = [];

    let prevZona = null;
    let valor_total_zona_perm = 0;
    let valor_total_zona_orc = 0; 
    let valor_total_zona_fr = 0; 
    let valor_total_zona_seg = 0; 
    let valor_total_zona_total = 0;

    this.simulacaoTotaisPDF = {
      permilagem: 0,
      orcamento: 0,
      fundoReserva: 0,
      seguro: 0,
      total: 0
    }

    let simulacaoList = JSON.parse(JSON.stringify(this.simulacaoList));
    simulacaoList.sort((a, b) => {
      if (a.cod_zona < b.cod_zona) {
        return -1;
      } else {
        return 1;
      }
    });

    simulacaoList.forEach((el, i) => {
      el['separator'] = false;
      el['subTotal'] = false;

      // TABLE ROW TOTAL AND SEPARATOR
      if (prevZona !== el['cod_zona']) {
        if (i > 0) {
          this.simulacaoListToPrint.push({ label: 'Subtotal', perm: valor_total_zona_perm, orc: valor_total_zona_orc, fr: valor_total_zona_fr, seg: valor_total_zona_seg, quota: valor_total_zona_total, separator: false, subTotal: true });
        }
        this.simulacaoListToPrint.push({ label: el.zona_nome, separator: true, subTotal: false });
        valor_total_zona_perm = 0;
        valor_total_zona_orc = 0; 
        valor_total_zona_fr = 0; 
        valor_total_zona_seg = 0; 
        valor_total_zona_total = 0;
      }
      prevZona = el['cod_zona'];
      valor_total_zona_perm += Number(el.permilagem);
      valor_total_zona_orc += Number(el.quota_orc);
      valor_total_zona_fr += Number(el.quota_fr);
      valor_total_zona_seg += Number(el.quota_seg);
      valor_total_zona_total += Number(el.total);

      this.simulacaoListToPrint.push(el);
    });

    if (this.simulacaoListToPrint.length > 0) {
      this.simulacaoListToPrint.push({ label: 'Subtotal', perm: valor_total_zona_perm, orc: valor_total_zona_orc, fr: valor_total_zona_fr, seg: valor_total_zona_seg, quota: valor_total_zona_total, separator: false, subTotal: true });

      this.simulacaoListToPrint.forEach(el => {
        if (el.hasOwnProperty('label') && !el.separator) {
          el.orc = (this.isVistaMensalSimulacao) ? el.orc : el.orc / nMesesORC; 
          el.fr = (this.isVistaMensalSimulacao) ? el.fr : el.fr / nMesesORC; 
          el.quota = (this.isVistaMensalSimulacao) ? el.quota : el.quota / nMesesORC;

          this.simulacaoTotaisPDF.permilagem += el.perm;
          this.simulacaoTotaisPDF.orcamento += el.orc;
          this.simulacaoTotaisPDF.fundoReserva += el.fr;
          this.simulacaoTotaisPDF.seguro += el.seg;
          this.simulacaoTotaisPDF.total += el.quota;
        } else {
          el.quota_orc = (this.isVistaMensalSimulacao) ? el.quota_orc : el.quota_orc / nMesesORC; 
          el.quota_fr = (this.isVistaMensalSimulacao) ? el.quota_fr : el.quota_fr / nMesesORC; 
          el.total = (this.isVistaMensalSimulacao) ? el.total : el.total / nMesesORC;
        }
      });

    }
  }

  generateRubricasOrcList() {
    let nMesesFR = this.cobrancaFR.filter(el => (el.sel)).length;
    let nMesesORC = this.cobrancaOrcamento.filter(el => (el.sel)).length;

    // RUBRICAS DO ORCAMENTO
    this.rubricasOrcListToPrint = [];
    let auxRubrica = {
      zona_nome: null,
      rubrica_nome: null,
      valor: null,
    };
    this.rubricasOrcTotais.valor = 0;

    this.zonas.forEach((zona, i) => {
      this.rubricasList.filter(el => (el['cod-zona-' + i] === zona.cod)).forEach(rubrica => {
        auxRubrica = {
          zona_nome: zona.nome,
          rubrica_nome: rubrica['nome'],
          valor: rubrica['valor-zona-' + i],
        };
        if (Number(auxRubrica.valor !== 0)) this.rubricasOrcListToPrint.push(auxRubrica);
      });
    });

    this.rubricasOrcListToPrint.forEach(el => {
      this.rubricasOrcTotais.valor += el.valor;
    });

    let prevZona = null;
    let valor_total_zona = 0;

    let auxArray = [];
    this.rubricasOrcListToPrint.forEach((el, i) => {
      el['separator'] = false;
      el['subTotal'] = false;

      // TABLE ROW TOTAL AND SEPARATOR
      if (prevZona !== el['zona_nome']) {
        if (i > 0) {
          auxArray.push({ label: 'Subtotal', valor: valor_total_zona, separator: false, subTotal: true });
        }
        auxArray.push({ label: el.zona_nome, separator: true, subTotal: false });
        valor_total_zona = 0;
      }
      prevZona = el['zona_nome'];
      valor_total_zona += Number(el.valor);

      auxArray.push(el);
    });
    if (auxArray.length > 0) {
      auxArray.push({ label: 'Subtotal', valor: valor_total_zona, separator: false, subTotal: true });
    }
    this.rubricasOrcListToPrint = auxArray;

    // RESUMO ORCAMENTO - ZONAS
    this.zonasOrcListToPrint = [];

    prevZona = null;
    let prevZonaNome = null;
    let valor_total_zona_orc = 0; 
    let valor_total_zona_fr = 0; 
    let valor_total_zona_seg = 0; 
    let valor_total_zona_total = 0;

    let aux = {};

    this.zonasOrcTotais = {
      orc: 0,
      fr: 0,
      seg: 0,
      quota: 0
    };

    this.orcamentoDataTable.forEach(el => {
      valor_total_zona_orc = el.orc_valor; 
      valor_total_zona_fr = el.fr_valor;
      
      valor_total_zona_seg = 0;

      this.simulacaoList.filter(it => it.cod_zona === el.cod).forEach(el => {
        valor_total_zona_seg += Number(el.quota_seg_anual);
      });

      valor_total_zona_total = el.valor + valor_total_zona_seg;

      aux = {
        zona_nome: el.nome,
        orc: (this.isVistaMensalOrcamento) ? valor_total_zona_orc * nMesesORC : valor_total_zona_orc,
        fr: (this.isVistaMensalOrcamento) ? valor_total_zona_fr * nMesesORC : valor_total_zona_fr,
        // seg: (this.isVistaMensalOrcamento) ? valor_total_zona_seg * nMesesORC : valor_total_zona_seg,
        seg: valor_total_zona_seg,
        quota: (this.isVistaMensalOrcamento) ? valor_total_zona_total * nMesesORC : valor_total_zona_total,
      }

      this.zonasOrcListToPrint.push(aux);

      this.zonasOrcTotais.orc += Number(valor_total_zona_orc); 
      this.zonasOrcTotais.fr += Number(valor_total_zona_fr); 
      this.zonasOrcTotais.seg += Number(valor_total_zona_seg); 
      this.zonasOrcTotais.quota += Number(valor_total_zona_total);
    });

    if (this.zonasOrcListToPrint.length > 0) {
      this.zonasOrcTotais.orc = (this.isVistaMensalOrcamento) ? this.zonasOrcTotais.orc * nMesesORC : this.zonasOrcTotais.orc; 
      this.zonasOrcTotais.fr = (this.isVistaMensalOrcamento) ? this.zonasOrcTotais.fr * nMesesORC : this.zonasOrcTotais.fr; 
      this.zonasOrcTotais.seg = (this.isVistaMensalOrcamento) ? this.zonasOrcTotais.seg * nMesesORC : this.zonasOrcTotais.seg; 
      this.zonasOrcTotais.quota = (this.isVistaMensalOrcamento) ? this.zonasOrcTotais.quota * nMesesORC : this.zonasOrcTotais.quota;
    }
    
  }

  updateDefaultDescricao() {
    if ((!this.isCreate && !this.isCopy) || this.geralForm.get('descricao').touched) return;

    let cod = this.getFormCodCondominio();
    let exercicio = this.geralForm.get('periodo').value;
 
    if (!cod || !exercicio) return;

    this.api.getOrcamentoNextDescricao(cod, exercicio).subscribe(res => {
      if (res.success && !this.geralForm.get('descricao').touched) {
        this.geralForm.patchValue({
          descricao: res.data,
        });
      }
    }, err => {

    });
  }


  setYear() {
    let exercicio = this.geralForm.get('periodo').value;
    if (exercicio && Number(exercicio)) {
      exercicio = Number(exercicio);
      let dt_inicio = this.geralForm.get('dt_inicio').value;
      let dt_fim = this.geralForm.get('dt_fim').value;

      this.geralForm.patchValue({
        dt_inicio: new Date(exercicio, dt_inicio.getMonth(), dt_inicio.getDate()),
        dt_fim: new Date(exercicio, dt_fim.getMonth(), dt_fim.getDate()),
      });

      this.updateDefaultDescricao();

      
    }
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.geralForm.get('cod_condominio').value); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
                } else {
                  return resolve([]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  rubricaTimer = null;
  rubricasLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.rubricaSelected); });
    }

    clearTimeout(this.rubricaTimer);
    return new Promise(resolve => {
      if (query) {
        this.rubricaTimer = setTimeout(() => {
          // PROPRIETARIOS/INGUILINOS LIST
          this.api.getAllRubricas(query).subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: { cod: el.cod, nome: el.nome, valFixo: (el.val_fixo === '1') ? true : false, despesa: (el.despesa === '1') ? true : false, receita: (el.receita === '1') ? true : false } }; }));
            } else {
              return resolve([]);
            }
          });
        }, 400);
      } else {
        this.api.getAllRubricas('NULL').subscribe(res => {
          if (res.success) {
            return resolve(res.data.map(el => { return { name: el.nome, value: { cod: el.cod, nome: el.nome, valFixo: (el.val_fixo === '1') ? true : false, despesa: (el.despesa === '1') ? true : false, receita: (el.receita === '1') ? true : false } }; }));
          } else {
            return resolve([]);
          }
        });
      }
    });
  };


  //Guardar simulação

  @ViewChild('guardarForm', { static: false }) guardarForm;
  @ViewChild('guardarAlertRef', { static: false }) guardarAlertRef;
  guardarModalRef = null;
  guardarAlertConfig: any = null;

  guardarOption:guardarAvailableActions = null
  guardarModalSubmitFeedback = false;



  guardarOptions:Array<guardarOptionEntry> = [
    { checked: false, label: 'Guardar', action: 'GUARDAR', default:true },
    { checked: false, label: 'Guardar e sair', action: 'GUARDAR_SAIR', default:false },
    { checked: false, label: 'Guardar e criar nova simulação', action: 'GUARDAR_CRIAR_NOVA_RAIZ', default:false },
    { checked: false, label: 'Guardar e criar simulação com base na atual', action: 'GUARDAR_CRIAR_NOVA_MESMOS_DADOS', default:false },
  ];

  async guardarSimulacaoBtn() {
    let optionChoosen = await this.presentGuardarModal();
    if (!optionChoosen) return;
    this.formSubmitted('orcamento-geral');
  }


  guardarDescricao:string = null;
  presentGuardarModal():Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.guardarDescricao = this.geralForm.get('descricao').value;

      this.guardarOptions.forEach(opt => opt.checked = opt.default);

      this.guardarModalRef = this.modalService
        .open(this.guardarAlertConfig)
        .onApprove(() => {
          this.geralForm.patchValue({
            descricao: this.guardarDescricao
          });
          resolve(true)
        })
        .onDeny(() => {
          resolve(false)
        });
    });
  }

  selectGuardarOption(choosenOption:guardarOptionEntry) {
    this.guardarOptions.forEach(el => el.checked = el.action === choosenOption.action);
  }

  closeGuardarModal(option:'APPROVE'|'DENY') {
    if (option === 'DENY') {
      this.guardarModalRef.deny();
      return;
    }

    if (!this.isValidInputGuardarModal()) {
      this.guardarModalSubmitFeedback = true;
      setTimeout(() => {
        this.guardarModalSubmitFeedback = false;
      }, 4000);
      return;
    }

    let choosenOption = this.guardarOptions.find(el => el.checked);
    this.guardarOption = choosenOption.action;
    

    this.guardarModalRef.approve();

  }

  isValidInputGuardarModal(): boolean {
    if (!this.guardarDescricao || this.guardarDescricao.trim() === '' || this.guardarDescricao.length > 50) return false;
    
    let choosenOption = this.guardarOptions.find(el => el.checked);
    if (!choosenOption) {
      this.toastr.error('Por favor, escolha uma opção para confirmar a operação.', 'Alerta');
      return false;
    }
    return true;
  }

}
