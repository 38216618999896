import { Component, OnInit, ViewChild, ViewChildren, ElementRef, QueryList, ChangeDetectorRef, ApplicationRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection, ITemplatePopupConfig } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { forkJoin } from 'rxjs';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { Location, formatDate } from '@angular/common';

type RegAtividadeFilters = 'HISTORY' | 'COMMENTS' | 'PROCESSES';

interface RegEmails {
  description: string,
  emailList: any[],
  name: string,
  date: Date,
  msg: string,
  obj?: {fileId?},
}
interface RegEmailsItem extends RegEmails {
  type: 'ACTIVITY',
}

interface RegComentariosItem extends AssembleiaComentarios {
  type: 'COMMENT',
  date: Date
}

type RegAtividadeItem = RegComentariosItem | RegEmailsItem;

interface AnexoAssembleiaOpt extends AnexoOpt {
  type: 'LISTA_PRESENCAS' | 'PROCURACOES' | 'LISTA_PRESENCAS_PROCURACOES' | 'CONTA_CORRENTE' | 'ORCAMENTO' | 'BALANCETE' | OrdemTrabalhoLabel,
  ordemTrabalhoType: 'ORDEM_TRABALHO' | 'ASSUNTO_DIVERSO',
  label: string,
  filename: string,
  base64: string,
  ordem: number,
  automatic: boolean,
  isUploaded: boolean,
  fileId: string | number,
  index_ordemTrabalho: number,
  index_itemOrdemTrabalho: number,
  idLinkWithValue: string | number,
  exercicio: number,
  changedContent: boolean,
  canUpload?: () => boolean
}

interface OrdemTrabalhoFlat extends OrdemTrabalhoConfig {
  respSel: OrdemTrabalhoRespConfig,
  resp: string,
  ordemTrabalhoType: 'ORDEM_TRABALHO' | 'ASSUNTO_DIVERSO',
  exercicio: number,
  index_o: number,
  index_r?: number,
  need_anexo_indicator: boolean,
  uploaded_anexo_indicator: boolean
  anexo_id: number,
  parametros: OrdemTrabalhoItem['parametros'],
  selAnos: Array<SelAno>,
  sufixo: string
}

interface OrdemTrabalhoDiversos extends OrdemTrabalhoConfig {
  respOpts: Array<{
    name: string,
    value: OrdemTrabalhoRespConfig,
  }>,
  respSel: OrdemTrabalhoRespConfig,
  respSelOrigId: number,
  resp: string,
  respOrig: string,
  need_anexo_indicator: boolean,
  uploaded_anexo_indicator: boolean
  anexo_id: number,
  attachmentInfo?: {
    name: string,
    base64: string,
    ext: fileExt
  },
  parametros: Array<OrdemTrabalhoParametros>,
  id_assembleia_ordem_trabalho: number,
  id_assembleia_ordem_trabalho_resp: number
  respsAta?: Array<string>
}

export interface OrdemTrabalhoList extends OrdemTrabalhoConfig {
  checked: boolean,
  selAnos: Array<SelAno>,
  anosDescricao: string,
  respOpts: Array<{
    name: string,
    value: OrdemTrabalhoRespConfig,
  }>,
  resps: Array<OrdemTrabalhoItem>,
  id_assembleia_ordem_trabalho: number,
  respsAta?: Array<Array<string>>
}

interface OrdemTrabalhoItem {
  respSel: OrdemTrabalhoRespConfig,
  respSelOrigId: number,
  resp: string,
  respOrig: string,
  need_anexo_indicator: boolean,
  uploaded_anexo_indicator: boolean
  anexo_id: number,
  attachmentInfo?: {
    name: string,
    base64: string,
    ext: fileExt
  },
  parametros: Array<OrdemTrabalhoParametros>,
  id_assembleia_ordem_trabalho_resp: number,
}

interface TipoAnexoConfigList extends TipoAnexoConfig {
  checked: boolean,
  uploaded: boolean,
  custom: boolean
}

interface saldos {
  caixas: Array<any>,
  totalCaixas: number,
  bancos: Array<{ banco, valor_total }>,
  totalBancos: number,
}

interface receitasExtraordinarias {
  rubricas: Array<any>,
  totalRubricas: number,
}

interface despesasExtraordinarias {
  totalRubricas: number,
  rubricas: Array<{ rubrica, valor_total }>
}

interface despesasOrdinarias {
  totalRubricas: number,
  rubricas: Array<{ rubrica: string, valor_total: number }>
}

interface receita {
  exerAnterior: { total: number, gestaoCorrente: number, fundoReserva: number, seguro: number, penalizacao: number },
  exerActual: { total: number, gestaoCorrente: number, fundoReserva: number, seguro: number, pagAdiantados: number, penalizacao: number, receitasExtras: Array<{ descricao: string, valor: number }>, creditosDisponiveis: number, creditosUtilizados: number },
  pagAdiantados: { total: number, gestaoCorrente: number, fundoReserva: number, seguro: number },
}

interface balancetePDFReport {
  title: string,
  reportType: string,
  startDate: Date,
  endDate: Date,
  saldosIniciais: saldos,
  saldosFinais: saldos,
  exercicio: number,
  nextExercicio: number,
  receitasExtraordinarias: receitasExtraordinarias,
  despesasExtraordinarias: despesasExtraordinarias,
  despesasOrdinarias: despesasOrdinarias,
  receitas: receita,
  receitaValor: number,
  despesaValor: number,
  caixaSaldo: number,
  bancoSaldo: number,
}

interface contaCorrenteList {
  separator,
  total,
  endFraccao?: boolean,
  cod_fraccao?: string,
  fraccao?: string,
  debito: number,
  credito: number,
  saldo: number,
  label: string,
  separatorCond: boolean
}

interface contaCorrentePDFReport {
  title: string,
  reportType: string,
  startDate: Date,
  exercicio: number,
  endDate: Date,
  hasAnteriorProprietario: boolean,
  reportList: Array<contaCorrenteList>,
  total: { debito: number, credito: number, saldo: number },
}

interface orcamentoPDFReport {
  id: string,
  exercicio: number
  despesaOrcTotal: number
  quota: number
  quotaLoja: number
  quotaHab: number,
  descricao: string,
  val_lancado: string,
}

interface anosPicker {
  checked: boolean,
  dateVisible: boolean,
  ano: string,
  data_inicio: string,
  data_fim: string,
}

interface orcamentoPickerOT extends orcamentoSelectonApiDataOrcamentos {
  checked: boolean,
  dt_inicio: Date,
  dt_fim: Date,
  tipo: string,
}

export interface FraccaoList {
  checked
  cod_condominio
  cod_fraccao
  zona
  cod_zona
  fraccao
  proprietario
  cod_proprietario
  morada_proprietario
  inquilino
  morada_inquilino
  moradaCondominio
  email_proprietario
  email_proprietario_obs
  email_proprietario_2
  email_proprietario_2_obs
  email_inquilino
  permilagem
  por_carta
  por_email
  codEntidade
  divida: number,
  credito: number,
  disabled:boolean,
}

interface AssembleiasPresencasList {
  id: number,
  id_assembleia: number,
  cod_fracao: string,
  cod_condomino: number,
  cod_representative: number,
  free_representative_name: string,
  permilage: number,
  present: boolean,
  signature: boolean,

  cod_representative_name: string,
  nome_condomino: string,
  nome_fracao: string,
}

type apiSub = 'CONTAS_CORRENTE' | 'BALANCETE' | 'SAVE_ATA' | 'ORCAMENTO' | 'ATA_POR_ASSINAR_EMAIL_TEMPLATE' | 'GET_DETAILS';

type pendingActions = 'PRINT' | 'SEND_ATA_TO_SIGN' | 'SEND_ATA_SIGNED' | 'OPEN_ANEXOS';

interface OrdemTrabalhoOpts {
  checked: boolean
  name: string,
  value: OrdemTrabalhoConfigDetailed,
}

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';

import { Email, EmailBD } from '../business-model-interfaces/email'

import { exportPDF, Group } from '@progress/kendo-drawing';
import { AnexoOpt, ListagemAnexosModalComponent } from '../listagem-anexos-modal/listagem-anexos-modal.component';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { ordem_trabalho_data, getTypeString } from '../business-model-interfaces/ordem-trabalho-datas';
import { AnoSemContasAprovadas, orcamentoSelectonApiDataOrcamentos } from '../api-requests';
import { OrcamentoPdfComponent } from '../orcamento-pdf/orcamento-pdf.component';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { OrcamentosService } from '../business-logic-services/orcamentos.service';
import { NavigationService } from '../navigation.service';
import { OrcamentoDetailed } from '../business-model-interfaces/orcamentos';
import { fileExt } from '../business-model-interfaces/anexo';
import { AssembleiaDetails, AssembleiaOrdemTrabalhoDetailed, AssembleiasPresencas, GetAssembleiasConfigsAPI, GetAssembleiasDetailsAPI, SaveAssembleiaOrdemTrabalhoResp, SaveAssembleiaOrdemTrabalho, SelAno, SaveAssembleiaOrdemTrabalhoRespParametros, AssembleiaParametroChave, AssembleiaOrdemTrabalhoRespParametros, OrdemTrabalhoParametros, BancoOperation, MapaReuniao, AssembleiaState, AssembleiaComentarios } from '../business-model-interfaces/assembleias';
import { AssembleiasService } from '../business-logic-services/assembleias.service';
import { bit, IContext } from '../business-model-interfaces/application';
import { OrdemTrabalhoConfig, OrdemTrabalhoConfigDetailed, OrdemTrabalhoLabel, OrdemTrabalhoRespConfig, TipoAnexoConfig } from '../configurations';
import { BusinessLogicService } from '../business-logic.service';
import { EmailCartaComunicacaoModalComponent, EntityEmailCartaInput } from '../email-carta-comunicacao-modal/email-carta-comunicacao-modal.component';
import { EditBancosAssembleiaRespComponent } from '../edit-bancos-assembleia-resp/edit-bancos-assembleia-resp.component';
import { EmailOficial, EntityType } from '../condominos';
import { SelectEntidadesList, SelectEntitiesModalComponent } from '../select-entities-modal/select-entities-modal.component';
import { CondominioTitularBancarioDB, GetEntitiesContactDetails, TipoComunicacao } from '../business-model-interfaces/condominios';
import { CondominiosService } from '../business-logic-services/condominios.service';
import { AssembleiasPickQuotaExtraComponent } from '../assembleias-pick-quota-extra/assembleias-pick-quota-extra.component';
import { Processamento, ProcessamentoDetails } from '../business-model-interfaces/processamentos';
import { ProcessamentoPdfComponent } from '../processamento-pdf/processamento-pdf.component';
import { ProcessamentosService } from '../business-logic-services/processamentos.service';
import { ProcessamentoDeleteRecibosComponent } from '../processamento-delete-recibos/processamento-delete-recibos.component';
import { AssembleiasPickEmailOficialComponent } from '../assembleias-pick-email-oficial/assembleias-pick-email-oficial.component';
import { pairwise } from 'rxjs/operators';
import { RegistoCTTSaveDetailed } from '../business-model-interfaces/comunicacoes';


@Component({
  selector: 'app-assembleias-details',
  templateUrl: './assembleias-details.component.html',
  styleUrls: ['./assembleias-details.component.scss']
})
export class AssembleiasDetailsComponent implements OnInit {


  dependenciesMap: Array<{ action: pendingActions, dependencies: Array<apiSub> }> = [
    {
      action: 'PRINT',
      dependencies: ['CONTAS_CORRENTE', 'BALANCETE', 'ORCAMENTO']
    },
    {
      action: 'SEND_ATA_TO_SIGN',
      dependencies: ['CONTAS_CORRENTE', 'BALANCETE', 'ORCAMENTO', 'ATA_POR_ASSINAR_EMAIL_TEMPLATE']
    },
    {
      action: 'SEND_ATA_SIGNED',
      dependencies: ['CONTAS_CORRENTE', 'BALANCETE', 'ORCAMENTO']
    },
    {
      action: 'OPEN_ANEXOS',
      dependencies: ['CONTAS_CORRENTE', 'BALANCETE', 'ORCAMENTO']
    },
  ]

  tabsObjDef = [
    { key: 'convocatorias', name: 'Convocatória', url: 'convocatoria', active: true, disabled: false },
    { key: 'presencas', name: 'Presenças e Assinaturas', url: 'xx', active: false, disabled: true },
    { key: 'ata', name: 'Ata', url: 'xx', active: false, disabled: true },
    { key: 'logs', name: 'Registo Atividade', url: 'registo-xx', active: false, disabled: true },
  ];

  componentType = "ASSEMBLEIA";
  defaultFilename = null;

  emailConfig = {
    assunto: null,
    corpo: null,
  }
  @ViewChild('updateEmailRef', { static: false }) updateEmailRef;
  updateEmailModalRef = null;
  updateEmailModalConfig: any = null;

  @ViewChild('pickOrdemTrabalhoAnosRef', { static: false }) pickOrdemTrabalhoAnosRef;
  pickOrdemTrabalhoAnosModalRef = null;
  pickOrdemTrabalhoAnosModalConfig: any = null;

  regEmails: RegEmails[] = [];
  regAtividade: RegAtividadeItem[] = [];
  regAtividadeMapaReuniao: RegAtividadeItem[] = [];
  regAtividadeFilters: { tipo:RegAtividadeFilters, tipoOpts: {name: string, value: RegAtividadeFilters}[]} = {
    tipo: 'HISTORY',
    tipoOpts: [
      { name: 'Histórico', value: 'HISTORY'},
      { name: 'Comentários', value: 'COMMENTS'},
      { name: 'Processos Assembleia', value: 'PROCESSES'},
    ]
  }
  hasRegEmails = false;

  // ATA AND CONFIGURATION OF ATA
  minAtaNum = 1;
  minAtaPag = 2;
  maxAtaPag = 59;

  hasRegPresencas = false;




  hasRegPresencasFunc = (): boolean => {
    if (!this.presencasList.find(el => el.present)) {
      this.toastr.error('Não é possível submeter o anexo selecionado. Nenhum presença foi registada na tabela do registo de presenças.', 'Alerta', { timeOut: 4000 });
      return false;
    }
    return true;
  }
  hasRepresentantesFunc = (): boolean => {
    if (!this.presencasList.find(el => el.cod_representative_name !== null || el.free_representative_name !== null)) {
      this.toastr.error('Não é possível submeter o anexo selecionado. Nenhum representante foi registado na tabela do registo de presenças.', 'Alerta', { timeOut: 4000 });
      return false;
    }
    return true;
  }
  hasRegPresencasAndRepresentantes = (): boolean => {
    let hasPresencas = this.hasRegPresencasFunc();
    if (!hasPresencas) return false;
    let hasRepresentantes = this.hasRepresentantesFunc();
    if (!hasRepresentantes) return false;
    return true;
  }


  // CONFIGURAÇÕES
  entregarParaAssinarOpts: { name: string, value: 'EMAIL' | 'PRESENCIAL' }[] = [
    { name: 'Entregue para Assinaturas', value: 'EMAIL' },
    { name: 'Presencial', value: 'PRESENCIAL' },
  ]
  entregueParaAssinar: 'EMAIL' | 'PRESENCIAL' = null;

  entregueParaAssinarChanged() {
    this.selectionFraccaoList.forEach(el => {
      el.disabled = this.entregueParaAssinar === 'PRESENCIAL';
    })
  }

  localAssembleiaOpts = [
    { name: 'Presencial', value: 'P' },
    { name: 'Misto', value: 'M' },
    { name: 'Online', value: 'O' },
  ]

  estadoAssembleiaOpts = [
    { name: 'Ativo', value: 'ATIVO' },
    { name: 'Cancelada', value: 'CANCELADA' },
  ]

  autorConvocatoriaOpts = [
    { name: 'Empresa Gestora', value: 1 },
    { name: 'Administração', value: 2 },
    { name: 'Condóminos', value: 3 },
  ]
  autorConvocatoria = null;

  getAutorConvocatoriaName() {
    let opt = this.autorConvocatoriaOpts.find(el => el.value === this.autorConvocatoria);
    return opt !== undefined ? opt.name : null
  }

  anexosAutomatic = [];
  ataFolderAnexosOpts: Array<AnexoOpt> = [
    { label: 'Relatório de Assembleia', type: 'RELATORIO_ASSEMBLEIA', filename: 'Relatorio_Assembleia', ext: null, base64: null, fileId: null, isUploaded: null, changedContent: false, automatic: false, canUpload: this.hasRepresentantesFunc },
    { label: 'Ata Assinada', type: 'ATA_ASSINADA', filename: 'Ata_Assinada', ext: null, base64: null, fileId: null, isUploaded: null, changedContent: false, automatic: false, canUpload: this.hasRegPresencasFunc },
  ];
  anexosOptsOrig: Array<AnexoAssembleiaOpt> = [
    { label: 'Lista de presenças', ordemTrabalhoType: null, ext: null, filename: 'Lista_Presenças', type: 'LISTA_PRESENCAS', index_ordemTrabalho: null, index_itemOrdemTrabalho: null, idLinkWithValue: null, ordem: 1, base64: null, fileId: null, isUploaded: null, changedContent: false, exercicio: null, automatic: false, canUpload: this.hasRegPresencasFunc },
    { label: 'Procurações', ordemTrabalhoType: null, ext: null, filename: 'Procurações', type: 'PROCURACOES', index_ordemTrabalho: null, index_itemOrdemTrabalho: null, idLinkWithValue: null, ordem: 2, base64: null, fileId: null, isUploaded: null, changedContent: false, automatic: false, exercicio: null, canUpload: this.hasRepresentantesFunc },
    { label: 'Lista de presenças e procurações', ordemTrabalhoType: null, ext: null, filename: 'Lista_Presenças_Procurações', type: 'LISTA_PRESENCAS_PROCURACOES', index_ordemTrabalho: null, index_itemOrdemTrabalho: null, idLinkWithValue: null, ordem: 3, base64: null, fileId: null, isUploaded: null, changedContent: false, exercicio: null, automatic: false, canUpload: this.hasRepresentantesFunc },
    // { label: 'Balancete', filename: 'Balancete.pdf', type: 'BALANCETE', index_ordemTrabalho: null, ordem: 3, base64: null, fileId: null, isUploaded:  null, changedContent: false,  automatic: true, },
    // { label: 'Orçamento', filename: 'Orçamento.pdf', type: 'ORCAMENTO', index_ordemTrabalho: null, ordem: 4, base64: null, fileId: null, isUploaded:  null, changedContent: false,  automatic: true, },
    // { label: 'Conta corrente das frações', filename: 'Conta_Corrente_Frações.pdf', index_ordemTrabalho: null, type: 'CONTA_CORRENTE', ordem: 5, base64: null, fileId: null, isUploaded:  null, changedContent: false,  automatic: true },
  ];
  // anexosOpts: Array<AnexoAssembleiaOpt|AnexoOpt> = [];

  regPresencasAnexosOpts = [
    { name: 'Lista de presenças', value: 'LISTA_PRESENCAS', ordem: 1, checked: false, hasAnexoUploaded: false },
    { name: 'Procurações', value: 'PROCURACOES', ordem: 2, checked: false, hasAnexoUploaded: false },
    { name: 'Lista de presenças e Procurações', value: 'LISTA_PRESENCAS_PROCURACOES', ordem: 3, checked: false, hasAnexoUploaded: false },
  ];

  condominioNome = null;
  condominioCod = null;

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  @ViewChild('revertEstadoAlertRef', { static: false }) revertEstadoAlertRef;
  revertEstadoModalRef = null;
  revertEstadoAlertConfig: any = null;

  revertEstadoStep:AssembleiaState = null;
  presentRevertEstadoModal(step:AssembleiaState): Promise<boolean> {
    return new Promise((resolve) => {
      if (['ENTREGUE_ASSINATURA','RECECAO_ATA','COPIA_ATA'].findIndex(el => el == step) == -1) {
        resolve(true);
        return;
      }
      this.revertEstadoStep = step;
      this.revertEstadoModalRef = this.modalService
        .open(this.revertEstadoAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => {
          resolve(false);
        });
    })
  }

  checkIfIsRevertingEstadoAssembleia(): Promise<boolean> {
    return new Promise(async (resolve) => {
      let actualStep = this.getAssembleiaState(this.mapaReuniaoForm);
      let prevStep = this.getAssembleiaState(this.mapaReuniaoFormOrig);

      let reverted = this.assembleiaStateOrder.findIndex(el => el === actualStep) < this.assembleiaStateOrder.findIndex(el => el === prevStep);
      if (reverted) {
        let proceed = await this.presentRevertEstadoModal(prevStep);
        if (!proceed) {
          resolve(false);
          return;
        }
      }
      resolve(true);
    });
  }
  
  @ViewChild('documentosAlertRef', { static: false }) documentosAlertRef;
  documentosModalRef = null;
  documentosAlertConfig: any = null;

  @ViewChild('convProcAlertRef', { static: false }) convProcAlertRef;
  convProcModalRef = null;
  convProcAlertConfig: any = null;

  @ViewChild('mapaReuAlertRef', { static: false }) mapaReuAlertRef;
  mapaReuModalRef = null;
  mapaReuAlertConfig: any = null;

  @ViewChild('assembChangedAlertRef', { static: false }) assembChangedAlertRef;
  assembChangedModalRef = null;
  assembChangedAlertConfig: any = null;

  @ViewChild('removerAtaAlertRef', { static: false }) removerAtaAlertRef;
  removerAtaModalRef = null;
  removerAtaAlertConfig: any = null;

  @ViewChild('cancelAssembAlertRef', { static: false }) cancelAssembAlertRef;
  cancelAssembModalRef = null;
  cancelAssembAlertConfig: any = null;

  @ViewChild('fraccoesListAlertRef', { static: false }) fraccoesListAlertRef;
  fraccoesListModalRef = null;
  fraccoesListAlertConfig: any = null;

  @ViewChild('contasListAlertRef', { static: false }) contasListAlertRef;
  contasListModalRef = null;
  contasListAlertConfig: any = null;

  @ViewChild('addAnexosAlertRef', { static: false }) addAnexosAlertRef;
  addAnexosModalRef = null;
  addAnexosAlertConfig: any = null;

  @ViewChild('ordemTrabAlertRef', { static: false }) ordemTrabAlertRef;
  ordemTrabModalRef = null;
  ordemTrabAlertConfig: any = null;

  @ViewChild('anexosMissingAlertRef', { static: false }) anexosMissingAlertRef;
  anexosMissingModalRef = null;
  anexosMissingAlertConfig: any = null;

  @ViewChild('ordemTrabParamAlertRef', { static: false }) ordemTrabParamAlertRef;
  ordemTrabParamModalRef = null;
  ordemTrabParamAlertConfig: any = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('unsavedAlertRef', { static: false }) unsavedAlertRef;
  unsavedModalRef = null;
  unsavedAlertConfig: any = null;

  @ViewChild('ataUploadedAlertRef', { static: false }) ataUploadedAlertRef;
  ataUploadedModalRef = null;
  ataUploadedAlertConfig: any = null;

  @ViewChild('alertaCttAlertRef', { static: false }) alertaCttAlertRef;
  alertaCttModalRef = null;
  alertaCttAlertConfig: any = null;

  @ViewChild('quorumAlertRef', { static: false }) quorumAlertRef;
  quorumModalRef = null;
  quorumAlertConfig: any = null;

  @ViewChild('representantesAlertRef', { static: false }) representantesAlertRef;
  representantesModalRef = null;
  representantesAlertConfig: any = null;

  @ViewChild('deleteAttachmentAlertRef', { static: false }) deleteAttachmentAlertRef;
  deleteAttachmentModalRef = null;
  deleteAttachmentAlertConfig: any = null;

  @ViewChild('confAtaAlertRef', { static: false }) confAtaAlertRef;
  confAtaModalRef = null;
  confAtaAlertConfig: any = null;

  @ViewChild('fornecedorAlertRef', { static: false }) fornecedorAlertRef;
  fornecedorModalRef = null;
  fornecedorAlertConfig: any = null;

  @ViewChild('dataDividasAlertRef', { static: false }) dataDividasAlertRef;
  dataDividasModalRef = null;
  dataDividasAlertConfig: any = null;

  @ViewChild('representanteAlertRef', { static: false }) representanteAlertRef;
  representanteModalRef = null;
  representanteAlertConfig: any = null;
  @ViewChildren('pdfBalancete') pdfBalanceteController: QueryList<PDFExportComponent>;

  @ViewChildren('pdfContaCorrente') pdfContaCorrenteController: QueryList<PDFExportComponent>;
  @ViewChildren('pdfOrcamento') pdfOrcamentoController: QueryList<OrcamentoPdfComponent>;
  @ViewChild('pdfAta', { static: false }) pdfAtaController;
  @ViewChild('pdfConvocatoria', { static: false }) pdfConvocatoriaController;
  @ViewChild('pdfRegPresencas', { static: false }) pdfRegPresencasController;
  @ViewChild('pdfProcuracoes', { static: false }) pdfProcuracoesController;
  @ViewChild('pdfConvocatorias', { static: false }) pdfConvocatoriasController;
  @ViewChild('pdfCartaAta', { static: false }) pdfCartaAtaController;
  @ViewChild('pdfConvProcuracoes', { static: false }) pdfConvProcuracoesController;
  @ViewChild('pdfDocCtt', { static: false }) pdfDocCttController;
  now = new Date();

  transitionController = new TransitionController();
  submittingForm = false;
  loading = false;

  selTab: string = 'convocatorias';

  // GERAL FORM
  geralForm = new FormGroup({
    dt: new FormControl(null, { validators: Validators.required }),
    dt_alter: new FormControl(null, { validators: Validators.required }),
    dt_fim: new FormControl(null),
    extraordinaria: new FormControl(null, { validators: Validators.required }),
    ficheiro: new FormControl(null),
    morada: new FormControl(null, { validators: Validators.required }),
    postalCode: new FormControl(null),
    locality: new FormControl(null),
    local: new FormControl(null),
    criado_por: new FormControl(null),
    realizada_por: new FormControl(null),
    responsavel_por: new FormControl(null),
    estado: new FormControl(null),
  });
  cod_condominio = null;

  presencasListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column' },
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: true, centered: false, class: '' },
    { key: 'condomino', name: 'Condómino', type: 'text', sort: null, searchable: true, centered: false, class: '' },
    { key: 'representante', name: 'Representante', type: 'text', sort: null, searchable: true, centered: false, class: '' },
    { key: 'permilagem', name: 'Permilagem', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'signature', name: 'Assinatura', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered' },
    // { key: 'action', name: 'mdi mdi-comment-multiple-outline', type: 'action', sort: null, searchable: false, centered: false, class: 'col-centered' },
  ];
  presencasList: Array<AssembleiasPresencasList> = [];
  presencasListSignatures: Array<AssembleiasPresencasList> = [];
  nfraccoes: number = 0;
  totalPerm: number = 0;
  totalSignatureQuorum = null;

  // FINAL CONVOCATORIA FORM
  finalConvocatoriaForm = new FormGroup({
    texto_final: new FormControl(null)
  });

  // DELIBERACOES FORM
  deliberacoesForm = new FormGroup({
    deliberacoes: new FormControl(null)
  });

  // OBSERVACOES FORM
  obsForm = new FormGroup({
    obs: new FormControl(null)
  });

  details: AssembleiaDetails = null;
  mapaReuniao: MapaReuniao = null;
  isCreate = true;
  isCreatePresencas = true;
  assembleiaId = null;
  condominioMorada = { morada: null, postalCode: null, locality: null };

  ordemTrabalhosConfig: Array<OrdemTrabalhoConfigDetailed> = [];

  comp = 'assembleia';

  loadingModal = false;
  loadingModalPagar = false;

  utilizadoresOpts = [];
  dataLimite = null;

  subsMsg = null;
  isZIndexZero = false;

  extraordinariaOpts = [
    { name: 'Ordinária', value: '0' },
    { name: 'Extraordinária', value: '1' },
  ]

  convEnviadaOpts = [
    { name: 'Enviada', value: true },
    { name: 'Por Enviar', value: false },
  ]
  convEnviada: boolean = undefined;
  convOrdensTrabalho = [];

  addEmailList = null;

  editingUsername = null;

  condominioNIF = null;


  apiSubs: Array<apiSub> = [];

  queryParams: ParamMap = null;

  constructor(public modalService: SuiModalService,
    public userSession: UserSessionService,
    public orcamentoService: OrcamentosService,
    public assembleiasService: AssembleiasService,
    public businessLogic: BusinessLogicService,
    public fb: FormBuilder,
    public toastr: ToastrService,
    public router: Router,
    public route: ActivatedRoute,
    public api: ApiService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public cdRef: ChangeDetectorRef,
    public appRef: ApplicationRef,
    public location: Location,
    public appState: AppStateService,
    public elem: ElementRef,
    public message: MessageService,
    public processamentos: ProcessamentosService,
    public condominios: CondominiosService,
    public navigationService: NavigationService) {
    this.subsMsg = this.message.getMessage().subscribe(async msg => {
      if (msg.dest === 'CAIXA_VERTIS' || msg.dest === 'ASSEMBLEIA') {
        switch (msg.cmd) {
          case 'HIDE_INPUT':
            this.isZIndexZero = true;
            break;
          case 'SHOW_INPUT':
            this.isZIndexZero = false;
            break;
          case 'SENDING_EMAILS':
            this.toastr.info('Emails de convocatórias estão a ser enviados.', 'Por favor aguarde...', { timeOut: 0, extendedTimeOut: 0 });
            break;
          case 'UNSAVED_ALERT':
            let res = await this.presentUnsavedChangesModal();
            if (res) {
              if (res.hasOwnProperty('exit') && !res['exit']) {

              } else {
                this.exit = true;
                if (msg.extras != null) {
                  this.router.navigateByUrl(msg.url, msg.extras);
                } else {
                  this.router.navigateByUrl(msg.url);
                }
              }
            } else {
              // EXIT
              this.exit = true;
              this.compState = {
                init: {
                  convocatoria: null,
                  presencas: null,
                  ata: null
                },
                onExit: {
                  convocatoria: null,
                  presencas: null,
                  ata: null
                },
              }
              if (msg.extras != null) {
                this.router.navigateByUrl(msg.url, msg.extras);
              } else {
                this.router.navigateByUrl(msg.url);
              }
            }
            break;
        }
      }
    });
    this.queryParams = this.route.snapshot.queryParamMap;
  }

  compState = {
    init: {
      convocatoria: {
        dt: null,
        dt_alter: null,
        dt_fim: null,
        extraordinaria: null,
        ficheiro: null,
        morada: null,
        postalCode: null,
        locality: null,
        ordensTrabalhos: null,
      },
      presencas: {
        presencasList: null,
      },
      ata: {
        ata: {
          intro: null,
          ordensTrabalho: null,
          conclusao: null,
        },
        numAta: null,
        numFolha: null,
        numPag: null,
        ata_concluida: null
      }
    },
    onExit: {
      convocatoria: {
        dt: null,
        dt_alter: null,
        dt_fim: null,
        extraordinaria: null,
        ficheiro: null,
        morada: null,
        postalCode: null,
        locality: null,
        ordensTrabalhos: null,
      },
      presencas: {
        presencasList: null,
      },
      ata: {
        ata: {
          intro: null,
          ordensTrabalho: null,
          conclusao: null,
        },
        numAta: null,
        numFolha: null,
        numPag: null,
        ata_concluida: null
      }
    },
  }
  exit = false;
  warnBeforeLeave() {
    if (this.exit || this.isCreate || !this.details || this.apiSubs.find(el => el === 'GET_DETAILS')) return false;

    // SAVE EXIT COMPONENT STATE
    this.compState.onExit = this.getCompState();

    this.retObj = {
      convocatoria: (JSON.stringify(this.compState.init.convocatoria) !== JSON.stringify(this.compState.onExit.convocatoria)),
      presencas: (JSON.stringify(this.compState.init.presencas) !== JSON.stringify(this.compState.onExit.presencas) && this.presencasVisited),
      ata: (JSON.stringify(this.compState.init.ata) !== JSON.stringify(this.compState.onExit.ata) && this.ataVisited),
    };

    return (this.retObj.convocatoria || this.retObj.presencas || (this.retObj.ata && !(this.isCreatePresencas || !this.introAtaOrig)));
  }
  retObj = {
    convocatoria: false,
    presencas: false,
    ata: false,
  };

  newAssembleia() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['assembleias/assembleias', 'criar']);

    // BREADCRUMB SIGNAL
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVA ASSEMBLEIA' });
  }

  setState() {
    let id = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.id : ((this.cod_condominio && this.cod_condominio.hasOwnProperty('id')) ? this.cod_condominio.id : null);
    let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : ((this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : null);
    let nome = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.nome : ((this.cod_condominio && this.cod_condominio.hasOwnProperty('nome')) ? this.cod_condominio.nome : null);

    this.appState.setInitState(this.comp, {
      condominioSelected: {
        id: id,
        cod: cod,
        nome: nome,
      }
    });
  }

  goToCondominio() {
    let id = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.id : ((this.cod_condominio && this.cod_condominio.hasOwnProperty('id')) ? this.cod_condominio.id : null);
    let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : ((this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : null);
    let nome = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.nome : ((this.cod_condominio && this.cod_condominio.hasOwnProperty('nome')) ? this.cod_condominio.nome : null);

    if (!id) return;

    this.setState();

    this.router.navigate(['condominios/condominio/geral', id]);

    // Emit signal to breadcrumb component
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${cod} - ${nome}` });
  }

  initState = null;
  async ngOnInit() {
    this.loadNavigationParams();

    this.api.getUtilizadores(0, 500).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        res.data.forEach(el => {
          this.utilizadoresOpts.push({ name: el.first_name + ' ' + el.last_name, value: el.id });
        });
        this.utilizadoresOpts = JSON.parse(JSON.stringify(this.utilizadoresOpts));
      }
    }, err => { });

    // HANDLE APPLICATION STATE
    this.initState = this.appState.getInitState(this.comp);

    if (this.initState && this.initState.hasOwnProperty('state') && this.initState.state.condominioSelected !== '-2') {
      this.cod_condominio = { name: this.initState.state.condominioSelected.cod + ' - ' + this.initState.state.condominioSelected.nome, value: this.initState.state.condominioSelected };

      this.condominioCod = this.cod_condominio.value.cod;
      this.condominioNome = this.cod_condominio.value.nome;

      this.appState.clearInitState(this.comp);
    }

    this.animate();


    this.getDetails();

    if (this.route.snapshot.params.id == 'criar') {
      this.isCreate = true;

      if (this.initState) { this.getCondAssembleiasDetails(); }

      this.restoreForm('geral');

      await this.getAssembleiasConfigs();
    } else {
      this.isCreate = false;
      this.tabsObjDef.find(el => el.key === 'presencas').disabled = false;
      this.assembleiaId = this.route.snapshot.params.id;
    }

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'GET_EMAILS_CONFIG' });

    this.startApiSub('ATA_POR_ASSINAR_EMAIL_TEMPLATE');
    this.getEmailTemplates('ATA_POR_ASSINAR').then(res => {
      this.appConfig.ataAnexoSubject = res.assunto;
      this.appConfig.ataAnexoBody = res.corpo.split('\n');
      this.finishApiSub('ATA_POR_ASSINAR_EMAIL_TEMPLATE');
    }).catch(err => {
      this.finishApiSub('ATA_POR_ASSINAR_EMAIL_TEMPLATE');
    });

    this.setTab('geral');
  }

  anchorAction: 'CHOOSE_ORCAMENTO' = null;
  anchorParams: any = {};
  async executeAnchorAction() {
    switch (this.anchorAction) {
      case 'CHOOSE_ORCAMENTO':
        this.tabsObjDef.find(el => el.key === 'ata').active = true;
        this.setTab('ata');
        let index_orcamento = this.ordensTrabalhos.findIndex(el => el.label === 'ORCAMENTO');
        if (index_orcamento !== -1 && this.anchorParams && this.anchorParams['exercicio']) {
          let scrolled = false;
          while (!scrolled) {
            let ordensTrabalho = document.getElementsByClassName('ot-' + index_orcamento);
            if (ordensTrabalho.length) {
              var deltaElement = ordensTrabalho[0].getBoundingClientRect().top;
              if (deltaElement === 0) {
                await this.utils.sleep(50);
                continue;
              }
              this.scroll(document.getElementById('ata-scrollable'), deltaElement, true);
              scrolled = true;
            }
          }
          let exercicio = this.anchorParams['exercicio']
          let resp_index = this.ordensTrabalhos[index_orcamento].selAnos.findIndex(el => el.ano === exercicio);
          if (resp_index >= 0 && resp_index < this.ordensTrabalhos[index_orcamento].resps.length) {
            this.selectOrcamento(this.ordensTrabalhos[index_orcamento], resp_index);
          }
        }
        break;
      default:
        this.route.fragment = null;
        break;
    }
    this.anchorAction = null;
  }

  async executeQueryAction() {
    switch (this.queryParams.get('action')) {
      case 'select_quota_extra':
        this.tabsObjDef.find(el => el.key === 'ata').active = true;
        this.setTab('ata');
        let index_quota_extra = this.ordensTrabalhos.findIndex(el => el.label === 'QUOTA_EXTRA');
        let nResp = this.queryParams.get('nResp');
        if (index_quota_extra !== -1 && !Number.isNaN(parseInt(nResp))) {
          let scrolled = false;
          while (!scrolled) {
            let ordensTrabalho = document.getElementsByClassName('ot-' + index_quota_extra);
            if (ordensTrabalho.length) {
              var deltaElement = ordensTrabalho[0].getBoundingClientRect().top;
              if (deltaElement === 0) {
                await this.utils.sleep(50);
                continue;
              }
              this.scroll(document.getElementById('ata-scrollable'), deltaElement, true);
              scrolled = true;
            }
          }

          if (parseInt(nResp) >= this.ordensTrabalhos[index_quota_extra].resps.length) break;

          let prevState: any = {};

          if (this.queryParams.has('id_processamento')) prevState['id_proc_select'] = this.queryParams.get('id_processamento');
          if (this.queryParams.has('startYear')) prevState['startYear'] = this.queryParams.get('startYear');
          if (this.queryParams.has('endYear')) prevState['endYear'] = this.queryParams.get('endYear');

          this.selectQuotaExtra(this.ordensTrabalhos[index_quota_extra], parseInt(nResp), prevState);
        }

        break;

      default:
        break;
    }
    this.queryParams = null;
  }

  smoothScroll = true;
  scroll(scrollable: HTMLElement, newScrollDelta: number, smoothScroll: boolean, offset = 10) {
    this.smoothScroll = smoothScroll;
    setTimeout(() => {
      scrollable.scrollTop += newScrollDelta - document.getElementById('breadcrumb').getBoundingClientRect().height - document.getElementById('assembleia-tabs').getBoundingClientRect().height - offset;
    });
  }

  loadAnchorAction(fragment: string): boolean {
    switch (true) {
      case /^(chooseorcamento\d*)$/g.test(fragment):
        let exercicio = fragment.replace('chooseorcamento', '');
        if (exercicio && Number.isInteger(Number(exercicio))) {
          this.anchorParams['exercicio'] = exercicio;
          this.anchorAction = 'CHOOSE_ORCAMENTO';
        } else {
          this.router.navigate([], { replaceUrl: true });
        }
        break;

      default:
        this.anchorAction = null;
        return false;
    }
    return true;
  }

  loadNavigationParams() {

    this.route.fragment
      .subscribe((fragment: string) => {
        let success = this.loadAnchorAction(fragment);
        if (!success) {
          let originalURL = this.navigationService.removeURLFragment(this.router.url);
          this.location.replaceState(originalURL);
        }
      });

    let moduleInfo = this.navigationService.getModule('ASSEMBLEIAS_DETAILS');
    if (moduleInfo) {
      this.anchorParams = { ...this.anchorParams, ...moduleInfo.data };
    }
  }

  presentUnsavedChangesModal() {
    return new Promise(resolve => {
      this.unsavedModalRef = this.modalService
        .open(this.unsavedAlertConfig)
        .onApprove(() => {
          resolve(true);
          this.loadingSaveAll = false;
        })
        .onDeny(res => {
          this.loadingSaveAll = false;
          if (res === 'LEAVE') {
            resolve({ exit: true });
          } else {
            resolve({ exit: false });
          }
        });
    });
  }

  presentRepresentantesAlert() {
    return new Promise(resolve => {
      this.representantesModalRef = this.modalService
        .open(this.representantesAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(res => {
          resolve(false);
        });
    });
  }

  presentQuorumModal() {
    return new Promise(resolve => {
      this.quorumModalRef = this.modalService
        .open(this.quorumAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(res => {
          resolve(false);
        });
    });
  }

  balanceteSubs = null;
  orcamentoSubs = null;
  contasCorrentesSubs = null;
  contasCorrentesDetalhadoSubs = null;
  async ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: null });

    if (this.balanceteSubs) this.balanceteSubs.unsubscribe();
    if (this.orcamentoSubs) this.orcamentoSubs.unsubscribe();
    if (this.contasCorrentesSubs) this.contasCorrentesSubs.unsubscribe();
    if (this.contasCorrentesDetalhadoSubs) this.contasCorrentesDetalhadoSubs.unsubscribe();
    if (this.subsMsg) this.subsMsg.unsubscribe();

    if (this.orcamentoPickerModalRef) this.orcamentoPickerModalRef.deny();
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngAfterViewInit() {
    this.updateEmailModalConfig = new TemplateModalConfig<IContext, string, string>(this.updateEmailRef);
    this.updateEmailModalConfig.closeResult = "closed";
    this.updateEmailModalConfig.size = 'small';
    this.updateEmailModalConfig.transition = 'fade up';
    this.updateEmailModalConfig.transitionDuration = 400;

    this.pickOrdemTrabalhoAnosModalConfig = new TemplateModalConfig<IContext, string, string>(this.pickOrdemTrabalhoAnosRef);
    this.pickOrdemTrabalhoAnosModalConfig.closeResult = "closed";
    this.pickOrdemTrabalhoAnosModalConfig.size = 'tiny';
    this.pickOrdemTrabalhoAnosModalConfig.transition = 'fade up';
    this.pickOrdemTrabalhoAnosModalConfig.transitionDuration = 400;

    this.documentosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.documentosAlertRef);
    this.documentosAlertConfig.closeResult = "closed";
    this.documentosAlertConfig.size = 'small';
    this.documentosAlertConfig.transition = 'fade';
    this.documentosAlertConfig.transitionDuration = 250;

    this.convProcAlertConfig = new TemplateModalConfig<IContext, string, string>(this.convProcAlertRef);
    this.convProcAlertConfig.closeResult = "closed";
    this.convProcAlertConfig.size = 'normal';
    this.convProcAlertConfig.transition = 'fade';
    this.convProcAlertConfig.transitionDuration = 250;

    this.despesasCttAlertConfig = new TemplateModalConfig<IContext, string, string>(this.despesasCttAlertRef);
    this.despesasCttAlertConfig.isClosable = false;
    this.despesasCttAlertConfig.closeResult = "closed";
    this.despesasCttAlertConfig.size = 'large';
    this.despesasCttAlertConfig.transition = 'fade';
    this.despesasCttAlertConfig.transitionDuration = 250;

    this.pagamentoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.pagamentoAlertRef);
    this.pagamentoAlertConfig.isClosable = false;
    this.pagamentoAlertConfig.closeResult = "closed";
    this.pagamentoAlertConfig.size = 'small';
    this.pagamentoAlertConfig.transition = 'fade up';
    this.pagamentoAlertConfig.transitionDuration = 400;

    this.fraccoesListAlertConfig = new TemplateModalConfig<IContext, string, string>(this.fraccoesListAlertRef);
    this.fraccoesListAlertConfig.isClosable = false;
    this.fraccoesListAlertConfig.closeResult = "closed";
    this.fraccoesListAlertConfig.size = 'small';
    this.fraccoesListAlertConfig.transition = 'fade';
    this.fraccoesListAlertConfig.transitionDuration = 250;

    this.contasListAlertConfig = new TemplateModalConfig<IContext, string, string>(this.contasListAlertRef);
    this.contasListAlertConfig.closeResult = "closed";
    this.contasListAlertConfig.size = 'tiny';
    this.contasListAlertConfig.transition = 'fade';
    this.contasListAlertConfig.transitionDuration = 250;

    this.addAnexosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addAnexosAlertRef);
    this.addAnexosAlertConfig.closeResult = "closed";
    this.addAnexosAlertConfig.size = 'small';
    this.addAnexosAlertConfig.transition = 'fade';
    this.addAnexosAlertConfig.transitionDuration = 250;

    this.ordemTrabAlertConfig = new TemplateModalConfig<IContext, string, string>(this.ordemTrabAlertRef);
    this.ordemTrabAlertConfig.closeResult = "closed";
    this.ordemTrabAlertConfig.size = 'small';
    this.ordemTrabAlertConfig.transition = 'fade';
    this.ordemTrabAlertConfig.transitionDuration = 250;

    this.anexosMissingAlertConfig = new TemplateModalConfig<IContext, string, string>(this.anexosMissingAlertRef);
    this.anexosMissingAlertConfig.closeResult = "closed";
    this.anexosMissingAlertConfig.size = 'mini';
    this.anexosMissingAlertConfig.transition = 'fade';
    this.anexosMissingAlertConfig.transitionDuration = 250;

    this.ordemTrabParamAlertConfig = new TemplateModalConfig<IContext, string, string>(this.ordemTrabParamAlertRef);
    this.ordemTrabParamAlertConfig.closeResult = "closed";
    this.ordemTrabParamAlertConfig.size = 'tiny';
    this.ordemTrabParamAlertConfig.transition = 'fade';
    this.ordemTrabParamAlertConfig.transitionDuration = 250;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.unsavedAlertConfig = new TemplateModalConfig<IContext, string, string>(this.unsavedAlertRef);
    this.unsavedAlertConfig.closeResult = "closed";
    this.unsavedAlertConfig.size = 'mini';
    this.unsavedAlertConfig.transition = 'fade';
    this.unsavedAlertConfig.transitionDuration = 250;

    this.alertaCttAlertConfig = new TemplateModalConfig<IContext, string, string>(this.alertaCttAlertRef);
    this.alertaCttAlertConfig.isClosable = false;
    this.alertaCttAlertConfig.closeResult = "closed";
    this.alertaCttAlertConfig.size = 'mini';
    this.alertaCttAlertConfig.transition = 'fade';
    this.alertaCttAlertConfig.transitionDuration = 250;

    this.quorumAlertConfig = new TemplateModalConfig<IContext, string, string>(this.quorumAlertRef);
    this.quorumAlertConfig.closeResult = "closed";
    this.quorumAlertConfig.size = 'mini';
    this.quorumAlertConfig.transition = 'fade';
    this.quorumAlertConfig.transitionDuration = 250;

    this.representantesAlertConfig = new TemplateModalConfig<IContext, string, string>(this.representantesAlertRef);
    this.representantesAlertConfig.closeResult = "closed";
    this.representantesAlertConfig.size = 'small';
    this.representantesAlertConfig.transition = 'fade';
    this.representantesAlertConfig.transitionDuration = 250;

    this.deleteAttachmentAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAttachmentAlertRef);
    this.deleteAttachmentAlertConfig.closeResult = "closed";
    this.deleteAttachmentAlertConfig.size = 'mini';
    this.deleteAttachmentAlertConfig.transition = 'fade';
    this.deleteAttachmentAlertConfig.transitionDuration = 250;

    this.confAtaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.confAtaAlertRef);
    this.confAtaAlertConfig.closeResult = "closed";
    this.confAtaAlertConfig.size = 'mini';
    this.confAtaAlertConfig.transition = 'fade';
    this.confAtaAlertConfig.transitionDuration = 250;

    this.mapaReuAlertConfig = new TemplateModalConfig<IContext, string, string>(this.mapaReuAlertRef);
    this.mapaReuAlertConfig.closeResult = "closed";
    this.mapaReuAlertConfig.size = 'mini';
    this.mapaReuAlertConfig.transition = 'fade';
    this.mapaReuAlertConfig.transitionDuration = 250;

    this.assembChangedAlertConfig = new TemplateModalConfig<IContext, string, string>(this.assembChangedAlertRef);
    this.assembChangedAlertConfig.closeResult = "closed";
    this.assembChangedAlertConfig.size = 'small';
    this.assembChangedAlertConfig.transition = 'fade';
    this.assembChangedAlertConfig.transitionDuration = 250;

    this.removerAtaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.removerAtaAlertRef);
    this.removerAtaAlertConfig.isClosable = false;
    this.removerAtaAlertConfig.closeResult = "closed";
    this.removerAtaAlertConfig.size = 'small';
    this.removerAtaAlertConfig.transition = 'fade';
    this.removerAtaAlertConfig.transitionDuration = 250;

    this.removerRelAssembleiaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.removerRelAssembleiaAlertRef);
    this.removerRelAssembleiaAlertConfig.isClosable = false;
    this.removerRelAssembleiaAlertConfig.closeResult = "closed";
    this.removerRelAssembleiaAlertConfig.size = 'small';
    this.removerRelAssembleiaAlertConfig.transition = 'fade';
    this.removerRelAssembleiaAlertConfig.transitionDuration = 250;

    this.cancelAssembAlertConfig = new TemplateModalConfig<IContext, string, string>(this.cancelAssembAlertRef);
    this.cancelAssembAlertConfig.isClosable = false;
    this.cancelAssembAlertConfig.closeResult = "closed";
    this.cancelAssembAlertConfig.size = 'small';
    this.cancelAssembAlertConfig.transition = 'fade';
    this.cancelAssembAlertConfig.transitionDuration = 250;

    this.fornecedorAlertConfig = new TemplateModalConfig<IContext, string, string>(this.fornecedorAlertRef);
    this.fornecedorAlertConfig.closeResult = "closed";
    this.fornecedorAlertConfig.size = 'mini';
    this.fornecedorAlertConfig.transition = 'fade';
    this.fornecedorAlertConfig.transitionDuration = 250;

    this.representanteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.representanteAlertRef);
    this.representanteAlertConfig.closeResult = "closed";
    this.representanteAlertConfig.size = 'mini';
    this.representanteAlertConfig.transition = 'fade';
    this.representanteAlertConfig.transitionDuration = 250;

    this.dataDividasAlertConfig = new TemplateModalConfig<IContext, string, string>(this.dataDividasAlertRef);
    this.dataDividasAlertConfig.closeResult = "closed";
    this.dataDividasAlertConfig.size = 'mini';
    this.dataDividasAlertConfig.transition = 'fade';
    this.dataDividasAlertConfig.transitionDuration = 250;

    this.ataUploadedAlertConfig = new TemplateModalConfig<IContext, string, string>(this.ataUploadedAlertRef);
    this.ataUploadedAlertConfig.isClosable = false;
    this.ataUploadedAlertConfig.closeResult = "closed";
    this.ataUploadedAlertConfig.size = 'normal';  // 'mini';
    this.ataUploadedAlertConfig.transition = 'fade';
    this.ataUploadedAlertConfig.transitionDuration = 250;

    this.editMapaReuniaoConfig = new TemplateModalConfig<IContext, string, string>(this.editMapaReuniaoAlertRef);
    this.editMapaReuniaoConfig.isClosable = false;
    this.editMapaReuniaoConfig.closeResult = "closed";
    this.editMapaReuniaoConfig.size = 'small';
    this.editMapaReuniaoConfig.transition = 'fade';
    this.editMapaReuniaoConfig.transitionDuration = 250;

    this.orcamentoPickerAlertConfig = new TemplateModalConfig<IContext, string, string>(this.orcamentoPickerAlertRef);
    this.orcamentoPickerAlertConfig.isClosable = false;
    this.orcamentoPickerAlertConfig.closeResult = "closed";
    this.orcamentoPickerAlertConfig.size = 'small';
    this.orcamentoPickerAlertConfig.transition = 'fade';
    this.orcamentoPickerAlertConfig.transitionDuration = 250;
    this.editMapaReuniaoConfig.transitionDuration = 250;

    this.alertAvisosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAvisosAlertRef);
    this.alertAvisosAlertConfig.isClosable = false;
    this.alertAvisosAlertConfig.closeResult = "closed";
    this.alertAvisosAlertConfig.size = 'mini';
    this.alertAvisosAlertConfig.transition = 'fade';
    this.alertAvisosAlertConfig.transitionDuration = 250;

    this.concluirAtaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.concluirAtaAlertRef);
    this.concluirAtaAlertConfig.isClosable = true;
    this.concluirAtaAlertConfig.closeResult = "closed";
    this.concluirAtaAlertConfig.size = 'small';
    this.concluirAtaAlertConfig.transition = 'fade';
    this.concluirAtaAlertConfig.transitionDuration = 250;
    this.alertAvisosAlertConfig.transitionDuration = 250;

    this.todoActionsModalConfig = new TemplateModalConfig<IContext, string, string>(this.todoActionsRef);
    this.todoActionsModalConfig.isClosable = false;
    this.todoActionsModalConfig.closeResult = "closed";
    this.todoActionsModalConfig.size = 'small';
    this.todoActionsModalConfig.transition = 'fade';
    this.todoActionsModalConfig.transitionDuration = 250;
    this.alertAvisosAlertConfig.transitionDuration = 250;

    this.alertFRConfig = new TemplateModalConfig<IContext, string, string>(this.alertFRRef);
    this.alertFRConfig.isClosable = true;
    this.alertFRConfig.closeResult = "closed";
    this.alertFRConfig.size = 'mini';
    this.alertFRConfig.transition = 'fade';
    this.alertFRConfig.transitionDuration = 250;
    this.alertAvisosAlertConfig.transitionDuration = 250;

    this.permilagemConfig = new TemplateModalConfig<IContext, string, string>(this.permilagemAlertRef);
    this.permilagemConfig.isClosable = true;
    this.permilagemConfig.closeResult = "closed";
    this.permilagemConfig.size = 'mini';
    this.permilagemConfig.transition = 'fade';
    this.permilagemConfig.transitionDuration = 250;

    this.revertEstadoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.revertEstadoAlertRef);
    this.revertEstadoAlertConfig.isClosable = true;
    this.revertEstadoAlertConfig.closeResult = "closed";
    this.revertEstadoAlertConfig.size = 'small';
    this.revertEstadoAlertConfig.transition = 'fade';
    this.revertEstadoAlertConfig.transitionDuration = 250;

    this.motivoSemDespesaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.motivoSemDespesaAlertRef);
    this.motivoSemDespesaAlertConfig.isClosable = false;
    this.motivoSemDespesaAlertConfig.closeResult = 'closed';
    this.motivoSemDespesaAlertConfig.size = 'small';
    this.motivoSemDespesaAlertConfig.transition = 'fade up';
    this.motivoSemDespesaAlertConfig.transitionDuration = 400;

    this.geralForm.get('extraordinaria').valueChanges.subscribe(newVal => {
      this.extraordinaria = newVal;
      this.extraChanged();
    });

    this.geralForm.get('estado')
      .valueChanges
      .pipe(pairwise())
      .subscribe(async ([prev, next]) => {
        if (!this.details) {
          this.geralForm.patchValue({ estado: prev });
        }
        if (prev != next) {
          let proceed = true;
          if (next == 'CANCELADA' && !this.asssembleiaCancelada) {
            proceed = await this.formSubmitted('cancelar-assembleia');
          } else if (next == 'ATIVO' && this.asssembleiaCancelada) {
            proceed = await this.formSubmitted('activar-assembleia');
          }
          if (!proceed) {
            this.geralForm.patchValue({ estado: prev });
          }
        }
      });

  }

  presentAlert() {
    if (this.ordensTrabalhos.filter(el => (el.checked)).length === 0) {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
      return;
    }

    this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => {
        this.ordensTrabalhos = this.ordensTrabalhos.filter(el => (!el.checked));
      })
      .onDeny(() => { });
  }

  public animate(transitionName: string = "fade up") {
    this.transitionController.animate(
      new Transition(transitionName, 400, TransitionDirection.In));
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.cod_condominio); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
      if (query) {
        this.condominiosTimer = setTimeout(() => {
          this.api.getAllCondominios(query).subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }, 400);
      } else {
        this.api.getAllCondominios('NULL').subscribe(res => {
          if (res.success) {
            return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
          } else {
            return resolve([]);
          }
        });
      }
    });
  };

  setAtaConcluida() {
    this.api.saveAtaConcluida(this.details['id_assembleia'], this.details.ata_concluida).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (this.compState && this.compState.hasOwnProperty('init')) {
          this.compState.init.ata['ata_concluida'] = this.details.ata_concluida;
          this.compState.init = JSON.parse(JSON.stringify(this.compState.init));
        }
      }
    }, err => { });
  }

  tiposAnexoOrig: Array<TipoAnexoConfigList> = [];
  ataOrigObj = null;
  hasAta = false;
  savedAsClosed = false;
  copiaAtaEnviada = false;
  prevQuorum = 0;
  prevReuniaoRealizada = false;
  fetchingDetails = false;
  getDetails() {

    let req = [
      this.getAssembleiaDetails(),
      this.getAssembleiasConfigs(),
      this.getEmailLogs()
    ];

    this.startApiSub('GET_DETAILS');
    Promise.all<GetAssembleiasDetailsAPI['data'] | GetAssembleiasConfigsAPI['data'] | boolean>(req).then((resArr) => {
      let assembDetails = resArr[0] as GetAssembleiasDetailsAPI['data'];
      let assembConfig = resArr[1] as GetAssembleiasConfigsAPI['data'];
      this.handleGetAssembleiaDetails(assembDetails, assembConfig.ordemTrabalhosConfig);
      this.handleGetAssembleiasConfigs(assembConfig);

      this.compState.init = this.getCompState();
      if (this.openAnexos) {
        this.openAnexos = false;
        this.openAnexosModal();
      } else if (this.anchorAction) {
        setTimeout(() => {
          this.executeAnchorAction();
        })
      } else if (this.queryParams) {
        setTimeout(() => {
          this.executeQueryAction();
        })
      }

      this.finishApiSub('GET_DETAILS');
    }).catch(err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.finishApiSub('GET_DETAILS');
    })

  }

  handleGetAssembleiaDetails(assembData: GetAssembleiasDetailsAPI['data'], ordemTrabalhosConfig: Array<OrdemTrabalhoConfigDetailed>) {
    this.loadDetails(assembData);
    this.loadOrdensTrabalhos(ordemTrabalhosConfig);
    this.loadPresencas(assembData ? assembData.fraccoes : []);

    this.restoreForm('geral');
  }

  loadDetails(assembData: GetAssembleiasDetailsAPI['data']) {
    if (assembData == null) return;

    this.details = assembData.assembleia;
    this.mapaReuniao = assembData.mapa_reuniao;

    this.editingUsername = this.details ? this.details.editingUsername : null;

    this.condominioNIF = (this.details.n_contribuinte) ? this.details.n_contribuinte : null;

    this.regPresencasAnexosOpts.find(it => it.value === 'LISTA_PRESENCAS').hasAnexoUploaded = (this.details.hasOwnProperty('presencas_ficheiro') && !!this.details.presencas_ficheiro);
    this.regPresencasAnexosOpts.find(it => it.value === 'PROCURACOES').hasAnexoUploaded = (this.details.hasOwnProperty('representantes_ficheiro') && !!this.details.representantes_ficheiro);
    this.regPresencasAnexosOpts.find(it => it.value === 'LISTA_PRESENCAS_PROCURACOES').hasAnexoUploaded = (this.details.hasOwnProperty('presencas_representantes_ficheiro') && !!this.details.presencas_representantes_ficheiro);

    this.savedAsClosed = !!this.details.ata_concluida;

    this.ataEditEnabled = this.details.ata_concluida == 1 || this.details.cancelada == 1;

    this.asssembleiaCancelada = this.details.cancelada == 1;

    this.ataConcluidaPrev = this.details.ata_concluida == 1;

    this.autorConvocatoria = assembData.assembleia.autor_convocatoria;
    this.convEnviada = (this.details.conv_enviada == 1 || (assembData.mapa_reuniao && assembData.mapa_reuniao.data_convocatoria != null) || this.autorConvocatoria != 1);
    this.reuniaoRealizada = this.details.reuniao_realizada == 1;
    this.prevReuniaoRealizada = this.reuniaoRealizada;
    this.convOrdensTrabalho = (assembData.assembleia.conv_ordens_trabalho) ? JSON.parse(assembData.assembleia.conv_ordens_trabalho) : [];

    this.copiaAtaEnviada = (assembData.mapa_reuniao && assembData.mapa_reuniao.copia_ata != null);
    this.isEntregueParaAssinatura = assembData.mapa_reuniao && assembData.mapa_reuniao.entrega_assinatura !== null;

    this.assembleiaRealizadaPor = assembData.mapa_reuniao ? assembData.mapa_reuniao.utilizador_id : null;

    this.contasList = assembData.contas.map(el => {
      el['checked'] = false;
      return el;
    });

    if (this.details.hasOwnProperty('num_ata') && this.details.num_ata && this.details.num_folha && this.details.num_pag) {
      this.ataNum = Number(this.details.num_ata);
      this.folhaNum = Number(this.details.num_folha);
    } else if (assembData.prev_ata && Array.isArray(assembData.prev_ata) && assembData.prev_ata.length > 0) {
      this.ataNum = Number(assembData.prev_ata[0].num_ata) + 1;
      this.folhaNum = Number(assembData.prev_ata[0].num_folha) + Number(assembData.prev_ata[0].num_pag) + 1;
    } else {
      this.ataNum = this.minAtaNum;
      this.folhaNum = this.minAtaPag;
    }

    // CHECK PAG LIMITES
    if (this.folhaNum < this.minAtaPag || this.folhaNum > this.maxAtaPag) this.folhaNum = this.minAtaPag;

    this.cod_condominio = { name: this.details.cod + ' - ' + this.details.nome_condominio, value: { cod: this.details.cod, nome: this.details.nome_condominio, id: this.details.id_condominio } };
    this.codCondominioChanged();

    if (this.details.ata) {
      this.hasAta = true;

      this.ataOrigObj = JSON.parse(this.details.ata as unknown as string);
      if (this.ataOrigObj && this.ataOrigObj.introAta) {
        this.introAta = this.ataOrigObj['introAta'];
      }
      this.anexosAta = [this.ataOrigObj['anexosAta']];
    }


    this.emailsOficiais = assembData.emailsOficiais;


    this.sortRegAtividade();
  }

  loadOrdensTrabalhos(ordemTrabalhosConfig: Array<OrdemTrabalhoConfigDetailed> = null) {
    if (ordemTrabalhosConfig) this.ordemTrabalhosConfig = ordemTrabalhosConfig;

    let ordens = this.details ? this.details.ordemTrabalhos : [];
    if (this.details && this.details.ata_concluida === 1) {
      ordens.sort((a, b) => {
        let diversoDiff = a.is_diverso - b.is_diverso;
        if (diversoDiff != 0) return diversoDiff;

        let ordemDiff = a.ordem - b.ordem;
        return ordemDiff;
      });
    }


    // Ordens Trabalho
    let newOrdens = ordens.filter(el => el.is_diverso == 0).map(ot => {
      let ordemConfig = this.ordemTrabalhosConfig.find(conf => conf.id === ot.id_ordem_trabalho);
      let ordem = this.createOrdemTrabalhoList(ot, ordemConfig);
      return ordem;
    })

    this.ordensTrabalhos.forEach((ordem, i) => {
      if (i < newOrdens.length) {
        Object.assign(ordem, newOrdens[i]);
      }
    });
    let diff = this.ordensTrabalhos.length - newOrdens.length;
    if (diff > 0) {
      this.ordensTrabalhos.splice(newOrdens.length);
    } else if (diff < 0) {
      this.ordensTrabalhos = this.ordensTrabalhos.concat(newOrdens.slice(this.ordensTrabalhos.length));
    }


    // Assuntos Diversos
    let newOrdensDiverso = ordens.filter(el => el.is_diverso == 1).map(ot => {
      let ordemConfig = this.ordemTrabalhosConfig.find(conf => conf.id === ot.id_ordem_trabalho);
      let ordem = this.createOrdemTrabalhoDiversos(ot, ordemConfig);
      return ordem;
    });

    this.assuntosDiversosRespObj.forEach((ordem, i) => {
      if (i < newOrdensDiverso.length) {
        Object.assign(ordem, newOrdensDiverso[i]);
      }
    });
    diff = this.assuntosDiversosRespObj.length - newOrdensDiverso.length;
    if (diff > 0) {
      this.assuntosDiversosRespObj.splice(newOrdensDiverso.length);
    } else if (diff < 0) {
      this.assuntosDiversosRespObj = this.assuntosDiversosRespObj.concat(newOrdensDiverso.slice(this.assuntosDiversosRespObj.length));
    }



    if (this.ataOrigObj && this.ataOrigObj.hasOwnProperty('assDiversosTextoFinal') && this.ataOrigObj.assDiversosTextoFinal) {
      this.assuntosDiversosTextoInicial = this.ataOrigObj.assDiversosTextoFinal;
    } else {
      this.assuntosDiversosTextoInicial = null;
    }
    this.assuntosDiversosTextoInicialOrig = this.assuntosDiversosTextoInicial;
  }

  loadPresencas(fraccoes: GetAssembleiasDetailsAPI['data']['fraccoes']) {
    if (!this.details) return;
    this.prevQuorum = 0;
    if (this.details.assembleiaPresencas && this.details.assembleiaPresencas.length) {
      this.presencasList = this.convertPresencasToList(this.details.assembleiaPresencas);

      this.presencasList.forEach(el => {
        if (el.present) this.prevQuorum += el.permilage;
      });

      this.isCreatePresencas = false;
      if (this.introAtaOrig) {
        this.tabsObjDef.find(el => el.key === 'ata').disabled = false;
      }

      if (!this.reuniaoRealizada) {
        this.reuniaoRealizada = !!this.presencasList.find(el => el.present);
        this.prevReuniaoRealizada = this.reuniaoRealizada;
      }
    } else {
      this.presencasList = fraccoes.map(el => {
        let aux: AssembleiasPresencasList = {
          id: null,
          cod_fracao: el.cod_fraccao,
          nome_fracao: el.nome_fraccao,
          nome_condomino: el.condomino,
          cod_condomino: el.cod_pagador,
          cod_representative: null,
          free_representative_name: null,
          cod_representative_name: null,
          permilage: Number(el.permilagem),
          signature: false,
          present: false,
          id_assembleia: this.details.id_assembleia,
        };
        return aux;
      });
    }

    this.presencasList = this.presencasList.sort((a, b) => {
      return a.cod_fracao.length - b.cod_fracao.length || a.cod_fracao.localeCompare(b.cod_fracao);
    });
    this.computePresencasTotal();
  }

  convertPresencasToList(presencas: Array<AssembleiasPresencas>): Array<AssembleiasPresencasList> {
    return presencas.map(el => { return { ...el, permilage: parseFloat(el.permilage), present: el.present == 1, signature: el.signature == 1 } });
  }
  convertListToPresencas(): Array<AssembleiasPresencas> {
    return this.presencasList.map(el => { return { ...el, permilage: el.permilage.toString(), present: el.present ? 1 : 0, signature: el.signature ? 1 : 0 } });
  }

  handleGetAssembleiasConfigs(assembConfigs: GetAssembleiasConfigsAPI['data']) {
    assembConfigs.ordemTrabalhosConfig = assembConfigs.ordemTrabalhosConfig.filter(el => el.active == 1);
    this.ordemTrabOpts = assembConfigs.ordemTrabalhosConfig.map(el => { return { name: el.descricao, value: el, checked: false } });

    this.procuracaoModel = assembConfigs.assembleia_docs.find(el => (el.tipo === 'PROCURACAO'));
    this.convocatoriaModel = assembConfigs.assembleia_docs.find(el => (el.tipo === 'CONVOCATORIA'));
    this.convocatoriaGeralModel = assembConfigs.assembleia_docs.find(el => (el.tipo === 'CONVOCATORIA_GERAL'));
    this.ataIntroModel = assembConfigs.assembleia_docs.find(el => (el.tipo === 'ATA_INTRODUCAO'));
    this.ataCartaModel = assembConfigs.assembleia_docs.find(el => (el.tipo === 'ATA_CARTA'));
    if (this.ataCartaModel.texto_inicial) this.ataCartaModel.texto_inicial = this.ataCartaModel.texto_inicial.replace(/\n/g, '<br>');
    this.introAtaOrig = this.ataIntroModel.texto_inicial;
    this.assuntosDiversosTextoInicialOrig = assembConfigs.ordemTrabalhosConfig.find(el => (el.label === 'ASSUNTOS_DIVERSOS')).texto_inicial;
    this.assuntosDiversosOrig = assembConfigs.ordemTrabalhosConfig.find(el => (el.label === 'ASSUNTOS_DIVERSOS')).texto_final;
    this.ataConclusaoModel = assembConfigs.assembleia_docs.find(el => (el.tipo === 'ATA_CONCLUSAO_ANEXOS'));
    this.anexosAtaOrig = (this.ataConclusaoModel) ? [this.ataConclusaoModel.texto_inicial] : [];


    this.setIntroOrig();



    this.tiposAnexoOrig = assembConfigs.tipos_anexo.filter(el => el.id != 0).map(el => {
      let isInAta = this.ordensTrabalhos.findIndex(ot => ot.tipo_anexo_id === el.id && ot.resps.findIndex(resp => resp.anexo_id != null) != -1) != -1;

      if (!isInAta) {
        isInAta = this.assuntosDiversosRespObj.findIndex(ot => ot.tipo_anexo_id == el.id && ot.anexo_id != null) != -1;
      }

      let tipoAnexo: TipoAnexoConfigList = {
        checked: isInAta,
        uploaded: isInAta,
        custom: true,
        id: el.id,
        tipo: el.tipo,
        name: el.name,
        ordem: el.ordem,
        obs: el.obs
      }
      return tipoAnexo;
    }).sort((a, b) => a.ordem - b.ordem);

    this.setAnexosIndicators();

    if (this.ataOrigObj && this.ataOrigObj.hasOwnProperty('anexosAta') && !!this.ataOrigObj.anexosAta) {
      this.anexosAta = [this.ataOrigObj.anexosAta];
    } else {
      this.anexosAta = this.anexosAtaOrig;
      this.setAnexosString();
    }

    this.fetchedOrdemTrabalhos = true;
    this.ataTabDisabled = false;
    if (!this.isCreatePresencas) this.tabsObjDef.find(el => el.key === 'ata').disabled = false;

    setTimeout(() => {
      this.getApresentacaoContas();
      this.getOrcamentos();
    });
  }

  getAssembleiaDetails(): Promise<GetAssembleiasDetailsAPI['data']> {
    return new Promise((resolve, reject) => {
      let id = this.route.snapshot.params.id;
      if (this.details && this.details.hasOwnProperty('id_assembleia')) {
        id = this.details.id_assembleia;
      }
      if (id == null || !Number.isFinite(parseFloat(id))) {
        resolve(null);
        return;
      }

      this.fetchingDetails = true;
      // GET ASSEMBLEIAS DETAILS
      this.api.getAssembleiaDetails(id).subscribe(async res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
        this.fetchingDetails = false;
      }, err => {
        this.fetchingDetails = false;
        reject(null);
      });
    });
  }

  getCompState(): { convocatoria, presencas, ata } {
    let ordensTrabalho: Array<{ descricao, exercicio, anexo_id, respSel, resp }> = [];

    this.ordensTrabalhos.forEach(ot => {
      ot.resps.forEach((resp, index_r) => {

        let exercicio = null;
        if (ot.selAnos && ot.selAnos.length && index_r < ot.selAnos.length) {
          exercicio = parseInt(ot.selAnos[index_r].ano);
        }
        ordensTrabalho.push({
          descricao: ot.descricao,
          exercicio: exercicio,
          respSel: resp.respSel,
          resp: resp.resp,
          anexo_id: resp.anexo_id,
        });
      })
    });

    this.assuntosDiversosRespObj.forEach(ot => {
      ordensTrabalho.push({
        descricao: ot.descricao,
        exercicio: null,
        respSel: ot.respSel,
        resp: ot.resp,
        anexo_id: ot.anexo_id,
      });
    })

    return JSON.parse(JSON.stringify({
      convocatoria: {
        dt: this.geralForm.get('dt').value,
        dt_alter: this.geralForm.get('dt_alter').value,
        dt_fim: this.geralForm.get('dt_fim').value,
        extraordinaria: this.geralForm.get('extraordinaria').value,
        ficheiro: this.geralForm.get('ficheiro').value,
        morada: this.geralForm.get('morada').value,
        postalCode: this.geralForm.get('postalCode').value,
        locality: this.geralForm.get('locality').value,
        ordensTrabalhos: this.ordensTrabalhos.map(el => {
          return {
            descricao: el.descricao,
            checked: el.checked,
            selAnos: el.selAnos,
            resps: el.resps.map(resp => {
              return {
                anexo_id: resp.anexo_id,
                respSel: resp.respSel,
                resp: resp.resp,
              };
            })
          }
        }),
      },
      presencas: {
        presencasList: this.convertListToPresencas(),
      },
      ata: {
        ata: {
          intro: this.introAta,
          ordensTrabalho: ordensTrabalho,
          conclusao: this.anexosAta[0],
        },
        numAta: this.ataNum ? this.ataNum.toString() : null,
        numFolha: this.folhaNum ? this.folhaNum.toString() : null,
        ata_concluida: this.details ? this.details.ata_concluida : null,
      }
    }));
  }

  codCondominioChanged() {
    this.getCondAssembleiasDetails();
    // this.saveGlobalState();
  }
  getEmailLogs(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let id = this.route.snapshot.params.id;
      if (this.details && this.details.hasOwnProperty('id_assembleia')) {
        id = this.details.id_assembleia;
      }
      // GET EMAIL REGISTRATION
      this.api.getEmailLogs('ASSEMBLEIAS', id).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.regEmails = [];
          let logs = res.data.map(item => {
            try {
              item.email_list = (item.email_list) ? JSON.parse(item.email_list) : [];
            } catch (e) {
              item.email_list = [];
            }

            return item;
          });
          logs.forEach(item => {
            let obj = null;
            try {
              obj = JSON.parse(item.obj);
            } catch (e) { }

            this.regEmails.push({
              description: item.descricao,
              emailList: item.email_list,
              name: item.first_name + ' ' + item.last_name,
              date: new Date(item.data),
              msg: item.msg,
              obj: obj,
            });
          });
          this.sortRegAtividade();
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => { resolve(false); });
    });
  }

  

  sortRegAtividadeMapaReuniao() {
    if (!this.details) return;
    this.regAtividadeMapaReuniao = [];
    if (this.mapaReuniaoFilters.tipo !== 'COMMENTS') {
      this.regEmails.forEach(el => {
        this.regAtividadeMapaReuniao.push({
          ...el,
          type: 'ACTIVITY'
        });
      });
    }
    if (this.mapaReuniaoFilters.tipo !== 'PROCESSES') {
      this.details.comentarios.forEach(el => {
        this.regAtividadeMapaReuniao.push({
          ...el,
          date: this.utils.getDate(el.date),
          type: 'COMMENT'
        });
      });
    }
    
    this.regAtividadeMapaReuniao.sort((a,b) => b.date.getTime() - a.date.getTime());
  }
  
  sortRegAtividade() {
    if (!this.details) return;
    this.regAtividade = [];
    if (this.regAtividadeFilters.tipo !== 'COMMENTS') {
      this.regEmails.forEach(el => {
        this.regAtividade.push({
          ...el,
          type: 'ACTIVITY'
        });
      });
    }
    if (this.regAtividadeFilters.tipo !== 'PROCESSES') {
      this.details.comentarios.forEach(el => {
        this.regAtividade.push({
          ...el,
          date: this.utils.getDate(el.date),
          type: 'COMMENT'
        });
      });
    }
    
    this.regAtividade.sort((a,b) => b.date.getTime() - a.date.getTime());

    this.hasRegEmails = (this.regEmails.length > 0 || this.details.comentarios.length > 0);
    this.tabsObjDef.find(el => el.key === 'logs').disabled = !this.hasRegEmails;
  }

  reuniaoRealizadaChanged() {
    if (!this.reuniaoRealizada && this.prevQuorum >= 250 && !this.userSession.isAdmin()) {
      this.toastr.error('Não tem permissões para reverter o estado de ata realizada. Quorum registado superior a 250,00‰.', 'Alerta', { timeOut: 4000 });
      setTimeout(() => { this.reuniaoRealizada = true; }, 100);
    }
  }

  fraccaoListOrig = [];
  fraccaoList: Array<FraccaoList> = [];
  fetchingFraccoes = false;
  getCondAssembleiasDetails() {
    return new Promise(resolve => {

      let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : ((this.cod_condominio && this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : null);

      if (this.fetchingFraccoes || !cod) return;

      this.condominioCod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : ((this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : null);
      this.condominioNome = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.nome : ((this.cod_condominio.hasOwnProperty('nome')) ? this.cod_condominio.nome : null);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      this.fetchingFraccoes = true;

      let req = [
        this.api.getFraccoesCircular(cod),
        this.api.getCondominiumDetailsByCod(cod),
      ];
      forkJoin(req).subscribe(res => {
        if (res[0].hasOwnProperty('success') && res[0].success) {

          // SORT BY LENGTH AND COD
          this.fraccaoListOrig = res[0].data.sort((a, b) => {
            return a.cod.length - b.cod.length || a.cod.localeCompare(b.cod);
          });

          this.fraccaoList = [];
          this.fraccaoListOrig.forEach(el => {
            let condMoradaAux = el.morada;

            el.morada_proprietario_coresp = this.utils.formatEmptyMoradaObject(el.morada_proprietario_coresp);
            let propMoradaAux = (el.morada_proprietario_coresp && el.morada_proprietario_coresp.replace('NEW_LINE', '').trim() && el.morada_proprietario_coresp !== '{"address":null,"postalCode":null,"locality":null}' && el.morada_proprietario_coresp !== '{"address":"","postalCode":"","locality":""}') ? el.morada_proprietario_coresp : el.morada_proprietario;

            // HANDLE OBJECT MORADA - CONDOMINIO
            try {
              let aux = '';
              let addressObj = JSON.parse(condMoradaAux);
              Object.keys(addressObj).forEach((key, i) => {
                if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE');
              });

              condMoradaAux = aux;
            } catch (e) { }

            // HANDLE OBJECT MORADA - CONDOMINIO
            try {
              let aux = '';
              let addressObj = JSON.parse(propMoradaAux);
              Object.keys(addressObj).forEach((key, i) => {
                if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE');
              });

              propMoradaAux = aux;
            } catch (e) { }

            let aux: FraccaoList = {
              checked: false,
              disabled: false,
              cod_condominio: el.cod_condominio,
              cod_fraccao: el.cod,
              zona: el.zona_nome,
              cod_zona: el.cod_zona,
              fraccao: el.cod + ' - ' + el.fraccao_nome,
              proprietario: el.nome_proprietario,
              cod_proprietario: el.cod_proprietario,
              morada_proprietario: (propMoradaAux) ? propMoradaAux.split(/NEW_LINE/g) : [],
              inquilino: el.nome_inquilino,
              morada_inquilino: (el.morada_inquilino) ? el.morada_inquilino.split(/NEW_LINE/g) : null,
              moradaCondominio: (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [],
              email_proprietario: el.email_proprietario,
              email_proprietario_obs: el.email_proprietario_obs,
              email_proprietario_2: el.email_proprietario_2,
              email_proprietario_2_obs: el.email_proprietario_2_obs,
              email_inquilino: el.email_inquilino,
              permilagem: Number(el.permilagem),
              por_carta: (el.por_carta === '1'),
              por_email: (el.por_email === '1'),
              codEntidade: el.codEntidade,
              divida: 0,
              credito: 0,
            }

            this.fraccaoList.push(aux);
          });


          if (this.isCreate) {
            // HANDLE MORADA CONDOMINIO
            let condoMoradaAux = res[1].data.morada;
            try {
              let aux = '';
              let addressObj = JSON.parse(condoMoradaAux);

              if (addressObj.hasOwnProperty('address')) {
                let morada = addressObj.address.replace(/NEW_LINE/g, '\n');
                let postalCode = (addressObj.postalCode) ? addressObj.postalCode.replace(/NEW_LINE/g, '\n') : null;
                let locality = (addressObj.locality) ? addressObj.locality.replace(/NEW_LINE/g, '\n') : null;
                this.condominioMorada = {
                  morada: morada,
                  postalCode: postalCode,
                  locality: locality,
                }
                this.geralForm.patchValue({
                  morada: morada,
                  postalCode: postalCode,
                  locality: locality,
                });
              }
            } catch (e) {
              this.geralForm.patchValue({
                morada: res[1].data.morada.replace(/NEW_LINE/g, '\n'),
                postalCode: null,
                locality: null,
              });
              this.condominioMorada = {
                morada: res[1].data.morada.replace(/NEW_LINE/g, '\n'),
                postalCode: null,
                locality: null,
              }
            }
          }

          this.condominioNif = res[1].data.n_contribuinte;
          if (this.isCreate) {
            this.ordensTrabalhos = [];
            this.extraChanged(true);
          }
        } else {
          this.utils.apiErrorMsg(res);
          if (this.isCreate) {
            this.ordensTrabalhos = [];
          }
        }

        this.fetchingFraccoes = false;

        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(true);
      }, err => {
        this.fetchingFraccoes = false;

        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(false);
      });

    });
  }

  setIntroOrig() {
    if (this.isCreatePresencas) return;

    let moradaPredio = null;
    let data = this.geralForm.getRawValue();

    if (data && (data.postalCode !== null || data.locality !== null)) {
      // moradaPredio = data.morada.replace(/\n/g, ', ') + ', ' + ( (data.postalCode) ? data.postalCode : '' ) + ' ' + ( (data.locality) ? data.locality : '' );
      moradaPredio = data.morada.replace(/\n/g, ', ') + ', ' + ((data.locality) ? data.locality : '') + ', ' + ((data.postalCode) ? data.postalCode : '');
    } else {
      if (data.morada) moradaPredio = data.morada.replace(/\n/g, ', ');
    }

    let numConv = null;
    let dataAssemb = null;
    let horaAssemb = null;

    if ((Math.round(Number(this.totalPerm) * 100) / 100) > 500) { // 1ª CONVOCATORIA
      numConv = 'primeira';

      dataAssemb = formatDate(this.geralForm.get('dt').value, 'dd-MMMM-yyyy', this.locale);
      horaAssemb = formatDate(this.geralForm.get('dt').value, 'HH:mm', this.locale);
    } else {  // 2ª CONVOCATORIA
      numConv = 'segunda';

      dataAssemb = formatDate(this.geralForm.get('dt').value, 'dd-MMMM-yyyy', this.locale);
      horaAssemb = formatDate(this.geralForm.get('dt_alter').value, 'HH:mm', this.locale);
    }

    let aux = dataAssemb.split('-');
    let dia = aux[0]; let mes = aux[1]; let ano = aux[2];
    if (dia === '1') {
      dataAssemb = 'No primeiro dia do mês de ' + mes + ' de ' + this.utils.getNumberPorExtenso(parseInt(ano), false);
    } else {
      dataAssemb = 'Aos ' + this.utils.getNumberPorExtenso(parseInt(dia), false) + ' dias do mês de ' + mes + ' de ' + this.utils.getNumberPorExtenso(parseInt(ano), false);
    }
    horaAssemb = horaAssemb.replace(':', 'h');

    // SET INTRODUCTION VALUES
    if (this.introAtaOrig.indexOf('[[ MORADA_PREDIO ]]') !== -1) {
      this.introAtaOrig = this.introAtaOrig.replace(/\[\[ MORADA_PREDIO \]\]/g, moradaPredio);
    }
    if (this.introAtaOrig.indexOf('[[ DATA_ASSEMB ]]') !== -1) {
      this.introAtaOrig = this.introAtaOrig.replace(/\[\[ DATA_ASSEMB \]\]/g, dataAssemb);
    }
    if (this.introAtaOrig.indexOf('[[ HORA_ASSEMB ]]') !== -1) {
      this.introAtaOrig = this.introAtaOrig.replace(/\[\[ HORA_ASSEMB \]\]/g, horaAssemb);
    }
    if (this.introAtaOrig.indexOf('[[ NUM_CONVOC ]]') !== -1) {
      this.introAtaOrig = this.introAtaOrig.replace(/\[\[ NUM_CONVOC \]\]/g, numConv);
    }
  }

  ataVisited = false;
  presencasVisited = false;
  alertSeen = false;
  setTab(targetTab) {
    if (targetTab === 'ata' && !this.isCreate && !this.hasAta && !this.isCreatePresencas) {
      setTimeout(() => {
        if (this.alertSeen && !(this.details.hasOwnProperty('presencas_ficheiro') && this.details.presencas_ficheiro) && !(this.details.hasOwnProperty('presencas_representantes_ficheiro') && this.details.presencas_representantes_ficheiro)) {
          this.toastr.warning('A presente ata está dada como concluída mas não tem o anexo correspondente à lista de presenças submetido.', 'Alerta', { timeOut: 10000 });
          this.alertSeen = true;
        }
      }, 1000);

      this.selTab = targetTab;

      this.setIntroOrig();
      this.addFraccoes('INTRODUCAO_ATA', null, true);

      if (!this.introAta) this.introAta = this.introAtaOrig;

    } else {
      if (targetTab === 'presencas' && !this.isCreate) this.selTab = targetTab;

      if (targetTab === 'ata' && !this.isCreatePresencas && this.introAtaOrig) {
        this.selTab = targetTab;

        this.addFraccoes('INTRODUCAO_ATA', null, true);
      }

      if (targetTab === 'convocatorias') this.selTab = targetTab;
    }

    if (targetTab === 'logs' && this.hasRegEmails) this.selTab = targetTab;

    if (this.selTab === 'ata') this.ataVisited = true;
    if (this.selTab === 'presencas') this.presencasVisited = true;
  }

  restoreForm(targetTab) {
    switch (targetTab) {
      case 'geral':
        // let morada = null;
        // let postalCode = null;
        // let locality = null;
        // if (this.details && this.details.morada) {
        //   morada = this.details.morada;
        //   locality = this
        //   try {
        //     let aux = '';
        //     let addressObj = JSON.parse(this.details.morada);

        //     if (addressObj.hasOwnProperty('address')) {
        //       morada = addressObj.address.replace(/NEW_LINE/g, '\n');
        //       postalCode = (addressObj.postalCode) ? addressObj.postalCode.replace(/NEW_LINE/g, '\n') : null;
        //       locality = (addressObj.locality) ? addressObj.locality.replace(/NEW_LINE/g, '\n') : null;
        //     }
        //   } catch (e) {
        //     morada = this.details.morada.replace(/NEW_LINE/g, '\n');
        //     postalCode = null;
        //     locality = null;
        //   }
        // }
        if (this.details) {
          this.geralForm.patchValue({
            // cod_condominio: (this.details) ? this.details.cod_condominio : null,
            dt: this.utils.getDate(this.details.dt, this.details.hora),
            dt_alter: this.utils.getDate(this.details.dt_alter, this.details.hora_alter),
            dt_fim: this.utils.getDate(this.details.dt_fim, this.details.hora_fim),
            extraordinaria: this.details.extraordinaria.toString(),
            ficheiro: this.details.ficheiro,

            morada: this.details.morada,
            locality: this.details.localidade,
            postalCode: this.details.cod_postal,
            local: 'P',
            realizada_por: this.assembleiaRealizadaPor,
            responsavel_por: this.details.responsavel_por,
            criado_por: (this.details.criado_por) ? this.details.criado_por.toString() : null,
            estado: this.details.cancelada == 1 ? 'CANCELADA' : 'ATIVO',
          });
          this.defaultFilename = this.utils.getFileNameFormatted(this.utils.getFormatedDate(this.geralForm.get('dt').value) + '_' + this.details.cod + '_' + this.details.nome_condominio);
          this.extraordinaria = (this.details.extraordinaria === 1) ? '1' : '0';
        } else {
          this.geralForm.clearValidators();
          this.geralForm.patchValue({
            dt: null,
            dt_alter: null,
            dt_fim: null,
            extraordinaria: null,
            ficheiro: null,
            morada: this.condominioMorada.morada,
            postalCode: this.condominioMorada.postalCode,
            locality: this.condominioMorada.locality,
            local: 'P',
            realizada_por: null,
            criado_por: null,
            responsavel_por: null,
            estado: null,
          }, { emitEvent: false });
          this.geralForm.markAsPristine();
          this.autorConvocatoria = this.autorConvocatoriaOpts[0].value;
        }
        this.defaultFilename = this.geralForm.get('dt').value ? this.utils.getFileNameFormatted(this.utils.getFormatedDate(this.geralForm.get('dt').value)) : '';
        this.extraordinaria = null;
        break;
      case 'presencas': ;

        this.presencasList.forEach(el => { el.present = false; });
        this.computePresencasTotal();
        break;
    }
  }

  presentMapaReuniaoModal() {
    return new Promise(resolve => {
      this.mapaReuModalRef = this.modalService
        .open(this.mapaReuAlertConfig)
        .onApprove(() => {
          this.mapaReuModalRef = null;
          resolve(true);
        })
        .onDeny(() => {
          this.mapaReuModalRef = null;
          resolve(false);
        });
    });
  }

  convEnviadaChanged() {
    if (this.setConvEnviada) {
      this.convOrdensTrabalho = JSON.parse(JSON.stringify(this.ordensTrabalhos));
    } else {
      this.convOrdensTrabalho = (this.details && this.details.conv_ordens_trabalho) ? JSON.parse(this.details.conv_ordens_trabalho) : [];
    }
  }

  getRevertStateWarningMsg(prevState: AssembleiaState, newState: AssembleiaState): string {
    let prevStateIndex = this.assembleiaStateOrder.findIndex(el => el === prevState);
    let newStateIndex = this.assembleiaStateOrder.findIndex(el => el === newState);
    if (prevStateIndex == -1 || newStateIndex == -1) return null;

    let msg: string = '<span>';
    switch (prevState) {
      case 'COPIA_ATA':
        msg += '<b class="c-warning">Atenção</b>: A cópia ata já foi enviada.';
        break;
        case 'RECECAO_ATA':
        msg += '<b class="c-warning">Atenção</b>: A ata assinada já foi carregada.';
        break;
        case 'ENTREGUE_ASSINATURA':
        msg += '<b class="c-warning">Atenção</b>: A ata já foi entregue para assinatura.';
        break;
    
      default:
        return null;
    }

    if (newStateIndex < prevStateIndex) {
      let deleteAtaAssinadaIndex = this.assembleiaStateOrder.findIndex(el => el === 'RECECAO_ATA');
      if (newStateIndex < deleteAtaAssinadaIndex && deleteAtaAssinadaIndex <= prevStateIndex) {
        msg += " A reversão do estado da assembleia irá <b>eliminar a ata carregada</b> e o respetivo <b>registo de assinaturas</b>.";
      }
    }

    msg += "</span>";
    return msg;
  }

  assembChangedTitle = null;
  assembChangedMsg = null;
  regMsg = null;
  presentAtaChanged(newState: AssembleiaState = 'CONVOCATORIA'): Promise<string> {
    return new Promise(resolve => {
      this.regMsg = null;
      
      this.assembChangedTitle = 'Actualizar Ata';
      
      this.assembChangedMsg = '<span>';
      
      
      if (newState === 'CONVOCATORIA') {
        this.assembChangedMsg += "A presente ata está dada como concluída. Pretende voltar a editar a ata?";
      } else if (newState === 'ENTREGUE_ASSINATURA') {
        this.assembChangedMsg += "Pretende enviar a ata para assinatura?";
      }

      this.assembChangedMsg += '</span>';
      
      let prevState = this.getAssembleiaState(this.mapaReuniao);

      let warningMsg = this.getRevertStateWarningMsg(prevState, newState);
      if (warningMsg != null) {
        this.assembChangedMsg += '<span><br><br></span>' + warningMsg;
      }

      this.assembChangedModalRef = this.modalService
        .open(this.assembChangedAlertConfig)
        .onApprove(() => {
          this.assembChangedTitle = null;
          this.assembChangedMsg = null;
          this.submittingMotivo = false;

          resolve(this.regMsg);
        })
        .onDeny(() => {
          this.assembChangedTitle = null;
          this.assembChangedMsg = null;
          this.regMsg = null;
          this.submittingMotivo = false;

          resolve(null);
        });
    });
  }

  presentMissingAttachement() {
    return new Promise(resolve => {
      this.anexosMissingModalRef = this.modalService
        .open(this.anexosMissingAlertConfig)
        .onApprove(() => {
          this.missingAttachments = [];

          resolve(true);
        })
        .onDeny(() => {
          this.missingAttachments = [];

          this.details.ata_concluida = 0;
          this.ataConcluidaChanged();

          resolve(false);
        });
    });
  }

  assembleiaRealizadaPor: string = null;
  updateAssembleiaFuncionario(id_utilizador) {
    let utilizadorOpt = this.utilizadoresOpts.find(el => el.value == id_utilizador);
    this.geralForm.patchValue({
      realizada_por: (utilizadorOpt !== undefined) ? utilizadorOpt.value : null,
    });
  }

  submittingMotivo = false;
  submitMotivo() {
    this.submittingMotivo = true;
    setTimeout(() => { this.submittingMotivo = false; }, 3000);

    if (this.regMsg) this.assembChangedModalRef.approve();
  }

  missingAttachments = [];
  needAnexoPresencas = false;
  needAnexoProcuracoes = false;
  hasAllAttachmentsUploaded() {
    this.missingAttachments = [];
    for (let i = 0; i < this.ordensTrabalhos.length; i++) {
      const el = this.ordensTrabalhos[i];
      if (el.label === 'ASSUNTOS_DIVERSOS') continue;
      for (let index_r = 0; index_r < el.resps.length; index_r++) {
        const resp = el.resps[index_r];
        if (!resp.respSel || !resp.respSel.tipo_anexo_id || !resp.need_anexo_indicator || resp.anexo_id != null) continue;
        let tipoAnexo = this.tiposAnexoOrig.find(tipoAnexo => tipoAnexo.id === resp.respSel.tipo_anexo_id);
        if (tipoAnexo) this.missingAttachments.push({ descricao: tipoAnexo.name });
      }
    }
    for (let i = 0; i < this.assuntosDiversosRespObj.length; i++) {
      const el = this.assuntosDiversosRespObj[i];
      if (!el.respSel || !el.respSel.tipo_anexo_id || !el.need_anexo_indicator || el.anexo_id != null) continue;
      let tipoAnexo = this.tiposAnexoOrig.find(tipoAnexo => tipoAnexo.id === el.tipo_anexo_id);
      if (tipoAnexo) this.missingAttachments.push({ descricao: tipoAnexo.name });
    }

    return (this.missingAttachments.length === 0);
  }

  ataConcluidaPrev = false;
  motivoCancelAssemb = null;
  formSubmitted(targetTab, hidden = false, force = false, actRegMsg = null): Promise<boolean> {
    return new Promise(async (resolve) => {
      let data = null;
      let isReopened = false;
      let assembPresencasAPI = this.convertListToPresencas();
      switch (targetTab) {
        case 'geral':
          this.submittingForm = true;

          if (!this.geralForm.valid || !this.cod_condominio || this.fetchingDetails) {
            setTimeout(() => { this.submittingForm = false; }, 4000);
            return;
          }

          data = this.geralForm.getRawValue();

          let ata = undefined;

          if (this.isCreate) {
            // let today = new Date();
            let today = new Date(data.dt);
            today.setDate(today.getDate() + 30);
            this.dataLimite = new Date(today);

            let res = await this.presentMapaReuniaoModal();
            if (!res) return;

          } else if (!this.isCreatePresencas) {

            isReopened = (this.ataConcluidaPrev && !this.details.ata_concluida);
            if (((this.ataConcluidaPrev && this.details.ata_concluida) || isReopened)) {
              actRegMsg = await this.presentAtaChanged();
              if (!actRegMsg) return;
            }


            let ordensTrabalhos = this.ordensTrabalhos.map(el => {
              let aux = JSON.parse(JSON.stringify(el));
              delete aux.respOpts;
              return aux;
            });

            ata = {
              introAta: this.introAta,
              anexosAta: this.anexosAta[0],
              condominioNome: this.condominioNome,
              moradaCondominio: this.moradaCondominio,
              condominioNif: this.condominioNif
            }

            // SAVE ASSUNTOS DIVERSOS OBJECT IN ATA OBJECT
            if (Array.isArray(this.assuntosDiversosRespObj) && this.assuntosDiversosRespObj.length > 0) {
              ata['assDiversosObj'] = this.assuntosDiversosRespObj.filter(el => (el !== null)).map(el => {
                delete el.attachmentInfo;
                return el;
              });
              ata['assDiversosTextoFinal'] = this.assuntosDiversosTextoInicial;
            } else {
              ata['assDiversosObj'] = [];
              ata['assDiversosTextoFinal'] = null;
            }
          }

          if (!hidden) this.loading = true;

          let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
          let ordensTrabalhosToSave = this.getOrdensTrabalhosToSave();
          this.api.saveAssembleiaGeral((this.details) ? this.details.id_assembleia : null, cod, ordensTrabalhosToSave, data.dt, data.dt_alter, data.dt_fim, data.extraordinaria, data.ficheiro, data.morada, data.postalCode, data.locality, this.autorConvocatoria, ata, data.realizada_por, this.dataLimite, (this.convEnviada) ? '1' : '0', JSON.stringify(this.convOrdensTrabalho)).subscribe(res => {
            if (res.hasOwnProperty('success') && res['success']) {


              let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
              if (actRegMsg) {
                this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, (isReopened) ? 'Ata em elaboração' : 'Retoma de elaboração de ata', JSON.stringify([]), this.userSession.getUserId(), new Date(), actRegMsg).subscribe(res => {
                  if (res.hasOwnProperty('success') && res.success) {
                    this.regEmails = [{
                      description: (isReopened) ? 'Ata em elaboração' : 'Retoma de elaboração de ata',
                      emailList: [],
                      name: this.userSession.getUserFullName(),
                      date: new Date(),
                      msg: actRegMsg,
                    } as RegEmails].concat(this.regEmails);
                    this.sortRegAtividade();
                  }
                }, err => { });
              }

              let titulo = null;
              let descricao = `Condomínio: ${res.data.assembleia.cod} - ${res.data.assembleia.nome_condominio}, Data convocatória:  ${res.data.assembleia.dt} ${res.data.assembleia.hora}, Tipo: ${(data.extraordinaria === '0') ? 'Ordinária' : 'Extra'}.`;
              if (this.details) {
                // REGISTO ACTIVIDADES - UPDATE
                titulo = 'Assembleia Actualizada';
                this.api.saveRegistoActividade(res.data.assembleia.cod, null, null, titulo, descricao).subscribe(res => { }, err => { });
              } else {
                // REGISTO ACTIVIDADES - CREATE
                titulo = 'Assembleia Criada';
                this.api.saveRegistoActividade(res.data.assembleia.cod, null, null, titulo, descricao).subscribe(res => { }, err => { });

                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['assembleias/assembleias', res.data.assembleia.id_assembleia])
                // this.location.replaceState('/assembleias/assembleias/' + res.data.assembleia.id_assembleia);
                this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: res.data.assembleia.cod + ' - ' + res.data.assembleia.nome_condominio });
                return;  
              }

              // API RETURN SUCCESS
              this.details = { ...res.data.assembleia };
              this.assembleiaRealizadaPor = data.realizada_por;
              this.restoreForm('geral');

              this.regPresencasAnexosOpts.find(it => it.value === 'LISTA_PRESENCAS').hasAnexoUploaded = (this.details.hasOwnProperty('presencas_ficheiro') && !!this.details.presencas_ficheiro);
              this.regPresencasAnexosOpts.find(it => it.value === 'PROCURACOES').hasAnexoUploaded = (this.details.hasOwnProperty('representantes_ficheiro') && !!this.details.representantes_ficheiro);
              this.regPresencasAnexosOpts.find(it => it.value === 'LISTA_PRESENCAS_PROCURACOES').hasAnexoUploaded = (this.details.hasOwnProperty('presencas_representantes_ficheiro') && !!this.details.presencas_representantes_ficheiro);

              this.ataEditEnabled = !!this.details.ata_concluida;

              this.ataConcluidaPrev = !!this.details.ata_concluida;
              this.convEnviada = (this.details.conv_enviada == 1 || this.autorConvocatoria != 1);

              this.loadOrdensTrabalhos();
              this.loadPresencas(res.data.fraccoes);

              // UPDATE INIT STATE
              this.compState.init = this.getCompState();

              this.isCreate = false;
              this.tabsObjDef.find(el => el.key === 'presencas').disabled = false;
            } else {
              this.utils.apiErrorMsg(res);
            }
            this.submittingForm = false;
            this.loading = false;
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.submittingForm = false;
            this.loading = false;
          });
          break;
        case 'cancelar-assembleia':
          // CHECK PERMISSIONS AND ASSEMBLEIA STATUS 
          if (!this.userSession.isAdmin() && (this.details.reuniao_realizada == 1 || !this.isCreatePresencas)) {
            this.toastr.error('Não tem permissões para cancelar a presente assembleia de condomínios. A mesma foi realizada.', 'Alerta', { timeOut: 4000 });
            resolve(false);
            return;
          }

          // SHOW ALERT TO INSERT MOTIVO
          this.cancelAssembModalRef = this.modalService
            .open(this.cancelAssembAlertConfig)
            .onApprove(() => {
              this.submittingMotivo = false;
              this.motivoCancelAssemb = null;
              window.history.back();
              resolve(true);
            })
            .onDeny(() => {
              this.submittingMotivo = false;
              this.motivoCancelAssemb = null;
              resolve(false);
            });
          break;
        case 'activar-assembleia':
          // SHOW ALERT TO INSERT MOTIVO
          this.cancelAssembModalRef = this.modalService
            .open(this.cancelAssembAlertConfig)
            .onApprove(() => {
              this.submittingMotivo = false;
              this.motivoCancelAssemb = null;

              if (!this.asssembleiaCancelada) this.ataEditEnabled = !!this.details.ata_concluida;
              resolve(true);
            })
            .onDeny(() => {
              this.submittingMotivo = false;
              this.motivoCancelAssemb = null;
              resolve(false);
            });
          break;
        case 'presencas':
          if (this.details.ata_concluida == 1) {
            this.toastr.error('Não é possível submeter as presenças. A ata está dada como concluída.', 'Alerta');
            return;
          }

          if (!this.minPresencas) {
            let res = await this.presentQuorumModal();
            if (!res) return;
          }

          // isReopened = (this.ataConcluidaPrev && !this.details.ata_concluida);
          // if ((this.ataConcluidaPrev && this.details.ata_concluida) || isReopened) {
          //   actRegMsg = await this.presentAtaChanged();
          //   if (!actRegMsg) return;
          // }

          this.submittingForm = true;


          this.loading = true;
          this.api.updateAssembleiaPresencas(this.details.id_assembleia, assembPresencasAPI, this.details.ata_concluida, !!this.reuniaoRealizada ? 1 : 0).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {

              if (this.tabsObjDef.find(el => el.key === 'ata').disabled == true) {
                let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
                this.saveRegistoComunicacao('ASSEMBLEIAS', this.details.id_assembleia, cod, 'Início de Elaboração de Ata', [], this.userSession.getUserId(), new Date(), actRegMsg);
              }

              this.details = { ...res.data.assembleia };

              let titulo = null;
              let descricao = `Condomínio: ${this.details.cod} - ${this.details.nome_condominio}, Data convocatória:  ${this.details.dt} ${this.details.hora}, Tipo: ${(this.details.extraordinaria == 0) ? 'Ordinária' : 'Extra'}.`;
              if (this.details) {
                // REGISTO ACTIVIDADES - UPDATE REGISTO PRESENCAS
                titulo = 'Reg. Presenças de Assembleia Actualizado';
                this.api.saveRegistoActividade(this.details.cod, null, null, titulo, descricao).subscribe(res => { }, err => { });
              }
              
              this.computePresencasTotal();

              this.isCreatePresencas = false;
              if (this.introAtaOrig) {
                this.tabsObjDef.find(el => el.key === 'ata').disabled = false;
              }
              this.ataConcluidaPrev = !!this.details.ata_concluida;

              this.loadOrdensTrabalhos();

              if (this.introAta) {
                this.addFraccoes('INTRODUCAO_ATA', null, true, true);
              }
            } else {
              this.utils.apiErrorMsg(res);
            }

            this.compState.init.presencas = this.getCompState().presencas;

            this.submittingForm = false;
            this.loading = false;
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.submittingForm = false;
            this.loading = false;
          });

          break;
        case 'ata':
          // CHECK IF ALL ATTACHMENTS WHERE UPLOADED
          if (this.details.ata_concluida && !this.hasAllAttachmentsUploaded()) {
            let res = await this.presentMissingAttachement();
            if (!res) return;
          }

          await this.presentInformationToastr();

          this.submittingForm = true;
          this.loading = true;

          this.pdfAtaController.export().then((group: Group) => {

            let ordensTrabalhos = this.ordensTrabalhos.map(el => {
              let aux = JSON.parse(JSON.stringify(el));
              delete aux.respOpts;

              return aux;
            });

            let ata = {
              introAta: this.introAta,
              anexosAta: this.anexosAta[0],
              condominioNome: this.condominioNome,
              moradaCondominio: this.moradaCondominio,
              condominioNif: this.condominioNif,
            }

            let numAta = this.ataNum;
            let numFolha = this.folhaNum;
            let numPag = group['children']['length'];

            // SAVE ASSUNTOS DIVERSOS OBJECT IN ATA OBJECT
            if (Array.isArray(this.assuntosDiversosRespObj) && this.assuntosDiversosRespObj.length > 0) {
              ata['assDiversosObj'] = this.assuntosDiversosRespObj.filter(el => (el !== null)).map(el => {
                delete el.attachmentInfo;
                return el;
              });
              ata['assDiversosTextoFinal'] = this.assuntosDiversosTextoInicial;
            } else {
              ata['assDiversosObj'] = [];
              ata['assDiversosTextoFinal'] = null;
            }

            let ordensTrabalhosToSave = this.getOrdensTrabalhosToSave();

            this.startApiSub('SAVE_ATA');
            this.api.saveAtaAssembleia(this.details.id_assembleia, ata, ordensTrabalhosToSave, this.details.presencas_ficheiro, this.details.representantes_ficheiro, this.details.presencas_representantes_ficheiro, numAta, numFolha, numPag, this.details.ata_concluida == 1, assembPresencasAPI).subscribe(async (res) => {
              if (res.hasOwnProperty('success') && res['success']) {

                let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
                if (actRegMsg) {
                 this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, (isReopened) ? 'Ata em elaboração' : 'Retoma de elaboração de ata', JSON.stringify([]), this.userSession.getUserId(), new Date(), actRegMsg).subscribe(res => {
                    if (res.hasOwnProperty('success') && res.success) {
                      this.regEmails = [{
                        description: (isReopened) ? 'Ata em elaboração' : 'Retoma de elaboração de ata',
                        emailList: [],
                        name: this.userSession.getUserFullName(),
                        date: new Date(),
                        msg: actRegMsg,
                      } as RegEmails].concat(this.regEmails);
                      this.sortRegAtividade();
                    }
                  }, err => { });
                }

                if (!this.ataConcluidaPrev && this.details.ata_concluida) {
                  await this.saveRegistoComunicacao('ASSEMBLEIAS', this.details.id_assembleia, cod, 'Ata concluída', [], this.userSession.getUserId(), new Date(), actRegMsg);
                }

                let titulo = null;
                let descricao = `Condomínio: ${this.details.cod} - ${this.details.nome_condominio}, Data convocatória:  ${this.details.dt} ${this.details.hora}, Tipo: ${(this.details.extraordinaria == 0) ? 'Ordinária' : 'Extra'}.`;
                if (this.details) {
                  // REGISTO ACTIVIDADES - UPDATE REGISTO PRESENCAS
                  titulo = 'Ata de Assembleia Actualizado';
                  this.api.saveRegistoActividade(this.details.cod, null, null, titulo, descricao).subscribe(res => { }, err => { });
                }

                // API RETURN SUCCESS
                this.hasAta = true;

                this.ataConcluidaPrev = !!this.details.ata_concluida;

                this.removedAttachments = [];
                this.addedAttachments = [];
                this.getDetails();
              } else {
                this.utils.apiErrorMsg(res);
              }


              this.submittingForm = false;
              this.loading = false;
              this.finishApiSub('SAVE_ATA');
            }, err => {
              this.finishApiSub('SAVE_ATA');
              this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
              this.submittingForm = false;
              this.loading = false;
            });
          });
          break;
      }
    })
  }

  saveRegistoComunicacao(origem, id_entidade, cod_condominio, descricao, email_list: Array<any>, id_utilizador, data, msg = undefined, obj = undefined): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.saveRegistoComunicacaoAssembleias(origem, id_entidade, cod_condominio, descricao, JSON.stringify(email_list), id_utilizador, data, msg, obj).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.regEmails = [{
            description: descricao,
            emailList: email_list,
            name: this.userSession.getUserFullName(),
            date: data,
            msg: msg,
            obj: obj,
          } as RegEmails].concat(this.regEmails);
          this.sortRegAtividade();
          resolve(true);
        }
      }, err => { 
        resolve(false);
      });
    });
  }

  presentInformationToastr(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.isEntregueParaAssinatura) {
        resolve(false);
        return;
      }

      let procs: Array<OrdemTrabalhoParametros> = [];

      this.ordensTrabalhos.forEach(el => {
        if (el.label === 'QUOTA_EXTRA') {
          el.resps.forEach(resp => {
            resp.parametros.forEach(param => {
              if (param.chave === 'PROCESSAMENTO') {
                procs.push(param);
              }
            })
          })
        }
      });
      if (!procs.length) {
        resolve(false);
        return;
      }

      let req = [];
      procs.forEach(el => {
        req.push(this.processamentos.getProcessamento(el.valor, { includeRecibos: false }));
      });

      Promise.all(req).then((resArr: Array<Processamento>) => {
        let toApprove = resArr.filter(res => res.is_lancado == 0).length;
        if (toApprove > 0) {
          let msg = toApprove === 1 ? 'O processamento aprovado em ata está a ser lançado' : 'Os processamentos aprovados em ata estão a ser lançados';
          this.toastr.info(msg, 'Informação');
        }
        resolve(true);
      }).catch(_ => {
        resolve(false);
      });
    });
  }

  asssembleiaCancelada = false;
  cancelAssembleia() {
    // CHECK PERMISSIONS AND ASSEMBLEIA STATUS 
    if (this.asssembleiaCancelada && !this.userSession.isAdmin()) {
      this.toastr.error('Não tem permissões para reverter o estado de cancelamento da presente assembleia.', 'Alerta', { timeOut: 4000 });
      return;
    }

    // INPUT VALIDATORS
    this.submittingMotivo = true;
    setTimeout(() => { this.submittingMotivo = false; }, 3000);

    if (!this.motivoCancelAssemb) return;

    // CANCEL ASSEMBLEIA
    this.loadingModal = true;
    this.api.cancelarAssembleia(this.details.id_assembleia, !this.asssembleiaCancelada).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.asssembleiaCancelada = !this.asssembleiaCancelada;
        let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
        this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, (this.asssembleiaCancelada) ? 'Assembleia Cancelada' : 'Assembleia Reactivada', JSON.stringify([]), this.userSession.getUserId(), new Date(), this.motivoCancelAssemb).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            this.regEmails = [{
              description: (this.asssembleiaCancelada) ? 'Assembleia Cancelada' : 'Assembleia Reactivada',
              emailList: [],
              name: this.userSession.getUserFullName(),
              date: new Date(),
              msg: this.motivoCancelAssemb,
            } as RegEmails].concat(this.regEmails);
            this.sortRegAtividade();
          }
        }, err => { });

        let titulo = null;
        let descricao = `Condomínio: ${this.details.cod} - ${this.details.nome_condominio}, Data convocatória:  ${this.details.dt} ${this.details.hora}, Tipo: ${(this.details.extraordinaria == 0) ? 'Ordinária' : 'Extra'}.`;
        if (this.details) {
          // REGISTO ACTIVIDADES - UPDATE REGISTO PRESENCAS
          titulo = (this.asssembleiaCancelada) ? 'Assembleia Cancelada' : 'Assembleia Reactivada';
          this.api.saveRegistoActividade(this.details.cod, null, null, titulo, descricao).subscribe(res => { }, err => { });
        }

        this.loadingModal = false;
        if (this.cancelAssembModalRef) this.cancelAssembModalRef.approve();
      } else {
        this.loadingModal = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
    },
      err => {
        this.loadingModal = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      });
  }

  getOrdensTrabalhosToSave(): Array<SaveAssembleiaOrdemTrabalho> {
    let ordensTrabalhos: Array<SaveAssembleiaOrdemTrabalho> = [];
    this.ordensTrabalhos.forEach((ot, ordemOT) => {
      let resps: Array<SaveAssembleiaOrdemTrabalhoResp> = [];
      ot.resps.forEach((resp, ordemResp) => {
        let parametros: Array<SaveAssembleiaOrdemTrabalhoRespParametros> = resp.parametros.map(param => {
          let saveParam: SaveAssembleiaOrdemTrabalhoRespParametros = {
            id: param.id,
            chave: param.chave,
            valor: param.valor
          }
          return saveParam;
        });
        let saveResp: SaveAssembleiaOrdemTrabalhoResp = {
          id: resp.id_assembleia_ordem_trabalho_resp,
          id_ordem_trabalho_resp: resp.respSel ? resp.respSel.id : null,
          resp: resp.resp,
          ordem: (ordemResp + 1),
          parametros: parametros
        }
        if (resp.hasOwnProperty('attachmentInfo')) saveResp.attachmentInfo = resp.attachmentInfo;

        resps.push(saveResp);
      })
      let ordemSave: SaveAssembleiaOrdemTrabalho = {
        id: ot.id_assembleia_ordem_trabalho,
        id_ordem_trabalho: ot.id,
        is_diverso: 0,
        ordem: (ordemOT + 1),
        resps: resps,
        selAnos: ot.selAnos,
      }
      ordensTrabalhos.push(ordemSave);
    })

    this.assuntosDiversosRespObj.forEach((ot, ordemOT) => {
      let parametros: Array<SaveAssembleiaOrdemTrabalhoRespParametros> = ot.parametros.map(param => {
        let saveParam: SaveAssembleiaOrdemTrabalhoRespParametros = {
          id: param.id,
          chave: param.chave,
          valor: param.valor
        }
        return saveParam;
      });

      let saveResp: SaveAssembleiaOrdemTrabalhoResp = {
        id: ot.id_assembleia_ordem_trabalho_resp,
        id_ordem_trabalho_resp: ot.respSel ? ot.respSel.id : null,
        resp: ot.resp,
        ordem: 1,
        parametros: parametros,
      };
      if (ot.hasOwnProperty('attachmentInfo')) saveResp.attachmentInfo = ot.attachmentInfo;

      let ordemSave: SaveAssembleiaOrdemTrabalho = {
        id: ot.id_assembleia_ordem_trabalho,
        id_ordem_trabalho: ot.id,
        is_diverso: 1,
        ordem: (ordemOT + 1),
        resps: [saveResp],
        selAnos: []
      }
      ordensTrabalhos.push(ordemSave);
    })
    return ordensTrabalhos;
  }

  loadingSaveAll = false;
  async saveAll() {
    if (this.loadingSaveAll) return;

    let req = [];
    if (this.retObj.convocatoria) {
      this.submittingForm = true;
      if (!this.geralForm.valid || !this.cod_condominio) {
        this.toastr.error('Convocatória incompleta. Por favor, complete os campos obrigatórios antes de guardar a presente assembleia.', 'Alerta', { timeOut: 4000 });
        this.unsavedModalRef.deny();

        setTimeout(() => { this.submittingForm = false; }, 4000);
        return;
      }

      let data = this.geralForm.getRawValue();

      if (this.isCreate) {
        let today = new Date(data.dt);
        today.setDate(today.getDate() + 30);
        this.dataLimite = new Date(today);

        let res = await this.presentMapaReuniaoModal();
        if (!res) return;
      }
      let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
      let ordensTrabalhosToSave = this.getOrdensTrabalhosToSave();
      req.push(this.api.saveAssembleiaGeral((this.details) ? this.details.id_assembleia : null, cod, ordensTrabalhosToSave, data.dt, data.dt_alter, data.dt_fim, data.extraordinaria, data.ficheiro, data.morada, data.postalCode, data.locality, this.autorConvocatoria, undefined, data.realizada_por, this.dataLimite, (this.convEnviada) ? '1' : '0', JSON.stringify(this.convOrdensTrabalho)));
    }

    let assembPresencas: Array<AssembleiasPresencas> = this.convertListToPresencas();
    if (this.retObj.presencas) {
      req.push(this.api.updateAssembleiaPresencas(this.details.id_assembleia, assembPresencas, this.details.ata_concluida, this.reuniaoRealizada ? 1 : 0));
    }

    if (this.retObj.ata) {
      // CHECK IF ALL ATTACHMENTS WHERE UPLOADED
      if (this.details.ata_concluida && !this.hasAllAttachmentsUploaded()) {
        let res = await this.presentMissingAttachement();
        if (res) {
          this.pdfAtaController.export().then((group: Group) => {
            let ordensTrabalhos = this.ordensTrabalhos.map(el => {
              let aux = JSON.parse(JSON.stringify(el));
              delete aux.respOpts;

              return aux;
            });

            let ata = {
              introAta: this.introAta,
              anexosAta: this.anexosAta[0],
              condominioNome: this.condominioNome,
              moradaCondominio: this.moradaCondominio,
              condominioNif: this.condominioNif
            }

            let numAta = this.ataNum;
            let numFolha = this.folhaNum;
            let numPag = group['children']['length'];

            // SAVE ASSUNTOS DIVERSOS OBJECT IN ATA OBJECT
            if (Array.isArray(this.assuntosDiversosRespObj) && this.assuntosDiversosRespObj.length > 0) {
              ata['assDiversosObj'] = this.assuntosDiversosRespObj.filter(el => (el !== null)).map(el => {
                delete el.attachmentInfo;
                return el;
              });
              ata['assDiversosTextoFinal'] = this.assuntosDiversosTextoInicial;
            } else {
              ata['assDiversosObj'] = [];
              ata['assDiversosTextoFinal'] = null;
            }

            let ordensTrabalhosToSave = this.getOrdensTrabalhosToSave();
            req.push(this.api.saveAtaAssembleia(this.details.id_assembleia, ata, ordensTrabalhosToSave, this.details.presencas_ficheiro, this.details.representantes_ficheiro, this.details.presencas_representantes_ficheiro, numAta, numFolha, numPag, this.details.ata_concluida == 1, assembPresencas));

            this.loadingSaveAll = true;
            forkJoin(req).subscribe(async res => {
              if (res[0].hasOwnProperty('success') && res[0].success) {

                let actRegMsg: any = false;
                let isReopened = false;
                if (this.retObj.presencas && this.retObj.ata) {
                  isReopened = (this.ataConcluidaPrev && !this.details.ata_concluida);
                  if ((this.ataConcluidaPrev && this.details.ata_concluida) || isReopened) {
                    actRegMsg = await this.presentAtaChanged();
                    if (!actRegMsg) return;
                  }
                }

                if (actRegMsg) {
                  let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
                  this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, (isReopened) ? 'Ata em elaboração' : 'Retoma de elaboração de ata', JSON.stringify([]), this.userSession.getUserId(), new Date(), actRegMsg).subscribe(res => {
                    this.unsavedModalRef.approve();
                  }, err => { });
                } else {
                  this.unsavedModalRef.approve();
                }

              }
            }, err => { });
          });

        } else {
          this.unsavedModalRef.deny();
        }
      } else {

        this.pdfAtaController.export().then((group: Group) => {
          let ordensTrabalhos = this.ordensTrabalhos.map(el => {
            let aux = JSON.parse(JSON.stringify(el));
            delete aux.respOpts;

            return aux;
          });

          let ata = {
            introAta: this.introAta,
            anexosAta: this.anexosAta[0],
            condominioNome: this.condominioNome,
            moradaCondominio: this.moradaCondominio,
            condominioNif: this.condominioNif
          }

          let numAta = this.ataNum;
          let numFolha = this.folhaNum;
          let numPag = group['children']['length'];

          // SAVE ASSUNTOS DIVERSOS OBJECT IN ATA OBJECT
          if (Array.isArray(this.assuntosDiversosRespObj) && this.assuntosDiversosRespObj.length > 0) {
            ata['assDiversosObj'] = this.assuntosDiversosRespObj.filter(el => (el !== null)).map(el => {
                delete el.attachmentInfo;
                return el;
              });
            ata['assDiversosTextoFinal'] = this.assuntosDiversosTextoInicial;
          } else {
            ata['assDiversosObj'] = [];
            ata['assDiversosTextoFinal'] = null;
          }

          let assembPresencas: Array<AssembleiasPresencas> = this.convertListToPresencas();
          let ordensTrabalhosToSave = this.getOrdensTrabalhosToSave();

          req.push(this.api.saveAtaAssembleia(this.details.id_assembleia, ata, ordensTrabalhosToSave, this.details.presencas_ficheiro, this.details.representantes_ficheiro, this.details.presencas_representantes_ficheiro, numAta, numFolha, numPag, this.details.ata_concluida == 1, assembPresencas));

          this.loadingSaveAll = true;
          forkJoin(req).subscribe(async res => {
            if (res[0].hasOwnProperty('success') && res[0].success) {

              let actRegMsg: any = false;
              let isReopened = false;
              if (this.retObj.presencas && this.retObj.ata) {
                isReopened = (this.ataConcluidaPrev && !this.details.ata_concluida);
                if ((this.ataConcluidaPrev && this.details.ata_concluida) || isReopened) {
                  actRegMsg = await this.presentAtaChanged();
                  if (!actRegMsg) return;
                }
              }

              if (actRegMsg) {
                let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
                this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, (isReopened) ? 'Ata em elaboração' : 'Retoma de elaboração de ata', JSON.stringify([]), this.userSession.getUserId(), new Date(), actRegMsg).subscribe(res => {
                  this.unsavedModalRef.approve();
                }, err => { });
              } else {
                this.unsavedModalRef.approve();
              }

            }
          }, err => { });
        });

      }

    } else {
      this.loadingSaveAll = true;
      forkJoin(req).subscribe(async res => {
        if (res[0].hasOwnProperty('success') && res[0].success) {

          let actRegMsg: any = false;
          let isReopened = false;
          if (this.retObj.presencas || this.retObj.ata) {
            isReopened = (this.ataConcluidaPrev && !this.details.ata_concluida);
            if ((this.ataConcluidaPrev && this.details.ata_concluida) || isReopened) {
              actRegMsg = await this.presentAtaChanged();
              if (!actRegMsg) return;
            }
          }

          if (actRegMsg) {
            let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
            this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, (isReopened) ? 'Ata em elaboração' : 'Retoma de elaboração de ata', JSON.stringify([]), this.userSession.getUserId(), new Date(), actRegMsg).subscribe(res => {
              this.unsavedModalRef.approve();
            }, err => { });
          } else {
            this.unsavedModalRef.approve();
          }

        }
      }, err => { });
    }
  }

  toggleFraccaoSelection(fraccao) {
    if (fraccao.divida > 0) fraccao.checked = !fraccao.checked;
  }

  saveGlobalState() {
    let cod = this.cod_condominio && this.cod_condominio.hasOwnProperty('value') ? this.cod_condominio.value : this.cod_condominio;
    if (cod) {
      this.appState.saveGlobalState('global', {
        selCondominio: { id: cod.id, cod: cod.cod, nome: cod.nome, exercicio: cod.exercicio },
      });
    } else {
      this.appState.clearGlobalState();
    }
  }

  presencasChecked = false;
  checkedCartaAll = false;
  checkedEmailAll = false;
  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'presencas':
        (ev.target.checked) ? this.presencasList.map(el => el.present = true) : this.presencasList.map(el => el.present = false);
        this.computePresencasTotal();
        break;
      case 'fraccoes-list':
        if (this.isEmailList) {
          (ev.target.checked) ? this.selectionFraccaoList.map(el => { el.checked = (el.email_proprietario !== null && el.email_proprietario !== ''); return el; }) : this.selectionFraccaoList.map(el => el.checked = false);
        } else {
          if (this.isDividaCondomino || this.isLimparDivida) {
            (ev.target.checked) ? this.selectionFraccaoListDivida.map(el => { if (el.divida > 0) { el.checked = true; } else { el.checked = false; } return el; }) : this.selectionFraccaoListDivida.map(el => el.checked = false);
          } else {
            (ev.target.checked) ? this.selectionFraccaoList.map(el => el.checked = true) : this.selectionFraccaoList.map(el => el.checked = false);
          }
        }
        break;
      case 'fraccoes-list-por-carta':
        this.selectionFraccaoConvProcList.map(el => el.checkedCarta = this.checkedCartaAll);
        break;
      case 'fraccoes-list-por-email':
        this.selectionFraccaoConvProcList.map(el => { el.checkedEmail = (el.email_proprietario !== null && el.email_proprietario.trim() !== '' && this.checkedEmailAll); return el; });
        break;
      case 'despesas-ctt':
        (ev.target.checked) ? this.despesasCttList.map(el => { el.checked = true; return el; }) : this.despesasCttList.map(el => el.checked = false);
        this.setCartasCounter();
        this.computeTotals();
        break;
    }
  }

  minPresencas = false;
  async computePresencasTotal(openComunicationModal = false) {
    this.nfraccoes = 0;
    this.totalPerm = 0;
    let totalSignatureQuorum = 0;

    this.presencasList.forEach(el => {
      if (el.present) {
        this.nfraccoes = this.nfraccoes + 1;
        this.totalPerm = this.totalPerm + el.permilage;

        totalSignatureQuorum += (el.signature) ? el.permilage : 0;
      }
    });
    this.totalPerm = this.utils.cleanDecimalDigits(this.totalPerm);
    totalSignatureQuorum = this.utils.cleanDecimalDigits(totalSignatureQuorum);
    this.quorumSigOk = this.checkQuorum(totalSignatureQuorum);
    this.minPresencas = (this.totalPerm >= 250);

    if (!this.reuniaoRealizada || (!this.prevReuniaoRealizada)) this.reuniaoRealizada = !!this.presencasList.find(el => el.present);

    // if (openComunicationModal && this.totalSignatureQuorum !== totalSignatureQuorum) {
    if (openComunicationModal) {
      if (this.checkQuorum(totalSignatureQuorum)) {
        await this.presentComunicacoesAutomaticas();
      }
    }
    this.totalSignatureQuorum = totalSignatureQuorum;
    this.presencasChecked = !this.presencasList.find(el => !el.present);
  }

  dateChanged(ev) {
    if (!ev) return;

    let newDate = new Date(ev.getTime() + 15 * 60000);
    this.geralForm.patchValue({
      dt_alter: newDate,
    });
  }

  docToPrint = {
    convocatoriaGeral: true,
    convocatorias: false,
    regPresencas: false,
    procuracoes: false,
  }

  docLocal = 'Amora';
  docData = new Date();

  anexosDisabled = {
    presencas_procuracoes: true,
    balancete: true,
    orcamento: true,
    contaCorrente: true,
    // representantes: true,
    outros: true,
  }


  getApresentacaoContas(): Promise<boolean> {
    return new Promise((resolve) => {
      let req = [this.getBalancetes(), this.getContasCorrente()]
      Promise.all(req).then(resArr => {
        if (!resArr.find(res => !res)) {
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch(err => { resolve(false) })
    });
  }


  pendingAction: pendingActions = null;
  async printDocuments() {
    if (this.isCreate) {
      this.toastr.error('Não é possível aceder ao menu de impressão. Necessita primeiro de guardar a presente assembleia.', 'Alerta', { timeOut: 4000 });
      return;
    }

    if (this.selTab === 'ata') {
      // CHECK IF ATA IS COMPELE
      let ataComplete = true;
      if ((((this.introAta && this.introAta.indexOf('[[ FRACCAO_DESCRICAO_LIST ]]') !== -1)) || (!this.anexosAta[0]))) {
        ataComplete = false;
      }
      this.ordensTrabalhos.filter(it => (it.label !== 'ASSUNTOS_DIVERSOS' && it.id != 5)).forEach(ordemTrab => {
        ordemTrab.resps.forEach(resp => {
          if (!resp.resp || (resp.resp && resp.resp.indexOf('[[') !== -1 && resp.resp.indexOf(']]') !== -1)) ataComplete = false;
        });
      });

      if (this.ordensTrabalhos.find(it => (it.hasOwnProperty('id') && it.id == 5))) {
        this.assuntosDiversosRespObj.forEach(ordemTrab => {
          if (!ordemTrab.resp || (ordemTrab.resp && ordemTrab.resp.indexOf('[[') !== -1 && ordemTrab.resp.indexOf(']]') !== -1)) ataComplete = false;
        });
      }

      if (!ataComplete) {
        this.toastr.error('Complete os campos abaixo assinalados antes de proceder à exportação dos ficheiros para impressão.', 'Alerta', { timeOut: 4000 });
        return;
      }

      // CHECK IF DATA WHERE FETCHED
      if (!this.canExecuteAction('PRINT')) {
        this.pendingAction = 'PRINT';
        this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });
        return;
      }

      let hasContaCorrente = (this.anexosAta[0].toLowerCase().indexOf('conta corrente') !== -1);
      let hasBalancete = (this.anexosAta[0].toLowerCase().indexOf('balancete') !== -1);

      if (hasContaCorrente || hasBalancete) {
        if (hasContaCorrente && hasBalancete) {
          if (!this.contaCorrenteReports.length && !this.balanceteReports.length) {
            this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });
            await this.getApresentacaoContas();
          } else if (!this.contaCorrenteReports.length) {
            this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });
            await this.getContasCorrente();
          } else if (!this.balanceteReports.length) {
            this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });
            await this.getBalancetes();
          }
        } else if (hasContaCorrente) {
          if (!this.contaCorrenteReports.length) {
            this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });
            await this.getContasCorrente();
          }
        } else {
          if (!this.balanceteReports.length) {
            this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });
            await this.getBalancetes();
          }
        }
      }

      // PRE-SET KNOWN VALUES
      this.anexos['presencas_procuracoes'] = (this.anexosAta[0].toLowerCase().indexOf('lista de presenças') !== -1 || this.anexosAta[0].toLowerCase().indexOf('procurações') !== -1);
      let aux = ((this.details.hasOwnProperty('presencas_ficheiro') && this.details.presencas_ficheiro) || this.details.hasOwnProperty('representantes_ficheiro') && this.details.representantes_ficheiro || (this.details.hasOwnProperty('presencas_representantes_ficheiro') && this.details.presencas_representantes_ficheiro));
      if (this.anexos['presencas_procuracoes'] && !aux) this.anexos['presencas_procuracoes'] = false;

      this.anexos['balancete'] = (this.anexosAta[0].toLowerCase().indexOf('balancete') !== -1);
      this.anexos['orcamento'] = (this.anexosAta[0].toLowerCase().indexOf('orçamento') !== -1);
      this.anexos['contaCorrente'] = (this.anexosAta[0].toLowerCase().indexOf('conta corrente') !== -1);
      // this.anexos['representantes'] = (this.anexosAta[0].toLowerCase().indexOf('lista de representantes') !== -1);
      this.anexos['outros'] = false;
      for (let i = 0; i < this.tiposAnexoOrig.length; i++) {
        if (this.anexosAta[0].toLowerCase().indexOf(this.tiposAnexoOrig[i].name.toLowerCase()) !== -1) {
          this.anexos['outros'] = true;
          break;
        }
      }

      this.anexosDisabled['presencas_procuracoes'] = !this.anexos['presencas_procuracoes'];
      this.anexosDisabled['balancete'] = !this.anexos['balancete'];
      this.anexosDisabled['orcamento'] = !this.anexos['orcamento'];
      this.anexosDisabled['contaCorrente'] = !this.anexos['contaCorrente'];
      // this.anexosDisabled['representantes'] = (!this.anexos['representantes'] || (this.details.hasOwnProperty('representantes_ficheiro') && !this.details.representantes_ficheiro));
      this.anexosDisabled['outros'] = !this.anexos['outros'];

      this.addAnexosModalRef = this.modalService
        .open(this.addAnexosAlertConfig)
        .onApprove(() => {

          this.exportPdf('ata');
        })
        .onDeny(() => {
          this.anexos = {
            presencas_procuracoes: false,
            balancete: false,
            orcamento: false,
            contaCorrente: false,
            outros: false,
          }
          this.anexosDisabled = {
            presencas_procuracoes: true,
            balancete: true,
            orcamento: true,
            contaCorrente: true,
            outros: true,
          }
        });
    } else {
      if (!this.introAta && !this.introAtaOrig) {
        this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });
        return;
      }

      this.documentosModalRef = this.modalService
        .open(this.documentosAlertConfig)
        .onApprove(() => {
          // CARTAS CONVOCATORIAS + PROCURAÇOES
          if (this.docToPrint.convocatorias && this.docToPrint.procuracoes) {
            this.exportPdf('convocatorias-procuracoes');
          } else {
            // CONVOCATORIAS
            if (this.docToPrint.convocatorias) this.exportPdf('convocatorias');

            // PROCURAÇOES
            if (this.docToPrint.procuracoes) this.exportPdf('procuracoes');
          }

          // CONVACATORIA GERAL
          if (this.docToPrint.convocatoriaGeral) this.exportPdf('convocatoria-geral');

          // REGISTO DE PRESENÇAS
          if (this.docToPrint.regPresencas) this.exportPdf('reg-presencas');

        })
        .onDeny(() => {
          this.docToPrint = {
            convocatoriaGeral: true,
            convocatorias: true,
            regPresencas: true,
            procuracoes: true,
          }
        });
    }

  }

  checkAnoEditOT(ano: anosPicker): void {
    switch (this.ordemTrabalho.label) {
      case 'APRESENTACAO_CONTAS':
        ano.checked = !ano.checked;
        break;

      case 'ORCAMENTO':
        // TODO Remove if can select multiple years
        // let newValue = !ano.checked;
        // this.editOrdemAnosOpts.forEach(el => el.checked = false);
        // ano.checked = newValue;
        ano.checked = !ano.checked;
        break;

      default:
        break;
    }
  }


  checkOrdemTrabalhoEdit() {
    switch (this.ordemTrabalho.label) {
      case 'APRESENTACAO_CONTAS':
        if (!this.editOrdemAnosOpts.find(el => el.checked)) {
          this.toastr.error('É necessário selecionar pelo menos um ano para prosseguir.', 'Selecione um ano');
          return;
        }
        break;
      case 'ORCAMENTO':
        if (!this.editOrdemAnosOpts.find(el => el.checked)) {
          this.toastr.error('É necessário selecionar pelo menos um ano para prosseguir.', 'Selecione um ano');
          return;
        }
        // TODO Remove if can select multiple years
        // let checked = this.editOrdemAnosOpts.filter(el => el.checked);
        // if (checked.length < 1) {
        //   this.toastr.error('É necessário selecionar um ano para prosseguir.','Selecione um ano');
        //   return;
        // }
        // if (checked.length > 1) {
        //   this.toastr.error('Só pode selecionar um ano para prosseguir.','Selecione um ano');
        //   return;
        // }
        break;
      default:
        break;
    }
    this.ordemTrabParamModalRef.approve();
  }

  handleYearSelectionEditOT(entry: OrdemTrabalhoList): void {

    if (entry.label === 'APRESENTACAO_CONTAS' || entry.label === 'ORCAMENTO') {
      let anosChosen: Array<SelAno> = this.editOrdemAnosOpts.filter(ano => !!ano.checked).map(ano => { return { ano: ano.ano, id_link: null, data_inicio: ano.data_inicio, data_fim: ano.data_fim } });
      this.setAnosDescricao(entry, anosChosen);

      if (entry.label === 'APRESENTACAO_CONTAS') {
        this.getBalancetes(true);
        this.getContasCorrente();
      }


      if (!this.isCreatePresencas) {
        this.setAnexosString();
      }
    }

  }

  ordemTrabalho: OrdemTrabalhoList = null;
  async editOrdemTrabalho(entry: OrdemTrabalhoList) {
    if (this.ataEditEnabled) return;

    await this.setOrdemAttributes(entry);

    if (this.ordemTrabParamModalRef) return;
    this.ordemTrabalho = JSON.parse(JSON.stringify(entry));
    this.ordemTrabParamModalRef = this.modalService
      .open(this.ordemTrabParamAlertConfig)
      .onApprove(() => {
        let aux = this.ordensTrabalhos.find(el => (el.id === entry.id));
        if (aux) aux['descricao'] = this.ordemTrabalho.descricao;

        this.handleYearSelectionEditOT(entry);

        this.editOrdemMainChecked = false;
        this.editOrdemAnosOpts = [];
        this.ordemTrabParamModalRef = null;
      })
      .onDeny(() => {
        this.ordemTrabalho = null;
        this.editOrdemMainChecked = false;
        this.editOrdemAnosOpts = [];
        this.ordemTrabParamModalRef = null;
      });
  }

  getRespTitle(ordemTrab: OrdemTrabalhoList, ordemIndex: number, respIndex: number): string {
    if ((ordemTrab.label === 'APRESENTACAO_CONTAS' || ordemTrab.label === 'ORCAMENTO') && respIndex < ordemTrab.selAnos.length) {
      return ordemTrab.selAnos[respIndex].ano;
    } else {
      return '(' + (ordemIndex + 1) + '.' + (respIndex + 1) + ')';
    }
  }


  async setOrdemAttributes(entry: OrdemTrabalhoList): Promise<boolean> {
    return new Promise(async (resolve) => {
      switch (entry.label) {
        case 'APRESENTACAO_CONTAS':
          let res_as = await this.getAnosSemContasAprovadas();
          if (res_as) {
            this.editOrdemAnosOpts = res_as.map(el => { return { checked: !!entry.selAnos && !!entry.selAnos.find(entry => entry.ano === el.ano), ano: el.ano, dateVisible: true, data_inicio: el.data_inicio, data_fim: el.data_fim } });
          }
          break;
        case 'ORCAMENTO':
          let res_orcamentos = await this.getUltimoOrcamento();
          let anosAvailable = this.getOrcamentoAvailableYears(res_orcamentos && res_orcamentos.exercicio);
          if (anosAvailable.length) {
            this.editOrdemAnosOpts = anosAvailable.map(el => { return { checked: !!entry.selAnos && !!entry.selAnos.find(entry => parseInt(entry.ano) === el), ano: el.toString(), dateVisible: false, data_inicio: '01-01-' + el, data_fim: '31-12-' + el } });
          }
          break;
        default:
          break;
      }
      this.checkedAnosPickerRow(this.editOrdemAnosOpts, 'EDIT_ORDEM');
      resolve(true);
    });
  }


  removedAttachments = [];
  addedAttachments = [];

  addAnexo(index_i, index_r = null) {
    this.openAnexosModal(index_i, index_r);
  }

  setAnexosString(): Promise<boolean> {
    return new Promise(async (resolve) => {
      let auxAnexos: string = this.anexosAta && this.anexosAta.length && !!this.anexosAta[0] ? this.anexosAta[0] : '';

      //Removes prev
      //Sorts so "Lista de presenças e procurações" is removed before "Lista de presenças"
      let anexosOpts: Array<{ label: string }> = JSON.parse(JSON.stringify(this.anexosOptsOrig));
      anexosOpts.sort((a, b) => b.label.length - a.label.length);
      anexosOpts.forEach(anexo => {
        auxAnexos = auxAnexos.replace('; ' + anexo.label, '');
        auxAnexos = auxAnexos.replace(anexo.label, '');
      });

      //Removes anexos_ata options
      let tiposAnexos: Array<{ name: string }> = JSON.parse(JSON.stringify(this.tiposAnexoOrig));
      tiposAnexos.sort((a, b) => b.name.length - a.name.length);
      tiposAnexos.forEach(anexo => {
        auxAnexos = auxAnexos.replace('; ' + anexo.name, '');
        auxAnexos = auxAnexos.replace(anexo.name, '');
      });

      //Removes Automatic anexos
      let automaticAnexosToDelete = [];
      this.automaticAnexos.forEach(anexo => {
        anexo.name.forEach(name => automaticAnexosToDelete.push(name));
      });
      automaticAnexosToDelete.forEach(name => {
        let reg1 = new RegExp('; ' + name + ' \\([\\s\\S]*\\)', 'g');
        let reg2 = new RegExp(name + ' \\([\\s\\S]*\\)', 'g');

        let reg3 = new RegExp('; ' + name + '( \\d*)*', 'g');
        let reg4 = new RegExp(name + '( \\d*)*', 'g');
        auxAnexos = auxAnexos.replace(reg1, '');
        auxAnexos = auxAnexos.replace(reg2, '');
        auxAnexos = auxAnexos.replace(reg3, '');
        auxAnexos = auxAnexos.replace(reg4, '');
      });

      if (auxAnexos[auxAnexos.length - 1] === '.') auxAnexos = auxAnexos.substring(0, auxAnexos.length - 1);


      let needAnexo = [];

      let ordensTrabalhoAux = (await this.getOrdensTrabalhosItens()).filter(el => (!!el.tipo_anexo_id && el.need_anexo_indicator) || !!this.getAutomaticAnexo(el));

      let ordensTrabalhoTemp: Array<OrdemTrabalhoFlat> = [];
      ordensTrabalhoAux.forEach(el => {
        if (!ordensTrabalhoTemp.find(it => it.id === el.id && it.index_r === el.index_r)) {
          ordensTrabalhoTemp.push(el);
        }
      });

      ordensTrabalhoTemp.forEach(anexo => {
        let automaticAnexo = this.getAutomaticAnexo(anexo);
        if (automaticAnexo) {
          if (automaticAnexo.isUploaded(anexo)) {
            automaticAnexo.name.forEach(name => {
              let anexoName = name;
              if (anexo.exercicio) {
                anexoName += ' ' + anexo.exercicio;
              }
              if (anexo.sufixo) {
                anexoName += ' (' + anexo.sufixo + ')';
              }
              let aux = {
                isAutomatic: true,
                name: anexoName,
                uploaded_anexo_indicator: true
              }
              needAnexo.push(aux);
            });
          }
        } else {
          let tipoAnexo = this.tiposAnexoOrig.find(el => el.id === anexo.respSel.tipo_anexo_id)
          if (!tipoAnexo) {
            console.error('Couldn\'t find attachment name');
            return;
          };

          let aux = {
            isAutomatic: false,
            name: tipoAnexo.name,
            uploaded_anexo_indicator: anexo.anexo_id != null
          }
          needAnexo.push(aux);
        }
      });

      //Inserts existing anexos
      let firstEntry = true;

      this.anexosOptsOrig.forEach(anexo => {
        if (anexo.isUploaded) {
          if (auxAnexos.indexOf(anexo.label) === -1) {
            if (!firstEntry) {
              auxAnexos = auxAnexos.substring(0, auxAnexos.length - 1);
            }
            auxAnexos += ((firstEntry ? '' : '; ') + anexo.label + '.');
            firstEntry = false;
          }
        }
      });

      //Ordem trabalhos
      needAnexo.forEach(el => {
        let isUploaded = el['isAutomatic'] || el.uploaded_anexo_indicator;
        if (isUploaded) {
          if (auxAnexos.indexOf(el['name']) === -1) {
            if (!firstEntry) {
              auxAnexos = auxAnexos.substring(0, auxAnexos.length - 1);
            }
            auxAnexos += ((firstEntry ? '' : '; ') + el['name'] + '.');
            firstEntry = false;
          }
        }
      });
      this.anexosAta = [auxAnexos];
      resolve(true);
    });
  }
  getAnexoUniqueName(tipo_anexo_id: number, index_o: number, index_r: number): string {
    let hasDifferentAnexos = this.ordensTrabalhos[index_o].resps.filter(el => !!el.need_anexo_indicator).length > 1;
    return this.tiposAnexoOrig.find(el => el.id === tipo_anexo_id).name + (index_r != null && hasDifferentAnexos ? ' (' + (index_o + 1) + '.' + (index_r + 1) + ')' : '')
  }
  getAnexoUniqueNameDiversos(tipo_anexo_id: number): string {
    return this.tiposAnexoOrig.find(el => el.id === tipo_anexo_id).name + ' (Assuntos Diversos)';
  }

  anexoInitText = '\nFazem parte integrante desta ata os seguintes anexos:\n\n';

  ordemTrabOptsModal: Array<{ checked, disabled } & OrdemTrabalhoOpts> = [];
  addOrdemTrabalho() {

    if (!this.canAddOrdemTrabalho()) return;

    this.ordemTrabOptsModal = this.ordemTrabOpts.filter(it => (it.value.is_ordem_trabalho == 1)).map(el => {
      let clone = JSON.parse(JSON.stringify(el));
      if (this.ordensTrabalhos.find(it => (it.id === el.value.id))) {
        clone['checked'] = true;
        clone['disabled'] = true;
      } else {
        clone['checked'] = false;
        clone['disabled'] = false;
      }
      return clone;
    });

    this.ordemTrabModalRef = this.modalService
      .open(this.ordemTrabAlertConfig)
      .onApprove(async () => {
        let aux: Array<OrdemTrabalhoList> = [];

        let unchangedOrdens = this.ordensTrabalhos.filter(ordem => this.ordemTrabOptsModal.findIndex(el => el.checked && ordem.id === el.value.id) !== -1);

        this.ordemTrabOptsModal.filter(el => el.checked && this.ordensTrabalhos.findIndex(ordem => ordem.id === el.value.id) === -1).forEach(el => {
          let newOrdem = this.generateNewOrdemTrabalho(el.value);
          aux.push(newOrdem);
        });

        aux = aux.concat(unchangedOrdens);


        aux.sort((a, b) => a.ordem - b.ordem);

        await this.presentOrdensTrabalhosAnosPicker(aux);
        this.setAnexosIndicators();
      })
      .onDeny(() => { this.ordemTrabOpts.forEach(el => { el.checked = false; }); });
  }


  createOrdemTrabalhoList(ordemTrabalho: AssembleiaOrdemTrabalhoDetailed, config: OrdemTrabalhoConfigDetailed): OrdemTrabalhoList {
    let respOpts: Array<{ name: string, value: OrdemTrabalhoRespConfig }> = config ? ([{ name: '--- Limpar selecção ---', value: -1 }] as any).concat(config.resps.map(it => { return { name: it.descricao, value: it }; })) : [];

    let resps: Array<OrdemTrabalhoItem> = [];

    ordemTrabalho.resps.forEach(resp => {
      let respSel = respOpts.find(opt => opt.value.id === resp.id_ordem_trabalho_resp);
      let parametros: Array<OrdemTrabalhoParametros> = resp.parametros.map(param => {
        return {
          id: param.id,
          chave: param.chave,
          valor: param.valor
        }
      })
      let respItem: OrdemTrabalhoItem = {
        resp: resp.resp,
        respSel: respSel ? respSel.value : null,
        uploaded_anexo_indicator: false,
        need_anexo_indicator: false,
        anexo_id: resp.id_anexo,
        respSelOrigId: resp.id_ordem_trabalho_resp,
        respOrig: resp.resp,
        id_assembleia_ordem_trabalho_resp: resp.id,
        parametros: parametros,
      }
      resps.push(respItem);
    });
    if (!resps.length) {
      resps = [{
        resp: null,
        respSel: null,
        uploaded_anexo_indicator: false,
        need_anexo_indicator: false,
        anexo_id: null,
        respSelOrigId: null,
        respOrig: null,
        id_assembleia_ordem_trabalho_resp: null,
        parametros: []
      }];
    }

    let newOrdem: OrdemTrabalhoList = {
      checked: false,
      selAnos: [],
      anosDescricao: '',
      respOpts: respOpts,
      resps: resps,
      id_assembleia_ordem_trabalho: ordemTrabalho.id,
      id: config ? config.id : null,
      descricao: config ? config.descricao : null,
      label: config ? config.label : null,
      ordinarias_predefinido: config ? config.ordinarias_predefinido : null,
      extras_predefinido: config ? config.extras_predefinido : null,
      ordinarias_diversos_predefinido: config ? config.ordinarias_diversos_predefinido : null,
      extras_diversos_predefinido: config ? config.extras_diversos_predefinido : null,
      is_ordem_trabalho: config ? config.is_ordem_trabalho : null,
      is_assunto_diverso: config ? config.is_assunto_diverso : null,
      is_multipla_entrada: config ? config.is_multipla_entrada : null,
      obj: config ? config.obj : null,
      ordem: config ? config.ordem : null,
      texto_inicial: config ? config.texto_inicial : null,
      texto_final: config ? config.texto_final : null,
      has_anexo: config ? config.has_anexo : null,
      tipo_anexo_id: config ? config.tipo_anexo_id : null,
      active: config ? config.active : null,
      can_delete: config ? config.can_delete : null
    };
    if (!this.details) return newOrdem;

    let selAnos = this.details.ordemTrabAnos.filter(ano => getTypeString(ano.type) === newOrdem.label);
    let anosChosen: Array<SelAno> = selAnos.map(el => { return { ano: this.getAnoOTDatas(el), id_link: el.id_link, data_inicio: el.data_inicio, data_fim: el.data_fim } });
    this.setAnosDescricao(newOrdem, anosChosen, false);
    return newOrdem;
  }
  createOrdemTrabalhoDiversos(ordemTrabalho: AssembleiaOrdemTrabalhoDetailed, config: OrdemTrabalhoConfigDetailed): OrdemTrabalhoDiversos {
    let respOpts: Array<{ name: string, value: OrdemTrabalhoRespConfig }> = ([{ name: '--- Limpar selecção ---', value: -1 }] as any).concat(config.resps.map(it => { return { name: it.descricao, value: it }; }));

    let resp = ordemTrabalho.resps.length ? ordemTrabalho.resps[0] : null;
    let respSel = resp ? respOpts.find(opt => opt.value.id === resp.id_ordem_trabalho_resp) : null;

    let parametros: Array<OrdemTrabalhoParametros> = []
    if (resp) {
      parametros = resp.parametros.map(param => {
        return {
          id: param.id,
          chave: param.chave,
          valor: param.valor
        }
      })
    }

    let newOrdem: OrdemTrabalhoDiversos = {
      respOpts: respOpts,
      respSel: respSel ? respSel.value : null,
      respSelOrigId: resp ? resp.id_ordem_trabalho_resp : null,
      resp: resp ? resp.resp : null,
      respOrig: resp ? resp.resp : null,
      need_anexo_indicator: false,
      uploaded_anexo_indicator: false,
      anexo_id: resp ? resp.id_anexo : null,
      parametros: parametros,
      id_assembleia_ordem_trabalho: ordemTrabalho.id,
      id_assembleia_ordem_trabalho_resp: resp ? resp.id : null,
      id: config ? config.id : null,
      descricao: config ? config.descricao : null,
      label: config ? config.label : null,
      ordinarias_predefinido: config ? config.ordinarias_predefinido : null,
      extras_predefinido: config ? config.extras_predefinido : null,
      ordinarias_diversos_predefinido: config ? config.ordinarias_diversos_predefinido : null,
      extras_diversos_predefinido: config ? config.extras_diversos_predefinido : null,
      is_ordem_trabalho: config ? config.is_ordem_trabalho : null,
      is_assunto_diverso: config ? config.is_assunto_diverso : null,
      is_multipla_entrada: config ? config.is_multipla_entrada : null,
      obj: config ? config.obj : null,
      ordem: config ? config.ordem : null,
      texto_inicial: config ? config.texto_inicial : null,
      texto_final: config ? config.texto_final : null,
      has_anexo: config ? config.has_anexo : null,
      tipo_anexo_id: config ? config.tipo_anexo_id : null,
      active: config ? config.active : null,
      can_delete: config ? config.can_delete : null,
    }
    return newOrdem;
  }

  generateNewOrdemTrabalho(otConfig: OrdemTrabalhoConfigDetailed, selectDefaultResp = true): OrdemTrabalhoList {
    let respOpts: Array<{ name: string, value: OrdemTrabalhoRespConfig }> = ([{ name: '--- Limpar selecção ---', value: -1 }] as any).concat(otConfig.resps.map(it => { return { name: it.descricao, value: it }; }));
    let resp: OrdemTrabalhoItem = {
      resp: null,
      respSel: null,
      uploaded_anexo_indicator: false,
      need_anexo_indicator: false,
      anexo_id: null,
      respSelOrigId: null,
      respOrig: null,
      parametros: [],
      id_assembleia_ordem_trabalho_resp: null,
    }

    if (otConfig.label !== 'ASSUNTOS_DIVERSOS' && selectDefaultResp) {
      //Sets default answers (length === 2 because there's always 1 that is to clean selection)
      if (respOpts.length === 2) {
        resp.resp = respOpts[1].value.modelo_texto;
        resp.respOrig = respOpts[1].value.modelo_texto;
        resp.respSel = respOpts[1].value;
        resp.need_anexo_indicator = respOpts[1].value.has_anexo == 1
      } else {
        let auxRespOpt = respOpts.find(it => it.value.hasOwnProperty('default') && it.value.default == 1);
        if (auxRespOpt) {
          resp.resp = auxRespOpt.value.modelo_texto;
          resp.respOrig = auxRespOpt.value.modelo_texto;
          resp.respSel = auxRespOpt.value;
          resp.need_anexo_indicator = auxRespOpt.value.has_anexo == 1
        }
      }
    }

    return {
      checked: false,
      selAnos: [],
      anosDescricao: '',
      respOpts: respOpts,
      resps: [resp],
      id_assembleia_ordem_trabalho: null,
      id: otConfig.id,
      descricao: otConfig.descricao,
      label: otConfig.label,
      ordinarias_predefinido: otConfig.ordinarias_predefinido,
      extras_predefinido: otConfig.extras_predefinido,
      ordinarias_diversos_predefinido: otConfig.ordinarias_diversos_predefinido,
      extras_diversos_predefinido: otConfig.extras_diversos_predefinido,
      is_ordem_trabalho: otConfig.is_ordem_trabalho,
      is_assunto_diverso: otConfig.is_assunto_diverso,
      is_multipla_entrada: otConfig.is_multipla_entrada,
      obj: otConfig.obj,
      ordem: otConfig.ordem,
      texto_inicial: otConfig.texto_inicial,
      texto_final: otConfig.texto_final,
      has_anexo: otConfig.has_anexo,
      tipo_anexo_id: otConfig.tipo_anexo_id,
      active: otConfig.active,
      can_delete: otConfig.can_delete
    };
  }

  generateNewOrdemTrabalhoDiversos(otConfig: OrdemTrabalhoConfigDetailed, selectDefaultResp = true): OrdemTrabalhoDiversos {
    let respOpts: Array<{ name: string, value: OrdemTrabalhoRespConfig }> = ([{ name: '--- Limpar selecção ---', value: -1 }] as any).concat(otConfig.resps.map(it => { return { name: it.descricao, value: it }; }));

    let newOrdem: OrdemTrabalhoDiversos = {
      respOpts: respOpts,
      respSel: null,
      respSelOrigId: null,
      resp: null,
      respOrig: null,
      need_anexo_indicator: false,
      uploaded_anexo_indicator: false,
      anexo_id: null,
      id_assembleia_ordem_trabalho: null,
      id_assembleia_ordem_trabalho_resp: null,
      parametros: [],

      id: otConfig.id,
      descricao: otConfig.descricao,
      label: otConfig.label,
      ordinarias_predefinido: otConfig.ordinarias_predefinido,
      extras_predefinido: otConfig.extras_predefinido,
      ordinarias_diversos_predefinido: otConfig.ordinarias_diversos_predefinido,
      extras_diversos_predefinido: otConfig.extras_diversos_predefinido,
      is_ordem_trabalho: otConfig.is_ordem_trabalho,
      is_assunto_diverso: otConfig.is_assunto_diverso,
      is_multipla_entrada: otConfig.is_multipla_entrada,
      obj: otConfig.obj,
      ordem: otConfig.ordem,
      texto_inicial: otConfig.texto_inicial,
      texto_final: otConfig.texto_final,
      has_anexo: otConfig.has_anexo,
      tipo_anexo_id: otConfig.tipo_anexo_id,
      active: otConfig.active,
      can_delete: otConfig.can_delete
    }


    if (selectDefaultResp) {
      if (respOpts.length === 2) {
        newOrdem.respSelOrigId = respOpts[1].value.id;
        newOrdem.respOrig = respOpts[1].value.modelo_texto;
        newOrdem.respSel = respOpts[1].value;
        newOrdem.resp = respOpts[1].value.modelo_texto;
      } else {
        let auxRespOpt = respOpts.find(it => it.value.hasOwnProperty('default') && it.value.default == 1);
        if (auxRespOpt) {
          newOrdem.respSelOrigId = auxRespOpt.value.id;
          newOrdem.respOrig = auxRespOpt.value.modelo_texto;
          newOrdem.respSel = auxRespOpt.value;
          newOrdem.resp = auxRespOpt.value.modelo_texto;
        }
      }
    }

    return newOrdem;
  }

  convocatoria = null;
  convocatorias = [];
  procuracoes = [];
  regPresencas = null;
  convocatoriasProcuracoes = [];
  permTotal = 0;
  assembleiaDatePDF = null;
  assembleiaAltDatePDF = null;
  assembleiaHourPDF = null;
  assembleiaAltHourPDF = null;

  moradaCondominio = '';
  moradaAssembleia = {
    morada: null,
    postalCode: null,
    locality: null,
  };



  ordensTrabalhos: Array<OrdemTrabalhoList> = [];
  ordensTrabalhosOrig = [];
  ordemTrabOpts: Array<OrdemTrabalhoOpts> = [];
  // ordemTrabOptsResp: Array<OrdemTrabalhoRespConfig> = [];
  ordermTrabSel = null;
  isEntregueParaAssinatura = false;

  assuntosDiversosOrig = null;

  ataTabDisabled = true;
  fetchingOrdemTrabalhos = false;
  fetchedOrdemTrabalhos = false;
  getAssembleiasConfigs(fromDetails = false): Promise<GetAssembleiasConfigsAPI['data']> {
    return new Promise((resolve, reject) => {
      this.fetchingOrdemTrabalhos = true;
      // let req = [
      //   this.api.getOrdemTrabalhos(),
      //   this.api.getOrdemTrabalhosResp(),
      //   this.api.getAssembDocsModels(),
      // ];
      this.api.getAssembleiasConfigs().subscribe(res => {
        if (!!res.success) {
          resolve(res.data)
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
        this.fetchedOrdemTrabalhos = false;
        this.fetchingOrdemTrabalhos = false;
      }, err => {
        this.fetchingOrdemTrabalhos = false;
        reject(null);
      });
    });
  }
  procuracaoModel = null;
  convocatoriaModel = null;
  convocatoriaGeralModel = null;
  ataIntroModel = null;
  ataCartaModel = null;

  setAnexosIndicators() {
    if (!this.details) return;

    this.ordensTrabalhos.filter(el => (el.label !== 'ASSUNTOS_DIVERSOS' && el.id != 5)).forEach(it => {
      it.resps.forEach(resp => {
        resp.need_anexo_indicator = resp.respSel && resp.respSel.has_anexo == 1;
        resp.uploaded_anexo_indicator = resp.anexo_id != null;
      });
    });

    this.assuntosDiversosRespObj.forEach(it => {
      it.need_anexo_indicator = it.respSel && it.respSel.has_anexo == 1;
      it.uploaded_anexo_indicator = it.anexo_id != null;
    });
  }

  getBalancetes(updateResps = false) {
    return new Promise(resolve => {

      if (this.apiSubs.find(el => el === 'BALANCETE')) {
        resolve(null);
        return;
      }

      if (this.ordensTrabalhos.findIndex(el => el.label === 'APRESENTACAO_CONTAS') === -1 || (!this.details && !this.geralForm.get('dt').value)) {
        resolve(null);
        return;
      }

      let dt = this.geralForm.get('dt').value ? this.geralForm.get('dt').value : this.details.dt;

      let default_year = (dt.getFullYear() - 1).toString();
      let exercicios: Array<SelAno> = [{ ano: default_year, id_link: null, data_inicio: '01-01-' + default_year, data_fim: '31-12-' + default_year }];
      if (this.fetchingOrdemTrabalhos) {
        if (this.details.ordemTrabAnos) {
          exercicios = this.details.ordemTrabAnos.filter(el => el.type === '1').map(el => { return { ano: this.utils.getDate(el.data_fim).getFullYear().toString(), data_inicio: el.data_inicio, data_fim: el.data_fim, id_link: null } });
        }
      } else {
        let auxOrdemTrabalho = this.ordensTrabalhos.find(el => el.label === 'APRESENTACAO_CONTAS');
        if (auxOrdemTrabalho && auxOrdemTrabalho.selAnos) {
          exercicios = auxOrdemTrabalho.selAnos;
        }
      }

      let req = [];
      exercicios.forEach(ano => {
        req.push(this.getEachBalancete(ano));
      })


      this.balanceteReports = [];
      if (!req.length) {
        resolve(true);
        return;
      }

      this.startApiSub('BALANCETE');
      Promise.all<balancetePDFReport>(req).then(res => {
        if (!res.find(el => !el)) {
          res.forEach(el => {
            this.balanceteReports.push(el);
          });
          if (updateResps) {
            let ordem = this.ordensTrabalhos.find(el => el.label === 'APRESENTACAO_CONTAS');
            if (ordem) {
              ordem.resps.forEach((resp, index) => {
                this.respSelected(ordem, resp, index);
              });
            }
          }
        }
        this.finishApiSub('BALANCETE');
        resolve(true);
      }).catch(err => {
        this.finishApiSub('BALANCETE');
        resolve(false);
      })

    });
  }


  startApiSub(type: apiSub) {
    if (!this.apiSubs.length) {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    }
    this.apiSubs.push(type);
  }

  finishApiSub(type: apiSub) {
    this.apiSubs = this.apiSubs.filter(el => el !== type);
    if (!this.apiSubs.length) {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }
    if (this.pendingAction) {
      this.executePendingAction();
    }
  }

  canExecuteAction(action: pendingActions): boolean {
    let dependencies = this.dependenciesMap.find(el => el.action === action);
    if (!dependencies) return true;

    let canExecute = true;
    dependencies.dependencies.forEach(el => {
      if (this.apiSubs.findIndex(apiSub => apiSub === el) !== -1) canExecute = false;
    });

    return canExecute;
  }

  executePendingAction(): void {
    let canExecute = this.canExecuteAction(this.pendingAction);
    if (!canExecute) return;

    switch (this.pendingAction) {
      case 'PRINT':
        this.printDocuments();
        break;

      case 'SEND_ATA_SIGNED':
        break;

      case 'SEND_ATA_TO_SIGN':
        this.sendAtaToSign();
        break;
      case 'OPEN_ANEXOS':
        this.openAnexosModal();
        break;
      default:
        break;
    }
    this.pendingAction = null;
  }

  getEachBalancete(exercicio: SelAno): Promise<balancetePDFReport> {
    return new Promise((resolve) => {
      let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;

      let startDate = this.utils.getDate(exercicio.data_inicio);
      let endDate = this.utils.getDate(exercicio.data_fim);

      this.balanceteSubs = forkJoin([
        this.api.getRelExercBalanceteV2(cod, 'despesas_extra', startDate, endDate, exercicio.ano),
        this.api.getRelExercBalanceteV2(cod, 'despesas_ordinarias', startDate, endDate, exercicio.ano),
        this.api.getRelExercBalanceteV2(cod, 'exerc_actual', startDate, endDate, exercicio.ano),
        this.api.getRelExercBalanceteV2(cod, 'exerc_anterior', startDate, endDate, exercicio.ano),
        this.api.getRelExercBalanceteV2(cod, 'saldos_finais', startDate, endDate, exercicio.ano),
        this.api.getRelExercBalanceteV2(cod, 'saldos_iniciais', startDate, endDate, exercicio.ano),
        this.api.getRelExercBalanceteV2(cod, 'receitas_extra', startDate, endDate, exercicio.ano),
        this.api.getRelExercBalanceteV2(cod, 'pag_adiantados', startDate, endDate, exercicio.ano),
        this.api.getRelExercBalanceteV2(cod, 'mov_despesas_extra', startDate, endDate, exercicio.ano),
      ]).subscribe(res => {
        if (res[0].hasOwnProperty('success') && res[0].success) {


          // START -UPDATE ON DESPESAS EXTRA COMPUTATIONS - TESTING (REMOVE TO FALLBACK TO PREV VERSION - AND BACKEND!)
          let auxMov = null;
          res[0].data.forEach(el => {
            auxMov = res[8].data.filter(it => (it.descricao.indexOf('D ' + el.n_despesa + ' ') !== -1 || it.descricao.indexOf('D ' + el.n_despesa + '-') !== -1));
            el.valor_liquidado = (auxMov) ? auxMov.map(elem => -1 * Number(elem.valor)).reduce((a, b) => a + b, 0) : 0;
          });
          let rubricasAux = [...new Set(res[0].data.map(el => el.cod_rubrica))];
          let auxDespesasExtra = [];
          let aux = null;

          rubricasAux.forEach(el => {
            aux = res[0].data.filter(it => it.cod_rubrica === el);
            if (aux) {
              auxDespesasExtra.push({
                valor_total: aux.map(it => it.valor_liquidado).reduce((a, b) => a + b, 0),
                valor_liquidado: aux[0].valor_liquidado,
                descricao: aux[0].descricao,
                cod_rubrica: aux[0].cod_rubrica,
                rubrica: aux[0].rubrica,
                dt_desp: aux[0].dt_desp,
                dt_pag: aux[0].dt_pag,
              });
            }
          });
          res[0].data = auxDespesasExtra.filter(el => el.valor_liquidado > 0);
          // END -UPDATE ON DESPESAS EXTRA COMPUTATIONS - TESTING


          let data = {
            despesas_extra: res[0].data,
            despesas_ordinarias: res[1].data,
            exerc_actual: res[2].data,
            exerc_anterior: res[3].data,
            saldos_finais: res[4].data,
            saldos_iniciais: res[5].data,
            receitas_extra: res[6].data,
            pag_adiantados: res[7].data,
            movimentos: res[8].data,
          }

          let exerc_anterior_aux = [
            { valor_total: 0, tipo_proc: 'F' },
            { valor_total: 0, tipo_proc: 'O' },
            { valor_total: 0, tipo_proc: 'S' },
            { valor_total: 0, tipo_proc: 'P' },
          ];
          data.exerc_anterior.forEach(el => {
            if (el.tipo_proc === 'F') {
              exerc_anterior_aux[0].valor_total += Number(el.valor_total);
            }

            if (el.tipo_proc === 'O' || el.tipo_proc === 'E') {
              exerc_anterior_aux[1].valor_total += Number(el.valor_total);
            }

            if (el.tipo_proc === 'S') {
              exerc_anterior_aux[2].valor_total += Number(el.valor_total);
            }

            if (el.tipo_proc === 'P') {
              exerc_anterior_aux[3].valor_total += Number(el.valor_total);
            }
          });
          data.exerc_anterior = exerc_anterior_aux;

          let balancete = this.generateBalanceteReportObj(data, parseInt(exercicio.ano), startDate, endDate);

          // TOTAL DESPESAS (FROM BALANCETE) -----------------------------------
          balancete.despesaValor = 0;
          if (data.despesas_ordinarias) {
            data.despesas_ordinarias.forEach(el => {
              balancete.despesaValor += Number(el.valor_total);
            });
            balancete.despesaValor = Math.round(Number(balancete.despesaValor) * 100) / 100;
          }
          if (data.despesas_extra) {
            data.despesas_extra.forEach(el => {
              balancete.despesaValor += Number(el.valor_total);
            });
            balancete.despesaValor = Math.round(Number(balancete.despesaValor) * 100) / 100;
          }
          // END ---------------------------------------------------------------

          // TOTAL RECEITAS (FROM BALANCETE) -----------------------------------
          balancete.receitaValor = 0;
          if (data.exerc_anterior && data.exerc_anterior.length > 0) {
            balancete.receitas.exerAnterior.gestaoCorrente = Number(data.exerc_anterior.find(el => (el.tipo_proc === 'O')).valor_total);
            balancete.receitas.exerAnterior.fundoReserva = Number(data.exerc_anterior.find(el => (el.tipo_proc === 'F')).valor_total);
            balancete.receitas.exerAnterior.seguro = (data.exerc_anterior.find(el => (el.tipo_proc === 'S'))) ? Number(data.exerc_anterior.find(el => (el.tipo_proc === 'S')).valor_total) : null;
            Object.keys(balancete.receitas.exerAnterior).forEach(key => {
              if (key !== 'total') {
                balancete.receitaValor += balancete.receitas.exerAnterior[key];
              }
            });
          }

          if (data.exerc_actual) {
            balancete.receitas.exerActual.gestaoCorrente = Number(data.exerc_actual.gc[0].valor_total);
            balancete.receitas.exerActual.fundoReserva = Number(data.exerc_actual.fr[0].valor_total);
            balancete.receitas.exerActual.seguro = Number(data.exerc_actual.seg[0].valor_total);
            balancete.receitas.exerActual.creditosDisponiveis = Number(data.exerc_actual.cred_disponivel[0].valor_total);
            balancete.receitas.exerActual.creditosUtilizados = -1 * Number(data.exerc_actual.cred_utilizado[0].valor_total);
            balancete.receitas.exerActual.pagAdiantados = 0;
            Object.keys(balancete.receitas.exerActual).forEach(key => {
              if (key !== 'total' && key !== 'receitasExtras') {
                balancete.receitaValor += balancete.receitas.exerActual[key];
              }
            });
          }

          // PREV VERSION - ERRO NO CALCULO DA RECEITA EXTRA
          if (data.receitas_extra) {
            data.receitas_extra.forEach(el => {
              balancete.receitaValor += Number(el.valor_total);
            });
          }

          if (balancete.receitas.exerActual.receitasExtras) {
            balancete.receitas.exerActual.receitasExtras.forEach(el => {
              balancete.receitaValor += el.valor;
            });
          }

          if (balancete.receitas.pagAdiantados && balancete.receitas.pagAdiantados.total) balancete.receitaValor += balancete.receitas.pagAdiantados.total;

          balancete.receitaValor = Math.round(Number(balancete.receitaValor) * 100) / 100;
          // END ---------------------------------------------------------------

          // TOTAL CAIXA (FROM BALANCETE) -----------------------------------
          balancete.caixaSaldo = 0;
          data.saldos_finais.filter(el => (el.banco.trim() === 'CAIXA' && Number(el.valor_total) !== 0)).forEach(el => {
            balancete.caixaSaldo += Number(el.valor_total);
          });
          balancete.caixaSaldo = Math.round(Number(balancete.caixaSaldo) * 100) / 100;
          // END ---------------------------------------------------------------

          // TOTAL BANCO (FROM BALANCETE) -----------------------------------
          balancete.bancoSaldo = 0;
          data.saldos_finais.filter(el => (el.banco.trim() !== 'CAIXA' && Number(el.valor_total) !== 0)).forEach(el => {
            balancete.bancoSaldo += Number(el.valor_total);
          });
          balancete.bancoSaldo = Math.round(Number(balancete.bancoSaldo) * 100) / 100;
          // END ---------------------------------------------------------------

          resolve(balancete);
        } else {
          resolve(null);
        }
      }, err => {
        resolve(null);
      });
    })
  }

  generateBalanceteReportObj(data, exercicio: number, startDate: Date, endDate: Date): balancetePDFReport {
    let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;

    let balancete: balancetePDFReport = {
      title: cod + ' - ' + this.condominioNome,
      reportType: 'Balancete' + ' ' + exercicio,
      startDate: startDate,
      endDate: endDate,
      exercicio: exercicio,
      nextExercicio: exercicio + 1,
      receitaValor: 0,
      despesaValor: 0,
      caixaSaldo: 0,
      bancoSaldo: 0,
      saldosIniciais: {
        caixas: [],
        totalCaixas: 0,
        bancos: [],
        totalBancos: 0,
      },
      saldosFinais: {
        caixas: [],
        totalCaixas: 0,
        bancos: [],
        totalBancos: 0,
      },
      receitasExtraordinarias: {
        rubricas: [],
        totalRubricas: 0,
      },
      despesasExtraordinarias: {
        rubricas: [],
        totalRubricas: 0,
      },
      despesasOrdinarias: {
        rubricas: [],
        totalRubricas: 0,
      },
      receitas: {
        exerAnterior: {
          total: 0,
          gestaoCorrente: 0,
          fundoReserva: 0,
          seguro: 0,
          penalizacao: 0,
        },
        exerActual: {
          total: 0,
          gestaoCorrente: 0,
          fundoReserva: 0,
          seguro: 0,
          penalizacao: 0,
          receitasExtras: [],
          creditosDisponiveis: 0,
          creditosUtilizados: 0,
          pagAdiantados: 0,
        },
        pagAdiantados: {
          total: 0,
          gestaoCorrente: 0,
          fundoReserva: 0,
          seguro: 0
        },
      },
    }

    this.isDetailed = true;

    // SALDOS INICIAS
    if (data.saldos_iniciais) {
      balancete.saldosIniciais.caixas = data.saldos_iniciais.filter(el => (el.banco.trim() === 'CAIXA' && Number(el.valor_total) !== 0));
      balancete.saldosIniciais.bancos = data.saldos_iniciais.filter(el => (el.banco.trim() !== 'CAIXA' && Number(el.valor_total) !== 0));

      // COMPUTE TOTALS
      balancete.saldosIniciais.totalCaixas = 0;
      balancete.saldosIniciais.caixas.forEach(el => {
        balancete.saldosIniciais.totalCaixas += Number(el.valor_total);
      });
      balancete.saldosIniciais.totalCaixas = Math.round(Number(balancete.saldosIniciais.totalCaixas) * 100) / 100;

      balancete.saldosIniciais.totalBancos = 0;
      balancete.saldosIniciais.bancos.forEach(el => {
        balancete.saldosIniciais.totalBancos += Number(el.valor_total);
      });
      balancete.saldosIniciais.totalBancos = Math.round(Number(balancete.saldosIniciais.totalBancos) * 100) / 100;
    }

    // SALDOS FINAIS
    if (data.saldos_finais) {
      // this.saldosFinais.caixas = data.saldos_finais.filter(el => (el.banco === 'CAIXA' && Number(el.valor_total) !== 0));
      // this.saldosFinais.bancos = data.saldos_finais.filter(el => (el.banco !== 'CAIXA' && Number(el.valor_total) !== 0));

      balancete.saldosFinais.caixas = data.saldos_finais.filter(el => (el.banco.trim() === 'CAIXA' && Number(el.valor_total) !== 0));
      balancete.saldosFinais.bancos = data.saldos_finais.filter(el => (el.banco.trim() !== 'CAIXA' && Number(el.valor_total) !== 0));

      // COMPUTE TOTALS
      balancete.saldosFinais.totalCaixas = 0;
      balancete.saldosFinais.caixas.forEach(el => {
        balancete.saldosFinais.totalCaixas += Number(el.valor_total);
      });
      balancete.saldosFinais.totalCaixas = Math.round(Number(balancete.saldosFinais.totalCaixas) * 100) / 100;

      balancete.saldosFinais.totalBancos = 0;
      balancete.saldosFinais.bancos.forEach(el => {
        balancete.saldosFinais.totalBancos += Number(el.valor_total);
      });
      balancete.saldosFinais.totalBancos = Math.round(Number(balancete.saldosFinais.totalBancos) * 100) / 100;
    }

    // DESPESAS ORDINARIAS
    if (data.despesas_ordinarias) {
      balancete.despesasOrdinarias.rubricas = data.despesas_ordinarias;

      // COMPUTE TOTALS
      balancete.despesasOrdinarias.totalRubricas = 0;
      balancete.despesasOrdinarias.rubricas.forEach(el => {
        balancete.despesasOrdinarias.totalRubricas += Number(el.valor_total);
      });
      balancete.despesasOrdinarias.totalRubricas = Math.round(Number(balancete.despesasOrdinarias.totalRubricas) * 100) / 100;
    }

    // DESPESAS EXTRAORDINARIAS
    if (data.despesas_extra) {
      balancete.despesasExtraordinarias.rubricas = data.despesas_extra;

      // COMPUTE TOTALS
      balancete.despesasExtraordinarias.totalRubricas = 0;
      balancete.despesasExtraordinarias.rubricas.forEach(el => {
        balancete.despesasExtraordinarias.totalRubricas += Number(el.valor_total);
      });
      balancete.despesasExtraordinarias.totalRubricas = Math.round(Number(balancete.despesasExtraordinarias.totalRubricas) * 100) / 100;



      balancete.despesasExtraordinarias.rubricas = balancete.despesasExtraordinarias.rubricas.filter(el => el.valor_total > 0);
    }

    // RECEITAS - EXERCICIO ANTERIOR
    if (data.exerc_anterior && data.exerc_anterior.length > 0) {
      balancete.receitas.exerAnterior.gestaoCorrente = Number(data.exerc_anterior.find(el => (el.tipo_proc === 'O')).valor_total);
      balancete.receitas.exerAnterior.fundoReserva = Number(data.exerc_anterior.find(el => (el.tipo_proc === 'F')).valor_total);
      balancete.receitas.exerAnterior.seguro = (data.exerc_anterior.find(el => (el.tipo_proc === 'S'))) ? Number(data.exerc_anterior.find(el => (el.tipo_proc === 'S')).valor_total) : null;
      balancete.receitas.exerAnterior.penalizacao = (data.exerc_anterior.find(el => (el.tipo_proc === 'P'))) ? Number(data.exerc_anterior.find(el => (el.tipo_proc === 'P')).valor_total) : null;
      balancete.receitas.exerAnterior.total = 0;
      Object.keys(balancete.receitas.exerAnterior).forEach(key => { if (key !== 'total') balancete.receitas.exerAnterior.total += balancete.receitas.exerAnterior[key]; });
    } else {
      balancete.receitas.exerAnterior.gestaoCorrente = 0;
      balancete.receitas.exerAnterior.fundoReserva = 0;
      balancete.receitas.exerAnterior.seguro = 0;
      balancete.receitas.exerAnterior.penalizacao = 0;
      balancete.receitas.exerAnterior.total = 0;
    }

    // RECEITAS - EXERCICIO ACTUAL
    if (data.exerc_actual) {
      balancete.receitas.exerActual.gestaoCorrente = Number(data.exerc_actual.gc[0].valor_total);
      balancete.receitas.exerActual.fundoReserva = Number(data.exerc_actual.fr[0].valor_total);
      balancete.receitas.exerActual.seguro = Number(data.exerc_actual.seg[0].valor_total);
      balancete.receitas.exerActual.penalizacao = Number(data.exerc_actual.penalizacao[0].valor_total);
      balancete.receitas.exerActual.creditosDisponiveis = Number(data.exerc_actual.cred_disponivel[0].valor_total);
      balancete.receitas.exerActual.creditosUtilizados = -1 * Number(data.exerc_actual.cred_utilizado[0].valor_total);
      balancete.receitas.exerActual.pagAdiantados = 0;
      balancete.receitas.exerActual.total = 0;
      Object.keys(balancete.receitas.exerActual).forEach(key => { if (key !== 'total' && key !== 'receitasExtras') balancete.receitas.exerActual.total += balancete.receitas.exerActual[key]; });

      // RECEITAS EXTRAS / PENALIZAÇÔES / ETC
      balancete.receitas.exerActual.receitasExtras = [];
      data.exerc_actual.extra.forEach(el => {
        balancete.receitas.exerActual.receitasExtras.push({ descricao: el.descricao, valor: Number(el.valor_total) });

        balancete.receitas.exerActual.total += Number(el.valor_total);
      });
    }

    // PAGAMENTOS POSTERIORES
    if (data.pag_adiantados) {
      balancete.receitas.pagAdiantados.gestaoCorrente = Number(data.pag_adiantados.gc[0].valor_total);
      balancete.receitas.pagAdiantados.fundoReserva = Number(data.pag_adiantados.fr[0].valor_total);
      balancete.receitas.pagAdiantados.seguro = Number(data.pag_adiantados.seg[0].valor_total);
      balancete.receitas.pagAdiantados.total = balancete.receitas.pagAdiantados.gestaoCorrente + balancete.receitas.pagAdiantados.fundoReserva + balancete.receitas.pagAdiantados.seguro;
    }

    // RECEITAS EXTRAORDINARIAS
    if (data.receitas_extra) {
      balancete.receitasExtraordinarias.rubricas = data.receitas_extra;

      // COMPUTE TOTALS
      balancete.receitasExtraordinarias.totalRubricas = 0;
      balancete.receitasExtraordinarias.rubricas.forEach(el => {
        balancete.receitasExtraordinarias.totalRubricas += Number(el.valor_total);
      });
      balancete.receitasExtraordinarias.totalRubricas = Math.round(Number(balancete.receitasExtraordinarias.totalRubricas) * 100) / 100;
    }

    return balancete;
  }

  getYear(descricao) {
    let year = descricao.match(/\d{4}/g);
    return (year && year.length) ? Number(year[0]) : null;
  }


  getEachContaCorrente(exercicio: SelAno): Promise<contaCorrentePDFReport> {
    return new Promise((resolve) => {
      let fraccoesSel = this.presencasList.map(el => { return el.cod_fracao });
      let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
      let endDate = this.utils.getDate(exercicio.data_fim);

      // let req = [];
      // fraccoesSel.forEach(fraccao_cod => {
      //   req.push(this.api.getContaCorrenteResumo('condominos', cod, [fraccao_cod], null, endDate, '0'));
      // })

      if (fraccoesSel.length == 0) {
        resolve(null);
        return;
      }

      this.fetchingContaCorrente = true;
      // GET RESUMED REPORT
      this.contasCorrentesSubs = this.api.getContaCorrenteResumo('condominos', cod, fraccoesSel, null, endDate, '0').subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          let dataset = [];

          // res.forEach(el => { dataset = dataset.concat(el.data); });

          let datasetAux = [];
          res.data.forEach(elem => {
            if ((Number(elem.debito) === 0 && Number(elem.saldo) === 0)) {

              let entries = dataset.filter(elem2 => (elem.cod_fraccao === elem2.cod_fraccao));

              if (entries && entries.length === 1) {
                datasetAux.push(elem);
              }

            } else {
              datasetAux.push(elem);
            }
          });
          dataset = JSON.parse(JSON.stringify(datasetAux));

          let contaCorrente = this.generateReportResumeListObj(dataset, 'condominos', parseInt(exercicio.ano), endDate);
          contaCorrente.reportList.forEach((el, i) => {
            if (i !== 0 && contaCorrente.reportList[i - 1].hasOwnProperty('fraccao') && el.hasOwnProperty('fraccao') && contaCorrente.reportList[i - 1]['fraccao'].split(' - ')[0] !== el['fraccao'].split(' - ')[0]) {
              contaCorrente.reportList[i - 1]['endFraccao'] = true;
            }
          });
          contaCorrente.hasAnteriorProprietario = (contaCorrente.reportList.find(el => (el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1))) ? true : false;

          this.fetchingContaCorrente = false;
          resolve(contaCorrente);

        } else {
          this.fetchingContaCorrente = false;
          resolve(null);
        }
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.fetchingContaCorrente = false;
        resolve(null);
      });
    });
  }

  getContasCorrente(): Promise<boolean> {
    return new Promise(resolve => {

      if (this.apiSubs.find(el => el === 'CONTAS_CORRENTE')) {
        resolve(null);
        return;
      }

      if (this.ordensTrabalhos.findIndex(el => el.label === 'APRESENTACAO_CONTAS') === -1 || (!this.details && !this.geralForm.get('dt').value)) {
        resolve(null);
        return;
      }

      let dt = this.geralForm.get('dt').value ? this.geralForm.get('dt').value : this.details.dt;

      let default_year = (dt.getFullYear() - 1).toString();
      let exercicios: Array<SelAno> = [{ ano: default_year, id_link: null, data_inicio: '01-01-' + default_year, data_fim: '31-12-' + default_year }];
      let auxOrdemTrabalho = this.ordensTrabalhos.find(el => el.label === 'APRESENTACAO_CONTAS');
      if (auxOrdemTrabalho && auxOrdemTrabalho.selAnos) {
        exercicios = auxOrdemTrabalho.selAnos;
      }

      let req = [];
      exercicios.forEach(ano => {
        req.push(this.getEachContaCorrente(ano));
      })


      this.contaCorrenteReports = [];
      if (!req.length) {
        resolve(true);
        return;
      }

      this.startApiSub('CONTAS_CORRENTE');
      Promise.all<contaCorrentePDFReport>(req).then(res => {
        if (res.findIndex(el => !el) == -1) {
          res.forEach(el => {
            this.contaCorrenteReports.push(el);
          });
        }
        this.finishApiSub('CONTAS_CORRENTE');
        resolve(true);
      }).catch(err => {
        this.finishApiSub('CONTAS_CORRENTE');
        resolve(false);
      })


    });
  }
  generateReportResumeListObj(data, tipoAgrupamento, exercicio: number, endDate: Date): contaCorrentePDFReport {

    let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;

    let contaCorrente: contaCorrentePDFReport = {
      title: cod + ' - ' + this.condominioNome,
      reportType: 'Conta Corrente',
      startDate: null,
      exercicio: exercicio,
      endDate: endDate,
      hasAnteriorProprietario: false,
      reportList: [],
      total: {
        debito: 0,
        credito: 0,
        saldo: 0
      },
    }


    let debito_total_zona = 0;
    let credito_total_zona = 0;
    let saldo_total_zona = 0;

    let prevZona = null;

    let continu = true;

    data.forEach((el, i) => {
      continu = true;

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        fraccao: (el['cod_fraccao'] && el['fraccao_nome']) ? el['cod_fraccao'] + ' - ' + el['fraccao_nome'] : null,
        debito: null,
        credito: null,
        saldo: null,
        label: null
      }

      aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
      aux['saldo'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
      aux['credito'] = aux.debito - aux.saldo;

      if (tipoAgrupamento === 'condominos') {
        let isActProp = this.fraccaoList.find(it => it.proprietario === el['condomino_nome'] && it.cod_fraccao === el['cod_fraccao']) ? true : false;

        if (data.filter(it => it.cod_fraccao === el['cod_fraccao']).length >= 2) {
          if (!isActProp && aux['saldo'] === 0) continu = true;

          aux['fraccao'] += (' / ' + el['condomino_nome'] + ((!isActProp) ? '*' : ''));
        } else {
          aux['fraccao'] += (' / ' + el['condomino_nome']);
        }
      }

      if (continu) {
        // TABLE ROW TOTAL AND SEPARATOR
        if (prevZona !== el['zona_cod'] && el['zona_cod'] !== null) {
          if (i > 0) {
            contaCorrente.reportList.push({ label: 'Total da zona', debito: debito_total_zona, credito: credito_total_zona, saldo: saldo_total_zona, separator: false, separatorCond: true, total: true });
          }

          // ZONA SEPARATOR
          contaCorrente.reportList.push({ label: `${el['zona_nome']}`, separator: true, separatorCond: false, total: false, debito: 0, credito: 0, saldo: 0 });
          debito_total_zona = 0;
          credito_total_zona = 0;
          saldo_total_zona = 0;
        }
        if (el['zona_cod'] !== null) prevZona = el['zona_cod'];

        debito_total_zona += aux.debito;
        credito_total_zona += aux.credito;
        saldo_total_zona += aux.saldo;

        contaCorrente.reportList.push(aux);

        contaCorrente.total.debito += aux.debito;
        contaCorrente.total.credito += aux.credito;
        contaCorrente.total.saldo += aux.saldo;
      }

    });
    if (contaCorrente.reportList.length > 0) {
      contaCorrente.reportList.push({ label: 'Total da zona', debito: debito_total_zona, credito: credito_total_zona, saldo: saldo_total_zona, separator: false, separatorCond: false, total: true });
    }

    let aux = contaCorrente.reportList.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1);
    let auxSet = [...new Set(aux.map(el => el.cod_fraccao))];
    auxSet.forEach(cod_fraccao => {
      let len2 = contaCorrente.reportList.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') === -1 && el.cod_fraccao === cod_fraccao).length;
      if (!len2) {
        let aux1 = contaCorrente.reportList.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1 && el.cod_fraccao === cod_fraccao);
        aux1.forEach((el, i) => {
          if (i > 0) contaCorrente.reportList = contaCorrente.reportList.filter(it => it.fraccao !== el.fraccao);
        });
      } else {
        let aux = contaCorrente.reportList.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1 && el.cod_fraccao === cod_fraccao);
        aux.forEach(el => {
          contaCorrente.reportList = contaCorrente.reportList.filter(it => it.fraccao !== el.fraccao);
        });
      }
    });

    // FILTER AND ORDER PREV CONDOMINO
    contaCorrente.reportList.forEach(el => {
      if (el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1) {
        let fraccoesAux = contaCorrente.reportList.filter(it => (it.cod_fraccao === el.cod_fraccao));
        if (fraccoesAux.length < 2) el.fraccao = el.fraccao.replace('*', '');
      }
    });
    contaCorrente.reportList = contaCorrente.reportList.filter(el => !(el.saldo === 0 && el.fraccao && el.fraccao.indexOf('*') !== -1));

    return contaCorrente;
  }

  // RESPOSTAS PARAMETERS SECTION - FORM LABELS

  firstCallRespSel = true;
  async respSelected(ordemTrab: OrdemTrabalhoList | OrdemTrabalhoDiversos, resp: OrdemTrabalhoItem, resp_index: number, flag = ''): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!resp.respSel) {
        resolve(false);
        return;
      }
      let respSel: number = (resp.respSel as unknown) as number;
      if (respSel === -1) {
        if (flag === 'ASSUNTOS_DIVERSOS_TEXTO_FINAL') {
          this.assuntosDiversosTextoInicial = '';
          resp.respSel = null;
        } else {
          resp.resp = '';
          resp.respSel = null;
        }
      } else if (!(typeof (resp.respSel) == 'string')) {

        let newResp: any = '';
        // SET PARAMETERS
        if (resp.respSel.modelo_texto) {
          newResp = resp.respSel.modelo_texto;

          switch (ordemTrab.label) {
            case 'APRESENTACAO_CONTAS':

              let anoApresentacaoContas = this.geralForm.get('dt').value ? ((this.geralForm.get('dt').value as Date).getFullYear() - 1).toString() : (this.now.getFullYear() - 1).toString();
              let receitaValor = 0;
              let despesaValor = 0;
              let caixaSaldo = 0;
              let bancoSaldo = 0;
              let balancete: balancetePDFReport = null;
              if (ordemTrab.label === 'APRESENTACAO_CONTAS' && 'selAnos' in ordemTrab && resp_index <= (ordemTrab.selAnos.length - 1)) {
                anoApresentacaoContas = ordemTrab.selAnos[resp_index].ano;
                balancete = this.balanceteReports.find(el => el.exercicio == parseInt(anoApresentacaoContas));
                if (balancete) {
                  receitaValor = balancete.receitaValor;
                  despesaValor = balancete.despesaValor;

                  caixaSaldo = balancete.caixaSaldo;

                  bancoSaldo = balancete.bancoSaldo;
                }
              }
              if (newResp.indexOf('[[ ANO ]]') !== -1) {
                newResp = newResp.replace(/\[\[ ANO \]\]/g, anoApresentacaoContas);
              }
              if (newResp.indexOf('[[ RECEITA_VALOR ]]') !== -1) {
                if (balancete && balancete.receitaValor === 0) {
                  if (this.firstCallRespSel) {
                    this.firstCallRespSel = false;
                    setTimeout(() => { this.respSelected(ordemTrab, resp, resp_index); }, 100);
                    resolve(false);
                    return;
                  }
                }
                newResp = newResp.replace(/\[\[ RECEITA_VALOR \]\]/g, this.utils.getNumberFormatted(receitaValor));
                this.firstCallRespSel = true;
              }
              if (newResp.indexOf('[[ RECEITA_VALOR_EXT ]]') !== -1) {
                newResp = newResp.replace(/\[\[ RECEITA_VALOR_EXT \]\]/g, this.utils.getNumberPorExtenso(balancete ? balancete.receitaValor : 0));
              }
              if (newResp.indexOf('[[ DESPESA_VALOR ]]') !== -1) {
                newResp = newResp.replace(/\[\[ DESPESA_VALOR \]\]/g, this.utils.getNumberFormatted(despesaValor));
              }
              if (newResp.indexOf('[[ DESPESA_VALOR_EXT ]]') !== -1) {
                newResp = newResp.replace(/\[\[ DESPESA_VALOR_EXT \]\]/g, this.utils.getNumberPorExtenso(despesaValor));
              }
              if (newResp.indexOf('[[ CAIXA_SALDO ]]') !== -1) {
                newResp = newResp.replace(/\[\[ CAIXA_SALDO \]\]/g, this.utils.getNumberFormatted(caixaSaldo));
              }
              if (newResp.indexOf('[[ CAIXA_SALDO_EXT ]]') !== -1) {
                newResp = newResp.replace(/\[\[ CAIXA_SALDO_EXT \]\]/g, this.utils.getNumberPorExtenso(caixaSaldo));
              }
              if (newResp.indexOf('[[ BANCO_SALDO ]]') !== -1) {
                newResp = newResp.replace(/\[\[ BANCO_SALDO \]\]/g, this.utils.getNumberFormatted(bancoSaldo));
              }
              if (newResp.indexOf('[[ BANCO_SALDO_EXT ]]') !== -1) {
                newResp = newResp.replace(/\[\[ BANCO_SALDO_EXT \]\]/g, this.utils.getNumberPorExtenso(bancoSaldo));
              }
              if (newResp.indexOf('[[ TOTAL_SALDO ]]') !== -1) {
                newResp = newResp.replace(/\[\[ TOTAL_SALDO \]\]/g, this.utils.getNumberFormatted(this.utils.cleanDecimalDigits(Number(caixaSaldo) + Number(bancoSaldo))));
              }
              if (newResp.indexOf('[[ TOTAL_SALDO_EXT ]]') !== -1) {
                newResp = newResp.replace(/\[\[ TOTAL_SALDO_EXT \]\]/g, this.utils.getNumberPorExtenso(this.utils.cleanDecimalDigits((Number(caixaSaldo) + Number(bancoSaldo)))));
              }
              break;

            case 'ORCAMENTO':
              if (!('selAnos' in ordemTrab) || resp_index > (ordemTrab.selAnos.length - 1)) {
                resolve(false);
                return;
              }
              if (flag !== 'RESET') {
                let anoOrcamento = ordemTrab.selAnos[resp_index].ano;
                let orcamento: orcamentoPDFReport = this.orcamentoReports.find(el => el.exercicio == parseInt(anoOrcamento));
                if (!orcamento) {
                  resolve(false);
                  return;
                }

                // ORDEM TRABALHO - ORCAMENTO
                if (newResp.indexOf('[[ ANO_ACT ]]') !== -1) {
                  newResp = newResp.replace(/\[\[ ANO_ACT \]\]/g, this.utils.getNumberFormatted(orcamento.exercicio, false));
                }
                if (newResp.indexOf('[[ DESPESA_ORC_TOTAL ]]') !== -1) {
                  newResp = newResp.replace(/\[\[ DESPESA_ORC_TOTAL \]\]/g, this.utils.getNumberFormatted(orcamento.despesaOrcTotal));
                }
                if (newResp.indexOf('[[ DESPESA_ORC_TOTAL_EXT ]]') !== -1) {
                  newResp = newResp.replace(/\[\[ DESPESA_ORC_TOTAL_EXT \]\]/g, this.utils.getNumberPorExtenso(orcamento.despesaOrcTotal));
                }
                if (newResp.indexOf('[[ QUOTA ]]') !== -1) {
                  newResp = newResp.replace(/\[\[ QUOTA \]\]/g, this.utils.getNumberFormatted(orcamento.quota));
                }
                if (newResp.indexOf('[[ QUOTA_EXT ]]') !== -1) {
                  newResp = newResp.replace(/\[\[ QUOTA_EXT \]\]/g, this.utils.getNumberPorExtenso(orcamento.quota));
                }
                if (newResp.indexOf('[[ QUOTA_LOJ ]]') !== -1) {
                  newResp = newResp.replace(/\[\[ QUOTA_LOJ \]\]/g, this.utils.getNumberFormatted(orcamento.quotaLoja));
                }
                if (newResp.indexOf('[[ QUOTA_LOJ_EXT ]]') !== -1) {
                  newResp = newResp.replace(/\[\[ QUOTA_LOJ_EXT \]\]/g, this.utils.getNumberPorExtenso(orcamento.quotaLoja));
                }
                if (newResp.indexOf('[[ QUOTA_HAB ]]') !== -1) {
                  newResp = newResp.replace(/\[\[ QUOTA_HAB \]\]/g, this.utils.getNumberFormatted(orcamento.quotaHab));
                }
                if (newResp.indexOf('[[ QUOTA_HAB_EXT ]]') !== -1) {
                  newResp = newResp.replace(/\[\[ QUOTA_HAB_EXT \]\]/g, this.utils.getNumberPorExtenso(orcamento.quotaHab));
                }
              } else {
                this.orcamentoReports = this.orcamentoReports.filter(el => el.id !== ordemTrab.selAnos[resp_index].id_link);
                ordemTrab.selAnos[resp_index].id_link = null;
                await this.setAnexosString();
              }
              break;


            case 'QUOTA_EXTRA':
              let param = resp.parametros.find(el => el.chave == 'PROCESSAMENTO');
              if (param !== undefined) {
                try {
                  let procInfo = await this.processamentos.getProcessamento(param.valor);
                  newResp = this.getQuotaExtraRespFilled(newResp, {
                    valorTotal: procInfo.valor,
                    data_venc: this.utils.getDate(procInfo.data_venc),
                    dt_inicio: this.utils.getDate(procInfo.data),
                    nPrestacoes: procInfo.tipo_reparticao === 'REPARTIDO' ? procInfo.meses_cobranca.match(/S/g).length : 1,
                    mesesCobranca: procInfo.meses_cobranca,
                    tipoReparticao: procInfo.tipo_reparticao
                  });
                } catch (err) {
                  resolve(false);
                  return;
                }
              }
              break;

            case 'EMAIL_OFICIAL':
              resp.parametros = [];
              break;

            default:
              break;
          }
        }

        if (flag === 'ASSUNTOS_DIVERSOS_TEXTO_FINAL') {
          this.assuntosDiversosTextoInicial = newResp;
        } else {
          resp.resp = newResp;
        }
      }

      this.setAnexosIndicators();
      resolve(true);
    });
  }

  condominioNif = null;
  moradaCondominioAta = null;


  balanceteReports: Array<balancetePDFReport> = [];
  contaCorrenteReports: Array<contaCorrentePDFReport> = [];
  orcamentoReports: Array<orcamentoPDFReport> = [];

  pdfReport2 = {
    title: null,
    reportType: null,
    startDate: null,
    endDate: null,
    now: new Date(),
  }

  wait(seconds) {
    return new Promise(resolve => { setTimeout(() => { resolve(true); }, seconds) });
  }


  getReports(): Promise<boolean> {
    return new Promise((resolve) => {
      let anexos = this.anexosAta[0].toLowerCase();
      let req = [];
      if (anexos.indexOf('balancete') !== -1) {
        req.push(this.getBalancetes());
      }
      if (anexos.indexOf('orçamento') !== -1) {
        req.push(this.getOrcamentos());
      }
      if (anexos.indexOf('conta corrente') !== -1) {
        req.push(this.getContasCorrente());
      }

      if (!req.length) {
        resolve(true);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      Promise.all(req).then(res => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(true);
      }).catch(err => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(false);
      });
    });
  }

  anexosAtaArr = [];
  ordemTrabalhosArr = [];
  cartasAtas = [];
  regPresencasAta = [];
  convocatoriasProcuracoesEmail = [];

  // this.exportPdf('ata', [], true, true)

  exportPdf(target, cartaEmailList = [], toExport = true, toEmail = false) {
    return new Promise(async resolve => {
      this.cdRef.detectChanges();
      let base64 = null;

      if (!this.introAta) {
        if (this.introAtaOrig) {
          this.introAta = this.introAtaOrig;
        } else {
          resolve(false);
          return;
        }
      }

      this.folhaNum = Number(this.folhaNum);

      let auxIntro = this.introAta.split('[[ ORDEM_TRABALHOS_LIST ]]');
      let auxIntroFirst = '';
      let auxIntroLast = '';
      if (auxIntro.length > 0) {
        auxIntroFirst = auxIntro[0];
        if (auxIntro.length > 1) auxIntroLast = auxIntro[1];
      }

      this.introAtafirst = auxIntroFirst.split('\n');
      if (this.introAtafirst.length > 0 && this.introAtafirst[0] === '') this.introAtafirst.shift();
      if (this.introAtafirst.length > 0 && this.introAtafirst[this.introAtafirst.length - 1] === '') this.introAtafirst.pop();

      this.introAtaLast = auxIntroLast.split('\n');
      if (this.introAtaLast.length > 0 && this.introAtaLast[0] === '') this.introAtaLast.shift();
      if (this.introAtaLast.length > 0 && this.introAtaLast[this.introAtaLast.length - 1] === '') this.introAtaLast.pop();

      this.moradaCondominio = '';
      this.fraccaoList[0]['moradaCondominio'].forEach(el => {
        this.moradaCondominio += (this.moradaCondominio === '') ? el : ', ' + el;
      });

      let data = this.geralForm.getRawValue();
      if (data && (data.postalCode !== null || data.locality !== null)) {
        // this.moradaCondominioAta = data.morada + '\n' + ( (data.postalCode) ? data.postalCode : null ) + ' ' + ( (data.locality) ? data.postalCode : null );
        this.moradaCondominioAta = data.morada + '\n' + ((data.locality) ? data.locality : null) + ', ' + ((data.postalCode) ? data.postalCode : null);
      } else {
        this.moradaCondominioAta = data.morada;
      }

      this.assembleiaDatePDF = this.utils.getFormatedDate(this.geralForm.get('dt').value);
      this.assembleiaHourPDF = formatDate(this.geralForm.get('dt').value, 'HH:mm', this.locale);
      this.assembleiaAltDatePDF = this.utils.getFormatedDate(this.geralForm.get('dt_alter').value);
      this.assembleiaAltHourPDF = formatDate(this.geralForm.get('dt_alter').value, 'HH:mm', this.locale);
      this.moradaAssembleia = {
        morada: this.geralForm.get('morada').value,
        postalCode: this.geralForm.get('postalCode').value,
        locality: this.geralForm.get('locality').value,
      };

      let filename = null;

      // HANDLE ORDEM DE TRABALHOS
      this.ordensTrabalhos.filter(el => el.label !== 'ASSUNTOS_DIVERSOS' && el.id != 5).forEach(el => {
        el.respsAta = [];
        el.resps.forEach(resp => {
          if (resp.resp) {
            let arrAux = resp.resp.split('\n');
            while (arrAux.length > 0 && (arrAux[0] === '' || arrAux[arrAux.length - 1] === '')) {
              if (arrAux[0] === '') arrAux.shift();
              if (arrAux[arrAux.length - 1] === '') arrAux.pop();
            }
            // if (arrAux.length > 0 && arrAux[0] === '') arrAux.shift();
            // if (arrAux.length > 0 && arrAux[arrAux.length - 1] === '') arrAux.pop();

            el.respsAta.push(arrAux);
          } else {
            el.respsAta.push(['']);
          }
        });
      });

      // HANDLE ORDEM DE TRABALHOS (ASSUNTOS DIVERSOS)
      this.assuntosDiversosRespObj.forEach(el => {
        if (el.resp) {
          let arrAux = el.resp.split('\n');
          while (arrAux.length > 0 && (arrAux[0] === '' || arrAux[arrAux.length - 1] === '')) {
            if (arrAux[0] === '') arrAux.shift();
            if (arrAux[arrAux.length - 1] === '') arrAux.pop();
          }
          // if (arrAux.length > 0 && arrAux[0] === '') arrAux.shift();
          // if (arrAux.length > 0 && arrAux[arrAux.length - 1] === '') arrAux.pop();

          el.respsAta = arrAux;
        } else {
          el.respsAta = [''];
        }
      });

      // HANDLE ASSUNTOS DIVERSOS TEXTO INICIAL
      if (this.assuntosDiversosTextoInicial) {
        let arrAux = this.assuntosDiversosTextoInicial.split('\n');
        if (arrAux.length > 0 && arrAux[0] === '') arrAux.shift();
        if (arrAux.length > 0 && arrAux[arrAux.length - 1] === '') arrAux.pop();

        this.assuntosDiversosTextoInicialArr = arrAux;
      } else {
        this.assuntosDiversosTextoInicialArr = [''];
      }

      // HANDLE ANEXOS
      let auxAnexo = this.anexosAta[0].split('\n');
      if (auxAnexo.length > 0 && auxAnexo[0] === '') auxAnexo.shift();
      if (auxAnexo.length > 0 && auxAnexo[auxAnexo.length - 1] === '') auxAnexo.pop();
      this.anexosAtaArr = auxAnexo;

      let cod = null;
      let exercicio = null;
      let startDate = null;
      let endDate = null;

      let res = await this.getReports();
      if (!res) resolve(false);

      switch (target) {
        case 'carta-ata':
          filename = 'Ata' + '_Nº' + this.ataNum + '_' + formatDate(new Date(), this.format, this.locale);

          // FILTER LIST BASED ON SELECTION
          this.convocatoriasProcuracoes = JSON.parse(JSON.stringify(this.fraccaoList));
          this.convocatoriasProcuracoes.forEach(el => {
            el['checkedCarta'] = cartaEmailList.find(it => (it.cod_fraccao === el.cod_fraccao)).checkedCarta;
            el['checkedEmail'] = cartaEmailList.find(it => (it.cod_fraccao === el.cod_fraccao)).por_email;
          });
          this.cartasAtas = this.convocatoriasProcuracoes.filter(el => (el.checkedCarta));

          if (this.cartasAtas.length > 0) {
            // CHECK FOR THE CONDITION TO PRINT THE CTT DOCUMENT FOR CORREIO REGISTDADO
            let auxRegCtt = null;
            this.correioRegistadoList = [];
            this.despesasCttList.filter(el => el.tipo === 'REGISTADO').forEach(el => {
              auxRegCtt = this.convocatoriasProcuracoes.find(it => it.cod_fraccao === el.codFraccao);
              if (auxRegCtt) {
                this.correioRegistadoList.push({
                  ...el,
                  moradaProprietario: auxRegCtt.morada_proprietario,
                });
              }
            });
            if (this.correioRegistadoList.length) {
              setTimeout(() => {
                let promisses = [
                  this.pdfDocCttController.export(),
                  this.pdfCartaAtaController.export(),
                ];
                Promise.all(promisses).then(async groups => {
                  const rootGroup = new Group({
                    pdf: {
                      multiPage: true
                    }
                  });
                  groups.forEach((group) => {
                    rootGroup.append(...group.children);
                  });

                  return exportPDF(rootGroup, { paperSize: 'A4' });
                }).then(async dataUri => {
                  this.utils.downloadFile(dataUri, filename);
                  // saveAs(dataUri, filename + '.pdf', {
                  //   proxyURL: this.appConfig.fileProxyUrl,
                  //   forceProxy: true,
                  //   proxyTarget: '_blank',
                  // });
                  this.downloadAtaAssinada();
                });
              }, 1);
            } else {
              setTimeout(() => {
                this.pdfCartaAtaController.proxyURL = this.appConfig.fileProxyUrl;
                this.pdfCartaAtaController.forceProxy = true;
                this.pdfCartaAtaController.proxyTarget = '_blank';
                this.pdfCartaAtaController.saveAs(filename + '.pdf');

                this.downloadAtaAssinada();

              });
            }
          }

          // SEND EMAILS
          let convocatoriasProcuracoesBCK = JSON.parse(JSON.stringify(this.convocatoriasProcuracoes));
          if (cartaEmailList.length > 0) {
            cartaEmailList.filter(el => (!el.checkedEmail)).forEach(el => {
              convocatoriasProcuracoesBCK = convocatoriasProcuracoesBCK.filter(item => (item.cod_fraccao !== el.cod_fraccao));
            });
          }

          cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
          if (convocatoriasProcuracoesBCK.length == 0) {
            if (cartaEmailList.findIndex(el => el.checkedCarta || el.checkedEmail) !== -1) return;

            this.saveRegistoComunicacao('ASSEMBLEIAS', this.details.id_assembleia, cod, 'Cópia da ata assinada enviada', [], this.userSession.getUserId(), new Date(), null);
            
            if (this.convProcModalRef) this.convProcModalRef.approve();
            return;
          }

          try {
            base64 = await this.genAndSendAnexoAtaAssinada(convocatoriasProcuracoesBCK, 'SIGNED_ATA');
          } catch(err) {
            return;
          }

          let emailList: {fraccao: string, name: string, email?: string}[] = [];
          convocatoriasProcuracoesBCK.forEach(prop => {
            let email = (prop.email_proprietario) ? prop.email_proprietario + (prop.email_proprietario_2 ? ';' + prop.email_proprietario_2 : '') : null;
            let newElement = {
              fraccao: prop.fraccao,
              name: prop.proprietario,
              email: email,
            };
            emailList.push(newElement);
          })
          let obj = this.details.id_ata_ficheiro ? { fileId: this.details.id_ata_ficheiro } : null;
          this.saveRegistoComunicacao('ASSEMBLEIAS', this.details.id_assembleia, cod, 'Cópia da ata assinada enviada por email', emailList, this.userSession.getUserId(), new Date(), null, obj);

          break;
        case 'ata':
          this.regPresencasAta = [];

          this.fraccaoList.forEach(el => {
            let entry = this.presencasList.find(it => el.cod_fraccao === it.cod_fracao);

            if (entry && entry.present) { this.regPresencasAta.push(el); }
          });

          let moradaPredio = null;
          let data = this.geralForm.getRawValue();

          if (data && (data.postalCode !== null || data.locality !== null)) {
            // moradaPredio = data.morada.replace(/\n/g, ', ') + ', ' + ( (data.postalCode) ? data.postalCode : '' ) + ' ' + ( (data.locality) ? data.locality : '' );
            moradaPredio = data.morada.replace(/\n/g, ', ') + ', ' + ((data.locality) ? data.locality : '') + ', ' + ((data.postalCode) ? data.postalCode : '');
          } else {
            if (data.morada) moradaPredio = data.morada.replace(/\n/g, ', ');
          }

          if (moradaPredio !== null) this.moradaCondominio = moradaPredio;

          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
          base64 = await new Promise(resolve => {
            setTimeout(() => {
              filename = 'Ata' + '_Nº' + this.ataNum + '_' + formatDate(new Date(), this.format, this.locale);
              cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;

              exercicio = (this.utils.getDate(this.details.dt)).getFullYear() - 1;
              startDate = this.utils.getDate('01-01-' + exercicio);
              endDate = this.utils.getDate('31-12-' + exercicio);

              setTimeout(async () => {
                let promisses = [];

                if (this.anexos.balancete && this.anexos.contaCorrente) {
                  if (this.pdfBalanceteController.length != this.pdfContaCorrenteController.length) {
                    await this.getBalancetes();
                    await this.getContasCorrente();
                  }
                  let balancetes = this.pdfBalanceteController.toArray();
                  let contasCorrente = this.pdfContaCorrenteController.toArray();
                  if (contasCorrente.length === balancetes.length) {
                    for (let i = 0; i < this.pdfBalanceteController.length; i++) {
                      promisses.push(balancetes[i].export());
                      promisses.push(contasCorrente[i].export());
                    }
                  }
                } else if (this.anexos.balancete) {
                  this.pdfBalanceteController.forEach(balancete => {
                    promisses.push(balancete.export());
                  });
                } else if (this.anexos.contaCorrente) {
                  this.pdfContaCorrenteController.forEach(contaCorrente => {
                    promisses.push(contaCorrente.export());
                  });
                }


                if (this.anexos.orcamento) {
                  this.pdfOrcamentoController.forEach((orcamento, i) => {
                    promisses.push(orcamento.exportPDF(null, null, this.orcamentoReports[i].id));
                  });
                }

                Promise.all(promisses).then(async groups => {
                  const rootGroup = new Group({
                    pdf: {
                      multiPage: true
                    }
                  });
                  groups.forEach((group) => {
                    rootGroup.append(...group.children);
                  });

                  return exportPDF(rootGroup, { paperSize: 'A4' });
                }).then(async dataUri => {
                  Promise.all([this.pdfAtaController.export()]).then(async groups => {
                    const rootGroup = new Group({
                      pdf: {
                        multiPage: true
                      }
                    });
                    groups.forEach((group) => {
                      rootGroup.append(...group.children);
                    });

                    return exportPDF(rootGroup, { paperSize: 'A4' });
                  }).then(async dataUriAta => {
                    if (this.anexos.outros || this.anexos.presencas_procuracoes) {
                      let auxAttachments = [dataUri];

                      // ADD LISTA REPRESENTANTES IF NEEDED
                      if (this.anexos.presencas_procuracoes) {
                        if (this.details.presencas_representantes_ficheiro) {
                          // auxAttachments.push(this.details.presencas_representantes_ficheiro);
                          auxAttachments = [this.details.presencas_representantes_ficheiro].concat(auxAttachments);
                        } else {
                          let aux = [];

                          if (this.details.presencas_ficheiro) {
                            aux.push(this.details.presencas_ficheiro);
                          }

                          if (this.details.representantes_ficheiro) {
                            aux.push(this.details.representantes_ficheiro);
                          }

                          auxAttachments = aux.concat(auxAttachments);
                        }
                      }

                      if (this.anexos.outros) {
                        let ordem = 0;
                        let automaticBase64Promises: Array<{ promise: Promise<string>, ordem }> = [];
                        let file_ids: Array<{ file_id, ordem }> = [];
                        let base64s: Array<{ ordem, base64 }> = [];

                        this.ordensTrabalhos.filter(el => el.label !== 'ASSUNTOS_DIVERSOS' && el.id != 5).forEach((el, index) => {
                          switch (el.label) {
                            case 'QUOTA_EXTRA':
                              el.resps.forEach(resp => {
                                resp.parametros.forEach((param, resp_index) => {
                                  this.previewQuotaExtraPDF.forEach((quotaExtraPDF, pdf_index) => {
                                    if (resp_index == pdf_index) {
                                      automaticBase64Promises.push({ promise: quotaExtraPDF.getBase64(null, param.valor), ordem: ordem });
                                      ordem++;
                                    }
                                  })
                                })
                              });
                              break;

                            default:
                              el.resps.filter(resp => resp.anexo_id != null).forEach(resp => {
                                file_ids.push({ file_id: resp.anexo_id, ordem: ordem });
                                ordem++;
                              });
                              break;
                          }
                        });

                        this.assuntosDiversosRespObj.filter(el => el.anexo_id != null).forEach((resp, index) => {
                          file_ids.push({ file_id: resp.anexo_id, ordem: ordem });
                          ordem++;
                        });

                        if (file_ids.length) {
                          base64s = await this.getFileArray(file_ids);
                        }
                        if (automaticBase64Promises.length) {
                          base64s = base64s.concat(await this.resolveAutomaticAnexosPromises(automaticBase64Promises));
                        }

                        base64s = base64s.filter(el => !!el.base64);

                        base64s.sort((a, b) => a.ordem - b.ordem);

                        auxAttachments = auxAttachments.concat(base64s.map(el => el.base64));
                      }

                      let aux: any = await this.mergePdf(filename + '.pdf', [dataUriAta].concat(auxAttachments));

                      if (aux) {
                        let base64Merged = aux;

                        if (!toEmail) {
                          this.utils.downloadFile(base64Merged, filename);

                          // saveAs(base64Merged, filename + '.pdf', { 
                          //   proxyURL: this.appConfig.fileProxyUrl,
                          //   forceProxy: true,
                          //   proxyTarget: '_blank',
                          // });
                        }
                      }
                      resolve(aux);
                    } else {
                      let base64Merged: any = await this.mergePdf(filename + '.pdf', [dataUriAta, dataUri]);

                      if (toEmail) {
                        base64 = base64Merged;

                      } else {
                        this.utils.downloadFile(base64Merged, filename);
                        // saveAs(base64Merged, filename + '.pdf', {
                        //   proxyURL: this.appConfig.fileProxyUrl,
                        //   forceProxy: true,
                        //   proxyTarget: '_blank',
                        // });
                      }
                      resolve(base64Merged);
                    }
                  });
                });
              }, 1);
            }, 1);
          });
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });

          break;
        case 'convocatorias-procuracoes':
          filename = 'Convocatórias_Procurações_' + formatDate(new Date(), this.format, this.locale);

          let conv = null;
          let proc = null;

          this.convocatoriasProcuracoes = [];

          let extraText1 = this.geralForm.get('extraordinaria').value == 1 ? 'extraordinária' : 'ordinária';

          this.fraccaoList.forEach(el => {
            // CONVOCATORIA
            conv = JSON.parse(JSON.stringify(el));
            conv['type'] = 'CONVOCATORIA';

            conv['texto_inicial'] = this.convocatoriaModel.texto_inicial
              .replace(/\[\[ ASSEMBLEIA_DATA \]\]/g, this.assembleiaDatePDF)
              .replace(/\[\[ ASSEMBLEIA_HORA \]\]/g, this.assembleiaHourPDF)
              .replace(/\[\[ ASSEMBLEIA_MORADA \]\]/g, this.moradaAssembleia.morada)
              .replace(/\[\[ ASSEMBLEIA_COD_POSTAL \]\]/g, (this.moradaAssembleia.postalCode) ? this.moradaAssembleia.postalCode : '')
              .replace(/\[\[ ASSEMBLEIA_LOCALIDADE \]\]/g, (this.moradaAssembleia.locality) ? this.moradaAssembleia.locality : '')
              .replace(/\[\[ EXTRAORDINARIA \]\]/g, extraText1)
              .replace(/(?:\r\n|\r|\n)/g, '<br>');

            conv['texto_final'] = this.convocatoriaModel.texto_final
              .replace(/\[\[ ASSEMBLEIA_2A_DATA \]\]/g, this.assembleiaAltDatePDF)
              .replace(/\[\[ ASSEMBLEIA_2A_HORA \]\]/g, this.assembleiaAltHourPDF)
              .replace(/\[\[ EXTRAORDINARIA \]\]/g, extraText1)
              .replace(/(?:\r\n|\r|\n)/g, '<br>');

            this.convocatoriasProcuracoes.push(conv);

            // PROCURACAO
            proc = JSON.parse(JSON.stringify(el));
            proc['type'] = 'PROCURACAO';

            proc['texto_inicial'] = this.procuracaoModel.texto_inicial
              .replace(/\[\[ NOME_PROPRIETARIO \]\]/g, '<b>' + el.proprietario + '</b>')
              .replace(/(?:\r\n|\r|\n)/g, '<br>');

            proc['texto_final'] = this.procuracaoModel.texto_final
              .replace(/\[\[ ASSEMBLEIA_DATA \]\]/g, this.assembleiaDatePDF)
              .replace(/\[\[ ASSEMBLEIA_HORA \]\]/g, this.assembleiaHourPDF)
              .replace(/\[\[ ASSEMBLEIA_2A_DATA \]\]/g, this.assembleiaAltDatePDF)
              .replace(/\[\[ ASSEMBLEIA_2A_HORA \]\]/g, this.assembleiaAltHourPDF)
              .replace(/(?:\r\n|\r|\n)/g, '<br>');

            this.convocatoriasProcuracoes.push(proc);
          });

          let convocatoriasProcuracoesAux = JSON.parse(JSON.stringify(this.convocatoriasProcuracoes));

          // FILTER LIST BASED ON SELECTION
          if (cartaEmailList.length > 0) {
            cartaEmailList.filter(el => (!el.checkedCarta)).forEach(el => {
              this.convocatoriasProcuracoes = this.convocatoriasProcuracoes.filter(item => (item.cod_fraccao !== el.cod_fraccao));
            });
          }

          // FILTER LIST BASED ON SELECTION
          this.convocatoriasProcuracoesEmail = [];
          if (cartaEmailList.length > 0) {
            cartaEmailList.filter(el => (!el.checkedEmail)).forEach(el => {
              convocatoriasProcuracoesAux = convocatoriasProcuracoesAux.filter(item => (item.cod_fraccao !== el.cod_fraccao));
            });
            this.convocatoriasProcuracoesEmail = convocatoriasProcuracoesAux;
          }

          if (toExport && this.convocatoriasProcuracoes.length > 0) {
            // CHECK FOR THE CONDITION TO PRINT THE CTT DOCUMENT FOR CORREIO REGISTDADO
            let auxRegCtt = null;
            this.correioRegistadoList = [];
            this.despesasCttList.filter(el => el.tipo === 'REGISTADO').forEach(el => {
              auxRegCtt = this.convocatoriasProcuracoes.find(it => it.cod_fraccao === el.codFraccao);
              if (auxRegCtt) {
                this.correioRegistadoList.push({
                  ...el,
                  moradaProprietario: auxRegCtt.morada_proprietario,
                });
              }
            });
            if (this.correioRegistadoList.length) {
              setTimeout(() => {
                let promisses = [
                  this.pdfDocCttController.export(),
                  this.pdfConvProcuracoesController.export(),
                ];
                Promise.all(promisses).then(async groups => {
                  const rootGroup = new Group({
                    pdf: {
                      multiPage: true
                    }
                  });
                  groups.forEach((group) => {
                    rootGroup.append(...group.children);
                  });

                  return exportPDF(rootGroup, { paperSize: 'A4' });
                }).then(async dataUri => {
                  this.utils.downloadFile(dataUri, filename);

                  // saveAs(dataUri, filename + '.pdf', {
                  //   proxyURL: this.appConfig.fileProxyUrl,
                  //   forceProxy: true,
                  //   proxyTarget: '_blank',
                  // });
                });
              }, 1);
            } else {
              setTimeout(() => {
                this.pdfConvProcuracoesController.proxyURL = this.appConfig.fileProxyUrl;
                this.pdfConvProcuracoesController.forceProxy = true;
                this.pdfConvProcuracoesController.proxyTarget = '_blank';
                this.pdfConvProcuracoesController.saveAs(filename + '.pdf');
              }, 1);
            }
          }
          break;
        case 'convocatorias':
          filename = 'Convocatórias_' + formatDate(new Date(), this.format, this.locale);

          this.convocatorias = [];
          let extraText = this.geralForm.get('extraordinaria').value == 1 ? 'ordinária' : 'extraordinária';
          this.fraccaoList.forEach(el => {
            el['texto_inicial'] = this.convocatoriaModel.texto_inicial
              .replace(/\[\[ ASSEMBLEIA_DATA \]\]/g, this.assembleiaDatePDF)
              .replace(/\[\[ ASSEMBLEIA_HORA \]\]/g, this.assembleiaHourPDF)
              .replace(/\[\[ ASSEMBLEIA_MORADA \]\]/g, this.moradaAssembleia.morada)
              .replace(/\[\[ ASSEMBLEIA_COD_POSTAL \]\]/g, (this.moradaAssembleia.postalCode) ? this.moradaAssembleia.postalCode : '')
              .replace(/\[\[ ASSEMBLEIA_LOCALIDADE \]\]/g, (this.moradaAssembleia.locality) ? this.moradaAssembleia.locality : '')
              .replace(/\[\[ EXTRAORDINARIA \]\]/g, extraText)
              .replace(/(?:\r\n|\r|\n)/g, '<br>');

            el['texto_final'] = this.convocatoriaModel.texto_final
              .replace(/\[\[ ASSEMBLEIA_2A_DATA \]\]/g, this.assembleiaAltDatePDF)
              .replace(/\[\[ ASSEMBLEIA_2A_HORA \]\]/g, this.assembleiaAltHourPDF)
              .replace(/\[\[ EXTRAORDINARIA \]\]/g, extraText)
              .replace(/(?:\r\n|\r|\n)/g, '<br>');

            this.convocatorias.push(el);
          });

          setTimeout(() => {
            this.pdfConvocatoriasController.proxyURL = this.appConfig.fileProxyUrl;
            this.pdfConvocatoriasController.forceProxy = true;
            this.pdfConvocatoriasController.proxyTarget = '_blank';
            this.pdfConvocatoriasController.saveAs(filename + '.pdf');
          }, 1);
          break;
        case 'procuracoes':
          filename = 'Procurações_' + formatDate(new Date(), this.format, this.locale);

          this.procuracoes = [];
          this.fraccaoList.forEach(el => {
            el['texto_inicial'] = this.procuracaoModel.texto_inicial
              .replace(/\[\[ NOME_PROPRIETARIO \]\]/g, '<b>' + el.proprietario + '</b>')
              .replace(/(?:\r\n|\r|\n)/g, '<br>');

            el['texto_final'] = this.procuracaoModel.texto_final
              .replace(/\[\[ ASSEMBLEIA_DATA \]\]/g, this.assembleiaDatePDF)
              .replace(/\[\[ ASSEMBLEIA_HORA \]\]/g, this.assembleiaHourPDF)
              .replace(/\[\[ ASSEMBLEIA_2A_DATA \]\]/g, this.assembleiaAltDatePDF)
              .replace(/\[\[ ASSEMBLEIA_2A_HORA \]\]/g, this.assembleiaAltHourPDF)
              .replace(/(?:\r\n|\r|\n)/g, '<br>');

            this.procuracoes.push(el);
          });

          setTimeout(() => {
            this.pdfProcuracoesController.proxyURL = this.appConfig.fileProxyUrl;
            this.pdfProcuracoesController.forceProxy = true;
            this.pdfProcuracoesController.proxyTarget = '_blank';
            this.pdfProcuracoesController.saveAs(filename + '.pdf');
          }, 1);
          break;
        case 'convocatoria-geral':
          filename = 'Convocatória_' + formatDate(new Date(), this.format, this.locale);

          this.convocatoriaGeralModel.texto_inicial = this.convocatoriaGeralModel.texto_inicial
            .replace(/\[\[ MORADA_CONDOMINIO \]\]/g, this.moradaCondominio)
            .replace(/\[\[ ASSEMBLEIA_DATA \]\]/g, '<b>' + this.assembleiaDatePDF + '</b>')
            .replace(/\[\[ ASSEMBLEIA_HORA \]\]/g, '<b>' + this.assembleiaHourPDF + '</b>')
            .replace(/(?:\r\n|\r|\n)/g, '<br>');

          this.convocatoriaGeralModel.texto_final = this.convocatoriaGeralModel.texto_final
            .replace(/\[\[ ASSEMBLEIA_2A_HORA \]\]/g, '<b>' + this.assembleiaAltHourPDF + '</b>')
            .replace(/(?:\r\n|\r|\n)/g, '<br>');

          setTimeout(() => {
            this.pdfConvocatoriaController.proxyURL = this.appConfig.fileProxyUrl;
            this.pdfConvocatoriaController.forceProxy = true;
            this.pdfConvocatoriaController.proxyTarget = '_blank';
            this.pdfConvocatoriaController.saveAs(filename + '.pdf');
          }, 1);
          break;
        case 'reg-presencas':
          filename = 'Registo_Presenças_' + formatDate(new Date(), this.format, this.locale);

          this.regPresencas = null;
          this.permTotal = 0;
          this.fraccaoList.forEach(el => {
            this.permTotal += el.permilagem;
          });

          setTimeout(() => {
            this.pdfRegPresencasController.proxyURL = this.appConfig.fileProxyUrl;
            this.pdfRegPresencasController.forceProxy = true;
            this.pdfRegPresencasController.proxyTarget = '_blank';
            this.pdfRegPresencasController.saveAs(filename + '.pdf');
          }, 1);
          break;
        case 'balancete': break;
        case 'orcamento': break;
        case 'conta-corrente': break;
      }

      resolve(base64);
    });
  }

  resolveAutomaticAnexosPromises(automaticAnexos: Array<{ promise: Promise<string>, ordem: number }>): Promise<Array<{ base64: string, ordem: number }>> {
    return new Promise((resolve, reject) => {
      let promises = automaticAnexos.map(el => el.promise);
      Promise.all(promises).then(resArr => {
        resolve(resArr.map((el, i) => {
          return {
            base64: el,
            ordem: automaticAnexos[i].ordem,
          }
        }))
      }).catch(err => {
        reject(null);
      })
    })
  }

  resetAtaEntry(target) {
    if (target.label === 'ASSUNTOS_DIVERSOS') {

      if (this.assuntosDiversosRespObj && this.assuntosDiversosRespObj.length > 0) {
        this.assuntosDiversosTextoInicial = this.assuntosDiversosTextoInicialOrig;
        this.assuntosDiversosRespObj.forEach((resp, i) => {
          this.resetOrdemEntry(resp, resp, i);
        });
      } else {
        target.resp = this.assuntosDiversosOrig;
      }

      return;
    }

    if (target === 'RESET_INTRODUCAO_ATA') {
      this.introAta = this.introAtaOrig;
      return;
    }

    if ('resps' in target) {
      target.resps.forEach((resp, index) => {
        if (resp.respSel === null) {
          if (resp.hasOwnProperty('respOrig') && resp.hasOwnProperty('respSelOrigId') && resp.respOrig && resp.respSelOrigId) {
            resp.resp = resp.respOrig;
            let respSel = target.respOpts.find(el => el.value.id === resp.respSelOrigId);
            resp.respSel = respSel ? respSel.value : null;
          }
          this.setAnexosIndicators();
        } else {
          this.respSelected(target, resp, index, 'RESET');
        }
      });
    }
  }

  selectDateModal() {
    return new Promise(resolve => {
      this.dataDividasModalRef = this.modalService
        .open(this.dataDividasAlertConfig)
        .onApprove(() => { resolve(true); })
        .onDeny(() => { resolve(false); });
    });
  }

  contaCorrenteDetalhada = [];
  creditosList = [];
  contaCorrenteLimitDate = null;
  contaCorrenteLimitDatePrev = null;
  getContaCorrenteDetalhada() {
    return new Promise(async resolve => {

      let exercicio = (this.utils.getDate(this.details.dt)).getFullYear() - 1;
      let auxOrdemTrabalho = this.ordensTrabalhos.find(el => el.label === 'APRESENTACAO_CONTAS');
      if (auxOrdemTrabalho && this.getYear(auxOrdemTrabalho.descricao)) {
        exercicio = this.getYear(auxOrdemTrabalho.descricao);
      }

      this.contaCorrenteLimitDate = this.utils.getDate('31-12-' + exercicio);
      let res = await this.selectDateModal();
      if (!res) {
        resolve([]);
        return;
      }

      if ((this.contaCorrenteLimitDate && this.contaCorrenteLimitDatePrev && this.contaCorrenteLimitDate.getTime() === this.contaCorrenteLimitDatePrev.getTime())) {
        resolve(this.contaCorrenteDetalhada);
      } else {
        this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });

        let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
        let req = [];

        let endDate = this.contaCorrenteLimitDate;

        this.fetchingContaCorrente = true;
        this.selectionFraccaoList.forEach(fraccao => {
          req.push(this.api.getContaCorrente('fraccoes', 'fraccao', cod, [fraccao.cod_fraccao], null, endDate, 0));
        });

        // req.push(this.api.getEachContaCorrente('fraccoes', 'fraccao', cod, ['AD'], null, endDate, 0));

        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
        this.contasCorrentesDetalhadoSubs = forkJoin(req).subscribe(res => {
          this.creditosList = [];
          if (res[0].hasOwnProperty('success') && res[0].success) {
            this.contaCorrenteLimitDatePrev = this.contaCorrenteLimitDate;

            let dataset = [];

            res.forEach(el => { dataset = dataset.concat(el.data); });

            // REMOVE CREDITOS FROM AVISOS LIST
            dataset = dataset.filter(el => (el.tipo_doc === 'A'));

            // SPLIT MONTHS AND YEAR
            this.contaCorrenteDetalhada = res.map(el => {

              // CHECK CREDITOS
              let saldoCreditosArr = el.data.filter(aviso => (aviso.tipo_doc === 'C' && Number(aviso.saldo) > 0));

              let fraccao_cod = (el.data.length > 0) ? el.data[0]['cod_fraccao'] : null;

              // CREATE CRETIDO ARR OBJECT
              if (fraccao_cod && !this.creditosList.find(el => (el.codFraccao === fraccao_cod))) {
                this.creditosList.push({
                  codFraccao: fraccao_cod,
                  creditos: saldoCreditosArr,
                });
              }

              let seenCondomino = '';
              let seenCondominoNome = [];
              el.data.forEach(aviso => {
                if (seenCondomino.indexOf(',' + aviso.condomino_cod + ',') === -1) {
                  seenCondomino += (',' + aviso.condomino_cod + ',');

                  seenCondominoNome.push({
                    condomino_nome: aviso.condomino_nome,
                    condomino_cod: aviso.condomino_cod,
                    divida: null,
                    credito: null,
                  });
                }
              });

              let fraccao = [];

              seenCondomino.split(',').filter(aux => (aux)).forEach(condomino => {

                let monthPrev = null; let yearPrev = null;
                let yearMonth = [];

                el.data.filter(el => (el.condomino_cod === condomino)).forEach(el => {
                  let month = el.data_emis.split('-')[1];
                  let year = el.data_emis.split('-')[2];

                  if (yearPrev === null || monthPrev === null || (yearPrev !== year || monthPrev !== month)) {
                    yearMonth.push({
                      month: month,
                      year: year,
                    });
                  }

                  monthPrev = month;
                  yearPrev = year;
                });

                fraccao.push({
                  condomino_cod: condomino,
                  yearMonth: yearMonth,
                });

              });

              return {
                fraccao_cod: fraccao_cod,
                condomino_nome: seenCondominoNome,
                data: fraccao,
              }

            });

            // COMPUTE TOTALS PER YEAR AND MONTH
            this.contaCorrenteDetalhada.forEach(fraccao => {

              fraccao.data.forEach(condomino => {
                condomino.yearMonth.forEach(interval => {
                  interval['avisos'] = dataset.filter(aviso => (aviso.cod_fraccao === fraccao.fraccao_cod && aviso.condomino_cod === condomino.condomino_cod && aviso.data_emis.indexOf(interval.month + '-' + interval.year) !== -1));
                  interval['avisosOrc'] = interval['avisos'].filter(aviso => (aviso.tipo_proc === 'O' || aviso.tipo_proc === 'F' || aviso.tipo_proc === 'S'));
                  interval['avisosExtra'] = interval['avisos'].filter(aviso => (aviso.tipo_proc === 'E' || !aviso.tipo_proc));

                  // COMPUTE SALDO
                  interval['saldo'] = 0;
                  interval['avisos'].forEach(a => { interval['saldo'] += (Number(a.saldo)); });
                  interval['saldo'] = Math.round(interval['saldo'] * 100) / 100;

                  // COMPUTE SALDO ORC
                  interval['saldoOrc'] = 0;
                  interval['avisosOrc'].forEach(a => { interval['saldoOrc'] += (Number(a.saldo)); });
                  interval['saldoOrc'] = Math.round(interval['saldoOrc'] * 100) / 100;

                  // COMPUTE SALDO EXTRA
                  interval['saldoExtra'] = 0;
                  interval['avisosExtra'].forEach(a => { interval['saldoExtra'] += (Number(a.saldo)); });
                  interval['saldoExtra'] = Math.round(interval['saldoExtra'] * 100) / 100;

                  // COMPUTE QUOTA
                  interval['quota'] = 0;

                  let orc = dataset.find(el => (el.cod_fraccao === fraccao.fraccao_cod && el.condomino_cod === condomino.condomino_cod && el.data_emis.indexOf('-' + interval.year) !== -1 && el.tipo_proc === 'O' && (el.descricao.toLowerCase().indexOf('ª mensalidade') !== -1 || el.descricao.toLowerCase().indexOf('ª prestação') !== -1)));
                  let fcr = dataset.find(el => (el.cod_fraccao === fraccao.fraccao_cod && el.condomino_cod === condomino.condomino_cod && el.data_emis.indexOf('-' + interval.year) !== -1 && el.tipo_proc === 'F' && (el.descricao.toLowerCase().indexOf('ª mensalidade') !== -1 || el.descricao.toLowerCase().indexOf('ª prestação') !== -1)));
                  let seg = dataset.find(el => (el.cod_fraccao === fraccao.fraccao_cod && el.condomino_cod === condomino.condomino_cod && el.data_emis.indexOf('-' + interval.year) !== -1 && el.tipo_proc === 'S' && (el.descricao.toLowerCase().indexOf('ª mensalidade') !== -1 || el.descricao.toLowerCase().indexOf('ª prestação') !== -1)));

                  if (orc) interval['quota'] += (Number(orc['debito']));
                  if (fcr) interval['quota'] += (Number(fcr['debito']));
                  if (seg) interval['quota'] += (Number(seg['debito']));

                  interval['quota'] = Math.round(interval['quota'] * 100) / 100;
                });
              });

            });

            // CLEAR PAYED ENTRIES
            this.contaCorrenteDetalhada.forEach(fraccao => {
              fraccao.data.forEach(condomino => {
                condomino.yearMonth = condomino.yearMonth.filter(el => (el.saldo > 0));
              });
            });
            this.contaCorrenteDetalhada.forEach(fraccao => {
              fraccao.data = fraccao.data.filter(el => (el.yearMonth.length > 0));
            });

            let aux = [];
            this.contaCorrenteDetalhada.forEach(fraccao => {
              if (fraccao.data.length > 0) {
                fraccao.data.forEach(el => {
                  aux.push({
                    fraccao_cod: fraccao.fraccao_cod,
                    condomino_nome: fraccao.condomino_nome,
                    data: [JSON.parse(JSON.stringify(el))],
                  });
                });
              } else {
                aux.push({
                  fraccao_cod: fraccao.fraccao_cod,
                  condomino_nome: fraccao.condomino_nome,
                  data: [],
                });
              }
            });

            this.selectionFraccaoListDivida = [];
            let creditosListAux = [];
            this.creditosList.forEach(el => {

              // GET PROPRIETARIOS LIST
              let proprietarios = [...new Set(el.creditos.map(item => item.condomino_cod))];

              proprietarios.forEach(item => {
                let credito = 0;
                el.creditos.filter(elem => (elem.condomino_cod === item)).forEach(elem => {
                  credito += Number(elem.saldo);
                });

                creditosListAux.push({
                  cod_fraccao: el.codFraccao,
                  condomino_nome: el.creditos.find(elem => (elem.condomino_cod === item)).condomino_nome,
                  condomino_cod: item,
                  credito: Math.round(credito * 100) / 100,
                });
              });
            });
            this.creditosList = creditosListAux;

            this.contaCorrenteDetalhada.forEach(fraccao => {
              fraccao.condomino_nome.forEach(condomino => {
                let saldo = 0;
                dataset.filter(el => (el.condomino_cod === condomino.condomino_cod && el.cod_fraccao === fraccao.fraccao_cod)).forEach(el => {
                  saldo += Number(el.saldo);
                });
                condomino.divida = Math.round(saldo * 100) / 100;

                let fraccaoAux = this.selectionFraccaoList.find(el => (el.cod_fraccao === fraccao.fraccao_cod));
                if (fraccaoAux) {

                  let credito = (this.creditosList.find(el => (el.condomino_cod === condomino.condomino_cod && el.cod_fraccao === fraccao.fraccao_cod))) ? parseFloat(this.creditosList.find(el => (el.condomino_cod === condomino.condomino_cod)).credito) : 0;

                  fraccaoAux = JSON.parse(JSON.stringify(fraccaoAux));
                  fraccaoAux.proprietario = condomino.condomino_nome;
                  fraccaoAux.divida = saldo;
                  fraccaoAux.credito = credito;

                  this.selectionFraccaoListDivida.push(fraccaoAux);
                }
              });
            });

            this.contaCorrenteDetalhada = JSON.parse(JSON.stringify(aux));

            this.loading = false;
            this.fetchingContaCorrente = false;

            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });

            resolve(res);
          } else {
            this.loading = false;
            this.fetchingContaCorrente = false;

            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
            resolve([]);
          }
        }, err => {
          this.loading = false;
          this.fetchingContaCorrente = false;

          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve([]);
        });
      }
    });
  }

  selectionFraccaoListDivida: Array<FraccaoList> = [];

  selectionFraccaoList: Array<FraccaoList> = []
  selectionFraccaoConvProcList: Array<FraccaoList & { checkedCarta, checkedEmail }> = []
  emailsOficiais: EmailOficial[] = [];
  fetchingContaCorrente = false;
  async addFraccoes(target: OrdemTrabalhoList | string = null, resp: OrdemTrabalhoItem = null, auto = false, updateAtaIntro = false) {
    this.selectionFraccaoList = JSON.parse(JSON.stringify(this.fraccaoList));
    this.selectionFraccaoList.forEach(el => { el.checked = false; });

    if (typeof (target) != 'string' && target.id) {
      if (target.id == 4 || target.id == 18) {
        if (this.fetchingContaCorrente) return;

        (resp.resp.indexOf('[[ FRACCAO_DESCRICAO ]]') !== -1) ? this.maxSelection = 1 : this.maxSelection = 1000;

        let contaCorrente = await this.getContaCorrenteDetalhada();
        if (!Array.isArray(contaCorrente) || contaCorrente.length === 0) return;
      }
    } else {
      switch (target) {
        case 'INTRODUCAO_ATA':
          this.selectionFraccaoList.forEach(el => {
            let auxFrac = this.presencasList.find(it => (it.cod_fracao === el.cod_fraccao));

            if (auxFrac && auxFrac.present) {
              el.checked = true;
            } else {
              el.checked = false;
            }

          });
          break;
      }
    }

    if (!auto) {
      if (target.hasOwnProperty('id') && target['id'] == 4) this.isDividaCondomino = true;
      if (target.hasOwnProperty('id') && target['id'] == 18) this.isLimparDivida = true;
      this.maxSelection = null;
      if (typeof (target) !== 'string' && target.label === 'ELEICAO_ADMINISTRACAO') {
        if (resp.resp.indexOf('[[ FRACCAO_DESCRICAO ]]') !== -1) {
          this.maxSelection = 1;
        } else if (resp.resp.indexOf('[[ FRACCAO_DESCRICAO_LIST ]]') !== -1) {
          this.maxSelection = 2
        }
      }

      this.fraccoesListAlertConfig.size = 'small';
      if (this.isDividaCondomino || this.isLimparDivida) this.fraccoesListAlertConfig.size = 'normal';

      this.addEmailList = null;
      this.fraccoesListModalRef = this.modalService
        .open(this.fraccoesListAlertConfig)
        .onApprove(() => {
          // ADD ADMINISTRADORES TO RESPONSE OBJECT
          if (target.hasOwnProperty('id') && target['id'] == '2') {
            if (resp) {
              resp.parametros = this.selectionFraccaoList.filter(el => (el.checked)).map(el => {
                return {
                  id: null,
                  chave: 'CONDOMINO',
                  valor: el.codEntidade
                }
              });
            }
          }

          if (this.isDividaCondomino || this.isLimparDivida) {
            this.selectionFraccaoList.forEach(f => { f.checked = false; });
            this.selectionFraccaoListDivida.forEach(f => {
              let aux = this.selectionFraccaoList.find(fAux => (fAux.cod_fraccao === f.cod_fraccao));
              if (aux && f.checked) {
                aux.checked = true;
              }
            });
          }

          this.setFraccoesIntroList(target, resp);
          this.loadingModal = false;
          this.isDividaCondomino = false;
          this.isLimparDivida = false;
          this.fraccoesListAlertConfig.size = 'small';

          this.selectionFraccaoListDivida.forEach(f => { f.checked = false; });
        })
        .onDeny(() => { this.maxSelection = null; this.loadingModal = false; this.isDividaCondomino = false; this.isLimparDivida = false; this.fraccoesListAlertConfig.size = 'small'; });
    } else {
      setTimeout(() => { this.setFraccoesIntroList(target, resp, updateAtaIntro); }, 1);
    }

  }

  isLimparDivida = false;
  isDividaCondomino = false;

  alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  result = '';
  resultToPrint = null;

  resultAlt = '';
  resultToPrintAlt = null;

  printToLetter(number, alt = false) {
    let charIndex = number % this.alphabet.length;
    let quotient = number / this.alphabet.length;

    if (charIndex - 1 == -1) {
      charIndex = this.alphabet.length;
      quotient--;
    }

    if (alt) {
      this.resultAlt = this.alphabet.charAt(charIndex - 1) + this.resultAlt;
    } else {
      this.result = this.alphabet.charAt(charIndex - 1) + this.result;
    }

    if (quotient >= 1) {
      this.printToLetter(quotient, alt);
    } else {
      if (alt) {
        this.resultToPrintAlt = this.resultAlt.toLowerCase();
        this.resultAlt = '';
      } else {
        this.resultToPrint = this.result.toLowerCase();
        this.result = '';
      }
    }

    if (alt) return this.resultToPrintAlt;
  }


  addDividaIntoText(data: { cod_condomino, yearMonth: Array<any> }, total, initAdded, initText, finalResp, linhasModelo, linhasModeloOrig, linhasModeloUmMesOrig, quota, newYear, prevMes, ano, mesInicio, mesFim)
    : { total, initAdded, finalResp } {
    let divida = 0;
    if (prevMes === mesFim && !newYear) {
      // data.yearMonth.filter(el => (Number(el.year) === ano && Number(el.month) === mesFim)).forEach(el => { divida += Number(el.saldoOrc); });
      data.yearMonth.filter(el => (Number(el.year) === ano && Number(el.month) >= mesInicio && Number(el.month) <= mesFim)).forEach(el => { divida += Number(el.saldoOrc); });
    } else {
      // data.yearMonth.filter(el => (Number(el.year) === ano)).forEach(el => { divida += Number(el.saldoOrc); });
      data.yearMonth.filter(el => (Number(el.year) === ano && Number(el.month) >= mesInicio && Number(el.month) <= mesFim)).forEach(el => { divida += Number(el.saldoOrc); });
    }
    quota = data.yearMonth.find(el => (Number(el.year) === ano)) ? data.yearMonth.find(el => (Number(el.year) === ano)).quota : 0;

    if (Math.round(divida * 100) / 100 > 0) {

      linhasModelo = this.setDividaResp(linhasModeloOrig, linhasModeloUmMesOrig, mesInicio, ano, mesFim, divida, quota);

      finalResp += ((!initAdded) ? initText : '') + linhasModelo + ' ';

      total += divida;

      initAdded = true;
    }


    return { total: total, initAdded: initAdded, finalResp: finalResp };
  }

  setFraccoesIntroList(target: OrdemTrabalhoList | string, resp: OrdemTrabalhoItem, updateAtaIntro = false) {
    let fraccoes = '';
    let fraccoesList = '';

    let permTotal: any = 0;
    let fraccaoListAux = (this.isDividaCondomino || this.isLimparDivida) ? this.selectionFraccaoListDivida : this.selectionFraccaoList;

    fraccaoListAux.filter(el => (el.checked)).forEach(el => {

      fraccoes += el.fraccao + ', ' + el.proprietario + '; ';

      permTotal += el.permilagem;

      let auxFraccao = this.presencasList.find(it => (it.cod_fracao === el.cod_fraccao && (it.cod_representative_name != null || it.free_representative_name != null)));
      if (auxFraccao) {
        let representante_nome = auxFraccao.cod_representative_name != null ? auxFraccao.cod_representative_name : auxFraccao.free_representative_name;
        fraccoesList += el.fraccao + ', ' + (Math.round(el.permilagem * 100) / 100).toFixed(2).replace('.', ',') + '‰' + ', ' + el.proprietario + ', representado por ' + representante_nome + '; '
      } else {
        fraccoesList += el.fraccao + ', ' + (Math.round(el.permilagem * 100) / 100).toFixed(2).replace('.', ',') + '‰' + ', ' + el.proprietario + '; '
      }
    });

    permTotal = permTotal.toFixed(2).replace('.', ',') + '‰';

    if (fraccoes !== '') fraccoes = fraccoes.substring(0, fraccoes.length - 2);
    if (fraccoesList !== '') fraccoesList = fraccoesList.substring(0, fraccoesList.length - 2);

    if (typeof (target) != 'string' && target.hasOwnProperty('id') && target.id != 4) {

      if (resp.resp.indexOf('[[ FRACCAO_DESCRICAO ]]') !== -1) {
        resp.resp = resp.resp.replace(/\[\[ FRACCAO_DESCRICAO \]\]/g, fraccoes);
      }

      if (resp.resp.indexOf('[[ FRACCAO_DESCRICAO_LIST ]]') !== -1) {
        resp.resp = resp.resp.replace(/\[\[ FRACCAO_DESCRICAO_LIST \]\]/g, fraccoes);
      }

    }

    if (typeof (target) != 'string' && resp.resp) {

      // DIVIDAS SECTION
      if (resp.resp.indexOf('[[ DIVIDA_MODELO_ITEM ]]') !== -1) {

        let finalResp = resp.resp.split('[[ DIVIDA_MODELO_ITEM ]]')[0];
        let auxModelo = resp.resp.split('[[ DIVIDA_MODELO_ITEM ]]')[1].trim();
        let linhasModeloOrig = auxModelo.split('[[ DIVIDA_INTERVALO ]]')[1].trim();
        let linhasModeloUmMesOrig = auxModelo.split('[[ DIVIDA_UM_MES ]]')[1].trim();
        let linhasModeloExtraOrig = auxModelo.split('[[ DIVIDA_QUOTA_EXTRA ]]')[1].trim();
        let linhasModeloFinalOrig = auxModelo.split('[[ DIVIDA_FINAL ]]')[1].trim();

        let linhasModelo = '';

        let add = false;
        let initAdded = false;
        let mesInicio = null;
        let ano = null;
        let prevMes = null;
        let prevAno = null;
        let mesFim = null;
        let divida = <any>'0';
        let dividaExtra = <any>'0';
        let quota = <any>'0';
        var total = 0;
        let dividaTotal = 0;

        let data = null;
        let initText = null;

        // DIVIDA ORCAMENTO
        let index = 0;
        let j = 0;

        this.selectionFraccaoList.filter(el => (el.checked)).forEach((el, i) => {

          initAdded = false;

          this.contaCorrenteDetalhada.filter(it => (it.fraccao_cod === el.cod_fraccao)).forEach(it => {

            total = 0;
            mesFim = null;
            initAdded = false;

            this.printToLetter(j + 1);

            let condomino_nome = '';
            if (it.data.length > 0 && it.data[0].yearMonth.length > 0 && it.data[0].yearMonth[0].avisos.length > 0) {
              condomino_nome = it.data[0].yearMonth[0].avisos[0].condomino_nome;
            }

            initText = ((index !== 0) ? '\n\n' : '') + this.resultToPrint + ') ' + el.fraccao + ', ' + condomino_nome + '. ';

            index++;
            j++;

            data = it.data[0];

            if (it.data.length > 0) {
              it.data[0].yearMonth.forEach((elem, i) => {
                if (i === 0) {
                  mesInicio = Number(elem.month);
                  ano = Number(elem.year);
                } else {
                  // TROCA DE ANO
                  let newYear = false;
                  if (prevAno !== Number(elem.year)) {
                    add = true;
                    mesFim = prevMes;
                    newYear = true;
                    ano = prevAno;

                  } else if ((prevMes + 1) !== Number(elem.month)) {
                    mesFim = prevMes;
                    add = true;
                  }


                  if (add) {
                    let res = this.addDividaIntoText(data, total, initAdded, initText, finalResp, linhasModelo, linhasModeloOrig, linhasModeloUmMesOrig, quota, newYear, prevMes, ano, mesInicio, mesFim);
                    finalResp = res.finalResp;
                    total = res.total;
                    initAdded = res.initAdded;
                    mesInicio = Number(elem.month);
                    add = false;
                  }

                  // CHECK IF THIS IS THE LAST ENTRY FROM ARRAY
                  if (i === it.data[0].yearMonth.length - 1) {
                    mesFim = Number(elem.month);
                    ano = Number(elem.year);
                    let res = this.addDividaIntoText(data, total, initAdded, initText, finalResp, linhasModelo, linhasModeloOrig, linhasModeloUmMesOrig, quota, newYear, prevMes, ano, mesInicio, mesFim);
                    finalResp = res.finalResp;
                    total = res.total;
                    initAdded = res.initAdded;

                    mesInicio = Number(elem.month);
                    add = false;
                  }
                }

                prevMes = Number(elem.month);
                prevAno = Number(elem.year);
              });
            }

            if (mesInicio && !mesFim && data) {
              divida = 0;
              data.yearMonth.filter(el => (Number(el.year) === ano)).forEach(el => { divida += Number(el.saldoOrc); });

              if (Math.round(divida * 100) / 100 > 0) {
                quota = data.yearMonth.find(el => (Number(el.year) === ano)) ? data.yearMonth.find(el => (Number(el.year) === ano)).quota : 0;
                mesFim = Number(data.yearMonth[data.yearMonth.length - 1].month);
                linhasModelo = this.setDividaResp(linhasModeloOrig, linhasModeloUmMesOrig, mesInicio, ano, mesFim, divida, quota);
                finalResp += initText + linhasModelo + ' ';

                initAdded = true;

                total += divida;
              }

              add = false;
              mesInicio = null;
              ano = null;
              prevMes = null;
              prevAno = null;
              mesFim = null;
              dividaExtra = '0';
              divida = '0';
              quota = '0';
            }

            if (!initAdded) finalResp += initText;

            // QUOTAS EXTRAS ------------
            if (data) {
              let allExtra = [];
              data.yearMonth.forEach((elem, i) => {
                allExtra = allExtra.concat(elem.avisosExtra);
                allExtra = allExtra.sort((a, b) => {
                  if (a.descricao > b.descricao) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
              });

              allExtra = JSON.parse(JSON.stringify(allExtra.filter(el => (el))));

              let prevDesc = null;
              let allJointExtra = [];
              let element = null;
              allExtra.forEach(el => {
                if (prevDesc === null) {
                  element = el;
                  element.saldo = Number(element.saldo);
                } else if (prevDesc === el.descricao) {
                  element.saldo = Number(element.saldo) + Number(el.saldo);
                } else {
                  allJointExtra.push(element);
                  element = el;
                }
                prevDesc = el.descricao;

                if (allExtra.length === 1) allJointExtra.push(element);
              });

              // CHECK FOR MISSING LAST ENTRY
              allExtra.forEach(el => {
                if (!allJointExtra.find(it => (it.descricao === el.descricao))) {
                  el.saldo = Number(el.saldo);

                  allJointExtra.push(el);
                }
              });
              if (allJointExtra.length > 0) {
                allJointExtra = allJointExtra.sort((a, b) => parseInt(a.id) - parseInt(b.id));
              }

              allJointExtra.forEach(aviso => {
                if (Number(aviso.saldo) > 0) {
                  finalResp += this.setDividaExtraResp(linhasModeloExtraOrig, Number(aviso.saldo), aviso.descricao);

                  total += Number(aviso.saldo);
                }
              });
            }

            if (total > 0) {
              finalResp += this.setFinalLine(linhasModeloFinalOrig, total);
            }
          });

          if (total === 0) {
            finalResp = finalResp.replace(initText, '');
            j--;
          }

        });

        resp.resp = finalResp;
      }

      // REMOVE UNSELECTED FRACCOES
      let aux = resp.resp.split('\n');
      let auxFinalResp = '';
      let n = 0;
      aux.forEach(it => {
        if (it.match(/(?<=\)\s+).*?(?=\s+Deve)/gs)) {
          this.selectionFraccaoListDivida.filter(elem => elem.checked).forEach(elem => {
            if (it.indexOf(elem.fraccao + ', ' + elem.proprietario) !== -1) {
              this.printToLetter(n + 1);

              auxFinalResp += this.resultToPrint + ') ' + it.substring(it.indexOf(')') + 1) + '\n';
              n++;
            }
          });
        } else {
          auxFinalResp += it + '\n';
        }
      });
      resp.resp = auxFinalResp.replace(/(\r\n|\r|\n){3,}/g, '\n\n');

      // ADD FRACCOES WITH DIVIDA
      if (target.hasOwnProperty('id') && target.id == 4) {

        let fraccoes = '';
        let fraccoesList = [];
        this.selectionFraccaoList.filter(el => (el.checked)).forEach(el => {
          if (resp.resp.indexOf(el.fraccao) !== -1) {

            let aux = resp.resp.split('\n').filter(it => (it.indexOf(el.fraccao) !== -1));
            aux.forEach(it => {
              let auxFraccao = it.match(/(?<=\)\s+).*?(?=\s+Deve)/gs);

              if (auxFraccao) fraccoesList.push(auxFraccao[0].substring(0, auxFraccao[0].length - 1));
            });

          }
        });

        fraccoesList.forEach(el => {
          fraccoes += el + '; ';
        });

        if (fraccoes !== '') fraccoes = fraccoes.substring(0, fraccoes.length - 2);

        if (resp.resp.indexOf('[[ FRACCAO_DESCRICAO ]]') !== -1) {
          resp.resp = resp.resp.replace(/\[\[ FRACCAO_DESCRICAO \]\]/g, fraccoes);
        }

        if (resp.resp.indexOf('[[ FRACCAO_DESCRICAO_LIST ]]') !== -1) {
          resp.resp = resp.resp.replace(/\[\[ FRACCAO_DESCRICAO_LIST \]\]/g, fraccoes);
        }

      }

    } else {
      switch (target) {
        case 'INTRODUCAO_ATA':
          if (!fraccaoListAux.length) break;

          if (updateAtaIntro) {
            this.introAta = this.introAtaOrig.replace(/\[\[ FRACCAO_DESCRICAO_LIST \]\]/g, fraccoesList);

            // ADD PERMILAGEM TOTAL
            if (this.introAta && this.introAta.indexOf('[[ PERM_TOTAL ]]') !== -1) {
              this.introAta = this.introAta.replace(/\[\[ PERM_TOTAL \]\]/g, permTotal);
            }
          } else {
            if (this.introAta && this.introAta.indexOf('[[ FRACCAO_DESCRICAO_LIST ]]') !== -1) {
              this.introAta = this.introAta.replace(/\[\[ FRACCAO_DESCRICAO_LIST \]\]/g, fraccoesList);
            }

            // ADD PERMILAGEM TOTAL
            if (this.introAta && this.introAta.indexOf('[[ PERM_TOTAL ]]') !== -1) {
              this.introAta = this.introAta.replace(/\[\[ PERM_TOTAL \]\]/g, permTotal);
            }

            let selectedFraccoes = fraccaoListAux.filter(el => (el.checked));
            this.presencasList.forEach(el => {
              let auxFraccao = (selectedFraccoes && Array.isArray(selectedFraccoes)) ? selectedFraccoes.find(it => it.cod_fraccao === el.cod_fracao) : null;
              el.present = !!auxFraccao;
            });
            this.computePresencasTotal();
          }
          break;
      }
    }

    this.maxSelection = null;
  }

  setFinalLine(linhaModeloTotal, total: number) {

    if (linhaModeloTotal.indexOf('[[ DIVIDA_TOTAL ]]') !== -1) {
      linhaModeloTotal = linhaModeloTotal.replace(/\[\[ DIVIDA_TOTAL \]\]/g, this.utils.getNumberFormatted(total));
    }

    if (linhaModeloTotal.indexOf('[[ DIVIDA_TOTAL_EXT ]]') !== -1) {
      linhaModeloTotal = linhaModeloTotal.replace(/\[\[ DIVIDA_TOTAL_EXT \]\]/g, this.utils.getNumberPorExtenso(total));
    }

    return linhaModeloTotal;
  }

  setDividaExtraResp(linhaModeloExtra, divida: number, descricao = '') {
    if (linhaModeloExtra.indexOf('[[ DIVIDA_EXTRA ]]') !== -1) {
      linhaModeloExtra = linhaModeloExtra.replace(/\[\[ DIVIDA_EXTRA \]\]/g, this.utils.getNumberFormatted(divida));
    }

    if (linhaModeloExtra.indexOf('[[ DIVIDA_EXTRA_EXT ]]') !== -1) {
      linhaModeloExtra = linhaModeloExtra.replace(/\[\[ DIVIDA_EXTRA_EXT \]\]/g, this.utils.getNumberPorExtenso(divida));
    }

    if (linhaModeloExtra.indexOf('[[ DIVIDA_EXTRA_DESCRICAO ]]') !== -1) {
      linhaModeloExtra = linhaModeloExtra.replace(/\[\[ DIVIDA_EXTRA_DESCRICAO \]\]/g, descricao);
    }

    return linhaModeloExtra + ' ';
  }

  setDividaResp(linhasModelo, linhaModeloUmMes, mesInicio, ano, mesFim, divida: number, quota: number) {
    if (mesInicio === mesFim) {
      if (linhaModeloUmMes.indexOf('[[ MES_INICIO ]]') !== -1) {
        linhaModeloUmMes = linhaModeloUmMes.replace(/\[\[ MES_INICIO \]\]/g, this.appConfig.meses[mesInicio - 1]);
      }

      if (linhaModeloUmMes.indexOf('[[ ANO ]]') !== -1) {
        linhaModeloUmMes = linhaModeloUmMes.replace(/\[\[ ANO \]\]/g, ano);
      }

      if (linhaModeloUmMes.indexOf('[[ DIVIDA ]]') !== -1) {
        linhaModeloUmMes = linhaModeloUmMes.replace(/\[\[ DIVIDA \]\]/g, this.utils.getNumberFormatted(divida));
      }

      if (linhaModeloUmMes.indexOf('[[ DIVIDA_EXT ]]') !== -1) {
        linhaModeloUmMes = linhaModeloUmMes.replace(/\[\[ DIVIDA_EXT \]\]/g, this.utils.getNumberPorExtenso(divida));
      }

      if (linhaModeloUmMes.indexOf('[[ VALOR_QUOTA ]]') !== -1) {
        linhaModeloUmMes = linhaModeloUmMes.replace(/\[\[ VALOR_QUOTA \]\]/g, this.utils.getNumberFormatted(quota));
      }

      if (linhaModeloUmMes.indexOf('[[ VALOR_QUOTA_EXT ]]') !== -1) {
        linhaModeloUmMes = linhaModeloUmMes.replace(/\[\[ VALOR_QUOTA_EXT \]\]/g, this.utils.getNumberPorExtenso(quota));
      }

      return linhaModeloUmMes;
    } else {
      if (linhasModelo.indexOf('[[ MES_INICIO ]]') !== -1) {
        linhasModelo = linhasModelo.replace(/\[\[ MES_INICIO \]\]/g, this.appConfig.meses[mesInicio - 1]);
      }

      if (linhasModelo.indexOf('[[ ANO ]]') !== -1) {
        linhasModelo = linhasModelo.replace(/\[\[ ANO \]\]/g, ano);
      }

      if (linhasModelo.indexOf('[[ MES_FIM ]]') !== -1) {
        linhasModelo = linhasModelo.replace(/\[\[ MES_FIM \]\]/g, this.appConfig.meses[mesFim - 1]);
      }

      if (linhasModelo.indexOf('[[ DIVIDA ]]') !== -1) {
        linhasModelo = linhasModelo.replace(/\[\[ DIVIDA \]\]/g, this.utils.getNumberFormatted(divida));
      }

      if (linhasModelo.indexOf('[[ DIVIDA_EXT ]]') !== -1) {
        linhasModelo = linhasModelo.replace(/\[\[ DIVIDA_EXT \]\]/g, this.utils.getNumberPorExtenso(divida));
      }

      if (linhasModelo.indexOf('[[ VALOR_QUOTA ]]') !== -1) {
        linhasModelo = linhasModelo.replace(/\[\[ VALOR_QUOTA \]\]/g, this.utils.getNumberFormatted(quota));
      }

      if (linhasModelo.indexOf('[[ VALOR_QUOTA_EXT ]]') !== -1) {
        linhasModelo = linhasModelo.replace(/\[\[ VALOR_QUOTA_EXT \]\]/g, this.utils.getNumberPorExtenso(quota));
      }

      return linhasModelo;
    }
  }

  checkMaxLimit(entry) {
    if (this.maxSelection === null) return;

    setTimeout(() => {
      let auxSel = this.selectionFraccaoList.filter(el => el.checked);
      if (auxSel.length > this.maxSelection) {
        for (let i = 0; i < this.selectionFraccaoList.length; i++) {
          if (this.selectionFraccaoList[i].checked && this.selectionFraccaoList[i].cod_fraccao !== entry.cod_fraccao) {
            this.selectionFraccaoList[i].checked = false;
            break;
          }
        }
      }
    }, 1);
  }

  minSelection = null;
  maxSelection = null;

  selectionContasList = [];
  selectionContasFechoList = [];
  contasList = [];
  fechoEnabled = false;
  addContaBanco(resp: OrdemTrabalhoItem) {
    this.selectionContasList = JSON.parse(JSON.stringify(this.contasList));
    this.selectionContasList.forEach(el => {
      el.checked = (el.hasOwnProperty('conta_principal') && el.conta_principal === '1');
    });

    this.fechoEnabled = (resp.resp && resp.resp.indexOf('BANCO_CONTA_FECHO') !== -1);

    if (this.fechoEnabled) {
      this.selectionContasFechoList = JSON.parse(JSON.stringify(this.contasList));
      this.selectionContasFechoList.forEach(el => { el.checked = false; });
    }

    this.contasListModalRef = this.modalService
      .open(this.contasListAlertConfig)
      .onApprove(() => {

        if (resp.hasOwnProperty('resp') && resp.resp) {
          this.selectionContasList.filter(el => (el.checked)).forEach((el, i) => {
            let banco = el.banco;
            if (!banco) {
              let aux = el.sigla.split(' - ');
              if (Array.isArray(aux) && aux.length > 1) {
                banco = aux[aux.length - 1].trim();
              } else {
                banco = el.sigla.trim();
              }
            }

            resp.resp = resp.resp.replace(/\[\[ BANCO_CONTA \]\]/g, banco + ', S.A.');
          });

          if (this.fechoEnabled) {
            this.selectionContasFechoList.filter(el => (el.checked)).forEach((el, i) => {
              let banco = el.banco;
              if (!banco) {
                let aux = el.sigla.split(' - ');
                if (Array.isArray(aux) && aux.length > 1) {
                  banco = aux[aux.length - 1].trim();
                } else {
                  banco = el.sigla.trim();
                }
              }

              resp.resp = resp.resp.replace(/\[\[ BANCO_CONTA_FECHO \]\]/g, banco + ', S.A.');
            });
          }
        }

        this.fechoEnabled = false;
      })
      .onDeny(() => { this.fechoEnabled = false; });

  }

  selTabContas = 'abertura';
  setTabContas(tab) {
    this.selTabContas = tab;
  }

  checkOne(list, el) {
    setTimeout(() => {
      if (!el.checked) return;

      list.forEach(it => {
        if (it.id !== el.id) it.checked = false;
      })
    }, 100);
  }

  isDetailed = true;

  extraordinaria: '0' | '1' = null;
  initOrdemTrab = true;
  submittingFormDate = false;
  submittingFormCondominio = false;

  canAddOrdemTrabalho(ignoreAlert = false): boolean {
    let auxDate = this.geralForm.get('dt').value;
    if (!auxDate || !this.cod_condominio) {
      // PRESENT ALTER
      if (!ignoreAlert) {
        if (!this.cod_condominio) {
          this.submittingFormCondominio = true;
          setTimeout(() => { this.submittingFormCondominio = false; }, 4000);
          this.toastr.error('Necessita primeiro de selecionar o condomínio.', 'Alerta', { timeOut: 4000 });
        } else {
          this.submittingFormDate = true;
          setTimeout(() => { this.submittingFormDate = false; }, 4000);
          this.toastr.error('Necessita primeiro de selecionar a data para a 1ª convocatória.', 'Alerta', { timeOut: 4000 });
        }
      }
      return false;
    }
    return true;
  }

  extraChanged(ignoreAlert = false) {
    if (!this.canAddOrdemTrabalho(ignoreAlert)) {
      this.geralForm.patchValue({ extraordinaria: null }, { emitEvent: false });
      this.extraordinaria = null;
      return;
    }

    if (!this.isCreate) return;

    if ((!this.initOrdemTrab && this.ordensTrabalhos.length > 0) || this.extraordinaria === null) return;

    let ordArr: Array<OrdemTrabalhoOpts> = [];
    let newOrdens: Array<OrdemTrabalhoList> = [];
    if (this.extraordinaria === '0') {
      ordArr = this.ordemTrabOpts.filter(el => (el.value.ordinarias_predefinido == 1));
    } else {
      ordArr = this.ordemTrabOpts.filter(el => (el.value.extras_predefinido == 1));
    }
    ordArr.forEach(el => {
      let entry = this.generateNewOrdemTrabalho(el.value);
      newOrdens.push(entry);
    });

    newOrdens = JSON.parse(JSON.stringify(newOrdens.sort((a, b) => a.ordem - b.ordem)));

    this.presentOrdensTrabalhosAnosPicker(newOrdens);
    this.setAnexosIndicators();
  }

  @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;
  filename = 'Nenhum ficheiro selecionado...';
  fileToUpload = null;
  base64File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files[0];
    const reader = new FileReader();

    this.filename = this.fileToUpload['name'];
    if (this.fileToUpload['name'].toLowerCase().indexOf('.pdf') === -1) {
      this.toastr.error('O ficheiro selecionado não é suportado. Por favor, submeta um ficheiro com extensão .pdf.', 'Ups...!', { timeOut: 4000 });
      this.fileInputRef.nativeElement.value = '';
      this.filename = 'Nenhum ficheiro selecionado...';
      return;
    }

    if (this.selTab === 'presencas' && this.fileToUpload.size > 2097152) {
      this.toastr.error('O ficheiro selecionado é superior ao tamanho máximo suportado (2Mb)', 'Ups...!', { timeOut: 4000 });
      this.fileInputRef.nativeElement.value = '';
      this.filename = 'Nenhum ficheiro selecionado...';
      return;
    }

    if (this.fileToUpload.size > 8388608) {
      this.toastr.error('O ficheiro selecionado é superior ao tamanho máximo suportado (8Mb)', 'Ups...!', { timeOut: 4000 });
      this.fileInputRef.nativeElement.value = '';
      this.filename = 'Nenhum ficheiro selecionado...';
      return;
    }


    reader.onload = (ev) => {
      let binaryString = ev.target['result'];

      this.base64File = binaryString;

      this.base64File = this.base64File.replace('data:application/pdf;base64,', '');
      this.base64File = this.base64File.replace(' ', '+');
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  getFileArray(file_ids: Array<{ file_id, ordem }>): Promise<Array<{ base64, ordem }>> {
    return new Promise((resolve) => {
      let req = file_ids.map(el => this.api.getFile(el.file_id));
      forkJoin(req).subscribe(resArr => {
        if (!resArr.find(el => !el.success)) {
          resolve(resArr.map((el, i) => {
            return {
              base64: el.data.file,
              ordem: file_ids[i].ordem
            }
          }));
        } else {
          this.utils.apiErrorMsg(resArr);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }


  getAndDownloadFile(file_id, filename?) {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getFile(file_id).subscribe(res => {
      if (res && res.success) {
        this.utils.downloadFile('data:application/pdf;base64,' + res.data.file, filename ? filename : res.data.filename);
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  downloadAtaAssinada() {
    if (this.selTab === 'ata') {
      if (this.details.hasOwnProperty('ata_ficheiro') && this.details.ata_ficheiro) {
        this.utils.downloadFile('data:application/pdf;base64,' + this.details.ata_ficheiro, 'Ata Assinada');

        // saveAs('data:application/pdf;base64,' + this.details.ata_ficheiro, 'Ata Assinada.pdf',{
        //   proxyURL: this.appConfig.fileProxyUrl,
        //   forceProxy: true,
        //   proxyTarget: '_blank',
        // });
      } else if (this.details.hasOwnProperty('id_ata_ficheiro') && this.details.id_ata_ficheiro) {
        this.getAndDownloadFile(this.details.id_ata_ficheiro, 'Ata Assinada.pdf');
        // saveAs('data:application/pdf;base64,' + this.details.ata_ficheiro, 'Ata Assinada.pdf',{
        //   proxyURL: this.appConfig.fileProxyUrl,
        //   forceProxy: true,
        //   proxyTarget: '_blank',
        // });
      }
    }

    if (this.selTab === 'presencas') {
      if (this.details.hasOwnProperty('representantes_ficheiro') && this.details.representantes_ficheiro) {
        this.utils.downloadFile('data:application/pdf;base64,' + this.details.representantes_ficheiro, 'Lista de Representantes');

        // saveAs('data:application/pdf;base64,' + this.details.representantes_ficheiro, 'Lista de Representantes.pdf',{
        //   proxyURL: this.appConfig.fileProxyUrl,
        //   forceProxy: true,
        //   proxyTarget: '_blank',
        // })
      }
    }
  }

  checkUserAccess(module, action): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.api.checkUserAccess(module, action).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
  }

  regRemoveCopiaAtaMsg = null;
  ataAssinadaToUploadBase64 = null;
  ataAssinadaToUploadFilename = null;
  uploadCopiaAta = (file, fileExt: fileExt, filename): Promise<boolean> => {
    return new Promise(async (resolve) => {
      if (!this.details.ata_concluida) {
        this.toastr.error('A ata não se encontra dada como concluída.', 'Alerta', { timeOut: 4000 });
        resolve(false);
        return;
      }

      if (this.copiaAtaEnviada && !(await this.checkUserAccess('UPLOAD_ATA_AFTER_SENT', 'allow'))) {
        this.toastr.error('Não tem permissões para atualizar a cópia da ata. A mesma já foi enviada.', 'Alerta', { timeOut: 4000 });
        resolve(false);
        return;
      }
      if (this.mapaReuniao.entrega_assinatura == null) {
        this.toastr.error('Não é possível carregar a cópia ata. A respetiva ata ainda não se encontra em assinatura.', 'Alerta', { timeOut: 4000 });
        resolve(false);
        return;
      }
      if (this.details.ata_ficheiro || this.details.id_ata_ficheiro) {
        let res = await this.presentDeleteAtaAssinada(false);
        if (!res) {
          resolve(false);
          return;
        }
      }

      this.ataAssinadaToUploadBase64 = file;
      this.ataAssinadaToUploadFilename = filename;

      this.utils.downloadFile('data:application/pdf;base64,' + file, filename);

      let res = await this.askForSignatures();
      if (!res) {
        resolve(false);
        return;
      }

      resolve(true);
    })
  }

  approveRemoverAtaModal() {
    if (!this.regRemoveCopiaAtaMsg || this.regRemoveCopiaAtaMsg.trim() === '') {
      this.submittingMotivo = true;
      setTimeout(() => {
        this.submittingMotivo = false;
      }, 4000);
      return;
    }
    this.removerAtaModalRef.approve();
  }

  titleDeleteAtaAssinada: string = null;
  presentDeleteAtaAssinada(isDelete): Promise<boolean> {
    return new Promise((resolve) => {
      this.titleDeleteAtaAssinada = (isDelete) ? 'Deseja remover o ficheiro submetido referente à cópia assinada da ata da presente assembleia de condomínios?' : 'Deseja substituir o ficheiro submetido referente à cópia assinada da ata da presente assembleia de condomínios?';
      this.regRemoveCopiaAtaMsg = null;
      this.loadingModal = false;
      this.removerAtaModalRef = this.modalService
        .open(this.removerAtaAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => {
          resolve(false);
        });
    })
  }

  async saveCopiaAta() {
    // UPDATE LISTA/REGISTO DE PRESENÇAS
    let presencasList: Array<AssembleiasPresencas> = this.convertListToPresencas();
    this.presencasListSignatures.forEach(el => {
      let auxSignature = presencasList.find(it => it.cod_fracao === el.cod_fracao);
      if (auxSignature) auxSignature.signature = el.signature ? 1 : 0;
    });

    this.loadingModal = true;
    let resPresencas = await this.savePresencasList(presencasList);
    if (!resPresencas) {
      this.loadingModal = false;
      return;
    }

    this.api.uploadAtaPdfFile(this.details.id_assembleia, this.ataAssinadaToUploadFilename, this.ataAssinadaToUploadBase64).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        if (this.ataUploadedModalRef) {
          this.ataUploadedModalRef.approve();
          this.computePresencasTotal(true);
        }

        // SET ARQUIVO DIGITAL
        this.details['ata_ficheiro'] = null;
        this.details['id_ata_ficheiro'] = res.data.fileId;
        this.mapaReuniao.rececao = new Date().toISOString();

        let fileLink = { fileId: res.data.fileId };
        let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
        let desc = this.hasAtaFicheiro() ? 'Cópia da ata assinada atualizada' : 'Cópia da ata assinada submetida';
        this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, desc, JSON.stringify([]), this.userSession.getUserId(), new Date(), this.regRemoveCopiaAtaMsg, fileLink).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            this.regEmails = [{
              description: desc,
              emailList: [],
              name: this.userSession.getUserFullName(),
              date: new Date(),
              msg: this.regRemoveCopiaAtaMsg,
              obj: fileLink,
            } as RegEmails].concat(this.regEmails);
            this.sortRegAtividade();
          }
          this.regRemoveCopiaAtaMsg = null;
        }, err => { this.regRemoveCopiaAtaMsg = null; });

      } else {
        this.utils.apiErrorMsg(res);
      }
      this.loadingModal = false;
    },
      err => {
        this.loadingModal = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      });
  }

  updateDataArquivo = false;
  // updateEntregaAssin = false;
  alertEnabled = false;

  automatizationsStep: 'DATA_ENTREGA_ASSINATURA' = 'DATA_ENTREGA_ASSINATURA';

  askForSignatures() {
    return new Promise((resolve) => {
      this.presencasListSignatures = this.presencasList.filter(el => el.present).map(el => {
        let clone: AssembleiasPresencasList = {
          present: el.present,
          signature: el.signature,
          id: el.id,
          id_assembleia: el.id_assembleia,
          cod_fracao: el.cod_fracao,
          cod_condomino: el.cod_condomino,
          cod_representative: el.cod_representative,
          free_representative_name: el.free_representative_name,
          permilage: el.permilage,
          cod_representative_name: el.cod_representative_name,
          nome_condomino: el.nome_condomino,
          nome_fracao: el.nome_fracao
        }
        return clone;
      });

      this.computeSignatureTotal();
      this.alertEnabled = this.hasAtaFicheiro();
      this.ataUploadedModalRef = this.modalService
        .open(this.ataUploadedAlertConfig)
        .onApprove(() => {
          this.filename = null;
          this.base64File = null;
          this.loadingModal = false;
          this.presencasListSignatures = [];
          resolve(true);
        })
        .onDeny(() => {
          this.filename = null;
          this.base64File = null;
          this.loadingModal = false;
          this.presencasListSignatures = [];
          resolve(false);
        });
    });
  }


  presencasListSignaturesTotal = null;
  quorumSigOk = false;
  computeSignatureTotal() {
    this.presencasListSignaturesTotal = 0;
    this.presencasListSignatures.filter(el => el.signature).forEach(el => {
      this.presencasListSignaturesTotal += Number(el.permilage);
    });
  }

  hasAtaFicheiro(): boolean {
    return (this.details.hasOwnProperty('ata_ficheiro') && !!this.details.ata_ficheiro) || (this.details.hasOwnProperty('id_ata_ficheiro') && !!this.details.id_ata_ficheiro);
  }

  hasRelatorioFicheiro() {
    return this.details && (this.details.id_relatorio_assembleia || this.details.relatorio_assembleia);
  }

  canCompleteAta(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let newValue = !this.details.ata_concluida;

      if (this.apiSubs.find(el => el === 'SAVE_ATA')) {
        reject(null);
        return;
      }
      if (this.ataEditEnabled && this.savedAsClosed && this.hasAtaFicheiro() && !this.userSession.isSuperAdmin()) {
        this.toastr.error('Ata concluída. Não tem permissões para editar a presente ata.', 'Alerta', { timeOut: 4000 });
        reject(null);
        return;
      }

      if (newValue) {
        let ataComplete = true;
        if ((((this.introAta && this.introAta.indexOf('[[ FRACCAO_DESCRICAO_LIST ]]') !== -1)) || (!this.anexosAta[0]))) {
          ataComplete = false;
        }
        this.ordensTrabalhos.filter(it => (it.label !== 'ASSUNTOS_DIVERSOS' && it.id != 5)).forEach(ordemTrab => {
          ordemTrab.resps.forEach(resp => {
            if (!resp.resp || (resp.resp && resp.resp.indexOf('[[') !== -1 && resp.resp.indexOf(']]') !== -1)) {
              ataComplete = false;
            }
            if (resp.need_anexo_indicator && !resp.uploaded_anexo_indicator) {
              ataComplete = false;
            }
          });

        });


        let assuntosDiversosIndex = this.ordensTrabalhos.findIndex(el => el.label === 'ASSUNTOS_DIVERSOS');
        if (assuntosDiversosIndex != -1) {
          if (this.assuntosDiversosRespObj.length === 0) {
            ataComplete = false;

            let ordensTrabalho = document.getElementsByClassName('ot-' + assuntosDiversosIndex);
            if (ordensTrabalho.length) {
              var offsetElement = ordensTrabalho[0].getBoundingClientRect().top;
              this.scroll(document.getElementById('ata-scrollable'), offsetElement, true);
            }
          }

          this.assuntosDiversosRespObj.forEach(ordemTrab => {
            if (!ordemTrab.resp || (ordemTrab.resp && ordemTrab.resp.indexOf('[[') !== -1 && ordemTrab.resp.indexOf(']]') !== -1)) {
              ataComplete = false;
            }

            if (ordemTrab.need_anexo_indicator && !ordemTrab.uploaded_anexo_indicator) {
              ataComplete = false;
            }
          });
        }

        if (!ataComplete) {
          this.toastr.error('Complete os campos assinalados antes de dar a presente ata como concluída.', 'Alerta', { timeOut: 4000 });
          reject(null);
          return;
        } else {
          if (newValue && !this.hasAllAttachmentsUploaded()) {
            let res = await this.presentMissingAttachement();
            if (!res) {
              reject(null);
              return;
            }
          }
          // }
        }

        let orcamento = this.ordensTrabalhos.find(el => el.label === 'ORCAMENTO');
        if (orcamento) {
          if (!orcamento.selAnos.length || !orcamento.resps.length) {
            reject(null);
            return;
          }
          for (let i = 0; i < orcamento.resps.length; i++) {
            let selAno = i < orcamento.selAnos.length ? orcamento.selAnos[i] : null;
            if (selAno == null || selAno.id_link == null) {
              this.toastr.error('Por favor, selecione o orçamento que foi aprovado em assembleia.', 'Alerta', { timeOut: 4000 });
              reject(null);
              return;
            }
          }
        }



        this.needAnexoPresencas = (!(this.details.hasOwnProperty('presencas_ficheiro') && this.details.presencas_ficheiro) && !(this.details.hasOwnProperty('presencas_representantes_ficheiro') && this.details.presencas_representantes_ficheiro));
        this.needAnexoProcuracoes = (!(this.details.hasOwnProperty('representantes_ficheiro') && this.details.representantes_ficheiro) && !(this.details.hasOwnProperty('presencas_representantes_ficheiro') && this.details.presencas_representantes_ficheiro) && (this.presencasList && !!this.presencasList.find(it => (it.cod_representative_name !== null || it.free_representative_name !== null))));

        if (this.needAnexoPresencas && !this.userSession.isSuperAdmin()) {
          this.toastr.error('Não é possível dar a ata como concluída, o anexo correspondente à lista de presenças está em falta.', 'Alerta', { timeOut: 4000 });
          reject(null);
          return;
        }

        if (this.needAnexoPresencas || this.needAnexoProcuracoes) {
          let res = await this.presentRepresentantesAlert();
          if (!res) {
            reject(null);
            return;
          }
        }
      }

      let actRegMsg = null;
      let isReopened = (this.ataConcluidaPrev && !newValue);
      if (((this.ataConcluidaPrev && newValue) || isReopened)) {
        actRegMsg = await this.presentAtaChanged();
        if (actRegMsg == null) {
          reject(null);
          return;
        }
        resolve(actRegMsg);
      }
      resolve(null)
    });
  }
  ataEditEnabled = false;
  ataConcluidaChanged(): Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        var regMsg = await this.canCompleteAta();
      } catch (err) {
        resolve(false);
        return;
      }

      this.details.ata_concluida = this.details.ata_concluida ? 0 : 1;

      this.ataEditEnabled = !!this.details.ata_concluida;

      this.formSubmitted('ata', false, true, regMsg);

      this.onlyShowPresencas = false;
      resolve(true);
    });
  }



  @ViewChild('concluirAtaAlertRef', { static: false }) concluirAtaAlertRef;
  concluirAtaModalRef = null;
  concluirAtaAlertConfig: any = null;
  presentConcluirAtaModal(): Promise<boolean> {
    return new Promise((resolve) => {
      this.concluirAtaModalRef = this.modalService
        .open(this.concluirAtaAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => {
          resolve(false)
        });
    })
  }


  editEmail = false;
  async sendAtaToSign() {
    if (this.selTab === 'ata') {
      if (!this.canExecuteAction('SEND_ATA_TO_SIGN')) {
        this.pendingAction = 'SEND_ATA_TO_SIGN';
        this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });
        return;
      }

      if (this.toastr.findDuplicate(this.appConfig.infMsg.fetchingData.msg, false, false)) {
        this.toastr.clear();
      }


      if (!this.details.ata_concluida) {
        let res = await this.presentConcluirAtaModal();
        if (!res) return;
        let canCompleteAta = await this.ataConcluidaChanged();
        if (!canCompleteAta) return;
      } else {
        this.needAnexoPresencas = (!(this.details.hasOwnProperty('presencas_ficheiro') && this.details.presencas_ficheiro) && !(this.details.hasOwnProperty('presencas_representantes_ficheiro') && this.details.presencas_representantes_ficheiro));
        this.needAnexoProcuracoes = (!(this.details.hasOwnProperty('representantes_ficheiro') && this.details.representantes_ficheiro) && !(this.details.hasOwnProperty('presencas_representantes_ficheiro') && this.details.presencas_representantes_ficheiro) && (this.presencasList && !!this.presencasList.find(it => (it.cod_representative_name !== null || it.free_representative_name !== null))));
        if (this.needAnexoPresencas || this.needAnexoProcuracoes) {
          let res = await this.presentRepresentantesAlert();
          if (!res) return;
        }
      }

      let regMsg: string = null;
      if (this.mapaReuniao.entrega_assinatura != null) {
        regMsg = await this.presentAtaChanged('ENTREGUE_ASSINATURA');
        if (regMsg == null) return;
      }

      this.isEmailList = true;
      this.entregueParaAssinar = this.entregarParaAssinarOpts[0].value;
      this.selectionFraccaoList = JSON.parse(JSON.stringify(this.fraccaoList));
      this.selectionFraccaoList.forEach(el => {
        let emailOficial = this.emailsOficiais.find(e => e.cod_fraccao === el.cod_fraccao && e.cod_condomino == el.cod_proprietario);

        el.email_proprietario = emailOficial ? emailOficial.email : el.email_proprietario,
          el['hasEmailOficial'] = emailOficial ? !!emailOficial.email && emailOficial.email.trim() !== '' : null,
          el.disabled = false;
          // el['disabled'] = el.email_proprietario == null || el.email_proprietario.trim() === '';
      })

      // PRE-SET KNOWN VALUES
      this.anexos['presencas_procuracoes'] = (this.anexosAta[0].toLowerCase().indexOf('lista de presenças') !== -1 || this.anexosAta[0].toLowerCase().indexOf('procurações') !== -1);
      let aux = ((this.details.hasOwnProperty('presencas_ficheiro') && this.details.presencas_ficheiro) || this.details.hasOwnProperty('representantes_ficheiro') && this.details.representantes_ficheiro || (this.details.hasOwnProperty('presencas_representantes_ficheiro') && this.details.presencas_representantes_ficheiro));
      if (this.anexos['presencas_procuracoes'] && !aux) this.anexos['presencas_procuracoes'] = false;

      this.anexos['balancete'] = (this.anexosAta[0].toLowerCase().indexOf('balancete') !== -1);
      this.anexos['orcamento'] = (this.anexosAta[0].toLowerCase().indexOf('orçamento') !== -1);
      this.anexos['contaCorrente'] = (this.anexosAta[0].toLowerCase().indexOf('conta corrente') !== -1);
      // this.anexos['representantes'] = (this.anexosAta[0].toLowerCase().indexOf('lista de representantes') !== -1);
      this.anexos['outros'] = false;
      for (let i = 0; i < this.tiposAnexoOrig.length; i++) {
        if (this.anexosAta[0].toLowerCase().indexOf(this.tiposAnexoOrig[i].name.toLowerCase()) !== -1) {
          this.anexos['outros'] = true;
          break;
        }
      }

      this.anexosDisabled['presencas_procuracoes'] = !this.anexos['presencas_procuracoes'];
      this.anexosDisabled['balancete'] = !this.anexos['balancete'];
      this.anexosDisabled['orcamento'] = !this.anexos['orcamento'];
      this.anexosDisabled['contaCorrente'] = !this.anexos['contaCorrente'];
      // this.anexosDisabled['representantes'] = (!this.anexos['representantes'] || (this.details.hasOwnProperty('representantes_ficheiro') && !this.details.representantes_ficheiro));
      this.anexosDisabled['outros'] = !this.anexos['outros'];

      this.fraccoesListAlertConfig.size = 'normal';
      this.addEmailList = null;
      // this.updateEntregaAssin = true;
      this.fraccoesListModalRef = this.modalService
        .open(this.fraccoesListAlertConfig)
        .onApprove(async () => {
          let propEmailList = this.entregueParaAssinar === 'EMAIL' ? this.selectionFraccaoList.filter(el => (el.checked)) : this.selectionFraccaoList;

          let send: any = true;
          if (this.editEmail) {
            send = await this.openEmailEditionModal();
          } else {
            if (this.entregueParaAssinar === 'EMAIL') {
              this.emailConfig['assunto'] = this.appConfig.ataAnexoSubject;
              this.emailConfig['corpo'] = this.appConfig.ataAnexoBody;
            } else {
              this.emailConfig['assunto'] = this.appConfig.ataEntreguePresencialmenteSubject;
              this.emailConfig['corpo'] = this.appConfig.ataEntreguePresencialmenteBody;
            }
          }

          // if (this.updateEntregaAssin && this.automatizationsStep === 'DATA_ENTREGA_ASSINATURA') {
          if (this.automatizationsStep === 'DATA_ENTREGA_ASSINATURA') {
            try {
              await this.presentDoAssembleiaAutomatizationsModal();
            } catch (err) {
              // this.updateEntregaAssin = false;
              this.isEmailList = false;
              this.loadingModal = false;
              this.editEmail = false;
              return;
            }
          }

          if (send) {
            // if (this.updateEntregaAssin) {
            let ataEnviadaPorEmail = this.entregueParaAssinar === 'EMAIL';  
            try {
                var base64 = await this.genAndSendAnexoAtaAssinada(propEmailList, undefined, ataEnviadaPorEmail);
              } catch (err) {
                return;
              }

              let id_file = await this.businessLogic.saveFile({
                origem: 'ASSEMBLEIAS',
                filename: 'ata_assembleia_' + formatDate(new Date(), this.format, this.locale) + '.pdf',
                base64: base64,
                ids_registo_ctt: [],
              });

              let emailList: {fraccao: string, name: string, email?: string}[] = [];
              propEmailList.forEach(prop => {
                let email = (prop.email_proprietario) ? prop.email_proprietario + (prop.email_proprietario_2 ? ';' + prop.email_proprietario_2 : '') : null;
                
                // When it's not sent by email, it is provided in the store but we sent an email to everyone informing them.
                if (!email && !ataEnviadaPorEmail) return;

                let newElement = {
                  fraccao: prop.fraccao,
                  name: prop.proprietario,
                };
                if (email != null) {
                  newElement['email'] = email;
                }
                emailList.push(newElement);
              })

              let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
              let obj = id_file ? { fileId: id_file } : null;
              this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, ataEnviadaPorEmail ? 'Ata enviada para assinatura' : 'Ata disponibilizada em loja para assinatura', JSON.stringify(emailList), this.userSession.getUserId(), new Date(), regMsg, obj).subscribe(res => {
                if (res.hasOwnProperty('success') && res.success) {
                  this.regEmails = [{
                    description: ataEnviadaPorEmail ? 'Ata enviada para assinatura' : 'Ata disponibilizada em loja para assinatura',
                    emailList: emailList,
                    obj: obj,
                    name: this.userSession.getUserFullName(),
                    date: new Date(),
                    msg: regMsg,
                  } as RegEmails].concat(this.regEmails);
                  this.sortRegAtividade();
                  
                  this.getEmailLogs();
                }
              }, err => { });

              this.api.mapaReuniaoUpdateDatas(this.details.id_assembleia, 'DATA_ENTREGA_ASSINATURA', new Date()).subscribe(res => {
                if (res.hasOwnProperty('success') && res.success) { 
                  this.mapaReuniao.entrega_assinatura = (new Date()).toISOString();
                  this.mapaReuniao.copia_ata = null;
                  this.mapaReuniao.rececao = null;
                  this.details.ata_ficheiro = null;
                  this.details.id_ata_ficheiro = null;
                  this.presencasList.forEach(el => {
                    el.signature = false;
                  });
                  this.computePresencasTotal(false);
                } else { this.utils.apiErrorMsg(res) }
              },
                err => { });

          }

          this.isEmailList = false;
          this.loadingModal = false;
          this.editEmail = false;
        })
        .onDeny(() => { this.isEmailList = false; this.loadingModal = false; });

      return;
    }
  }


  @ViewChild('todoActionsRef', { static: false }) todoActionsRef;
  todoActionsModalRef = null;
  todoActionsModalConfig: any = null;
  doingActions = false;
  todoActionslist: Array<{ label: string, list: Array<{ descricao }> }> = [];
  presentDoAssembleiaAutomatizationsModal(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let todoFunctions = [];
      this.todoActionslist = [];

      //Orcamento
      let orcamentosList = this.orcamentoReports.filter(el => el.val_lancado == '0').map(el => { return { exercicio: el.exercicio, descricao: el.descricao } });
      if (orcamentosList.length) {
        this.todoActionslist.push({ label: 'ORCAMENTO', list: orcamentosList });
        todoFunctions.push(() => this.lancarOrcamento());
      }

      if (!this.todoActionslist.length) {
        resolve(true);
        return;
      }

      this.todoActionsModalRef = this.modalService
        .open(this.todoActionsModalConfig)
        .onApprove(() => {
          if (!todoFunctions.length) {
            resolve(true);
            return;
          }
          Promise.all(todoFunctions.map((a) => a())).then(res => {
            if (res.findIndex(el => !el) === -1) {
              resolve
            } else {
              reject(null);
            }
          }).catch(err => {
            reject(null);
          });
          resolve(true);
        })
        .onDeny((msg) => {
          if (msg === 'CANCEL') {
            reject(null);
          } else {
            resolve(false);
          }
        });
    });
  }

  lancarOrcamento(): Promise<boolean> {
    return new Promise(async resolve => {
      let req = [];
      let presentFCR = false;
      let presentPermilagem = false;
      await Promise.all(this.orcamentoReports.filter(el => el.val_lancado === '0').map(async el => {
        let orcamento = new OrcamentoDetailed(el.id, this.api, this.appConfig, this.utils, this.userSession, this.toastr);
        await orcamento.initialize();
        req.push(this.orcamentoService.lancarValores(orcamento));
        if (this.utils.cleanDecimalDigits(orcamento.orcamentoTotais.orc_valor * 0.1) > this.utils.cleanDecimalDigits(orcamento.orcamentoTotais.fr_valor)) {
          presentFCR = true;
        }

        if (orcamento.orcamentoTotais.permilagem < 999.99 || orcamento.orcamentoTotais.permilagem > 1000.009) {
          presentPermilagem = true;
        }
      }));

      if (presentFCR) {
        let success = await this.presentOrcamentoFCRAlert();
        if (!success) {
          this.todoActionsModalRef.deny('CANCEL');
          resolve(false);
          return;
        }
      }

      if (presentPermilagem) {
        let success = await this.presentOrcamentoPermilagemAlert();
        if (!success) {
          this.todoActionsModalRef.deny('CANCEL');
          resolve(false);
          return;
        }
      }

      if (req.length) {
        this.doingActions = true;
        forkJoin(req).subscribe(res => {
          res.forEach(el => {
            if (el) {
              let orc = this.orcamentoReports.find(orc => orc.id === el);
              if (orc) orc.val_lancado = '1';
            }
          });
          if (res.findIndex(el => !el) === -1) {
            this.doingActions = false;
            this.todoActionsModalRef.approve();
            this.toastr.success('Processamentos e avisos gerados com sucesso.', res.length === 1 ? 'Orçamento Lançado' : 'Orçamentos Lançados', { timeOut: 4000 });
            resolve(true);
          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.doingActions = false;
            this.todoActionsModalRef.deny('CANCEL');
            resolve(false);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.doingActions = false;
          this.todoActionsModalRef.deny('CANCEL');
          resolve(false);
        });
      } else {
        this.todoActionsModalRef.approve();
        resolve(true);
      }
    });
  }

  @ViewChild('alertFRRef', { static: false }) alertFRRef;
  alertFRModalRef = null;
  alertFRConfig: any = null;
  presentOrcamentoFCRAlert(): Promise<boolean> {
    return new Promise(resolve => {
      this.alertFRModalRef = this.modalService
        .open(this.alertFRConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }



  @ViewChild('permilagemAlertRef', { static: false }) permilagemAlertRef;
  permilagemModalRef = null;
  permilagemConfig: any = null;
  presentOrcamentoPermilagemAlert(): Promise<boolean> {
    return new Promise(resolve => {
      this.permilagemModalRef = this.modalService
        .open(this.permilagemConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }


  openEmailEditionModal() {
    return new Promise(resolve => {
      if (this.entregueParaAssinar === 'EMAIL') {
        this.emailConfig['assunto'] = this.appConfig.ataAnexoSubject;
        this.emailConfig['corpo'] = this.appConfig.ataAnexoBody.join('\n');
      } else {
        this.emailConfig['assunto'] = this.appConfig.ataEntreguePresencialmenteSubject;
        this.emailConfig['corpo'] = this.appConfig.ataEntreguePresencialmenteBody.join('\n');
      }

      this.updateEmailModalRef = this.modalService
        .open(this.updateEmailModalConfig)
        .onApprove(() => {
          this.emailConfig['corpo'] = this.emailConfig['corpo'].split('\n');

          resolve(true);
        })
        .onDeny(() => {
          this.emailConfig['assunto'] = null;
          this.emailConfig['corpo'] = null;

          resolve(false);
        });
    });
  }

  isEmailList = false;
  sendByEmail() {
    this.openDespesasModal = false;
    this.openGenAndSendDocsModal();
  }

  // ATA SPECIFIQUE CODE
  ataNum = 1;
  folhaNum = 2;
  anexosAta = [];
  anexosAtaOrig = [];
  introAta = null;
  introAtaOrig = null;
  ataConclusaoModel = null;
  introAtafirst = [];
  introAtaLast = [];

  anexos = {
    presencas_procuracoes: false,
    balancete: false,
    orcamento: false,
    contaCorrente: false,
    outros: false,
  }

  reportListColPDF = [
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left padding-left' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right valor-col' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right valor-col' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right valor-col' },
  ];

  presencasItemClicked(row: AssembleiasPresencas) {
    if (this.ataEditEnabled) return;

    row.present = row.present ? 0 : 1;
    this.computePresencasTotal();
    if (this.tabsObjDef.find(el => el.key === 'ata').disabled) return;

    this.addFraccoes('INTRODUCAO_ATA', null, true, true);
  }

  setConvEnviada = null;
  openGenAndSendDocsModal() {
    if (this.isCreate) {
      this.toastr.error('Não é possível gerar os documentos para envio. Necessita primeiro de guardar a presente assembleia.', 'Alerta', { timeOut: 4000 });
      return;
    }

    if (this.selTab === 'convocatorias') {
      if (this.details && this.details.ata_concluida == 1) {
        this.toastr.error('Não é possível proceder ao envio da convocatória. A ata já se encontra concluída.', 'Alerta', { timeOut: 4000 });
        return;
      }
      if (!this.tabsObjDef.find(el => el.key === 'ata').disabled) {
        this.toastr.error('Não é possível proceder ao envio da convocatória. A ata já se encontra em elaboração.', 'Alerta', { timeOut: 4000 });
        return;
      }
    }


    if (this.selTab === 'ata' && !this.hasAtaFicheiro()) {
      this.toastr.error('Não é possível proceder ao envio da ata. A correspondente digitalização não foi submetida.', 'Alerta', { timeOut: 4000 });
      return;
    }
    this.selectionFraccaoConvProcList = JSON.parse(JSON.stringify(this.fraccaoList)).map(el => {
      let emailOficial = this.emailsOficiais.find(e => e.cod_fraccao === el.cod_fraccao && e.cod_condomino == el.cod_proprietario);
      return {
        ...el,
        email_proprietario: emailOficial !== undefined ? emailOficial.email : el.email_proprietario,
        hasEmailOficial: emailOficial !== undefined ? !!emailOficial.email && emailOficial.email.trim() !== '' : null,
        checkedCarta: false,
        checkedEmail: emailOficial !== undefined,
      }
    });

    this.setConvEnviada = !this.convEnviada;
    this.updateCopiaAta = this.mapaReuniao.copia_ata != null ? false : true;
    this.addEmailList = null;
    this.genDespesaCTT = true;
    this.loadingModal = false;

    this.convProcModalRef = this.modalService
      .open(this.convProcAlertConfig)
      .onApprove(() => {
        this.convEnviadaChanged();
        if (this.selTab === 'convocatorias' && this.setConvEnviada) {
          // SUBMIT FORM GERAL
          this.formSubmitted('geral', true);
        }

        this.loadingModal = false;
        this.convProcModalRef = null;
      })
      .onDeny((openModal: boolean) => {
        this.loadingModal = false;
        if (openModal === true) {
          this.openDespesasCTTModal();
        }
        this.convProcModalRef = null;
      });

    if (this.selTab === 'convocatorias') this.checkDespesasCtt('CONVOCATORIA');
    if (this.selTab === 'ata') this.checkDespesasCtt('COPIA_ATA');
  }

  cartaProprietario = null;
  cartaMoradaProprietario = null;
  cartaCondominioNome = null;
  cartaMoradaCondominio = null;
  getAttachment(target, fraccao = null) {
    switch (target) {
      case 'convocatoria-procuracao':
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            this.pdfConvProcuracoesController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {

              let base64 = dataUri.replace('data:application/pdf;base64,', '');
              base64 = base64.replace(' ', '+');
              resolve(base64);
            });
          }, 10);
        });
        break;
      case 'anexo-ata':
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            let base64 = '';
            if (this.details.hasOwnProperty('ata_ficheiro') && this.details.ata_ficheiro) {
              base64 = this.details.ata_ficheiro.replace('data:application/pdf;base64,', '');
              base64 = base64.replace(' ', '+');
              resolve(base64);
            } else if (this.details.hasOwnProperty('id_ata_ficheiro') && this.details.id_ata_ficheiro) {
              this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
              this.api.getFile(this.details.id_ata_ficheiro).subscribe(res => {
                if (res && res.success) {
                  resolve(res.data.file);
                } else {
                  this.utils.apiErrorMsg(res);
                }
                this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
                resolve('');
              }, err => {
                this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
                this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
                resolve('');
              });

            }

          }, 10);
        });
        break;
    }
  }

  getEmailList(bckList, addEmailList = null) {
    return new Promise(async (resolve, reject) => {

      let emailsToSend = [];
      let auxArr = this.selectionFraccaoConvProcList.filter(el => (el.checkedEmail));

      let dataAssembleia = formatDate(this.geralForm.get('dt').value, 'dd-MM-yyyy HH:mm', this.locale);

      if (addEmailList) {
        addEmailList = addEmailList.replace(/ /g, '').split(';').map(it => { return { email_proprietario: it }; });
        if (Array.isArray(addEmailList) && addEmailList.length > 0) {
          auxArr = auxArr.concat(addEmailList);
        }
      }

      for (let i = 0; i < auxArr.length; i++) {

        this.convocatoriasProcuracoes = [
          bckList.find(it => (it.cod_fraccao === auxArr[i].cod_fraccao && it.type === 'CONVOCATORIA')),
          bckList.find(it => (it.cod_fraccao === auxArr[i].cod_fraccao && it.type === 'PROCURACAO')),
        ];

        if (this.convocatoriasProcuracoes[0] && this.convocatoriasProcuracoes[1]) {
          let base64 = await this.getAttachment('convocatoria-procuracao');
          let from = this.appConfig.company.email;
          let to = auxArr[i].email_proprietario;
          let subjectMsg = null;
          if (auxArr[i].hasOwnProperty('proprietario')) {
            subjectMsg = auxArr[i].proprietario + ' - Convocatória para Assembleia em: ' + dataAssembleia;
          } else {
            subjectMsg = 'Convocatória para Assembleia em: ' + dataAssembleia;
          }
          let bodyMsg = this.getEmailBody('convocatoria-procuracao');
          let attachment = base64;
          let fromName = 'VERTIS - Gestão Condomínios';
          let toName = (auxArr[i].hasOwnProperty('proprietario')) ? auxArr[i].proprietario : null;
          let filename = 'convocatoria_procuracao_' + formatDate(new Date(), this.format, this.locale) + '.pdf';

          emailsToSend.push({
            from: from,
            to: to,
            subject: subjectMsg,
            body: bodyMsg,
            attachment: attachment,
            filename: filename,
            fromName: fromName,
            toName: toName,
            email_proprietario_2: (auxArr[i].hasOwnProperty('proprietario')) ? auxArr[i].email_proprietario_2 : null,
            fraccao: (auxArr[i].hasOwnProperty('proprietario')) ? auxArr[i].fraccao : null,
          });
        }

      }

      let auxEmailsToSend = [];
      emailsToSend.forEach(el => {
        if (el.hasOwnProperty('email_proprietario_2') && el.email_proprietario_2) {
          let aux = JSON.parse(JSON.stringify(el));
          aux.email_proprietario = el.email_proprietario_2;
          aux.to = el.email_proprietario_2;
          auxEmailsToSend.push(aux);
        }
      });
      emailsToSend = emailsToSend.concat(auxEmailsToSend);

      resolve(emailsToSend);
    });
  }

  sendEmail = false;
  updateCopiaAta = false;
  openDespesasModal = false;
  sendingEmails = false;

  async genAndSendDocs() {
    if (this.selTab === 'ata') {
      if (this.mapaReuniao.copia_ata != null && this.selectionFraccaoConvProcList.findIndex(el => el.checkedCarta || el.checkedEmail) === -1) {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
        return;
      }
      if (!this.openDespesasModal && this.selectionFraccaoConvProcList.findIndex(el => el.checkedCarta) !== -1) {
        // OPEN DESPESAS CTT MODAL (BY APPROVING COPIA ATAS MODAL)
        this.openDespesasModal = true;
        this.convProcModalRef.deny(true);
      } else {
        if (this.addEmailList && this.addEmailList.trim()) {

          // CHECK IF EMAILS ARE VALID
          let auxEmails = this.addEmailList.replace(/ /g, '').split(';');
          for (let i = 0; i < auxEmails.length; i++) {
            if (!this.utils.validateEmail(auxEmails[i])) {
              this.emailInvalid = true;
              this.toastr.error('Por favor, verifique os endereços de email introduzidos antes de proceder com o envio.', 'Email(s) Inválido(s)', { timeOut: 4000 });

              setTimeout(() => { this.emailInvalid = false; }, 4000);
              return;
            }
          }

        }

        this.loadingModal = true;
        this.exportPdf('carta-ata', this.selectionFraccaoConvProcList, false);

        // UPDATE DATA COPIA ATA - MAPA DE REUNIAO
        if (this.updateCopiaAta) {
          this.api.mapaReuniaoUpdateDatas(this.details.id_assembleia, 'DATA_COPIA_ATA', new Date()).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) { 
              this.copiaAtaEnviada = true;
              this.mapaReuniao.copia_ata = (new Date()).toISOString();
            }
          },
            err => { });
        }

      }
    }

    if (this.selTab === 'convocatorias') {
      if (this.selectionFraccaoConvProcList.findIndex(el => el.checkedCarta || el.checkedEmail) === -1) {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
        return;
      }

      if (!this.openDespesasModal && this.selectionFraccaoConvProcList.findIndex(el => el.checkedCarta) !== -1) {
        // OPEN DESPESAS CTT MODAL (BY APPROVING CONVOCATORIAS MODAL)
        this.openDespesasModal = true;
        this.convProcModalRef.deny(true);
      } else {
        this.loadingModal = true;

        if (this.selectionFraccaoConvProcList.find(el => el.checkedEmail)) this.sendingEmails = true;

        // ADD SOME FEEDBACK IN CASE OF ERROR
        if (this.setConvEnviada) {
          this.api.saveDataConvocatoria(this.details.id_assembleia, this.docData).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {
              this.convEnviada = true;
            } else {
              this.utils.apiErrorMsg(res);
            }
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          });
        }

        // EXPORT FILE WITH LETTERS
        await this.exportPdf('convocatorias-procuracoes', this.selectionFraccaoConvProcList);

        setTimeout(async () => {

          // SEND EMAILS
          let emailsToSend: any = await this.getEmailList(this.convocatoriasProcuracoesEmail);

          // SEND EMAILS
          if (Array.isArray(emailsToSend) && emailsToSend.length > 0) {

            this.sendingEmails = true;
            this.api.sendEmailV2(emailsToSend).subscribe(res => {

              if (this.convProcModalRef) {
                this.convProcModalRef.approve();
              } else {
                if (this.setConvEnviada) this.formSubmitted('geral', true);
              }

              if (Array.isArray(emailsToSend)) {
                this.toastr.success(emailsToSend.length + ' emails foram enviados com sucesso', 'Email Enviado', { timeOut: 4000 });

                let emailList = [];
                emailsToSend.forEach(item => {
                  emailList.push({
                    fraccao: item.fraccao,
                    name: item.toName,
                    email: item.to
                  });
                });
                let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
                this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, 'Convocatória Enviada por Email', JSON.stringify(emailList), this.userSession.getUserId(), new Date()).subscribe(res => {
                  if (res.hasOwnProperty('success') && res.success) {

                    this.regEmails = [{
                      description: 'Convocatória Enviada por Email',
                      emailList: emailList,
                      name: this.userSession.getUserFullName(),
                      date: new Date(),
                      msg: null,
                    } as RegEmails].concat(this.regEmails);
                    this.sortRegAtividade();
                    this.sendingEmails = false;
                  }
                }, err => { this.sendingEmails = false; });

              } else {
                this.sendingEmails = false;
              }
            }, err => { this.sendingEmails = false; });
          } else {
            setTimeout(() => {
              if (this.convProcModalRef) {
                this.convProcModalRef.approve();
              } else {
                if (this.setConvEnviada) this.formSubmitted('geral', true);
              }

              this.sendingEmails = false;
            }, 1000);
          }

        }, 500);

      }
    }
  }

  emailInvalid = false;
  approveFraccoesList() {
    if (this.isEmailList && this.addEmailList && this.addEmailList.trim()) {
      // CHECK IF EMAILS ARE VALID
      let auxEmails = this.addEmailList.replace(/ /g, '').split(';');
      for (let i = 0; i < auxEmails.length; i++) {
        if (!this.utils.validateEmail(auxEmails[i])) {
          this.emailInvalid = true;
          this.toastr.error('Por favor, verifique os endereços de email introduzidos antes de proceder com o envio.', 'Email(s) Inválido(s)', { timeOut: 4000 });

          setTimeout(() => { this.emailInvalid = false; }, 4000);
          return;
        }
      }

      this.fraccoesListModalRef.approve();
    } else {
      if (this.entregueParaAssinar === 'EMAIL') {
        let nChecked = this.selectionFraccaoList.filter(el => !!el.checked).length;
        let justOne = this.maxSelection === this.minSelection
        if (this.minSelection != null && nChecked < this.minSelection) {
          this.toastr.error('Por favor, selecione ' + (justOne ? '' : 'no mínimo ') + this.minSelection + ' proprietários.', 'Ups...!', { timeOut: 4000 });
          return;
        }
        if (this.maxSelection != null && nChecked > this.maxSelection) {
          this.toastr.error('Por favor, selecione ' + (justOne ? '' : 'no máximo ') + this.maxSelection + ' proprietários.', 'Ups...!', { timeOut: 4000 });
          return;
        }
      }
      this.fraccoesListModalRef.approve();
    }
  }

  getEmailAnexoListV2(emailList, addEmailList = null, addAnexo: boolean): Promise<Email> {
    return new Promise(async (resolve, reject) => {

      let dataAssembleia = formatDate(this.geralForm.get('dt').value, 'dd-MM-yyyy HH:mm', this.locale);

      if (addEmailList) {
        addEmailList = addEmailList.replace(/ /g, '').split(';').map(it => { return { email_proprietario: it }; });
        if (Array.isArray(addEmailList) && addEmailList.length > 0) {
          emailList = emailList.concat(addEmailList);
        }
      }

      let base64: any = await this.exportPdf('ata', [], true, true);
      if (!base64) {
        resolve(null);
        return;
      }
      let filename = 'ata_assembleia_' + formatDate(new Date(), this.format, this.locale) + '.pdf';
      let attachment = base64.replace('data:application/pdf;base64,', '');
      let email: Email = {
        from: this.appConfig.company.email,
        to: [],
        bcc: [],
        cc: [],
        userId: this.userSession.getUserId(),
        origin: 'ASSEMBLEIAS',
        id_comunicacao: null,
        id_entidade: this.assembleiaId,
        subject: ((this.emailConfig['assunto'] !== null) ? this.emailConfig['assunto'] : this.appConfig.ataAnexoSubject) + ' - (' + dataAssembleia + ')',
        body: this.getEmailBody('anexo-ata'),
        attachment: addAnexo ? [{ ext: '.pdf', filename: filename, base64: attachment }] : [],
        filename: filename,
        fromName: 'VERTIS - Gestão Condomínios',
        descricao: 'Ata para Assinatura Enviada por Email',
      }

      for (let i = 0; i < emailList.length; i++) {
        if (emailList[i].email_proprietario) {
          email.bcc.push({ email: emailList[i].email_proprietario, name: emailList[i].proprietario, fraccao: emailList[i].fraccao });
          if (emailList[i].email_proprietario_2) {
            email.bcc.push({ email: emailList[i].email_proprietario_2, name: emailList[i].proprietario, fraccao: emailList[i].fraccao });
          }
        }
      }

      resolve(email);
    });
  }

  getEmailAnexoList(emailList, addEmailList = null, addAnexo: boolean): Promise<Email> {
    return new Promise(async (resolve, reject) => {

      let dataAssembleia = formatDate(this.geralForm.get('dt').value, 'dd-MM-yyyy HH:mm', this.locale);

      if (addEmailList) {
        addEmailList = addEmailList.replace(/ /g, '').split(';').map(it => { return { email_proprietario: it }; });
        if (Array.isArray(addEmailList) && addEmailList.length > 0) {
          emailList = emailList.concat(addEmailList);
        }
      }

      let base64: any = await this.getAttachment('anexo-ata');
      if (!base64) {
        resolve(null);
        return;
      }
      let filename = 'ata_assembleia_assinada_' + formatDate(new Date(), this.format, this.locale) + '.pdf';
      let attachment = base64.replace('data:application/pdf;base64,', '');
      let email: Email = {
        from: this.appConfig.company.email,
        to: [],
        bcc: [],
        cc: [],
        userId: this.userSession.getUserId(),
        origin: 'ASSEMBLEIAS',
        id_comunicacao: null,
        id_entidade: this.assembleiaId,
        subject: this.appConfig.ataAssinadaSubject + ' - (' + dataAssembleia + ')',
        body: this.getEmailBody('anexo-ata-assinada'),
        attachment: addAnexo ? [{ ext: '.pdf', filename: filename, base64: attachment }] : [],
        filename: filename,
        fromName: 'VERTIS - Gestão Condomínios',
        descricao: 'Ata Assinada Enviada por Email',
      }

      for (let i = 0; i < emailList.length; i++) {
        if (emailList[i].email_proprietario) {
          email.bcc.push({ email: emailList[i].email_proprietario, name: emailList[i].proprietario, fraccao: emailList[i].fraccao });
          if (emailList[i].email_proprietario_2) {
            email.bcc.push({ email: emailList[i].email_proprietario_2, name: emailList[i].proprietario, fraccao: emailList[i].fraccao });
          }
        }
      }
      resolve(email);
    });
  }

  loadingFile = false;
  genAndSendAnexoAtaAssinada(emailList, target = null, addAnexo = true): Promise<string> {
    return new Promise(async (resolve, reject) => {
      this.loadingModal = true;
  
      let email: Email = null;
      if (target === 'SIGNED_ATA') {
        email = await this.getEmailAnexoList(emailList, this.addEmailList, addAnexo);
      } else {
        email = await this.getEmailAnexoListV2(emailList, this.addEmailList, addAnexo);
      }
  
      // SEND EMAILS
      if (email && email.bcc.length) {
        this.api.sendEmail(email).subscribe(res => {
          if (this.convProcModalRef) this.convProcModalRef.approve();
  
          this.toastr.success('O email foi enviado com sucesso', 'Email Enviado', { timeOut: 4000 });
  
          let emailListAux = [];
          email.bcc.forEach(item => {
            emailListAux.push({
              fraccao: item.fraccao,
              name: item.name,
              email: item.email
            });
          });
          this.loadingModal = false;
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(email.attachment.length ? email.attachment[0].base64 : null);
        }, err => {
          this.loadingModal = false;
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          reject(null);
        });
  
      } else {
        if (this.convProcModalRef) this.convProcModalRef.approve();
        this.loadingModal = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(email.attachment.length ? email.attachment[0].base64 : null);
      }

    })
  }

  getEmailBody(target) {
    let htmlEmail = '';

    switch (target) {
      case 'convocatoria-procuracao':

        // TEXTO INICIAL
        htmlEmail += '<div style="margin-bottom: 35px;">';
        this.appConfig.convocatoriaBody.forEach(line => {
          if (line) {
            htmlEmail += '<span>' + line + '</span>';
          } else {
            htmlEmail += '<span><br><br></span>';
          }
        });
        htmlEmail += '</div>';

        htmlEmail += this.utils.getEmailFooterSimple(true);

        return this.utils.setEmailTemplate(htmlEmail);

      case 'anexo-ata-assinada':

        // TEXTO INICIAL
        htmlEmail += '<div style="margin-bottom: 35px;">';

        this.appConfig.ataAssinadaBody.forEach(line => {
          if (line) {
            htmlEmail += '<span>' + line + '</span>';
          } else {
            htmlEmail += '<span><br><br></span>';
          }
        });

        htmlEmail += '</div>';

        htmlEmail += this.utils.getEmailFooterSimple(true);

        return this.utils.setEmailTemplate(htmlEmail);

      case 'anexo-ata':

        // TEXTO INICIAL
        htmlEmail += '<div style="margin-bottom: 35px;">';

        if (this.emailConfig['corpo'] !== null) {
          this.emailConfig['corpo'].forEach(line => {
            if (line) {
              htmlEmail += '<span>' + line + '</span>';
            } else {
              htmlEmail += '<span><br><br></span>';
            }
          });
        } else {
          this.appConfig.ataAssinadaBody.forEach(line => {
            if (line) {
              htmlEmail += '<span>' + line + '</span>';
            } else {
              htmlEmail += '<span><br><br></span>';
            }
          });
        }

        htmlEmail += '</div>';

        htmlEmail += this.utils.getEmailFooterSimple(true);

        return this.utils.setEmailTemplate(htmlEmail);
    }
  }

  fromAssuntosDiversos = false;
  ordemTrabOptsAssuntosDiv: Array<{ disabled: boolean } & OrdemTrabalhoOpts> = [];
  assuntosDiversosRespObj: Array<OrdemTrabalhoDiversos> = [];  // STORE ALL RESPONSE FOR 'ASSUNTOS DIVERSOS'
  assuntosDiversosTextoInicial = null;
  assuntosDiversosTextoInicialArr = [];
  assuntosDiversosTextoInicialOrig = null;
  assuntosDiversosTextoInicialRespSel = null;

  canAddAssuntoDiverso(): boolean {
    return this.ordensTrabalhos.findIndex(ot => ot.label === 'ASSUNTOS_DIVERSOS') !== -1;
  }


  addAssuntoDiverso() {
    if (!this.canAddAssuntoDiverso()) return;

    this.fromAssuntosDiversos = true;
    this.ordemTrabOptsAssuntosDiv = JSON.parse(JSON.stringify(this.ordemTrabOpts.filter(el => (el.value.label !== 'ASSUNTOS_DIVERSOS' && el.value.id != 5))));
    this.ordemTrabOptsAssuntosDiv.forEach(el => { el.checked = false; el.disabled = false });
    this.ordemTrabOptsAssuntosDiv = this.ordemTrabOptsAssuntosDiv.filter(it => (it.value.is_assunto_diverso == 1));

    // OPTIONS PRE SELECTION
    this.ordemTrabOptsAssuntosDiv.forEach(el => {
      el.checked = this.assuntosDiversosRespObj.find(it => it.id === el.value.id) !== undefined;
      el.disabled = el.checked || this.ordensTrabalhos.find(it => it.id == el.value.id) != undefined;
    });

    this.ordemTrabModalRef = this.modalService
      .open(this.ordemTrabAlertConfig)
      .onApprove(() => {
        this.ordemTrabOptsAssuntosDiv.filter(el => (el.checked && this.assuntosDiversosRespObj.findIndex(ordem => ordem.id === el.value.id) === -1)).forEach(el => {
          this.assuntosDiversosRespObj.push(this.generateNewOrdemTrabalhoDiversos(el.value));
        });
        this.assuntosDiversosRespObj = this.assuntosDiversosRespObj.sort((a, b) => a.ordem - b.ordem);
        if (!this.assuntosDiversosTextoInicial) this.assuntosDiversosTextoInicial = this.assuntosDiversosTextoInicialOrig;

        this.fromAssuntosDiversos = false;

        this.setAnexosIndicators();
      })
      .onDeny(() => { this.fromAssuntosDiversos = false; });
  }

  checkAddOTModal(row: { disabled: boolean, checked: boolean }) {
    if (!row.disabled) row.checked = !row.checked;
  }

  deleteMultipleAttachments = false;
  deleteOrdemTrab = false;
  presentDeleteAttachmentModal(deleteMultipleAttachments: boolean, isDeleteOrdemTrabalho: boolean): Promise<boolean> {
    return new Promise((resolve) => {
      this.deleteMultipleAttachments = deleteMultipleAttachments;
      this.deleteOrdemTrab = isDeleteOrdemTrabalho
      this.deleteAttachmentModalRef = this.modalService
        .open(this.deleteAttachmentAlertConfig)
        .onApprove(() => { resolve(true) })
        .onDeny(() => { resolve(false) });
    })
  }

  async deleteAtaEntry(ordemTrabalho: OrdemTrabalhoDiversos) {
    if (ordemTrabalho.anexo_id != null) {
      // PRESENT ALERT TO DELETE ORDEM DE TRABALHO AND ASSOCIATED ATTACHMENT
      let del = await this.presentDeleteAttachmentModal(false, true);
      if (!del) return;
    }

    this.assuntosDiversosRespObj = this.assuntosDiversosRespObj.filter(el => el.id !== ordemTrabalho.id);
    this.setAnexosString();
  }

  mergePdf(fileName, base64Arr, contentType = 'data:application/pdf;base64,') {
    return new Promise(resolve => {
      this.api.mergePdf(fileName, contentType, base64Arr).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.data) {
            resolve(contentType + res.data);
          } else {
            resolve(false);
          }
        }
      },
        err => {
          resolve(false);
        });
    });
  }

  move(ordemTrab, action) {
    let index = this.assuntosDiversosRespObj.findIndex(el => el === ordemTrab);
    switch (action) {
      case 'UP':
        if (index === 0) return;

        this.assuntosDiversosRespObj = this.arrayMove(this.assuntosDiversosRespObj, index, index - 1);

        // UPDATE ORDEM
        let nextOrdem = this.assuntosDiversosRespObj[index].ordem;
        this.assuntosDiversosRespObj[index - 1].ordem = nextOrdem - 1;
        this.assuntosDiversosRespObj = this.assuntosDiversosRespObj.filter(el => (el !== null));
        break;
      case 'DOWN':
        if (index === this.assuntosDiversosRespObj.length - 1) return;

        this.assuntosDiversosRespObj = this.arrayMove(this.assuntosDiversosRespObj, index, index + 1);

        // UPDATE ORDEM
        let prevOrdem = this.assuntosDiversosRespObj[index].ordem;
        this.assuntosDiversosRespObj[index + 1].ordem = prevOrdem + 1;
        this.assuntosDiversosRespObj = this.assuntosDiversosRespObj.filter(el => (el !== null));
        break;
    }
  }

  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  addFornecedor(resp: OrdemTrabalhoItem) {
    if (!resp) return;
    this.selFornecedor = null;
    this.valorDespFornecedor = null;

    this.fornecedorModalRef = this.modalService
      .open(this.fornecedorAlertConfig)
      .onApprove(() => {
        if (resp.resp.indexOf('[[ FORNECEDOR ]]') !== -1 && this.selFornecedor) {
          resp.resp = resp.resp.replace(/\[\[ FORNECEDOR \]\]/g, this.selFornecedor.nome);
        }

        if (resp.resp.indexOf('[[ VALOR_DESPESA ]]') !== -1 && this.valorDespFornecedor) {
          resp.resp = resp.resp.replace(/\[\[ VALOR_DESPESA \]\]/g, this.utils.getNumberFormatted(Number(this.valorDespFornecedor)));
        }

        if (resp.resp.indexOf('[[ VALOR_DESPESA_EXT ]]') !== -1 && this.valorDespFornecedor) {
          resp.resp = resp.resp.replace(/\[\[ VALOR_DESPESA_EXT \]\]/g, this.utils.getNumberPorExtenso(Number(this.valorDespFornecedor)));
        }
      })
      .onDeny(() => { this.selFornecedor = null; this.valorDespFornecedor = null; });
  }

  fornecedorTimer = null;
  selFornecedor = null;
  valorDespFornecedor = null;
  fornecedoresLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selFornecedor); });
    }

    clearTimeout(this.fornecedorTimer);
    return new Promise(resolve => {
      if (query) {
        this.fornecedorTimer = setTimeout(() => {
          // FORNECEDOR LIST
          this.api.getAllFornecedores(query).subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }, 400);
      } else {
        this.api.getAllFornecedores('NULL').subscribe(res => {
          if (res.success) {
            return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
          } else {
            return resolve([]);
          }
        });
      }
    });
  };

  representanteTimer = null;
  selRepresentante: { cod: string, nome: string } = null;
  nomeRepresentante = null;
  representantesLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selFornecedor); });
    }

    clearTimeout(this.representanteTimer);
    return new Promise(resolve => {
      if (query) {
        this.representanteTimer = setTimeout(() => {
          // PROPRIETARIOS/INGUILINOS LIST
          this.api.getAllCondominos(query, 'proprietarios-inquilinos').subscribe(res => {
            if (res.success) {
              return resolve([{ name: '--- Entrada livre ---', value: { cod: '0', nome: null } }, { name: '--- Sem representante ---', value: { cod: '-1', nome: null } }].concat(res.data.map(el => { return { name: el.nome, value: { cod: el.cod, nome: el.nome } }; })));
            } else {
              return resolve([{ name: '--- Entrada livre ---', value: { cod: '0', nome: null } }, { name: '--- Sem representante ---', value: { cod: '-1', nome: null } }]);
            }
          });
        }, 400);
      } else {
        this.api.getAllCondominos('NULL', 'proprietarios-inquilinos').subscribe(res => {
          if (res.success) {
            return resolve([{ name: '--- Entrada livre ---', value: { cod: '0', nome: null } }, { name: '--- Sem representante ---', value: { cod: '-1', nome: null } }].concat(res.data.map(el => { return { name: el.nome, value: { cod: el.cod, nome: el.nome } }; })));
          } else {
            return resolve([{ name: '--- Entrada livre ---', value: { cod: '0', nome: null } }, { name: '--- Sem representante ---', value: { cod: '-1', nome: null } }]);
          }
        });
      }
    });
  };

  addRepresentante(presenca: AssembleiasPresencasList) {
    if (this.ataEditEnabled) return;

    this.selRepresentante = null;
    this.nomeRepresentante = null;

    this.submittingRepresentante = false;
    this.representanteModalRef = this.modalService
      .open(this.representanteAlertConfig)
      .onApprove(() => {

        if (this.selRepresentante.cod === '0') {
          presenca.free_representative_name = this.nomeRepresentante;
          presenca.cod_representative = null;
          presenca.cod_representative_name = null;
        } else if (this.selRepresentante.cod === '-1') {
          presenca.free_representative_name = null;
          presenca.cod_representative = null;
          presenca.cod_representative_name = null;
        } else {
          presenca.free_representative_name = null;
          presenca.cod_representative = parseInt(this.selRepresentante.cod);
          presenca.cod_representative_name = this.selRepresentante.nome;
        }

        if (this.selRepresentante.cod !== '-1') presenca.present = true;
        this.computePresencasTotal();
      })
      .onDeny(() => { this.selRepresentante = null; this.nomeRepresentante = null; });
  }

  submittingRepresentante = false;
  submitRepresentante() {
    this.submittingRepresentante = true;

    let invalid = false;
    if (!this.selRepresentante || (this.selRepresentante && !this.selRepresentante.hasOwnProperty('cod')) || (this.selRepresentante && this.selRepresentante.hasOwnProperty('cod') && this.selRepresentante.cod !== '0')) {
      // SELECT FIELD
      if (!this.selRepresentante) invalid = true;
    } else {
      // INPUT FIELD
      if (!this.nomeRepresentante || this.nomeRepresentante.trim() === '') invalid = true;
    }

    if (invalid) {
      setTimeout(() => { this.submittingRepresentante = false; }, 3000);
      return;
    }

    if (this.representanteModalRef) this.representanteModalRef.approve();
  }

  async deleteOrdemTrabalho(ordemTrab: OrdemTrabalhoList, flag = true) {
    let nAttachmentsToDelete = ordemTrab.resps.filter(resp => resp.anexo_id != null).length;


    if (flag && nAttachmentsToDelete > 0) {
      // PRESENT ALERT TO DELETE ORDEM DE TRABALHO AND ASSOCIATED ATTACHMENT
      let del = await this.presentDeleteAttachmentModal(nAttachmentsToDelete > 1, true);
      if (!del) return;
    }

    let res = await this.canDeleteOrdemTrabalho(ordemTrab);
    if (!res) return;

    this.ordensTrabalhos = this.ordensTrabalhos.filter(el => el.id !== ordemTrab.id);
    if (!this.ordensTrabalhos.find(el => el.label === 'ASSUNTOS_DIVERSOS')) this.assuntosDiversosRespObj = [];
    this.updateReports([], ordemTrab.label);
    this.setAnexosString();
  }


  @ViewChild('recibosToDelete', { static: false }) recibosToDelete: ProcessamentoDeleteRecibosComponent;
  canDeleteOrdemTrabalho(ordemTrab: OrdemTrabalhoList): Promise<boolean> {
    return new Promise(async (resolve) => {
      let checkProcessamentosToDelete: Array<{ id, cod }> = [];
      for (let i = 0; i < ordemTrab.resps.length; i++) {
        const resp = ordemTrab.resps[i];
        for (let j = 0; j < resp.parametros.length; j++) {
          const param = resp.parametros[j];
          let valor = null;
          switch (param.chave) {
            case 'BANCO_ABERTURA':
              try {
                valor = JSON.parse(param.valor);
              } catch (err) {
              }
              if (valor == null || valor['conta'] == null) continue;

              let hasMovs = await this.assembleiasService.contaHasMovements(valor['conta']);
              if (hasMovs === 1) {
                this.toastr.error(this.appConfig.errorCodes.ERR_ASSEMBLEIAS_003.msg, this.appConfig.errorCodes.ERR_ASSEMBLEIAS_003.title);
                resolve(false);
                return;
              }
              break;

            case 'PROCESSAMENTO':
              valor = param.valor;
              if (valor == null) continue;

              let procDetails: ProcessamentoDetails = null;
              try {
                procDetails = await this.processamentos.getProcessamento(valor, { includeRecibos: true });
              } catch (err) {
                resolve(false);
                return;
              }

              if (procDetails.is_lancado == 1 && procDetails.active == 1 && procDetails.id_assembleia_lancamento == this.details.id_assembleia) {
                checkProcessamentosToDelete.push({ id: procDetails.id, cod: procDetails.cod });
              }

              break;

            default:
              break;
          }
        }
      }

      if (checkProcessamentosToDelete.length) {
        let msg = checkProcessamentosToDelete.length === 1 ?
          '<b>Deseja remover a ordem de trabalho</b>? Todos os avisos e créditos provenientes do processamento lançado através da presente assembleia serão eliminados e o processamento será convertido de novo para simulação.' :
          '<b>Deseja remover a ordem de trabalho</b>? Todos os avisos e créditos provenientes dos processamentos lançados através da presente assembleia serão eliminados e os processamentos serão convertidos de novo para simulações.'
        let continu = await this.recibosToDelete.open(checkProcessamentosToDelete, 'REVERT', msg, false);
        if (!continu) {
          resolve(false);
          return;
        }
      }

      resolve(true);
    });
  }

  canDeleteOrdemTrabalhoItem(resp: OrdemTrabalhoItem): Promise<boolean> {
    return new Promise(async (resolve) => {

      let checkProcessamentosToDelete: Array<{ id, cod }> = [];
      let valor = null;
      for (let i = 0; i < resp.parametros.length; i++) {
        const param = resp.parametros[i];
        switch (param.chave) {
          case 'PROCESSAMENTO':
            valor = param.valor;
            if (valor == null) continue;

            let procDetails: ProcessamentoDetails = null;
            try {
              procDetails = await this.processamentos.getProcessamento(valor, { includeRecibos: true });
            } catch (err) {
              resolve(false);
              return;
            }

            if (procDetails.is_lancado == 1 && procDetails.active == 1 && procDetails.id_assembleia_lancamento == this.details.id_assembleia) {
              checkProcessamentosToDelete.push({ id: procDetails.id, cod: procDetails.cod });
            }
            break;

          default:
            break;
        }

      }

      if (checkProcessamentosToDelete.length) {
        let msg = checkProcessamentosToDelete.length === 1 ?
          '<b>Deseja remover a resposta à ordem de trabalho?</b> Todos os avisos e créditos provenientes do processamento lançado através da presente assembleia serão eliminados e o processamento será convertido de novo para simulação.' :
          '<b>Deseja remover a resposta à ordem de trabalho?</b> Todos os avisos e créditos provenientes dos processamentos lançados através da presente assembleia serão eliminados e os processamentos serão convertidos de novo para simulações.'
        let continu = await this.recibosToDelete.open(checkProcessamentosToDelete, 'REVERT', msg, false);
        if (!continu) {
          resolve(false);
          return;
        }
      }
      resolve(true);
    });
  }

  async deleteOrdemTrabalhoItem(ordemIndex, respIndex) {
    let resp = this.ordensTrabalhos[ordemIndex].resps[respIndex];
    if (resp.anexo_id != null) {
      let del = await this.presentDeleteAttachmentModal(false, true);
      if (!del) return;
    }


    let continu = await this.canDeleteOrdemTrabalhoItem(this.ordensTrabalhos[ordemIndex].resps[respIndex]);
    if (!continu) {
      return;
    }


    let ordem = this.ordensTrabalhos[ordemIndex];
    ordem.resps.splice(respIndex, 1);
    if (ordem.selAnos) {
      ordem.selAnos.splice(respIndex, 1);
      this.setAnosDescricao(ordem, ordem.selAnos, false);
    }
    this.setAnexosString();
  }

  // DESPESAS CTT SECTION - METHOS AND VARIABLES
  genDespesaCTT = false;
  despCttAllSelection = false;

  @ViewChild('despesasCttAlertRef', { static: false }) despesasCttAlertRef;
  despesasCttModalRef = null;
  despesasCttAlertConfig: any = null;
  despesasCttList = [];
  cartasCounter = 0;

  criarDespesa = true;
  async openDespesasCTTModal() {
    // GENERATE DESPESAS CTT TABLE
    
    this.criarDespesa = this.genDespesaCTT;
    
    this.despesasCttList = [];
    let aux = null;

    let nFolhasPorEnvelope = 2;
    if (this.selTab === 'ata') {
      nFolhasPorEnvelope = 1;
      if (this.details && this.details.hasOwnProperty('ata_ficheiro') && this.details.ata_ficheiro) {
        nFolhasPorEnvelope += this.utils.getPdfPageCount(this.details.ata_ficheiro);  // COMPUTE BASED ON GENERATED PDF
      } else if (this.details && this.details.hasOwnProperty('id_ata_ficheiro') && this.details.id_ata_ficheiro) {
        let base64AtaFicheiro = await this.businessLogic.getFile(this.details.id_ata_ficheiro);
        if (!base64AtaFicheiro) return;
        nFolhasPorEnvelope += this.utils.getPdfPageCount(base64AtaFicheiro);  // COMPUTE BASED ON GENERATED PDF
      }
    }

    this.selectionFraccaoConvProcList.forEach(el => {
      aux = {
        codCondominio: el.cod_condominio,
        codZona: el.cod_zona,
        codFraccao: el.cod_fraccao,
        permilagem: el.permilagem,
        codRubrica: null,

        descricao: el.fraccao,
        condomino: el.proprietario,
        cod_condomino: el.cod_proprietario,
        dataDespesa: new Date(this.docData),
        // tipo: 'SIMPLES',
        tipo: 'NORMAL',
        tipo_entidade: 'PROPRIETARIO',
        avisoRececao: false,

        nEnvelopes: 1,
        nFolhasPorEnvelope: nFolhasPorEnvelope,

        valorDespesa: null,
        custoVertis: null,
        custoCtt: null,

        checked: el.checkedCarta,
      }

      this.computeDespesaCtt(aux, false);

      this.despesasCttList.push(aux);
    });

    this.setCartasCounter();
    this.totalDespesas = {
      simples: null, simplesDisabled: true,
      normal: null, normalDisabled: true,
      registado: null, registadoDisabled: true,
      avisoRececao: null, avisoRececaoDisabled: true,
      totalComputedCtt: null,
      total: null,
    }
    this.computeTotals();

    // OPEN DESPESAS CTT MODAL
    this.despesasCttModalRef = this.modalService
      .open(this.despesasCttAlertConfig)
      .onApprove(() => {
        this.despCttAllSelection = false;
        this.genDespesaCTT = false;
        this.genAndSendDocs();
      })
      .onDeny(() => {
        this.despCttAllSelection = false;
      });

    if (this.despesasCttList.length > 0) this.getPaymentData(this.despesasCttList[0].codCondominio);
  }

  computeDespesaCtt(item, computeTotals = true) {
    let cttObj = this.utils.computeCorrespValue(item.nEnvelopes, item.nFolhasPorEnvelope, item.tipo, item.avisoRececao);
    if (cttObj) {
      item.codRubrica = cttObj.codRubrica;
      item.valorDespesa = (Number(cttObj.valorDespesa)).toFixed(2);
      item.custoVertis = cttObj.custoVertis;
      item.custoCtt = cttObj.custoCtt;
    }
    if (computeTotals) this.computeTotals();
  }

  totalDespesas = {
    simples: null, simplesDisabled: true,
    normal: null, normalDisabled: true,
    registado: null, registadoDisabled: true,
    avisoRececao: null, avisoRececaoDisabled: true,
    totalComputedCtt: null,
    total: null,
  }
  computeTotals(updateDespesas = true) {
    // COMPUTE TOTALS
    this.totalDespesas.totalComputedCtt = this.despesasCttList.filter(el => el.checked).map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);

    if (updateDespesas) {
      this.totalDespesas.simples = this.despesasCttList.filter(el => el.checked && el.tipo === 'SIMPLES').map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);
      this.totalDespesas.normal = this.despesasCttList.filter(el => el.checked && el.tipo === 'NORMAL').map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);
      this.totalDespesas.registado = this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao).map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);
      this.totalDespesas.avisoRececao = this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao).map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);

      this.totalDespesas.simples = (this.totalDespesas.simples) ? Number(this.totalDespesas.simples).toFixed(2) : null;
      this.totalDespesas.normal = (this.totalDespesas.normal) ? Number(this.totalDespesas.normal).toFixed(2) : null;
      this.totalDespesas.registado = (this.totalDespesas.registado) ? Number(this.totalDespesas.registado).toFixed(2) : null;
      this.totalDespesas.avisoRececao = (this.totalDespesas.avisoRececao) ? Number(this.totalDespesas.avisoRececao).toFixed(2) : null;
    }

    this.totalDespesas.total = Number(this.totalDespesas.simples) + Number(this.totalDespesas.normal) + Number(this.totalDespesas.registado) + Number(this.totalDespesas.avisoRececao);
    if (!this.totalDespesas.total) this.totalDespesas.total = this.totalDespesas.totalComputedCtt;

    // SET DISABLED FLAG
    this.totalDespesas.simplesDisabled = (this.despesasCttList.filter(el => el.checked && el.tipo === 'SIMPLES').length === 0);
    this.totalDespesas.normalDisabled = (this.despesasCttList.filter(el => el.checked && el.tipo === 'NORMAL').length === 0);
    this.totalDespesas.registadoDisabled = (this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao).length === 0);
    this.totalDespesas.avisoRececaoDisabled = (this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao).length === 0);
  }

  setCartasCounter() {
    let simplesCounter = (this.despesasCttList.filter(el => el.checked && el.tipo === 'SIMPLES').length > 0) ? 1 : 0;
    let normalCounter = (this.despesasCttList.filter(el => el.checked && el.tipo === 'NORMAL').length > 0) ? 1 : 0;
    let registadoCounter = (this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao).length > 0) ? 1 : 0;
    let avisoRececaoCounter = (this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao).length > 0) ? 1 : 0;

    this.cartasCounter = simplesCounter + normalCounter + registadoCounter + avisoRececaoCounter;
  }

  typeChanged(item, type = null) {
    if (type === 'AVISO_RECEPCAO' && item.avisoRececao) {
      item.tipo = 'REGISTADO';
    } else {
      if (item.tipo !== 'REGISTADO') item.avisoRececao = false;
    }

    this.computeDespesaCtt(item);
    this.setCartasCounter();
  }

  setTypeAll(type) {
    let avisoRececao = true;
    if (type === 'AVISO_RECECAO') {
      this.despesasCttList.forEach(el => el.tipo = 'REGISTADO');

      if (this.despesasCttList.filter(el => el.avisoRececao).length === this.despesasCttList.length) {
        avisoRececao = false;
      }
    }

    this.despesasCttList.forEach(el => {
      if (el.checked) {
        switch (type) {
          case 'SIMPLES': el.tipo = 'SIMPLES'; el.avisoRececao = false; break;
          case 'NORMAL': el.tipo = 'NORMAL'; el.avisoRececao = false; break;
          case 'REGISTADO': el.tipo = 'REGISTADO'; break;
          case 'AVISO_RECECAO': if (el.tipo === 'REGISTADO') el.avisoRececao = avisoRececao; break;
        }

        this.computeDespesaCtt(el);
      }
    });
  }

  getCodFornecedorCtt(codRubrica) {
    switch (codRubrica) {
      case '98':  // Correio Normal
        return '16';
      case '42':  // Correio Registado
        return '16';
      case '47':  // Correio Simples
        return '9';
    }
  }

  async genDespesasCtt(target = null, pagar = false) {
    // TODO: IMPLEMENT VALIDATORS (AT LEAST ONE ROW SELECTED, TOTAL DISABLED => VALUE > 0)
    if (!this.despesasCttList.find(el => el.checked)) {
      this.toastr.error('Não é possível lançar despesas Ctt. Por favor, selecione pelo menos um condómino.', 'Alerta', { timeOut: 4000 });
      return;
    }
    if (this.criarDespesa && (this.despesasCttList.find(el => el.checked && !el.dataDespesa) || (!this.totalDespesas.simplesDisabled && !this.totalDespesas.simples) || (!this.totalDespesas.normalDisabled && !this.totalDespesas.normal) || (!this.totalDespesas.registadoDisabled && !this.totalDespesas.registado) || (!this.totalDespesas.avisoRececaoDisabled && !this.totalDespesas.avisoRececao))) {
      this.toastr.error('Não é possível lançar despesas Ctt. Por favor, verifique todos os campos assinalados.', 'Alerta', { timeOut: 4000 });
      return;
    }

    if (target === 'LANCAR_PAGAR') {
      this.submittingPaymentForm = false;

      let max = Math.max.apply(null, this.despesasCttList.filter(el => el.checked && el.dataDespesa).map(el => el.dataDespesa));
      this.dt_pag = (max) ? new Date(max) : null;

      this.form_pagam = '1';
      this.tipoPagamento = ' -N';
      let contaSeleted = this.contaOptsOrig.find(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      if (contaSeleted) this.contaSeleted = contaSeleted['value'];
      setTimeout(() => { this.paymentOptChanged(); }, 1);

      this.pagamentoModalRef = this.modalService.open(this.pagamentoAlertConfig)
        .onApprove(() => { this.genDespesasCtt(null, true); })
        .onDeny(() => { });

      return;
    } else {
      let dataConv = (this.geralForm.get('dt').value) ? new Date(this.geralForm.get('dt').value) : null;

      let ePagaMsg = (pagar) ? ' e Liquidada' : '';

      // SAVE ALL DESPESAS CTT (ONE API CALL) AND SAVE REGISTO DE ACTIVIDADE --
      let despesas = [];
      let despesa = null;

      let registosCtt:Array<{aviso_recepcao, cod_condominio, condominos_obj, conta_simples, correiro_normal, custo_ctt, custo_vertis, data, data_despesa, descricao, destinatarios: Array<any>, id_user, n_envelope, n_folhas_env, nome_condomino, pag_ctt, recibo_ctt, registado, valor_lancado}> = [];
      let proprietarios = [];

      let linhasDespesas = [];
      this.despesasCttList.forEach(el => {
        if (!linhasDespesas.find(it => it.cod_zona === el.codZona)) {
          linhasDespesas.push({
            cod_zona: el.codZona,
            permilagem: this.despesasCttList.filter(item => item.codZona === el.codZona).map(item => item.permilagem).reduce((a, b) => a + b, 0),
            valor: null,
          });
        }
      });

      let despesaDesc = `Assembleia (${this.utils.getFormatedDate(dataConv, 'dd-MM-yyyy HH:mm')})`;
      switch (this.selTab) {
        case 'convocatorias': despesaDesc = `Assembleia (${this.utils.getFormatedDate(dataConv, 'dd-MM-yyyy HH:mm')})`; break;
        case 'ata': despesaDesc = `Copia da ata nº ${this.ataNum}`; break;
      }

      // DESPESAS SIMPLES
      if (!this.totalDespesas.simplesDisabled) {
        despesa = this.despesasCttList.find(el => el.checked && el.tipo === 'SIMPLES');

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(this.totalDespesas.simples) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesaDesc,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(this.totalDespesas.simples),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ePagaMsg}. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${this.totalDespesas.simples} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dt_pag) ? this.utils.getFormatedDate(new Date(this.dt_pag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.cod_conta_bancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = this.despesasCttList.filter(el => el.checked && el.tipo === 'SIMPLES');
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '1',
          correiro_normal: '0',
          registado: '0',
          aviso_recepcao: '0',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesaDesc,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,

          valor_lancado: Number(this.totalDespesas.simples),
        });
      }

      // DESPESAS NORMAL
      if (!this.totalDespesas.normalDisabled) {
        despesa = this.despesasCttList.find(el => el.checked && el.tipo === 'NORMAL');

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(this.totalDespesas.normal) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesaDesc,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(this.totalDespesas.normal),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ePagaMsg}. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${this.totalDespesas.normal} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dt_pag) ? this.utils.getFormatedDate(new Date(this.dt_pag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.cod_conta_bancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = this.despesasCttList.filter(el => el.checked && el.tipo === 'NORMAL');
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '0',
          correiro_normal: '1',
          registado: '0',
          aviso_recepcao: '0',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesaDesc,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,

          valor_lancado: Number(this.totalDespesas.normal),
        });
      }

      // DESPESAS REGISTADO
      if (!this.totalDespesas.registadoDisabled) {
        despesa = this.despesasCttList.find(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao);

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(this.totalDespesas.registado) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesaDesc,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(this.totalDespesas.registado),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ePagaMsg}. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${this.totalDespesas.registado} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dt_pag) ? this.utils.getFormatedDate(new Date(this.dt_pag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.cod_conta_bancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao);
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '0',
          correiro_normal: '0',
          registado: '1',
          aviso_recepcao: '0',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesaDesc,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,

          valor_lancado: Number(this.totalDespesas.registado),
        });
      }

      // DESPESAS REGISTADO / AVISO RECECAO
      if (!this.totalDespesas.avisoRececaoDisabled) {
        despesa = this.despesasCttList.find(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao);

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(this.totalDespesas.avisoRececao) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesaDesc,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(this.totalDespesas.avisoRececao),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ePagaMsg}. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${this.totalDespesas.avisoRececao} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dt_pag) ? this.utils.getFormatedDate(new Date(this.dt_pag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.cod_conta_bancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao);
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '0',
          correiro_normal: '0',
          registado: '1',
          aviso_recepcao: '1',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesaDesc,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,

          valor_lancado: Number(this.totalDespesas.avisoRececao),
        });
      }

      let res: any = await this.createDespesasCtt(despesas, registosCtt, pagar);
      if (res) {
        this.loadingModal = false;
        this.loadingModalPagar = false;
        this.despesasCttModalRef.approve();
      } else {
        this.loadingModal = false;
        this.loadingModalPagar = false;
      }
    }
  }

  createDespesasCtt(despesas, registosCtt: Array<{aviso_recepcao, cod_condominio, condominos_obj, conta_simples, correiro_normal, custo_ctt, custo_vertis, data, data_despesa, descricao, destinatarios: Array<any>, id_user, n_envelope, n_folhas_env, nome_condomino, pag_ctt, recibo_ctt, registado, valor_lancado}>, pagar = false) {
    return new Promise(async (resolve) => {

      if (!this.criarDespesa) {
        try {
          var motivoSemDespesa = await this.askForMotivoSemDespesa()
        } catch (err) {
          resolve(false);
          return;
        }
      }

      
      let description = this.selTab === 'ata' ? 'Cópia da ata assinada enviada por carta': 'Convocatória enviada por carta';
      let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;

      if (!this.criarDespesa) {

        let registosCTT: Array<RegistoCTTSaveDetailed> = [];
        registosCtt.forEach(el => {
          registosCTT.push({
            cod_condominio: el.cod_condominio,
            conta_simples: el.conta_simples,
            correiro_normal: el.correiro_normal,
            registado: el.registado,
            aviso_recepcao: el.aviso_recepcao,
            data: el.data,
            data_despesa: null,
            id_user: el.id_user,
            nome_condomino: el.nome_condomino,
            condominos_obj: el.condominos_obj,
            n_envelope: el.n_envelope,
            n_folhas_env: el.n_folhas_env,
            custo_ctt: el.custo_ctt,
            custo_vertis: el.custo_vertis,
            pag_ctt: el.pag_ctt,
            recibo_ctt: el.recibo_ctt,
            valor_lancado: null,
            descricao: el.descricao,
            idDespesa: null,
            nDespesa: null,
            motivo_sem_despesa: motivoSemDespesa,
            destinatarios: el.destinatarios.map(dest => {
              let aux = {
                codCondominio: dest.codCondominio,
                codFraccao: dest.codFraccao,
                cod_condomino: dest.cod_condomino,
                tipo_entidade: dest.tipo_entidade,
                id_texto_predefinido: dest.id_texto_predefinido,
                data_divida: dest.data_divida,
              }
              if (dest.hasOwnProperty('id_reconciliacao')) {
                aux['id_reconciliacao'] = dest.id_reconciliacao;
              }
              return aux;
            })
          });
        });

        (pagar) ? this.loadingModalPagar = true : this.loadingModal = true;
        this.api.saveRegistoCTTDetailed(registosCTT).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            let regCtt = [];
            registosCtt.forEach((el, i) => {
              let aux = {
                nome_condomino: el.nome_condomino,
                n_envelope: el.n_envelope,
                n_folhas_env: el.n_folhas_env,
                valor_lancado: null,
                id_despesa: null,
                n_despesa: null,
                cod_despesa: null,
                subTitle: 'Sem despesa CTT',
                motivo: motivoSemDespesa
              } 
  
              aux['tipo'] = null;
              if (el.conta_simples === '1') {
                aux['tipo'] = 'SIMPLES';
              } else if (el.correiro_normal === '1') {
                aux['tipo'] = 'NORMAL';
              } else if (el.registado === '1' && el.aviso_recepcao === '0') {
                aux['tipo'] = 'REGISTADO';
              } else if (el.registado === '1' && el.aviso_recepcao === '1') {
                aux['tipo'] = 'AVISO_RECECAO';
              }
              regCtt.push(aux);
            });

            
            this.saveRegistoComunicacao('ASSEMBLEIAS', this.details.id_assembleia, cod, description, regCtt, this.userSession.getUserId(), new Date(), null).then(res => {
              resolve(true);
            }).catch(err => {
              resolve(false);
            })
            
            resolve(true);
          } else {
            this.utils.apiErrorMsg(res);
            resolve(false);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          resolve(false);
        })
        return;
      }


      (pagar) ? this.loadingModalPagar = true : this.loadingModal = true;

      this.api.saveAssembleiaDespesasCtt(this.details.id_assembleia, despesas, registosCtt, 'ASSEMBLEIAS', pagar, 'DESPESAS').subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          // REGISTO DE ACTIVIDADE LOCAL
          let subTitle = (!pagar) ? 'Despesa(s) CTT lançada(s)' : 'Despesa(s) CTT lançada(s) e paga(s)';
          let regCtt = [];
          registosCtt.forEach((el, i) => {
            let aux = {
              nome_condomino: el.nome_condomino,
              n_envelope: el.n_envelope,
              n_folhas_env: el.n_folhas_env,
              valor_lancado: el.valor_lancado,
              id_despesa: Number(res.data[i].id_despesa),
              n_despesa: Number(res.data[i].n_despesa),
              cod_despesa: Number(res.data[i].cod_despesa),
              subTitle: subTitle,
            } 

            aux['tipo'] = null;
            if (el.conta_simples === '1') {
              aux['tipo'] = 'SIMPLES';
            } else if (el.correiro_normal === '1') {
              aux['tipo'] = 'NORMAL';
            } else if (el.registado === '1' && el.aviso_recepcao === '0') {
              aux['tipo'] = 'REGISTADO';
            } else if (el.registado === '1' && el.aviso_recepcao === '1') {
              aux['tipo'] = 'AVISO_RECECAO';
            }

            regCtt.push(aux);
          });
          
          this.saveRegistoComunicacao('ASSEMBLEIAS', this.details.id_assembleia, cod, description, regCtt, this.userSession.getUserId(), new Date(), null).then(res => {
            this.toastr.success('Foram lançadas ' + ((pagar) ? ' e dadas como pagas ' : '') + despesas.length + ' despesas CTT.', 'Despesas CTT', { timeOut: 4000 });
            resolve(true);
          }).catch(err => {
            resolve(false);
          })

        } else {
          resolve(false);
        }
      },
        err => {
          resolve(false);
        });
    });
  }

  @ViewChild('motivoSemDespesaAlertRef', { static: false }) motivoSemDespesaAlertRef;
  motivoSemDespesaModalRef = null;
  motivoSemDespesaAlertConfig: any = null;

  submittingMotivoSemDespesa = {
    show: false,
    timeout: null
  };
  motivoSemDespesa: string = null;
  askForMotivoSemDespesa(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.motivoSemDespesaModalRef = this.modalService
      .open(this.motivoSemDespesaAlertConfig)
      .onApprove((motivo: string) => {
        resolve(motivo);
      })
      .onDeny(() => { 
        reject(null);
      });
    })
  }

  approveMotivoSemDespesa() {
    this.utils.setFormFeedback(this.submittingMotivoSemDespesa);
    if (!this.motivoSemDespesa || this.motivoSemDespesa.trim() === '') return;
    this.motivoSemDespesaModalRef.approve(this.motivoSemDespesa.trim());
  }

  goToPag(target, item) {
    switch (target) {
      case 'DESPESA':
        this.router.navigate(['lancamentos/despesa', item.id_despesa]);

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `DESPESA / ${item.n_despesa}` });
        break;
    }
  }

  despesasAlertTarget = null;
  checkDespesasCtt(target) {
    if (!this.genDespesaCTT) return;

    this.despesasAlertTarget = target;
    let openAlert = false;
    switch (target) {
      case 'CONVOCATORIA':
        openAlert = !!(this.regEmails.find(el => (el.description.indexOf('Despesa(s)') !== -1 && el.description.indexOf('Convocatória') !== -1)));
        break;
      case 'COPIA_ATA':
        openAlert = !!(this.regEmails.find(el => (el.description.indexOf('Despesa(s)') !== -1 && el.description.indexOf('Cópia da Ata') !== -1)));
        break;
    }

    if (openAlert) {
      this.alertaCttModalRef = this.modalService
        .open(this.alertaCttAlertConfig)
        .onApprove(() => { })
        .onDeny(res => { this.genDespesaCTT = false; });
    }
  }

  // PAYMENT VARIABLE AND METHODS
  @ViewChild('pagamentoAlertRef', { static: false }) pagamentoAlertRef;
  pagamentoModalRef = null;
  pagamentoAlertConfig: any = null;

  form_pagam = null;
  contaSeleted = null;
  dt_pag = null;
  conta_saldo = null;
  cod_conta_bancaria = null;

  contaOpts = [];

  submittingPaymentForm = false;
  isCaixaVertisLoggedIn = this.userSession.isCaixaVertisLoggedIn();
  datePagEnabled = true;
  minPagamentoDate = null;
  tipoPagamento = null;

  setPayment() {
    this.submittingPaymentForm = true;

    if (!this.form_pagam || !this.dt_pag || !this.contaSeleted) {
      this.toastr.error('Formulário de pagamento incompleto. Por favor, verifique todos os campos assinalados.', 'Alerta', { timeOut: 4000 });
      return;
    }

    this.pagamentoModalRef.approve();
  }

  contaOptChanged() {
    let conta = this.contaOptsOrig.find(el => (el.value === this.contaSeleted));
    this.conta_saldo = (conta) ? conta.saldo : null;
  }

  paymentOptChanged() {
    if (this.form_pagam === null) return;

    if (this.form_pagam === '1' || this.form_pagam === '6') {
      if (this.form_pagam === '1') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      }

      if (this.form_pagam === '6') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name.indexOf('CX ADM') !== -1 || el.name.indexOf('CAIXA ADM') !== -1));
      }

      this.datePagEnabled = false;

      if (this.contaOpts.length === 1) {
        this.cod_conta_bancaria = this.contaOpts[0].value;
        this.conta_saldo = this.contaOpts[0].saldo;
      } else {
        this.cod_conta_bancaria = null;
        this.conta_saldo = null;
      }

      this.tipoPagamento = ' -N';
    } else {
      this.contaOpts = this.contaOptsOrig.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1));
      this.datePagEnabled = true;

      if (this.form_pagam === '2') {
        let aux = this.contaOpts.find(el => (el.details.conta_principal === '1'));
        this.cod_conta_bancaria = (aux) ? aux.value : null;
      } else {
        this.cod_conta_bancaria = null;
      }
      this.conta_saldo = null;
      this.tipoPagamento = ' -T';
    }
  }

  contaOptsOrig = [];
  getPaymentData(codCondominio) {
    // CONTAS
    this.api.getCondContasDetails(codCondominio).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.contaOptsOrig = res.data.map(el => {
          return { name: el.banco, value: el.cod, conta_principal: el.conta_principaal, saldo: Number(el.saldo), details: el };
        });
        this.contaOpts = JSON.parse(JSON.stringify(this.contaOptsOrig));
      }
    }, err => { });
  }

  reuniaoRealizada = false;


  // ENTRADA MAPA DE REUNIAO - VARIABLES AND METHODS
  @ViewChild('editMapaReuniaoAlertRef', { static: false }) editMapaReuniaoAlertRef;
  editMapaReuniaoModalRef = null;
  editMapaReuniaoConfig: any = null;
  
  mapaReuniaoFilters: { tipo:RegAtividadeFilters, tipoOpts: {name: string, value: RegAtividadeFilters}[]} = {
    tipo: 'COMMENTS',
    tipoOpts: [
      { name: 'Histórico', value: 'HISTORY'},
      { name: 'Comentários', value: 'COMMENTS'},
      { name: 'Processos Assembleia', value: 'PROCESSES'},
    ]
  }

  mapaReuniaoFormOrig = {
    id: null,
    id_assembleia: null,
    data_reuniao: null,
    utilizador_id: null,
    data_convocatoria: null,
    data_limite: null,
    data_ata: null,
    entrega_assinatura: null,
    rececao: null,
    copia_ata: null,
    arquivo_digital: null,
    obs: null,
    comentario: null,
    processo_concluido: false as any,
  }
  mapaReuniaoForm = this.mapaReuniaoFormOrig;
  clearFollowingDates(step: 'ENTREGUE_PARA_ASSINATURA') {
    if (step === 'ENTREGUE_PARA_ASSINATURA' && this.mapaReuniaoForm.entrega_assinatura == null) {
      this.mapaReuniaoForm.rececao = null;
      this.mapaReuniaoForm.copia_ata = null;
    }
  }
  dataReuniaoChanged() {
    let today = new Date(this.mapaReuniaoForm.data_reuniao);
    today.setDate(today.getDate() + 30);
    this.mapaReuniaoForm.data_limite = new Date(today);
  }

  getMapaReuniao() {
    return new Promise(resolve => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      this.api.getMapaReuniaoDetails(this.details['id_assembleia']).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {

          this.mapaReuniaoFormOrig = {
            id: res.data.id,
            id_assembleia: res.data.id_assembleia,
            data_reuniao: (res.data.data_reuniao) ? new Date(res.data.data_reuniao) : null,
            utilizador_id: res.data.utilizador_id,
            data_convocatoria: (res.data.data_convocatoria) ? new Date(res.data.data_convocatoria) : null,
            data_limite: (res.data.data_limite) ? new Date(res.data.data_limite) : null,
            data_ata: (res.data.data_ata) ? new Date(res.data.data_ata) : null,
            entrega_assinatura: (res.data.entrega_assinatura) ? new Date(res.data.entrega_assinatura) : null,
            rececao: (res.data.rececao) ? new Date(res.data.rececao) : null,
            copia_ata: (res.data.copia_ata) ? new Date(res.data.copia_ata) : null,
            arquivo_digital: (res.data.arquivo_digital) ? new Date(res.data.arquivo_digital) : null,
            obs: res.data.obs,
            comentario: null,
            processo_concluido: (res.data.processo_concluido == '1') ? true : false,
          }

          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(false);
      });
    });
  }

  async reuniaoSelected() {
    this.mapaReuniaoFilters.tipo = 'COMMENTS';
    this.sortRegAtividadeMapaReuniao();
    this.loadingModal = false;
    let res = await this.getMapaReuniao();
    if (res) {
      this.mapaReuniaoForm = {...JSON.parse(JSON.stringify(this.mapaReuniaoFormOrig)), comentario: this.mapaReuniaoForm.comentario };
      this.mapaReuniaoForm['data_reuniao'] = (this.mapaReuniaoForm['data_reuniao']) ? new Date(this.mapaReuniaoForm['data_reuniao']) : null,
        this.mapaReuniaoForm['data_convocatoria'] = (this.mapaReuniaoForm['data_convocatoria']) ? new Date(this.mapaReuniaoForm['data_convocatoria']) : null,
        this.mapaReuniaoForm['data_limite'] = (this.mapaReuniaoForm['data_limite']) ? new Date(this.mapaReuniaoForm['data_limite']) : null,
        this.mapaReuniaoForm['data_ata'] = (this.mapaReuniaoForm['data_ata']) ? new Date(this.mapaReuniaoForm['data_ata']) : null,
        this.mapaReuniaoForm['entrega_assinatura'] = (this.mapaReuniaoForm['entrega_assinatura']) ? new Date(this.mapaReuniaoForm['entrega_assinatura']) : null,
        this.mapaReuniaoForm['rececao'] = (this.mapaReuniaoForm['rececao']) ? new Date(this.mapaReuniaoForm['rececao']) : null,
        this.mapaReuniaoForm['copia_ata'] = (this.mapaReuniaoForm['copia_ata']) ? new Date(this.mapaReuniaoForm['copia_ata']) : null,
        this.mapaReuniaoForm['arquivo_digital'] = (this.mapaReuniaoForm['arquivo_digital']) ? new Date(this.mapaReuniaoForm['arquivo_digital']) : null,
        this.mapaReuniaoForm['processo_concluido'] = (this.mapaReuniaoForm['processo_concluido'] == '1') ? true : false,

        this.editMapaReuniaoModalRef = this.modalService
          .open(this.editMapaReuniaoConfig)
          .onApprove(() => { this.newCommentHtml = null; })
          .onDeny(() => { this.newCommentHtml = null; });
    }
  }

  assembleiaStateOrder: AssembleiaState[] = ['AGENDAMENTO', 'CONVOCATORIA', 'ELABORACAO_ATA', 'ENTREGUE_ASSINATURA', 'RECECAO_ATA', 'COPIA_ATA'];
  getAssembleiaState(state: {data_convocatoria: string, data_ata: string, entrega_assinatura: string, rececao: string, copia_ata: string}): AssembleiaState {
    if (state.copia_ata != null) return 'COPIA_ATA';
    if (state.rececao != null) return 'RECECAO_ATA';
    if (state.entrega_assinatura != null) return 'ENTREGUE_ASSINATURA';
    if (state.data_ata != null) return 'ELABORACAO_ATA';
    if (state.data_convocatoria != null || this.autorConvocatoria == 1) return 'CONVOCATORIA';
    return 'AGENDAMENTO';
  }

  mapaReuniaoInitDates = null;
  async saveMapaReuniao() {
    let doAutomatizations = false;
    if (this.automatizationsStep === 'DATA_ENTREGA_ASSINATURA') {
      if (this.mapaReuniaoForm.entrega_assinatura
        && (!this.mapaReuniaoFormOrig.entrega_assinatura
          ||
          this.utils.compareDayDates(this.mapaReuniaoFormOrig.entrega_assinatura, this.mapaReuniaoForm.entrega_assinatura) !== 0
        )) {
        doAutomatizations = true;
      }
    }

    if (doAutomatizations) {
      try {
        await this.presentDoAssembleiaAutomatizationsModal();
      } catch (err) {
        return;
      }
    }

    if (this.mapaReuniaoForm.comentario) {
      this.mapaReuniaoForm.comentario = this.mapaReuniaoForm.comentario.trim();
      if (this.mapaReuniaoForm.comentario !== '') {
        let comentario = await this.assembleiasService.saveAssembleiaComentario(this.details.id_assembleia, this.mapaReuniaoForm.comentario);
        this.details.comentarios.push(comentario);
        this.sortRegAtividade();
        
        this.mapaReuniaoForm.comentario = null;
      }
    }

    this.loadingModal = true;
    this.api.saveMapaReuniao(this.mapaReuniaoForm.id, this.mapaReuniaoForm.data_reuniao, this.mapaReuniaoForm.utilizador_id, this.mapaReuniaoForm.data_convocatoria, this.mapaReuniaoForm.data_limite, this.mapaReuniaoForm.data_ata, this.mapaReuniaoForm.entrega_assinatura, this.mapaReuniaoForm.rececao, this.mapaReuniaoForm.copia_ata, this.mapaReuniaoForm.arquivo_digital, this.mapaReuniaoForm.obs, this.mapaReuniaoForm.processo_concluido, this.mapaReuniaoForm.id_assembleia).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.mapaReuniaoFormOrig = JSON.parse(JSON.stringify(this.mapaReuniaoForm));
        this.updateAssembleiaFuncionario(this.mapaReuniaoFormOrig.utilizador_id);

        this.convEnviada = !!this.mapaReuniaoForm.data_convocatoria || this.autorConvocatoria != 1;
        this.isEntregueParaAssinatura = !!this.mapaReuniaoForm.entrega_assinatura;
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.editMapaReuniaoModalRef.approve();
      this.loadingModal = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModal = false;
    });
  }

  regPresencasAnexoType = null;
  downloadFile(item) {
    if (item.obj && item.obj.hasOwnProperty('fileId') && item.obj.fileId) {
      let fileId = item.obj.fileId;
      this.getAndDownloadFile(fileId);
    } else if (item.description && item.description.toLowerCase().indexOf('cópia da ata assinada submetida') !== -1) {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      this.api.getAtaAssinada(this.assembleiaId, true).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.data.ata_ficheiro_copy) {
            this.utils.downloadFile('data:application/pdf;base64,' + res.data.ata_ficheiro_copy, 'Ata Assinada');

            // saveAs('data:application/pdf;base64,' + res.data.ata_ficheiro_copy, 'Ata Assinada.pdf',{
            //   proxyURL: this.appConfig.fileProxyUrl,
            //   forceProxy: true,
            //   proxyTarget: '_blank',
            // })
          }
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });
    } else if (this.loadingFile) {
      this.toastr.info('O ficheiro está a ser carregado. Por favor, tente novamente mais tarde.', 'Info', { timeOut: 4000 });
    }
  }

  goToCondomino(item) {
    if (!(item && item.hasOwnProperty('cod_condomino') && item.cod_condomino)) return;

    this.router.navigate(['entidades/proprietario', item.cod_condomino]);

    // Emit signal to breadcrumb component
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROPRIETÁRIOS / ${item.condomino}` });
  }

  savePresencasList(presencasList: Array<AssembleiasPresencas>) {
    return new Promise(resolve => {
      this.api.updateAssembleiaPresencas(this.details.id_assembleia, presencasList, this.details.ata_concluida, this.reuniaoRealizada ? 1 : 0).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {

          // ADD REGISTO DE ACTIVIDADE
          let signatureList = [];
          presencasList.filter(item => item.signature).forEach(item => {
            signatureList.push({
              fraccao: item.nome_fracao,
              name: item.nome_condomino,
            });
          });

          if (signatureList.length) {
            let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
            this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, 'Actualização assinaturas ata', JSON.stringify(signatureList), this.userSession.getUserId(), new Date()).subscribe(res => {
              if (res.hasOwnProperty('success') && res.success) {

                this.regEmails = [{
                  description: 'Actualização assinaturas ata',
                  emailList: signatureList,
                  name: this.userSession.getUserFullName(),
                  date: new Date(),
                  msg: null,
                  obj: null,
                } as RegEmails].concat(this.regEmails);
                this.sortRegAtividade();
              }
            }, err => { });
          }

          this.presencasList = this.convertPresencasToList(presencasList);
          this.compState.init.presencas.presencasList = this.convertListToPresencas(),
            this.computePresencasTotal();
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
  }

  correioRegistadoList = [];

  // START - UPLOAD 'ASSEMBLEIA' REPORT -----------------------------------------------------------
  @ViewChild('removerRelAssembleiaAlertRef', { static: false }) removerRelAssembleiaAlertRef;
  removerRelAssembleiaModalRef = null;
  removerRelAssembleiaAlertConfig: any = null;
  regRemoverRelAssembleiaMsg = null;

  titleDeleteRelatorioAssembleia = null;
  presentDeleteRelAssembleia(isDelete = false): Promise<boolean> {
    this.titleDeleteRelatorioAssembleia = (isDelete) ? 'Deseja remover o presente relatório de assembleia?' : 'Deseja substituir o presente relatório de assembleia?';

    this.submittingMotivo = false;
    this.regRemoverRelAssembleiaMsg = null;
    return new Promise(resolve => {
      this.removerRelAssembleiaModalRef = this.modalService
        .open(this.removerRelAssembleiaAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => {
          resolve(false);
        });
    });
  }


  deleteRelatorioAssembleia = (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      let temp = await this.presentDeleteRelAssembleia(true);
      if (!temp) {
        resolve(false);
        return;
      }

      this.loadingModal = true;
      this.api.uploadRelatorioAssembleiaFile(this.details.id_assembleia, null, null).subscribe(res => { 
        if (res.hasOwnProperty('success') && res.success) {
          // ADD REGISTO DE ACTIVIDADE
          let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
          this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, 'Relatório de Assembleia Removido', JSON.stringify([]), this.userSession.getUserId(), new Date(), this.regRemoverRelAssembleiaMsg, null).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {

              this.regEmails = [{
                description: 'Relatório de Assembleia Removido',
                emailList: [],
                name: this.userSession.getUserFullName(),
                date: new Date(),
                msg: this.regRemoverRelAssembleiaMsg,
                obj: null,
              } as RegEmails].concat(this.regEmails);
              this.sortRegAtividade();
              resolve(true);
            } else {
              resolve(false);
            }
            this.regRemoverRelAssembleiaMsg = null;
          }, err => { resolve(false); this.regRemoverRelAssembleiaMsg = null; });

          this.details.relatorio_assembleia = null;
          this.details.id_relatorio_assembleia = null;
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
        this.loadingModal = false;
      },
        err => {
          this.loadingModal = false;
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          resolve(false);
        });
    });
  }

  uploadRelatorioAssembleia = (file, fileExt: fileExt, filename): Promise<boolean> => {
    return new Promise(async (resolve) => {
      if (this.details.relatorio_assembleia || this.details.id_relatorio_assembleia) {
        let temp = await this.presentDeleteRelAssembleia();
        if (!temp) {
          resolve(false);
          return;
        }
      }

      this.api.uploadRelatorioAssembleiaFile(this.details.id_assembleia, file, filename).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          // ADD REGISTO DE ACTIVIDADE
          let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;

          this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, this.hasRelatorioFicheiro() ? 'Relatório de Assembleia Atualizado' : 'Relatório de Assembleia Submetido', JSON.stringify([]), this.userSession.getUserId(), new Date(), this.regRemoverRelAssembleiaMsg, null).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {

              this.regEmails = [{
                description: this.hasRelatorioFicheiro() ? 'Relatório de Assembleia Atualizado' : 'Relatório de Assembleia Submetido',
                emailList: [],
                name: this.userSession.getUserFullName(),
                date: new Date(),
                msg: this.regRemoverRelAssembleiaMsg,
                obj: null,
              } as RegEmails].concat(this.regEmails);
              this.sortRegAtividade();
            }
            resolve(true);
            this.regRemoverRelAssembleiaMsg = null;
          }, err => { resolve(true); this.regRemoverRelAssembleiaMsg = null; });

          this.details.relatorio_assembleia = null;
          this.details.id_relatorio_assembleia = res.data.fileId;
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      },
        err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          resolve(false);
        });
    });
  }
  // END - UPLOAD 'ASSEMBLEIA' REPORT -----------------------------------------------------------

  // START - COMMENTS FEATURE VARIABLES AND METHODS -----------------------------------------------
  commentFraccaoSel = null;

  addComment(item) {
    this.reuniaoSelected();
  }

  @ViewChild('newCommentEditor', { static: false }) newCommentEditor;
  newCommentHtml: string = null;
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      // [{ 'header': 1 }, { 'header': 2 }], // custom button values
      // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
      // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      // [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      // [{ 'font': [] }],
      // [{ 'align': [] }],
      // ['image'],
    ],
    imageResize: true
  };

  isEditorOpened = false;
  editorSelectionChanged(ev) {
    this.isEditorOpened = ev.range;
  }

  cancelNewComment() {
    this.isEditorOpened = false;
    this.newCommentHtml = null;
  }

  respondeComment(comment) {

  }

  quoteComment(comment) {
    this.newCommentHtml = `<b>${comment.username}</b>, `;
  }

  editComment(comment) {

  }

  deleteComment(comment) {
    comment.comment = `<del>${comment.comment}</del>`;
    comment.deleted = true;
  }
  // END - COMMENTS FEATURE VARIABLES AND METHODS -------------------------------------------------

  onlyShowPresencas = false;
  hasPresencas(): boolean {
    return !this.onlyShowPresencas || this.presencasList.findIndex(el => el.present) > -1;
  }

  commentsList = [
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
    { comment: 'Comentário de teste...', username: 'Alexandre Emidio', date: new Date(), deleted: false },
  ];



  // START - REGISTO E ENVIO DE EMAILS DE TITULARES BANCARIOS E ADMINISTRADORES -------------------
  @ViewChild('comAdministradoresPdfRef', { static: false }) comAdministradoresPdf;
  @ViewChild('comTitularesBancariosPdfRef', { static: false }) comTitularesBancariosPdf;
  cartasAdministradores = [];
  cartasTitularesBancarios = [];

  comunEntityList = [];
  comunEmailTemplate = null;
  @ViewChild('comunicaoModalRef', { static: false }) comunicaoModalRef: EmailCartaComunicacaoModalComponent;

  checkQuorum(quorum) {
    let nConvocatoria = (this.ataOrigObj && this.ataOrigObj.introAta && this.ataOrigObj.introAta.indexOf('segunda convocatória') != -1) ? 2 : 1;
    if (nConvocatoria === 1) {
      return (quorum > 500);
    } else {
      return (quorum >= 250);
    }
  }

  presentComunicacoesAutomaticas() {
    return new Promise(async (resolve) => {

      let alreadySent = await this.assembleiasService.entitiesCommunicationsAlreadySent(this.assembleiaId);
      if (alreadySent === null) {
        resolve(false);
        return;
      }
      let entidades: Array<{ cod: string, cod_conta: number, tipo: EntityType, idEmail: number, tipoComunicacao: TipoComunicacao }> = [];

      let administracao = this.ordensTrabalhos.find(el => el.label === 'ELEICAO_ADMINISTRACAO');
      if (administracao && !alreadySent.administradores) {
        entidades = entidades.concat(await this.getEntidades(administracao));
      }

      let bancos = this.ordensTrabalhos.find(el => el.label === 'BANCOS');
      if (bancos) {
        entidades = entidades.concat(await this.getEntidades(bancos, alreadySent.titularesBancarios));
      }


      entidades = entidades.filter(el => el.idEmail != null);

      if (!entidades.length) {
        resolve(true);
        return;
      }

      let req = [];
      let diffEmails = [];
      entidades.forEach(ent => {
        req.push(this.api.getEntityContactDetails(ent.cod, ent.tipo));
        if (diffEmails.findIndex(em => em === ent.idEmail) === -1) diffEmails.push(ent.idEmail);
      })

      diffEmails.forEach(ent => {
        req.push(this.api.getEmailConfig(ent));
      })

      forkJoin(req).subscribe(async res => {
        if (!res.find(el => !el.hasOwnProperty('success') || (el.hasOwnProperty('success') && !el.success))) {
          let entidadesDetails: Array<EntityEmailCartaInput> = [];
          let emails: Array<EmailBD> = [];

          for (let i = entidades.length; i < res.length; i++) {
            emails.push(res[i].data);
          }

          for (let i = 0; i < entidades.length; i++) {
            const element: GetEntitiesContactDetails['data'] = res[i].data;
            let email = emails.find(email => email.id == entidades[i].idEmail.toString());
            let ent: EntityEmailCartaInput = {
              cod: element.cod,
              nome: element.nome,
              cod_condominio: this.details.cod_condominio.toString(),
              nome_condominio: this.details.nome_condominio,
              cod_fraccao: null,
              nome_fraccao: null,
              subjectMsg: email ? email.assunto : null,
              bodyMsg: email ? email.corpo : null,
              email_1: element.email_1,
              email_2: element.email_2,
              tipo: entidades[i].tipo,
              communicationType: 'bcc',
              id_texto_predefinido: null,
              data_divida: null,
              tipoComunicacao: entidades[i].tipoComunicacao,
              nomeComunicacao: email.legenda,
              classes: '',
            }
            entidadesDetails.push(ent);
          }

          let nSpans = 0;
          entidadesDetails.forEach((el, index) => {
            if (index === 0 || entidadesDetails[index - 1].tipoComunicacao !== entidadesDetails[index].tipoComunicacao) {
              el.classes += 'showSpannables ';
              nSpans++;
            }
            el.classes += (nSpans % 2 === 0) ? 'even ' : 'odd ';
            el.classes = el.classes.trim();
          })

          // PRESENT ALERT MODAL TO CONFIRM COMUNICATION INTENT
          this.cdRef.detectChanges();
          let aux = await this.comunicaoModalRef.open(null, entidadesDetails, this.assembleiaId);

          if (!aux || !aux.success || !aux.sentToIndexes.length) {
            resolve(false);
            return;
          }

          let entSaved: Array<{ cod_entidade: string, tipo_entidade: EntityType, cod_conta: number, nome_entidade: string, emails: string, carta: boolean, email: boolean, tipoComunicacao: TipoComunicacao }> = entidades.map((el, i) => {
            if (i >= aux.sentToIndexes.length) return;
            return {
              cod_entidade: el.cod,
              tipo_entidade: el.tipo,
              cod_conta: el.cod_conta,
              carta: aux.sentToIndexes[i].carta,
              email: aux.sentToIndexes[i].email,
              nome_entidade: entidadesDetails[i].nome,
              emails: aux.sentToIndexes[i].emails,
              tipoComunicacao: el.tipoComunicacao,
            }
          })

          this.saveEntityAssociation(entSaved);

          // REFRESH ACTIVITY LOG
          this.getEmailLogs();
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
        // TODO: HANDLE ERROR
      });
    });
  }

  saveEntityAssociation(entities: Array<{ cod_entidade: string, tipo_entidade: EntityType, cod_conta: number, nome_entidade: string, emails: string, carta: boolean, email: boolean, tipoComunicacao: TipoComunicacao }>) {
    return new Promise(resolve => {
      // ADD REGISTO DE ACTIVIDADE
      let registoAdministradores = [];
      let registoTitulares = [];
      let saveComunicacao: Array<{ cod_conta: number, tipoComunicacao: TipoComunicacao }> = [];
      entities.filter(el => el.carta || el.email).forEach(item => {
        if (saveComunicacao.findIndex(el => el.cod_conta == item.cod_conta && el.tipoComunicacao == item.tipoComunicacao) === -1) {
          saveComunicacao.push({
            cod_conta: item.cod_conta,
            tipoComunicacao: item.tipoComunicacao,
          });
        }
        let reg = {
          checkedCarta: item.carta,
          checkedEmail: item.email,
          cod: item.cod_entidade,
          tipo_entidade: item.tipo_entidade,
          emails: item.emails,
          nomeEntidade: item.nome_entidade,
        };
        switch (item.tipoComunicacao) {
          case 'ADMINISTRADOR':
            registoAdministradores.push(reg);
            break;
          default:
            registoTitulares.push(reg);
            break;
        }
      });

      let reqs = [];


      let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
      if (registoAdministradores.length) {
        let descricao = 'Comunicação - Administradores';
        reqs.push(this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.assembleiaId, cod, descricao, JSON.stringify(registoAdministradores), this.userSession.getUserId(), new Date()));
      }

      if (registoTitulares.length) {
        let descricao = 'Comunicação - Titulares Bancários';
        reqs.push(this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.assembleiaId, cod, descricao, JSON.stringify(registoTitulares), this.userSession.getUserId(), new Date()));
      }

      if (saveComunicacao.length) {
        reqs.push(this.api.saveCondominioEntidadesComunicacao(this.assembleiaId, saveComunicacao));
      }

      if (reqs.length) {
        forkJoin(reqs).subscribe(res => {
          resolve(true);
        }, err => { resolve(false); });
      } else {
        resolve(false);
      }
    });
  }


  getEntidades(ordemTrabalho: OrdemTrabalhoList, titularesCommunicated: Array<CondominioTitularBancarioDB> = []): Promise<Array<{ cod: string, cod_conta: number, tipo: EntityType, idEmail: number, tipoComunicacao: TipoComunicacao }>> {
    return new Promise(async (resolve, reject) => {
      let entidades: Array<{ cod: string, cod_conta: number, tipo: EntityType, idEmail: number, tipoComunicacao: TipoComunicacao }> = [];
      let banco = this.ordemTrabOpts.find(el => el.value.label === 'BANCOS');
      let bancoResps = banco ? banco.value.resps : [];
      for (let i = 0; i < ordemTrabalho.resps.length; i++) {
        const resp = ordemTrabalho.resps[i];
        resp.parametros.sort((a, b) => {
          let a_count = 0;
          let b_count = 0;
          switch (a.chave) {
            case 'BANCO_ABERTURA':
              a_count = 1;
              break;
            case 'BANCO_ALTERACAO':
              a_count = 2;
              break;
            case 'BANCO_ALTERACAO_FECHO':
              a_count = 3;
              break;
            case 'BANCO_FECHO':
              a_count = 4;
              break;
            default:
              break;
          }
          switch (b.chave) {
            case 'BANCO_ABERTURA':
              b_count = 1;
              break;
            case 'BANCO_ALTERACAO':
              b_count = 2;
              break;
            case 'BANCO_ALTERACAO_FECHO':
              b_count = 3;
              break;
            case 'BANCO_FECHO':
              b_count = 4;
              break;
            default:
              break;
          }
          return a_count - b_count;
        });
        for (let j = 0; j < resp.parametros.length; j++) {
          const param = resp.parametros[j];
          let valor = null;
          let respDB = null;
          let cod_conta = null;
          switch (param.chave) {
            case 'BANCO_ABERTURA':
            case 'BANCO_ALTERACAO':
            case 'BANCO_ALTERACAO_FECHO':
              respDB = bancoResps ? bancoResps.find(respDB => respDB.label === param.chave) : null;
              valor = JSON.parse(param.valor);
              cod_conta = valor.hasOwnProperty('conta') ? valor.conta : null;
              if (titularesCommunicated.findIndex(tit => tit.cod_conta == valor.conta && tit.operacao == param.chave) !== -1) break;
              if (valor && valor.hasOwnProperty('entidades')) {
                valor.entidades.forEach(ent => {
                  entidades.push({ cod: ent.cod.toString(), cod_conta: cod_conta, tipo: ent.type, idEmail: respDB ? respDB.emailId : null, tipoComunicacao: param.chave as BancoOperation });
                });
              }
              break;
            case 'BANCO_FECHO':
              respDB = bancoResps ? bancoResps.find(respDB => respDB.label === param.chave) : null;
              valor = JSON.parse(param.valor);
              cod_conta = valor.hasOwnProperty('conta') ? valor.conta : null;
              if (titularesCommunicated.findIndex(tit => tit.cod_conta == valor.conta && tit.operacao == param.chave) !== -1) break;
              let titulares = valor ? await this.condominios.getTitularesBancariosByConta(valor.conta) : [];
              for (let k = 0; k < titulares.length; k++) {
                const tit = titulares[k];
                entidades.push({ cod: tit.cod_entidade.toString(), cod_conta: cod_conta, tipo: tit.tipo_entidade, idEmail: respDB ? respDB.emailId : null, tipoComunicacao: param.chave as BancoOperation })
              }
              break;
            case 'CONDOMINO':
            case 'PROCURADOR':
            case 'FORNECEDOR':
              entidades.push({ cod: param.valor, cod_conta: null, tipo: param.chave, idEmail: resp.respSel ? resp.respSel.emailId : null, tipoComunicacao: 'ADMINISTRADOR' });
              break;

            default:
              break;
          }
        }

      }

      resolve(entidades);
    });
  }

  getEmailTemplates(type): Promise<EmailBD> {
    return new Promise((resolve, reject) => {
      this.api.getEmailTemplateByType(type).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          reject();
        }
      }, err => {
        reject();
      });
    });
  }

  getEmailTemplate(id, idOrdemTrabalho) {
    return new Promise((resolve, reject) => {
      this.api.getEmailTemplate(id, idOrdemTrabalho).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          reject();
        }
      }, err => {
        reject();
      });
    });
  }
  // END - REGISTO E ENVIO DE EMAILS DE TITULARES BANCARIOS E ADMINISTRADORES ---------------------
  // Converts resps: [{respSel1, resp1}, {respSel2, resp2}] in [{respSel1, resp1}, {respSel2, resp2}] 
  // Instead of having a list of ordemTrabalhoTopics we get a list of all answers.
  getOrdensTrabalhosItens(): Promise<Array<OrdemTrabalhoFlat>> {
    return new Promise(async (resolve) => {
      let ordemTrabalhoFlat: Array<OrdemTrabalhoFlat> = [];

      for (let index_o = 0; index_o < this.ordensTrabalhos.length; index_o++) {
        const el = this.ordensTrabalhos[index_o];
        if (el.label === 'ASSUNTOS_DIVERSOS' || el.id == 5) continue;
        for (let index_r = 0; index_r < el.resps.length; index_r++) {
          const resp = el.resps[index_r];

          let exercicio = null;
          if (el.selAnos && el.selAnos.length && index_r < el.selAnos.length) {
            exercicio = parseInt(el.selAnos[index_r].ano);
          }

          let sufixo: string = null;
          if (el.label === 'QUOTA_EXTRA') {
            let procParam = resp.parametros.find(p => p.chave === 'PROCESSAMENTO');
            if (procParam && procParam.valor) {
              try {
                let proc = await this.processamentos.getProcessamento(procParam.valor);
                sufixo = this.processamentos.cleanDescricaoFromAtaInfo(proc.descricao);
              } catch (err) {
              }
            }
          }

          let clone: OrdemTrabalhoFlat = {
            respSel: JSON.parse(JSON.stringify(resp.respSel)),
            resp: resp.resp,
            ordemTrabalhoType: 'ORDEM_TRABALHO',
            exercicio: exercicio,
            index_o: index_o,
            index_r: index_r,
            need_anexo_indicator: resp.need_anexo_indicator,
            uploaded_anexo_indicator: resp.uploaded_anexo_indicator,
            anexo_id: resp.anexo_id,
            tipo_anexo_id: resp.respSel ? resp.respSel.tipo_anexo_id : null,
            parametros: resp.parametros,
            sufixo: sufixo,

            id: el.id,
            selAnos: el.selAnos,
            descricao: el.descricao,
            label: el.label,
            ordinarias_predefinido: el.ordinarias_predefinido,
            extras_predefinido: el.extras_predefinido,
            ordinarias_diversos_predefinido: el.ordinarias_diversos_predefinido,
            extras_diversos_predefinido: el.extras_diversos_predefinido,
            is_ordem_trabalho: el.is_ordem_trabalho,
            is_assunto_diverso: el.is_assunto_diverso,
            is_multipla_entrada: el.is_multipla_entrada,
            obj: el.obj,
            ordem: el.ordem,
            texto_inicial: el.texto_inicial,
            texto_final: el.texto_final,
            has_anexo: el.has_anexo,
            active: el.active,
            can_delete: el.can_delete
          }
          ordemTrabalhoFlat.push(clone);
        }
      }

      for (let index_o = 0; index_o < this.assuntosDiversosRespObj.length; index_o++) {
        const el = this.assuntosDiversosRespObj[index_o];

        let clone: OrdemTrabalhoFlat = {
          respSel: JSON.parse(JSON.stringify(el.respSel)),
          resp: el.resp,
          ordemTrabalhoType: 'ASSUNTO_DIVERSO',
          exercicio: null,
          index_o: index_o,
          need_anexo_indicator: el.need_anexo_indicator,
          uploaded_anexo_indicator: el.uploaded_anexo_indicator,
          anexo_id: el.anexo_id,
          tipo_anexo_id: el.respSel ? el.respSel.tipo_anexo_id : null,
          parametros: el.parametros,
          sufixo: null,

          id: el.id,
          descricao: el.descricao,
          selAnos: [],
          label: el.label,
          ordinarias_predefinido: el.ordinarias_predefinido,
          extras_predefinido: el.extras_predefinido,
          ordinarias_diversos_predefinido: el.ordinarias_diversos_predefinido,
          extras_diversos_predefinido: el.extras_diversos_predefinido,
          is_ordem_trabalho: el.is_ordem_trabalho,
          is_assunto_diverso: el.is_assunto_diverso,
          is_multipla_entrada: el.is_multipla_entrada,
          obj: el.obj,
          ordem: el.ordem,
          texto_inicial: el.texto_inicial,
          texto_final: el.texto_final,
          has_anexo: el.has_anexo,
          active: el.active,
          can_delete: el.can_delete
        }
        ordemTrabalhoFlat.push(clone);
      }

      resolve(ordemTrabalhoFlat);
    })
  }

  savePageState(action: 'ORCAMENTO') {
    let ordemTrabalho = this.ordensTrabalhos.find(el => el.label === 'ORCAMENTO');
    if (!ordemTrabalho) return;

    let obj = {
      url: this.location.path(),
      action: 'SELECTING_ORCAMENTO',
      ordemTrabalhoId: ordemTrabalho.id,
      exercicio: this.exercicioSelectingOrcamento,
    }
    // this.navigation.savePageState(obj);
  }

  // START ANEXOS PARA REGISTO DE PRESENÇAS - VARIABLES AND METHODS
  @ViewChild('listagemAnexosModal', { static: false }) listagemAnexosModal: ListagemAnexosModalComponent;
  openAnexos = false;
  async openAnexosModal(index_i: number = null, index_r: number = null) {
    if (!this.details || !this.ordensTrabalhos || !this.ordensTrabalhos.length || !this.tiposAnexoOrig || !this.tiposAnexoOrig.length) {
      this.openAnexos = true;
      this.toastr.info('Por favor aguarde, os anexos estão a ser carregados.', 'Informação', { timeOut: 3000 });
      return;
    }
    if (!this.canExecuteAction('OPEN_ANEXOS')) {
      this.pendingAction = 'OPEN_ANEXOS';
      this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title, { timeOut: 4000 });
      return;
    }
    let anexosOpts: Array<AnexoAssembleiaOpt> = this.anexosOptsOrig.slice();

    anexosOpts.forEach(anexo => {
      if (anexo.type === 'LISTA_PRESENCAS') {
        anexo.base64 = this.details.presencas_ficheiro;
        anexo.fileId = this.details.id_presencas_ficheiro;
        anexo.isUploaded = !!anexo.base64 || !!anexo.fileId;
      }
      if (anexo.type === 'PROCURACOES') {
        anexo.base64 = this.details.representantes_ficheiro;
        anexo.fileId = this.details.id_representantes_ficheiro;
        anexo.isUploaded = !!anexo.base64 || !!anexo.fileId;
      }
      if (anexo.type === 'LISTA_PRESENCAS_PROCURACOES') {
        anexo.base64 = this.details.presencas_representantes_ficheiro
        anexo.fileId = this.details.id_presencas_representantes_ficheiro;
        anexo.isUploaded = !!anexo.base64 || !!anexo.fileId;
      }
    });
    this.assuntosDiversosRespObj.forEach((el, i) => {
      el['ordemTrabalhoType'] = 'ASSUNTO_DIVERSO';
      el['index_o'] = i;
    });
    let ordensTrabalhoTemp: Array<OrdemTrabalhoFlat> = (await this.getOrdensTrabalhosItens()).filter(el => (el.respSel && el.respSel.tipo_anexo_id && !!el.need_anexo_indicator) || !!this.getAutomaticAnexo(el));
    let ordensTrabalho = [];
    ordensTrabalhoTemp.forEach(el => {
      let automaticAnexo = this.getAutomaticAnexo(el);
      let link = el.respSel ? el.respSel.tipo_anexo_id : null;
      let base64 = null;
      let isUploaded = (!!automaticAnexo && automaticAnexo.isUploaded(el)) || el.anexo_id != null;
      let aux: AnexoAssembleiaOpt = {
        label: null,
        filename: this.utils.getFileNameFormatted(el.descricao + (el.index_r > 0 ? ' ' + (el.index_r + 1) + ' ' : '')) + '_' + this.defaultFilename,
        ext: null,
        base64: base64,
        ordem: Number(el.ordem),
        fileId: el.anexo_id,
        type: el.label,
        index_ordemTrabalho: el.index_o,
        index_itemOrdemTrabalho: el.index_r,
        idLinkWithValue: link,
        isUploaded: isUploaded,
        automatic: !!automaticAnexo,
        changedContent: false,
        exercicio: el.exercicio,
        ordemTrabalhoType: el.ordemTrabalhoType
      }
      if (automaticAnexo) {
        automaticAnexo.name.forEach(name => {
          let temp: any = {};
          Object.assign(temp, aux);
          if (el.exercicio) {
            temp.label = name + ' ' + el.exercicio;
          } else if (el.sufixo) {
            temp.label = name + ' (' + el.sufixo + ')';
          } else {
            temp.label = name + ' (' + (el.index_o + 1) + '.' + (el.index_r + 1) + ')';
          }
          ordensTrabalho.push(temp);
        });
      } else {
        let temp: any = {};
        Object.assign(temp, aux);
        if (aux.ordemTrabalhoType === 'ASSUNTO_DIVERSO') {
          temp.label = this.getAnexoUniqueNameDiversos(link);
        } else {
          temp.label = this.getAnexoUniqueName(link, aux.index_ordemTrabalho, aux.index_itemOrdemTrabalho);
        }
        ordensTrabalho.push(temp);
      }

    });
    if (ordensTrabalho) anexosOpts = anexosOpts.concat(ordensTrabalho);


    let label = null;
    if (index_r != null) {
      let anexo = anexosOpts.find(el => el.ordemTrabalhoType === 'ORDEM_TRABALHO' && el.index_ordemTrabalho === index_i && el.index_itemOrdemTrabalho === index_r);
      if (anexo) label = anexo.label;
    } else if (index_i != null) {
      let anexo = anexosOpts.find(el => el.ordemTrabalhoType === 'ASSUNTO_DIVERSO' && el.index_ordemTrabalho === index_i);
      if (anexo) label = anexo.label;
    }

    this.cdRef.detectChanges();
    this.listagemAnexosModal.openModal('Anexos', anexosOpts, true, this.details && !this.details.ata_concluida, label).then(async (anexosRes: AnexoAssembleiaOpt[]) => {
      if (anexosRes) {
        let alreadyAlerted = false;

        await Promise.all(anexosRes.filter(el => !el.automatic).map(async anexo => {
          switch (anexo.type) {
            case 'LISTA_PRESENCAS':
              if (this.details.ata_concluida && this.details.presencas_ficheiro !== anexo.base64 && !alreadyAlerted) {
                alreadyAlerted = true;
                this.toastr.error('Não é possível alterar o anexos respetivos à lista de presenças e procurações. A ata está dada como concluída.', 'Alerta');
              } else {
                this.details.presencas_ficheiro = anexo.base64;
              }
              break;
            case 'PROCURACOES':
              if (this.details.ata_concluida && this.details.representantes_ficheiro !== anexo.base64 && !alreadyAlerted) {
                alreadyAlerted = true;
                this.toastr.error('Não é possível alterar o anexos respetivos à lista de presenças e procurações. A ata está dada como concluída.', 'Alerta');
              } else {
                this.details.representantes_ficheiro = anexo.base64;
              }
              break;
            case 'LISTA_PRESENCAS_PROCURACOES':
              if (this.details.ata_concluida && this.details.presencas_representantes_ficheiro !== anexo.base64 && !alreadyAlerted) {
                alreadyAlerted = true;
                this.toastr.error('Não é possível alterar o anexos respetivos à lista de presenças e procurações. A ata está dada como concluída.', 'Alerta');
              } else {
                this.details.presencas_representantes_ficheiro = anexo.base64;
              }
              break;
            default:
              let ordemTrabalho: OrdemTrabalhoItem | OrdemTrabalhoDiversos = null;
              if (anexo.ordemTrabalhoType === 'ASSUNTO_DIVERSO') {
                ordemTrabalho = this.assuntosDiversosRespObj[anexo.index_ordemTrabalho];
              } else {
                ordemTrabalho = this.ordensTrabalhos[anexo.index_ordemTrabalho].resps[anexo.index_itemOrdemTrabalho];
              }
              if (anexo.changedContent) {
                ordemTrabalho.attachmentInfo = {
                  name: anexo.filename,
                  base64: anexo.base64,
                  ext: anexo.ext,
                }
                ordemTrabalho.uploaded_anexo_indicator = anexo.base64 != null;
              }
              break;
          }
        }));
        await this.setAnexosString();
        this.formSubmitted('ata');
      }
    }).catch(err => { });
  }

  uploadAnexo(tipo_anexo_id, base64, nome_ficheiro): Promise<number> {
    return new Promise((resolve) => {
      this.api.uploadAtaAttachmentFile(this.details.id_assembleia, tipo_anexo_id, base64, nome_ficheiro).subscribe(res => {
        if (res.success && res.attachment_id) {
          resolve(parseInt(res.attachment_id));
        } else {
          resolve(null);
        }
      }, err => {
        resolve(null);
      });
    });
  }

  automaticAnexos = [
    {
      name: [
        'Balancete',
        'Conta Corrente',
      ],
      value: 'APRESENTACAO_CONTAS',
      isUploaded: (ordemTrab: OrdemTrabalhoFlat) => { return true },
    },
    {
      name: ['Orçamento'],
      value: 'ORCAMENTO',
      isUploaded: (ordemTrab: OrdemTrabalhoFlat) => {
        if (ordemTrab == null || ordemTrab.index_r == null || !ordemTrab.selAnos) return false;
        return this.hasOrcamentoSelection(ordemTrab, ordemTrab.index_r);
      }
    },
    {
      name: ['Quota Extraordinária'],
      value: 'QUOTA_EXTRA',
      isUploaded: (ordemTrab: OrdemTrabalhoFlat) => {
        if (ordemTrab == null || ordemTrab.index_r == null) return false;
        return this.hasQuotaExtraSelection(ordemTrab.parametros);
      }
    },
  ];

  getAutomaticAnexo(el: OrdemTrabalhoList | OrdemTrabalhoFlat) {
    return this.automaticAnexos.find(anexo => anexo.value == el.label);
  }
  uploadFile = async (row: AnexoOpt) => {
    if (!row.base64) {
      this.toastr.error('Nenhum ficheiro selecionado.', 'Ups...!', { timeOut: 4000 });
      return;
    }

    if (row.type === 'LISTA_PRESENCAS' || row.type === 'PROCURACOES') {
      this.loadingModal = true;

      this.api.uploadRegPresencasAnexoPdfFile(this.details.id_assembleia, row.type, row.base64).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {

          // SET ARQUIVO DIGITAL
          let regAct = null;
          switch (row.type) {
            case 'LISTA_PRESENCAS':
              this.details.presencas_ficheiro = row.base64;
              regAct = 'Lista de Presenças Submetida';
              break;
            case 'PROCURACOES':
              this.details.representantes_ficheiro = row.base64;
              regAct = 'Procurações Submetidas';
              break;
          }

          // REGISTO DE ACTIVIDADE
          let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
          this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, cod, regAct, JSON.stringify([]), this.userSession.getUserId(), new Date()).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {

              this.regEmails = [{
                description: regAct,
                emailList: [],
                name: this.userSession.getUserFullName(),
                date: new Date(),
                msg: null,
              } as RegEmails].concat(this.regEmails);
              this.sortRegAtividade();
            }
          }, err => { });

        } else {
          this.utils.apiErrorMsg(res);
        }
        this.loadingModal = false;
      },
        err => {
          this.loadingModal = false;
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
    }
  }

  async generateBalancete(exercicio: number): Promise<boolean> {
    return new Promise(async (resolve) => {
      let ordem = this.ordensTrabalhos.find(el => el.label === 'APRESENTACAO_CONTAS');
      let index = ordem.selAnos ? ordem.selAnos.findIndex(el => parseInt(el.ano) === exercicio) : -1;
      if (index >= 0 && index <= this.pdfBalanceteController.length - 1) {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
        this.pdfBalanceteController.forEach((el, i) => {
          if (i === index) {
            el.proxyURL = this.appConfig.fileProxyUrl;
            el.forceProxy = true;
            el.proxyTarget = '_blank';
            let cond_nome = this.cod_condominio.hasOwnProperty('value') ? this.cod_condominio.value.nome : this.cod_condominio.nome
            el.saveAs('Balancete_' + this.utils.getFileNameFormatted(cond_nome) + '.pdf');
          }
        });
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        // let success = await this.getEachBalancete(exercicio);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
  async generateOrcamento(exercicio: number): Promise<boolean> {
    return new Promise(async (resolve) => {
      let index = this.orcamentoReports.findIndex(el => el.exercicio === exercicio);

      if (index >= 0 && index <= this.pdfOrcamentoController.length - 1) {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
        this.pdfOrcamentoController.forEach(async (el, i) => {
          if (i === index) {
            await el.generatePDF(null, null, this.orcamentoReports[index].id);
          }
        });
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
  async generateContaCorrente(exercicio: number): Promise<boolean> {
    return new Promise(async (resolve) => {
      let ordem = this.ordensTrabalhos.find(el => el.label === 'APRESENTACAO_CONTAS');
      let index = ordem.selAnos ? ordem.selAnos.findIndex(el => parseInt(el.ano) === exercicio) : -1;
      if (index >= 0 && index <= this.pdfContaCorrenteController.length - 1) {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
        this.pdfContaCorrenteController.forEach((el, i) => {
          if (i === index) {
            el.proxyURL = this.appConfig.fileProxyUrl;
            el.forceProxy = true;
            el.proxyTarget = '_blank';
            let cond_nome = this.cod_condominio.hasOwnProperty('value') ? this.cod_condominio.value.nome : this.cod_condominio.nome
            el.saveAs('Conta_Corrente_' + this.utils.getFileNameFormatted(cond_nome) + '.pdf');
          }
        });
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
  async generateQuotaExtra(index_r): Promise<boolean> {
    return new Promise(async (resolve) => {
      let ordem = this.ordensTrabalhos.find(el => el.label === 'QUOTA_EXTRA');
      if (!ordem || index_r >= ordem.resps.length) {
        resolve(false);
        return;
      }
      let proc = ordem.resps[index_r].parametros.find(el => el.chave === 'PROCESSAMENTO');
      if (!proc || proc.valor == null) {
        resolve(false);
        return;
      }

      let found = false;
      this.previewQuotaExtraPDF.forEach((el, i) => {
        if (i === index_r) {
          found = true;
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
          el.exportPDF(null, proc.valor).then(res => {
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
            resolve(res);
          }).catch(err => {
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
            resolve(false);
          });
        }
      });
      if (!found) {
        resolve(false);
      }
    });
  }

  downloadAutomaticFile(row: AnexoAssembleiaOpt): Promise<boolean> {
    return new Promise(async (resolve) => {
      let res = null;
      switch (true) {
        case /Balancete/.test(row.label):
          res = await this.generateBalancete(row.exercicio);
          break;
        case /Conta Corrente/.test(row.label):
          res = await this.generateContaCorrente(row.exercicio);
          break;
        case /Orçamento/.test(row.label):
          res = await this.generateOrcamento(row.exercicio);
          break;
        case /Quota Extraordinária/.test(row.label):
          res = await this.generateQuotaExtra(row.index_itemOrdemTrabalho);
          break;
        default:
          break;
      }
      if (res) {
        resolve(true);
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      }
    })
  }

  downloadAtaFile = (row: AnexoAssembleiaOpt): Promise<boolean> => {
    return new Promise(async (resolve) => {

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      if (row.automatic) {
        let res = await this.downloadAutomaticFile(row);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(res);
        return;
      }

      if (row.base64) {
        this.utils.downloadFile('data:application/pdf;base64,' + row.base64, row.filename);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(true);
        return;
      }

      if (!row.fileId) {
        row.fileId = row.ordemTrabalhoType === 'ASSUNTO_DIVERSO' ? this.assuntosDiversosRespObj[row.index_ordemTrabalho].anexo_id : this.ordensTrabalhos[row.index_ordemTrabalho].resps[row.index_itemOrdemTrabalho].anexo_id;
      }

      if (row.fileId) {
        this.api.getFile(row.fileId).subscribe(res => {
          if (res.success && res.data) {
            this.utils.downloadFile('data:application/pdf;base64,' + res.data.file, res.data.filename);
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
            resolve(true);
          } else {
            this.utils.apiErrorMsg(res);
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
            resolve(false);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(false);
        });
        return;
      }
    });
  }

  downloadAnexo(resp: OrdemTrabalhoItem | OrdemTrabalhoDiversos) {
    if (resp.anexo_id == null) return;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getFile(resp.anexo_id).subscribe(res => {
      if (res.success && res.data) {
        this.utils.downloadFile('data:application/pdf;base64,' + res.data.file, res.data.filename);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      } else {
        this.utils.apiErrorMsg(res);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  // Ata Folder modal
  openAtaFolderModal(): void {
    let anexosOpts: Array<AnexoOpt> = JSON.parse(JSON.stringify(this.ataFolderAnexosOpts));

    anexosOpts.forEach(anexo => {
      if (anexo.type === 'RELATORIO_ASSEMBLEIA') {
        anexo.base64 = this.details.relatorio_assembleia
        anexo.fileId = this.details.id_relatorio_assembleia;
        anexo.isUploaded = !!anexo.base64 || !!anexo.fileId;
        anexo.filename = 'Relatorio_Assembleia_' + this.defaultFilename;
        anexo.uploadFunction = this.uploadRelatorioAssembleia;
        anexo.deleteFunction = this.deleteRelatorioAssembleia;
        //15Mb
        anexo.maxSize = 15728640;
      }
      if (anexo.type === 'ATA_ASSINADA') {
        anexo.base64 = this.details.ata_ficheiro
        anexo.fileId = this.details.id_ata_ficheiro;
        anexo.isUploaded = !!anexo.base64 || !!anexo.fileId;
        anexo.canDelete = false;
        anexo.filename = 'Ata_Assinada' + this.defaultFilename;
        anexo.uploadFunction = this.uploadCopiaAta;
        //8Mb
        anexo.maxSize = 8388608;
      }
    });

    this.cdRef.detectChanges();
    this.listagemAnexosModal.openModal('Ficheiros', anexosOpts, false, true).then(res => {
    }).catch();
  }

  addMultiplaEntrada(ordemTrab: OrdemTrabalhoList) {
    let newEntry: OrdemTrabalhoItem = {
      respSel: null,
      resp: null,
      respSelOrigId: null,
      respOrig: null,
      need_anexo_indicator: false,
      uploaded_anexo_indicator: false,
      anexo_id: null,
      id_assembleia_ordem_trabalho_resp: null,
      parametros: []
    }
    this.selectDefaultResp(newEntry, ordemTrab.respOpts);
    ordemTrab.resps.push(newEntry);
  }

  selectDefaultResp(resp: OrdemTrabalhoItem, respOpts: Array<{ name: string, value: OrdemTrabalhoRespConfig }>) {
    let auxRespOpts = respOpts.filter(it => (it.value && typeof (it.value) !== 'number'));

    if (auxRespOpts && auxRespOpts.length === 1) {
      resp.resp = auxRespOpts[0].value.modelo_texto;
      resp.respOrig = auxRespOpts[0].value.modelo_texto;
      resp.respSel = auxRespOpts[0].value;
      resp.need_anexo_indicator = auxRespOpts[0].value.has_anexo == 1;
    }

    if (!resp.resp && auxRespOpts && auxRespOpts.length > 1) {
      let auxRespOpt = auxRespOpts.find(it => it.value.default == 1);

      if (auxRespOpt) {
        resp.resp = auxRespOpt.value.modelo_texto;
        resp.respOrig = auxRespOpt.value.modelo_texto;
        resp.respSel = auxRespOpt.value;
        resp.need_anexo_indicator = auxRespOpt.value.has_anexo == 1;
      }
    }
  }

  resetOrdemEntry(ordem: OrdemTrabalhoList | OrdemTrabalhoDiversos, resp: OrdemTrabalhoItem, index: number) {
    if (resp.respSel === null) {
      if (resp.hasOwnProperty('respOrig') && resp.hasOwnProperty('respSelOrigId') && resp.respOrig && resp.respSelOrigId) {
        resp.resp = resp.respOrig;
        let respSel = ordem.respOpts.find(el => el.value.id === resp.respSelOrigId);
        resp.respSel = respSel ? respSel.value : null;
      }
      this.setAnexosIndicators();
    } else {
      this.respSelected(ordem, resp, index, 'RESET');
    }
  }

  addNewOrdens(ordensToAdd: Array<OrdemTrabalhoList>) {
    let is_inserting_diversos = this.ordensTrabalhos.findIndex(el => el.label === 'ASSUNTOS_DIVERSOS') === -1 && ordensToAdd.findIndex(el => el.label === 'ASSUNTOS_DIVERSOS') !== -1;
    this.ordensTrabalhos = ordensToAdd.sort((a, b) => a.ordem - b.ordem);

    if (is_inserting_diversos) {
      let diversosPredefinidos = this.extraordinaria == '1' ? this.ordemTrabalhosConfig.filter(ot => ot.extras_diversos_predefinido === 1) : this.ordemTrabalhosConfig.filter(ot => ot.ordinarias_diversos_predefinido === 1);
      this.assuntosDiversosRespObj = diversosPredefinidos.map(ot => {
        return this.generateNewOrdemTrabalhoDiversos(ot);
      });
    }
  }

  handleRequiredSelectionOT(ordensToAdd: Array<OrdemTrabalhoList>, newOrdens: Array<OrdemTrabalhoList>): Promise<Array<OrdemTrabalhoList>> {
    return new Promise(async (resolve, reject) => {
      try {
        if (newOrdens.find(el => el.label === 'APRESENTACAO_CONTAS')) {
          ordensToAdd = await this.handleApresentacaoContasOptions(ordensToAdd);
        }

        if (newOrdens.find(el => el.label === 'ORCAMENTO')) {
          ordensToAdd = await this.handleOrcamentoOptions(ordensToAdd);
        }
        resolve(ordensToAdd);
      } catch (e) {
        reject(null);
        return;
      }
    })
  }

  //Seleção dos nos consoante a ordem de trabalho
  ordensDatePickerActivePage = 0;
  ordensWithDateSelection: Array<{ label: string, title: string, checked: boolean, anos: Array<anosPicker> }> = [];
  presentingOrdensTrabalhoAnosPicker = false;
  async presentOrdensTrabalhosAnosPicker(ordensToAdd: Array<OrdemTrabalhoList>) {
    return new Promise(async (resolve) => {
      if (this.presentingOrdensTrabalhoAnosPicker) {
        resolve(false);
        return;
      }
      this.presentingOrdensTrabalhoAnosPicker = true;
      this.ordensWithDateSelection = [];

      let newOrdens = ordensToAdd.filter(el => !this.ordensTrabalhos.find(ordem => ordem.id === el.id));
      try {
        ordensToAdd = await this.handleRequiredSelectionOT(ordensToAdd, newOrdens);
      } catch (e) {
        this.presentingOrdensTrabalhoAnosPicker = false;
        resolve(false);
        return;
      }

      if (this.ordensWithDateSelection.length) {
        this.ordensDatePickerActivePage = 0;
        this.pickOrdemTrabalhoAnosModalRef = this.modalService
          .open(this.pickOrdemTrabalhoAnosModalConfig)
          .onApprove(() => {
            this.saveOrdemTrabalhoInputDates(ordensToAdd);

            this.presentingOrdensTrabalhoAnosPicker = false;
            this.ordensWithDateSelection = [];
            this.pickOrdemTrabalhoAnosModalRef = null;
            resolve(true);
          })
          .onDeny(() => {
            ordensToAdd = ordensToAdd.filter(el => this.ordensWithDateSelection.findIndex(requireSel => requireSel.label === el.label) === -1);
            this.saveOrdemTrabalhoInputDates(ordensToAdd);

            this.presentingOrdensTrabalhoAnosPicker = false;
            this.ordensWithDateSelection = [];
            this.pickOrdemTrabalhoAnosModalRef = null;
            resolve(true);
          });
      } else {
        this.presentingOrdensTrabalhoAnosPicker = false;
        newOrdens.forEach(ordem => {
          ordem.resps.forEach((resp, index) => {
            this.respSelected(ordem, resp, index);
          });
        });
        this.addNewOrdens(ordensToAdd);
        resolve(true);
      }
    })

  }

  handleApresentacaoContasOptions(ordensToAdd): Promise<Array<OrdemTrabalhoList>> {
    return new Promise(async (resolve, reject) => {
      let res = await this.getAnosSemContasAprovadas();
      if (!res) {
        reject(null);
        return;
      }
      if (res.length) {
        let initialChecked = res.length == 1 ? true : false;
        this.ordensWithDateSelection.push({
          label: 'APRESENTACAO_CONTAS',
          title: 'Pretende apresentar contas referentes a que ano(s)?',
          checked: initialChecked,
          anos: res.map(el => { return { checked: initialChecked, dateVisible: true, ano: el.ano, data_inicio: el.data_inicio, data_fim: el.data_fim } })
        });
      } else {
        ordensToAdd = ordensToAdd.filter(el => el.label !== 'APRESENTACAO_CONTAS');
        this.toastr.info('Não é possível apresentar contas. Todas as contas do prédio selecionado, até à data da convocatória, estão apresentadas. ', 'Informação', { timeOut: 0, extendedTimeOut: 0, tapToDismiss: true });
      }
      resolve(ordensToAdd);
    });
  }

  getOrcamentoAvailableYears(lastAnoAprovado: string): Array<number> {
    let dt: Date = this.geralForm.get('dt').value;
    if (!dt) {
      this.toastr.error('Necessita primeiro de selecionar a data para a 1ª convocatória.', 'Alerta', { timeOut: 4000 });
      return [];
    }
    let availableDates = [dt.getFullYear(), dt.getFullYear() + 1];
    return availableDates;
    // return lastAnoAprovado ? availableDates.filter(el => el > parseInt(lastAnoAprovado)) : availableDates;
  }

  // START - ORCAMENTO "CONVOCATÓRIA" OPTIONS
  handleOrcamentoOptions(ordensToAdd: Array<OrdemTrabalhoList>): Promise<Array<OrdemTrabalhoList>> {
    return new Promise(async (resolve, reject) => {
      let res = await this.getUltimoOrcamento();
      let anosAvailable = this.getOrcamentoAvailableYears(res && res.exercicio);
      //Se não tem orçamentos aprovados
      if (!anosAvailable.length) {
        let anos: Array<anosPicker> = anosAvailable.map(ano => { return { checked: false, dateVisible: false, data_inicio: '01-01-' + ano, data_fim: '31-12-' + ano, ano: ano.toString() } });
        this.ordensWithDateSelection.push({
          label: 'ORCAMENTO',
          title: 'Pretende apresentar orçamento(s) referente(s) a que ano(s)?',
          checked: false,
          anos: anos,
        });
        resolve(ordensToAdd);
        return;
      }

      let firstYearWithoutBudget = anosAvailable[0];

      if (firstYearWithoutBudget) {
        //Atribui automaticamente o primeiro ano sem orçamento
        let ordem = ordensToAdd.find(el => el.label === 'ORCAMENTO');
        if (ordem) {
          let selAnos: Array<SelAno> = [{ ano: firstYearWithoutBudget.toString(), id_link: null, data_inicio: '01-01-' + firstYearWithoutBudget, data_fim: '01-01-' + firstYearWithoutBudget }]
          this.setAnosDescricao(ordem, selAnos);
        }
      } else {
        ordensToAdd = ordensToAdd.filter(el => el.label !== 'ORCAMENTO');
        this.toastr.info('Não é possível selecionar um orçamento. Já tem orçamentos aprovados até ' + res.exercicio + '.', 'Informação', { timeOut: 0, extendedTimeOut: 0, tapToDismiss: true });
      }

      resolve(ordensToAdd);
    });
  }

  getUltimoOrcamento(): Promise<{ exercicio }> {
    return new Promise((resolve) => {
      let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
      this.api.checkPreviousOrcamentoByCondominio(cod).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  // START - ordem_trabalho_datas selection Functions

  //Handles year selection by fetching and updating any data needed
  saveOrdemTrabalhoInputDates(ordensToAdd: Array<OrdemTrabalhoList>, default_values = false) {
    let newOrdens = ordensToAdd.filter(el => !this.ordensTrabalhos.find(ordem => ordem.id === el.id));
    this.processOrdemTrabalhoDatasInput(ordensToAdd, default_values);

    this.addNewOrdens(ordensToAdd);

    newOrdens.forEach(ordem => {
      if (ordem.label === 'APRESENTACAO_CONTAS') {
        this.getBalancetes(true);
        this.getContasCorrente();
      }
    });
    if (!this.isCreatePresencas) {
      this.setAnexosString();
    }
  }

  //Stores years selected at "Convocatória" in member 'selAnos'
  processOrdemTrabalhoDatasInput(ordensToAdd: Array<OrdemTrabalhoList>, default_values: boolean) {
    this.ordensWithDateSelection.forEach(el => {
      if (el.label === 'APRESENTACAO_CONTAS' || el.label === 'ORCAMENTO') {
        let ordem = ordensToAdd.find(ordem => ordem.label === el.label);
        if (ordem) {
          let anosChosen: Array<SelAno> = default_values ? el.anos.map(ano => { return { ano: ano.ano, id_link: null, data_inicio: ano.data_inicio, data_fim: ano.data_fim } }) : el.anos.filter(ano => !!ano.checked).map(ano => { return { ano: ano.ano, id_link: null, data_inicio: ano.data_inicio, data_fim: ano.data_fim } });
          this.setAnosDescricao(ordem, anosChosen, true);
        }
      }
    });
  }

  updateSelAnosIdLinkWithReports(ordem: OrdemTrabalhoList) {
    if (ordem && ordem.selAnos) {
      if (ordem.label === 'ORCAMENTO') {
        ordem.selAnos.forEach(ano => {
          let orc = this.orcamentoReports.find(el => el.exercicio === parseInt(ano.ano));
          if (orc) {
            ano.id_link = orc.id;
          }
        });
      }
    }
  }

  setAnosDescricao(ordem: OrdemTrabalhoList, anos: Array<SelAno>, reset = true) {
    anos.sort((a, b) => parseInt(a.ano) - parseInt(b.ano));
    ordem.selAnos = anos;
    let anosDescricao = '';
    ordem.selAnos.forEach((ano, i) => {
      if (i == 0) {
        anosDescricao += ano.ano;
      } else if (i < ordem.selAnos.length - 1) {
        anosDescricao += ', ' + ano.ano;
      } else {
        anosDescricao += ' e ' + ano.ano;
      }
    });
    ordem.anosDescricao = anosDescricao;

    this.updateSelAnosIdLinkWithReports(ordem);
    this.updateReports(anos, ordem.label);

    if (!reset) return;

    ordem.resps = [];
    let i = 0;
    while (i < anos.length) {
      let defaultItem: OrdemTrabalhoItem = {
        resp: null,
        respOrig: null,
        respSel: null,
        respSelOrigId: null,
        need_anexo_indicator: false,
        uploaded_anexo_indicator: false,
        anexo_id: null,
        id_assembleia_ordem_trabalho_resp: null,
        parametros: [],
      }

      let resp_default_opt = ordem.respOpts.find(el => el.value.hasOwnProperty('default') && el.value.default == 1);
      if (resp_default_opt) {
        let resp_default: OrdemTrabalhoRespConfig = resp_default_opt.value;
        defaultItem.resp = resp_default.modelo_texto
        defaultItem.respOrig = resp_default.modelo_texto
        defaultItem.respSel = resp_default
        defaultItem.respSelOrigId = resp_default.id
      }
      ordem.resps.push(defaultItem);
      i++;
    }

    // if (!this.isCreatePresencas) {
    ordem.resps.forEach((resp, index) => {
      this.respSelected(ordem, resp, index);
    });
    // }

  }

  updateReports(novosAnos: Array<SelAno>, label: OrdemTrabalhoLabel) {
    switch (label) {
      case 'APRESENTACAO_CONTAS':
        this.balanceteReports = this.balanceteReports.filter(el => novosAnos.find(ano => el.exercicio === parseFloat(ano.ano)));
        this.contaCorrenteReports = this.contaCorrenteReports.filter(el => novosAnos.find(ano => el.exercicio === parseFloat(ano.ano)));
        break;
      case 'ORCAMENTO':
        this.orcamentoReports = this.orcamentoReports.filter(el => novosAnos.find(ano => el.exercicio === parseFloat(ano.ano) && el.id === ano.id_link));
        break;

      default:
        break;
    }
  }

  getAnoOTDatas(el: ordem_trabalho_data): string {
    switch (el.type) {
      case 1:
      case '1':
        return el.data_fim ? this.utils.getDate(el.data_fim).getFullYear().toString() : null;
      case 2:
      case '2':
        return this.utils.getDate(el.data_inicio).getFullYear().toString();
      default:
        break;
    }
  }

  getAnosSemContasAprovadas(): Promise<Array<AnoSemContasAprovadas>> {
    return new Promise((resolve) => {
      let cod = (this.cod_condominio && this.cod_condominio.hasOwnProperty('value')) ? this.cod_condominio.value.cod : this.cod_condominio.cod;
      let id = this.details ? this.details.id_assembleia : null;
      let dt: Date = this.geralForm.get('dt').value;
      this.api.getAnosSemContasAprovadas(cod, id, dt).subscribe(res => {
        if (res.success && res.data) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  nextPageOrdensDatePicker() {
    //Validate input
    let ordem = this.ordensWithDateSelection[this.ordensDatePickerActivePage];
    if (!ordem.anos.find(el => el.checked)) {
      this.toastr.error('É necessário selecionar pelo menos um ano para prosseguir.', 'Selecione um ano');
      return;
    }
    if (this.ordensDatePickerActivePage < this.ordensWithDateSelection.length - 1) {
      this.ordensDatePickerActivePage++;
    } else {
      this.pickOrdemTrabalhoAnosModalRef.approve();
    }
  }

  editOrdemMainChecked = false;
  editOrdemAnosOpts: Array<anosPicker> = [];
  checkAllAnosPicker(opts: Array<{ checked }>, mainCheckboxValue: boolean) {
    opts.forEach(el => {
      el.checked = mainCheckboxValue;
    })
  }

  checkedAnosPickerRow(opts: Array<{ checked }>, type: 'EDIT_ORDEM' | 'ADD_ORDEM') {
    switch (type) {
      case 'ADD_ORDEM':
        this.ordensWithDateSelection[this.ordensDatePickerActivePage].checked = !opts.find(el => !el.checked)
        break;
      case 'EDIT_ORDEM':
        this.editOrdemMainChecked = !opts.find(el => !el.checked);
        break;

      default:
        break;
    }
  }

  // -------------- START - ORCAMENTO Functions

  @ViewChild('orcamentoPDF', { static: false }) orcamentoPDF: OrcamentoPdfComponent;
  getOrcamentos(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.apiSubs.find(el => el === 'ORCAMENTO')) {
        resolve(null);
        return;
      }

      if (this.ordensTrabalhos.findIndex(el => el.label === 'ORCAMENTO') === -1 || (!this.details && !this.geralForm.get('dt').value)) {
        resolve(null);
        return;
      }

      if (this.fetchingOrdemTrabalhos) {
        resolve(true);
        return;
      }
      let auxOrdemTrabalho = this.ordensTrabalhos.find(el => el.label === 'ORCAMENTO');
      if (!auxOrdemTrabalho || !auxOrdemTrabalho.selAnos) {
        resolve(true);
        return;
      }

      this.startApiSub('ORCAMENTO');

      let req = auxOrdemTrabalho.selAnos.reduce((acc, el, i) => {
        if (!!el.id_link) {
          acc.push(() => this.saveOrcamentoInfo(auxOrdemTrabalho, i, el.id_link, el.ano));
        }
        return acc;
      }, []);
      if (req.length) {
        Promise.all(req.map(el => el())).then(resArr => {
          this.orcamentoReports = resArr.filter(el => !!el);
          this.finishApiSub('ORCAMENTO');
          resolve(true);
        });
      } else {
        this.orcamentoReports = [];
        this.finishApiSub('ORCAMENTO');
        resolve(true);
      }
    })
  }

  exercicioSelectingOrcamento: string = null;
  selectingOrcamento = false;
  async selectOrcamento(ordemTrab: OrdemTrabalhoList, respIndex: number) {

    if (this.selectingOrcamento) {
      return;
    }
    this.selectingOrcamento = true;
    this.exercicioSelectingOrcamento = ordemTrab.selAnos[respIndex].ano;
    let fragment = "chooseorcamento" + this.exercicioSelectingOrcamento;
    this.router.navigate([], { fragment: fragment, replaceUrl: true });

    try {
      await this.getOrcamentosListEditOT(ordemTrab.selAnos[respIndex].ano);
      await this.presentOrcamentoPickerOT(ordemTrab, respIndex);
    } catch (err) {
      this.selectingOrcamento = false;
      this.exercicioSelectingOrcamento = null;
    }

    this.router.navigate([], { fragment: null, replaceUrl: true });
    this.selectingOrcamento = false;
    this.exercicioSelectingOrcamento = null;
  }



  createOrcamentoBtn(createOption: 'NEW' | 'COPY_SIMULACAO' | 'COPY_LAST_YEAR') {
    return new Promise(async (resolve) => {
      let copiedID = null;
      switch (createOption) {
        case 'NEW':
          this.goToOrcamento('criar')
          resolve(true);
          return;
        case 'COPY_LAST_YEAR':
          copiedID = this.lastOrcamentoId;

          break;
        case 'COPY_SIMULACAO':
          let selected = this.orcamentosPickerListOT.find(el => el.checked && el.val_lancado === '0');
          if (!selected) {
            this.toastr.error('É necessário selecionar primeiro a simulação que pretende copiar.', 'Selecione uma simulação');
            resolve(false);
            return;
          }
          copiedID = selected.id;
          break;

        default:
          resolve(false);
          return;
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      let copyID = await this.orcamentoService.copyOrcamento(copiedID, this.exercicioSelectingOrcamento);
      if (!copyID) {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(null);
        return;
      }
      let orcamento = await this.orcamentoService.getDetailsSimple(copyID);
      let orcamentoPicker: orcamentoPickerOT = {
        checked: false,
        dt_inicio: this.utils.getDate(orcamento.dt_inicio),
        dt_fim: this.utils.getDate(orcamento.dt_fim),
        tipo: orcamento.val_lancado === '1' ? 'Orçamento' : 'Simulação',
        id: orcamento.id,
        cod: orcamento.cod,
        periodo: orcamento.periodo,
        descricao: orcamento.descricao,
        val_lancado: orcamento.val_lancado === '1' ? orcamento.val_lancado : '0'
      }
      this.orcamentosPickerListOT.push(orcamentoPicker);
      this.orcamentosPickerListOT.sort((a, b) => {
        if (a.val_lancado !== b.val_lancado) return parseInt(b.val_lancado) - parseInt(a.val_lancado);
        return a.descricao.localeCompare(b.descricao)
      });
      this.alreadyHasOrcamento = this.orcamentosPickerListOT.findIndex(el => el.val_lancado === '1') !== -1;
      this.checkOrcamento(orcamentoPicker);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      resolve(true);
    })
  }

  getOrcamentoDetails(id): Promise<{
    orcamento: any[];
    zonas: any[];
    fraccoes: any[];
    fraccoes_fr: any[];
    rubricas: any[];
  }> {
    return new Promise((resolve) => {
      this.api.getOrcamentoDetailsREVIEW(id).subscribe(res => {
        if (res.success && res.data && res.data.orcamento.length) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  @ViewChild('orcamentoPickerAlertRef', { static: false }) orcamentoPickerAlertRef;
  orcamentoPickerModalRef = null;
  orcamentoPickerAlertConfig: any = null;
  presentOrcamentoPickerOT(ordemTrab: OrdemTrabalhoList, respIndex: number): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.orcamentoPickerModalRef = this.modalService
        .open(this.orcamentoPickerAlertConfig)
        .onApprove(async (chosenOrcamento: orcamentoPickerOT) => {
          let orcamento = await this.saveOrcamentoInfo(ordemTrab, respIndex, chosenOrcamento.id, chosenOrcamento.periodo);
          if (orcamento) {
            this.orcamentoReports = this.orcamentoReports.filter(el => el.exercicio === parseInt(chosenOrcamento.periodo));
            this.orcamentoReports.push(orcamento);
          }
          await this.respSelected(ordemTrab, ordemTrab.resps[respIndex], respIndex);
          await this.setAnexosString();
          resolve(true);
        })
        .onDeny(res => {
          resolve(false);
        });
    })
  }

  goToOrcamento(param) {
    let id_assembleia = this.details ? this.details.id_assembleia : null;
    if (id_assembleia) {
      this.router.navigate(['orcamentos/orcamento/' + param], { queryParams: { redirectUrl: this.router.url } });
    }
  }

  saveOrcamentoInfo(ordemTrab, respIndex, id, periodo): Promise<orcamentoPDFReport> {
    return new Promise(async (resolve) => {
      let orcamento = new OrcamentoDetailed(id, this.api, this.appConfig, this.utils, this.userSession, this.toastr);
      if (!(await orcamento.initialize())) {
        resolve(null);
        return;
      }

      let despesaOrcTotal = orcamento.rubricasTotais.total;

      let quota = orcamento.simulacaoList.length ? orcamento.simulacaoList[0].total : 0;

      let aux = orcamento.simulacaoList.find(el => (el.cod_zona === '6'));
      let quotaLoja = (aux) ? aux.total : 0

      aux = orcamento.simulacaoList.find(el => (el.cod_zona == 12));
      let quotaHab = (aux) ? aux.total : 0

      ordemTrab.selAnos[respIndex].id_link = id;
      let orcamentoReport: orcamentoPDFReport = {
        id: id,
        exercicio: parseInt(periodo),
        despesaOrcTotal: despesaOrcTotal,
        quota: quota,
        quotaLoja: quotaLoja,
        quotaHab: quotaHab,
        descricao: orcamento.orcamento.descricao,
        val_lancado: orcamento.orcamento.val_lancado,
      }
      resolve(orcamentoReport);
    })
  }

  lastOrcamentoId = null;
  alreadyHasOrcamento = false;
  orcamentosPickerListOT: Array<orcamentoPickerOT> = [];
  getOrcamentosListEditOT(exercicio): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.details || !this.details.id_assembleia) {
        reject(null);
        return;
      }
      this.alreadyHasOrcamento = false;
      this.api.getOrcamentosListAta(this.details.id_assembleia, exercicio).subscribe(res => {
        if (res.success) {
          this.orcamentosPickerListOT = res.data.orcamentos.map(el => { return { ...el, checked: false, val_lancado: el.val_lancado === '1' ? el.val_lancado : '0', tipo: el.val_lancado === '1' ? 'Orçamento' : 'Simulação', dt_inicio: this.utils.getDate(el.dt_inicio), dt_fim: this.utils.getDate(el.dt_fim) } });
          this.lastOrcamentoId = res.data.ultimo_orcamento;
          let orcamentoLancado = this.orcamentosPickerListOT.find(el => el.val_lancado === '1');
          if (orcamentoLancado) {
            orcamentoLancado.checked = true;
            this.alreadyHasOrcamento = true;
          } else if (this.anchorParams.selectingOrcamentoID) {
            let orcamento = this.orcamentosPickerListOT.find(el => el.id === this.anchorParams.selectingOrcamentoID);
            if (orcamento) orcamento.checked = true;
            this.anchorParams.selectingOrcamentoID = null;
          }
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        reject(null);
      });
    })
  }

  presentAlreadyExistsOrcamentoToastr() {
    let msg = 'Se pretender selecionar outra simulação, terá de remover o orçamento aprovado primeiro.';
    if (this.toastr.findDuplicate(msg, false, false)) this.toastr.clear();
    this.toastr.warning(msg, 'Existe um orçamento aprovado');
  }

  openCreateOrcamentoDropdown() {
    if (this.alreadyHasOrcamento) {
      this.presentAlreadyExistsOrcamentoToastr();
    }
  }

  checkOrcamento(entry: orcamentoPickerOT) {
    if (this.alreadyHasOrcamento && entry.val_lancado !== '1') {
      this.presentAlreadyExistsOrcamentoToastr();
      setTimeout(() => {
        this.orcamentosPickerListOT.forEach(el => {
          el.checked = el.val_lancado === '1';
        })
      });
      return;
    }
    let newValue = !entry.checked;
    this.orcamentosPickerListOT.forEach(el => el.checked = false);
    entry.checked = newValue;
  }

  confirmOrcamentoSelectionBtn() {
    let chosenOrcamento = this.orcamentosPickerListOT.find(el => el.checked);
    if (!chosenOrcamento) {
      this.toastr.error('É necessário selecionar um orçamento para prosseguir.', 'Selecione um orçamento');
      return;
    }
    this.orcamentoPickerModalRef.approve(chosenOrcamento);
  }

  hasOrcamentoSelection(ordemTrabalho: OrdemTrabalhoList | OrdemTrabalhoFlat, index_r): boolean {
    return ordemTrabalho.selAnos && index_r < ordemTrabalho.selAnos.length && !!ordemTrabalho.selAnos[index_r].id_link;
  }

  @ViewChildren('previewOrcamentoSelectionPDF') previewOrcamentoSelectionPDF: QueryList<OrcamentoPdfComponent>;
  // async viewPDFBtn(ordemTrabalho:OrdemTrabalhoList, resp_index:number) {
  //   if (resp_index >= ordemTrabalho.selAnos.length) return;
  //   await this.generateOrcamentoPDF(ordemTrabalho.selAnos[resp_index].id_link);
  // } 

  generateOrcamentoPDF(id_orc, indexPreview): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      await this.previewOrcamentoSelectionPDF.toArray()[indexPreview].generatePDF(null, null, id_orc);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      resolve(true);
    })
  }


  @ViewChild('deleteModal', { static: false }) deleteModal: DeleteModalComponent;
  deletingOrcamento = false;
  deleteOrcamento(orcamento: orcamentoPickerOT): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (this.deletingOrcamento) {
        resolve(true);
        return;
      }
      this.deletingOrcamento = true;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      await this.presentDeleteOrcamentoAlert(orcamento);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.deletingOrcamento = false;
      resolve(true);
    })
  }

  // Delete function
  delOrcamento: orcamentoPickerOT = null;
  presentDeleteOrcamentoAlert(orcamento: orcamentoPickerOT) {
    this.delOrcamento = orcamento;
    this.deleteModal.open('Deseja eliminar o orçamento selecionado?');
  }

  @ViewChild('deleteAvisosAlertRef', { static: false }) deleteAvisosAlertRef;
  alertAvisosModalRef = null;
  alertAvisosAlertConfig: any = null;
  loadingDeleteAvisos = false;
  del = (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      if (this.delOrcamento.val_lancado === '1') {
        this.alertAvisosModalRef = this.modalService
          .open(this.alertAvisosAlertConfig)
          .onApprove(async () => {

            this.loadingDeleteAvisos = true;
            let res = await this.orcamentoService.del([this.delOrcamento]);
            if (res) {
              this.orcamentosPickerListOT = this.orcamentosPickerListOT.filter(el => el.id !== this.delOrcamento.id);
              this.alreadyHasOrcamento = this.orcamentosPickerListOT.findIndex(el => el.val_lancado === '1') !== -1;
            }
            this.loadingDeleteAvisos = false;
            resolve(res);
          })
          .onDeny(() => {
          });
        resolve(true);
      } else {
        let res = await this.orcamentoService.del([this.delOrcamento]);
        if (res) {
          this.orcamentosPickerListOT = this.orcamentosPickerListOT.filter(el => el.id !== this.delOrcamento.id);
          this.alreadyHasOrcamento = this.orcamentosPickerListOT.findIndex(el => el.val_lancado === '1') !== -1;
        }
        resolve(res);
      }
    });
  }

  layeredOrdemTrabalhos: Array<OrdemTrabalhoLabel> = ['BANCOS', 'QUOTA_EXTRA', 'EMAIL_OFICIAL'];
  ordemTrabalhoHasLayer(label: OrdemTrabalhoLabel): boolean {
    return this.layeredOrdemTrabalhos.findIndex(el => el === label) !== -1;
  }

  @ViewChild('editBancosAssembleiaResp', { static: false }) editBancosAssembleiaResp: EditBancosAssembleiaRespComponent;
  clickedOrdemTrabalhoLayer(ordemTrab: OrdemTrabalhoList | OrdemTrabalhoDiversos, resp_index: number) {
    if (!this.ordemTrabalhoHasLayer(ordemTrab.label) || this.ataEditEnabled) return;

    switch (ordemTrab.label) {
      case 'BANCOS':
        this.handleClickOnBancosLayer(ordemTrab);
        break;
      case 'QUOTA_EXTRA':
        this.handleClickOnQuotaExtraLayer(ordemTrab, resp_index);
        break;
      case 'EMAIL_OFICIAL':
        this.handleClickOnEmailOficialLayer(ordemTrab, resp_index);
        break;

      default:
        break;
    }

  }

  async handleClickOnBancosLayer(ordemTrab: OrdemTrabalhoList | OrdemTrabalhoDiversos) {
    let respHolder: { resp: string, parametros: OrdemTrabalhoParametros[], respSel: OrdemTrabalhoRespConfig, respOrig, id_assembleia_ordem_trabalho_resp } = null;
    if ('resps' in ordemTrab) {
      respHolder = ordemTrab.resps.length ? ordemTrab.resps[0] : null;
    } else {
      respHolder = ordemTrab;
    }

    let resps = await this.editBancosAssembleiaResp.open(respHolder ? respHolder.parametros.filter(el => el.chave !== 'CONDOMINO') : undefined);
    if (resps == null) return;
    let bancoOrdem = this.ordemTrabOpts.find(el => el.value.label === 'BANCOS');
    if (!bancoOrdem || !bancoOrdem.value) {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      return;
    }
    let abertura = bancoOrdem.value.resps.find(el => el.label === 'BANCO_ABERTURA');
    let alteracao = bancoOrdem.value.resps.find(el => el.label === 'BANCO_ALTERACAO');
    let alteracaoFecho = bancoOrdem.value.resps.find(el => el.label === 'BANCO_ALTERACAO_FECHO');
    let fecho = bancoOrdem.value.resps.find(el => el.label === 'BANCO_FECHO');

    let text = '';
    resps.forEach((resp, respIndex) => {
      let operationText = '';
      let numAssinaturas = null;
      let nomeTitulares = '';
      let nomeContaBanco = null;
      if (resp.entidades.length) {
        resp.entidades.forEach((el, i) => {
          nomeTitulares += el.name;
          if (i < resp.entidades.length - 2) {
            nomeTitulares += ', ';
          } else if (i < resp.entidades.length - 1) {
            nomeTitulares += ' e ';
          }
        });
      }

      switch (resp.operation) {
        case 'BANCO_ABERTURA':
          operationText += abertura.modelo_texto;
          numAssinaturas = resp.numAssinaturas;
          nomeContaBanco = resp.banco.name;
          break;
        case 'BANCO_ALTERACAO':
          operationText += alteracao.modelo_texto;
          numAssinaturas = resp.numAssinaturas;
          nomeContaBanco = resp.conta.name;
          break;
        case 'BANCO_ALTERACAO_FECHO':
          operationText += alteracaoFecho.modelo_texto;
          numAssinaturas = resp.numAssinaturas;
          nomeContaBanco = resp.conta.name;
          break;
        case 'BANCO_FECHO':
          operationText += fecho.modelo_texto;
          nomeContaBanco = resp.conta.name;
          break;
        default:
          break;
      }

      operationText = operationText.replace(/\[\[ BANCO_CONTA \]\]/g, nomeContaBanco + ', S.A.');
      if (nomeTitulares.length) operationText = operationText.replace(/\[\[ NOME_TITULARES \]\]/g, nomeTitulares);
      if (numAssinaturas != null) operationText = operationText.replace(/\[\[ NUM_ASSINATURAS \]\]/g, numAssinaturas);

      text += operationText;
      if (respIndex < resps.length - 1) text += '\n\n';
    })

    let newResp = {
      resp: text,
      respSel: null,
      uploaded_anexo_indicator: false,
      need_anexo_indicator: false,
      anexo_id: null,
      respSelOrigId: null,
      respOrig: respHolder ? respHolder.respOrig : null,
      id_assembleia_ordem_trabalho_resp: respHolder ? respHolder.id_assembleia_ordem_trabalho_resp : null,
      parametros: resps.map(resp => {
        return {
          id: resp.id_param,
          chave: resp.operation,
          valor: JSON.stringify({
            conta: resp.conta ? resp.conta.cod : null,
            banco: resp.banco ? resp.banco.cod : null,
            num_assinaturas: resp.numAssinaturas,
            entidades: resp.entidades.map(ent => { return { cod: ent.cod, type: ent.type } })
          }),
        }
      }),
    };

    if ('resps' in ordemTrab) {
      ordemTrab.resps = [newResp];
    } else {
      ordemTrab = Object.assign(ordemTrab, newResp);
    }
  }

  async handleClickOnQuotaExtraLayer(ordemTrab: OrdemTrabalhoList | OrdemTrabalhoDiversos, resp_index: number) {
    this.selectQuotaExtra(ordemTrab, resp_index);
  }
  async handleClickOnEmailOficialLayer(ordemTrab: OrdemTrabalhoList | OrdemTrabalhoDiversos, resp_index: number) {
    this.selectEmailOficial(ordemTrab, resp_index);
  }

  @ViewChild('selectEntities', { static: false }) selectEntities: SelectEntitiesModalComponent;
  async askForEntidades(ordemTrabalho: OrdemTrabalhoList, resp: OrdemTrabalhoItem) {

    let entidades = await this.selectEntities.open({
      entidadesList: this.details.assembleiaPresencas.map(frac => {
        let ent: SelectEntidadesList = {
          nome_fraccao: frac.nome_fracao,
          cod_entidade: frac.cod_condomino,
          nome_entidade: frac.nome_condomino,
          checked: false
        };
        return ent;
      }),
      nSelection: null
    });
    if (entidades === null) return;

    let nome_titulares = '';
    let nomeEntidades = '';
    entidades.forEach((el, i) => {
      nomeEntidades += el.name;
      if (i < entidades.length - 2) {
        nomeEntidades += ', ';
      } else if (i < entidades.length - 1) {
        nomeEntidades += ' e ';
      }
    });

    resp.resp = resp.resp.replace(/\[\[ ENTIDADES_LIST \]\]/g, nomeEntidades);

    let parametros: Array<OrdemTrabalhoParametros> = []
    entidades.forEach(entidade => {
      parametros.push({
        id: null,
        chave: entidade.type,
        valor: entidade.cod.toString()
      });
    })
    resp.parametros = parametros;
  }



  // ------------------- QUOTA EXTRA ZONE -------------------

  //Array used to reduce API Requests
  processamentosInfo: Array<ProcessamentoDetails> = []

  @ViewChildren('previewQuotaExtraPDF') previewQuotaExtraPDF: QueryList<ProcessamentoPdfComponent>;
  getNumberOfQuotasExtra() {
    let ot = this.ordensTrabalhos.find(el => el.label === 'QUOTA_EXTRA');
    return ot ? ot.resps : [];
  }

  hasQuotaExtraSelection(parametros: Array<OrdemTrabalhoParametros>): boolean {
    return parametros.findIndex(param => param.chave === 'PROCESSAMENTO') !== -1;
  }

  @ViewChild('pickQuotaExtra', { static: false }) pickQuotaExtra: AssembleiasPickQuotaExtraComponent;
  async selectQuotaExtra(ordemTrab: OrdemTrabalhoList | OrdemTrabalhoDiversos, respIndex: number, prevState = undefined) {
    this.router.navigate([], {
      queryParams: {
        action: 'select_quota_extra',
        nResp: respIndex,
      },
      replaceUrl: true,
    });

    let respHolder: { resp: string, parametros: OrdemTrabalhoParametros[], respSel: OrdemTrabalhoRespConfig } = null;
    if ('resps' in ordemTrab) {
      respHolder = ordemTrab.resps[respIndex];
    } else {
      respHolder = ordemTrab;
    }

    let procSelected = respHolder.parametros.find(p => p.chave === 'PROCESSAMENTO');
    if (procSelected) {
      if (prevState === undefined) {
        prevState = {
          prevProcId: procSelected.valor,
          id_proc_select: procSelected.valor,
        }
      } else {
        prevState['prevProcId'] = procSelected.valor;
        if (!('id_proc_select' in prevState) || prevState.id_proc_select == null) {
          prevState['id_proc_select'] = procSelected.valor;
        }
      }
    }

    let procsAlreadyAdded: Array<string> = [];
    this.ordensTrabalhos.filter(ot => ot.label === 'QUOTA_EXTRA').forEach(ot => {
      ot.resps.forEach((resp, r_i) => {
        if (r_i === respIndex) return;
        resp.parametros.filter(param => {
          if (param.chave === 'PROCESSAMENTO' && param.valor != null) procsAlreadyAdded.push(param.valor);
        })
      })
    })

    let cod_condominio = this.cod_condominio.hasOwnProperty('value') ? this.cod_condominio.value : this.cod_condominio;
    let processamento = await this.pickQuotaExtra.open(cod_condominio, respIndex, procsAlreadyAdded, prevState);
    this.router.navigate([], {
      queryParams: null,
      replaceUrl: true,
    });
    if (!processamento) return;

    let respOpt = null;
    let respConfig: OrdemTrabalhoRespConfig = null;
    if (processamento.tipoReparticao === 'UNICO') {
      respOpt = ordemTrab.respOpts.find(el => el.value.hasOwnProperty('label') && el.value.label === 'QUOTA_EXTRA_UNICA');
      respConfig = respOpt.value;
    } else {
      respOpt = ordemTrab.respOpts.find(el => el.value.hasOwnProperty('label') && el.value.label === 'QUOTA_EXTRA_REPARTIDA');
      respConfig = respOpt.value;
    }

    respHolder.respSel = respConfig;

    respHolder.resp = this.getQuotaExtraRespFilled(respConfig.modelo_texto, processamento);

    respHolder.parametros = [
      {
        id: null,
        chave: 'PROCESSAMENTO',
        valor: processamento.id.toString(),
      }
    ];

    this.setAnexosString();
  }

  getQuotaExtraRespFilled(resp: string, data: { valorTotal: number, dt_inicio: Date, data_venc: Date, nPrestacoes: number, mesesCobranca: string, tipoReparticao: Processamento['tipo_reparticao'] }): string {
    resp = resp.replace(/\[\[ QUOTA_EXTRA \]\]/g, this.utils.getNumberFormatted(data.valorTotal, true));
    resp = resp.replace(/\[\[ QUOTA_EXTRA_EXT \]\]/g, this.utils.getNumberPorExtenso(data.valorTotal, true));
    resp = resp.replace(/\[\[ DATA_LIMITE_PAG \]\]/g, this.utils.getFormatedDate(data.data_venc));
    resp = resp.replace(/\[\[ NUM_PRESTACOES \]\]/g, data.nPrestacoes.toString());
    let mesesCobranca = '';
    if (data.tipoReparticao === 'UNICO') {
      mesesCobranca = this.appConfig.meses[data.dt_inicio.getMonth()].toLowerCase();
    } else {
      let startYear = data.dt_inicio.getFullYear();
      let cobrancas: Array<{ year: number, monthsIndex: Array<number> }> = [];
      let monthIndex = undefined;
      for (let i = 0; i < data.mesesCobranca.length; i++) {
        if (data.mesesCobranca.charAt(i) === 'S') {
          let currYear = startYear + Math.floor(i / 12);
          let cobranca = cobrancas.find(cob => cob.year === currYear);
          monthIndex = i % 12;
          if (cobranca === undefined) {
            cobrancas.push({
              year: currYear,
              monthsIndex: [monthIndex],
            });
          } else {
            cobranca.monthsIndex.push(monthIndex)
          }
        }
      }

      cobrancas.forEach((cobranca, i) => {
        if (i !== 0) {
          mesesCobranca += i === cobrancas.length - 1 ? " e " : ", ";
        }

        cobranca.monthsIndex.forEach((monthIndex, j) => {
          if (j !== 0) {
            mesesCobranca += j === cobranca.monthsIndex.length - 1 ? " e " : ", ";
          }
          mesesCobranca += this.appConfig.meses[monthIndex].toLowerCase();
        })
        mesesCobranca += " de " + cobranca.year
      })
    }
    resp = resp.replace(/\[\[ MESES_PAG_LIST \]\]/g, mesesCobranca);

    return resp;
  }
  // ---------------------------------------------------------
  // -------------------- EMAIL OFICIAL ----------------------
  @ViewChild('pickEmailOficial', { static: false }) pickEmailOficial: AssembleiasPickEmailOficialComponent;
  selectEmailOficial(ordemTrab: OrdemTrabalhoList | OrdemTrabalhoDiversos, respIndex: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let prevParametros: { id: number, cod_fraccao: string, cod_condomino: number, email: string }[] = [];

      let respHolder: { resp: string, parametros: OrdemTrabalhoParametros[] } = null;
      if ('resps' in ordemTrab) {
        respHolder = ordemTrab.resps[respIndex];
      } else {
        respHolder = ordemTrab;
      }
      respHolder.parametros.forEach(p => {
        if (p.chave !== 'EMAIL_OFICIAL') return;
        try {
          var valor = JSON.parse(p.valor);
        } catch (err) {
          return;
        }
        prevParametros.push({
          id: p.id,
          cod_fraccao: valor.cod_fraccao,
          cod_condomino: parseInt(valor.cod_condomino),
          email: valor.email
        });
      })

      try {
        var emailsOficiais = await this.pickEmailOficial.open(prevParametros);
      } catch (err) {
        resolve(false);
        return;
      }


      let emailsOficiaisStr = '';

      let parametros: OrdemTrabalhoParametros[] = [];

      for (let i = 0; i < emailsOficiais.length; i++) {
        const element = emailsOficiais[i];
        if (i > 0) emailsOficiaisStr += ';\n';
        emailsOficiaisStr += element.nome_fracao + ', ' + element.nome_condomino + ', ' + element.email;
        parametros.push({
          id: element.id_parametro,
          chave: 'EMAIL_OFICIAL',
          valor: JSON.stringify({
            cod_fraccao: element.cod_fraccao,
            cod_condomino: element.cod_condomino,
            email: element.email,
          }),
        });
      }
      emailsOficiaisStr += '.';

      let respConfig = ordemTrab.respOpts.find(el => (el.value as any) !== -1 && el.value.label === 'EMAIL_OFICIAL');
      if (!respConfig) {
        resolve(false);
        return;
      }

      let resp = respConfig.value.modelo_texto;
      resp = resp.replace(/\[\[ EMAIL_LIST \]\]/g, emailsOficiaisStr);

      respHolder.resp = resp;


      respHolder.parametros = parametros;
    });
  }

  // ---------------------------------------------------------
}
