import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, enableProdMode } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeDECH from '@angular/common/locales/pt-PT';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SuiModule } from 'ng2-semantic-ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { NgProgressModule } from '@ngx-progressbar/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutosizeModule } from 'ngx-autosize';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling'
import { TextFieldModule } from '@angular/cdk/text-field';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { QuillModule } from 'ngx-quill';

// SERVICES
import { UserSessionService } from './user-session.service';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { MessageService } from './message.service';
import { AuthGuardService } from './auth-guard.service';
import { DeactivateGuardService } from './deactivate-guard.service';
import { UtilitiesService } from './utilities.service';
import { AppStateService } from './app-state.service';
import { PreviousRouteService } from './previous-route.service';

// PIPES
import { IsNullPipe } from './is-null.pipe';
import { ReplacePipe } from './replace.pipe';

// COMPONENTS
import { SideMenuComponent } from './side-menu/side-menu.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LoginComponent } from './login/login.component';
import { CondominiumsComponent } from './condominiums/condominiums.component';
import { CondominiumDetailsComponent } from './condominium-details/condominium-details.component';
import { EntitiesComponent } from './entities/entities.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { ActivitiesComponent } from './activities/activities.component';
import { MainComponent } from './main/main.component';
import { LancamentosComponent } from './lancamentos/lancamentos.component';
import { FraccoesDetailsComponent } from './fraccoes-details/fraccoes-details.component';
import { ContactosDetailsComponent } from './contactos-details/contactos-details.component';
import { ContasDetailsComponent } from './contas-details/contas-details.component';
import { OrcamentosComponent } from './orcamentos/orcamentos.component';
import { UtilizadoresComponent } from './utilizadores/utilizadores.component';
import { UtilizadorDetailsComponent } from './utilizador-details/utilizador-details.component';
import { OrcamentoDetailsComponent } from './orcamento-details/orcamento-details.component';
import { AssembleiasDetailsComponent } from './assembleias-details/assembleias-details.component';
import { EntityDetailsComponent } from './entity-details/entity-details.component';
import { ProcessamentoComponent } from './processamento/processamento.component';
import { AvisoComponent } from './aviso/aviso.component';
import { ContasBancariasComponent } from './contas-bancarias/contas-bancarias.component';
import { MovimentosDetailsComponent } from './movimentos-details/movimentos-details.component';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number.directive';
import { ThreeDigitDecimalNumberDirective } from './three-digit-decimal-number.directive';
import { DespesaV2Component } from './despesa-v2/despesa-v2.component';
import { CreditosDetailsComponent } from './creditos-details/creditos-details.component';
import { ReceitasDetailsComponent } from './receitas-details/receitas-details.component';
import { RecibosDetailsComponent } from './recibos-details/recibos-details.component';
import { AssembleiasComponent } from './assembleias/assembleias.component';
import { ContasCorrentesComponent } from './contas-correntes/contas-correntes.component';
import { ContaCorrenteRelatorioComponent } from './conta-corrente-relatorio/conta-corrente-relatorio.component';
import { RelatorioExercicioComponent } from './relatorio-exercicio/relatorio-exercicio.component';
import { CaixaVertisComponent } from './caixa-vertis/caixa-vertis.component';
import { CorrespondenciaComponent } from './correspondencia/correspondencia.component';
import { FuncionariosComponent } from './funcionarios/funcionarios.component';
import { AssembleiasAgendamentoComponent } from './assembleias-agendamento/assembleias-agendamento.component';
import { RegistoProcessosComponent } from './registo-processos/registo-processos.component';
import { SendEmailsByCondominioComponent } from './send-emails-by-condominio/send-emails-by-condominio.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { RegistoTitularesBancariosComponent } from './registo-titulares-bancarios/registo-titulares-bancarios.component';
import { ComunicacaoAdministradoresPdfComponent } from './comunicacao-administradores-pdf/comunicacao-administradores-pdf.component';
import { RelatorioAssembleiaPdfComponent } from './relatorio-assembleia-pdf/relatorio-assembleia-pdf.component';
import { ListaPresencasAssembleiaPdfComponent } from './lista-presencas-assembleia-pdf/lista-presencas-assembleia-pdf.component';
import { PagamentosNaoIdentificadosPdfComponent } from './pagamentos-nao-identificados-pdf/pagamentos-nao-identificados-pdf.component';
import { EmailCartaComunicacaoModalComponent } from './email-carta-comunicacao-modal/email-carta-comunicacao-modal.component';
import { DespesasCttModalComponent } from './despesas-ctt-modal/despesas-ctt-modal.component';
import { ReplaceRegexPipe } from './replace-regex.pipe';
import { ReconciliacaoBancariaRelatorioComponent } from './reconciliacao-bancaria-relatorio/reconciliacao-bancaria-relatorio.component';
import { RecibosAnuladosRelatorioComponent } from './recibos-anulados-relatorio/recibos-anulados-relatorio.component';
import { ListagemDespesasUtilizadorComponent } from './listagem-despesas-utilizador/listagem-despesas-utilizador.component';
import { ListagemRecibosUtilizadorComponent } from './listagem-recibos-utilizador/listagem-recibos-utilizador.component';
import { SuiSelectHolderComponent } from './sui-select-holder/sui-select-holder.component';
import { EditPayAgendamentoComponent } from './edit-pay-agendamento/edit-pay-agendamento.component';
import { PropostasComponent } from './propostas/propostas.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { CartaComunicacaoComponent } from './carta-comunicacao/carta-comunicacao.component';
import { CartaCttAvisoRececaoComponent } from './carta-ctt-aviso-rececao/carta-ctt-aviso-rececao.component';
import { CondominioContactsPdfComponent } from './condominio-contacts-pdf/condominio-contacts-pdf.component';
import { ViewSentEmailModalComponent } from './view-sent-email-modal/view-sent-email-modal.component';
import { CustosComunicacoesComponent } from './custos-comunicacoes/custos-comunicacoes.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { EditEntregaFaturaCttModalComponent } from './edit-entrega-fatura-ctt-modal/edit-entrega-fatura-ctt-modal.component';
import { EntregasFaturasComponent } from './entregas-faturas/entregas-faturas.component';
import { ListagemAnexosModalComponent } from './listagem-anexos-modal/listagem-anexos-modal.component';
import { UnsavedChangesModalComponent } from './unsaved-changes-modal/unsaved-changes-modal.component';
import { CartaHeaderComponent } from './carta-header/carta-header.component';
import { OrcamentoPdfComponent } from './orcamento-pdf/orcamento-pdf.component';
import { LoadingDotsComponent } from './loading-dots/loading-dots.component';
import { ModalDefaultComponent } from './modal-default/modal-default.component';
import { ViewReconciliacoesActivityModalComponent } from './view-reconciliacoes-activity-modal/view-reconciliacoes-activity-modal.component';
import { UploadModalDefaultComponent } from './upload-modal-default/upload-modal-default.component';
import { ReciboPdfComponent } from './recibo-pdf/recibo-pdf.component';
import { EnviarRecibosEmailComponent } from './enviar-recibos-email/enviar-recibos-email.component';
import { PrintRecibosPdfComponent } from './print-recibos-pdf/print-recibos-pdf.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { ContenciosoModalComponent } from './contencioso-modal/contencioso-modal.component';
import { ConfissaoDividaPdfComponent } from './confissao-divida-pdf/confissao-divida-pdf.component';
import { NoticePaymentsComponent } from './notice-payments/notice-payments.component';
import { RegistoComunicacoesComponent } from './registo-comunicacoes/registo-comunicacoes.component';
import { MapaQuotasPdfComponent } from './mapa-quotas-pdf/mapa-quotas-pdf.component';
import { ContaCorrentePdfComponent } from './conta-corrente-pdf/conta-corrente-pdf.component';
import { ConfigAvisosCobrancasComponent } from './config-avisos-cobrancas/config-avisos-cobrancas.component';
import { ConfigAvisosCobrancaDetailsComponent } from './config-avisos-cobranca-details/config-avisos-cobranca-details.component';
import { IbanPipe } from './iban.pipe';
import { ComunicarComponent } from './comunicar/comunicar.component';
import { EditBancosAssembleiaRespComponent } from './edit-bancos-assembleia-resp/edit-bancos-assembleia-resp.component';
import { SelectEntitiesModalComponent } from './select-entities-modal/select-entities-modal.component';
import { CondominioEntidadesRegAtividadeModalComponent } from './condominio-entidades-reg-atividade-modal/condominio-entidades-reg-atividade-modal.component';
import { ProcessamentoPdfComponent } from './processamento-pdf/processamento-pdf.component';
import { AssembleiasPickQuotaExtraComponent } from './assembleias-pick-quota-extra/assembleias-pick-quota-extra.component';
import { ProcessamentoDeleteRecibosComponent } from './processamento-delete-recibos/processamento-delete-recibos.component';
import { ProcessamentoDuplicationModalComponent } from './processamento-duplication-modal/processamento-duplication-modal.component';
import { AssembleiasPickEmailOficialComponent } from './assembleias-pick-email-oficial/assembleias-pick-email-oficial.component';
import { AssembleiaOtSelectYearsComponent } from './assembleia-ot-select-years/assembleia-ot-select-years.component';

registerLocaleData(localeDECH);

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent,
    BreadcrumbComponent,
    CondominiumsComponent,
    LoginComponent,
    MainComponent,
    IsNullPipe,
    CondominiumDetailsComponent,
    EntitiesComponent,
    ReplacePipe,
    ConfigurationsComponent,
    ActivitiesComponent,
    LancamentosComponent,
    FraccoesDetailsComponent,
    ContactosDetailsComponent,
    ContasDetailsComponent,
    OrcamentosComponent,
    OrcamentoDetailsComponent,
    AssembleiasDetailsComponent,
    UtilizadoresComponent,
    UtilizadorDetailsComponent,
    EntityDetailsComponent,
    ProcessamentoComponent,
    AvisoComponent,
    ContasBancariasComponent,
    MovimentosDetailsComponent,
    TwoDigitDecimalNumberDirective,
    ThreeDigitDecimalNumberDirective,
    DespesaV2Component,
    CreditosDetailsComponent,
    ReceitasDetailsComponent,
    RecibosDetailsComponent,
    AssembleiasComponent,
    ContasCorrentesComponent,
    ContaCorrenteRelatorioComponent,
    RelatorioExercicioComponent,
    CaixaVertisComponent,
    CorrespondenciaComponent,
    FuncionariosComponent,
    AssembleiasAgendamentoComponent,
    RegistoProcessosComponent,
    SendEmailsByCondominioComponent,
    SanitizeHtmlPipe,
    RegistoTitularesBancariosComponent,
    ComunicacaoAdministradoresPdfComponent,
    RelatorioAssembleiaPdfComponent,
    ListaPresencasAssembleiaPdfComponent,
    PagamentosNaoIdentificadosPdfComponent,
    EmailCartaComunicacaoModalComponent,
    DespesasCttModalComponent,
    ReplaceRegexPipe,
    ReconciliacaoBancariaRelatorioComponent,
    RecibosAnuladosRelatorioComponent,
    ListagemDespesasUtilizadorComponent,
    ListagemRecibosUtilizadorComponent,
    SuiSelectHolderComponent,
    EditPayAgendamentoComponent,
    PropostasComponent,
    SafeHtmlPipe,
    CartaComunicacaoComponent,
    CartaCttAvisoRececaoComponent,
    CondominioContactsPdfComponent,
    ViewSentEmailModalComponent,
    CustosComunicacoesComponent,
    DeleteModalComponent,
    EditEntregaFaturaCttModalComponent,
    EntregasFaturasComponent,
    ListagemAnexosModalComponent,
    UnsavedChangesModalComponent,
    CartaHeaderComponent,
    OrcamentoPdfComponent,
    LoadingDotsComponent,
    ModalDefaultComponent,
    ViewReconciliacoesActivityModalComponent,
    UploadModalDefaultComponent,
    ReciboPdfComponent,
    EnviarRecibosEmailComponent,
    PrintRecibosPdfComponent,
    PaymentModalComponent,
    ContenciosoModalComponent,
    ConfissaoDividaPdfComponent,
    NoticePaymentsComponent,
    RegistoComunicacoesComponent,
    MapaQuotasPdfComponent,
    ContaCorrentePdfComponent,
    ConfigAvisosCobrancasComponent,
    ConfigAvisosCobrancaDetailsComponent,
    IbanPipe,
    ComunicarComponent,
    EditBancosAssembleiaRespComponent,
    SelectEntitiesModalComponent,
    CondominioEntidadesRegAtividadeModalComponent,
    ProcessamentoPdfComponent,
    AssembleiasPickQuotaExtraComponent,
    ProcessamentoDeleteRecibosComponent,
    ProcessamentoDuplicationModalComponent,
    AssembleiasPickEmailOficialComponent,
    AssembleiaOtSelectYearsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgProgressModule,
    FormsModule,
    TextFieldModule,
    ReactiveFormsModule,
    SuiModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      newestOnTop: true,
      autoDismiss: true,
      maxOpened: 0,
      timeOut: 8000
    }),
    PDFExportModule,
    InfiniteScrollModule,
    AutosizeModule,
    DragDropModule,
    ScrollingModule,
    InViewportModule,
    QuillModule.forRoot(),
  ],
  providers: [
    ApiService,
    UserSessionService,
    AuthGuardService,
    DeactivateGuardService,
    AppConfigService,
    MessageService,
    UtilitiesService,
    AppStateService,
    PreviousRouteService,
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    DatePipe,
    SendEmailsByCondominioComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

enableProdMode();
