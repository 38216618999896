import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { companyInfo, companyInfoBD } from './business-model-interfaces/application';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigRequestsService {

  constructor(
    public api: ApiService,
    public toastr: ToastrService,
    public appConfig: AppConfigService,
    public utils: UtilitiesService,
  ) { 
    this.getCompanyInfo();
  }


  fetchedCompanyInfo = false;
  loopGetCompanyInfoTimeout = 1000; //Each second
  getCompanyInfo() {
    if (this.fetchedCompanyInfo) return;
    this.api.getCompanyInfo().subscribe(res => {
      if (res && res.success) {
        let data:companyInfoBD = res.data;
        let phoneNumber = this.utils.insertStringAt(data.phone_number, ' ', 2);
        phoneNumber = this.utils.insertStringAt(phoneNumber, ' ', 6);
        phoneNumber = this.utils.insertStringAt(phoneNumber, ' ', 9);
        let company: companyInfo = {
          nome: data.name,
          morada: data.address,
          localidade: data.locality,
          codigoPostal: data.zip_code,
          tel: phoneNumber,
          email: data.email,
          emailFromName: data.name,
          website: data.website,
        }
        this.fetchedCompanyInfo = true;
        this.appConfig.updateCompanyInfo(company);
      } else {
        setTimeout(() => this.getCompanyInfo(), this.loopGetCompanyInfoTimeout); 
      }
    }, err => {
      setTimeout(() => this.getCompanyInfo(), this.loopGetCompanyInfoTimeout); 
    });
  }
}
