import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';


@Component({
  selector: 'app-utilizadores',
  templateUrl: './utilizadores.component.html',
  styleUrls: ['./utilizadores.component.scss']
})
export class UtilizadoresComponent implements OnInit {

  transitionController = new TransitionController();
  loadingModalForm = false;
  submittingModalForm = false;
  searchable = true;
  passwordNotEqual = false;

  // NEW UTILIZADOR FORM
  newForm = new FormGroup({
    first_name: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    last_name: new FormControl(null),
    title: new FormControl(null),
    username: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    password: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    password_conf: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
  });
  newFormSubmitted = false;
  @ViewChild('addEntRef', { static: false }) addEntRef;
  addModalRef = null;
  addModalConfig: any = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  // ORCAMENTOS TABLE VARIABLES
  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'title', name: 'Título', type: 'text', sort: null, searchable: false, centered: false },
    { key: 'username', name: 'Username', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'is_admin', name: 'Supervisor', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'super_admin', name: 'Administrador', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'is_blocked', name: 'Acesso Bloqueado', type: 'text', sort: null, searchable: false, centered: true },
  ];
  list: Array<any> = [];
  listLength: number = 0;
  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;
  page: number = 0;
  selectedPage: number = null;
  itemPerPage: number = 20;
  keyword: string = null;
  sortParam: string = null;
  sortDirection: string = null;
  searching: boolean = false;

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public router: Router,
              public api: ApiService,
              public appConfig: AppConfigService,
              public message: MessageService) { }

  ngOnInit() {
    this.animate();

    // GET INITAL DATA
    this.getList();
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngAfterViewInit() {
    // SEARCH INPUT
    fromEvent(this.tableSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'orcamentos');
    });

    this.addModalConfig = new TemplateModalConfig<IContext, string, string>(this.addEntRef);
    this.addModalConfig.closeResult = "closed";
    this.addModalConfig.size = 'small';
    this.addModalConfig.transition = 'fade up';
    this.addModalConfig.transitionDuration = 400;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => resolve(true))
      .onDeny(() => resolve(false));
    });
  }

  add() {
    this.addModalRef = this.modalService.open(this.addModalConfig);
  }

  del(toDelete) {
    this.api.delUtilizadores(toDelete).subscribe(res => {
      if (res.success) {
        this.list = this.list.filter(el => !el.checked);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  newSubmit() {
    this.newFormSubmitted = true;

    if (!this.newForm.valid) return;

    let data = this.newForm.getRawValue();

    if (data.password !== data.password_conf) {
      this.passwordNotEqual = true;
      return;
    }

    this.loadingModalForm = true;  
    this.api.addUtilizadores(data.username, data.password, data.first_name, data.last_name, data.title, this.appConfig.permissionAccessOpts[1], '09:00', '20:00', JSON.stringify([true,true,true,true,true,false,false])).subscribe(res => {
      if (res.success) {  
        if (this.list.length >= this.itemPerPage) this.list.pop();

        res.data['nome'] = res.data['first_name'] + ' ' + res.data['last_name'];
        res.data['checked'] = false;
        res.data['is_admin'] = (res.data['is_admin'] === '1');
        res.data['is_blocked'] = (res.data['is_blocked'] === '1');
        res.data['super_admin'] = (res.data['super_admin'] === '1');

        this.list = [res.data].concat(this.list);

        this.listLength ++;

        this.clearForm();
        this.addModalRef.approve();
        this.loadingModalForm = false;  
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loadingModalForm = false;  
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModalForm = false;  
    });
  }

  clearForm() {
    this.newForm.reset();
    this.newFormSubmitted = false;
    this.passwordNotEqual = false;
  }

  onPasswordChanged() {
    this.passwordNotEqual = false;
  }

  getList() {
    this.api.getUtilizadores(this.page, this.itemPerPage, this.keyword, this.sortParam, this.sortDirection, true).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        // CONVERT 1/0 TO true/false
        this.list = res.data.map(el => {
          el['nome'] = el['first_name'] + ' ' + el['last_name'];
          el['checked'] = false;
          el['is_admin'] = (el['is_admin'] === '1');
          el['super_admin'] = (el['super_admin'] === '1');
          el['is_blocked'] = (el['is_blocked'] === '1');

          return el;
        });
        this.listLength = res.total;
        this.searching = false;
      }
    }, err => {
      this.searching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  pageChange(ev) {
    this.page = ev;
    this.getList();
  }

  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'utilizadores':
        (ev.target.checked) ? this.list.map(el => el.checked = true ) : this.list.map(el => el.checked = false );
        break;
    }
  }

  async tableAction(action, targetList) {
    switch (targetList) {
      case 'utilizadores':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add': this.add(); break;
          case 'delete':
            let toDelete = this.list.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.del(toDelete);
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
    }
  }

  tableSearch(value, targetList) {
    switch (targetList) {
      case 'utilizadores':
        this.keyword = value.toLowerCase().trim();
        this.searching = true;

        this.getList();
        break;
    }
  }

  tableSort(key, targetList) {
    switch (targetList) {
      case 'utilizadores':

        this.listCol.forEach(el => {
          if (el.key === key) {
    
            if (el.sort === 'DESC') {
              el.sort = 'ASC';
            } else if (el.sort === 'ASC') {
              el.sort = 'DESC';
            } else if (el.sort === null) {
              el.sort = 'ASC';
            }

            this.sortParam = key;
            this.sortDirection = el.sort;
            this.getList();
          } else {
            el.sort = null;
          }
        });
        break;
    }
  }

  goToDetails(item) {
    this.router.navigate(['utilizadores/utilizador', item.id]);

    // Emit signal to breadcrumb component
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: item.nome });
  }

}
