import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';


import { UtilitiesService } from '../utilities.service';
import { custosComunicacoesListCorrespondencia } from '../business-model-interfaces/comunicacoes';
import { MessageService } from '../message.service';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { UserSessionService } from '../user-session.service';
interface IContext {
  data:string;
}

@Component({
  selector: 'app-custos-comunicacoes',
  templateUrl: './custos-comunicacoes.component.html',
  styleUrls: ['./custos-comunicacoes.component.scss']
})
export class CustosComunicacoesComponent implements OnInit {

  custosComunicacoesListCol = [ 
    { key: 'checked', name: 'checked', type:'checkbox', class:'table-checkbox-column'},
    { key: 'utilizador', name: 'Utilizador', type:'text', class:'col-centered col-4'},
    { key: 'data', name: 'Data', type:'date', class:'col-centered col-smd'},
    { key: 'condominio', name: 'Condomínio', type:'text', class:'col-align-left col-20'},
    { key: 'condomino', name: 'Condómino(s)', type:'text', class:'col-align-left col-25'},
    { key: 'descricao', name: 'Descrição', type:'number', class:'col-align-left'},
    { key: 'custo_ctt', name: 'Custo Ctt', type:'number', class:'col-align-right'},
    { key: 'custo_vertis', name: 'Custo Vertis', type:'number', class:'col-align-right'},
    { key: 'valor', name: 'Valor', type:'number', class:'col-align-right'},
  ]

  custosComunicacoesList: Array<custosComunicacoesListCorrespondencia> = [];
  custosComunicacoesListOrig: Array<custosComunicacoesListCorrespondencia> = [];

  tableChecked = false;
  toDelete = [];

  //Filters
  startDate:Date = null;
  endDate:Date = new Date();

  //Api
  noMoreData = false;
  apiSub = null;

  //Pagination
  page = 1;
  limit = 20;

  //Totals
  total = 0;
  totalCustoCtt = 0;
  totalCustoVertis = 0;


  @ViewChild('deleteModal', { static: false }) deleteModal: DeleteModalComponent;

  @ViewChild('alertRegComRef', { static: false }) alertRegComRef;
  alertRegComModalRef = null;
  alertRegComConfig: any = null;
  
  fetching = false;

  componentType = 'CUSTOS_COMUNICACOES';
  subsMsg = null;


  constructor(public modalService: SuiModalService,
              public message: MessageService,
              public api: ApiService,
              public router: Router,
              public toastr: ToastrService,
              public userSession: UserSessionService,
              public utils: UtilitiesService,
              public appConfig: AppConfigService,) { 
                this.subsMsg = this.message.getMessage().subscribe(async msg => {
                  if (msg.dest === this.componentType || msg.dest === 'ALL') {
                    switch (msg.cmd) {
                      case 'PERMISSIONS_UPDATED':
                        this.checkPermissions();
                    }
                  }
                });
              }

  ngOnInit() {
    this.checkPermissions();
  }

  ngAfterViewInit() {
    this.alertRegComConfig = new TemplateModalConfig<IContext, string, string>(this.alertRegComRef);
    this.alertRegComConfig.closeResult = "closed";
    this.alertRegComConfig.size = 'small';
    this.alertRegComConfig.transition = 'fade';
    this.alertRegComConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    if (this.subsMsg) this.subsMsg.unsubscribe();
  }

  cc_read = false;
  cc_delete = false;
  cc_deleteRegisto = false;
  cc_viewDetailsIndividual = false;
  cc_viewTotais = false;
  checkPermissions() {
    this.cc_read = this.userSession.checkUserAccess('CORRESPONDENCIA_CC', 'read');
    this.cc_delete = this.userSession.checkUserAccess('CORRESPONDENCIA_CC','delete');
    this.cc_deleteRegisto = this.userSession.checkUserAccess('CORRESPONDENCIA_CC_REMOVE_REGISTO_ASSOCIADO','allow');
    this.cc_viewDetailsIndividual = this.userSession.checkUserAccess('CORRESPONDENCIA_CC_VER_DETALHE_CUSTO_INDIVIDUAL','allow');
    this.cc_viewTotais = this.userSession.checkUserAccess('CORRESPONDENCIA_CC_VER_DETALHE_CUSTO_TOTAL','allow');
    if (this.cc_read && this.custosComunicacoesListOrig == [])
      this.getCustosComunicacoes();
  }
  getCustosComunicacoes(init=true) {
    if (!this.cc_read) return;

    if (init) {
      this.noMoreData = false;
      this.page = 1;
      this.total = 0;
      this.totalCustoCtt = 0;
      this.totalCustoVertis = 0;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    }

    if (this.fetching) return;

    this.fetching = true;
    this.apiSub = this.api.getCustosComunicacoes(null, this.page, this.limit, this.startDate, this.endDate).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.fetching = false;
        if (!res.data.custos.length) this.noMoreData = true;

        if (init) {
          this.custosComunicacoesListOrig = res.data.custos.map(el =>  {
            return {...el, checked: false, user_abrev: this.utils.getNomeUtilizadorIniciais(el.entregue_por_nome)};
          });
        } else {
          this.custosComunicacoesListOrig = this.custosComunicacoesListOrig.concat(res.data.custos.map(el =>  {
            return {...el, checked: false, user_abrev: this.utils.getNomeUtilizadorIniciais(el.entregue_por_nome)};
          }));
        }
  
        this.filterList();

        this.total = res.data.totals.total;
        this.totalCustoVertis = res.data.totals.totalCustoVertis;
        this.totalCustoCtt = res.data.totals.totalCustoCtt;

      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetching = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetching = false;
    });
  }

  filterList() {
    this.custosComunicacoesList = this.custosComunicacoesListOrig.slice();
    this.custosComunicacoesList.sort((a,b) => {
      return this.utils.compareDayDates(new Date(b.data), new Date(a.data));
    })
  }

  onScroll() {
    if (this.noMoreData || !this.apiSub.closed) return;
    this.page += 1;
    this.getCustosComunicacoes(false);
  }

  rowSelectionToggle() {
    this.custosComunicacoesListOrig.forEach(el => {
      el.checked = this.tableChecked;
    });
  }

  goToDetails(row: custosComunicacoesListCorrespondencia) {
    this.router.navigate(['lancamentos/despesa', row.id_despesa]);
  }

  async presentDeleteModal() {
    if (!this.cc_delete) {
      this.toastr.error(this.appConfig.errMsg.unAuth.msg,this.appConfig.errMsg.unAuth.title);
      return;
    }
    this.toDelete = this.custosComunicacoesList.filter(el => el.checked);
    if (this.toDelete.length > 0) {
      this.delCriterio = 'DESPESA';
      let res = await this.presentDeleteRegComAlert();
      if (!res) return;
      this.deleteModal.open();
    } else {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
    }
  }

  presentDeleteRegComAlert():Promise<boolean> {
    return new Promise((resolve) => {
      this.alertRegComModalRef = this.modalService
      .open(this.alertRegComConfig)
      .onApprove(() => { 
        resolve(true);
      })
      .onDeny(() => {
        resolve(false);
      });
    });
  }

  getColSpan():string {
    return this.cc_viewDetailsIndividual ? '9' : '7';
  }

  delCriterio:'DESPESA'|'BOTH' = 'DESPESA';
  delete = (): Promise<boolean> => {
    return new Promise(resolve => {
      this.api.delRegistoCtt(this.toDelete.filter(el => !el.tipo),'CUSTOS_COMUNICACOES', this.delCriterio).subscribe(res => {
        if (res.success) {
          this.getCustosComunicacoes(true);
        } else {
          this.utils.apiErrorMsg(res);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });
      resolve(true);
    });
  }
}
