import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { UserSessionService } from '../user-session.service';
import { UtilitiesService } from '../utilities.service';

interface ReciboDetails {
  n_recibo
  cond_nome
  cond_nif
  cond_morada_pdf
  pag_nif
  data
  dt_valor
  banco
  tipo_pagamento
  forma_tratamento
  pag_nome
  pag_morada_fact_pdf
  cond_morada
  pag_morada_fact
  pag_morada
  linhasRecibo: Array<linhaRecibo>
  obs
  val_cod
  criado_em
  valorTotal,
  isRemoved:boolean,
  removedObj: {
    how
    when
    obs
  },
  alterado_por
  alterado_em
  del_razao,
  active: '0'|'1',
  dateOK,
  utilizador
  first_name
  last_name
  conta
}

interface linhaRecibo {
  n_aviso
  data
  fraccao
  descricao
  valor
}

@Component({
  selector: 'app-recibo-pdf',
  templateUrl: './recibo-pdf.component.html',
  styleUrls: ['./recibo-pdf.component.scss']
})
export class ReciboPdfComponent implements OnInit {

  @ViewChild('pdf', { static: false }) pdfController: PDFExportComponent;

  
  details:ReciboDetails = null;

  fetchDone = false;

  constructor(
    public appConfig: AppConfigService,
    public userSession: UserSessionService,
    public utils: UtilitiesService,
    public api: ApiService,
    public toastr: ToastrService,
    public cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }


  async exportPDF(idRecibo) {
    let success = await this.getDetails(idRecibo);
    if (!success) return;
    this.cdRef.detectChanges();
    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';
    
    let filename = this.details.cond_nome.replace(/ /g, '_') + '_' + this.details.n_recibo + '_' + this.utils.getFormatedDate(new Date());
    filename = filename.replace(/,/g, '');
    
    this.pdfController.saveAs(filename + '.pdf');
  }

  getBase64(idRecibo): Promise<string> {
    return new Promise(async (resolve) => {
      let success = await this.getDetails(idRecibo);
      if (!success) {
        resolve(null);
        return;
      }
      this.cdRef.detectChanges();
      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');

        resolve(base64);
      }).catch(err => {
        resolve(null);
      });

    })
  }


  private getDetails(idRecibo): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.getRecibosDetails(idRecibo).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.details = res.data;
          
          this.details['nomeBanco'] = (res.data.banco) ? res.data.banco : res.data.conta;
  
          if (this.details.active === '0') {
            this.details.isRemoved = true;
            this.details.removedObj = {
              how: this.details.alterado_por,
              when: (this.details.alterado_em) ? new Date(this.details.alterado_em) : null,
              obs:this.details.del_razao,
            }
          }

          let condMoradaAux = this.details.cond_morada;
  
          this.details.pag_morada_fact = this.utils.formatEmptyMoradaObject(this.details.pag_morada_fact);
          this.details.pag_morada = this.utils.formatEmptyMoradaObject(this.details.pag_morada);
          if (this.details.pag_morada === '{"address":null,"postalCode":null,"locality":null}') this.details.pag_morada = null;
          let pagMoradaAux = (this.details.pag_morada_fact && this.details.pag_morada_fact !== '' && this.details.pag_morada_fact !== '{"address":null,"postalCode":null,"locality":null}') ? this.details.pag_morada_fact : this.details.pag_morada;
  
          // HANDLE OBJECT MORADA - CONDOMINIO
          try {
            let aux = '';
            let addressObj = JSON.parse(this.details.cond_morada);
            Object.keys(addressObj).forEach((key, i) => {
              if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
            });
  
            condMoradaAux = aux;
            this.details.cond_morada = (aux) ? aux.replace(/NEW_LINE/g, ', ') : null;
          } catch(e) {}
  
          // HANDLE OBJECT MORADA - CONDOMINIO
          try {
            let aux = '';
            let addressObj = JSON.parse(pagMoradaAux);
            Object.keys(addressObj).forEach((key, i) => {
              if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
            });
  
            pagMoradaAux = aux;
          } catch(e) {}
  
          this.details.cond_morada_pdf = (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [];
          this.details.pag_morada_fact_pdf = (pagMoradaAux) ? pagMoradaAux.split(/NEW_LINE/g) : [];
  
          this.details.cond_morada = (condMoradaAux) ? condMoradaAux.replace(/NEW_LINE/g, ', ') : null;
          this.details.pag_morada_fact = (pagMoradaAux) ? pagMoradaAux.replace(/NEW_LINE/g, ', ') : null;
  
          // CHECK IF DATE IS VALID
          if (this.details.criado_em) {
            let d = new Date(this.details.criado_em);
  
            if ( d instanceof Date && !isNaN(d.getTime()) ) {
              this.details.criado_em = new Date(this.details.criado_em);
            } else {
              this.details.dateOK = false;
            }
          } else {
            this.details.criado_em = null;
            this.details.dateOK = false;
          }
  
          this.details.data = (this.details.data) ? this.utils.getDate(this.details.data) : null;
          this.details.dt_valor = (this.details.dt_valor) ? this.utils.getDate(this.details.dt_valor) : null;
          this.details['diffDates'] = this.utils.compareDayDates(this.details.data, this.details.dt_valor) !== 0;


          this.details.banco = (this.details.banco) ? this.getPaymentType(this.details.banco) : null;
          this.details.utilizador = this.details.first_name + ' ' + this.details.last_name;
  
          this.details['tipo_pagamento'] = null;
          if (this.details.conta) {
            this.details['tipo_pagamento'] = (this.details.conta === 'CAIXA') ? 'N' : 'T';
          }
  
          this.details.valorTotal = 0;
          this.details.linhasRecibo = [];
          res.linhas_recibo.filter(el => ((el.n_aviso && el.data) || el.tipo_doc === 'C')).forEach(el => {
            let aux = {
              n_aviso: el.tipo_doc + ' - ' + el.n_aviso,
              data: (el.data) ? this.utils.getDate(el.data) : null,
              fraccao: (el.cod_fraccao && el.nome_fraccao) ? el.cod_fraccao + ' - ' + el.nome_fraccao : null,
              descricao: el.descricao,
              valor: (el.tipo_doc === 'C') ? -1 * Number(el.valor) : Number(el.valor),
            }
            this.details.linhasRecibo.push(aux);
  
            (el.tipo_doc === 'C') ? this.details.valorTotal -= Number(el.valor) : this.details.valorTotal += Number(el.valor);
          });
  
          this.fetchDone = true;
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }

  private getPaymentType(banc) {
    if (banc === 'CAIXA') {
      return 'Numerário';
    } else {
      return 'Transferência';
    }
  }

}
