import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceRegex'
})
export class ReplaceRegexPipe implements PipeTransform {

  escapeStr(str) {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  transform(value: any, ...args: any[]): any {
    return value.replace(new RegExp(this.escapeStr(args[0]), 'g'), args[1]);
  }

}
