import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})
export class RecibosService {

  constructor(public api: ApiService,
            public toastr: ToastrService,
            public utils: UtilitiesService,
            public appConfig: AppConfigService
            ) { }


  canChangeDataRecibo(id_recibo): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.canChangeDataRecibo(id_recibo).subscribe(res => {
        if (res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    })
  }

  changeDataRecibo(id_recibo:string, data:Date): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.changeDataRecibo(id_recibo, data).subscribe(res => {
        if (res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    });
  }
}
