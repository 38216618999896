import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { UserSessionService } from '../user-session.service';
import { AppConfigService } from '../app-config.service';
import { TipoCorreio } from '../business-model-interfaces/carta';
import { CommunicationsOrigin, RegistoCTTSaveDetailed, TipoEntidade } from '../business-model-interfaces/comunicacoes';
import { forkJoin } from 'rxjs';


export interface EntityDespesasCTTModalInput {
  cod:string,
  nomeEntidade: string,
  cod_condominio:string,
  condominioNome:string,
  cod_fraccao: string,
  nome_fraccao: string,
  tipo_entidade: TipoEntidade,
  base64FileArr:Array<string>,
  id_texto_predefinido: string,
  id_reconciliacao?:string,
  data_divida: Date
}
interface EntityList {
  codCondominio:string,
  nomeCondominio: string,
  codZona: string,
  codFraccao: string,
  permilagem: number,
  codRubrica: string,
  tipo_entidade: TipoEntidade,
  designacaoFraccao: string,
  descricao: string,
  condomino: string,
  cod_condomino: string,
  dataDespesa: Date,
  tipo: TipoCorreio,
  avisoRececao: boolean,
  nEnvelopes: number,
  nFolhasPorEnvelope: number,
  valorDespesa: number,
  custoVertis: number,
  custoCtt: number,
  checked: boolean,
  id_texto_predefinido:string,
  id_reconciliacao?: string,
  data_divida:string,
}

@Component({
  selector: 'app-despesas-ctt-modal',
  templateUrl: './despesas-ctt-modal.component.html',
  styleUrls: ['./despesas-ctt-modal.component.scss']
})
export class DespesasCttModalComponent implements OnInit {

  @Input('origin') origin:CommunicationsOrigin = 'ASSEMBLEIAS_COMUNICACOES';

  loadingModal = false;
  loadingModalPagar = false;

  idAssembleia = null;
  codCondominio = null;

  genDespesaCTT = false;
  despCttAllSelection = false;

  fraccoes = [];
  despesasCttList:Array<EntityList> = [];
  cartasCounter = 0;

  totalDespesas = {
    simples: null, simplesDisabled: true,
    normal: null, normalDisabled: true,
    registado: null, registadoDisabled: true,
    avisoRececao: null, avisoRececaoDisabled: true,
    totalComputedCtt: null,
    total: null,
  }

  submittingPaymentForm = false;
  dtPag = null;
  formPagam = null;
  tipoPagamento = null;
  contaOptsOrig = [];
  contaOpts = [];
  contaSelected = null;
  codContaBancaria = null;
  datePagEnabled = true;
  contaSaldo = null;

  
  @ViewChild('despesasCttAlertRef', { static: false }) despesasCttAlertRef;
  despesasCttModalRef = null;
  despesasCttAlertConfig: any = null;
  
  @ViewChild('pagamentoAlertRef', { static: false }) pagamentoAlertRef;
  pagamentoModalRef = null;
  pagamentoAlertConfig: any = null;

  //Multiple condominios
  multipleCondominios: boolean = false;
  multipleCondominiosData: Array<{cod_condominio, fraccoes}> = [];


  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public userSession: UserSessionService,
              public appConfig: AppConfigService,
              public api: ApiService) {
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.despesasCttAlertConfig = new TemplateModalConfig<IContext, string, string>(this.despesasCttAlertRef);
    this.despesasCttAlertConfig.isClosable = false;
    this.despesasCttAlertConfig.closeResult = 'closed';
    this.despesasCttAlertConfig.size = 'large';
    this.despesasCttAlertConfig.transition = 'fade';
    this.despesasCttAlertConfig.transitionDuration = 250;

    this.pagamentoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.pagamentoAlertRef);
    this.pagamentoAlertConfig.isClosable = false;
    this.pagamentoAlertConfig.closeResult = 'closed';
    this.pagamentoAlertConfig.size = 'small';
    this.pagamentoAlertConfig.transition = 'fade up';
    this.pagamentoAlertConfig.transitionDuration = 400;

    this.motivoSemDespesaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.motivoSemDespesaAlertRef);
    this.motivoSemDespesaAlertConfig.isClosable = false;
    this.motivoSemDespesaAlertConfig.closeResult = 'closed';
    this.motivoSemDespesaAlertConfig.size = 'small';
    this.motivoSemDespesaAlertConfig.transition = 'fade up';
    this.motivoSemDespesaAlertConfig.transitionDuration = 400;
  }

  modalOpened = false;
  criarDespesa = true;
  open(entities:Array<EntityDespesasCTTModalInput>, codCondominio, descricaoDespesa, base64Pdf, idAssembleia=null, anexos=[]): Promise<{ status?: 'OPENED', cttList?:Array<any>,id_registo_ctt?:Array<any>}> {
    return new Promise(async resolve => {
      if (this.modalOpened) {
        resolve({ status: 'OPENED' });
        return;
      }
      this.modalOpened = true;
      this.criarDespesa = true;

      this.multipleCondominios = codCondominio == null;

      this.fraccoes = [];
      this.idAssembleia = idAssembleia;
      this.codCondominio = codCondominio;
      try {
        await this.initDespesasCttList(entities, codCondominio, descricaoDespesa, base64Pdf, anexos);
      } catch (err) {
        this.utils.apiErrorMsg(err);
      }

      setTimeout(() => {
        this.despesasCttModalRef = this.modalService
          .open(this.despesasCttAlertConfig)
          .onApprove(() => {
            this.despCttAllSelection = false;
            this.genDespesaCTT = false;
            // this.genAndSendDocs();
            this.modalOpened = false;
            let toReturn = this.despesasCttList.map(el => {
              if (!el.checked) return null;
              if (el.avisoRececao) return 'REGISTADO_AR';
              return el.tipo;
            });
            resolve({cttList: toReturn, id_registo_ctt: this.despesasId.map(el => el.id_registo_ctt)});
          })
          .onDeny(() => { 
            this.despCttAllSelection = false;
            this.modalOpened = false;
            resolve(null);
          });
      }, 100);
    });
  }

  setTypeAll(type) {
    let avisoRececao = true;
    if (type === 'AVISO_RECECAO') {
      this.despesasCttList.forEach(el => el.tipo = 'REGISTADO');

      if (this.despesasCttList.filter(el => el.avisoRececao).length === this.despesasCttList.length) {
        avisoRececao = false;
      }
    }

    this.despesasCttList.forEach(el => {
      if (el.checked) {
        switch (type) {
          case 'SIMPLES': el.tipo = 'SIMPLES'; el.avisoRececao = false; break;
          case 'NORMAL': el.tipo = 'NORMAL'; el.avisoRececao = false; break;
          case 'REGISTADO': el.tipo = 'REGISTADO'; break;
          case 'AVISO_RECECAO': if (el.tipo === 'REGISTADO') el.avisoRececao = avisoRececao; break;
        }

        this.computeDespesaCtt(el);
      }
    });
  }

  computeDespesaCtt(item, computeTotals=true) {
    let cttObj = this.utils.computeCorrespValue(item.nEnvelopes, item.nFolhasPorEnvelope, item.tipo, item.avisoRececao);
    if (cttObj) {
      item.codRubrica = cttObj.codRubrica;
      item.valorDespesa = (Number(cttObj.valorDespesa)).toFixed(2);
      item.custoVertis = cttObj.custoVertis;
      item.custoCtt = cttObj.custoCtt;
    }
    if (computeTotals) this.computeTotals();
  }

  computeTotals(updateDespesas=true) {
    // COMPUTE TOTALS
    this.totalDespesas.totalComputedCtt = this.despesasCttList.filter(el => el.checked).map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);

    if (updateDespesas) {
      this.totalDespesas.simples = this.despesasCttList.filter(el => el.checked && el.tipo === 'SIMPLES').map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);
      this.totalDespesas.normal = this.despesasCttList.filter(el => el.checked && el.tipo === 'NORMAL').map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);
      this.totalDespesas.registado = this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao).map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);
      this.totalDespesas.avisoRececao = this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao).map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);

      this.totalDespesas.simples = (this.totalDespesas.simples) ? Number(this.totalDespesas.simples).toFixed(2) : null;
      this.totalDespesas.normal = (this.totalDespesas.normal) ? Number(this.totalDespesas.normal).toFixed(2) : null;
      this.totalDespesas.registado = (this.totalDespesas.registado) ? Number(this.totalDespesas.registado).toFixed(2) : null;
      this.totalDespesas.avisoRececao = (this.totalDespesas.avisoRececao) ? Number(this.totalDespesas.avisoRececao).toFixed(2) : null;
    }

    this.totalDespesas.total = Number(this.totalDespesas.simples) + Number(this.totalDespesas.normal) + Number(this.totalDespesas.registado) + Number(this.totalDespesas.avisoRececao);
    if (!this.totalDespesas.total) this.totalDespesas.total = this.totalDespesas.totalComputedCtt;

    // SET DISABLED FLAG
    this.totalDespesas.simplesDisabled = (this.despesasCttList.filter(el => el.checked && el.tipo === 'SIMPLES').length === 0);
    this.totalDespesas.normalDisabled = (this.despesasCttList.filter(el => el.checked && el.tipo === 'NORMAL').length === 0);
    this.totalDespesas.registadoDisabled = (this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao).length === 0);
    this.totalDespesas.avisoRececaoDisabled = (this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao).length === 0);
  }

  typeChanged(item, type=null) {
    if (type === 'AVISO_RECEPCAO' && item.avisoRececao) {
      item.tipo = 'REGISTADO';
    } else {
      if (item.tipo !== 'REGISTADO') item.avisoRececao = false;
    }

    this.computeDespesaCtt(item);
    this.setCartasCounter();
  }

  setCartasCounter() {
    let simplesCounter = (this.despesasCttList.filter(el => el.checked && el.tipo === 'SIMPLES').length > 0) ? 1 : 0;
    let normalCounter = (this.despesasCttList.filter(el => el.checked && el.tipo === 'NORMAL').length > 0) ? 1 : 0;
    let registadoCounter = (this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao).length > 0) ? 1 : 0;
    let avisoRececaoCounter = (this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao).length > 0) ? 1 : 0;

    this.cartasCounter = simplesCounter + normalCounter + registadoCounter + avisoRececaoCounter;
  }

  async genDespesasCtt(target=null, pagar=false) {
    // TODO: IMPLEMENT VALIDATORS (AT LEAST ONE ROW SELECTED, TOTAL DISABLED => VALUE > 0)
    if (!this.despesasCttList.find(el => el.checked)) {
      this.toastr.error('Não é possível lançar despesas Ctt. Por favor, selecione pelo menos um condómino.', 'Alerta', { timeOut: 4000 });      
      return;
    }
    if (this.criarDespesa && (this.despesasCttList.find(el => el.checked && !el.dataDespesa) || (!this.totalDespesas.simplesDisabled && !this.totalDespesas.simples) ||  (!this.totalDespesas.normalDisabled && !this.totalDespesas.normal) || (!this.totalDespesas.registadoDisabled && !this.totalDespesas.registado) || (!this.totalDespesas.avisoRececaoDisabled && !this.totalDespesas.avisoRececao))) {
      this.toastr.error('Não é possível lançar despesas Ctt. Por favor, verifique todos os campos assinalados.', 'Alerta', { timeOut: 4000 });      
      return;
    }

    if (this.multipleCondominios) {
      //Automatically chooses CAIXA
      let contaSelected = this.contaOptsOrig.find(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      if (contaSelected) this.codContaBancaria = contaSelected['value']; 
      try {
        let res: any = await this.genDespesasCttMultipleConds(target === 'LANCAR_PAGAR');
        if (res) {
          this.loadingModal = false;
          this.loadingModalPagar = false;
          this.despesasCttModalRef.approve();
        } else {
          this.loadingModal = false;
          this.loadingModalPagar = false;
        }
      } catch (err) {
        this.utils.apiErrorMsg(err);
      }
      return;
    }

    if (target === 'LANCAR_PAGAR') {
      this.submittingPaymentForm = false;

      let max = Math.max.apply(null, this.despesasCttList.filter(el => el.checked && el.dataDespesa).map(el => el.dataDespesa));
      this.dtPag = (max) ? new Date(max) : null;

      this.formPagam = '1';
      this.tipoPagamento = ' -N';
      let contaSelected = this.contaOptsOrig.find(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      if (contaSelected) this.contaSelected = contaSelected['value']; 
      setTimeout(() => { this.paymentOptChanged(); }, 1);

      this.pagamentoModalRef = this.modalService
        .open(this.pagamentoAlertConfig)
        .onApprove(() => { 
          this.genDespesasCtt(null, true); 
        })
        .onDeny(() => { });
        return;
    } else {

      let ePagaMsg = (pagar) ? ' e Liquidada' : '';

      // SAVE ALL DESPESAS CTT (ONE API CALL) AND SAVE REGISTO DE ACTIVIDADE --
      let despesas = [];
      let despesa = null;

      let registosCtt = [];
      let proprietarios = [];

      let linhasDespesas = [];
      this.fraccoes.forEach(el => {
        if (!linhasDespesas.find(it => it.codZona === el.zona_cod)) {
          linhasDespesas.push({
            codZona: el.zona_cod,
            permilagem: this.fraccoes.filter(item => item.codZona === el.codZona).map(item => Number(item.permilagem)).reduce((a, b) => a + b, 0),
            valor: null, 
          });
        }
      });

      // DESPESAS SIMPLES
      if (!this.totalDespesas.simplesDisabled) {
        despesa = this.despesasCttList.find(el => el.checked && el.tipo === 'SIMPLES');

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(this.totalDespesas.simples) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesa.descricao,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(this.totalDespesas.simples),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ ePagaMsg }. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${this.totalDespesas.simples} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dtPag) ? this.utils.getFormatedDate(new Date(this.dtPag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.codContaBancaria,

          //Dados Comunicação
          base64File: null
        });

        // REGISTO CTT ----------------
        proprietarios = this.despesasCttList.filter(el => el.checked && el.tipo === 'SIMPLES');
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '1',
          correiro_normal: '0',
          registado: '0',
          aviso_recepcao: '0',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesa.descricao,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,
          
          valor_lancado: Number(this.totalDespesas.simples),
        });
      }

      // DESPESAS NORMAL
      if (!this.totalDespesas.normalDisabled) {
        despesa = this.despesasCttList.find(el => el.checked && el.tipo === 'NORMAL');

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(this.totalDespesas.normal) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesa.descricao,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(this.totalDespesas.normal),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ ePagaMsg }. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${this.totalDespesas.normal} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dtPag) ? this.utils.getFormatedDate(new Date(this.dtPag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.codContaBancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = this.despesasCttList.filter(el => el.checked && el.tipo === 'NORMAL');
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '0',
          correiro_normal: '1',
          registado: '0',
          aviso_recepcao: '0',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesa.descricao,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,
          
          valor_lancado: Number(this.totalDespesas.normal),
        });
      }

      // DESPESAS REGISTADO
      if (!this.totalDespesas.registadoDisabled) {
        despesa = this.despesasCttList.find(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao);

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(this.totalDespesas.registado) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesa.descricao,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(this.totalDespesas.registado),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ ePagaMsg }. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${this.totalDespesas.registado} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dtPag) ? this.utils.getFormatedDate(new Date(this.dtPag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.codContaBancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao);
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '0',
          correiro_normal: '0',
          registado: '1',
          aviso_recepcao: '0',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesa.descricao,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,
          
          valor_lancado: Number(this.totalDespesas.registado),
        });
      }

      // DESPESAS REGISTADO / AVISO RECECAO
      if (!this.totalDespesas.avisoRececaoDisabled) {
        despesa = this.despesasCttList.find(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao);

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(this.totalDespesas.avisoRececao) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesa.descricao,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(this.totalDespesas.avisoRececao),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ ePagaMsg }. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${this.totalDespesas.avisoRececao} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dtPag) ? this.utils.getFormatedDate(new Date(this.dtPag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.codContaBancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = this.despesasCttList.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao);
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '0',
          correiro_normal: '0',
          registado: '1',
          aviso_recepcao: '1',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesa.descricao,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,
          
          valor_lancado: Number(this.totalDespesas.avisoRececao),
        });
      }

      try {
        let res: any = await this.createDespesasCtt(this.idAssembleia, despesas, registosCtt, this.idAssembleia? 'ASSEMBLEIAS' : null, pagar);
        if (res) {
          this.loadingModal = false;
          this.loadingModalPagar = false;
          this.despesasCttModalRef.approve();
        } else {
          this.loadingModal = false;
          this.loadingModalPagar = false;
        }
      } catch (err) {
        this.utils.apiErrorMsg(err);
      }
    }
  }

  /*
    @Requires that despesasCttList is ordered by condominios
  */
  async genDespesasCttMultipleConds(pagar=null) {

    let ePagaMsg = (pagar) ? ' e Liquidada' : '';

    // SAVE ALL DESPESAS CTT (ONE API CALL) AND SAVE REGISTO DE ACTIVIDADE --
    let despesas = [];


    let registosCtt = [];
    let proprietarios = [];

    let despesasByCondominio:Array<{codCondominio, entidades:Array<any>}> = (this.despesasCttList as Array<any>).reduce((newArr, el) => {
      if (!newArr || !newArr.length || el.codCondominio != newArr[newArr.length - 1].codCondominio) {
        let entidades = [el];
        newArr.push({codCondominio: el.codCondominio, entidades: entidades });
        return newArr;
      }
      newArr[newArr.length - 1].entidades.push(el);
      return newArr;
    },[] as Array<{codCondominio, entidades:Array<any>}>);
    

    despesasByCondominio.forEach(condominio => {
      let despesa = null;
      
      let totalSimples = null;
      let totalNormal = null;
      let totalRegistado = null;
      let totalAvisoRececao = null;

      totalSimples = condominio.entidades.filter(el => el.checked && el.tipo === 'SIMPLES').map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);
      totalNormal = condominio.entidades.filter(el => el.checked && el.tipo === 'NORMAL').map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);
      totalRegistado = condominio.entidades.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao).map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);
      totalAvisoRececao = condominio.entidades.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao).map(el => Number(el.valorDespesa)).reduce((a, b) => a + b, 0);

      totalSimples = (totalSimples) ? Number(totalSimples).toFixed(2) : null;
      totalNormal = (totalNormal) ? Number(totalNormal).toFixed(2) : null;
      totalRegistado = (totalRegistado) ? Number(totalRegistado).toFixed(2) : null;
      totalAvisoRececao = (totalAvisoRececao) ? Number(totalAvisoRececao).toFixed(2) : null;


      let simplesDisabled = (condominio.entidades.filter(el => el.checked && el.tipo === 'SIMPLES').length === 0);
      let normalDisabled = (condominio.entidades.filter(el => el.checked && el.tipo === 'NORMAL').length === 0);
      let registadoDisabled = (condominio.entidades.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao).length === 0);
      let avisoRececaoDisabled = (condominio.entidades.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao).length === 0);

      let linhasDespesas:Array<{codZona, permilagem, valor}> = [];
      let fraccoes = this.multipleCondominiosData.find(el => el.cod_condominio == condominio.codCondominio).fraccoes;
      fraccoes.forEach(el => {
        if (!linhasDespesas.find(it => it.codZona === el.zona_cod)) {
          linhasDespesas.push({
            codZona: el.zona_cod,
            permilagem: this.fraccoes.filter(item => item.codZona === el.zona_cod).map(item => Number(item.permilagem)).reduce((a, b) => a + b, 0),
            valor: null, 
          });
        }
      });

      // DESPESAS SIMPLES DONE
      if (!simplesDisabled) {
        despesa = condominio.entidades.find(el => el.checked && el.tipo === 'SIMPLES');
        if (!despesa) return;
  
        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(totalSimples) * el.permilagem / 1000) * 100) / 100;
        });
  
        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: condominio.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesa.descricao,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(totalSimples),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ ePagaMsg }. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${totalSimples} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),
  
          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dtPag) ? this.utils.getFormatedDate(new Date(this.dtPag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.codContaBancaria,
        });
  
        // REGISTO CTT ----------------
        proprietarios = condominio.entidades.filter(el => el.checked && el.tipo === 'SIMPLES');
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '1',
          correiro_normal: '0',
          registado: '0',
          aviso_recepcao: '0',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesa.descricao,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,
  
          pag_ctt: null,
          recibo_ctt: null,
          
          valor_lancado: Number(totalSimples),
        });
      }

      // DESPESAS NORMAL DONE
      if (!normalDisabled) {
        despesa = condominio.entidades.find(el => el.checked && el.tipo === 'NORMAL');

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(totalNormal) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesa.descricao,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(totalNormal),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ ePagaMsg }. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${totalNormal} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dtPag) ? this.utils.getFormatedDate(new Date(this.dtPag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.codContaBancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = condominio.entidades.filter(el => el.checked && el.tipo === 'NORMAL');
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '0',
          correiro_normal: '1',
          registado: '0',
          aviso_recepcao: '0',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesa.descricao,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,
          
          valor_lancado: Number(totalNormal),
        });
      }

      // DESPESAS REGISTADO
      if (!registadoDisabled) {
        despesa = condominio.entidades.find(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao);

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(totalRegistado) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesa.descricao,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(totalRegistado),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ ePagaMsg }. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${totalRegistado} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dtPag) ? this.utils.getFormatedDate(new Date(this.dtPag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.codContaBancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = condominio.entidades.filter(el => el.checked && el.tipo === 'REGISTADO' && !el.avisoRececao);
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '0',
          correiro_normal: '0',
          registado: '1',
          aviso_recepcao: '0',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesa.descricao,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,
          
          valor_lancado: Number(totalRegistado),
        });
      }

      // DESPESAS REGISTADO / AVISO RECECAO
      if (!avisoRececaoDisabled) {
        despesa = condominio.entidades.find(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao);

        // COMPUTE LINHAS DE DESPESA (PROPORCIONAL)
        linhasDespesas.forEach(el => {
          el.valor = Math.round((Number(totalAvisoRececao) * el.permilagem / 1000) * 100) / 100;
        });

        // DESPESAS -------------------
        despesas.push({
          // DADOS LANCAMENTO
          cod_condominio: despesa.codCondominio,
          cod_fornecedor: this.getCodFornecedorCtt(despesa.codRubrica),
          descricao: despesa.descricao,
          cod_rubrica: despesa.codRubrica,
          dt_desp: this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy'),
          exercicio: despesa.dataDespesa.getFullYear(),
          n_receita: '0',
          orcamentada: '0',
          reparticao: 'P',
          tipo_despesa: 'CTT',
          tipo_proc: 'E',
          valor: Number(totalAvisoRececao),
          linhas_despesa: JSON.parse(JSON.stringify(linhasDespesas)),
          obj: JSON.stringify([{ msg: `Despesa lançada${ ePagaMsg }. Data de lançamento: ${this.utils.getFormatedDate(despesa.dataDespesa, 'dd-MM-yyyy')}, Valor: ${totalAvisoRececao} €.`, activity: 'Criado por ', user: this.userSession.getUserFullName(), date: new Date() }]),

          // DADOS PAGAMENTO
          tipo_pagamento: this.tipoPagamento,
          dt_pag: (this.dtPag) ? this.utils.getFormatedDate(new Date(this.dtPag), 'dd-MM-yyyy') : null,
          cod_conta_bancaria: this.codContaBancaria,
        });

        // REGISTO CTT ----------------
        proprietarios = condominio.entidades.filter(el => el.checked && el.tipo === 'REGISTADO' && el.avisoRececao);
        registosCtt.push({
          cod_condominio: despesa.codCondominio,
          conta_simples: '0',
          correiro_normal: '0',
          registado: '1',
          aviso_recepcao: '1',
          data: new Date(),
          data_despesa: despesa.dataDespesa,
          descricao: despesa.descricao,
          id_user: this.userSession.getUserId(),
          nome_condomino: (proprietarios.map(el => el.condomino).reduce((a, b) => a + b + ', ', '')).slice(0, -2),
          condominos_obj: JSON.stringify({ selectedEntry: 'MANUAL', condList: proprietarios.map(el => el.cod_condomino), descricao: null }),
          destinatarios: proprietarios,
          n_envelope: proprietarios.map(el => el.nEnvelopes).reduce((a, b) => a + b, 0),
          n_folhas_env: proprietarios[0]['nFolhasPorEnvelope'],
          custo_ctt: Math.round(proprietarios.map(el => el.custoCtt).reduce((a, b) => a + b, 0) * 100) / 100,
          custo_vertis: Math.round(proprietarios.map(el => el.custoVertis).reduce((a, b) => a + b, 0) * 100) / 100,

          pag_ctt: null,
          recibo_ctt: null,
          
          valor_lancado: Number(totalAvisoRececao),
        });
      }
    });

    try {
      let res :any = await this.createDespesasCtt(null,despesas, registosCtt, 'CORRESPONDENCIA', pagar);
      if (res) {
        this.loadingModal = false;
        this.loadingModalPagar = false;
        this.despesasCttModalRef.approve();
      } else {
        this.loadingModal = false;
        this.loadingModalPagar = false;
      }
    } catch (err) {
      this.utils.apiErrorMsg(err);
    }
  }

  despesasId:Array<{id_despesa, n_despesa, cod_despesa, id_registo_ctt}> = [];
  createDespesasCtt(idAssembleia, despesas, registosCtt: Array<{aviso_recepcao, cod_condominio, condominos_obj, conta_simples, correiro_normal, custo_ctt, custo_vertis, data, data_despesa, descricao, destinatarios: Array<EntityList>, id_user, n_envelope, n_folhas_env, nome_condomino, pag_ctt, recibo_ctt, registado, valor_lancado}>, origem, pagar=false) {
    return new Promise(async (resolve) => {
      let value = null;
      
      switch (this.origin) {
        case 'ASSEMBLEIAS_COMUNICACOES':
          value = 'DESPESAS';
          break;
        case 'CORRESPONDENCIA_AC':
          value = 'CORRESPONDENCIA_AC';
          break;
        case 'CORRESPONDENCIA_COM':
          value = 'CORRESPONDENCIA_C';
          break;
        default:
          break;
      }
      if (!this.criarDespesa) {

        try {
          var motivoSemDespesa = await this.askForMotivoSemDespesa()
        } catch (err) {
          resolve(false);
          return;
        }

        let registosCTT: Array<RegistoCTTSaveDetailed> = [];
        registosCtt.forEach(el => {
          registosCTT.push({
            cod_condominio: el.cod_condominio,
            conta_simples: el.conta_simples,
            correiro_normal: el.correiro_normal,
            registado: el.registado,
            aviso_recepcao: el.aviso_recepcao,
            data: el.data,
            data_despesa: null,
            id_user: el.id_user,
            nome_condomino: el.nome_condomino,
            condominos_obj: el.condominos_obj,
            n_envelope: el.n_envelope,
            n_folhas_env: el.n_folhas_env,
            custo_ctt: el.custo_ctt,
            custo_vertis: el.custo_vertis,
            pag_ctt: el.pag_ctt,
            recibo_ctt: el.recibo_ctt,
            valor_lancado: null,
            descricao: el.descricao,
            idDespesa: null,
            nDespesa: null,
            motivo_sem_despesa: motivoSemDespesa,
            destinatarios: el.destinatarios.map(dest => {
              let aux = {
                codCondominio: dest.codCondominio,
                codFraccao: dest.codFraccao,
                cod_condomino: dest.cod_condomino,
                tipo_entidade: dest.tipo_entidade,
                id_texto_predefinido: dest.id_texto_predefinido,
                data_divida: dest.data_divida,
              }
              if (dest.hasOwnProperty('id_reconciliacao')) {
                aux['id_reconciliacao'] = dest.id_reconciliacao;
              }
              return aux;
            })
          });
        });

        
        (pagar) ? this.loadingModalPagar = true : this.loadingModal = true;
        this.api.saveRegistoCTTDetailed(registosCTT).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.despesasId = res.data.map(id_registo_ctt => {
              return {
                id_despesa: null,
                n_despesa: null,
                cod_despesa: null,
                id_registo_ctt: id_registo_ctt,
              }
            })
            resolve(true);
          } else {
            this.utils.apiErrorMsg(res);
            resolve(false);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          resolve(false);
        })
        return;
      }
      
      (pagar) ? this.loadingModalPagar = true : this.loadingModal = true;
      this.api.saveAssembleiaDespesasCtt(idAssembleia, despesas, registosCtt, origem, pagar, value).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {

          // // REGISTO DE ACTIVIDADE LOCAL
          // let regCtt = [];
          // registosCtt.forEach((el, i) => {
          //   let aux = {
          //     nome_condomino: el.nome_condomino,
          //     n_envelope: el.n_envelope,
          //     n_folhas_env: el.n_folhas_env,
          //     valor_lancado: el.valor_lancado,
          //     id_despesa: Number(res.data[i].id_despesa),
          //     n_despesa: Number(res.data[i].n_despesa),
          //     cod_despesa: Number(res.data[i].cod_despesa),
          //   }

          //   aux['tipo'] = null;
          //   if (el.conta_simples === '1') {
          //     aux['tipo'] = 'SIMPLES';
          //   } else if (el.correiro_normal === '1') {
          //     aux['tipo'] = 'NORMAL';
          //   } else if (el.registado === '1' && el.aviso_recepcao === '0') {
          //     aux['tipo'] = 'REGISTADO';
          //   } else if (el.registado === '1' && el.aviso_recepcao === '1') {
          //     aux['tipo'] = 'AVISO_RECECAO';
          //   }

          //   regCtt.push(aux);
          // });

          // let description = (!pagar) ? 'Despesa(s) Ctt Lançada(s) - Convocatória' : 'Despesa(s) Ctt Lançada(s) e Paga(s) - Convocatória';
          // this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', this.details.id_assembleia, description, JSON.stringify(regCtt), this.userSession.getUserId(), new Date(), null).subscribe(res => {
          //   if (res.hasOwnProperty('success') && res.success) {

          //     this.regEmails = [{
          //       description: description,
          //       emailList: regCtt,
          //       name: this.userSession.getUserFullName(),
          //       date: new Date(),
          //       msg: null,
          //     }].concat(this.regEmails);
          //     this.hasRegEmails = (this.regEmails.length > 0);

          //     resolve(true);
          //     this.toastr.success('Foram lançadas ' + ((pagar) ? ' e dadas como pagas ' : '') + despesas.length + ' despesas Ctt.', 'Despesas Ctt', { timeOut: 4000 });      
          //   } else {
          //     resolve(false);
          //   }
          // }, err => { resolve(false); });

          this.despesasId = res.data;
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      },
      err => {
        resolve(false);
      });
    });
  }

  @ViewChild('motivoSemDespesaAlertRef', { static: false }) motivoSemDespesaAlertRef;
  motivoSemDespesaModalRef = null;
  motivoSemDespesaAlertConfig: any = null;

  submittingMotivo = {
    show: false,
    timeout: null
  };
  motivoSemDespesa: string = null;
  askForMotivoSemDespesa(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.motivoSemDespesaModalRef = this.modalService
      .open(this.motivoSemDespesaAlertConfig)
      .onApprove((motivo: string) => {
        resolve(motivo);
      })
      .onDeny(() => { 
        reject(null);
      });
    })
  }

  approveMotivoSemDespesa() {
    this.utils.setFormFeedback(this.submittingMotivo);
    if (!this.motivoSemDespesa || this.motivoSemDespesa.trim() === '') return;
    this.motivoSemDespesaModalRef.approve(this.motivoSemDespesa.trim());
  }

  getCodFornecedorCtt(codRubrica) {
    switch (codRubrica) {
      case '98':  // Correio Normal
        return '16';
      case '42':  // Correio Registado
        return '16';
      case '47':  // Correio Simples
        return '9';
    }
  }

  getFraccao(entity): string {
    let fraccao = this.fraccoes.find(f => f.cod_proprietario == entity.cod);
    return fraccao && fraccao.cod && fraccao.nome ? fraccao.cod + ' - ' + fraccao.nome : '--';
  }

  async initDespesasCttList(entities:Array<EntityDespesasCTTModalInput>, codCondominio, descricaoDespesa, base64PdfFile, anexos:Array<{ext,filename,base64}>) {
    // GENERATE DESPESAS CTT TABLE
    this.despesasCttList = [];
    try {
      if (this.multipleCondominios) {
        let differentCondominios = [...new Set(entities.map(el => el.cod_condominio))];
        let req = differentCondominios.map(cond => this.getCondominioZonasFraccoes(cond));
        let res = await Promise.all(req);
        res.forEach((el,i) => {
          this.multipleCondominiosData.push({cod_condominio: differentCondominios[i], fraccoes: el});
        });
      } else {
        let fraccoes: any = await this.getCondominioZonasFraccoes(codCondominio);
        if (!fraccoes) return;
        this.fraccoes = fraccoes;
      }
    } catch (err) {
      this.utils.apiErrorMsg(err);
    }

    entities.forEach(el => {
      let nome = el.nomeEntidade;
      let cod = el.cod;

      let cod_condominio = this.multipleCondominios? el.cod_condominio : codCondominio ;
      let condominioNome = this.multipleCondominios? el.condominioNome :  '';
      let designacaoFraccao = el.cod_fraccao && el.nome_fraccao ? el.cod_fraccao + ' - ' + el.nome_fraccao : this.getFraccao(el);

      let nFolhasPorEnvelope = 0;
      if (el.base64FileArr && Array.isArray(el.base64FileArr) && el.base64FileArr.length) {
        el.base64FileArr.forEach(base64File => {
          nFolhasPorEnvelope += this.getPdfPageCount(base64File);
        });
      } else {
        nFolhasPorEnvelope = this.getPdfPageCount(base64PdfFile);
      }

      if (anexos) {
        anexos.forEach(anexo => {
          nFolhasPorEnvelope += this.getPdfPageCount(anexo.base64);
        });
      }
      let aux: EntityList = {
        codCondominio: cod_condominio,
        nomeCondominio: condominioNome,
        codZona: null,
        codFraccao: el.cod_fraccao,
        permilagem: null,
        codRubrica: null,
        tipo_entidade: el.hasOwnProperty('tipo')? el['tipo'] : 'PROPRIETARIO',

        designacaoFraccao: designacaoFraccao,
        descricao: descricaoDespesa,
        condomino: nome,
        cod_condomino: cod,
        dataDespesa: new Date(),
        tipo: 'NORMAL',
        avisoRececao: false,

        nEnvelopes: 1,
        nFolhasPorEnvelope: nFolhasPorEnvelope,

        valorDespesa: null,
        custoVertis: null,
        custoCtt: null,

        id_texto_predefinido: el.id_texto_predefinido,
        data_divida: el.data_divida ? this.utils.getFormatedDate(el.data_divida): null,

        checked: true,
      }

      if (el.id_reconciliacao) aux.id_reconciliacao = el.id_reconciliacao;

      this.computeDespesaCtt(aux, false);

      this.despesasCttList.push(aux);
    });

    this.setCartasCounter();
    this.totalDespesas = {
      simples: null, simplesDisabled: true,
      normal: null, normalDisabled: true,
      registado: null, registadoDisabled: true,
      avisoRececao: null, avisoRececaoDisabled: true,
      totalComputedCtt: null,
      total: null,
    }
    this.computeTotals();

    if (this.despesasCttList.length > 0 && !this.multipleCondominios) {
      this.getPaymentData(codCondominio).then(_ => {}).catch(err => this.utils.apiErrorMsg(err));
    }
  }

  rowSelectionToggle($event) {
    this.despesasCttList.forEach(el => el.checked = $event.target.checked);
  }

  getPdfPageCount(pdfBase64) {
    return (pdfBase64 && Array.isArray(atob(pdfBase64).match(/\/Type[\s]*\/Page[^s]/g))) ? atob(pdfBase64).match(/\/Type[\s]*\/Page[^s]/g).length : 0;
  }

  getCondominioZonasFraccoes(codCondominio) {
    return new Promise(resolve => {
      this.api.getCondFraccoes(codCondominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success)  {
          resolve(res.data);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
  }

  setPayment() {
    this.submittingPaymentForm = true;
    if (!this.formPagam || !this.dtPag || !this.contaSelected) {
      this.toastr.error('Formulário de pagamento incompleto. Por favor, verifique todos os campos assinalados.', 'Alerta', { timeOut: 4000 });      
      return;
    }

    this.pagamentoModalRef.approve();
  }

  contaOptChanged() {
    this.codContaBancaria = this.contaSelected;
    let conta = this.contaOptsOrig.find(conta => conta.value == this.contaSelected);
    this.contaSaldo = conta && conta.saldo ? conta.saldo : null;
  }


  paymentOptChanged() {
    if (this.formPagam === null) return;

    if (this.formPagam === '1' || this.formPagam === '6') {
      if (this.formPagam === '1') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      }

      if (this.formPagam === '6') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name.indexOf('CX ADM') !== -1 || el.name.indexOf('CAIXA ADM') !== -1));
      }

      this.datePagEnabled = false;

      if (this.contaOpts.length === 1) {
        this.codContaBancaria = this.contaOpts[0].value;
        this.contaSaldo = this.contaOpts[0].saldo;
      } else {
        this.codContaBancaria = null;
        this.contaSaldo = null;
      }

      this.tipoPagamento = ' -N';
    } else {
      this.contaOpts = this.contaOptsOrig.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1));
      this.datePagEnabled = true;

      if (this.formPagam === '2') {
        let aux = this.contaOpts.find(el => (el.details.conta_principal === '1'));
        this.codContaBancaria = (aux) ? aux.value : null;
      } else {
        this.codContaBancaria = null; 
      }
      this.contaSaldo = null;
      this.tipoPagamento = ' -T';
    }
  }

  getPaymentData(codCondominio) {
    return new Promise(resolve => {
        this.api.getCondContasDetails(codCondominio).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.contaOptsOrig =  res.data.map(el => {
              return { name: el.banco, value: el.cod, conta_principal: el.conta_principaal, saldo: Number(el.saldo), details: el };
            });
            this.contaOpts = JSON.parse(JSON.stringify(this.contaOptsOrig));
            resolve(true);
          } else {
            resolve(false);
          }
        }, err => {
          resolve(false);
        });
    });
  }

  getPrettyTipo(tipo: string):string {
    if (!tipo) return null;
    return tipo.charAt(0).toUpperCase() + tipo.substring(1).toLowerCase();
  }

}
