import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserSessionService } from './user-session.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public userSession: UserSessionService, public router: Router, public message: MessageService) { }

  // canDeactivate(component: AssembleiasDetailsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {

  //   if (component.warnBeforeLeave()) {
  //     this.message.sendMessage({ dest: 'ASSEMBLEIA', cmd: 'UNSAVED_ALERT', url: nextState.url });
  //     return false
  //   }

  //   return true;
  // }

  canDeactivate(component, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    let dest = component.componentType;
    let extras = this.router.getCurrentNavigation().extras;
    if (typeof(component.warnBeforeLeave) === 'function' && component.warnBeforeLeave()) {
      this.message.sendMessage({ dest: dest, cmd: 'UNSAVED_ALERT', url: nextState.url, extras: extras });
      return false
    }

    if (component.hasOwnProperty('sendingEmails') && component['sendingEmails']) {
      this.message.sendMessage({ dest: dest, cmd: 'SENDING_EMAILS', url: nextState.url, extras: extras });
      return false
    }

    return true;
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const redirectUrl = route['_routerState']['url'];

    if (this.userSession.isLogged()) {
      return true;
    }

    this.router.navigateByUrl(
      this.router.createUrlTree(
        ['/login'], {
          queryParams: {
            redirectUrl
          }
        }
      )
    );

    return false;
  }
}
