import { Component, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { IContext } from '../business-model-interfaces/application';
import { SelAno } from '../business-model-interfaces/assembleias';

export interface SelAnoOpt extends SelAno {
  checked: boolean
}

@Component({
  selector: 'app-assembleia-ot-select-years',
  templateUrl: './assembleia-ot-select-years.component.html',
  styleUrls: ['./assembleia-ot-select-years.component.scss']
})
export class AssembleiaOtSelectYearsComponent implements OnInit {

  
  @ViewChild('alertRef', { static: false }) alertRef;
  modalRef = null;
  alertConfig: any = null;

  selAnosOpts: SelAnoOpt[] = [];
  selAnosAllChecked = false;
  showPeriodo = false;

  constructor(
    public modalService: SuiModalService,
    public toastr: ToastrService,
    public appConfig: AppConfigService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.alertConfig = new TemplateModalConfig<IContext, string, string>(this.alertRef);
    this.alertConfig.closeResult = "closed";
    this.alertConfig.isClosable = false;
    this.alertConfig.size = 'mini';
    this.alertConfig.transition = 'fade';
    this.alertConfig.transitionDuration = 250;
  }
  

  ngOnDestroy() {
    if (this.modalRef) this.modalRef.deny();
  }


  open(selAnos: SelAnoOpt[], showPeriodo:boolean): Promise<SelAno[]> {
    return new Promise((resolve, reject) => {
      this.selAnosOpts = selAnos.map(ano => {
        return {
          checked: ano.checked,
          ano: ano.ano,
          id_link: ano.id_link,
          data_inicio: ano.data_inicio,
          data_fim: ano.data_fim,
        }
      })

      this.showPeriodo = showPeriodo;

      this.updateAllChecked();

      this.modalRef = this.modalService
      .open(this.alertConfig)
      .onApprove((selAnos: SelAno[]) => {
        resolve(selAnos);
      })
      .onDeny(() => {
        reject(null);
      })
    })
  }

  updateAllChecked() {
    this.selAnosAllChecked = this.selAnosOpts.find(a => !a.checked) === undefined;
  }

  checkAllAnosPicker() {
    this.selAnosOpts.forEach(opt => {
      opt.checked = !!this.selAnosAllChecked;
    })
  }

  checkAno(ano) {
    setTimeout(() => {
      ano.checked = !ano.checked;
      this.updateAllChecked();
    }, 1);
  }

  confirmSelAnos() {
    let selAnos = this.selAnosOpts.filter(a => !!a.checked);
    if (!selAnos.length) {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
      return;
    }

    this.modalRef.approve(selAnos);
  }
}
