import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent, forkJoin } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
import { Location, formatDate } from '@angular/common';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';


@Component({
  selector: 'app-contas-correntes',
  templateUrl: './contas-correntes.component.html',
  styleUrls: ['./contas-correntes.component.scss']
})
export class ContasCorrentesComponent implements OnInit {

  actRegApiCall = false;

  // TABS DEFINITION ----------------------------------------------------------
  tabsObjDef: any = [
    { key: 'fraccoes', name: 'Fracções', url: 'fraccoes', active: true, disabled: false },
    { key: 'condominos', name: 'Condóminos', url: 'condominos', active: false, disabled: false },
    { key: 'fornecedores', name: 'Fornecedores', url: 'fornecedores', active: false, disabled: false },
  ];
  selTabKey = 'fraccoes';

  // GLOBAL VARIABLES ----------------------------------------------------------
  transitionController = new TransitionController();
  tapTransitionController = new TransitionController();
  loading = false;
  loadingModal = false;
  searchable = true;
  clearEntry = { name: '-- limpar selecção --', value: '-1' };

  // FILTERS VARIABLES ----------------------------------------------------------
  startDate = null;
  endDate = new Date();
  selCondominio = null;
  selFraccao = null;
  selTipoListagem = '0';
  selTipoRelatorio = '0';
  selTipoAgrupamento = '1';
  selCondomino = null;
  selFornecedor = null;
  selTipoDespesa = 'D';

  // FRACCOES TABLE VARIABLES
  fraccoesListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide col-centered' },  // 'ASC', 'DESC'
    { key: 'zona_nome', name: 'Zona', type: 'text', sort: null, searchable: true, centered: true, class: 'tree wide z-index-0' },
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: true, centered: true, class: 'tree wide z-index-0' },
    { key: 'condomino_nome', name: 'Proprietário', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0' },
  ];
  fraccoesList: Array<any> = [];

  // CONDOMINIOS TABLE VARIABLES
  condominiosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide col-centered' },  // 'ASC', 'DESC'
    { key: 'condominio', name: 'Condomínio', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0' },
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: true, centered: true, class: 'tree wide z-index-0' },
  ];
  condominiosList: Array<any> = [];

  // REPORT TABLE VARIABLES
  reportListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide col-centered' },  // 'ASC', 'DESC'
    { key: 'tipo_doc', name: 'Tipo Doc', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered' },
    { key: 'doc', name: 'Doc', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered' },
    { key: 'data_venc', name: 'Vencimento', type: 'text', sort: null, searchable: false, centered: false, class: 'two wide col-centered' },
    { key: 'proc', name: 'Processamento', type: 'text', sort: null, searchable: false, centered: false, class: '' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];
  reportList: Array<any> = [];
  reportSortParam: string = null;
  reportSortDirection: string = null;

  total = {
    valor_limpeza: 0,
    debito: 0,
    credito: 0,
    saldo: 0,
  }

  comp = 'contas-correntes';
  initState = null;
  prevState = null;

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public route: ActivatedRoute,
              public router: Router,
              public userSession: UserSessionService,
              public api: ApiService,
              public cdRef:ChangeDetectorRef,
              public location: Location,
              public utils: UtilitiesService,
              public appState: AppStateService,
              public appConfig: AppConfigService,
              public message: MessageService) {
    // GET GLOBAL STATE
    let globalState = this.appState.getGlobalState('global');
    if (globalState && globalState.hasOwnProperty('selCondominio')) {
      this.selCondominio = (globalState.selCondominio) ? { name: globalState.selCondominio.cod + ' - ' + globalState.selCondominio.nome, value: globalState.selCondominio } : null;
      this.setCondominio();
    }
  }

  saveGlobalState() {
    if (this.selCondominio && this.selCondominio !== '-1') {
      this.appState.saveGlobalState('global', { 
        selCondominio: { id: this.selCondominio.id, cod: this.selCondominio.cod, nome: this.selCondominio.nome, exercicio: this.selCondominio.exercicio },
      });
    } else {
      this.appState.clearGlobalState();
    }
  }

  // SELECT LOOKUPS FUNCTIONS -------------------------------------------------
  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selCondominio); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
                } else {
                  return resolve([this.clearEntry]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
            } else {
              return resolve([this.clearEntry]);
            }
          });
        }
    });
  };

  fornecedorTimer = null;
  fornecedoresLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selFornecedor); });
    }

    clearTimeout(this.fornecedorTimer);
    return new Promise(resolve => {
        if (query) {
          this.fornecedorTimer = setTimeout(() => {
            // FORNECEDOR LIST
            this.api.getAllFornecedores(query).subscribe(res => {
              if (res.success) {
                return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
              } else {
                return resolve([this.clearEntry]);
              }
            });
          }, 400);
        } else {
          this.api.getAllFornecedores('NULL').subscribe(res => {
            if (res.success) {
              return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
            } else {
              return resolve([this.clearEntry]);
            }
          });
        }
    });
  };

  condominosTimer = null;
  condominosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selCondomino); });
    }

    clearTimeout(this.condominosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominosTimer = setTimeout(() => {
            // PROPRIETARIOS/INGUILINOS LIST
            this.api.getAllCondominos(query, 'proprietarios').subscribe(res => {
              if (res.success) {
                return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
              } else {
                return resolve([this.clearEntry]);
              }
            });
          }, 400);
        } else {
          this.api.getAllCondominos('NULL', 'proprietarios').subscribe(res => {
            if (res.success) {
              return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
            } else {
              return resolve([this.clearEntry]);
            }
          });
        }
    });
  };
  // --------------------------------------------------------------------------

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.setPrevState();

    setTimeout(() => { 
      this.setTab(this.tabsObjDef.find(el => (el.active)).key);

      this.getList();
    }, 1);

    this.animate();
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  getCondominiosList() {
    if (!this.selCondomino) return;

    let cod = (this.selCondomino.hasOwnProperty('value')) ? this.selCondomino.value.cod : this.selCondomino.cod;

    this.api.getCondominios(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.condominiosList = [];

        let aux = null;
        res.data.forEach(el => {
          aux = {
            checked: false,
            condominio: el.cod_condominio + ' - ' + el.nome_condominio,
            fraccao: el.cod_fraccao + ' - ' + el.nome_fraccao,
            cod_condominio: el.cod_condominio,
            cod_fraccao: el.cod_fraccao,
          };
          this.condominiosList.push(aux);
        });
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  listCheckbox = true;
  getFraccoesList() {
    if (!this.selCondominio || this.selCondominio === '-1') return;

    let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;

    this.api.getFraccoes(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.fraccoesList = [];

        // CHECK AND SET LOCAL STATE
        let localState = null;
        if (this.appState.contaCorrenteFraccoesState.active) {
          localState = JSON.parse(JSON.stringify(this.appState.contaCorrenteFraccoesState));
          this.appState.clearContaCorrenteFraccoesState();
        }

        let aux = null;
        res.data.forEach(el => {
          aux = {
            checked: (!localState || (localState && localState.selectedFraccoes.find(it => it.cod_fraccao === el.cod))),
            cod_fraccao: el.cod,
            zona_nome: el.zona_nome,
            fraccao: el.cod + ' - ' + el.nome,
            condomino_nome: el.condomino_nome,
          };
          this.fraccoesList.push(aux);
          
        });

      } else {
        this.utils.apiErrorMsg(res);
      }

      this.updateEmailConfigs();
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  rowSelectionToggle(ev) {
    switch (this.selTabKey) {
      case 'fraccoes':
        (ev.target.checked) ? this.fraccoesList.map(el => el.checked = true ) : this.fraccoesList.map(el => el.checked = false );
        break;
      case 'condominos':
        (ev.target.checked) ? this.condominiosList.map(el => el.checked = true ) : this.condominiosList.map(el => el.checked = false );
        break;
    }
  }

  getList() {
    switch (this.selTabKey) {
      case 'fraccoes':
        this.getFraccoesList();
        break;
      case 'condominos':
        this.getCondominiosList();
        // this.getContasCorrentesFornecedoresList();
        break;
      case 'fornecedores':
        // this.getContasCorrentesFornecedoresList();
        break;
    }
  }

  getContasCorrentesFraccoesList() {
    let fraccoesSel = this.fraccoesList.filter(el => (el.checked)).map(el => { return el.cod_fraccao; });

    if (!this.selCondominio || this.selCondominio === '-1' || fraccoesSel.length === 0 || !this.selTipoRelatorio) {
      if (fraccoesSel.length === 0) {
        this.toastr.warning('Selecionar pelo menos uma fracção antes de gerar o relatório.', 'Ups...!', { timeOut: 4000 });
      } else {
        
      }
      return;
    }

    this.loading = true;  
    let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;

    if (this.selTipoRelatorio === '1') {
      let req = [];
      fraccoesSel.forEach(fraccao_cod => {
        req.push(this.api.getContaCorrente('fraccoes', this.selTipoAgrupamento, cod, [fraccao_cod], this.startDate, this.endDate, this.selTipoListagem, ));
      });

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      forkJoin(req).subscribe(res => {
        if (res[0].hasOwnProperty('success') && res[0].success) {
          let dataset = [];

          res.forEach(el => { dataset = dataset.concat(el.data); });

          // this.generateReportListObj(dataset);

          // SAVE FILTER STATE
          this.setState();

          // SAVE REPORT OBJECT AND NAVIGATE TO REPORT PAGE
          this.appState.setReportData(dataset, this.selCondominio, this.startDate, this.endDate, this.total, 'condominio', this.selTipoListagem);
          this.router.navigate(['contascorrentes/relatorio-detalhado']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RELATÓRIO DETALHADO` });

          this.loading = false;

          // REGISTO ACTIVIDADES API CALL
          let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
          let nome = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;
          let titulo = 'Relatório Contas Correntes Visualizado';
          let descricao = 'Condomínio: ' + cod + ' - ' + nome;
          this.api.saveRegistoActividade(null, null, null, titulo, descricao).subscribe(res => {}, err => { });

        } else {
          this.loading = false;
          this.utils.apiErrorMsg(res[0]);
          // this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });
      
    } else {
      let req = [];
      let tipoAgrupamento = (this.selTipoAgrupamento === '0') ? 'fraccoes' : 'condominos';
      fraccoesSel.forEach(fraccao_cod => {
        req.push(this.api.getContaCorrenteResumo(tipoAgrupamento, cod, [fraccao_cod], this.startDate, this.endDate, this.selTipoListagem));
      })

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      // GET RESUMED REPORT
      forkJoin(req).subscribe(res => {
        if (res[0].hasOwnProperty('success') && res[0].success) {

          let dataset = [];
          res.forEach(el => { dataset = dataset.concat(el.data); });

          let datasetAux = [];
          if (tipoAgrupamento === 'condominos') {

            dataset.forEach(elem => {
              if ( (Number(elem.debito) === 0 && Number(elem.saldo) === 0) ) {

                let entries = dataset.filter(elem2 => (elem.cod_fraccao === elem2.cod_fraccao));
                if (entries && entries.length === 1) {
                  datasetAux.push(elem);
                }
                
              } else {
                datasetAux.push(elem);
              }
            });
            dataset = JSON.parse(JSON.stringify(datasetAux));
            // dataset = dataset.filter(el => (!(Number(el.debito) === 0 && Number(el.saldo) === 0)));
          }

          this.generateReportResmuseListObj(dataset, tipoAgrupamento);

          // SAVE FILTER STATE
          this.setState();

          // SAVE REPORT OBJECT AND NAVIGATE TO REPORT PAGE
          this.appState.setReportData(this.reportList, this.selCondominio, this.startDate, this.endDate, this.total, 'condominio', this.selTipoListagem, tipoAgrupamento);
          this.router.navigate(['contascorrentes/relatorio-resumido']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RELATÓRIO RESUMIDO` });

          this.loading = false;

          // REGISTO ACTIVIDADES API CALL
          let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
          let nome = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;
          let titulo = 'Relatório Contas Correntes Visualizado';
          let descricao = 'Condomínio: ' + cod + ' - ' + nome;
          this.api.saveRegistoActividade(null, null, null, titulo, descricao).subscribe(res => {}, err => { });

        } else {
          this.loading = false;
          this.utils.apiErrorMsg(res[0]);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });
    }

  }

  getContasCorrentesCondominoList() {
    let condominiosSel = this.condominiosList.filter(el => (el.checked)).map(el => { return { cod_condominio: el.cod_condominio, cod_fraccao: el.cod_fraccao }; });

    if (!this.selCondomino || condominiosSel.length === 0 || !this.selTipoRelatorio) {
      if (condominiosSel.length === 0) {
        this.toastr.warning('Selecionar pelo menos um condomínio antes de gerar o relatório.', 'Ups...!', { timeOut: 4000 });
      } else {
        
      }
      return;
    }

    this.loading = true;
    let cod_condomino = (this.selCondomino.hasOwnProperty('value')) ? this.selCondomino.value.cod : this.selCondomino.cod;

    if (this.selTipoRelatorio === '1') {
      // GET DETAILED REPORT
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      this.api.getContaCorrenteCondomino(cod_condomino, condominiosSel, this.startDate, this.endDate, this.selTipoListagem).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {

          this.generateReportByCondominoListObj(res.data);

          // SAVE FILTER STATE
          this.setState();

          // SAVE REPORT OBJECT AND NAVIGATE TO REPORT PAGE
          this.appState.setReportData(this.reportList, this.selCondomino, this.startDate, this.endDate, this.total, 'condomino', this.selTipoListagem);
          this.router.navigate(['contascorrentes/relatorio-detalhado']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RELATÓRIO DETALHADO` });

          this.loading = false;

          // REGISTO ACTIVIDADES API CALL
          let cod = (this.selCondomino.hasOwnProperty('value')) ? this.selCondomino.value.cod : this.selCondomino.cod;
          let nome = (this.selCondomino.hasOwnProperty('value')) ? this.selCondomino.value.nome : this.selCondomino.nome;
          let titulo = 'Relatório Contas Correntes Visualizado';
          let descricao = 'Condómino: ' + cod + ' - ' + nome;
          this.api.saveRegistoActividade(null, null, null, titulo, descricao).subscribe(res => {}, err => { });

        } else {
          this.loading = false;
          this.utils.apiErrorMsg(res);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });

    } else {
      // GET RESUMED REPORT
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      this.api.getContaCorrenteResumoCondomino(cod_condomino, condominiosSel, this.startDate, this.endDate, this.selTipoListagem).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.generateReportResmuseByCondominoListObj(res.data);

          // SAVE FILTER STATE
          this.setState();

          // SAVE REPORT OBJECT AND NAVIGATE TO REPORT PAGE
          this.appState.setReportData(this.reportList, this.selCondomino, this.startDate, this.endDate, this.total, 'condomino', this.selTipoListagem);
          this.router.navigate(['contascorrentes/relatorio-resumido']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RELATÓRIO RESUMIDO` });

          this.loading = false;

          // REGISTO ACTIVIDADES API CALL
          let cod = (this.selCondomino.hasOwnProperty('value')) ? this.selCondomino.value.cod : this.selCondomino.cod;
          let nome = (this.selCondomino.hasOwnProperty('value')) ? this.selCondomino.value.nome : this.selCondomino.nome;
          let titulo = 'Relatório Contas Correntes Visualizado';
          let descricao = 'Condómino: ' + cod + ' - ' + nome;
          this.api.saveRegistoActividade(null, null, null, titulo, descricao).subscribe(res => {}, err => { });

        } else {
          this.loading = false;
          this.utils.apiErrorMsg(res);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });
    }
  }

  getContasCorrentesFornecedoresList() {
    if (!this.selFornecedor || !this.selTipoRelatorio) return;

    this.loading = true;
    let cod_fornecedor = (this.selFornecedor.hasOwnProperty('value')) ? this.selFornecedor.value.cod : this.selFornecedor.cod;
  
    if (this.selTipoRelatorio === '1') {
      // GET DETAILED REPORT
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      this.api.getContaCorrenteFornecedor(cod_fornecedor, this.startDate, this.endDate, this.selTipoDespesa, this.selTipoListagem).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          let dataset = res.data;
          // this.reportList = res.data;

          // this.generateReportByFornecedorListObj(res.data);

          // SAVE FILTER STATE
          this.setState();

          // SAVE REPORT OBJECT AND NAVIGATE TO REPORT PAGE
          this.appState.setReportData(dataset, this.selFornecedor, this.startDate, this.endDate, this.total, 'fornecedor', this.selTipoListagem);
          this.router.navigate(['contascorrentes/relatorio-detalhado']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RELATÓRIO DETALHADO` });

          this.loading = false;

          // REGISTO ACTIVIDADES API CALL
          let cod = (this.selFornecedor.hasOwnProperty('value')) ? this.selFornecedor.value.cod : this.selFornecedor.cod;
          let nome = (this.selFornecedor.hasOwnProperty('value')) ? this.selFornecedor.value.nome : this.selFornecedor.nome;
          let titulo = 'Relatório Contas Correntes Visualizado';
          let descricao = 'Fornecedor: ' + cod + ' - ' + nome;
          this.api.saveRegistoActividade(null, null, null, titulo, descricao).subscribe(res => {}, err => { });

        } else {
          this.loading = false;
          this.utils.apiErrorMsg(res);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });

    } else {
      // GET RESUMED REPORT
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      this.api.getContaCorrenteResumoFornecedor(cod_fornecedor, this.startDate, this.endDate, this.selTipoDespesa, this.selTipoListagem).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.generateReportResmuseByFornecedorListObj(res.data);

          // SAVE FILTER STATE
          this.setState();

          // SAVE REPORT OBJECT AND NAVIGATE TO REPORT PAGE
          this.appState.setReportData(this.reportList, this.selFornecedor, this.startDate, this.endDate, this.total, 'fornecedor', this.selTipoListagem);
          this.router.navigate(['contascorrentes/relatorio-resumido']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RELATÓRIO RESUMIDO` });

          this.loading = false;

          // REGISTO ACTIVIDADES API CALL
          let cod = (this.selFornecedor.hasOwnProperty('value')) ? this.selFornecedor.value.cod : this.selFornecedor.cod;
          let nome = (this.selFornecedor.hasOwnProperty('value')) ? this.selFornecedor.value.nome : this.selFornecedor.nome;
          let titulo = 'Relatório Contas Correntes Visualizado';
          let descricao = 'Fornecedor: ' + cod + ' - ' + nome;
          this.api.saveRegistoActividade(null, null, null, titulo, descricao).subscribe(res => {}, err => { });

        } else {
          this.loading = false;
          this.utils.apiErrorMsg(res);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });
    }

  }
  
  generateReportListObj(data) {
    this.reportList = [];

    this.total['debito'] = 0;
    this.total['credito'] = 0;
    this.total['saldo'] = 0;

    let debito_total = 0;
    let credito_total = 0;
    let saldo_total = 0;

    let debito_total_condominio = 0;
    let credito_total_condominio = 0;
    let saldo_total_condominio = 0;

    let prevZona = null;
    let prevFrac = null;

    let prevCondomino = null;

    data.forEach((el, i) => {

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        tipo_doc: (el['tipo_doc']) ? el['tipo_doc'] : null,
        doc: (el['n_doc']) ? el['tipo_doc'] + ' ' + el['n_doc'] : null,
        data: (el['data_emis']) ? this.utils.getDate(el['data_emis']) : null,
        data_venc: (el['data_venc']) ? this.utils.getDate(el['data_venc']) : null,
        proc: (el['descricao']) ? el['descricao'] : null,
        debito: null,
        credito: null,
        saldo: null,
      }
      if (el['tipo_doc'] === 'C') {
        aux['debito'] = 0;
        aux['credito'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
        aux['saldo'] = aux.debito - aux.credito;
      } else {
        aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
        aux['saldo'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
        aux['credito'] = aux.debito - aux.saldo;
      }

      // if (this.selTipoListagem === '0' || (this.selTipoListagem === '1' && aux['saldo'] !== 0) || (this.selTipoListagem === '2' && aux['saldo'] === 0)) {

        if (this.selTipoAgrupamento === '0') {  // FRACCAO

          // TABLE ROW TOTAL AND SEPARATOR
          if ((prevZona !== el['zona_cod'] || prevFrac !== el['cod_fraccao']) && el['tipo_doc'] !== 'C') {
            if (i > 0) {
              this.reportList.push({ label: 'Total do condómino', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
              this.reportList.push({ label: 'Total da fracção', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
            }

            // ZONA AND FRACCAO SEPARATOR
            this.reportList.push({ label: `${el['zona_nome']} / ${el['cod_fraccao']} - ${el['fraccao_nome']}`, separator: true, separatorCond: false, total: false });
            debito_total = 0;
            credito_total = 0;
            saldo_total = 0;

            // ENTITY SEPARATOR
            this.reportList.push({ label: `${el['condomino_nome']}`, separator: true, separatorCond: true, total: false });
            prevCondomino = null;
            debito_total_condominio = 0;
            credito_total_condominio = 0;
            saldo_total_condominio = 0;
          }
          if (el['tipo_doc'] !== 'C') prevZona = el['zona_cod'];
          if (el['tipo_doc'] !== 'C') prevFrac = el['cod_fraccao'];

          if (prevCondomino && prevCondomino !== el['condomino_nome']) {
            // ENTITY SEPARATOR
            this.reportList.push({ label: 'Total do condómino', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
            
            this.reportList.push({ label: `${el['condomino_nome']}`, separator: true, separatorCond: true, total: false });
            debito_total_condominio = 0;
            credito_total_condominio = 0;
            saldo_total_condominio = 0;
          }
          prevCondomino = el['condomino_nome'];

        } else {  // CONDOMINO

          // TABLE ROW TOTAL AND SEPARATOR
          if (prevCondomino !== el['condomino_nome']     /*(prevZona !== el['zona_cod'] || prevFrac !== el['cod_fraccao']) && el['tipo_doc'] !== 'C'*/) {
            if (i > 0) {
              this.reportList.push({ label: 'Total do fracção', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
              this.reportList.push({ label: 'Total da condómino', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
            }

            // ZONA AND FRACCAO SEPARATOR
            this.reportList.push({ label: `${el['condomino_nome']}`, separator: true, separatorCond: false, total: false });
            debito_total = 0;
            credito_total = 0;
            saldo_total = 0;

            // ENTITY SEPARATOR
            this.reportList.push({ label: `${el['zona_nome']} / ${el['cod_fraccao']} - ${el['fraccao_nome']}`, separator: true, separatorCond: true, total: false });
            prevZona = null;
            prevFrac = null;
            debito_total_condominio = 0;
            credito_total_condominio = 0;
            saldo_total_condominio = 0;
          }

          prevCondomino = el['condomino_nome'];

          if (prevZona && prevFrac && (prevZona !== el['zona_cod'] || prevFrac !== el['cod_fraccao']) && el['tipo_doc'] !== 'C') {
            // ENTITY SEPARATOR
            this.reportList.push({ label: 'Total da fracção', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
            
            this.reportList.push({ label: `${el['zona_nome']} / ${el['cod_fraccao']} - ${el['fraccao_nome']}`, separator: true, separatorCond: true, total: false });
            debito_total_condominio = 0;
            credito_total_condominio = 0;
            saldo_total_condominio = 0;
          }
          if (el['tipo_doc'] !== 'C') prevZona = el['zona_cod'];
          if (el['tipo_doc'] !== 'C') prevFrac = el['cod_fraccao'];
          
        }

        debito_total += aux.debito;
        credito_total += aux.credito;
        saldo_total += aux.saldo;

        debito_total_condominio += aux.debito;
        credito_total_condominio += aux.credito;
        saldo_total_condominio += aux.saldo;

        this.reportList.push(aux);

        this.total['debito'] += aux.debito;
        this.total['credito'] += aux.credito;
        this.total['saldo'] += aux.saldo;
      // }

    });
    if (this.reportList.length > 0) {
      if (this.selTipoAgrupamento === '0') {  // FRACCAO
        this.reportList.push({ label: 'Total do condómino', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
        this.reportList.push({ label: 'Total da fracção', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
      } else {  // CONDOMINO
        this.reportList.push({ label: 'Total do fracção', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
        this.reportList.push({ label: 'Total da condómino', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
      }
    }
  }

  generateReportResmuseListObj(data, tipoAgrupamento=null) {
    this.reportList = [];

    this.total['debito'] = 0;
    this.total['credito'] = 0;
    this.total['saldo'] = 0;    

    let debito_total_zona = 0;
    let credito_total_zona = 0;
    let saldo_total_zona = 0;

    let prevZona = null;

    let continu = true;

    data.forEach((el, i) => {
      continu = true;

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        fraccao: (el['cod_fraccao'] && el['fraccao_nome']) ? el['cod_fraccao'] + ' - ' + el['fraccao_nome'] : null,
        debito: null,
        credito: null,
        saldo: null,
        cod_fraccao: el['cod_fraccao'],
      }

      aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
      aux['saldo'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
      aux['credito'] = aux.debito - aux.saldo;

      if (tipoAgrupamento === 'condominos') {
        let isActProp = (this.fraccoesList.find(it => (it.condomino_nome === el['condomino_nome'] && it.cod_fraccao === el['cod_fraccao']))) ? true : false;

        if (data.filter(it => it.cod_fraccao === el['cod_fraccao']).length >= 2) {
          if (!isActProp && aux['saldo'] === 0) continu = true;

          aux['fraccao'] += (' / ' + el['condomino_nome'] + ((!isActProp) ? '*' : ''));
        } else {
          aux['fraccao'] += (' / ' + el['condomino_nome']);
        }

      }

      if (continu) {
        // TABLE ROW TOTAL AND SEPARATOR
        if (prevZona !== el['zona_cod'] && el['zona_cod'] !== null && prevZona !== null) {
          if (i > 0) {
            this.reportList.push({ label: 'Total da zona', debito: debito_total_zona, credito: credito_total_zona, saldo: saldo_total_zona, separator: false, separatorCond: true, total: true });
          }

          // ZONA SEPARATOR
          this.reportList.push({ label: `${el['zona_nome']}`, separator: true, separatorCond: false, total: false });
          debito_total_zona = 0;
          credito_total_zona = 0;
          saldo_total_zona = 0;
        }
        prevZona = el['zona_cod'];

        debito_total_zona += aux.debito;
        credito_total_zona += aux.credito;
        saldo_total_zona += aux.saldo;

        this.reportList.push(aux);

        this.total['debito'] += aux.debito;
        this.total['credito'] += aux.credito;
        this.total['saldo'] += aux.saldo;
      }

    });
    if (this.reportList.length > 0) {
      this.reportList.push({ label: 'Total da zona', debito: debito_total_zona, credito: credito_total_zona, saldo: saldo_total_zona, separator: false, separatorCond: false, total: true });
    }

    let aux = this.reportList.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1);
    let auxSet = [...new Set(aux.map(el => el.cod_fraccao))];
    auxSet.forEach(cod_fraccao => {
      let len2 = this.reportList.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') === -1 && el.cod_fraccao === cod_fraccao).length;
      if (!len2) {
        let aux1 = this.reportList.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1 && el.cod_fraccao === cod_fraccao);
        aux1.forEach((el, i) => {
          if (i > 0) this.reportList = this.reportList.filter(it => it.fraccao !== el.fraccao);
        });
      } else {
        let aux = this.reportList.filter(el => el.saldo === 0 && el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1 && el.cod_fraccao === cod_fraccao);
        aux.forEach(el => {
          this.reportList = this.reportList.filter(it => it.fraccao !== el.fraccao);
        });
      }
    });
    
    // FILTER AND ORDER PREV CONDOMINO
    this.reportList.forEach(el => {
      if (el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1) {
        let fraccoesAux = this.reportList.filter(it => (it.cod_fraccao === el.cod_fraccao));
        if (fraccoesAux .length < 2) el.fraccao = el.fraccao.replace('*', '');
      }
    });
    this.reportList = this.reportList.filter(el => !(el.saldo === 0 && el.fraccao && el.fraccao.indexOf('*') !== -1));
  }

  generateReportByCondominoListObj(data) {
    this.reportList = [];

    this.total['debito'] = 0;
    this.total['credito'] = 0;
    this.total['saldo'] = 0;

    let debito_total = 0;
    let credito_total = 0;
    let saldo_total = 0;

    let debito_total_condominio = 0;
    let credito_total_condominio = 0;
    let saldo_total_condominio = 0;

    let prevCondominio = null;
    let prevFrac = null;

    data.forEach((el, i) => {

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        tipo_doc: (el['tipo_doc']) ? el['tipo_doc'] : null,
        doc: (el['n_doc']) ? el['tipo_doc'] + ' ' + el['n_doc'] : null,
        data: (el['data_emis']) ? this.utils.getDate(el['data_emis']) : null,
        data_venc: (el['data_venc']) ? this.utils.getDate(el['data_venc']) : null,
        proc: (el['descricao']) ? el['descricao'] : null,
        debito: null,
        credito: null,
        saldo: null,
      }
      if (el['tipo_doc'] === 'C') {
        aux['debito'] = 0;
        aux['credito'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
        aux['saldo'] = aux.debito - aux.credito;
      } else {
        aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
        aux['saldo'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
        aux['credito'] = aux.debito - aux.saldo;
      }

        // TABLE ROW TOTAL AND SEPARATOR
        if ((prevCondominio !== el['cod_condominio'] || prevFrac !== el['cod_fraccao']) && el['tipo_doc'] !== 'C') {
          if (i > 0) {
            this.reportList.push({ label: 'Total da fracção', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
            this.reportList.push({ label: 'Total do condomínio', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
          }

          // ZONA AND FRACCAO SEPARATOR
          if (prevCondominio !== el['cod_condominio']) {
            this.reportList.push({ label: `${el['cod_condominio']} - ${el['nome_condominio']}`, separator: true, separatorCond: false, total: false });
            debito_total = 0;
            credito_total = 0;
            saldo_total = 0;
          }

          // ENTITY SEPARATOR
          this.reportList.push({ label: `${el['cod_fraccao']} - ${el['fraccao_nome']}`, separator: true, separatorCond: true, total: false });
          debito_total_condominio = 0;
          credito_total_condominio = 0;
          saldo_total_condominio = 0;
        }
        if (el['tipo_doc'] !== 'C') prevCondominio = el['cod_condominio'];
        if (el['tipo_doc'] !== 'C') prevFrac = el['cod_fraccao'];

        debito_total += aux.debito;
        credito_total += aux.credito;
        saldo_total += aux.saldo;

        debito_total_condominio += aux.debito;
        credito_total_condominio += aux.credito;
        saldo_total_condominio += aux.saldo;

        this.reportList.push(aux);

        this.total['debito'] += aux.debito;
        this.total['credito'] += aux.credito;
        this.total['saldo'] += aux.saldo;
    });
    if (this.reportList.length > 0) {
      this.reportList.push({ label: 'Total da fracção', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
      this.reportList.push({ label: 'Total do condomínio', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
    }
  }

  generateReportResmuseByCondominoListObj(data) {
    this.reportList = [];

    this.total['debito'] = 0;
    this.total['credito'] = 0;
    this.total['saldo'] = 0;    

    let debito_total_zona = 0;
    let credito_total_zona = 0;
    let saldo_total_zona = 0;

    let prevCondominio = null;

    data.forEach((el, i) => {

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        fraccao: (el['cod_fraccao'] && el['fraccao_nome']) ? el['cod_fraccao'] + ' - ' + el['fraccao_nome'] : null,
        debito: null,
        credito: null,
        saldo: null,
      }
      aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
      aux['saldo'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
      aux['credito'] = aux.debito - aux.saldo;

      // TABLE ROW TOTAL AND SEPARATOR
      if (prevCondominio !== el['cod_condominio']) {
        if (i > 0) {
          this.reportList.push({ label: 'Total do condomínio', debito: debito_total_zona, credito: credito_total_zona, saldo: saldo_total_zona, separator: false, separatorCond: true, total: true });
        }

        // ZONA SEPARATOR
        this.reportList.push({ label: `${el['cod_condominio']} - ${el['nome_condominio']}`, separator: true, separatorCond: false, total: false });
        debito_total_zona = 0;
        credito_total_zona = 0;
        saldo_total_zona = 0;
      }
      prevCondominio = el['cod_condominio'];

      debito_total_zona += aux.debito;
      credito_total_zona += aux.credito;
      saldo_total_zona += aux.saldo;

      this.reportList.push(aux);

      this.total['debito'] += aux.debito;
      this.total['credito'] += aux.credito;
      this.total['saldo'] += aux.saldo;
    });
    if (this.reportList.length > 0) {
      this.reportList.push({ label: 'Total do condomínio', debito: debito_total_zona, credito: credito_total_zona, saldo: saldo_total_zona, separator: false, separatorCond: false, total: true });
    }
  }

  generateReportByFornecedorListObj(data) {
    this.reportList = [];

    this.total['valor_limpeza'] = 0;
    this.total['debito'] = 0;
    this.total['credito'] = 0;
    this.total['saldo'] = 0;

    let valor_limpeza_total_condominio = 0;
    let debito_total_condominio = 0;
    let credito_total_condominio = 0;
    let saldo_total_condominio = 0;

    let prevCondominio = null;

    data.forEach((el, i) => {

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        tipo_doc: (el['tipo_doc']) ? el['tipo_doc'] : null,
        doc: (el['n_doc']) ? el['tipo_doc'] + ' ' + el['n_doc'] : null,
        data: (el['data_emis']) ? this.utils.getDate(el['data_emis']) : null,
        data_pag: (el['data_pag']) ? this.utils.getDate(el['data_pag']) : null,
        proc: (el['descricao']) ? el['descricao'] : null,
        valor_limpeza: (el['valor_limpeza']) ? el['valor_limpeza'] : 0,
        banco: (el['form_pagam'] && el['banco']) ? el['form_pagam'] + ' - ' + el['banco']: null,
        debito: (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : 0,
        credito: 0,
        saldo: 0,
      }
      aux['credito'] = (el['credito']) ? Math.round(Number(el['credito']) * 100) / 100 : 0;
      aux['saldo'] = aux.credito - aux.debito;

      if (this.selTipoListagem === '0' || (this.selTipoListagem === '1' && aux['saldo'] !== 0) || (this.selTipoListagem === '2' && aux['saldo'] === 0)) {

        // TABLE ROW TOTAL AND SEPARATOR
        if (prevCondominio !== el['cod_condominio']) {
          if (i > 0) {
            this.reportList.push({ label: 'Total do condomínio', valor_limpeza: valor_limpeza_total_condominio, debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: false, total: true });
          }

          // ENTITY SEPARATOR
          this.reportList.push({ label: `${el['cod_condominio']} - ${el['nome_condominio']}`, separator: true, separatorCond: false, total: false });
          valor_limpeza_total_condominio = 0;
          debito_total_condominio = 0;
          credito_total_condominio = 0;
          saldo_total_condominio = 0;
        }
        prevCondominio = el['cod_condominio'];

        valor_limpeza_total_condominio += aux.valor_limpeza;
        debito_total_condominio += aux.debito;
        credito_total_condominio += aux.credito;
        saldo_total_condominio += aux.saldo;

        this.reportList.push(aux);

        this.total['valor_limpeza'] += aux.valor_limpeza;
        this.total['debito'] += aux.debito;
        this.total['credito'] += aux.credito;
        this.total['saldo'] += aux.saldo;
      }

    });
    if (this.reportList.length > 0) {
      this.reportList.push({ label: 'Total do condomínio', valor_limpeza: valor_limpeza_total_condominio, debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: false, total: true });
    }
  }

  generateReportResmuseByFornecedorListObj(data) {
    this.reportList = [];

    this.total['valor_limpeza'] = 0;
    this.total['debito'] = 0;
    this.total['credito'] = 0;
    this.total['saldo'] = 0;    

    data.forEach((el, i) => {

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        condominio: (el['cod_condominio'] && el['nome_condominio']) ? el['cod_condominio'] + ' - ' + el['nome_condominio'] : null,
        valor_limpeza: el['valor_limpeza'] ? el['valor_limpeza'] : 0,
        debito: (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : 0,
        credito: 0,
        saldo: 0,
      }
      aux['credito'] = (el['credito']) ? Math.round(Number(el['credito']) * 100) / 100 : 0;
      aux['saldo'] = aux.credito - aux.debito;

      this.reportList.push(aux);

      this.total['valor_limpeza'] += aux.valor_limpeza;
      this.total['debito'] += aux.debito;
      this.total['credito'] += aux.credito;
      this.total['saldo'] += aux.saldo;
    });
  }

  setTab(targetList) {
    this.selTabKey = targetList;

    switch (targetList) {
      case 'fraccoes':
        this.location.replaceState('/contascorrentes/fraccoes');
        break;
      case 'condominos':
        this.location.replaceState('/contascorrentes/condominos');
        break;
      case 'fornecedores':
        this.location.replaceState('/contascorrentes/fornecedores');
        break;
    }

    this.updateEmailConfigs();
  }

  setPrevState() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);

    if (this.prevState) {
      // this.selCondominio = (this.prevState.state.selCondominio) ? { name: this.prevState.state.selCondominio.cod + ' - ' + this.prevState.state.selCondominio.nome, value: this.prevState.state.selCondominio } : null;
      this.selTipoAgrupamento = (this.prevState.state.selTipoAgrupamento) ? this.prevState.state.selTipoAgrupamento : null;
      this.selCondomino = (this.prevState.state.selCondomino) ? { name: this.prevState.state.selCondomino.nome, value: this.prevState.state.selCondomino } : null;
      this.selFornecedor = (this.prevState.state.selFornecedor) ? { name: this.prevState.state.selFornecedor.nome, value: this.prevState.state.selFornecedor } : null;
      this.selTipoDespesa = (this.prevState.state.selTipoDespesa) ? this.prevState.state.selTipoDespesa : null;
      this.selTipoListagem = (this.prevState.state.selTipoListagem) ? this.prevState.state.selTipoListagem : null;
      this.startDate = (this.prevState.state.startDate) ? this.prevState.state.startDate : null;
      this.endDate = (this.prevState.state.endDate) ? this.prevState.state.endDate : null;
      this.selTipoRelatorio = (this.prevState.state.selTipoRelatorio) ? this.prevState.state.selTipoRelatorio : null;

      // SET ACTIVE TAB
      if (this.prevState.state.hasOwnProperty('activeTab') && this.prevState.state.activeTab !== null) {
        this.tabsObjDef.forEach(tab => {
          if (tab.key === this.prevState.state.activeTab.key) {
            tab.active = true;
          } else {
            tab.active = false;
          }
        });
      }

      this.appState.clearPrevState(this.comp);

      this.setCondomino();
    }
  }

  setState() {
    let selCondominio = null;
    if (this.selCondominio) { 
      selCondominio = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio; 
    }

    let selCondomino = null;
    if (this.selCondomino) selCondomino = (this.selCondomino.hasOwnProperty('value')) ? this.selCondomino.value : this.selCondomino;

    let selFornecedor = null;
    if (this.selFornecedor) selFornecedor = (this.selFornecedor.hasOwnProperty('value')) ? this.selFornecedor.value : this.selFornecedor;

    this.appState.setPrevState(this.comp, {
      activeTab: this.tabsObjDef.find(el => (el.key === this.selTabKey)),
      selCondominio: selCondominio,
      selTipoAgrupamento: this.selTipoAgrupamento,
      selCondomino: selCondomino,
      selFornecedor: selFornecedor,
      selTipoDespesa: this.selTipoDespesa,
      selTipoListagem: this.selTipoListagem,
      startDate: this.startDate,
      endDate: this.endDate,
      selTipoRelatorio: this.selTipoRelatorio,
    });
  }

  // SEND EMAILS MODULES - VARIABLES AND METHODS ------------------------------
  codCondominio = null;
  nomeCondominio = null;

  codCondomino = null;
  nomeCondomino = null;

  emailConfigs = {
    ccType: null,
    startDate: null,
    endDate: null,
    selTipoRelatorio: null,
    selTipoAgrupamento: null,
    selTipoListagem: null,
    condominosSelected: [],
    condominiosSelected: [],
  }

  updateEmailConfigs() {
    this.emailConfigs = {
      ccType: this.selTabKey,
      startDate: this.startDate,
      endDate: this.endDate,
      selTipoRelatorio: this.selTipoRelatorio,
      selTipoAgrupamento: '1',
      selTipoListagem: '0',
      condominosSelected: this.fraccoesList.filter(el => el.checked),
      condominiosSelected: this.condominiosList.filter(el => el.checked),
    }
  }

  setCondominio() {
    if (!this.selCondominio) return;

    if (this.selCondominio.hasOwnProperty('cod') && this.selCondominio.cod) {
      this.codCondominio = this.selCondominio.cod;
      this.nomeCondominio = this.selCondominio.nome;
    } else if (this.selCondominio.hasOwnProperty('value') && this.selCondominio.value && this.selCondominio.value.hasOwnProperty('cod') && this.selCondominio.value.cod ) {
      this.codCondominio = this.selCondominio.value.cod;
      this.nomeCondominio = this.selCondominio.value.nome;
    }
  }

  setCondomino() {
    if (!this.selCondomino) return;

    if (this.selCondomino.hasOwnProperty('cod') && this.selCondomino.cod) {
      this.codCondomino = this.selCondomino.cod;
      this.nomeCondomino = this.selCondomino.nome;
    } else if (this.selCondomino.hasOwnProperty('value') && this.selCondomino.value && this.selCondomino.value.hasOwnProperty('cod') && this.selCondomino.value.cod ) {
      this.codCondomino = this.selCondomino.value.cod;
      this.nomeCondomino = this.selCondomino.value.nome;
    }
  }

}
