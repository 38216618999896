import { Injectable } from '@angular/core';
import { Toast, ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { Orcamento, OrcamentoDetailed } from '../business-model-interfaces/orcamentos';
import { UserSessionService } from '../user-session.service';
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})
export class OrcamentosService {

  constructor(public api:ApiService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public userSession: UserSessionService,
              public utils: UtilitiesService) { }


  copyOrcamento(copied_id, newExercicio=null):Promise<string> {
    return new Promise((resolve) => {
      if (!copied_id) {
        resolve(null);
        return
      }
      this.api.copyOrcamento(copied_id, newExercicio).subscribe(res => {
        if (res.success && res.data) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  lancarValores(orcamento:OrcamentoDetailed):Promise<string> {
    return new Promise(async (resolve) => {
      if (!(await orcamento.initialize())) {
        resolve(null);
        return;
      }
      if (!(await orcamento.lancarValores())) {
        resolve(null);
        return;
      }
      resolve(orcamento.id);
    });
  }


  getDetailsSimple(orc_id):Promise<Orcamento> {
    return new Promise((resolve) => {
      this.api.getOrcamentoDetailsSimple(orc_id).subscribe(res => {
        if (res.success && res.data) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }


  del(toDelete:Array<{id, val_lancado, periodo}>): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.delOrcamentos(toDelete).subscribe(res => {
        if (res.hasOwnProperty('success') & res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }

  getLastOrcamento(cod_condominio): Promise<Orcamento> {
    return new Promise(resolve => {
      this.api.getOrcamentoByPeriodoAndAprovado(cod_condominio, true).subscribe(res => {
        if ((res.hasOwnProperty('success') && res.success)) {
          resolve(res.data.orcamento);
        } else {
          resolve(null);
        }
      }, err => {
        resolve(null);
      });
    });
  }
}
