import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { resolve } from 'url';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { AppStateService } from './app-state.service';
import { Movimento, MovimentoBD } from './business-model-interfaces/movimento';
import { PermissionAction, PermissionKey } from './business-model-interfaces/permissions';
import { ReconciliacaoBancariaBD, ReconciliacaoBancaria, ReconciliacaoBancariaBD_Detailed, ReconciliacaoBancaria_Detailed, ReconciliacaoBancariaRegAtividade, ReconciliacaoBancariaRegAtividadeBD } from './business-model-interfaces/reconciliacoes-bancarias';
import { RegistoAtividade, RegistoAtividadeBD } from './business-model-interfaces/registo-atividade';
import { MessageService } from './message.service';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessLogicService {

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public message: MessageService,
              public utils: UtilitiesService,
              public appState: AppStateService,
              public appConfig: AppConfigService
              ) { }

  isMovementInReconciliacaoEmCurso(cod_conta, id_mov, dt_mov): Promise<string> {
    return new Promise((resolve, reject) => {
      this.api.isMovementInReconciliacaoEmCurso(cod_conta, id_mov, dt_mov).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.reconciliacao_id_user);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        reject(null);
      });
    });
  }

  mergePdf(filename:string, base64Array:Array<string>, contentType='data:application/pdf;base64,'):Promise<string> {
    return new Promise((resolve) => {
      this.api.mergePdf(filename, contentType, base64Array).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.data) {
            resolve(contentType + res.data);
          } else {
            resolve(null);
          }
        }
      }, err => {
        resolve(null);
      });
    });
  }

  checkUserAccess(module:PermissionKey, action: PermissionAction, silent=false):Promise<boolean> {
    return new Promise((resolve) => {
      this.api.checkUserAccess(module, action).subscribe(res => {
        if (res.success) {
          if (!res.data && !silent) {
            this.toastr.error(this.appConfig.errMsg.unAuth.msg, this.appConfig.errMsg.unAuth.title);
          }
          resolve(res.data);
        } else {
          if (!silent) this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        if (!silent) this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  saveFile(body:{origem, filename, base64, ids_registo_ctt: Array<any>}):Promise<number> {
    return new Promise((resolve) => {
      this.api.saveCartaPDF(body.ids_registo_ctt, body.base64, body.origem, body.filename).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  //Type conversions
  convertReconciliacaoBancariaType(atBD:ReconciliacaoBancariaBD|ReconciliacaoBancariaBD_Detailed):ReconciliacaoBancaria|ReconciliacaoBancaria_Detailed {
    return {
        ...atBD, 
        saldo: atBD.saldo ? parseFloat(atBD.saldo) : null, 
        data: atBD.data? this.utils.getDate(atBD.data) : null,
        data_reconciliacao: atBD.data_reconciliacao? this.utils.getDate(atBD.data_reconciliacao) : null,
      }
  }
  convertRegistoAtividadeType(atBD:RegistoAtividadeBD):RegistoAtividade {
    let obj = atBD.obj ? JSON.parse(atBD.obj) : null
    return {
        ...atBD, 
        obj: obj,
        data: atBD.data? this.utils.getDate(atBD.data) : null,
      }
  }

  convertReconciliacaoBancariaRegAtividadeType(atBD:ReconciliacaoBancariaRegAtividadeBD):ReconciliacaoBancariaRegAtividade {
    let obj = atBD.obj ? JSON.parse(atBD.obj) : null
    if (obj && obj.data_reconciliacao) obj.data_reconciliacao = this.utils.getDate(obj.data_reconciliacao);
    return {
        ...atBD, 
        data_reconciliacao: atBD.data_reconciliacao ? this.utils.getDate(atBD.data_reconciliacao) : null,
        saldo: atBD.saldo ? parseFloat(atBD.saldo) : null,
        rec_data: atBD.rec_data ? this.utils.getDate(atBD.rec_data) : null,
        obj: obj,
        data: atBD.data ? this.utils.getDate(atBD.data) : null,
      }
  }
  convertMovimentoType(atBD: MovimentoBD): Movimento {
    return {
      ...atBD, 
      dt_mov: atBD.dt_mov ? this.utils.getDate(atBD.dt_mov) : null,
      dt_valor: atBD.dt_valor ? this.utils.getDate(atBD.dt_valor) : null,
      data_reconciliacao: atBD.data_reconciliacao ? this.utils.getDate(atBD.data_reconciliacao) : null,
      obj: atBD.obj ? JSON.parse(atBD.obj) : [],
      valor: atBD.valor? this.utils.cleanDecimalDigits(atBD.valor) : null, 
    }
  }

  getAndDownloadFile(file_id, filename?): Promise<boolean> {
    return new Promise((resolve) => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      this.api.getFile(file_id).subscribe(res => {
        if (res && res.success) {
          this.utils.downloadFile('data:application/pdf;base64,' + res.data.file, filename? filename : res.data.filename);
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err=> {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(false);
      });
    });
  }

  getFile(file_id): Promise<string> {
    return new Promise((resolve) => {
      this.api.getFile(file_id).subscribe(res => {
        if (res && res.success) {
          resolve(res.data.file);
        } else {
          resolve(null);
        }
      }, err=> {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

}
