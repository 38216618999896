import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { forkJoin } from 'rxjs';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { CondominiosService } from '../business-logic-services/condominios.service';
import { OrcamentosService } from '../business-logic-services/orcamentos.service';
import { BusinessLogicService } from '../business-logic.service';
import { CCDataSet, ContaCorrentePdfComponent, ContaCorrentePDFInput } from '../conta-corrente-pdf/conta-corrente-pdf.component';
import { UtilitiesService } from '../utilities.service';


interface MapaQuotasDetails {
  cod_condominio
  nome_condominio
  exercicio
  data: Date
  list: Array<QuotasList>
  listSeg: Array<QuotasList>
  valorPorLiquidar
  valorPorLiquidar_seg
  iban_condominio
}

interface QuotasList {
  fraccao_descricao,
  quota,
  porLiquidar,
  jan: quotaMes,
  fev: quotaMes,
  mar: quotaMes,
  abr: quotaMes,
  mai: quotaMes,
  jun: quotaMes,
  jul: quotaMes,
  ago: quotaMes,
  set: quotaMes,
  out: quotaMes,
  nov: quotaMes,
  dez: quotaMes,
}

type quotaMes = 'L'|'NL'|'PL'

// Econominco, Original ou Ambos
type economico = 'E'|'O'|'A'

@Component({
  selector: 'app-mapa-quotas-pdf',
  templateUrl: './mapa-quotas-pdf.component.html',
  styleUrls: ['./mapa-quotas-pdf.component.scss']
})
export class MapaQuotasPdfComponent implements OnInit {


  @ViewChild('contaCorrentePDF', { static: false }) contaCorrentePDF: ContaCorrentePdfComponent;

  @ViewChild('pdf', { static: false }) pdfController: PDFExportComponent;

  now = new Date();
  mq:MapaQuotasDetails = {
    cod_condominio: null,
    nome_condominio: null,
    exercicio: null,
    data: null,
    list: [],
    listSeg: [],
    valorPorLiquidar: null,
    valorPorLiquidar_seg: null,
    iban_condominio: null
  }

  economico

  constructor(
    public api: ApiService,
    public orcamentos: OrcamentosService,
    public condominios: CondominiosService,
    public utils: UtilitiesService,
    public businessLogic: BusinessLogicService,
    public appConfig: AppConfigService,
    public cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  contaCorrenteRes = [];
  getBase64(cod_condominio, data: Date, economico:economico='O', includeContaCorrente=true): Promise<{economico: string, original: string, contaCorrente:string}> {
    return new Promise(async (resolve) => {
      this.now = new Date();
      let contaCorrenteInput: ContaCorrentePDFInput = {
        type:'FRACAO', 
        cod: cod_condominio, 
        data: data, 
        tipoAgrupamento: null,
        tipoListagem: '1',
      }
      
      await this.getMapaQuotas(cod_condominio, data);

      let ccDataSet: CCDataSet = {
        nib: this.mq.iban_condominio,
        nome: this.mq.nome_condominio,
        data: this.contaCorrenteRes
      }
      let req:any = [
        this.getMapaQuotasBase64(economico),
      ];

      if (includeContaCorrente) req.push(this.contaCorrentePDF.getBase64(contaCorrenteInput, ccDataSet));

      Promise.all(req).then(async res => {
        let mq = res[0] as {economico: string, original: string};
        let cc = includeContaCorrente ? res[1] as string : null;
        resolve({economico: mq.economico, original: mq.original, contaCorrente: cc});
      }).catch(err => {
        resolve(null);
      });
    })
  }

  getFilename() {
    return this.utils.getFileNameFormatted(this.mq.cod_condominio + ' - ' + this.mq.nome_condominio + ' ' + this.mq.exercicio);
  }

  getMapaQuotasBase64(economico:economico): Promise<{economico: string, original: string}> {
    return new Promise(async (resolve) => {

      this.economico = economico === 'E' ? true : false;
      this.cdRef.detectChanges();

      let base64_1 = await this.exportAndGetBase64();

      if (economico === 'E') {
        resolve({economico: base64_1, original: null});
        return;
      }
      if (economico === 'O') {
        resolve({economico: null, original: base64_1});
        return;
      }

      this.economico = true;

      this.cdRef.detectChanges();
      let base64_2 = await this.exportAndGetBase64();
      resolve({economico: base64_2, original: base64_1});
    });
  }

  exportAndGetBase64(): Promise<string> {
    return new Promise(async (resolve) => {
      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');
        resolve(base64);
        return;
      }).catch(err => {
        resolve(null);
      });
    });
  }

  generatePDF(cod_condominio, data: Date): Promise<boolean> {
    return new Promise(async (resolve) => {
      let mq = await this.getBase64(cod_condominio, data);
      let filename = this.getFilename();
      let file = !!mq.original ? mq.original : mq.economico;
      this.utils.downloadFile(file, filename)
      // alert('NOT IMPLEMENTED! NEED TO MERGE CONTA CORRENTE WITH MAPA QUOTAS')
      resolve(true);
    })
  }

  getMapaQuotas(cod_condominio, data_reconciliada: Date): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.now = new Date();
      this.mq = {
        cod_condominio: cod_condominio,
        nome_condominio: null,
        exercicio: null,
        data: data_reconciliada,
        list: [],
        listSeg: [],
        valorPorLiquidar: null,
        valorPorLiquidar_seg: null,
        iban_condominio: null
      }
      let startDate = new Date(Number(data_reconciliada.getFullYear()), 0, 1);
      let endDateMQ = new Date(data_reconciliada);

      // CHECK FOR LAST ORCAMENTO LANCADO
      let orcamento = await this.orcamentos.getLastOrcamento(cod_condominio);
      if (orcamento && 'periodo' in orcamento) {
        if (Number(orcamento['periodo']) < Number(data_reconciliada.getFullYear())) {
          startDate = new Date(Number(orcamento['periodo']), 0, 1);
          endDateMQ = new Date(Number(orcamento['periodo']), 11, 31);
        }
      }

      let exercicio = endDateMQ.getFullYear().toString();
      this.mq.exercicio = exercicio;
      
      this.mq.data = data_reconciliada

      let fraccoes = await this.condominios.getFraccoesLight(cod_condominio);

      let req = [this.api.getRelExercMapaQuotas(cod_condominio, data_reconciliada, startDate, endDateMQ, exercicio)];
      if (fraccoes.length) req.push(this.api.getContaCorrente('fraccoes', 'condomino', cod_condominio, fraccoes.map(el => el.cod), null, data_reconciliada, '0'));
    
      forkJoin(req).subscribe(res => {
        if (res[0].hasOwnProperty('success') && res[0].success) {
          // let contaCorrente = res.slice(1).filter(el => !!el.success).reduce((acc, el) => {
          //   acc = acc.concat(el.data);
          //   return acc;
          // }, []);
          // let contaCorrente = res[1].data;
          this.contaCorrenteRes = res.length > 1? res[1].data : [];
          this.generateMapaQuotasList(res[0].data, this.contaCorrenteRes);
          this.cdRef.detectChanges();
          resolve(true);
        } else {
          this.cdRef.detectChanges();
          resolve(false);
        }
      }, err => {
        this.cdRef.detectChanges();
        resolve(false);
      });
    })
  }

  generateMapaQuotasList(mqData, ccData: Array<any>) {
    let list = [];
    let listSeg = [];

    let prevFraccao = (mqData.mapa_quotas.length) ? mqData.mapa_quotas[0].fraccao : null;

    let prevFraccaoSeg = (mqData.mapa_quotas_seg.length > 0) ? mqData.mapa_quotas_seg[0].fraccao : null;
    let aux: QuotasList = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0, fraccao_descricao: null, quota: 0 };
    let auxSeg:QuotasList = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0, fraccao_descricao: null, quota: 0};
    let hasSeg = false;

    let contasCorrente = ccData;
    let contaCorrente = mqData.mapa_quotas.length > 0 ? contasCorrente.filter(item => (item.cod_fraccao === mqData.mapa_quotas[0].cod_fraccao)) : undefined;
    if (contaCorrente) {
      contaCorrente.forEach(item => {
        let saldoItem = (item.hasOwnProperty('saldo')) ? this.utils.cleanDecimalDigits(item.saldo) : 0;
        if (item.tipo_doc === 'C') {
          aux.porLiquidar -= saldoItem;
        } else {
          aux.porLiquidar += saldoItem;
        }
      });
    }

    mqData.mapa_quotas.forEach((el, i) => {
      el.quota = Number(el.quota);
      el.debito = Number(el.debito);
      el.mes = Number(el.mes);

      if (prevFraccao !== el.fraccao) {
        list.push(aux);
        aux = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0, fraccao_descricao: null, quota: 0 };
      
        let contaCorrente = contasCorrente.filter(item => (item.cod_fraccao === el.cod_fraccao));
        if (contaCorrente) {
          contaCorrente.forEach(item => {
            let saldoItem = (item.hasOwnProperty('saldo')) ? this.utils.cleanDecimalDigits(item.saldo) : 0;
            if (item.tipo_doc === 'C') {
              aux.porLiquidar -= saldoItem;
            } else {
              aux.porLiquidar += saldoItem;
            }
          });
        }
      }

      switch (el.mes) {
        case 1: aux['jan'] = this.setPaymentStatus(el); break;
        case 2: aux['fev'] = this.setPaymentStatus(el); break;
        case 3: aux['mar'] = this.setPaymentStatus(el); break;
        case 4: aux['abr'] = this.setPaymentStatus(el); break;
        case 5: aux['mai'] = this.setPaymentStatus(el); break;
        case 6: aux['jun'] = this.setPaymentStatus(el); break;
        case 7: aux['jul'] = this.setPaymentStatus(el); break;
        case 8: aux['ago'] = this.setPaymentStatus(el); break;
        case 9: aux['set'] = this.setPaymentStatus(el); break;
        case 10: aux['out'] = this.setPaymentStatus(el); break;
        case 11: aux['nov'] = this.setPaymentStatus(el); break;
        case 12: aux['dez'] = this.setPaymentStatus(el); break;
      }

      aux.fraccao_descricao = el.fraccao;
      aux.quota = Number(el.quota);

      prevFraccao = el.fraccao;
    });
    list.push(aux);


    // COMPUTE TOTAL
    let valorPorLiquidar = 0;
    list.forEach(el => {
      valorPorLiquidar += el.porLiquidar;
    });

    mqData.mapa_quotas_seg.forEach(el => {
      hasSeg = true;

      el.quota = Number(el.quota);
      el.debito = Number(el.debito);
      el.mes = Number(el.mes);

      if (prevFraccaoSeg !== el.fraccao) {
        listSeg.push(auxSeg);
        auxSeg = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0, fraccao_descricao: null, quota: 0 };
      }

      switch (el.mes) {
        case 1: auxSeg['jan'] = this.setPaymentStatus(el); break;
        case 2: auxSeg['fev'] = this.setPaymentStatus(el); break;
        case 3: auxSeg['mar'] = this.setPaymentStatus(el); break;
        case 4: auxSeg['abr'] = this.setPaymentStatus(el); break;
        case 5: auxSeg['mai'] = this.setPaymentStatus(el); break;
        case 6: auxSeg['jun'] = this.setPaymentStatus(el); break;
        case 7: auxSeg['jul'] = this.setPaymentStatus(el); break;
        case 8: auxSeg['ago'] = this.setPaymentStatus(el); break;
        case 9: auxSeg['set'] = this.setPaymentStatus(el); break;
        case 10: auxSeg['out'] = this.setPaymentStatus(el); break;
        case 11: auxSeg['nov'] = this.setPaymentStatus(el); break;
        case 12: auxSeg['dez'] = this.setPaymentStatus(el); break;
      }

      auxSeg.fraccao_descricao = el.fraccao;
      auxSeg.quota = Number(el.quota);

      auxSeg.porLiquidar += (el.hasOwnProperty('debito')) ? (Number(el.debito) * 100) / 100 : 0;

      prevFraccaoSeg = el.fraccao;
    });
    if (hasSeg) listSeg.push(auxSeg);

    // COMPUTE TOTAL
    let valorPorLiquidar_seg = 0;
    listSeg.forEach(el => {
      valorPorLiquidar_seg += el.porLiquidar;
    });

    this.mq.nome_condominio = mqData.condominio.nome;
    this.mq.list = list;
    this.mq.listSeg = listSeg;
    this.mq.valorPorLiquidar = valorPorLiquidar;
    this.mq.valorPorLiquidar_seg = valorPorLiquidar_seg;
    this.mq.iban_condominio = mqData.condominio.nib;
  }

  setPaymentStatus(el) {
    let debito = this.utils.cleanDecimalDigits(el.debito);
    let quota = this.utils.cleanDecimalDigits(el.quota);
    //If it happens to have two avisos with same cod, debt can be negative
    if (debito <= 0) {  // PAGO
      return 'L';
    } else if (debito === quota) {  // NAO PAGO
      if (Number(el.ano) < this.mq.data.getFullYear() || el.mes < this.mq.data.getMonth() + 1 || (el.mes === this.mq.data.getMonth() + 1 && this.mq.data.getDate() > 8)) {
        return 'NL';
      }
    } else {  // PARCIALMENTE PAGO
      return 'PL';
    }
  }

}
