import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { ToastrService } from 'ngx-toastr';
import { resolve } from 'url';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { ReconciliacoesBancariasService } from '../business-logic-services/reconciliacoes-bancarias.service';
import { BusinessLogicService } from '../business-logic.service';
import { RegistoCttAR } from '../business-model-interfaces/carta';
import { ReciboDetailed } from '../business-model-interfaces/recibo';
import { ReconciliacaoBancariaEnvioRecibos, ReconciliacaoBancariaRegAtividade } from '../business-model-interfaces/reconciliacoes-bancarias';
import { CartaCttAvisoRececaoComponent } from '../carta-ctt-aviso-rececao/carta-ctt-aviso-rececao.component';
import { DespesasCttModalComponent, EntityDespesasCTTModalInput } from '../despesas-ctt-modal/despesas-ctt-modal.component';
import { MessageService } from '../message.service';
import { ReciboPdfComponent } from '../recibo-pdf/recibo-pdf.component';
import { UtilitiesService } from '../utilities.service';

@Component({
  selector: 'app-print-recibos-pdf',
  templateUrl: './print-recibos-pdf.component.html',
  styleUrls: ['./print-recibos-pdf.component.scss']
})
export class PrintRecibosPdfComponent implements OnInit {

  constructor(
    public appConfig: AppConfigService,
    public utils: UtilitiesService,
    public message: MessageService,
    public api: ApiService,
    public toastr: ToastrService,
    public recBancariasService: ReconciliacoesBancariasService,
    public businessLogic: BusinessLogicService,
    public cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  @ViewChildren('reciboPDF') previewReciboPDF: QueryList<ReciboPdfComponent>;
  recibosToPrint: Array<ReciboDetailed & ReconciliacaoBancariaEnvioRecibos> = [];
  @ViewChild('despesasCTTModal', { static: false }) despesasCTTModal: DespesasCttModalComponent
  correioRegistadoList:Array<RegistoCttAR> = [];
  
  @ViewChildren(CartaCttAvisoRececaoComponent) cttForms: QueryList<CartaCttAvisoRececaoComponent>;
  printRecibos(rec_id):Promise<boolean> {
    return new Promise(async (resolve) => {

      this.recibosToPrint = await this.recBancariasService.getRecibosPorEnviarCartaDetails(rec_id);

      if (!this.recibosToPrint.length) {
        resolve(false);
        return;
      }

      this.recibosToPrint.sort((a,b) => Number(a.cod_pagador) - Number(b.cod_pagador));
      this.cdRef.detectChanges();
      let req:Array<Promise<string>> = [];
      this.previewReciboPDF.forEach((pdfRecibo, i) => {
        let recibo = this.recibosToPrint[i];
        req.push(pdfRecibo.getBase64(recibo.id_recibo));
      });

      if (!req.length) {
        resolve(true);
        return;
      }

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      Promise.all(req).then(async (res) => {
        if (res.find(el => !el)) {
          this.toastr.error(this.appConfig.errMsg.pdf.couldntExportMultiple.msg, this.appConfig.errMsg.pdf.couldntExportMultiple.title);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(false);
          return;
        }

        let entities:Array<EntityDespesasCTTModalInput & { indexes }> = [];
        res.forEach((el, i) => {
          let recibo = i < this.recibosToPrint.length ? this.recibosToPrint[i] : null;
          if (recibo) {
            let entityIndex = entities.findIndex(ent => ent.cod === recibo.cod_pagador);
            if (entityIndex !== -1) {
              entities[entityIndex].base64FileArr.push(el);
              entities[entityIndex].indexes.push(i);
            } else {
              let aux: EntityDespesasCTTModalInput & {indexes} = {
                cod:recibo.cod_pagador,
                nomeEntidade: recibo.pag_nome,
                cod_condominio: recibo.cod_condominio,
                condominioNome: recibo.cond_nome,
                cod_fraccao: recibo.cod_fraccao,
                nome_fraccao: null,
                tipo_entidade: 'PROPRIETARIO',
                base64FileArr:[el],
                indexes: [i],
                id_texto_predefinido: null,
                data_divida: null,
              }
              entities.push(aux);
            }
          }
        });

        if (!entities.length) {
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(false);
          return;
        }
        
        let pdfToMerge = [];
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        let despesasCttRes = await this.despesasCTTModal.open(entities, entities[0].cod_condominio, 'Envio de Recibos', null) as {cttList: Array<any>, id_registo_ctt: Array<any>};
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
        if (!despesasCttRes) {
          pdfToMerge = res;
        } else {
          
          let recibosRegistados:Array<ReciboDetailed & ReconciliacaoBancariaEnvioRecibos> = [];
          let recibosNotRegistados:Array<ReciboDetailed & ReconciliacaoBancariaEnvioRecibos> = [];
          entities.forEach((ent, correioIndex) => {
            let entidadeRecibos = this.recibosToPrint.filter(rec => rec.cod_pagador === ent.cod);
            if (despesasCttRes.cttList[correioIndex] === 'REGISTADO' || despesasCttRes.cttList[correioIndex] === 'REGISTADO_AR') {
              recibosRegistados = recibosRegistados.concat(entidadeRecibos);
            } else {
              recibosNotRegistados = recibosNotRegistados.concat(entidadeRecibos);
            }
          })
          this.correioRegistadoList = recibosRegistados.reduce((newArr, el) => {
            //Se já está na lista de destinatários, continua.
            if (!!newArr.find(reg => !!reg.entities.find(ent => ent.cod === el.cod_pagador))) {
              return newArr;
            }
            
            let entryIndex = newArr? newArr.findIndex(el2 => el2.n_contribuinte == el.cond_nif) : null;
            let pag_morada_fact = el.pag_morada_fact? this.utils.moradaStrToList(el.pag_morada_fact) : [];
            let pag_morada = el.pag_morada? this.utils.moradaStrToList(el.pag_morada) : [];
            let entry = { 
                nomeEntidade: el.pag_nome,
                moradaEntidade: pag_morada_fact.length? pag_morada_fact : pag_morada,
                cod: el.cod_pagador
            };
            if (entryIndex == null || entryIndex === -1) {
              newArr.push({
                n_contribuinte: el.cond_nif, 
                entities: [entry]
              });
              return newArr;
            }
            newArr[entryIndex].entities.push(entry);
            return newArr;
          },[] as Array<RegistoCttAR>);
          this.cdRef.detectChanges();

          if (this.correioRegistadoList.length) {
            //Obtem todos os formulários CTT
            let cttFormsBase64 = await this.getCTTFormsBase64();
            if (cttFormsBase64.length !== this.correioRegistadoList.length) {
              this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
              resolve(false);
              return;
            }

            // Agrupa os PDF's por folha de rosto e respetivos recibos, agrupando por NIF (Condomínio) 
            this.correioRegistadoList.forEach((el, i) => {
              //Junta a folha de rosto com os pdf's em base64 de quem é suposto ir em registado
              pdfToMerge = pdfToMerge.concat([cttFormsBase64[i]]).concat(recibosRegistados.map(rec => {
                let indexAtBase64Arr = this.recibosToPrint.findIndex(el => el.id === rec.id);
                if (indexAtBase64Arr !== -1) return res[indexAtBase64Arr];
                return null;
              }).filter(rec => !!rec));
            })
            //Concat com os que não foram filtrados para o correioregistadolist
            pdfToMerge = pdfToMerge.concat(recibosNotRegistados.map(rec => {
              let indexAtBase64Arr = this.recibosToPrint.findIndex(el => el.id === rec.id);
              if (indexAtBase64Arr !== -1) return res[indexAtBase64Arr];
              return null;
            }).filter(rec => !!rec));
          } else {
            pdfToMerge = res;
          }
        }

        let filename = 'Recibos_' + this.utils.getFormatedDate(new Date()) + '.pdf';
        this.businessLogic.mergePdf(filename, pdfToMerge).then(async (base64Merged:string)=> {
          let base64File = base64Merged.replace(this.utils.getBase64Prefix('.pdf'), '');
          if (despesasCttRes) {
            let succeeded = await this.savePDFAssociation(base64File, despesasCttRes.id_registo_ctt.map(el => el.id_registo_ctt), filename);
            if (!succeeded) {
              this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
              resolve(false);
              return;
            }
          }
          this.utils.downloadFile(base64Merged, filename);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(true);
          return;
        }).catch(err => {
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(false);
          return;
        })
      }).catch(err => {
        this.toastr.error(this.appConfig.errMsg.pdf.couldntExportMultiple.msg, this.appConfig.errMsg.pdf.couldntExportMultiple.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(false);
        return;
      });
    });
  }


  savePDFAssociation(pdfToMerge:string, idsRegistoCtt: Array<string>, filename): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.saveCartaPDF(idsRegistoCtt, pdfToMerge, 'RECIBOS', filename).subscribe(res => {
          if (res.success) {
            resolve(true);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          resolve(false);
      })
    });
  }


  getCTTFormsBase64(): Promise<Array<string>> {
    return new Promise(async (resolve) => {
      let promises = [];
      this.cttForms.forEach(formPDF => promises.push(formPDF.getBase64()));

      Promise.all(promises).then(resArr => {
        resolve(resArr);
      })
    });
  }

}
