import { Component, OnInit, ViewChild } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location, formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
interface IContext {
  data: string;
}

import { Subscription } from 'rxjs';

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { UserSessionService } from '../user-session.service';
import { MessageService } from '../message.service';
import { AccessOption, AccessOptions, Permission, PermissionModule, PermissionTab } from '../business-model-interfaces/permissions';

@Component({
  selector: 'app-utilizador-details',
  templateUrl: './utilizador-details.component.html',
  styleUrls: ['./utilizador-details.component.scss']
})
export class UtilizadorDetailsComponent implements OnInit {

  transitionController = new TransitionController();
  submittingForm = false;
  loadingModalForm = false;
  loading = false;
  selTab: string = null;
  searchable: boolean = true;

  // GERAL FORM 
  geralForm = new FormGroup({
    first_name: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    username: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    last_name: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    title: new FormControl(null),
    email: new FormControl(null),
    phone: new FormControl(null),
    last_login: new FormControl(null),
    profile_img: new FormControl(null),

    address: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),

    utilizador_caixa: new FormControl(null),
    utilizador_balcao: new FormControl(null),
    super_admin: new FormControl(null),
  });

  details: any = {
    is_blocked: null,
  };

  addressObj = {
    address: null,
    postalCode: null,
    locality: null,
  }

  @ViewChild('addEntRef', { static: false }) addEntRef;
  addModalRef = null;
  addModalConfig: any = null;

  // NEW UTILIZADOR FORM
  newForm = new FormGroup({
    password: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    password_conf: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
  });
  newFormSubmitted = false;
  passwordNotEqual = false;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('frontOfficeAlertRef', { static: false }) frontOfficeAlertRef;
  frontOfficeModalRef = null;
  frontOfficeAlertConfig: any = null;

  @ViewChild('dependenciasAlertRef', { static: false }) dependenciasAlertRef;
  dependenciasModalRef = null;
  dependenciasAlertConfig: any = null;

  // PERMISSIONS TABLE VARIABLES
  permissionsCol = [
    { key: 'null', name: '', type: 'text', sort: null, searchable: false, centered: false, class: '' },
    { key: 'null', name: 'Ler', type: 'text', sort: null, searchable: false, centered: true, class: 'permission-col' },
    { key: 'null', name: 'Criar', type: 'text', sort: null, searchable: false, centered: true, class: 'permission-col' },
    { key: 'null', name: 'Alterar', type: 'text', sort: null, searchable: false, centered: true, class: 'permission-col' },
    { key: 'null', name: 'Eliminar', type: 'text', sort: null, searchable: false, centered: true, class: 'permission-col' },
    { key: 'null', name: 'Permitir', type: 'text', sort: null, searchable: false, centered: true, class: 'permission-col' },
  ];
  permissions: Array<PermissionModule> = [{
    name: 'Condomínios',
    key: 'CONDOMINIOS',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    tabs: [
      {
        key: 'CONDOMINIOS_ENTIDADES',
        name: 'Entidades',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
      }
    ]
  },
  {
    name: 'Entidades',
    key: 'ENTIDADES',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
      allow: false,
    },
    tabs: [
      {
        key: 'ENTIDADES_CONTENCIOSO',
        name: 'Contencioso',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
          allow: false,
        },
        actions: [
          {
            key: 'ENTIDADES_CONTENCIOSO_CHANGE_FILE',
            name: 'Poder alterar o ficheiro do acordo assinado, depois de submetido',
            access: {
              allow: false,
            }
          }
        ]
      }
    ]
  },
  {
    name: 'Orçamentos',
    key: 'ORCAMENTOS',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  },
  {
    key: 'DESPESAS',
    name: 'Despesas',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    tabs: [
      {
        key: 'DESPESA_PAYMENT',
        name: 'Efetuar pagamento de despesa sem permissão de alterar despesa',
        access: {}
      }
    ]
  },
  {
    key: 'RECEBIMENTOS',
    name: 'Recebimentos',
    access: {
      create: false,
      read: false,
    }
  },
  {
    key: 'CREDITOS',
    name: 'Créditos',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
      allow: false
    }
  },
  {
    key: 'RECEITA_EXTRA',
    name: 'Receita Extra',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }
  },
  {
    key: 'RECIBOS',
    name: 'Recibos',
    access: {
      create: false,
      read: false,
      delete: false,
    }
  },
  {
    key: 'AVISOS',
    name: 'Avisos',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }
  },
  {
    key: 'PROCESSAMENTOS',
    name: 'Processamentos',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }
  },
  {
    key: 'FINANCAS',
    name: 'Finanças',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
      allow: false,
    },
    tabs: [
      {
        key: 'MOVIMENTOS_BANCARIOS',
        name: 'Movimentos Financeiros',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
          allow: false,
        },
      },
      {
        name: '* Reconciliações Bancárias',
        key: 'RECONCILIACOES_BANCARIAS',
        access: {
          read: false,
          create: false,
          delete: false,
          allow: false,
        },
        actions: [
          {
            name: 'Visualizar reconciliação bancária em curso',
            key: 'RECONCILIACOES_BANCARIAS_VIEW_CURRENT',
            access: {
              allow: false,
            }
          },
          {
            name: 'Visualizar registo de atividade de reconciliações bancárias',
            key: 'RECONCILIACOES_BANCARIAS_VIEW_REG_ATIVIDADE',
            access: {
              allow: false,
            }
          },
          {
            name: 'Continuar reconciliação bancária iniciada por outro utilizador',
            key: 'RECONCILIACOES_BANCARIAS_OTHER_USER_CONTINUE',
            access: {
              allow: false,
            }
          },
          {
            name: 'Poder efetuar, alterar e/ou eliminar movimentos financeiros com reconciliação em curso',
            key: 'RECONCILIACOES_BANCARIAS_CHANGE_MOVEMENTS_EM_CURSO',
            access: {
              allow: false,
            }
          },
          {
            name: 'Poder efetuar comunicações de avisos e cobranças de reconciliações efetuadas por outros utilizadores',
            key: 'RECONCILIACOES_BANCARIAS_COMMUNICATE_OTHER_USER_NOTICES',
            access: {
              allow: false,
            }
          },
        ]
      },

    ],
  },
  {
    key: 'CONTAS_CORRENTES',
    name: 'Contas Correntes',
    access: {
      read: false,
    },
    tabs: [
      {
        name: 'Frações',
        key: 'CONTAS_CORRENTES_FRACCOES',
        access: {
          read: false,
        }
      },
      {
        name: 'Condóminos',
        key: 'CONTAS_CORRENTES_CONDOMINOS',
        access: {
          read: false,
        }
      },
      {
        name: 'Fornecedores',
        key: 'CONTAS_CORRENTES_FORNECEDORES',
        access: {
          read: false,
        }
      },
    ],
  },
  {
    key: 'RELATORIOS',
    name: 'Relatórios',
    access: {
      read: false,
    },
    tabs: [
      {
        name: 'Balancete',
        key: 'BALANCETE',
        access: {
          read: false,
        }
      },
      {
        name: 'Listagem Despesas',
        key: 'LISTAGEM_DESPESAS',
        access: {
          read: false,
        }
      },
      {
        name: 'Justificação Orçamento',
        key: 'JUSTIFICACAO_ORCAMENTO',
        access: {
          read: false,
        }
      },
      {
        name: 'Resumo Financeiro',
        key: 'RESUMO_FINANCEIRO',
        access: {
          read: false,
        }
      },
      {
        name: 'Mapa de Quotas',
        key: 'MAPA_QUOTAS',
        access: {
          read: false,
        }
      },
      {
        name: 'Caixas',
        key: 'CAIXAS',
        access: {
          read: false,
        }
      },
    ]
  },
  {
    key: 'CORRESPONDENCIA',
    name: 'Correspondência',
    access: {
      create: false,
      read: false,
      delete: false,
      allow: false,
    },
    tabs: [
      {
        key: 'CORRESPONDENCIA_AC',
        name: '* Avisos e Cobranças',
        access: {
          read: false,
          create: false,
        }
      },
      {
        key: 'CORRESPONDENCIA_C',
        name: '* Comunicações',
        access:
        {
          read: false,
          create: false,
          allow: false,
        },
        actions:
          [
            {
              key: 'CORRESPONDENCIA_C_COPIAR_EMAILS',
              name: 'Copiar emails',
              access:
              {
                allow: false
              }
            },
            {
              key: 'CORRESPONDENCIA_C_ASSINAR_POR_TODOS',
              name: 'Selecionar qualquer utilizador como remetente',
              access:
              {
                allow: false
              }
            },
          ]
      },
      {
        key: 'CORRESPONDENCIA_RC',
        name: '* Registo de Comunicações',
        access:
        {
          read: false,
          delete: false,
        },
      },
      {
        key: 'CORRESPONDENCIA_CC',
        name: '* Custos Comunicações',
        access:
        {
          read: false,
          delete: false,
          allow: false,
        },
        actions:
          [
            {
              key: 'CORRESPONDENCIA_CC_REMOVE_REGISTO_ASSOCIADO',
              name: 'Remover registo associado ao custo',
              access:
              {
                allow: false,
              }
            },
            {
              key: 'CORRESPONDENCIA_CC_VER_DETALHE_CUSTO_INDIVIDUAL',
              name: 'Visualizar detalhes do custo individual',
              access:
              {
                allow: false,
              }
            },
            {
              key: 'CORRESPONDENCIA_CC_VER_DETALHE_CUSTO_TOTAL',
              name: 'Visualizar custos totais',
              access:
              {
                allow: false,
              }
            }
          ]
      }
    ]
  },
  {
    key: 'ASSEMBLEIAS',
    name: 'Assembleias',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
      allow: false,
    },
    tabs: [
      {
        key: 'ASSEMBLEIAS_ATA',
        name: '* Ata',
        access: {
          allow: false,
        },
        actions: [
          {
            key: 'UPLOAD_ATA_AFTER_SENT',
            name: 'Efetuar upload da ata assinada após a cópia de ata já ter sido enviada.',
            access: {
              allow: false,
            },
          },
          {
            key: 'DELETE_ATA_AFTER_SENT',
            name: 'Remover a ata assinada após a cópia de ata já ter sido enviada.',
            access: {
              allow: false,
            },
          }
        ]
      }
    ]
  },
  {
    key: 'CAIXA_VERTIS',
    name: 'Caixa Vertis',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
    }
  },
  {
    key: 'PROPOSTAS',
    name: 'Propostas',
    access: {
      read: false,
      update: false,
      delete: false,
    }
  },
  {
    key: 'CONFIGURACOES',
    name: 'Configurações',
    access: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    tabs: [
      {
        key: 'RUBRICAS',
        name: 'Rubricas',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
      },
      {
        key: 'TEXTOS',
        name: 'Textos',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        }
      },
      {
        key: 'BANCOS',
        name: 'Bancos',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        }
      },
      {
        key: 'CONTAS',
        name: 'Contas',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        }
      },
      {
        key: 'DOC_ASSEMBLEIAS',
        name: 'Doc. Assembleias',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        }
      },
      {
        key: 'ORDEM_TRABALHOS',
        name: 'Ordem Trabalhos',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        }
      },
      {
        key: 'CONFIGURACOES_EMAILS',
        name: 'Emails',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        }
      },
      {
        key: 'CONFIGURACOES_MINUTAS',
        name: 'Minutas',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        }
      },
      {
        key: 'CONFIGURACOES_AVISOS_COBRANCA',
        name: 'Avisos e Cobrança',
        access: {
          create: false,
          read: false,
          update: false,
          delete: false,
        }
      },
    ]
  },
  ]
  dependenciasListOrig: Array<{ nome, motivo, permission: { key: string, access: AccessOption, value: boolean }, dependency: { key: string, access: AccessOption, value: boolean } }> = [
    {
      nome: '<i><b>Custos Comunicação</b> - Remover registo associado ao custo (Permitir)</i>',
      motivo: 'É necessário ter igualmente a permissão <i><b>Custos Comunicações</b> (Eliminar)</i>',
      permission:
      {
        key: 'CORRESPONDENCIA_CC_REMOVE_REGISTO_ASSOCIADO',
        access: 'allow',
        value: true,
      },
      dependency:
      {
        key: 'CORRESPONDENCIA_CC',
        access: 'delete',
        value: true,
      }
    },
    {
      nome: '<i><b>Reconciliações Bancárias</b> - Continuar reconciliação bancária iniciada por outro utilizador (Permitir)</i>',
      motivo: 'É necessário ter igualmente a permissão <i><b>Reconciliações Bancárias</b> (Criar)</i>',
      permission:
      {
        key: 'RECONCILIACOES_BANCARIAS_OTHER_USER_CONTINUE',
        access: 'allow',
        value: true,
      },
      dependency:
      {
        key: 'RECONCILIACOES_BANCARIAS',
        access: 'create',
        value: true,
      }
    },
    {
      nome: '<i><b>Reconciliações Bancárias</b> - Continuar reconciliação bancária iniciada por outro utilizador (Permitir)</i>',
      motivo: 'É necessário ter igualmente a permissão <i><b>Reconciliações Bancárias</b> - Visualizar reconciliação bancária em curso (Permitir)</i>',
      permission:
      {
        key: 'RECONCILIACOES_BANCARIAS_OTHER_USER_CONTINUE',
        access: 'allow',
        value: true,
      },
      dependency:
      {
        key: 'RECONCILIACOES_BANCARIAS_VIEW_CURRENT',
        access: 'allow',
        value: true,
      }
    },
  ];




  dependenciasList: Array<{ nome, motivo, permission: { key: string, access: AccessOption, value: boolean }, dependency: { key: string, access: AccessOption, value: boolean } }> = [];
  superAdminOrig = false;
  selPermissionModel = null;

  days = ['Seg.', 'Terç.', 'Qua.', 'Qui.', 'Sex.', 'Sáb.', 'Dom.'];
  accessDays = [false, false, false, false, false, false, false];
  accessDaysObj = [{ sel: false }, { sel: false }, { sel: false }, { sel: false }, { sel: false }, { sel: false }, { sel: false }];

  selDaysOfWeek = null;
  horaInicio = null;
  horaFim = null;

  caixa = false;

  notAdmin = false;

  format = 'HH:mm';
  locale = 'pt-PT';

  subsMsg: Subscription = null;
  isZIndexZero = false;

  actualFrontOfficeUSer = null;

  paymentDespesas = false;
  paymentCreditos = false;



  permissionsOrig: Array<{ key, access: AccessOptions }> = [];

  constructor(public api: ApiService,
    public toastr: ToastrService,
    public utils: UtilitiesService,
    public route: ActivatedRoute,
    public message: MessageService,
    public router: Router,
    public userSession: UserSessionService,
    public modalService: SuiModalService,
    public appConfig: AppConfigService) {
    this.api.getUtilizadorFrontOffice().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.actualFrontOfficeUSer = res.data.first_name + ' ' + res.data.last_name;
      } else { }
    }, err => { });

    this.notAdmin = !this.userSession.isAdmin();

    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'CAIXA_VERTIS') {
        switch (msg.cmd) {
          case 'HIDE_INPUT':
            this.isZIndexZero = true;
            break;
          case 'SHOW_INPUT':
            this.isZIndexZero = false;
            break;
        }
      }
    });
  }

  ngOnInit() {
    this.animate();

    this.getDetails();
    //this.getPermissions();

    this.setTab('geral');
  }

  ngAfterViewInit() {
    this.addModalConfig = new TemplateModalConfig<IContext, string, string>(this.addEntRef);
    this.addModalConfig.closeResult = "closed";
    this.addModalConfig.size = 'mini';
    this.addModalConfig.transition = 'fade up';
    this.addModalConfig.transitionDuration = 400;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.frontOfficeAlertConfig = new TemplateModalConfig<IContext, string, string>(this.frontOfficeAlertRef);
    this.frontOfficeAlertConfig.closeResult = "closed";
    this.frontOfficeAlertConfig.size = 'mini';
    this.frontOfficeAlertConfig.transition = 'fade';
    this.frontOfficeAlertConfig.transitionDuration = 250;

    this.dependenciasAlertConfig = new TemplateModalConfig<IContext, string, string>(this.dependenciasAlertRef);
    this.dependenciasAlertConfig.closeResult = "closed";
    this.dependenciasAlertConfig.size = 'small';
    this.dependenciasAlertConfig.transition = 'fade';
    this.dependenciasAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    if (this.subsMsg) this.subsMsg.unsubscribe();
  }

  public animate(transitionName: string = "fade up") {
    this.transitionController.animate(
      new Transition(transitionName, 400, TransitionDirection.In));
  }

  daysOfWeek() {
    if (this.selDaysOfWeek !== '0') {
      this.accessDays = this.appConfig.daysOfWeekOpts.find(el => (el.value === this.selDaysOfWeek)).model;

      this.accessDays.forEach((el, i) => {
        this.accessDaysObj[i]['sel'] = el;
      });

    }
  }

  getDetails() {
    this.api.getUtilizadorDetails(this.route.snapshot.params.id).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.details = res.data;

        this.details.super_admin = (this.details.super_admin === '1');
        this.details.is_blocked = (this.details.is_blocked === '1');
        this.details.utilizador_caixa = (this.details.utilizador_caixa === '1');
        this.details.utilizador_balcao = (this.details.utilizador_balcao === '1');

        let auxList = [];
        try {
          this.details.permissions = JSON.parse(this.details.permissions);
        } catch (e) { }

        this.selPermissionModel = this.details.permission_model;

        this.permissionsOrig = JSON.parse(JSON.stringify(this.details.permissions))
        this.superAdminOrig = this.details.super_admin;
        this.updateAllPermissions();

        if (this.details.address) {
          let auxAddr = JSON.parse(this.details.address);
          this.addressObj.address = auxAddr.address;
          this.addressObj.postalCode = auxAddr.postalCode;
          this.addressObj.locality = auxAddr.locality;
        }

        try {
          this.accessDays = JSON.parse(this.details.access_days);

          this.accessDays.forEach((el, i) => {
            this.accessDaysObj[i]['sel'] = el;
          });

          // HANDLE TIME INTERVAL
          let inicioAux = (this.details.hora_inicio) ? this.details.hora_inicio.split(':') : null;
          let fimAux = (this.details.hora_fim) ? this.details.hora_fim.split(':') : null;

          if (inicioAux.length === 2) this.horaInicio = new Date(new Date().setHours(inicioAux[0], inicioAux[1]));
          if (fimAux.length === 2) this.horaFim = new Date(new Date().setHours(fimAux[0], fimAux[1]));

          this.selDaysOfWeek = this.details.access_days_model;
        } catch (e) { }

        this.restoreForm('geral');
      } else { }
    }, err => { });
  }

  setTab(targetTab) {
    this.selTab = targetTab;
  }

  restoreForm(targetTab) {
    switch (targetTab) {
      case 'geral':
        this.geralForm.patchValue({
          first_name: this.details.first_name,
          username: this.details.username,
          last_name: this.details.last_name,
          title: this.details.title,
          email: this.details.email,
          phone: this.details.phone,
          last_login: this.details.last_login,
          profile_img: this.details.profile_img,

          address: this.addressObj.address,
          postalCode: this.addressObj.postalCode,
          locality: this.addressObj.locality,

          utilizador_caixa: this.details.utilizador_caixa,
          utilizador_balcao: this.details.utilizador_balcao,
        });
        break;
      case 'permissions':
        this.details.super_admin = this.superAdminOrig;
        this.selPermissionModel = this.details.permission_model;
        this.updateAllPermissions();
        break;
      case 'horario':
        try {
          this.accessDays = JSON.parse(this.details.access_days);

          this.accessDays.forEach((el, i) => {
            this.accessDaysObj[i]['sel'] = el;
          });

          // HANDLE TIME INTERVAL
          let inicioAux = (this.details.hora_inicio) ? this.details.hora_inicio.split(':') : null;
          let fimAux = (this.details.hora_fim) ? this.details.hora_fim.split(':') : null;

          if (inicioAux.length === 2) this.horaInicio = new Date(new Date().setHours(inicioAux[0], inicioAux[1]));
          if (fimAux.length === 2) this.horaFim = new Date(new Date().setHours(fimAux[0], fimAux[1]));

          this.selDaysOfWeek = this.details.access_days_model;
        } catch (e) {
          this.accessDays = [false, false, false, false, false, false, false];
          this.accessDaysObj = [{ sel: false }, { sel: false }, { sel: false }, { sel: false }, { sel: false }, { sel: false }, { sel: false }];

          this.selDaysOfWeek = null;
          this.horaInicio = null;
          this.horaFim = null;
        }
        break;

    }
  }

  isPhoneValid = true;
  isEmailValid = true;
  formSubmitted(targetTab) {
    let data = null;

    switch (targetTab) {
      case 'geral':
        this.submittingForm = true;

        this.isPhoneValid = true;
        this.isEmailValid = true;

        if (!this.geralForm.valid) return;

        data = this.geralForm.getRawValue();
        data.address = JSON.stringify({
          address: data.address,
          postalCode: data.postalCode,
          locality: data.locality,
        });

        // CHECK PHONE NUMBER (telefone_1) - 9 digits
        if (data.phone && (!this.utils.isNumber(data.phone.replace(/\s/g, '').replace('+', '')) || (data.phone.replace(/\s/g, '').length !== 9 && data.phone.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK EMAIL ADDRESS (email_1) - email pattern
        if (data.email && !this.utils.validateEmail(data.email)) {
          this.isEmailValid = false;
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        this.loading = true;

        if (data.utilizador_balcao && this.actualFrontOfficeUSer) {

          this.frontOfficeModalRef = this.modalService
            .open(this.frontOfficeAlertConfig)
            .onApprove(() => {
              this.api.updateUtilizadoresGeral(this.details.id, data.first_name, data.username, data.last_name, data.title, data.email, data.phone, data.last_login, data.is_blocked, data.is_admin, data.address, data.profile_img, data.utilizador_caixa, true).subscribe(res => {
                if (res['success']) {
                  // API RETURN SUCCESS
                } else {
                  this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
                }
                this.submittingForm = false;
                this.loading = false;
              }, err => {
                this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
                this.submittingForm = false;
                this.loading = false;
              });
            })
            .onDeny(() => {
              this.api.updateUtilizadoresGeral(this.details.id, data.first_name, data.username, data.last_name, data.title, data.email, data.phone, data.last_login, data.is_blocked, data.is_admin, data.address, data.profile_img, data.utilizador_caixa, false).subscribe(res => {
                if (res['success']) {
                  this.geralForm.patchValue({ utilizador_balcao: false });
                } else {
                  this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
                }
                this.submittingForm = false;
                this.loading = false;
              }, err => {
                this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
                this.submittingForm = false;
                this.loading = false;
              });
            });
        } else {
          this.api.updateUtilizadoresGeral(this.details.id, data.first_name, data.username, data.last_name, data.title, data.email, data.phone, data.last_login, data.is_blocked, data.is_admin, data.address, data.profile_img, data.utilizador_caixa, data.utilizador_balcao).subscribe(res => {
            if (res['success']) {
              // API RETURN SUCCESS
            } else {
              this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            }
            this.submittingForm = false;
            this.loading = false;
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.submittingForm = false;
            this.loading = false;
          });
        }
        break;
      case 'permissions':
        let permissionsObj = this.getPermissionsObj();
        console.log(JSON.stringify(permissionsObj));
        this.loading = true;

        this.api.updateUtilizadoresPermissions(this.details.id, permissionsObj, this.selPermissionModel, this.details.super_admin).subscribe(res => {
          if (res['success']) {
            // API RETURN SUCCESS
            this.details.permissions = JSON.parse(JSON.stringify(permissionsObj));
            this.superAdminOrig = this.details.super_admin;
            this.details.permission_model = this.selPermissionModel;
          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          }
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;
      case 'horario':
        this.loading = true;

        let horaInicio = formatDate(this.horaInicio, this.format, this.locale);
        let horaFim = formatDate(this.horaFim, this.format, this.locale);

        this.accessDaysObj.forEach((el, i) => {
          this.accessDays[i] = el.sel;
        });

        this.api.updateUtilizadoresSchedule(this.details.id, horaInicio, horaFim, JSON.stringify(this.accessDays), this.selDaysOfWeek).subscribe(res => {
          if (res['success']) {
            // API RETURN SUCCESS

          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          }
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;

    }
  }

  getPermissionsObj(): Array<{ key, access }> {
    let permissionsObj: Array<{ key, access }> = [];

    this.permissions.forEach(el => {
      permissionsObj.push({ key: el.key, access: el.access });
      this.getPermissionsRec(permissionsObj, el)
    });

    return permissionsObj;
  }


  getPermission(key: string): Permission {
    let result = null;
    this.permissions.forEach(module => {
      if (module.key === key) {
        result = module;
        return;
      }
      if (!('tabs' in module)) return;

      module.tabs.forEach(tab => {
        if (tab.key === key) {
          result = tab;
          return;
        }
        if (!('actions' in tab)) return;

        tab.actions.forEach(action => {
          if (action.key === key) {
            result = action;
            return;
          }
        });
      });
    });
    return result;
  }

  getPermissionsRec(permissionsObj: Array<{ key, access }>, permission: PermissionModule | PermissionTab | Permission): void {
    if ('tabs' in permission) {
      permission.tabs.forEach(tab => {
        permissionsObj.push({ key: tab.key, access: tab.access });
        this.getPermissionsRec(permissionsObj, tab);
      });
    } else if ('actions' in permission) {
      permission.actions.forEach(action => {
        permissionsObj.push({ key: action.key, access: action.access });
        this.getPermissionsRec(permissionsObj, action);
      });
    }
  }

  permissionModelSelected() {
    let selectedModel = this.appConfig.permissionAccessOpts.find(el => el.value === this.selPermissionModel);

    if (selectedModel) {
      this.updateAllPermissions(selectedModel.model);
    }
  }

  clearForm() {
    this.newForm.reset();
    this.newFormSubmitted = false;
    this.passwordNotEqual = false;
  }

  action(type) {
    switch (type) {
      case 'change-password':
        this.addModalRef = this.modalService.open(this.addModalConfig);
        break;
      case 'block-user':
        this.presentAlert().then(res => {
          if (res) {
            this.api.accessControl(this.details.id, !this.details.is_blocked).subscribe(res => {
              if (res['success']) {
                // API RETURN SUCCESS
                this.details.is_blocked = !this.details.is_blocked;
              } else {
                this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
              }
            }, err => {
              this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            });
          }
        });
        break;
    }
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }

  onPasswordChanged() {
    this.passwordNotEqual = false;
  }

  changePassword() {
    this.newFormSubmitted = true;

    if (!this.newForm.valid) return;

    let data = this.newForm.getRawValue();

    if (data.password !== data.password_conf) {
      this.passwordNotEqual = true;
      return;
    }

    this.loadingModalForm = true;
    this.api.changePassword(this.details.id, data.password).subscribe(res => {
      if (res.success) {
        this.clearForm();
        this.addModalRef.approve();
        this.loadingModalForm = false;
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loadingModalForm = false;
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModalForm = false;
    });
  }

  updateAllPermissions(permissionsPattern = this.details.permissions) {
    this.permissions.forEach((el, i_mod) => {
      this.updatePermission(el, permissionsPattern);

      if ('tabs' in el) {
        el.tabs.forEach((tab, i_tab) => {
          this.updatePermission(tab, permissionsPattern);

          if ('actions' in tab) {
            tab.actions.forEach(action => {
              this.updatePermission(action, permissionsPattern);
            });
          }
        });
      }
    });
  }

  updatePermission(el: Permission, permissionsPattern: Array<Permission>) {
    let newPermission = permissionsPattern.find(el2 => el2.key === el.key);
    let access = newPermission ? newPermission.access : null;
    this.updatePermissionWithValue(el, access)
  }

  updatePermissionWithValue(currPermission: Permission, newPermissions: AccessOptions) {
    if (!newPermissions) {
      if (currPermission.access.hasOwnProperty('create')) this.checkPermission(currPermission, 'create', false)
      if (currPermission.access.hasOwnProperty('read')) this.checkPermission(currPermission, 'read', false)
      if (currPermission.access.hasOwnProperty('update')) this.checkPermission(currPermission, 'update', false)
      if (currPermission.access.hasOwnProperty('delete')) this.checkPermission(currPermission, 'delete', false)
      if (currPermission.access.hasOwnProperty('allow')) this.checkPermission(currPermission, 'allow', false)
    } else {
      if (currPermission.access.hasOwnProperty('create')) this.checkPermission(currPermission, 'create', newPermissions.hasOwnProperty('create') ? newPermissions.create : false);
      if (currPermission.access.hasOwnProperty('read')) this.checkPermission(currPermission, 'read', newPermissions.hasOwnProperty('read') ? newPermissions.read : false);
      if (currPermission.access.hasOwnProperty('update')) this.checkPermission(currPermission, 'update', newPermissions.hasOwnProperty('update') ? newPermissions.update : false);
      if (currPermission.access.hasOwnProperty('delete')) this.checkPermission(currPermission, 'delete', newPermissions.hasOwnProperty('delete') ? newPermissions.delete : false);
      if (currPermission.access.hasOwnProperty('allow')) this.checkPermission(currPermission, 'allow', newPermissions.hasOwnProperty('allow') ? newPermissions.allow : (newPermissions.hasOwnProperty('payment') ? newPermissions['payment'] : false));
    }
  }

  checkPermission(el: PermissionModule | PermissionTab | Permission, accessOpt: AccessOption, value: boolean): boolean {
    let allDependenciesChecked = true;
    //Dependencia que precisa ser satisfeita
    let dependencias = [];
    this.dependenciasListOrig.forEach(dep => {
      if (dep.permission.key === el.key && dep.permission.access === accessOpt && dep.permission.value === value) {
        dependencias.push(dep);
      }
    });

    dependencias.forEach(dependencia => {
      if (dependencia) {
        let accessOptRequired = dependencia.dependency.access;
        let valueRequired = dependencia.dependency.value;

        //Permissao atual
        let permissaoAtual = this.getPermission(dependencia.dependency.key);
        let unsatisfiedDependency = !permissaoAtual || !(accessOptRequired in permissaoAtual.access) || permissaoAtual.access[accessOptRequired] !== valueRequired;
        if (unsatisfiedDependency) {
          this.dependenciasList.push(dependencia);
          allDependenciesChecked = false;
          setTimeout(() => {
            el.access[accessOpt] = !value
            let actualPermissionsPattern = this.getPermissionsObj();
            this.updateAllPermissions(actualPermissionsPattern);
          });
        }
      }
    });

    if (allDependenciesChecked) {
      el.access[accessOpt] = value;
    }

    let dependenciaFrom = [];

    this.dependenciasListOrig.forEach(dep => {
      if (dep.dependency.key === el.key && dep.dependency.access === accessOpt) {
        dependenciaFrom.push(dep);
      }
    });

    dependenciaFrom.forEach(isADependencia => {
      //O novo valor não satisfaz as dependencias.
      if (isADependencia && isADependencia.dependency.value !== value) {
        let permissaoDependente = this.getPermission(isADependencia.permission.key);
        if (permissaoDependente) {
          allDependenciesChecked = false;
          setTimeout(() => {
            permissaoDependente.access[isADependencia.permission.access] = !isADependencia.permission.value;
          });
        }
      }
    });


    return allDependenciesChecked;
  }

  presentDependenciesAlert(): Promise<boolean> {
    return new Promise((resolve) => {
      this.dependenciasModalRef = this.modalService
        .open(this.dependenciasAlertConfig)
        .onApprove(() => {
          this.dependenciasList = [];
          resolve(true);
        })
        .onDeny(() => {
          this.dependenciasList = [];
          resolve(false);
        });
    });
  }



  checkChildren(entry: PermissionModule | PermissionTab | Permission, tab: AccessOption, value = null) {
    if (value === null) {
      value = tab in entry.access ? entry.access[tab] : false;
    } else if (tab in entry.access) {
      this.checkPermission(entry, tab, value);
    }

    this.checkReadDependency(tab, entry, value);

    if ('tabs' in entry) {
      entry.tabs.forEach(el => this.checkChildren(el, tab, value));
      return;
    }
    if ('actions' in entry) {
      entry.actions.forEach(el => this.checkChildren(el, tab, value));
      return;
    }
  }

  updateParentsValue(action: AccessOption, i_mod: number, i_tab: number = null) {
    let module = this.permissions[i_mod];
    if (!this.isNewPermission(module)) return;

    this.updateParentsValueRec(action, i_mod, i_tab);
  }

  updateParentsValueRec(action: AccessOption, i_mod: number, i_tab: number = null) {
    if (i_tab == null) {
      //It is a module
      let value = !this.permissions[i_mod].tabs.find(el => el.access.hasOwnProperty(action) && !el.access[action])
      this.checkPermission(this.permissions[i_mod], action, value);
      return;
    }
    if (i_tab != null) {
      let value = !this.permissions[i_mod].tabs[i_tab].actions.find(el => el.access.hasOwnProperty(action) && !el.access[action])
      this.checkPermission(this.permissions[i_mod].tabs[i_tab], action, value);
      this.updateParentsValueRec(action, i_mod);
      return;
    }
  }

  newPermissions = ['CONFIGURACOES', 'CONTAS_CORRENTES', 'CORRESPONDENCIA', 'RELATORIOS', 'FINANCAS']
  isNewPermission(module: PermissionModule): boolean {
    return !!this.newPermissions.find(el => el === module.key);
  }

  checkReadDependency(accessOpt: AccessOption, entry: PermissionModule | PermissionTab | Permission, value: boolean) {
    if (accessOpt === 'read' && !value) {
      this.updatePermissionWithValue(entry, null);
      return;
    }

    if (accessOpt in entry.access && entry.access[accessOpt] && entry.access.hasOwnProperty('read')) {
      this.checkPermission(entry, 'read', true);
    }
  }

  // HTML events
  checkModule(module: PermissionModule, accessOpt: AccessOption) {
    this.dependenciasList = [];
    let dependencyChecked = this.checkPermission(module, accessOpt, module.access[accessOpt]);
    if (dependencyChecked) {
      this.checkChildren(module, accessOpt);
    }
    if (this.dependenciasList.length > 0) {
      this.presentDependenciesAlert();
    }

  }

  checkTab(tab: PermissionTab, accessOpt: AccessOption, i_mod: number) {
    this.dependenciasList = [];

    let dependencyChecked = this.checkPermission(tab, accessOpt, tab.access[accessOpt]);
    if (dependencyChecked) {
      this.checkChildren(tab, accessOpt);
      this.updateParentsValue(accessOpt, i_mod)
    }
    if (this.dependenciasList.length > 0) {
      this.presentDependenciesAlert();
    }

  }

  checkAction(action: Permission, accessOpt: AccessOption, i_mod: number, i_tab: number) {
    this.dependenciasList = [];

    let dependencyChecked = this.checkPermission(action, accessOpt, action.access[accessOpt]);
    if (dependencyChecked) {
      this.updateParentsValue(accessOpt, i_mod, i_tab);
    }
    if (this.dependenciasList.length > 0) {
      this.presentDependenciesAlert();
    }
  }
}
