import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

import { AppConfigService } from '../app-config.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  appVersion:string = null;
  
  constructor(public router: Router,
              public appConfig: AppConfigService) {
    this.sideMenuEntries = this.appConfig.getSideMenuEntries()
    this.updateMenuSel();
  }

  ngOnInit() {
    // Listen to navigation changes
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) this.updateMenuSel();
    });

    this.appVersion = environment.appVersion;
  }


  sideMenuEntries = [];


  updateMenuSel() {
    this.sideMenuEntries.forEach(el => {
      el.selected = false;

        if (el.hasOwnProperty('routes')) {
          el.routes.forEach(route => {
            if (this.router.url.indexOf(el.route) != -1 || this.router.url.indexOf(route) != -1) {
              if (!el.sideMenu) {
                this.appConfig.menuArr.find(menu => (menu.key === el.key)).selected = true;
              } else {
                el.selected = true;
              }
            }
          });
        } else {
          if (this.router.url.indexOf(el.route) != -1) {
            if (!el.sideMenu) {
              this.appConfig.menuArr.find(menu => (menu.key === el.key)).selected = true;
            } else {
              el.selected = true;
            }
          }
        }

    });
  }

}
