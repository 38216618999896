import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { ApiService } from '../api.service';
import { forkJoin, Observable } from 'rxjs';
import * as moment from 'moment';
import { UtilitiesService } from '../utilities.service';


@Component({
  selector: 'app-listagem-recibos-utilizador',
  templateUrl: './listagem-recibos-utilizador.component.html',
  styleUrls: ['./listagem-recibos-utilizador.component.scss']
})
export class ListagemRecibosUtilizadorComponent implements OnInit {


  @Input() data = {
    utilizadores: null,
    startDate: null,
    endDate: null
  }

  recibos = [];

  now: Date = null;
  filename: string = 'Listagem_Recibos';
  title: string = 'Listagem de Recibos';

  @ViewChild('pdf', { static: false }) pdfController;


  constructor(public appConfig: AppConfigService,
    public api: ApiService,
    public utils: UtilitiesService) { }

  ngOnInit() {
  }

  clearData(timeout:number = 1500) {
    setTimeout(() => this.recibos = [], timeout);
  }

  updateData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.now = new Date();

     
      let req = []

      if (this.data.utilizadores.find(el => el === 'all')) this.data.utilizadores = ['all'];

      this.data.utilizadores.forEach(u => {
        req.push(this.api.getListagemRecibos(this.data.startDate, this.data.endDate, u));
      });
      
      forkJoin(req).subscribe(resRecibos => {
        if (!resRecibos.find(el => !el.hasOwnProperty('success') || (el.hasOwnProperty('success') && !el.success))) {
          
          // let reqDetails = [];
          // //Por cada utilizador
          // resRecibos.forEach(r => {
          //   console.log(r.data);
          //   if (r.data.length) {
          //     //Por cada recibo
          //     r.data.forEach(recibo => {
          //       let nome = (recibo.utilizador as string).split(" ");
          //       this.recibos.push({numero: recibo.n_recibo, utilizador: nome[0].charAt(0).toUpperCase() + nome[0].charAt(nome.length - 1).toUpperCase(), condominio: recibo.cod_condominio + " - " + recibo.nome_condominio, data: recibo.data, valor: recibo.valor });
          //       reqDetails.push(this.api.getRecibosDetails(recibo.n_recibo));
          //     });
          //   }
          // });

          // //Ir buscar os detalhes de cada recibo
          // forkJoin(reqDetails).subscribe(resDetails => {
          //   var self = this;
          //   if (!resDetails.find(el => !el.hasOwnProperty('success') || (el.hasOwnProperty('success') && !el.success))) {
          //     resDetails.forEach(function(el, i) {
          //       self.recibos[i]['valor'] = el.data.valor
          //       self.recibos[i]['linhas_recibo'] = el.linhas_recibo;
          //     });

          //     console.log(this.recibos);
          //     resolve(true);
          //   }
            
          // }, err => {
          //   reject(err);
          // });

          this.recibos = [];
          //Cada utilizador
          resRecibos.forEach(ru => {
            if (ru.data.length) {
              
              //Cada recibo
              ru.data.forEach(r => {
                let nome = (r.utilizador as string).split(" ");
                let valor: number = 0;
                r.details.forEach(d => {
                  if (d.tipo_doc === 'C') d.valor = - Number(d.valor);
                  valor += Number(d.valor);
                });
                
                r.details.sort(function(a, b){
                  var A = moment(a.data, "DD-MM-YYYY").toDate();
                  var B = moment(b.data, "DD-MM-YYYY").toDate();
                  return A.valueOf() - B.valueOf();
                });
                
                this.recibos.push({ 
                  linhas_recibo: r.details, 
                  valor: valor, 
                  numero: r.n_recibo, 
                  utilizador: nome[0].charAt(0).toUpperCase() + nome[1].charAt(0).toUpperCase(), 
                  condominio: r.cod_condominio + " - " + r.nome_condominio, 
                  data: r.criado_em 
                });
              })
            }
          });
          this.recibos.sort(function(a, b){
            var A = moment(a.data, "DD-MM-YYYY").toDate();
            var B = moment(b.data, "DD-MM-YYYY").toDate();
            return A.valueOf() - B.valueOf();
          });
        }
        resolve(true);
      }, err => {
        reject(err)
      });

    });
  }

  export() {
    return this.pdfController.export();
  }

}
