import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { AppStateService } from '../app-state.service';
import { MessageService } from '../message.service';
import { FraccoesService } from '../business-logic-services/fraccoes.service';
import { registoProprietario, registoProprietarioDivida, saveProprietario, saveProprietarioData, saveProprietarioMethod } from '../business-model-interfaces/fraccoes';
import { FraccoesNavState } from '../navigation';
import { CondominoFraccao, estadoContencioso } from '../condominos';
import { CondominosService } from '../business-logic-services/condominos.service';


interface registoProprietarioList extends registoProprietario {
  checked:boolean, 
}

interface registoProprietarioEdit {
  id_registo:string,
  nome: string,
  cod_proprietario,
  data_inicio:Date,
  divida:registoProprietarioDivida,
}

@Component({
  selector: 'app-fraccoes-details',
  templateUrl: './fraccoes-details.component.html',
  styleUrls: ['./fraccoes-details.component.scss']
})
export class FraccoesDetailsComponent implements OnInit {

  @ViewChild('pageContainer', {static: false}) pageContainer: any;

  transitionController = new TransitionController();
  submittingForm = false;
  loading = false;
  loadingModal = false;
  searchable: boolean = true;

  selTab = null;

  // GERAL FORM
  geralForm = new FormGroup({
    cod: new FormControl(null, { validators: Validators.required }),
    nome: new FormControl(null, { validators: Validators.required }),
    cod_zona: new FormControl(null, { validators: Validators.required }),
    cod_proprietario: new FormControl(null, { validators: Validators.required }),
    nome_proprietario: new FormControl(null),
    cod_inquilino: new FormControl(null),
    nome_inquilino: new FormControl(null),

    permilagem: new FormControl(null, { validators: Validators.required }),
    permilagem_aux_1: new FormControl(null),
    permilagem_aux_2: new FormControl(null),
    
    area: new FormControl(null),
    n_votos: new FormControl(null),
    
    paga_seg_colect: new FormControl(null),
    capital_obrigat: new FormControl(null),
    capital_pagar: new FormControl(null),
    capital_individ: new FormControl(null),
  });

  estadoContOpts = [];
  zonasOpts = [];
  propSelected = null;
  inqSelected = null;

  // QUOTAS FORM
  quotasForm = new FormGroup({
    actual_orc: new FormControl(null),
    quota_orc: new FormControl(null),

    actual_fr: new FormControl(null),
    quota_fr: new FormControl(null),

    actual_seg: new FormControl(null),
    quota_seg: new FormControl(null),
  });

  // OBSERVACOES FORM
  obsForm = new FormGroup({
    obs: new FormControl(null)
  });
  
  details: any = null;
  isCreate: boolean = false;
  fraccaoId = null;
  condominioCod = null;

  codAlreadyExiste = false;

  fracList = [];
  zonaSelOpts: Array<any> = [];

  totalPermilagem:number = null;
  permilagemError = false;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('rmProprietarioAlertRef', { static: false }) rmProprietarioAlertRef;
  rmProprietarioModalRef = null;
  rmProprietarioAlertConfig: any = null;

  @ViewChild('addProprietarioAlertRef', { static: false }) addProprietarioAlertRef;
  addProprietarioModalRef = null;
  addProprietarioAlertConfig: any = null;

  @ViewChild('recibosAlertRef', { static: false }) recibosAlertRef;
  recibosModalRef = null;
  recibosAlertConfig: any = null;

  comp = 'fraccoes';
  initState = null;
  prevState = null;
  
  permilagemZona = null;

  prevFraccaoPermilagem = null;

  tiposAppOpts = [];
  newDescricao = false;

  prevProprietario = null;
  propSelectDisabled = false;
  prevProprietariosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'proprietario', name: 'Proprietário', type: 'text', sort: null, searchable: true, centered: false, class: '' },
    { key: 'divida', name: '', type: 'text', sort: null, searchable: true, centered: false, class: 'col-220' },
    { key: 'data_inicio', name: 'Data Início', type: 'number', sort: null, searchable: true, centered: false, class: 'col-centered date-width' },
    { key: 'data_fim', name: 'Data Fim', type: 'number', sort: null, searchable: true, centered: false, class: 'col-centered date-width' },
  ];
  prevProprietarioList: Array<registoProprietarioList> = [];

  tabsObjDef = [
    { key: 'geral', name: 'Geral', active: true, disabled: false },
    { key: 'contencioso', name: 'Contencioso', active: false, disabled: false },
    { key: 'quotas', name: 'Quotas do Exercício', active: false, disabled: false },
    { key: 'reg-proprietarios', name: 'Reg. de Proprietários', active: false, disabled: false },
    { key: 'observacoes', name: 'Observações', active: false, disabled: false },
  ];

  subsMsg = null;
  isZIndexZero = true;

  navState:FraccoesNavState = null;

  maxRegPropDate:Date = null;

  
  // Contencioso (START)
  proprietariosContenciosoCol = [
    { key: 'condomino', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'estado_contencioso', name: 'Estado', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: false, centered: false, class: 'date-width col-centered' },
  ]
  
  proprietariosContencioso:Array<estadoContencioso> = [];

  
  // Contencioso (END)



  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public cdRef:ChangeDetectorRef,
              public router: Router,
              public modalService: SuiModalService,
              public location: Location,
              public message: MessageService,
              public appState: AppStateService,
              public condominoService: CondominosService,
              public fraccoesService: FraccoesService,
              public appConfig: AppConfigService) {
    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'CAIXA_VERTIS') {
        switch (msg.cmd) {
          case 'HIDE_INPUT':
            this.isZIndexZero = true;
            break;
          case 'SHOW_INPUT':
            this.isZIndexZero = false;
            break;
        }
      }
    });

    let currNav = this.router.getCurrentNavigation()
    if (currNav.extras.state) {
      this.navState = currNav.extras.state as FraccoesNavState;
    }
  }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: null });
    if (this.subsMsg) this.subsMsg.unsubscribe();

    if (this.addProprietarioModalRef) this.addProprietarioModalRef.deny();
    if (this.rmProprietarioModalRef) this.rmProprietarioModalRef.deny();
  }

  ngOnInit() {

    this.maxRegPropDate = new Date();
    this.maxRegPropDate.setHours(0,0,0,0);

    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);

    if (this.prevState) {
      if (this.prevState.state.selTab && this.prevState.state.selTab.key === 'reg-proprietarios') {
        this.propRegSelected = { name: this.prevState.state.nome_proprietario, value: this.prevState.state.cod_proprietario };
        this.newReg.cod_proprietario = { 
          cod: this.prevState.state.cod_proprietario, 
          nome: this.prevState.state.nome_proprietario };
      } else {
        this.geralForm.patchValue({
          cod: (this.prevState.state.cod) ? this.prevState.state.cod : null,
          nome: (this.prevState.state.nome) ? this.prevState.state.nome : null,
          cod_zona: (this.prevState.state.cod_zona) ? this.prevState.state.cod_zona : null,
          cod_proprietario: (this.prevState.state.cod_proprietario) ? this.prevState.state.cod_proprietario : null,
          nome_proprietario: (this.prevState.state.nome_proprietario) ? this.prevState.state.nome_proprietario : null,
          cod_inquilino: (this.prevState.state.cod_inquilino) ? this.prevState.state.cod_inquilino : null,
          nome_inquilino: (this.prevState.state.nome_inquilino) ? this.prevState.state.nome_inquilino : null,
      
          permilagem: (this.prevState.state.permilagem) ? this.prevState.state.permilagem : null,
          permilagem_aux_1: (this.prevState.state.permilagem_aux_1) ? this.prevState.state.permilagem_aux_1 : null,
          permilagem_aux_2: (this.prevState.state.permilagem_aux_2) ? this.prevState.state.permilagem_aux_2 : null,
          
          area: (this.prevState.state.area) ? this.prevState.state.area : null,
          n_votos: (this.prevState.state.n_votos) ? this.prevState.state.n_votos : null,
          
          paga_seg_colect: (this.prevState.state.paga_seg_colect) ? this.prevState.state.paga_seg_colect : null,
          capital_obrigat: (this.prevState.state.capital_obrigat) ? this.prevState.state.capital_obrigat : null,
          capital_pagar: (this.prevState.state.capital_pagar) ? this.prevState.state.capital_pagar : null,
          capital_individ: (this.prevState.state.capital_individ) ? this.prevState.state.capital_individ : null,
        });

        if (this.prevState.state.cod_proprietario && this.prevState.state.nome_proprietario) this.propSelected = { name: this.prevState.state.nome_proprietario, value: this.prevState.state.cod_proprietario };
        if (this.prevState.state.cod_inquilino && this.prevState.state.nome_inquilino) this.inqSelected = { name: this.prevState.state.nome_inquilino, value: this.prevState.state.cod_inquilino };
        if (this.prevState.state.nome) this.fracDescSelected = { name: this.prevState.state.nome, value: this.prevState.state.nome };

        this.quotasForm.patchValue({   
          actual_orc: (this.prevState.state.actual_orc) ? this.prevState.state.actual_orc : null,
          quota_orc: (this.prevState.state.quota_orc) ? this.prevState.state.quota_orc : null,
      
          actual_fr: (this.prevState.state.actual_fr) ? this.prevState.state.actual_fr : null,
          quota_fr: (this.prevState.state.quota_fr) ? this.prevState.state.quota_fr : null,
      
          actual_seg: (this.prevState.state.actual_seg) ? this.prevState.state.actual_seg : null,
          quota_seg: (this.prevState.state.quota_seg) ? this.prevState.state.quota_seg : null,
        });

        this.obsForm.patchValue({
          obs: (this.prevState.state.obs) ? this.prevState.state.obs : null,
        });
      }

      this.appState.clearPrevState(this.comp);
    }

    this.animate();

    this.isCreate = (this.route.snapshot.params.id === 'criar');
    this.init();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges(); 
  }

  init(clearForm=false) {
    this.submittingForm = false;
    this.loading = false;
    this.condominioCod = this.route.snapshot.params.cond_id;

    if (!this.prevState || clearForm) {
      this.geralForm.reset();
      this.quotasForm.reset();
      this.obsForm.reset();
    }

    // GET INITIAL DATA
    if (!this.isCreate) {
      this.fraccaoId = this.route.snapshot.params.id;
      this.condominioCod = this.route.snapshot.params.cond_id;
      this.propSelectDisabled = true;
      this.getDetails();
    } else {
      this.getCondZonaDetails();
    }

    this.api.getEstadosContencioso().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.estadoContOpts = [{ name: '--- Limpar seleção ---', value: '-1' }].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
      } else {}
    }, err => {});

    this.getAllFraccoesDetails();
  }

  ngAfterViewInit() {
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.rmProprietarioAlertConfig = new TemplateModalConfig<IContext, string, string>(this.rmProprietarioAlertRef);
    this.rmProprietarioAlertConfig.isClosable = false;
    this.rmProprietarioAlertConfig.closeResult = "closed";
    this.rmProprietarioAlertConfig.size = 'small';
    this.rmProprietarioAlertConfig.transition = 'fade';
    this.rmProprietarioAlertConfig.transitionDuration = 250;

    this.addProprietarioAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addProprietarioAlertRef);
    this.addProprietarioAlertConfig.isClosable = false;
    this.addProprietarioAlertConfig.closeResult = "closed";
    this.addProprietarioAlertConfig.size = 'tiny';
    this.addProprietarioAlertConfig.transition = 'fade';
    this.addProprietarioAlertConfig.transitionDuration = 250;

    this.recibosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.recibosAlertRef);
    this.recibosAlertConfig.isClosable = false;
    this.recibosAlertConfig.closeResult = "closed";
    this.recibosAlertConfig.size = 'normal';
    this.recibosAlertConfig.transition = 'fade';
    this.recibosAlertConfig.transitionDuration = 250;

    this.tabsObjDef.forEach(tab => {
      if (this.router.url.indexOf(tab.key) !== -1) {
        tab.active = true;
      }
    });

    this.loadNavigationState();
  }

  loadNavigationState() {
    
    if (!this.navState) return;
    setTimeout(() => {
      switch (this.navState.action) {
        case 'OPEN_NEW_PROP_MODAL':
          let tab = this.tabsObjDef.find(el => el.key === 'reg-proprietarios');
          tab.active = true;
          this.setTab(tab);
          if (this.navState.data) {
            this.propRegSelected = { name: this.navState.data.nome_proprietario, value: { nome: this.navState.data.nome_proprietario, cod: this.navState.data.cod_proprietario} }
          }
          this.presentProprietarioModal('ADD', null);
          break;
        default:
          break;
      }
    });
  }

  scrollToTop() { this.pageContainer.nativeElement.scrollTop = 0; }

  getNextFraccaoCod() {
    let lastFrac = null;
    this.fracList.sort((a, b) => {

      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;

    }).forEach(el => {

      if (lastFrac && el.cod > lastFrac.cod) {
        lastFrac = el;
      } else {
        lastFrac = el;
      }
    });

    if (lastFrac) {
      this.geralForm.patchValue({
        cod: String.fromCharCode(lastFrac.cod.charCodeAt(0) + 1),
      });
    } else {
      this.geralForm.patchValue({
        cod: 'A',
      });
    }
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  propTimer = null;
  propSelOptsLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.propSelected); });
    }

    clearTimeout(this.propTimer);
    return new Promise(resolve => {
        if (query) {
          this.propTimer = setTimeout(() => {
            // PROPRIETARIOS/INGUILINOS LIST
            this.api.getAllCondominos(query, 'proprietarios').subscribe(res => {
              if (res.success) {
                return resolve([{ name: '--- Criar proprietário ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
              } else {
                return resolve([{ name: '--- Criar proprietário ---', value: '0' }]);
              }
            });
          }, 400);
        } else {
          this.api.getAllCondominos('NULL', 'proprietarios').subscribe(res => {
            if (res.success) {
              return resolve([{ name: '--- Criar proprietário ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
            } else {
              return resolve([{ name: '--- Criar proprietário ---', value: '0' }]);
            }
          });
        }
    });
  };

  propRegSelected = null;
  propRegTimer = null;
  propRegSelOptsLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.propRegSelected); });
    }

    clearTimeout(this.propRegTimer);
    return new Promise(resolve => {
        if (query) {
          this.propRegTimer = setTimeout(() => {
            // PROPRIETARIOS/INGUILINOS LIST
            this.api.getAllCondominos(query, 'proprietarios').subscribe(res => {
              if (res.success) {
                return resolve([{ name: '--- Criar proprietário ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
              } else {
                return resolve([{ name: '--- Criar proprietário ---', value: '0' }]);
              }
            });
          }, 400);
        } else {
          this.api.getAllCondominos('NULL', 'proprietarios').subscribe(res => {
            if (res.success) {
              return resolve([{ name: '--- Criar proprietário ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
            } else {
              return resolve([{ name: '--- Criar proprietário ---', value: '0' }]);
            }
          });
        }
    });
  };

  inqTimer = null;
  inquilSelOptsLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.inqSelected); });
    }

    clearTimeout(this.inqTimer);
    return new Promise(resolve => {
        if (query) {
          this.inqTimer = setTimeout(() => {
            // PROPRIETARIOS/INGUILINOS LIST
            this.api.getAllCondominos(query, 'inquilinos').subscribe(res => {
              if (res.success) {
                return resolve([{ name: '--- Criar inquilino ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; })));
              } else {
                return resolve([{ name: '--- Criar inquilino ---', value: '0' }]);
              }
            });
          }, 400);
        } else {
          this.api.getAllCondominos('NULL', 'inquilinos').subscribe(res => {
            if (res.success) {
              return resolve([{ name: '--- Criar inquilino ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; })));
            } else {
              return resolve([{ name: '--- Criar inquilino ---', value: '0' }]);
            }
          });
        }
    });
  };

  fracDescTimer = null;
  fracDescSelected = null;
  fracDescSelOptsLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.fracDescSelected); });
    }

    clearTimeout(this.inqTimer);
    return new Promise(resolve => {
        if (query) {
          this.inqTimer = setTimeout(() => {
            // PROPRIETARIOS/INGUILINOS LIST
            this.api.getAllTiposApp(query).subscribe(res => {
              if (res.success) {
                return resolve([{ name: '-- Criar nova --', value: 'NEW'}].concat(res.data.map(el => { return { name: el.nome, value: el.nome }; })));
              } else {
                return resolve([{ name: '-- Criar nova --', value: 'NEW'}]);
              }
            });
          }, 400);
        } else {
          this.api.getAllTiposApp('NULL').subscribe(res => {
            if (res.success) {
              return resolve([{ name: '-- Criar nova --', value: 'NEW'}].concat(res.data.map(el => { return { name: el.nome, value: el.nome }; })));
            } else {
              return resolve([{ name: '-- Criar nova --', value: 'NEW'}]);
            }
          });
        }
    });
  };

  getFirstAviso(codProprietario) {
    return new Promise(resolve => {
      this.api.getFirstAviso(codProprietario).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve((!!res.data) ? res.data : false);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
  }

  getFirstAvisoV2(codCondominio, codFraccao) {
    return new Promise(resolve => {
      this.api.getFirstAvisoByProprietarios(codCondominio, codFraccao).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve((!!res.data) ? res.data.reverse() : []);
        } else {
          resolve([]);
        }
      }, err => {
        resolve([]);
      });
    });
  }

  getDetails() {
    this.api.getFraccaoDetails(this.route.snapshot.params.id).subscribe(async res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.details = res.data;

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.details.cod} - ${this.details.nome}` });

        // HANDLE PREVIOUS PROPRIETARIOS
        this.prevProprietario = {
          cod: this.details.cod_proprietario,
          nome: this.details.nome_proprietario,
        }

        this.details['permilagem'] = Number(this.details['permilagem']).toFixed(3);
        this.details['permilagem_aux_1'] = Number(this.details['permilagem_aux_1']).toFixed(2);
        this.details['permilagem_aux_3'] = Number(this.details['permilagem_aux_3']).toFixed(2);
        this.details['quota_fr'] = Number(this.details['quota_fr']).toFixed(2);
        this.details['quota_orc'] = Number(this.details['quota_orc']).toFixed(2);
        this.details['quota_seg'] = Number(this.details['quota_seg']).toFixed(2);
        this.details['capital_individ'] = Number(this.details['capital_individ']).toFixed(2);
        this.details['capital_obrigat'] = Number(this.details['capital_obrigat']).toFixed(2);
        this.details['capital_pagar'] = Number(this.details['capital_pagar']).toFixed(2);

        this.fraccaoId = this.route.snapshot.params.id;

        if (this.details.nome_proprietario) this.propSelected = { name: this.details.nome_proprietario, value: this.details.cod_proprietario };
        if (this.details.nome_inquilino) this.inqSelected = { name: this.details.nome_inquilino, value: this.details.cod_inquilino };
        if (this.details.nome) this.fracDescSelected = { name: this.details.nome, value: this.details.nome };

        this.zonaSelOpts = res.zonas.map(el => { return { name: el.nome, value: el.cod }; });

        this.prevFraccaoPermilagem = Number(this.details['permilagem']);

        this.restoreForm('geral');
        this.restoreForm('contencioso');
        this.restoreForm('quotas');
        this.restoreForm('observacoes');
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {});

    this.getProprietarios();
    
  }

  async getProprietarios() {
    try {
      let proprietarios = await this.fraccoesService.getFracaoRegistoProprietarios(this.route.snapshot.params.id);
      this.prevProprietarioList = proprietarios.map(el => {return {...el, checked: false}});
      // TODO
      let contenciosos = await this.condominoService.getContenciososByFracao(this.route.snapshot.params.id);
      this.proprietariosContencioso = contenciosos? contenciosos : [];
      this.sortProprietariosList();
    } catch (err) {
      this.prevProprietarioList = [];
    }
  }

  getAllFraccoesDetails() {
    this.api.getCondFraccoesDetailsLight(this.condominioCod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.fracList = res.data;

        if (this.isCreate) this.getNextFraccaoCod();
      } else {}
    }, err => {});
  }

  descricaoSelected(ev) {
    if (ev.value === 'NEW') {
      this.geralForm.patchValue({
        nome: null,
      });
      this.newDescricao = true;
    }
  }

  descricaoChanged(ev) {
    if (!this.geralForm.getRawValue().nome) {
      this.geralForm.patchValue({
        nome: (this.details) ? this.details.nome : null,
      });
      this.newDescricao = false;
    }
  }

  computeTotalPermilagem() {
    this.totalPermilagem = 0;
    if (this.details) {
      this.fracList.filter(frac => (frac.cod !== this.details.cod)).forEach(frac => {
        this.totalPermilagem += Math.round(Number(frac.permilagem) * 1000) / 1000;
      });
    } else {
      this.fracList.forEach(frac => {
        this.totalPermilagem += Math.round(Number(frac.permilagem) * 1000) / 1000;
      });
    }
    this.totalPermilagem = parseFloat(this.totalPermilagem.toFixed(3));
  }

  getCondZonaDetails() {
    this.api.getCondZonasDetails(this.condominioCod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.zonaSelOpts = res.data.map(el => {
          return { name: el.nome, value: el.cod }
        });
      } else {}
    }, err => {});
  }

  setTab(tab) {
    this.selTab = this.tabsObjDef.find(el => (el.key === tab.key));

    switch (this.selTab.key) {
      case 'geral':
        if (this.isCreate) {
          this.location.replaceState('/fraccoes/geral/' + this.condominioCod + '/criar');
        } else {
          this.location.replaceState('/fraccoes/geral/' + this.condominioCod + '/' + this.fraccaoId);
        }
        break;
      case 'contencioso':
        if (this.isCreate) {
          this.location.replaceState('/fraccoes/contencioso/' + this.condominioCod + '/criar');
        } else {
          this.location.replaceState('/fraccoes/contencioso/' + this.condominioCod + '/' + this.fraccaoId);
        }
        break;
      case 'quotas':
        if (this.isCreate) {
          this.location.replaceState('/fraccoes/quotas/' + this.condominioCod + '/criar');
        } else {
          this.location.replaceState('/fraccoes/quotas/' + this.condominioCod + '/' + this.fraccaoId);
        }
        break;
      case 'reg-proprietarios':
        if (this.isCreate) {
          this.location.replaceState('/fraccoes/reg-proprietarios/' + this.condominioCod + '/criar');
        } else {
          this.location.replaceState('/fraccoes/reg-proprietarios/' + this.condominioCod + '/' + this.fraccaoId);
        }
        break;
      case 'observacoes':
        if (this.isCreate) {
          this.location.replaceState('/fraccoes/observacoes/' + this.condominioCod + '/criar');
        } else {
          this.location.replaceState('/fraccoes/observacoes/' + this.condominioCod + '/' + this.fraccaoId);
        }
        break;
    }
  }

  restoreForm(targetTab) {
    switch (targetTab) {
      case 'geral':
        this.newDescricao = false;
        if (this.isCreate) {
          this.geralForm.reset();
          this.getNextFraccaoCod();
          this.submittingForm = false;
        } else {
          setTimeout(() => {
            this.geralForm.reset();
            this.geralForm.patchValue({
              cod: this.details.cod,
              nome: this.details.nome,
              cod_zona: this.details.cod_zona,
              cod_proprietario: this.details.cod_proprietario,
              nome_proprietario: this.details.nome_proprietario,
              cod_inquilino: this.details.cod_inquilino,
              nome_inquilino: this.details.nome_inquilino,
          
              permilagem: this.details.permilagem,
              permilagem_aux_1: this.details.permilagem_aux_1,
              permilagem_aux_2: this.details.permilagem_aux_2,
              
              area: this.details.area,
              n_votos: this.details.n_votos,
              
              paga_seg_colect: this.details.paga_seg_colect,
              capital_obrigat: this.details.capital_obrigat,
              capital_pagar: this.details.capital_pagar,
              capital_individ: this.details.capital_individ,
            });
            this.cdRef.detectChanges();
          }, 500);
        }
        this.codAlreadyExiste = false;
        break;
      case 'quotas':
        this.quotasForm.patchValue({   
          actual_orc: this.details.actual_orc,
          quota_orc: this.details.quota_orc,
      
          actual_fr: this.details.actual_fr,
          quota_fr: this.details.quota_fr,
      
          actual_seg: this.details.actual_seg,
          quota_seg: this.details.quota_seg,
        });
        break;
      case 'observacoes':
        this.obsForm.patchValue({
          obs: (this.details.obs) ? this.details.obs.replace(/NEW_LINE/g, '\n') : null,
        });
        break;
    }
  }

  getPermZona(codFraccao, codZona, init) {
    let permilagem_zona = Number(init);
    this.fracList.filter(frac => (frac.zona_cod === codZona && frac.cod !== codFraccao)).forEach(el => {
      if (el.hasOwnProperty('permilagem') && el.permilagem) permilagem_zona += Number(el.permilagem);
    });
    return permilagem_zona;
  }

  onChanged(target) {
    switch (target) {
      case 'cod': this.codAlreadyExiste = false; break;
    }
  }

  formSubmitted() {
    let geralData = null;
    let quotasData = null;
    let obsData = null;

    // CREATE FRACCAO
    this.submittingForm = true;

    if (!this.geralForm.valid) return;

    geralData = this.geralForm.getRawValue();
    quotasData = this.quotasForm.getRawValue();
    obsData = this.obsForm.getRawValue();

    // CHECK PERMILAGEM > 1000
    this.computeTotalPermilagem()
    if (this.totalPermilagem + Number(geralData.permilagem) > 1000) {
      this.toastr.error('Total da permilagem do condomínio excede o valor de 1000 ‰.', 'Ups...!', { timeOut: 4000 });
      this.permilagemError = true; setTimeout(() => { this.permilagemError = false; }, 4000);
      return;
    }

    let permilagem_zona = this.getPermZona(geralData.cod, geralData.cod_zona, geralData.permilagem);

    this.loading = true;
    if (this.isCreate) {

      let cod_proprietario = null;
      let nome_proprietario = null;
      if (geralData.cod_proprietario.hasOwnProperty('cod')) {
        cod_proprietario = geralData.cod_proprietario.cod;
        nome_proprietario = geralData.cod_proprietario.nome;
      } else {
        cod_proprietario = geralData.cod_proprietario;
        nome_proprietario = geralData.nome_proprietario;
      }

      // REVIEW - CHANGE PROPRIETARIO !!!!!
      let propReg = JSON.stringify([{ cod: cod_proprietario, nome: nome_proprietario, dataInicio: new Date() }]);
      this.api.addFraccaoCondominio(this.condominioCod, geralData.cod, geralData.nome, geralData.cod_zona, cod_proprietario, geralData.cod_inquilino, geralData.permilagem, geralData.permilagem_aux_1, geralData.permilagem_aux_2, geralData.area, geralData.n_votos, geralData.paga_seg_colect, geralData.capital_obrigat, geralData.capital_pagar, geralData.capital_individ, permilagem_zona, quotasData.actual_orc, quotasData.quota_orc, quotasData.actual_fr, quotasData.quota_fr, quotasData.actual_seg, quotasData.quota_seg, obsData.obs, propReg).subscribe(res => {
        if (res.hasOwnProperty('success') && res['success']) {
          this.fraccaoId = res.data.id;
          if (this.isCreate) {
            if (this.totalPermilagem + Number(geralData.permilagem) >= 1000) {
              this.location.back();
            } else {
              this.presentAlert();
            }
          }
        } else {
          if (res.hasOwnProperty('status')) {
            switch (res.status) {
              case 'COD_ALREADY_EXISTS':
                this.codAlreadyExiste = true;
                break;
            }
          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          }
        }
        this.newDescricao = false;
        this.loading = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loading = false;
      });
    } else {
      permilagem_zona = Number(permilagem_zona) - Number(this.prevFraccaoPermilagem) + Number(geralData.permilagem);

      this.api.updateFraccaoCondominio(this.fraccaoId, this.condominioCod, geralData.cod, geralData.nome, geralData.cod_zona, geralData.cod_proprietario, geralData.cod_inquilino, geralData.permilagem, geralData.permilagem_aux_1, geralData.permilagem_aux_2, geralData.area, geralData.n_votos, geralData.paga_seg_colect, geralData.capital_obrigat, geralData.capital_pagar, geralData.capital_individ, permilagem_zona, quotasData.actual_orc, quotasData.quota_orc, quotasData.actual_fr, quotasData.quota_fr, quotasData.actual_seg, quotasData.quota_seg, obsData.obs).subscribe(res => {
        if (res.hasOwnProperty('success') && res['success']) {
          this.isCreate = false;

          this.location.back();
        } else {
          if (res.hasOwnProperty('status')) {
            switch (res.status) {
              case 'COD_ALREADY_EXISTS':
                this.codAlreadyExiste = true;
                break;
            }
          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          }
        }
        this.newDescricao = false;
        this.loading = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loading = false;
      });
    }

    if (this.newDescricao) {
      // SAVE NEW DESCRICAO
      this.api.addTipoApp(geralData.nome, 'FRACCAO_DESCRICAO').subscribe(res => {
        if (res.hasOwnProperty('success') && res['success']) {
          this.newDescricao;
        }
      }, err => {});
    }
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => {
          this.scrollToTop();
          this.init(true);
        })
        .onDeny(() => {
          this.isCreate = false;
          this.loading = false;

          this.location.back();
        });
    });
  }

  setState() {
    let data = this.geralForm.getRawValue();
    let data1 = this.quotasForm.getRawValue();
    let data2 = this.obsForm.getRawValue();

    this.appState.setPrevState(this.comp, { 
      cod: data.cod,
      nome: data.nome,
      cod_zona: data.cod_zona,
      cod_proprietario: data.cod_proprietario,
      nome_proprietario: data.nome_proprietario,
      cod_inquilino: data.cod_inquilino,
      nome_inquilino: data.nome_inquilino,
  
      permilagem: data.permilagem,
      permilagem_aux_1: data.permilagem_aux_1,
      permilagem_aux_2: data.permilagem_aux_2,
      
      area: data.area,
      n_votos: data.n_votos,
      
      paga_seg_colect: data.paga_seg_colect,
      capital_obrigat: data.capital_obrigat,
      capital_pagar: data.capital_pagar,
      capital_individ: data.capital_individ,

      actual_orc: data1.actual_orc,
      quota_orc: data1.quota_orc,
  
      actual_fr: data1.actual_fr,
      quota_fr: data1.quota_fr,
  
      actual_seg: data1.actual_seg,
      quota_seg: data1.quota_seg,

      obs: data2.obs,

      selTab: this.selTab,
    });
  }

  onEntitySelected(target, value) {
    if (value === 0 || value === '0') {
      // SAVE THIS STATE
      this.setState();

      this.appState.setDescFraccao(this.geralForm.get('nome').value);

      switch (target) {
        case 'reg-proprietario':
        case 'proprietario':
          this.router.navigate(['entidades/proprietario', 'criar']);
          break;
        case 'inquilino': 
          this.router.navigate(['entidades/inquilino', 'criar']);
          break;
      }
    }
  }

  goToEntity(target) {
    switch (target) {
      case 'proprietario':
        let codProp = this.geralForm.get('cod_proprietario').value;

        if (codProp === null || codProp === 0|| codProp === '0') {
          this.toastr.error('Fracção não tem proprietário associado.', 'Alerta', { timeOut: 4000 });
          return;
        }

        this.router.navigate(['entidades/proprietario', codProp]);
        break;
      case 'inquilino':
        let codInq = this.geralForm.get('cod_inquilino').value;

        if (codInq === null || codInq === 0 || codInq === '0') {
          this.toastr.error('Fracção não tem inquilino associado.', 'Alerta', { timeOut: 4000 });
          return;
        }

        this.router.navigate(['entidades/inquilino', codInq]);
        break;
    }
  }

  // START - REGISTO DE PROPRIETARIOS -------------------------------------------------------------
  recibosListCol = [
    { key: 'n_recibo', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'table-checkbox-column col-align-left' },
    { key: 'cod_fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'two wide col-centered' },
    { key: 'nome_condomino', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: '' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'date-width col-centered' },
    { key: 'criado_em', name: 'Data Criação', type: 'text', sort: null, searchable: true, centered: true, class: 'date-width col-centered' },
    { key: 'valor', name: 'Liquidado', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right' },
  ];
  newReg: registoProprietarioEdit = {
    id_registo: null, 
    cod_proprietario: null, 
    nome: null, 
    data_inicio: null, 
    divida: null,
  };
  proprietarioModalTitle = null;
  proprietarioEditMode = false;
  recibosList = [];

  selectProprietario(row) {
    row.checked = !row.checked;
    if (row.checked) this.prevProprietarioList.filter(el => el !== row).forEach(el => { el['checked'] = false });
  }

  checkAvisosRecibos(data:saveProprietario): Promise<boolean> {
    return new Promise(async (resolve) => {

      let recibosResp: any = await this.checkRecibos(data);
      if (!recibosResp) {
        resolve(false);
        return;
      }

      if (recibosResp.hasOwnProperty('closeModal') && recibosResp.closeModal) {
        if (this.addProprietarioModalRef) this.addProprietarioModalRef.deny();
        if (this.rmProprietarioModalRef) this.rmProprietarioModalRef.deny();
        resolve(false);
        return;
      }
      resolve(true);
    })
  }

  async saveProprietario(method:saveProprietarioMethod) {
    if (method !== 'UPDATE' && method !== 'DELETE') return;
    this.submittingForm = true;
    let data:saveProprietario = null;
    if (method === 'UPDATE') {
      if (!this.newReg.cod_proprietario || !this.newReg.data_inicio || !this.newReg.divida) return;
      
      let cod_proprietario = this.newReg.cod_proprietario.hasOwnProperty('cod') ? this.newReg.cod_proprietario.cod : this.newReg.cod_proprietario;
      
      data = {
        id_registo: this.newReg.id_registo,
        id_fracao: this.fraccaoId,
        cod_proprietario: cod_proprietario,
        data_inicio: this.newReg.data_inicio,
        divida: this.newReg.divida,
        method: 'UPDATE'
      }
    } else if (method === 'DELETE') {
      let chosen = this.prevProprietarioList.find(el => !!el.checked);

      if (!chosen) {
        this.toastr.error('Nenhuma entrada selecionada para remover.', 'Ups...!', { timeOut: 4000 });
        return;
      }

      data = {
        id_registo: chosen.id_registo,
        id_fracao: chosen.id_fracao,
        method: 'DELETE'
      }
    }
    
    let res = await this.checkAvisosRecibos(data);
    if (!res) return;
    
    this.loadingModal = true;
    let resData: saveProprietarioData = null;
    try {
      resData = await this.fraccoesService.saveProprietario(data);
    } catch (err) {     
    }
    this.loadingModal = false;
    if (!resData) return;
    let proprietarioSaved = resData.proprietario;

    if (method === 'UPDATE' && proprietarioSaved) {
      //Caso tenha sido guardado (Method = 'UPDATE')
      let prevProprietario = this.prevProprietarioList.find(el => el.id_registo === proprietarioSaved.id_registo);
      if (!!prevProprietario) {
        prevProprietario.divida = proprietarioSaved.divida;
        prevProprietario.data_inicio = this.utils.getDate(proprietarioSaved.data_inicio);
      } else {
        let proprietario: registoProprietarioList = { ... this.fraccoesService.convertRegistoProprietarioType(proprietarioSaved), checked: false };
        this.prevProprietarioList.push(proprietario);
      }
      this.newReg = {
        id_registo: null, 
        cod_proprietario: null, 
        nome: null,
        data_inicio: null, 
        divida: null,
      }
      if (this.addProprietarioModalRef) this.addProprietarioModalRef.approve();
    } else if (method === 'DELETE') {
      this.prevProprietarioList = this.prevProprietarioList.filter(el => el.id_registo !== data.id_registo);
      if (this.rmProprietarioModalRef) this.rmProprietarioModalRef.approve();
    }

    this.getDetails();
    return;
  }

  sortProprietariosList() {
    this.prevProprietarioList.sort((a,b) => this.utils.compareDayDates(b.data_inicio, a.data_inicio));
  }

  checkRecibos(data:saveProprietario) {
    return new Promise(async (resolve) => {

      this.recibosList = [];
      let recibos = null;
      try {
        recibos = await this.fraccoesService.checkRecibosChanged(data);
      } catch (error) {
        resolve(false);
        return;
      }
      recibos.forEach(el => {
        el.n_recibo = Number(el.n_recibo);
        el['data'] = (el['data']) ? this.utils.getDate(el['data']) : null;
        let auxDate = this.utils.getDate(el['criado_em']);
        if (auxDate.toString() === 'Invalid Date') {
          el['criado_em'] = new Date(el['criado_em']);
        } else {
          el['criado_em'] = auxDate;
        }
        el['valor'] = (el['valor']) ? Number(el['valor']) : null;

        this.recibosList.push(el);
      });
      this.recibosList = this.recibosList.sort((a, b) => a.n_recibo - b.n_recibo);

      if (!this.recibosList.length) {
        resolve(true);
        return;
      }
      this.recibosModalRef = this.modalService
        .open(this.recibosAlertConfig)
        .onApprove(() => { resolve(true); })
        .onDeny(() => { resolve(false); }); 
    });
  }

  newRegProprietarioSelected() {
    if (this.newReg.cod_proprietario == 0) {
      this.router.navigate(['entidades/proprietario/criar'], { 
        state: { 
          redirectAfterTo: { 
            url: this.router.url, 
            action: 'OPEN_NEW_PROP_MODAL',
          }  
        } 
      });
    }
  }

  presentProprietarioModal(action, row:registoProprietarioList) {
    switch (action) {
      case 'ADD':
        this.proprietarioModalTitle = 'Novo Registo de Proprietário';
        this.loadingModal = false;
        this.proprietarioEditMode = false;

        this.newReg = {
          id_registo: null, 
          cod_proprietario: this.propRegSelected ? this.propRegSelected.value : null, 
          nome: null,
          data_inicio: null, 
          divida: null,
        }


        this.addProprietarioModalRef = this.modalService
          .open(this.addProprietarioAlertConfig)
          .onApprove(() => { this.loadingModal = false; this.submittingForm = false; })
          .onDeny(() => { this.loadingModal = false; this.submittingForm = false;});  
        
        setTimeout(() => {
          this.propRegSelected = null;
        }, 0);
        break;
      case 'EDIT':
        this.proprietarioModalTitle = 'Editar Registo de Proprietário';
        this.loadingModal = false;
        this.proprietarioEditMode = true;

        this.newReg = {
          id_registo: row.id_registo, 
          cod_proprietario: row.cod_proprietario, 
          nome: row.nome,
          data_inicio: new Date(row.data_inicio), 
          divida: row.divida
        }

        this.addProprietarioModalRef = this.modalService
          .open(this.addProprietarioAlertConfig)
          .onApprove(() => { this.loadingModal = false; this.submittingForm = false; })
          .onDeny(() => { this.loadingModal = false; this.submittingForm = false;});  
        break;
      case 'DELETE':
        if (!this.prevProprietarioList.find(el => el.checked)) {
          this.toastr.error('Nenhuma entrada selecionada para remover.', 'Ups...!', { timeOut: 4000 });
          return;
        }

        if (this.prevProprietarioList.length <= 1) {
          let error = this.appConfig.errorCodes['ERR_FRACOES_002'];
          this.toastr.error(error.msg, error.title, { timeOut: 4000 });
          this.prevProprietarioList.filter(el => el !== row).forEach(el => { el['checked'] = false });
          return;
        }
    
        this.loadingModal = false;

        this.rmProprietarioModalRef = this.modalService
          .open(this.rmProprietarioAlertConfig)
          .onApprove(() => { this.loadingModal = false; this.submittingForm = false; })
          .onDeny(() => { this.loadingModal = false; this.submittingForm = false;});
        break;
    }
  }

  goToProprietarioContencioso(row: estadoContencioso) {
    this.condominoService.goToContencioso(row.id);
  }
  // END - REGISTO DE PROPRIETARIOS ---------------------------------------------------------------

}
