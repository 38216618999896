import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { AppConfigService } from '../app-config.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { UserSessionService } from '../user-session.service';
import { UtilitiesService } from '../utilities.service';
import { MessageService } from '../message.service';
import { Email, EmailRecipientTypes } from '../business-model-interfaces/email';
import { CartaComunicacaoComponent } from '../carta-comunicacao/carta-comunicacao.component';
import { CommunicationsOrigin, RegistoComunicacaoSave, TipoEntidade } from '../business-model-interfaces/comunicacoes';
import { DespesasCttModalComponent, EntityDespesasCTTModalInput } from '../despesas-ctt-modal/despesas-ctt-modal.component';
import { CommunicationsService } from '../business-logic-services/communications.service';
import { BancoOperation } from '../business-model-interfaces/assembleias';


interface Entity {
  cod:string,
  nomeEntidade: string,
  moradaEntidade: Array<any>,
  cod_condominio:string,
  condominioNome:string,
  moradaCondominio: Array<any>,
  cod_fraccao: string,
  nome_fraccao: string,
  checkedCarta: boolean,
  checkedEmail: boolean,
  communicationType: EmailRecipientTypes,
  emails: string,
  tipo: TipoEntidade,
  subjectMsg: string,
  bodyMsg: string,
  tipo_correio: 'REGISTADO' | 'REGISTADO_AR' | 'NORMAL' |'SIMPLES',
  n_contribuinte: string,
  id_texto_predefinido: string,
  data_divida: Date,
  tipoComunicacao: BancoOperation | 'ADMINISTRADOR'
  nomeComunicacao: string,
  classes: string
}

export interface EntityEmailCartaInput {
  cod:string,
  nome: string,
  cod_condominio:string,
  nome_condominio:string,
  cod_fraccao: string,
  nome_fraccao: string,
  subjectMsg:string,
  bodyMsg:string,
  email_1: string,
  email_2: string,
  tipo:TipoEntidade
  communicationType: EmailRecipientTypes,
  id_texto_predefinido: string,
  data_divida: Date,
  tipoComunicacao?: BancoOperation | 'ADMINISTRADOR',
  nomeComunicacao?: string,
  classes?: string,
}

@Component({
  selector: 'app-email-carta-comunicacao-modal',
  templateUrl: './email-carta-comunicacao-modal.component.html',
  styleUrls: ['./email-carta-comunicacao-modal.component.scss']
})
export class EmailCartaComunicacaoModalComponent implements OnInit {

  @Input('canEditText') canEditText: boolean = true;
  @Input('needAllSelected') needAllSelected: boolean = false;
  @Input('subTitle') subTitle: string = null;
  @Input('origin') origin:CommunicationsOrigin = 'CORRESPONDENCIA_COM';


  @ViewChild('despesasCttRef', { static: false }) despesasCttRef: DespesasCttModalComponent;

  @ViewChild('comTitularesBancariosPdfRef', { static: false }) comTitularesBancariosPdf: CartaComunicacaoComponent;
  cartasTitularesBancarios = [];
  cartasTitularesBancariosCTT = [];

  @ViewChild('modalRef', { static: false }) modalRef;
  modal = null;
  modalConfig: any = null;

  @ViewChild('editEmailsModalRef', { static: false }) editEmailsModalRef;
  editEmailsModal = null;
  editEmailsModalConfig: any = null;
  emails = null;


  filename = "Titulares_Bancários";
  submitting = false;
  actualComunicationStep = 'SELECT_COMUNICATION_TYPE';

  codCondominio = null;
  idAssembleia = null;
  entityList:Array<Entity> = [];
  subjectMsg = null;
  bodyMsg = null;
  editComunication = false;
  sendingEmails = false;
  sendingLetters = false;

  anexos:Array<any> = [];
  emailFromName: string = null;
  emailFrom: string = null;
  emailFromUser:{name, email} = null;
  assinatura :{email:string, name:string } = null;
  addAssinatura = false;
  multipleCondominios = false;

  constructor(public modalService: SuiModalService,
              public api: ApiService,
              public toastr: ToastrService,
              public userSession: UserSessionService,
              public utils: UtilitiesService,
              public message: MessageService,
              public cdRef: ChangeDetectorRef,
              public communicationsService: CommunicationsService,
              public appConfig: AppConfigService) {
                
  }

  ngOnInit() {
    this.emailFromName = this.appConfig.company.nome;
    this.emailFrom = this.appConfig.company.email;
  }

  ngAfterViewInit() {
    this.modalConfig = new TemplateModalConfig<IContext, string, string>(this.modalRef);
    this.modalConfig.isClosable = false;
    this.modalConfig.closeResult = 'closed';
    this.modalConfig.size = 'normal';
    this.modalConfig.transition = 'fade';
    this.modalConfig.transitionDuration = 250;

    this.editEmailsModalConfig = new TemplateModalConfig<IContext, string, string>(this.editEmailsModalRef);
    this.editEmailsModalConfig.isClosable = true;
    this.editEmailsModalConfig.closeResult = 'closed';
    this.editEmailsModalConfig.size = 'small';
    this.editEmailsModalConfig.transition = 'fade';
    this.editEmailsModalConfig.transitionDuration = 250;

    this.editEmailToSendConfig = new TemplateModalConfig<IContext, string, string>(this.editEmailToSendAlertRef);
    this.editEmailToSendConfig.isClosable = true;
    this.editEmailToSendConfig.closeResult = 'closed';
    this.editEmailToSendConfig.size = 'normal';
    this.editEmailToSendConfig.transition = 'fade';
    this.editEmailToSendConfig.transitionDuration = 250;

  }

  ngOnDestroy() { 
    if (this.modal) this.modal.deny(); 
    if (this.editEmailsModal) this.editEmailsModal.deny(); 
  }

  setEmailFrom(from:string, fromName:string, assinatura: {email:string, name:string } = null) {
    this.emailFromName = fromName;
    this.emailFrom = from;
    this.assinatura = assinatura;
    this.addAssinatura = true;
  }

  modalOpened = false;
  selTipoDocumento = null;
  open(emailTemplate, entityList:Array<EntityEmailCartaInput>, idAssembleia=null):Promise<{success, status?, sentToIndexes?:Array<{carta:boolean, email:boolean, emails:string}>}> {
    this.cdRef.detectChanges();
    this.modalConfig.size = (this.origin === 'ASSEMBLEIAS_COMUNICACOES') ? 'large' : 'normal';
    if (this.origin.indexOf('CORRESPONDENCIA') >= 0) this.filename = 'Correspondência';
    return new Promise(resolve => {
      if (this.modalOpened) {
        resolve({success:false, status: 'OPENED'});
        return;
      }
      this.modalOpened = true;
      let condominios = [... new Set(entityList.map(entity => entity.cod_condominio).filter(el => !!el))];
      if (condominios.length > 1 || this.origin.indexOf('CORRESPONDENCIA') != -1)  {
        this.multipleCondominios = true;
      } else {
        this.multipleCondominios = false;
        this.codCondominio = condominios.length == 1? condominios[0] : null;
      }

      this.actualComunicationStep = 'SELECT_COMUNICATION_TYPE';
      this.submitting = false;

      if (this.origin === 'CORRESPONDENCIA_AC') {
        this.selTipoDocumento = idAssembleia;
      } else {
        this.idAssembleia = idAssembleia;
      }


      // GET EMAIL TEMPLATE
      this.subjectMsg = (emailTemplate) ? emailTemplate.assunto : '';
      this.bodyMsg = (emailTemplate) ? emailTemplate.corpo : '';

      // FORMAT ENTITY LIST
      this.entityList = entityList.map(el => {
        let emails =  (((el.email_1) ? ` ${el.email_1};` : '') + ((el.email_2) ? ` ${el.email_2};` : '')).trim();
        let emailsAux = emails;
        if (emailsAux) emailsAux = emails.slice(0, -1);
        let aux: Entity = {
          cod: el.cod,
          emails: emails,
          checkedEmail: !!emailsAux,
          checkedCarta: !emailsAux,
          cod_fraccao: el.cod_fraccao,
          nome_fraccao: el.nome_fraccao,
          cod_condominio: el.cod_condominio,
          condominioNome: el.nome_condominio,
          moradaCondominio: [],
          nomeEntidade: el.nome,
          moradaEntidade: [],
          communicationType: el.communicationType? el.communicationType : 'to',
          subjectMsg: el.subjectMsg,
          bodyMsg: el.bodyMsg,
          tipo: el.tipo? el.tipo : 'PROPRIETARIO',
          tipo_correio: null,
          n_contribuinte:null,
          id_texto_predefinido: el.id_texto_predefinido,
          data_divida: el.data_divida,
          tipoComunicacao: el.hasOwnProperty('tipoComunicacao')? el.tipoComunicacao : null,
          nomeComunicacao: el.hasOwnProperty('nomeComunicacao')? el.nomeComunicacao : null,
          classes: el.hasOwnProperty('classes')? el.classes : null,
        }
        return aux;
      });

      this.entityList.sort((a,b) => {
          if (!a.cod_condominio && !b.cod_condominio) return 0;
          if (!a.cod_condominio) return 1;
          if (!b.cod_condominio) return -1;
          if (a.cod_condominio != b.cod_condominio) return Number(a.cod_condominio) - Number(b.cod_condominio);

          if (!a.cod_fraccao && !b.cod_fraccao) return 0;
          if (!a.cod_fraccao) return 1;
          if (!b.cod_fraccao) return -1;
          return (a.cod_fraccao as string).localeCompare(b.cod_fraccao as string);
      });
      
      this.editComunication = false;
      this.modal = this.modalService
        .open(this.modalConfig)
        .onApprove(() => {
          this.submitting = false;
          this.modalOpened = false;

          this.sendingLetters = false;
          this.sendingEmails = false;
          this.subjectMsg = null;
          this.bodyMsg = null;
          this.anexos = [];
          this.multipleCondominios = false;

          let sentToIndexes: Array<{carta:boolean, email:boolean, emails: string}> = [];
          for (let i = 0; i < this.entityList.length; i++) {
            const element = this.entityList[i];
            sentToIndexes.push({
              carta: element.checkedCarta,
              email: element.checkedEmail,
              emails: element.emails,
            });
          }

          this.entityList = [];
          resolve({success: true, sentToIndexes: sentToIndexes});
        })
        .onDeny(() => {
          this.submitting = false;
          this.modalOpened = false;

          this.sendingLetters = false;
          this.sendingEmails = false;
          this.subjectMsg = null;
          this.bodyMsg = null;
          this.anexos = [];
          this.multipleCondominios = false;
          this.entityList = [];
          resolve({success: false});
        });
    })
  }

  goBack() {
    this.actualComunicationStep = 'SELECT_COMUNICATION_TYPE';
    this.editComunication = false;
  }

  getPrettyTipo(tipo: string):string {
    if (!tipo) return null;
    return tipo.charAt(0).toUpperCase() + tipo.substring(1).toLowerCase();
  }

  editComunicationTemplate () {
    this.actualComunicationStep = 'EDIT_EMAIL_TEMPLATE';
  }

  editEmails(item) {
    return new Promise(resolve => {
      this.emails = item.emails;

      this.editEmailsModal = this.modalService
      .open(this.editEmailsModalConfig)
      .onApprove(() => {
        item.emails = this.emails;
        item.checkedEmail = true;
        this.emails = null;
        resolve(true);
      })
      .onDeny(() => {
        this.emails = null;
        resolve(false);
      });
    });
  }

  setDataComunicacoes(multiplecondominios:boolean, subjectMsg, bodyMsg, entityList, anexos) {
    this.multipleCondominios = multiplecondominios;
    this.subjectMsg = subjectMsg;
    this.bodyMsg = bodyMsg;
    this.entityList = entityList;
    this.anexos = anexos;
  }
  async submit():Promise<boolean> {
    if ((this.origin !== 'CORRESPONDENCIA_AC' && this.origin !== 'ASSEMBLEIAS_COMUNICACOES') && (!this.subjectMsg || !this.bodyMsg)) {
      this.editComunicationTemplate();
      this.toastr.error('Preencha por favor todos os campos assinalados.', 'Atenção', { timeOut: 4000 });
      return false;
    }

    if (this.needAllSelected && this.entityList.find(el => !el.checkedCarta && !el.checkedEmail)) {
      this.toastr.error('Selecione por favor pelo menos uma forma de comunicação para cada entidade.', 'Atenção', { timeOut: 4000 });
      return false;
    }

    let entityListClone: Array<Entity> = this.entityList.map(el => {
      return {
        cod: el.cod,
        emails: el.emails,
        checkedEmail: el.checkedEmail,
        checkedCarta: el.checkedCarta,
        cod_fraccao: el.cod_fraccao,
        nome_fraccao: el.nome_fraccao,
        cod_condominio: el.cod_condominio,
        condominioNome: el.condominioNome,
        moradaCondominio: el.moradaCondominio,
        nomeEntidade: el.nomeEntidade,
        moradaEntidade: el.moradaEntidade,
        communicationType: el.communicationType,
        subjectMsg: el.subjectMsg,
        bodyMsg: el.bodyMsg,
        tipo: el.tipo,
        tipo_correio: el.tipo_correio,
        n_contribuinte: el.n_contribuinte,
        id_texto_predefinido: el.id_texto_predefinido,
        data_divida: el.data_divida,
        tipoComunicacao: el.tipoComunicacao,
        nomeComunicacao: el.nomeComunicacao,
        classes: el.classes,
      }
    })

    // 1.1 GENERATE AND SEND EMAIL
    let byEmail = entityListClone.filter(el => el.checkedEmail);
    let byCarta = entityListClone.filter(el => el.checkedCarta);

    try {
      let success = await this.processEmails(byEmail);
      if (!success) {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        return false;
      }
      success = await this.processCartas(byCarta);
      if (!success) {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        return false;
      }
    } catch (err) {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.utils.apiErrorMsg(err);
      return false;
    }

    if (this.modal) this.modal.approve();
    this.submitting = false;
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    return true;
  }

  async processCartas(byCarta): Promise<boolean> {
    // 1.2 GENERATE MAIL LETTERS
    if (byCarta.length) {
      let temp: any = await this.openDespesasCttModal(byCarta);
      this.submitting = true;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      if (!temp || (temp.hasOwnProperty('status') && temp['status'] === 'OPENED') || !temp.hasOwnProperty('cttList') || !temp.hasOwnProperty('id_registo_ctt')) {
        this.submitting = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        return false;
      } 
      byCarta.forEach((el, i) => {
        el.tipo_correio = temp.cttList[i];
      });
      let resp = await this.generateLetters(byCarta, temp.id_registo_ctt);
      if (!resp) {
        this.submitting = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        return false;
      }
    }
    return true;
  }
  
  async processEmails(byEmail): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (byEmail.length) {
        this.sendingEmails = true;
        try {
          let res = await this.sendEmails(byEmail);
          if (res == null || res == 0) {
            this.sendingEmails = false;
            resolve(false);
            return;
          }
          // PRESENT SUCCESS TOASTR
          if (this.origin === 'ASSEMBLEIAS_COMUNICACOES' || this.origin === 'CORRESPONDENCIA_AC') {
            this.toastr.success(res + (res == 1? ' email foi enviado' : ' emails foram enviados') + ' com sucesso.', 'Envio de Emails', { timeOut: 4000 });
          } else if (this.origin === 'CORRESPONDENCIA_COM') {
            this.toastr.success('O email foi enviado com sucesso.', 'Envio de Email', { timeOut: 4000 });
          }
        } catch(err) {
          this.utils.apiErrorMsg(err);
          if (this.origin === 'ASSEMBLEIAS_COMUNICACOES' || this.origin === 'CORRESPONDENCIA_AC') {
            this.toastr.error('Ocorreu um erro no envio de emails. Nenhum email foi enviado.', 'Envio de Emails', { timeOut: 4000 });
          } else if (this.origin === 'CORRESPONDENCIA_COM') {
            this.toastr.error('Ocorreu um erro no envio de email. O email não foi enviado.', 'Envio de Emails', { timeOut: 4000 });
          }
          // PRESENT ERROR TOASTR
          this.submitting = false;
          this.sendingEmails = false;
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(false);
        }
        this.sendingEmails = false;
      }
      resolve(true);
    });
  }

  rowSelectionToggle(target) {
    if (target === 'EMAIL') {
      (this.entityList.find(el => !el.checkedEmail && el.emails != null && el.emails.trim() !== '')) ? this.entityList.map(el => { el.checkedEmail = (el.emails !== null && el.emails.trim() !== ''); return el; }) : this.entityList.map(el => el.checkedEmail = false );
    } 

    if (target === 'CARTA') {
      (this.entityList.find(el => !el.checkedCarta)) ? this.entityList.map(el => el.checkedCarta = true) : this.entityList.map(el => el.checkedCarta = false);
    }
  }

  sendEmails(entities: Array<Entity>): Promise<number> {
    return new Promise(async (resolve, reject) => {
      // GENERATE OBJECT
      let attachment = (this.anexos.length > 0) ? JSON.stringify(this.anexos) : null;
      let emailsToSend = [];
      
      let footer = null;
      switch (this.origin) {
        case 'ASSEMBLEIAS_COMUNICACOES':
          footer = this.utils.getEmailFooterSimple(false, undefined, true);
          entities.forEach(el => {
            let body = el.bodyMsg ? el.bodyMsg.replace(/\n/g, '<br>'): '';
            el.emails.split(';').filter(el => !!el).map(el => el.trim()).forEach(it => {
              let temp = {
                from: this.emailFrom,
                to: it,
                subject: el.subjectMsg,
                body: '<div style="text-align: justify;">' + body + '</div>' + footer,
                attachment: attachment,
                filename: 'FILE',
                fromName: this.emailFromName,
                toName: el['nome'],
              }
              emailsToSend.push(temp);
            });
          });
          // SEND EMAILS
          this.api.sendEmailV2(emailsToSend).subscribe(res => {
            resolve(emailsToSend.length);
          }, err => { reject(err); });
          break;
        case 'CORRESPONDENCIA_COM':
          let assinatura = this.assinatura != null ? this.assinatura : { name: this.emailFromName, email: this.emailFrom };
          let body = '<div style="text-align: justify;">' + this.bodyMsg + '</div>' + this.utils.getEmailFooterSimple(this.addAssinatura, assinatura);
          let emailToSend: Email = {
            from: this.emailFrom,
            fromName: this.emailFromName,
            subject: this.subjectMsg,
            body: body,
            filename: 'File',
            attachment: this.anexos,
            bcc: [],
            cc: [],
            to: [],
            origin: 'CORRESPONDENCIA_COM',
            userId: this.userSession.getUserId(),
            id_comunicacao:null,
          }
          entities.forEach((el:Entity) => {
            el.emails.split(';').filter(el => !!el).map(el => el.trim()).forEach(it => {
              emailToSend[el.communicationType].push({name: el.nomeEntidade, email: it});
            });
          });
          // SEND EMAILS
          let regCorrespondenciaCom = this.utils.getRegistoComunicacaoCorrespondenciaEmail(entities);
          this.api.saveRegistoComunicacaoCorrespondencia('CORRESPONDENCIA_COM', null, null, this.subjectMsg, body, regCorrespondenciaCom, this.userSession.getUserId(), new Date()).subscribe(res => {
            if (res.success && res.data) {
              emailToSend.id_comunicacao = res.data;
              this.api.sendEmail(emailToSend, 'CORRESPONDENCIA_C').subscribe(res => {
                if (res && res.hasOwnProperty('status') && res.status == 'UNAUTHORIZED') {
                  this.toastr.error(this.appConfig.errMsg.unAuth.msg, this.appConfig.errMsg.unAuth.title);
                  resolve(0);
                }
                resolve(1);
              }, err => { 
                reject(err); 
              });
            } else {
              this.utils.apiErrorMsg(res);
              resolve(0);
            }
            }, err => {
              reject(err);
            });
          
          break;
      
        case 'CORRESPONDENCIA_AC':
          footer = this.utils.getEmailFooterSimple(false);
          entities.forEach((el:Entity) => {
            el.emails.split(';').filter(el => !!el).map(el => el.trim()).forEach(it => {
              let aux = {
                from: this.emailFrom,
                to: it,
                subject: el.subjectMsg + (el.condominioNome? ' - ' + el.condominioNome : ''),
                body: el.bodyMsg + footer,
                attachment: attachment,
                filename: 'File',
                fromName: this.emailFromName,
                toName: el.nomeEntidade,
                userId: this.userSession.getUserId(),
                origin: this.origin
              }
              emailsToSend.push(aux);
            });
          });
          let registos = entities.map(el => {
            let emails = el.emails.split(';').filter(email => !!email);
            let reg: RegistoComunicacaoSave = {
              cod_condominio: el.cod_condominio,
              assunto: el.subjectMsg + (el.condominioNome? ' - ' + el.condominioNome : ''),
              id_texto_predefinido: el.id_texto_predefinido? el.id_texto_predefinido : null,
              origem: this.origin,
              id_entidade: null,
              data_divida: el.data_divida ? this.utils.getFormatedDate(el.data_divida) : null,
              corpo: el.bodyMsg + footer,
              email_list: [{
                  nomeEntidade: el.nomeEntidade,
                  cod_fraccao: el.cod_fraccao,
                  tipo: el.tipo ? el.tipo : 'PROPRIETARIO',
                  cod: el.cod,
                  emails: emails,
                }],
              attachments: []
            }
            return reg;
          });

          // SEND EMAILS
          let res = await this.communicationsService.saveRegistoComunicacao(registos);
          if (!res) {
            resolve(0);
            return;
          }

          this.api.sendEmailV2(emailsToSend, null, this.origin).subscribe(res => {
            if (res && res.status) {
              this.utils.apiErrorMsg(res);
              resolve(0);
            } else {
              resolve(emailsToSend.length);
            }
          }, err => { 
            reject(err); 
          });

          
          
          
          // let regCorrespondenciaAvisos = this.utils.getRegistoComunicacaoCorrespondenciaEmail(entities);
          // this.(this.origin, this.selTipoDocumento, this.selData, null, null,regCorrespondenciaAvisos, this.userSession.getUserId(), new Date()).subscribe(res => {
          //   if (res.success && res.data) {
          //     this.api.sendEmailV2(emailsToSend, res.data, this.origin).subscribe(res => {
          //       if (res && res.status) {
          //         this.utils.apiErrorMsg(res);
          //         resolve(false);
          //       } else {
          //         resolve(true);
          //       }
          //     }, err => { 
          //       reject(err); 
          //     });
          //   } else {
          //     this.utils.apiErrorMsg(res);
          //     resolve(false);
          //   }
          //   }, err => {
          //     reject(err);
          // });

        break;

        case 'NOTICE_AVISOS':
          alert('NOT IMPLEMENTED');
          break;
        default:
          break;
      }

    });
  }

  getEmailBodyMapaDeQuotas(bodyMsg) {
    // let userAssinatura = null;
    // if (this.assinaturaSelected) {
    //   userAssinatura = this.assinaturaOpts.find(el => el.value === this.assinaturaSelected);
    // }

    let htmlEmail = '<div style="margin-bottom: 35px;text-align: justify;">';
    bodyMsg.split('\n').forEach((line, i) => {
        htmlEmail += (i ? '<br>' : '') + line;
    });

    htmlEmail += '</div>';
    // htmlEmail += (userAssinatura) ? this.utils.getEmailFooterV2(userAssinatura.name, userAssinatura.phone, userAssinatura.email) : this.utils.getEmailFooterV2();
    htmlEmail += this.utils.getEmailFooterSimple(true);

    return htmlEmail;
  }
  
  @ViewChild('editEmailToSendAlertRef', { static: false }) editEmailToSendAlertRef;
  editEmailToSendModalRef = null;
  editEmailToSendConfig: any = null;

  editEmailToSendFeedback = {show: false, timeout:null}

  editEmailToSendAssunto:string = null;
  editEmailToSendCorpo:string = null;
  editRowText(index: number): void {
    this.editEmailToSendAssunto = this.entityList[index].subjectMsg;
    this.editEmailToSendCorpo = this.entityList[index].bodyMsg;

    this.editEmailToSendModalRef = this.modalService
    .open(this.editEmailToSendConfig)
    .onApprove(() => {
      this.entityList[index].subjectMsg = this.editEmailToSendAssunto;
      this.entityList[index].bodyMsg = this.editEmailToSendCorpo;
      //Updates following communications with same type
      for (let i = index + 1; i < this.entityList.length; i++) {
        if (this.entityList[i].tipoComunicacao !== this.entityList[i - 1].tipoComunicacao) break;
        this.entityList[i].subjectMsg = this.editEmailToSendAssunto;
        this.entityList[i].bodyMsg = this.editEmailToSendCorpo;
      }
      console.log(this.entityList)
      this.editEmailToSendAssunto = null;
      this.editEmailToSendCorpo = null;
    })
    .onDeny(() => {
      this.editEmailToSendAssunto = null;
      this.editEmailToSendCorpo = null;
    });
  }

  approveEmailToSend() {
    this.utils.setFormFeedback(this.editEmailToSendFeedback);
    if (!this.editEmailToSendAssunto || this.editEmailToSendAssunto.trim() === '') return;
    if (!this.editEmailToSendCorpo || this.editEmailToSendCorpo.trim() === '') return;
    this.editEmailToSendModalRef.approve();
  }

  renderTableCellTipo(index:number):boolean {
    if (index === 0 || this.entityList[index - 1].tipoComunicacao !== this.entityList[index].tipoComunicacao) return true;
    return false;
  }

  getRowSpan(index:number): number {
    let i = index;
    for (i = index + 1; i < this.entityList.length; i++) {
      if (this.entityList[i].tipoComunicacao !== this.entityList[index].tipoComunicacao) return i - index;
    }
    return i - index;
  }

  getLetterBase64Format(entity) {
    return new Promise(async resolve => {
      switch (this.origin) {
        case 'ASSEMBLEIAS_COMUNICACOES':
        case 'CORRESPONDENCIA_COM':
        case 'CORRESPONDENCIA_AC':
          let entities = [entity];  

          let cond = this.codCondominio;
          if (this.multipleCondominios) cond = (entity as Entity).cod_condominio;
          let resp = await this.getLetterContactDetails(cond, entities);
          if (!resp) {
            resolve(null);
            return;
          }

          // HANDLE MORADA CONDOMINIO
          let condominioNome = (resp['condominio']) ? resp['condominio'].nome : '';
          let moradaCondominio: any = [];
          if (resp['condominio'] && resp['condominio'].morada) {
            try {
              let aux = '';
              let addressObj = JSON.parse(resp['condominio'].morada);
              Object.keys(addressObj).forEach((key, i) => {
                if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE');
              });
          
              moradaCondominio = (aux) ? aux.split('NEW_LINE') : [];
            } catch(e) {
              moradaCondominio = resp['condominio'].morada.split('NEW_LINE');
            }
          }
          this.cartasTitularesBancarios = entities.map(el => {
            if (this.origin !== 'CORRESPONDENCIA_AC' && this.origin !== 'ASSEMBLEIAS_COMUNICACOES') {
              el['subjectMsg'] = this.subjectMsg;
              el['bodyMsg'] = this.bodyMsg;
            }

            el.condominioNome = condominioNome;
            el.moradaCondominio = moradaCondominio;

            // HANDLE MORADA ENTIDADE
            let temp = (resp['entities']) ? resp['entities'].find(it => it.cod === el.cod) : null;
            if (temp)  {
              el['nomeEntidade'] = (temp.nome) ? temp.nome : '';
              if (temp.morada_fact) temp.morada = temp.morada_fact; 
              if (temp.morada) {
                try {
                  let aux = '';
                  let addressObj = JSON.parse(temp.morada);
                  Object.keys(addressObj).forEach((key, i) => {
                    if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE');
                  });
              
                  el['moradaEntidade'] = (aux) ? aux.split('NEW_LINE') : [];
                } catch(e) {
                  el['moradaEntidade'] = temp.morada.split('NEW_LINE');
                }
              }
            }

            return el;
          });

          setTimeout(async () => {
            let base64 = await this.comTitularesBancariosPdf.getBase64();
            resolve(base64);
          });
          break;
      }
    });
  }

  generateLetters(entities:Array<Entity>, despesasId:Array<any> = null): Promise<any> {
    return new Promise(async (resolve, reject) => {
      var resp = null;
      switch (this.origin) {
        case 'ASSEMBLEIAS_COMUNICACOES':

          resp = await this.getLetterContactDetails(this.codCondominio, entities);
          if (!resp) {
            resolve(null);
            return;
          }
        

          // HANDLE MORADA CONDOMINIO
          let condominioNome = (resp['condominio']) ? resp['condominio'].nome : '';
          let moradaCondominio: any = [];
          if (resp['condominio'] && resp['condominio'].morada) {
            try {
              let aux = '';
              let addressObj = JSON.parse(resp['condominio'].morada);
              Object.keys(addressObj).forEach((key, i) => {
                if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE');
              });
          
              moradaCondominio = (aux) ? aux.split('NEW_LINE') : [];
            } catch(e) {
              moradaCondominio = resp['condominio'].morada.split('NEW_LINE');
            }
          }
          this.cartasTitularesBancarios = entities.map(el => {
            el['subjectMsg'] = el.subjectMsg;
            el['bodyMsg'] = el.bodyMsg ? el.bodyMsg.replace(/\n/g, '<br>'): '';

            el.condominioNome = condominioNome;
            el.moradaCondominio = moradaCondominio;

            // HANDLE MORADA ENTIDADE
            let temp = (resp['entities']) ? resp['entities'].find(it => it.cod === el.cod) : null;
            if (temp)  {
              el['nomeEntidade'] = (temp.nome) ? temp.nome : '';
              if (temp.morada_fact) temp.morada = temp.morada_fact; 
              if (temp.morada) {
                try {
                  let aux = '';
                  let addressObj = JSON.parse(temp.morada);
                  Object.keys(addressObj).forEach((key, i) => {
                    if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE');
                  });
              
                  el['moradaEntidade'] = (aux) ? aux.split('NEW_LINE') : [];
                } catch(e) {
                  el['moradaEntidade'] = temp.morada.split('NEW_LINE');
                }
              }
            }

            return el;
          });
          this.cdRef.detectChanges();

          setTimeout(() => {
            this.comTitularesBancariosPdf.generatePdf(despesasId);
            resolve(true);
          });
          break;
        case 'CORRESPONDENCIA_COM': 
          let despesasByCondominio:Array<{cod_condominio, entidades:Array<Entity>}> = (entities as Array<Entity>).reduce((newArr, el) => {
            if (!newArr || !newArr.length || el.cod_condominio != newArr[newArr.length - 1].cod_condominio) {
              let entidades = [el];
              newArr.push({cod_condominio: el.cod_condominio, entidades: entidades });
              return newArr;
            }
            newArr[newArr.length - 1].entidades.push(el);
            return newArr;
          },[] as Array<{cod_condominio, entidades:Array<Entity>}>);

          let req = [];
          despesasByCondominio.forEach(condominio => {
            req.push(this.getLetterContactDetails(condominio.cod_condominio, condominio.entidades));
          });
          resp = await Promise.all(req);
          if (!resp) {
            reject(resp);
          }
        
          resp.forEach(res => {
            this.fillEntidadesDetails(res, entities);
          });
          
          this.cartasTitularesBancarios = entities.map(el => {
            el.subjectMsg = this.subjectMsg;
            el.bodyMsg = this.bodyMsg;
            return el;
          });

          this.filename = 'Correspondência';
          this.cdRef.detectChanges();

          setTimeout(() => {
            this.comTitularesBancariosPdf.generatePdf(despesasId);
            resolve(true);
          }, 1);

          break;
        case 'CORRESPONDENCIA_AC': 
            let res = null;
            try {
              let codCondominio = entities.find(el => !!el.cod_condominio);
              if (!codCondominio) reject(null);
              res = await this.getLetterContactDetails(codCondominio.cod_condominio, entities);
              if (!res) {
                reject(res);
              }
            } catch (err) {
              reject(err);
            }
           
            // HANDLE MORADA CONDOMINIO
            this.fillEntidadesDetails(res, entities);
            this.cartasTitularesBancarios = entities;
            
            this.filename = entities.length? entities[0].subjectMsg : 'Avisos e Cobrança';
            this.cdRef.detectChanges();

            setTimeout(() => {
              this.comTitularesBancariosPdf.generatePdf(despesasId);
              resolve(true);
            }, 1);
      }
      
    });
  }

  fillEntidadesDetails(res, entities) {
    
    let condominioNome = '';
    let condominio_nif = null;
    if (res['condominio']) {
      condominioNome = res['condominio'].nome;
      condominio_nif = res['condominio'].n_contribuinte;
    }
    let moradaCondominio: any = [];
    if (res['condominio'] && res['condominio'].morada) {
      try {
        let aux = '';
        let addressObj = JSON.parse(res['condominio'].morada);
        Object.keys(addressObj).forEach((key, i) => {
          if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE');
        });
    
        moradaCondominio = (aux) ? aux.split('NEW_LINE') : [];
      } catch(e) {
        moradaCondominio = res['condominio'].morada.split('NEW_LINE');
      }
    }
    

    // HANDLE MORADA ENTIDADE
    let entidades = res['entities'];
    entidades.forEach(entity => {
      let entityIndexes = this.utils.getAllIndexesObject(entities, ['cod_condominio', 'cod'], [res['condominio'].cod, entity.cod]);
      if (!entityIndexes.length) return;
      
      let nomeEntidade = (entity.nome) ? entity.nome : '';
      let moradaEntidade = null;
      let morada_fact = null;
      try{
          morada_fact = entity.morada_fact? JSON.parse(entity.morada_fact) : null;
      } catch(e) {
          morada_fact = entity.morada_fact;
      }
      if (morada_fact && morada_fact.address && morada_fact.postalCode && morada_fact.locality) {
        entity.morada = entity.morada_fact;
      }
      
      if (entity.morada) {
        try {
          let aux = '';
          let addressObj = JSON.parse(entity.morada);
          Object.keys(addressObj).forEach((key, i) => {
            if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE');
          });
          moradaEntidade = (aux) ? aux.split('NEW_LINE') : [];
        } catch(e) {
          moradaEntidade = entity.morada.split('NEW_LINE');
        }
      }

      entityIndexes.forEach(index => {
        entities[index].moradaCondominio = moradaCondominio;
        entities[index].condominioNome = condominioNome;
        entities[index].nomeEntidade = nomeEntidade;
        entities[index].moradaEntidade = moradaEntidade;
        entities[index].n_contribuinte = condominio_nif;
      });
    });
  }

  getLetterContactDetails(codCondominio, entities):Promise<{condominio, entities}> {
    return new Promise((resolve, reject) => {
      let req = [this.api.getCondominioContactDetails(codCondominio)];
      entities.forEach((el:Entity) => {
        req.push(this.api.getEntityContactDetails(el.cod, el.tipo));
      });
      forkJoin(req).subscribe(res => {
        if (!res.find(el => !el.hasOwnProperty('success') || (el.hasOwnProperty('success') && !el.success))) {
          resolve({ condominio: res[0].data, entities: res.splice(1, res.length).map(el => el.data) });
        } else {
          reject(null);
        }
      }, err => {
        reject(err);
      });
    });
  }

  openDespesasCttModal(entities: Array<Entity>) {
    return new Promise(async resolve => {
      try {
        let base64 = await this.getLetterBase64Format(entities[0]);
        let entitiesCttInput = entities.map(el => {
          let aux: EntityDespesasCTTModalInput = {
            cod: el.cod,
            nomeEntidade: el.nomeEntidade,
            cod_condominio: el.cod_condominio,
            condominioNome: el.condominioNome,
            cod_fraccao: el.cod_fraccao,
            nome_fraccao: el.nome_fraccao,
            tipo_entidade: el.tipo,
            base64FileArr: [],
            id_texto_predefinido: el.id_texto_predefinido,
            data_divida: el.data_divida,
          }
          return aux;
        })
        let temp = await this.despesasCttRef.open(entitiesCttInput, this.codCondominio, entities[0].subjectMsg, base64, this.idAssembleia, this.anexos);
        resolve(temp)
      } catch(err) {
        this.utils.apiErrorMsg(err);
      }
    });
  }
}
