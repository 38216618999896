import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { exportPDF, Group } from '@progress/kendo-drawing';


@Component({
  selector: 'app-relatorio-assembleia-pdf',
  templateUrl: './relatorio-assembleia-pdf.component.html',
  styleUrls: ['./relatorio-assembleia-pdf.component.scss']
})
export class RelatorioAssembleiaPdfComponent implements OnInit, OnDestroy {

  nDeliberacoes = [1, 2, 3, 4, 5, 6];

  @Input() data = {
    nomeCondominio: '',
    nomeColaborador: '',
    numAta: '',
    data: '',
    hora: '',
  };

  @ViewChild('pdfRef', { static: false }) pdfController;

  now = null;
  filename = 'default_filename';

  constructor(public appConfig: AppConfigService) { }

  ngOnInit() {}

  ngOnDestroy() {}

  generatePdf() {
    this.now = new Date();

    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';
    this.pdfController.saveAs(this.filename + '.pdf');
  }

  getBase64() {
    return new Promise((resolve, reject) => {
      this.now = new Date();

      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');

        resolve(base64);
      }).catch(err => {
        reject(err);
      });
    });
  }

  export() {
    this.now = new Date();

    return this.pdfController.export()
  }
  
}
