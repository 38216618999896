import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { EstadosContenciosos } from '../business-model-interfaces/estados-contenciosos';
import { Fraccao, registoProprietario, registoProprietarioBD, saveProprietario, saveProprietarioData } from '../business-model-interfaces/fraccoes'
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})
export class FraccoesService {

  constructor(
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public api: ApiService,
    public toastr: ToastrService,
  ) { }

  
  getFraccoesByProprietario(cod_proprietario): Promise<Array<Fraccao>> {
    return new Promise((resolve) => {
      this.api.getFraccoesByProprietario(cod_proprietario).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  getFracaoRegistoProprietarios(id_fracao): Promise<Array<registoProprietario>> {
    return new Promise((resolve, reject) => {
      this.api.getFracaoRegistoProprietarios(id_fracao).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data.map(prop => this.convertRegistoProprietarioType(prop)));
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    })
  }

  saveProprietario(body:saveProprietario): Promise<saveProprietarioData> {
    return new Promise((resolve, reject) => {
      this.api.saveProprietario(body).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          let msg = res.data.n_avisos? 'Foram alterados ' + res.data.n_avisos + ' avisos' : null;
          if (res.data.n_creditos) {
            msg = msg? msg + " e emitidos " + res.data.n_creditos + " créditos" : 'Foram emitidos ' + res.data.n_creditos + " créditos";
          }
          if (msg) {
            msg += ' com sucesso';
            this.toastr.success(msg,'Actualização de Avisos / Créditos');
          }
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    })
  }

  checkRecibosChanged(data:saveProprietario): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.checkRecibosChanged(data).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    })
  }
  
  getEstadosContencioso(): Promise<Array<EstadosContenciosos>> {
    return new Promise((resolve) => {
      this.api.getEstadosContencioso().subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  convertRegistoProprietarioType(proprietario:registoProprietarioBD): registoProprietario {
    return {... proprietario, 
      data_inicio: proprietario.data_inicio ? this.utils.getDate(proprietario.data_inicio) : null, 
      data_fim: proprietario.data_fim ? this.utils.getDate(proprietario.data_fim) : null, 
    }
  }
}
