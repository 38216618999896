import { Component, OnInit, OnDestroy, ElementRef, ViewChild, DefaultIterableDiffer, ViewChildren } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
import { Location, formatDate } from '@angular/common';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { UserSessionService } from '../user-session.service';
import { RecibosService } from '../business-logic-services/recibos.service';

import { exportPDF, Group } from '@progress/kendo-drawing';
import { ReciboPdfComponent } from '../recibo-pdf/recibo-pdf.component';

@Component({
  selector: 'app-recibos-details',
  templateUrl: './recibos-details.component.html',
  styleUrls: ['./recibos-details.component.scss']
})
export class RecibosDetailsComponent implements OnInit {

  transitionController = new TransitionController();

  // DESPESAS TABLE VARIABLES
  linhasReciboCol = [
    // { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'n_aviso', name: 'Nº Doc', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered' },
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: false, centered: false },
    { key: 'valor', name: 'Recebido', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
  ];
  linhasRecibo: Array<any> = [];
  valorTotal = null;

  fetchDone = false;
  details = null;

  now = new Date();

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  @ViewChild('emailAlertRef', { static: false }) emailAlertRef;
  emailModalRef = null;
  emailAlertConfig: any = null;

  @ViewChild('reciboPDF', { static: false }) previewReciboPDF: ReciboPdfComponent;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public cdRef:ChangeDetectorRef,
              public router: Router,
              public location: Location,
              public message: MessageService,
              public modalService: SuiModalService,
              public userSession: UserSessionService,
              public recibos: RecibosService,
              public appConfig: AppConfigService) { }

  ngOnInit() {
    this.getDetails();
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngAfterViewInit() {
    this.emailAlertConfig = new TemplateModalConfig<IContext, string, string>(this.emailAlertRef);
    this.emailAlertConfig.closeResult = "closed";
    this.emailAlertConfig.size = 'mini';
    this.emailAlertConfig.transition = 'fade up';
    this.emailAlertConfig.transitionDuration = 400;
  
    this.editAlertConfig = new TemplateModalConfig<IContext, string, string>(this.editAlertRef);
    this.editAlertConfig.closeResult = "closed";
    this.editAlertConfig.size = 'mini';
    this.editAlertConfig.transition = 'fade up';
    this.editAlertConfig.transitionDuration = 400;
  }

  getPaymentType(banc) {
    if (banc === 'CAIXA') {
      return 'Numerário';
    } else {
      return 'Transferência';
    }
  }

  isRemoved = false;
  removedObj = {
    how: null,
    when: null,
    obs: null,
  }
  dateOK = true;
  getDetails() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getRecibosDetails(this.route.snapshot.params.id).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.details = res.data;
        this.details['nomeBanco'] = (res.data.banco) ? res.data.banco : res.data.conta;

        if (this.details.active === '0') {
          this.isRemoved = true;

          this.removedObj.how = this.details.alterado_por;
          this.removedObj.when = (this.details.alterado_em) ? new Date(this.details.alterado_em) : null;
          this.removedObj.obs = this.details.del_razao;
        }

        let condMoradaAux = this.details.cond_morada;

        this.details.pag_morada_fact = this.utils.formatEmptyMoradaObject(this.details.pag_morada_fact);
        this.details.pag_morada = this.utils.formatEmptyMoradaObject(this.details.pag_morada);
        if (this.details.pag_morada === '{"address":null,"postalCode":null,"locality":null}') this.details.pag_morada = null;
        let pagMoradaAux = (this.details.pag_morada_fact && this.details.pag_morada_fact !== '' && this.details.pag_morada_fact !== '{"address":null,"postalCode":null,"locality":null}') ? this.details.pag_morada_fact : this.details.pag_morada;

        // HANDLE OBJECT MORADA - CONDOMINIO
        try {
          let aux = '';
          let addressObj = JSON.parse(this.details.cond_morada);
          Object.keys(addressObj).forEach((key, i) => {
            if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
          });

          condMoradaAux = aux;
          this.details.cond_morada = (aux) ? aux.replace(/NEW_LINE/g, ', ') : null;
        } catch(e) {}

        // HANDLE OBJECT MORADA - CONDOMINIO
        try {
          let aux = '';
          let addressObj = JSON.parse(pagMoradaAux);
          Object.keys(addressObj).forEach((key, i) => {
            if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
          });

          pagMoradaAux = aux;
        } catch(e) {}

        this.details.cond_morada_pdf = (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [];
        this.details.pag_morada_fact_pdf = (pagMoradaAux) ? pagMoradaAux.split(/NEW_LINE/g) : [];

        this.details.cond_morada = (condMoradaAux) ? condMoradaAux.replace(/NEW_LINE/g, ', ') : null;
        this.details.pag_morada_fact = (pagMoradaAux) ? pagMoradaAux.replace(/NEW_LINE/g, ', ') : null;

        // CHECK IF DATE IS VALID
        if (this.details.criado_em) {
          let d = new Date(this.details.criado_em);

          if ( d instanceof Date && !isNaN(d.getTime()) ) {
            this.details.criado_em = new Date(this.details.criado_em);
          } else {
            this.dateOK = false;
          }
        } else {
          this.details.criado_em = null;
          this.dateOK = false;
        }

        this.details.data = (this.details.data) ? this.utils.getDate(this.details.data) : null;
        this.details.dt_valor = (this.details.dt_valor) ? this.utils.getDate(this.details.dt_valor) : null;
        this.details['diffDates'] = this.utils.compareDayDates(this.details.data, this.details.dt_valor) !== 0;

        let isBanco = (this.details.banco) ? this.getPaymentType(this.details.banco) : null;
        this.details.utilizador = this.details.first_name + ' ' + this.details.last_name;

        this.details['tipo_pagamento'] = null;
        if (this.details.conta) {
          this.details['tipo_pagamento'] = (this.details.conta === 'CAIXA') ? 'N' : 'T';
        }

        this.valorTotal = 0;
        this.linhasRecibo = [];
        res.linhas_recibo.filter(el => ((el.n_aviso && el.data) || el.tipo_doc === 'C')).forEach(el => {
          let aux = {
            n_aviso: el.tipo_doc + ' - ' + el.n_aviso,
            data: (el.data) ? this.utils.getDate(el.data) : null,
            fraccao: (el.cod_fraccao && el.nome_fraccao) ? el.cod_fraccao + ' - ' + el.nome_fraccao : null,
            descricao: el.descricao,
            valor: (el.tipo_doc === 'C') ? -1 * Number(el.valor) : Number(el.valor),
          }
          this.linhasRecibo.push(aux);

          (el.tipo_doc === 'C') ? this.valorTotal -= Number(el.valor) : this.valorTotal += Number(el.valor);
        });

        this.fetchDone = true;
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  async exportPDF() {
    // if (this.isRemoved) {
    //   this.toastr.error('Não é possível imprimir o presente recibo.', 'Recibo Removido', { timeOut: 4000 });
    //   return;
    // }
    this.previewReciboPDF.exportPDF(this.details.id);
  }

  public dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  emailInvalid = false;
  hasEmailAddr() {
    if (!this.utils.validateEmail(this.emailAddr)) {
      this.emailInvalid = true;
      this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
    }

    this.submittingFormEmail = true;
    if (!this.emailAddr || this.emailInvalid) {
      setTimeout(() => { this.submittingFormEmail = false; this.emailInvalid = false; }, 4000);
      return;
    }

    this.emailModalRef.approve();
  }

  emailAddr = null;
  emailName = null;
  submittingFormEmail = false;

  sendingEmail = false;
  sendEmail() {
    if (this.sendingEmail) return;

    // CHECK IF EMAIL IS SET
    if (this.details.hasOwnProperty('email_1') && this.details.email_1) {
      this.emailAddr = this.details.email_1;
    }

    this.emailModalRef = this.modalService.open(this.emailAlertConfig)
      .onApprove(async () => {
          this.submittingFormEmail = false; 

          this.sendingEmail = true;

          let base64 = await this.previewReciboPDF.getBase64(this.details.id);

          let from = this.appConfig.company.email;;
          let to = this.emailAddr;

          let subjectMsg = 'Envio de recibo nº ' + this.details.n_recibo;
          let bodyMsg = this.getEmailBody();
          if (this.isRemoved) {
            subjectMsg = 'Anulação do recibo nº ' + this.details.n_recibo;
            bodyMsg = this.getEmailBody(true);
          }

          let attachment = base64;
          let fromName = 'VERTIS - Gestão Condomínios';
          let toName = (this.details.email_1 === this.emailAddr && this.details.pag_nome) ? this.details.pag_nome : '';
          
          let filename = 'recibo_' + formatDate(new Date(), this.format, this.locale) + '.pdf';

          let emailsToSend = [{
            from: from,
            to: to,
            subject: subjectMsg,
            body: bodyMsg,
            attachment: attachment,
            filename: filename,
            fromName: fromName,
            toName: toName,
          }];
          this.api.sendEmailV2(emailsToSend).subscribe(res => {
            this.toastr.success('Recibo enviado com sucesso.', 'Email Enviado', { timeOut: 4000 });

            this.sendingEmail = false;
          }, err => { this.sendingEmail = false; });
      })
      .onDeny(() => { 
        this.emailAddr = null;
        this.submittingFormEmail = false;
      });
  }

  getEmailBody(anulado=false) {
    let htmlEmail = '';

    // TEXTO INICIAL
    if (anulado) {
      htmlEmail += '<div style="margin-bottom: 35px;">';
      this.appConfig.reciboAnuladoBody.forEach(line => {
        if (line) {
          htmlEmail += '<span>' + line + '</span>';
        } else {
          htmlEmail += '<span><br><br></span>';
        }
      });
      htmlEmail += '</div>';
    } else {
      htmlEmail += '<div style="margin-bottom: 35px;">';
      this.appConfig.reciboBody.forEach(line => {
        if (line) {
          htmlEmail += '<span>' + line + '</span>';
        } else {
          htmlEmail += '<span><br><br></span>';
        }
      });
      htmlEmail += '</div>';
    }

    htmlEmail += this.utils.getEmailFooter();

    return this.utils.setEmailTemplate(htmlEmail);
  }

  goToCondominio() {
    if ( !(this.details && this.details.hasOwnProperty('classificacao')) ) return;

    let classificacao = this.details.classificacao;
    let cod = this.details.cod_condominio;
    let id = this.details.cond_id;
    let nome = this.details.cond_nome;

    this.router.navigate(['condominios/condominio/geral', id]);

    // Emit signal to breadcrumb component
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${classificacao} - ${cod} - ${nome}` });
  }

  
  @ViewChild('editAlertRef', { static: false }) editAlertRef;
  editModalRef = null;
  editAlertConfig: any = null;
  newReciboData: Date = null;
  maxDate:Date = null;
  async editReciboData() {
    if (!this.details) return;

    try {
      let canEdit = await this.recibos.canChangeDataRecibo(this.details.id);
      if (!canEdit) {
        this.toastr.error(this.appConfig.errMsg.recibos.cantEdit.msg, this.appConfig.errMsg.recibos.cantEdit.title);
        return;
      }
    } catch (err) {
      return;
    }
    this.maxDate = new Date();
    this.maxDate.setHours(0,0,0,0);
    this.newReciboData = new Date(this.details.data);
    this.editModalRef = this.modalService.open(this.editAlertConfig)
    .onApprove(async () => {
        this.submittingEdit = false;
      })
      .onDeny(() => { 
        this.submittingEdit = false;
      });

  }
  
  submittingEdit = false;
  submitReciboEdit():Promise<boolean> {
    return new Promise(async (resolve) => {
      
      if (!this.newReciboData || this.utils.compareDayDates(this.newReciboData, this.maxDate) > 0) {
        this.submittingEdit = true;
        setTimeout(() => {
          this.submittingEdit = false;
        }, 4000);
        resolve(false);
        return;
      }

      try {
        await this.recibos.changeDataRecibo(this.details.id, this.newReciboData);
      } catch (err) {
        return;
      }
      
      this.editModalRef.approve();
      this.getDetails();
    });
  }
}
