import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { File } from '../business-model-interfaces/file';
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})
export class DespesasService {

  constructor(
    public api: ApiService,
    public utils: UtilitiesService,
    public toastr: ToastrService,
    public appConfig: AppConfigService,
  ) { }


  getDespesaFiles(id): Promise<Array<File>> {
    return new Promise((resolve, reject) => {
      this.api.getDespesaFiles(id).subscribe(res => {
        if (res && res.success) {
          resolve(res.data);
        } else {
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      })
    });
  }
}
