import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalSize, SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';

interface IContext {
  data:string;
}

export interface ModalDefaultOptions {
  cancelText:string
}

@Component({
  selector: 'app-modal-default',
  templateUrl: './modal-default.component.html',
  styleUrls: ['./modal-default.component.scss']
})
export class ModalDefaultComponent implements OnInit {

  @Input() deleting = false;
  @Input() confirming = false;

  title: string = null;
  msg: string = null;
  subMsg: string = null;
  confirmBtnText: string = null;
  deleteBtnText = null;
  canCancel = false;
  needMotivo = false;
  motivo:string = null;
  deleteFunc = null;
  approveFunc = null;
  cancelText = null;

  @ViewChild('alertRef', { static: false }) alertRef;
  modalRef = null;
  alertConfig: any = null;
  constructor(public modalService: SuiModalService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.alertConfig = new TemplateModalConfig<IContext, string, string>(this.alertRef);
    this.alertConfig.closeResult = "closed";
    this.alertConfig.size = 'small';
    this.alertConfig.isClosable = false;
    this.alertConfig.transition = 'fade';
    this.alertConfig.transitionDuration = 250;
  }


  open(title, msg:string, subMsg:string, confirmBtnText:string, deleteBtnText:string, modalSize:ModalSize, deleteFunc: () => Promise<boolean>, approveFunc: () => Promise<boolean>, canCancel=true, needMotivo = false, options: ModalDefaultOptions = null):Promise<{action:'CONFIRM'|'DELETE', motivo:string}> {
    return new Promise((resolve => {
      this.deleting = false;
      this.confirming = false;
      this.submitting = false;
      this.title = title;
      this.msg = msg;
      this.subMsg = subMsg;
      this.confirmBtnText = confirmBtnText;
      this.deleteBtnText = deleteBtnText;
      this.alertConfig.size = modalSize;
      this.canCancel = canCancel;
      this.needMotivo = needMotivo;
      this.motivo = null;
      this.deleteFunc = deleteFunc;
      this.approveFunc = approveFunc;
      this.cancelText = 'Cancelar';
      if (options) {
        if (options.cancelText && options.cancelText.trim() !== '') this.cancelText = options.cancelText;
      }

      this.modalRef = this.modalService
        .open(this.alertConfig)
        .onApprove((action:'CONFIRM'|'DELETE') => { resolve({
          action: action,
          motivo: this.needMotivo? this.motivo : null
        }) })
        .onDeny(() => { resolve(null) });
    }))
  }

  async delete() {
    if (!this.validateForm()) return;

    if (this.deleteFunc) {
      let res = await this.deleteFunc();
      if (!res) return;
    }
    this.modalRef.approve('DELETE');
  }

  submitting = false;
  async confirm() {
    if (!this.validateForm()) return;

    if (this.approveFunc) {
      let res = await this.approveFunc();
      if (!res) return;
    }
    this.modalRef.approve('CONFIRM');
  }

  validateForm() {
    this.submitting = true;
    setTimeout(() => {
      this.submitting = false;
    }, 4000);
    return !this.needMotivo || (this.motivo && this.motivo.trim() !== '');
  }

}
