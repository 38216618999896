import { Component, Input, OnInit, Output, ViewChild, EventEmitter, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { AppStateService } from '../app-state.service';
import { UnsavedChangesModalComponent } from '../unsaved-changes-modal/unsaved-changes-modal.component';
import { fileExt } from '../business-model-interfaces/anexo';
import { OrdemTrabalhoLabel } from '../configurations';

interface IContext {
  data: string;
}

export interface AnexoOpt {
  type: 'RELATORIO_ASSEMBLEIA' | 'ATA_ASSINADA' | 'LISTA_PRESENCAS' | 'PROCURACOES' | 'LISTA_PRESENCAS_PROCURACOES' | 'ORDEM_TRABALHO' | 'CONTA_CORRENTE' | 'ORCAMENTO' | 'BALANCETE' | 'CONTENCIOSO_ACORDO' | OrdemTrabalhoLabel | 'OTHER',
  label: string,
  ext: fileExt
  filename: string,
  base64: string,
  automatic: boolean,
  isUploaded: boolean,
  fileId: string | number,
  changedContent: boolean,
  maxSize?: number,
  canDelete?: boolean,
  canUpload?: () => boolean,
  uploadFunction?: (file, fileExt: fileExt, filename) => Promise<boolean>,
  deleteFunction?: () => Promise<boolean>,
}


@Component({
  selector: 'app-listagem-anexos-modal',
  templateUrl: './listagem-anexos-modal.component.html',
  styleUrls: ['./listagem-anexos-modal.component.scss']
})
export class ListagemAnexosModalComponent implements OnInit {

  @Input() downloadFunction: (row) => Promise<boolean> = null;
  @Input() canSubmit: boolean = false;

  canEdit: boolean = false;

  title: string = 'Anexos';
  lazyUpdate = true;

  anexosOptsOrig: Array<AnexoOpt> = [];
  anexosOpts: Array<AnexoOpt> = [];

  filename: string = 'Nenhum ficheiro selecionado...';
  loadingModal = false;

  //2Mb
  maxSize = 2097152;

  @ViewChild('anexosAlertRef', { static: false }) anexosAlertRef;
  anexosModalRef = null;
  anexosAlertConfig: any = null;

  @ViewChild('uploadAlertRef', { static: false }) uploadAlertRef;
  uploadModalRef = null;
  uploadAlertConfig: any = null;

  @ViewChild('unsavedChangesModal', { static: false }) unsavedChangesModal: UnsavedChangesModalComponent;


  constructor(public modalService: SuiModalService,
    public toastr: ToastrService,
    public api: ApiService,
    public utils: UtilitiesService,
    public cdRef: ChangeDetectorRef,
    public appConfig: AppConfigService,
    public appState: AppStateService,
    public message: MessageService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.anexosModalRef) this.anexosModalRef.deny();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }


  ngAfterViewInit() {
    this.anexosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.anexosAlertRef);
    this.anexosAlertConfig.isClosable = false;
    this.anexosAlertConfig.closeResult = "closed";
    this.anexosAlertConfig.size = 'small';
    this.anexosAlertConfig.transition = 'fade';
    this.anexosAlertConfig.transitionDuration = 250;

    this.uploadAlertConfig = new TemplateModalConfig<IContext, string, string>(this.uploadAlertRef);
    this.uploadAlertConfig.closeResult = "closed";
    this.uploadAlertConfig.size = 'tiny';
    this.uploadAlertConfig.transition = 'fade';
    this.uploadAlertConfig.transitionDuration = 250;
  }

  async anexosAction(row: AnexoOpt, type) {
    switch (type) {
      case 'UPLOAD':
        // CHECK IF REPRESENTANTES IN PRESENCAS TABLE
        if (!this.canEdit || ('canUpload' in row && !row.canUpload())) {
          return;
        }
        // OPEN UPLOAD MODAL
        this.filename = 'Nenhum ficheiro selecionado...';
        this.base64File = null;
        this.toUploadLabel = row.label;
        this.toUploadMaxSize = row.hasOwnProperty('maxSize') ? row.maxSize : this.maxSize;
        this.uploadModalRef = this.modalService
          .open(this.uploadAlertConfig)
          .onApprove(async () => {
            if (!this.base64File) return;

            if ('uploadFunction' in row) {
              let res = await row.uploadFunction(this.base64File, this.fileToUploadExt, row.filename);
              if (!res) return;
            }
            row.base64 = this.base64File;
            row.ext = this.fileToUploadExt;
            this.toUploadLabel = null;
            row.isUploaded = true;
            row.changedContent = true;
            row.fileId = null;
            this.toUploadMaxSize = null;
          })
          .onDeny(() => {
            this.toUploadLabel = null;
            this.toUploadMaxSize = null;
          });

        break;
      case 'DOWNLOAD':
        if (!row.isUploaded) return;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });


        if (row.base64) {
          this.utils.downloadFile('data:application/pdf;base64,' + row.base64, row.filename);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          return;
        }

        if (row.fileId && !row.changedContent) {
          this.api.getFile(row.fileId).subscribe(res => {
            if (res.success && res.data) {
              let filenameFormatted = this.utils.getFileNameFormatted(res.data.filename);
              this.utils.downloadFile('data:application/pdf;base64,' + res.data.file, filenameFormatted);
            } else {
              this.utils.apiErrorMsg(res);
            }
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });

          });
          return;
        }

        await this.downloadFunction(row);

        break;
      case 'DELETE':
        if (!this.canEdit) return;

        if (row.hasOwnProperty('deleteFunction')) {
          let res = await row.deleteFunction();
          if (!res) return;
        }

        row.base64 = null;
        row.isUploaded = false;
        row.changedContent = true;
        row.fileId = null;
    }
  }


  openModal(title: string, anexosOpts, lazyUpdate, canEdit: boolean, label: string = null): Promise<Array<any>> {
    return new Promise((resolve) => {
      this.title = title;
      this.canEdit = canEdit;
      this.lazyUpdate = lazyUpdate;
      this.anexosOpts = anexosOpts.map(el => {
        el.canDelete = el.hasOwnProperty('canDelete') ? el.canDelete : true;
        return el;
      });
      this.anexosOptsOrig = JSON.parse(JSON.stringify(anexosOpts));
      this.anexosModalRef = this.modalService
        .open(this.anexosAlertConfig)
        .onApprove(() => {
          resolve(this.anexosOpts);
        })
        .onDeny(() => {
          resolve(null);
        });
      if (label) {
        let row = this.anexosOpts.find(el => el.label === label);
        if (row) this.anexosAction(row, 'UPLOAD');
      }
    });
  }
  closeModal() {
    if (this.lazyUpdate && this.anexosOpts.filter(el => !el.automatic).find(el => !!el.changedContent)) {
      this.openUnsavedChangesModal();
      return;
    }
    this.anexosModalRef.deny();
    return;
  }


  async openUnsavedChangesModal() {
    let save = await this.unsavedChangesModal.openModal();
    if (save === 'CONFIRM') {
      this.anexosModalRef.approve();
    } else if (save === 'DENY') {
      this.anexosModalRef.deny();
    }
  }

  @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;
  fileToUpload = null;
  fileToUploadExt: fileExt = null;
  base64File = null;
  toUploadLabel = null;
  toUploadMaxSize = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files[0];
    const reader = new FileReader();

    this.filename = this.fileToUpload['name'];
    if (this.fileToUpload['name'].toLowerCase().indexOf('.pdf') === -1) {
      this.toastr.error('O ficheiro selecionado não é suportado. Por favor, submeta um ficheiro com extensão .pdf.', 'Ups...!', { timeOut: 4000 });
      this.fileInputRef.nativeElement.value = '';
      this.filename = 'Nenhum ficheiro selecionado...';
      return;
    }

    // if (this.fileToUpload.size > 8388608) {
    if (this.fileToUpload.size > this.toUploadMaxSize) {
      let bytesStr = this.utils.formatBytes(this.toUploadMaxSize, 0);
      this.toastr.error('O ficheiro selecionado é superior ao tamanho máximo suportado (' + bytesStr + ')', 'Ups...!', { timeOut: 4000 });
      this.fileInputRef.nativeElement.value = '';
      this.filename = 'Nenhum ficheiro selecionado...';
      return;
    }

    this.fileToUploadExt = this.fileToUpload.name.substring(this.fileToUpload.name.lastIndexOf('.')).toLowerCase();


    reader.onload = (ev) => {
      let binaryString = ev.target['result'];

      this.base64File = binaryString;

      this.base64File = this.base64File.replace('data:application/pdf;base64,', '');
      this.base64File = this.base64File.replace(' ', '+');
    };
    reader.readAsDataURL(this.fileToUpload);
  }
}

