import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent, forkJoin } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
interface IContext {
  data: string;
}
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';
import { setDefaultService } from 'selenium-webdriver/opera';
import { resolve } from 'url';
import { Processamento, ProcessamentoDetails, SaveProcessamento } from '../business-model-interfaces/processamentos';
import { ProcessamentosService } from '../business-logic-services/processamentos.service';
import { ProcessamentoPDF, ProcessamentoPdfComponent, ProcessamentoPDFInput } from '../processamento-pdf/processamento-pdf.component';
import { AssembleiaQuotaExtraNavState } from '../navigation';
import { ProcessamentoDuplicationModalComponent } from '../processamento-duplication-modal/processamento-duplication-modal.component';


interface Prestacao {
  id,
  descricao: string,
  data: Date,
  data_venc: Date,
  valor: number,
}

@Component({
  selector: 'app-processamento',
  templateUrl: './processamento.component.html',
  styleUrls: ['./processamento.component.scss']
})
export class ProcessamentoComponent implements OnInit {

  @ViewChild('scroller', { static: false }) scroller: ElementRef;

  @ViewChild('pdf', { static: false }) pdfController;

  @ViewChild('repartidoAjusteAlertRef', { static: false }) repartidoAjusteAlertRef;
  repartidoAjusteModalRef = null;
  repartidoAjusteAlertConfig: any = null;
  repartidoAjuste: number = 0;
  repartidoAjusteDist: number[] = [];

  isCreate = false;
  isLancado = false;

  transitionController = new TransitionController();
  submittingModalForm = false;
  submittingForm = false;
  loading = false;
  loadingModal = false;
  loadingModalPayment = false;
  searchable: boolean = true;

  apiSub = null;

  comp = 'processamento';
  initState = null;
  prevState = null;

  // PROCESSAMENTO FORM
  cod = null;
  mes = null;
  ano = null;
  data: Date = null;
  data_venc: Date = null;
  cod_condominio: { cod, exercicio?, id, nome } = null;
  cod_zona = 'ALL';
  exercicio = null;
  tipo_proc = 'E';  // O, F, E, P, S
  descricao = null;
  pluri_anual = null;
  valor = null;
  tipo_reparticao: 'UNICO' | 'REPARTIDO' = 'UNICO'; // 'REPARTIDO'
  reparticao: 'P' | 'E' | 'M' = 'P';
  dia = 1;
  dia_venc = 8;
  fracoes_info: Array<{ cod_fracao: string, cod_proprietario: string, valor: string }> = [];
  meses_cobranca: string = null;

  processamentoId = null;
  processamentoCod = null;
  processamentoDate = null;
  processamentoDetails = null;

  condominiosListOrig = [];
  condominiosList = [];
  condSearching = false;

  months = ['Jan.', 'Fev.', 'Mar.', 'Abr.', 'Mai.', 'Jun.', 'Jul.', 'Ago.', 'Set.', 'Out.', 'Nov.', 'Dez.'];
  reparticaoStartYear = new Date();
  reparticaoEndYear = new Date();
  procMonths = [[{ sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }]];
  selMesesCobranca = '1';

  now = new Date();

  fraccoesListCol = [
    { key: 'zona_nome', name: 'Zona', type: 'text', sort: null, searchable: true, center: false, class: 'col-align-left two wide' },
    { key: 'fraccao_nome', name: 'Fracção', type: 'text', sort: null, searchable: true, center: false, class: 'col-align-left' },
    { key: 'condomino_nome', name: 'Condómino', type: 'text', sort: null, searchable: true, center: false, class: 'col-align-left' },
    { key: 'permilagem', name: 'Permilagem', type: 'text', sort: null, searchable: true, center: false, class: 'col-align-left' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: false, center: false, class: 'col-align-right two wide' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: true, class: 'col-centered one wide' },
  ];
  fraccoesList: Array<{ cod_proprietario: string, zona_nome: string, fraccao_nome: string, condomino_nome: string, valor: number | string, checked: boolean, credito: number, cod, zona_cod, permilagem: number, proprietarios: Array<{ name: string, value: String }> }> = [];
  fraccoesListOrig: Array<any> = [];

  avisosCreditosListCol = [
    { key: 'doc_num', name: 'Nº', type: 'text', sort: null, class: 'col-centered two wide' },
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, class: 'two wide' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, class: 'four wide' },
    { key: 'condomino', name: 'Condómino', type: 'text', sort: null, class: '' },
    { key: 'dt_emissao', name: 'Data Emissão', type: 'date', sort: null, class: 'col-centered two wide' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, class: 'col-align-right two wide' },
  ];
  avisosCreditosList: Array<any> = [];

  prestacoesListCol = [
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, class: 'col-align-left' },
    { key: 'data', name: 'Data', type: 'text', sort: null, class: 'two wide col-centered' },
    { key: 'data_venc', name: 'Data Vencimento', type: 'text', sort: null, class: 'two wide col-centered' },
    { key: 'valor', name: 'Valor', type: 'text', sort: null, class: 'col-align-right' },
  ];
  prestacoesList: Array<Prestacao> = [];

  zonasOpts: Array<any> = [{ name: 'Todas', value: 'ALL' }];

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve({ name: this.cod_condominio.cod + ' - ' + this.cod_condominio.nome, value: this.cod_condominio }); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
      if (query) {
        this.condominiosTimer = setTimeout(() => {
          this.api.getAllCondominios(query).subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }, 400);
      } else {
        this.api.getAllCondominios('NULL').subscribe(res => {
          if (res.success) {
            return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
          } else {
            return resolve([]);
          }
        });
      }
    });
  };

  navState: AssembleiaQuotaExtraNavState = null;

  constructor(public api: ApiService,
    public toastr: ToastrService,
    public utils: UtilitiesService,
    public route: ActivatedRoute,
    public router: Router,
    public message: MessageService,
    public modalService: SuiModalService,
    public location: Location,
    public cdRef: ChangeDetectorRef,
    public appConfig: AppConfigService,
    public userSession: UserSessionService,
    public processamentos: ProcessamentosService,
    public appState: AppStateService) {

    let currNav = this.router.getCurrentNavigation()
    if (currNav && currNav.extras && currNav.extras.state) {
      this.navState = currNav.extras.state as AssembleiaQuotaExtraNavState;
    }
    if (this.navState && this.navState.data && this.navState.data.cod_condominio != null) {
      this.cod_condominio = this.navState.data.cod_condominio;
    }
  }

  restoreForm() {
    if (this.isCreate) {
      this.cod = null;
      this.mes = null;
      this.ano = null;
      this.dia = 1;
      this.dia_venc = 8;
      this.data = new Date((new Date()).setDate(this.dia));
      this.data_venc = new Date((new Date()).setDate(this.dia_venc));
      this.cod_condominio = null;
      this.cod_zona = null;
      this.exercicio = null;
      this.tipo_proc = 'O';  // O, F, E, P, S
      this.descricao = null;
      this.pluri_anual = null;
      this.valor = null;
      this.tipo_reparticao = 'UNICO'; // 'REPARTIDO'
      this.reparticao = 'P'; // P, E, M
      this.reparticaoStartYear = new Date();
      this.reparticaoStartYear.setFullYear(this.reparticaoStartYear.getFullYear(), 0, 1);
      this.reparticaoStartYear.setHours(0, 0, 0, 0);
      this.reparticaoEndYear = new Date(this.reparticaoStartYear);
      this.procMonths = [[{ sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }]];
      this.selMesesCobranca = '1';
      this.fraccoesList = [];
      this.fraccoesListOrig = [];
      this.zonasOpts = [];
    } else {
      if (!this.procDetails) return;

      this.isDeleted = this.procDetails.active === 0;
      this.isLancado = this.procDetails.is_lancado === 1;

      this.cod = this.procDetails.cod;
      this.mes = this.procDetails.mes;
      this.ano = this.procDetails.ano;
      this.dia = this.procDetails.dia_emissao ? this.procDetails.dia_emissao : 1;
      this.dia_venc = this.procDetails.dia_vencimento ? this.procDetails.dia_vencimento : 8;
      this.data = this.utils.getDate(this.procDetails.data);
      this.data_venc = this.utils.getDate(this.procDetails.data_venc);
      this.cod_condominio = {
        cod: this.procDetails.cod_condominio,
        exercicio: this.procDetails.exercicio,
        id: this.procDetails.id_condominio,
        nome: this.procDetails.nome_condominio,
      }
      this.exercicio = this.procDetails.exercicio;
      this.tipo_proc = this.procDetails.tipo_proc;
      this.tipo_reparticao = this.procDetails.tipo_reparticao;
      this.descricao = this.procDetails.descricao;
      this.pluri_anual = this.procDetails.pluri_anual === 1;
      this.valor = Number(this.procDetails.valor);
      this.reparticao = this.procDetails.reparticao_fracoes;
      this.meses_cobranca = this.procDetails.meses_cobranca;
      this.simObj = (this.procDetails.simObj) ? JSON.parse(this.procDetails.simObj) : null;

      this.reparticaoStartYear = new Date();
      this.reparticaoStartYear.setFullYear(parseInt(this.procDetails.ano), 0, this.dia);
      this.reparticaoEndYear = new Date(this.reparticaoStartYear);

      let procMonths: Array<Array<{ sel: boolean }>> = [];

      procMonths = [[{ sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }, { sel: true }]];
      if (this.meses_cobranca) {
        let nYears = Math.ceil(this.meses_cobranca.length / 12);
        this.reparticaoEndYear.setFullYear(this.reparticaoStartYear.getFullYear() + nYears - 1);
        procMonths = this.generateNewYears(nYears);
    
        for (let year_index = 0; year_index < procMonths.length; year_index++) {
          for (let cobranca_index = 0; cobranca_index < procMonths[year_index].length; cobranca_index++) {
            procMonths[year_index][cobranca_index].sel = this.meses_cobranca.charAt(year_index * 12 + cobranca_index) === 'S';
          }
        }
      }
      this.procMonths = procMonths;
      this.loadPeriodicityModel();

      this.zonasOpts = [{ name: 'Todas', value: 'ALL' }];
      if (this.procDetails.avisos.length > 0) {
        let cod_zona = this.procDetails.avisos[0].cod_zona.toString();
        this.procDetails.avisos.forEach(el => {
          if (cod_zona == 'ALL') return;
          if (cod_zona !== el.cod_zona.toString()) {
            this.zonasOpts = [{ name: 'Todas', value: 'ALL' }];
            cod_zona = 'ALL';
          } else {
            this.zonasOpts.push({ name: el.nome_zona, value: el.cod_zona.toString() });
            cod_zona = el.cod_zona.toString();
          }
        });
        this.cod_zona = cod_zona;
      } else {
        this.zonasOpts = [{ name: 'Todas', value: 'ALL' }];
      }

      this.zonaSelected();


      try {
        if (this.procDetails.fracoes_info) {
          this.fracoes_info = JSON.parse(this.procDetails.fracoes_info);
        } else if (this.procDetails.simObj) {
          let simObj = JSON.parse(this.procDetails.simObj);
          if (simObj.hasOwnProperty('fraccoesList')) {
            this.fracoes_info = simObj['fraccoesList'].filter(sim => !!sim.checked).map(sim => {
              return {
                cod_fracao: sim.cod,
                cod_proprietario: sim.cod_proprietario,
                valor: sim.valor
              }
            })
          } else {
            this.fracoes_info = [];
          }
        }
      } catch (err) {
        this.fracoes_info = [];
      }

      if (this.fraccoesList.length) {
        let fracInfo: {
          cod_fracao: string;
          cod_proprietario: string;
          valor: string;
        } = null;

        for (let i = 0; i < this.fraccoesList.length; i++) {
          const frac = this.fraccoesList[i];
          fracInfo = this.fracoes_info.find(fracInfo => fracInfo.cod_fracao == frac.cod);
          if (fracInfo != null) {
            frac.valor = fracInfo.valor;
            frac.cod_proprietario = fracInfo.cod_proprietario;
            frac.checked = true;
          } else {
            frac.checked = false;
            frac.valor = null;
          }
        }
      }
      this.computeTotal();

    }
  }


  loadPeriodicityModel() {
    let selMesesCobranca = '1';
    
    if (this.meses_cobranca) {
      let periodicityChosen = this.appConfig.periodicityOpts.filter(periodo => periodo.model.length > 0).find(periodo => {
        for (let i_periodo = 0; i_periodo < periodo.model.length; i_periodo++) {
          const modeloPeriodicidade = periodo.model[i_periodo];
          for (let yearCobrancaIndex = 0; yearCobrancaIndex < this.procMonths.length; yearCobrancaIndex++) {
            if (modeloPeriodicidade !== this.procMonths[yearCobrancaIndex][i_periodo].sel) return false;
          }
        }
        return true;
      })
      selMesesCobranca = periodicityChosen ? periodicityChosen.value : '0';
    }
    this.selMesesCobranca = selMesesCobranca;
  }

  ngOnInit() {
    // HANDLE APPLICATION STATE
    this.initState = this.appState.getInitState(this.comp);
    if (this.initState) {
      this.cod_condominio = this.initState.state.condominioSelected;
      this.appState.clearInitState(this.comp);

    }
    if (this.cod_condominio != null) this.getCondominioDetails();

    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.isCreate = true;

      this.data = new Date((new Date()).setDate(this.dia));
      this.data_venc = new Date((new Date()).setDate(this.dia_venc));
    } else {
      this.processamentoId = this.route.snapshot.params.id;
    }

    this.reparticaoStartYear = new Date();
    this.reparticaoStartYear.setFullYear(this.reparticaoStartYear.getFullYear(), 0, 1);
    this.reparticaoStartYear.setHours(0, 0, 0, 0);

    this.init();
  }

  ngAfterViewInit() {

    this.repartidoAjusteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.repartidoAjusteAlertRef);
    this.repartidoAjusteAlertConfig.closeResult = "closed";
    this.repartidoAjusteAlertConfig.size = 'tiny';
    this.repartidoAjusteAlertConfig.transition = 'fade';
    this.repartidoAjusteAlertConfig.transitionDuration = 250;
  }

  ngAfterViewChecked() { this.cdRef.detectChanges }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: null });
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
  }

  init() {
    if (this.isCreate) {

    } else {

      this.fetchingData = true;
      this.getProcessamentoDetails().then(res => {
        this.getCondominioDetails().then(_ => {
          this.fetchingData = false;
        }).catch(_ => {
          this.fetchingData = false;
        })
      }).catch(_ => {
        this.fetchingData = false;
      });
    }
  }

  public animate(transitionName: string = "fade up") {
    this.transitionController.animate(
      new Transition(transitionName, 400, TransitionDirection.In));
  }

  zonaSelected() {
    if (this.cod_zona !== 'ALL') {
      this.fraccoesList = this.fraccoesListOrig.filter(el => (el.zona_cod === this.cod_zona));
    } else {
      this.fraccoesList = JSON.parse(JSON.stringify(this.fraccoesListOrig));
    }

    this.valorChanged();
  }

  selectFraccao(row) {
    row.checked = !row.checked;

    if (!row.checked) row.valor = null;

    setTimeout(() => {
      if (this.reparticao === 'M') {
        this.computeTotal();
      } else {
        this.valorChanged();
      }
    }, 1);
  }

  savingSimulation = false;
  saveSimulation(lancar: boolean = false): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (this.savingSimulation || this.loading) {
        resolve(false);
        return;
      }

      let saveProcessamento: SaveProcessamento = null;
      let data_fim: Date = null;

      if (this.isLancado) {
        if (!this.descricao || this.descricao.trim() === '') {
          resolve(false);
          return;
        }
        saveProcessamento = {
          id: this.procDetails ? this.procDetails.id : null,
          descricao: this.descricao.trim(),
        };

        this.loading = true;
      } else {
        let canSubmit = await this.canSubmitForm();
        if (!canSubmit) {
          resolve(false);
          return;
        }

        if (lancar) {
          this.loading = true;
        } else {
          this.savingSimulation = true;
        }

        let cod_condominio = this.cod_condominio.cod;
        let data: Date = null;
        let data_venc: Date = null;
        let dia = null;
        let dia_venc = null;
        let meses_cobranca: string = null;
        if (this.tipo_reparticao === 'UNICO') {
          data = this.data;
          data_fim = new Date(this.data);
          data_venc = this.data_venc;
          dia = null;
          dia_venc = null;
        } else {
          let startingPrestacao = this.getPrestacoesStartDate();
          if (startingPrestacao === null) return;

          let year = startingPrestacao.getFullYear();
          let month = startingPrestacao.getMonth();

          data = new Date();
          data.setHours(0, 0, 0, 0);
          data.setFullYear(year, month, this.dia);

          data_venc = new Date();
          data_venc.setHours(0, 0, 0, 0);
          data_venc.setFullYear(year, month, this.dia_venc);

          dia = this.dia;
          dia_venc = this.dia_venc;

          meses_cobranca = this.getPrestacoesCobrancaTextRepresentation();
          let last_cobranca_index = meses_cobranca.lastIndexOf('S');

          if (last_cobranca_index !== -1) {
            data_fim = new Date();
            data_fim.setFullYear(this.reparticaoStartYear.getFullYear() + Math.floor(last_cobranca_index / 12), last_cobranca_index % 12, this.dia);
            data_fim.setHours(0, 0, 0, 0);
            data_fim.setMonth(last_cobranca_index);
          }
        }

        let fracoesInfo: SaveProcessamento['fracoes_info'] = [];
        for (let i = 0; i < this.fraccoesList.length; i++) {
          const element = this.fraccoesList[i];
          if (!element.checked) continue;
          fracoesInfo.push({
            cod_fracao: element.cod,
            cod_proprietario: element.cod_proprietario,
            valor: element.valor,
          });
        }

        saveProcessamento = {
          id: this.procDetails ? this.procDetails.id : null,
          cod: this.procDetails ? this.procDetails.cod : null,
          data: this.utils.getFormatedDate(data),
          cod_condominio: cod_condominio,
          tipo_proc: this.tipo_proc,
          data_venc: this.utils.getFormatedDate(data_venc),
          descricao: this.descricao.trim(),
          pluri_anual: this.pluri_anual ? 1 : 0,
          simObj: this.simObj,
          dia_emissao: dia,
          dia_vencimento: dia_venc,
          reparticao_fracoes: this.reparticao,
          fracoes_info: fracoesInfo,
          tipo_reparticao: this.tipo_reparticao,
          meses_cobranca: meses_cobranca,
          valor: this.valor,
          is_parent: 1,
          is_lancado: 0,
        }
      }

      let id_proc = null;
      if (lancar) {
        let input = {
          tipo_proc: saveProcessamento.tipo_proc,
          cod_condominio: saveProcessamento.cod_condominio,
          valor: saveProcessamento.valor,
          data_inicio: this.utils.getDate(saveProcessamento.data),
          data_fim: data_fim,
          descricao: saveProcessamento.descricao,
        }
        this.loading = false;
        let proceed = await this.processamentoDuplicationModal.open(input);
        if (!proceed) {
          resolve(false);
          return;
        }

        this.loading = true;
        id_proc = await this.processamentos.lancarProcessamento(saveProcessamento);
      } else {
        id_proc = await this.processamentos.saveProcessamento(saveProcessamento);
      }
      this.loading = false;
      this.savingSimulation = false;

      if (id_proc == null) {
        resolve(false);
        return;
      }

      if (this.navState && this.navState.redirectAfterTo && this.navState.redirectAfterTo.url != null) {
        // When we are creating, we need to append the new id to query
        if (this.isCreate) {
          let queryParamIndex = this.navState.redirectAfterTo.url.indexOf('?');
          let url = this.navState.redirectAfterTo.url.substring(0, queryParamIndex);
          let urlParams = new URLSearchParams(this.navState.redirectAfterTo.url.substring(queryParamIndex + 1));
          urlParams.append('id_processamento', id_proc);
          this.router.navigateByUrl(url + '?' + urlParams.toString());
        } else {
          this.router.navigateByUrl(this.navState.redirectAfterTo.url);

        }
      } else if (this.isCreate) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate(['/lancamentos/processamento', id_proc]);
      } else {
        this.getProcessamentoDetails().then(res => {
          this.getCondominioDetails();
          this.scroller.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
        }).catch(err => { });
      }
    })
  }

  getPrestacoesStartDate(): Date {
    for (let yearIndex = 0; yearIndex < this.procMonths.length; yearIndex++) {
      for (let cobrancaIndex = 0; cobrancaIndex < this.procMonths[yearIndex].length; cobrancaIndex++) {
        const cobranca = this.procMonths[yearIndex][cobrancaIndex];
        if (cobranca.sel) {
          let startDate = new Date();
          startDate.setFullYear(this.reparticaoStartYear.getFullYear() + yearIndex, cobrancaIndex, this.dia);
          startDate.setHours(0, 0, 0, 0);
          return startDate;
        }
      }
    }
    return null;
  }
  getPrestacoesEndDate(): Date {
    for (let yearIndex = this.procMonths.length - 1; yearIndex >= 0; yearIndex--) {
      for (let cobrancaIndex = this.procMonths[yearIndex].length - 1; cobrancaIndex >= 0; cobrancaIndex--) {
        const cobranca = this.procMonths[yearIndex][cobrancaIndex];
        if (cobranca.sel) {
          let endDate = new Date();
          endDate.setFullYear(this.reparticaoStartYear.getFullYear() + yearIndex, cobrancaIndex, this.dia);
          endDate.setHours(0, 0, 0, 0);
          return endDate;
        }
      }
    }
    return null;
  }

  getPrestacoesCobrancaTextRepresentation(): string {
    let meses_cobranca = '';
    for (let yearIndex = 0; yearIndex < this.procMonths.length; yearIndex++) {
      const year = this.procMonths[yearIndex];
      for (let cobrancaIndex = 0; cobrancaIndex < year.length; cobrancaIndex++) {
        const element = year[cobrancaIndex];
        if (element.sel) {
          meses_cobranca += 'S';
        } else {
          meses_cobranca += 'N';
        }
      }
    }
    return meses_cobranca;
  }

  getCondominioDetails(): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (this.apiSub) {
        this.apiSub.unsubscribe();
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }
      this.fraccoesListOrig = [];
      let proprietarios = [];
      this.zonasOpts = [];

      let cod = this.cod_condominio.cod;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      this.apiSub = this.api.getCondFraccoesDetails(cod).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          // GENERATE ZONAS LIST

          let seenZonas = '';
          res.data.forEach(el => {
            // SET RUBRICAS LIST OPTIONS
            if (seenZonas.indexOf(el.zona_cod) === -1) {
              // ADD RUBRICA TO SEEN STRING
              seenZonas += (',' + el.zona_cod);

              this.zonasOpts.push({ name: el.zona_nome, value: el.zona_cod });
            }
          });
          this.zonasOpts = JSON.parse(JSON.stringify([{ name: 'Todas', value: 'ALL' }].concat(this.zonasOpts)));

          // GENERATE FRACCOES LIST
          this.fraccoesListOrig = res.data.map(el => {
            try {
              proprietarios = res.proprietarios.filter(prop => el.id == prop.id_fracao);
              proprietarios = proprietarios.map(it => {
                return { name: it.nome, value: it.cod_proprietario };
              });
            } catch {
              proprietarios = [];
            }

            let aux = {
              id: el.id,
              cod: el.cod,
              zona_nome: el.zona_nome,
              cod_proprietario: el.cod_proprietario,
              proprietarios: proprietarios,
              zona_cod: el.zona_cod,
              fraccao_nome: el.cod + ' - ' + el.nome,
              condomino_nome: el.condomino_nome,
              permilagem: Number(el.permilagem),
              valor: null,
              checked: true,
              credito: 0,
            };
            return aux;
          });
          this.fraccoesList = JSON.parse(JSON.stringify(this.fraccoesListOrig));
          if (!this.isCreate) this.restoreForm();

          resolve(true);
        } else {
          resolve(false);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });

  }

  procDetails: ProcessamentoDetails = null;
  simObj = null;
  isDeleted = false;
  fetchingData = false;
  getProcessamentoDetails(): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      let req: any = [
        this.processamentos.getProcessamento(this.processamentoId),
        this.processamentos.getAvisosCreditosByProcessamento(this.processamentoId),
      ];

      Promise.all(req).then(resArr => {
        this.procDetails = resArr[0] as ProcessamentoDetails;
        this.cod_condominio = {
          cod: this.procDetails.cod_condominio,
          exercicio: this.procDetails.exercicio,
          id: this.procDetails.id_condominio,
          nome: this.procDetails.nome_condominio,
        }

        this.prestacoesList = this.procDetails.prestacoes.map(el => {
          return {
            id: el.id,
            descricao: el.descricao,
            data: this.utils.getDate(el.data),
            data_venc: this.utils.getDate(el.data_venc),
            valor: el.valor
          }
        })

        // HANBLE AVISOS AND CREDITOS
        let auxAviso = null;
        let avisos = (resArr[1] as { avisos: Array<any> }).avisos.map(el => {
          auxAviso = {
            id: el.id,
            cod: el.cod,
            n_doc: Number(el.n_aviso),
            cod_fraccao: el.cod_fraccao,
            type: 'AVISO',

            doc_num: 'A - ' + Number(el.n_aviso),
            fraccao: el.cod_fraccao + ' - ' + el.nome_fraccao,
            descricao: el.descricao,
            condomino: el.nome_condomino,
            dt_emissao: this.utils.getDate(el.dt_emissao),
            valor: Number(el.valor),
          }
          return auxAviso;
        });

        let auxCreditos = null;
        let creditos = (resArr[1] as { creditos: Array<any> }).creditos.map(el => {
          auxCreditos = {
            id: el.id,
            cod: el.cod,
            n_doc: Number(el.n_credito),
            cod_fraccao: el.cod_fraccao,
            type: 'CREDITO',

            doc_num: 'C - ' + Number(el.n_credito),
            fraccao: el.cod_fraccao + ' - ' + el.nome_fraccao,
            descricao: el.descricao,
            condomino: el.nome_condomino,
            dt_emissao: this.utils.getDate(el.data),
            valor: Number(el.valor),
          }
          return auxCreditos;
        });
        this.avisosCreditosList = creditos.concat(avisos).sort((a, b) => { return (a.cod_fraccao > b.cod_fraccao || a.cod_fraccao.length > b.cod_fraccao.length) ? 1 : -1; });

        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(true);
      }).catch(err => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(err);
      });
    });
  }

  dateEmiChanged() {
    if (this.data) {
      let days = 7;
      let date = new Date(this.data);
      this.data_venc = new Date(date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)));
    }
  }

  canSubmitForm(): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.submittingForm = true;
      if (this.total != this.valor && this.valor !== null && this.tipo_reparticao !== 'REPARTIDO') {
        setTimeout(() => { this.submittingForm = false; }, 4000);
        this.toastr.error('O valor do processamento é diferente do total das repartições pelas fraccões.', 'Ajuste Manual Necessário', { timeOut: 4000 });
        resolve(false);
        return;
      }

      // CHECK INPUTS
      if (!this.cod_condominio || !this.cod_zona || !this.tipo_proc || !this.valor || !this.descricao) {
        setTimeout(() => { this.submittingForm = false; }, 4000);
        resolve(false);
        return;
      }

      if (!this.fraccoesList.find(el => el.checked)) {
        setTimeout(() => { this.submittingForm = false; }, 4000);
        this.toastr.error('Nenhuma fracção selecionada.', 'Ups...!', { timeOut: 4000 });
        resolve(false);
        return;
      }

      if (this.tipo_reparticao === 'UNICO' && (!this.data || !this.data_venc)) {
        setTimeout(() => { this.submittingForm = false; }, 4000);
        resolve(false);
        return;
      }

      if (this.tipo_reparticao === 'REPARTIDO' && (!this.dia || !this.dia_venc)) {
        setTimeout(() => { this.submittingForm = false; }, 4000);
        resolve(false);
        return;
      }

      
      let monthsSelected = this.getTotalMonthsSelected();
      if (this.tipo_reparticao === 'REPARTIDO') {
        if (this.reparticaoStartYear == null || this.reparticaoEndYear == null) {
          this.toastr.error('É necessário indicar os anos de ínicio e fim do processamento', 'Seleção dos meses', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          resolve(false);
          return;
        }
        
        if (monthsSelected < 2) {
          this.toastr.error('Tem de selecionar no minímo 2 meses para criar um processamento repartido', 'Seleção dos meses', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          resolve(false);
          return;
        }
        let yearHasCobranca = false;
        for (let yearIndex = 0; yearIndex < this.procMonths.length; yearIndex++) {
          yearHasCobranca = false;
          for (let monthIndex = 0; monthIndex < this.procMonths[yearIndex].length; monthIndex++) {
            const element = this.procMonths[yearIndex][monthIndex];
            if (element.sel) {
              yearHasCobranca = true
              break;
            }
          }
          
          if (!yearHasCobranca) {
            this.toastr.error('Tem de selecionar no minímo 1 mês por cada ano para criar um processamento repartido', 'Seleção dos meses', { timeOut: 4000 });
            setTimeout(() => { this.submittingForm = false; }, 4000);
            resolve(false);
            return;
          }
        }
        
        if (this.procMonths.length > 10) {
          this.toastr.error('Não é possível criar um processamento com uma duração superior a 10 anos.', 'Seleção dos meses', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          resolve(false);
          return;
        }
      }

      this.fraccoesList.forEach(el => { if (!el.valor || el.valor == 0) el.checked = false; });

      if (this.tipo_reparticao === 'REPARTIDO' && this.reparticao !== 'M') {
        this.repartidoAjuste = 0;
        this.repartidoAjusteDist = new Array(this.fraccoesList.filter(el => el.checked).length).fill(0);

        this.valorChanged(true);

        this.repartidoAjuste = Math.round((this.valor - this.total * monthsSelected) * 100) / 100

        if (this.repartidoAjuste > 0) {
          let success = await this.presentAjustmentModal();
          if (!success) {
            resolve(false);
            return;
          }
        }

        this.repartidoAjuste = Math.round((this.valor - this.total * monthsSelected) * 100) / 100;
        let totalDistributed = 0;
        let i = 0;
        while (totalDistributed < this.repartidoAjuste) {
          this.repartidoAjusteDist[i] = +(this.repartidoAjusteDist[i] + 0.01).toFixed(2);
          totalDistributed += 0.01;
          i = (i + 1) % this.repartidoAjusteDist.length;
        }
      }
      resolve(true);
    })
  }

  presentAjustmentModal(): Promise<boolean> {
    return new Promise((resolve) => {
      this.repartidoAjusteModalRef = this.modalService
        .open(this.repartidoAjusteAlertConfig)
        .onApprove(() => {
          this.repartidoAjuste = 0;
          this.repartidoAjusteDist = new Array(this.fraccoesList.filter(el => el.checked).length).fill(0);
          resolve(true);
        })
        .onDeny(() => {
          this.repartidoAjuste = 0;
          this.repartidoAjusteDist = new Array(this.fraccoesList.filter(el => el.checked).length).fill(0);
          resolve(false);
        });
    });
  }

  changedReparticaoYears() {
    if (this.reparticaoStartYear == null || this.reparticaoEndYear == null) return;
    //If start and end are the same, 1 entry needs to be entered
    let nYears = 1 + this.reparticaoEndYear.getFullYear() - this.reparticaoStartYear.getFullYear();
    if (nYears < 0) return;

    if (nYears <= this.procMonths.length) {
      this.procMonths.splice(nYears);
    } else {
      let nNewYears = nYears - this.procMonths.length;
      let newYears = this.generateNewYears(nNewYears);
      this.procMonths = this.procMonths.concat(newYears);
      this.cobrancaSelected();
    }
  }

  generateNewYears(nYears:number): Array<Array<{sel:boolean}>> {
    let newYears:Array<Array<{sel:boolean}>> = [];
    for (let indexYear = 0; indexYear < nYears; indexYear++) {
      let yearCobrancas = [];
      for (let i = 0; i < 12; i++) {
        yearCobrancas.push({sel: false});
      }
      newYears.push(yearCobrancas);
    }
    return newYears;
  }

  monthSelected(year, month) {
    if (this.isLancado) return;
    if (this.procMonths[year][month].sel) {
      this.procMonths[year][month].sel = false;
    } else {
      this.procMonths[year][month].sel = true;
    }
    this.loadPeriodicityModel();
    this.valorChanged(this.reparticao === 'M');
  }

  cobrancaSelected() {
    let selection = this.appConfig.periodicityOpts.find(el => (el.value === this.selMesesCobranca));
    if (selection && selection.model.length > 0) {

      for (let yearIndex = 0; yearIndex < this.procMonths.length; yearIndex++) {
        for (let monthIndex = 0; monthIndex < this.procMonths[yearIndex].length; monthIndex++) {
          this.procMonths[yearIndex][monthIndex].sel = monthIndex < selection.model.length ? selection.model[monthIndex] : false;
        }
      }
    }
    this.valorChanged(this.reparticao === 'M');
  }

  getTotalMonthsSelected() {
    let monthsSelected = 0;
    this.procMonths.forEach(year => {
      year.forEach(month => {
        if (month.sel) monthsSelected++;
      })
    })
    return monthsSelected;
  }

  total = 0;
  valorChanged(fromValor = false) {
    if (this.isLancado || this.fetchingData) return;

    if (this.valor === '-') return;

    if (isNaN(this.valor)) { this.valor = '0'; }

    if (this.valor !== null && this.valor !== 0) this.valor = Math.round(this.valor * 100) / 100;

    if (!this.valor && this.reparticao !== 'M') {
      this.fraccoesList.forEach(el => { el.valor = 0; });
    }

    let value = null;
    let months = this.getTotalMonthsSelected();

    switch (this.reparticao) {
      case 'P':
        let permTotal = 0;
        this.fraccoesList.filter(el => el.checked).forEach(el => {
          permTotal += el.permilagem;
        });
        this.fraccoesList.filter(el => el.checked).forEach(el => {

          el.valor = this.tipo_reparticao === 'UNICO' ?
            el.valor = Math.round((el.permilagem / permTotal) * this.valor * 100) / 100 :
            el.valor = Math.floor((el.permilagem / permTotal) * this.valor / months * 100) / 100;
        });
        break;
      case 'E':
        value = this.tipo_reparticao === 'UNICO' ?
          Math.round((this.valor / this.fraccoesList.filter(el => el.checked).length) * 100) / 100 :
          Math.floor((this.valor / this.fraccoesList.filter(el => el.checked).length) / months * 100) / 100;

        this.fraccoesList.filter(el => el.checked).forEach(el => { el.valor = value; });
        break;
      case 'M':
        if (fromValor) {
          this.computeTotal();
        } else {
          this.fraccoesList.forEach(el => { el.valor = 0; });
          this.total = 0;
          this.valor = null;
        }
        break;
    }

    if (this.reparticao !== 'M') {
      this.computeTotal();

      if (this.fraccoesList.length > 1 && this.total !== this.valor) {

        if (this.tipo_reparticao === 'UNICO') {
          // HANDLE ARREDONDAMENTOS (AUTOMÁTICO)
          let fraccaoMaxPerm = this.fraccoesList.filter(el => (el.checked)).reduce((acc, el) => {
            if (!acc) return el;
            return (el.permilagem >= acc.permilagem) ? el : acc;
          }, null);
          let diff = this.total - this.valor;
          if (fraccaoMaxPerm) {
            fraccaoMaxPerm.valor = parseFloat(fraccaoMaxPerm.valor.toString()) - diff;

            fraccaoMaxPerm.valor = Math.round(fraccaoMaxPerm.valor * 100) / 100;
          }

          this.total = this.valor;
          if (!this.total) this.total = 0;
        } else {
          this.total = this.fraccoesList.filter(el => (el.checked)).reduce((a, b) => a + (b.valor ? parseFloat(b.valor.toString()) : 0), 0);
        }
      }
    } else {
      this.computeValor();
    }
  }

  computeValor(): void {
    let valor: number = 0;
    this.fraccoesList.filter(el => el.checked).forEach(el => valor += Number(el.valor));

    if (this.tipo_reparticao === 'REPARTIDO') valor *= this.getTotalMonthsSelected();

    this.valor = Number(valor).toFixed(2);
  }

  computeTotal() {
    this.total = 0;
    this.fraccoesList.filter(el => (el.checked)).forEach(el => {
      this.total += Number(el.valor);
    });
    this.total = Math.round(this.total * 100) / 100;
  }

  reparticaoChanged() {
    this.valorChanged();
  }

  allFraccoes = true;
  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.fraccoesList.map(el => el.checked = true) : this.fraccoesList.map(el => el.checked = false);

    this.fraccoesList.filter(el => !el.checked).forEach(el => { el.valor = null; });

    this.valorChanged();
  }

  fraccaoChanged(fraccao) {
    fraccao.checked = (fraccao.valor || this.reparticao === 'M');

    setTimeout(() => {
      this.valorChanged(true);
    }, 1);
  }

  fraccaoChangedChecked(fraccao) {
    if (!fraccao.checked) {
      fraccao.valor = null;
    }
    setTimeout(() => {
      this.valorChanged(true);
    }, 1);

  }

  goToProcessamento(id) {
    if (id == null) return;

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['lancamentos/processamento', id]);
  }

  pdfReport = {
    title: null,
    nif: null,
    description: null,
    exercicio: null,
    now: new Date(),
  }
  @ViewChild('processamentoDuplicationModal', { static: false }) processamentoDuplicationModal: ProcessamentoDuplicationModalComponent;
  @ViewChild('processamentoPDF', { static: false }) processamentoPDF: ProcessamentoPdfComponent;
  async printPdf() {
    if (this.procDetails && (this.procDetails.is_lancado == 1 || this.procDetails.id_proc_parent)) {
      await this.processamentoPDF.exportPDF(null, this.procDetails.id_proc_parent ? this.procDetails.id_proc_parent : this.procDetails.id);
      return;
    }

    // CHECK IF DESCRIPTION IS OK
    let canSubmit = await this.canSubmitForm();
    if (!canSubmit || this.savingSimulation) {
      return;
    }

    this.submittingModalForm = true;
    if (!this.descricao && this.isCreate) {
      setTimeout(() => { this.submittingModalForm = false; }, 4000);
      return;
    }

    let nPrestacoes = this.tipo_reparticao === 'UNICO' ? 1 : this.getTotalMonthsSelected();

    this.repartidoAjuste = Math.round((this.valor - this.total * nPrestacoes) * 100) / 100;
    let fraccoesListPDF = this.getFraccoesPDF(nPrestacoes);

    // GET CONDOMINIO DETAILS
    let cod = this.cod_condominio.cod;
    this.loadingModal = true;
    this.api.getCondominiumDetailsByCod(cod).subscribe(async res => {
      if (res.hasOwnProperty('success') && res.success) {

        let tipo_reparticao = this.tipo_reparticao;
        if (tipo_reparticao == null) {
          // Accept processings stored between changing database structure
          // Previously tipo_reparticao wasn't stored and every none of the processes was a simulation, so every one had notices.
          let isRepartido = false;
          for (let i = 0; i < this.avisosCreditosList.length && !isRepartido; i++) {
            for (let j = i + 1; j < this.avisosCreditosList.length && !isRepartido; j++) {
              if (this.avisosCreditosList[i].cod_fraccao == this.avisosCreditosList[j].cod_fraccao) isRepartido = true;
            }
          }
          tipo_reparticao = isRepartido ? 'REPARTIDO' : 'UNICO';
        }

        let data_inicio = this.data;
        if (tipo_reparticao === 'REPARTIDO') {
          data_inicio = this.getPrestacoesStartDate();
        }

        let total = 0;
        let totalPrestacao = 0;

        for (let i = 0; i < fraccoesListPDF.length; i++) {
          const element = fraccoesListPDF[i];
          total = this.utils.cleanDecimalDigits(total + element.valor);
          totalPrestacao = this.utils.cleanDecimalDigits(totalPrestacao + element.valorPrestacao);
        }

        let ajuste = this.utils.cleanDecimalDigits(total - (totalPrestacao * nPrestacoes));

        let end_date = null;
        if (tipo_reparticao === 'REPARTIDO') {
          end_date = this.getPrestacoesEndDate();
          if (end_date === -1) {
            end_date = null;
          }
        }


        let inputPDF: ProcessamentoPDFInput = {
          title: res.data.cod + ' - ' + res.data.nome,
          nif: res.data.n_contribuinte,
          nome_condominio: res.data.nome,
          descricao: this.descricao,
          start_date: data_inicio,
          tipo_reparticao: tipo_reparticao,
          now: new Date(),
          fraccoesListPDF: fraccoesListPDF,
          nPrestacoes: nPrestacoes,
          end_date: end_date,
          ajuste: ajuste,
          total: total,
          totalPrestacao: totalPrestacao
        }

        try {
          await this.processamentoPDF.exportPDF(inputPDF);
        } catch (err) {
        }
        this.loadingModal = false;
        this.submittingModalForm = false;
      } else {
        this.toastr.error('Não foi possível efectuar a operação. Por favor, verifique a sua ligação à Internet e/ou tente novamente mais tarde.', 'Ups...!');
        this.loadingModal = false;
        this.submittingModalForm = false;
      }
    }, err => {
      this.toastr.error('Não foi possível efectuar a operação. Por favor, verifique a sua ligação à Internet e/ou tente novamente mais tarde.', 'Ups...!');
      this.loadingModal = false;
      this.submittingModalForm = false;
    });
  }

  getFraccoesPDF(nPrestacoes: number): ProcessamentoPDF['fraccoesListPDF'] {
    let fraccoesPDF = this.fraccoesList.filter(el => (el.checked)).map(el => {
      let prop = el.proprietarios.find(p => p.value == el.cod_proprietario);
      let valorPrestacao = parseFloat(el.valor.toString());
      return {
        ...el,
        valor: this.utils.cleanDecimalDigits(nPrestacoes * valorPrestacao),
        condomino_nome: prop ? prop.name : el.condomino_nome,
        valorPrestacao: valorPrestacao,
      }
    });

    let distributed = 0;
    let i = 0;
    while (distributed < this.repartidoAjuste) {
      fraccoesPDF[i].valor = this.utils.cleanDecimalDigits(fraccoesPDF[i].valor + 0.01);
      distributed = this.utils.cleanDecimalDigits(distributed + 0.01);
      i = (i + 1) % fraccoesPDF.length;
    }

    return fraccoesPDF;
  }

  createActivityLog(codCondominio, tipoDoc, nDoc, titulo, descricao, link, cod, obs = null) {
    let obj = { link: link, cod: cod };

    this.api.saveRegistoActividade(codCondominio, tipoDoc, nDoc, titulo, descricao, obj, obs).subscribe(res => { }, err => { });
  }

  avisoCreditoSelected(item) {
    switch (item.type) {
      case 'AVISO':
        this.router.navigate(['lancamentos/aviso', item.id]);

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `AVISO / ${item.n_aviso}` });
        break;
      case 'CREDITO':
        this.router.navigate(['lancamentos/credito', item.id]);

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `CRÉDITO / ${item.cod}` });
        break;
    }
  }


}
