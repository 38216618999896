import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, forkJoin } from 'rxjs';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { formatDate } from '@angular/common';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { UserSessionService } from '../user-session.service';


@Component({
  selector: 'app-send-emails-by-condominio',
  templateUrl: './send-emails-by-condominio.component.html',
  styleUrls: ['./send-emails-by-condominio.component.scss']
})
export class SendEmailsByCondominioComponent implements OnInit {

  @ViewChild('emailSelectionAlertRef', { static: false }) emailSelectionAlertRef;
  emailSelectionModalRef = null;
  emailSelectionAlertConfig: any = null;

  @Input() codCondominio = null;
  @Input() nomeCondominio = null;

  @Input() codCondomino = null;
  @Input() nomeCondomino = null;

  @Input() modalTitle = null;
  @Input() configs = null;
  @Input() target:'CONTA_CORRENTE'|'LANCAMENTOS' = null;
 
  @Input() classes = '';

  loadingModal = false;

  // TABLE VARIABLES
  fraccoesEmailList = [];
  proprietariosEmailList = [];
  condominiosList = [];
  allSelected = true;

  // EDIT EMAIL VARIABLES
  editEmailsMode = false;
  editEmails = false;

  mapaDeQuotas = true;
  contaCorrenteAnexo = true;
  avisoDePagamento = true;
  hasAnexoSelected = true;
  editEmailsTabsDef = [
    // RECONCICLIACAO
    { target: 'RECONCILIACAO', key: 'MAPA_DE_QUOTAS', name: 'Mapa de Quotas', assunto: this.appConfig.mapaDeQuotasEmailSubject, corpo: this.appConfig.mapaDeQuotasEmailAnexoBody.join('\n'), disabled: false, selected: true },
    { target: 'RECONCILIACAO', key: 'AVISO_DE_PAGAMENTO', name: 'Aviso de Pagamento', assunto: this.appConfig.avisoDePagamentoEmailSubject, corpo: this.appConfig.avisoDePagamentoEmailBody.join('\n'), disabled: false, selected: true },

    // CONTA CORRENTE
    { target: 'CONTA_CORRENTE', key: 'CONTA_CORRENTE', name: 'Conta Corrente', assunto: this.appConfig.contaCorrenteEmailSubject, corpo: this.appConfig.contaCorrenteEmailBody.join('\n'), disabled: false, selected: true },

    // LANCAMENTOS
    { target: 'LANCAMENTOS', key: 'RECIBOS_CREDITOS', name: 'Recibos e Créditos', assunto: this.appConfig.recibosCreditosEmailSubject, corpo: this.appConfig.recibosCreditosEmailBody.join('\n'), disabled: false, selected: true },

    // EMAILS REMETENTE AND ASSINATURA EDIT
    { target: 'ALL', key: 'REMETENTE_ASSINATURA', name: 'Remetente e Assinatura', assunto: null, corpo: null, disabled: !this.userSession.isAdmin(), selected: false },
  ];
  selTab = null;

  // RECONCILIACAO VARIABLES --------------------------------------------------
  reconciliacaoDate = null;
  maxDate = null;
  minDate = null;

  // CONTA CORRENTE VARIABLES -------------------------------------------------
  ccType = null;
  startDate = null;
  endDate =  null;
  selTipoRelatorio = '1';  
  selTipoAgrupamento = null;  
  selTipoListagem = null;  
  emailsCondomino = [];

  subsMsg = null;

  emailsToSend = [];

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  // REMETENETE E ASSINATURA 
  remetentesOpts = [{ name: this.appConfig.company.emailFromName, value: '-1', email: this.appConfig.company.email }];
  remetenteSelected = null;

  assinaturaOpts = [];
  assinaturaSelected = null;
  IBANCondominio = null;
  
  constructor(public api: ApiService,
              public modalService: SuiModalService,
              public message: MessageService,
              public cdRef:ChangeDetectorRef,
              public appConfig: AppConfigService,
              public utils: UtilitiesService,
              public userSession: UserSessionService,
              public toastr: ToastrService) {
    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'SEND_EMAILS_COMP') {
        switch (msg.cmd) {
          case 'OPEN_MODAL':
            this.openModal();
            break;
        }
      }
    });
  }

  ngOnInit() {
    // GET USER LIST
    this.api.getUtilizadores(0, 500).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        res.data.forEach(el => {
          if (el.hasOwnProperty('email') && el.email) this.remetentesOpts.push({ name: el.first_name + ' ' + el.last_name + ' | ' + this.appConfig.company.emailFromName, value: el.id, email: el.email });
          this.assinaturaOpts.push({ name: el.first_name + ' ' + el.last_name, value: el.id, email: el.email, phone: el.phone });
        });
        this.remetentesOpts = JSON.parse(JSON.stringify(this.remetentesOpts));
        this.remetenteSelected = '-1';

        this.assinaturaOpts = JSON.parse(JSON.stringify(this.assinaturaOpts));
        this.assinaturaSelected = this.userSession.getUserId();
      }
    }, err => { });

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'GET_EMAILS_CONFIG' });
  }

  ngOnDestroy() {
    if (this.subsMsg) this.subsMsg.unsubscribe();
    if (this.lancamentosSubs) this.lancamentosSubs.unsubscribe();
  }

  ngAfterViewInit() {
    this.editEmailsTabsDef = this.editEmailsTabsDef.filter(el => el.target === this.target || el.target === 'ALL');
    this.selTab = this.editEmailsTabsDef[0];

    this.emailSelectionAlertConfig = new TemplateModalConfig<IContext, string, string>(this.emailSelectionAlertRef);
    this.emailSelectionAlertConfig.isClosable = false;
    this.emailSelectionAlertConfig.closeResult = "closed";
    this.emailSelectionAlertConfig.size = 'large';
    this.emailSelectionAlertConfig.transition = 'fade';
    this.emailSelectionAlertConfig.transitionDuration = 250;

    this.editEmailAlertConfig = new TemplateModalConfig<IContext, string, string>(this.editEmailAlertRef);
    this.editEmailAlertConfig.isClosable = false;
    this.editEmailAlertConfig.closeResult = "closed";
    this.editEmailAlertConfig.size = 'tiny';
    this.editEmailAlertConfig.transition = 'fade';
    this.editEmailAlertConfig.transitionDuration = 250;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  rowSelectionToggle(ev, target=null) {
    if (target) {
      switch (target) {
        case 'CREDITOS':
          (ev.target.checked) ? this.creditosList.map(el => el.checked = true ) : this.creditosList.map(el => el.checked = false );
          break;
        case 'RECIBOS':
          (ev.target.checked) ? this.recibosList.map(el => el.checked = this.canSendRecibo(el) ) : this.recibosList.map(el => el.checked = false );
          break;
      }
    } else {
      if (ev.target.checked) {
        this.fraccoesEmailList.map(el => el.checked = !!el.email_proprietario);
        this.allSelected = true;
      } else {
        this.fraccoesEmailList.map(el => el.checked = false);
        this.allSelected = false;
      }
    }
  }

  canSendRecibo(row): boolean {
    return row.recibo_pendente !== '0';
  }


  allSelectedChanged() {
    if (this.target === 'CONTA_CORRENTE' && this.ccType === 'condominos') {
      if (this.allSelected) {
        this.condominiosList.map(el => el.checked = true);
      } else {
        this.condominiosList.map(el => el.checked = false);
      }

      this.condominioSelectionChanged();
    } else if (this.target === 'LANCAMENTOS') {
      if (this.allSelected) {
        this.proprietariosEmailList.map(el => el.checked = !!el.email_proprietario);
      } else {
        this.proprietariosEmailList.map(el => el.checked = false);
      }

      this.emailSelectionChanged();
    } else {
      if (this.allSelected) {
        this.fraccoesEmailList.map(el => el.checked = !!el.email_proprietario);
      } else {
        this.fraccoesEmailList.map(el => el.checked = false);
      }
  
      this.emailSelectionChanged();
    }
  }

  emailSelectionChanged() {
    if (this.target === 'LANCAMENTOS') {
      this.hasAnexoSelected = !!this.proprietariosEmailList.find(el => el.checked);
    } else {
      this.hasAnexoSelected = !!this.fraccoesEmailList.find(el => el.checked);
    }
  }

  condominioSelectionChanged() {
    this.hasAnexoSelected = !!this.condominiosList.find(el => el.checked);
  }

  getFraccoesEmailList() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    return new Promise(resolve => {
      if (this.target === 'LANCAMENTOS') {

        let startDate = (this.startDate) ? formatDate(this.startDate, this.format, this.locale) : 'NULL';
        let endDate = (this.endDate) ? formatDate(this.endDate, this.format, this.locale) : 'NULL';
        this.api.getFraccoesCondominos(this.codCondominio, startDate, endDate).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            let aux = null;
            this.proprietariosEmailList = res.data.map(el => {
              aux = {
                cod_pagador: el.cod_pagador,
                proprietario: el.nome,
                email_proprietario: null,
                emails: [],
                emailsOrig: [],
                checked: false,
              };
              
              let auxEmails = [];
              if (el.email_1) {
                auxEmails.push(el.email_1);
              }
              if (el.email_2) {
                auxEmails.push(el.email_2);
              }

              if (auxEmails.length > 0) {
                aux.emails = auxEmails;
                aux.emailsOrig = auxEmails;
                aux.email_proprietario = auxEmails.join('; ');
              }

              aux.checked = !!aux.email_proprietario;

              return aux;
            });

            // SORT BY LENGTH AND ALPHABETICALLY
            this.proprietariosEmailList = this.proprietariosEmailList.sort((a, b) => {
              return a.proprietario.localeCompare(b.proprietario);
            });

            resolve(true);
          } else {
            this.proprietariosEmailList = [];
            resolve(false);
          }

          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        }, err => {
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          this.fraccoesEmailList = [];
          resolve(false);
        });


      } else {
        let req = [this.api.getFraccoesCircular(this.codCondominio)];
        forkJoin(req).subscribe(res => {
          if (res[0].hasOwnProperty('success') && res[0].success) {

            let aux = null;
            let fraccoesEmailList = res[0].data.map(el => {
              aux = {
                fraccao_cod: el.cod,
                fraccao_descricao: el.cod + ' - ' + el.fraccao_nome,
                cod_proprietario: el.cod_proprietario,
                proprietario: el.nome_proprietario,
                email_proprietario: null,
                emails: [],
                emailsOrig: [],
                checked: false,
                estadoContencioso: (el.cod_estado && el.cod_estado !== '0'),
              };
              
              let auxEmails = [];
              if (el.email_proprietario) {
                auxEmails.push(el.email_proprietario);
              }
              if (el.email_proprietario_2) {
                auxEmails.push(el.email_proprietario_2);
              }
  
              if (auxEmails.length > 0) {
                aux.emails = auxEmails;
                aux.emailsOrig = auxEmails;
                aux.email_proprietario = auxEmails.join('; ');
              }
  
              aux.checked = !!aux.email_proprietario && !aux.estadoContencioso;
  
              return aux;
            });
  
            // SORT BY LENGTH AND ALPHABETICALLY
            fraccoesEmailList = fraccoesEmailList.sort((a, b) => {
              return a.fraccao_cod.length - b.fraccao_cod.length || a.fraccao_cod.localeCompare(b.fraccao_cod);
            });

            if (res.length === 1) {
              this.fraccoesEmailList = fraccoesEmailList;
            } else {
              let auxProprietarios = [];
              this.fraccoesEmailList = [];
              fraccoesEmailList.forEach(el => {
                this.fraccoesEmailList.push(el);
                auxProprietarios = res[1].data.filter(it => it.cod_fraccao === el.fraccao_cod && it.cod_pagador !== el.cod_proprietario);
                auxProprietarios.forEach(it => {
                  aux = {
                    fraccao_cod: it.cod_fraccao,
                    fraccao_descricao: it.cod_fraccao + ' - ' + it.fraccao_nome,
                    cod_proprietario: it.cod_pagador,
                    proprietario: it.nome_proprietario,
                    email_proprietario: null,
                    emails: [],
                    emailsOrig: [],
                    checked: false,
                    estadoContencioso: (it.cod_estado !== '0'),
                  };
                  
                  let auxEmails = [];
                  if (it.email_proprietario) {
                    auxEmails.push(it.email_proprietario);
                  }
                  if (it.email_proprietario_2) {
                    auxEmails.push(it.email_proprietario_2);
                  }
      
                  if (auxEmails.length > 0) {
                    aux.emails = auxEmails;
                    aux.emailsOrig = auxEmails;
                    aux.email_proprietario = auxEmails.join('; ');
                  }
      
                  aux.checked = !!aux.email_proprietario && !aux.estadoContencioso;
  
                  this.fraccoesEmailList.push(aux);
                });
              });
            }

            resolve(true);
          } else {
            this.fraccoesEmailList = [];
            resolve(false);
          }
  
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        }, err => {
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          this.fraccoesEmailList = [];
          resolve(false);
        });
      }
    });
  }

  getCondominiosList() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    return new Promise(resolve => {
      let req = [
        this.api.getCondominoContacts(this.codCondomino),
        this.api.getCondominios(this.codCondomino),
      ];

      forkJoin(req).subscribe(res => {
        if (!res.find(el => (el.hasOwnProperty('success') && !el.success))) {
          // SET CONDOMINIO CONTACTS
          let contacts = res[0].data;
          this.emailsCondomino = [];
          if (contacts && contacts.hasOwnProperty('email_1') && contacts.email_1) this.emailsCondomino.push(contacts.email_1);
          if (contacts && contacts.hasOwnProperty('email_2') && contacts.email_2) this.emailsCondomino.push(contacts.email_2);

          this.condominiosList = [];

          let aux = null;
          res[1].data.forEach(el => {
            aux = {
              checked: false,
              condominio: el.cod_condominio + ' - ' + el.nome_condominio,
              fraccao: el.cod_fraccao + ' - ' + el.nome_fraccao,
              cod_condominio: el.cod_condominio,
              cod_fraccao: el.cod_fraccao,
            };
            this.condominiosList.push(aux);
          });

          resolve(true);
        } else {
          this.condominiosList = [];
          resolve(false)
        }
        
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.condominiosList = [];
        resolve(false);
      });
    });
  }

  listType = null;
  modalOpened = false;
  async openModal() {
    return new Promise(async (resolve) => {
      if (this.emailSelectionModalRef) {
        resolve(false);
        return;
      }

      this.editEmailsMode = false;
      this.editEmails = false;
      this.selectDocs = false;
  
      // SET INPUT CONFIG OBJECT
      if (this.configs) {
        switch (this.target) {
          case 'CONTA_CORRENTE':
            if (this.configs.hasOwnProperty('ccType')) this.ccType = this.configs.ccType;
            if (this.configs.hasOwnProperty('startDate')) this.startDate = this.configs.startDate;
            if (this.configs.hasOwnProperty('endDate')) this.endDate = this.configs.endDate;
            if (this.configs.hasOwnProperty('selTipoAgrupamento')) this.selTipoAgrupamento = this.configs.selTipoAgrupamento;  
            if (this.configs.hasOwnProperty('selTipoListagem')) this.selTipoListagem = this.configs.selTipoListagem;  
            break;
          case 'LANCAMENTOS':
            this.estadoCredito = 'VALOR_EM_CREDITO';
            this.lancamentosSelectedTab = 'RECIBOS';
  
            if (this.configs.hasOwnProperty('startDate')) this.startDate = this.configs.startDate;
            if (this.configs.hasOwnProperty('endDate')) this.endDate = this.configs.endDate;
            if (this.configs.hasOwnProperty('recibosListOrig')) this.recibosListOrigConfig = this.configs.recibosListOrig;
            if (this.configs.hasOwnProperty('creditosListOrig')) this.creditosListOrigConfig = this.configs.creditosListOrig;
            break;
        }
      }
  
      if (this.target === 'CONTA_CORRENTE' && this.ccType === 'condominos') {
        this.listType = 'CONDOMINIOS';
  
        if (!this.codCondomino) { // TODO: CHECK IF HAS EMAIL DEFINED
          this.toastr.error('Nenhum condómino selecionado.', 'Alerta', { timeOut: 4000 });
          resolve(false);
          return;
        }
      } else {
        switch (this.target) {
          case 'CONTA_CORRENTE': this.listType = 'FRACCOES'; break;
          case 'LANCAMENTOS': this.listType = 'FRACCOES_PROPRIETARIOS'; break;  // LISTA COM PROPRITARIOS ANTERIORES
        }      
  
        if (!this.codCondominio) {
          this.toastr.error('Nenhum condomínio selecionado.', 'Alerta', { timeOut: 4000 });
          resolve(false);
          return;
        }
      }
      
      if (this.target === 'CONTA_CORRENTE') {
        let auxCC = this.editEmailsTabsDef.find(el => el.key === 'CONTA_CORRENTE');
        if (auxCC) {
          auxCC.assunto = this.appConfig.contaCorrenteEmailSubject;
          auxCC.corpo = this.appConfig.contaCorrenteEmailBody.join('\n');
        }
      }
  
      if (this.target === 'LANCAMENTOS') {
        let auxRec = this.editEmailsTabsDef.find(el => el.key === 'RECIBOS');
        if (auxRec) {
          auxRec.assunto = this.appConfig.recibosEmailSubject;
          auxRec.corpo = this.appConfig.recibosEmailBody.join('\n');
        }
  
        let auxCre = this.editEmailsTabsDef.find(el => el.key === 'CREDITOS');
        if (auxCre) {
          auxCre.assunto = this.appConfig.creditosEmailSubject;
          auxCre.corpo = this.appConfig.creditosEmailBody.join('\n');
        }
      }
  
      await this.getContaPrincipal();
  
      if (this.target === 'CONTA_CORRENTE' && this.ccType === 'condominos') {
        this.fraccoesEmailList = [];
  
        let res = await this.getCondominiosList();
        if (!res) {
          resolve(false);
          return;
        }
  
        if (!Array.isArray(this.emailsCondomino) || this.emailsCondomino.length === 0) {
          this.toastr.error('Condómino selecionado não tem nenhum endereço de email registado.', 'Alerta', { timeOut: 4000 });
          resolve(false);
          return;
        }
  
        // SET SELECTED CONDOMINOS
        if (this.configs.hasOwnProperty('condominiosSelected') && Array.isArray(this.configs.condominiosSelected)) {
          let auxCond = null;
          this.condominiosList.forEach(el => {
            auxCond = this.configs.condominiosSelected.find(it => it.cod_condominio === el.cod_condominio);
            el.checked = (!!auxCond );
          });
        }
  
        this.condominioSelectionChanged();
      } else {
        this.condominiosList = [];
  
        let res = await this.getFraccoesEmailList();
        if (!res) return;
    
        if (this.target === 'LANCAMENTOS') {
  
        } else {
          // SET SELECTED CONDOMINOS
          if (this.configs.hasOwnProperty('condominosSelected') && Array.isArray(this.configs.condominosSelected)) {
            let auxFraccao = null;
            this.fraccoesEmailList.forEach(el => {
              auxFraccao = this.configs.condominosSelected.find(it => it.cod_fraccao === el.fraccao_cod);
              el.checked = (!!auxFraccao && el.checked);
            });
          }
        }
  
        this.emailSelectionChanged();
      }
  
      // INIT MODAL VARIABLES
      this.loadingModal = false;
      this.editEmailsMode = false;
      this.editEmails = false;    
  
      // SET DEFAULT REMETENTE E ASSINATURA
      this.remetenteSelected = '-1';
      this.assinaturaSelected = this.userSession.getUserId();
  
      this.modalOpened = true;
      this.emailSelectionModalRef = this.modalService
        .open(this.emailSelectionAlertConfig)
        .onApprove(() => { this.loadingModal = false; this.emailSelectionModalRef = null; this.modalOpened = false; resolve(true); })
        .onDeny(() => { this.loadingModal = false; this.emailSelectionModalRef = null; this.modalOpened = false; resolve(false); });
    })
  }

  getContaPrincipal() {
    return new Promise(resolve => {
      this.api.getContaPrincipal(this.codCondominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.data && res.data.hasOwnProperty('banco') && res.data.banco !== 'CAIXA' && res.data.nib) {
            if (res.data.nib.indexOf('PT50') !== -1) {
              this.IBANCondominio = res.data.nib;
            } else {
              this.IBANCondominio = 'PT50' + res.data.nib;
            }
          }
        }
        resolve(true);
      }, err => {
        this.IBANCondominio = null;
        resolve(false);
      });
    });
  }

  anexoSelected(target) {
    let aux = this.editEmailsTabsDef.find(el => el.key === target);

    switch (target) {
      case 'MAPA_DE_QUOTAS':
        if (aux) aux.selected = (this.mapaDeQuotas || this.contaCorrenteAnexo);
        break;
      case 'AVISO_DE_PAGAMENTO':
        if (aux) aux.selected = this.avisoDePagamento;
        break;
    }
    this.hasAnexoSelected = !!this.editEmailsTabsDef.find(el => ((el.key === 'MAPA_DE_QUOTAS' || el.key === 'AVISO_DE_PAGAMENTO') && el.selected));
  }

  tabSelected(tab) {
    this.selTab = tab;
  }

  // EDIT PROPREITARIO EMAIL LIST --------------------------------------------
  @ViewChild('editEmailAlertRef', { static: false }) editEmailAlertRef;
  editEmailModalRef = null;
  editEmailAlertConfig: any = null;

  editEmailProprietario = null;
  emailEditEntry = null;
  emailList = null;
  submittingEmailList = false;
  invalidEmails = false;

  editEmailAddr(item) {
    if (item.hasOwnProperty('fraccao_descricao')) {
      this.editEmailProprietario = `${item.fraccao_descricao}, ${item.proprietario}`;
    } else {
      this.editEmailProprietario = `${item.proprietario}`;
    }

    this.emailEditEntry = item;
    this.emailList = item.email_proprietario;

    this.editEmailModalRef = this.modalService
      .open(this.editEmailAlertConfig)
      .onApprove(() => {
        item.emails = this.emailList.split(';').filter(el => !!(el.trim())).map(el => el.trim());
        item.email_proprietario = item.emails.join('; ');

        item.checked = !!(item.email_proprietario);

        this.emailSelectionChanged();

        this.editEmailProprietario = null;
        this.emailEditEntry = null;
        this.emailList = null;
        this.submittingEmailList = false;
        this.invalidEmails = false;
      })
      .onDeny(() => {
        this.editEmailProprietario = null;
        this.emailEditEntry = null;
        this.emailList = null;
        this.submittingEmailList = false;
        this.invalidEmails = false;
      });
  }

  submitNewEmailList() {
    this.submittingEmailList = true;
    if (!this.emailList) {
      this.toastr.error('Por favor, introduza um endereço de email válido antes de submeter.', 'Nenhum Email Introduzido', { timeOut: 4000 });
      return;
    }

    let emailList = this.emailList.split(';').filter(el => !!(el.trim())).map(el => el.trim())
    for (let i = 0; i < emailList.length; i++) {
      if (!this.utils.validateEmail(emailList[i])) {
        this.invalidEmails = true;
        setTimeout(() => { this.invalidEmails = false; }, 4000);
        this.toastr.error('Por favor, verifique o(s) endereço(s) de email introduzido(s).', 'Email(s) Inválido(s)', { timeOut: 4000 });
        return;
      }
    }
    this.emailSelectionChanged();
    this.editEmailModalRef.approve();
  }

  setOrigEmailList() {
    this.emailEditEntry.emails = this.emailEditEntry.emailsOrig;
    if (this.emailEditEntry.emails.length > 0) {
      this.emailEditEntry.email_proprietario = this.emailEditEntry.emails.join('; ');
    } else {
      this.emailEditEntry.email_proprietario = null;
    }
    this.emailEditEntry.checked = !!(this.emailEditEntry.email_proprietario);
    this.editEmailModalRef.deny();
  }

  // START - MAPA DE QUOTAS ---------------------------------------------------
  @ViewChild('pdfMQ', { static: false }) pdfMQController;

  getLastOrcamento() {
    return new Promise(resolve => {
      this.api.getOrcamentoByPeriodoAndAprovado(this.codCondominio, true).subscribe(res => {
        if ((res.hasOwnProperty('success') && res.success)) {
          resolve(res.data.orcamento);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
  }

  exercicioDP = null;
  nowMQ = null;
  getMapaDeQuotas() {
    return new Promise(async resolve => {
      let startDate = new Date(Number(this.reconciliacaoDate.getFullYear()), 0, 1);
      let endDateMQ = this.reconciliacaoDate;
      let endDate = this.reconciliacaoDate;

      // CHECK FOR LAST ORCAMENTO LANCADO
      let orcamento = await this.getLastOrcamento();
      if (orcamento && orcamento.hasOwnProperty('periodo')) {
        if (Number(orcamento['periodo']) < Number(this.reconciliacaoDate.getFullYear())) {
          startDate = new Date(Number(orcamento['periodo']), 0, 1);
          endDateMQ = new Date(Number(orcamento['periodo']), 11, 31);
        }
      }

      let exercicio = endDateMQ.getFullYear().toString();
      this.exercicioDP = exercicio;

      let req = [this.api.getRelExercMapaQuotas(this.codCondominio, null,startDate, endDateMQ, exercicio)];
      let fraccoes = [...new Set(this.fraccoesEmailList.map(el => el.fraccao_cod))];
      fraccoes.forEach(codFraccao => {
        req.push(this.api.getContaCorrente('fraccoes', 'condomino', this.codCondominio, [ codFraccao ], null, endDate, '0'));
      });

      forkJoin(req).subscribe(res => {
        if ((res[0].hasOwnProperty('success') && res[0].success)) {
          resolve(res);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });

    });
  }

  reportListMQ = [];
  reportListMQSeg = [];
  mapaQuotasTotal = {
    valorLiquidado: null,
    valorPorLiquidar: null,
  }
  mapaQuotasSegTotal = {
    valorLiquidado: null,
    valorPorLiquidar: null,
  }
  contaCorrenteMQ = [];
  generateMapaQuotasReportObj(res) {
    this.reportListMQ = [];
    this.reportListMQSeg = [];

    let prevFraccao = (res[0].data.mapa_quotas.length) ? res[0].data.mapa_quotas[0].fraccao : null;

    let prevFraccaoSeg = (res[0].data.mapa_quotas_seg.length > 0) ? res[0].data.mapa_quotas_seg[0].fraccao : null;
    let aux = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0 };
    let auxSeg = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0 };
    let hasSeg = false;

    let contasCorrente = res.slice(1);

    this.contaCorrenteMQ = [];
    contasCorrente.forEach(el => { this.contaCorrenteMQ = this.contaCorrenteMQ.concat(el.data); });

    let contaCorrente = contasCorrente.find(item => (Array.isArray(item.data) && item.data.length > 0 && item.data[0].cod_fraccao === res[0].data.mapa_quotas[0].cod_fraccao));
    if (contaCorrente) {
      contaCorrente.data.forEach(item => {
        if (item.tipo_doc === 'C') {
          aux['porLiquidar'] -= (item.hasOwnProperty('saldo')) ? (Number(item.saldo) * 100) / 100 : 0;
        } else {
          aux['porLiquidar'] += (item.hasOwnProperty('saldo')) ? (Number(item.saldo) * 100) / 100 : 0;
        }
      });
    }

    res[0].data.mapa_quotas.forEach((el, i) => {
      el.quota = Number(el.quota);
      el.debito = Number(el.debito);
      el.mes = Number(el.mes);

      if (prevFraccao !== el.fraccao) {
        this.reportListMQ.push(aux);
        aux = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0 };

        let contaCorrente = contasCorrente.find(item => (Array.isArray(item.data) && item.data.length > 0 && item.data[0].cod_fraccao === el.cod_fraccao));
        if (contaCorrente) {
          contaCorrente.data.forEach(item => {
            if (item.tipo_doc === 'C') {
              aux['porLiquidar'] -= (item.hasOwnProperty('saldo')) ? (Number(item.saldo) * 100) / 100 : 0;
            } else {
              aux['porLiquidar'] += (item.hasOwnProperty('saldo')) ? (Number(item.saldo) * 100) / 100 : 0;
            }
          });
        }
      }

      switch (el.mes) {
        case 1: aux['jan'] = this.setPaymentStatus(el); break;
        case 2: aux['fev'] = this.setPaymentStatus(el); break;
        case 3: aux['mar'] = this.setPaymentStatus(el); break;
        case 4: aux['abr'] = this.setPaymentStatus(el); break;
        case 5: aux['mai'] = this.setPaymentStatus(el); break;
        case 6: aux['jun'] = this.setPaymentStatus(el); break;
        case 7: aux['jul'] = this.setPaymentStatus(el); break;
        case 8: aux['ago'] = this.setPaymentStatus(el); break;
        case 9: aux['set'] = this.setPaymentStatus(el); break;
        case 10: aux['out'] = this.setPaymentStatus(el); break;
        case 11: aux['nov'] = this.setPaymentStatus(el); break;
        case 12: aux['dez'] = this.setPaymentStatus(el); break;
      }

      aux['fraccao_descricao'] = el.fraccao;
      aux['quota'] = Number(el.quota);

      prevFraccao = el.fraccao;
    });
    this.reportListMQ.push(aux);

    // COMPUTE TOTAL
    this.mapaQuotasTotal['valorPorLiquidar'] = 0;
    this.reportListMQ.forEach(el => {
      this.mapaQuotasTotal['valorPorLiquidar'] += el.porLiquidar;
    });

    res[0].data.mapa_quotas_seg.forEach(el => {
      hasSeg = true;

      el.quota = Number(el.quota);
      el.debito = Number(el.debito);
      el.mes = Number(el.mes);

      if (prevFraccaoSeg !== el.fraccao) {
        this.reportListMQSeg.push(auxSeg);
        auxSeg = { jan: null, fev: null, mar: null, abr: null, mai: null, jun: null, jul: null, ago: null, set: null, out: null, nov: null, dez: null, porLiquidar: 0 };
      }

      switch (el.mes) {
        case 1: auxSeg['jan'] = this.setPaymentStatus(el); break;
        case 2: auxSeg['fev'] = this.setPaymentStatus(el); break;
        case 3: auxSeg['mar'] = this.setPaymentStatus(el); break;
        case 4: auxSeg['abr'] = this.setPaymentStatus(el); break;
        case 5: auxSeg['mai'] = this.setPaymentStatus(el); break;
        case 6: auxSeg['jun'] = this.setPaymentStatus(el); break;
        case 7: auxSeg['jul'] = this.setPaymentStatus(el); break;
        case 8: auxSeg['ago'] = this.setPaymentStatus(el); break;
        case 9: auxSeg['set'] = this.setPaymentStatus(el); break;
        case 10: auxSeg['out'] = this.setPaymentStatus(el); break;
        case 11: auxSeg['nov'] = this.setPaymentStatus(el); break;
        case 12: auxSeg['dez'] = this.setPaymentStatus(el); break;
      }

      auxSeg['fraccao_descricao'] = el.fraccao;
      auxSeg['quota'] = Number(el.quota);

      auxSeg['porLiquidar'] += (el.hasOwnProperty('debito')) ? (Number(el.debito) * 100) / 100 : 0;

      prevFraccaoSeg = el.fraccao;
    });
    if (hasSeg) this.reportListMQSeg.push(auxSeg);

    // COMPUTE TOTAL
    this.mapaQuotasSegTotal['valorPorLiquidar'] = 0;
    this.reportListMQSeg.forEach(el => {
      this.mapaQuotasSegTotal['valorPorLiquidar'] += el.porLiquidar;
    });
  }

  setPaymentStatus (el) {
    if (Math.round(el.debito * 100) / 100 === 0) {  // PAGO
      return 'L';
    } else if (Math.round(el.debito * 100) / 100 === Math.round(el.quota * 100) / 100) {  // NAO PAGO
      if (Number(el.ano) < this.reconciliacaoDate.getFullYear() || el.mes < this.reconciliacaoDate.getMonth() + 1 || (el.mes === this.reconciliacaoDate.getMonth() + 1 && this.reconciliacaoDate.getDate() > 8)) {
        return 'NL';
      }
    } else {  // PARCIALMENTE PAGO
      return 'PL';
    }

    return null;
  }

  exportMapaDeQuotas() {
    return new Promise(resolve => {
      this.startDate = null;
      this.endDate = this.reconciliacaoDate;

      setTimeout(() => {
        this.pdfMQController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {

          let base64  = dataUri.replace('data:application/pdf;base64,', '');
          base64  = base64.replace(' ', '+');
          resolve(base64);
        });
      }, 1);
    });
  }

  exportContaCorrenteTotal(cc) {
    return new Promise(resolve => {
      this.nowCC = new Date();
      this.contaCorrente = cc;
      this.startDate = null;
      this.endDate = this.reconciliacaoDate;

      setTimeout(() => {
        this.pdfCCController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {

          let base64  = dataUri.replace('data:application/pdf;base64,', '');
          base64  = base64.replace(' ', '+');
          resolve(base64);
        });
      }, 1);
    });
  }

  mergePdf(fileName, base64Arr, contentType='data:application/pdf;base64,') {
    return new Promise(resolve => {
      this.api.mergePdf(fileName, contentType, base64Arr).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.data) {
            resolve(contentType + res.data);
          } else {
            resolve(false);
          }
        }
      },
      err => {
        resolve(false);
      });
    });
  }

  getEmailBodyMapaDeQuotas() {
    let userAssinatura = null;
    if (this.assinaturaSelected) {
      userAssinatura = this.assinaturaOpts.find(el => el.value === this.assinaturaSelected);
    }

    let htmlEmail = '<div style="margin-bottom: 35px;">';

    this.editEmailsTabsDef.find(elem => elem.key === 'MAPA_DE_QUOTAS').corpo.split('\n').forEach(line => {
      if (line) {
        htmlEmail += '<span>' + line + '</span>';
      } else {
        htmlEmail += '<span><br><br></span>';
      }
    });

    htmlEmail += '</div>';
    htmlEmail += (userAssinatura) ? this.utils.getEmailFooterV2(userAssinatura.name, userAssinatura.phone, userAssinatura.email) : this.utils.getEmailFooterV2();
    htmlEmail += '<img style="width: 30%; max-width: 200px; height: auto; margin-top: 10px;" src="' + this.appConfig.emailLogoUrl + '">'
    
    return htmlEmail;
  }
  // END - MAPA DE QUOTAS -----------------------------------------------------


  // START - AVISOS DE PAGAMENTO ----------------------------------------------
  @ViewChild('pdfAP', { static: false }) pdfAPController;

  getAvisosDePagamento() {
    return new Promise(resolve => {

      let req = [
        this.api.fraccoesEmDivida(this.codCondominio, 'DATE', null, this.reconciliacaoDate, '-2'),
        this.api.fraccoesEmDivida(this.codCondominio, 'DATE', null, this.reconciliacaoDate, '-1'),
        this.api.getTextosDetails('8'),
      ];

      forkJoin(req).subscribe(res => {
        if (!res.find(el => (el.hasOwnProperty('success') && !el.success))) {
          let auxArr = res[0].data.concat(res[1].data);

          auxArr = auxArr.filter(el => (el.hasOwnProperty('divida') && (Math.round(Number(el.divida) * 100) / 100) > 0));

          // FILTER AVISOS A ZERO
          auxArr.forEach(el => {
            if (el.hasOwnProperty('avisos')) el['avisos'] = el['avisos'].filter(el => (el.hasOwnProperty('divida') && (Math.round(Number(el.divida) * 100) / 100) > 0));
          });
  
          let aux = null;
          let fraccaoList = [];
          auxArr.forEach(el => {
            let condMoradaAux = el.morada;
            el.morada_proprietario_coresp = this.utils.formatEmptyMoradaObject(el.morada_proprietario_coresp);
            let propMoradaAux = (el.morada_proprietario_coresp && el.morada_proprietario_coresp !== '{"address":null,"postalCode":null,"locality":null}' && el.morada_proprietario_coresp !== '{"address":"","postalCode":"","locality":""}') ? el.morada_proprietario_coresp : el.morada_proprietario;
  
            // HANDLE OBJECT MORADA - CONDOMINIO
            try {
              let aux = '';
              let addressObj = JSON.parse(condMoradaAux);
              Object.keys(addressObj).forEach((key, i) => {
                if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE'); 
              });
          
              condMoradaAux = aux;
            } catch(e) {}
          
            // HANDLE OBJECT MORADA - CONDOMINIO
            try {
              let aux = '';
              let addressObj = JSON.parse(propMoradaAux);
              Object.keys(addressObj).forEach((key, i) => {
                if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE'); 
              });
          
              propMoradaAux = aux;
            } catch(e) {}
  
            aux = {
              checked: false,
              cod_condominio: el.cod_condominio,
              cod_fraccao: el.cod_fraccao,
              zona: el.zona_nome,
              fraccao: el.cod_fraccao + ' - ' + el.fraccao_nome,
              proprietario: el.nome_proprietario,
              morada_proprietario: (propMoradaAux) ? propMoradaAux.split(/NEW_LINE/g) : [],
              inquilino: el.nome_inquilino,
              morada_inquilino: (el.morada_inquilino) ? el.morada_inquilino.split(/NEW_LINE/g) : null,
              valor: Number(el.divida),
              moradaCondominio: (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [],
              avisos: (el.avisos) ? el.avisos : [],
  
              nib: (el.hasOwnProperty('nib') && el.nib) ? ( (el.nib.indexOf('PT50') !== -1) ? el.nib : 'PT50' + el.nib ) : null,
  
              nif: (el.hasOwnProperty('nif')) ? el.nif : null,
              email_proprietario: el.email_proprietario,
              email_proprietario_2: el.email_proprietario_2,
              email_inquilino: el.email_inquilino,
            }

            fraccaoList.push(aux);
          });

          this.docAVModel = res[2].data;
          if (this.docAVModel.text_inicial.indexOf('NEW_LINE') !== -1) {
            this.docAVModel.text_inicial = this.docAVModel.text_inicial.split(/NEW_LINE/g);
          } else {
            this.docAVModel.text_inicial = this.docAVModel.text_inicial.split('\n');
          }
    
          if (this.docAVModel.texto_final.indexOf('NEW_LINE') !== -1) {
            this.docAVModel.texto_final = this.docAVModel.texto_final.split(/NEW_LINE/g);
          } else {
            this.docAVModel.texto_final = this.docAVModel.texto_final.split('\n');
          }

          resolve(fraccaoList);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
  }

  docAVModel = null;
  nowAP = null;
  cartaAvisoPagamento = null;
  getEmailBodyAvisoDePagamento() {
    let userAssinatura = null;
    if (this.assinaturaSelected) {
      userAssinatura = this.assinaturaOpts.find(el => el.value === this.assinaturaSelected);
    }

    let htmlEmail = '<div style="margin-bottom: 35px;">';

    this.editEmailsTabsDef.find(elem => elem.key === 'AVISO_DE_PAGAMENTO').corpo.split('\n').forEach(line => {
      if (line) {
        htmlEmail += '<span>' + line + '</span>';
      } else {
        htmlEmail += '<span><br><br></span>';
      }
    });

    htmlEmail += '</div>';
    htmlEmail += (userAssinatura) ? this.utils.getEmailFooterV2(userAssinatura.name, userAssinatura.phone, userAssinatura.email) : this.utils.getEmailFooterV2();
    htmlEmail += '<img style="width: 30%; max-width: 200px; height: auto; margin-top: 10px;" src="' + this.appConfig.emailLogoUrl + '">'
    
    return htmlEmail;
  }
  getEmailAttachAvisoDePagamento(fraccao) {
    return new Promise(resolve => {
      this.nowAP = new Date();
      this.cartaAvisoPagamento = fraccao;

      setTimeout(() => {
        this.pdfAPController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {

          let base64  = dataUri.replace('data:application/pdf;base64,', '');
          base64  = base64.replace(' ', '+');
          resolve(base64);
        });
      }, 1);
    });
  }
  // END - AVISOS DE PAGAMENTO ------------------------------------------------


  // START - CONTA CORRENTE ---------------------------------------------------
  @ViewChild('pdfCC', { static: false }) pdfCCController;
  nowCC = null;
  contaCorrente = [];
  totalCC = {
    debito: null,
    credito: null,
    saldo: null,
  }
  titleCC = 'Conta Corrente';

  contaCorrenteDetailCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered first-report-col' },
    { key: 'doc', name: 'Doc', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'proc', name: 'Processamento', type: 'text', sort: null, searchable: false, centered: false, class: 'big-report-col' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];

  contaCorrenteCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered first-report-col' },
    { key: 'condominio', name: 'Condomínio', type: 'text', sort: null, searchable: false, centered: false, class: 'big-report-col-fornecedor' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'two wide col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right' },
  ];

  getContasCorrentes() {
    return new Promise(resolve => {

      if (this.ccType === 'fraccoes') {
        if (this.selTipoRelatorio === '1') {
          let req = [];
          this.fraccoesEmailList.filter(el => el.checked).forEach(el => {
            req.push(this.api.getContaCorrente('fraccoes', this.selTipoAgrupamento, this.codCondominio, [ el.fraccao_cod ], this.startDate, this.endDate, this.selTipoListagem));
          });
          forkJoin(req).subscribe(res => {
            if (res[0].hasOwnProperty('success') && res[0].success) {
              let dataset = [];
    
              res.forEach(el => { dataset = dataset.concat(el.data); });
  
              resolve(dataset);
            } else { resolve(false); }
          }, err => { resolve(false); });
        } else {
          resolve(false);
        }
      }

      if (this.ccType === 'condominos') {
        if (this.selTipoRelatorio === '1') {
          let condominiosSel = this.condominiosList.filter(el => (el.checked)).map(el => { return { cod_condominio: el.cod_condominio, cod_fraccao: el.cod_fraccao }; });
          this.api.getContaCorrenteCondomino(this.codCondomino, condominiosSel, this.startDate, this.endDate, this.selTipoListagem).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {
              resolve(res.data);
            } else { resolve(false); }
          }, err => { resolve(false); });
        } else {
          resolve(false);
        }
      }
    });
  }

  getContaCorrenteCondomino(data) {
    let contaCorrente = [];

    this.totalCC['debito'] = 0;
    this.totalCC['credito'] = 0;
    this.totalCC['saldo'] = 0;

    let debito_total = 0;
    let credito_total = 0;
    let saldo_total = 0;

    let debito_total_condominio = 0;
    let credito_total_condominio = 0;
    let saldo_total_condominio = 0;

    let prevCondominio = null;
    let prevFrac = null;

    data.forEach((el, i) => {
      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        tipo_doc: (el['tipo_doc']) ? el['tipo_doc'] : null,
        doc: (el['n_doc']) ? el['tipo_doc'] + ' ' + el['n_doc'] : null,
        data: (el['data_emis']) ? this.utils.getDate(el['data_emis']) : null,
        data_venc: (el['data_venc']) ? this.utils.getDate(el['data_venc']) : null,
        proc: (el['descricao']) ? el['descricao'] : null,
        debito: null,
        credito: null,
        saldo: null,
      }
      if (el['tipo_doc'] === 'C') {
        aux['debito'] = 0;
        aux['credito'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
        aux['saldo'] = aux.debito - aux.credito;
      } else {
        aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
        aux['saldo'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
        aux['credito'] = aux.debito - aux.saldo;
      }

      // TABLE ROW TOTAL AND SEPARATOR
      if ((prevCondominio !== el['cod_condominio'] || prevFrac !== el['cod_fraccao']) && el['tipo_doc'] !== 'C') {
        if (i > 0) {
          contaCorrente.push({ label: 'Total da fracção', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
          contaCorrente.push({ label: 'Total do condomínio', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
        }

        // ZONA AND FRACCAO SEPARATOR
        if (prevCondominio !== el['cod_condominio']) {
          contaCorrente.push({ label: `${el['cod_condominio']} - ${el['nome_condominio']}`, separator: true, separatorCond: false, total: false });
          debito_total = 0;
          credito_total = 0;
          saldo_total = 0;
        }

        // ENTITY SEPARATOR
        contaCorrente.push({ label: `${el['cod_fraccao']} - ${el['fraccao_nome']}`, separator: true, separatorCond: true, total: false });
        debito_total_condominio = 0;
        credito_total_condominio = 0;
        saldo_total_condominio = 0;
      }
      if (el['tipo_doc'] !== 'C') prevCondominio = el['cod_condominio'];
      if (el['tipo_doc'] !== 'C') prevFrac = el['cod_fraccao'];

      debito_total += aux.debito;
      credito_total += aux.credito;
      saldo_total += aux.saldo;

      debito_total_condominio += aux.debito;
      credito_total_condominio += aux.credito;
      saldo_total_condominio += aux.saldo;

      contaCorrente.push(aux);

      this.totalCC['debito'] += aux.debito;
      this.totalCC['credito'] += aux.credito;
      this.totalCC['saldo'] += aux.saldo;
    });
    if (contaCorrente.length > 0) {
      contaCorrente.push({ label: 'Total da fracção', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
      contaCorrente.push({ label: 'Total do condomínio', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
    }

    return contaCorrente;
  }

  getContaCorrente(data) {
    let dataOrig = data;
    data = data.filter(el => Number(el.saldo) > 0);

    let contaCorrente = [];

    let fraccoes = [...new Set(data.map(el => el.cod_fraccao))];
    fraccoes = fraccoes.sort((a, b) => { return (a > b || a['length'] > b['length']) ? 1 : -1; });

    let perFraccaoArr = [];
    let condominos = [];
    let perCondominoArr = [];

    let debito_total_condominio = 0;
    let credito_total_condominio = 0;
    let saldo_total_condominio = 0;

    let debito_total = 0;
    let credito_total = 0;
    let saldo_total = 0;

    this.totalCC['debito'] = 0;
    this.totalCC['credito'] = 0;
    this.totalCC['saldo'] = 0;

    fraccoes.forEach(fraccao => {

      debito_total = 0;
      credito_total = 0;
      saldo_total = 0;

      perFraccaoArr = data.filter(it => it.cod_fraccao === fraccao).sort((a, b) => {
        if (a.data_emis === b.data_emis) {
          return Number(a.n_doc) - Number(b.n_doc);
        } else {
          return this.utils.getDate(a.data_emis).getTime() - this.utils.getDate(b.data_emis).getTime();
        }
      });

      if (perFraccaoArr.length) { perFraccaoArr[0]['zona_nome']
        let zonaNome = dataOrig.find(it => it.cod_fraccao === perFraccaoArr[0]['cod_fraccao'] && it.zona_nome);
        contaCorrente.push({ label: `${(zonaNome) ? zonaNome.zona_nome : null} / ${perFraccaoArr[0]['cod_fraccao']} - ${perFraccaoArr[0]['fraccao_nome']}`, separator: true, separatorCond: false, total: false });
      }

      condominos = [...new Set(perFraccaoArr.map(it => it.condomino_cod))]
      condominos.forEach(condomino => {

        debito_total_condominio = 0;
        credito_total_condominio = 0;
        saldo_total_condominio = 0;

        perCondominoArr = perFraccaoArr.filter(it => it.condomino_cod === condomino);

        if (perCondominoArr.length) contaCorrente.push({ label: `${perCondominoArr[0]['condomino_nome']}`, separator: true, separatorCond: true, total: false });

        perCondominoArr.forEach(avisoCredito => {

          let aux = {
            checked: false,
            separator: false,
            separatorCond: false,
            total: false,
            tipo_doc: (avisoCredito['tipo_doc']) ? avisoCredito['tipo_doc'] : null,
            doc: (avisoCredito['n_doc']) ? avisoCredito['tipo_doc'] + ' ' + avisoCredito['n_doc'] : null,
            data: (avisoCredito['data_emis']) ? this.utils.getDate(avisoCredito['data_emis']) : null,
            data_venc: (avisoCredito['data_venc']) ? this.utils.getDate(avisoCredito['data_venc']) : null,
            proc: (avisoCredito['descricao']) ? avisoCredito['descricao'] : null,
            debito: null,
            credito: null,
            saldo: null,
          }
          if (avisoCredito['tipo_doc'] === 'C') {
            aux['debito'] = 0;
            aux['credito'] = (avisoCredito['saldo']) ? Math.round(Number(avisoCredito['saldo']) * 100) / 100 : null;
            aux['saldo'] = aux.debito - aux.credito;
          } else {
            aux['debito'] = (avisoCredito['debito']) ? Math.round(Number(avisoCredito['debito']) * 100) / 100 : null;
            aux['saldo'] = (avisoCredito['saldo']) ? Math.round(Number(avisoCredito['saldo']) * 100) / 100 : null;
            aux['credito'] = aux.debito - aux.saldo;
          }

          debito_total_condominio += aux.debito;
          credito_total_condominio += aux.credito;
          saldo_total_condominio += aux.saldo;

          debito_total += aux.debito;
          credito_total += aux.credito;
          saldo_total += aux.saldo;

          this.totalCC['debito'] += aux.debito;
          this.totalCC['credito'] += aux.credito;
          this.totalCC['saldo'] += aux.saldo;

          contaCorrente.push(aux);
        });

        if (perCondominoArr.length) contaCorrente.push({ label: 'Total do condómino', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
      });

      if (perFraccaoArr.length) contaCorrente.push({ label: 'Total da fracção', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
    });

    return contaCorrente;
  }

  getEmailAttachContaCorrente(cc) {
    return new Promise(resolve => {
      this.nowCC = new Date();
      this.contaCorrente = cc;

      setTimeout(() => {
        this.pdfCCController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {

          let base64  = dataUri.replace('data:application/pdf;base64,', '');
          base64  = base64.replace(' ', '+');
          resolve(base64);
        });
      }, 1);
    });
  }

  getEmailBodyContaCorrente() {
    let userAssinatura = null;
    if (this.assinaturaSelected) {
      userAssinatura = this.assinaturaOpts.find(el => el.value === this.assinaturaSelected);
    }

    let htmlEmail = '<div style="margin-bottom: 35px;">';

    this.editEmailsTabsDef.find(elem => elem.key === 'CONTA_CORRENTE').corpo.split('\n').forEach(line => {
      if (line) {
        htmlEmail += '<span>' + line + '</span>';
      } else {
        htmlEmail += '<span><br><br></span>';
      }
    });

    htmlEmail += '</div>';
    htmlEmail += (userAssinatura) ? this.utils.getEmailFooterV2(userAssinatura.name, userAssinatura.phone, userAssinatura.email) : this.utils.getEmailFooterV2();
    htmlEmail += '<img style="width: 30%; max-width: 200px; height: auto; margin-top: 10px;" src="' + this.appConfig.emailLogoUrl + '">'
    
    return htmlEmail;
  }
  // END - CONTA CORRENTE -----------------------------------------------------

  // START - LANCAMENTOS ------------------------------------------------------
  lancamentoType = null;
  selectDocs = false;
  lancamentosTabsDef = [
    { key: 'RECIBOS', name: 'Recibos', disabled: false, selected: true },
    { key: 'CREDITOS', name: 'Créditos', disabled: false, selected: true },
  ];
  lancamentosSelectedTab = 'RECIBOS';

  recibosListCol = [
    { key: 'n_recibo', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered z-index-0' },
    { key: 'nome_condomino', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered z-index-0' },
    { key: 'criado_em', name: 'Data Criação', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered z-index-0' },
    { key: 'valor', name: 'Liquidado', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right z-index-0' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide table-checkbox-column' },  // 'ASC', 'DESC'
  ];
  recibosListOrigConfig: Array<any> = [];
  recibosListOrig: Array<any> = [];
  recibosList: Array<any> = [];

  creditosListCol = [
    { key: 'n_credito', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered z-index-0' },
    { key: 'cod_fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'one wide col-centered z-index-0' },
    { key: 'nome_condomino', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered z-index-0' },
    { key: 'valor', name: 'Valor', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right z-index-0' },
    { key: 'credito', name: 'Em Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right z-index-0' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide table-checkbox-column' },  // 'ASC', 'DESC'
  ];
  creditosListOrigConfig: Array<any> = [];
  creditosListOrig: Array<any> = [];
  creditosList: Array<any> = [];

  @ViewChild('pdfCreditos', { static: false }) pdfCreditosController;
  @ViewChild('pdfRecibos', { static: false }) pdfRecibosController;

  creditos = [];
  estadoCredito = null;

  recibos = [];
  
  lancamentosSubs = null;
  getLancamentos() {
    return new Promise(resolve => {
      if (this.lancamentosSubs) this.lancamentosSubs.unsubscribe();

      let startDate = (this.startDate) ? formatDate(this.startDate, this.format, this.locale) : 'NULL';
      let endDate = (this.endDate) ? formatDate(this.endDate, this.format, this.locale) : 'NULL';
      let req = [
        this.api.getCreditos(this.codCondominio, startDate, endDate),
        this.api.getRecibos(this.codCondominio, startDate, endDate),
      ];
      this.lancamentosSubs = forkJoin(req).subscribe(res => {
        // CREDITOS
        if (res[0].success) {
          this.creditosListOrig = res[0].data.map(el => {
            el['data'] = (el['data']) ? this.utils.getDate(el['data']) : null;
            el['valor'] = (el['valor']) ? Number(el['valor']) : null;
            el['credito'] = (el['credito']) ? Number(el['credito']) : null;
            el['checked'] = false;

            return el;
          }).sort((a, b) => { return a.nome_condomino.localeCompare(b.nome_condomino); });
          // FILTER FROM SELECTED
          this.filterLancamentosList('CREDITOS');
        }

        // RECIBOS
        if (res[1].success) {
          this.recibosListOrig = res[1].data.map(el => {
            el['data'] = (el['data']) ? this.utils.getDate(el['data']) : null;
            let auxDate = this.utils.getDate(el['criado_em']);
            if (auxDate.toString() === 'Invalid Date') {
              el['criado_em'] = new Date(el['criado_em']);
            } else {
              el['criado_em'] = auxDate;
            }
            el['valor'] = (el['valor']) ? Number(el['valor']) : null;
            el['checked'] = false;
  
            return el;
          }).sort((a, b) => { return a.nome_condomino.localeCompare(b.nome_condomino); });
          // FILTER FROM SELECTED
          this.filterLancamentosList('RECIBOS');
        }

        this.lancamentosSubs = null;
        resolve(true);
      }, err => { this.lancamentosSubs = null; resolve(false); });
    });
  }

  filterLancamentosList(target) {
    switch (target) {
      case 'CREDITOS':
        this.creditosList = [];
        this.proprietariosEmailList.filter(el => el.checked).forEach(el => {
          this.creditosList = this.creditosList.concat(this.creditosListOrig.filter(it => it.cod_pagador === el.cod_pagador));
        });

        if (this.estadoCredito === 'VALOR_EM_CREDITO') {
          this.creditosList = this.creditosList.filter(el => el.credito > 0);
        }
        break;
      case 'RECIBOS':
        this.recibosList = [];
        this.proprietariosEmailList.filter(el => el.checked).forEach(el => {
          this.recibosList = this.recibosList.concat(this.recibosListOrig.filter(it => it.cod_pagador === el.cod_pagador));
        });
        break;
    }
  }

  getCreditoDetails(creditoId) {
    return new Promise(resolve => {
      this.api.getCreditoDetails(creditoId).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          let creditoDetails = this.handleMoradas(res.data, 'CREDITO');
          resolve(creditoDetails);
        } else {
          resolve(false)
        }
      }, err => { resolve(false); });
    });
  }

  getRecibosDetails(reciboId) {
    return new Promise(resolve => {
      this.api.getRecibosDetails(reciboId).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          let reciboDetails = this.handleMoradas(res, 'RECIBO');
          resolve(reciboDetails);
        } else {
          resolve(false)
        }
      }, err => { resolve(false); });
    });
  }

  handleMoradas(item, target) {
    let condMoradaAux = null;
    let pagMoradaAux = null;

    switch (target) {
      case 'CREDITO':

        condMoradaAux = item.cond_morada;
        item.pag_morada_fact = this.utils.formatEmptyMoradaObject(item.pag_morada_fact);
        pagMoradaAux = (item.pag_morada_fact && item.pag_morada_fact !== '' && item.pag_morada_fact !== '{"address":null,"postalCode":null,"locality":null}') ? item.pag_morada_fact : item.pag_morada;
      
        // HANDLE OBJECT MORADA - CONDOMINIO
        try {
          let aux = '';
          let addressObj = JSON.parse(item.cond_morada);
          Object.keys(addressObj).forEach((key, i) => {
            if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
          });
      
          condMoradaAux = aux;
        } catch(e) {}
      
        // HANDLE OBJECT MORADA - CONDOMINIO
        try {
          let aux = '';
          let addressObj = JSON.parse(pagMoradaAux);
          Object.keys(addressObj).forEach((key, i) => {
            if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
          });
      
          pagMoradaAux = aux;
        } catch(e) {}
      
        item.cond_morada_pdf = (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [];
        item.pag_morada_fact_pdf = (pagMoradaAux) ? pagMoradaAux.split(/NEW_LINE/g) : [];

        item['type'] = 'CREDITO';

        break;
      case 'RECIBO':

        condMoradaAux = item.data.cond_morada;
        item.data.pag_morada_fact = this.utils.formatEmptyMoradaObject(item.data.pag_morada_fact);
        pagMoradaAux = (item.data.pag_morada_fact && item.data.pag_morada_fact !== '' && item.data.pag_morada_fact !== '{"address":null,"postalCode":null,"locality":null}') ? item.data.pag_morada_fact : item.data.pag_morada;

        // HANDLE OBJECT MORADA - CONDOMINIO
        try {
          let aux = '';
          let addressObj = JSON.parse(item.data.cond_morada);
          Object.keys(addressObj).forEach((key, i) => {
            if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
          });

          condMoradaAux = aux;
          item.data.cond_morada = (aux) ? aux.replace(/NEW_LINE/g, ', ') : null;
        } catch(e) {}

        // HANDLE OBJECT MORADA - CONDOMINIO
        try {
          let aux = '';
          let addressObj = JSON.parse(pagMoradaAux);
          Object.keys(addressObj).forEach((key, i) => {
            if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
          });

          pagMoradaAux = aux;
        } catch(e) {}

        item.data.cond_morada_pdf = (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [];
        item.data.pag_morada_fact_pdf = (pagMoradaAux) ? pagMoradaAux.split(/NEW_LINE/g) : [];

        item.data.cond_morada = (condMoradaAux) ? condMoradaAux.replace(/NEW_LINE/g, ', ') : null;
        item.data.pag_morada_fact = (pagMoradaAux) ? pagMoradaAux.replace(/NEW_LINE/g, ', ') : null;

        // CHECK IF DATE IS VALID
        if (item.data.criado_em) {
          let d = new Date(item.data.criado_em);

          if (d instanceof Date && !isNaN(d.getTime())) {
            item.data.criado_em = new Date(item.data.criado_em);
          } else {
            item.data.criado_em = null;
          }
        } else {
          item.data.criado_em = null;
        }

        item.data.data = (item.data.data) ? item.data.data : null;
        item.data.banco = (item.data.banco) ? this.getPaymentType(item.data.banco) : null;
        item.data.utilizador = item.data.first_name + ' ' + item.data.last_name;

        item.data['tipo_pagamento'] = null;
        if (item.data.conta) {
          item.data['tipo_pagamento'] = (item.data.conta === 'CAIXA') ? 'N' : 'T';
        }

        item.data['valorTotal'] = 0;
        let linhasRecibo = [];
        item.linhas_recibo.filter(el => ((el.n_aviso && el.data) || el.tipo_doc === 'C')).forEach(el => {
          let aux = {
            n_aviso: el.tipo_doc + ' - ' + el.n_aviso,
            data: (el.data) ? this.utils.getDate(el.data) : null,
            fraccao: (el.cod_fraccao && el.nome_fraccao) ? el.cod_fraccao + ' - ' + el.nome_fraccao : null,
            descricao: el.descricao,
            valor: (el.tipo_doc === 'C') ? -1 * Number(el.valor) : Number(el.valor),
          }
          linhasRecibo.push(aux);

          (el.tipo_doc === 'C') ? item.data.valorTotal -= Number(el.valor) : item.data.valorTotal += Number(el.valor);
        });
        item = item.data;
        item['linhasRecibo'] = linhasRecibo;

        item['type'] = 'RECIBO';

        break;
    }

    return item;
  }

  getPaymentType(banc) {
    if (banc === 'CAIXA') {
      return 'Numerário';
    } else {
      return 'Transferência';
    }
  }

  getEmailBodyReciboCredito() {
    let userAssinatura = null;
    if (this.assinaturaSelected) {
      userAssinatura = this.assinaturaOpts.find(el => el.value === this.assinaturaSelected);
    }

    let htmlEmail = '<div style="margin-bottom: 35px;">';

    this.editEmailsTabsDef.find(elem => elem.key === 'RECIBOS_CREDITOS').corpo.split('\n').forEach(line => {
      if (line) {
        htmlEmail += '<span>' + line + '</span>';
      } else {
        htmlEmail += '<span><br><br></span>';
      }
    });

    htmlEmail += '</div>';
    htmlEmail += (userAssinatura) ? this.utils.getEmailFooterV2(userAssinatura.name, userAssinatura.phone, userAssinatura.email) : this.utils.getEmailFooterV2();
    htmlEmail += '<img style="width: 30%; max-width: 200px; height: auto; margin-top: 10px;" src="' + this.appConfig.emailLogoUrl + '">'
    
    return htmlEmail;
  }

  wait(miliseconds) {
    return new Promise(resolve => {
      setTimeout(() => { resolve(true); }, miliseconds);
    });
  }

  // creditoDetails = null;
  creditoDetailsArr = [];
  nowCre = new Date();
  getEmailAttachCreditos(creditos) {
    return new Promise(async resolve => {
      if (creditos.length === 0) resolve(false);

      // let promisses = [];
      // for (let i = 0; i < creditos.length; i++) {

      //   this.creditoDetails = creditos[i];
      //   await this.wait(10);

      //   promisses.push(this.pdfCreditosController.export());
      // }

      // Promise.all(promisses).then(async groups => {
      //   const rootGroup = new Group({
      //       pdf: {
      //           multiPage: true
      //       }
      //   });
      //   groups.forEach((group) => {
      //       rootGroup.append(...group.children);
      //   });

      //   return exportPDF(rootGroup, { paperSize: 'A4' });
      // }).then(async dataUri => {
      //   let base64  = dataUri.replace('data:application/pdf;base64,', '');
      //   base64  = base64.replace(' ', '+');
      //   resolve(base64);
      // });

      this.creditoDetailsArr = [];
      creditos.forEach(el => {
        el.data = this.utils.getDate(el.data);

        this.creditoDetailsArr.push(el);
      });

      setTimeout(() => {
        this.pdfCreditosController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {

          let base64  = dataUri.replace('data:application/pdf;base64,', '');
          base64  = base64.replace(' ', '+');
          resolve(base64);
        });
      }, 1);

    });
  }

  // reciboDetails = null;
  reciboDetailsArr = [];
  getEmailAttachRecibos(recibos) {
    return new Promise(async resolve => {
      if (recibos.length === 0) resolve(false);

      // recibos.forEach(el => el.data = this.utils.getDate(el.data));

      // let promisses = [];
      // for (let i = 0; i < recibos.length; i++) {
      //   this.reciboDetails = recibos[i];

      //   await this.wait(10);

      //   promisses.push(this.pdfRecibosController.export());
      // }

      // Promise.all(promisses).then(async groups => {
      //   const rootGroup = new Group({
      //       pdf: {
      //           multiPage: true
      //       }
      //   });
      //   groups.forEach((group) => {
      //       rootGroup.append(...group.children);
      //   });

      //   return exportPDF(rootGroup, { paperSize: 'A4' });
      // }).then(async dataUri => {
      //   let base64  = dataUri.replace('data:application/pdf;base64,', '');
      //   base64  = base64.replace(' ', '+');
      //   resolve(base64);
      // });

      this.reciboDetailsArr = [];
      recibos.forEach(el => {
        el.data = this.utils.getDate(el.data);

        this.reciboDetailsArr.push(el);
      });

      setTimeout(() => {
        this.pdfRecibosController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {

          let base64  = dataUri.replace('data:application/pdf;base64,', '');
          base64  = base64.replace(' ', '+');
          resolve(base64);
        });
      }, 1);

    });
  }
  // END - LANCAMENTOS --------------------------------------------------------

  sendSubmited = false;
  async sendEmails() {
    // INPUT VALIDATORS
    let inputAux = null;
    this.sendSubmited = true;
    switch (this.target) {
      case 'CONTA_CORRENTE':
        inputAux = this.editEmailsTabsDef.find(el => (el.target === 'CONTA_CORRENTE' && el.selected && (!el.assunto || !el.corpo)));
        if (inputAux) {
          this.editEmails = true;
          this.editEmailsMode = true;
          this.toastr.error('Email sem assunto e/ou corpo definido.', 'Alerta', { timeOut: 4000 });
          return;
        }
      case 'LANCAMENTOS':
        inputAux = this.editEmailsTabsDef.find(el => (el.target === 'LANCAMENTOS' && el.selected && (!el.assunto || !el.corpo)));
        if (inputAux) {
          this.editEmails = true;
          this.editEmailsMode = true;
          this.toastr.error('Email sem assunto e/ou corpo definido.', 'Alerta', { timeOut: 4000 });
          return;
        }
    }

    this.emailsToSend = [];
    this.loadingModal = true;

    this.toastr.info('A informação para envio por email está a ser carregada.', 'Por favor aguarde...', { timeOut: 0, extendedTimeOut: 0 });

    let fromEmail = (this.remetenteSelected) ? this.remetentesOpts.find(el => el.value === this.remetenteSelected).email : this.appConfig.company.email;
    let fromName = (this.remetenteSelected) ? this.remetentesOpts.find(el => el.value === this.remetenteSelected).name : this.appConfig.company.emailFromName;

    if (this.target === 'CONTA_CORRENTE') {
      this.titleCC = 'Conta Corrente';

      if (this.ccType === 'condominos') {
        // GET CONTAS CORRENTES - FRACCOES
        let res: any = await this.getContasCorrentes();
        if (res) {
          let cc = this.getContaCorrenteCondomino(res);
          let base64 = await this.getEmailAttachContaCorrente(cc);

          this.emailsCondomino.forEach(it => {
            this.emailsToSend.push({
              type: 'CONTA_CORRENTE',
              from: fromEmail,
              to: it,
              subject: this.editEmailsTabsDef.find(elem => elem.key === 'CONTA_CORRENTE').assunto,
              body: this.getEmailBodyContaCorrente(),
              attachment: base64,
              filename: 'Conta Corrente ' + ((this.startDate) ? formatDate(this.startDate, this.format, this.locale) + '-' : '') + formatDate(this.endDate, this.format, this.locale) + '.pdf',
              fromName: fromName,
              toName: this.nomeCondomino,
              fraccao_descricao: '',
            }); 
          });
        }
      }

      if (this.ccType === 'fraccoes') {
        // GET CONTAS CORRENTES - FRACCOES
        let res: any = await this.getContasCorrentes();
        if (res) {
          let auxFraccoes = this.fraccoesEmailList.filter(el => el.checked);
          for (let i = 0; i < auxFraccoes.length; i++) {
            let fraccao = res.find(el => el.cod_fraccao === auxFraccoes[i].fraccao_cod);
            if (fraccao) {
              let cc = this.getContaCorrente(res.filter(el => el.cod_fraccao === fraccao.cod_fraccao));
              let base64 = await this.getEmailAttachContaCorrente(cc);

              auxFraccoes[i].emails.forEach(it => {
                this.emailsToSend.push({
                  type: 'CONTA_CORRENTE',
                  from: fromEmail,
                  to: it,
                  subject: this.editEmailsTabsDef.find(elem => elem.key === 'CONTA_CORRENTE').assunto,
                  body: this.getEmailBodyContaCorrente(),
                  attachment: base64,
                  filename: 'Conta Corrente ' + ((this.startDate) ? formatDate(this.startDate, this.format, this.locale) + '-' : '') + formatDate(this.endDate, this.format, this.locale) + '.pdf',
                  fromName: fromName,
                  toName: auxFraccoes[i].proprietario,
                  fraccao_descricao: auxFraccoes[i].fraccao_descricao,
                }); 
              });
            }
          }
        }
      }
    }

    if (this.target === 'LANCAMENTOS') {
      // RECIBOS
      let recibosToSend = this.recibosList.filter(el => el.checked);
      if (recibosToSend.length > 0) {
        let aux = null;
        this.recibos = [];
        for (let i = 0; i < recibosToSend.length; i++) {
          aux = await this.getRecibosDetails(recibosToSend[i].id);
          if (aux) { this.recibos.push(aux); }
        }
      }

      // CREDITOS
      let creditosToSend = this.creditosList.filter(el => el.checked);
      if (creditosToSend.length > 0) {
        let aux = null;
        this.creditos = [];
        for (let i = 0; i < creditosToSend.length; i++) {
          aux = await this.getCreditoDetails(creditosToSend[i].id);
          if (aux) { this.creditos.push(aux); }
        }
      }

      let allDocs = this.recibos.concat(this.creditos);

      // PREPARE EMAILS TO SEND
      let proprietarios = [ ...new Set(allDocs.map(item => item.cod_pagador)) ];
      for (let i = 0; i < proprietarios.length; i++) {
        let propAux = this.proprietariosEmailList.find(el => el.cod_pagador === proprietarios[i]);
        if (propAux && Array.isArray(propAux.emails) && propAux.emails.length > 0) {

          let attachment = [];
          
          let base64Creditos = await this.getEmailAttachCreditos(allDocs.filter(el => el.cod_pagador === proprietarios[i] && el.type === 'CREDITO'));
          let filenameCreditos = 'Créditos ' + ((this.startDate) ? formatDate(this.startDate, this.format, this.locale) + '-' : '') + formatDate(this.endDate, this.format, this.locale) + '.pdf';
          if (base64Creditos) {
            attachment.push({
              filename: filenameCreditos,
              base64: base64Creditos,
              ext: 'application/pdf',
            });
          }

          let base64Recibos = await this.getEmailAttachRecibos(allDocs.filter(el => el.cod_pagador === proprietarios[i] && el.type === 'RECIBO'));
          let filenameRecibos = 'Recibos ' + ((this.startDate) ? formatDate(this.startDate, this.format, this.locale) + '-' : '') + formatDate(this.endDate, this.format, this.locale) + '.pdf';
          if (base64Recibos) {
            attachment.push({
              filename: filenameRecibos,
              base64: base64Recibos,
              ext: 'application/pdf',
            });
          }

          propAux.emails.forEach(it => {
            this.emailsToSend.push({
              type: 'RECIBOS_CREDITOS',
              from: fromEmail,
              to: it,
              subject: this.editEmailsTabsDef.find(elem => elem.key === 'RECIBOS_CREDITOS').assunto,
              body: this.getEmailBodyReciboCredito(),
              fromName: fromName,
              toName: propAux.proprietario,
              attachment: (attachment) ? JSON.stringify(attachment) : null,
            }); 
          });
        }
      }
    }

    // SEND EMAILS
    this.api.sendEmailV2(this.emailsToSend).subscribe(res => {
      this.toastr.success(this.emailsToSend.length + ' emails foram enviados com sucesso', 'Email Enviado', { timeOut: 4000 });

      let descricao = null;
      switch (this.target) {
        case 'CONTA_CORRENTE':
          if (this.ccType === 'fraccoes') {
            let emailsCC = this.emailsToSend.filter(el => el.type === 'CONTA_CORRENTE').map(el => {
              delete el.attachment;
              delete el.body;
      
              return el;
            });
            descricao = `Condomínio: ${this.codCondominio} - ${this.nomeCondominio}` + ((this.startDate) ? `, Data de início:  ${formatDate(this.startDate, this.format, this.locale)}` : '') + ((this.endDate) ? `, Data de fim:  ${formatDate(this.endDate, this.format, this.locale)}` : '');
            if (emailsCC.length > 0) this.api.saveRegistoActividade(this.codCondominio, 'CONTA_CORRENTE_EMAILS', null, 'Conta Corrente Enviada', descricao, emailsCC).subscribe(res => {}, err => { });  
          }

          if (this.ccType === 'condominos') {
            let emailsCC = this.emailsToSend.filter(el => el.type === 'CONTA_CORRENTE').map(el => {
              delete el.attachment;
              delete el.body;
      
              return el;
            });
            descricao = `Condómino: ${this.codCondomino} - ${this.nomeCondomino}` + ((this.startDate) ? `, Data de início:  ${formatDate(this.startDate, this.format, this.locale)}` : '') + ((this.endDate) ? `, Data de fim:  ${formatDate(this.endDate, this.format, this.locale)}` : '');
            if (emailsCC.length > 0) this.api.saveRegistoActividade(null, 'CONTA_CORRENTE_CONDOMINO_EMAILS', null, 'Conta Corrente Enviada', descricao, emailsCC).subscribe(res => {}, err => { });  
          }
          break;
      }

      if (this.emailSelectionModalRef) {
        this.emailSelectionModalRef.approve();
        this.sendSubmited = false;
      }

      this.loadingModal = false;
    }, err => {
      this.loadingModal = false;
      this.toastr.error('Não foi posível enviar os emails selecionados.', 'Alerta', { timeOut: 4000 });
    });
  }

  cancelBtn(close=false) {
    if (this.emailSelectionModalRef && close) this.emailSelectionModalRef.deny();

    if (this.target === 'LANCAMENTOS') {
      if (this.selectDocs) {
        if (this.editEmailsMode) {
          this.editEmailsMode = false;
          this.selectDocs = true;
        } else {
          this.selectDocs = !this.selectDocs;
        }
      } else {
        if (this.emailSelectionModalRef) this.emailSelectionModalRef.deny();
      }
    } else {
      if (this.editEmailsMode && !close) {  // VOLTAR
        this.editEmailsMode = false;
      } else {
        if (this.loadingModal) {
          this.toastr.info('A informação para envio por email está a ser carregada.', 'Por favor aguarde...', { timeOut: 0, extendedTimeOut: 0 });
          return;
        } else if (this.emailSelectionModalRef) {
          this.emailSelectionModalRef.deny();
        }
      }
    }
  }

  checkLancamentosSelected() {
    this.hasAnexoSelected = (this.creditosList.find(it => it.checked) || this.recibosList.find(it => it.checked));
  }

  submitBtn() {
    if (this.loadingModal) return;

    if (this.target === 'LANCAMENTOS') {
      if (!this.editEmailsMode && !this.selectDocs) {
        // TODO: FILTER LISTA DE DOCUMENTOS
        this.filterLancamentosList('RECIBOS');
        this.filterLancamentosList('CREDITOS');

        this.hasAnexoSelected = (this.creditosList.find(it => it.checked) || this.recibosList.find(it => it.checked));
        this.selectDocs = true;
      } else {
        if (!(this.editEmailsMode || !this.editEmails)) {
          this.editEmailsMode = true;
        } else {
          if (this.editEmailsMode || !this.editEmails) {
            this.sendEmails();
          }
        }
      }
    } else {
      if (this.editEmailsMode || !this.editEmails) {
        this.sendEmails();
      } else {
        this.editEmailsMode = true;
      }
    }
  }

}
