import { Component, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { AppConfigService } from '../app-config.service';
import { ProcessamentosService } from '../business-logic-services/processamentos.service';
import { IContext } from '../business-model-interfaces/application';
import { ProcessamentoDetailsSimple } from '../business-model-interfaces/processamentos';

interface CopyProc {
  data: Date,
  descricao: string,
  valor: number,
}

@Component({
  selector: 'app-processamento-duplication-modal',
  templateUrl: './processamento-duplication-modal.component.html',
  styleUrls: ['./processamento-duplication-modal.component.scss']
})
export class ProcessamentoDuplicationModalComponent implements OnInit {

  similarList: Array<ProcessamentoDetailsSimple> = [];
  copyProc: CopyProc = null;

  constructor(
    public appConfig: AppConfigService,
    public processamentos: ProcessamentosService,
    public modalService: SuiModalService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalConfig = new TemplateModalConfig<IContext, string, string>(this.alertRef);
    this.modalConfig.closeResult = "closed";
    this.modalConfig.size = 'normal';
    this.modalConfig.transition = 'fade';
    this.modalConfig.transitionDuration = 250;
  }

  @ViewChild('alertRef', { static: false }) alertRef;
  modalRef = null;
  modalConfig: any = null;


  open(input: { tipo_proc: string, cod_condominio: number, valor: number, data_inicio: Date, data_fim: Date, descricao: string } | { id_processamento: string }): Promise<boolean> {
    return new Promise(async (resolve) => {

      let success = await this.fetchData(input);
      if (!success) {
        resolve(false);
        return;
      }

      if (this.similarList.length == 0) {
        resolve(true);
        return;
      }


      this.modalRef = this.modalService
        .open(this.modalConfig)
        .onApprove(() => {
          resolve(true)
        })
        .onDeny(() => {
          resolve(false)
        });

    });
  }

  fetchData(input: { tipo_proc: string, cod_condominio: number, valor: number, data_inicio: Date, data_fim: Date, descricao: string } | { id_processamento: string }): Promise<boolean> {
    return new Promise(async (resolve) => {

      let req: any = [
        this.processamentos.hasSimilar(input),
      ];

      if ('id_processamento' in input) {
        req.push(this.processamentos.getProcessamento(input.id_processamento));
      }

      this.similarList = [];
      this.copyProc = {
        data: null,
        descricao: null,
        valor: null,
      }

      Promise.all(req).then(resArr => {
        this.similarList = resArr[0] as Array<ProcessamentoDetailsSimple>;

        if (resArr.length > 1) {
          this.copyProc = resArr[1] as ProcessamentoDetailsSimple;
        } else if ('data_inicio' in input) {
          this.copyProc = {
            data: input.data_inicio,
            descricao: input.descricao,
            valor: input.valor,
          }
        }
        resolve(true);
      }).catch(err => {
        resolve(false);
      });
    });
  }

}
