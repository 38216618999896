import { ToastrService } from "ngx-toastr";
import { ApiService } from "../api.service";
import { AppConfigService } from "../app-config.service";
import { UserSessionService } from "../user-session.service";
import { UtilitiesService } from "../utilities.service";

export interface Orcamento {
    id:string,
    cod:string,
    cod_condominio:string,
    id_act_seg:string,
    descricao:string,
    periodo:string,
    vap_prop:string,
    val_equip:string,
    val_fr_prop:string,
    val_fr_equit:string,
    dt_inicio:string,
    dt_fim:string,
    val_lancado:'0'|'1',
    meses_orc:string,
    meses_fr:string,
    data_lancamento:string|Date,
    user_lancamento:string,
    active:string,
}

// export interface OrcamentoDetailed {
//     id:string,
//     cod:string,
//     cod_condominio:string,
//     id_act_seg:string,
//     descricao:string,
//     periodo:string,
//     vap_prop:string,
//     val_equip:string,
//     val_fr_prop:string,
//     val_fr_equit:string,
//     dt_inicio:string,
//     dt_fim:string,
//     val_lancado:'0'|'1',
//     meses_orc:string,
//     meses_fr:string,
//     data_lancamento:string,
//     user_lancamento:string,
//     active:string,
//     orcamentoTotais:{
//         permilagem: number,
//         orc_valor: number,
//         fr_valor: number,
//         valor: number,
//     },
//     selectedTotalFR:number,
//     cobrancaFR:Array<{sel:boolean}>,
//     simulacaoTotais:{
//         permilagem: number,
//         orcamento: number,
//         fundoReserva: number,
//         seguro: number,
//         total: number,
//     },
//     segMonths: Array<{month:string, active:boolean}>,
//     simulacaoList,
//     fraccoes,
//     orcamentoObj,
//     orcamentoDataTable,
//     rubricasList,
//     zonas,
//     orcRubricas,
// }

interface orcamento_Simulacao {
    id:string,
    fraccao_id:string,
    cod:string,
    cod_zona:string,
    cod_proprietario:string,
    nome:string,
    zona_nome:string,
    permilagem:number,
    quota_fr:number,
    quota_orc:number,
    quota_seg:number,
    total:number,
    quota_fr_anual:number,
    quota_orc_anual:number,
    quota_seg_anual:number,
}

interface orcamento_orcamentoDetailed extends Orcamento {
    nome_condominio: string,
}

interface orcamento_Zona {
    checked:boolean,
    nome:string,
    cod:string,
    cod_condominio
    cod_orcamento
    cod_zona
    orc_zona_id
    meses_fr
    meses_orc
    n_fraccoes
    pagamento_fr
    perm_seg
    permilagem
    permilagem_aux_1
    permilagem_aux_2
    reparticao_fr
    saldo_inicial
    saldo_inicial_fr
    valor_fr
}
interface orcamento_Fraccao {
    paga_seg_colect
    id
    cod
    nome
    zona_cod
    zona_nome
    permilagem
    quota_orc
    quota_fr
    quota_seg
    cod_proprietario
}
interface orcamento_Rubrica {
    zona_nome
    zona_cod
    zona_id
    despesa
    receita
    rub_nome
    rub_cod
    rub_id
    id
    cod
    cod_orcamento
    cod_zona
    cod_rubrica
    cod_sub_rubrica
    valor
    pagamento
    reparticao
    tipo
}


export class OrcamentoDetailed {
    id:string;
    orcamento:orcamento_orcamentoDetailed;
    orcamentoTotais = {
        permilagem: 0,
        orc_valor: 0,
        fr_valor: 0,
        valor: 0,
    };
    selectedTotalFR:number = null;
    cobrancaFR = [{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true }];
    cobrancaOrcamento = [{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true },{ sel: true }];
    simulacaoTotais = {
        permilagem: 0,
        orcamento: 0,
        fundoReserva:0,
        seguro: 0,
        total: 0,
    };
    segMonths = [{ month: 'janeiro', active: false },{ month: 'fevereiro', active: false },{ month: 'marco', active: false },{ month: 'abril', active: false },{ month: 'maio', active: false },{ month: 'junho', active: false }, { month: 'julho', active: false }, { month: 'agosto', active: false },{ month: 'setembro', active: false },{ month: 'outubro', active: false },{ month: 'novembro', active: false },{ month: 'dezembro', active: false }];
    simulacaoList:Array<any> = [];
    fraccoes:Array<orcamento_Fraccao> = [];
    orcamentoObj = null;
    orcamentoDataTable:Array<{
        cod
        nome
        perm
        fr_reparticao
        fr_valor
        orc_reparticao
        orc_valor
        valor
    }> = [];
    rubricasList:Array<{
        id
        cod
        nome
        reparticao
        tipo
        cod_rubrica
        valor
        checked
        centered
        total
        despesa
        receita
    }> = [];
    zonas:Array<orcamento_Zona> = [];
    orcRubricas:Array<orcamento_Rubrica> = [];
    orcFraccoesSeg = [];
    rubricasListCol:Array<{
        key:string,
        name:string,
        type:string,
        sort:string,
        searchable:boolean,
        data:orcamento_Zona,
    }> = [];
    fraccoesListCol: Array<{
        key:string,
        name:string,
        type:string,
        sort:string,
        searchable:boolean,
        data:orcamento_Fraccao,
    }> = [];
    rubricasTotais = {
        total:0,
    };
    premioSeg = 0;
    premilagemSeg = 0;
    reparticaoSeg: 'P'|'E' = null;


    constructor(
        id:string,
        public api: ApiService, 
        public appConfig: AppConfigService,
        public utils: UtilitiesService,
        public userSession: UserSessionService,
        public toastr: ToastrService) {
            this.id = id;
    }

    initialize(): Promise<boolean> {
        return new Promise(async (resolve) => {
            this.api.getOrcamentoDetailsREVIEW(this.id).subscribe(async res => {
                if (res.hasOwnProperty('success') && res.success) {
                    // COMPUTE QUOTA SEGURO --------------------------------
                    if (this.orcFraccoesSeg.length === 0) {
                        if (res.data.orcamento[0].id_act_seg) {
                            this.api.getActSegORC(res.data.orcamento[0].id_act_seg).subscribe(async seg => {
                                if (seg.hasOwnProperty('success') && seg.success) {

                                    this.premioSeg = Number(seg.data.premio);
                                    this.reparticaoSeg = seg.data.pagamento;
                                    this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });

                                    this.premilagemSeg = 0;
                                    this.orcFraccoesSeg = [];
                                    res.data.fraccoes_fr.forEach(el => {
                                    if (el.paga_seg_colect === '1') this.premilagemSeg = this.premilagemSeg + Number(el.permilagem);

                                    this.orcFraccoesSeg.push({
                                        cod_fraccao: el.cod,
                                        paga_seg_colect: el.paga_seg_colect,
                                        permilagem: Number(el.permilagem),
                                        quota_seg_anual: 0,
                                    })
                                    });

                                    if (this.reparticaoSeg === 'P') {
                                    this.orcFraccoesSeg.forEach(el => {
                                        if (el.paga_seg_colect === '1') el.quota_seg_anual = this.utils.cleanDecimalDigits((this.premioSeg * el.permilagem) / this.premilagemSeg);
                                    });
                                    }

                                    if (this.reparticaoSeg === 'E') {
                                    let nFraccoes = this.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
                                    this.orcFraccoesSeg.forEach(el => {
                                        if (el.paga_seg_colect === '1') el.quota_seg_anual = this.utils.cleanDecimalDigits(this.premioSeg / nFraccoes);
                                    });
                                    }
                                    
                                    await this.handleOrcDetailsObjFromApi(res);
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            }, err => {resolve(false);});
                        } else {
                            if (res.data.orcamento[0].val_lancado === '0') {
                            this.api.getActSegCond(res.data.orcamento[0].cod_condominio).subscribe(async seg => {
                                if (seg.hasOwnProperty('success') && seg.success) {
                                    this.premioSeg = Number(seg.data.premio);
                                    this.reparticaoSeg = seg.data.pagamento;
                                    this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });
                        
                                    this.premilagemSeg = 0;
                                    this.orcFraccoesSeg = [];
                                    res.data.fraccoes_fr.forEach(el => {
                                        if (el.paga_seg_colect === '1') this.premilagemSeg = this.premilagemSeg + Number(el.permilagem);
                        
                                        this.orcFraccoesSeg.push({
                                        cod_fraccao: el.cod,
                                        paga_seg_colect: el.paga_seg_colect,
                                        permilagem: Number(el.permilagem),
                                        quota_seg_anual: 0,
                                        })
                                    });
                        
                                    if (this.reparticaoSeg === 'P') {
                                        this.orcFraccoesSeg.forEach(el => {
                                        if (el.paga_seg_colect === '1') el.quota_seg_anual = this.utils.cleanDecimalDigits((this.premioSeg * el.permilagem) / this.premilagemSeg);
                                        });
                                    }
                        
                                    if (this.reparticaoSeg === 'E') {
                                        let nFraccoes = this.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
                                        this.orcFraccoesSeg.forEach(el => {
                                        if (el.paga_seg_colect === '1') el.quota_seg_anual = this.utils.cleanDecimalDigits(this.premioSeg / nFraccoes);
                                        });
                                    }
                                    await this.handleOrcDetailsObjFromApi(res);
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            }, err => {resolve(false);});
                            } else {
                                await this.handleOrcDetailsObjFromApi(res);
                                resolve(true);
                            }
                        }
                    } else {
                        await this.handleOrcDetailsObjFromApi(res);
                        resolve(true);
                    }
                } else {
                    resolve(false);
                }
            }, err => {
                resolve(false);
            });
        })
    }

    async handleOrcDetailsObjFromApi(res):Promise<boolean> {
        return new Promise(async (resolve) => {
            await this.setInitRubricasList(res.data.orcamento[0].cod_condominio);
          
            if (this.id && this.fraccoes.length === 0) {
                await this.setInitFraccoesList(res.data.orcamento[0].cod_condominio);
            }
        
            this.orcRubricas = res.data.rubricas;
            this.orcamento = (Array.isArray(res.data.orcamento)) ? res.data.orcamento[0] : null;
        
            if (this.orcamento.data_lancamento) {
                this.orcamento.data_lancamento = this.utils.getDate(this.orcamento.data_lancamento);
            }
        
            let auxRubricas = [];
            this.orcRubricas.forEach(el => {
            if (!auxRubricas.find(it => el.rub_cod === it.rub_cod)) auxRubricas.push(el); 
            });
        
            this.rubricasList = [];
            auxRubricas.forEach(el => {
            let newRubrica = {
                id: (el.hasOwnProperty('id') && el.id) ? el.id : null,
                cod: el.cod,
                nome: el.rub_nome,
                reparticao: (el.reparticao === 'P' || el.reparticao === 'E') ? el.reparticao : 'M',
                tipo: (el.tipo) ? el.tipo : null,
                cod_rubrica: el.cod_rubrica,
                valor: 0,
                checked: false,
                centered: true,
                total: 0,
        
                despesa: (el.despesa === '1'),
                receita: (el.receita === '1'),
            };
        
            this.zonas.forEach((el, i) => {
                newRubrica['valor-zona-' + i] = 0;
                newRubrica['cod-zona-' + i] = el.cod;
                newRubrica['perm-zona-' + i] = el.permilagem;
            });
        
            let aux = this.orcRubricas.filter(it => it.rub_cod === el.rub_cod);
            aux.forEach(it => {
                if (it) {
                for (let i = 0; i < this.zonas.length ; i++) {
                    if (it.cod_zona === newRubrica['cod-zona-' + i]) {
                    newRubrica['valor-zona-' + i] = Number(it.valor);
                    break;
                    }
                }
                newRubrica.valor += Number(it.valor);
                }
            });
        
            this.rubricasList.push(newRubrica);
            });
        
            this.computeRubricasTotal();
        
            // ORCAMENTO TABLE SECTION ----------------------------------------------
            if (Array.isArray(res.data.orcamento) && res.data.orcamento.length > 0) {
        
            this.orcamentoObj = res.data;
        
            // ALWAYS USE THE FIRST ZONE PERIODICITY (TODO - ADD THIS TO ORCAMENTO)
            if (this.orcamentoObj.zonas.length > 0) {
                this.cobrancaOrcamento = Array.prototype.map.call(this.orcamentoObj.zonas[0].meses_orc, (el) => { return { sel: (el === 'S') } });
                this.cobrancaFR = Array.prototype.map.call(this.orcamentoObj.zonas[0].meses_fr, (el) => { return { sel: (el === 'S') } });
            }
        
            this.getOrcamentoDatatable(this.orcamentoObj.zonas, this.orcamentoObj.fraccoes, this.orcamentoObj.fraccoes_fr);
            }
            resolve(true);
        });
    }

    getOrcamentoDatatable(zonas, fraccoes, fraccoes_fr) {
        // SET ORCAMENTO DATA TABLE
        this.orcamentoDataTable = [];

        zonas.forEach((zona, i) => {
            // GET REPARTICAO INDICATION STRING
            let seenRep = '';
            this.rubricasList.forEach(rubrica => {
            if (seenRep.indexOf(rubrica.reparticao) === -1 && rubrica['valor-zona-' + i] !== 0) {
                // ADD RUBRICA TO SEEN STRING
                seenRep += (rubrica.reparticao + '/');
            }
            });

            if (seenRep) seenRep = seenRep.slice(0, -1);

            // HANDLE ORCAMENTO TABLE
            let auxObj = {
                cod: zona.cod,
                nome: zona.nome,
                perm: Number(zona.permilagem),
                fr_reparticao: zona.pagamento_fr,
                fr_valor: 0,
                orc_reparticao: seenRep,
                orc_valor: 0,
                valor: null,
            };

            // fraccoes.filter(el => (el.cod_zona === zona.cod)).forEach(fraccao => {
            //   auxObj.orc_valor += Number(fraccao.valor);
            // });

            auxObj.orc_valor += this.rubricasTotais['total-zona-' + i];
          
            fraccoes_fr.filter(el => (el.cod_zona === zona.cod)).forEach(fraccao => {
                auxObj.fr_valor += Number(fraccao.valor);
            });

            auxObj.valor = auxObj.orc_valor + auxObj.fr_valor;

            this.orcamentoDataTable.push(auxObj);
        });

        this.computeOrcamentoTotal();

        this.getSimulacaoDatatable();
    }

    computeOrcamentoTotal() {
        this.orcamentoTotais.permilagem = 0;
        this.orcamentoTotais.orc_valor = 0;
        this.orcamentoTotais.fr_valor = 0;
        this.orcamentoTotais.valor = 0;

        this.orcamentoDataTable.forEach(el => {
            this.orcamentoTotais.permilagem += Number(el.perm);
            this.orcamentoTotais.orc_valor += Number(el.orc_valor);
            this.orcamentoTotais.fr_valor += Number(el.fr_valor);
            this.orcamentoTotais.valor += Number(el.valor);
        });
        let FCR = this.utils.cleanDecimalDigits(((this.orcamentoTotais.valor / this.orcamentoTotais.orc_valor) - 1) * 100);

        if (FCR) this.selectedTotalFR = FCR;
    }

    getSimulacaoDatatable() {
        this.simulacaoList = this.getSimObject();
    
        this.computeSimulacaoTotal();
    }

    computeSimulacaoTotal() {
        this.simulacaoTotais.permilagem = 0;
        this.simulacaoTotais.orcamento = 0;
        this.simulacaoTotais.fundoReserva = 0;
        this.simulacaoTotais.seguro = 0;
        this.simulacaoTotais.total = 0;
    
        this.simulacaoList.forEach(fraccao => {
          this.simulacaoTotais.permilagem += fraccao.permilagem;
          this.simulacaoTotais.fundoReserva += fraccao.quota_fr;
          this.simulacaoTotais.orcamento += fraccao.quota_orc;
          this.simulacaoTotais.seguro += fraccao.quota_seg;
          this.simulacaoTotais.total += fraccao.total;
        });
    }

    computeRubricasTotal() {
        this.rubricasTotais.total = 0;
        this.rubricasListCol.filter(el => (el.data !== null)).forEach((it, i) => {
          this.rubricasTotais['total-zona-' + i] = 0;
          this.rubricasList.forEach(el => {
            this.rubricasTotais['total-zona-' + i] += Number(el['valor-zona-' + i]);
          });
          this.rubricasTotais.total += Number(this.rubricasTotais['total-zona-' + i]);
        });
    }

    async setInitRubricasList(codCondominio) {
        return new Promise((resolve, reject) => {
    
          this.api.getCondZonasDetails(codCondominio).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {
              // CREATE RUBRICAS TABLE COLUMN OBJECT
              this.zonas = res.data.sort((a, b) => {
                if (Number(a.permilagem) < Number(b.permilagem)) {
                  return 1;
                } else {
                  return -1;
                }
              });
      
              this.rubricasListCol = [];
              this.zonas.forEach(el => {
                el['checked'] = true;
      
                this.rubricasListCol.push({
                  key: 'null',
                  name: el.nome,
                  type: 'number',
                  sort: null,
                  searchable: false,
                  data: el
                });
              });
      
              resolve(true);
            } else {
              resolve(false);
            }
          }, err => {
            resolve(false);
          });
    
        });
    }
    async setInitFraccoesList(codCondominio) {
        return new Promise((resolve, reject) => {
            this.api.getOrcCondFraccoesDetails(codCondominio).subscribe(res => {
                if (res.hasOwnProperty('success') && res.success) {
                    this.fraccoes = res.data;
            
                    this.fraccoesListCol = [];
                    this.fraccoes.forEach(el => {
                    this.fraccoesListCol.push({
                        key: 'null',
                        name: el.nome,
                        type: 'number',
                        sort: null,
                        searchable: false,
                        data: el
                    });
                    });

                    resolve(true);
                } else {
                    this.utils.apiErrorMsg(res);
                    resolve(false);
                }
            }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            resolve(false);
            });

        });
    }

    getSimObject():Array<orcamento_Simulacao> {
        let simulation:Array<orcamento_Simulacao> = [];
        let seenRubricas = '';
        this.orcamentoObj.fraccoes.sort((a, b) => { if ( a.cod !== null  && b.cod !== null && (a.cod > b.cod || a.cod.length > b.cod.length) ) { return 1; } else { return -1; } }).forEach(fraccao => {
    
    
          if (seenRubricas.indexOf(fraccao.cod) === -1) {
            seenRubricas += fraccao.cod + '/';
    
            let fraccaoFR = this.orcamentoObj.fraccoes_fr.find(el => (el.cod_fraccao === fraccao.cod_fraccao));
    
            let zona = this.zonas.find(el => (el.cod === fraccao.cod_zona));
    
            simulation.push({
                id: fraccao.id,
                fraccao_id: fraccao.fraccao_id,
                cod: fraccao.cod,
                cod_zona: fraccao.cod_zona,
                cod_proprietario: fraccao.cod_proprietario,
                nome: fraccao.nome,
                zona_nome: (zona) ? zona.nome : null,
                permilagem: Number(fraccao.permilagem),

                quota_fr: (fraccaoFR) ? Number(fraccaoFR['valor']) : 0,
                quota_orc: Number(fraccao.valor),
                quota_seg: 0,
                total: 0,
                quota_fr_anual: 0,
                quota_orc_anual: 0,
                quota_seg_anual: 0,
            });
          } else {
            let index = simulation.findIndex(it => (it.fraccao_id === fraccao.fraccao_id));
            if (index >= 0) {
              simulation[index].quota_orc += Number(fraccao.valor);
              simulation[index].total += Number(fraccao.valor);
            }
          }
        });
    
        simulation.forEach(el => {
          el.quota_seg = (this.orcFraccoesSeg.find(it => (it.cod_fraccao === el.cod && it.paga_seg_colect === '1'))) ? this.orcFraccoesSeg.find(it => (it.cod_fraccao === el.cod && it.paga_seg_colect === '1')).quota_seg_anual : 0;
          el.total = this.utils.cleanDecimalDigits(el.quota_orc + el.quota_fr + el.quota_seg);
          el.quota_fr = this.utils.cleanDecimalDigits(el.quota_fr);
          el.quota_orc = this.utils.cleanDecimalDigits(el.quota_orc);
          el.quota_fr_anual = el.quota_fr;
          el.quota_orc_anual = el.quota_orc;
          el.quota_seg_anual = el.quota_seg;
        });
    
        // CHECK IF ALL FRACCOES ARE AVAILABLE
        let hasAllFraccoes = true;
        for (let i = 0; i < this.orcamentoObj.fraccoes_fr.length; i++) {
          if (!this.orcamentoObj.fraccoes.find(el => (el.cod === this.orcamentoObj.fraccoes_fr[i].cod_fraccao))) {
            hasAllFraccoes = false;
            break;
          } 
        }
    
    
        return simulation.sort((a, b) => {
          if ( a.cod !== null && b.cod !== null && (a.cod > b.cod || a.cod.length > b.cod.length) ) {
            return 1;
          } else {
            return -1;
          }
        });
    }

    lancarValores(): Promise<boolean> {
        return new Promise((resolve) => {

        
        // if (this.orcamentoTotais.orc_valor && this.orcamentoTotais.fr_valor) {
        //     let frRes = null;

        //     if (this.selectedTotalFR < 10 || this.orcamentoTotais.orc_valor * 0.1 > this.orcamentoTotais.fr_valor + 0.1) { // [TODO]: REMOVE MARTELO 0.1 -> 0.01
        //       // FR LESS THEN 10%
        //       frRes = await this.presentAlertFR();
        //       if (!frRes) return;
        //     }
        //   }

        //   // CHECK IF PERMILAGEM IS 1000 %
        //   if (this.orcamentoTotais.hasOwnProperty('permilagem') && (this.orcamentoTotais.permilagem < 999.99 || this.orcamentoTotais.permilagem > 1000.009)) {
        //     let res = await this.presentPermilagemAlert();
        //     if (!res) return;
        //   }

          // this.loading = true;

          // GENERATE PROCESSAMENTOS, AVISOS AND DESPESAS -----------------------
          let processamentos = [];
          let nOrcPrestacao = 0; let orcObjProcessamento = null; let orcObjAviso = null;
          let nFRPrestacao = 0; let frObjProcessamento = null; let frObjAviso = null;
          let nSegPrestacao = 0; let segObjProcessamento = null; let segObjAviso = null;

          // let totalOrc = (!this.isVistaMensalOrcamentoZona) ? (this.orcamentoTotais.orc_valor * this.cobrancaOrcamento.filter(el => (el.sel)).length) : this.orcamentoTotais.orc_valor;
          // let totalFR = (!this.isVistaMensalOrcamentoZona) ? (this.orcamentoTotais.fr_valor * this.cobrancaFR.filter(el => (el.sel)).length) : this.orcamentoTotais.fr_valor;
          
          let totalOrc = this.utils.cleanDecimalDigits(this.orcamentoTotais.orc_valor / this.cobrancaOrcamento.filter(el => (el.sel)).length);
          let totalFR = this.utils.cleanDecimalDigits(this.orcamentoTotais.fr_valor / this.cobrancaFR.filter(el => (el.sel)).length);
          let totalSeg = this.simulacaoTotais.seguro;

          let nPrestacaoSeg = this.segMonths.filter(el => (el.active)).length;

          let firstMonthIndex = 0;
          this.appConfig.mesesAbrevDot.forEach((month, i) => {
            // ADD PROCESSAMENTO ORCAMENTO
            if (this.cobrancaOrcamento[i].sel) {
              if (firstMonthIndex === 0) firstMonthIndex = i;

              nOrcPrestacao++;

              orcObjProcessamento = {
                mes: i+1,
                ano: this.orcamento.periodo,
                cod_condominio: this.orcamento.cod_condominio,
                exercicio: this.orcamento.periodo,
                tipo_proc: 'O',
                data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                data_venc: null,
                descricao: i+1 + ' ª Prestação do Orçamento',
                // descricao: nOrcPrestacao + ' ª Prestação do Orçamento',
                pluri_anual: '0',
                nome: null,
                valor: totalOrc,
                avisos: [],
                despesas: [],
              };

              this.simulacaoList.forEach((fraccao, j) => {
                orcObjAviso = {
                  cod_condominio: this.orcamento.cod_condominio,
                  n_aviso: null,
                  mes: i+1,
                  ano: this.orcamento.periodo,
                  dt_emissao: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                  cod_fraccao: fraccao.cod,
                  cod_zona: fraccao.cod_zona,
                  dt_vencimento: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1+7)),
                  cod_pagador: this.fraccoes.find(el => (el.cod === fraccao.cod)).cod_proprietario,
                  tipo_proc: 'O',
                  cod_proc: null,
                  descricao: i+1 + ' ª Prestação do Orçamento',
                  // descricao: nOrcPrestacao + ' ª Prestação do Orçamento',
                  valor: this.utils.cleanDecimalDigits(fraccao.quota_orc / this.cobrancaOrcamento.filter(el => (el.sel)).length),
                  debito: this.utils.cleanDecimalDigits(fraccao.quota_orc / this.cobrancaOrcamento.filter(el => (el.sel)).length),
                  criado_por: this.userSession.getUserId(),
                  criado_em: this.utils.getFormatedDate(new Date()),
                  alterado_por: null,
                  alterado_em: null
                }
                if (fraccao.quota_orc > 0) orcObjProcessamento.avisos.push(orcObjAviso);
              });

              processamentos.push(orcObjProcessamento);
            }

            // ADD PROCESSAMENTO FUNDO RESERVA
            if (this.cobrancaFR[i].sel) {
              nFRPrestacao++;

              frObjProcessamento = {
                mes: i+1,
                ano: this.orcamento.periodo,
                cod_condominio: this.orcamento.cod_condominio,
                exercicio: this.orcamento.periodo,
                tipo_proc: 'F',
                data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                data_venc: null,
                descricao: i+1 + ' ª Prestação do FCR',
                // descricao: nFRPrestacao + ' ª Prestação do FCR',
                pluri_anual: '0',
                nome: null,
                valor: totalFR,
                avisos: [],
                despesas: [],
              };

              this.simulacaoList.forEach((fraccao, j) => {
                frObjAviso = {
                  cod_condominio: this.orcamento.cod_condominio,
                  n_aviso: null,
                  mes: i+1,
                  ano: this.orcamento.periodo,
                  dt_emissao: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                  cod_fraccao: fraccao.cod,
                  cod_zona: fraccao.cod_zona,
                  dt_vencimento: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1+7)),
                  cod_pagador: this.fraccoes.find(el => (el.cod === fraccao.cod)).cod_proprietario,
                  tipo_proc: 'F',
                  cod_proc: null,
                  descricao: i+1 + ' ª Prestação do FCR',
                  // descricao: nFRPrestacao + ' ª Prestação do FCR',
                  valor: this.utils.cleanDecimalDigits(fraccao.quota_fr / this.cobrancaFR.filter(el => (el.sel)).length),
                  debito: this.utils.cleanDecimalDigits(fraccao.quota_fr / this.cobrancaFR.filter(el => (el.sel)).length),
                  criado_por: this.userSession.getUserId(),
                  criado_em: this.utils.getFormatedDate(new Date()),
                }
                if (fraccao.quota_fr > 0) frObjProcessamento.avisos.push(frObjAviso);
              });

              processamentos.push(frObjProcessamento);
            }

            // ADD PROCESSAMENTO SEGURO
            if (this.segMonths[i].active) {
              nSegPrestacao++;

              segObjProcessamento = {
                mes: i+1,
                ano: this.orcamento.periodo,
                cod_condominio: this.orcamento.cod_condominio,
                exercicio: this.orcamento.periodo,
                tipo_proc: 'S',
                data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                data_venc: null,
                // descricao: nSegPrestacao + ' ª Prestação do Seguro',
                descricao: i+1 + ' ª Prestação do Seguro',
                pluri_anual: '0',
                nome: null,
                valor: totalSeg,
                avisos: [],
                despesas: [],
              };

              this.simulacaoList.forEach((fraccao, j) => {
                let quota_seg = fraccao.quota_seg / nPrestacaoSeg;

                segObjAviso = {
                  cod_condominio: this.orcamento.cod_condominio,
                  n_aviso: null,
                  mes: i+1,
                  ano: this.orcamento.periodo,
                  dt_emissao: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1)),
                  cod_fraccao: fraccao.cod,
                  cod_zona: fraccao.cod_zona,
                  dt_vencimento: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), i, 1+7)),
                  cod_pagador: this.fraccoes.find(el => (el.cod === fraccao.cod)).cod_proprietario,
                  tipo_proc: 'S',
                  cod_proc: null,
                  descricao: i+1 + ' ª Prestação do Seguro',
                  // descricao: nSegPrestacao + ' ª Prestação do Seguro',

                  valor: this.utils.cleanDecimalDigits(quota_seg),
                  debito: this.utils.cleanDecimalDigits(quota_seg),

                  criado_por: this.userSession.getUserId(),
                  criado_em: this.utils.getFormatedDate(new Date()),
                }
                if (quota_seg > 0) segObjProcessamento.avisos.push(segObjAviso);
              });

              processamentos.push(segObjProcessamento);
            }
          });

          // ADD PROCESSAMENTO EXTRAORDINÁRIO
          let processamentoExtra = {
            mes: firstMonthIndex,
            ano: this.orcamento.periodo,
            cod_condominio: this.orcamento.cod_condominio,
            exercicio: this.orcamento.periodo,
            tipo_proc: 'E',
            data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), 1, 1)),
            data_venc: null,
            descricao: 'Processamento Extraordinário',
            pluri_anual: '0',
            nome: null,
            valor: 0,
            avisos: [],
            despesas: [],
          };
          processamentos.push(processamentoExtra);
          
          // ADD PROCESSAMENTO PENALIZAÇÔES
          let processamentoPenal = {
            mes: firstMonthIndex,
            ano: this.orcamento.periodo,
            cod_condominio: this.orcamento.cod_condominio,
            exercicio: this.orcamento.periodo,
            tipo_proc: 'P',
            data: this.utils.getFormatedDate(new Date(Number(this.orcamento.periodo), 1, 1)),
            data_venc: null,
            descricao: 'Processamento Penalizações',
            pluri_anual: '0',
            nome: null,
            valor: 0,
            avisos: [],
            despesas: [],
          };
          processamentos.push(processamentoPenal);

          let auxApiObject = this.prepareBodyApiObject();

          auxApiObject.orcFraccoes.forEach(el => {
            el.quota_seg = this.simulacaoList.find(it => (it.cod === el.cod)).quota_seg.quota_seg;
            // el.quota_seg = this.simulacaoList.find(it => (it.cod === el.cod)).quota_seg_anual;
            if (el.quota_seg === null) el.quota_seg = 0;
          });

          let idActSeg = null;

          let now = new Date();
          this.api.addProcessamentos(this.id, processamentos, auxApiObject.orcFraccoes, idActSeg, now).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {
              this.orcamento.val_lancado = '1';
              resolve(true);
            } else {
              resolve(false);
            }
          }, err => {
            resolve(false);
          });
        })
    }

    private prepareBodyApiObject() {
        let orcZonasAux = [];
        if (this.orcamentoObj && this.orcamentoObj.hasOwnProperty('zonas')) {
          orcZonasAux = this.orcamentoObj.zonas.map(zona => {
            zona['cod_orcamento'] = this.orcamento.cod;
            zona['reparticao_fr'] = this.orcamentoDataTable.find(el => (el.cod === zona.cod)).fr_reparticao;
            zona['orc_zona_id'] = zona.orc_zona_id;
            return zona;
          })
        }
    
        let orcFraccoesAux = [];
        if (this.orcamentoObj && this.orcamentoObj.hasOwnProperty('zonas')) {
    
          orcFraccoesAux = this.orcamentoObj.fraccoes.map(fraccao => {
            fraccao['cod_orcamento'] = this.orcamento.cod;
            fraccao['orc_fraccao_id'] = fraccao.orc_fraccao_id;
            fraccao['quota_fr'] = 0;
            fraccao['quota_orc'] = 0;
            let auxFraccao = this.simulacaoList.find(frac => (frac.cod === fraccao.cod));
            if (auxFraccao) {
              fraccao['quota_fr'] = auxFraccao.quota_fr;
              fraccao['quota_orc'] = auxFraccao.quota_orc;
            }
    
            return fraccao;
          })
        }
        
        let orcFraccoesFrAux = this.orcamentoObj.fraccoes_fr;
    
        let orcRubricasAux = [];
        this.rubricasList.forEach((rubrica, i) => {
          this.zonas.forEach((zona, j) => {
            let aux = null;
            if (this.orcRubricas && Array.isArray(this.orcRubricas)) {
              aux = this.orcRubricas.find(el => (el.cod_rubrica === rubrica.cod_rubrica && el.cod_zona === rubrica['cod-zona-' + j]));
            }
    
            orcRubricasAux.push({
              id: (aux) ? aux.id : null,
              cod_orcamento: this.orcamento.cod,
              cod_rubrica: rubrica.cod_rubrica,
              cod_zona: zona.cod,
              reparticao: rubrica.reparticao,
              tipo: rubrica.tipo,
              valor: rubrica['valor-zona-' + j],
            });
          });
        });
    
        return { orcZonas: orcZonasAux, orcRubricas: orcRubricasAux, orcFraccoes: orcFraccoesAux, orcFraccoesFr: orcFraccoesFrAux };
      }
}
