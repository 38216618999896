import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}

@Component({
  selector: 'app-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss']
})
export class UnsavedChangesModalComponent implements OnInit {

  @Input('saveOption') saveOption = true;

  @ViewChild('unsavedAlertRef', { static: false }) unsavedAlertRef;
  unsavedModalRef = null;
  unsavedAlertConfig: any = null;

  constructor(public modalService: SuiModalService,) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.unsavedModalRef) this.unsavedModalRef.deny();
  }

  ngAfterViewInit() {
    this.unsavedAlertConfig = new TemplateModalConfig<IContext, string, string>(this.unsavedAlertRef);
    this.unsavedAlertConfig.closeResult = "closed";
    this.unsavedAlertConfig.size = 'tiny';
    this.unsavedAlertConfig.transition = 'fade';
    this.unsavedAlertConfig.transitionDuration = 250;
  }

  openModal():Promise<'CONFIRM'|'DENY'|'CANCEL'> {
    return new Promise((resolve) => {
      this.unsavedModalRef = this.modalService
      .open(this.unsavedAlertConfig)
      .onApprove((res:'CONFIRM'|'DENY') => {
        resolve (res);
      })
      .onDeny(_ => { 
        resolve('CANCEL');
      });

    });
  }

}
