export interface ordem_trabalho_data {
    id:string,
    id_link:string,
    type:number|string,
    id_assembleia:string,
    cod_condominio:string,
    data_inicio:string,
    data_fim:string,
}

export function getTypeString(type:number|string) {
    if (typeof(type) === 'string') {
        type = parseInt(type);
    }
    switch (type) {
        case 1:
            return 'APRESENTACAO_CONTAS';
        case 2:
            return 'ORCAMENTO';
    }
    return null;
}