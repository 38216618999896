import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { CommunicationsService } from '../business-logic-services/communications.service';
import { CondominosService } from '../business-logic-services/condominos.service';
import { FraccoesService } from '../business-logic-services/fraccoes.service';
import { ConfigAvisosCobrancaHypothesisCriterionDetails, ConfigAvisosCobrancaHypothesisCriterionExpression, ConfigAvisosCobrancaHypothesisDetails, ConfigurationNavigation, savehypothesisBodyAPI } from '../configurations';
import { ConfigurationsService } from '../configurations.service';
import { NavigationState } from '../navigation';
import { NavigationService } from '../navigation.service';
import { UtilitiesService } from '../utilities.service';

export interface configACCriterionDetailsList extends ConfigAvisosCobrancaHypothesisCriterionDetails {
  canAddMoreCondition: boolean,
  hasThreshold: boolean
}

@Component({
  selector: 'app-config-avisos-cobranca-details',
  templateUrl: './config-avisos-cobranca-details.component.html',
  styleUrls: ['./config-avisos-cobranca-details.component.scss']
})
export class ConfigAvisosCobrancaDetailsComponent implements OnInit {

  // conditionalFields = [];

  createNewEntry = {name: '-- Criar novo --', value: '-1'};

  conditionalFields = {
    '-1': [
      {name: 'Quota Extraordinária (Dias Atrasados)', value: 'extra_dias_vencimento'},
      {name: 'Quota Extraordinária (Valor Dívida)', value: 'extra_valor'},
      {name: 'Quotas Ordinárias (Mensalidades Atrasadas)', value: 'n_mensalidades_atraso'},
      {name: 'Global (Valor Dívida)', value: 'global_divida'},
    ],
    '4': [
      // {name: 'Entrega de Ação Executiva (Número de Dias)', value: 'entrega_ae_dias'},
    ],
    '7': [
      {name: 'Ação Executiva Em Ata', value: 'ae_em_ata'},
    ],
    '8': [
      {name: 'Liquidação da Dívida (%)', value: 'liquidacao_divida'},
    ]
  };

  booleanFields = [
    'ae_em_ata',
  ]

  compareOperators = [
    {name: 'Menor', value: '<'},
    {name: 'Menor ou Igual', value: '<='},
    {name: 'Igual', value: '=='},
    {name: 'Maior ou Igual', value: '>='},
    {name: 'Maior', value: '>'},
  ]
  booleanOperators = [
    {name: 'Sim', value: 'sim'},
    {name: 'Não', value: 'nao'},
  ]
  conditionalOperators = [
    {name: 'ou', value: '||'},
    {name: 'e', value: '&&'},
  ]

  textosOpts = [];
  estadoContenciosoOpts:Array<{name,value, id_texto_alteracao_estado}> = [];

  hypo: ConfigAvisosCobrancaHypothesisDetails = null;
  criterios: Array<configACCriterionDetailsList> = [];
  loading = false;

  @ViewChild('alertRef', { static: false }) alertRef;
  modalRef = null;
  alertConfig: any = null;

  @ViewChild('scrollableForm', { static: false }) scrollableForm: ElementRef;
  form = new FormGroup({
    descricao: new FormControl(null, { validators: [Validators.required, Validators.maxLength(150), Validators.minLength(1)] }),
    cod_estado_contencioso: new FormControl(null, { validators: [Validators.required] }),
    comunicarTransicaoEstado: new FormControl(false),
    id_texto_alteracao_estado: new FormControl({disabled:true}, { validators: [Validators.required] }),
  });
  get descricao() { return this.form.get('descricao'); }
  get cod_estado_contencioso() { return this.form.get('cod_estado_contencioso'); }
  get comunicarTransicaoEstado() { return this.form.get('comunicarTransicaoEstado'); }
  get id_texto_alteracao_estado() { return this.form.get('id_texto_alteracao_estado'); }


  navState: ConfigurationNavigation = null;

  constructor(
    public api: ApiService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public fb: FormBuilder,
    public toastr: ToastrService,
    public navigation: NavigationService,
    public fraccoesService: FraccoesService,
    public cdRef: ChangeDetectorRef,
    public modalService: SuiModalService,
    public communications: CommunicationsService,
    public condominos: CondominosService,
    public router: Router,
    public location: Location,
    public configurations: ConfigurationsService,) { 
      let currState = this.router.getCurrentNavigation();
      if ('state' in currState.extras) {
        this.navState = currState.extras.state;
      }
    }

  ngOnInit() {
    this.conditionalFields['-1'].forEach(el => {
      for (const item in this.conditionalFields) {
        if (parseInt(item) < 0) continue;
        if (this.conditionalFields.hasOwnProperty(item)) {
          const element = this.conditionalFields[item];
          element.push(el);
        }
      }
    })

  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  refreshingSelects = false;
  ngAfterViewInit() {
    this.alertConfig = new TemplateModalConfig<any, string, string>(this.alertRef);
    this.alertConfig.closeResult = "closed";
    this.alertConfig.isClosable = false;
    this.alertConfig.size = 'large';
    this.alertConfig.transition = 'fade';
    this.alertConfig.transitionDuration = 250;

    this.comunicarTransicaoEstado.valueChanges.subscribe(val => {
      if (!!val) {
        this.id_texto_alteracao_estado.enable();
      } else {
        this.id_texto_alteracao_estado.disable();
      }
    })

    this.cod_estado_contencioso.valueChanges.subscribe(val => {
      if (val == '-1') {
        this.cod_estado_contencioso.patchValue(null, {
          emitEvents: false,
        })
        this.createNew('ESTADO', null);
        return;
      }
      this.loadConditionalFields();
      this.refreshingSelects = true;
      setTimeout(() => {
        this.refreshingSelects = false;
      }, 1);
    })
  }

  ngOnDestroy(alreadyReplacedUrl) {
    this.alreadyReplacedUrl = this.alreadyReplacedUrl || alreadyReplacedUrl;
    if (this.modalRef) this.modalRef.deny();
  }

  alreadyReplacedUrl = false;
  createNew(type:'ESTADO'|'TEXTO', options:{index_criterio?:string, texto_type?:'THRESHOLD'|'CRITERIO'|'TRANSITION_STATE'}={}) {
    let navState: ConfigurationNavigation = null;
    let prevState: any = {
      id: this.hypo? this.hypo.id : null,
      descricao: this.descricao.value,
      cod_estado_contencioso: this.cod_estado_contencioso.value,
      comunicarTransicaoEstado: this.comunicarTransicaoEstado.value,
      id_texto_alteracao_estado: this.id_texto_alteracao_estado.value,
      criterios: JSON.parse(JSON.stringify(this.criterios)),
      index_criterio: options.hasOwnProperty('index_criterio')? options.index_criterio : null,
      texto_type: options.hasOwnProperty('texto_type')? options.texto_type : null,
    }

    switch (type) {
      case 'ESTADO':
        
        break;
      case 'TEXTO':
        navState = {
          redirectAfterTo: { 
            url: this.location.path(), 
            action: 'CONFIG_OPEN_NEW_AC_SET_TEXT',
            data: prevState
          },
          action: 'CREATE_NEW_TEXT'
        }
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.alreadyReplacedUrl = true;
        this.router.navigate(['configuracoes','textos'], { state: navState, replaceUrl: true });
        break;
    
      default:
        break;
    }
  }

  changedTexto(type:'THRESHOLD'|'CRITERIO'|'TRANSITION_STATE', index_criterio=null) {
    switch (type) {
      case 'CRITERIO':
        if (index_criterio < this.criterios.length && this.criterios[index_criterio].id_texto == '-1') {
          this.criterios[index_criterio].id_texto = null;
          this.createNew('TEXTO', {index_criterio: index_criterio, texto_type: type});
        }
        break;
      case 'THRESHOLD':
        if (index_criterio < this.criterios.length && this.criterios[index_criterio].threshold_id_texto == '-1') {
          this.criterios[index_criterio].threshold_id_texto = null;
          this.createNew('TEXTO', {index_criterio: index_criterio, texto_type: type});
        }
        break;
      case 'TRANSITION_STATE':
        if (this.id_texto_alteracao_estado.value == '-1') {
          this.id_texto_alteracao_estado.patchValue(null);
          this.createNew('TEXTO', {index_criterio: index_criterio, texto_type: type});
        }
        break;
    
      default:
        break;
    }
    
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.criterios, event.previousIndex, event.currentIndex);
  }

  open(id_hypothesis, navState:ConfigurationNavigation): Promise<{success:boolean, isRedirecting:boolean,}> {
    return new Promise(async (resolve) => {
      this.form.reset();
      this.alreadyReplacedUrl = false;
      this.criterios = [];
      this.loading = true;
      this.modalRef = this.modalService
      .open(this.alertConfig)
      .onApprove(() => {
        this.form.reset();
        this.criterios = [];
        this.hypo = null;
        resolve({success: true, isRedirecting: this.alreadyReplacedUrl});
      })
      .onDeny(() => {
        this.form.reset();
        this.criterios = [];
        this.hypo = null;
        resolve({success: false, isRedirecting: this.alreadyReplacedUrl});
      });
      if (id_hypothesis) {
        this.hypo = await this.configurations.getConfigAvisosCobrancaDetails(id_hypothesis);
        // this.router.routeReuseStrategy.shouldReuseRoute = () => true;
        // this.router.onSameUrlNavigation = 'ignore';
      } else {
        this.hypo = null;
      }
      this.loadComponentInfo();
      this.restoreForm();
      this.loadConditionalFields();
      this.loadNavigationAction(navState);
      this.loading = false;
    })
  }

  loadNavigationAction(navState:ConfigurationNavigation) {
    if (!navState || !navState.action) return;
    switch (navState.action) {
      case 'CONFIG_OPEN_NEW_AC_SET_TEXT':
        if (!navState.data) break;
        this.form.patchValue({
          descricao: navState.data.descricao,
          cod_estado_contencioso: navState.data.cod_estado_contencioso,
          comunicarTransicaoEstado: navState.data.comunicarTransicaoEstado,
          id_texto_alteracao_estado: navState.data.id_texto_alteracao_estado,
        });
        this.criterios = navState.data.criterios;

        let index_criterio = navState.data.index_criterio;
        let id_texto = navState.data.id_texto;

        if (id_texto == null) break;
        switch (navState.data.texto_type) {
          case 'CRITERIO':
            if (index_criterio == null || index_criterio >= this.criterios.length) break;
            this.criterios[index_criterio].id_texto = id_texto;
            break;
            case 'THRESHOLD':
            if (index_criterio == null || index_criterio >= this.criterios.length) break;
            this.criterios[index_criterio].threshold_id_texto = id_texto;
            break;
          case 'TRANSITION_STATE':
            this.id_texto_alteracao_estado.patchValue(id_texto);
            break;
        
          default:
            break;
        }
        break;
    
      default:
        break;
    }
    return;
  }

  async loadComponentInfo() {
    this.textosOpts = (await this.communications.getTextos()).map(el => {
      return {
        name: el.nome,
        value: el.id,
      }
    })
    this.textosOpts.unshift(this.createNewEntry);

    this.condominos.getContenciososNotConfiguredOptions().then(estados => {
      this.estadoContenciosoOpts = estados.map(el => {
        return {
          name: el.nome,
          value: el.cod,
          id_texto_alteracao_estado: el.id_texto_alteracao_estado,
        }
      })
      if (this.hypo && this.hypo.nome_estado_contencioso != null) {
        this.estadoContenciosoOpts.push({name: this.hypo.nome_estado_contencioso, value: this.hypo.cod_estado_contencioso, id_texto_alteracao_estado: this.hypo.id_texto_alteracao_estado});
      }
      this.estadoContenciosoOpts.sort((a,b) => parseInt(a.value) - parseInt(b.value));
    });


  }
  
  changedField(expression: ConfigAvisosCobrancaHypothesisCriterionExpression) {
    if (this.conditionalOperators.find(el => el.value === expression.operator)) return;
    if (this.hasComparisonField(expression)) {
      if (!this.compareOperators.find(el => el.value === expression.operator)) expression.operator = null;
    } else {
      if (!this.booleanOperators.find(el => el.value === expression.operator)) expression.operator = null;
    }
  }

  loadConditionalFields() {
    let conditionalFields = [];
    if (this.conditionalFields.hasOwnProperty(this.cod_estado_contencioso.value)) {
      conditionalFields = this.conditionalFields[this.cod_estado_contencioso.value];
    } else {
      conditionalFields = this.conditionalFields['-1'];
    }

    this.criterios.forEach(crit => {
      crit.expressions.forEach(exp => {
        if (!conditionalFields.find(cond => cond.value === exp.field)) {
          exp.field = null;
        }
        let newOperators = this.hasComparisonField(exp)? this.compareOperators : this.booleanOperators;
        newOperators = newOperators.concat(this.conditionalOperators);
        if (!newOperators.find(cond => cond.value === exp.operator)) {
          exp.operator = null;
        }
      })
    })
  }

  getConditionalFields() {
    return this.conditionalFields.hasOwnProperty(this.cod_estado_contencioso.value) ? this.conditionalFields[this.cod_estado_contencioso.value] : this.conditionalFields['-1'];
  }

  
  restoreForm() {
    this.form.reset();
    // this.cod_estado_contencioso.enable();
    // this.id_texto_alteracao_estado.enable();
    // this.comunicarTransicaoEstado.pat
    if (!this.hypo) {
      this.criterios = [];
      return;
    }
    this.hypo.cod_estado_contencioso == null ? this.cod_estado_contencioso.disable() : this.cod_estado_contencioso.enable();
    this.hypo.id_texto_alteracao_estado == null ? this.id_texto_alteracao_estado.disable() : this.id_texto_alteracao_estado.enable();
    

    this.form.patchValue({
      descricao: this.hypo.descricao,
      cod_estado_contencioso: this.hypo.cod_estado_contencioso,
      comunicarTransicaoEstado: this.hypo.id_texto_alteracao_estado != null,
      id_texto_alteracao_estado: this.hypo.id_texto_alteracao_estado,
    }, { emitEvent: false });

    let criterios = (JSON.parse(JSON.stringify(this.hypo.criterios)) as Array<ConfigAvisosCobrancaHypothesisCriterionDetails>).map(el => {
      let expressions = el.expressions.map(exp => {
        let operatorName = exp.operator === '&&' || exp.operator === '||' ? this.conditionalOperators.find(el => el.value === exp.operator) : null;
        let field = exp.field;
        let operator = exp.operator;
        let value = exp.value;
        
        if (this.booleanFields.find(el => el === exp.field)) {
          operator = exp.value == '1'? 'sim' : 'nao';
          value = null;
        }
        let aux: ConfigAvisosCobrancaHypothesisCriterionExpression = {
          id: exp.id,
          id_criterion: exp.id_criterion,
          field: field,
          operator: operator,
          value: value,
          name: operatorName ? operatorName.name : null,
        }
        return aux;
      });
      let canAddMoreCondition = expressions.length <= 1;
      expressions.sort((a,b) => parseInt(a.id) - parseInt(b.id)); 
      return {
        ...el,
        expressions: expressions,
        canAddMoreCondition: canAddMoreCondition,
        hasThreshold: !!el.threshold_value && !!el.threshold_id_texto,
      }
    });
    criterios.sort((a,b) => parseInt(a.ordem) - parseInt(b.ordem));
    
    this.utils.updateArrayElementsWithOther(this.criterios, criterios);

  }

  changeCondition(exp:ConfigAvisosCobrancaHypothesisCriterionExpression) {
    exp.operator = exp.operator === '&&' ? '||' : '&&';
    let operator = exp.operator === '&&' || exp.operator === '||' ? this.conditionalOperators.find(el => el.value === exp.operator) : null;
    exp.name = operator? operator.name : null;
  }

  addExpression(criterio: configACCriterionDetailsList) {
    if (criterio.expressions.length === 0) {
      let expression: ConfigAvisosCobrancaHypothesisCriterionExpression = {
        id: null,
        id_criterion: criterio.id,
        field: null,
        operator: null,
        value: null
      }
      criterio.expressions.push(expression);
    } else if (criterio.expressions.length === 1) {
      let condOperator = this.conditionalOperators.find(el => el.value === '&&');
      let operator: ConfigAvisosCobrancaHypothesisCriterionExpression = {
        id: null,
        id_criterion: criterio.id,
        field: null,
        operator: '&&',
        value: null,
        name: condOperator? condOperator.name : null,
      }
      criterio.expressions.push(operator);
      let expression: ConfigAvisosCobrancaHypothesisCriterionExpression = {
        id: null,
        id_criterion: criterio.id,
        field: null,
        operator: null,
        value: null
      }
      criterio.expressions.push(expression);
    }
    criterio.canAddMoreCondition = criterio.expressions.length <= 1;
  }

  removeExpression(criterio: configACCriterionDetailsList, index_expression: number) {
    if (index_expression >= criterio.expressions.length) return;
    if (index_expression === 0) {
      criterio.expressions.splice(index_expression,2);
    } else if (index_expression === 2) {
      criterio.expressions.splice(index_expression - 1,2);
    }
    criterio.canAddMoreCondition = criterio.expressions.length <= 1;
  }

  addCriterio() {
    let newCriterio: configACCriterionDetailsList = {
      canAddMoreCondition: true,
      hasThreshold: false,
      expressions: [],
      id: null,
      id_hyphotesis: this.hypo? this.hypo.id : null,
      id_texto: null,
      threshold_id_texto: null,
      threshold_value: null,
      ordem: null,
    }
    this.criterios.push(newCriterio);
    setTimeout(() => {
      this.scrollableForm.nativeElement.scrollTop = this.scrollableForm.nativeElement.scrollHeight;
    });
  }

  removeCriterio(index:number) {
    this.criterios.splice(index,1);
  }

  approve() {
    this.modalRef.approve();
  }

  hasComparisonField(expression:ConfigAvisosCobrancaHypothesisCriterionExpression) {
    return !this.booleanFields.find(el => el === expression.field);
  }


  isFormValid(): boolean {
    if (!this.form.valid) return false;
    for (let i = 0; i < this.criterios.length; i++) {
      const element = this.criterios[i];
      if (!this.isValidCriterio('id_texto', element)) return false;
      if (!this.isValidCriterio('threshold_value', element)) return false;
      if (!this.isValidCriterio('threshold_id_texto', element)) return false;
      if (!this.isValidCriterio('expressions', element)) return false;
    }
    return true;
  }

  isValidCriterio(key:string, criterio:configACCriterionDetailsList) {
    switch (key) {
      case 'id_texto':
        return criterio.id_texto != null;
      case 'threshold_value':
        return !criterio.hasThreshold || !!criterio.threshold_value && parseInt(criterio.threshold_value) > 0; 
      case 'threshold_id_texto':
        return !criterio.hasThreshold || criterio.threshold_id_texto != null; 
      case 'expressions':
        if (criterio.expressions.length !== 0 && criterio.expressions.length !== 1 && criterio.expressions.length !== 3) return false;
        for (let i = 0; i < criterio.expressions.length; i++) {
          const element = criterio.expressions[i];
          if (i !== 1) {
            if (!this.isValidExpression('field', element)) return false;
            if (!this.isValidExpression('operator', element)) return false;
            if (!this.isValidExpression('value', element)) return false;
          }
        }
        return true;
      default:
        break;
    }
  }

  isValidExpression(key, expression: ConfigAvisosCobrancaHypothesisCriterionExpression) {
    let isComparison = this.hasComparisonField(expression);
    switch (key) {
      case 'field':
        return expression.field != null;
      case 'operator':
        return isComparison? !!this.compareOperators.find(el => el.value === expression.operator) : this.booleanOperators.find(el => el.value === expression.operator);
      case 'value':
        return  !isComparison || (expression.value != null && parseFloat(expression.value) >= 0);
      default:
        break;
    }
  }

  setFormFeedback() {
    if (this.showFormFeedbackTimeout) {
      clearTimeout(this.showFormFeedbackTimeout);
      this.showFormFeedbackTimeout = null;
    }
    this.showFormFeedback = true;
    this.showFormFeedbackTimeout = setTimeout(() => {
      this.showFormFeedback = false;
    }, 4000);
  }

  showFormFeedback = false;
  showFormFeedbackTimeout = null;
  submitting = false;
  submit(): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!this.isFormValid()) {
        this.setFormFeedback();
        this.toastr.error(this.appConfig.errMsg.incorrectForm.msg, this.appConfig.errMsg.incorrectForm.title, { timeOut: 4000 });
        resolve(false);
        return;
      }
  
      let criterios = this.criterios.map((el, i) => {
        let expressions = el.expressions.map(exp => {
          let operator = exp.operator;
          let value = exp.value;
          if (this.booleanOperators.find(op => op.value === operator)) {
            value = operator === 'sim' ? '1' : '0';
            operator = '==';
          }
          return {
            field: exp.field,
            operator: operator,
            value: value,
          }
        });
        let threshold_value = el.threshold_value;
        let threshold_id_texto = el.threshold_id_texto;
        if (!el.hasThreshold) {
          threshold_value = null;
          threshold_id_texto = null;
        }
        return {
          id: el.id,
          id_texto: el.id_texto,
          threshold_value: threshold_value,
          threshold_id_texto: threshold_id_texto,
          ordem: (i + 1),
          expressions: expressions,
        };
      });
  
      let body:savehypothesisBodyAPI = {
        id: this.hypo? this.hypo.id : null,
        descricao: this.descricao.value,
        cod_estado_contencioso: this.cod_estado_contencioso.value,
        id_texto_alteracao_estado: this.comunicarTransicaoEstado.value ? this.id_texto_alteracao_estado.value : null,
        criterios: criterios,
        ordem: this.hypo? this.hypo.ordem : null,
      }
      
      this.submitting = true;
      let res = await this.configurations.saveAvisosCobrancaHypothesis(body);
      this.submitting = false;
      if (res) this.approve();
      resolve(res);
    })

  }
}
