import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from './utilities.service';

@Pipe({
  name: 'iban'
})
export class IbanPipe implements PipeTransform {

  constructor(public utils: UtilitiesService) {

  }

  transform(value: any, ...args: any[]): any {
    return this.utils.getIBANFormatted(value);
  }

}
