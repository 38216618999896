import { Location, PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { module } from './business-model-interfaces/application';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  navigations = 1;
  
  lastVisitedUrl:string = null;
  actualVisitedUrl:string = null;

  shareableData:Array<{module:module, data:Object}> = [
    {
      module: 'ASSEMBLEIAS_DETAILS',
      data: {
        selectingOrcamentoID: null,
      }
    }
  ]

  constructor(public router: Router,
              public platLocation: PlatformLocation, 
              public location: Location,
              ) { 
  }

  init() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((value:NavigationEnd) => {
      
      if (value.id === (this.navigations + 1)) {
        this.navigations++;
        
        this.lastVisitedUrl = this.actualVisitedUrl;
        this.actualVisitedUrl = value.url;
      }
    });
  } 

  getModule(module:module) {
    return this.shareableData.find(el => el.module === module);
  }

  resetModuleData(module:module) {
    let moduleInfo = this.getModule(module);
    if (moduleInfo) {
      for (const key in moduleInfo.data) {
        moduleInfo.data[key] = null;
      }
    } 
  }

  setData(module:module, fields:Object) {
    let moduleInfo = this.getModule(module);
    if (moduleInfo) {
      for (const key in fields) {
        if (moduleInfo.data.hasOwnProperty(key)) {
          moduleInfo.data[key] = fields[key];
        }
      }
    }
  }

  removeURLFragment(url:string) {
    let hashIndex = url.indexOf('#');
    if (hashIndex === -1) return url;
    return url.substring(0, hashIndex);
  }


  private getTotalNavigations() {
    return this.navigations;
  }

  goBack() {
    if (this.getTotalNavigations() > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }
}
