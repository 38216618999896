import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { exportPDF, Group } from '@progress/kendo-drawing';


import { RegistoCttAR } from '../business-model-interfaces/carta';
import { AppConfigService } from '../app-config.service';

@Component({
  selector: 'app-carta-ctt-aviso-rececao',
  templateUrl: './carta-ctt-aviso-rececao.component.html',
  styleUrls: ['./carta-ctt-aviso-rececao.component.scss']
})
export class CartaCttAvisoRececaoComponent implements OnInit {

  @Input() regCttList:RegistoCttAR = null;

  @ViewChild('pdf', { static: false }) pdfController;

  now: Date = null;

  constructor(public cdRef: ChangeDetectorRef,
    public appConfig: AppConfigService) { }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  generatePdf() {
    return this.pdfController.export();
  }

  getBase64():Promise<string> {
    return new Promise((resolve, reject) => {
      this.now = new Date();

      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');

        resolve(base64);
      }).catch(err => {
        resolve(null);
      });
    });
  }

}
