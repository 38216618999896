import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { FraccaoList } from '../assembleias-details/assembleias-details.component';
import { bit, IContext } from '../business-model-interfaces/application';
import { UtilitiesService } from '../utilities.service';

interface EmailOficialList {
  id_parametro: number,
  nome_fracao: string,
  cod_fraccao: string,
  nome_condomino: string,
  cod_condomino: number,
  email: string
}
interface EmailOficialViewList extends EmailOficialList {
  checked: boolean,
  manualEmail: string,
  isManualEmail: boolean,
  emailOpts: {name:string, value:string, principal: bit, obs: string}[]
}
@Component({
  selector: 'app-assembleias-pick-email-oficial',
  templateUrl: './assembleias-pick-email-oficial.component.html',
  styleUrls: ['./assembleias-pick-email-oficial.component.scss']
})
export class AssembleiasPickEmailOficialComponent implements OnInit {

  @Input() fraccaoList: Array<FraccaoList> = [];

  @ViewChild('alertRef', { static: false }) alertRef;
  modalRef = null;
  modalConfig: any = null;


  list: Array<EmailOficialViewList> = [];
  fetchingInitialData = false;

  formFeedback = { show: false, timeout: null };


  constructor(
    public modalService: SuiModalService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public toastr: ToastrService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.modalRef) this.modalRef.deny();
  }

  ngAfterViewInit() {
    this.modalConfig = new TemplateModalConfig<IContext, string, string>(this.alertRef);
    this.modalConfig.closeResult = "closed";
    this.modalConfig.size = 'normal';
    this.modalConfig.transition = 'fade up';
    this.modalConfig.transitionDuration = 400;
  }

  open(prev_parametros: { id: number, cod_fraccao: string, cod_condomino: number, email: string }[] = []): Promise<Array<EmailOficialList>> {
    return new Promise((resolve, reject) => {
      this.formFeedback = { show: false, timeout: null };

      this.list = this.fraccaoList.map(el => {
        //Find prev param and fill id_parametro and email
        let prevParam = prev_parametros.find(p => p.cod_fraccao == el.cod_fraccao && p.cod_condomino == el.cod_proprietario);
        let emailOpts = [{name: ' -- Entrada Livre --', value: '-1', principal: 0 as bit, obs: null}];
        if (el.email_proprietario) emailOpts.push({ name: el.email_proprietario, value: el.email_proprietario, principal: 1, obs: el.email_proprietario_obs});
        if (el.email_proprietario_2) emailOpts.push({ name: el.email_proprietario_2, value: el.email_proprietario_2, principal: 0, obs: el.email_proprietario_2_obs});


        let email = null;
        let manualEmail = null;

        let prevEmail = prevParam !== undefined ? prevParam.email : null;
        if (prevEmail) {
          if (emailOpts.findIndex(e => e.value === prevEmail) !== -1) {
            email = prevEmail;
          } else {
            manualEmail = prevEmail;
          }
        }
        return {
          checked: email !== null || manualEmail !== null,
          id_parametro: prevParam !== undefined ? prevParam.id : null,
          nome_fracao: el.fraccao,
          cod_fraccao: el.cod_fraccao,
          nome_condomino: el.proprietario,
          cod_condomino: el.cod_proprietario,
          email: prevParam !== undefined ? prevParam.email : null,
          emailOpts: emailOpts,
          manualEmail: manualEmail,
          isManualEmail: manualEmail !== null,
        }
      });
      this.modalRef = this.modalService
        .open(this.modalConfig)
        .onApprove(_ => {
          let selected: Array<EmailOficialList> = this.list.filter(el => !!el.checked).map(el => {
            let email = el.isManualEmail ? el.manualEmail : el.email;
            return {
              id_parametro: el.id_parametro,
              nome_fracao: el.nome_fracao,
              cod_fraccao: el.cod_fraccao,
              nome_condomino: el.nome_condomino,
              cod_condomino: el.cod_condomino,
              email: email,
            }
          })
          console.log(selected);

          resolve(selected);
        })
        .onDeny(_ => {
          reject(null);
        });
    })
  }

  cancelManualEntry(row: EmailOficialViewList, index) {
    row.isManualEmail = false;
    row.manualEmail = null;
    this.focusInput(index);
    }

  focusInput(index) {
    setTimeout(() => {
      let input = document.getElementById('email-selector-' + index);
      if (!input) input = document.getElementById('email-input-' + index);
      if (input) {
        setTimeout(() => {
          input.focus();
        }, 0);
      }
    }, 0);
  }

  checkRow(row: EmailOficialViewList, index) {
    row.checked = !row.checked;
    if (row.checked) {
      let emailPrincipal = row.emailOpts.find(el => el.principal == 1);
      if (emailPrincipal !== undefined) {
        row.email = emailPrincipal.value;
      } else {
        this.focusInput(index);
      }
    } else {
      setTimeout(() => {
        row.isManualEmail = false;
        row.manualEmail = null;
        row.email = null;
      }, 0);
    }
  }

  selectedEmail(row: EmailOficialViewList, index) {
    row.isManualEmail = row.email === '-1';
    if (row.isManualEmail) {
      setTimeout(() => {
        row.email = null;
        this.focusInput(index);
      }, 0);
    } else {
      setTimeout(() => {
        row.manualEmail = null;
      }, 0);
    }
  }

  isValid(): boolean {
    let nSelected = 0;
    for (let i = 0; i < this.list.length; i++) {
      const element = this.list[i];
      if (!element.checked) continue;
      if (element.isManualEmail) {
        if (!this.utils.validateEmail(element.manualEmail)) return false;
      } else {
        if (element.email == null) return false;
      }
      nSelected++;
    }

    if (nSelected === 0) {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
      return false;
    }

    return true;
  }

  submit() {
    this.utils.setFormFeedback(this.formFeedback);
    if (!this.isValid()) return;
    this.modalRef.approve();
  }

}
