import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { custosComunicacoesListCaixaVertis, custosComunicacoesListCorrespondencia } from '../business-model-interfaces/comunicacoes';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { EditEntregaFaturaCttModalComponent } from '../edit-entrega-fatura-ctt-modal/edit-entrega-fatura-ctt-modal.component';
import { MessageService } from '../message.service';
import { UserSessionService } from '../user-session.service';
import { UtilitiesService } from '../utilities.service';

@Component({
  selector: 'app-entregas-faturas',
  templateUrl: './entregas-faturas.component.html',
  styleUrls: ['./entregas-faturas.component.scss']
})
export class EntregasFaturasComponent implements OnInit {

  entregasFaturasList: Array<custosComunicacoesListCaixaVertis> = [];
  entregasFaturasListOrig: Array<custosComunicacoesListCaixaVertis> = [];

  startDate:Date = null;
  endDate:Date = new Date();

  //Api
  noMoreData = false;
  apiSub = null;

  //Pagination
  page = 1;
  limit = 20;

  fetching = false;

  toDelete = [];

  @ViewChild('editFaturaCtt', { static: false }) editFaturaCtt: EditEntregaFaturaCttModalComponent;

  @ViewChild('deleteModal', { static: false }) deleteModal: DeleteModalComponent;


  constructor(public message: MessageService,
    public api: ApiService,
    public router: Router,
    public toastr: ToastrService,
    public userSession: UserSessionService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService
  ) { }

  ngOnInit() {
  }

  getEntregasFaturas(init=true) {
    if (init) {
      this.noMoreData = false;
      this.page = 1;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    }

    if (this.fetching) return;

    this.fetching = true;
    this.apiSub = this.api.getEntregasFaturas(null, this.page, this.limit, this.startDate, this.endDate).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.fetching = false;
        if (!res.data) this.noMoreData = true;

        if (init) {
          this.entregasFaturasListOrig = res.data.map(el =>  {
            return {...el, checked: false, user_abrev: this.utils.getNomeUtilizadorIniciais(el.entregue_por_nome)};
          });
        } else {
          this.entregasFaturasListOrig = this.entregasFaturasListOrig.concat(res.data.map(el =>  {
            return {...el, checked: false, user_abrev: this.utils.getNomeUtilizadorIniciais(el.entregue_por_nome)};
          }));
        }
  
        this.filterList();

      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetching = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetching = false;
    });
  }

  editFaturaEntrega(row: custosComunicacoesListCorrespondencia) {
    this.editFaturaCtt.open(row, row.tipo == '0'? 'ENTREGA' : 'FATURA').then(res => {
      if (res) {
        this.getEntregasFaturas();
      }
    })
  }

  filterList() {
    this.entregasFaturasList = this.entregasFaturasListOrig.slice();
    this.entregasFaturasList.sort((a,b) => {
      return this.utils.compareDayDates(new Date(b.data), new Date(a.data));
    })
  }

  onScroll() {
    if (this.noMoreData || !this.apiSub.closed) return;
    this.page += 1;
    this.getEntregasFaturas(false);
  }



  rowChecked(row:custosComunicacoesListCorrespondencia, showAlert=true): void {
    if (!row.tipo) return;
    if (row.checked) {
      if (row.tipo == '0') {
        if (row.id_fatura) {
          this.toastr.error('Não é possível eliminar uma entrega que tenha uma fatura associada.', 'Ups...!', { timeOut: 4000 });
          setTimeout(() => row.checked = false);
          return;
        }
        if (!this.userSession.isSuperAdmin()) {
          this.toastr.error('Não tem permissões para aceder ao recurso/funcionalidade pretendida. Por favor contacte o administrador de sistema.', 'Permissão Negada', { timeOut: 4000 });
          setTimeout(() => row.checked = false);
          return;
        }
        if (this.utils.compareDayDates(new Date(row.data), new Date()) < 0 ) {
          this.toastr.warning('A entrega selecionada encontra-se no registo da caixa vertis. Alterar os dados da entrega irá afetá-lo.', 'Alerta');
        }
      }
      let isToday = this.utils.compareDayDates(new Date(row.data), new Date()) == 0;
      if (row.tipo == '1' && !this.userSession.isSuperAdmin() && (!isToday || isToday && row.entregue_por !== this.userSession.getUserId())) {
        this.toastr.error('Não tem permissões para aceder ao recurso/funcionalidade pretendida. Por favor contacte o administrador de sistema.', 'Permissão Negada', { timeOut: 4000 });
        setTimeout(() => row.checked = false);
        return;
      }
      if (row.tipo == '1' && this.userSession.isSuperAdmin() && !isToday) {
        this.toastr.warning('A fatura selecionada encontra-se no registo da caixa vertis. Alterar os dados da fatura irá afetá-lo.', 'Alerta');
      }
    }
  }

  presentDeleteModal() {
    this.toDelete = this.entregasFaturasList.filter(el => el.checked);
    if (this.toDelete.length > 0) {
      this.deleteModal.open();
    } else {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
    }
  }

  delete = (): Promise<boolean> => {
    return new Promise(resolve => {
      this.api.delEntregasFaturas(this.toDelete).subscribe(res => {
        if (res.success) {
          this.entregasFaturasListOrig = this.entregasFaturasListOrig.filter(el => !this.toDelete.find(del => (el.id == del.id)));
          this.filterList();
        } else {
          this.utils.apiErrorMsg(res);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });
      resolve(true);
    });
  }

}
