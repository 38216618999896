import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MessageService } from './message.service';
import { AccessOption, PermissionObj } from './business-model-interfaces/permissions';


@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  permissions:PermissionObj = []; 

  constructor(public router: Router,
              public message: MessageService,
              public toastr: ToastrService) { }

  setSession(userData: any): void {
    localStorage.setItem('user_data', JSON.stringify(userData));
    localStorage.setItem('token', 'TODO');
    this.message.sendMessage({ dest: 'ALL', cmd: 'PERMISSIONS_UPDATED' })
  }

  isLogged() {
    return localStorage.getItem('token') != null;
  }

  isAdmin() {
    let user_data = localStorage.getItem('user_data');
    if (user_data) return (JSON.parse(user_data).is_admin === '1');
    return null;
  }

  isSuperAdmin() {
    let user_data = localStorage.getItem('user_data');
    if (user_data) return (JSON.parse(user_data).super_admin === '1');
    return null;
  }

  getUserId() {
    let user_data = localStorage.getItem('user_data');
    if (user_data) return JSON.parse(user_data).id;
    return null;
  }

  getUsername() {
    let user_data = localStorage.getItem('user_data');
    if (user_data) return JSON.parse(user_data).username;
    return null;
  }

  getUserFullName() {
    let user_data = localStorage.getItem('user_data');
    if (user_data) {
      return JSON.parse(user_data).first_name + ' ' + JSON.parse(user_data).last_name;
    }
    return null;
  }

  getUserPhone() {
    let user_data = localStorage.getItem('user_data');
    if (user_data) {
      return JSON.parse(user_data).phone;
    }
    return null;
  }

  getUserEmail() {
    let user_data = localStorage.getItem('user_data');
    if (user_data) {
      return JSON.parse(user_data).email;
    }
    return null;
  }

  getUserFullNameAbrv(fullAbrv=false) {
    let userData = JSON.parse(localStorage.getItem('user_data'));
    if (userData && userData.hasOwnProperty('first_name') && userData.hasOwnProperty('last_name')) {
      if (!userData['last_name']) {
        return (!fullAbrv) ? `${userData['first_name']}` : userData['first_name'][0];
      } else {
        return (!fullAbrv) ? `${userData['first_name']} ${userData['last_name'][0]}.`: userData['first_name'][0] + userData['last_name'][0];
      }
    }
    return null;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  setCaixaVertisState(action) {
    switch (action) {
      case 'login':
        // BREADCRUMB SIGNAL
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'CAIXA_VERTIS_LOGGED_IN' });

        localStorage.setItem('isCaixaVertisLoggedIn', '1');
        break;
      case 'logout':
        // BREADCRUMB SIGNAL
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'CAIXA_VERTIS_LOGGED_OUT' });

        localStorage.setItem('isCaixaVertisLoggedIn', '0');
        break;
    }
  }

  isCaixaVertisLoggedIn() {
    return (localStorage.getItem('isCaixaVertisLoggedIn') === '1');
  }

  isUtilizadorCaixaVertis() {
    let user_data = localStorage.getItem('user_data');
    if (user_data) return (JSON.parse(user_data).utilizador_caixa === '1');
    return false;
  }


  checkUserAccess(key:string, action: AccessOption): boolean {
    let user = null;
    let user_data = localStorage.getItem('user_data');
    if (user_data) user = JSON.parse(user_data); 
    if (!user || !user.hasOwnProperty('permissions')) return false;
    let permission = JSON.parse(user.permissions).find(el => el.key === key);
    return permission && permission.access.hasOwnProperty(action) && permission.access[action];
  }


}
