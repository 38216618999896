import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { bit } from '../business-model-interfaces/application';
import { AssembleiaComentarios, GetAssembleiasParamsFullInfoAPI, OrdemTrabalhoParametros } from '../business-model-interfaces/assembleias';
import { CondominioTitularBancario, CondominioTitularBancarioDB } from '../business-model-interfaces/condominios';
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})
export class AssembleiasService {

  constructor(
      public api: ApiService,
      public toastr: ToastrService,
      public utils: UtilitiesService,
  ) { }


  saveAssembleiaComentario(id_assembleia: number, comentario: string): Promise<AssembleiaComentarios> {
    return new Promise((resolve) => {
      this.api.saveAssembleiaComentario(id_assembleia, comentario).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        resolve(null);
      })
    })
  }
  
  getParamsFullInfo(params: Array<OrdemTrabalhoParametros>): Promise<GetAssembleiasParamsFullInfoAPI['data']> {
    return new Promise((resolve) => {
      this.api.getAssembleiasParamFullInfo(params).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        resolve(null);
      })
    })
  }

  contaHasMovements(cod_conta): Promise<bit> {
    return new Promise((resolve) => {
      this.api.checkIfContaHasMovements(cod_conta).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        resolve(null);
      })
    })
  }

  entitiesCommunicationsAlreadySent(id_assembleia): Promise<{administradores: bit, titularesBancarios:Array<CondominioTitularBancarioDB>}> {
    return new Promise((resolve) => {
      this.api.entitiesCommunicationsAlreadySent(id_assembleia).subscribe(res => {
        if (res.success) {
          resolve({administradores: res.data.administradores, titularesBancarios: res.data.titulares_bancarios});
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        resolve(null);
      })
    })
  }

}
