import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent, forkJoin } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
import { Location, formatDate } from '@angular/common';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
interface IContext {
  data:string;
}

import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module-withfix';
Quill.register('modules/imageResize', ImageResize);

const Clipboard = Quill.import('modules/clipboard')
const Delta = Quill.import('delta')

class PlainClipboard extends Clipboard {
  onPaste (e) {
    let range;
    let text;
    let delta;
    let index;
    try {
      e.preventDefault();
      range = this.quill.getSelection();
      text = e.clipboardData.getData('text/plain');
      delta = new Delta()
        .retain(range.index)
        .delete(range.length)
        .insert(text);
      index = text.length + range.index;
      const length = 0;
      this.quill.updateContents(delta);  //, 'silent');
      setTimeout(() => this.quill.setSelection(index, length), 0);  //, 'silent');
      this.quill.scrollIntoView();
    } catch (err) {
    }
  }
}
Quill.register('modules/clipboard', PlainClipboard, true);

import { PreventLeaving } from '../prevent-leaving'
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';

import { EmailCartaComunicacaoModalComponent, EntityEmailCartaInput } from '../email-carta-comunicacao-modal/email-carta-comunicacao-modal.component';
import { Minuta } from '../business-model-interfaces/minuta';
import { SuiSelect } from 'ng2-semantic-ui/dist';
import { ViewSentEmailModalComponent } from '../view-sent-email-modal/view-sent-email-modal.component';
import { CustosComunicacoesComponent } from '../custos-comunicacoes/custos-comunicacoes.component';
import { TipoEntidade } from '../business-model-interfaces/comunicacoes';
import { BusinessLogicService } from '../business-logic.service';
import { EmailRecipientTypes } from '../business-model-interfaces/email';
import { CondominiosService } from '../business-logic-services/condominios.service';
// import { emitKeypressEvents } from 'readline';


interface EntityComunicacoes {
  cod:string,
  nomeEntidade: string,
  moradaEntidade: Array<any>,
  cod_condominio:string,
  condominioNome:string,
  moradaCondominio: Array<any>,
  cod_fraccao: string,
  nome_fraccao: string,
  checkedCarta: boolean,
  checkedEmail: boolean,
  communicationType: EmailRecipientTypes,
  emails: string,
  tipo:string
}
interface EntityAvisos {
  avisos:Array<any>,
  cod:string,
  nome: string,
  nif: string,
  nib: string,
  valor: number,
  moradaEntidade: Array<any>,
  cod_condominio:string,
  nome_condominio:string,
  moradaCondominio: Array<any>,
  cod_fraccao: string,
  nome_fraccao: string,
  checkedCarta: boolean,
  checkedEmail: boolean,
  subjectMsg:string,
  bodyMsg:string,
  email_1: string,
  email_2: string,
  tipo:string
}

interface EntitySelected {
  tipo: TipoEntidade,
  method: 'CARTA' | 'EMAIL'
  cod: string,
  nome: string,
  nome_primeiro_ultimo: string,
  email_1?: string,
  email_2?: string,
  cod_condominio?: string,
  nome_condominio?: string
  cod_fraccao?:string,
  nome_fraccao?:string,
}

type communicationField = 'bccSelected' | 'toSelected' | 'ccSelected' |  'cartaSelected' ;

@Component({
  selector: 'app-correspondencia',
  templateUrl: './correspondencia.component.html',
  styleUrls: ['./correspondencia.component.scss'],
})
export class CorrespondenciaComponent implements OnInit, PreventLeaving {

  componentType = "CORRESPONDENCIA";
  origin:'CORRESPONDENCIA_COM'|'CORRESPONDENCIA_AC' = null;
  needAllSelected:boolean = false;

  @ViewChild('changeMinutaAlertRef', { static: false }) changeMinutaAlertRef;
  changeMinutaModalRef = null;
  changeMinutaAlertConfig: any = null;

  @ViewChild('setCondAlertRef', { static: false }) setCondAlertRef;
  setCondModalRef = null;
  setCondAlertConfig: any = null;

  // TABS DEFINITION ----------------------------------------------------------
  tabsOrder = ['cartas-cobranca', 'comunicacoes', 'registo-comunicacoes', 'custos-comunicacoes'];
  tabsObjDef: Array<any> = [
    { key: 'cartas-cobranca', name: 'Avisos e Cobranças', url: 'cartascobranca', active: true, disabled: false },
    { key: 'comunicacoes', name: 'Comunicações', url: 'comunicacoes', active: false, disabled: true },
    { key: 'registo-comunicacoes', name: 'Registo Comunicações', url: 'registo-comunicacoes', active: false, disabled: true },
    { key: 'custos-comunicacoes', name: 'Custos Comunicações', url: 'custos-comunicacoes', active: false, disabled: true }
  ];
  selTabKey = 'cartas-cobranca';
  // GLOBAL VARIABLES ----------------------------------------------------------
  transitionController = new TransitionController();
  tapTransitionController = new TransitionController();
  loading = false;
  loadingModal = false;
  searchable = true;
  clearEntry = { name: '-- limpar selecção --', value: '-1' };
  clearEntryMinuta:Minuta = {  nome: '-- limpar selecção --', id: -1, assunto:null, texto: null };
  
  // FRACCOES TABLE VARIABLES
  fraccaoListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-align-center' },  // 'ASC', 'DESC'
    { key: 'zona', name: 'Zona', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0' },
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0' },
    { key: 'proprietario', name: 'Proprietário', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    // { key: 'inquilino', name: 'Inquilino', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'estado-contencioso', name: 'Estado Contencioso', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: false, centered: false, class: 'col-align-right z-index-0' },
  ];
  fraccaoListOrig: Array<any> = [];
  fraccaoList: Array<any> = [];

  tipoDocumentoOpts = [];
  selTipoDocumento = null;

  estadoContenciosoOpts = [];
  selEstadoContencioso = '-3';  // DEFAULT: TODOS

  remetenteOpts = [];
  remetenteOptsOrig = [];
  remetenteCompany = { name: 'VERTIS - Gestão Condomínios', value: 'geral@vertis.pt' };
  remetenteDefault = { name: 'VERTIS - Gestão Condomínios', value: 'geral@vertis.pt' };
  selRemetenteEmail = this.remetenteDefault.value;
  selRemetenteCircular = this.remetenteDefault.value;

  selCondominio = null;

  startDate = null;
  endDate = new Date();

  startValue = null;
  endValue = null;

  selCriterio:'DATE'|'VALUE' = 'DATE'; // DATE / VALUE

  docModel = null;

  cartasConbranca = [];

  @ViewChild('pdf', { static: false }) pdfController;
  pageMargin = null;
  now = new Date();

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  docLocal = 'Amora';
  docData = new Date();

  comp = 'correspondencia';
  initState = null;
  prevState = null;

  // FRACCOES CIRCULAR TABLE VARIABLES
  fraccaoCircularListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-align-center' },  // 'ASC', 'DESC'
    { key: 'zona', name: 'Zona', type: 'text', sort: null, searchable: true, centered: true, class: '' },
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: true, centered: true, class: '' },
    { key: 'proprietario', name: 'Proprietário', type: 'text', sort: null, searchable: false, centered: false, class: '' },
    { key: 'inquilino', name: 'Inquilino', type: 'text', sort: null, searchable: false, centered: false, class: '' },
  ];
  fraccaoCircularListOrig: Array<any> = [];
  fraccaoCircularList: Array<any> = [];

  metodoEnvio = 'CARTA';

  rececaoCorreioList = [];

  isZIndexZero = false;
  subsMsg = null;

  dividaTotal = null;

  exit = false;

  selMinuta: number = null;
  prevMinuta:Minuta = null;
  minutasOpts:Array<Minuta> = [];

  @ViewChild('minutasSelect', { static: false }) minutasSelect:SuiSelect<any, any>;
  @ViewChild('remetenteEmailSelect', { static: false }) remetenteEmailSelect:SuiSelect<any, any>;

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public route: ActivatedRoute,
              public router: Router,
              public userSession: UserSessionService,
              public api: ApiService,
              public cdRef:ChangeDetectorRef,
              public location: Location,
              public utils: UtilitiesService,
              public appState: AppStateService,
              public appConfig: AppConfigService,
              public businessLogic: BusinessLogicService,
              public condominios: CondominiosService,
              public message: MessageService) {

    // GET GLOBAL STATE
    let globalState = this.appState.getGlobalState('global');
    if (globalState && globalState.hasOwnProperty('selCondominio')) {
      this.selCondominio = (globalState.selCondominio) ? { name: globalState.selCondominio.cod + ' - ' + globalState.selCondominio.nome, value: globalState.selCondominio } : null;
      this.getFraccoes();
    }

    // CHECK AND SET LOCAL STATE
    if (this.appState.avisosCobrancas.active) {
      this.selTipoDocumento = this.appState.avisosCobrancas.selTipoDocumento;
      this.selEstadoContencioso = this.appState.avisosCobrancas.selEstadoContencioso;
      this.selCriterio = this.appState.avisosCobrancas.selCriterio;
      this.startDate = this.appState.avisosCobrancas.startDate;
      this.endDate = this.appState.avisosCobrancas.endDate;
      this.appState.clearAvisosCobrancasState();
      if (this.selTipoDocumento) this.getDocDetails();
    }

    this.subsMsg = this.message.getMessage().subscribe(async msg => {
      if (msg.dest === 'CAIXA_VERTIS' || msg.dest === this.componentType || msg.dest === 'ALL') {
        switch (msg.cmd) {
          case 'HIDE_INPUT':
            this.isZIndexZero = true;
            break;
          case 'SHOW_INPUT':
            this.isZIndexZero = false;
            break;
          case 'UNSAVED_ALERT':
            if (this.exit){
              this.router.navigateByUrl(msg.url);
              break;
            }
            try {
              await this.presentSaveAllModal();
              this.exit = true;
              this.router.navigateByUrl(msg.url);
            } catch (err) {

            }
          break;
          case 'PERMISSIONS_UPDATED':
            this.checkPermissions();
        }
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {
    if (!this.formIsEmpty()) {
      $event.returnValue = false;
    }
  }

  saveGlobalState() {
    if (this.selCondominio && this.selCondominio !== '-1') {
      this.appState.saveGlobalState('global', { 
        selCondominio: { id: this.selCondominio.id, cod: this.selCondominio.cod, nome: this.selCondominio.nome, exercicio: this.selCondominio.exercicio },
      });
    } else {
      this.appState.clearGlobalState();
    }
  }

  ngAfterViewChecked() { 
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.checkPermissions();

    this.setPrevState();

    // GET ESTADO CONTENCIOSO OPTIONS
    this.api.getEstadosContencioso().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        let auxEntries = [
          { name: 'Todos', value: '-3' },
          { name: 'Todas em contencioso', value: '-1' },
          { name: 'Todas sem contencioso', value: '-2' },
        ];

        this.estadoContenciosoOpts = auxEntries.concat(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
      } else {}
    }, err => {});

    // GET TIPOS DOCUMENTOS OPTIONS
    this.api.getTextosOptions().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.tipoDocumentoOpts = [this.clearEntry].concat(res.data.map(el => { return { name: el.nome, value: el.id }; }));
      } else {}
    }, err => {});

    this.api.getUtilizadores(0,500).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.remetenteOptsOrig = res.data;

        res.data = res.data.filter(el => el.first_name && el.email);

        if (!this.c_selectAnyRemetente) {
          res.data = res.data.filter(el => el.username === this.userSession.getUsername());
        }

        let user = res.data.find(el => el.username === this.userSession.getUsername())
        if (user) {
          if (!this.stateWasSaved) this.selRemetenteEmail = user.email;  
          this.remetenteDefault = { name: user.first_name  + (user.last_name? ' ' + user.last_name : '') + ' | ' + this.appConfig.company.emailFromName , value: user.email };
          let firstEntries = [
            this.remetenteDefault,
            this.remetenteCompany
          ]
          this.remetenteOpts = firstEntries.concat(
            res.data.reduce((filtered, el) => {
              if (el.username !== user.username) {
                return filtered.concat({ name: el.first_name + (el.last_name? ' ' + el.last_name : '') +  ' | ' + this.appConfig.company.emailFromName , value: el.email });
              }
              return filtered;
            }, []));
        } else {
          this.remetenteOpts = [this.remetenteCompany].concat(
            res.data.reduce((filtered, el) => {
              return filtered.concat({ name: el.first_name + (el.last_name? ' ' + el.last_name : '') + ' | ' + this.appConfig.company.emailFromName , value: el.email });
            }, []));
        }

      } else {}
    }, err=> {});

    this.getAllContactsbyCondominio();

    this.api.getAllMinutas().subscribe(res => {
      if (res.success) {
        this.utils.sort('minutas', res.data);
        this.minutasOpts = [this.clearEntryMinuta].concat(res.data);
      }
    }, err => {});

    this.animate();
  }


  @ViewChild('startValueRef', { static: false }) startValueRef: ElementRef;
  @ViewChild('endValueRef', { static: false }) endValueRef: ElementRef;

  ngAfterViewInit() {
    if (this.userSession.checkUserAccess('CORRESPONDENCIA_RC','read')) {
      fromEvent(this.searchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
        this.keywordCtt = val['target']['value'].toLowerCase().trim();
  
        this.getRegistoComunicacoes();
      });
    }

    if (this.userSession.checkUserAccess('CORRESPONDENCIA_AC','read')) {
      fromEvent(this.startValueRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
        this.getFraccoes();
      });
      fromEvent(this.endValueRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
        this.getFraccoes();
      });
      fromEvent(this.condTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
        this.tableSearch(val['target']['value']);
      });
    }
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.addAnexosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addAnexosAlertRef);
    this.addAnexosAlertConfig.closeResult = "closed";
    this.addAnexosAlertConfig.size = 'tiny';
    this.addAnexosAlertConfig.transition = 'fade';
    this.addAnexosAlertConfig.transitionDuration = 250;

    this.changeMinutaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.changeMinutaAlertRef);
    this.changeMinutaAlertConfig.closeResult = "closed";
    this.changeMinutaAlertConfig.size = 'small';
    this.changeMinutaAlertConfig.transition = 'fade';
    this.changeMinutaAlertConfig.transitionDuration = 250;

    this.contactosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.contactosAlertRef);
    this.contactosAlertConfig.isClosable = false;
    this.contactosAlertConfig.closeResult = "closed";
    this.contactosAlertConfig.size = 'large';
    this.contactosAlertConfig.transition = 'fade';
    this.contactosAlertConfig.transitionDuration = 250;

    this.saveStateAlertConfig = new TemplateModalConfig<IContext, string, string>(this.saveStateAlertRef);
    this.saveStateAlertConfig.isClosable = true;
    this.saveStateAlertConfig.closeResult = "closed";
    this.saveStateAlertConfig.size = 'tiny';
    this.saveStateAlertConfig.transition = 'fade';
    this.saveStateAlertConfig.transitionDuration = 250;

    this.setCondAlertConfig = new TemplateModalConfig<IContext, string, string>(this.setCondAlertRef);
    this.setCondAlertConfig.isClosable = true;
    this.setCondAlertConfig.closeResult = "closed";
    this.setCondAlertConfig.size = 'small';
    this.setCondAlertConfig.transition = 'fade';
    this.setCondAlertConfig.transitionDuration = 250;

    this.searchContenciosoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.searchContenciosoAlertRef);
    this.searchContenciosoAlertConfig.isClosable = true;
    this.searchContenciosoAlertConfig.closeResult = "closed";
    this.searchContenciosoAlertConfig.size = 'tiny';
    this.searchContenciosoAlertConfig.transition = 'fade';
    this.searchContenciosoAlertConfig.transitionDuration = 250;

    if (this.userSession.checkUserAccess('CORRESPONDENCIA_C','read')) {
      this.minutasSelect.searchInput.onQueryUpdated.subscribe(a => {
        if (a === '' && this.selMinuta) {
          let minuta = this.minutasOpts.find(el => el.id === this.selMinuta);
          if (minuta && minuta.id) this.minutasSelect.selectOption(minuta);
        }
      });
      
      this.remetenteEmailSelect.searchInput.onQueryUpdated.subscribe(a => {
        if (a === '' && this.selRemetenteEmail) {
          let remetente = this.remetenteOpts.find(el => el.value === this.selRemetenteEmail);
          if (remetente && remetente.value) this.remetenteEmailSelect.selectOption(remetente);
        }
      });
    }
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  confirmLeave = false;
  ngOnDestroy() {
    this.setState();

    if (this.subsMsg) this.subsMsg.unsubscribe();
    if (this.apiSubs) this.apiSubs.unsubscribe();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
  }

  warnBeforeLeave():boolean {
    return !this.exit && !this.formIsEmpty();
  }

  presentSaveAllModal() {
    return new Promise((resolve, reject) => {
      this.saveStateModalRef = this.modalService
      .open(this.saveStateAlertConfig)
      .onApprove((res:boolean) => { 
        if (res) {
          this.saveAll = true;
        } else {
          this.saveAll = false;
        }
        resolve(true);
        })
      .onDeny(() => { 
        reject(false);
      });
    });
  }


  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selCondominio); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
                } else {
                  return resolve([this.clearEntry]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
            } else {
              return resolve([this.clearEntry]);
            }
          });
        }
    });
  };

  setTab(targetList) {
    let tab = this.tabsObjDef.find(el => el.key === targetList);
    if (!tab || tab.disabled) {
      this.toastr.error(this.appConfig.errMsg.unAuth.msg,this.appConfig.errMsg.unAuth.title);
      return;
    }
    this.selTabKey = targetList;

    switch (targetList) {
      case 'cartas-cobranca':
        this.location.replaceState('/correspondencia/cartascobranca/');
        break;
      case 'comunicacoes':
        this.location.replaceState('/correspondencia/comunicacoes/');
        break;
      case 'registo-comunicacoes':
        this.getRegistoComunicacoes();
        this.location.replaceState('/correspondencia/registo-comunicacoes/');
        break;
      case 'custos-comunicacoes':
        if (this.custosComunicacoesRef) this.custosComunicacoesRef.getCustosComunicacoes();
        this.location.replaceState('/correspondencia/custos-comunicacoes/');
        break;
    }
  }

  fetchingDocDetails = false;
  getDocDetails() {
    if (this.selTipoDocumento === '-1') {
      this.selTipoDocumento = null;
      return;
    }

    this.fetchingDocDetails = true;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.api.getTextosDetails(this.selTipoDocumento).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.docModel = res.data;

        // CLEAR DATA
        if (this.docModel.text_inicial.indexOf('NEW_LINE') !== -1) {
          this.docModel.text_inicial = this.docModel.text_inicial.split(/NEW_LINE/g);
        } else {
          this.docModel.text_inicial = this.docModel.text_inicial.split('\n');
        }
        if (this.docModel.texto_final.indexOf('NEW_LINE') !== -1) {
          this.docModel.texto_final = this.docModel.texto_final.split(/NEW_LINE/g);
        } else {
          this.docModel.texto_final = this.docModel.texto_final.split('\n');
        }
        
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }

      this.fetchingDocDetails = false
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.fetchingDocDetails = false
      
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  formSubmittedFraccoes = false;
  fetchingFraccoes = false;
  nomeCondominio = null;
  apiSubs = null;
  getFraccoes() {
    if (this.apiSubs) this.apiSubs.unsubscribe();
    if (!this.selCondominio || !this.selEstadoContencioso) return;

    // if (this.selCriterio === 'VALUE' && !this.startValue && !this.endValue) {
    //   this.formSubmittedFraccoes = true;
    //   setTimeout(() => { this.formSubmittedFraccoes = false; }, 4000);
    //   return;
    // }

    let start = null;
    let end = null;
    switch (this.selCriterio) {
      case 'DATE': 
        start = this.startDate;
        end = this.endDate;
        break;
      case 'VALUE':
        start = this.startValue;
        end = this.endValue;
        break;
    }

    this.fraccaoListToggle = false;
    this.condominioListToggle = false;
    this.fraccaoListOrig = [];
    this.fraccaoList = [];
    this.dividaTotal = null;

    if (this.selCondominio === '-1') return;

    this.fetchingFraccoes = true;
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;
    this.nomeCondominio = (this.selCondominio && this.selCondominio.hasOwnProperty('nome')) ? this.selCondominio.nome : this.selCondominio.value.nome;

    this.apiSubs = this.api.fraccoesEmDivida(cod, this.selCriterio, start, end, this.selEstadoContencioso).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        res.data = res.data.filter(el => (el.hasOwnProperty('divida') && (Math.round(Number(el.divida) * 100) / 100) > 0));

        // FILTER AVISOS A ZERO
        res.data.forEach(el => {
          if (el.hasOwnProperty('avisos')) el['avisos'] = el['avisos'].filter(el => (el.hasOwnProperty('divida') && (Math.round(Number(el.divida) * 100) / 100) > 0));
        });

        this.fraccaoListOrig = res.data.sort((a, b) => {
          return a.cod_fraccao.length - b.cod_fraccao.length || a.cod_fraccao.localeCompare(b.cod_fraccao);
        });

        if (this.fraccaoListOrig.length === 0) this.fraccaoList = [];

        this.fraccaoList = [];
        let aux = null;

        res.data.forEach(el => {
          let condMoradaAux = el.morada;
          el.morada_proprietario_coresp = this.utils.formatEmptyMoradaObject(el.morada_proprietario_coresp);
          let propMoradaAux = (el.morada_proprietario_coresp && el.morada_proprietario_coresp !== '{"address":null,"postalCode":null,"locality":null}' && el.morada_proprietario_coresp !== '{"address":"","postalCode":"","locality":""}') ? el.morada_proprietario_coresp : el.morada_proprietario;

          // HANDLE OBJECT MORADA - CONDOMINIO
          try {
            let aux = '';
            let addressObj = JSON.parse(condMoradaAux);
            Object.keys(addressObj).forEach((key, i) => {
              if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE'); 
            });
        
            condMoradaAux = aux;
          } catch(e) {}
        
          // HANDLE OBJECT MORADA - CONDOMINIO
          try {
            let aux = '';
            let addressObj = JSON.parse(propMoradaAux);
            Object.keys(addressObj).forEach((key, i) => {
              if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE'); 
            });
        
            propMoradaAux = aux;
          } catch(e) {}

          aux = {
            checked: false,
            cod_condominio: el.cod_condominio,
            cod_fraccao: el.cod_fraccao,
            zona: el.zona_nome,
            fraccao: el.cod_fraccao + ' - ' + el.fraccao_nome,
            fraccao_nome: el.fraccao_nome,
            fraccao_id: el.fraccao_id,
            nome_proprietario: el.nome_proprietario,
            morada_proprietario: (propMoradaAux) ? propMoradaAux.split(/NEW_LINE/g) : [],
            inquilino: el.nome_inquilino,
            morada_inquilino: (el.morada_inquilino) ? el.morada_inquilino.split(/NEW_LINE/g) : null,
            valor: Number(el.divida),
            moradaCondominio: (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [],
            avisos: (el.avisos) ? el.avisos : [],

            nib: (el.hasOwnProperty('nib') && el.nib) ? ( (el.nib.indexOf('PT50') !== -1) ? el.nib : 'PT50' + el.nib ) : null,

            nif: (el.hasOwnProperty('nif')) ? el.nif : null,
            email_proprietario: el.email_proprietario,
            email_proprietario_2: el.email_proprietario_2,
            email_inquilino: el.email_inquilino,

            estadoContencioso: null,
            estadoContenciosoCod: el.cod_estado,
            cod_proprietario: el.cod_proprietario,
          }

          let estadoContAux = this.estadoContenciosoOpts.find(it => it.value === el.cod_estado);
          if (estadoContAux) aux.estadoContencioso = estadoContAux.name;

          this.fraccaoList.push(aux);
        });
        this.fraccaoListToggle = false;
        this.condominioListToggle = false;
        this.dividaTotal = this.fraccaoList.map(el => Number(el.valor)).reduce((a, b) => a + b, 0);
      } else {
        this.utils.apiErrorMsg(res);
      }

      this.fetchingFraccoes = false
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.apiSubs = null;
    }, err => {
      this.fetchingFraccoes = false
      
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.apiSubs = null;
    });
  }

  select(entry, target) {
    entry.checked = !entry.checked;
  }

  rowSelectionToggle(ev, target) {
    switch (target) {
      case 'cartas-cobranca':
        (ev.target.checked) ? this.fraccaoList.map(el => el.checked = true ) : this.fraccaoList.map(el => el.checked = false );
        break;
      case 'envio-em-lote':
        (ev.target.checked) ? this.condominiosList.map(el => el.checked = true ) : this.condominiosList.map(el => el.checked = false );
        break;
      case 'registo-comunicacoes':
        (ev.target.checked) ? this.registoCttList.map(el => el.checked = true ) : this.registoCttList.map(el => el.checked = false );
        break;
      case 'rececao-correio':
        (ev.target.checked) ? this.rececaoCorreioList.map(el => el.checked = true ) : this.rececaoCorreioList.map(el => el.checked = false );
        break;
    }
  }
  rowCommunicationToggle(tab:'por_condominio'|'por_nome', method, i) {
    switch (method) {
      case 'CARTA':
        if (tab === 'por_condominio') {
          let newValue = !!this.contactsListOrig[i].contactos.find(el => !el.checkedCarta);
          this.contactsListOrig[i].contactos.forEach((el, index) => {
            this.contactoChecked('por_condominio','CARTA',newValue, i, index);
          });
        } else if (tab === 'por_nome'){
          let newValue = !!this.contactsEntityListOrig[i].contactos.find(el => !el.checkedCarta);
          this.contactsEntityListOrig[i].contactos.forEach((el, index) => {
            this.contactoChecked('por_nome','CARTA',newValue, i, index);
          });
        }
        break;
      case 'EMAIL':
        if (tab === 'por_condominio') {
          let newValue = !!this.contactsListOrig[i].contactos.find(el => !el.checkedEmail && (el.email_1 || el.email_2));
          this.contactsListOrig[i].contactos.forEach((el, index) => {
            if (el.email_1 || el.email_2) {
              this.contactoChecked('por_condominio','EMAIL',newValue, i, index);
            }
          });
        } else if (tab === 'por_nome'){
          let newValue = !!this.contactsEntityListOrig[i].contactos.find(el => !el.checkedEmail && (el.email_1 || el.email_2));
          this.contactsEntityListOrig[i].contactos.forEach((el, index) => {
            if (el.email_1 || el.email_2) {
              this.contactoChecked('por_nome','EMAIL',newValue, i, index);
            }
          });
        }
        break;

    }
  }

  selCondominioCircular = null;
  fetchingFraccoesCircular = false;
  getFraccoesCircular() {
    if (this.fetchingFraccoesCircular || !this.selCondominioCircular) return;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.fetchingFraccoesCircular = true;
    this.api.getFraccoesCircular(this.selCondominioCircular.cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.fraccaoCircularListOrig = res.data;

        this.fraccaoCircularList = [];
        let aux = null;
        res.data.forEach(el => {
          let condMoradaAux = el.morada;
          el.morada_proprietario_coresp = this.utils.formatEmptyMoradaObject(el.morada_proprietario_coresp);
          let propMoradaAux = (el.morada_proprietario_coresp && el.morada_proprietario_coresp !== '{"address":null,"postalCode":null,"locality":null}' && el.morada_proprietario_coresp !== '{"address":"","postalCode":"","locality":""}') ? el.morada_proprietario_coresp : el.morada_proprietario;

          // HANDLE OBJECT MORADA - CONDOMINIO
          try {
            let aux = '';
            let addressObj = JSON.parse(condMoradaAux);
            Object.keys(addressObj).forEach((key, i) => {
              if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE'); 
            });
        
            condMoradaAux = aux;
          } catch(e) {}
        
          // HANDLE OBJECT MORADA - CONDOMINIO
          try {
            let aux = '';
            let addressObj = JSON.parse(propMoradaAux);
            Object.keys(addressObj).forEach((key, i) => {
              if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE'); 
            });
        
            propMoradaAux = aux;
          } catch(e) {}

          aux = {
            checked: false,
            cod_condominio: el.cod_condominio,
            cod_fraccao: el.cod,
            zona: el.zona_nome,
            fraccao: el.cod + ' - ' + el.fraccao_nome,
            proprietario: el.nome_proprietario,
            morada_proprietario: (propMoradaAux) ? propMoradaAux.split(/NEW_LINE/g) : [],
            inquilino: el.nome_inquilino,
            morada_inquilino: (el.morada_inquilino) ? el.morada_inquilino.split(/NEW_LINE/g) : null,
            moradaCondominio: (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [],
            email_proprietario: el.email_proprietario,
            email_proprietario_2: el.email_proprietario_2,
            email_inquilino: el.email_inquilino,
          }

          this.fraccaoCircularList.push(aux);
        });

      } else {
        this.utils.apiErrorMsg(res);
      }

      this.fetchingFraccoesCircular = false
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.fetchingFraccoesCircular = false
      
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  maxCondominiosSel = 5;
  checkMaxSelNumber(entry) {
    setTimeout(() => {
      let aux = this.condominiosList.filter(el => (el.checked));
      if (aux.length > this.maxCondominiosSel) {
        this.toastr.warning('O número máximo de condomínios selecionados igual a ' + this.maxCondominiosSel, 'Aviso', { timeOut: 4000 });
        entry.checked = false;
      }
    }, 1);
  }

  formSubmitted = false
  gerarDocumentos() {
    if (!this.selTipoDocumento) {
      this.formSubmitted = true;
      setTimeout(() => { this.formSubmitted = false; }, 4000);
      return;
    } 
    (this.envioLote)? this.sendByLote() : this.sendIndividually();

  }
  
  async sendIndividually() {
    this.cartasConbranca = JSON.parse(JSON.stringify(this.fraccaoList.filter(el => (el.checked))));

    let cartasConbrancaAux = [];
    this.cartasConbranca.forEach(carta => {
      carta.avisos.forEach(aviso => { aviso['fraccao'] = carta.fraccao; });

      let aux = cartasConbrancaAux.find(item => (item.proprietario === carta.proprietario));
      if (aux) {
        aux.avisos = aux.avisos.concat(carta.avisos);
      } else {
        cartasConbrancaAux.push(carta);
      }
    });
    this.cartasConbranca = cartasConbrancaAux;

    // COMPUTE TOTAL
    this.cartasConbranca.forEach(el => {
      if (Array.isArray(el.avisos)) {
        el.valor = 0;
        el.avisos.forEach(aviso => {
          el.valor += Number(aviso.divida);
        });
        el.valor = Math.round(el.valor * 100) / 100;
      } 
    });

    if (this.cartasConbranca.length === 0) {
      this.toastr.warning('Selecionar pelo menos uma fracção antes de gerar o relatório.', 'Ups...!', { timeOut: 4000 });
      return;
    }


    let nome_condominio = 'value' in this.selCondominio && 'nome' in this.selCondominio.value? this.selCondominio.value.nome : ('nome' in this.selCondominio ? this.selCondominio.nome : '');
    let entities = await this.getFormattedEntitiesAvisos(this.fraccaoList.filter(el => el.checked), nome_condominio);
    if (!entities.length) return;
    this.comunicaoModalRef.setEmailFrom(this.appConfig.company.email, this.appConfig.company.emailFromName);
    this.updateSharedInput('CORRESPONDENCIA_AC', true);
    this.comunicaoModalRef.setDataComunicacoes(false, null, null, null, []);
    this.comunicaoModalRef.open(null, entities, this.selTipoDocumento).then(res => {
      if (res.success) {
        this.saveRegistoAtividade('carta-cobranca');
        this.fraccaoList.map(fraccao => fraccao.checked = false);
        this.condominiosList.map(cond => cond.checked = false);
        this.condominioListToggle = false;
        this.fraccaoListToggle = false;
        this.cartasConbranca = [];
      }
    }).catch(err => {});
  }

  saveRegistoAtividade(key) {
    switch (key) {
      case 'carta-cobranca':
        let req = [];
        this.cartasConbranca.forEach(el => {
          // REGISTO ACTIVIDADES API CALL
          let titulo = 'Carta de Cobrança Criada';
          let descricao = 'Fracção: ' + el.fraccao + ', Condómino: ' + el.nome_proprietario;
          req.push(this.api.saveRegistoActividade(el.cod_condominio, null, null, titulo, descricao));
        });
        forkJoin(req).subscribe(res => {}, err => { });
        break;
    
      default:
        break;
    }
    
  }
  
  @ViewChild('pdfEmail', { static: false }) pdfControllerEmail;
  cartaEmail = null;
  emailList = [];
  emailReq = [];
  pdfEnabledEmail = false;

  filterNull(str) { return (!!str && str !== 'null') ? str : ''; }

  stateWasSaved = false;
  setPrevState() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);

    if (this.prevState) {
      // SET ACTIVE TAB
      if (this.prevState.state.hasOwnProperty('activeTab') && !!this.prevState.state.activeTab) {
          this.tabsObjDef.forEach(tab => {
            if (tab.key === this.prevState.state.activeTab.key) {
              tab.active = true;
              this.selTabKey = tab.key;
            }
          });
          this.setTab(this.prevState.state.activeTab.key);
      }

      this.saveAll = ( this.prevState.state.hasOwnProperty('saveAll') ) ? this.prevState.state.saveAll : null;
      if (this.saveAll) {
        this.stateWasSaved = true;
        this.selMinuta = ( this.prevState.state.hasOwnProperty('selMinuta') && this.prevState.state.selMinuta) ? this.prevState.state.selMinuta : null;
        this.emailGeralSubject = ( this.prevState.state.hasOwnProperty('emailGeralSubject') && this.prevState.state.emailGeralSubject) ? this.prevState.state.emailGeralSubject : null;
        this.htmlContentEmails = ( this.prevState.state.hasOwnProperty('htmlContentEmails') && this.prevState.state.htmlContentEmails) ? this.prevState.state.htmlContentEmails : null;
        this.toSelected = ( this.prevState.state.hasOwnProperty('toSelected') && this.prevState.state.toSelected) ? this.prevState.state.toSelected : null;
        this.bccSelected = ( this.prevState.state.hasOwnProperty('bccSelected') && this.prevState.state.bccSelected) ? this.prevState.state.bccSelected : null;
        this.ccSelected = ( this.prevState.state.hasOwnProperty('ccSelected') && this.prevState.state.ccSelected) ? this.prevState.state.ccSelected : null;
        this.cartaSelected = ( this.prevState.state.hasOwnProperty('cartaSelected') && this.prevState.state.cartaSelected) ? this.prevState.state.cartaSelected : null;
        this.anexosGeralList = ( this.prevState.state.hasOwnProperty('anexosGeralList') && this.prevState.state.anexosGeralList) ? this.prevState.state.anexosGeralList : null;
        this.emailGeralList = ( this.prevState.state.hasOwnProperty('emailGeralList') && this.prevState.state.emailGeralList) ? this.prevState.state.emailGeralList : null;
        this.selRemetenteEmail = ( this.prevState.state.hasOwnProperty('selRemetenteEmail') && this.prevState.state.selRemetenteEmail) ? this.prevState.state.selRemetenteEmail : null;
        this.allContacts = ( this.prevState.state.hasOwnProperty('allContacts') && this.prevState.state.allContacts) ? this.prevState.state.allContacts : null;
        this.allContactsEntity = ( this.prevState.state.hasOwnProperty('allContactsEntity') && this.prevState.state.allContactsEntity) ? this.prevState.state.allContactsEntity : null;
      }

      this.appState.clearPrevState(this.comp);
    }
  }

  // ENVIO EM LOTE SECTION - START ---------------------------------------------------
  envioLote = false;
  @ViewChild('condTableSearch', { static: false }) condTableSearch: ElementRef;
  condominioListCol = [
    { key: 'cod', name: 'Código', type: 'text', sort: null, searchable: true, class: 'two wide z-index-0' },
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, class: 'thirteen wide z-index-0' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class: 'table-checkbox-column one wide' },  // 'ASC', 'DESC'
  ];
  condominiosListOrig = [];  
  condominiosList = [];
  condSearching = false;
  condominioListToggle = false;
  fraccaoListToggle = false;

  sendByLote(): void {
    if (!this.condominiosList.find(el => el.checked)) {
      this.toastr.warning('Selecionar pelo menos um condomínio antes de gerar o relatório.', 'Ups...!', { timeOut: 4000 });
      return;
    }

    let aux = this.condominiosList.filter(el => (el.checked));
    if (aux.length > this.maxCondominiosSel) {
      this.toastr.warning('O número máximo de condomínios selecionados igual a ' + this.maxCondominiosSel, 'Aviso', { timeOut: 4000 });
      return;
    }
    this.getFraccoesLote(aux);
  }

  envioLoteToggle() {
    if (this.envioLote) {
      if (this.condominiosListOrig.length === 0) {
        this.getCondominiosList();
      } 
    } else { }
  }

  getCondominiosList() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.api.getAllCondominios('NULL', true).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.condominiosListOrig = res.data.map(el => {
          el['checked'] = false;
          return el;
        });
        this.condominiosList = this.condominiosListOrig;
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  tableSearch(keyword) {
    keyword = keyword.toLowerCase().trim();
    if (keyword) {
      this.condSearching = true;

      // SAVE SELECTION STATE
      this.condominiosList.forEach(cond => {
        let aux = this.condominiosListOrig.find(el => (el.cod === cond.cod));
        if (aux) aux.ckecked = cond.checked;
      });

      this.condominiosList = this.utils.tableSearch(keyword, this.condominioListCol, this.condominiosList);
      this.condSearching = false;
    } else {
      this.condominiosList = this.condominiosListOrig;
    }
  }

  fetchingFraccoesLote = false;
  getFraccoesLote(condominios) {
    if (this.fetchingFraccoesLote || !this.selEstadoContencioso || condominios.length === 0) return;

    if (this.selCriterio === 'VALUE' && !this.startValue && !this.endValue) {
      this.formSubmittedFraccoes = true;
      setTimeout(() => { this.formSubmittedFraccoes = false; }, 4000);
      return;
    }

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    let start = null;
    let end = null;
    switch (this.selCriterio) {
      case 'DATE': 
        start = this.startDate;
        end = this.endDate;
        break;
      case 'VALUE':
        start = this.startValue;
        end = this.endValue;
        break;
    }

    this.fetchingFraccoesLote = true;

    let req = [];
    condominios.forEach(el => {
      req.push(this.api.fraccoesEmDivida(el.cod, this.selCriterio, start, end, this.selEstadoContencioso));
    });

    this.loading = true;
    forkJoin(req).subscribe(res => {
      if (res[0].hasOwnProperty('success') && res[0].success) {

        let fraccaoListLoteOrig = [];
        res.forEach(req => {
          fraccaoListLoteOrig = fraccaoListLoteOrig.concat(req.data);
        });

        if (fraccaoListLoteOrig.length === 0) { 
          this.toastr.warning('Nenhum resultado encontrado para os critérios selecionados.', 'Aviso', { timeOut: 4000 });
          this.loading = false;
          this.fetchingFraccoesLote = false
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          return;
        }


        this.cartasConbranca = [];
        res.forEach((res2, i) => {
          this.cartasConbranca = this.cartasConbranca.concat(this.getFormattedEntitiesAvisos(res2.data, condominios[i].nome));
        })
        this.updateSharedInput('CORRESPONDENCIA_AC', true);
        
        this.comunicaoModalRef.open(null, this.cartasConbranca, this.selTipoDocumento).then(res => {
          if (res.success) {
            this.saveRegistoAtividade('carta-cobranca');
            this.fraccaoList.map(fraccao => fraccao.checked = false);
            this.condominiosList.map(cond => cond.checked = false);
            this.condominioListToggle = false;
            this.fraccaoListToggle = false;
            this.cartasConbranca = [];
          }
        }).catch(err => {});

      } else {
        this.utils.apiErrorMsg(res);
      }

      this.loading = false;
      this.fetchingFraccoesLote = false
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.loading = false;
      this.fetchingFraccoesLote = false
      
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }
  // ENVIO EM LOTE SECTION - END ----------------------------------------------------



  // REGISTO CTT --------------------------------------------------------------------
  pdfReport = {
    title: null,
    reportType: 'Registo Ctt',
    startDate: null,
    endDate: null,
    now: new Date(),
  }

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('searchRef', { static: false }) searchRef: ElementRef;

  @ViewChild('saveStateAlertRef', { static: false }) saveStateAlertRef;
  saveStateModalRef = null;
  saveStateAlertConfig: any = null;

  registoCttListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column col-edit-width col-align-center' },  // 'ASC', 'DESC'
    { key: 'metodo', name: 'Método', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-100px' },
    { key: 'nome_utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left col-60px' },
    { key: 'data', name: 'Data', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered col-100px' },
    { key: 'nome_condominio', name: 'Condomínio', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-300px' },
    { key: 'nome_condomino', name: 'Condómino(s)', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-300px' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left' },
  ];
  // registoCttListColPDF = [
  //   { key: 'nome_utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left z-index-0 col-80px' },
  //   { key: 'data', name: 'Data', type: 'date', sort: null, searchable: true, centered: true, class: 'col-align-left z-index-0 col-100px' },
  //   { key: 'nome_condominio', name: 'Condominio', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left z-index-0 col-250px' },
  //   { key: 'nome_condominio', name: 'Condómino(s)', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left z-index-0' },
  //   { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left z-index-0' },
  //   { key: 'tipo_correio', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-50px z-index-0' },
  //   { key: 'custo_ctt', name: 'Custo Ctt', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right z-index-0 col-100px' },
  //   { key: 'custo_vertis', name: 'Custo Vertis', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right z-index-0 col-100px' },
  //   { key: 'valor_lancado', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right z-index-0 col-100px' },
  // ];
  registoCttList: Array<any> = [];

  endDateCtt = new Date();
  startDateCtt = new Date(this.endDateCtt.getFullYear() - 1, 0, 1);
  searchingCtt = false;
  totalCtt = null;
  totalCustoCtt = null;
  totalCustoVertis = null;
  keywordCtt = null;

  tipoCorreio = 'TODOS';
  metodoComunicacao = 'TODOS';
  avisoRececaoDisabled = true;
  avisoRececao = false;

  fetchingRegistoCtt = false;
  getRegistoCtt() {
    if (this.fetchingRegistoCtt) return;

    // this.pdfEnabled = false;
    this.fetchingRegistoCtt = true;

    if (this.keywordCtt) this.searchingCtt = true; 

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getRegistoCtt(this.startDateCtt, this.endDateCtt, this.keywordCtt).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.fetchingRegistoCtt = false;

        this.generateRegistoCttListObj(res.data);
      } else {
        this.utils.apiErrorMsg(res);
      }

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingRegistoCtt = false;
      this.searchingCtt = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingRegistoCtt = false;
      this.searchingCtt = false;
    });
  }

  page = 1;
  limit = 20;
  noMoreData = false;
  apiSub;
  searchingComunicacoes = false;
  @ViewChild('viewSentEmailAlertRef', { static: false }) viewSentEmailAlertRef: ViewSentEmailModalComponent;
  @ViewChild('custosComunicacoesRef', { static: false }) custosComunicacoesRef: CustosComunicacoesComponent;
  getRegistoComunicacoes(init=true) {

    if (!this.rc_read) return;

    if (init) {
      this.noMoreData = false;
      this.page = 1;
      this.registoCttList = [];
      this.totalCtt = 0;
      this.totalCustoCtt = 0;
      this.totalCustoVertis = 0;
    }

    if (this.fetchingRegistoCtt) return;

    
    if (this.metodoComunicacao === 'EMAIL') {
      this.tipoCorreio = 'TODOS';
      this.avisoRececao = false;
    }

    let tipoCorreio = this.tipoCorreio;
    if (this.avisoRececao) tipoCorreio = 'REGISTADO_AR';
    this.searchingComunicacoes = true;
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.apiSub = this.api.getRegistoComunicacoes(this.metodoComunicacao, tipoCorreio, this.keywordCtt, this.page, this.limit, this.startDateCtt, this.endDateCtt).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.fetchingRegistoCtt = false;
        if (!res.data.reg_com.length) this.noMoreData = true;
        this.generateRegistoCttListObj(res.data.reg_com);

      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingRegistoCtt = false;
      this.searchingCtt = false;
      this.searchingComunicacoes = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingRegistoCtt = false;
      this.searchingCtt = false;
      this.searchingComunicacoes = false;
    });
  }

  onScrollRegistoComs() {
    if (this.noMoreData || !this.apiSub.closed) return;
    this.page += 1;
    this.getRegistoComunicacoes(false);
  }


  filterLocalRegistoCtt() {
   
    switch (this.tipoCorreio) {
      case 'TODOS':
        this.avisoRececao = false;
        this.avisoRececaoDisabled = true;
        break;
      case 'SIMPLES':
        this.avisoRececao = false;
        this.avisoRececaoDisabled = true;
        break;
      case 'NORMAL':
        this.avisoRececao = false;
        this.avisoRececaoDisabled = true;
        break;
      case 'REGISTADO':
        this.avisoRececaoDisabled = false;
        break;
    }
  }

  generateRegistoCttListObj(data) {
    data.forEach((el, i) => {
      let nome_condominos = el.nome_condominos ? el.nome_condominos : null;
      if (el.nome_fornecedores) {
        nome_condominos = nome_condominos ? nome_condominos + ', ' + el.nome_fornecedores : el.nome_fornecedores;
      }
      
      let aux = {
        checked: false,
        id: el.id,
        id_despesa: el.id_despesa,
        n_despesa: el.n_despesa,
        nome_utilizador: this.utils.getNomeUtilizadorIniciais(el.nome_utilizador),
        data: el.data,
        nome_condominio: (el.nome_condominio) ? el.nome_condominio : null,
        nome_condomino: nome_condominos,
        cod_condominio: (el.cod_condominio) ? el.cod_condominio : null,
        descricao: el.descricao,

        conta_simples: (el.conta_simples === '1'),
        registado: (el.registado === '1'),
        correiro_normal: (el.correiro_normal === '1'),
        aviso_recepcao: (el.aviso_recepcao === '1'),

        obj: el.obj,
        tipo: el.tipo,
        assunto: el.assunto,
        corpo: el.corpo,
        id_file: el.id_file,
        valor: el.valor,
      }
      aux['tipo_correio'] = null;
      if (aux['conta_simples']) {
        aux['tipo_correio'] = 'Simples';
      } else if (aux['correiro_normal']) {
        aux['tipo_correio'] = 'Normal';
      } else if (aux['registado']) {
        if (aux['aviso_recepcao']) {
          aux['tipo_correio'] = 'Registado (AR)';
        } else {
          aux['tipo_correio'] = 'Registado';
        }
      }

      // if (aux.tipo === 'EMAIL') {
      //   try {
      //     let emails = JSON.parse(el.email_list);
      //     if (emails && emails.length) {
      //       aux['nome_condomino'] = emails.map(el => {
      //         if (el.nomeEntidade) return el.nomeEntidade;
      //         return el.email.length? el.email.join(', ') : ''
      //     }).join(', ');
      //     }
      //   } catch(e) {}
      // }
      this.registoCttList.push(aux);

    });

    this.filterLocalRegistoCtt();
  }


  goToDetails(registoCtt) {
    if (registoCtt.tipo === 'EMAIL') {
      this.viewSentEmailAlertRef.open(registoCtt.id);
    } else {
      if (registoCtt.id_despesa == null) {
        this.toastr.info('A comunicação não tem despesa associada.', 'Informação');
        return;
      }
      this.setState(true);

      this.router.navigate(['lancamentos/despesa', registoCtt.id_despesa]);

      // Emit signal to breadcrumb component
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `DESPESA / ${registoCtt.n_despesa}` });
    }
  }

  downloadCarta(row) {
    if (!row.id_file) {
      this.toastr.info('Não é possível visualizar a carta relativa a esta comunicação.','Informação');
      return;
    }
    this.businessLogic.getAndDownloadFile(row.id_file);
  }


  setState(saveAll=false) {
    let state = {          
      activeTab: this.tabsObjDef.find(el => (el.key === this.selTabKey)),

      saveAll: this.saveAll,
      selMinuta: null,
      emailGeralSubject: null,
      htmlContentEmails: null,
      bccSelected: null,
      ccSelected: null,
      toSelected: null,
      cartaSelected: null,
      anexosGeralList: null,
      emailGeralList: null,
      selRemetenteEmail: null,
      allContacts: null,
      allContactsEntity: null,
      differentCondominios: null,
    }
    if (this.saveAll) {
      state.anexosGeralList = this.anexosGeralList;
      state.selMinuta = this.selMinuta;
      state.emailGeralSubject = this.emailGeralSubject;
      state.htmlContentEmails = this.htmlContentEmails;
      state.bccSelected = this.bccSelected;
      state.ccSelected = this.ccSelected;
      state.toSelected = this.toSelected;
      state.cartaSelected = this.cartaSelected;
      state.anexosGeralList = this.anexosGeralList;
      state.emailGeralList = this.emailGeralList;
      state.selRemetenteEmail = this.selRemetenteEmail;
      state.allContacts = this.allContacts;
      state.allContactsEntity = this.allContactsEntity;
    }
    if (saveAll) {
      state['startDateCtt'] = this.startDateCtt;
      state['endDateCtt'] = this.endDateCtt;
      state['keywordCtt'] = this.keywordCtt;
    }

    this.appState.setPrevState(this.comp, state);
  }

  toDelete = [];
  presentDeleteModal() {
    if (!this.rc_delete) {
      this.toastr.error(this.appConfig.errMsg.unAuth.msg,this.appConfig.errMsg.unAuth.title);
      return;
    }
    this.toDelete = this.registoCttList.filter(el => el.checked);
    if (this.toDelete.length > 0) {
      
      this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => { this.loadingModal = false; this.toDelete = []; })
      .onDeny(() => { this.loadingModal = false; this.toDelete = []; });

    } else {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
    }
  }

  del() {
    this.loadingModal = true;

    let email = this.toDelete.filter(el => el.tipo === 'EMAIL');
    let carta = this.toDelete.filter(el => el.tipo === 'CARTA');

    let req = [];

    if (carta.length) req.push(this.api.delRegistoCtt(carta, 'REGISTO_COMUNICACOES','BOTH'));
    if (email.length) req.push(this.api.delRegistoComunicacao(email));

    forkJoin(req).subscribe(res => {
      if (res.find(el => el.hasOwnProperty('success') && el.success)) {
        if (carta.length) {
          if (res[0].hasOwnProperty('success') && res[0].success) {
            this.registoCttList = this.registoCttList.filter(el => !carta.find(c => c.id == el.id));
          } else {
            this.utils.apiErrorMsg(res[0]);
          }

          if (email.length) {
            if (res[1].hasOwnProperty('success') && res[1].success) {
              this.registoCttList = this.registoCttList.filter(el => !email.find(e => e.id == el.id));
            } else {
              this.utils.apiErrorMsg(res[1]);
            }
          }
        } else if (email.length) {
          if (res[0].hasOwnProperty('success') && res[0].success) {
            this.registoCttList = this.registoCttList.filter(el => !email.find(e => e.id == el.id));
          } else {
            this.utils.apiErrorMsg(res[0]);
          }
        }
      } else {
          this.utils.apiErrorMsg(res[0]);
      }
      this.alertModalRef.approve();
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }


  // EMAIL SECTION ------------------------------------------------------------------
  @ViewChild('contactosAlertRef', { static: false }) contactosAlertRef;
  contactosModalRef = null;
  contactosAlertConfig: any = null;

  @ViewChild('contactSearch', { static: false }) contactSearch: ElementRef;
  @ViewChild('contactSearchEntity', { static: false }) contactSearchEntity: ElementRef;
  contactSearching = false;

  @ViewChild('addAnexosAlertRef', { static: false }) addAnexosAlertRef;
  addAnexosModalRef = null;
  addAnexosAlertConfig: any = null;

  limitScroll = 15;
  nEmailAddr = null;
  emailGeralList = null;
  anexosGeralList: Array<{filename, base64, ext, size}> = [];
  emailGeralSubject = null;
  selContactTab = 'por_condominio';
  
  invalidEmail = false;
  invalidEmailAddr = false;
  isEmailValid() {
    if (!this.emailGeralSubject || this.emailGeralSubject.trim() === '' || !this.htmlContentEmails || this.htmlContentEmails.trim() === '' || !this.selRemetenteEmail) return false;

    if (this.emailGeralList && this.emailGeralList.trim() !== ''){
      // CHECK IF ALL EMAILS ARE WELL FORMATED
      let allAddr = this.emailGeralList.split(';').filter(el => (el !== '' && el !== null)).map(el => el.trim())
      
      for (let i = 0; i < allAddr.length; i++) {
        if (!this.utils.validateEmail(allAddr[i])) {
          this.invalidEmailAddr = true;
          setTimeout(() => { this.invalidEmailAddr = false; }, 4000);

          return false;
        }
      }
    }
    return true;
  }
  hasDestinatarios(): boolean {
    return this.getTotalEmails() > 0 || this.cartaSelected.length > 0;
  }

  formIsEmpty(): boolean {
    if (this.bccSelected && this.bccSelected.length) return false;
    if (this.toSelected && this.toSelected.length) return false;
    if (this.ccSelected && this.ccSelected.length) return false;
    if (this.cartaSelected && this.cartaSelected.length) return false;
    if (this.emailGeralSubject && this.emailGeralSubject.trim() !== '') return false;
    if (this.htmlContentEmails && this.htmlContentEmails.trim() !== '') return false;
    if (this.selRemetenteEmail != this.remetenteDefault.value) return false;
    return true;
  }

  contactTabSelected(tab){
    this.selContactTab = tab;

    switch (this.selContactTab) {
      case 'por_condominio':
        fromEvent(this.contactSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
          this.contactsSearch(val['target']['value']);
        }); 
        break;
      case 'por_nome':
        fromEvent(this.contactSearchEntity.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
          this.contactsSearch(val['target']['value']);
        }); 
        break;
    }
  }

  otherEmailsValid(): boolean {
    if (!this.emailGeralList || this.emailGeralList.trim() === '') return true;

    // CHECK IF ALL EMAILS ARE WELL FORMATED
    let allAddr = this.emailGeralList.split(';').filter(el => (el !== '' && el !== null)).map(el => el.trim())
    
    for (let i = 0; i < allAddr.length; i++) {
      if (!this.utils.validateEmail(allAddr[i])) {
        this.invalidEmailAddr = true;
        setTimeout(() => { this.invalidEmailAddr = false; }, 4000);
        return false;
      }
    }
    return true;
  }

  verifyOtherEmails() {
    if (!this.otherEmailsValid()) return;

    this.contactosModalRef.approve({ target: 'APP' });
  }

  openContactList: communicationField = null;
  allContactsEntity = false;
  allContacts = false;
  entidadesToAdd: Array<EntitySelected> = [];
  addingType: communicationField = null;
  selContactsMethod: 'Bcc' | 'Para' | 'Cc' | 'Carta'
  addEmailList(type: communicationField) {

    this.toastr.clear();
    if (!this.contactListFetched) {
      this.toastr.info('Por favor aguarde, a lista de contactos está a ser carregada.', 'Informação', { timeOut: 3000 });
      this.openContactList = type;
      return;
    }
    this.openContactList = null;

    switch (type) {
      case 'bccSelected':
        this.selContactsMethod = 'Bcc';
        break;
      case 'toSelected':
        this.selContactsMethod = 'Para';
        break;
      case 'ccSelected':
        this.selContactsMethod = 'Cc';
        break;
      case 'cartaSelected':
        this.selContactsMethod = 'Carta';
        break;
    
      default:
        break;
    }

    this.addingType = type;
    this.selContactTab = 'por_condominio';
    this.prepareSelectContactsInput();


    this.contactsList = this.contactsListOrig.slice(0, this.limitScroll);
    this.contactsEntityList = this.contactsEntityListOrig.slice(0, this.limitScroll);

    this.openContactList = null;
    let prevCheckboxContacts = this.allContacts;
    let prevCheckboxContactsEntity = this.allContactsEntity;
    if (type !== 'cartaSelected') {
      this.entidadesToAdd = [...this[type], ...this.cartaSelected];
    } else {
      this.entidadesToAdd = [...this.cartaSelected];
    }

    if (this.contactosModalRef) {
      this.contactosModalRef.deny();
    }
    this.contactosModalRef = this.modalService
      .open(this.contactosAlertConfig)
      .onApprove(res => {
        if (res && res.hasOwnProperty('target') && res['target'] === 'OUTLOOK') {
          // window.open('mailto:?bcc=alexandre.rodrigues.emidio@gmail.com', '_blank');
          return;
        }

        this.addOtherEmails();

        let differentCondominios = this.getTotalDifferentCondominios();
        
        if (differentCondominios > 1) {
          this.emailGeralList = null;

          let n_fornecedores = this.getTotalFornecedores(this.addingType) + this.entidadesToAdd.filter(el => el.tipo === 'FORNECEDOR').length;
          
          this.entidadesToAdd = this.entidadesToAdd.filter(el => el.tipo !== 'FORNECEDOR');
          this.bccSelected = this.bccSelected.filter(el => el.tipo !== 'FORNECEDOR');
          this.ccSelected = this.ccSelected.filter(el => el.tipo !== 'FORNECEDOR');
          this.toSelected = this.toSelected.filter(el => el.tipo !== 'FORNECEDOR');
          this.cartaSelected = this.cartaSelected.filter(el => el.tipo !== 'FORNECEDOR'); 
          if (n_fornecedores > 0) this.toastr.info('Não é possivel incluir fornecedores numa comunicação com diferentes condomínios. ','Informação');

          this.entidadesToAdd.map(el => el.tipo === 'OUTRO' ? el.cod_condominio = null : el);
          this.bccSelected.map(el => el.tipo === 'OUTRO' ? el.cod_condominio = null : el);
          this.ccSelected.map(el => el.tipo === 'OUTRO' ? el.cod_condominio = null : el);
          this.toSelected.map(el => el.tipo === 'OUTRO' ? el.cod_condominio = null : el);
          this.cartaSelected.map(el => el.tipo === 'OUTRO' ? el.cod_condominio = null : el);
        } else if (differentCondominios == 1) {
          let cod_condominio = this.getUniqueCond();
          this.entidadesToAdd = this.entidadesToAdd.map(entity => {
            entity.cod_condominio = (entity.tipo === 'FORNECEDOR' || entity.tipo === 'OUTRO') ? cod_condominio : entity.cod_condominio;
            return entity
          });
          this.updateAllCond(cod_condominio);
        } else if (differentCondominios == 0){
          this.updateAllCond(null);
        }
        

        if (type !== 'cartaSelected') {
          this.checkAndRemovePrev();
          this[type] = [...this.entidadesToAdd.filter(el => el.method === 'EMAIL')];
        }
        this.cartaSelected = [... this.entidadesToAdd.filter(el => el.method === 'CARTA')]

        this.addingType = null;
        this.emailGeralList = null;
        
      })
      .onDeny(() => {
        this.allContacts = prevCheckboxContacts;
        this.allContactsEntity = prevCheckboxContactsEntity;
        this.addingType = null;
        this.emailGeralList = null;
      });
  }

  getUniqueCond() {
    let cond = this.entidadesToAdd.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
    if (cond) return cond.cod_condominio;
    switch (this.addingType) {
      case 'bccSelected':
        if (!cond) cond = this.toSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        if (!cond) cond = this.cartaSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        if (!cond) cond = this.ccSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        break;
      case 'toSelected':
        if (!cond) cond = this.bccSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        if (!cond) cond = this.cartaSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        if (!cond) cond = this.ccSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        break;
      case 'ccSelected':
        if (!cond) cond = this.toSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        if (!cond) cond = this.cartaSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        if (!cond) cond = this.bccSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        break;
      case 'cartaSelected':
        if (!cond) cond = this.toSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        if (!cond) cond = this.bccSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        if (!cond) cond = this.ccSelected.find(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO');
        break;
      default:
        break;
    }
    
    return cond? cond.cod_condominio : null;
  }

  getTotalFornecedores(type: communicationField):number  {
    let total = type? this[type].filter(el => el.tipo === 'FORNECEDOR').length : 0;
    switch (type) {
      case 'bccSelected':
        total += this.ccSelected.filter(el => el.tipo === 'FORNECEDOR').length
              + this.cartaSelected.filter(el => el.tipo === 'FORNECEDOR').length
              + this.toSelected.filter(el => el.tipo === 'FORNECEDOR').length;
        break;
      case 'ccSelected':
        total += this.bccSelected.filter(el => el.tipo === 'FORNECEDOR').length
              + this.cartaSelected.filter(el => el.tipo === 'FORNECEDOR').length
              + this.toSelected.filter(el => el.tipo === 'FORNECEDOR').length;
        break;
      case 'toSelected':
        total += this.bccSelected.filter(el => el.tipo === 'FORNECEDOR').length
              + this.cartaSelected.filter(el => el.tipo === 'FORNECEDOR').length
              + this.ccSelected.filter(el => el.tipo === 'FORNECEDOR').length;
        break;
      case 'cartaSelected':
        total += this.bccSelected.filter(el => el.tipo === 'FORNECEDOR').length
              + this.toSelected.filter(el => el.tipo === 'FORNECEDOR').length
              + this.ccSelected.filter(el => el.tipo === 'FORNECEDOR').length;
        break;
      default:
        total += this.bccSelected.filter(el => el.tipo === 'FORNECEDOR').length
        + this.toSelected.filter(el => el.tipo === 'FORNECEDOR').length
        + this.cartaSelected.filter(el => el.tipo === 'FORNECEDOR').length
        + this.ccSelected.filter(el => el.tipo === 'FORNECEDOR').length;
        break;
    }
    return total;
  }

  getTotalOutros(type: communicationField):number {
    let total = type? this[type].filter(el => el.tipo === 'OUTRO').length : 0;
    switch (type) {
      case 'bccSelected':
        total += this.ccSelected.filter(el => el.tipo === 'OUTRO').length
                + this.toSelected.filter(el => el.tipo === 'OUTRO').length;
        break;
      case 'ccSelected':
        total += this.bccSelected.filter(el => el.tipo === 'OUTRO').length
                + this.toSelected.filter(el => el.tipo === 'OUTRO').length;
        break;
      case 'toSelected':
        total += this.bccSelected.filter(el => el.tipo === 'OUTRO').length
                + this.ccSelected.filter(el => el.tipo === 'OUTRO').length;
        break;
      default:
        total += this.bccSelected.filter(el => el.tipo === 'OUTRO').length
        + this.toSelected.filter(el => el.tipo === 'OUTRO').length
        + this.ccSelected.filter(el => el.tipo === 'OUTRO').length;
        break;
    }
    return total;
  }

  updateAllCond(cod) {
    this.bccSelected.map(el => el.cod_condominio = cod);
    this.toSelected.map(el => el.cod_condominio = cod);
    this.ccSelected.map(el => el.cod_condominio = cod);
    this.cartaSelected.map(el => el.cod_condominio = cod);
  }

  checkAndRemovePrev() {
    let preparedComparation = this.entidadesToAdd.filter(el => el.method !== 'CARTA' && el.tipo !== 'OUTRO').map(el => el.cod + '.' + el.cod_condominio + '.' + el.cod_fraccao);
    switch (this.addingType) {
      case 'bccSelected':
        for (let i = this.ccSelected.length - 1; i >= 0; i--) {
          let el = this.ccSelected[i];
          if (el.tipo === 'OUTRO' && this.entidadesToAdd.find(ent => ent.email_1 === el.email_1)) {
            this.removeEntidade('ccSelected', i);
          } else if (preparedComparation.indexOf(el.cod + '.' + el.cod_condominio + '.' + el.cod_fraccao) != -1) {
            this.removeEntidade('ccSelected', i);
          }
        }
        for (let i = this.toSelected.length - 1; i >= 0; i--) {
          let el = this.toSelected[i];
          if (el.tipo === 'OUTRO' && this.entidadesToAdd.find(ent => ent.email_1 === el.email_1)) {
            this.removeEntidade('toSelected', i);
          } else if (preparedComparation.indexOf(el.cod + '.' + el.cod_condominio + '.' + el.cod_fraccao) != -1) {
            this.removeEntidade('toSelected', i);
          }
        }
        break;
    
      case 'ccSelected':
        for (let i = this.bccSelected.length - 1; i >= 0; i--) {
          let el = this.bccSelected[i];
          if (el.tipo === 'OUTRO' && this.entidadesToAdd.find(ent => ent.email_1 === el.email_1)) {
            this.removeEntidade('bccSelected', i);
          } else if (preparedComparation.indexOf(el.cod + '.' + el.cod_condominio + '.' + el.cod_fraccao) != -1) {
            this.removeEntidade('bccSelected', i);
          }
        }
        for (let i = this.toSelected.length - 1; i >= 0; i--) {
          let el = this.toSelected[i];
          if (el.tipo === 'OUTRO' && this.entidadesToAdd.find(ent => ent.email_1 === el.email_1)) {
            this.removeEntidade('toSelected', i);
          } else if (preparedComparation.indexOf(el.cod + '.' + el.cod_condominio + '.' + el.cod_fraccao) != -1) {
            this.removeEntidade('toSelected', i);
          }
        }
        break;
      case 'toSelected':
        for (let i = this.bccSelected.length - 1; i >= 0; i--) {
          let el = this.bccSelected[i];
          if (el.tipo === 'OUTRO' && this.entidadesToAdd.find(ent => ent.email_1 === el.email_1)) {
            this.removeEntidade('bccSelected', i);
          } else if (preparedComparation.indexOf(el.cod + '.' + el.cod_condominio + '.' + el.cod_fraccao) != -1) {
            this.removeEntidade('bccSelected', i);
          }
        }
        for (let i = this.ccSelected.length - 1; i >= 0; i--) {
          let el = this.ccSelected[i];
          if (el.tipo === 'OUTRO' && this.entidadesToAdd.find(ent => ent.email_1 === el.email_1)) {
            this.removeEntidade('ccSelected', i);
          } else if (preparedComparation.indexOf(el.cod + '.' + el.cod_condominio + '.' + el.cod_fraccao) != -1) {
            this.removeEntidade('ccSelected', i);
          }
        }
        break;
      default:
        break;
    }
  }

  prepareSelectContactsInput() {
    // CLEAN SELECTIONS IF NO PREV INPUTED EMAIL ADDRESSES
    if (!this[this.addingType].length) {
      this.emailGeralList = null;
      this.contactsListOrig.forEach(el => {
        el.opened = false;
        el.checked = false;
        el.contactos.forEach(it => it.checkedEmail = false);
        el.contactos.forEach(it => it.checkedCarta = this.cartaSelected.find(carta => carta.cod == it.cod_contacto && carta.cod_condominio == el.cod && carta.cod_fraccao == it.cod_fraccao));
      });
      this.contactsEntityListOrig.forEach(el => {
        el.opened = false;
        el.checked = false;
        el.contactos.forEach(it => it.checkedEmail = false);
        el.contactos.forEach(it => it.checkedCarta = this.cartaSelected.find(carta => carta.cod == el.cod_contacto && carta.cod_condominio == el.cod_contacto && carta.cod_fraccao == it.cod_fraccao));
      });
    } else {
      let preparedSelected = this[this.addingType].filter(el => el.tipo !== 'OUTRO').map(el => el.cod + '.' + el.cod_condominio + '.' + el.cod_fraccao);
      let cartaPreparedSelected = this.cartaSelected.map(el => el.cod + '.' + el.cod_condominio + '.' + el.cod_fraccao);
      this.contactsListOrig.forEach(cond => {
        cond.opened = false;
        if (this.addingType === 'cartaSelected') {
          cond.contactos.forEach(contacto => contacto.checkedEmail = false);
        } else {
          cond.contactos.forEach(contacto => contacto.checkedEmail = (preparedSelected.indexOf( contacto.cod_contacto + '.' + cond.cod + '.' + contacto.cod_fraccao) != -1));
        }
        cond.contactos.forEach(contacto => contacto.checkedCarta = (cartaPreparedSelected.indexOf( contacto.cod_contacto + '.' + cond.cod + '.' + contacto.cod_fraccao) != -1));
        cond.checked = !cond.contactos.find(el => !el.checkedEmail && !el.checkedCarta); 
      });
      this.contactsEntityListOrig.forEach(entidade => {
        entidade.opened = false;
        if (this.addingType === 'cartaSelected') {
          entidade.contactos.forEach(contacto => contacto.checkedEmail = false);
        } else {
          entidade.contactos.forEach(contacto => contacto.checkedEmail = (preparedSelected.indexOf(entidade.cod_contacto + '.' + contacto.cod_condominio + '.' + contacto.cod_fraccao) != -1));
        }
        entidade.contactos.forEach(contacto => contacto.checkedCarta = (cartaPreparedSelected.indexOf( entidade.cod_contacto + '.' + contacto.cod_condominio + '.' + contacto.cod_fraccao) != -1));
        entidade.checked = !entidade.contactos.find(el => !el.checkedEmail && !el.checkedCarta); 
      });
      this.emailGeralList = this[this.addingType].filter(el => el.tipo === 'OUTRO').map(el => el.email_1).join(';');
    }

    // //Prevent showing emails from different condominiums
    // if (this.addingType !== 'bccSelected') {
    //   let conds = [... new Set(this.bccSelected.filter(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO').map(el => el.cod_condominio))];
    //   if (conds.length > 0) {
    //     this.contactsListOrig.filter(el => el.cod == conds[0]);
    //     this.contactsEntityList.forEach(el => el.contactos.filter(el => el.cod_condominio == conds[0]));
    //     this.contactsEntityList.filter
    //   }
    // }



    this.allContacts = !this.contactsListOrig.find(el => !el.checked);
    this.allContactsEntity = !this.contactsEntityListOrig.find(el => !el.checked);
  }

  addAnexosList() {
    this.filename = 'Nenhum ficheiro selecionado...';
    this.filesToUpload = [];

    this.addAnexosModalRef = this.modalService
      .open(this.addAnexosAlertConfig)
      .onApprove(() => {

        switch (this.selTabKey) {
          
          case 'comunicacoes':
              let anexosTotalSize = this.anexosGeralList.reduce((acc, el) => acc + el.size, 0);
              let limitExceeded = false;
              for (let i = 0; i < this.filesToUpload.length; i++) {
                if ((anexosTotalSize + this.filesToUpload[i].size) > 10485760) {
                  limitExceeded = true;
                  continue;
                }
                anexosTotalSize += this.filesToUpload[i].size;
                
                this.anexosGeralList.push({
                  filename: this.utils.getFileNameFormatted(this.filesToUpload[i].name),
                  base64: this.filesToUpload[i].base64File,
                  ext: this.filesToUpload[i].fileExt,
                  size: this.filesToUpload[i].size
                });
              }
              if (limitExceeded) this.toastr.error('Não foi possível adicionar todos anexos. O tamanho total dos anexos é superior ao tamanho máximo suportado (10Mb).<br>Tamanho atual: ' + (anexosTotalSize / 1000000).toFixed(2) + 'Mb' , 'Ups...!', { timeOut: 4000, enableHtml: true });
            break;
        }
        this.filesToUpload = [];
      })
      .onDeny(() => { this.filesToUpload = [] });
  }

  condominioChecked(ev, i) {
    this.contactsListOrig[i].contactos.forEach((el, index) => {
      if (this.addingType !== 'cartaSelected' && (el.email_1 || el.email_2)) {
        this.contactoChecked('por_condominio','EMAIL',ev.target.checked, i, index);
        this.contactoChecked('por_condominio','CARTA',false, i, index);
      } else {
        this.contactoChecked('por_condominio','CARTA',ev.target.checked, i, index);
      }
    });
  }

  entityChecked(ev, i) {
    this.contactsEntityListOrig[i].contactos.forEach((el, index) => {
      if (this.addingType !== 'cartaSelected' && (el.email_1 || el.email_2)) {
        this.contactoChecked('por_nome','EMAIL',ev.target.checked, i, index);
        this.contactoChecked('por_nome','CARTA',false, i, index);
      } else {
        this.contactoChecked('por_nome','CARTA',ev.target.checked, i, index);
      }
    });
  }

  addOtherEmails() {
    if (!this.emailGeralList) return;
    let allAddr:Array<string> = this.emailGeralList.split(';').filter(el => (el !== '' && el !== null)).map(el => el.trim());
    allAddr.forEach(email => {
      if (this.utils.validateEmail(email) && !this.entidadesToAdd.find(el => el.email_1 === email)) {
        let nomes = email.split('@');
        let aux: EntitySelected = {
          tipo: 'OUTRO', 
          method: 'EMAIL', 
          cod:null, 
          nome: nomes.length? nomes[0] : email, 
          nome_primeiro_ultimo: nomes.length? nomes[0] : email,
          email_1: email,
          cod_condominio: null,
        }
        this.entidadesToAdd.push(aux);
      }
    });
  }

  // emailAddrChange() {
  //   this.nEmailAddr = this.bccSelected.length;
  //   if (!this.emailGeralList) return;

  //   let allAddr = this.emailGeralList.split(';').filter(el => (el !== '' && el !== null)).map(el => el.trim());
  //   let aux = 0;
  //   for (let i = 0; i < allAddr.length; i++) {
  //     if (this.utils.validateEmail(allAddr[i])) aux++;
  //   }
  //   this.nEmailAddr += aux; 
  // }


  keywordCond = null;
  keywordEnt = null;
  contactsSearch(keyword) {
    this.contactSearching = true;

    switch (this.selContactTab) {
      case 'por_condominio':
        this.keywordCond = keyword.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        if (this.keywordCond) {
    
        this.contactsList = this.contactsListOrig
          .filter(el => (el.cod.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.keywordCond) !== -1 || el.nome_condominio.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.keywordCond) !== -1))
          .slice(0, this.limitScroll);
        } else {
          this.keywordCond = null;
          this.contactsList = this.contactsListOrig.slice(0, this.limitScroll);
        }
          
        break;
      case 'por_nome':
        this.keywordEnt = keyword.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        if (this.keywordEnt) {
    
        this.contactsEntityList = this.contactsEntityListOrig
          .filter(el => (el.nome_contacto.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.keywordEnt) !== -1 || el.contactos.find(it => (it.email_1 && it.email_1.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.keywordEnt) !== -1)) || el.contactos.find(it => (it.email_2 && it.email_2.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.keywordEnt) !== -1))))
          .slice(0, this.limitScroll);
        } else {
          this.keywordEnt = null;
          this.contactsEntityList = this.contactsEntityListOrig.slice(0, this.limitScroll);
        }

        
        break;
    }
    setTimeout(() => {
      this.contactSearching = false;
    }, 1);
  }

  porCondAllSelected = false;
  porNomeAllSelected = false;
  selectAllContacts(ev) {
    switch (this.selContactTab) {
      case 'por_condominio':
        if (ev.target.checked) {
          // RENDERED LIST
          this.contactsList.forEach(el => {
            el.checked = true;
            el.contactos.forEach(it => {
              if (this.addingType !== 'cartaSelected' && (it.email_1 || it.email_2)) {
                it.checkedEmail = true
              } else {
                it.checkedCarta = true
              }
            });
          });
          
          // FULL LIST
          this.contactsListOrig.forEach((el, i) => {
            el.checked = true;
            el.contactos.forEach((it, i_contact) => {
              if (this.addingType !== 'cartaSelected' && (it.email_1 || it.email_2)) {
                it.checkedEmail = true;
                this.contactoChecked('por_condominio', 'EMAIL',ev.target.checked, i, i_contact);
              } else {
                it.checkedCarta = true;
                this.contactoChecked('por_condominio', 'CARTA',ev.target.checked, i, i_contact);
              }
            });
          });
        } else {
          // RENDERED LIST
          this.contactsList.forEach(el => {
            el.checked = false;
            el.contactos.forEach(it => {
                it.checkedEmail = false
                it.checkedCarta = false
            });
          });
    
          // FULL LIST
          this.contactsListOrig.forEach((el, i) => {
            el.checked = false;
            el.contactos.forEach((it, i_contact) => {
                it.checkedEmail = false;
                it.checkedCarta = false;
                this.contactoChecked('por_condominio', 'EMAIL',ev.target.checked, i, i_contact);
                this.contactoChecked('por_condominio', 'CARTA',ev.target.checked, i, i_contact);
            });
          });
        }
        break;
      case 'por_nome':
        if (ev.target.checked) {
          // RENDERED LIST
          this.contactsEntityList.forEach(el => {
            el.checked = true;
            el.contactos.forEach(it => {
              if (this.addingType !== 'cartaSelected' && (it.email_1 || it.email_2)) {
                it.checkedEmail = true
              } else {
                it.checkedCarta = true
              }
            });
          });
    
          // FULL LIST
          this.contactsEntityListOrig.forEach((el, i) => {
            el.checked = true;
            el.contactos.forEach((it, i_contact) => {
              if (this.addingType !== 'cartaSelected' && (it.email_1 || it.email_2)) {
                it.checkedEmail = true;
                this.contactoChecked('por_nome', 'EMAIL',ev.target.checked, i, i_contact);
              } else {
                it.checkedCarta = true;
                this.contactoChecked('por_nome', 'CARTA',ev.target.checked, i, i_contact);
              }
            });
          });
        } else {
          // RENDERED LIST
          this.contactsEntityList.forEach(el => {
            el.checked = false;
            el.contactos.forEach(it => {
              it.checkedEmail = false
              it.checkedCarta = false
            });
          });
    
          // FULL LIST
          this.contactsEntityListOrig.forEach((el, i) => {
            el.checked = false;
            el.contactos.forEach((it, i_contact) => {
              it.checkedEmail = false;
              it.checkedCarta = false;
              this.contactoChecked('por_nome', 'EMAIL',ev.target.checked, i, i_contact);
              this.contactoChecked('por_nome', 'CARTA',ev.target.checked, i, i_contact);
            });
          });
        }
        break;
    }
  }

  onScroll() {
    switch (this.selContactTab) {
      case 'por_condominio':
        if (this.keywordCond) {
          this.contactsList = this.contactsList.concat(
            this.contactsListOrig
              .filter(el => (el.cod.toLowerCase().indexOf(this.keywordCond) !== -1 || el.nome_condominio.toLowerCase().indexOf(this.keywordCond) !== -1))
              .slice(this.contactsList.length, this.contactsList.length + this.limitScroll)
            );
        } else {
          this.contactsList = this.contactsList.concat(this.contactsListOrig.slice(this.contactsList.length, this.contactsList.length + this.limitScroll));
        }  
        break;
      case 'por_nome':
        if (this.keywordEnt) {
          this.contactsEntityList = this.contactsEntityList.concat(
            this.contactsEntityListOrig
              .filter(el => (el.cod_contacto.toLowerCase().indexOf(this.keywordEnt) !== -1 || el.nome_contacto.toLowerCase().indexOf(this.keywordEnt) !== -1))
              .slice(this.contactsEntityList.length, this.contactsEntityList.length + this.limitScroll)
            );
        } else {
          this.contactsEntityList = this.contactsEntityList.concat(this.contactsEntityListOrig.slice(this.contactsEntityList.length, this.contactsEntityList.length + this.limitScroll));
        }  
        break;
    }
  }

  removeAnexo(target) {
    switch (this.selTabKey) {
      case 'comunicacoes':
        this.anexosGeralList = this.anexosGeralList.filter(el => (el.base64 !== target.base64));
    } 
  }

  downloadAnexo(anexo: {filename:string, base64, ext, size}) {
    let contentType = 'data:' + anexo.ext + ';base64,';
    let lastIndex = anexo.filename.lastIndexOf('.');
    let filename = anexo.filename.substring(0, lastIndex? lastIndex : anexo.filename.length);
    this.utils.downloadFile(contentType + anexo.base64, filename);
  }

  contactsList = [];
  contactsListOrig:Array<{checked,index,opened,cod,nome_condominio,contactos:(Array<{checkedCarta, checkedEmail,tipo,email_1,email_2,cod_contacto,nome_contacto,cod_proprietario,cod_fraccao, nome_fraccao}>)}> = [];
  contactsEntityList = [];
  contactsEntityListOrig:Array<{checked,index,cod_contacto,opened,nome_contacto,tipo, contactos:Array<{checkedCarta, checkedEmail, cod_condominio, nome_condominio, cod_fraccao, nome_fraccao, email_1, email_2}>}> = [];


  contactListFetched = false;
  saveAll = false;
  getAllContactsbyCondominio() {
    if (!this.c_read) return;

    let req = [
      this.api.getContactsByCondominio(),
      this.api.getContactsByEntities()
    ];
    forkJoin(req).subscribe(res => {
      // BY CONDOMINIO
      if (res[0].hasOwnProperty('success') && res[0].success) {
        let prevCod = null;
        this.contactsListOrig = [];
        res[0].data.forEach(el => {
          if (prevCod === null || prevCod !== el.cod) {
            this.contactsListOrig.push({
              checked: false,
              index:0,
              opened: false,
              cod: el.cod,
              nome_condominio: el.nome_condominio,
              contactos: [{
                checkedCarta: false,
                checkedEmail: false,
                tipo: 'PROPRIETARIO',
                email_1: el.email_1,
                email_2: el.email_2,
                cod_contacto: el.cod_contacto,
                nome_contacto: el.nome_contacto,
                cod_proprietario: el.cod_proprietario,
                cod_fraccao:el.cod_fraccao,
                nome_fraccao:el.nome_fraccao,
              }],
            });
          } else {
            this.contactsListOrig[this.contactsListOrig.length -1].contactos.push({
              tipo: 'PROPRIETARIO',
              checkedCarta: false,
              checkedEmail: false,
              email_1: el.email_1,
              email_2: el.email_2,
              cod_contacto: el.cod_contacto,
              nome_contacto: el.nome_contacto,
              cod_proprietario: el.cod_proprietario,
              cod_fraccao:el.cod_fraccao,
              nome_fraccao:el.nome_fraccao,
            });
          }

          prevCod = el.cod;
        });
      } else {
        this.utils.apiErrorMsg(res[0]);
      }


      // BY ENTITY
      if (res[1].hasOwnProperty('success') && res[1].success) {
        this.contactsEntityListOrig = [];
        let prevCod = null;
        res[1].data.forEach((el, i) => {
          if (prevCod === null || prevCod !== el.cod_contacto) {
            prevCod = el.cod_contacto;
            let aux = {
              checked: false,
              index:0,
              opened: false,
              cod_contacto: el.cod_contacto,
              nome_contacto: el.nome_contacto,
              checkedEmail: false,
              checkedCarta: false,
              tipo: el.tipo === 'FORNECEDOR' ? el.tipo : 'PROPRIETARIO',
              contactos: [ {
                checkedCarta: false,
                checkedEmail: false,
                cod_condominio: el.cod_condominio,
                nome_condominio: el.nome_condominio,
                cod_fraccao:el.cod_fraccao,
                nome_fraccao:el.nome_fraccao,
                email_1: el.email_1,
                email_2: el.email_2,
              }],
            }
            this.contactsEntityListOrig.push(aux);
          } else {
            let contacto = {
              checkedCarta: false,
              checkedEmail: false,
              cod_condominio: el.cod_condominio,
              nome_condominio: el.nome_condominio,
              cod_fraccao:el.cod_fraccao,
              nome_fraccao:el.nome_fraccao,
              email_1: el.email_1,
              email_2: el.email_2,
            }
            this.contactsEntityListOrig[this.contactsEntityListOrig.length - 1].contactos.push(contacto);
          }
        });

      } else {
        this.utils.apiErrorMsg(res[1]);
      }

      this.contactsListOrig.forEach((contacto, i) => {
        contacto.index = i;
        contacto.contactos.sort((a,b) => {
          if (!a.cod_fraccao && !b.cod_fraccao) return 0;
          if (!a.cod_fraccao) return 1;
          if (!b.cod_fraccao) return -1;
          return (a.cod_fraccao as string).localeCompare(b.cod_fraccao as string);
        });
      });
      this.contactsEntityListOrig.forEach((contacto, i) => {
        contacto.index = i;
        contacto.contactos.sort((a,b) => {
          if (!a.cod_condominio && !b.cod_condominio) return 0;
          if (!a.cod_condominio) return 1;
          if (!b.cod_condominio) return -1;
          if (a.cod_condominio != b.cod_condominio) return Number(a.cod_condominio) - Number(b.cod_condominio);

          if (!a.cod_fraccao && !b.cod_fraccao) return 0;
          if (!a.cod_fraccao) return 1;
          if (!b.cod_fraccao) return -1;
          return (a.cod_fraccao as string).localeCompare(b.cod_fraccao as string);
        });
      });

      this.contactListFetched = true;
      if (this.openContactList) this.addEmailList(this.openContactList);
    }, err => { });
  }

  @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;
  filename = 'Nenhum ficheiro selecionado...';
  filesToUpload:Array<{base64File, name, fileExt, size}> = [];
  handleFileInput(files: FileList) {

    for (let i = 0; i < files.length; i++) {
      let fileToUpload = files[i];
      this.filename = (this.filename === 'Nenhum ficheiro selecionado...' || !this.filename) ? fileToUpload['name'] : '; ' + fileToUpload['name'];
      let filename = fileToUpload['name'];
      if (filename.indexOf('.pdf') === -1 && filename.indexOf('.png') === -1 && filename.indexOf('.jpeg') === -1 && filename.indexOf('.jpg') === -1) {
        this.toastr.error('O ficheiro selecionado não é suportado. Por favor, submeta um ficheiro com extensão uma das seguintes extensões: .pdf, .png, .jpeg, .jpg.' , 'Ups...!', { timeOut: 4000 });
        this.fileInputRef.nativeElement.value = '';
        this.filename = 'Nenhum ficheiro selecionado...';
        return;
      }
  
      if (fileToUpload.size > 8388608) {
        this.toastr.error('O ficheiro selecionado é superior ao tamanho máximo suportado (8Mb)' , 'Ups...!', { timeOut: 4000 });
        this.fileInputRef.nativeElement.value = '';
        this.filename = 'Nenhum ficheiro selecionado...';
        return;
      }
      let fileExt = null;
  
      if (fileToUpload['name'].indexOf('.pdf') !== -1) fileExt = 'application/pdf';
      if (fileToUpload['name'].indexOf('.png') !== -1) fileExt = 'image/png';
      if (fileToUpload['name'].indexOf('.jpeg') !== -1) fileExt = 'image/jpeg';
      if (fileToUpload['name'].indexOf('.jpg') !== -1) fileExt = 'image/jpg';

      const reader = new FileReader();
      reader.onload = (ev) => {
        let binaryString = ev.target['result'];
  
        let base64File = binaryString;
  
        switch (fileExt) {
          case 'application/pdf': base64File  = ((base64File as string) as string).replace('data:application/pdf;base64,', ''); break
          case 'image/png': base64File = (base64File as string).replace('data:image/png;base64,', ''); break
          case 'image/jpeg': base64File = (base64File as string).replace('data:image/jpeg;base64,', ''); break
          case 'image/jpg': base64File = (base64File as string).replace('data:image/jpg;base64,', ''); break
        }
        if (this.filesToUpload.find(anexo => anexo.base64File === base64File)) {
          return;
        } 
        if (this.anexosGeralList.find(anexo => anexo.base64 === base64File)) {
          this.toastr.info('O ficheiro ' + fileToUpload.name + ' já se encontra em anexo.', 'Informação');
          return;
        }
        this.filesToUpload.push({name: fileToUpload.name, fileExt: fileExt, base64File: base64File, size: fileToUpload.size});
      };
      reader.readAsDataURL(fileToUpload);
    }

  }
  // --------------------------------------------------------------------------------

  addCircularAssinatura = true;
  addEmailAssinatura = true;

  goTo(target, fraccao=null) {
    // SAVE PREV PAGE STATE
    this.appState.setAvisosCobrancasState(this.selTipoDocumento, this.selEstadoContencioso, this.selCriterio, this.startDate, this.endDate, this.startValue, this.endValue);

    switch (target) {
      case 'CONTENCIOSO':
        this.router.navigate(['fraccoes/contencioso', fraccao.cod_condominio, fraccao.fraccao_id]);
        break;
      case 'CONTA_CORRENTE':
        if (!this.selCondominio) { this.toastr.error('Nenhum condomínio selecionado.', 'Alerta', { timeOut: 4000 }); return; }

        if (!this.fraccaoList.find(el => el.checked)) { this.toastr.error('Nenhum condómino selecionado.', 'Alerta', { timeOut: 4000 }); return; }

        // SAVE CONTA CORRENTE SELECTED FRACÇÕES
        this.appState.setContaCorrenteFraccoesState(this.fraccaoList.filter(el => el.checked));

        this.router.navigate(['contascorrentes/fraccoes']);
        break;
      case 'RECIBOS':
        if (!this.selCondominio) { this.toastr.error('Nenhum condomínio selecionado.', 'Alerta', { timeOut: 4000 }); return; }

        this.appState.setPrevState('lancamentos', { activeTab: { key: 'recibos' } });

        this.router.navigate(['lancamentos/recibos/']);
        break;
    }
  }

  // HTML EDITOR VARIABLES AND METHODS SECTION (NGX-EDITOR) ---------------------------------------
  @ViewChild('editorCirculares', { static: false }) editorCirculares;
  @ViewChild('editorEmails', { static: false }) editorEmails;
  htmlContent: string = null;
  htmlContentEmails: string = null;
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['image'],
    ],
    imageResize: true
  };

  onContentCircularesChanged(ev) {
    this.htmlContent = ev.html;
  }

  onContentEmailsChanged(ev) {
    this.htmlContentEmails = ev.html;
  }

  contactoChecked(tab:'por_condominio'|'por_nome', method:'CARTA'|'EMAIL', checked:boolean, i, i_contact) {

    let checkField = method === 'CARTA' ? 'checkedCarta' : 'checkedEmail';
    let element: {checked,opened,cod,nome_condominio,contactos:Array<{checkedEmail, checkedCarta,tipo,email_1,email_2,cod_contacto,nome_contacto,cod_proprietario,cod_fraccao, nome_fraccao}>}|
    {checked,cod_contacto,opened,nome_contacto,tipo,contactos:Array<{checkedEmail, checkedCarta, cod_condominio, nome_condominio, cod_fraccao, nome_fraccao, email_1, email_2}>};
    let contacto = null;
    
    let cod_condominio = null;
    let nome_condominio = null;
    let cod_contacto = null;
    let nome_contacto = null;
    let cod_fraccao = null;
    let nome_fraccao = null;
    let tipo = null;
    if (tab === 'por_condominio') {
      let element = this.contactsListOrig[i];
      contacto = this.contactsListOrig[i].contactos[i_contact];

      cod_condominio = element.cod;
      nome_condominio = element.nome_condominio;
      cod_contacto = contacto.cod_contacto;
      nome_contacto = contacto.nome_contacto;
      cod_fraccao = contacto.cod_fraccao;
      nome_fraccao = contacto.nome_fraccao;
      tipo = contacto.tipo;

      //Updates por condominio table
      this.contactsListOrig[i].contactos[i_contact][checkField] = checked;
      !this.contactsListOrig[i].contactos.find(el => !el.checkedCarta && !el.checkedEmail)? this.contactsListOrig[i].checked = true : this.contactsListOrig[i].checked = false;

      //Updates por nome table
      this.contactsEntityListOrig = this.contactsEntityListOrig.map(el => {
        if (el.cod_contacto == cod_contacto) {
          el.contactos = el.contactos.map(cont => {
            if (cont.cod_condominio == cod_condominio && cont.cod_fraccao == cod_fraccao) {
              cont[checkField] = checked;
            }
            return cont;
          });
          !el.contactos.find(el => !el.checkedCarta && !el.checkedEmail) ? el.checked = true : el.checked = false;
        }
        return el;
      }); 
    } else if (tab === 'por_nome') {
      element = this.contactsEntityListOrig[i];
      contacto = this.contactsEntityListOrig[i].contactos[i_contact];
      
      cod_condominio = contacto.cod_condominio;
      nome_condominio = contacto.nome_condominio;
      cod_contacto = element.cod_contacto;
      nome_contacto = element.nome_contacto;
      cod_fraccao = contacto.cod_fraccao;
      nome_fraccao = contacto.nome_fraccao;
      tipo = element.tipo;

      //Updates por condominio table
      this.contactsListOrig = this.contactsListOrig.map(el => {
        if (el.cod == cod_condominio) {
          el.contactos = el.contactos.map(cont => {
            if (cont.cod_contacto == cod_contacto && cont.cod_fraccao == cod_fraccao) {
              cont[checkField] = checked;
            }
            return cont;
          });
          !el.contactos.find(el => !el.checkedCarta && !el.checkedEmail) ? el.checked = true : el.checked = false;
        }
        return el;
      }); 

      //Updates por nome table
      this.contactsEntityListOrig[i].contactos[i_contact][checkField] = checked;
      !this.contactsEntityListOrig[i].contactos.find(el => !el.checkedCarta && !el.checkedEmail)? this.contactsEntityListOrig[i].checked = true : this.contactsEntityListOrig[i].checked = false;
    }

    this.selectContact(checked, method, cod_condominio,nome_condominio,cod_contacto, nome_contacto,cod_fraccao, nome_fraccao, tipo, contacto.email_1, contacto.email_2);
  }

  selectContact(checked, method:'CARTA'|'EMAIL', cod_condominio,nome_condominio,cod_contacto, nome_contacto,cod_fraccao,nome_fraccao,tipo, email_1, email_2): void {

    if (checked) {
      let entityAlreadyAdded = this.entidadesToAdd.find(e => e.method === method && (e.cod == cod_contacto) && (e.cod_condominio == cod_condominio) && (e.cod_fraccao == cod_fraccao));
      if (entityAlreadyAdded) return;

      let nomes = nome_contacto.split(" ").filter(el => el != '' && !!el);
      let entidade: EntitySelected = {
          tipo: tipo,
          cod: cod_contacto,
          nome: nome_contacto,
          nome_primeiro_ultimo: nomes.length > 1? nomes[0] + ' ' + nomes[nomes.length - 1] : nomes[0],
          email_1: email_1,
          email_2: email_2,
          cod_condominio: cod_condominio,
          nome_condominio: nome_condominio,
          cod_fraccao: cod_fraccao,
          nome_fraccao: nome_fraccao,
          method: method,
        }

      this.entidadesToAdd.push(entidade);

    } else {
      this.entidadesToAdd = this.entidadesToAdd.filter(e => ! (e.method === method && (e.cod == cod_contacto) && (e.cod_condominio == cod_condominio) && (e.cod_fraccao == cod_fraccao)));
    }
  }

  //Implementing ctt modal and restructuring entity selection
  bccSelected:Array<EntitySelected> = [];
  toSelected:Array<EntitySelected> = [];
  ccSelected:Array<EntitySelected> = [];
  cartaSelected:Array<EntitySelected> = [];
  @ViewChild('comunicaoModalRef', { static: false }) comunicaoModalRef: EmailCartaComunicacaoModalComponent;

  async enviar() {

    if (!this.hasDestinatarios()) {
      this.toastr.error('Não é possível efectuar o envio do email. Por favor, preencha os destinatários.' , 'Ups...!', { timeOut: 4000 });
      return;
    }

    if (!this.isEmailValid()) {
      this.invalidEmail = true;
      setTimeout(() => { this.invalidEmail = false; }, 4000);

      this.toastr.error('Não é possível efectuar o envio do email. Por favor, verifique os campos acima assinalados.' , 'Ups...!', { timeOut: 4000 });
      return;
    }

    let differentCondominios = this.getTotalDifferentCondominios();

    if (differentCondominios == 0 && this.cartaSelected.length > 0) {
      let res = await this.presentSetCondominioAccountModal();
      if (!res || res.canceled || !this.condSelected) {
        return;
      }
    }

    let entities:Array<EntityComunicacoes> = this.getFormattedEntitiesComunicacoes(differentCondominios);

    entities.sort((a,b) => {
        if (!a.cod_condominio && !b.cod_condominio) return 0;
        if (!a.cod_condominio) return 1;
        if (!b.cod_condominio) return -1;
        if (a.cod_condominio != b.cod_condominio) return Number(a.cod_condominio) - Number(b.cod_condominio);

        if (!a.cod_fraccao && !b.cod_fraccao) return 0;
        if (!a.cod_fraccao) return 1;
        if (!b.cod_fraccao) return -1;
        return (a.cod_fraccao as string).localeCompare(b.cod_fraccao as string);
    });

    let from;
    let fromName;
  
    let specialSignature = null;
    if (this.selRemetenteEmail !== this.remetenteCompany.value) {
      let fromUser = this.remetenteOptsOrig.find(el => el.email === this.selRemetenteEmail);
      from = fromUser.email;
      fromName = fromUser.first_name + ' ' + fromUser.last_name + ' | ' + this.appConfig.company.emailFromName;
    } else {
      fromName = this.remetenteCompany.name;
      from = this.remetenteCompany.value;
      let loggedUser = this.remetenteOptsOrig.find(el => el.id == this.userSession.getUserId());
      console.log(loggedUser);
      if (loggedUser) specialSignature = { email: loggedUser.email, name: loggedUser.nome_user};
    }
    
    this.comunicaoModalRef.setEmailFrom(from, fromName, specialSignature);

    let subjectMsg = this.emailGeralSubject;
    let bodyMsg = this.utils.replaceHtmlEditorCssClasses(this.htmlContentEmails);
    bodyMsg = this.utils.formatHTMLEditorText(bodyMsg);
    
    this.updateSharedInput('CORRESPONDENCIA_COM', false);
    this.comunicaoModalRef.setDataComunicacoes(true,subjectMsg,bodyMsg,entities, this.anexosGeralList);
    this.comunicaoModalRef.submit().then(res => {
      if (!!res) {
        this.clearInput();
      }
    }).catch(err => {});
  }

  updateSharedInput(origin, needAllSelected:boolean) {
    this.origin = origin;
    this.needAllSelected = needAllSelected;
    this.cdRef.detectChanges();
  }

  condSelected = null;
  presentSetCondominioAccountModal():Promise<{canceled}> {
    this.condSelected = null;
    return new Promise((resolve, reject) => {
      this.setCondModalRef = this.modalService
      .open(this.setCondAlertConfig)
      .onApprove(() => { 
        resolve({canceled:false});
        })
      .onDeny(() => { 
        resolve({canceled:true})
        this.condSelected = null;
      });
    });
  }

  checkDespesasCttCond() {
    if (!this.condSelected) return;
    this.setCondModalRef.approve();
  }

  async getFormattedEntitiesAvisos(fraccaoList, nomeCondominio: string): Promise<Array<EntityEmailCartaInput>> {
    let cod_condominio = this.selCondominio.hasOwnProperty('cod')? this.selCondominio.cod : this.selCondominio.value.cod;
    let data_reconciliacao = await this.condominios.getReconciliationDate(cod_condominio);
    var NoReconciliation = {};
    try {
      let entities = fraccaoList.map(fraccao => {
        let entityParam:{nib,nif,nome,avisos:Array<{tipo_proc, divida,dt_emissao,descricao, dt_vencimento,fraccao,n_aviso}>} = {
          nib: fraccao.nib ? (fraccao.nib.indexOf('PT50') !== -1 ? fraccao.nib : 'PT50' + fraccao.nib) : null,
          nif: fraccao.nif,
          nome: fraccao.nome_proprietario,
          avisos: fraccao.avisos,
        }
        let endDate = this.selCriterio === 'DATE'? new Date(this.endDate) : new Date();
        endDate.setHours(0,0,0,0);
        
        if (this.docModel.id !== '8') {
          //Se não é aviso de pagamento, só consta nas cartas os avisos realmente vencidos: (para ordinários conta a data emissão, para os extra conta a data de vencimnento.)
          entityParam.avisos = entityParam.avisos.filter(el => {
            return el.tipo_proc !== 'E' || this.utils.compareDayDates(this.utils.getDate(el.dt_vencimento,undefined,undefined,true), endDate) <= 0;
          })
        }
        let bodyMsg = this.utils.getAvisosBody(this.docModel.text_inicial,this.docModel.texto_final, entityParam, { reconciliation_date: data_reconciliacao});
        if (!bodyMsg) {
          throw NoReconciliation;
        }
        let entity: EntityEmailCartaInput = {
          cod: fraccao.cod_proprietario,
          nome: fraccao.nome_proprietario,
          cod_condominio: fraccao.cod_condominio,
          nome_condominio: nomeCondominio,
          cod_fraccao: fraccao.cod_fraccao,
          nome_fraccao: fraccao.fraccao_nome,
          subjectMsg: this.docModel.assunto,
          bodyMsg: bodyMsg,
          email_1: fraccao.email_proprietario,
          email_2: fraccao.email_proprietario_2,
          tipo: 'PROPRIETARIO',
          communicationType: null,
          id_texto_predefinido: this.selTipoDocumento,
          data_divida: endDate
        }
        return entity;
      });
      return entities;
    } catch (err) {
      if (err !== NoReconciliation) throw err;
      return [];
    }
  }

  getFormattedEntitiesComunicacoes(differentCondominios: number): Array<EntityComunicacoes> {
    let entities = this.bccSelected.map(el => {
      let emails =  (((el.email_1) ? ` ${el.email_1};` : '') + ((el.email_2) ? ` ${el.email_2};` : '')).trim();
      let aux: EntityComunicacoes = {
        cod: el.cod,
        emails: emails,
        checkedEmail: true,
        communicationType:'bcc',
        checkedCarta: false,
        cod_fraccao: el.cod_fraccao,
        nome_fraccao: el.nome_fraccao,
        cod_condominio: el.cod_condominio,
        condominioNome: el.nome_condominio,
        moradaCondominio: [],
        nomeEntidade: el.nome,
        moradaEntidade: [],
        tipo: el.tipo? el.tipo : 'PROPRIETARIO',
      }
      return aux;
    });

    entities.concat(this.ccSelected.map(el => {
      let emails =  (((el.email_1) ? ` ${el.email_1};` : '') + ((el.email_2) ? ` ${el.email_2};` : '')).trim();
      let emailsAux = emails;
      if (emailsAux) emailsAux = emails.slice(0, -1);
      let aux: EntityComunicacoes = {
        cod: el.cod,
        emails: emails,
        checkedEmail: true,
        communicationType:'cc',
        checkedCarta: false,
        cod_fraccao: el.cod_fraccao,
        nome_fraccao: el.nome_fraccao,
        cod_condominio: el.cod_condominio,
        condominioNome: el.nome_condominio,
        moradaCondominio: [],
        nomeEntidade: el.nome,
        moradaEntidade: [],
        tipo: el.tipo? el.tipo : 'PROPRIETARIO',
      }
      return aux;
    }));
    
    entities = entities.concat(this.toSelected.map(el => {
      let emails =  (((el.email_1) ? ` ${el.email_1};` : '') + ((el.email_2) ? ` ${el.email_2};` : '')).trim();
      let emailsAux = emails;
      if (emailsAux) emailsAux = emails.slice(0, -1);
      let aux: EntityComunicacoes = {
        cod: el.cod,
        emails: emails,
        checkedEmail: true,
        communicationType:'to',
        checkedCarta: false,
        cod_fraccao: el.cod_fraccao,
        nome_fraccao: el.nome_fraccao,
        cod_condominio: el.cod_condominio,
        condominioNome: el.nome_condominio,
        moradaCondominio: [],
        nomeEntidade: el.nome,
        moradaEntidade: [],
        tipo: el.tipo? el.tipo : 'PROPRIETARIO',
      }
      return aux;
    }));
    
    entities = entities.concat(this.cartaSelected.map(el => {
      let aux: EntityComunicacoes = {
        cod: el.cod,
        emails: null,
        checkedEmail: false,
        communicationType:null,
        checkedCarta: true,
        cod_fraccao: el.cod_fraccao,
        nome_fraccao: el.nome_fraccao,
        cod_condominio: differentCondominios === 0 ? this.condSelected.cod : el.cod_condominio,
        condominioNome: differentCondominios === 0 ? this.condSelected.nome : el.nome_condominio,
        moradaCondominio: [],
        nomeEntidade: el.nome,
        moradaEntidade: [],
        tipo: el.tipo? el.tipo : 'PROPRIETARIO',
      }
      return aux;
    }));


    return entities;
  }

  removeEntidade(communicationfield:communicationField,index: number) {
    this[communicationfield].splice(index, 1);
    let differentCondominios = this.getTotalDifferentCondominios();
    if (differentCondominios == 0) {
      this.updateAllCond(null);
    } if (differentCondominios == 1) {
      this.updateAllCond(this.getUniqueCond());
    }
  }

  openAccordion(tab, entry) {
    switch (tab) {
      case "por_condominio":
        this.contactsList.forEach(el => {
          if (el.cod != entry.cod) {
            el.opened = false
          } else {
            el.opened = !el.opened
          }
        });
        break;
      case "por_nome":
        this.contactsEntityList.forEach(el => {
          if (el.cod_contacto != entry.cod_contacto) {
            el.opened = false
          } else {
            el.opened = !el.opened
          }
        });
        break;
        
        default:
          break;
        }
    
    this.cdRef.detectChanges();
  }

  clearInput():void {
    // CLEAN EMAIL VARIABLES 
    this.anexosGeralList = [];
    this.nEmailAddr = null;
    this.emailGeralList = null;
    this.anexosGeralList = [];

    this.emailGeralSubject = null;
    this.htmlContentEmails = null;
    this.selMinuta = null;

    this.filesToUpload = [];

    this.bccSelected= [];
    this.ccSelected= [];
    this.toSelected= [];
    this.cartaSelected= [];

    this.selRemetenteEmail = this.remetenteDefault.value;
    this.allContacts = false;
    this.allContactsEntity = false;
    this.condSelected = null;
  }

  // getDifferentCondominios(type: communicationField, adding:boolean=false) {
  //   return adding? [...new Set(this.entidadesToAdd.filter(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO').map(el => el.cod_condominio))].length: [...new Set(this[type].filter(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO').map(el => el.cod_condominio))].length;
  // }

  getTotalDifferentCondominios(): number {
    let diffCondominios = [];
    switch (this.addingType) {
      case 'bccSelected':
        diffCondominios = [... new Set(this.entidadesToAdd.concat(this.toSelected).concat(this.ccSelected).filter(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO').map(el => el.cod_condominio))];
        break;
    
      case 'ccSelected':
        diffCondominios = [... new Set(this.bccSelected.concat(this.toSelected).concat(this.entidadesToAdd).filter(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO').map(el => el.cod_condominio))];
        break;
    
      case 'toSelected':
        diffCondominios = [... new Set(this.bccSelected.concat(this.entidadesToAdd).concat(this.ccSelected).filter(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO').map(el => el.cod_condominio))];
        break;
    
      case 'cartaSelected':
        diffCondominios = [... new Set(this.bccSelected.concat(this.toSelected).concat(this.ccSelected).concat(this.entidadesToAdd).filter(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO').map(el => el.cod_condominio))];
        break;
    
      default:
        diffCondominios = [... new Set(this.bccSelected.concat(this.toSelected).concat(this.ccSelected).concat(this.cartaSelected).filter(el => el.tipo !== 'FORNECEDOR' && el.tipo !== 'OUTRO').map(el => el.cod_condominio))];
        break;
    }
    return diffCondominios.length;

  }

  clearRemetente() {
    this.selRemetenteEmail = null;
  }

  getTotalEmails(): number {
    return this.bccSelected.length + this.toSelected.length + this.ccSelected.length;
  }

  copyEmails(type: communicationField) {
    let emails = this[type].reduce((arr, el) => {
      if (el.email_1 && el.email_1.trim() !== '') arr.push(el.email_1);
      if (el.email_2 && el.email_2.trim() !== '') arr.push(el.email_2);
      return arr;
    }, []);

    if (!emails || !emails.length) {
      this.toastr.info('Nenhum email foi copiado.', 'Informação', { timeOut: 3000 });
      return;
    }
    let textToCopy = emails.join(';');
    try {
      if (!navigator.clipboard){
        var textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        textArea.style.display = 'none';
    
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          let successful = document.execCommand('copy');
          if (successful) {
            this.toastr.info('Foram copiados ' + emails.length + ' emails.', 'Informação', { timeOut: 3000 });
          }
        } catch (err) {
        }
        document.body.removeChild(textArea);
      } else{
          navigator.clipboard.writeText(textToCopy).then(_ => {
            this.toastr.info('Foram copiados ' + emails.length + ' emails.', 'Informação', { timeOut: 3000 });
          }).catch(err => {
          });
      }    
    } catch (err) {
    }
  }

  setMinuta(minuta: Minuta) {
    //Subject
    this.emailGeralSubject = minuta.assunto;
    //Text
    this.htmlContentEmails = this.minutaStrToQuillEditorStr(minuta.texto);

    this.prevMinuta = minuta;
  }

  changeMinutaOrigin:string = null;
  selectMinuta(origin:'RESTORE'|'CHANGE') {
    if (this.selMinuta === -1) {
      this.clearMinuta();
      return;
    }
    let minuta = this.minutasOpts.find(el => el.id === this.selMinuta );
    if (!minuta){
      this.clearMinuta();
      return;
    }
    this.changeMinutaOrigin = origin;

    if (this.presentChangedMinutaAlert(minuta)) {
      this.changeMinutaModalRef = this.modalService
      .open(this.changeMinutaAlertConfig)
      .onApprove(() => { 
        this.setMinuta(minuta);
        this.changeMinutaOrigin = null;
        })
      .onDeny(() => { 
        if (this.prevMinuta) {
          this.selMinuta = this.prevMinuta.id;
        } else {
          this.clearMinuta();
        }
        this.changeMinutaOrigin = null;
      });
    } else {
      this.setMinuta(minuta);
      this.changeMinutaOrigin = null;
    }
  }

  presentChangedMinutaAlert(minuta: Minuta):boolean {
    let empty = (!this.htmlContentEmails || this.htmlContentEmails.trim() === '') && (!this.emailGeralSubject || this.emailGeralSubject.trim() === '');
    let textIsMinuta = (this.emailGeralSubject === minuta.assunto) && (this.minutaStrToQuillEditorStr(minuta.texto) === this.htmlContentEmails);
    return !textIsMinuta && !empty; 
  }

  minutaChanged() {
    let minuta = this.minutasOpts.find(el => el.id === this.selMinuta);
    return minuta && ((this.emailGeralSubject !== minuta.assunto) || (this.minutaStrToQuillEditorStr(minuta.texto) !== this.htmlContentEmails));
  }

  minutaStrToQuillEditorStr(minutaText:string):string {
    let texto = '<p>' + minutaText + '</p>';
    texto = texto.replace(/\n/g, '</p><p>').replace(/<p><\/p>/g, '<p><br></p>');
    return texto
  }

  clearMinuta() {
    setTimeout(() => {
      this.selMinuta = null;
      this.prevMinuta = null;
      this.cdRef.detectChanges();
    });
  }

  ac_read = false;
  ac_create = false;

  c_read = false;
  c_create = false;
  c_copyEmails = false;
  c_selectAnyRemetente = false;

  rc_read = false;
  rc_delete = false;

  cc_read = false;
  cc_delete = false;
  cc_deleteRegisto = false;
  cc_viewDetailsIndividual = false;
  cc_viewTotais = false;
  
  checkPermissions() {
    this.ac_read = this.userSession.checkUserAccess('CORRESPONDENCIA_AC', 'read');
    this.c_read = this.userSession.checkUserAccess('CORRESPONDENCIA_C', 'read');
    this.rc_read = this.userSession.checkUserAccess('CORRESPONDENCIA_RC', 'read');
    this.cc_read = this.userSession.checkUserAccess('CORRESPONDENCIA_CC', 'read');

    this.tabsObjDef.forEach(tab => {
      if (tab.key === 'cartas-cobranca') tab.disabled = !this.ac_read;
      if (tab.key === 'comunicacoes') tab.disabled = !this.c_read;
      if (tab.key === 'registo-comunicacoes') tab.disabled = !this.rc_read;
      if (tab.key === 'custos-comunicacoes') tab.disabled = !this.cc_read;
    });
    this.tabsObjDef.sort((a,b) => {
      if ((a.disabled ^ b.disabled) == 0) return this.tabsOrder.findIndex(el => el == a.key) - this.tabsOrder.findIndex(el => el == b.key);
      if (a.disabled) return 1;
      return -1;
    });
    let firstActive = this.tabsObjDef.find(el => !el.disabled);
    if (firstActive) {
      this.tabsObjDef.forEach(el => el.active = el.key == firstActive.key? true : false);
      this.selTabKey = firstActive.key;
    } else {
      this.selTabKey = null; 
      this.toastr.error(this.appConfig.errMsg.unAuth.msg,this.appConfig.errMsg.unAuth.title);
      return;
    }
    this.ac_create = this.userSession.checkUserAccess('CORRESPONDENCIA_AC','create');
    this.c_create = this.userSession.checkUserAccess('CORRESPONDENCIA_C','create');
    this.c_copyEmails = this.userSession.checkUserAccess('CORRESPONDENCIA_C_COPIAR_EMAILS','allow');
    this.c_selectAnyRemetente = this.userSession.checkUserAccess('CORRESPONDENCIA_C_ASSINAR_POR_TODOS','allow');
    this.rc_delete = this.userSession.checkUserAccess('CORRESPONDENCIA_RC','delete');

    if (this.ac_read && this.fraccaoListOrig.length == 0) {
      this.getFraccoes();
    }
    if (this.rc_read && this.registoCttList.length == 0) {
      this.getRegistoComunicacoes();
    }
    
    if (this.c_read && (this.contactsListOrig.length == 0 || this.contactsEntityListOrig.length == 0)) {
      this.getAllContactsbyCondominio();
    }


  }

  @ViewChild('searchContenciosoAlertRef', { static: false }) searchContenciosoAlertRef;
  searchContenciosoModalRef = null;
  searchContenciosoAlertConfig: any = null;

  searchContenciosos = [];
  searchContenciososOpts = [];
  openSearchContenciosoModal() { 
    this.searchContenciososOpts = JSON.parse(JSON.stringify(this.estadoContenciosoOpts));
    this.searchContenciosos = [];
    this.searchContenciosoModalRef = this.modalService
      .open(this.searchContenciosoAlertConfig)
      .onApprove(() => { 
      })
      .onDeny(() => { 
      });
  }

  selectedSearchContenciosoOpt() {
    let specialOption = null;
    for (let i = this.searchContenciosos.length - 1; i >= 0; i--) {
      if (this.searchContenciosos[i] < 0) {
        specialOption = this.searchContenciosos[i];
      }
    }

    if (specialOption != null) {
      this.searchContenciososOpts = this.searchContenciososOpts.filter(el => el.value === specialOption);
      this.searchContenciosos = this.searchContenciosos.filter(el => el === specialOption);
    } else {
      this.searchContenciososOpts = JSON.parse(JSON.stringify(this.estadoContenciosoOpts));
    }
  }
  
  fetchingAllContenciosos = false;
  searchContenciososFeedBack:{show:boolean, timeout} = { show: false, timeout: null};
  exportSearchContenciosoExcel() {
    this.utils.setFormFeedback(this.searchContenciososFeedBack);
    if (this.searchContenciosos.length == 0) return;

    this.fetchingAllContenciosos = true;
    this.api.getAllEstadosContenciosos(this.searchContenciosos).subscribe(res => {
      if (res.success) {
        let contenciososInfo = res.data as Array<{cod_fracao, nome_fracao, cod_condominio,nome_condominio,nome_condomino,nome_estado_contencioso}>;
        let excelData = [{
          nome_condominio: 'Condomínio',
          fracao: 'Fração',
          condomino: 'Condómino',
          contencioso: 'Contencioso',
        }];

        contenciososInfo.forEach(cont => {
          excelData.push({
            nome_condominio: cont.cod_condominio + ' - ' + cont.nome_condominio,
            fracao: cont.cod_fracao + ' - ' + cont.nome_fracao,
            condomino: cont.nome_condomino,
            contencioso: cont.nome_estado_contencioso == null ? '--' : cont.nome_estado_contencioso
          });
        });

        let worksheet = XLSX.utils.json_to_sheet(excelData, { header:['nome_condominio', 'fracao', 'condomino', 'contencioso'], skipHeader: true });
        let workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        worksheet['!cols'] = this.utils.fitToColumn(excelData);


        let excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let data = new Blob([excelBuffer], {type: EXCEL_TYPE});

        FileSaver.saveAs(data, 'Estados_Contenciosos_' + this.utils.getFormatedDate(new Date()) + EXCEL_EXTENSION);
        
        this.searchContenciosoModalRef.approve();

      } else {
        this.utils.apiErrorMsg(res);
      }
      this.fetchingAllContenciosos = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.fetchingAllContenciosos = false;
    })
  }


}
