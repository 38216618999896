import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: any, sep): any {
    if (value) {
      // return value.replace('[NEW_LINE]', sep);
      // return value.replace(new RegExp('NEW_LINE', 'g'), sep);
      return value.split('NEW_LINE').join(sep);
    } else {
      return null;
    }
  }

}
