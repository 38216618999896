import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { EntitiesToNotice, EntitiesToNoticeBD, getTextoFilledToCommunicateApi, NoticeInfo, NoticeInfoBD, RegistoComunicacaoSave, RegistoComunicacaoSendAndSave, saveEntityWithoutDebt, Texto, TextoDB, TextoFilled } from '../business-model-interfaces/comunicacoes';
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationsService {


  constructor(
    public api: ApiService,
    public appConfig: AppConfigService,
    public toastr: ToastrService,
    public utils: UtilitiesService,
  ) { }

  // ------------- TEXTOS (Start)
  getTextos(): Promise<Array<Texto>> {
    return new Promise((resolve, reject) => {
      this.api.getTextos().subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data.map(el => this.convertTextoType(el)));
        } else {
          this.utils.apiErrorMsg(res);
          resolve([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve([]);
      });
    });
  }
  getTexto(id): Promise<Texto> {
    return new Promise((resolve, reject) => {
      this.api.getTextosDetails(id).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(this.convertTextoType(res.data));
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }
  getTextoFilledToCommunicate(id_texto, cod_condomino, id_fracao, fillingParams:{advogado, agente_execucao}=null): Promise<{ emailToSendInfo: getTextoFilledToCommunicateApi['data']['emailToSendInfo'], texto: TextoFilled }> {
    return new Promise((resolve, reject) => {
      this.api.getTextoFilledToCommunicate(id_texto, cod_condomino, id_fracao, fillingParams).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve({ emailToSendInfo: res.data.emailToSendInfo, texto: res.data.texto as TextoFilled });
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }
  convertTextoType(atBD: TextoDB): Texto | TextoFilled {
    let text_inicial = null;
    let texto_final = null;
    if (atBD.text_inicial.indexOf('NEW_LINE') !== -1) {
      text_inicial = atBD.text_inicial.split(/NEW_LINE/g);
    } else {
      text_inicial = atBD.text_inicial.split('\n');
    }
    if (atBD.texto_final.indexOf('NEW_LINE') !== -1) {
      texto_final = atBD.texto_final.split(/NEW_LINE/g);
    } else {
      texto_final = atBD.texto_final.split('\n');
    }
    return {
      ...atBD,
      text_inicial: text_inicial,
      texto_final: texto_final,
    }
  }
  // ------------- TEXTOS (End)


  // ------------- SEND EMAIL (Start)
  sendAndSaveEmail(reg: Array<RegistoComunicacaoSendAndSave>): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.api.sendAndSaveEmail(reg).subscribe(res => {
        if (res.success && res.data) {
          if (reg.length > 1) {
            this.toastr.success(this.appConfig.succMsg.sentEmails.msg, this.appConfig.succMsg.sentEmails.title, { timeOut: 0 });
          } else if (reg.length === 1) {
            this.toastr.success(this.appConfig.succMsg.sentEmail.msg, this.appConfig.succMsg.sentEmail.title, { timeOut: 0 });
          }
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        if (reg.length > 1) {
          this.toastr.error(this.appConfig.errMsg.sentEmails.msg, this.appConfig.errMsg.sentEmails.title, { timeOut: 0 });
        } else if (reg.length === 1) {
          this.toastr.error(this.appConfig.errMsg.sentEmail.msg, this.appConfig.errMsg.sentEmail.title, { timeOut: 0 });
        }
        resolve(false);
      })
    });
  }

  // ------------- SEND EMAIL (End)


  // ------------- PAYMENT NOTICES (Start)
  sendPaymentsNoticesEmail(emailsToSend: Array<RegistoComunicacaoSendAndSave>, condominosWithoutDebt: Array<saveEntityWithoutDebt>, id_reconciliacao): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.sendPaymentNoticesEmail(emailsToSend, condominosWithoutDebt, id_reconciliacao).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (emailsToSend.length > 1) {
            this.toastr.success(this.appConfig.succMsg.sentEmails.msg, this.appConfig.succMsg.sentEmails.title);
          } else if (emailsToSend.length === 1) {
            this.toastr.success(this.appConfig.succMsg.sentEmail.msg, this.appConfig.succMsg.sentEmail.title);
          }
          resolve(true);
        } else {
          if (emailsToSend.length > 1) {
            this.toastr.error(this.appConfig.errMsg.sentEmails.msg, this.appConfig.errMsg.sentEmails.title);
          } else if (emailsToSend.length === 1) {
            this.toastr.error(this.appConfig.errMsg.sentEmail.msg, this.appConfig.errMsg.sentEmail.title);
          }
          this.utils.apiErrorMsg(res);
          reject(false);
        }
      }, err => {
        if (emailsToSend.length > 1) {
          this.toastr.error(this.appConfig.errMsg.sentEmails.msg, this.appConfig.errMsg.sentEmails.title);
        } else if (emailsToSend.length === 1) {
          this.toastr.error(this.appConfig.errMsg.sentEmail.msg, this.appConfig.errMsg.sentEmail.title);
        }
        reject(false);
      });
    });
  }
  // ------------- PAYMENT NOTICES (End)




  getEntitiesNoticeSuggestion(cod_condominio, id_reconciliacao): Promise<{ noticeInfo: NoticeInfo, list: Array<EntitiesToNotice>, defaultMapaQuotasText: {subject, body} }> {
    return new Promise((resolve) => {
      this.api.getEntitiesNoticeSuggestion(cod_condominio, id_reconciliacao).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve({ noticeInfo: this.convertNoticeInfoType(res.data.noticeInfo), list: res.data.entities.map(el => this.convertEntitiesToNoticeType(el)), defaultMapaQuotasText: res.data.defaultMapaQuotasText });
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }



  saveRegistoComunicacao(reg: Array<RegistoComunicacaoSave>): Promise<Array<string>> {
    return new Promise(async (resolve) => {
      this.api.saveRegistoComunicacao(reg).subscribe(res => {
        if (res.success && res.data) {
          resolve(res.data.id_comunicacoes);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        resolve(null);
      })
    });
  }

  convertNoticeInfoType(atBD: NoticeInfoBD): NoticeInfo {
    return {
      ...atBD,
      data: atBD.data ? this.utils.getDate(atBD.data) : null,
      data_ultima_comunicacao: atBD.data_ultima_comunicacao ? this.utils.getDate(atBD.data_ultima_comunicacao) : null,
    }
  }

  convertEntitiesToNoticeType(atBD: EntitiesToNoticeBD): EntitiesToNotice {
    return {
      ...atBD
    }
  }

  deleteCommunications(list: Array<{ tipo: 'EMAIL' | 'CARTA' } & any>): Promise<{ carta: boolean, email: boolean }> {
    return new Promise((resolve) => {
      let email = list.filter(el => el.tipo === 'EMAIL');
      let carta = list.filter(el => el.tipo === 'CARTA');

      let req = [];

      if (carta.length) req.push(this.api.delRegistoCtt(carta, 'REGISTO_COMUNICACOES', 'BOTH'));
      if (email.length) req.push(this.api.delRegistoComunicacao(email));

      forkJoin(req).subscribe(res => {
        let succeededEmail = true;
        let succeededCarta = true;
        if (res.find(el => el.hasOwnProperty('success') && el.success)) {
          if (carta.length) {
            if (res[0].hasOwnProperty('success') && res[0].success) {
            } else {
              this.utils.apiErrorMsg(res[0]);
              succeededCarta = false;
            }

            if (email.length) {
              if (res[1].hasOwnProperty('success') && res[1].success) {
              } else {
                this.utils.apiErrorMsg(res[1]);
                succeededEmail = false;
              }
            }
          } else if (email.length) {
            if (res[0].hasOwnProperty('success') && res[0].success) {
            } else {
              this.utils.apiErrorMsg(res[0]);
              succeededEmail = false;
            }
          }
        } else {
          this.utils.apiErrorMsg(res[0]);
          succeededEmail = false;
          succeededCarta = false;
        }
        resolve({ carta: succeededCarta, email: succeededEmail });
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve({ carta: false, email: false });
      });
    });
  }
}
