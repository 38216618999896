import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DelType, GetProcessamentoListQuery, Processamento, ProcessamentoDetails, ProcessamentoDetailsSimple, ProcessamentoDetailsToPrint, SaveProcessamento } from '../business-model-interfaces/processamentos';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { FraccoesService } from '../business-logic-services/fraccoes.service';
import { UtilitiesService } from '../utilities.service';
import { Recibo } from '../business-model-interfaces/recibo';

@Injectable({
  providedIn: 'root'
})
export class ProcessamentosService {

  constructor(
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public api: ApiService,
    public fraccoesService: FraccoesService,
    public toastr: ToastrService) { }

    

  cleanDescricaoFromAtaInfo(descricao: string): string {
    let aprovadoTextoIndex = descricao.indexOf(' - Aprovad');
    if (aprovadoTextoIndex !== -1) {
      descricao = descricao.substring(0, aprovadoTextoIndex);
    }
    return descricao;
  }

  getList(cod_condominio: string | number, exercicio, query: GetProcessamentoListQuery = null): Promise<Array<Processamento>> {
    return new Promise(async (resolve) => {
      this.api.getProcessamentos(cod_condominio, exercicio, 0, null, undefined, undefined, undefined, query).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  saveProcessamento(data: SaveProcessamento): Promise<number> {
    return new Promise(async (resolve) => {
      this.api.saveProcessamento(data).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  lancarProcessamento(data: SaveProcessamento): Promise<number> {
    return new Promise(async (resolve) => {
      this.api.lancarProcessamento(data).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  getProcessamento(id, queryParams: { includeRecibos: boolean } = null): Promise<ProcessamentoDetails> {
    return new Promise(async (resolve, reject) => {
      this.api.getProcessamentoDetails(id, queryParams).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    });
  }

  getProcessamentoToPrint(id): Promise<ProcessamentoDetailsToPrint> {
    return new Promise(async (resolve, reject) => {
      this.api.getProcessamentoDetailsToPrint(id).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    });
  }

  getAvisosCreditosByProcessamento(id): Promise<{ avisos: Array<any>, creditos: Array<any> }> {
    return new Promise(async (resolve, reject) => {
      this.api.getAvisosCreditosByProcessamento(id).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    });
  }

  getRecibos(codigos: Array<string | number>): Promise<Array<Recibo>> {
    return new Promise(async (resolve, reject) => {
      this.api.getProcessamentoRecibos(codigos).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data.map(el => {
            return {
              ...el,
              data: this.utils.getDate(el.data),
              criado_em: this.utils.getDate(el.criado_em),
            }
          }));
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    });
  }

  copyProcessamento(id): Promise<number> {
    return new Promise(async (resolve, reject) => {
      this.api.copyProcessamento(id).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    });
  }


  delete(ids: Array<number>, delType: DelType = 'DELETE'): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.api.delProcessamentos(ids, delType).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  hasSimilar(query: { tipo_proc: string, cod_condominio: number, valor: number, data_inicio: Date, data_fim: Date } | { id_processamento: string } = null): Promise<Array<ProcessamentoDetailsSimple>> {
    return new Promise(async (resolve, reject) => {
      this.api.hasSimilarProcessamento(query).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data.map(el => {
            return {
              ...el,
              data: this.utils.getDate(el.data),
              criado_em: this.utils.getDate(el.criado_em),
            }
          }));
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(false);
      });
    });
  }
}
