import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent, forkJoin } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { AppStateService } from '../app-state.service';
import { PreviousRouteService } from '../previous-route.service';
import { identifierModuleUrl } from '@angular/compiler';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { CondominioContactsPdfComponent } from '../condominio-contacts-pdf/condominio-contacts-pdf.component';
import { CondominiosService } from '../business-logic-services/condominios.service';


@Component({
  selector: 'app-condominium-details',
  templateUrl: './condominium-details.component.html',
  styleUrls: ['./condominium-details.component.scss']
})
export class CondominiumDetailsComponent implements OnInit, OnDestroy {

  tabsObjDef: any = [
    { key: 'geral', name: 'Geral', active: true, disabled: false },
    { key: 'zonas', name: 'Zonas', active: false, disabled: false },
    { key: 'fraccoes', name: 'Fracções', active: false, disabled: false },
    { key: 'contas', name: 'Contas', active: false, disabled: false },
    { key: 'saldos-iniciais', name: 'Saldos Iniciais', active: false, disabled: false },
    { key: 'seguro', name: 'Seguro', active: false, disabled: false },
    { key: 'documentos', name: 'Documentos', active: false, disabled: false },
    { key: 'penalizacoes', name: 'Penalizações', active: false, disabled: false },
    { key: 'observacoes', name: 'Observações', active: false, disabled: false },
    { key: 'entidades', name: 'Entidades', active: false, disabled: false },
  ];

  selTab: any = null;

  transitionController = new TransitionController();
  loading = false;
  loadingModal = false;
  isCreate = true;

  codAlreadyExiste = false;

  condominioId = null;
  condDetails: any = null;
  toDelete = [];

  contador: any = {
    n_aviso: null,
    n_credito: null,
    n_despesa: null,
    n_recibo: null,
    n_receita: null,
  };

  // GERAL CONDOMINIO FORM
  genCondForm = new FormGroup({
    estado: new FormControl(null),
    cod: new FormControl(null, { validators: Validators.required}),
    exercicio: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required}),
    classificacao: new FormControl('A', { validators: Validators.required}),

    morada: new FormControl(null, { validators: Validators.required}),
    postalCode: new FormControl(null),
    locality: new FormControl(null),

    pag_orcamento: new FormControl(null),
    pag_fr: new FormControl(null),
    fr: new FormControl(null),
    fr_perc: new FormControl(null),
    latitude: new FormControl(null),
    longitude: new FormControl(null),

    onedrive_link: new FormControl(null),
    bitrix_link: new FormControl(null),
  });
  maxCod = null;

  addressObj = {
    address: null,
    postalCode: null,
    locality: null,
  }

  obsCondForm = new FormGroup({
    administador_1: new FormControl(null),
    administador_2: new FormControl(null),
    administador_3: new FormControl(null),
    titular_1: new FormControl(null),
    titular_2: new FormControl(null),
    titular_3: new FormControl(null),
    titular_4: new FormControl(null),
    titulares_obs: new FormControl(null),
    obs: new FormControl(null),
  });

  searchable = true;
  submittingForm = false;
  submittingModalForm = false;

  // ZONAS TABLE VARIABLES
  zonaListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: true },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'number', sort: null, searchable: true, center: false },
    { key: 'n_fraccoes', name: '#Fracções', type: 'number', sort: null, searchable: true, center: true },
    { key: 'permilagem', name: 'Perm.', type: 'number', sort: null, searchable: false, center: true },
  ];
  zonaList: Array<any> = [];
  zonaListOrig: Array<any> = [];
  zonaListPrevState: Array<any> = [];
  zonaListLength: number = null;
  @ViewChild('zonaTableSearch', { static: false }) zonaTableSearch: ElementRef;
  zonaKeyword: string = null;
  zonaSortParam: string = null;
  zonaSortDirection: string = null;
  zonaSearching: boolean = false;


  // SALDOS INICIAIS TABLE VARIABLES
  saldosIniciaisListCol = [
    { key: 'nome', name: 'Nome', type: 'checkbox', sort: null, searchable: true, center: false, class: '' },
    { key: 'permilagem', name: 'Permilagem', type: 'number', sort: null, searchable: true, center: false, class: 'col-align-right' },
    { key: 'saldo_inicial', name: 'Gestão Corrente', type: 'number', sort: null, searchable: false, center: true, class: 'col-value col-align-right' },
    { key: 'saldo_inicial_fr', name: 'FCR', type: 'number', sort: null, searchable: false, center: true, class: 'col-value col-align-right' },
    { key: 'total', name: 'Total', type: 'number', sort: null, searchable: false, center: true, class: 'col-value col-align-right' },
  ];
  saldosIniciaisList: Array<any> = [];
  saldosIniciaisListOrig: Array<any> = [];
  saldosIniciaisListPrevState: Array<any> = [];
  saldosIniciaisListLength: number = null;
  @ViewChild('saldosIniciaisTableSearch', { static: false }) saldosIniciaisTableSearch: ElementRef;
  saldosIniciaisKeyword: string = null;
  saldosIniciaisSortParam: string = null;
  saldosIniciaisSortDirection: string = null;
  saldosIniciaisSearching: boolean = false;
  saldosIniciaisModoEdicao: boolean = false;


  // SALDOS INICIAIS DISTRIBUICAO TABLE VARIABLES
  saldoInicialDistrListCol = [
    { key: 'conta', name: 'Conta', type: 'text', sort: null, searchable: true, centered: true, class: '' },
    { key: 'dt_mov', name: 'Data do Movimento', type: 'text', sort: null, searchable: true, centered: true, class: 'col-value' },
    { key: 'dist_gc', name: 'Saldo', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right col-value' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
  ];
  saldoInicialDistrList: Array<any> = [];
  saldoInicialDistrListToDelete: Array<any> = [];
  selectedConta = null;
  saldoInicialData = null;
  submittingAdd = false;
  distValorGc = null;
  distValorFr = null;
  contaOptsOrig = [];
  contaOpts = [];
  valorTotalFr = 0;
  valorTotalGc = 0; 
  gcInputError = false;
  frInputError = false;
  inputError = false;

  // FRACCOES TABLE VARIABLES
  fracListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: false },  // 'ASC', 'DESC'
    { key: 'cod', name: 'Fracção', type: 'text', sort: null, searchable: true, center: false },
    { key: 'condomino_nome', name: 'Condómino', type: 'text', sort: null, searchable: true, center: false },
    { key: 'zona_nome', name: 'Zona', type: 'text', sort: null, searchable: true, center: false },
    { key: 'permilagem', name: 'Perm.', type: 'text', sort: null, searchable: false, center: true },
    { key: 'quota_orc', name: 'Quota Orc.', type: 'number', sort: null, searchable: false, center: true },
    { key: 'quota_fr', name: 'Quota F. R.', type: 'number', sort: null, searchable: false, center: true },
    { key: 'quota_seg', name: 'Quota Seg', type: 'number', sort: null, searchable: false, center: true },
  ];
  fracList: Array<any> = [];
  fracListOrig: Array<any> = [];
  fracListPrevState: Array<any> = [];
  fracListLength: number = null;
  @ViewChild('fracTableSearch', { static: false }) fracTableSearch: ElementRef;
  fracKeyword: string = null;
  fracSortParam: string = null;
  fracSortDirection: string = null;
  fracSearching: boolean = false;

  // PAGAMENTOS ANTERIORES TABLE VARIABLES
  pagAnterioresListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: false },
    { key: 'cod', name: 'Fracção', type: 'text', sort: null, searchable: true, center: false },
    { key: 'nome', name: 'Condómino', type: 'text', sort: null, searchable: true, center: false },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: false, center: true },
  ];
  pagAnterioresList: Array<any> = [];
  pagAnterioresListOrig: Array<any> = [];
  @ViewChild('pagAnterioresTableSearch', { static: false }) pagAnterioresTableSearch: ElementRef;
  pagAnterioresSearching: boolean = false;


  // CONTACTOS TABLE VARIABLES
  contactListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, center: false },
    { key: 'tipo', name: 'tipo', type: 'text', sort: null, searchable: true, center: false },
    { key: 'n_contribuinte', name: 'Contribuinte', type: 'text', sort: null, searchable: true, center: false },
    { key: 'telefone_1', name: 'Telefone', type: 'text', sort: null, searchable: true, center: false },
    { key: 'obs', name: 'Observações', type: 'text', sort: null, searchable: false, center: false },
  ];
  contactList: Array<any> = [];
  contactListOrig: Array<any> = [];
  contactListPrevState: Array<any> = [];
  contactListLength: number = null;
  @ViewChild('contactTableSearch', { static: false }) contactTableSearch: ElementRef;
  contactPage: number = 0;
  contactSelectedPage: number = null;
  contactItemPerPAge: number = 9;
  contactKeyword: string = null;
  contactSortParam: string = null;
  contactSortDirection: string = null;
  contactSearching: boolean = false;

  // CONTAS TABLE VARIABLES
  bancListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'banco', name: 'Banco', type: 'text', sort: null, searchable: false, center: false, class: '' },
    { key: 'n_conta', name: 'Nº Conta', type: 'text', sort: null, searchable: false, center: false, class: '' },
    { key: 'tipo_nome', name: 'Tipo', type: 'text', sort: null, searchable: false, center: false, class: '' },
    { key: 'null_6', name: 'Saldo', type: 'text', sort: null, searchable: false, center: false, class: 'col-align-right' },
    { key: 'conta_principal', name: 'Principal', type: 'text', sort: null, searchable: false, center: true, class: 'col-centered' },
  ];
  bancList: Array<any> = [];
  bancListOrig: Array<any> = [];
  bancListPrevState: Array<any> = [];  
  bancListLength: number = null;
  @ViewChild('bancTableSearch', { static: false }) bancTableSearch: ElementRef;
  bancPage: number = 0;
  bancSelectedPage: number = null;
  bancItemPerPAge: number = 9;
  bancKeyword: string = null;
  bancSortParam: string = null;
  bancSortDirection: string = null;
  bancSearching: boolean = false;


  // GENERIC MODAL VARIABLES
  genModalTitle
  @ViewChild('genRef', { static: false }) genRef;
  genModalRef = null;
  genModalConfig: any = null;

  // UPDATE MODAL VARIABLES
  updModalTitle
  @ViewChild('updRef', { static: false }) updRef;
  updModalRef = null;
  updModalConfig: any = null;

  // NEW ZONA FORM
  zonaNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required }),
    morada: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),
    saldo_inicial: new FormControl(null),
    saldo_inicial_fr: new FormControl(null)
  });

  // UPDATE ZONA FORM
  zonaUpdForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required }),
    morada: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),
    saldo_inicial: new FormControl(null),
    saldo_inicial_fr: new FormControl(null)
  });
  zonasDataForm = null;

  // UPDATE SALDOS INICIAIS FORM
  saldosIniciaisUpdForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required }),
    saldo_inicial: new FormControl(null),
    saldo_inicial_fr: new FormControl(null)
  });
  saldosIniciaisDataForm = null;

  // NEW FRACCAO FORM
  fracNewForm = new FormGroup({
    cod: new FormControl(null, { validators: Validators.required }),
    nome: new FormControl(null, { validators: Validators.required }),
    cod_zona: new FormControl(null, { validators: Validators.required}),
    cod_proprietario: new FormControl(null, { validators: Validators.required}),
    cod_inquilino: new FormControl(null),
    permilagem: new FormControl(null),
    obs: new FormControl(null)
  });

  zonaDistinctSelOpts: Array<any> = [];
  zonaDistinctSelOptsOrig: Array<any> = [];
  newAddZonaSel: boolean = false;

  // NEW ASSEMBLEIA FORM
  assembNewForm = new FormGroup({
    dt: new FormControl(null, { validators: Validators.required}),
    dt_alter: new FormControl(null, { validators: Validators.required}),
    dt_fim: new FormControl(null),
    morada: new FormControl(null),
    extraordinaria: new FormControl('0'),
    assunto: new FormControl(null),
  });

  // SEGURO FORM
  segCondForm = new FormGroup({
    cod_companhia: new FormControl(null),
    n_apolice: new FormControl(null),
    dt_inicio: new FormControl(null),
    dt_fim: new FormControl(null),
    pagamento: new FormControl(null),
    janeiro: new FormControl(null),
    fevereiro: new FormControl(null),
    marco: new FormControl(null),
    abril: new FormControl(null),
    maio: new FormControl(null),
    junho: new FormControl(null),
    julho: new FormControl(null),
    agosto: new FormControl(null),
    setembro: new FormControl(null),
    outubro: new FormControl(null),
    novembro: new FormControl(null),
    dezembro: new FormControl(null),
    obs: new FormControl(null),
  });

  // NEW CONTA FORM
  contaNewForm = new FormGroup({
    banco: new FormControl(null, { validators: Validators.required}),
    nib: new FormControl(null),
    cod_tipo_conta: new FormControl(null),
    dt_abertura: new FormControl(null),
    agencia: new FormControl(null),
    gestor: new FormControl(null),
    telefone_1: new FormControl(null),
    morada: new FormControl(null),
    obs: new FormControl(null),
  });

  // NEW CONTACTO FORM
  contactoNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required}),
    tipo_contacto: new FormControl(null),
    n_contribuinte: new FormControl(null),
    telefone_1: new FormControl(null),
    morada: new FormControl(null),
    email_1: new FormControl(null),
    obs: new FormControl(null),
    conta_principal: new FormControl(null),
  });

  // DOCUMENTOS FORM
  docCondForm = new FormGroup({
    // dia_emis_aviso: new FormControl(null, { validators: Validators.required}),
    // dia_venc: new FormControl(null, { validators: Validators.required}),
    dia_emis_aviso: new FormControl(null),
    dia_venc: new FormControl(null),
    n_contribuinte: new FormControl(null),
    identificador_local_EDP: new FormControl(null),
    numero_cliente_SMAS: new FormControl(null),
    dt_admissao: new FormControl(null),
    artigo_matricial: new FormControl(null),
    valor_matricial: new FormControl(null),
    financas: new FormControl(null),
    senha_financas: new FormControl(null),
    cartorio_notarial: new FormControl(null),
    dt_prop_horiz: new FormControl(null),
    livro: new FormControl(null),
    folhas: new FormControl(null),
    conservatoria_registo_predial: new FormControl(null),
    desc_predial: new FormControl(null),
    freguesia_registo_predial: new FormControl(null),
    n_licenca_habitab: new FormControl(null),
    dt_licenca_habitab: new FormControl(null),
    dt_construcao: new FormControl(null),
    camara: new FormControl(null),
    processo_construcao: new FormControl(null),
  });

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('saldoInicialContaAlertRef', { static: false }) saldoInicialContaAlertRef;
  saldoInicialContaModalRef = null;
  saldoInicialContaAlertConfig: any = null;

  @ViewChild('actSegurosAlertRef', { static: false }) actSegurosAlertRef;
  actSegurosModalRef = null;
  actSegurosAlertConfig: any = null;

  @ViewChild('penalizacaoAlertRef', { static: false }) penalizacaoAlertRef;
  penalizacaoModalRef = null;
  penalizacaoAlertConfig: any = null;

  @ViewChild('saldoInicialAlertRef', { static: false }) saldoInicialAlertRef;
  saldoInicialModalRef = null;
  saldoInicialAlertConfig: any = null;

  @ViewChild('saldoInicialDiffAlertRef', { static: false }) saldoInicialDiffAlertRef;
  saldoInicialDiffModalRef = null;
  saldoInicialDiffAlertConfig: any = null;

  @ViewChild('deleteAlertRef_v2', { static: false }) deleteAlertRef_v2;
  alertModalRef_v2 = null;
  deleteAlertConfig_v2: any = null;

  @ViewChild('pagAnteriorAlertRef', { static: false }) pagAnteriorAlertRef;
  pagAnteriorModalRef = null;
  pagAnteriorAlertConfig: any = null;
  
  compSelOpts: Array<any> = [];
  zonaSelOpts: Array<any> = [];

  // TABLES TOTALS
  zonasTotal = {
    n_fraccoes: 0,
    permilagem: 0,
  }

  fraccoesTotal = {
    permilagem: 0,
    quota_orc: 0,
    quota_fr: 0,
    quota_seg: 0,    
  }

  saldosIniciais = {
    orc: 0,
    fr: 0,
    permilagem: 0,
    total: 0,
  }

  accountWarningON = true;

  tabSaldosIniciaisPassed = false;
  
  @ViewChild('condominioContactsPdf', { static: false }) condominioContactsPdf: CondominioContactsPdfComponent;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public location: Location,
              public router: Router,
              public cdRef:ChangeDetectorRef,
              public modalService: SuiModalService,
              public message: MessageService,
              public appState: AppStateService,
              public prevRoute: PreviousRouteService,
              public condominios: CondominiosService,
              public appConfig: AppConfigService) {
    this.getSelectLists();
  }

  canExit = true;
  canDeactivate() {
    return true;

    // if (this.canExit) return true;

    // let zonaListAux = JSON.stringify(this.zonaList);
    // let saldoInicialDistrAux = JSON.stringify(this.saldoInicialDistrList);

    // if (((zonaListAux !== this.zonaListInitSaved || (saldoInicialDistrAux !== this.saldoInicialDistrListSaved)) || (this.selectedConta && this.selectedConta !== '-1' && (this.distValorGc !== null || this.distValorFr !== null))) ) {

    //   if (this.tabSaldosIniciaisPassed) {
    //     this.presentSaldosIniciaisAlert();
    //     return false;  
    //   }
      
    // }

    // return true;
  }

  presentSaldosIniciaisAlert() {
    this.saldoInicialModalRef = this.modalService
      .open(this.saldoInicialAlertConfig)
      .onApprove(() => {
        this.canExit = true;

        // ADD ENTRY IF NEDDED
        if (this.selectedConta && this.selectedConta !== '-1' && (this.distValorGc !== null || this.distValorFr !== null)) {
          this.tableAction('add-dist', 'saldos-iniciais');
        }

        this.formSubmitted('saldos-iniciais-distribuicao', true);
      })
      .onDeny(() => {
        this.canExit = true;
      });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode == 13) {
      if (this.selTab.key !== 'saldos-iniciais') return;

      if (this.selectedConta) {
        this.tableAction('add-dist', 'saldos-iniciais');
      } else {
        // this.formSubmitted('saldos-iniciais-distribuicao');
      }
    }
  }

  ngOnInit() {
    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.isCreate = true;
      this.accountWarningON = false;

      this.tabsObjDef.forEach((el, i) => {
        if (i !== 0) el.disabled = true;
      });
    } else {
      this.isCreate = false;
    }

    this.init();
  }

  init() {
    this.submittingForm = false;
    this.loading = false;

    // this.genCondForm.reset();

    if (!this.isCreate) {
      this.condominioId = (!this.condominioId) ? this.route.snapshot.params.id : this.condominioId;
      this.getCondDetails();
    } else {
      this.api.getCondominioMaxCod().subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.maxCod = Number(res.data.max_cod) + 1;

          this.genCondForm.patchValue({
            estado: '1',
            cod: this.maxCod,
            exercicio: (new Date()).getFullYear(),
          });
        }
      }, err => {});
      
      // Emit signal to breadcrumb component
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVO CONDOMÍNIO` });
    }
  }

  ngAfterViewChecked() {
    this.tabsObjDef.forEach(tab => {
      if (this.router.url.indexOf(tab.key) !== -1) {
        tab.active = true;
      } else {
        tab.active = false;
      }
    });

    this.cdRef.detectChanges();
  }

  zonaListInitSaved = null;
  saldoInicialDistrListSaved = null;
  prevTab = null;
  tabSelected(tab) {
    if (tab.disabled) {
      this.submittingForm = true;
      setTimeout(() => { this.submittingForm = false; }, 2000);
    } else {
      this.selTab = this.tabsObjDef.find(el => (el.key === tab.key));

      if (this.selTab.key === 'saldos-iniciais') {
        // SAVE INICIAL STATE -------------------------  LIMPAR DEPOIS DE SUBMETER
        this.zonaListInitSaved = JSON.stringify(this.zonaList);
        this.saldoInicialDistrListSaved = JSON.stringify(this.saldoInicialDistrList);
        this.prevTab = 'saldos-iniciais';
        this.canExit = false;
        this.tabSaldosIniciaisPassed = true;
        // --------------------------------------------
      } else {

      }

      switch (this.selTab.key) {
        case 'geral':
            this.location.replaceState('/condominios/condominio/geral/' + this.condominioId);
            break;
        case 'zonas':
          this.genModalTitle = 'Nova Zona';
          this.updModalTitle = 'Alterar Zona';

          this.location.replaceState('/condominios/condominio/zonas/' + this.condominioId);
          break;
        case 'saldos-iniciais':
          this.genModalTitle = 'Nova Zona';
          this.updModalTitle = 'Alterar Saldos Iniciais';

          this.location.replaceState('/condominios/condominio/saldosiniciais/' + this.condominioId);
          break;
        case 'fraccoes':
          this.genModalTitle = 'Nova Fracção';

          this.location.replaceState('/condominios/condominio/fraccoes/' + this.condominioId);
          break;
        case 'contactos':
          this.genModalTitle = 'Novo Contacto';

          this.location.replaceState('/condominios/condominio/contactos/' + this.condominioId);
          break;
        case 'contas':
          this.genModalTitle = 'Nova Conta';

          this.location.replaceState('/condominios/condominio/contas/' + this.condominioId);
          break;
        case 'seguro':
          this.location.replaceState('/condominios/condominio/seguro/' + this.condominioId);
          break;
        case 'documentos':
          this.location.replaceState('/condominios/condominio/documentos/' + this.condominioId);
          break;
        case 'observacoes':
          this.location.replaceState('/condominios/condominio/observacoes/' + this.condominioId);
          break;
        case 'penalizacoes':
          this.location.replaceState('/condominios/condominio/penalizacoes/' + this.condominioId);
          break;
        case 'entidades':
          this.location.replaceState('/condominios/condominio/entidades/' + this.condominioId);
          break;
      }
    }
  }

  // TABLES TOTALS
  computeTotals(targetList) {
    switch (targetList) {
      case 'saldos-iniciais-distribuicao':
        this.valorTotalFr = 0;this.valorTotalGc = 0; 
        this.saldoInicialDistrList.forEach(el => {
          this.valorTotalGc += Number(el.dist_valor_gc);
          this.valorTotalFr += Number(el.dist_valor_fr);
        });
        break;
      case 'zonas':
      case 'saldos-iniciais':
        // ZONAS
        this.zonasTotal.permilagem = 0;
        this.zonasTotal.n_fraccoes = 0;
        this.saldosIniciais.orc = 0;
        this.saldosIniciais.fr = 0;
        this.zonaList.forEach(el => {
          this.zonasTotal.permilagem += (el.permilagem) ? Number(el.permilagem) : 0;
          this.zonasTotal.n_fraccoes += (el.n_fraccoes) ? Number(el.n_fraccoes) : 0;
          this.saldosIniciais.orc += (el.saldo_inicial) ? Number(el.saldo_inicial) : 0;
          this.saldosIniciais.fr += (el.saldo_inicial_fr) ? Number(el.saldo_inicial_fr) : 0;
          this.saldosIniciais.permilagem += (el.permilagem) ? Number(el.permilagem) : 0;
        });
        this.saldosIniciais.total = this.saldosIniciais.orc + this.saldosIniciais.fr;
        break;
      case 'fraccoes':
        // FRACCOES
        this.fraccoesTotal.permilagem = 0;
        this.fraccoesTotal.quota_orc = 0;
        this.fraccoesTotal.quota_fr = 0;
        this.fraccoesTotal.quota_seg = 0;
        this.fracList.forEach(el => {
          this.fraccoesTotal.permilagem += (el.permilagem) ? Number(el.permilagem) : 0;
          this.fraccoesTotal.quota_orc += (el.quota_orc) ? Number(el.quota_orc) : 0;
          this.fraccoesTotal.quota_fr += (el.quota_fr) ? Number(el.quota_fr) : 0;
          this.fraccoesTotal.quota_seg += (el.quota_seg) ? Number(el.quota_seg) : 0;
        });
        break;
    }
  }

  tableSearch(keyword, targetList) {
    keyword = keyword.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    switch (targetList) {
      case 'zonas':
        let zonaListOrig = JSON.parse(JSON.stringify(this.zonaListOrig));
        if (keyword) {
          this.zonaList = this.utils.tableSearch(keyword, this.zonaListCol, zonaListOrig);
        } else {
          this.zonaList = zonaListOrig;
        }
        break;
      case 'pag-anteriores':
        if (keyword) {
          this.pagAnterioresList = this.utils.tableSearch(keyword, this.pagAnterioresList, this.pagAnterioresListOrig);
        } else {
          this.pagAnterioresList = [].concat(this.pagAnterioresListOrig);
        }
        break;
      case 'fraccoes':
        if (keyword) {
          this.fracList = this.utils.tableSearch(keyword, this.fracListCol, this.fracListOrig);
        } else {
          this.fracList = [].concat(this.fracListOrig);
        }
        break;
      case 'contactos':
        if (keyword) {
          this.contactList = this.utils.tableSearch(keyword, this.contactListCol, this.contactListOrig);
        } else {
          this.contactList = this.contactListOrig;
        }
        break;
      case 'contas':
        if (keyword) {
          this.bancList = this.utils.tableSearch(keyword, this.bancListCol, this.bancListOrig);
        } else {
          this.bancList = this.bancListOrig;
        }
        break;
    }
  }

  ngAfterViewInit() {
    // ZONAS INPUT SEARCH
    fromEvent(this.zonaTableSearch.nativeElement, 'keyup').pipe(debounceTime(200)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'zonas');
    });
    // FRACCOES INPUT SEARCH
    fromEvent(this.fracTableSearch.nativeElement, 'keyup').pipe(debounceTime(200)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'fraccoes');
    });
    // // PAGAMENTOS ANTERIORES INPUT SEARCH
    // fromEvent(this.pagAnterioresTableSearch.nativeElement, 'keyup').pipe(debounceTime(200)).subscribe(val => {
    //   this.tableSearch(val['target']['value'], 'pag-anteriores');
    // });
    // // CONTACTOS INPUT SEARCH
    // fromEvent(this.contactTableSearch.nativeElement, 'keyup').pipe(debounceTime(200)).subscribe(val => {
    //   this.tableSearch(val['target']['value'], 'contactos');
    // });
    // CONTAS INPUT SEARCH
    fromEvent(this.bancTableSearch.nativeElement, 'keyup').pipe(debounceTime(200)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'contas');
    });

    this.genModalConfig = new TemplateModalConfig<IContext, string, string>(this.genRef);
    this.genModalConfig.closeResult = "closed";
    this.genModalConfig.size = 'small';
    this.genModalConfig.transition = 'fade up';
    this.genModalConfig.transitionDuration = 400;

    this.updModalConfig = new TemplateModalConfig<IContext, string, string>(this.updRef);
    this.updModalConfig.closeResult = "closed";
    this.updModalConfig.size = 'small';
    this.updModalConfig.transition = 'fade up';
    this.updModalConfig.transitionDuration = 400;

    this.saldoInicialContaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.saldoInicialContaAlertRef);
    this.saldoInicialContaAlertConfig.isClosable = false;
    this.saldoInicialContaAlertConfig.closeResult = "closed";
    this.saldoInicialContaAlertConfig.size = 'mini';
    this.saldoInicialContaAlertConfig.transition = 'fade';
    this.saldoInicialContaAlertConfig.transitionDuration = 250;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.actSegurosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.actSegurosAlertRef);
    this.actSegurosAlertConfig.closeResult = "closed";
    this.actSegurosAlertConfig.size = 'normal';
    this.actSegurosAlertConfig.transition = 'fade';
    this.actSegurosAlertConfig.transitionDuration = 250;

    this.deleteAlertConfig_v2 = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef_v2);
    this.deleteAlertConfig_v2.closeResult = "closed";
    this.deleteAlertConfig_v2.size = 'mini';
    this.deleteAlertConfig_v2.transition = 'fade';
    this.deleteAlertConfig_v2.transitionDuration = 250;

    this.penalizacaoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.penalizacaoAlertRef);
    this.penalizacaoAlertConfig.closeResult = "closed";
    this.penalizacaoAlertConfig.size = 'mini';
    this.penalizacaoAlertConfig.transition = 'fade';
    this.penalizacaoAlertConfig.transitionDuration = 250;

    this.saldoInicialAlertConfig = new TemplateModalConfig<IContext, string, string>(this.saldoInicialAlertRef);
    this.saldoInicialAlertConfig.closeResult = "closed";
    this.saldoInicialAlertConfig.size = 'mini';
    this.saldoInicialAlertConfig.transition = 'fade';
    this.saldoInicialAlertConfig.transitionDuration = 250;

    this.saldoInicialDiffAlertConfig = new TemplateModalConfig<IContext, string, string>(this.saldoInicialDiffAlertRef);
    this.saldoInicialDiffAlertConfig.isClosable = false;
    this.saldoInicialDiffAlertConfig.closeResult = 'closed';
    this.saldoInicialDiffAlertConfig.size = 'mini';
    this.saldoInicialDiffAlertConfig.transition = 'fade';
    this.saldoInicialDiffAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: null });
    this.toastr.clear();
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  getCondZonaDetails() {
    // SECTION TABS API CALLS
    this.api.getCondZonasDetails(this.condDetails.cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.zonaSelOpts = res.data.map(el => {
          return { name: el.nome, value: el.cod }
        });

        // SET APP STATE
        if (this.zonaSelOpts.length > 0) this.appState.condZonasList = this.zonaSelOpts;

        let zonaAux = res.data.map(el => {
          try {
            let addressObj = this.utils.moradaStrToJSON(el, el.morada);
            Object.keys(addressObj).forEach(key => {
              el[key] = addressObj[key];
            });
          } catch(e) {}
          return el;
        });

        zonaAux = zonaAux.sort((a, b) => {
          if (Number(a.permilagem) < Number(b.permilagem)) {
            return 1;
          } else {
            return -1;
          }
        });

        zonaAux.forEach(el => {
          el.permilagem = (el.permilagem) ? Number(el.permilagem) : 0;
          el.saldo_inicial = (el.saldo_inicial) ? Number(el.saldo_inicial) : 0;
          el.saldo_inicial_fr = (el.saldo_inicial_fr) ? Number(el.saldo_inicial_fr) : 0;
          el['total'] = el.saldo_inicial + el.saldo_inicial_fr;
        });

        this.zonaList = zonaAux;
        this.zonaListOrig = JSON.parse(JSON.stringify(zonaAux));
        this.zonaListLength = zonaAux.length;

        this.computeTotals('zonas');
        this.computeTotals('fraccoes');
      } else {}
    }, err => {});
  }

  enableTabs() {
    this.tabsObjDef.forEach(tab => { tab.disabled = false; });
  }

  getContasDetails() {
    this.api.getCondContasDetails(this.condDetails.cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        let caixas = res.data.filter(el => (el.banco === 'CAIXA'));
        let contas = res.data.filter(el => (el.banco !== 'CAIXA'));

        if (caixas.length === 0) {

          // CREATE CAIXA
          this.api.createCaixa(this.condDetails.cod).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {
              this.getContasDetails();
            } else {}
          }, err => {});
          
        } else {

          this.bancList = caixas.concat(contas);
          this.bancListOrig = caixas.concat(contas).map(el => {
            el.conta_principal = this.utils.strToBool(el.conta_principal);
            el.saldo = Number(el.saldo);
            return el;
          });
  
          this.bancListLength = res.data.length;
  
          this.contaOptsOrig =  res.data.map(el => {
            return { name: el.banco, value: el.cod };
          });
          this.contaOpts = JSON.parse(JSON.stringify([{ name: '-- Limpar selecção --', value: '-1' }].concat(this.contaOptsOrig)));
  
          contas = this.contaOpts.map(el => { return { nid_conta: el.value , nid_rubrica: '8' }; });
  
          let req = [
            this.api.getCondSaldosIniciaisDetails(contas),
          ];
          contas.filter(el => el.nid_conta !== '-1').forEach(el => { req.push(this.api.getFirstMovimento(el.nid_conta)); });
          forkJoin(req).subscribe(res => {
            if (res[0].hasOwnProperty('success') && res[0].success) {
              let distValorGc = null;
              let distValorFr = null;
              this.saldoInicialDistrList = res[0].data.map(el => {
                if (el.obj) {
                  try {
                    el.obj = JSON.parse(el.obj);
                    distValorGc = el.obj.dist_valor_gc;
                    distValorFr = el.obj.dist_valor_fr;
                  } catch {
                    distValorGc = Number(el.valor);
                    distValorFr = 0;
                  }
                } else {
                  distValorGc = Number(el.valor);
                  distValorFr = 0;
                }
  
                let saldoInicial = {
                  id: el.id,
                  nid_conta: el.nid_conta,
                  conta: this.contaOpts.find(conta => (conta.value === el.nid_conta)).name,
                  dist_valor_gc: (distValorGc) ? Number(distValorGc) : 0,
                  dist_valor_fr: (distValorFr) ? Number(distValorFr) : 0,
                  dt_mov: (el.dt_mov) ? this.utils.getDate(el.dt_mov) : null,
                  checked: false,
                }
  
                this.contaOpts = this.contaOpts.filter(conta => (conta.value !== el.nid_conta));
  
                return saldoInicial;
              });
  
              let caixas = this.saldoInicialDistrList.filter(el => (el.conta === 'CAIXA'));
              let bancos = this.saldoInicialDistrList.filter(el => (el.conta !== 'CAIXA'));
              this.saldoInicialDistrList = caixas.concat(bancos);

              this.computeTotals('saldos-iniciais-distribuicao');

              // SAVE SALDO INICIAL ISTRIBUTION
              this.saldoInicialDistrListSaved = JSON.stringify(this.saldoInicialDistrList);

              // CHECK IF THERE IS SALDO INICIAL DEFINIDO PARA AS ZONAS
              let hasSaldoInicialZona = false;
              this.zonaList.forEach(el => {
                if (el.saldo_inicial !== null || el.saldo_inicial_fr !== null) {
                  hasSaldoInicialZona = true;
                }
              });

              let caixa = this.saldoInicialDistrList.find(el => (el.conta === 'CAIXA'));
              if (!hasSaldoInicialZona && caixa && caixa.hasOwnProperty('dist_valor_gc') && caixa.dist_valor_gc > 0 && this.zonaList.length > 0) {
                this.zonaList[0]['saldo_inicial'] = caixa.dist_valor_gc;
              }

              // CHECK SE EXISTE CONTA BANCARIA PRINCIPAL E SE ESTA TEM IBAN ATRIBUIDO
              let tab = this.tabsObjDef.find(el => (el.active));
              if (this.accountWarningON && tab && tab.key === this.tabsObjDef[0].key) {
                let contasBancarias = this.bancListOrig.find(conta => (conta.banco !== 'CAIXA' && conta.conta_principal));
                let contasBancariasList = this.bancListOrig.filter(conta => (conta.banco !== 'CAIXA'));
                if (contasBancarias) {
                  if (!contasBancarias.nib) {
                    this.toastr.warning('Conta bancária principal não tem IBAN atribuído.', 'Aviso', { timeOut: 0, extendedTimeOut: 0, tapToDismiss: true });
                  }
                } else {
                  if (contasBancariasList.length > 0) this.toastr.warning('Condomínio não tem conta bancária principal atribuída.', 'Aviso', { timeOut: 0, extendedTimeOut: 0, tapToDismiss: true });              
                }
              }

              try {
                let aux = JSON.parse(this.condDetails.saldo_inicial_config);
                this.tipoReparticaoZona = aux['tipoReparticaoZona'];
                this.saldoInicialDistSel = aux['saldoInicialDistSel'];
              } catch (err) {
                if (Array.isArray(this.saldoInicialDistrList) && this.saldoInicialDistrList.length > 0) this.tipoReparticaoZona = 'MANUAL';
              }
              this.contasFirstMovimentos = [];
              res.forEach((el, i) => {
                if (i > 0 && el.hasOwnProperty('success') && el.success && !!el.data) {
                  this.contasFirstMovimentos.push(el.data);
                }
              });
              this.saldosIniciaisFetched = true;

            } else { this.saldosIniciaisFetched = true; }
          }, err => { this.saldosIniciaisFetched = true; });
        }
      } else {}
    }, err => {});
  }

  // ACTUALIZACOES DE SERGURO TABLE VARIABLES
  actSegListCol = [
    { key: 'checked', name: null, type: 'checkbox', class: 'table-checkbox-column col-centered' },
    { key: 'act_dt_inicio', name: 'Data Início', type: 'date', class: 'col-centered' },
    { key: 'act_dt_fim', name: 'Data Fim', type: 'date', class: 'col-centered' },
    { key: 'act_dt_vencimento', name: 'Data Venc.', type: 'date', class: 'col-centered' },
    { key: 'obrigat', name: 'Cap. Obrigatório', type: 'number', class: 'col-align-right' },
    { key: 'capital', name: 'Cap. Real', type: 'number', class: 'col-align-right' },
    { key: 'premio', name: 'Prémio', type: 'number', class: 'col-align-right' },
    { key: 'activo', name: 'Activo', type: 'boolean', class: 'col-centered' },
  ];
  actSegList: Array<any> = [];

  actSegDistriListCol = [
    { key: 'cod', name: 'Fracção', type: 'text', class: '' },
    { key: 'nome', name: 'Condómino', type: 'text', class: '' },
    { key: 'permilagem', name: 'Perm.', type: 'text', class: 'col-align-right' },
    { key: 'seg_colect', name: 'Seg. Colectivo', type: 'boolean', class: 'col-centered' },
    { key: 'capital', name: 'Cap. Obrigatório', type: 'number', class: 'col-align-right' },
    { key: 'premio', name: 'Prémio', type: 'number', class: 'col-align-right' },
  ];
  actSegDistriList = [];
  actSegTotal = {
    actPermilagem: null,
    permilagem: null,
    capital: null,
    premio: null,
  }
  dismissDisabled = false;
  getActSeguros() {
    this.api.getActSeguros(this.condDetails.cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.actSegList = res.data.map(el => {
          el['checked'] = false;

          el['obrigat'] = Math.round(Number(el.obrigat) * 100) / 100;
          el['premio'] = Math.round(Number(el.premio) * 100) / 100;

          el['act_dt_inicio'] = (el['act_dt_inicio']) ? el['act_dt_inicio'] : null;
          el['act_dt_fim'] = (el['act_dt_fim']) ? el['act_dt_fim'] : null;
          el['act_dt_vencimento'] = (el['act_dt_vencimento']) ? el['act_dt_vencimento'] : null;

          el['activo'] = (el['activo'] === '1');

          return el;
        });

        if (this.actSegurosModalRef && !this.dismissDisabled) this.actSegurosModalRef.approve();
      } else {
        // this.toastr.error('Não foi possível efectuar a operação. Por favor, verifique a sua ligação à Internet e/ou tente novamente mais tarde.', 'Ups...!');
      }
    }, err => {
      // this.toastr.error('Não foi possível efectuar a operação. Por favor, verifique a sua ligação à Internet e/ou tente novamente mais tarde.', 'Ups...!');
    });
  }

  submitActSeguro() {
    this.submittingModalForm = true;

    // CHECK VALIDATORS
    if (this.actSeguro && (!this.actSeguro.act_dt_inicio || !this.actSeguro.obrigat)) {
      setTimeout(() => { this.submittingModalForm = false; }, 4000);
      return;
    }

    this.loadingModal = true;
    // SEND CREATE API CALL

    if (!this.actSeguro.hasOwnProperty('cod_seguro')) {
      this.actSeguro['cod_seguro'] = this.condDetails.seguro.cod;
    }

    this.api.saveActSeguros(this.actSeguro.id_act_seguro, this.actSeguro.cod_seguro, this.actSeguro.act_dt_inicio, this.actSeguro.act_dt_fim, this.actSeguro.act_dt_vencimento, this.actSeguro.obrigat, this.actSeguro.capital, this.actSeguro.premio, this.actSeguro.activo, this.condDetails.cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.getActSeguros();
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.loadingModal = false;
      this.submittingModalForm = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title, { timeOut: 4000 });
      this.loadingModal = false;
      this.submittingModalForm = false;
    });

  }

  actSegChanged(target) {
    let reparticao = this.segCondForm.get('pagamento').value;

    switch (target) {
      case 'CAP_OBRIGATORIO':  // UPDATE CAPITAL
        if (reparticao === 'P') {
          this.actSegDistriList.forEach(el => {
            el.capital = Math.round(((this.actSeguro.obrigat * el.permilagem) / this.actSegTotal.permilagem) * 100) / 100;
          });
        }

        if (reparticao === 'E') {
          let nFraccoes = this.actSegDistriList.filter(el => (el.seg_colect === '1')).length;
          this.actSegDistriList.forEach(el => {
            el.capital = Math.round((this.actSeguro.obrigat / nFraccoes) * 100) / 100;
          });
        }
        break;
      case 'PREMIO':  // UPDATE PREMIO
        if (reparticao === 'P') {
          this.actSegDistriList.forEach(el => {
            if (el.seg_colect === '1') el.premio = Math.round(((this.actSeguro.premio * el.permilagem) / this.actSegTotal.actPermilagem) * 100) / 100;
          });
        }

        if (reparticao === 'E') {
          let nFraccoes = this.actSegDistriList.filter(el => (el.seg_colect === '1')).length;
          this.actSegDistriList.forEach(el => {
            if (el.seg_colect === '1') el.premio = Math.round((this.actSeguro.premio / nFraccoes) * 100) / 100;
          });
        }
        break;
    }

    this.computeActSegTotal();
  }

  codCondominio = null;
  nomeCondominio = null;
  getCondDetails(showNotification=true) {
    this.accountWarningON = showNotification;

    this.api.getCondominiumDetails(this.condominioId).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${res.data.classificacao} - ${res.data.cod} - ${res.data.nome}` });

        this.enableTabs();

        this.condDetails = { ... res.data, seguro: res.seguro };
        this.codCondominio = this.condDetails.cod;
        this.nomeCondominio = this.condDetails.nome;
        try {
          this.condDetails = this.utils.moradaStrToJSON(this.condDetails, this.condDetails.morada);
        } catch(e) {}

        if (res.contador) this.contador = res.contador;

        this.getCondZonaDetails();
    
        this.api.getCondFraccoes(this.condDetails.cod).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.fracList = res.data;
            this.fracListOrig = res.data;
            this.fracListLength = res.data.length;

            // CREATE PAGAMENTOS ANTERIORES TABLE
            this.pagAnterioresList = [];
            this.actSegDistriList = [];
            this.fracList.forEach(el => {
              this.pagAnterioresList.push({
                checked: false,
                cod: el.cod,
                nome: el.nome,
                condomino_nome: el.condomino_nome,
                cod_pagador: el.cod_proprietario,
                data: el.data_credito,
                cod_condominio: this.condDetails.cod,
                id: el.id_credito,
                valor: (el.valor_credito) ? Number(el.valor_credito) : null,
                credito: (el.credito_credito) ? Number(el.credito_credito) : null,
              });

              this.actSegDistriList.push({
                cod: el.cod + ' - ' + el.nome,
                cod_fraccao: el.cod,
                nome: el.condomino_nome,
                permilagem: Number(el.permilagem),
                seg_colect: el.paga_seg_colect,
                capital: null,
                premio: null,
              });
            });
            this.pagAnterioresListOrig = JSON.parse(JSON.stringify(this.pagAnterioresList));

            this.computeTotals('fraccoes');
          } else {}
        }, err => {});

        this.getContasDetails();

        this.api.getCondContactosDetails(this.condDetails.cod).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            this.contactList = res.data.map(el => {
              el.tipo_contacto_label = this.appConfig.tipoContactSelOpts.find(aux => (el.tipo_contacto === aux.value));
              if (el.tipo_contacto_label) el.tipo_contacto_label = el.tipo_contacto_label.name;
              return el;
            });

            this.contactListOrig = [].concat(this.contactList);
            this.contactListLength = res.data.length;
          } else {}
        }, err => {});

        this.getActSeguros();

        // SET PENALIZACOES OBJECT
        if (this.condDetails.penal_table) {
          this.penalizacoesList = JSON.parse(this.condDetails.penal_table);
        }
        
        // REGISTO ACTIVIDADES API CALL
        let titulo = 'Condomínio Visualizado'
        let descricao = 'Condomínio: ' + this.condDetails.cod + ' - ' + this.condDetails.nome;
        this.api.saveRegistoActividade(this.condDetails.cod, 'CONDOMINIO', this.condDetails.cod, titulo, descricao, { link: this.condDetails.id, classificacao: this.condDetails.classificacao, cod: this.condDetails.cod, nome: this.condDetails.nome }).subscribe(res => {}, err => { });


        this.condominios.hasTitularesPorEfetivar(this.condDetails.cod).then(hasTitulares => {
          if (hasTitulares) {
            this.toastr.warning('O presente condomínio possui titulares bancários que carecem de validação.', 'Atenção');
          }
        }).catch();
        
        this.restoreForm('geral');
        this.restoreForm('seguro');
        this.restoreForm('observacoes');
        this.restoreForm('documentos');
      } else {
        this.toastr.error('Não foi possível efectuar a operação. Por favor, verifique a sua ligação à Internet e/ou tente novamente mais tarde.', 'Ups...!');
      }
    }, err => {
      this.toastr.error('Não foi possível efectuar a operação. Por favor, verifique a sua ligação à Internet e/ou tente novamente mais tarde.', 'Ups...!');
    });
  }

  selectedOpt(ev, targetForm) {
    if (ev.target.innerText.indexOf('-- N') !== -1) {
      switch (targetForm) {
        case 'zona-add':
          this.newAddZonaSel = true;
          break;
      }
    }
  }

  goBackSelect(ev, targetForm) {
      if (!ev.target.value) {
        switch (targetForm) {
          case 'zona-add':
            this.newAddZonaSel = false;
            break;
        }
      }
  }

  getSelectLists() {
    // ZONAS LIST
    this.api.getAllDistinctZonas().subscribe(res => {
      if (res.success) {
        let zonaDistinct = res.data.map(el => { return { name: el.nome, value: el.nome }; });

        this.zonaDistinctSelOpts = [{ name: '-- Nova Zona --', value: 'NEW',}].concat(zonaDistinct);
        this.zonaDistinctSelOptsOrig = JSON.parse(JSON.stringify(this.zonaDistinctSelOpts));
      }
    });

    // COMPANHIAS LIST
    this.api.getAllCompanhias().subscribe(res => {
      if (res.success) this.compSelOpts = [{ name: '-- Nova Companhia --', value: 'NEW',}].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
    });
  }

  computeActSegTotal() {
    this.actSegTotal = { actPermilagem: null, permilagem: null, capital: null, premio: null };

    this.actSegDistriList.forEach(el => {
      this.actSegTotal.actPermilagem += (el.seg_colect === '1') ? Number(el.permilagem) : 0;
      this.actSegTotal.permilagem += Number(el.permilagem);
      this.actSegTotal.capital += Number(el.capital);
      this.actSegTotal.premio += Number(el.premio);
    });
  }

  pagAnteriorEntry = null;
  credito = null;
  submittingFormPagAnterior = false;
  submittingActSeguros = false;
  actSeguro = null;
  goToDetails(sel, targetList) {
    switch (targetList) {
      case 'zonas':
        this.zonasDataForm = sel;
        this.restoreForm('zona-update') 

        this.openUpdModal();
        break;
      case 'saldos-iniciais':
        this.saldosIniciaisDataForm = sel;
        this.restoreForm('saldos-iniciais') 

        this.openUpdModal();
        break;
      case 'act-seguros':
        this.actSeguro = JSON.parse(JSON.stringify(sel));
        this.actSeguro.act_dt_inicio = (this.actSeguro.act_dt_inicio) ? new Date(this.actSeguro.act_dt_inicio) : null;
        this.actSeguro.act_dt_fim = (this.actSeguro.act_dt_fim) ? new Date(this.actSeguro.act_dt_fim) : null;
        this.actSeguro.act_dt_vencimento = (this.actSeguro.act_dt_vencimento) ? new Date(this.actSeguro.act_dt_vencimento) : null;

        this.computeActSegTotal();
        
        this.actSegChanged('CAP_OBRIGATORIO');
        this.actSegChanged('PREMIO');
        this.actSegurosModalRef = this.modalService
          .open(this.actSegurosAlertConfig)
          .onApprove(() => { this.loadingModal = false; this.submittingActSeguros = false; this.actSeguro = null; this.actSegurosModalRef = null; this.dismissDisabled = false; })
          .onDeny(() => { this.loadingModal = false; this.submittingActSeguros = false; this.actSeguro = null; this.actSegurosModalRef = null; this.dismissDisabled = false; });
        break;
      case 'fraccoes':
        this.appState.setMoradaCondominio({
          morada: this.genCondForm.get('morada').value,
          postalCode: this.genCondForm.get('postalCode').value,
          locality: this.genCondForm.get('locality').value,
        });

        this.router.navigate(['fraccoes/geral', this.condDetails.cod, sel.id]);
        break;
      case 'contactos':
        this.router.navigate(['contactos', this.condDetails.cod, sel.id]);
        break;
      case 'contas':
        this.router.navigate(['contas', this.condDetails.cod, sel.id]);
        break;
    }

    // Emit signal to breadcrumb component
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.condDetails.nome}` });
  }

  openUpdModal() {
    this.updModalRef = this.modalService.open(this.updModalConfig)
      .onApprove(() => { 
        this.submittingModalForm = false; 
        this.loadingModal = false; 
      })
      .onDeny(() => { 
        this.submittingModalForm = false; 
        this.loadingModal = false; 
      });
  }

  // saldoInicialDistrChanged(target) {
  //   setTimeout(() => {
  //     let caixa = this.saldoInicialDistrList.find(el => (el.conta === 'CAIXA'));
  //     let total = null;
  //     switch (target) {
  //       case 'gestao-corrente':
  //         total = 0;
  //         this.saldoInicialDistrList.filter(el => (el.conta !== 'CAIXA')).forEach(el => {
  //           total += Number(el.dist_valor_gc);
  //         });

  //         caixa.dist_valor_gc = Number(this.saldosIniciais.orc) - total;
  //         break;
  //       case 'fundo-reserva':
  //         total = 0;
  //         this.saldoInicialDistrList.filter(el => (el.conta !== 'CAIXA')).forEach(el => {
  //           total += Number(el.dist_valor_fr);
  //         });

  //         caixa.dist_valor_fr = Number(this.saldosIniciais.fr) - total;
  //         break;
  //     }
  //   });
  // }

  fetchingGeral = false;
  fetchingSeguro = false;
  fetchingSaldosIniDistr = false;
  fetchingSaldosIniciais = false;
  fetchingZona = false;
  fetchingContacto = false;
  fetchingConta = false;
  fetchingDoc = false;
  fetchingPenal = false;
  fetchingObs = false;

  toAddError = false;

  formSubmitted(targetForm, presentToast=false, openSegModal=false) {
    let data = null;

    switch (targetForm) {
      case 'geral':
        this.submittingForm = true;

        if (!this.genCondForm.valid) return;
  
        this.loading = true;

        data = this.genCondForm.getRawValue();

        data.morada = this.utils.moradaJSONToStr(data);

        if (this.isCreate) {

          if (this.fetchingGeral) {
            this.loading = false;
            return;
          } 

          this.fetchingGeral = true;
          this.api.addCondominium(data.cod, data.exercicio, data.nome, data.morada, data.classificacao, data.onedrive_link, data.bitrix_link).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {
              this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${data.classificacao} - ${data.cod} - ${data.nome}` });

              this.condominioId = res.data.id;

              this.location.go('/condominios/condominio/' + this.condominioId);

              this.isCreate = false;
              this.loading = false;
              this.fetchingGeral = false;

              this.init();

              // REGISTO ACTIVIDADES API CALL
              let titulo = 'Condomínio Criado'
              let descricao = 'Condomínio: ' + data.cod + ' - ' + data.nome;
              this.api.saveRegistoActividade(data.cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

            } else {
              if (res.hasOwnProperty('status')) {
                switch (res.status) {
                  case 'COD_ALREADY_EXISTS':
                    this.codAlreadyExiste = true;
                    break;
                  case 'UNAUTHORIZED':
                    this.utils.apiErrorMsg(res);
                    break;
                }
              } else {
                // this.utils.apiErrorMsg(res);
              }
              this.loading = false;
            }
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.loading = false;
            this.fetchingGeral = false;
          });
        } else {

          if (this.fetchingGeral) {
            this.submittingForm = false;
            this.loading = false;
            return;
          } 

          this.api.updateGeralCondominio(this.condominioId, data.estado, data.cod,data.exercicio,data.nome,data.morada, data.pag_orcamento, data.pag_fr, data.fr, data.fr_perc, data.latitude, data.longitude, data.classificacao, data.onedrive_link, data.bitrix_link).subscribe(res => {
            if (res.hasOwnProperty('success') && res['success']) {     
              // API RETURN SUCCESS
              this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${data.classificacao} - ${data.cod} - ${data.nome}` });

              // REGISTO ACTIVIDADES API CALL
              let titulo = 'Condomínio Actualizado'
              let descricao = 'Condomínio: ' + data.cod + ' - ' + data.nome;
              this.api.saveRegistoActividade(data.cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

            } else {
              this.utils.apiErrorMsg(res);              
            }
            this.submittingForm = false;
            this.loading = false;
            this.fetchingGeral = false;
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.submittingForm = false;
            this.loading = false;
            this.fetchingGeral = false;
          });
        }
        break;
      case 'seguro':
          this.submittingForm = true;

          if (!this.segCondForm.valid) return;
    
          this.loading = true;

          if (this.fetchingSeguro) {
            this.submittingForm = false;
            this.loading = false;
            return;
          } 
          this.fetchingSeguro = true;

          data = this.segCondForm.getRawValue();
          this.api.updateSeguroCondominio(this.condDetails.cod, data.cod_companhia, data.n_apolice, data.dt_inicio, data.dt_fim, data.pagamento, data.janeiro, data.fevereiro, data.marco, data.abril, data.maio, data.junho, data.julho, data.agosto, data.setembro, data.outubro, data.novembro, data.dezembro, data.obs).subscribe(res => {
            if (res.hasOwnProperty('success') && res['success']) {     
              // API RETURN SUCCESS

              // REGISTO ACTIVIDADES API CALL
              let auxData = this.genCondForm.getRawValue();
              let titulo = 'Condomínio Actualizado'
              let descricao = 'Condomínio: ' + auxData.cod + ' - ' + auxData.nome;
              this.api.saveRegistoActividade(auxData.cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

              // UPDATE CONDOMINIO DETAILS
              this.getCondDetails(false);

              if (openSegModal) {
                this.dismissDisabled = true;

                this.actSeguro = {
                  obrigat: null,
                  premio: null,
                  act_dt_inicio: null,
                  act_dt_fim: null,
                  act_dt_vencimento: null,
                  activo: false,
                };
                this.computeActSegTotal();
                
                this.actSegChanged('CAP_OBRIGATORIO');
                this.actSegChanged('PREMIO');
                this.actSegurosModalRef = this.modalService
                  .open(this.actSegurosAlertConfig)
                  .onApprove(() => { this.loadingModal = false; this.submittingActSeguros = false; this.actSeguro = null; this.dismissDisabled = false; })
                  .onDeny(() => { this.loadingModal = false; this.submittingActSeguros = false; this.actSeguro = null; this.dismissDisabled = false; });
              }

            } else {
              this.utils.apiErrorMsg(res);
            }
            this.submittingForm = false;
            this.loading = false;
            this.fetchingSeguro = false;
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.submittingForm = false;
            this.loading = false;
            this.fetchingSeguro = false;
          }); 
          break;
      case 'zona-add':
        this.submittingModalForm = true;

        if (!this.zonaNewForm.valid) return;

        this.loadingModal = true;

        if (this.fetchingZona) {
          this.submittingModalForm = false;
          this.loadingModal = false;
          return;
        } 
        this.fetchingZona = true;

        data = this.zonaNewForm.getRawValue();
        data.morada = this.utils.moradaJSONToStr(data);
        this.api.addZonaCondominio(this.condDetails.cod, data.nome, data.morada, data.saldo_inicial, data.saldo_inicial_fr).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {
            try {
              res.data = this.utils.moradaStrToJSON(res.data, res.data.morada);
            } catch(e) {}

            res.data['checked'] = false;
            this.condDetails.n_zonas = res.data.n_zonas;
            this.zonaList = [res['data']].concat(this.zonaList);

            this.zonaListLength ++;

            this.clearForm('zona-add');
            this.genModalRef.approve();

            this.getCondZonaDetails();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingModalForm = false;
          this.loadingModal = false;
          this.fetchingZona = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingModalForm = false;
          this.loadingModal = false;
          this.fetchingZona = false;
        });
        break;
      case 'saldos-iniciais-distribuicao':
        break;
      case 'saldos-iniciais':
        this.submittingModalForm = true;

        if (!this.saldosIniciaisUpdForm.valid) return;

        this.loadingModal = true;

        data = this.saldosIniciaisUpdForm.getRawValue();

        if (this.saldoInicialDistrList.find(el => (el.conta !== 'CAIXA'))) {

          // CHECK IF SALDO INICIAL IS OK
          let zonaListAux = JSON.parse(JSON.stringify(this.zonaList));
          let zonaAux = zonaListAux.find(el => (el.id ===data.id));
          if (zonaAux) {
            zonaAux.saldo_inicial = data.saldo_inicial;
            zonaAux.saldo_inicial_fr = data.saldo_inicial_fr;
          }
          let saldosIniciaisOrc = 0;
          let saldosIniciaisFr = 0;
          zonaListAux.forEach(el => {
            saldosIniciaisOrc += (el.saldo_inicial) ? Number(el.saldo_inicial) : 0;
            saldosIniciaisFr += (el.saldo_inicial_fr) ? Number(el.saldo_inicial_fr) : 0;
          });

          let valorTotalGc = 0;
          let valorTotalFr = 0;
          this.saldoInicialDistrList.filter(el => (el.conta !== 'CAIXA')).forEach(el => {
            valorTotalGc += Number(el.dist_valor_gc);
            valorTotalFr += Number(el.dist_valor_fr);
          });

          if (saldosIniciaisOrc < valorTotalGc || saldosIniciaisFr < valorTotalFr) {
            this.toastr.error('O valor dos saldos iniciais totais das zonas é inferior ao total da distribuição pelas contas. Ajustar o valor da contas bancárias para continuar.', 'Alerta', { timeOut: 4000 });
            this.loadingModal = false;
            return;
          }

        }

        let zona = this.zonaList.find(el => (el.id ===data.id));
        if (zona) {
          zona.saldo_inicial = data.saldo_inicial;
          zona.saldo_inicial_fr = data.saldo_inicial_fr;
        }

        this.computeTotals('saldos-iniciais');

        let caixa = this.contaOpts.find(el => (el.name === 'CAIXA'));
        this.contaOpts = this.contaOpts.filter(el => (el.name !== 'CAIXA'));

        if (this.saldoInicialDistrList.length === 0) {
          // ADD ALL SALDO INICIAL TO CAIXA
          this.saldoInicialDistrList = [{
            nid_conta: caixa.value,
            conta: caixa.name,
            dist_valor_gc: (this.saldosIniciais.orc) ? Number(this.saldosIniciais.orc) : 0,
            dist_valor_fr: (this.saldosIniciais.fr) ? Number(this.saldosIniciais.fr) : 0,
            checked: false,
          }].concat(this.saldoInicialDistrList);
        } else {
          let inBankOrc = 0;
          let inBankFr = 0;
          this.saldoInicialDistrList.forEach(el => {
            if (el.conta !== 'CAIXA') {
              inBankOrc += Number(el.dist_valor_gc);
              inBankFr += Number(el.dist_valor_fr);
            }
          });
          let distCaixa = this.saldoInicialDistrList.find(el => (el.conta === 'CAIXA'));
          distCaixa.dist_valor_gc = (this.saldosIniciais.orc) ? Number(this.saldosIniciais.orc) - inBankOrc : 0;
          distCaixa.dist_valor_fr = (this.saldosIniciais.fr) ? Number(this.saldosIniciais.fr) - inBankFr : 0;  
        }

        this.computeTotals('saldos-iniciais-distribuicao');
        this.clearForm('saldos-iniciais');

        this.submittingModalForm = false;
        this.loadingModal = false;

        this.updModalRef.approve();
        break;
      case 'zona-update':
        this.submittingModalForm = true;

        if (!this.zonaUpdForm.valid && targetForm === 'zona-update') return;
        // if (!this.saldosIniciaisUpdForm.valid && targetForm === 'saldos-iniciais') return;

        this.loadingModal = true;
  
        data = this.zonaUpdForm.getRawValue();
        data.morada = this.utils.moradaJSONToStr(data);

        // if (targetForm === 'saldos-iniciais') {
        //   let auxData = this.saldosIniciaisUpdForm.getRawValue();
        //   data.id = auxData.id;
        //   data.nome = auxData.nome;
        //   data.saldo_inicial = auxData.saldo_inicial;
        //   data.saldo_inicial_fr = auxData.saldo_inicial_fr;
        // }

        if (this.fetchingZona) {
          this.submittingModalForm = false;
          this.loadingModal = false;
          return;
        } 
        this.fetchingZona = true;

        this.api.updateZonaDetails(data.id, data.nome, data.morada).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {

            let zona = this.zonaList.find(el => (el.id === data.id));
            if (zona) {
              zona.nome = data.nome;
              // zona.saldo_inicial = data.saldo_inicial;
              // zona.saldo_inicial_fr = data.saldo_inicial_fr;

              try {
                zona = this.utils.moradaStrToJSON(zona, data.morada);
              } catch(e) {}

              this.zonaList = [].concat(this.zonaList);
            }

            this.clearForm('zona-update');
            // this.clearForm('saldos-iniciais');
            // this.computeTotals('saldos-iniciais');

            this.updModalRef.approve();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingModalForm = false;
          this.loadingModal = false;
          this.fetchingZona = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingModalForm = false;
          this.loadingModal = false;
          this.fetchingZona = false;
        });
        break;
      case 'fraccao-add':
        break;
      case 'contacto-add':
        this.submittingModalForm = true;

        if (!this.contactoNewForm.valid) return;

        this.loadingModal = true;

        if (this.fetchingContacto) {
          this.submittingModalForm = false;
          this.loadingModal = false;
          return;
        } 
        this.fetchingContacto = true;

        data = this.contactoNewForm.getRawValue();
        this.api.addContactoCondominio(this.condDetails.cod, data.nome, data.tipo_contacto, data.n_contribuinte, data.telefone_1, data.morada, data.email_1, data.obs, this.utils.boolToStr(data.conta_principal)).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {
            res['data'].tipo_contacto_label = this.appConfig.tipoContactSelOpts.find(el => (res['data'].tipo_contacto === el.value));
            if (res['data'].tipo_contacto_label) res['data'].tipo_contacto_label = res['data'].tipo_contacto_label.name;

            this.contactList = [res['data']].concat(this.contactList);

            this.contactListLength++;

            this.clearForm('contacto-add');
            this.genModalRef.approve();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingModalForm = false;
          this.loadingModal = false;
          this.fetchingContacto = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingModalForm = false;
          this.loadingModal = false;
          this.fetchingContacto = false;
        });
        break;
      case 'conta-add':
        this.submittingModalForm = true;

        if (!this.contaNewForm.valid) return;
  
        this.loadingModal = true;

        if (this.fetchingConta) {
          this.submittingModalForm = false;
          this.loadingModal = false;
          return;
        } 
        this.fetchingConta = true;

        data = this.contaNewForm.getRawValue();
        this.api.addContaCondominio(this.condDetails.cod, data.banco, data.nib, data.cod_tipo_conta, data.dt_abertura, data.agencia, data.gestor, data.telefone_1, data.morada, data.obs).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {
            this.bancList = [res['data']].concat(this.bancList);

            this.bancListLength ++;

            this.clearForm('conta-add');
            this.genModalRef.approve();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingModalForm = false;
          this.loadingModal = false;
          this.fetchingConta = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingModalForm = false;
          this.loadingModal = false;
          this.fetchingConta = false;
        });
        break;
      case 'documentos':
        this.submittingForm = true;

        if (!this.docCondForm.valid) return;

        this.loading = true;

        if (this.fetchingDoc) {
          this.submittingForm = false;
          this.loading = false;
          return;
        } 
        this.fetchingDoc = true;

        data = this.docCondForm.getRawValue();
        this.api.updateDocumentosCondominio(this.condDetails.id, data.dia_emis_aviso, data.dia_venc, data.n_contribuinte, data.identificador_local_EDP, data.numero_cliente_SMAS, data.dt_admissao, data.artigo_matricial, data.valor_matricial, data.financas, data.senha_financas, data.cartorio_notarial, data.dt_prop_horiz, data.livro, data.folhas, data.conservatoria_registo_predial, data.desc_predial, data.freguesia_registo_predial, data.dt_licenca_habitab, data.dt_construcao).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     

            // REGISTO ACTIVIDADES API CALL
            let auxData = this.genCondForm.getRawValue();
            let titulo = 'Condomínio Actualizado'
            let descricao = 'Condomínio: ' + auxData.cod + ' - ' + auxData.nome;
            this.api.saveRegistoActividade(auxData.cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
          this.loading = false;
          this.fetchingDoc = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
          this.loading = false;
          this.fetchingDoc = false;
      });
        break;
      case 'penalizacoes':
        this.submittingForm = true;

        this.loading = true;

        if (this.fetchingPenal) {
          this.submittingForm = false;
          this.loading = false;
          return;
        } 
        this.fetchingPenal = true;

        this.api.updatePenalizacoesCondominio(this.condDetails.id, this.penalizacoesList).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {   

            // SEND TRIGGER TO BACKEND BACKGROUND PROCESSES
            this.api.setProcessamentosPenalizacoes().subscribe(res => {}, err => {});
            
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
          this.loading = false;
          this.fetchingPenal = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
          this.loading = false;
          this.fetchingPenal = false;
        });
        break
      case 'observacoes':
        this.submittingForm = true;        

        if (!this.obsCondForm.valid) return;
  
        this.loading = true;

        if (this.fetchingObs) {
          this.submittingForm = false;
          this.loading = false;
          return;
        } 
        this.fetchingObs = true;

        data = this.obsCondForm.getRawValue();
        this.api.updateObsCondominio(this.condDetails.id, data.administador_1, data.administador_2, data.administador_3, data.titular_1, data.titular_2, data.titular_3, data.titular_4, data.titulares_obs, data.obs).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS

            // REGISTO ACTIVIDADES API CALL
            let auxData = this.genCondForm.getRawValue();
            let titulo = 'Condomínio Actualizado'
            let descricao = 'Condomínio: ' + auxData.cod + ' - ' + auxData.nome;
            this.api.saveRegistoActividade(auxData.cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
          this.loading = false;
          this.fetchingObs = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
          this.loading = false;
          this.fetchingObs = false;
        });         
        break;
    }
  }

  getPermZona(codZona, init) {
    let permilagem_zona = Number(init);
    this.fracList.filter(frac => (frac.zona_cod === codZona)).forEach(el => {
      if (el.hasOwnProperty('permilagem') && el.permilagem) permilagem_zona += Number(el.permilagem);
    });
    return permilagem_zona;
  }

  clearForm(targetForm) {
    switch (targetForm) {
      case 'zona-add':
        this.zonaNewForm.reset();
        this.newAddZonaSel = false;
        break;
      case 'zona-update':
          this.zonaUpdForm.reset();
          break;
      case 'saldos-iniciais':
        this.saldosIniciaisUpdForm.reset();
        break;
      case 'fraccao-add':
        this.fracNewForm.reset();
        this.codAlreadyExiste = false;
        break;
      case 'contacto-add':
        this.contactoNewForm.reset();
        break;
      case 'assembleia-add':
        this.assembNewForm.reset();
        break;
      case 'conta-add':
        this.contaNewForm.reset();
        break;
    }
  }

  restoreForm(targetForm) {
    switch (targetForm) {
      case 'geral':
        if (this.isCreate) {
          this.genCondForm.reset();
          this.genCondForm.patchValue({
            estado: '1',
            cod: this.maxCod,
            exercicio: (new Date()).getFullYear(),
          });
        } else {
          this.genCondForm.patchValue({
            estado: this.condDetails.estado,
  
            cod: this.condDetails.cod,
            exercicio: this.condDetails.exercicio,
            nome: this.condDetails.nome,

            classificacao: this.condDetails.classificacao,
      
            morada: (this.condDetails.morada) ? this.condDetails.morada.replace(/NEW_LINE/g, '\n') : null,
            postalCode: this.condDetails.postalCode,
            locality: this.condDetails.locality,
  
            pag_orcamento: this.condDetails.pag_orcamento,
            pag_fr: this.condDetails.pag_fr,
            fr: this.condDetails.fr,
            fr_perc: Number(this.condDetails.fr_perc),
      
            latitude: Number(this.condDetails.latitude),
            longitude: Number(this.condDetails.longitude),

            onedrive_link: this.condDetails.onedrive_link,
            bitrix_link: this.condDetails.bitrix_link,
          });
        }
        break;
      case 'seguro':
        this.segCondForm.patchValue({
          cod_companhia: (this.condDetails.seguro) ? this.condDetails.seguro.cod_companhia : null,
          n_apolice: (this.condDetails.seguro) ? this.condDetails.seguro.n_apolice : null,
          dt_inicio: (this.condDetails.seguro) ? this.utils.getDate(this.condDetails.seguro.dt_inicio) : null,
          dt_fim: (this.condDetails.seguro) ? this.utils.getDate(this.condDetails.seguro.dt_fim) : null,
          pagamento: (this.condDetails.seguro) ? this.condDetails.seguro.pagamento : null,
          janeiro: (this.condDetails.seguro) ? (this.condDetails.seguro.janeiro === '1' || this.condDetails.seguro.janeiro === 'true') : false,
          fevereiro: (this.condDetails.seguro) ? (this.condDetails.seguro.fevereiro === '1' || this.condDetails.seguro.janeiro === 'true') : false,
          marco: (this.condDetails.seguro) ? (this.condDetails.seguro.marco === '1' || this.condDetails.seguro.marco === 'true') : false,
          abril: (this.condDetails.seguro) ? (this.condDetails.seguro.abril === '1' || this.condDetails.seguro.abril === 'true') : false,
          maio: (this.condDetails.seguro) ? (this.condDetails.seguro.maio === '1' || this.condDetails.seguro.maio === 'true') : false,
          junho: (this.condDetails.seguro) ? (this.condDetails.seguro.junho === '1' || this.condDetails.seguro.junho === 'true') : false,
          julho: (this.condDetails.seguro) ? (this.condDetails.seguro.julho === '1' || this.condDetails.seguro.julho === 'true') : false,
          agosto: (this.condDetails.seguro) ? (this.condDetails.seguro.agosto === '1' || this.condDetails.seguro.agosto === 'true') : false,
          setembro: (this.condDetails.seguro) ? (this.condDetails.seguro.setembro === '1' || this.condDetails.seguro.setembro === 'true') : false,
          outubro: (this.condDetails.seguro) ? (this.condDetails.seguro.outubro === '1' || this.condDetails.seguro.outubro === 'true') : false,
          novembro: (this.condDetails.seguro) ? (this.condDetails.seguro.novembro === '1' || this.condDetails.seguro.novembro === 'true') : false,
          dezembro: (this.condDetails.seguro) ? (this.condDetails.seguro.dezembro === '1' || this.condDetails.seguro.dezembro === 'true') : false,
          obs: (this.condDetails.seguro && this.condDetails.seguro.obs) ? this.condDetails.seguro.obs.replace(/NEW_LINE/g, '\n') : null,
        });
        break;
      case 'documentos':
        this.docCondForm.patchValue({
          dia_emis_aviso: this.condDetails.dia_emis_aviso,
          dia_venc: this.condDetails.dia_venc,
          n_contribuinte: this.condDetails.n_contribuinte,
          identificador_local_EDP: this.condDetails.identificador_local_EDP,
          numero_cliente_SMAS: this.condDetails.numero_cliente_SMAS,
          dt_admissao: this.condDetails.dt_admissao,
          artigo_matricial: this.condDetails.artigo_matricial,
          valor_matricial: this.condDetails.valor_matricial,
          financas: this.condDetails.financas,
          senha_financas: this.condDetails.senha_financas,
          cartorio_notarial: this.condDetails.cartorio_notarial,
          dt_prop_horiz: this.condDetails.dt_prop_horiz,
          livro: this.condDetails.livro,
          folhas: this.condDetails.folhas,
          conservatoria_registo_predial: this.condDetails.conservatoria_registo_predial,
          desc_predial: this.condDetails.desc_predial,
          freguesia_registo_predial: this.condDetails.freguesia_registo_predial ,
          n_licenca_habitab: this.condDetails.n_licenca_habitab,
          dt_licenca_habitab: this.condDetails.dt_licenca_habitab,
          dt_construcao: this.condDetails.dt_construcao,
        });
        break;
      case 'observacoes':
        this.obsCondForm.patchValue({
          administador_1: (this.condDetails.administador_1) ? this.condDetails.administador_1 : null,
          administador_2: (this.condDetails.administador_2) ? this.condDetails.administador_2 : null,
          administador_3: (this.condDetails.administador_3) ? this.condDetails.administador_3 : null,
          titular_1: (this.condDetails.titular_1) ? this.condDetails.titular_1 : null,
          titular_2: (this.condDetails.titular_2) ? this.condDetails.titular_2 : null,
          titular_3: (this.condDetails.titular_3) ? this.condDetails.titular_3 : null,
          titular_4: (this.condDetails.titular_4) ? this.condDetails.titular_4 : null,
          titulares_obs: (this.condDetails.titulares_obs) ? this.condDetails.titulares_obs.replace(/NEW_LINE/g, '\n') : null,
          obs: (this.condDetails.obs) ? this.condDetails.obs.replace(/NEW_LINE/g, '\n') : null,
        });
        break;
      case 'zona-update':
        this.zonaUpdForm.patchValue({
          id: this.zonasDataForm.id,
          nome: this.zonasDataForm.nome,

          morada: (this.zonasDataForm.morada) ? this.zonasDataForm.morada.replace(/NEW_LINE/g, '\n') : null,
          postalCode: this.zonasDataForm.postalCode,
          locality: this.zonasDataForm.locality,

          saldo_inicial: this.zonasDataForm.saldo_inicial,
          saldo_inicial_fr: this.zonasDataForm.saldo_inicial_fr
        });
        break;
      case 'saldos-iniciais':
        this.saldosIniciaisUpdForm.patchValue({
          id: this.saldosIniciaisDataForm.id,
          nome: this.saldosIniciaisDataForm.nome,
          saldo_inicial: Number(this.saldosIniciaisDataForm.saldo_inicial).toFixed(2),
          saldo_inicial_fr: Number(this.saldosIniciaisDataForm.saldo_inicial_fr).toFixed(2)
        });
        break;
    }
  }

  clearInputEntry() {
    this.selectedConta = null;
    this.saldoInicialData = null;
    this.distValorGc = null;
    this.distValorFr = null;
  }

  async tableAction(action, targetList, item=null) {
    switch (targetList) {
      case 'act-seguros':
        switch (action) {
          case 'add':
            let codCompanhiaSeg = this.segCondForm.get('cod_companhia').value;

            if (!codCompanhiaSeg) {
              this.toastr.error('Por favor, selecione uma companhia de seguro antes de proceder com a contração/actualização do seguro.', 'Alerta', { timeOut: 4000 });
              return;
            }

            if (codCompanhiaSeg && !this.condDetails.seguro) {
              this.formSubmitted('seguro', false, true);
              return;
            }

            this.actSeguro = {
              obrigat: null,
              premio: null,
              act_dt_inicio: null,
              act_dt_fim: null,
              act_dt_vencimento: null,
              activo: false,
            };
            this.computeActSegTotal();
            
            this.actSegChanged('CAP_OBRIGATORIO');
            this.actSegChanged('PREMIO');
            this.actSegurosModalRef = this.modalService
              .open(this.actSegurosAlertConfig)
              .onApprove(() => { this.loadingModal = false; this.submittingActSeguros = false; this.actSeguro = null; })
              .onDeny(() => { this.loadingModal = false; this.submittingActSeguros = false; this.actSeguro = null; });


            break;
          case 'delete':
            this.toDelete = this.actSegList.filter(el => el.checked);
            if (this.toDelete.length > 0) {
              this.presentAlert_v2();
            } else {
              this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            }
            break;
        }
        break;
      case 'pag-anteriores':
        switch (action) {
          case 'delete':
            this.toDelete = this.pagAnterioresList.filter(el => el.checked);
            if (this.toDelete.length > 0) {
              this.presentAlert_v2();
            } else {
              this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            }
            break;
        }
        break;
      case 'saldos-iniciais-distribuicao':
        switch (action) {
          case 'add':
            this.presentSaldoInicialAlert();
            break;
          case 'edit':
            this.presentSaldoInicialAlert(item);
            break;
          case 'delete':
            this.toDelete = this.saldoInicialDistrList.filter(el => el.checked);
            if (this.toDelete.length > 0) {
              this.presentAlert_v2();
            } else {
              this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            }
            break;
        }
        break;
      case 'zonas':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            // REMOVE USED ZONAS FROM OPTIONS ARRAY
            this.zonaDistinctSelOpts = this.zonaDistinctSelOptsOrig.filter(el => {
              return (this.zonaList.findIndex(it => (it.nome === el.name)) === -1);
            });

            if (this.zonasTotal.permilagem < 1000) {
              let auxZona = this.zonaNewForm.getRawValue();
              let auxCond = this.genCondForm.getRawValue();

              if (auxZona.morada === null) this.zonaNewForm.patchValue({ morada: auxCond.morada });
              if (auxZona.locality === null) this.zonaNewForm.patchValue({ locality: auxCond.locality });
              if (auxZona.postalCode === null) this.zonaNewForm.patchValue({ postalCode: auxCond.postalCode });

              this.openGenModal();
            } else {
              this.toastr.error('Não é possível adicionar mais zonas, total da permilagem do condomínio igual 1000 ‰.', 'Ups...!', { timeOut: 4000 });
            }

            break;
          case 'delete':
            let toDelete = this.zonaList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'zonas');
              });
            } else {
              this.toastr.error('Nenhuma entrada selecionada para remover.', 'Ups...!');
            }
            break;
        }
        break;
      case 'saldos-iniciais':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            this.openGenModal();
            break;
          case 'add-dist':
            this.saldoInicialDistrList = [{
              nid_conta: this.selectedConta,
              dt_mov: this.saldoInicialData,
              conta: this.contaOpts.find(el => (el.value === this.selectedConta)).name,
              dist_valor_gc: (this.distValorGc) ? this.distValorGc : 0,
              dist_valor_fr: (this.distValorFr) ? this.distValorFr : 0,
              checked: false,
            }].concat(this.saldoInicialDistrList);

            // COMPUTE TOTAL
            this.computeTotals('saldos-iniciais-distribuicao');

            // REMOVE USED ACCOUNT FROM OPTIONS
            this.contaOpts = this.contaOpts.filter(el => (el.value !== this.selectedConta));

            this.clearInputEntry();
            break;
          case 'delete-dist':

            break;
          case 'delete':
            break;
        }
        break;
      case 'frac':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            if (this.zonaList.length === 0) {
              this.toastr.error('Necessita de adicionar zonas ao condomínio antes de adicionar fracções.', 'Ups...!');
              return;
            }

            let totalPermilagem = 0;
            this.fracList.forEach(frac => {
              totalPermilagem += Number(frac.permilagem);
            });
            if (totalPermilagem >= 1000) {
              this.toastr.error('Não é possível adicionar uma nova fracção. Total da permilagem do condomínio igual 1000 ‰..', 'Ups...!');
              return;
            }

            this.appState.setMoradaCondominio({
              morada: this.genCondForm.get('morada').value,
              postalCode: this.genCondForm.get('postalCode').value,
              locality: this.genCondForm.get('locality').value,
            });

            this.router.navigate(['fraccoes/geral', this.condDetails.cod,'criar']);
            break;
          case 'delete':
            let toDelete = this.fracList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'fraccoes');
              });
            } else {
              this.toastr.error('Nenhuma entrada selecionada para remover.', 'Ups...!');
            }
            break;
        }
        break;
      case 'banc':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            this.router.navigate(['contas', this.condDetails.cod,'criar']);

            // BREADCRUMB SIGNAL
            this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVA CONTA' });
          break;
          case 'delete':
            let toDelete = this.bancList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'contas');
              });
            } else {
              this.toastr.error('Nenhuma entrada selecionada para remover.', 'Ups...!');
            }
            break;
        }
        break;
      case 'contact':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            this.router.navigate(['contactos', this.condDetails.cod,'criar']);

            // BREADCRUMB SIGNAL
            this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVO CONTACTO' });
  
            break;
          case 'delete':
            let toDelete = this.contactList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'contactos');
              });
            } else {
              this.toastr.error('Nenhuma entrada selecionada para remover.', 'Ups...!');
            }
            break;
        }
        break;
    }
  }

  presentAlert_v2() {
    this.alertModalRef_v2 = this.modalService
      .open(this.deleteAlertConfig_v2)
      .onApprove(() => {
        this.loadingModal = false;
        this.toDelete = [];

        this.selectedConta = '-1';
        this.contaSelected();
      })
      .onDeny(() => { this.loadingModal = false; this.toDelete = []; });
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => { this.loadingModal = false; resolve(true); })
        .onDeny(() => { this.loadingModal = false; resolve(false); });
    });
  }

  openGenModal() {
    this.genModalRef = this.modalService.open(this.genModalConfig)
      .onApprove(() => {
        this.submittingModalForm = false;
        this.loadingModal = false;
        this.zonaDistinctSelOpts = JSON.parse(JSON.stringify(this.zonaDistinctSelOptsOrig));
        this.codAlreadyExiste = false;
      })
      .onDeny(() => { 
        this.submittingModalForm = false;
        this.loadingModal = false;
        this.zonaDistinctSelOpts = JSON.parse(JSON.stringify(this.zonaDistinctSelOptsOrig));
        this.codAlreadyExiste = false;
      });
  }

  delEntity(toDelete, target) {
    this.loadingModal = true;

    switch (target) {
      case 'saldos-iniciais':
        let movimentosToDelete = [];
        this.toDelete.forEach(saldoInicial => {
          let movimento = {
            id: (saldoInicial.hasOwnProperty('id')) ? saldoInicial.id : null,
            nid_conta: saldoInicial.nid_conta,
            descricao: 'Saldos Iniciais',
            tipo_movimento: 'SI',
          };

          movimentosToDelete.push(movimento);
        });

        this.api.saveMovimentosList('CONDOMINIOS', [], movimentosToDelete).subscribe(
          res => {
            if (res.hasOwnProperty('success') && res['success']) {
              let req = [];
              res.deleted.forEach(el => {
                if (el.saldoConta !== null) {
                  let conta = this.bancList.find(conta => (conta.cod === el.nid_conta));
                  if (conta) conta.saldo = Number(el.saldoConta);
                }

                this.contaOpts.push(this.contaOptsOrig.find(conta => (conta.value === el.nid_conta)));
                this.contaOpts = JSON.parse(JSON.stringify(this.contaOpts));

                req.push(this.api.getFirstMovimento(el.nid_conta));
              });

              this.saldoInicialDistrList = this.saldoInicialDistrList.filter(el => !el.checked);

              if (req.length > 0) {
                forkJoin(req).subscribe(res => {
                  let aux = null;
                  res.forEach(el => {
                    if (el.hasOwnProperty('success') && el.success && !!el.data) {
                      aux = this.contasFirstMovimentos.find(it => it.nid_conta === el.data.nid_conta);
                      if (aux) {
                        aux = el.data;
                      } else {
                        this.contasFirstMovimentos.push(el.data);
                      }
                    }
                  });
                  this.alertModalRef_v2.approve();
                }, err => { this.alertModalRef_v2.approve(); });
              } else {
                this.alertModalRef_v2.approve();
              }

              this.computeTotals('saldos-iniciais-distribuicao');
            } else {
              this.loadingModal = false;
              this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            }
          },
          err => {
            this.loadingModal = false;
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          }
        );
        break;
      case 'zonas':
        this.api.delZonas(toDelete, this.condDetails.cod).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.zonaList = this.zonaList.filter(el => !el.checked);
            this.condDetails.n_zonas = this.condDetails.n_zonas - toDelete.length;
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'fraccoes':
        this.api.delFraccoes(toDelete, this.condDetails.cod).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            toDelete.forEach(frac => {
              let auxZona = this.zonaList.find(zona => (zona.cod === frac.zona_cod));
              if (auxZona) auxZona.n_fraccoes = auxZona.n_fraccoes - 1;
            });

            this.fracList = this.fracList.filter(el => !el.checked);

            this.getCondZonaDetails();
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'pagamentos-anteriores':
        this.api.delPagAnteriores(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.pagAnterioresList.forEach(el => {
              let aux = this.toDelete.find(it => (it.cod === el.cod));
              if (aux) {
                el.checked = false;
                el.credito = null;
                el.data = null;
                el.id = null;
                el.valor = null;
              }
            });
            this.toDelete = [];
            this.pagAnterioresList = JSON.parse(JSON.stringify(this.pagAnterioresList));
            this.alertModalRef_v2.approve();
          } else {
            this.utils.apiErrorMsg(res);
            this.loadingModal = false;
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loadingModal = false;
        });
        break;
      case 'contas':
        this.api.delContas(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.bancListOrig = this.bancListOrig.filter(el => toDelete.findIndex(del => del.id == el.id) === -1);
            this.bancList = this.bancList.filter(el => toDelete.findIndex(del => del.id == el.id) === -1);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'contactos':
        this.api.delContactos(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.contactList = this.contactList.filter(el => !el.checked);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'seguro':
        this.api.delActSeguros(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.alertModalRef_v2.approve();

            this.actSegList = this.actSegList.filter(el => !el.checked);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
    }
  }

  tableSort(key, targetList) {
    switch (targetList) {
      case 'zonas':
        this.utils.tableSort(this.zonaListCol, this.zonaList, key);
        break;
      case 'fraccoes':
        this.utils.tableSort(this.fracListCol, this.fracList, key);
        break;
      case 'contactos':
        this.utils.tableSort(this.contactListCol, this.contactList, key);
        break;
      case 'contas':
        this.utils.tableSort(this.bancListCol, this.bancList, key);
        break;
    }
  }

  onChanged(target) {
    switch (target) {
      case 'cod': this.codAlreadyExiste = false; break;
    }
  }

  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'saldos-iniciais-distribuicao':
        (ev.target.checked) ? this.saldoInicialDistrList.map(el => el.checked = true ) : this.saldoInicialDistrList.map(el => el.checked = false );
        break;
      case 'contactos':
        (ev.target.checked) ? this.contactList.map(el => el.checked = true ) : this.contactList.map(el => el.checked = false );
        break;
      case 'pag-anteriores':
        (ev.target.checked) ? this.pagAnterioresList.map(el => el.checked = true ) : this.pagAnterioresList.map(el => el.checked = false );
        break;
    }
  }

  // PENALIZACOES SECTIO ----------------------- START
  penalizacoesList = [];
  penalizaoesListCol = [
    { key: 'dias_atraso', name: 'Dias de Atraso', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-left' },
    { key: 'penalizacao', name: 'Penalização [%]', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
  ];

  diasAtraso = null;
  penalizacao = null;

  diasAtrasoEdit = null;
  penalizacaoEdit = null;
  penalizacoesReg(action, toRemove=null) {
    if (action === 'add') {
      this.submittingForm = true;
      if (!this.diasAtraso || !this.penalizacao) {
        setTimeout(() => { this.submittingForm = false; }, 4000);
        return;
      }
      this.penalizacoesList = [{ dias_atraso: this.diasAtraso, penalizacao: this.penalizacao }].concat(this.penalizacoesList);
      this.submittingForm = false;
      this.diasAtraso = null;
      this.penalizacao = null;
    }

    if (action === 'remove') {
      this.penalizacoesList = this.penalizacoesList.filter(el => (el !== toRemove));
    }
  }

  submitEditPenalizacao() {
    this.submittingModalForm = true;
    if (!this.diasAtrasoEdit || !this.penalizacaoEdit) {
      setTimeout(() => { this.submittingModalForm = false; }, 4000);
      return;
    }

    this.penalizacaoModalRef.approve();
  }

  editMode(penalizacao) {
    this.diasAtrasoEdit = penalizacao.dias_atraso;
    this.penalizacaoEdit = penalizacao.penalizacao;

    this.penalizacaoModalRef = this.modalService
      .open(this.penalizacaoAlertConfig)
      .onApprove(() => {
        penalizacao.dias_atraso = this.diasAtrasoEdit;
        penalizacao.penalizacao = this.penalizacaoEdit;

        this.loadingModal = false;
        this.submittingModalForm = false;
        this.diasAtrasoEdit =null;
        this.penalizacaoEdit = null;
      })
      .onDeny(() => {
        this.loadingModal = false;
        this.submittingModalForm = false;
        this.diasAtrasoEdit =null;
        this.penalizacaoEdit = null;
      });
  }
  // PENALIZACOES SECTIO ----------------------- END

  // PAGAMENTOS ANTERIORES SECTION ------------- START
  savingPagAnteriores = false;
  savePagAnterior() {
    if (this.savingPagAnteriores) return;
    
    this.submittingFormPagAnterior = true;
    if (!this.credito || Number(this.credito) < 0) {
      setTimeout(() => { this.submittingFormPagAnterior = false; }, 4000);
    }

    this.savingPagAnteriores = true;
    this.loadingModal = true;

    let dataAux = (this.pagAnteriorEntry.cod_condominio.data) ? this.pagAnteriorEntry.cod_condominio.data : this.utils.getFormatedDate(new Date());
    // this.api.savePagAnterior(this.pagAnteriorEntry.id, this.pagAnteriorEntry.cod_condominio, this.pagAnteriorEntry.cod, this.pagAnteriorEntry.cod_pagador, dataAux, this.pagAnteriorEntry.credito, this.credito).subscribe(res => {
    this.api.savePagAnterior(this.pagAnteriorEntry.id, this.pagAnteriorEntry.cod_condominio, this.pagAnteriorEntry.cod, this.pagAnteriorEntry.cod_pagador, dataAux, dataAux, 'Pag. Anterior', this.credito, this.credito).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        let aux = this.pagAnterioresList.find(el => (el.cod === res.data.cod_fraccao));
        if (aux) {
          aux['checked'] = false;
          aux['data'] = res.data.data;
          aux['id'] = res.data.id;
          aux['valor'] = (res.data.valor) ? Number(res.data.valor) : null;
          aux['credito'] = (res.data.credito) ? Number(res.data.credito) : null;
        }
        this.pagAnterioresList = JSON.parse(JSON.stringify(this.pagAnterioresList));

        aux = this.pagAnterioresListOrig.find(el => (el.cod === res.data.cod_fraccao));
        if (aux) {
          aux['checked'] = false;
          aux['data'] = res.data.data;
          aux['id'] = res.data.id;
          aux['valor'] = (res.data.valor) ? Number(res.data.valor) : null;
          aux['credito'] = (res.data.credito) ? Number(res.data.credito) : null;
        }

        this.pagAnteriorModalRef.approve();
        this.pagAnteriorEntry = null;
        this.savingPagAnteriores = false;
      }
    }, err => {
      this.toastr.error('Não foi possível efectuar a operação. Por favor, verifique a sua ligação à Internet e/ou tente novamente mais tarde.', 'Ups...!');
      this.savingPagAnteriores = false;
      this.loadingModal = false;
    });
  }
  // PAGAMENTOS ANTERIORES SECTION ------------- END

  // SEGUROS METHODS
  codCompanhiaChanged() {
    let data = this.segCondForm.getRawValue();

    if (data.pagamento === null) data.pagamento = 'P';

    if (!data.janeiro && !data.fevereiro && !data.marco && !data.abril && !data.maio && !data.junho && !data.julho && !data.agosto && !data.setembro && !data.outubro && !data.novembro && !data.dezembro) {
      data.janeiro = true;
      data.fevereiro = true;
      data.marco = true;
      data.abril = true;
      data.maio = true;
      data.junho = true;
      data.julho = true;
      data.agosto = true;
      data.setembro = true;
      data.outubro = true;
      data.novembro = true;
      data.dezembro = true;
    } 

    this.segCondForm.patchValue({
      pagamento: data.pagamento,
      janeiro: data.janeiro,
      fevereiro: data.fevereiro,
      marco: data.marco,
      abril: data.abril,
      maio: data.maio,
      junho: data.junho,
      julho: data.julho,
      agosto: data.agosto,
      setembro: data.setembro,
      outubro: data.outubro,
      novembro: data.novembro,
      dezembro: data.dezembro,
    });
  }

  computeContasSaldoTotal() {
    return this.bancList.map(el => Number(el.saldo)).reduce((a, b) => a + b, 0);
  }

  // SALDOS INICIAIS SECTION - START --------------------------------------------------------------
  saldoInicialEditMode = false;
  presentSaldoInicialAlert(item=null) {
    return new Promise(resolve => {
      if (item) {
        this.saldoInicialEditMode = true;

        this.selectedConta = { value: item.nid_conta, name: item.conta };
        this.saldoInicialData = item.dt_mov;
        this.distValorGc = item.dist_valor_gc;
      }

      this.saldoInicialContaModalRef = this.modalService
        .open(this.saldoInicialContaAlertConfig)
        .onApprove(() => { this.loadingModal = false; this.saldoInicialSubmitted = false; this.saldoInicialEditMode = false; this.clearInputEntry(); resolve(true); })
        .onDeny(() => { this.loadingModal = false; this.saldoInicialSubmitted = false; this.saldoInicialEditMode = false; this.clearInputEntry(); resolve(false); });
    });
  }

  saldoInicialSubmitted = false;
  saveSaldoInicial() {
    this.saldoInicialSubmitted = true;
    if (!this.selectedConta || !this.saldoInicialData || !this.distValorGc) return;

    this.loadingModal = true;

    let nidConta = (this.selectedConta.hasOwnProperty('value')) ? this.selectedConta.value : this.selectedConta;
    let saldoInicial = this.saldoInicialDistrList.find(el => el.nid_conta === nidConta);

    if (!saldoInicial) {
      saldoInicial = {
        nid_conta: this.selectedConta,
        dt_mov: this.saldoInicialData,
        conta: this.contaOpts.find(el => (el.value === nidConta)).name,
        dist_valor_gc: (this.distValorGc) ? this.distValorGc : 0,
        dist_valor_fr: (this.distValorFr) ? this.distValorFr : 0,
        checked: false,
      }
    } else {
      saldoInicial = JSON.parse(JSON.stringify(saldoInicial));
      
      saldoInicial['dt_mov'] = this.saldoInicialData;
      saldoInicial['dist_valor_gc'] = (this.distValorGc) ? this.distValorGc : 0;
      saldoInicial['dist_valor_fr'] = (this.distValorFr) ? this.distValorFr : 0;
    }

    let movimento = {
      id: (saldoInicial.hasOwnProperty('id')) ? saldoInicial.id : null,
      dt_mov: this.utils.getFormatedDate(saldoInicial.dt_mov),
      valor: Number(saldoInicial.dist_valor_gc) + Number(saldoInicial.dist_valor_fr),
      dt_valor: null,
      nid_rubrica: '8', // RUBRICA SALDO INICIAL
      nid_conta: saldoInicial.nid_conta,
      descricao: 'Saldos Iniciais',
      tipo_movimento: 'SI',
      obj: JSON.stringify({
        conta_origem: null,
        conta_destino: (this.contaOptsOrig.find(el => (el.value === saldoInicial.nid_conta))) ? this.contaOptsOrig.find(el => (el.value === saldoInicial.nid_conta)).name: null,

        dist_valor_gc: saldoInicial.dist_valor_gc,
        dist_valor_fr: saldoInicial.dist_valor_fr,
      }),
    };

    this.api.saveMovimentosList('CONDOMINIOS', [ movimento ]).subscribe(
      res => {
        if (res.hasOwnProperty('success') && res['success']) {
          // SET SALDO CONTA
          res.data.forEach(el => {
            if (!!el.saldoConta) {
              let conta = this.bancList.find(conta => (conta.cod === el.nid_conta));
              if (conta) conta.saldo = Number(el.saldoConta);
            }

            let saldoInicial = this.saldoInicialDistrList.find(it => it.nid_conta === nidConta);
            if (!saldoInicial) {
              this.saldoInicialDistrList.push({
                id: el.id,
                nid_conta: nidConta,
                dt_mov: this.saldoInicialData,
                conta: this.contaOpts.find(it => (it.value === nidConta)).name,
                dist_valor_gc: (this.distValorGc) ? this.distValorGc : 0,
                dist_valor_fr: (this.distValorFr) ? this.distValorFr : 0,
                checked: false,
              });
            } else {
              saldoInicial['dt_mov'] = this.saldoInicialData;
              saldoInicial['dist_valor_gc'] = (this.distValorGc) ? this.distValorGc : 0;
              saldoInicial['dist_valor_fr'] = (this.distValorFr) ? this.distValorFr : 0;
            }
          });

          this.saldoInicialDistrList.forEach(saldo => {
            let aux = res.data.find(el => (el.nid_conta === saldo.nid_conta));
            if (aux) {
              saldo['id'] = aux.id;
              saldo['dt_mov'] = this.utils.getDate(aux.dt_mov);
            }
          });

          // COMPUTE TOTAL
          this.computeTotals('saldos-iniciais-distribuicao');

          // REMOVE USED ACCOUNT FROM OPTIONS
          this.contaOpts = this.contaOpts.filter(el => (el.value !== this.selectedConta));

          this.saldoInicialContaModalRef.approve();
        } else {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loadingModal = false
        }
      }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModal = false
    });
  }

  contaSelected(clear=false) {
    if (this.selectedConta === '-1' || clear) {
      setTimeout(() => {
        this.selectedConta = null;
        this.saldoInicialData = null;
        this.distValorFr = null;
        this.distValorGc = null;
      }, 1);
    } else {
      if (!this.saldoInicialEditMode) {
        let aux = this.contasFirstMovimentos.find(el => el.nid_conta === this.selectedConta);
        if (aux) {
          let auxDate = this.utils.getDate(aux.dt_mov);
          auxDate = new Date(auxDate.getTime() - (24*60*60*1000));
          this.saldoInicialData = auxDate;
        }
      }
    }
  }

  contasFirstMovimentos = [];
  saldosIniciaisFetched = false;
  tipoReparticaoZona = null; //'PROPORCIONAL';
  saldoInicialDistOpts = [
    { name: 'Gestão Corrente', value: 'ORCAMENTO' },
    { name: 'FCR', value: 'FCR' },
  ];
  saldoInicialDistSel = this.saldoInicialDistOpts[0].value;

  saldoInicialDistSelected() {
    this.tipoRepZonaChanged();  
  }

  tipoRepZonaChanged() {
    if (this.tipoReparticaoZona === 'MANUAL') {
      let auxZona = null;
      this.zonaListOrig.forEach(el => {
        auxZona = this.zonaList.find(it => it.nome === el.nome);
        if (auxZona) {
          auxZona['saldo_inicial'] = el.saldo_inicial;
          auxZona['saldo_inicial_fr'] = el.saldo_inicial_fr;
        }
      });
    } 

    this.computeTotalSaldoInicialDistZonas();
  }

  computeTotalSaldoInicialDistZonas() {
    this.saldosIniciais.orc = 0;
    this.saldosIniciais.fr = 0;
    this.saldosIniciais.total = 0;

    switch (this.tipoReparticaoZona) {
      case 'MANUAL':
        this.zonaList.forEach(el => {
          el.saldo_inicial = (!!el.saldo_inicial) ? Math.round(Number(el.saldo_inicial) * 100) / 100 : 0;
          el.saldo_inicial_fr = (!!el.saldo_inicial_fr) ? Math.round(Number(el.saldo_inicial_fr) * 100) / 100 : 0;
          el.total = el.saldo_inicial + el.saldo_inicial_fr;

          this.saldosIniciais.orc += el.saldo_inicial;
          this.saldosIniciais.fr += el.saldo_inicial_fr;
          this.saldosIniciais.total += el.total;
        });
        break;
      case 'PROPORCIONAL':
        this.saldosIniciais.total = Number(this.valorTotalGc);

        let totalPermilagem = this.zonaList.map(el => Number(el.permilagem)).reduce((a, b) => a + b, 0);

        this.zonaList.forEach(el => {
          if (this.saldoInicialDistSel === 'ORCAMENTO') {
            el.saldo_inicial = this.saldosIniciais.total * (Number(el.permilagem) / totalPermilagem);
            el.saldo_inicial = Math.round(Number(el.saldo_inicial) * 100) / 100;
            el.saldo_inicial_fr = 0;
          } else if (this.saldoInicialDistSel === 'FCR') {
            el.saldo_inicial = 0;
            el.saldo_inicial_fr = this.saldosIniciais.total * (Number(el.permilagem) / totalPermilagem);
            el.saldo_inicial_fr = Math.round(Number(el.saldo_inicial_fr) * 100) / 100;
          } else {
            el.saldo_inicial = 0;
            el.saldo_inicial_fr = 0;
          }
          el.total = el.saldo_inicial + el.saldo_inicial_fr;

          this.saldosIniciais.orc += el.saldo_inicial;
          this.saldosIniciais.fr += el.saldo_inicial_fr;
        });
        break;
      case 'EQUITATIVO':
        this.saldosIniciais.total = Number(this.valorTotalGc);

        let valor = (this.zonaList.length > 0) ? this.saldosIniciais.total / this.zonaList.length : 0;
        this.zonaList.forEach(el => {
          if (this.saldoInicialDistSel === 'ORCAMENTO') {
            el.saldo_inicial = valor;
            el.saldo_inicial = Math.round(Number(el.saldo_inicial) * 100) / 100;
            el.saldo_inicial_fr = 0;
          } else if (this.saldoInicialDistSel === 'FCR') {
            el.saldo_inicial = 0;
            el.saldo_inicial_fr = valor;
            el.saldo_inicial_fr = Math.round(Number(el.saldo_inicial_fr) * 100) / 100;
          } else {
            el.saldo_inicial = 0;
            el.saldo_inicial_fr = 0;
          }
          el.total = el.saldo_inicial + el.saldo_inicial_fr;

          this.saldosIniciais.orc += el.saldo_inicial;
          this.saldosIniciais.fr += el.saldo_inicial_fr;
        });
        break;
    }

    if (this.tipoReparticaoZona === 'PROPORCIONAL' || this.tipoReparticaoZona === 'EQUITATIVO') {
      if (this.saldoInicialDistSel === 'ORCAMENTO') {
        if (this.saldosIniciais.total !== this.saldosIniciais.orc) {
          let diff = this.saldosIniciais.total - this.saldosIniciais.orc;
          let adjustZone = this.zonaList.find(zone => Number(zone.permilagem) === Math.max(...this.zonaList.map(el => Number(el.permilagem))));
          adjustZone.saldo_inicial += diff;
          adjustZone.total = adjustZone.saldo_inicial + adjustZone.saldo_inicial_fr;
          this.saldosIniciais.orc = this.saldosIniciais.total;
        }
      }

      if (this.saldoInicialDistSel === 'FCR') {
        if (this.saldosIniciais.total !== this.saldosIniciais.fr) {
          let diff = this.saldosIniciais.total - this.saldosIniciais.fr;
          let adjustZone = this.zonaList.find(zone => Number(zone.permilagem) === Math.max(...this.zonaList.map(el => Number(el.permilagem))));
          adjustZone.saldo_inicial_fr += diff;
          adjustZone.total = adjustZone.saldo_inicial + adjustZone.saldo_inicial_fr;
          this.saldosIniciais.fr = this.saldosIniciais.total;
        }
      }
    }
  }

  presentSaldosIniciaisDiffAlert() {
    return new Promise(resolve => {
      this.saldoInicialDiffModalRef = this.modalService
      .open(this.saldoInicialDiffAlertConfig)
      .onApprove(() => {
        resolve(true);
      })
      .onDeny(() => {
        resolve(false);
      });
    });
  }

  async saveSaldosIniciaisZonas() {
    if ((this.saldosIniciais.total - this.valorTotalGc >= 0.01 || this.valorTotalGc - this.saldosIniciais.total >= 0.01)) {
      let res = await this.presentSaldosIniciaisDiffAlert();
      if (!res) return;
    }

    let saldosIniciaisConfig = {
      tipoReparticaoZona: this.tipoReparticaoZona,
      saldoInicialDistSel: this.saldoInicialDistSel,
    }

    this.loading = true;
    this.api.updateSaldosIniciaisZona(this.condDetails.cod, this.zonaList, saldosIniciaisConfig).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        let auxZona = null;
        this.zonaList.forEach(el => {
          auxZona = this.zonaListOrig.find(it => it.nome === el.nome);
          if (auxZona) {
            auxZona['saldo_inicial'] = el.saldo_inicial;
            auxZona['saldo_inicial_fr'] = el.saldo_inicial_fr;
          }
        });

        this.loading = false;
      } else {
        this.loading = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
    }, err => {
      this.loading = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }
  // SALDOS INICIAIS SECTION - END ----------------------------------------------------------------
  
  exportEntidadesContacts() {
    this.condominioContactsPdf.exportPdf();
  }
}
