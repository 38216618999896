import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';


@Component({
  selector: 'app-funcionarios',
  templateUrl: './funcionarios.component.html',
  styleUrls: ['./funcionarios.component.scss']
})
export class FuncionariosComponent implements OnInit {

  transitionController = new TransitionController();
  loadingModalForm = false;
  submittingModalForm = false;
  searchable = true;
  passwordNotEqual = false;

  // NEW UTILIZADOR FORM
  newForm = new FormGroup({
    first_name: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    last_name: new FormControl(null),
    title: new FormControl(null),
    phone: new FormControl(null),
    email: new FormControl(null),
  });

  editForm = new FormGroup({
    id: new FormControl(null),
    first_name: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    last_name: new FormControl(null),
    title: new FormControl(null),
    phone: new FormControl(null),
    email: new FormControl(null),
  });

  newFormSubmitted = false;
  @ViewChild('addEntRef', { static: false }) addEntRef;
  addModalRef = null;
  addModalConfig: any = null;

  @ViewChild('editEntRef', { static: false }) editEntRef;
  editModalRef = null;
  editModalConfig: any = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  // ORCAMENTOS TABLE VARIABLES
  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'title', name: 'Função', type: 'text', sort: null, searchable: false, centered: false },
    { key: 'phone', name: 'Tel.', type: 'text', sort: null, searchable: false, centered: false },
    { key: 'email', name: 'Email', type: 'text', sort: null, searchable: false, centered: false },
  ];
  list: Array<any> = [];
  listLength: number = 0;
  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;
  page: number = 0;
  selectedPage: number = null;
  itemPerPage: number = 20;
  keyword: string = null;
  sortParam: string = null;
  sortDirection: string = null;
  searching: boolean = false;

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public router: Router,
              public api: ApiService,
              public appConfig: AppConfigService,
              public message: MessageService) { }

  ngOnInit() {
    this.animate();

    // GET INITAL DATA
    this.getList();
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngAfterViewInit() {
    // SEARCH INPUT
    fromEvent(this.tableSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value']);
    });

    this.addModalConfig = new TemplateModalConfig<IContext, string, string>(this.addEntRef);
    this.addModalConfig.closeResult = "closed";
    this.addModalConfig.size = 'tiny';
    this.addModalConfig.transition = 'fade up';
    this.addModalConfig.transitionDuration = 400;

    this.editModalConfig = new TemplateModalConfig<IContext, string, string>(this.editEntRef);
    this.editModalConfig.closeResult = "closed";
    this.editModalConfig.size = 'tiny';
    this.editModalConfig.transition = 'fade up';
    this.editModalConfig.transitionDuration = 400;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => resolve(true))
      .onDeny(() => resolve(false));
    });
  }

  add() {
    this.addModalRef = this.modalService.open(this.addModalConfig);
  }

  del(toDelete) {
    this.api.delFuncionarios(toDelete).subscribe(res => {
      if (res.success) {
        this.list = this.list.filter(el => !el.checked);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  newSubmit() {
    this.newFormSubmitted = true;

    if (!this.newForm.valid) return;

    let data = this.newForm.getRawValue();

    this.loadingModalForm = true;  
    this.api.addFuncionario(data.first_name, data.last_name, data.title, data.phone, data.email).subscribe(res => {
      if (res.success) {  
        if (this.list.length >= this.itemPerPage) this.list.pop();

        res.data['nome'] = res.data['first_name'];
        if (res.data['last_name']) res.data['nome'] += ' ' + res.data['last_name'];

        res.data['checked'] = false;

        this.list = [res.data].concat(this.list);

        this.listLength ++;

        this.clearForm();
        this.addModalRef.approve();
        this.loadingModalForm = false;  
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loadingModalForm = false;  
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModalForm = false;  
    });
  }

  clearForm() {
    this.newForm.reset();
    this.editForm.reset();
    this.newFormSubmitted = false;
    this.passwordNotEqual = false;
  }

  getList() {
    this.api.getFuncionarios(this.page, this.itemPerPage, this.keyword, this.sortParam, this.sortDirection).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.list = res.data.map(el => {
          el['nome'] = el['first_name'];
          if (el['last_name']) el['nome'] += ' ' + el['last_name'];

          el['checked'] = false;

          return el;
        });
        this.listLength = res.total;
        this.searching = false;
      }
    }, err => {
      this.searching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  pageChange(ev) {
    this.page = ev;
    this.getList();
  }

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.list.map(el => el.checked = true ) : this.list.map(el => el.checked = false );
  }

  async tableAction(action) {
      switch (action) {
        case 'csv': break;
        case 'pdf': break;
        case 'print': break;
        case 'add': this.add(); break;
        case 'delete':
          let toDelete = this.list.filter(el => el.checked);
          if (toDelete.length > 0) {
            this.presentAlert().then(res => {
              if (res) this.del(toDelete);
            });
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
  }

  tableSearch(value) {
    this.keyword = value.toLowerCase().trim();
    this.searching = true;

    this.getList();
  }

  funcionarioId = null;
  goToDetails(item) {
    this.funcionarioId = item.id;

    this.editForm.patchValue({
      id: item.id,
      first_name: item.first_name,
      last_name: item.last_name,
      title: item.title,
      phone: item.phone,
      email: item.email,
    });

    this.editModalRef = this.modalService
      .open(this.editModalConfig)
      .onApprove(() => { this.clearForm(); this.loadingModalForm = false; this.funcionarioId = null; })
      .onDeny(() => { this.clearForm(); this.loadingModalForm = false; this.funcionarioId = null; });
  }

  editSubmit() {
    this.newFormSubmitted = true;

    if (!this.editForm.valid) return;

    let data = this.editForm.getRawValue();

    this.loadingModalForm = true;  
    this.api.updateFuncionario(data.id, data.first_name, data.last_name, data.title, data.phone, data.email).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        let aux = this.list.find(el => (el.id === this.funcionarioId));

        aux['nome'] = data['first_name'];
        if (data['last_name']) aux['nome'] += ' ' + data['last_name'];

        aux['first_name'] = data['first_name'];
        aux['last_name'] = data['last_name'];
        aux['title'] = data['title'];
        aux['phone'] = data['phone'];
        aux['email'] = data['email'];
        aux['checked'] = false;

        this.editModalRef.approve();
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loadingModalForm = false;  
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModalForm = false;  
    });
  }

}
