import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { CondominosService } from '../business-logic-services/condominos.service';
import { BusinessLogicService } from '../business-logic.service';
import { ContaCorrentePdfComponent, ContaCorrentePDFInput } from '../conta-corrente-pdf/conta-corrente-pdf.component';
import { UtilitiesService } from '../utilities.service';

export interface acordoInfoInput {
    divida: string,
    penalizacao: null,
    periodicidade: string,
    n_prestacoes: number,
    ajuste: number,
    valorPrestacao: number,
    data_divida: Date,
    primeiraPrestacao: Date,
    ultimaPrestacao: Date,
    ajustePrestacao: 'U'|'P',
    prestacoesAdicionais: Array<{
      data: Date,
      valor: number
    }>,
    cod_entidade:string,
    cod_condominio:string,
    id_fracao:string,
    cod_fracao:string
}

interface data {
  entidade: {
    nome:string,
    nif:string,
    morada:string,
  }
  fracao: {
    dt_inicio: Date,
    dt_inicio_ext: string,
    dt_fim: Date,
    dt_fim_ext: string,
    cod_fracao_visible: string
  }
  condominio: {
    banco: string,
    nib: string,
    nome_condominio: string,
    morada_condominio: string,
  }
  divida: {
    valor:number
    valor_ext:string
  }
  acordo: {
    penalizacao:number
    periodicidade:string
    n_prestacoes:number
    ajuste: number,
    valorPrestacao: number,
    primeiraPrestacao: Date,
    data_divida:Date,
    data_divida_ext:string,
    ultimaPrestacao:Date
    ajustePrestacao:string
    prestacoesAdicionais:Array<{
      data: Date,
      valor: number,
    }>
  }
}

@Component({
  selector: 'app-confissao-divida-pdf',
  templateUrl: './confissao-divida-pdf.component.html',
  styleUrls: ['./confissao-divida-pdf.component.scss']
})
export class ConfissaoDividaPdfComponent implements OnInit {

  filename = 'Confissao_Divida';
  now = null;
  now_ext = null;

  constructor(
      public appConfig: AppConfigService,
      public cdRef: ChangeDetectorRef,
      public utils: UtilitiesService,
      public toastr: ToastrService,
      public businessLogic: BusinessLogicService,
      public condominosService: CondominosService,
    ) { }

  ngOnInit() {
  }

  ngAfterViewChecked() { 
    this.cdRef.detectChanges();
  }


  data:data = {
    entidade: {
      nome: null,
      nif: null,
      morada: null,
    },
    fracao: {
      dt_inicio: null,
      dt_inicio_ext: null,
      dt_fim: null,
      dt_fim_ext: null,
      cod_fracao_visible: null,
    },
    condominio: {
      banco: null,
      nib: null,
      nome_condominio: null,
      morada_condominio: null,
    },
    divida: {
      valor: null,
      valor_ext: null,
    },
    acordo: {
      penalizacao: null,
      periodicidade: null,
      n_prestacoes: null,
      ajuste: null,
      valorPrestacao: null,
      data_divida:null,
      data_divida_ext:null,
      primeiraPrestacao:null,
      ultimaPrestacao:null,
      ajustePrestacao:null,
      prestacoesAdicionais: [],
    }
  }

  clausulaPrimeira = null;
  clausulaTerceira = null;
  clausulaQuarta = null;

  @ViewChild('contaCorrentePDF', { static: false }) contaCorrentePDF:ContaCorrentePdfComponent;
  @ViewChild('pdfRef', { static: false }) pdfController:PDFExportComponent;
  open(acordoInfoInput:acordoInfoInput):Promise<boolean> {
    return new Promise(async (resolve) => {
      let res = await this.loadDataAndExportPDF(acordoInfoInput);
      resolve(res);
    });
  }

  getConfissaoDividaBase64(acordoInfoInput:acordoInfoInput):Promise<string> {
    return new Promise(async (resolve) => {
      let success = await this.loadInfo(acordoInfoInput);
      if (!success) {
        resolve(null);
        return;
      }
      this.cdRef.detectChanges();
      
      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');
        resolve(base64);
      }).catch(err => {
        resolve(null);
      });
    });
  }

  getContaCorrenteBase64(acordoInfoInput:acordoInfoInput):Promise<string> {
    return new Promise(async (resolve) => {
      let ccInput: ContaCorrentePDFInput = {
        type: 'CONDOMINO',
        cod: acordoInfoInput.cod_entidade,
        data: acordoInfoInput.data_divida,
        tipoAgrupamento: '1',
        tipoListagem: '0',
        condominos: [{cod_fraccao: acordoInfoInput.cod_fracao, cod_condominio: acordoInfoInput.cod_condominio}]
      }
      let base64 = await this.contaCorrentePDF.getBase64(ccInput);
      resolve(base64);
    });
  }

  loadDataAndExportPDF(acordoInfoInput: acordoInfoInput):Promise<boolean> {
    return new Promise(async (resolve) => {
      let req = [
        this.getConfissaoDividaBase64(acordoInfoInput),
        this.getContaCorrenteBase64(acordoInfoInput),
      ];

      Promise.all(req).then(async res => {
        let base64 = await this.businessLogic.mergePdf(this.filename, res as string[]);
        this.utils.downloadFile(base64, this.filename);
        resolve(true);
      }).catch(err => {resolve(false)});
    });
  }
  

  loadInfo(acordoInfoInput:acordoInfoInput):Promise<boolean> {
    return new Promise(async (resolve) => {
      this.clausulaTerceira = null;
      this.clausulaQuarta = null;
      this.now = new Date();
      this.now_ext = this.utils.getDataPorExtenso(this.now);

      let res = await this.condominosService.getContenciosoPDFInfo(acordoInfoInput.id_fracao, acordoInfoInput.cod_entidade);
      if (!res) {
        resolve(false);
        return;
      }

      let dt_inicio = this.utils.getDate(res.fracao.dt_inicio, undefined, undefined, true);
      let dt_inicio_ext = this.utils.getDataPorExtenso(dt_inicio);
      let dt_fim = this.utils.getDate(res.fracao.dt_fim, undefined, undefined, true);
      let dt_fim_ext = this.utils.getDataPorExtenso(dt_fim);

      let divida = parseFloat(acordoInfoInput.divida);
      let divida_ext = this.utils.getNumberPorExtenso(divida);

      this.data = {
        entidade: {
          nome:res.entidade.nome,
          nif:res.entidade.nif,
          morada: this.utils.formatMoradaJSON(this.utils.moradaStrToJSON({}, res.entidade.morada)),
        },
        fracao: {
          dt_inicio: dt_inicio,
          dt_inicio_ext: dt_inicio_ext,
          dt_fim: dt_fim,
          dt_fim_ext: dt_fim_ext,
          cod_fracao_visible: res.fracao.cod_fracao_visible,
        },
        condominio: {
          ...res.condominio,
          morada_condominio: this.utils.formatMoradaJSON(this.utils.moradaStrToJSON({}, res.condominio.morada_condominio)),
        },
        divida: {
          valor: divida,
          valor_ext: divida_ext,
        },
        acordo: {
          penalizacao: acordoInfoInput.penalizacao,
          periodicidade: acordoInfoInput.periodicidade,
          n_prestacoes: acordoInfoInput.n_prestacoes,
          ajuste: acordoInfoInput.ajuste,
          valorPrestacao    : acordoInfoInput.valorPrestacao,
          data_divida: acordoInfoInput.data_divida,
          data_divida_ext: this.utils.getDataPorExtenso(acordoInfoInput.data_divida),
          primeiraPrestacao: acordoInfoInput.primeiraPrestacao,
          ultimaPrestacao: acordoInfoInput.ultimaPrestacao,
          ajustePrestacao: acordoInfoInput.ajustePrestacao,
          prestacoesAdicionais: acordoInfoInput.prestacoesAdicionais,
        }
      }

      if (!this.isNeededInfoFilled()) {
        resolve(false);
        return;
      }

      this.data.fracao.dt_inicio_ext = this.utils.getDataPorExtenso(this.data.fracao.dt_inicio);
      this.data.fracao.dt_fim_ext = this.utils.getDataPorExtenso(this.data.fracao.dt_fim);
      this.data.divida.valor_ext = this.utils.getNumberPorExtenso(this.data.divida.valor);

      this.loadPrimeira();
      this.loadTerceira();
      this.loadQuarta();
      resolve(true);
    });
  }

  isNeededInfoFilled() {
    if (this.data.entidade.nif == null) {
      this.toastr.error(this.appConfig.errorCodes['ERR_CONDOMINO_004'].msg, this.appConfig.errorCodes['ERR_CONDOMINO_004'].title);
      return false;
    }
    if (this.data.entidade.morada == null || this.data.entidade.morada.trim() === '') {
      this.toastr.error(this.appConfig.errorCodes['ERR_CONDOMINO_004'].msg, this.appConfig.errorCodes['ERR_CONDOMINO_004'].title);
      return false;
    }
    return true;
  }

  loadPrimeira() {
    this.clausulaPrimeira = !this.data.fracao.dt_fim? 'ser ' : 'ter sido ';
    this.clausulaPrimeira += ' proprietário da fração ' + this.data.fracao.cod_fracao_visible + ' do condomínio ' + this.data.condominio.nome_condominio + ' com morada em ' + this.data.condominio.morada_condominio;
    if (this.data.fracao.dt_fim) {
      this.clausulaPrimeira += ', entre ' + this.data.fracao.dt_inicio_ext + ' e ' + this.data.fracao.dt_fim_ext;
    }
    this.clausulaPrimeira += '.';
  }

  loadTerceira() {
    let nPrestacoes = this.data.acordo.n_prestacoes;
    let valorPrestacao = this.data.acordo.valorPrestacao;
    let diaPrestacao = this.data.acordo.primeiraPrestacao.getDate();
    let inicioAcordo = this.utils.getDataPorExtenso(this.data.acordo.primeiraPrestacao, false); 
    let fimAcordo = this.utils.getDataPorExtenso(this.data.acordo.ultimaPrestacao, false); 
    let ajustePrestacao = this.data.acordo.ajustePrestacao;
    let ajuste = this.data.acordo.ajuste;


    this.clausulaTerceira = "Promete liquidar a quantia em dívida ";
    if (this.data.acordo.n_prestacoes > 0) {
      let periodicidade = this.appConfig.periodicityOpts.find(el => el.value === this.data.acordo.periodicidade);
      this.clausulaTerceira += "em " + this.utils.getNumberPorExtenso(nPrestacoes, false) + " (" + nPrestacoes + ") prestações " + (periodicidade? periodicidade.plural.toLowerCase() : ' ');
      this.clausulaTerceira += ajuste > 0? ", sucessivas, " : ", iguais e sucessivas, ";
      this.clausulaTerceira += "no montante de " + this.utils.getNumberFormatted(valorPrestacao) + this.appConfig.currencySymbol + ' (' + this.utils.getNumberPorExtenso(valorPrestacao) + ')';
      this.clausulaTerceira += ", cada, até ao dia " + diaPrestacao + " de cada mês, com início em " + inicioAcordo + " e termo em " + fimAcordo;
      if (ajustePrestacao && ajuste) {
        this.clausulaTerceira += ", com um ajuste na " + (ajustePrestacao === 'P' ? 'primeira' : 'última') + ' prestação, tendo a mesma um acréscimo de ' + this.utils.getNumberFormatted(ajuste) + " " + this.appConfig.currencySymbol + " (" + this.utils.getNumberPorExtenso(ajuste) + ").";
      } else {
        this.clausulaTerceira += ".";
      }
      if (this.data.acordo.prestacoesAdicionais.length) {
        this.clausulaTerceira += " Serão ainda cobradas as seguintes prestações:"
      }
    } else {
      this.clausulaTerceira += "conforme as seguintes prestações:"
    }
  }

  loadQuarta() {
    this.clausulaQuarta = "Promete liquidar os valores acordados ";
    if (this.data.condominio.banco && this.data.condominio.nib) {
      this.clausulaQuarta += "para a conta bancária do condomínio do banco " + this.data.condominio.banco + " com o IBAN " + this.utils.getIBANFormatted(this.data.condominio.nib) + ' ou ';
    }
    
    this.clausulaQuarta += "em numerário em " + (this.appConfig.company.nome ? this.appConfig.company.nome : '_________________') + ' ';
    this.clausulaQuarta += "situada em " + (this.appConfig.company.morada ? this.appConfig.company.morada : '_________________') + ', ';
    this.clausulaQuarta += (this.appConfig.company.localidade ? this.appConfig.company.localidade : '______________') + ', ';
    this.clausulaQuarta += (this.appConfig.company.codigoPostal ? this.appConfig.company.codigoPostal : '_________-______') + '.';
  }

}

