import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { OrdemTrabalhoList } from '../assembleias-details/assembleias-details.component';
import { AssembleiasService } from '../business-logic-services/assembleias.service';
import { IContext } from '../business-model-interfaces/application';
import { AssembleiasPresencas, BancoOperation, OrdemTrabalhoParametros } from '../business-model-interfaces/assembleias';
import { EntityType } from '../condominos';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { SelectEntitiesModalComponent } from '../select-entities-modal/select-entities-modal.component';
import { UtilitiesService } from '../utilities.service';



interface InsertedResp {
  id_param: number,
  operation_id: BancoOperation,
  operation_name: string,

  banco_cod: string,
  banco_name: string,

  conta_cod: string,
  conta_name: string,

  num_titulares: number,
  num_assinaturas: number,

  titulares: Array<{ name: string, cod: number, type: EntityType }>,
}

type InputFields = 'OPERATION' | 'BANK' | 'ACCOUNT' | 'NUM_TITULARES' | 'NUM_ASSINATURAS' | 'SELECT_TITULARES'

@Component({
  selector: 'app-edit-bancos-assembleia-resp',
  templateUrl: './edit-bancos-assembleia-resp.component.html',
  styleUrls: ['./edit-bancos-assembleia-resp.component.scss']
})
export class EditBancosAssembleiaRespComponent implements OnInit {


  @Input() cod_condominio = null;
  @Input() assembleiaFracoesList: Array<AssembleiasPresencas> = [];

  //------------- INSERT FORM -------------

  // -----------------------------------
  // ------------- OPERATION --------------
  operationsOpts: Array<{name:string, value:BancoOperation }> = [];
  operationSel: BancoOperation = null;
  //-----------------------------------
  // ------------- BANK --------------
  bancosOpts: Array<{ name: string, value: {cod, sigla, banco} }> = [];
  bancoSel:{cod, sigla, banco} = null;
  //-----------------------------------

  // ------------- BANK ACCOUNT --------------
  contasOpts: Array<{ name: string, value: {cod, banco, banco_nome}  }> = [];
  contaSel: {cod, banco, banco_nome} = null;
  //-----------------------------------

  // ------------- NUM TITULARES --------------
  numTitulares = null;
  numTitularesChanged() {
    let input = document.getElementById('input-assinaturas');
    input.setAttribute('min', this.numTitulares ? '1' : '0');
    input.setAttribute('max', this.numTitulares ? this.numTitulares : '0');
    if (this.numTitulares == null || this.numAssinaturas > this.numTitulares) this.numAssinaturas = this.numTitulares;
  }
  //---------------------------------------------
  // ------------- NUM Assinaturas --------------
  numAssinaturas = null;
  numAssinaturasChanged() {
  }
  //---------------------------------------------
  //---------------------------------------------


  respsInserted: Array<InsertedResp> = [];

  constructor(
    public cdRef: ChangeDetectorRef,
    public modalService: SuiModalService,
    public api: ApiService,
    public toastr: ToastrService,
    public utils: UtilitiesService,
    public assembleias: AssembleiasService,
    public appConfig: AppConfigService,
  ) { }

  @ViewChild('editBancosRespAlertRef', { static: false }) editBancosRespAlertRef;
  editBancosRespModalRef = null;
  editBancosRespAlertConfig: any = null;

  ngOnInit() {
    this.getSelectsOptions();
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngAfterViewInit() {
    this.editBancosRespAlertConfig = new TemplateModalConfig<IContext, string, string>(this.editBancosRespAlertRef);
    this.editBancosRespAlertConfig.isClosable = false;
    this.editBancosRespAlertConfig.closeResult = "closed";
    this.editBancosRespAlertConfig.size = 'medium';
    this.editBancosRespAlertConfig.transition = 'fade';
    this.editBancosRespAlertConfig.transitionDuration = 250;
  }

  getSelectsOptions(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.cod_condominio) {
        resolve(false);
        return;
      }
      let req = [
        this.api.getBancos(),
        this.api.getCondContasDetails(this.cod_condominio),
        this.api.getOrdemTrabalhosResp({ordem_trabalho_label: 'BANCOS'}),
      ];

      this.operationsOpts = [];
      forkJoin(req).subscribe(resArr => {
        if (!resArr.find(el => !el.hasOwnProperty('success') || !el.success)) {
          this.bancosOpts = resArr[0].data.filter(el => (el.sigla !== 'CAIXA' && el.sigla.indexOf('CX VERTIS') === -1)).map(el => { return { name: el.sigla, value: el } })
          this.contasOpts = resArr[1].data.filter(el => (el.banco !== 'CAIXA' && el.banco.indexOf('CX VERTIS') === -1)).map(el => { return { name: el.banco, value: el } })
          this.operationsOpts = resArr[2].data.map(el => { return { name: el.descricao, value: el.label } })
          resolve(true);
        } else {
          this.utils.apiErrorMsg(resArr);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      })
    });
  }


  // --------------- ngModelChanges ---------------
  operationSelChanged() {
    this.bancoSel = null;
    this.contaSel = null;
    this.numTitulares = null;
    this.numAssinaturas = null;
  }


  // ----------------------------------------------
  fetchingInfo = false;
  open(parametros: Array<OrdemTrabalhoParametros> = []): Promise<Array<{id_param: number, operation: BancoOperation, conta:{cod:number, name:string}, banco:{cod:number, name:string}, entidades:Array<{cod, name, type:EntityType}>, numAssinaturas: number}>> {
    return new Promise((resolve) => {
      
      this.resetFields();
      
      this.fetchingInfo = true;
      this.getSelectsOptions().then(async (res) => {
        if (res) {
          await this.loadInfo(parametros);
        }
        this.fetchingInfo = false;
      });

      this.editBancosRespModalRef = this.modalService
        .open(this.editBancosRespAlertConfig)
        .onApprove(() => {
          resolve(this.respsInserted.map(el => {
            return {
              id_param: el.id_param,
              operation: el.operation_id,
              conta: el.conta_cod != null ? { name: el.conta_name, cod: parseInt(el.conta_cod) } : null,
              banco: el.banco_cod != null ? { name: el.banco_name, cod: parseInt(el.banco_cod) } : null,
              entidades: el.titulares,
              numAssinaturas: el.num_assinaturas,
            }
          }));
        })
        .onDeny(() => {
          resolve(null);
        });
    })
  }

  async loadInfo(parametros: Array<OrdemTrabalhoParametros>): Promise<boolean> {
    return new Promise(async (resolve) => {
      let paramsFullInfo = await this.assembleias.getParamsFullInfo(parametros);
      if (!paramsFullInfo || paramsFullInfo.length !== parametros.length) {
        resolve(false);
        return;
      }
  
      paramsFullInfo.map((valor, index) => {
        let operationName = this.operationsOpts.find(op => op.value === parametros[index].chave);
        let fields = null;
        try {
          fields = JSON.parse(valor)
        } catch (err) { }
  
        this.respsInserted.push({
          id_param: parametros[index].id,
          operation_id: (parametros[index].chave as BancoOperation),
          operation_name: operationName? operationName.name : null,
          banco_cod: fields && fields['banco'] ? fields['banco']['cod'] : null,
          banco_name: fields && fields['banco'] ? fields['banco']['nome'] : null,
          conta_cod: fields && fields['conta'] ? fields['conta']['cod'] : null,
          conta_name: fields && fields['conta'] ? fields['conta']['nome'] : null,
          num_titulares: fields && fields['entidades'] ? fields['entidades'].length : null,
          num_assinaturas: fields ? fields['num_assinaturas'] : null,
          titulares: fields && fields['entidades'] ? fields['entidades'].map(ent => { return { name: ent.nome, cod: ent.cod, type: ent.type }}) : []
        });
      })
      this.sortResps();
      resolve(true);
    })
  }


  resetFields(compReset = true) {
    this.operationSel = null;
    this.bancoSel = null;
    this.contaSel = null;
    this.numTitulares = null;
    this.numAssinaturas = null;

    if (compReset) {
      this.respsInserted = [];  
    }
     
    this.insertingFeedback = { show: false, timeout: null};
  }

  needFieldInput(field: InputFields) {
    switch (field) {
      case 'OPERATION':
        return true;
      case 'BANK':
        return this.operationSel === 'BANCO_ABERTURA';
      case 'ACCOUNT':
        return this.operationSel !== 'BANCO_ABERTURA';
      case 'NUM_TITULARES':
        return this.operationSel != null && this.operationSel !== 'BANCO_FECHO';
      case 'NUM_ASSINATURAS':
        return this.operationSel != null && this.operationSel !== 'BANCO_FECHO';
      case 'SELECT_TITULARES':
        return this.operationSel != null && this.operationSel !== 'BANCO_FECHO';
      default:
        break;
    }

  }

  isFieldValid(field: InputFields) {
    switch (field) {
      case 'OPERATION':
        return this.operationsOpts.findIndex(el => el.value === this.operationSel) !== -1;
      case 'BANK':
        return this.bancoSel && this.bancosOpts.findIndex(el => el.value.cod === this.bancoSel.cod) !== -1;
      case 'ACCOUNT':
        return this.contaSel && this.contasOpts.findIndex(el => el.value.cod === this.contaSel.cod) !== -1;
      case 'NUM_TITULARES':
        return this.numTitulares > 0;
      case 'NUM_ASSINATURAS':
        return this.numAssinaturas > 0 && this.numAssinaturas <= this.numTitulares;
      default:
        break;
    }
  }

  contaOptSelected() {
    if (this.operationSel !== 'BANCO_ABERTURA' && this.respsInserted.findIndex(el => el.operation_id !== 'BANCO_ABERTURA' && el.conta_cod == this.contaSel.cod) !== -1) {
      this.toastr.error('Já existe uma resposta com conta selecionada. Se pretender acrescentar uma nova resposta, elimine a resposta já inserida.', 'Ups...!');
      setTimeout(() => {
       this.contaSel = null; 
      });
    }
  }

  insertingFeedback = {
    show: false,
    timeout: null,
  };
  
  @ViewChild('selectEntities', { static: false }) selectEntities: SelectEntitiesModalComponent;
  async insert() {
    this.utils.setFormFeedback(this.insertingFeedback);
    if (!this.isFormValid()) return;

    let selectedTitulares = [];
    if (this.operationSel !== 'BANCO_FECHO') {
      selectedTitulares = await this.askForEntities();
      if (selectedTitulares == null) return;
    }


    let operation = this.operationSel ? this.operationsOpts.find(el => el.value === this.operationSel) : null;
    let banco = this.bancoSel ? this.bancosOpts.find(el => el.value.cod === this.bancoSel.cod) : null;
    let conta = this.contaSel ? this.contasOpts.find(el => el.value.cod === this.contaSel.cod) : null;

    let resp: InsertedResp = {
      id_param: null,
      operation_id: this.operationSel,
      operation_name: operation ? operation.name : null,
      banco_cod: banco ? banco.value.cod : null,
      banco_name: banco ? banco.value.banco : null,
      conta_cod: conta ? conta.value.cod : null,
      conta_name: conta ? (conta.value.banco_nome ? conta.value.banco_nome : conta.value.banco) : null,
      num_titulares: this.numTitulares,
      num_assinaturas: this.numAssinaturas,
      titulares: selectedTitulares
    }
    this.respsInserted.push(resp);

    this.sortResps();

    this.resetFields(false);
  }

  sortResps() {
    this.respsInserted.sort((a,b) => {
      let a_count = 0;
      let b_count = 0;
      switch (a.operation_id) {
        case 'BANCO_ABERTURA':
            a_count = 1;
            break;
          case 'BANCO_ALTERACAO':
            a_count = 2;
            break;
          case 'BANCO_ALTERACAO_FECHO':
            a_count = 3;
            break;
          case 'BANCO_FECHO':
            a_count = 4;
            break;
        default:
          break;
      }
      switch (b.operation_id) {
        case 'BANCO_ABERTURA':
          b_count = 1;
            break;
          case 'BANCO_ALTERACAO':
            b_count = 2;
            break;
          case 'BANCO_ALTERACAO_FECHO':
            b_count = 3;
            break;
          case 'BANCO_FECHO':
            b_count = 4;
            break;
        default:
          break;
      }
      return a_count - b_count;
    })
  }

  askForEntities(): Promise<Array<{name:string, cod:number, type:EntityType}>> {
    return new Promise(async (resolve) => {
      let entidadesList = this.assembleiaFracoesList.map(el => {
        let clone = {
          checked: false,
          nome_fraccao: el.nome_fracao,
          cod_entidade: el.cod_condomino,
          nome_entidade: el.nome_condomino,
        }
        return clone;
      })

      let titulares = await this.selectEntities.open({
        entidadesList: entidadesList,
        nSelection: this.numTitulares
      });
      resolve(titulares);
    })
  }

  async deleteResp(i: number) {
    if (this.respsInserted[i].operation_id === 'BANCO_ABERTURA' && this.respsInserted[i].conta_cod != null) {
      let hasMovs = await this.assembleias.contaHasMovements(this.respsInserted[i].conta_cod);
      if (hasMovs === 1) {
        this.toastr.error(this.appConfig.errorCodes.ERR_ASSEMBLEIAS_003.msg, this.appConfig.errorCodes.ERR_ASSEMBLEIAS_003.title);
        return;
      }
    }
    this.respsInserted.splice(i, 1);
  }



  isFormValid(): boolean {
    if (this.needFieldInput('OPERATION') && !this.isFieldValid('OPERATION')) return false;
    if (this.needFieldInput('BANK') && !this.isFieldValid('BANK')) return false;
    if (this.needFieldInput('ACCOUNT') && !this.isFieldValid('ACCOUNT')) return false;
    if (this.needFieldInput('NUM_TITULARES') && !this.isFieldValid('NUM_TITULARES')) return false;
    if (this.needFieldInput('NUM_ASSINATURAS') && !this.isFieldValid('NUM_ASSINATURAS')) return false;
    return true;
  }

  @ViewChild('modalDefault', { static: false }) modalDefault: ModalDefaultComponent;
  async submit() {
    if (!this.respsInserted.length) {
      this.toastr.error('É necessário adicionar pelo menos 1 resposta.', 'Ups...!')
      return;
    }

    if (this.contaSel != null || this.bancoSel != null || this.numTitulares > 0 || this.numAssinaturas > 0) {
      let res = await this.modalDefault.open('Confirmação', 'Tem uma operação por submeter. Deseja ignorá-los e submeter ' + this.respsInserted.length + (this.respsInserted.length > 1 ? ' operações' : ' operação') + '?', null, 'Sim', null, 'tiny', null, null);
      if (res == null) return;
    }
    this.editBancosRespModalRef.approve();
  }

  deny() {
    this.editBancosRespModalRef.deny();
  }

}
