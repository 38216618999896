import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
import { Location, formatDate } from '@angular/common';
interface IContext {
  data:string;
}
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';


@Component({
  selector: 'app-conta-corrente-relatorio',
  templateUrl: './conta-corrente-relatorio.component.html',
  styleUrls: ['./conta-corrente-relatorio.component.scss']
})
export class ContaCorrenteRelatorioComponent implements OnInit {

  // PDF REPORT VARIABLES -----------------------------------------------------
  @ViewChild('pdf', { static: false }) pdfController;

  reportDetailedListColPDF = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered first-report-col' },
    { key: 'doc', name: 'Doc', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'proc', name: 'Processamento', type: 'text', sort: null, searchable: false, centered: false, class: 'big-report-col' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];

  reportDetailedFornecedorListColPDF = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered first-report-col' },  // 'ASC', 'DESC'
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: true, class: 'big-report-col-descricao' },
    { key: 'doc', name: 'Doc', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'data_pag', name: 'Data Pag.', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered' },
    { key: 'banco', name: 'Banco', type: 'text', sort: null, searchable: false, centered: false, class: 'big-report-col-banco' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'valor-col col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'valor-col col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'three wide col-align-right' },
  ];

  reportFornecedorListColPDF = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered first-report-col' },  // 'ASC', 'DESC'
    { key: 'condominio', name: 'Condomínio', type: 'text', sort: null, searchable: false, centered: false, class: '' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'valor-col col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'valor-col col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'valor-col col-align-right' },
  ];


  pdfReport = {
    title: null,
    reportType: 'Conta Corrente',
    startDate: null,
    endDate: null,
    now: new Date(),
  }
  pdfEnabled = false;

  // GLOBAL VARIABLES ----------------------------------------------------------
  transitionController = new TransitionController();
  tapTransitionController = new TransitionController();
  loading = false;
  loadingModal = false;
  searchable = true;
  clearEntry = { name: '-- limpar selecção --', value: '-1' };
  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  // REPORT DETAILED TABLE VARIABLES
  reportDetailedListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide col-centered' },  // 'ASC', 'DESC'
    { key: 'doc', name: 'Doc', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered' },
    { key: 'data_venc', name: 'Vencimento', type: 'text', sort: null, searchable: false, centered: false, class: 'two wide col-centered' },
    { key: 'proc', name: 'Processamento', type: 'text', sort: null, searchable: false, centered: false, class: '' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];
  // REPORT RESUMED TABLE VARIABLES
  reportListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide col-centered' },  // 'ASC', 'DESC'
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'two wide col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right' },
  ];
  reportListColPDF = [
    { key: 'fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left padding-left' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right valor-col' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right valor-col' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right valor-col' },
  ];
  // REPORT DETAILED FORNECEDOR TABLE VARIABLES
  reportDetailedFornecedorListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide col-centered' },  // 'ASC', 'DESC'
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left' },
    { key: 'doc', name: 'Doc', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered' },
    { key: 'data_pag', name: 'Data Pag.', type: 'text', sort: null, searchable: false, centered: false, class: 'two wide col-centered' },
    { key: 'banco', name: 'Banco', type: 'text', sort: null, searchable: false, centered: false, class: 'two wide' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];
  reportFornecedorListCol = [
    { key: 'condominio', name: 'Condomínio', type: 'text', sort: null, searchable: false, centered: false, class: '' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];
  reportList: Array<any> = [];
  reportListPDF: Array<any> = [];
  reportSortParam: string = null;
  reportSortDirection: string = null;

  nomeCondominio = null;
  startDate = null;
  endDate = null;
  total = {
    valor_limpeza: 0,
    debito: 0,
    credito: 0,
    saldo: 0,
  };
  targetEntity = null;
  selTipoListagem = null;
  tipoAgrupamento = null;

  isDetailed = true;
  isFornecedor = true;

  offset = 0;
  limit = 30;

  reportParams = {};

  isZIndexZero = false;

  hasAnteriorProprietario = false;

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public route: ActivatedRoute,
              public router: Router,
              public userSession: UserSessionService,
              public api: ApiService,
              public cdRef:ChangeDetectorRef,
              public location: Location,
              public utils: UtilitiesService,
              public appState: AppStateService,
              public appConfig: AppConfigService,
              public message: MessageService) {
    // SET REPORT TYPE
    this.isDetailed = (this.router.url.indexOf('detalhado') !== -1);

    this.reportParams = this.appState.getReportData();

    this.nomeCondominio = (this.reportParams['condominio']) ? this.reportParams['condominio']['cod'] + ' - ' + this.reportParams['condominio']['nome'] : null;
    this.isLimpezaDespesa = this.nomeCondominio && this.nomeCondominio.indexOf('Limpeza V') !== -1 && (this.userSession.getUsername() === 'fred' || this.userSession.getUsername() === 'sergio.assuncao');
    if (this.isLimpezaDespesa) {
      this.reportFornecedorListCol.splice(1, 0, { key: 'valor_limpeza', name: 'Valor Limpeza', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right valor-col' });
      this.reportDetailedFornecedorListCol.splice(6, 0, { key: 'valor_limpeza', name: 'Valor Limpeza', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right valor-col' });
      
      this.reportFornecedorListColPDF.splice(2, 0, { key: 'valor_limpeza', name: 'Valor Limpeza', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right valor-col' });
      this.reportDetailedFornecedorListColPDF.splice(6, 0, { key: 'valor_limpeza', name: 'Valor Limpeza', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right valor-col' });
    }

    this.startDate = this.reportParams['startDate'];
    this.endDate = (this.reportParams['endDate']) ? this.reportParams['endDate'] : new Date();
    this.total = this.reportParams['total'];
    this.targetEntity = this.reportParams['targetEntity'];
    this.selTipoListagem = this.reportParams['tipoListagem'];
    this.tipoAgrupamento = this.reportParams['tipoAgrupamento'];

    switch (this.targetEntity) {
      case 'condominio':
        if (this.isDetailed) {
          this.generateReportListObj(this.reportParams['reportData']);
        } else {
          this.reportList = this.reportParams['reportData'];
          this.reportListPDF = this.reportParams['reportData'];

          if (this.tipoAgrupamento === 'condominos') {
            this.reportListPDF.forEach((el, i) => {
              if (i !== 0 && this.reportListPDF[i-1].hasOwnProperty('fraccao') && el.hasOwnProperty('fraccao') && this.reportListPDF[i-1]['fraccao'].split(' - ')[0] !== el['fraccao'].split(' - ')[0]) {
                this.reportListPDF[i-1]['endFraccao'] = true;
              }
            });

            this.hasAnteriorProprietario = (this.reportListPDF.find(el => (el.hasOwnProperty('fraccao') && el.fraccao.indexOf('*') !== -1))) ? true : false;
          }
        }
        break;
      case 'condomino':
        if (this.isDetailed) {
          this.totalPDF = this.total;
        }
        this.reportList = this.reportParams['reportData'];
        this.reportListPDF = this.reportParams['reportData'];
        break;
      case 'fornecedor':
        if (this.isDetailed) {
          this.generateReportByFornecedorListObj(this.reportParams['reportData']);
        } else {
          this.reportList = this.reportParams['reportData'];
          this.reportListPDF = this.reportParams['reportData'];
        }
        break;
    }

    // SET PDF REPORT PARAMETERS
    this.pdfReport.title = this.nomeCondominio;
    this.pdfReport.startDate = this.startDate;
    this.pdfReport.endDate = this.endDate;

    if (!this.nomeCondominio) this.location.back();

    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'CAIXA_VERTIS') {
        switch (msg.cmd) {
          case 'HIDE_INPUT':
            this.isZIndexZero = true;
            break;
          case 'SHOW_INPUT':
            this.isZIndexZero = false;
            break;
        }
      }
    });

    this.getContaPrincipal();
  }

  IBANCondominio = null;
  getContaPrincipal() {
    return new Promise(resolve => {
      if (this.reportParams && this.reportParams.hasOwnProperty('condominio') && this.reportParams['condominio'] && this.reportParams['condominio']['cod']) {
        this.api.getContaPrincipal(this.reportParams['condominio']['cod']).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            if (res.data && res.data.hasOwnProperty('banco') && res.data.banco !== 'CAIXA' && res.data.nib) {
              if (res.data.nib.indexOf('PT50') !== -1) {
                this.IBANCondominio = res.data.nib;
              } else {
                this.IBANCondominio = 'PT50' + res.data.nib;
              }
            }
          }
          resolve(true);
        }, err => {
          this.IBANCondominio = null;
          resolve(false);
        });
      } else {
        this.IBANCondominio = null;
        resolve(false);
      }
    });
  }

  reportGenerated = false;
  exportPDF() {
    this.toastr.info('Por favor aguarde, o documento está a ser criado...', 'Gerar Documento');

    setTimeout(() => {
      if (!this.reportGenerated) {
        switch (this.targetEntity) {
          case 'condominio': if (this.isDetailed) this.generateReportListObjPDF(this.reportParams['reportData']); break;
          case 'condomino': break;
          case 'fornecedor': if (this.isDetailed) this.generateReportByFornecedorListObjPDF(this.reportParams['reportData']); break;
        }
      }

      this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
      this.pdfController.forceProxy = true;
      this.pdfController.proxyTarget = '_blank';
  
      let filename = this.pdfReport.title.replace(/ /g, '_') + '_'
        + ((this.pdfReport.startDate) ? formatDate(this.pdfReport.startDate, this.format, this.locale) : '') + '_'
        + formatDate(this.pdfReport.endDate, this.format, this.locale) + '_'
        + this.pdfReport['reportType'].replace(/ /g, '_');
      filename = filename.replace(/,/g, '');
      
      setTimeout(() => { this.pdfController.saveAs(filename + '.pdf'); }, 1);
  
      this.reportGenerated = true;
    }, 50);
  }

  exportExcel() {
    let filename = this.pdfReport.title.replace(/ /g, '_') + '_'
      + ((this.pdfReport.startDate) ? formatDate(this.pdfReport.startDate, this.format, this.locale) : '') + '_'
      + formatDate(this.pdfReport.endDate, this.format, this.locale) + '_'
      + this.pdfReport['reportType'].replace(/ /g, '_');
    filename = filename.replace(/,/g, '');

    let excelData = [];
    let worksheet: XLSX.WorkSheet = null;
    let totalDebito = 0;
    let totalCredito = 0;
    let totalSaldo = 0;
    let aux = null;

    let reportFilter = this.selTipoListagem == '1' ? 'Valores por liquidar' : (this.selTipoListagem == '2'? 'Valores liquidados' : null);

    if (this.targetEntity === 'condominio') {

      if (this.isDetailed) {
        // HEADER
        excelData = [{ doc: this.pdfReport.title, data: null, data_venc: null, descricao: null, debito: null, credito: null, saldo: null }];
        excelData.push({ doc: this.pdfReport.reportType, data: null, data_venc: null, descricao: null, debito: null, credito: null, saldo: null });

        if (this.pdfReport.startDate === null && this.pdfReport.endDate !== null) {
          excelData.push({ doc: (reportFilter? reportFilter + ' até ' : 'Até ') + this.utils.getFormatedDate(this.pdfReport.endDate), data: null, data_venc: null, descricao: null, debito: null, credito: null, saldo: null });
        } else {
          excelData.push({ doc: (reportFilter? reportFilter + ' de ' : 'De ') + this.utils.getFormatedDate(this.pdfReport.startDate) + ' até ' + this.utils.getFormatedDate(this.pdfReport.endDate), data: null, data_venc: null, descricao: null, debito: null, credito: null, saldo: null });
        }

        // DATA
        excelData.push({ doc: null, data: null, data_venc: null, descricao: null, debito: null, credito: null, saldo: null });
        excelData.push({ doc: null, data: null, data_venc: null, descricao: null, debito: null, credito: null, saldo: null });

        excelData.push({ doc: 'Doc', data: 'Data', data_venc: 'Vencimento', descricao: 'Processamento', debito: 'Débito [€]', credito: 'Crédito [€]', saldo: 'Saldo [€]' });

        this.reportParams['reportData'].forEach(el => {

          if (!el.separator) {
            aux = {
              doc: el.n_doc,
              data: this.utils.getFormatedDate(el.data_emis),
              data_venc: this.utils.getFormatedDate(el.data_venc),
              descricao: el.descricao,
              debito: (Math.round(Number(el['debito']) * 100) / 100).toString().replace('.', ','),
              credito: (Math.round(Number(el['credito']) * 100) / 100).toString().replace('.', ','),
              saldo: (Math.round(Number(el['saldo']) * 100) / 100).toString().replace('.', ','),
            }

            excelData.push(aux);
        
            if ((typeof el.doc !== 'undefined')) {
              totalDebito += Math.round(Number(el['debito']) * 100) / 100;
              totalCredito += Math.round(Number(el['credito']) * 100) / 100;
              totalSaldo += Math.round(Number(el['saldo']) * 100) / 100;
            }
          } else {
            if (el.separator && !el.separatorCond) excelData.push({ doc: null, data: null, data_venc: null, descricao: null, debito: null, credito: null, saldo: null });
            excelData.push({ doc: el.label, data: null, data_venc: null, descricao: null, debito: null, credito: null, saldo: null });
          }
        });

        excelData.push({ doc: null, data: null, data_venc: null, descricao: null, debito: null, credito: null, saldo: null });
        excelData.push({ doc: 'Total', data: null, data_venc: null, descricao: null, debito: (Math.round(totalDebito * 100) / 100).toString().replace('.', ','), credito: (Math.round(totalCredito * 100) / 100).toString().replace('.', ','), saldo: (Math.round(totalSaldo * 100) / 100).toString().replace('.', ',') });

        worksheet = XLSX.utils.json_to_sheet(excelData, { header:['doc', 'data', 'data_venc', 'descricao', 'debito', 'credito', 'saldo'], skipHeader: true });



      } else {
        // HEADER
        excelData = [{ fraccao: this.pdfReport.title, debito: null, credito: null, saldo: null }];
        excelData.push({ fraccao: this.pdfReport.reportType, debito: null, credito: null, saldo: null });
        if (this.pdfReport.startDate !== null && this.pdfReport.endDate !== null) {
          excelData.push({ fraccao: (reportFilter? reportFilter + ' de ' : 'De ') + this.utils.getFormatedDate(this.pdfReport.startDate) + ' até ' + this.utils.getFormatedDate(this.pdfReport.endDate), debito: null, credito: null, saldo: null });
        } else {
          excelData.push({ fraccao: (reportFilter? reportFilter + ' até ' : 'Até ') + this.utils.getFormatedDate(this.pdfReport.endDate), debito: null, credito: null, saldo: null });
        }

        // DATA
        excelData.push({ fraccao: null, debito: null, credito: null, saldo: null });
        excelData.push({ fraccao: null, debito: null, credito: null, saldo: null });
        excelData.push({ fraccao: 'Zona/Fracção', debito: 'Débito [€]', credito: 'Crédito [€]', saldo: 'Saldo [€]' });

        this.reportParams['reportData'].forEach(el => {

          if (!el.separator) {
            aux = {
              fraccao: (typeof el.fraccao !== 'undefined') ? el.fraccao : 'Total da zona',
              debito: (Math.round(Number(el['debito']) * 100) / 100).toString().replace('.', ','),
              credito: (Math.round(Number(el['credito']) * 100) / 100).toString().replace('.', ','),
              saldo: (Math.round(Number(el['saldo']) * 100) / 100).toString().replace('.', ','),
            }

            excelData.push(aux);
        
            if ((typeof el.fraccao !== 'undefined')) {
              totalDebito += Math.round(Number(el['debito']) * 100) / 100;
              totalCredito += Math.round(Number(el['credito']) * 100) / 100;
              totalSaldo += Math.round(Number(el['saldo']) * 100) / 100;
            }
          } else {
            excelData.push({ fraccao: null, debito: null, credito: null, saldo: null });
            excelData.push({ fraccao: el.label, debito: null, credito: null, saldo: null });
          }

        });
        excelData.push({ fraccao: null, debito: null, credito: null, saldo: null });
        excelData.push({ fraccao: 'Total', debito: (Math.round(totalDebito * 100) / 100).toString().replace('.', ','), credito: (Math.round(totalCredito * 100) / 100).toString().replace('.', ','), saldo: (Math.round(totalSaldo * 100) / 100).toString().replace('.', ',') });

        worksheet = XLSX.utils.json_to_sheet(excelData, { header:['fraccao', 'debito', 'credito', 'saldo'], skipHeader: true });
      }

    }

    if (this.targetEntity === 'fornecedor') {
      if (this.isDetailed) {
        // HEADER
        excelData = [{ condominio: this.pdfReport.title, descricao: null, doc: null, data_emis: null, data_pag: null, banco: null, debito: null, credito: null, saldo: null }];
        excelData.push({ condominio: this.pdfReport.reportType, descricao: null, doc: null, data_emis: null, data_pag: null, banco: null, debito: null, credito: null, saldo: null });

        if (this.pdfReport.startDate !== null && this.pdfReport.endDate !== null) {
          excelData.push({ condominio: (reportFilter? reportFilter + ' de ' : 'De ') + this.utils.getFormatedDate(this.pdfReport.startDate) + ' até ' + this.utils.getFormatedDate(this.pdfReport.endDate), descricao: null, doc: null, data_emis: null, data_pag: null, banco: null, debito: null, credito: null, saldo: null });
        } else {
          excelData.push({ condominio: (reportFilter? reportFilter + ' até ' : 'Até ') + this.utils.getFormatedDate(this.pdfReport.endDate), descricao: null, doc: null, data_emis: null, data_pag: null, banco: null, debito: null, credito: null, saldo: null });
        }

        // DATA
        excelData.push({ condominio: null, descricao: null, doc: null, data_emis: null, data_pag: null, banco: null, debito: null, credito: null, saldo: null });
        excelData.push({ condominio: null, descricao: null, doc: null, data_emis: null, data_pag: null, banco: null, debito: null, credito: null, saldo: null });
        excelData.push({ condominio: 'Condomínio', descricao: 'Descrição', doc: 'Doc', data_emis: 'Data', data_pag: 'Data Pag.', banco: 'Banco', debito: 'Débito [€]', credito: 'Crédito [€]', saldo: 'Saldo [€]' });
  
        this.reportParams['reportData'].forEach(el => {
          aux = {
            condominio: el.cod_condominio + ' - ' + el.nome_condominio,
            descricao: el.descricao,
            doc: el.tipo_doc + ' ' + el.n_doc,
            data_emis: el.data_emis,
            data_pag: el.data_pag,
            banco: el.form_pagam + ' - ' + el.banco,
            debito: Math.round(Number(el['debito']) * 100) / 100,
            credito: Math.round(Number(el['credito']) * 100) / 100,
            saldo: null,
          }
          aux.saldo = aux.credito - aux.debito;
  
          totalDebito += aux.debito;
          totalCredito += aux.credito;
          totalSaldo += aux.saldo;
  
          aux['debito'] = (aux['debito']).toString().replace('.', ',');
          aux['credito'] = (aux['credito']).toString().replace('.', ',');
          aux['saldo'] = (Math.round(aux['saldo'] * 100) / 100).toString().replace('.', ',');
          excelData.push(aux);;
        });
        excelData.push({ condominio: null, descricao: null, doc: null, data_emis: null, data_pag: null, banco: null, debito: null, credito: null, saldo: null });
        excelData.push({ condominio: 'Total', descricao: null, doc: null, data_emis: null, data_pag: null, banco: null, debito: (Math.round(totalDebito * 100) / 100).toString().replace('.', ','), credito: (Math.round(totalCredito * 100) / 100).toString().replace('.', ','), saldo: (Math.round(totalSaldo * 100) / 100).toString().replace('.', ',') });
  
        worksheet = XLSX.utils.json_to_sheet(excelData, { header:['condominio', 'descricao', 'doc', 'data_emis', 'data_pag', 'banco', 'credito', 'debito', 'saldo'], skipHeader: true });
  
      } else {
        // HEADER
        excelData = [{ condominio: this.pdfReport.title, debito: null, credito: null, saldo: null }];
        excelData.push({ condominio: this.pdfReport.reportType, debito: null, credito: null, saldo: null });

        if (this.pdfReport.startDate !== null && this.pdfReport.endDate !== null) {
          excelData.push({ condominio: (reportFilter? reportFilter + ' de ' : 'De ') + this.utils.getFormatedDate(this.pdfReport.startDate) + ' até ' + this.utils.getFormatedDate(this.pdfReport.endDate), debito: null, credito: null, saldo: null });
        } else {
          excelData.push({ condominio: (reportFilter? reportFilter + ' até ' : 'Até ') + this.utils.getFormatedDate(this.pdfReport.endDate), debito: null, credito: null, saldo: null });
        }

        // DATA
        excelData.push({ condominio: null, debito: null, credito: null, saldo: null });
        excelData.push({ condominio: null, debito: null, credito: null, saldo: null });
        excelData.push({ condominio: 'Condomínio', debito: 'Débito [€]', credito: 'Crédito [€]', saldo: 'Saldo [€]' });
        this.reportParams['reportData'].forEach(el => {
          aux = {
            condominio: el.condominio,
            debito: (Math.round(Number(el['debito']) * 100) / 100).toString().replace('.', ','),
            credito: (Math.round(Number(el['credito']) * 100) / 100).toString().replace('.', ','),
            saldo: (Math.round(Number(el['saldo']) * 100) / 100).toString().replace('.', ','),
          }
          excelData.push(aux);
  
          totalDebito += Math.round(Number(el['debito']) * 100) / 100;
          totalCredito += Math.round(Number(el['credito']) * 100) / 100;
          totalSaldo += Math.round(Number(el['saldo']) * 100) / 100;
  
        });
        excelData.push({ condominio: null, debito: null, credito: null, saldo: null });
        excelData.push({ condominio: 'Total', debito: (Math.round(totalDebito * 100) / 100).toString().replace('.', ','), credito: (Math.round(totalCredito * 100) / 100).toString().replace('.', ','), saldo: (Math.round(totalSaldo * 100) / 100).toString().replace('.', ',') });
  
        worksheet = XLSX.utils.json_to_sheet(excelData, { header:['condominio', 'credito', 'debito', 'saldo'], skipHeader: true });
      }
    }

    let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    let data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});

    FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
  }

  ngOnInit() {
    setTimeout(() => {
      this.pdfEnabled = true;
    }, 500);
    
    this.animate();

    switch (this.targetEntity) {
      case 'condominio':
        if (this.isDetailed) {
          let aux = { debito: null, credito: null, saldo: null };

          this.reportParams['reportData'].forEach(el => {
            if (el['tipo_doc'] === 'C') {
              aux['debito'] = 0;
              aux['credito'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
              aux['saldo'] = aux.debito - aux.credito;
            } else {
              aux['valor_limpeza'] = (el['valor_limpeza']) ? this.utils.cleanDecimalDigits(el['valor_limpeza']) : null;
              aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
              aux['saldo'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
              aux['credito'] = aux.debito - aux.saldo;
            }
      
            if (this.selTipoListagem === '0' || (this.selTipoListagem === '1' && aux['saldo'] !== 0) || (this.selTipoListagem === '2' && aux['saldo'] === 0)) {
              this.total['valor_limpeza'] += aux['valor_limpeza'];
              this.total['debito'] += aux.debito;
              this.total['credito'] += aux.credito;
              this.total['saldo'] += aux.saldo;
            }
          });
        }
        break;
      case 'condomino':
        this.reportList = this.reportParams['reportData'];
        break;
      case 'fornecedor':
        if (this.isDetailed) {
          let aux = { debito: null, credito: null, saldo: null };

          this.reportParams['reportData'].forEach(el => {
            aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
            aux['credito'] = (el['credito']) ? Math.round(Number(el['credito']) * 100) / 100 : null;
            aux['valor_limpeza'] = (el['valor_limpeza']) ? this.utils.cleanDecimalDigits(el['valor_limpeza']) : null;
            aux['saldo'] = aux.credito - aux.debito;

            // if (this.selTipoListagem === '0' || (this.selTipoListagem === '1' && aux['saldo'] !== 0) || (this.selTipoListagem === '2' && aux['saldo'] === 0)) {
              this.total['valor_limpeza'] += aux['valor_limpeza'];  
              this.total['debito'] += aux.debito;
              this.total['credito'] += aux.credito;
              this.total['saldo'] += aux.saldo;
            // }
          });
        }
        break;
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() { }

  subsMsg = null;
  ngOnDestroy() {
    this.appState.clearReportData();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    if (this.subsMsg) this.subsMsg.unsubscribe();
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  valor_limpeza_total = 0;
  debito_total = 0;
  credito_total = 0;
  saldo_total = 0;
  valor_limpeza_total_condominio = 0;
  debito_total_condominio = 0;
  credito_total_condominio = 0;
  saldo_total_condominio = 0;
  prevCondominio = null;
  prevZona = null;
  prevFrac = null;
  prevCondomino = null;

  generateReportByFornecedorListObj(data) {
    data = data.slice(this.offset, this.offset + this.limit);

    if (data.length === 0 && !this.endList) {
      this.reportList.push({ label: 'Total do condomínio', valor_limpeza: this.valor_limpeza_total_condominio, credito: this.debito_total_condominio, debito: this.credito_total_condominio, saldo: this.saldo_total_condominio, separator: false, separatorCond: false, total: true });
      this.endList = true;
      return;
    }

    if (this.endList) return;

    this.offset = this.offset + this.limit;

    for (let i = 0; i < data.length; i++) {
      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        tipo_doc: (data[i]['tipo_doc']) ? data[i]['tipo_doc'] : null,
        doc: (data[i]['n_doc']) ? data[i]['tipo_doc'] + ' ' + data[i]['n_doc'] : null,
        data: (data[i]['data_emis']) ? this.utils.getDate(data[i]['data_emis']) : null,
        data_pag: (data[i]['data_pag']) ? this.utils.getDate(data[i]['data_pag']) : null,
        proc: (data[i]['descricao']) ? data[i]['descricao'] : null,
        valor_limpeza: this.isLimpezaDespesa? this.utils.cleanDecimalDigits(data[i]['valor_limpeza']) : 0,
        banco: (data[i]['form_pagam'] && data[i]['banco']) ? data[i]['form_pagam'] + ' - ' + data[i]['banco']: null,
        // debito: (data[i]['debito']) ? Math.round(Number(data[i]['debito']) * 100) / 100 : 0,
        // credito: 0,
        credito: (data[i]['debito']) ? Math.round(Number(data[i]['debito']) * 100) / 100 : 0,
        debito: 0,
        saldo: 0,
      }

      // aux['credito'] = (data[i]['credito']) ? Math.round(Number(data[i]['credito']) * 100) / 100 : 0;
      // aux['saldo'] = aux.credito - aux.debito;
      aux['debito'] = (data[i]['credito']) ? Math.round(Number(data[i]['credito']) * 100) / 100 : 0;
      aux['saldo'] = aux.debito - aux.credito;

      // if (this.selTipoListagem === '0' || (this.selTipoListagem === '1' && aux['saldo'] !== 0) || (this.selTipoListagem === '2' && aux['saldo'] === 0)) {

        // TABLE ROW TOTAL AND SEPARATOR
        if (this.prevCondominio !== data[i]['cod_condominio']) {
          if (i > 0) {
            // this.reportList.push({ label: 'Total do condomínio', debito: this.debito_total_condominio, credito: this.credito_total_condominio, saldo: this.saldo_total_condominio, separator: false, separatorCond: false, total: true });
            this.reportList.push({ label: 'Total do condomínio', valor_limpeza: this.valor_limpeza_total_condominio, credito: this.debito_total_condominio, debito: this.credito_total_condominio, saldo: this.saldo_total_condominio, separator: false, separatorCond: false, total: true });
          }

          // ENTITY SEPARATOR
          this.reportList.push({ label: `${data[i]['cod_condominio']} - ${data[i]['nome_condominio']}`, separator: true, separatorCond: false, total: false });
          this.valor_limpeza_total_condominio = 0;
          this.debito_total_condominio = 0;
          this.credito_total_condominio = 0;
          this.saldo_total_condominio = 0;
        }
        this.prevCondominio = data[i]['cod_condominio'];

        this.valor_limpeza_total_condominio += aux.valor_limpeza;
        this.debito_total_condominio += aux.debito;
        this.credito_total_condominio += aux.credito;
        this.saldo_total_condominio += aux.saldo;

        this.reportList.push(aux);
      }

    // }

    if (this.reportList.length > 0 && data.length === 0) {
      // this.reportList.push({ label: 'Total do condomínio', debito: this.debito_total_condominio, credito: this.credito_total_condominio, saldo: this.saldo_total_condominio, separator: false, separatorCond: false, total: true });
      this.reportList.push({ label: 'Total do condomínio', valor_limpeza: this.valor_limpeza_total_condominio, credito: this.debito_total_condominio, debito: this.credito_total_condominio, saldo: this.saldo_total_condominio, separator: false, separatorCond: false, total: true });
    }
  }

  endList = false;
  generateReportListObj(data) {
    data = data.slice(this.offset, this.offset + this.limit);

    // LIST END HANDLER
    if (data.length === 0 && !this.endList) {
      if (this.reportList.length > 0) {
        this.reportList.push({ label: 'Total do condómino', debito: this.debito_total_condominio, credito: this.credito_total_condominio, saldo: this.saldo_total_condominio, separator: false, separatorCond: true, total: true });
        this.reportList.push({ label: 'Total da fracção', debito: this.debito_total, credito: this.credito_total, saldo: this.saldo_total, separator: false, separatorCond: false, total: true });
      }
      this.endList = true;
      return;
    }
    if (this.endList) return;

    this.offset = this.offset + this.limit;

    for (let i = 0; i < data.length; i++) {

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        tipo_doc: (data[i]['tipo_doc']) ? data[i]['tipo_doc'] : null,
        doc: (data[i]['n_doc']) ? data[i]['tipo_doc'] + ' ' + data[i]['n_doc'] : null,
        data: (data[i]['data_emis']) ? this.utils.getDate(data[i]['data_emis']) : null,
        data_venc: (data[i]['data_venc']) ? this.utils.getDate(data[i]['data_venc']) : null,
        proc: (data[i]['descricao']) ? data[i]['descricao'] : null,
        debito: null,
        credito: null,
        saldo: null,
      }
      if (data[i]['tipo_doc'] === 'C') {
        aux['debito'] = 0;
        aux['credito'] = (data[i]['saldo']) ? Math.round(Number(data[i]['saldo']) * 100) / 100 : null;
        aux['saldo'] = aux.debito - aux.credito;
      } else {
        aux['debito'] = (data[i]['debito']) ? Math.round(Number(data[i]['debito']) * 100) / 100 : null;
        aux['saldo'] = (data[i]['saldo']) ? Math.round(Number(data[i]['saldo']) * 100) / 100 : null;
        aux['credito'] = aux.debito - aux.saldo;
      }

      // TABLE ROW TOTAL AND SEPARATOR
      if (((this.prevZona !== data[i]['zona_cod'] || this.prevFrac !== data[i]['cod_fraccao']) && data[i]['tipo_doc'] !== 'C')) {
        if (i > 0) {
          this.reportList.push({ label: 'Total do condómino', debito: this.debito_total_condominio, credito: this.credito_total_condominio, saldo: this.saldo_total_condominio, separator: false, separatorCond: true, total: true });
          this.reportList.push({ label: 'Total da fracção', debito: this.debito_total, credito: this.credito_total, saldo: this.saldo_total, separator: false, separatorCond: false, total: true });
        }

        // ZONA AND FRACCAO SEPARATOR
        this.reportList.push({ label: `${data[i]['zona_nome']} / ${data[i]['cod_fraccao']} - ${data[i]['fraccao_nome']}`, separator: true, separatorCond: false, total: false });

        this.debito_total = 0;
        this.credito_total = 0;
        this.saldo_total = 0;

        // ENTITY SEPARATOR
        this.reportList.push({ label: `${data[i]['condomino_nome']}`, separator: true, separatorCond: true, total: false });

        this.prevCondomino = null;
        this.debito_total_condominio = 0;
        this.credito_total_condominio = 0;
        this.saldo_total_condominio = 0;

      }

      if (data[i]['tipo_doc'] !== 'C') {
        this.prevZona = data[i]['zona_cod'];
      }
      if (data[i]['tipo_doc'] !== 'C') {
        this.prevFrac = data[i]['cod_fraccao'];
      }

      if ((this.prevCondomino && this.prevCondomino !== data[i]['condomino_nome'])) {
        // ENTITY SEPARATOR
        this.reportList.push({ label: 'Total do condómino', debito: this.debito_total_condominio, credito: this.credito_total_condominio, saldo: this.saldo_total_condominio, separator: false, separatorCond: true, total: true });
        this.reportList.push({ label: `${data[i]['condomino_nome']}`, separator: true, separatorCond: true, total: false });

        this.debito_total_condominio = 0;
        this.credito_total_condominio = 0;
        this.saldo_total_condominio = 0;
      }

      this.prevCondomino = data[i]['condomino_nome'];

      this.debito_total += aux.debito;
      this.credito_total += aux.credito;
      this.saldo_total += aux.saldo;

      this.debito_total_condominio += aux.debito;
      this.credito_total_condominio += aux.credito;
      this.saldo_total_condominio += aux.saldo;

      this.reportList.push(aux);
    }

    if (this.reportList.length > 0 && data.length === 0) {
      this.reportList.push({ label: 'Total do condómino', debito: this.debito_total_condominio, credito: this.credito_total_condominio, saldo: this.saldo_total_condominio, separator: false, separatorCond: true, total: true });
      this.reportList.push({ label: 'Total da fracção', debito: this.debito_total, credito: this.credito_total, saldo: this.saldo_total, separator: false, separatorCond: false, total: true });
    }
  }

  totalPDF = {
    valor_limpeza: 0,
    debito: 0,
    credito: 0,
    saldo: 0,
  };
  generateReportListObjPDF(data) {
    let fraccoes = [...new Set(data.map(el => el.cod_fraccao))];
    fraccoes = fraccoes.sort((a, b) => { return (a > b || a['length'] > b['length']) ? 1 : -1; });

    let perFraccaoArr = [];
    let condominos = [];
    let perCondominoArr = [];

    let debito_total_condominio = 0;
    let credito_total_condominio = 0;
    let saldo_total_condominio = 0;

    let debito_total = 0;
    let credito_total = 0;
    let saldo_total = 0;

    this.reportListPDF = [];

    this.totalPDF['valor_limpeza'] = 0;
    this.totalPDF['debito'] = 0;
    this.totalPDF['credito'] = 0;
    this.totalPDF['saldo'] = 0;

    fraccoes.forEach(fraccao => {

      debito_total = 0;
      credito_total = 0;
      saldo_total = 0;

      perFraccaoArr = data.filter(it => it.cod_fraccao === fraccao).sort((a, b) => {
        if (a.data_emis === b.data_emis) {
          return Number(a.n_doc) - Number(b.n_doc);
        } else {
          return this.utils.getDate(a.data_emis).getTime() - this.utils.getDate(b.data_emis).getTime();
        }
      });

      if (perFraccaoArr.length) this.reportListPDF.push({ label: `${perFraccaoArr[0]['zona_nome']} / ${perFraccaoArr[0]['cod_fraccao']} - ${perFraccaoArr[0]['fraccao_nome']}`, separator: true, separatorCond: false, total: false });

      condominos = [...new Set(perFraccaoArr.map(it => it.condomino_cod))]
      condominos.forEach(condomino => {

        debito_total_condominio = 0;
        credito_total_condominio = 0;
        saldo_total_condominio = 0;

        perCondominoArr = perFraccaoArr.filter(it => it.condomino_cod === condomino);

        if (perCondominoArr.length) this.reportListPDF.push({ label: `${perCondominoArr[0]['condomino_nome']}`, separator: true, separatorCond: true, total: false });

        perCondominoArr.forEach(avisoCredito => {

          let aux = {
            checked: false,
            separator: false,
            separatorCond: false,
            total: false,
            tipo_doc: (avisoCredito['tipo_doc']) ? avisoCredito['tipo_doc'] : null,
            doc: (avisoCredito['n_doc']) ? avisoCredito['tipo_doc'] + ' ' + avisoCredito['n_doc'] : null,
            data: (avisoCredito['data_emis']) ? this.utils.getDate(avisoCredito['data_emis']) : null,
            data_venc: (avisoCredito['data_venc']) ? this.utils.getDate(avisoCredito['data_venc']) : null,
            proc: (avisoCredito['descricao']) ? avisoCredito['descricao'] : null,
            debito: null,
            credito: null,
            saldo: null,
          }
          if (avisoCredito['tipo_doc'] === 'C') {
            aux['debito'] = 0;
            aux['credito'] = (avisoCredito['saldo']) ? Math.round(Number(avisoCredito['saldo']) * 100) / 100 : null;
            aux['saldo'] = aux.debito - aux.credito;
          } else {
            aux['debito'] = (avisoCredito['debito']) ? Math.round(Number(avisoCredito['debito']) * 100) / 100 : null;
            aux['saldo'] = (avisoCredito['saldo']) ? Math.round(Number(avisoCredito['saldo']) * 100) / 100 : null;
            aux['credito'] = aux.debito - aux.saldo;
          }

          debito_total_condominio += aux.debito;
          credito_total_condominio += aux.credito;
          saldo_total_condominio += aux.saldo;

          debito_total += aux.debito;
          credito_total += aux.credito;
          saldo_total += aux.saldo;

          this.totalPDF['debito'] += aux.debito;
          this.totalPDF['credito'] += aux.credito;
          this.totalPDF['saldo'] += aux.saldo;

          this.reportListPDF.push(aux);
        });

        if (perCondominoArr.length) this.reportListPDF.push({ label: 'Total do condómino', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true });
      });

      if (perFraccaoArr.length) this.reportListPDF.push({ label: 'Total da fracção', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true });
    });
  }

  onScroll() {
    switch (this.targetEntity) {
      case 'condominio':
        if (this.isDetailed) {
          this.generateReportListObj(this.reportParams['reportData']);
        } else {
          // this.reportList = this.reportParams['reportData'];
        }
        break;
      case 'condomino':
        // this.reportList = this.reportParams['reportData'];
        break;
      case 'fornecedor':
        if (this.isDetailed) {
          this.generateReportByFornecedorListObj(this.reportParams['reportData']);
        } else {
          // this.reportList = this.reportParams['reportData'];
        }
        break;
    }
  }

  isLimpezaDespesa = false;
  generateReportByFornecedorListObjPDF(data) {
    this.reportListPDF = [];

    this.totalPDF['valor_limpeza_total_condominio'] = 0;
    this.totalPDF['debito'] = 0;
    this.totalPDF['credito'] = 0;
    this.totalPDF['saldo'] = 0;

    let valor_limpeza_total_condominio = 0;
    let debito_total_condominio = 0;
    let credito_total_condominio = 0;
    let saldo_total_condominio = 0;

    let prevCondominio = null;

    data.forEach((el, i) => {

      let aux = {
        checked: false,
        separator: false,
        separatorCond: false,
        total: false,
        tipo_doc: (el['tipo_doc']) ? el['tipo_doc'] : null,
        doc: (el['n_doc']) ? el['tipo_doc'] + ' ' + el['n_doc'] : null,
        data: (el['data_emis']) ? this.utils.getDate(el['data_emis']) : null,
        data_pag: (el['data_pag']) ? this.utils.getDate(el['data_pag']) : null,
        proc: (el['descricao']) ? el['descricao'] : null,
        valor_limpeza: this.isLimpezaDespesa && el['valor_limpeza']? this.utils.cleanDecimalDigits(el['valor_limpeza']) : 0,
        banco: (el['form_pagam'] && el['banco']) ? el['form_pagam'] + ' - ' + el['banco']: null,
        debito: (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null,
        credito: null,
        saldo: null,
      }
      aux['credito'] = (el['credito']) ? Math.round(Number(el['credito']) * 100) / 100 : null;
      aux['saldo'] = aux.credito - aux.debito;

      // if (this.selTipoListagem === '0' || (this.selTipoListagem === '1' && aux['saldo'] !== 0) || (this.selTipoListagem === '2' && aux['saldo'] === 0)) {

        // TABLE ROW TOTAL AND SEPARATOR
        if (prevCondominio !== el['cod_condominio']) {
          if (i > 0) {
            this.reportListPDF.push({ label: 'Total do condomínio', valor_limpeza: valor_limpeza_total_condominio, debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: false, total: true });
          }

          // ENTITY SEPARATOR
          this.reportListPDF.push({ label: `${el['cod_condominio']} - ${el['nome_condominio']}`, separator: true, separatorCond: false, total: false });
          debito_total_condominio = 0;
          credito_total_condominio = 0;
          saldo_total_condominio = 0;
        }
        prevCondominio = el['cod_condominio'];

        valor_limpeza_total_condominio += aux.valor_limpeza;
        debito_total_condominio += aux.debito;
        credito_total_condominio += aux.credito;
        saldo_total_condominio += aux.saldo;

        this.reportListPDF.push(aux);

        this.totalPDF['valor_limpeza'] += aux.valor_limpeza;
        this.totalPDF['debito'] += aux.debito;
        this.totalPDF['credito'] += aux.credito;
        this.totalPDF['saldo'] += aux.saldo;
      // }
    });
    if (this.reportListPDF.length > 0) {
      this.reportListPDF.push({ label: 'Total do condomínio', valor_limpeza: valor_limpeza_total_condominio, debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: false, total: true });
    }
  }

}
