import { Component, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { CondominiosService } from '../business-logic-services/condominios.service';
import { IContext } from '../business-model-interfaces/application';
import { EntityType } from '../condominos';
import { UtilitiesService } from '../utilities.service';


export interface SelectEntidadesList {
  nome_fraccao: string, 
  cod_entidade: number, 
  nome_entidade: string, 
  checked: boolean
}

interface SelectEntitiesModalInput {
  entidadesList?:Array<SelectEntidadesList> 
  nSelection?: number
  cod_condominio?: number
}


@Component({
  selector: 'app-select-entities-modal',
  templateUrl: './select-entities-modal.component.html',
  styleUrls: ['./select-entities-modal.component.scss']
})
export class SelectEntitiesModalComponent implements OnInit {

  entidadesList: Array<SelectEntidadesList> = [];
  nSelection = null;
  numEntCondominio = 0;
  
  otherEntity:{ cod: string, nome: string, type: EntityType } = null;
  outrasEntidadesSelected: Array<{ cod: string, nome: string, type: EntityType }> = [];
  outrasEntidadesTimer = null;
  outrasEntidadesLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve({name: this.otherEntity.nome, value: this.otherEntity }) });
    }
    clearTimeout(this.outrasEntidadesTimer);
    return new Promise(resolve => {
      if (query) {
        this.outrasEntidadesTimer = setTimeout(() => {
          this.api.getAllEntities(query, undefined, 1, 0).subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }, 400);
      } else {
        this.api.getAllEntities(null, undefined, 1, 0).subscribe(res => {
          if (res.success) {
            return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
          } else {
            return resolve([]);
          }
        });
      }
    });
  };;

  constructor(
    public modalService: SuiModalService,
    public toastr: ToastrService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public api: ApiService,
    public condominios: CondominiosService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.selectEntitiesAlertConfig = new TemplateModalConfig<IContext, string, string>(this.selectEntitiesAlertRef);
    this.selectEntitiesAlertConfig.isClosable = false;
    this.selectEntitiesAlertConfig.closeResult = "closed";
    this.selectEntitiesAlertConfig.size = 'small';
    this.selectEntitiesAlertConfig.transition = 'fade';
    this.selectEntitiesAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    if (this.selectEntitiesModalRef) this.selectEntitiesModalRef.deny();
  }

  // ENTITIES MODAL
  @ViewChild('selectEntitiesAlertRef', { static: false }) selectEntitiesAlertRef;
  selectEntitiesModalRef = null;
  selectEntitiesAlertConfig: any = null;
  open(input: SelectEntitiesModalInput): Promise<Array<{name:string, cod:number, type:EntityType}>> {
    return new Promise(async (resolve) => {
      this.resetComp();
      if (!input.hasOwnProperty('entidadesList') || !input.entidadesList.length) {
        if (input.hasOwnProperty('cod_condominio') && input.cod_condominio != null) {
          await this.getEntities(input.cod_condominio);
        }
      } else {
        this.entidadesList = input.entidadesList;
      }
      if (input.hasOwnProperty('nSelection')) this.nSelection = input.nSelection;
      
      this.selectEntitiesModalRef = this.modalService
        .open(this.selectEntitiesAlertConfig)
        .onApprove(() => {
          let entidades = this.entidadesList.filter(el => !!el.checked).map(el => {
            return {
              name: el.nome_entidade,
              cod: el.cod_entidade,
              type: ('CONDOMINO' as EntityType)
            }
          }).concat(this.outrasEntidadesSelected.map(el => {
            return {
              cod: parseInt(el.cod),
              name: el.nome,
              type: el.type,
            }
          }))
          resolve(entidades);
        })
        .onDeny(() => {
          resolve(null);
        });
    })
  }

  resetComp() {
    this.entidadesList = [];
    this.nSelection = null;
    this.numEntCondominio = 0;
    this.otherEntity = null;
    this.outrasEntidadesSelected  = [];
    this.outrasEntidadesTimer = null;
  }

  deny() {
    if (this.selectEntitiesModalRef) this.selectEntitiesModalRef.deny();
  }

  getEntities(cod_condominio: number): Promise<boolean> {
    return new Promise(async (resolve) => {
      let entities = await this.condominios.getCondominioProprietarios(cod_condominio, true);
      entities.forEach(reg => {
        this.entidadesList.push({
          nome_fraccao: reg.nome_fracao,
          cod_entidade: parseInt(reg.cod_proprietario),
          nome_entidade: reg.nome,
          checked: false
        })
      })
      resolve(true);
    });
  }

  checkMaxLimit(item: SelectEntidadesList) {
    let selected = this.entidadesList.filter(el => !!el.checked).length + this.outrasEntidadesSelected.length;
    if (this.nSelection === null || selected <= this.nSelection) {
      this.numEntCondominio = this.entidadesList.filter(el => !!el.checked).length;
      let otherSelectedIndex = this.outrasEntidadesSelected.findIndex(el => parseInt(el.cod) == item.cod_entidade);
      if (otherSelectedIndex !== -1) {
        this.deleteOtherEntity(otherSelectedIndex);
      }
      return;
    }
    setTimeout(() => {
      item.checked = false;
      this.numEntCondominio = this.entidadesList.filter(el => !!el.checked).length;
    }, 1);
  }

  approveEntities() {
    let totalSelected = (this.entidadesList.filter(el => !!el.checked).length + this.outrasEntidadesSelected.length);
    if (this.nSelection !== null && totalSelected !== this.nSelection) {
      this.toastr.error('Por favor, selecione ' + (this.nSelection) + ' entidade(s).', 'Ups...!');
      return;
    } else if (totalSelected == 0) {
      this.toastr.error('Por favor, selecione pelo menos uma entidade.', 'Ups...!');
      return;
    }
    this.selectEntitiesModalRef.approve();
  }

  otherEntitySelected() {
    if (this.outrasEntidadesSelected.findIndex(el => el.cod === this.otherEntity.cod) !== -1) {
      this.toastr.info('A entidade já se encontra selecionada.','Informação');
    } else if (this.entidadesList.findIndex(el => el.checked && el.cod_entidade == parseInt(this.otherEntity.cod)) !== -1) {
      this.toastr.info('A entidade já se encontra selecionada no separador do condomínio.','Informação');
    } else {
      this.outrasEntidadesSelected.push(this.otherEntity);
    }
    setTimeout(() => {
      this.otherEntity = null;
    });
  }

  deleteOtherEntity(i: number) {
    this.outrasEntidadesSelected.splice(i, 1);
  }

}
