import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { resolve } from 'url';
import { AnoSemContasAprovadas } from '../api-requests';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { SelAno } from '../business-model-interfaces/assembleias';
import { CondominioEntidadesRegAtividade, CondominioPaymentInfo, GetCondominioTitularBancarioFlatAPI } from '../business-model-interfaces/condominios';
import { FraccaoLight, registoProprietario } from '../business-model-interfaces/fraccoes';
import { RegistoAtividade } from '../business-model-interfaces/registo-atividade';
import { UtilitiesService } from '../utilities.service';
import { FraccoesService } from './fraccoes.service';

@Injectable({
  providedIn: 'root'
})
export class CondominiosService {

  constructor(
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public api: ApiService,
    public fraccoesService: FraccoesService,
    public toastr: ToastrService,
  ) { }


  /**
   * Retorna a data de reconciliação do condomínio se não tiver banco. Se não tiver conta principal retorna a data atual, se tiver, retorna a data de reconciliação da respetiva conta.
   * @param cod_condominio 
   * @returns 
   */
  getReconciliationDate(cod_condominio): Promise<Date> {
    return new Promise((resolve) => {
      this.api.getCondominioReconciliationDate(cod_condominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(this.utils.getDate(res.data, undefined, undefined, true));
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  getFraccoesLight(cod_condominio): Promise<Array<FraccaoLight>> {
    return new Promise((resolve) => {
      this.api.getCondFraccoesLight(cod_condominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve([null]);
      });
    })
  }

  getCondZonasDetails(cod_condominio): Promise<Array<any>> {
    return new Promise((resolve) => {
      this.api.getCondZonasDetails(cod_condominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve([]);
      });
    })
  }

  getPaymentInfo(cod_condominio): Promise<CondominioPaymentInfo> {
    return new Promise((resolve) => {
      this.api.getCondominioPaymentInfo(cod_condominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  getCondominioProprietarios(cod_condominio, onlyAtual=false): Promise<Array<registoProprietario>> {
    return new Promise((resolve) => {
      this.api.getCondominioProprietarios(cod_condominio, onlyAtual).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data.map(el => this.fraccoesService.convertRegistoProprietarioType(el)));
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  getCondContasDetails(cod_condominio): Promise<Array<any>> {
    return new Promise((resolve) => {
      this.api.getCondContasDetails(cod_condominio).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  // ---------------- CONDOMINIOS ENTIDADES ----------------
  getCondEntidadesRegAtividade(cod_condominio): Promise<Array<CondominioEntidadesRegAtividade>> {
    return new Promise((resolve) => {
      this.api.getCondEntidadesRegAtividade(cod_condominio).subscribe(res => {
        if (res.success) {
          resolve(res.data.map(atBD => {
            let obj = atBD.obj ? JSON.parse(atBD.obj) : null
            return {
              ...atBD,
              obj: obj
            }
          }));
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  hasTitularesPorEfetivar(cod_condominio, cod_conta=null): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.condominioHasTitularesPorEfetivar(cod_condominio, cod_conta).subscribe(res => {
        if (res.success) {
          resolve(res.data === 1);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  getTitularesBancariosByConta(cod_conta): Promise<GetCondominioTitularBancarioFlatAPI['data']> {
    return new Promise((resolve) => {
      this.api.getTitularesBancariosByConta(cod_conta).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  getAssembleiaAvailableYears(cod_condominios: Array<string>): Promise<{apresentacaoContas: { cod_condominio: number, data: AnoSemContasAprovadas[]}[], orcamentosAprovados: { cod_condominio: number, data: number[]}[]}> {
    return new Promise((resolve) => {
      this.api.getAssembleiaAvailableYears(cod_condominios).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }
  
  // ------------------------------------------------------------
}
