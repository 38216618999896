import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { Credito } from '../business-model-interfaces/creditos';
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})
export class CreditosService {

  constructor(
    public api: ApiService,
    public toastr: ToastrService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
  ) { }


  saveCredito(id, n_credito, cod_condominio, cod_fraccao, cod_pagador, credito, tipo_doc, n_doc, descricao, dt_valor, data, banco, conta_bancaria, valor, cod_conta_bancaria, prevValor, nome_condomino, prevCodContaBancaria): Promise<Credito> {
    return new Promise((resolve) => {
      this.api.saveCredito(id, n_credito, cod_condominio, cod_fraccao, cod_pagador, credito, tipo_doc, n_doc, descricao, dt_valor, data, banco, conta_bancaria, valor, cod_conta_bancaria, prevValor, nome_condomino, prevCodContaBancaria).subscribe(res => {
        if (res.hasOwnProperty('success') && !!res.data) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
        }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }
}
