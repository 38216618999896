import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNull'
})
export class IsNullPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return (value) ? value : '--';
  }

}
