import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { AppConfigService } from '../app-config.service';
import { CondominiosService } from '../business-logic-services/condominios.service';
import { ProcessamentosService } from '../business-logic-services/processamentos.service';
import { ProcessamentoDetails, ProcessamentoDetailsToPrint } from '../business-model-interfaces/processamentos';
import { UtilitiesService } from '../utilities.service';

export interface ProcessamentoPDFInput {
  title: string,
  nif: string,
  nome_condominio: string,
  descricao: string,
  start_date: Date,
  end_date: Date,
  tipo_reparticao: 'UNICO' | 'REPARTIDO',
  now: Date,
  fraccoesListPDF: Array<{
    zona_nome: string,
    fraccao_nome: string,
    condomino_nome: string,
    permilagem: number,
    valorPrestacao: number,
    valor: number
  }>
  nPrestacoes: number,
  total: number,
  totalPrestacao: number,
  ajuste: number,
}

export interface ProcessamentoPDF extends ProcessamentoPDFInput {
}

@Component({
  selector: 'app-processamento-pdf',
  templateUrl: './processamento-pdf.component.html',
  styleUrls: ['./processamento-pdf.component.scss']
})
export class ProcessamentoPdfComponent implements OnInit {

  data: ProcessamentoPDF = {
    title: null,
    nif: null,
    nome_condominio: null,
    descricao: null,
    start_date: null,
    end_date: null,
    tipo_reparticao: null,
    now: null,
    fraccoesListPDF: [],
    total: null,
    totalPrestacao: null,
    ajuste: null,
    nPrestacoes: null,
  }

  constructor(
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public processamentos: ProcessamentosService,
    public condominios: CondominiosService,
    public cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }


  @ViewChild('pdf', { static: false }) pdfController;
  exportPDF(input: ProcessamentoPDFInput, idProcessamentoToFetch = null): Promise<boolean> {
    return new Promise(async (resolve) => {
      let success = await this.prepareComponentToExport(input, idProcessamentoToFetch);
      if (!success) {
        resolve(false);
        return;
      }

      this.cdRef.detectChanges();

      let filename = this.data.nome_condominio.replace(/ /g, '_') + '_' + '_' + this.utils.getFormatedDate(this.data.now);
      filename = filename.replace(/,/g, '');

      setTimeout(() => {
        this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
        this.pdfController.forceProxy = true;
        this.pdfController.proxyTarget = '_blank';
        this.pdfController.saveAs(filename + '.pdf');
        resolve(true);
      });
    })
  }

  getBase64(input: ProcessamentoPDFInput, idProcessamentoToFetch = null): Promise<string> {
    return new Promise(async (resolve) => {
      let success = await this.prepareComponentToExport(input, idProcessamentoToFetch);
      if (!success) {
        resolve(null);
        return;
      }

      this.cdRef.detectChanges();
      
      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');
        resolve(base64);
      }).catch(err => {
        resolve(null);
      });

    });
  }

  prepareComponentToExport(input: ProcessamentoPDFInput, idProcessamentoToFetch = null): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (idProcessamentoToFetch != null) {
        try {
          let success = await this.getData(idProcessamentoToFetch);
          if (!success) {
            resolve(false);
            return;
          }
        } catch (err) {
          resolve(false);
          return;
        }
      } else {
        this.data = {
          ...input,
        };
      }

      this.data.descricao = this.processamentos.cleanDescricaoFromAtaInfo(this.data.descricao);

      this.data.now = new Date();
      resolve(true);
    });
  }
  

  getData(id_processamento): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.processamentos.getProcessamentoToPrint(id_processamento).then(processamento => {
        this.data = {
          title: processamento.cod_condominio + ' - ' + processamento.nome_condominio,
          nif: processamento.n_contribuinte,
          nome_condominio: processamento.nome_condominio,
          descricao: processamento.descricao,
          start_date: this.utils.getDate(processamento.data),
          end_date: this.utils.getDate(processamento.end_date),
          tipo_reparticao: processamento.tipo_reparticao,
          now: new Date(),
          fraccoesListPDF: processamento.fraccoes.map(el => {
            return {
              zona_nome: el.zona_nome,
              fraccao_nome: el.fraccao_nome,
              condomino_nome: el.condomino_nome,
              permilagem: parseFloat(el.permilagem),
              valorPrestacao: parseFloat(el.valorPrestacao),
              valor: parseFloat(el.valorTotal),
            }
          }),
          nPrestacoes: processamento.n_prestacoes,
          ajuste: processamento.ajuste,
          total: processamento.total,
          totalPrestacao: processamento.totalPrestacao,
        };
        resolve(true);
      }).catch(err => {
        resolve(false);
        return;
      });
    });
  }



  capitalizeFirst(str: string): string {
    return str && str.length > 1 ? str.charAt(0).toUpperCase() + str.substring(1) : str;
  }

}
