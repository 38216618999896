import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { Orcamento } from '../business-model-interfaces/orcamentos';
import { UtilitiesService } from '../utilities.service';


export interface OrcamentoPDF {

  orcamento: Orcamento & {
    nome_condominio:string,
  },
  report: {
    title:string,
    reportType:string,
    exercicio:string,
    descricao:string,
    startDate:Date,
    endDate:Date,
    now: Date,
  },
  targetReport:'rubricas-orcamento'|'listagem-quotas'|'all',
  rubricasOrcListToPrint:Array<{
    separator?,
    subTotal?,
    label?:string,
    zona_nome?:string,
    valor?:number,
    rubrica_nome?:string,
  }>,
  rubricasOrcTotais: {
    valor:number,
  },
  zonasOrcListToPrint:Array<{
    zona_nome:string,
    orc:number,
    fr:number,
    seg:number,
    quota:number,
  }>,
  zonasOrcTotais: {
    orc:number,
    fr:number,
    seg:number,
    quota:number,
  },
  simulacaoListToPrint:Array<{
    separator?,
    subTotal?,
    label?:string,
    perm?:number,
    orc?:number,
    fr?:number,
    seg?:number,
    quota?:number,
    cod?:string,
    nome?:string,
    permilagem?:number,
    quota_orc?:number,
    quota_fr?:number,
    quota_seg?:number,
    total?:number,
  }>,
  simulacaoTotaisPDF:{
    permilagem:number,
    orcamento:number,
    fundoReserva:number,
    seguro:number,
    total:number,
  },
  cobrancaFR: Array<any>,
  cobrancaOrcamento: Array<any>,
  zonas:Array<{cod, nome, permilagem}>,
  orcFraccoesSeg: Array<{
    cod_fraccao
    paga_seg_colect,
    permilagem
    quota_seg_anual
  }>,
  fraccoes:Array<any>,
  fraccoesListCol: Array<any>,
  orcFraccoesFR:Array<any>,
  rubricasList: Array<any>,
  orcFraccoes: Array<any>,
  orcRubricas:Array<any>,
  rubricasListCol:Array<any>,
  rubricasTotais: {total},
  orcamentoDataTable:Array<any>,
  orcamentoTotais:{
    permilagem:number,
    orc_valor:number,
    fr_valor:number,
    valor:number,
  },
  selectedTotalFR:number
  simulacaoList:Array<any>,
  simulacaoTotais: {
    permilagem: number,
    fundoReserva: number,
    orcamento: number,
    seguro: number,
    total: number,
  }
}



@Component({
  selector: 'app-orcamento-pdf',
  templateUrl: './orcamento-pdf.component.html',
  styleUrls: ['./orcamento-pdf.component.scss']
})
export class OrcamentoPdfComponent implements OnInit {

  reportData:OrcamentoPDF = {
    orcamento: {
      id: null,
      cod: null,
      cod_condominio: null,
      id_act_seg: null,
      descricao: null,
      periodo: null,
      vap_prop: null,
      val_equip: null,
      val_fr_prop: null,
      val_fr_equit: null,
      dt_inicio: null,
      dt_fim: null,
      val_lancado: null,
      meses_orc: null,
      meses_fr: null,
      data_lancamento: null,
      user_lancamento: null,
      active: null,
      nome_condominio:null,
    },
    report: {
      title: null,
      reportType: null,
      exercicio: null,
      descricao: null,
      startDate: null,
      endDate: null,
      now: new Date(),
    },
    targetReport:'all',
    rubricasOrcListToPrint:[],
    rubricasOrcTotais: {
      valor: 0,
    },
    zonasOrcListToPrint: [],
    zonasOrcTotais: {
      orc:0,
      fr:0,
      seg:0,
      quota:0,
    },
    simulacaoListToPrint: [],
    simulacaoTotaisPDF: {
      permilagem:0,
      orcamento:0,
      fundoReserva:0,
      seguro:0,
      total:0,
    },
    cobrancaFR: [],
    cobrancaOrcamento: [],
    zonas: [],
    orcFraccoesSeg: [],
    fraccoes: [],
    fraccoesListCol: [],
    orcFraccoesFR: [],
    rubricasList: [],
    orcFraccoes: [],
    orcRubricas: [],
    rubricasListCol: [],
    rubricasTotais: {
      total: 0
    },
    orcamentoDataTable: [],
    orcamentoTotais:{
      permilagem:0,
      orc_valor:0,
      fr_valor:0,
      valor:0,
    },
    selectedTotalFR:0,
    simulacaoList: [],
    simulacaoTotais: {
      permilagem: 0,
      fundoReserva: 0,
      orcamento: 0,
      seguro: 0,
      total: 0,
    }
  }
  

  segMonths = [{ month: 'janeiro', active: false },{ month: 'fevereiro', active: false },{ month: 'marco', active: false },{ month: 'abril', active: false },{ month: 'maio', active: false },{ month: 'junho', active: false }, { month: 'julho', active: false }, { month: 'agosto', active: false },{ month: 'setembro', active: false },{ month: 'outubro', active: false },{ month: 'novembro', active: false },{ month: 'dezembro', active: false }];



  @ViewChild('pdf', { static: false }) pdfController:PDFExportComponent;


  constructor(public appConfig: AppConfigService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public cdRef: ChangeDetectorRef,
              public api: ApiService,
              ) { }

  ngOnInit() {
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  async generatePDF(reportData:OrcamentoPDF=null, targetReport=null,orcamentoIdToFetch=null) {
    return new Promise(async (resolve) => {
      let res = await this.updateData(reportData, targetReport, orcamentoIdToFetch);
      if (!res) {
        resolve(false);
        return;
      }
      
      this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
      this.pdfController.forceProxy = true;
      this.pdfController.proxyTarget = '_blank';
      
      let filename = this.reportData.report.title.replace(/ /g, '_') + '_' + this.utils.getFormatedDate(this.reportData.report.startDate) + '_' + this.utils.getFormatedDate(this.reportData.report.endDate);
      filename = filename.replace(/,/g, '');
  
      this.pdfController.saveAs(filename + '_Orçamento_' + this.reportData.orcamento.descricao + '.pdf');
      resolve(true);
    });
  }

  updateData(reportData:OrcamentoPDF, targetReport, orcamentoIdToFetch): Promise<boolean> {
    return new Promise(async (resolve) => {

      if (!reportData && !orcamentoIdToFetch) {
        resolve(false);
        return;
      }

      if (reportData) {
        this.reportData = reportData;
      }
  
      if (orcamentoIdToFetch) {
        this.initReportData();
        if (targetReport) {
          this.reportData.targetReport = targetReport;
        }
        let res = await this.getOrcamentoData(orcamentoIdToFetch);
        if (!res) {
          resolve(false);
          return;
        }
        this.reportData.report = {
          title: this.reportData.orcamento.cod_condominio + ' - ' + this.reportData.orcamento.nome_condominio,
          reportType: null,
          exercicio: this.reportData.orcamento.periodo,
          descricao: this.reportData.orcamento.descricao,
          startDate: this.utils.getDate(this.reportData.orcamento.dt_inicio),
          endDate: this.utils.getDate(this.reportData.orcamento.dt_fim),
          now: new Date(),
        }
      }
      
  
      switch (this.reportData.targetReport) {
        case 'all':
          this.reportData.report.reportType = 'Orçamento - Rubricas e Listagem de Quotas';
          break;
        case 'listagem-quotas':
          this.reportData.report.reportType = 'Orçamento - Listagem de Quotas';
          break;
        case 'rubricas-orcamento':
          this.reportData.report.reportType = 'Orçamento - Rubricas';
          break;
      
        default:
          this.toastr.error('Não foi possível gerar o relatório, por favor, tente mais tarde.','Ups...!');
          return;
      }
  
      this.cdRef.detectChanges();
      resolve(true);
    })
  }

  async exportPDF(reportData:OrcamentoPDF, targetReport, orcamentoIdToFetch): Promise<any> {
    let res = await this.updateData(reportData, targetReport, orcamentoIdToFetch);
    if (!res) return;
    return this.pdfController.export();
  }

  initReportData() {
    this.reportData = {
      orcamento: {
        id: null,
        cod: null,
        cod_condominio: null,
        id_act_seg: null,
        descricao: null,
        periodo: null,
        vap_prop: null,
        val_equip: null,
        val_fr_prop: null,
        val_fr_equit: null,
        dt_inicio: null,
        dt_fim: null,
        val_lancado: null,
        meses_orc: null,
        meses_fr: null,
        data_lancamento: null,
        user_lancamento: null,
        active: null,
        nome_condominio:null,
      },
      report: {
        title: null,
        reportType: null,
        exercicio: null,
        descricao: null,
        startDate: null,
        endDate: null,
        now: new Date(),
      },
      targetReport:'all',
      rubricasOrcListToPrint:[],
      rubricasOrcTotais: {
        valor: 0,
      },
      zonasOrcListToPrint: [],
      zonasOrcTotais: {
        orc:0,
        fr:0,
        seg:0,
        quota:0,
      },
      simulacaoListToPrint: [],
      simulacaoTotaisPDF: {
        permilagem:0,
        orcamento:0,
        fundoReserva:0,
        seguro:0,
        total:0,
      },
      cobrancaFR: [],
      cobrancaOrcamento: [],
      zonas: [],
      orcFraccoesSeg: [],
      fraccoes: [],
      fraccoesListCol: [],
      orcFraccoesFR: [],
      rubricasList: [],
      orcFraccoes: [],
      orcRubricas: [],
      rubricasListCol: [],
      rubricasTotais: {
        total: 0
      },
      orcamentoDataTable: [],
      orcamentoTotais:{
        permilagem:0,
        orc_valor:0,
        fr_valor:0,
        valor:0,
      },
      selectedTotalFR:0,
      simulacaoList: [],
      simulacaoTotais: {
        permilagem: 0,
        fundoReserva: 0,
        orcamento: 0,
        seguro: 0,
        total: 0,
      }
    };
  }

  getActSegOrc(id_act_seg, fraccoes_fr) {
    return new Promise((resolve) => {
      this.api.getActSegORC(id_act_seg).subscribe(async seg => {
        if (seg.hasOwnProperty('success') && seg.success) {

          let premioSeg = Number(seg.data.premio);
          let reparticaoSeg = seg.data.pagamento;
          this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });

          let premilagemSeg = 0;
          this.reportData.orcFraccoesSeg = [];
          fraccoes_fr.forEach(el => {
            if (el.paga_seg_colect === '1') premilagemSeg = premilagemSeg + Number(el.permilagem);

            this.reportData.orcFraccoesSeg.push({
              cod_fraccao: el.cod,
              paga_seg_colect: el.paga_seg_colect,
              permilagem: Number(el.permilagem),
              quota_seg_anual: 0,
            })
          });

          if (reparticaoSeg === 'P') {
            this.reportData.orcFraccoesSeg.forEach(el => {
              if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round(((premioSeg * el.permilagem) / premilagemSeg) * 100) / 100;
            });
          }

          if (reparticaoSeg === 'E') {
            let nFraccoes = this.reportData.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
            this.reportData.orcFraccoesSeg.forEach(el => {
              if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round((premioSeg / nFraccoes) * 100) / 100;
            });
          }
          
        }
        resolve(true);
      }, err => {resolve(false);});
    });
  }
  getActSegCond(cod_condominio, fraccoes_fr): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.getActSegCond(cod_condominio).subscribe(async seg => {
        if (seg.hasOwnProperty('success') && seg.success) {
    
          let premioSeg = Number(seg.data.premio);
          let reparticaoSeg = seg.data.pagamento;
          this.segMonths.forEach(el => { el.active = (seg.data[el.month] === '1'); });
    
          let premilagemSeg = 0;
          this.reportData.orcFraccoesSeg = [];
          fraccoes_fr.forEach(el => {
            if (el.paga_seg_colect === '1') premilagemSeg = premilagemSeg + Number(el.permilagem);
    
            this.reportData.orcFraccoesSeg.push({
              cod_fraccao: el.cod,
              paga_seg_colect: el.paga_seg_colect,
              permilagem: Number(el.permilagem),
              quota_seg_anual: 0,
            })
          });
    
          if (reparticaoSeg === 'P') {
            this.reportData.orcFraccoesSeg.forEach(el => {
              if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round(((premioSeg * el.permilagem) / premilagemSeg) * 100) / 100;
            });
          }
    
          if (reparticaoSeg === 'E') {
            let nFraccoes = this.reportData.orcFraccoesSeg.filter(el => (el.paga_seg_colect === '1')).length;
            this.reportData.orcFraccoesSeg.forEach(el => {
              if (el.paga_seg_colect === '1') el.quota_seg_anual = Math.round((premioSeg / nFraccoes) * 100) / 100;
            });
          }
        }
        resolve(true);
      }, err => {resolve(false);});
    })
  }

  getOrcamentoData(orcamentoIdToFetch): Promise<boolean> {
    return new Promise((resolve) => {

      this.api.getOrcamentoDetailsREVIEW(orcamentoIdToFetch).subscribe(async res => {
        if (res.hasOwnProperty('success') && res.success) {
          
          this.reportData.orcamento = res.data.orcamento[0];
          
        // COMPUTE QUOTA SEGURO --------------------------------
          if (this.reportData.orcFraccoesSeg.length === 0) {
              if (res.data.orcamento[0].id_act_seg) {
                await this.getActSegOrc(res.data.orcamento[0].id_act_seg, res.data.fraccoes_fr);
                await this.handleOrcDetailsObjFromApi(res);
                
              } else {
                if (res.data.orcamento[0].val_lancado === '0') {
                  await this.getActSegCond(res.data.orcamento[0].cod_condominio, res.data.fraccoes_fr);
                  await this.handleOrcDetailsObjFromApi(res);
                  
                } else {
                  await this.handleOrcDetailsObjFromApi(res);
                }
              }
          } else {
            await this.handleOrcDetailsObjFromApi(res);
          }

          if (this.reportData.targetReport === 'listagem-quotas' || this.reportData.targetReport === 'all') {
            this.generateQuotasList();
          }
      
          if (this.reportData.targetReport === 'rubricas-orcamento' || this.reportData.targetReport === 'all') {
            this.generateRubricasOrcList();
          }

          this.reportData.report.title = this.reportData.orcamento.cod_condominio + ' - ' + this.reportData.orcamento.nome_condominio;
          this.reportData.report.startDate = this.utils.getDate(this.reportData.orcamento.dt_inicio);
          this.reportData.report.endDate = this.utils.getDate(this.reportData.orcamento.dt_fim);
          this.reportData.report.exercicio = this.reportData.orcamento.periodo;
        } else {
          this.utils.apiErrorMsg(res);
        }
        resolve(true);
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });

    });
  }

  async handleOrcDetailsObjFromApi(res) {

    if (res.data.zonas.length === 0) {
      await this.setInitRubricasList();
    } else {
      // this.reportData.orcZonas = res.data.zonas;
      this.reportData.zonas = res.data.zonas;
    }

    if (this.reportData.fraccoes.length === 0) {
      await this.setInitFraccoesList(res.data.orcamento[0].cod_condominio);
    }

    this.reportData.orcFraccoes = res.data.fraccoes;
    this.reportData.orcFraccoesFR = res.data.fraccoes_fr;

    this.reportData.orcRubricas = res.data.rubricas;

    // RUBRICA TABLE SECTION
    this.reportData.rubricasListCol = [];
    this.reportData.zonas.forEach(el => {
      this.reportData.rubricasListCol.push({
        key: 'null',
        name: el.nome,
        type: 'number',
        sort: null,
        searchable: false,
        data: el
      });
    });

    let auxRubricas = [];
    res.data.rubricas.forEach(el => {
      if (!auxRubricas.find(it => el.rub_cod === it.rub_cod)) auxRubricas.push(el); 
    });

    this.reportData.rubricasList = [];
    auxRubricas.forEach(el => {
      let newRubrica = {
        id: (el.hasOwnProperty('id') && el.id) ? el.id : null,
        cod: el.cod,
        nome: el.rub_nome,
        reparticao: (el.reparticao === 'P' || el.reparticao === 'E') ? el.reparticao : 'M',
        tipo: (el.tipo) ? el.tipo : null,
        cod_rubrica: el.cod_rubrica,
        valor: 0,
        checked: false,
        centered: true,
        total: 0,

        despesa: (el.despesa === '1'),
        receita: (el.receita === '1'),
      };

      this.reportData.zonas.forEach((el, i) => {
        newRubrica['valor-zona-' + i] = 0;
        newRubrica['cod-zona-' + i] = el.cod;
        newRubrica['perm-zona-' + i] = el.permilagem;
      });

      let aux = res.data.rubricas.filter(it => it.rub_cod === el.rub_cod);
      aux.forEach(it => {
        if (it) {
          for (let i = 0; i < this.reportData.zonas.length ; i++) {
            if (it.cod_zona === newRubrica['cod-zona-' + i]) {
              newRubrica['valor-zona-' + i] = Number(it.valor);
              break;
            }
          }
          newRubrica.valor += Number(it.valor);
        }
      });

      this.reportData.rubricasList.push(newRubrica);
    });

    this.computeRubricasTotal();

    // ORCAMENTO TABLE SECTION ----------------------------------------------
    if (Array.isArray(res.data.orcamento) && res.data.orcamento.length > 0) {

      // ALWAYS USE THE FIRST ZONE PERIODICITY (TODO - ADD THIS TO ORCAMENTO)
      if (res.data.zonas.length > 0) {
        this.reportData.cobrancaOrcamento = Array.prototype.map.call(res.data.zonas[0].meses_orc, (el) => { return { sel: (el === 'S') } });
        this.reportData.cobrancaFR = Array.prototype.map.call(res.data.zonas[0].meses_fr, (el) => { return { sel: (el === 'S') } });
      }

      this.getOrcamentoDatatable(res.data.zonas, res.data.fraccoes_fr);
    }
  }

  computeRubricasTotal() {
    this.reportData.rubricasTotais.total = 0;
    this.reportData.rubricasListCol.filter(el => (el.data !== null)).forEach((it, i) => {
      this.reportData.rubricasTotais['total-zona-' + i] = 0;
      this.reportData.rubricasList.forEach(el => {
        this.reportData.rubricasTotais['total-zona-' + i] += Number(el['valor-zona-' + i]);
      });
      this.reportData.rubricasTotais.total += Number(this.reportData.rubricasTotais['total-zona-' + i]);
    });
  }

  getOrcamentoDatatable(zonas, fraccoes_fr) {
    // SET ORCAMENTO DATA TABLE
    this.reportData.orcamentoDataTable = [];

    zonas.forEach((zona, i) => {
      // GET REPARTICAO INDICATION STRING
      let seenRep = '';
      this.reportData.rubricasList.forEach(rubrica => {
        if (seenRep.indexOf(rubrica.reparticao) === -1 && rubrica['valor-zona-' + i] !== 0) {
          // ADD RUBRICA TO SEEN STRING
          seenRep += (rubrica.reparticao + '/');
        }
      });

      if (seenRep) seenRep = seenRep.slice(0, -1);

      // HANDLE ORCAMENTO TABLE
      let auxObj = {
        cod: zona.cod,
        nome: zona.nome,
        perm: Number(zona.permilagem),
        fr_reparticao: zona.pagamento_fr,
        fr_valor: 0,
        orc_reparticao: seenRep,
        orc_valor: 0,
        valor: null,
      };

      // fraccoes.filter(el => (el.cod_zona === zona.cod)).forEach(fraccao => {
      //   auxObj.orc_valor += Number(fraccao.valor);
      // });

      auxObj.orc_valor += this.reportData.rubricasTotais['total-zona-' + i];

      fraccoes_fr.filter(el => (el.cod_zona === zona.cod)).forEach(fraccao => {
        auxObj.fr_valor += Number(fraccao.valor);
      });

      auxObj.valor = auxObj.orc_valor + auxObj.fr_valor;

      this.reportData.orcamentoDataTable.push(auxObj);
    });

    this.computeOrcamentoTotal();

    this.getSimulacaoDatatable(fraccoes_fr);
  }

  computeOrcamentoTotal() {
    this.reportData.orcamentoTotais.permilagem = 0;
    this.reportData.orcamentoTotais.orc_valor = 0;
    this.reportData.orcamentoTotais.fr_valor = 0;
    this.reportData.orcamentoTotais.valor = 0;

    this.reportData.orcamentoDataTable.forEach(el => {
      this.reportData.orcamentoTotais.permilagem += Number(el.perm);
      this.reportData.orcamentoTotais.orc_valor += Number(el.orc_valor);
      this.reportData.orcamentoTotais.fr_valor += Number(el.fr_valor);
      this.reportData.orcamentoTotais.valor += Number(el.valor);
    });
    let FCR = Math.round((((this.reportData.orcamentoTotais.valor / this.reportData.orcamentoTotais.orc_valor) - 1) * 100) * 100) / 100;

    this.reportData.selectedTotalFR = FCR;
  }

  getSimulacaoDatatable(fraccoes_fr) {
    this.reportData.simulacaoList = this.getSimObject(fraccoes_fr);

    this.computeSimulacaoTotal();
  }

  getSimObject(fraccoes_fr) {
    let simulation = [];
    let seenRubricas = '';
    this.reportData.orcFraccoes.sort((a, b) => { if ( a.cod !== null  && b.cod !== null && (a.cod > b.cod || a.cod.length > b.cod.length) ) { return 1; } else { return -1; } }).forEach(fraccao => {


      if (seenRubricas.indexOf(fraccao.cod) === -1) {
        seenRubricas += fraccao.cod + '/';

        let fraccaoFR = fraccoes_fr.find(el => (el.cod_fraccao === fraccao.cod_fraccao));

        let zona = this.reportData.zonas.find(el => (el.cod === fraccao.cod_zona));

        simulation.push({
          id: fraccao.id,
          fraccao_id: fraccao.fraccao_id,
          cod: fraccao.cod,
          cod_zona: fraccao.cod_zona,
          cod_proprietario: fraccao.cod_proprietario,
          nome: fraccao.nome,
          zona_nome: (zona) ? zona.nome : null,
          permilagem: Number(fraccao.permilagem),

          quota_fr: (fraccaoFR) ? Number(fraccaoFR['valor']) : 0,
          quota_orc: Number(fraccao.valor),
          quota_seg: 0,
        });
      } else {
        let index = simulation.findIndex(it => (it.fraccao_id === fraccao.fraccao_id));
        if (index >= 0) {
          simulation[index].quota_orc += Number(fraccao.valor);
          simulation[index].total += Number(fraccao.valor);
        }
      }
    });

    simulation.forEach(el => {
      el.quota_seg = (this.reportData.orcFraccoesSeg.find(it => (it.cod_fraccao === el.cod && it.paga_seg_colect === '1'))) ? this.reportData.orcFraccoesSeg.find(it => (it.cod_fraccao === el.cod && it.paga_seg_colect === '1')).quota_seg_anual : 0;
      el.quota_orc = this.utils.cleanDecimalDigits(el.quota_orc);
      el.quota_fr = this.utils.cleanDecimalDigits(el.quota_fr);
      el.quota_seg = this.utils.cleanDecimalDigits(el.quota_seg);
      el.total = el.quota_orc + el.quota_fr + el.quota_seg;

      el.quota_fr_anual = el.quota_fr;
      el.quota_orc_anual = el.quota_orc;
      el.quota_seg_anual = el.quota_seg;
    });

    return simulation.sort((a, b) => {
      if ( a.cod !== null && b.cod !== null && (a.cod > b.cod || a.cod.length > b.cod.length) ) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  computeSimulacaoTotal() {
    this.reportData.simulacaoTotais.permilagem = 0;
    this.reportData.simulacaoTotais.orcamento = 0;
    this.reportData.simulacaoTotais.fundoReserva = 0;
    this.reportData.simulacaoTotais.seguro = 0;
    this.reportData.simulacaoTotais.total = 0;

    this.reportData.simulacaoList.forEach(fraccao => {
      this.reportData.simulacaoTotais.permilagem += fraccao.permilagem;
      this.reportData.simulacaoTotais.fundoReserva += fraccao.quota_fr;
      this.reportData.simulacaoTotais.orcamento += fraccao.quota_orc;
      this.reportData.simulacaoTotais.seguro += fraccao.quota_seg;
      this.reportData.simulacaoTotais.total += fraccao.total;
    });
  }

  async setInitRubricasList():Promise<boolean> {
    return new Promise((resolve, reject) => {
  
      this.api.getCondZonasDetails(this.reportData.orcamento.cod_condominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          // CREATE RUBRICAS TABLE COLUMN OBJECT
          this.reportData.zonas = res.data.sort((a, b) => {
            if (Number(a.permilagem) < Number(b.permilagem)) {
              return 1;
            } else {
              return -1;
            }
          });
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
  
    });
  }

  async setInitFraccoesList(codCondominio) {
    return new Promise((resolve, reject) => {
      this.api.getOrcCondFraccoesDetails(codCondominio).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.reportData.fraccoes = res.data;
  
          this.reportData.fraccoesListCol = [];
          this.reportData.fraccoes.forEach(el => {
            this.reportData.fraccoesListCol.push({
              key: 'null',
              name: el.nome,
              type: 'number',
              sort: null,
              searchable: false,
              data: el
            });
          });

          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });

    });
  }

  generateQuotasList() {
    let nMesesORC = this.reportData.cobrancaOrcamento.filter(el => (el.sel)).length;

    this.reportData.simulacaoListToPrint = [];

    let prevZona = null;
    let valor_total_zona_perm = 0;
    let valor_total_zona_orc = 0; 
    let valor_total_zona_fr = 0; 
    let valor_total_zona_seg = 0; 
    let valor_total_zona_total = 0;

    this.reportData.simulacaoTotaisPDF = {
      permilagem: 0,
      orcamento: 0,
      fundoReserva: 0,
      seguro: 0,
      total: 0
    }

   

    let simulacaoList = JSON.parse(JSON.stringify(this.reportData.simulacaoList));
    simulacaoList.sort((a, b) => {
      if (a.cod_zona < b.cod_zona) {
        return -1;
      } else {
        return 1;
      }
    });
    simulacaoList.forEach((el, i) => {
      el['separator'] = false;
      el['subTotal'] = false;

      // TABLE ROW TOTAL AND SEPARATOR
      if (prevZona !== el['cod_zona']) {
        if (i > 0) {
          this.reportData.simulacaoListToPrint.push({ label: 'Subtotal', perm: valor_total_zona_perm, orc: valor_total_zona_orc, fr: valor_total_zona_fr, seg: valor_total_zona_seg, quota: valor_total_zona_total, separator: false, subTotal: true });
        }
        this.reportData.simulacaoListToPrint.push({ label: el.zona_nome, separator: true, subTotal: false });
        valor_total_zona_perm = 0;
        valor_total_zona_orc = 0; 
        valor_total_zona_fr = 0; 
        valor_total_zona_seg = 0; 
        valor_total_zona_total = 0;
      }
      prevZona = el['cod_zona'];
      valor_total_zona_perm += el.permilagem;
      valor_total_zona_orc += el.quota_orc;
      valor_total_zona_fr += el.quota_fr;
      valor_total_zona_seg += el.quota_seg;
      valor_total_zona_total += el.total;

      this.reportData.simulacaoListToPrint.push(el);
    });

    if (this.reportData.simulacaoListToPrint.length > 0) {
      this.reportData.simulacaoListToPrint.push({ label: 'Subtotal', perm: valor_total_zona_perm, orc: valor_total_zona_orc, fr: valor_total_zona_fr, seg: valor_total_zona_seg, quota: valor_total_zona_total, separator: false, subTotal: true });

      let totalOrc = 0;
      let totalFr = 0;
      let totalSeg = 0;
      let totalQuota = 0;
      this.reportData.simulacaoListToPrint.forEach(el => {
        if (el.hasOwnProperty('label') && !el.separator) {
          el.orc = totalOrc; 
          el.fr = totalFr;
          el.seg = totalSeg; 
          el.quota = totalQuota;

          this.reportData.simulacaoTotaisPDF.permilagem += el.perm;
          this.reportData.simulacaoTotaisPDF.orcamento += el.orc;
          this.reportData.simulacaoTotaisPDF.fundoReserva += el.fr;
          this.reportData.simulacaoTotaisPDF.seguro += el.seg;
          this.reportData.simulacaoTotaisPDF.total += el.quota;

          totalOrc = 0;
          totalFr = 0;
          totalSeg = 0;
          totalQuota = 0;
        } else {
          el.quota_orc = this.utils.cleanDecimalDigits(el.quota_orc / nMesesORC); 
          el.quota_fr = this.utils.cleanDecimalDigits(el.quota_fr / nMesesORC); 
          el.quota_seg = this.utils.cleanDecimalDigits(el.quota_seg / nMesesORC); 
          el.total = el.quota_orc + el.quota_fr + el.quota_seg;

          totalOrc += el.quota_orc ? el.quota_orc : 0;
          totalFr += el.quota_fr ? el.quota_fr : 0;
          totalSeg += el.quota_seg ? el.quota_seg : 0;
          totalQuota += el.total ? el.total : 0;
        }
      });

    }
  }

  generateRubricasOrcList() {
    let nMesesFR = this.reportData.cobrancaFR.filter(el => (el.sel)).length;
    let nMesesORC = this.reportData.cobrancaOrcamento.filter(el => (el.sel)).length;

    // RUBRICAS DO ORCAMENTO
    this.reportData.rubricasOrcListToPrint = [];
    let auxRubrica = {
      zona_nome: null,
      rubrica_nome: null,
      valor: null,
    };
    this.reportData.rubricasOrcTotais.valor = 0;

    this.reportData.zonas.forEach((zona, i) => {
      this.reportData.rubricasList.filter(el => (el['cod-zona-' + i] === zona.cod)).forEach(rubrica => {
        auxRubrica = {
          zona_nome: zona.nome,
          rubrica_nome: rubrica['nome'],
          valor: rubrica['valor-zona-' + i],
        };
        if (Number(auxRubrica.valor !== 0)) this.reportData.rubricasOrcListToPrint.push(auxRubrica);
      });
    });

    this.reportData.rubricasOrcListToPrint.forEach(el => {
      this.reportData.rubricasOrcTotais.valor += el.valor;
    });

    let prevZona = null;
    let valor_total_zona = 0;

    let auxArray = [];
    this.reportData.rubricasOrcListToPrint.forEach((el, i) => {
      el['separator'] = false;
      el['subTotal'] = false;

      // TABLE ROW TOTAL AND SEPARATOR
      if (prevZona !== el['zona_nome']) {
        if (i > 0) {
          auxArray.push({ label: 'Subtotal', valor: valor_total_zona, separator: false, subTotal: true });
        }
        auxArray.push({ label: el.zona_nome, separator: true, subTotal: false });
        valor_total_zona = 0;
      }
      prevZona = el['zona_nome'];
      valor_total_zona += Number(el.valor);

      auxArray.push(el);
    });
    if (auxArray.length > 0) {
      auxArray.push({ label: 'Subtotal', valor: valor_total_zona, separator: false, subTotal: true });
    }
    this.reportData.rubricasOrcListToPrint = auxArray;

    // RESUMO ORCAMENTO - ZONAS
    this.reportData.zonasOrcListToPrint = [];

    prevZona = null;
    let valor_total_zona_orc = 0; 
    let valor_total_zona_fr = 0; 
    let valor_total_zona_seg = 0; 
    let valor_total_zona_total = 0;


    this.reportData.zonasOrcTotais = {
      orc: 0,
      fr: 0,
      seg: 0,
      quota: 0
    };

    this.reportData.orcamentoDataTable.forEach(el => {
      valor_total_zona_orc = el.orc_valor; 
      valor_total_zona_fr = el.fr_valor;
      
      valor_total_zona_seg = 0;

      this.reportData.simulacaoList.filter(it => it.cod_zona === el.cod).forEach(el => {
        valor_total_zona_seg += Number(el.quota_seg_anual);
      });

      valor_total_zona_total = el.valor + valor_total_zona_seg;

      let aux = {
        zona_nome: el.nome,
        orc: valor_total_zona_orc,
        fr: valor_total_zona_fr,
        // seg: (this.isVistaMensalOrcamento) ? valor_total_zona_seg * nMesesORC : valor_total_zona_seg,
        seg: valor_total_zona_seg,
        quota:  valor_total_zona_total,
      }

      this.reportData.zonasOrcListToPrint.push(aux);

      this.reportData.zonasOrcTotais.orc += Number(valor_total_zona_orc); 
      this.reportData.zonasOrcTotais.fr += Number(valor_total_zona_fr); 
      this.reportData.zonasOrcTotais.seg += Number(valor_total_zona_seg); 
      this.reportData.zonasOrcTotais.quota += Number(valor_total_zona_total);
    });

    if (this.reportData.zonasOrcListToPrint.length > 0) {
      this.reportData.zonasOrcTotais.orc = this.reportData.zonasOrcTotais.orc; 
      this.reportData.zonasOrcTotais.fr = this.reportData.zonasOrcTotais.fr; 
      this.reportData.zonasOrcTotais.seg = this.reportData.zonasOrcTotais.seg; 
      this.reportData.zonasOrcTotais.quota = this.reportData.zonasOrcTotais.quota;
    }
    
  }
}
