import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { ConfigAvisosCobrancaDetailsComponent } from '../config-avisos-cobranca-details/config-avisos-cobranca-details.component';
import { ConfigAvisosCobrancaHypothesis, ConfigurationNavigation } from '../configurations';
import { ConfigurationsService } from '../configurations.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { UtilitiesService } from '../utilities.service';

@Component({
  selector: '[app-config-avisos-cobrancas]',
  templateUrl: './config-avisos-cobrancas.component.html',
  styleUrls: ['./config-avisos-cobrancas.component.scss']
})
export class ConfigAvisosCobrancasComponent implements OnInit {

  @Input() keyword = null;

  listOrig:Array<ConfigAvisosCobrancaHypothesis> = [];
  padraoList:Array<ConfigAvisosCobrancaHypothesis> = [];
  contenciosoList:Array<ConfigAvisosCobrancaHypothesis & {checked:boolean}> = [];

  padraoListCol = [
    { key: 'checked', name: null, type: 'checkbox', searchable: false, centered: false },
    { key: 'ordem', name: 'Ordem', type: 'text', searchable: true, centered: false },
    { key: 'descricao', name: 'Nome', type: 'text', searchable: true, centered: false },
  ];
  contenciosoListCol = [
    { key: 'checked', name: null, type: 'checkbox', searchable: false, centered: false },
    // { key: 'ordem', name: 'Ordem', type: 'text', searchable: true, centered: false },
    { key: 'descricao', name: 'Nome', type: 'text', searchable: true, centered: false },
  ];

  @ViewChild('configDetails', { static: false }) configDetails: ConfigAvisosCobrancaDetailsComponent;
  @ViewChild('deleteModal', { static: false }) deleteModal: DeleteModalComponent;


  hypoTabs = [
    { key:'sem-contencioso', active: true, name: 'Sem Contencioso' },
    { key:'com-contencioso', active: false, name: 'Com Contencioso' }
  ];




  // Paginations
  // selectedPage = 0;
  // itemsPerPage = 20;

  navState:ConfigurationNavigation = null;
  constructor(
    public api: ApiService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public toastr: ToastrService,
    public location: Location,
    public router: Router,
    public configurations: ConfigurationsService,
  ) { 
    let currState = this.router.getCurrentNavigation();
    if ('state' in currState.extras) {
      this.navState = currState.extras.state;
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.configDetails.ngOnDestroy(true);
      }
    });
  }

  loadData(): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.listOrig = await this.configurations.getAvisosCobrancaHypotheses();
      this.searchList(this.keyword);
      this.loadNavigationAction();
      resolve(true);
    })
  }

  loadNavigationAction() {
    if (!this.navState || !this.navState.action) return;
    switch (this.navState.action) {
      case 'CONFIG_OPEN_NEW_AC_SET_TEXT':
        let id = this.navState.data? this.navState.data.id : null;
        
        this.open(id, this.navState);
        break;
    
      default:
        break;
    }
    this.navState = null;
    return;
  }

  updateLists(filteredList) {
    filteredList = JSON.parse(JSON.stringify(filteredList));
    this.padraoList = [];
    this.contenciosoList = [];
    filteredList.forEach(hypo => {
      if (hypo.cod_estado_contencioso == null) {
        this.padraoList.push(JSON.parse(JSON.stringify(hypo)));
      } else {
        this.contenciosoList.push(JSON.parse(JSON.stringify(hypo)));
      }
    });
  }

  searchList(keyword) {
    let listFiltered = JSON.parse(JSON.stringify(this.listOrig));
    if (keyword) {
      listFiltered = this.utils.tableSearch(keyword, this.contenciosoListCol, this.listOrig);
    } else {
      listFiltered = this.listOrig;
    }
    this.updateLists(listFiltered);
  }

  allContenciososChecked = false;
  checkHeaderContenciosos(column) {
    if (column.type !== 'checkbox') return;
    this.allContenciososChecked = !this.allContenciososChecked;
    this.contenciosoList.forEach(el => {
      el.checked = this.allContenciososChecked;
    })
  }

  checkContencioso(row: ConfigAvisosCobrancaHypothesis & {checked:boolean}) {
    row.checked = !row.checked;
    this.allContenciososChecked = !this.contenciosoList.find(el => !el.checked);
  }
  
  modalIsOpened = false;
  open(id_hypo=null, navState=null) {
    if (this.modalIsOpened) return;
    this.modalIsOpened = true;
    let isPadrao = !!this.padraoList.find(el => el.id === id_hypo);
    let tab = null;
    if (isPadrao) {
      tab = this.hypoTabs.find(el => el.key === 'sem-contencioso');
    } else {
      tab = this.hypoTabs.find(el => el.key === 'com-contencioso');
    }
    if (tab) tab.active = true;
    this.configDetails.open(id_hypo, navState).then(_ => {
      this.modalIsOpened = false;
      // // this.router.routeReuseStrategy.shouldReuseRoute =( ) => true;
      // // this.router.onSameUrlNavigation = 'ignore';
      // if (!res.isRedirecting) {
      //   this.location.replaceState('/configuracoes/avisos-cobranca');
      // }
      this.loadData()
    }).catch(err => {
      this.modalIsOpened = false;
    });
  }

  del(): Promise<boolean> {
    return new Promise(async (resolve) => {
      let toDelete = this.contenciosoList.filter(el => !!el.checked).map(el => el.id);
      if (!toDelete.length) {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
        return;
      }
      this.deleteModal.open();
      resolve(true);
    })
  }

  deleteFunction: () => Promise<boolean> = () => {
    return new Promise(async (resolve) => {
      let toDelete = this.contenciosoList.filter(el => !!el.checked).map(el => el.id);
      let success = await this.configurations.deleteAvisosCobrancaHypotheses(toDelete);
      if (success) {
        this.listOrig = this.listOrig.filter(el => !toDelete.find(del => del === el.id));
        this.searchList(this.keyword);
      }
      resolve(success);
    })
  }

}
