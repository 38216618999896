import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';


@Component({
  selector: 'app-contas-details',
  templateUrl: './contas-details.component.html',
  styleUrls: ['./contas-details.component.scss']
})
export class ContasDetailsComponent implements OnInit {

  @ViewChild('contaAlertRef', { static: false }) contaAlertRef;
  contaModalRef = null;
  contaAlertConfig: any = null;

  transitionController = new TransitionController();
  submittingForm = false;
  loading = false;
  isCreate = false;
  searchable = true;

  selTab: string = null;

  condominioCod = null;
  contaId = null;
  contaCod = null;

  contasForm = new FormGroup({
    banco: new FormControl(null, { validators: Validators.required }),
    n_conta: new FormControl(null),
    morada: new FormControl(null),
    telefone_1: new FormControl(null),
    obs: new FormControl(null),
    titular: new FormControl(null),
    nib: new FormControl(null),
    dt_abertura: new FormControl(null),
    gestor: new FormControl(null),
    agencia: new FormControl(null),
    conta_principal: new FormControl(null),
    // cod_tipo_conta: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    cod_tipo_conta: new FormControl(null),
  });
  
  details: any = null;
  contasPrincipais = [];
  selBanco = null;

  nibNotOk = false;

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public router: Router,
              public route: ActivatedRoute,
              public location: Location,
              public cdRef:ChangeDetectorRef,
              public api: ApiService,
              public utils: UtilitiesService,
              public appConfig: AppConfigService,
              public message: MessageService) { }

  ngOnInit() {
    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.isCreate = true;
    } else {
      this.contaId = this.route.snapshot.params.id;
    }

    this.init();
  }

  init() {
    this.submittingForm = false;
    this.loading = false;
    this.condominioCod = this.route.snapshot.params.cond_id;

    if (this.isCreate) {

    } else {
      this.getDetails();
    }
    this.setTab('geral');
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngAfterViewInit() {
    this.contaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.contaAlertRef);
    this.contaAlertConfig.closeResult = "closed";
    this.contaAlertConfig.size = 'mini';
    this.contaAlertConfig.transition = 'fade';
    this.contaAlertConfig.transitionDuration = 250;
  }

  bancoTimer = null;
  bancoSelected = null;
  bancoSelOptsLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.bancoSelected); });
    }

    clearTimeout(this.bancoTimer);
    return new Promise(resolve => {
        if (query) {
          this.bancoTimer = setTimeout(() => {

            this.api.getAllBancos(query).subscribe(res => {
              if (res.success) {
                return resolve([{ name: '--- Criar Banco ---', value: '0' }].concat(res.data.map(el => { return { name: el.sigla + ' - ' + el.banco, value: el.sigla + ' - ' + el.banco }; })));
              } else {
                return resolve([{ name: '--- Criar Banco ---', value: '0' }]);
              }
            });
          }, 400);
        } else {
          this.api.getAllBancos('NULL').subscribe(res => {
            if (res.success) {
              return resolve([{ name: '--- Criar Banco ---', value: '0' }].concat(res.data.map(el => { return { name: el.sigla + ' - ' + el.banco, value: el.sigla + ' - ' + el.banco }; })));
            } else {
              return resolve([{ name: '--- Criar Banco ---', value: '0' }]);
            }
          });
        }
    });
  };

  getDetails() {
    this.api.getContaDetails(this.route.snapshot.params.id).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.details = res.data;

        this.details.dt_abertura = (this.details.dt_abertura) ? this.utils.getDate(this.details.dt_abertura) : null;

        this.condominioCod = this.details.cod_condominio;
        this.contaCod = this.details.cod;
        this.details.conta_principal = this.utils.strToBool(this.details.conta_principal);

        this.contasPrincipais = res.contas_principais;

        if (this.details.banco) this.bancoSelected = { name: this.details.banco, value: this.details.banco };

        this.restoreForm();
      } else {}
    }, err => {});
  }

  setTab(targetTab) {
    this.selTab = targetTab;
  }

  restoreForm(targetForm=null) {
    if (this.isCreate) {
      this.contasForm.reset();
    } else {
      this.contasForm.patchValue({
        n_conta: this.details.n_conta,
        morada: this.details.morada,
        telefone_1: this.details.telefone_1,
        obs: this.details.obs,
        banco: this.details.banco,
        titular: this.details.titular,
        nib: (this.details.nib && this.details.nib.indexOf('PT50') !== -1) ? this.details.nib : ((this.details.nib !== null && this.details.nib !== '') ? 'PT50' : '') + ((this.details.nib) ? this.details.nib : ''),
        dt_abertura: this.details.dt_abertura,
        gestor: this.details.gestor,
        agencia: this.details.agencia,
        conta_principal: this.details.conta_principal,
        cod_tipo_conta: this.details.cod_tipo_conta,
      });
    }
  }

  isPhoneValid = true;
  formSubmitted() {
    this.submittingForm = true;

    this.isPhoneValid = true;

    // if (!this.contasForm.valid) return;

    let data = this.contasForm.getRawValue();

    if ((!data.cod_tipo_conta && this.selBanco.indexOf('CAIXA')) === -1) {
      setTimeout(() => { this.submittingForm = false; }, 4000);
      return;
    }

    // CHECK IF IBAN IS OK
    if (data.nib && (data.nib.indexOf('PT50') === -1 || data.nib.replace(/\s/g, '').length !== 25)) {
      this.nibNotOk = true;

      setTimeout(() => { this.submittingForm = false; }, 4000);
      this.toastr.error("O campo IBAN tem obrigatóriamente de ser composto pelo prefixo 'PT50' seguido de 21 digitos numéricos.", 'Alerta', { timeOut: 4000 });
      return;
    }

    // CHECK PHONE NUMBER (telefone_1) - 9 digits
    if (data.telefone_1 && (!this.utils.isNumber(data.telefone_1.replace(/\s/g, '').replace('+', '')) || (data.telefone_1.replace(/\s/g, '').length !== 9 && data.telefone_1.replace(/\s/g, '').length !== 13))) {
      this.isPhoneValid = false;
      this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
      setTimeout(() => { this.submittingForm = false; }, 4000);
      return;
    }

    if (data.conta_principal && this.contasPrincipais.find(el => (el.cod_conta !== this.contaCod))) {
      this.contaModalRef = this.modalService
      .open(this.contaAlertConfig)
      .onApprove(() => { 
        this.save(data);
      })
      .onDeny(() => { });
    } else {
      this.save(data);
    }
  }

  save(data) {
    this.loading = true;
    this.api.saveConta(this.contaId, this.contaCod,data.n_conta,data.morada,data.telefone_1,data.obs,this.condominioCod,data.cod_tipo_conta,data.banco,data.titular,data.nib,data.dt_abertura,data.gestor,data.agencia,data.conta_principal).subscribe(res => {
      if (res.hasOwnProperty('success') && res['success']) {
        this.location.back();
        } else {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        }
        this.submittingForm = false;
        this.loading = false;
      }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.submittingForm = false;
      this.loading = false;
    }); 
  }

}
