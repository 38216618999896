import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, formatDate, getLocaleId } from '@angular/common';
import { MessageService } from './message.service';
import { UserSessionService } from './user-session.service';
import { AppConfigService } from './app-config.service';
import { Minuta } from './business-model-interfaces/minuta';
import { EntitiesCorrespondenciaEmail, RegistoComunicacaoCorrespondenciaApi } from './business-model-interfaces/comunicacoes';
import { saveAs } from '@progress/kendo-file-saver';
import { fileExt, contentType } from './business-model-interfaces/anexo';
import * as extenso from 'extenso';
import { ToWords } from 'to-words';
import { Morada } from './business-model-interfaces/application';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  format = 'dd-MM-yyyy';

  constructor(public datePipe: DatePipe,
    public userSession: UserSessionService,
    public appConfig: AppConfigService,
    public message: MessageService,
    @Inject(LOCALE_ID) protected localeId: string,
    public toastr: ToastrService) { }

  setFormFeedback(formFeedback: { show: boolean, timeout }) {
    if (formFeedback.timeout) {
      clearTimeout(formFeedback.timeout);
      formFeedback.timeout = null;
    }
    formFeedback.show = true;
    formFeedback.timeout = setTimeout(() => {
      formFeedback.show = false;
    }, 4000);
  }

  getDayOfWeek(date) {
    let days = ['Domingo', '2ª feira', '3ª feira', '4ª feira', '5ª feira', '6ª feira', 'Sábado'];
    let index = date.getDay();
    return days[index];
  }

  isNumber(str) {
    var pattern = /^\d+$/;
    return pattern.test(str);  // returns a boolean
  }

  validateEmail(email) {
    return /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
      .test(email);
  }

  getNumberFormatted(valor, currency = true): string {
    return parseFloat(valor).toLocaleString(this.localeId, { minimumFractionDigits: currency ? 2 : 0 });
  }

  getDataPorExtenso(data: Date, day: boolean = true, month: boolean = true, year: boolean = true): string {
    if (!data) return null;
    let opt = {};
    if (day) opt['day'] = 'numeric';
    if (month) opt['month'] = 'long';
    if (year) opt['year'] = 'numeric';
    return data.toLocaleString(this.localeId, opt);
  }

  getMonthPorExtenso(data: Date): string {
    if (!data) return null;
    return data.toLocaleString(this.localeId, { month: 'long' });
  }

  getNumberPorExtenso(valor: number, currency = true, female = false) {
    const toWords = new ToWords({
      localeCode: this.localeId, converterOptions: {
        currency: currency,
        doNotAddOnly: true
      }
    });
    try {
      return toWords.convert(valor);
    } catch (err) {
      this.toastr.error(this.appConfig.errMsg.languageUnsupported.msg, this.appConfig.errMsg.languageUnsupported.title);
      return undefined;
    }
  }

  // ------------ START - Date Utilities

  months = ['Jan - ', 'Fev - ', 'Mar - ', 'Abr - ', 'Mai - ', 'Jun - ', 'Jul - ', 'Ago - ', 'Set - ', 'Out - ', 'Nov - ', 'Dez - '];
  monthsExtenso = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  getMonthDesc(date) {
    return this.months[date.getMonth()];
  }

  getMonthDescByIndex(index) {
    return this.months[index].replace(' - ', '');
  }
  getMonthDescExtensoByIndex(index) {
    return this.monthsExtenso[index].replace(' - ', '');
  }

  isDate(date) {
    try {
      let aux = formatDate(date, this.format, this.localeId);
      return true;
    } catch {
      return false;
    }
  }

  getFormatedDate(date, format = null) {
    if (date) {
      if (format !== null) {
        try {
          return formatDate(date, format, this.localeId)
        } catch {
          return date;
        }
      } else {
        try {
          return formatDate(date, this.format, this.localeId)
        } catch {
          return date;
        }
      }
    }

    return date;
  }

  isLastDayOfMonth(data: Date): boolean {
    let nextDay = new Date(data.getFullYear(), data.getMonth(), data.getDate() + 1);
    return nextDay.getMonth() !== data.getMonth();
  }
  // ------------ END - Date Utilities

  insertStringAt(str, value, index) {
    return str.substr(0, index) + value + str.substr(index);
  }

  updateObjectProperies(obj, properties) {
    // let keys = Object.keys(properties);
    if (Array.isArray(obj)) {
      this.updateArrayElementsWithOther(obj, properties);
      return;
    }
    for (let i = 0; i < properties.length; i++) {
      for (var prop in properties[i]) {
        var val = properties[i][prop];
        if (val != null && typeof val == "object") { // this also applies to arrays or null!
          this.updateObjectProperies(obj[prop], val);
        }
        else {
          obj[prop] = val;
        }
      }
    }
  }

  updateArrayElementsWithOther(destination: Array<any>, origin: Array<any>) {
    for (let i = 0; i < destination.length; i++) {
      if (i < origin.length) {
        this.updateObjectProperies(destination[i], [origin[i]]);
      }
    }
    let diff = destination.length - origin.length;
    if (diff > 0) {
      destination.splice(origin.length);
    } else if (diff < 0) {
      let newElements = origin.slice(destination.length);
      newElements.forEach(newEl => {
        destination.push(newEl);
      })
    }
    return destination;
  }

  sort(key: string, list: Array<any>): void {
    switch (key) {
      case 'minutas':
        (list as Array<Minuta>).sort((a, b) => b.id - a.id)
      default:
    }
  }

  tableSort(colList, targetList, key) {
    let aux = targetList;

    colList.forEach(el => {
      if (el.key === key) {
        if (el.sort === 'DESC') {
          el.sort = 'ASC';

          aux = targetList.sort((a, b) => {

            if (a[key] === null) {
              return 1;
            }
            if (b[key] === null) {
              return -1;
            }

            if (a[key] < b[key]) {
              return -1;
            }
            if (a[key] > b[key]) {
              return 1;
            }
            return 0;
          });
        } else if (el.sort === 'ASC') {
          el.sort = 'DESC';

          aux = targetList.sort((a, b) => {

            if (a[key] === null) {
              return 1;
            }
            if (b[key] === null) {
              return -1;
            }

            if (a[key] < b[key]) {
              return 1;
            }
            if (a[key] > b[key]) {
              return -1;
            }
            return 0;
          });
        } else if (el.sort === null) {
          el.sort = 'ASC';

          aux = targetList.sort((a, b) => {

            if (a[key] === null) {
              return 1;
            }
            if (b[key] === null) {
              return -1;
            }

            if (a[key] < b[key]) {
              return -1;
            }
            if (a[key] > b[key]) {
              return 1;
            }
            return 0;
          });
        }
      } else {
        el.sort = null;
      }
    });

    return aux;
  }

  getAllIndexes<T>(arr: Array<T>, val: T) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i] === val)
        indexes.push(i);
    return indexes;
  }

  getAllIndexesObject(arr: Array<any>, keys: Array<string>, vals: Array<any>) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++) {
      let equals = true;
      keys.forEach((key, index) => {
        if (arr[i][key] !== vals[index])
          equals = false;
      });
      if (equals)
        indexes.push(i);
    }
    return indexes;
  }

  tableSearch(keyword: string, colList, targetList) {
    keyword = keyword.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return targetList.filter(el => {
      for (let i = 0; i < colList.length; i++) {
        if (colList[i].searchable) {
          if (colList[i].type === 'date') {

            if (el[colList[i].key]) {
              if (this.datePipe.transform(el[colList[i].key], "dd-MM-yyyy").indexOf(keyword) !== -1) return true;
            }

          } else if (colList[i].type === 'number') {
            let num = Number(keyword);

            if (el[colList[i].key] && Number(el[colList[i].key]) <= num + 0.001 && Number(el[colList[i].key]) >= num - 0.001) return true;

          } else {
            if (el[colList[i].key] && el[colList[i].key].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(keyword) !== -1) return true;
          }
        }
      }
      return false;
    });
  }

  getListPagination(arr, page, itemPerPage) {
    let offset = (--page) * itemPerPage;
    return arr.slice(offset, offset + itemPerPage);
  }

  // --------------------------- TYPE CONVERTION FUNCTIONS - START
  boolToStr(value) {
    return (value) ? '1' : '0';
  }
  strToBool(value) {
    return (value === '1' || value === true);
  }
  // --------------------------- TYPE CONVERTION FUNCTIONS - END
  /**
   * Function converts date (assuming it is in utc timezone) to user local timezone.
   */
  getDateUser(date: string) {
    if (!date || date.trim() === '') return null;
    return moment.utc(date).local().toDate();
  }

  // Use when @date is not at timezone. It assumes date is at utc and converts to user timezone
  getDate(date, hour = null, format = 'DD-MM-YYYY', ignoreTime = false) {
    if (!date) return null;

    if (hour === null && moment(date, moment.ISO_8601, true).isValid()) {
      let retDate = moment.utc(date).local().toDate();
      if (ignoreTime) retDate.setHours(0, 0, 0, 0);
      return retDate;
    }

    let mom: moment.Moment = null;
    if (date && hour) {
      mom = moment(date + ' ' + hour, format + ' HH:mm');
      if (!mom.isValid()) return;
      let retDate = mom.toDate();
      if (ignoreTime) retDate.setHours(0, 0, 0, 0);
      return retDate;
    } else {
      mom = moment(date, format);
      if (!mom.isValid()) return null;

      let retDate = mom.toDate();
      if (ignoreTime) retDate.setHours(0, 0, 0, 0);
      return retDate;
    }
  }

  formatDate(date: Date, format: string = 'DD-MM-YYYY'): String {
    return moment(date).format(format);
  }

  sameDateDay(date1: Date, date2: Date): boolean {
    return date1.getDate() == date2.getDate()
      && date1.getMonth() == date2.getMonth()
      && date1.getFullYear() == date2.getFullYear();
  }

  compareDayDates(date1: Date, date2: Date): number {
    if (date1.getFullYear() != date2.getFullYear()) return date1.getFullYear() - date2.getFullYear();
    if (date1.getMonth() != date2.getMonth()) return date1.getMonth() - date2.getMonth();
    return date1.getDate() - date2.getDate();
  }

  compareMonthDates(a: Date, b: Date): number {
    let a_months = a.getFullYear() * 12 + (a.getMonth() + 1);
    let b_months = b.getFullYear() * 12 + (b.getMonth() + 1);
    return a_months - b_months;
  }

  getTipoMovimento(value) {
    switch (value) {
      case 'R': return 'Receita';
      case 'D':
      case 'DESP': return 'Despesa';
      case 'RE': return 'Receita Extra';
      case 'C': return 'Crédito';
      case 'DEP': return 'Depósito';
      case 'LEV': return 'Levantamento';
      case 'TRAN': return 'Transferência';
      case 'SI': return 'Saldo Inicial';
      case 'QUO_ORC': return 'Quotas';
    }
    return value;
  }

  /**
   * Avalia se um movimento foi feito por caixa ou por banco
   * @param descricao descrição do movimento
   */
  getMovimentoMetodo(descricao: string): 'NUMERARIO' | 'TRANSFERENCIA' {
    if (!descricao) return null;
    let sufix = descricao.substring(descricao.length - 2);
    if (sufix === '-T') return 'TRANSFERENCIA';
    if (sufix === '-N') return 'NUMERARIO';
    return null;
  }

  reverseString(str) {
    return str.split("").reverse().join("");
  }

  moradaStrToList(morada: string): Array<string> {
    try {
      let aux = '';
      let addressObj = JSON.parse(morada);
      Object.keys(addressObj).forEach((key, i) => {
        if (addressObj[key]) aux += (i === 0) ? addressObj[key].replace('\n', 'NEW_LINE') : 'NEW_LINE' + addressObj[key].replace('\n', 'NEW_LINE');
      });

      return (aux) ? aux.split('NEW_LINE').filter(el => el && el.trim() !== '') : [];
    } catch (e) {
      return morada.split('NEW_LINE').filter(el => el && el.trim() !== '');
    }
  }

  formatMoradaJSON(morada: Morada) {
    let formatted = [];
    if (morada.morada) formatted.push(morada.morada);
    if (morada.locality) formatted.push(morada.locality);
    if (morada.postalCode) formatted.push(morada.postalCode);
    return formatted.join(', ');
  }

  moradaStrToJSON(obj, morada) {
    try {
      let aux = JSON.parse(morada);
      obj.morada = (aux.hasOwnProperty('address')) ? aux.address : '';
      obj.postalCode = aux.postalCode;
      obj.locality = aux.locality;

      return obj;
    } catch (err) {
      obj.morada = morada.replace(/NEW_LINE/g, ', ');
      return obj;
    }
  }

  moradaJSONToStr(data) {
    return JSON.stringify({
      address: data.morada,
      postalCode: data.postalCode,
      locality: data.locality,
    });
  }

  printObj(data) {
    console.log(JSON.parse(JSON.stringify(data)));
  }

  getStrMeses(arrMeses) {
    let aux = '';
    arrMeses.forEach(mes => {
      (mes) ? aux += 'S' : aux += 'N';
    });
    return aux;
  }

  getStrMesesV2(arrMeses) {
    let aux = '';
    arrMeses.forEach(mes => {
      (mes.sel) ? aux += 'S' : aux += 'N';
    });
    return aux;
  }

  getUserFullNameAbrv(firstName, lastName = null) {
    if (lastName) {
      return `${firstName}`;
    } else {
      return `${firstName} ${lastName[0]}.`;
    }
  }

  apiErrorMsg(res) {
    let msg = 'Não foi possível efectuar a operação. Por favor, verifique a sua ligação à Internet e/ou tente novamente mais tarde.';
    let title = 'Ups...!';
    let type: 'ERROR' | 'INFO' | 'WARN' = 'ERROR';
    if ('status' in res) {
      switch (res.status) {
        case 'UNAUTHORIZED':
          msg = 'Não tem permissões para aceder ao recurso/funcionalidade pretendida.';
          title = 'Permissão Negada';
          break;
        case 'SCHEDULE_UNAUTHORIZED':
          msg = 'Encontra-se fora do horário de acesso, a sua sessão será encerrada.';
          title = 'Permissão Negada';
          setTimeout(() => { this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'LOGOUT' }); }, 4300);
          break;
        case 'PAYMENT_ACCESS_DENIED':
          msg = 'Não tem permissões para editar os dados de pagamento pretendidos.';
          title = 'Permissão Negada';
          setTimeout(() => { this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'LOGOUT' }); }, 4300);
          break;
        case 'BAD_REQUEST':
          msg = 'O pedido realizado encontra-se mal formado. Por favor, altere o seu pedido.';
          title = 'Pedido Mal Formado';
          break;
        case 'DOMAIN_MODEL_CONFLICT':
          msg = 'Não é possível realizar o seu pedido. Por favor, atualize a página e tente novamente.';
          title = 'Ups...!';
          break;

        default:
          let errorCode = this.appConfig.errorCodes[res.status];
          if (!!errorCode && !!errorCode.msg && !!errorCode.title) {
            msg = errorCode.msg;
            title = errorCode.title;
          }

          let infoCode = this.appConfig.infoCodes[res.status];
          if (!!infoCode && !!infoCode.msg && !!infoCode.title) {
            msg = infoCode.msg;
            title = infoCode.title;
            type = 'INFO';
          }

          let warningCode = this.appConfig.warningCodes[res.status];
          if (!!warningCode && !!warningCode.msg && !!warningCode.title) {
            msg = warningCode.msg;
            title = warningCode.title;
            type = 'WARN';
          }
          break;
      }
    }
    if (this.toastr.findDuplicate(msg, false, false)) {
      this.toastr.clear();
    }
    switch (type) {
      case 'WARN':
        this.toastr.warning(msg, title);
        break;
      case 'INFO':
        this.toastr.info(msg, title);
        break;
      case 'ERROR':
      default:
        this.toastr.error(msg, title);
        break;
    }

  }

  handleDeleteCVPermissions(entry, dates: Date[], type: string, adminText: string, superAdminText: string) {
    let toShow = [];

    dates.forEach(data => {
      if (this.sameDateDay(data, new Date())) {
        if (this.userSession.isAdmin() || this.userSession.isSuperAdmin()) {
          toShow.push(2);
        } else {
          toShow.push(1);
        }
      } else if (this.userSession.isSuperAdmin()) {
        toShow.push(4);
      } else {
        toShow.push(3);
      }
    });

    let selEntryDate = null;


    switch (toShow.reduce((a, b) => { return Math.max(a, b) })) {
      case 1:
        this.toastr.error(type + ' em Caixa Vertis. ' + adminText, 'Permissão Negada', { timeOut: 4000 });
        entry.checked = false;
        break;
      case 2:
        this.toastr.warning(type + ' em Caixa Vertis.', 'Atenção', { timeOut: 4000 });
        break;
      case 3:
        this.toastr.error(type + ' no registo da Caixa Vertis. ' + superAdminText, 'Permissão Negada', { timeOut: 4000 });
        entry.checked = false;
        break;
      case 4:
        selEntryDate = dates.filter(data => !this.sameDateDay(data, new Date())).map(el => { return this.formatDate(el) });
        selEntryDate = [... new Set(selEntryDate)].join(', ');
        let lastIndex = selEntryDate.lastIndexOf(',');
        if (lastIndex != -1) {
          selEntryDate = selEntryDate.substring(0, lastIndex) + ' e' + selEntryDate.substring(lastIndex + 1);
        }
        break;
      default:
        break;
    }
    return selEntryDate;
  }

  checkCustosComunicacoesPermissions(row: { tipo, data, entregue_por }): boolean {
    if (row.tipo == '0') {
      if (!this.userSession.isSuperAdmin()) {
        this.toastr.error('Não tem permissões para aceder ao recurso/funcionalidade pretendida. Por favor contacte o administrador de sistema.', 'Permissão Negada', { timeOut: 4000 });
        return false;
      }
      if (this.compareDayDates(new Date(row.data), new Date()) < 0) {
        this.toastr.warning('A entrega selecionada encontra-se no registo da caixa vertis. Alterar os dados da entrega irá afetá-lo.', 'Alerta');
      }
    }
    let isToday = this.compareDayDates(new Date(row.data), new Date()) == 0;
    if (row.tipo == '1' && !this.userSession.isSuperAdmin() && (!isToday || isToday && row.entregue_por !== this.userSession.getUserId())) {
      this.toastr.error('Não tem permissões para aceder ao recurso/funcionalidade pretendida. Por favor contacte o administrador de sistema.', 'Permissão Negada', { timeOut: 4000 });
      return false;
    }
    if (row.tipo == '1' && this.userSession.isSuperAdmin() && !isToday) {
      this.toastr.warning('A fatura selecionada encontra-se no registo da caixa vertis. Alterar os dados da fatura irá afetá-lo.', 'Alerta');
    }
    return true;
  }

  getEmailFooterV2(userFullName = null, userPhone = null, userEmail = null) {
    if (!userFullName) userFullName = (this.userSession.getUserFullName() && this.userSession.getUserFullName() !== 'null') ? this.userSession.getUserFullName() : '';
    if (!userPhone) userPhone = (this.userSession.getUserPhone() && this.userSession.getUserPhone() !== 'null') ? this.userSession.getUserPhone() : '';
    if (!userEmail) userEmail = (this.userSession.getUserEmail() && this.userSession.getUserEmail() !== 'null') ? this.userSession.getUserEmail() : '';

    let footer = '';

    footer += `
      <div style="margin-top: 25px;">
        <span>`
      + 'Sem outro assunto,' +
      `<br>`
      + 'Cumprimentos,' +
      `<br><br>
        </span>
      </div>
    `;

    footer += `
      <div style="margin-top: 25px;">
        <span>`
      + userFullName +

      ((userPhone) ? `<br><br>Tel. ` + userPhone : ' ') +

      ((userEmail) ? `<br>Email: ` + userEmail : ' ') +

      `<br><a href="https://` + this.appConfig.company.website + `">`
      + this.appConfig.company.website +
      `</a></span>
      </div>`;

    footer += `
      <div style="margin-top: 25px;">
        <span>`
      + this.appConfig.company.nome +
      `<br>`
      + this.appConfig.company.morada +
      `<br>`
      + this.appConfig.company.localidade +
      `<br>`
      + this.appConfig.company.codigoPostal +
      `<br><br>
            Tel. ` + this.appConfig.company.tel +
      `<br>
            Email: <a href="mailto:` + this.appConfig.company.email + `">` + this.appConfig.company.email + `</a>` +
      `</span>
      </div>
    `;

    return footer;
  }

  getEmailFooter(isCorrespondecia = false, addAssinatura = true, user = null) {
    let footer = '';

    if (!isCorrespondecia && addAssinatura) {
      footer += `
        <div style="margin-top: 25px;">
          <span>`
        + 'Sem outro assunto,' +
        `<br>`
        + 'Cumprimentos,' +
        `<br><br>
          </span>
        </div>
      `;
    }

    let userFullName = (this.userSession.getUserFullName() && this.userSession.getUserFullName() !== 'null') ? this.userSession.getUserFullName() : '';
    let userPhone = (this.userSession.getUserPhone() && this.userSession.getUserPhone() !== 'null') ? this.userSession.getUserPhone() : '';
    let userEmail = (this.userSession.getUserEmail() && this.userSession.getUserEmail() !== 'null') ? this.userSession.getUserEmail() : '';

    if (user) {
      userFullName = (user.first_name && user.last_name) ? user.first_name + ' ' + user.last_name : '';
      userPhone = (user.phone) ? user.phone : '';
      userEmail = (user.email) ? user.email : '';
    }

    if (addAssinatura) {
      footer += `
        <div style="margin-top: 25px;">
          <span>`
        + userFullName +

        ((userPhone) ? `<br><br>Tel. ` + userPhone : ' ') +

        ((userEmail) ? `<br>Email: ` + userEmail : ' ') +

        `<br><a href="https://` + this.appConfig.company.website + `">`
        + this.appConfig.company.website +
        `</a></span>
        </div>`;
    }

    footer += `
      <div style="margin-top: 25px;">
        <span>`
      + this.appConfig.company.nome +
      `<br>`
      + this.appConfig.company.morada +
      `<br>`
      + this.appConfig.company.localidade +
      `<br>`
      + this.appConfig.company.codigoPostal +
      `<br><br>
            Tel. ` + this.appConfig.company.tel +
      `<br>
            Email: <a href="mailto:` + this.appConfig.company.email + `">` + this.appConfig.company.email + `</a>` +
      `</span>
      </div>
    `;

    return footer;
  }

  getEmailFooterSimple(addAssinatura = false, user: { name, email } = null, addCompanyAssinatura = false) {
    let footer = '';

    let userFullName = (this.userSession.getUserFullName() && this.userSession.getUserFullName() !== 'null') ? this.userSession.getUserFullName() : '';
    let userEmail = (this.userSession.getUserEmail() && this.userSession.getUserEmail() !== 'null') ? this.userSession.getUserEmail() : '';

    if (user) {
      userFullName = user.name;
      userEmail = user.email;
    }

    //TODO Condição caso seja da imobiliária (Remover na comercialização)
    if (userEmail === 'imobiliaria@vertis.pt') {
      footer += `
      <div style="margin-top: 25px;">
        <p>&nbsp</p>
        <span>
          `+ userFullName + `
        </span>
      </div>`;
      footer += `
      <div style="margin-top: 30px;">
        <p style="font-size: 12pt;font-family:Times New Roman,serif;margin:0;"><b><span style="font-family:Century Gothic,sans-serif;">VERTIS</span></b><br></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><span style="font-size:8pt;font-family:Century Gothic,sans-serif;">Mediação Imobiliária</span></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><span style="font-size:8pt;font-family:Century Gothic,sans-serif;">Gestão de Condomínios</span></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><span style="font-size:8pt;font-family:Century Gothic,sans-serif;">&nbsp</span></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><span style="font-size:8pt;font-family:Century Gothic,sans-serif;">911523390</span></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><span style="font-size:8pt;font-family:Century Gothic,sans-serif;"><a href="mailto:imobiliaria@vertis.pt">imobiliaria@vertis.pt</a></span></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><span style="font-size:8pt;font-family:Century Gothic,sans-serif;"><a href="https://www.vertis.pt">www.vertis.pt</a></span></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><span style="font-size:8pt;font-family:Century Gothic,sans-serif;">&nbsp</span></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><b><span style="font-size:8pt;font-family:Century Gothic,sans-serif;">Licença AMI nº 15007</span></b></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><b><span style="font-size:8pt;font-family:Century Gothic,sans-serif;">Intermediário de Crédito registado no Banco de Portugal com o nº 4157</span></b></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><span style="font-size:8pt;font-family:Century Gothic,sans-serif;">&nbsp</span></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;">
          <span style="font-size:8pt;font-family:Century Gothic,sans-serif;">`
        + this.appConfig.company.morada +
        `</span>
        </p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;">
          <span style="font-size:8pt;font-family:Century Gothic,sans-serif;">`
        + this.appConfig.company.localidade +
        `</span>
        </p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;">
          <span style="font-size:8pt;font-family:Century Gothic,sans-serif;">`
        + this.appConfig.company.codigoPostal +
        `</span>
        </p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;">
          <span style="font-size:8pt;font-family:Century Gothic,sans-serif;">
          Tel.` + this.appConfig.company.tel +
        `</span>
        </p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;"><span style="font-size:8pt;font-family:Century Gothic,sans-serif;">&nbsp</span></p>
        <p style="font-size:12pt;font-family:Times New Roman,serif;margin:0;">
          <b>
            <span style="font-size:10pt;font-family:Century Gothic,sans-serif;">
              Caso pretenda deixar de receber estas mensagens, contacte-nos através do e-mail: <a href="mailto:geral@vertis.pt">geral@vertis.pt</a>
            </span>
          </b>
        </p>

      </div>
      <div style="text-align: justify;margin-top: 25px;border-top: 1px solid #000000;padding-top: 5px;line-height: 12pt;font-size: 9pt;font-family: 'Century Gothic',sans-serif !important;">
        <span>
          Esta mensagem e os seus anexos constituem informação confidencial e/ou privilegiada para uso exclusivo do respectivo destinatário. 
          Se não é o destinatário ou recebeu esta mensagem por engano, por favor informe o remetente e destrua-a de imediato. 
          É proibido e ilegal o uso, reencaminhamento ou reprodução total ou parcial desta mensagem sem autorização expressa do remetente.
          <br>
          <br>
          This e-mail may contain confidential and/or privileged information. 
          If you are not the intended recipient or have received this e-mail in error, notify the sender immediately and destroy this e-mail. 
          Any unauthorised use, copying, disclosure or distribution of the contents of this e-mail is strictly forbidden and may be unlawful.
        </span>
      </div>
      
      `;

      return footer;
    }

    if (addCompanyAssinatura) {
      footer += `
      <div style="margin-top: 25px;">
        <span>
            Com os melhores cumprimentos,<br>
            A empresa gestora<br>
            <br>
            <br>
        <span>
      </div>`;
    } else if (addAssinatura) {
      footer += `
      <div style="margin-top: 25px;">
        <span>
            Sem outro assunto, <br>
            Cumprimentos, <br>
            <br>
            <br>
        <span>
            
        <span style="font-family: 'Century Gothic',sans-serif !important;font-size: 9pt;">`+ userFullName + `<br>
            <a style="font-family: 'Century Gothic',sans-serif !important;font-size: 9pt;" href="mailto:` + userEmail + `">` + userEmail + `</a>
        </span>
      </div>`;
    }

    footer += `
      <div style="margin-top: 30px;font-family: 'Century Gothic',sans-serif !important;line-height: 12pt;font-size: 9pt;">
        <span style="font-size: 11pt;"><b>VERTIS</b><br></span>
        <span>Gestão Condomínios</span>
        <span>
            <br><br>`
      + this.appConfig.company.morada +
      `<br>`
      + this.appConfig.company.localidade +
      `<br>`
      + this.appConfig.company.codigoPostal +
      `<br>
            Tel. ` + this.appConfig.company.tel +
      `<br>
            <a href="https://` + this.appConfig.company.website + `">` + this.appConfig.company.website + `</a>` +
      `<br>
            <a href="mailto:` + this.appConfig.company.email + `">` + this.appConfig.company.email + `</a>` +
      ` </span>
      </div>
      <div style="text-align: justify;margin-top: 25px;border-top: 1px solid #000000;padding-top: 5px;line-height: 12pt;font-size: 9pt;font-family: 'Century Gothic',sans-serif !important;">
        <span>
          Esta mensagem e os seus anexos constituem informação confidencial e/ou privilegiada para uso exclusivo do respectivo destinatário. 
          Se não é o destinatário ou recebeu esta mensagem por engano, por favor informe o remetente e destrua-a de imediato. 
          É proibido e ilegal o uso, reencaminhamento ou reprodução total ou parcial desta mensagem sem autorização expressa do remetente.
          <br>
          <br>
          This e-mail may contain confidential and/or privileged information. 
          If you are not the intended recipient or have received this e-mail in error, notify the sender immediately and destroy this e-mail. 
          Any unauthorised use, copying, disclosure or distribution of the contents of this e-mail is strictly forbidden and may be unlawful.
        </span>
      </div>
      
      `;

    return footer;
  }

  setEmailTemplate(htmlBody, minWidth = false) {
    return `
      <!doctype html>
      <html lang="pt">
      <head>
        <meta charset="utf-8">
      
        <style type="text/css">
          @media screen {
            /* cyrillic-ext */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
              unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* vietnamese */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
              unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
            }
            /* latin-ext */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
              unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }

            body {
              font-family: "Montserrat", "sans-serif" !important;
              font-size: 0.9em;
            }

            .ql-align-center { 
              text-align: center !important;
            } 
            .ql-align-right { 
                text-align: right !important; 
            } 
            .ql-align-justify { 
                text-align: justify !important;
            }
        </style>


      </head>
      <body ` + ((minWidth) ? 'style="min-width: 700px !important";' : '') + `>
        <div style="letter-spacing: 596px; line-height: 0; mso-hide: all">&nbsp;</div><!-- nbsp is 4px wide -->
        ${htmlBody}
      </body>
      </html>
      `;
  }

  // VALIDATE DATES
  daysInMonth(m, y) {
    switch (m) {
      case 1:
        return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
      case 8: case 3: case 5: case 10:
        return 30;
      default:
        return 31
    }
  };

  isValidDate(d, m, y) {
    m = parseInt(m, 10);
    return m >= 0 && m < 12 && d > 0 && d <= this.daysInMonth(m, y);
  };

  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  findLastIndex(array, searchKey, searchValue) {
    var index = array.slice().reverse().findIndex(x => x[searchKey] === searchValue);
    var count = array.length - 1
    var finalIndex = index >= 0 ? count - index : index;

    return finalIndex;
  }


  // REGISTO CTT --------------------------------------------------------------
  // TODO: SAVE IN DATABASE
  vertisCustos = {
    coefNEnvelope: 0.5,
    coefNFolhas: 0.2,
    custoMinimo: [
      { type: 'SIMPLES', price: 1.85 },
      { type: 'NORMAL', price: 2.55 },
      { type: 'REGISTADO', price: 2.55 }
    ],
  };
  correioMaterialPeso = [
    { type: 'PAPEL_NORMAL', label: 'Papel 80gsm', peso: 5 },  // GRAMAS
    { type: 'ENVELOPE', label: 'Envelope DL', peso: 6.00 },  // GRAMAS
  ];
  correioTabelaPrecos = [
    {
      type: 'CORREIO_NORMAL',
      label: 'Correio Normal',
      priceArr: [
        { intInf: -0.01, intSup: 20, price: 0.57 },
        { intInf: 20, intSup: 50, price: 0.85 },
        { intInf: 50, intSup: 100, price: 1.15 },
        { intInf: 100, intSup: 500, price: 1.70 },
        { intInf: 500, intSup: 2000, price: 4.05 },
      ]
    },
    {
      type: 'CORREIO_REGISTADO',
      label: 'Correio Registado',
      priceArr: [
        { intInf: -0.01, intSup: 20, price: 2.75 },
        { intInf: 20, intSup: 50, price: 2.90 },
        { intInf: 50, intSup: 100, price: 3.10 },
        { intInf: 100, intSup: 500, price: 3.95 },
        { intInf: 500, intSup: 2000, price: 6.50 },
      ]
    },
    {
      type: 'AVISO_RECECAO',
      label: 'Aviso Receção',
      priceArr: [
        { intInf: 0, intSup: 0, price: 1.3 },
      ]
    },
    {
      type: 'CARTAS_COBRANCA',
      label: 'Cartas de Cobrança',
      priceArr: [
        { type: 'CORREIO_NORMAL', label: 'Correio Normal', price: 4.25 },
        { type: 'CORREIO_REGISTADO', label: 'Correio Registado', price: 6.85 },
      ]
    },
  ];
  correspRubricas = [{ name: 'Correio Normal', value: '98' }, { name: 'Correio Registado', value: '42' }, { name: 'Correio Simples', value: '47' }];
  correspFornecedores = [{ name: 'Ctt', value: '16' }, { name: 'Correspondência', value: '9' }];
  // END - TODO: SAVE IN DATABASE

  formatEmptyMoradaObject(morada) {
    try {
      let aux = JSON.parse(morada);
      if (aux.hasOwnProperty('address') && aux.address !== null && !aux.address.replace('NEW_LINE').trim()) aux.address = null;

      if (aux.hasOwnProperty('postalCode') && aux.postalCode !== null && !aux.postalCode.replace('NEW_LINE').trim()) aux.postalCode = null;

      if (aux.hasOwnProperty('locality') && aux.locality !== null && !aux.locality.replace('NEW_LINE').trim()) aux.locality = null;

      return JSON.stringify(aux);
    } catch (e) {
      return morada;
    }
  }

  getPdfPageCount(pdfBase64) {
    return (pdfBase64 && Array.isArray(atob(pdfBase64).match(/\/Type[\s]*\/Page[^s]/g))) ? atob(pdfBase64).match(/\/Type[\s]*\/Page[^s]/g).length : 0;
  }

  computeCorrespValue(nEnvelopes, nFolhasPorEnvelope, tipoCorreio, avisoRececao = false) {
    if (!nEnvelopes || !nFolhasPorEnvelope || !tipoCorreio) return false;

    let codRubrica = null;

    // COMPUTE N_FOLHAS
    let nFolhas = Math.floor(nEnvelopes * nFolhasPorEnvelope);

    let pesoPorCarta = (nFolhasPorEnvelope * this.correioMaterialPeso.find(el => (el.type === 'PAPEL_NORMAL')).peso) + this.correioMaterialPeso.find(el => (el.type === 'ENVELOPE')).peso;
    let custoVertis = (this.vertisCustos.coefNEnvelope * nEnvelopes) + (this.vertisCustos.coefNFolhas * nFolhas);
    let custoCtt = 0;

    // COMPUTE VALOR DESPESA
    let tabelaPreco = null;
    let precoPorCarta = null;
    switch (tipoCorreio) {
      case 'SIMPLES':
        custoCtt = 0;
        codRubrica = '47';
        break;
      case 'NORMAL':
        tabelaPreco = this.correioTabelaPrecos.find(el => (el.type === 'CORREIO_NORMAL')).priceArr;
        precoPorCarta = tabelaPreco.find(el => (el.intInf < pesoPorCarta && pesoPorCarta <= el.intSup));

        precoPorCarta = (precoPorCarta) ? precoPorCarta.price : tabelaPreco[tabelaPreco.length - 1].price;


        custoCtt = nEnvelopes * precoPorCarta;

        codRubrica = '98';
        break;
      case 'REGISTADO':
        tabelaPreco = this.correioTabelaPrecos.find(el => (el.type === 'CORREIO_REGISTADO')).priceArr;
        precoPorCarta = tabelaPreco.find(el => (el.intInf < pesoPorCarta && pesoPorCarta <= el.intSup));
        precoPorCarta = (precoPorCarta) ? precoPorCarta.price : tabelaPreco[tabelaPreco.length - 1].price;

        custoCtt = nEnvelopes * precoPorCarta;

        codRubrica = '42';
        break;
    }

    
    let minCost = this.vertisCustos.custoMinimo.find(el => el.type === tipoCorreio).price;
    if ((custoVertis + custoCtt) < minCost) {
      custoVertis = minCost - custoCtt;
    }
    let computedValue = custoVertis + custoCtt;

    if (avisoRececao && tipoCorreio === 'REGISTADO') {
      let custoAvisoRecepcao = this.correioTabelaPrecos.find(el => (el.type === 'AVISO_RECECAO'));
      if (custoAvisoRecepcao) computedValue += (nEnvelopes * custoAvisoRecepcao.priceArr[0].price);
    }

    computedValue = Math.round(computedValue * 100) / 100;
    custoVertis = Math.round(custoVertis * 100) / 100;
    custoCtt = Math.round(custoCtt * 100) / 100;

    return { valorDespesa: computedValue, codRubrica: codRubrica, custoVertis: custoVertis, custoCtt: custoCtt };
  }

  fitToColumn(arrayOfArray: Array<{}>) {
    // get maximum character of each column
    let converted = arrayOfArray.map(el => Object.values(el));
    return converted[0].map((a, i) => ({ wch: Math.max(...converted.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
  }

  replaceHtmlEditorCssClasses(htmlStr) {
    return htmlStr
      .replaceAll('class="ql-align-center"', 'style="text-align: center;"')
      .replaceAll('class="ql-align-right"', 'style="text-align: right;"')
      .replaceAll('class="ql-align-justify"', 'style="text-align: justify;"');
  }
  formatHTMLEditorText(htmlStr) {
    return htmlStr
      .replaceAll('<p>', "<div>")
      .replaceAll('</p>', "</div>");
  }

  // Joins words with _ and capitalizes first letter
  // "Condominio rua   carmEN     Miranda" -> "Condominio_Rua_CarmEN_Miranda"
  getFileNameFormatted(name: string): string {
    if (!name) return '';
    return name.replace(/,/g, ' ').split(' ').filter(el => el && el.trim() != '').map(el => el.charAt(0).toUpperCase() + el.substring(1)).join('_');
  }

  capitalizeSentence(sentence: string): string {
    if (!sentence) return sentence;
    let words = sentence.split(' ');
    words = words.map(word => {
      return word.length ? word.charAt(0).toUpperCase() + word.substring(1).toLowerCase() : word;
    });
    return words.join(' ');
  }

  getNomeUtilizadorIniciais(nome: string): string {
    if (!nome) return '';
    let nomes = nome.split(' ').filter(el => el && el.trim() !== '');
    return nomes[0].charAt(0) + (nomes.length > 1 ? nomes[nomes.length - 1].charAt(0) : '');
  }

  getIBANFormatted(iban: string): string {
    if (!iban) return null;
    iban = iban.trim();
    let formattedIBAN = '';
    formattedIBAN = this.insertStringAt(iban, ' ', 23);
    formattedIBAN = this.insertStringAt(formattedIBAN, ' ', 12);
    formattedIBAN = this.insertStringAt(formattedIBAN, ' ', 8);
    formattedIBAN = this.insertStringAt(formattedIBAN, ' ', 4);
    return formattedIBAN;
  }

  getRegistoComunicacaoCorrespondenciaEmail(entities: Array<EntitiesCorrespondenciaEmail>) {
    return entities.reduce((newArr, el) => {
      let emails = el.emails.split(';').filter(el => !!el).map(el => el.trim());
      let index = newArr.findIndex(e => e.cod_condominio === el.cod_condominio);
      if (!newArr || !newArr.length || index == -1) {
        newArr.push({ cod_condominio: el.cod_condominio, email_list: [{ tipo: el.tipo, cod: el.cod, cod_fraccao: el.cod_fraccao, emails: emails, nomeEntidade: el.nomeEntidade }] });
        return newArr;
      }
      newArr[index].email_list = newArr[index].email_list.concat({ tipo: el.tipo, cod: el.cod, cod_fraccao: el.cod_fraccao, emails: emails, nomeEntidade: el.nomeEntidade });
      return newArr;
    }, [] as Array<RegistoComunicacaoCorrespondenciaApi>);
  }

  getAvisosBody(texto_inicial: Array<string>, texto_final: Array<string>, data: { nib, nif, nome, avisos: Array<{ divida, dt_emissao, descricao, dt_vencimento, fraccao, n_aviso }> }, params: { reconciliation_date: Date }) {
    let bodyMsg = `<div style="text-align: justify;">Sr/Srª</div><div>` + data.nome + `</div>`;
    if (texto_inicial.length) {
      bodyMsg += `<div style="margin: 1.2em 0;"><p style="text-align:justify; margin: 0;">`
    }
    texto_inicial.forEach((line, i) => {
      if (line) {
        bodyMsg += (i != 0 ? '<br>' : '') + line;
      } else {
        bodyMsg += '<br>';
      }
    });

    if (texto_inicial.length) {
      bodyMsg += `  </p></div>`
    }

    if (data.nif && data.nib) {
      bodyMsg +=
        `<div><span>NIF: ` + data.nif + `</span><span style="padding-left: 15px;">IBAN: ` + this.getIBANFormatted(data.nib) + `</span></div>`;
    } else if (data.nif) {
      bodyMsg +=
        `<div><span>NIF: ` + data.nif + `</span></div>`;
    } else if (data.nib) {
      bodyMsg +=
        `<div><span>IBAN: ` + this.getIBANFormatted(data.nib) + `</span></div>`;
    }

    // AVISOS TABLE
    bodyMsg +=
      `<table class="table" style="border-collapse: collapse; width: 100%;  margin-bottom: 25px; margin-top: 15px;"><thead><tr><th style="border-bottom: 1px solid black; width: 120px !important;text-align: left;">Fracção</th><th style="border-bottom: 1px solid black; width: 100px !important;text-align: left;">Nº Doc</th><th style="border-bottom: 1px solid black; width: 120px !important;text-align: left;">Data Emis.</th><th style="border-bottom: 1px solid black; width: 120px !important;text-align: left;">Data Venc.</th><th style="border-bottom: 1px solid black; text-align: left;">Descrição</th><th style="border-bottom: 1px solid black; width: 100px !important;text-align: right;">Valor</th></tr></thead><tbody>`;

    let dataEmissao = null;
    let dataVencimento = null;


    let totalValor = 0;
    data.avisos.forEach(aviso => {
      aviso.divida = (Math.round(Number(aviso.divida) * 100) / 100).toFixed(2);
      totalValor += Math.round(Number(aviso.divida) * 100) / 100;
      dataEmissao = aviso.dt_emissao;
      dataVencimento = aviso.dt_vencimento;

      // try {
      //   dataEmissao = formatDate(aviso.dt_emissao, this.format, this.locale);
      // } catch {}

      // try {
      //   dataVencimento = formatDate(aviso.dt_vencimento, this.format, this.locale);
      // } catch {}
      // aviso.dt_emissao = new Date(aviso.dt_emissao);
      // aviso.dt_vencimento = new Date(aviso.dt_vencimento);

      bodyMsg +=
        `<tr><td style="text-align: left;">` + aviso.fraccao + `</td><td style="text-align: left;">A ` + aviso.n_aviso + `</td><td style="text-align: left;">` + dataEmissao + `</td><td style="text-align: left;">` + dataVencimento + `</td><td style="text-align: left;">` + aviso.descricao + `</td><td style="text-align: right;">` + aviso.divida + ` €</td></tr>`;
    });

    bodyMsg +=
      `<tr style="background-color: transparent !important;"><td style="font-weight: bold; font-size: 1.2em; padding-left: 0; margin-left: 0; padding-top: 15px; border-top: 1px solid black;" colspan="5">Total</td><td class="col-align-right" style="font-weight: bold; font-size: 1.2em; border-top: 1px solid black; text-align: right;">` + (Math.round(totalValor * 100) / 100).toFixed(2) + ` €</td></tr></tbody></table>`;
    // TEXTO FINAL
    if (texto_final.length) {
      bodyMsg += `<div class="prevent-split"><p style="text-align:justify; margin: 0;">`
      texto_final.forEach(line => {
        if (line) {
          bodyMsg += line + '<br>';
        } else {
          bodyMsg += '<br>';
        }
      });
      bodyMsg += `  </p></div>`
    }

    if (bodyMsg.match(/{{ RECONCILIATION_DATE }}/g)) {
      if (!params || !params.reconciliation_date) {
        this.toastr.error(this.appConfig.errorCodes.ERR_REC_017.msg, this.appConfig.errorCodes.ERR_REC_017.title);
        return null;
      }
      bodyMsg = bodyMsg.replace(/{{ RECONCILIATION_DATE }}/g, this.getFormatedDate(params.reconciliation_date));
    }

    return bodyMsg;
  }

  // REGISTO DE ACTIVIDADES
  getRegActividadeMsg(action: string, obj: { date: Date, valor: number, form_pagam?: string, conta?: string }): string {
    let msg = '';
    switch (action) {
      case 'DESPESA_LANCADA_AUTOPAY':
        msg = 'Despesa lançada com agendamento de pagamento automático.\nData de lançamento: ' + this.getFormatedDate(obj.date) + ', Valor: ' + obj.valor + ' €.';
        msg += '\nForma de Pagamento: ' + obj.form_pagam + ', Conta: ' + obj.conta + '.';
        break;
      case 'DESPESA_LANCADA':
        msg = 'Despesa lançada' + (obj.hasOwnProperty('form_pagam') ? ' com predefinição dos dados de pagamento' : '') + '.\nData de lançamento: ' + this.getFormatedDate(obj.date) + ', Valor: ' + obj.valor + ' €.'
        if (obj.hasOwnProperty('form_pagam')) {
          msg += '\nForma de pagamento: ' + obj.form_pagam;
          if (obj.hasOwnProperty('conta')) {
            msg += ', Conta: ' + obj.conta;
          }
          msg += '.';
        }
        break;
      case 'DESPESA_UPDATE_AUTOPAY':
        msg = 'Despesa atualizada com agendamento de pagamento automático.\nData de lançamento: ' + this.getFormatedDate(obj.date) + ', Valor: ' + obj.valor + ' €.';
        msg += '\nAgendamento de Pagamento Criado. Forma de Pagamento: ' + obj.form_pagam + ', Conta: ' + obj.conta + '.';
        break;
      case 'DESPESA_UPDATE':
        msg = 'Despesa atualizada' + (obj.hasOwnProperty('form_pagam') ? ' com predefinição dos dados de pagamento' : '') + '.\nData de lançamento: ' + this.getFormatedDate(obj.date) + ', Valor: ' + obj.valor + ' €.'
        if (obj.hasOwnProperty('form_pagam')) {
          msg += '\nForma de pagamento: ' + obj.form_pagam;
          if (obj.hasOwnProperty('conta')) {
            msg += ', Conta: ' + obj.conta;
          }
          msg += '.';
        }
        break;
      default:
        break;
    }
    return msg;
  }

  cleanDecimalDigits(n: number | string): number {
    return Math.round(Number(n) * 100) / 100;
  }

  cleanBase64Prefix(dataUri: string, ext: fileExt = '.pdf'): string {
    let prefix = this.getBase64Prefix(ext);
    let base64 = dataUri.replace(prefix, '');
    base64 = base64.replace(' ', '+');
    return base64;
  }

  getBase64Prefix(ext: fileExt) {
    let contentType = 'data:';
    switch (ext) {
      case '.pdf':
        contentType += 'application/pdf;';
        break;
      case '.jpeg':
        contentType += 'image/jpeg;';
        break;
      case '.jpg':
        contentType += 'image/jpg;';
        break;
      case '.png':
        contentType += 'image/png;';
        break;
      default:
        break;
    }
    contentType += 'base64,';
    return contentType;
  }

  downloadFile(link: string, filename: string) {
    let firstIndex = link.indexOf(':');
    let lastIndex = link.indexOf(';');
    if (!firstIndex || !lastIndex) {
      return;
    }
    firstIndex++;

    let contentType: contentType = (link.substring(firstIndex, lastIndex) as contentType);
    switch (contentType) {
      case 'image/jpeg':
        filename += '.jpeg';
        break;
      case 'image/jpg':
        filename += '.jpg';
        break;
      case 'image/png':
        filename += '.png';
        break;
      case 'application/pdf':
      default:
        filename += '.pdf';
        break;
    }

    filename = this.getFileNameFormatted(filename);

    saveAs(link, filename, {
      proxyURL: this.appConfig.fileProxyUrl,
      forceProxy: true,
      proxyTarget: '_blank',
    });

  }

  dblclickDate(ev: Event) {
    let b = (ev.target as HTMLElement);
    b.blur();
    ev.stopPropagation();
  }

  sleep(timeout: number): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, timeout);
    });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  // American Numbering System
  // th = ['', 'thousand', 'million', 'billion', 'trillion'];
  // // uncomment this line for English Number System
  // // var th = ['','thousand','million', 'milliard','billion'];

  // dg = ['zero', 'one', 'two', 'three', 'four',
  //     'five', 'six', 'seven', 'eight', 'nine'
  // ];
  // tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
  //     'seventeen', 'eighteen', 'nineteen'
  // ];
  // tw = ['twenty', 'thirty', 'forty', 'fifty',
  //     'sixty', 'seventy', 'eighty', 'ninety'
  // ];

  // toWords(s) {
  //   s = s.toString();
  //   s = s.replace(/[\, ]/g, '');
  //   if (s != parseFloat(s)) return 'not a number';
  //   var x = s.indexOf('.');
  //   if (x == -1) x = s.length;
  //   if (x > 15) return 'too big';
  //   var n = s.split('');
  //   var str = '';
  //   var sk = 0;
  //   for (var i = 0; i < x; i++) {
  //       if ((x - i) % 3 == 2) {
  //           if (n[i] == '1') {
  //               str += this.tn[Number(n[i + 1])] + ' ';
  //               i++;
  //               sk = 1;
  //           } else if (n[i] != 0) {
  //               str += this.tw[n[i] - 2] + ' ';
  //               sk = 1;
  //           }
  //       } else if (n[i] != 0) {
  //           str += this.dg[n[i]] + ' ';
  //           if ((x - i) % 3 == 0) str += 'hundred ';
  //           sk = 1;
  //       }
  //       if ((x - i) % 3 == 1) {
  //           if (sk) {
  //             str += th[(x - i - 1) / 3] + ' ';
  //           }
  //           sk = 0;
  //       }
  //   }
  //   if (x != s.length) {
  //     var y = s.length;
  //     str += 'point ';
  //     for (var i = x + 1; istr.replace(/\s+/g, ' ');
  //     }
  //   }
}
