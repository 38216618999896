import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ModalTemplate, SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { ReconciliacoesBancariasService } from '../business-logic-services/reconciliacoes-bancarias.service';
import { BusinessLogicService } from '../business-logic.service';
import { ReconciliacaoBancariaRegAtividade } from '../business-model-interfaces/reconciliacoes-bancarias';
import { PrintRecibosPdfComponent } from '../print-recibos-pdf/print-recibos-pdf.component';
import { UtilitiesService } from '../utilities.service';

interface IContext {
  data:string;
}
@Component({
  selector: 'app-view-reconciliacoes-activity-modal',
  templateUrl: './view-reconciliacoes-activity-modal.component.html',
  styleUrls: ['./view-reconciliacoes-activity-modal.component.scss']
})
export class ViewReconciliacoesActivityModalComponent implements OnInit {

  @ViewChild('reconcRegAtividadeAlertRef', { static: false }) reconcRegAtividadeAlertRef: ModalTemplate<any, any, any>;
  reconcRegAtividadeModalRef = null;
  reconcRegAtividadeAlertConfig: any = null;

  cod_conta = null;

  // FILTERS
  //Actions
  allUtilizadoresOpt = { name: 'Todos', value: '-1'}
  utilizadorSel = null;
  utilizadorOpts = [];

  //Dates
  startDate = new Date();
  endDate = new Date();

  constructor(
      public modalService: SuiModalService,
      public businessLogic: BusinessLogicService,
      public appConfig: AppConfigService,
      public utils: UtilitiesService,
      public api: ApiService,
      public toastr: ToastrService,
      public recBancariasService: ReconciliacoesBancariasService,
      public cdRef: ChangeDetectorRef,
    ) { }

  ngOnInit() {
    this.getUtilizadores();
  }

  ngAfterViewInit() {
    this.reconcRegAtividadeAlertConfig = new TemplateModalConfig<IContext, string, string>(this.reconcRegAtividadeAlertRef);
    this.reconcRegAtividadeAlertConfig.closeResult = "closed";
    this.reconcRegAtividadeAlertConfig.size = 'normal';
    this.reconcRegAtividadeAlertConfig.transition = 'fade';
    this.reconcRegAtividadeAlertConfig.transitionDuration = 250;
  }

  async open(cod_conta:string) {
    this.cod_conta = cod_conta;
    this.restoreComponent();
    this.getReconciliacaoRegAtividade();
    this.reconcRegAtividadeModalRef = this.modalService
      .open(this.reconcRegAtividadeAlertConfig)
      .onApprove(() => {this.reconcRegAtividadeModalRef = null})
      .onDeny(() => {this.reconcRegAtividadeModalRef = null});
  }

  restoreComponent(): void {
    this.startDate = new Date();
    this.startDate.setMonth(0);
    this.startDate.setDate(1);

    this.endDate = new Date();
    this.utilizadorSel = this.allUtilizadoresOpt.value;
    this.reconciliacaoRegAtividade = [];
  }

  getUtilizadores() {
    return new Promise((resolve) => {
      this.api.getUtilizadores().subscribe(res => {
        if (res.success) {
          this.utilizadorOpts = [this.allUtilizadoresOpt].concat(res.data.map(el => { return { name: el.nome_user, value: el.id }}));
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }


  reconciliacaoRegAtividade: Array<ReconciliacaoBancariaRegAtividade> = [];
  fetchingData = false;
  getReconciliacaoRegAtividade(): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.fetchingData = true;
      this.api.getReconciliacoesBancariasRegAtividade(this.cod_conta, this.startDate, this.endDate, this.utilizadorSel).subscribe(res => {
        if (res.success) {
          this.reconciliacaoRegAtividade = res.data.map(el => this.businessLogic.convertReconciliacaoBancariaRegAtividadeType(el));
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
        this.fetchingData = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.fetchingData = false;
        resolve(false);
      });
    })
  }

  @ViewChild('printRecibosPDF', { static: false }) printRecibosPDF: PrintRecibosPdfComponent;
  printRecibos(reconciliacao:ReconciliacaoBancariaRegAtividade):Promise<boolean> {
    return new Promise(async (resolve) => {
      let res = await this.printRecibosPDF.printRecibos(reconciliacao.rec_id);
      resolve(res);
    }) 
  }
}
