import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { fromEvent, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
import { Location, formatDate } from '@angular/common';
interface IContext {
  data:string;
}
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';

// IMPORT COMPONENTS
import { ReconciliacaoBancaria_Detailed, ReconciliacaoBancariaBD_Detailed, ReconciliacaoBancariaMovimento, ReconciliacaoBancariaEnvioRecibos } from '../business-model-interfaces/reconciliacoes-bancarias';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { BusinessLogicService } from '../business-logic.service';
import { ViewReconciliacoesActivityModalComponent } from '../view-reconciliacoes-activity-modal/view-reconciliacoes-activity-modal.component';
import { UploadModalDefaultComponent } from '../upload-modal-default/upload-modal-default.component';
import { AnexoUpload } from '../business-model-interfaces/anexo';
import { Movimento } from '../business-model-interfaces/movimento';
import { EnviarRecibosEmailComponent } from '../enviar-recibos-email/enviar-recibos-email.component';
import { ReconciliacoesBancariasService } from '../business-logic-services/reconciliacoes-bancarias.service';
import { ReciboDetailed } from '../business-model-interfaces/recibo';
import { PrintRecibosPdfComponent } from '../print-recibos-pdf/print-recibos-pdf.component';
import { SendEmailsByCondominioComponent } from '../send-emails-by-condominio/send-emails-by-condominio.component';
import { NoticePaymentsComponent } from '../notice-payments/notice-payments.component';
import { CondominiosService } from '../business-logic-services/condominios.service';


interface movimentoList {
  saldo,
  valor,
  dt_valor:Date,
  dt_mov:Date,
  descricao:string,
  checked:boolean,
  obj:any,
  id,
  reconciliado: { id_user, nome_iniciais, nome_user },
  beingReconciliadoPor:{ id_user, nome_iniciais, nome_user },
  reconciliacaoSequence,
  id_file_reconciliacao:string,
}

@Component({
  selector: 'app-contas-bancarias',
  templateUrl: './contas-bancarias.component.html',
  styleUrls: ['./contas-bancarias.component.scss']
})
export class ContasBancariasComponent implements OnInit {

  // PDF REPORT VARIABLES -----------------------------------------------------
  @ViewChild('pdf', { static: false }) pdfController;

  reportListColPDF = [
    { key: 'dt_valor', name: 'Data', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-report-col' },
    { key: 'dt_mov', name: 'Data Mov.', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered date-report-col' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'saldo-col col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'number', sort: null, searchable: true, centered: false, class: 'saldo-col col-align-right' },
  ];

  pdfReport = {
    title: null,
    reportType: 'Movimentos',
    startDate: null,
    endDate: null,
    now: new Date(),
  }
  pdfEnabled = false;

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('viewReconciliacoesModal', { static: false }) viewReconciliacoesModal: ViewReconciliacoesActivityModalComponent;

  @ViewChild('deleteReciboAlertRef', { static: false }) deleteReciboAlertRef;
  alertReciboModalRef = null;
  deleteReciboAlertConfig: any = null;

  @ViewChild('deleteRecAlertRef', { static: false }) deleteRecAlertRef;
  deleteRecModalRef = null;
  deleteRecAlertConfig: any = null;

  @ViewChild('cancelRecAlertRef', { static: false }) cancelRecAlertRef;
  cancelRecModalRef = null;
  cancelRecAlertConfig: any = null;
  

  @ViewChild('reconciliacaoAlertRef', { static: false }) reconciliacaoAlertRef;
  reconciliacaoModalRef = null;
  reconciliacaoAlertConfig: any = null;

  @ViewChild('changeCVAlertRef', { static: false }) changeCVAlertRef;
  changeCVModalRef = null;
  changeCVAlertConfig: any = null;
  
  transitionController = new TransitionController();
  submittingForm = false;
  loading = false;
  loadingModal = false;
  searchable: boolean = true;
  clearEntry = { name: '-- limpar selecção --', value: '-1' };
  toDelete = [];
  contasSearching = false;
  selEntryDate = null;

  condominioSelected = null;
  contaOpts = [];
  contaSelected = null;
  endDate = new Date();

  // startDate = new Date(this.endDate.getFullYear() - 1, 0, 1);
  startDate = null;
  keyword = null;

  valorTotal = null;
  totalContabilistico = null;
  aLiquidarTotalToDate = null;

  // MOVIMENTOS TABLE VARIABLES
  movimentosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, sortable: false, searchable: false, centered: false, class: 'table-checkbox-column one wide' },  // 'ASC', 'DESC'
    // { key: 'tipo_doc', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered two wide z-index-0' },
    { key: 'dt_valor', name: 'Data', type: 'date', sort: null, sortable: true, searchable: true, centered: true, class: 'col-centered two wide ' },
    { key: 'dt_mov', name: 'Data Mov.', type: 'date', sort: null, sortable: true, searchable: true, centered: true, class: 'col-centered two wide ' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, sortable: false, searchable: true, centered: false, class: 'nine wide' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, sortable: false, searchable: true, centered: false, class: 'col-align-right two wide' },
    { key: 'beingReconciliadoPor', name: '', type: 'icon', sort: null, sortable: false, searchable: true, centered: false, class: 'one wide' },
    { key: 'saldo', name: 'Saldo', type: 'number', sort: null, sortable: false, searchable: true, centered: false, class: 'col-align-right two wide' },
  ];
  movimentosList: Array<movimentoList> = [];
  movimentosListOrig: Array<movimentoList> = [];
  @ViewChild('movimentosTableSearch', { static: false }) movimentosTableSearch: ElementRef;
  movimentosKeyword: string = null;
  movimentosSearching: boolean = false;

  comp = 'contas-bancarias';
  initState = null;
  prevState = null;

  codCondominio = null;
  nomeCondominio = null;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public router: Router,
              public userSession: UserSessionService,
              public message: MessageService,
              public modalService: SuiModalService,
              public location: Location,
              public cdRef:ChangeDetectorRef,
              public appConfig: AppConfigService,
              public businessLogic: BusinessLogicService,
              public condominios: CondominiosService,
              public reconciliacoesService: ReconciliacoesBancariasService,
              public appState: AppStateService) {
    // GET GLOBAL STATE
    let globalState = this.appState.getGlobalState('global');
    if (globalState && globalState.hasOwnProperty('selCondominio')) {
      this.condominioSelected = (globalState.selCondominio) ? { name: globalState.selCondominio.cod + ' - ' + globalState.selCondominio.nome, value: globalState.selCondominio } : null;
      this.getContas();
      this.setCodCondominio();
    }
  }

  saveGlobalState() {
    if (this.condominioSelected && this.condominioSelected !== '-1') {
      this.appState.saveGlobalState('global', { 
        selCondominio: { id: this.condominioSelected.id, cod: this.condominioSelected.cod, nome: this.condominioSelected.nome, exercicio: this.condominioSelected.exercicio },
      });
    } else {
      this.appState.clearGlobalState();
    }
  }

  ngOnInit() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);

    if (this.prevState) {
      this.condominioSelected = (this.prevState.state.condominioSelected) ? { name: this.prevState.state.condominioSelected.cod + ' - ' + this.prevState.state.condominioSelected.nome, value: this.prevState.state.condominioSelected } : null;
      this.getContas();
    }

    this.loadReconciliacaoPermissions();

    this.animate();
  }

  ngOnDestroy() {
    if (this.router.url.indexOf('lancamentos') !== -1) {
      if (!this.condominioSelected || this.condominioSelected === '-1') return;

      let auxLancState = this.appState.getPrevState('lancamentos');

      let cod = (this.condominioSelected.hasOwnProperty('cod')) ? this.condominioSelected.cod : this.condominioSelected.value.cod

      if (auxLancState) {
        if (cod && auxLancState.state.selCondominio.cod !== cod) {
          this.appState.clearPrevState('lancamentos');

          this.appState.setPrevState('lancamentos', { 
            selCondominio: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
            activeTab: (auxLancState.hasOwnProperty('activeTab')) ? auxLancState.activeTab : null,
          });
        }
      } else {
        this.appState.setPrevState('lancamentos', { 
          selCondominio: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
        });
      }
    }

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    setTimeout(() => { 
      if (this.apiSub) this.apiSub.unsubscribe();
      if (this.apiSub1) this.apiSub1.unsubscribe();
      if (this.apiSub2) this.apiSub2.unsubscribe();
      if (this.apiSub3) this.apiSub3.unsubscribe();
    }, 1);
  }

  ngAfterViewChecked() { 
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    // MOVIMENTOS INPUT SEARCH
    fromEvent(this.movimentosTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.contasSearching = true;
      this.tableSearch(val['target']['value']);
      this.contasSearching = false;
    });

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.deleteReciboAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteReciboAlertRef);
    this.deleteReciboAlertConfig.closeResult = "closed";
    this.deleteReciboAlertConfig.size = 'tiny';
    this.deleteReciboAlertConfig.transition = 'fade';
    this.deleteReciboAlertConfig.transitionDuration = 250;

    this.deleteRecAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteRecAlertRef);
    this.deleteRecAlertConfig.isClosable = false;
    this.deleteRecAlertConfig.closeResult = "closed";
    this.deleteRecAlertConfig.size = 'small';
    this.deleteRecAlertConfig.transition = 'fade';
    this.deleteRecAlertConfig.transitionDuration = 250;

    this.cancelRecAlertConfig = new TemplateModalConfig<IContext, string, string>(this.cancelRecAlertRef);
    this.cancelRecAlertConfig.isClosable = false;
    this.cancelRecAlertConfig.closeResult = "closed";
    this.cancelRecAlertConfig.size = 'small';
    this.cancelRecAlertConfig.transition = 'fade';
    this.cancelRecAlertConfig.transitionDuration = 250;

    this.reconciliacaoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.reconciliacaoAlertRef);
    this.reconciliacaoAlertConfig.isClosable = false;
    this.reconciliacaoAlertConfig.closeResult = "closed";
    this.reconciliacaoAlertConfig.size = 'small';
    this.reconciliacaoAlertConfig.transition = 'fade';
    this.reconciliacaoAlertConfig.transitionDuration = 250;

    this.changeCVAlertConfig = new TemplateModalConfig<IContext, string, string>(this.changeCVAlertRef);
    this.changeCVAlertConfig.closeResult = "closed";
    this.changeCVAlertConfig.size = 'small';
    this.changeCVAlertConfig.transition = 'fade';
    this.changeCVAlertConfig.transitionDuration = 250;

    this.conferencenciaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.conferencenciaAlertRef);
    this.conferencenciaAlertConfig.closeResult = "closed";
    this.conferencenciaAlertConfig.size = 'tiny';
    this.conferencenciaAlertConfig.transition = 'fade';
    this.conferencenciaAlertConfig.isClosable = false;
    this.conferencenciaAlertConfig.transitionDuration = 250;
    this.changeCVAlertConfig.transitionDuration = 250;
    
    this.recDateDontMatchAlertConfig = new TemplateModalConfig<IContext, string, string>(this.recDateDontMatchAlertRef);
    this.recDateDontMatchAlertConfig.closeResult = "closed";
    this.recDateDontMatchAlertConfig.size = 'tiny';
    this.recDateDontMatchAlertConfig.transition = 'fade';
    this.recDateDontMatchAlertConfig.transitionDuration = 250;
    this.changeCVAlertConfig.transitionDuration = 250;
    
    this.startRecAlertConfig = new TemplateModalConfig<IContext, string, string>(this.startRecAlertRef);
    this.startRecAlertConfig.closeResult = "closed";
    this.startRecAlertConfig.size = 'small';
    this.startRecAlertConfig.transition = 'fade';
    this.startRecAlertConfig.transitionDuration = 250;
    
    this.deleteReconciliacaoRecibosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteReconciliacaoRecibosAlertRef);
    this.deleteReconciliacaoRecibosAlertConfig.closeResult = "closed";
    this.deleteReconciliacaoRecibosAlertConfig.size = 'tiny';
    this.deleteReconciliacaoRecibosAlertConfig.transition = 'fade';
    this.deleteReconciliacaoRecibosAlertConfig.transitionDuration = 250;
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.condominioSelected); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
                } else {
                  return resolve([this.clearEntry]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
            } else {
              return resolve([this.clearEntry]);
            }
          });
        }
    });
  };

  getContas() {
    this.contaSelected = null;
    this.movimentosListOrig = [];
    this.movimentosList = [];

    if (this.condominioSelected === '-1') return;

    let cod = (this.condominioSelected.hasOwnProperty('cod')) ? this.condominioSelected.cod : this.condominioSelected.value.cod;
    this.api.getCondContasDetails(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.contaOpts =  res.data.map(el => {
          return { name: el.banco, value: el };
        });

        if (this.prevState) {
          this.contaSelected = (this.prevState.state.contaSelected) ? this.contaOpts.find(el => el.value.id === this.prevState.state.contaSelected.id).value : null;
          this.startDate = (this.prevState.state.startDate) ? this.prevState.state.startDate : this.startDate;
          this.endDate = (this.prevState.state.endDate) ? this.prevState.state.endDate : this.endDate;
          this.movimentosKeyword = this.prevState.state.movimentosKeyword;

          this.getMovimentos();
          this.appState.clearPrevState(this.comp);
        } else {
          this.getMovimentos();
        }

      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });

  }

  apiSub = null;
  fetchingMovimentos = false;
  totalVisibleList = 0;
  canSendReconciliacaoCommunications = false;
  lastReconciliacao:ReconciliacaoBancaria_Detailed = null;
  reconciliacaoEmCurso:ReconciliacaoBancaria_Detailed = null;
  reconciliacoesBancarias:Array<ReconciliacaoBancaria_Detailed> = [];
  watchingReconciliacoesDetails = false;
  userIsDoingReconciliacao = false;
  isBanco = false;
  getMovimentos() {
    if (this.apiSub) {
      this.apiSub.unsubscribe();
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }

    if (this.fetchingMovimentos) {
      if (!this.contaSelected) {
        let aux = this.contaOpts.find(el => (el.name === 'CAIXA'));
        this.contaSelected = (aux) ? aux.value : null;
      };
      return;
    }

    if (!this.contaSelected) {
      let aux = this.contaOpts.find(el => (el.name === 'CAIXA'));
      this.contaSelected = (aux) ? aux.value : null;

      if (!this.contaSelected) return;
    };

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    let cod = (this.contaSelected.hasOwnProperty('cod')) ? this.contaSelected.cod : this.contaSelected.value.cod;

    let req = [
      this.api.getMovimentos(cod, this.startDate, this.endDate),
      this.api.getSaldoMovimentos(cod),
      this.api.getSaldoMovimentos(cod, new Date()),
      this.api.getSaldoMovimentos(cod, (this.endDate) ? this.endDate : null),
      this.api.getReconciliacoesBancarias(cod),
      this.api.getReconciliacaoEmCurso(cod),
    ];

    this.apiSub = forkJoin(req).subscribe(res => {
      if (res[0].hasOwnProperty('success') && res[0].success) {
        this.reconciliacoesBancarias = res[4].success? ((res[4].data as Array<ReconciliacaoBancariaBD_Detailed>).map(el => this.businessLogic.convertReconciliacaoBancariaType(el)) as Array<ReconciliacaoBancaria_Detailed>) : [];
        this.lastReconciliacao = this.reconciliacoesBancarias.reduce((acc, el) => {
          if (!acc) {
            acc = el;
          } else {
            let diff = this.utils.compareDayDates(el.data_reconciliacao, acc.data_reconciliacao);
            if (diff > 0) {
              acc = el;
            } else if (diff === 0) {
              if (el.id > acc.id) acc = el;
            }
          }
          return acc;
        }, null)
        this.canSendReconciliacaoCommunications = this.lastReconciliacao && this.lastReconciliacao.comunicacoes_enviadas === '0' && this.utils.compareDayDates(this.lastReconciliacao.data, new Date()) === 0 && this.contaSelected.conta_principal === '1';
        this.reconciliacaoEmCurso = res[5].success? this.businessLogic.convertReconciliacaoBancariaType(res[5].data) as ReconciliacaoBancaria_Detailed : null;
        this.userIsDoingReconciliacao = this.reconciliacaoEmCurso && this.reconciliacaoEmCurso.id_user === this.userSession.getUserId();
        this.watchingReconciliacoesDetails = this.userIsDoingReconciliacao && this.canCreateReconciliacao;

        this.emailConfigs.reconciliacaoDate = this.lastReconciliacao? this.lastReconciliacao.data_reconciliacao : null;

        this.totalVisibleList = 0;
        this.movimentosListOrig = res[0].data.map(el => {
          this.totalVisibleList += Number(el['valor']);
          if (el.id == '161021') console.log(el);
          
          el['saldo'] = 0;
          el['valor'] = Number(parseFloat(el['valor']).toFixed(2));
          el['dt_valor'] = (el['dt_valor']) ? this.utils.getDate(el['dt_valor']) : null;
          el['dt_mov'] = (el['dt_mov']) ? this.utils.getDate(el['dt_mov']) : null;
          el['checked'] = false;
          
          try {
            el['obj'] = JSON.parse(el['obj']);
          } catch {
            el['obj'] = null;
          }
          el['id_file_reconciliacao'] = null;
          return el;
        });

        this.loadReconciliacao();

        this.totalVisibleList = Math.round(this.totalVisibleList * 100) / 100;
        this.movimentosList = this.movimentosListOrig;

        this.filterList();

        this.totalContabilistico = Number(res[1].data.saldo);
        this.aLiquidarTotalToDate = Number(res[2].data.saldo);
        this.valorTotal = Number(res[3].data.saldo);

        if (this.contaSelected.hasOwnProperty('cod')) {
          this.isBanco = (this.contaSelected.banco !== 'CAIXA');
        } else {
          this.isBanco = (this.contaSelected.value.banco !== 'CAIXA');
        }

        if (this.movimentosList.length > 0) {
          this.movimentosList.forEach((el, i) => {
            if (i === 0) {
              el['saldo'] = this.valorTotal;
            } else {
              el['saldo'] = this.movimentosList[i - 1]['saldo'] - this.movimentosList[i - 1]['valor'];
            }
          });

          if (this.valorTotal !== this.totalVisibleList) {
            this.movimentosList.push({
              dt_valor: this.startDate,
              dt_mov: this.startDate,
              descricao: 'SALDO ANTERIOR',
              valor: this.utils.cleanDecimalDigits(this.valorTotal - this.totalVisibleList),
              saldo: this.utils.cleanDecimalDigits(this.valorTotal - this.totalVisibleList),
              checked:false,
              obj:[],
              id:null,
              reconciliado:{ id_user: 1, nome_iniciais: '', nome_user: 'Automático' },
              beingReconciliadoPor: null,
              reconciliacaoSequence:false,
              id_file_reconciliacao: null,
            });
          }
        }

        this.appState.clearPrevState(this.comp);
        this.prevState = null;

        this.condominios.hasTitularesPorEfetivar(this.codCondominio, cod).then(hasTitulares => {
          if (hasTitulares) {
            let msg = 'O conta selecionada possui titulares bancários que carecem de validação.';
            let toast = this.toastr.findDuplicate(msg, false, false);
            if (!!toast) {
              this.toastr.clear(toast.toastId);
            }
            
            setTimeout(() => {
              this.toastr.warning(msg, 'Atenção');
            }, 300);
            
          }
        }).catch();
        
        this.computeTotals();
      } else {
        this.utils.apiErrorMsg(res[0]);
      }
      this.fetchingMovimentos = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.apiSub = null;
    }, err => {
      this.fetchingMovimentos = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.apiSub = null;
    });
  }

  apiSub1 = null;
  apiSub2 = null;
  apiSub3 = null;
  getSaldos() {
    this.valorTotal = null;
    this.aLiquidarTotalToDate = null;
    this.totalContabilistico = null;

    if (this.apiSub1) {
      this.apiSub1.unsubscribe();
      // this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }

    let cod = (this.contaSelected.hasOwnProperty('cod')) ? this.contaSelected.cod : this.contaSelected.value.cod;

    let req = [
      this.api.getSaldoMovimentos(cod),
      this.api.getSaldoMovimentos(cod, new Date()),
      this.api.getSaldoMovimentos(cod, (this.endDate) ? this.endDate : null),
    ];
    this.apiSub1 = forkJoin(req).subscribe(res => {
      if (res[0].hasOwnProperty('success') && res[0].success) {
        this.totalContabilistico = Number(res[0].data.saldo);
        this.aLiquidarTotalToDate = Number(res[1].data.saldo);
        this.valorTotal = Number(res[2].data.saldo);

        if (this.movimentosList.length > 0) {
          this.movimentosList.forEach((el, i) => {
            if (i === 0) {
              el['saldo'] = this.valorTotal;
            } else {
              el['saldo'] = this.utils.cleanDecimalDigits(this.movimentosList[i -1]['saldo'] - this.movimentosList[i -1]['valor']);
            }
          });

          if (this.valorTotal !== this.totalVisibleList) {
            this.movimentosList.push({
              dt_valor: this.startDate,
              dt_mov: this.startDate,
              descricao: 'SALDO ANTERIOR',
              valor: this.utils.cleanDecimalDigits(this.valorTotal - this.totalVisibleList),
              saldo: this.utils.cleanDecimalDigits(this.valorTotal - this.totalVisibleList),
              checked:false,
              obj:[],
              id:null,
              reconciliado: { id_user: 1, nome_iniciais: '', nome_user: 'Automático' },
              beingReconciliadoPor:null,
              reconciliacaoSequence:false,
              id_file_reconciliacao: null,
            });
          }
        }

      } else {
        this.utils.apiErrorMsg(res[0]);
      }
      this.apiSub1 = null;
      // this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.apiSub1 = null;
      // this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  goToDetails(movimento:movimentoList, col:string) {
    switch (col) {
      case 'VALOR':
      case 'RECONCILIACAO':
        if (!movimento.reconciliado && this.watchingReconciliacoesDetails && this.userIsDoingReconciliacao) {
          this.checkMovimentoReconciliacaoBtn(movimento);
          return;
        }
        break;
        case 'SALDO':
          if (movimento.reconciliado || (this.userIsDoingReconciliacao && this.watchingReconciliacoesDetails)) {
            this.setReconciliacaoBtn(movimento.id);
            return;
          }
          break;
      default:
        break;
    }

    if (movimento.hasOwnProperty('dt_mov') && movimento.dt_mov === null) return;

    if (movimento.hasOwnProperty('obj') && movimento.obj && (movimento.obj.hasOwnProperty('credito_id') || movimento.obj.hasOwnProperty('n_credito') || movimento.obj.hasOwnProperty('n_recibo') || movimento.obj.hasOwnProperty('n_despesa') || movimento.obj.hasOwnProperty('n_receita'))) {
      if (this.condominioSelected) {
        this.appState.setPrevState(this.comp, { 
          condominioSelected: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
          contaSelected: this.contaSelected,
          startDate: this.startDate,
          endDate: this.endDate,
          movimentosKeyword: this.movimentosKeyword,
        });
      }

      if (movimento.obj.hasOwnProperty('credito_id')) {
        this.router.navigate(['lancamentos/credito', movimento.obj.credito_id]);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `CRÉDITO / ${movimento.obj.n_credito}` });
      }

      if (movimento.obj.hasOwnProperty('recibo_id')) {
        this.router.navigate(['lancamentos/recibo', movimento.obj.recibo_id]);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RECIBO / ${movimento.obj.n_recibo}` });
      }

      if (movimento.obj.hasOwnProperty('despesa_id')) {
        this.router.navigate(['lancamentos/despesa', movimento.obj.despesa_id]);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `DESPESA / ${movimento.obj.n_despesa}` });
      }

      if (movimento.obj.hasOwnProperty('receita_id')) {
        this.router.navigate(['lancamentos/receita', movimento.obj.receita_id]);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RECEITA / ${movimento.obj.n_receita}` });
      }
    } else {
      // SAVE THIS STATE
      if (this.condominioSelected) {
        this.appState.setPrevState(this.comp, { 
          condominioSelected: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
          contaSelected: this.contaSelected,
          startDate: this.startDate,
          endDate: this.endDate,
          movimentosKeyword: this.movimentosKeyword,
        });
      }

      this.router.navigate(['movimentosbancarios', movimento.id]);

      // Emit signal to breadcrumb component
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${movimento.id}` });
    }
  }

  async tableAction(action) {
    switch (action) {
      case 'csv': break;
      case 'pdf': break;
      case 'print': break;
      case 'add-deposito':
        // SAVE THIS STATE
        if (this.condominioSelected) {
          this.appState.setPrevState(this.comp, { 
            condominioSelected: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
            contaSelected: this.contaSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            movimentosKeyword: this.movimentosKeyword,
          });
        }

        // FORWARD INIT STATE FOR NEXT COMPONENT
        if (this.condominioSelected) {
          this.appState.setInitState('movimentos-details', {
            condominioSelected: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
          });
        }

        this.router.navigate(['movimentosbancarios', 'criar-deposito']);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVOS DEPÓSITOS` });
        break;
      case 'add-levantamento':
        // SAVE THIS STATE
        if (this.condominioSelected) {
          this.appState.setPrevState(this.comp, { 
            condominioSelected: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
            contaSelected: this.contaSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            movimentosKeyword: this.movimentosKeyword,
          });
        }

        // FORWARD INIT STATE FOR NEXT COMPONENT
        if (this.condominioSelected) {
          this.appState.setInitState('movimentos-details', {
            condominioSelected: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
          });
        }

        this.router.navigate(['movimentosbancarios', 'criar-levantamento']);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVOS LEVANTAMENTOS` });
        break;
      case 'add-transferencia':
        // SAVE THIS STATE
        if (this.condominioSelected) {
          this.appState.setPrevState(this.comp, { 
            condominioSelected: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
            contaSelected: this.contaSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            movimentosKeyword: this.movimentosKeyword,
          });
        }

        // FORWARD INIT STATE FOR NEXT COMPONENT
        if (this.condominioSelected) {
          this.appState.setInitState('movimentos-details', {
            condominioSelected: (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value : this.condominioSelected,
          });
        }

        this.router.navigate(['movimentosbancarios', 'criar-transferencia']);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVAS TRANSFERÊNCIAS` });
        break;
      case 'delete':
        this.toDelete = this.movimentosList.filter(el => el.checked);
        if (this.toDelete.length > 0) {
          this.presentAlert();
        } else {
          this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
        }
        break;
    }
  }

  tableSearch(keyword, formSearch=true) {
    this.movimentosKeyword = keyword.toLowerCase().trim();

    if (this.movimentosKeyword) {
      this.movimentosList = this.utils.tableSearch(this.movimentosKeyword, this.movimentosListCol.filter(el => el.searchable), this.movimentosList);
    } else {
      this.movimentosList = this.movimentosListOrig;
    }

    if (formSearch) {
      this.filterList();
    } else {
      this.computeTotals();
    }
  }

  filterList() {
    if (this.startDate && this.endDate) {
      this.movimentosList = this.movimentosListOrig.filter(el => (el.dt_mov >= this.startDate && el.dt_mov <= this.endDate));
    } else if (this.startDate) {
      this.movimentosList = this.movimentosListOrig.filter(el => (el.dt_mov >= this.startDate));
    } else if (this.endDate) {
      this.movimentosList = this.movimentosListOrig.filter(el => (el.dt_mov <= this.endDate));
    }

    if (this.movimentosKeyword) this.tableSearch(this.movimentosKeyword, false);

    this.computeTotals();
  }

  submittingFormRecibo = false;
  razaoReciboDelete = null;
  presentAlert() {
    let descricao = this.toDelete[0].descricao;

    // CHECK IF THIS IS A RECIBO
    if (descricao && descricao.match(/^R \d{1,}/g) !== null) {
      this.alertReciboModalRef = this.modalService
        .open(this.deleteReciboAlertConfig)
        .onApprove(() => { this.loadingModal = false; this.toDelete = []; this.razaoReciboDelete = null; this.submittingFormRecibo = false; })
        .onDeny(() => { this.loadingModal = false; this.toDelete = []; this.razaoReciboDelete = null; this.submittingFormRecibo = false; });
    } else {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => { this.loadingModal = false; this.toDelete = []; })
        .onDeny(() => { this.loadingModal = false; this.toDelete = []; });
    }

  }


  del(target=null) {
    
    if (target === 'RECIBO') {
      this.submittingFormRecibo = true;
      setTimeout(() => {
        this.submittingFormRecibo = false;
      }, 4000);

      if (!this.razaoReciboDelete) return;
    }

    let cod = (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value.cod : this.condominioSelected.cod;

    this.loadingModal = true;

    let toDeleteDespesas = [];
    let toDeleteRecibos = [];
    let toDeleteReceitas = [];
    let toDeleteCreditos = [];
    let toDeleteRest = [];

    let despObj = null;

    this.toDelete.forEach(mov => {
      if (mov.obj) {
        if (mov.obj.hasOwnProperty('despesa_id')) {
          let movValor = (mov.valor > 0) ? mov.valor : -1 * mov.valor;
          despObj = {
            msg: 'Movimento removido. Data de pagamento: ' + this.utils.getFormatedDate(mov.dt_mov) + ', Conta: ' + mov.banco + ', Valor: ' + movValor + ' €.',
            activity: 'Actualizado por ',
            user: this.userSession.getUserFullName(),
            date: new Date(),
          };

          toDeleteDespesas.push({ dt_mov: this.utils.getFormatedDate(mov.dt_mov), id: mov.obj.despesa_id, cod_condominio: cod, n_despesa: mov.obj.n_despesa, valor: mov.valor, action: 'REMOVE_MOV', id_mov: mov.id, nid_conta: mov.nid_conta, desp_obj: JSON.stringify(despObj) });
        } else if (mov.obj.hasOwnProperty('receita_id')) {
          let movValor = (mov.valor > 0) ? mov.valor : -1 * mov.valor;
          despObj = {
            msg: 'Movimento removido. Data de pagamento: ' + this.utils.getFormatedDate(mov.dt_mov) + ', Conta: ' + mov.banco + ', Valor: ' + movValor + ' €.',
            activity: 'Actualizado por ',
            user: this.userSession.getUserFullName(),
            date: new Date(),
          };

          toDeleteReceitas.push({ dt_mov: this.utils.getFormatedDate(mov.dt_mov), id: mov.obj.receita_id, cod_condominio: cod, n_receita: mov.obj.n_receita, valor: mov.valor, action: 'REMOVE_MOV', id_mov: mov.id, nid_conta: mov.nid_conta, desp_obj: JSON.stringify(despObj) });
        } else if (mov.obj.hasOwnProperty('recibo_id')) {
          toDeleteRecibos.push({ dt_mov: this.utils.getFormatedDate(mov.dt_mov), id: mov.obj.recibo_id, cod_condominio: cod, n_recibo: mov.obj.n_recibo, cod: 'GET_COD', del_razao: this.razaoReciboDelete, alterado_por: this.userSession.getUserFullName(), alterado_em: new Date() });
        } else if (mov.obj.hasOwnProperty('credito_id')) {
          toDeleteCreditos.push({ dt_mov: this.utils.getFormatedDate(mov.dt_mov), id: mov.obj.credito_id, cod_condominio: cod, n_credito: mov.obj.n_credito, valor: mov.valor, action: 'REMOVE_MOV_BLOCKED', id_mov: mov.id, nid_conta: mov.nid_conta });
        } else {
          toDeleteRest.push({...mov, dt_mov: mov.dt_mov? this.utils.getFormatedDate(mov.dt_mov) : null});
        }
      } else {
        toDeleteRest.push(mov);
      }

    });

    let req = [];

    if (toDeleteDespesas.length > 0) req.push(this.api.delDespesas(toDeleteDespesas, 'MOVIMENTOS_BANCARIOS'));

    if (toDeleteRecibos.length > 0) req.push(this.api.delRecibos(toDeleteRecibos, 'MOVIMENTOS_BANCARIOS'));

    if (toDeleteReceitas.length > 0) req.push(this.api.delReceitas(toDeleteReceitas, 'MOVIMENTOS_BANCARIOS'));

    if (toDeleteCreditos.length > 0) req.push(this.api.delCreditos(toDeleteCreditos, 'MOVIMENTOS_BANCARIOS'));

    if (toDeleteRest.length > 0) req.push(this.api.delMovimentos(toDeleteRest));

    forkJoin(req).subscribe(res => {

      if (res[0].hasOwnProperty('success') && res[0].success) {

        this.toDelete.forEach(del => {
          this.movimentosList = this.movimentosList.filter(el => (el.id !== del.id));
          this.movimentosListOrig = this.movimentosListOrig.filter(el => (el.id !== del.id));
        });

        // UPDATE SALDOS
        this.getSaldos();

        let cod = (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value.cod : this.condominioSelected.cod;
        let nome = (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value.nome : this.condominioSelected.nome;
        let req = [];
        this.toDelete.forEach(el => {
          // REGISTO ACTIVIDADES API CALL
          let titulo = 'Movimento Financeiro Removido'
          let descricao = 'Condomínio: ' + cod + ' - ' + nome + ', Descrição: ' + el.descricao;
          req.push(this.api.saveRegistoActividade(cod, null, null, titulo, descricao));
        });
        forkJoin(req).subscribe(res => {}, err => { });

      } else {
        this.utils.apiErrorMsg(res);
      }
      if (this.alertModalRef) this.alertModalRef.approve();
      if (this.alertReciboModalRef) this.alertReciboModalRef.approve();
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });

  }

  rowSelectionToggle(ev) {
        (ev.target.checked) ? this.movimentosList.map(el => el.checked = true ) : this.movimentosList.map(el => el.checked = false );
  }

  valorTotalList = 0;
  computeTotals() {
    this.valorTotalList = 0;
    this.movimentosList.forEach(el => {
      this.valorTotalList += (el.valor) ? el.valor : 0;
    });
  }

  tableSort(key, sortable) {
    if (!sortable) return;

    if (key === 'dt_mov') {
      let col = this.movimentosListCol.find(el => el.key === 'dt_mov')
      if (col.sort === 'ASC') {
        col.sort = 'DESC';
        this.movimentosList.sort((a,b) => {
          let diff = this.utils.compareDayDates(b.dt_mov, a.dt_mov);
          if (diff !== 0) return diff;
          return b.id - a.id;
        });
      } else if (!col.sort || col.sort === 'DESC') {
        col.sort = 'ASC';
        this.movimentosList.sort((a,b) => {
          let diff = this.utils.compareDayDates(a.dt_mov, b.dt_mov);
          if (diff !== 0) return diff;
          return a.id - b.id;
        });
      } else {
        col.sort = null;
      }
    }
    // this.utils.tableSort(this.movimentosListCol, this.movimentosList, key);
  }

  reportGenerated = false;
  movimentosListPDF = [];
  exportPDF() {
    if (this.condominioSelected === '-1' || !this.contaSelected || this.fetchingMovimentos) return;

    this.toastr.info('Por favor aguarde, o documento está a ser criado...', 'Gerar Documento');

    this.pdfReport['title'] = (this.condominioSelected.hasOwnProperty('cod')) ? this.condominioSelected.cod + ' - ' + this.condominioSelected.nome : this.condominioSelected.value.cod + ' - ' + this.condominioSelected.value.nome;
    this.pdfReport['reportType'] = 'Movimentos';
    this.pdfReport['reportType'] += (' - ' + this.contaSelected.banco);
    this.pdfReport['startDate'] = this.startDate;
    this.pdfReport['endDate'] = this.endDate;

    setTimeout(() => {
      this.movimentosListPDF = [].concat(this.movimentosList);

      // HANDLE LIST DIRECTION
      let firstDate = this.utils.getDate(this.movimentosList[0].dt_mov);
      let lastDate = this.utils.getDate(this.movimentosList[this.movimentosList.length - 1].dt_mov);

      if ((firstDate && lastDate && lastDate.getTime() < firstDate.getTime())) {
        this.movimentosListPDF.reverse();
      }

      this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
      this.pdfController.forceProxy = true;
      this.pdfController.proxyTarget = '_blank';
  
      let filename = this.pdfReport.title.replace(/ /g, '_') + '_'
        + ((this.pdfReport.startDate) ? formatDate(this.pdfReport.startDate, this.format, this.locale) : '') + '_'
        + formatDate(this.pdfReport.endDate, this.format, this.locale) + '_'
        + this.pdfReport['reportType'].replace(/ /g, '_');
      filename = filename.replace(/,/g, '');
      
      setTimeout(() => { this.pdfController.saveAs(filename + '.pdf'); }, 1);
  
      this.reportGenerated = true;
    }, 50);
  }

  exportExcel() {
    this.pdfReport['title'] = (this.condominioSelected.hasOwnProperty('cod')) ? this.condominioSelected.cod + ' - ' + this.condominioSelected.nome : this.condominioSelected.value.cod + ' - ' + this.condominioSelected.value.nome;
    this.pdfReport['reportType'] = 'Movimentos';
    this.pdfReport['reportType'] += (' - ' + this.contaSelected.banco);
    this.pdfReport['startDate'] = this.startDate;
    this.pdfReport['endDate'] = this.endDate;

    let filename = this.pdfReport.title.replace(/ /g, '_') + '_'
      + ((this.pdfReport.startDate) ? formatDate(this.pdfReport.startDate, this.format, this.locale) : '') + '_'
      + formatDate(this.pdfReport.endDate, this.format, this.locale) + '_'
      + this.pdfReport['reportType'].replace(/ /g, '_');
    filename = filename.replace(/,/g, '');

    let excelData = [];
    let worksheet: XLSX.WorkSheet = null;
    let aux = null;

    // ADD HEADER
    excelData = [{ dt_valor: 'Data', dt_mov: 'Data Mov.', descricao: 'Descrição', valor: 'Valor [€]', saldo: 'Saldo [€]' }];

    let movimentosList = [].concat(this.movimentosList);
    // HANDLE LIST DIRECTION
    let firstDate = this.utils.getDate(this.movimentosList[0].dt_mov);
    let lastDate = this.utils.getDate(this.movimentosList[this.movimentosList.length - 1].dt_mov);
    if ((firstDate && lastDate && lastDate.getTime() < firstDate.getTime())) {
      this.movimentosListPDF.reverse();
    }

    movimentosList.forEach(el => {
      aux = {
        dt_valor: el.dt_valor ? this.utils.getFormatedDate(el.dt_valor) : null,
        dt_mov: el.dt_mov ? this.utils.getFormatedDate(el.dt_mov): null,
        descricao: el.descricao,
        valor: (Math.round(Number(el['valor']) * 100) / 100).toString().replace('.', ','),
        saldo: (Math.round(Number(el['saldo']) * 100) / 100).toString().replace('.', ','),
      }
      excelData.push(aux);
    });
    excelData.push({ dt_valor: null, dt_mov: null, descricao: null, valor: null, saldo: null });
    excelData.push({ dt_valor: 'Total', dt_mov: null, descricao: null, valor: this.utils.cleanDecimalDigits(this.valorTotalList).toString().replace('.', ','), saldo: null });

    worksheet = XLSX.utils.json_to_sheet(excelData, { header:['dt_valor','dt_mov', 'descricao', 'valor', 'saldo'], skipHeader: true });
    worksheet['!cols'] = this.utils.fitToColumn(excelData);

    let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    let data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});

    FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
  }

  movSelected(mov) {
    setTimeout(() => {

      if (!mov.checked) return;

      let auxTipoMov = mov.descricao.trim().split(' ');

      if (auxTipoMov.length > 1 && (auxTipoMov[0] === 'R' || auxTipoMov[0] === 'RE' || auxTipoMov[0] === 'D' || auxTipoMov[0] === 'C') && mov.obj === null ) {
        this.toastr.error('Não é possível proceder à remoção deste movimento. Por favor, remova o documento correspondente associado.', 'Alerta', { timeOut: 4000 });
        mov.checked = false;
      } else {
        // CHECK FOR MOVIMENTO EM CAIXA VERTIS
        let cod = (this.condominioSelected.hasOwnProperty('value')) ? this.condominioSelected.value.cod : this.condominioSelected.cod;
  
        if (mov.obj) {
          if (mov.obj.hasOwnProperty('despesa_id')) {

            this.api.hasCaixaVertisMovimento(cod, 'D', mov.obj.n_despesa).subscribe(res => {
              if (res.hasOwnProperty('success') && res.success) {
                if (res.status && res.dates) {
                  let dates = [];
                  res.dates.forEach(el => {
                    dates.push(this.utils.getDate(el));
                  });
                  this.handleDeletePermissions(mov, dates, 'Despesa',
                    'Por favor contacte um administrador para eliminar a despesa selecionada.',
                    'Por favor contacte o administrador de sistema para eliminar a despesa selecionada.');
                }
              }
            }, err => {});

          } else if (mov.obj.hasOwnProperty('receita_id')) {

            this.api.hasCaixaVertisMovimento(cod, 'RE', mov.obj.n_receita).subscribe(res => {
              if (res.hasOwnProperty('success') && res.success) {
                if (res.status && res.dates) {
                  let dates = [];
                  res.dates.forEach(el => {
                    dates.push(this.utils.getDate(el));
                  });
                  this.handleDeletePermissions(mov, dates, 'Receita',
                    'Por favor contacte um administrador para eliminar a receita selecionada.',
                    'Por favor contacte o administrador de sistema para eliminar a receita selecionada.');
                }
              }
            }, err => {});

          } else if (mov.obj.hasOwnProperty('recibo_id')) {

            this.api.hasCaixaVertisMovimento(cod, 'R', mov.obj.n_recibo).subscribe(res => {
              if (res.hasOwnProperty('success') && res.success) {
                if (res.status && res.dates) {
                  let dates = [];
                  res.dates.forEach(el => {
                    dates.push(this.utils.getDate(el));
                  });
                  this.handleDeletePermissions(mov, dates, 'Recibo',
                    'Por favor contacte um administrador para eliminar o recibo selecionado.',
                    'Por favor contacte o administrador de sistema para eliminar o recibo selecionado.');
                }
              }
            }, err => {});

          } else if (mov.obj.hasOwnProperty('credito_id')) {

            this.api.hasCaixaVertisMovimento(cod, 'C', mov.obj.n_credito).subscribe(res => {
              if (res.hasOwnProperty('success') && res.success) {
                if (res.status && res.dates) {
                  let dates = [];
                  res.dates.forEach(el => {
                    dates.push(this.utils.getDate(el));
                  });
                  this.handleDeletePermissions(mov, dates, 'Crédito',
                    'Por favor contacte um administrador para eliminar o crédito selecionado.',
                    'Por favor contacte o administrador de sistema para eliminar o crédito selecionado.');
                }
              }
            }, err => {});

          }
        }
      }

      // DISABLE OTHER SELECTED ENTRIES
      this.movimentosList.filter(el => (el.id !== mov.id)).forEach(el => { el.checked = false; });
    }, 1);
  }


  handleDeletePermissions(entry, dates: Date[], type:string, adminText: string, superAdminText: string): void {

    this.selEntryDate = this.utils.handleDeleteCVPermissions(entry, dates, type, adminText, superAdminText);

    if (this.selEntryDate) {
      this.changeCVModalRef = this.modalService.open(this.changeCVAlertConfig)
      .onApprove(() => {
        this.selEntryDate = null;
      })
      .onDeny(() =>  {
        entry.checked = false;
        this.selEntryDate = null;
      });
      return;
    }
    this.selEntryDate = null;
    return;
  }

  // RECONCILIACAO BANCARIO - VARIABLES AND METHODS ---------------------------
  recCondominio = null;
  recSaldo = null;
  recConta = null;
  recMov: Movimento = null;
  reconciliacaoDate: Date = null;
  minDate: Date = null;
  maxDate: Date = null;
  emailConfigs = {
    reconciliacaoDate: null,
    minDate: null,
    maxDate: null,  
  }
  sendEmails = false;

  async setReconciliacao() {
    this.recCondominio = this.codCondominio + ' - ' + this.nomeCondominio;

    this.restoreFinishReconciliacaoModal();

    if (this.recMov && this.utils.compareDayDates(this.reconciliacaoDate, this.recMov.dt_mov) !== 0) {
      try {
        this.confirmedDate = await this.presentRecDateDontMatchModal();
        if (!this.confirmedDate) {
          this.reconciliacaoDate = this.recMov.dt_mov;
        }
      } catch (err) {
        this.openingSetReconciliacaoModal = false;
        return;
      }
    } else {
      this.confirmedDate = false;
    }

    // OPEN RECONCILIACAO CONFIRMATION MODAL
    this.reconciliacaoModalRef = this.modalService
      .open(this.reconciliacaoAlertConfig)
      .onApprove(() => { 
        this.loadingModal = false;
        this.recMov = null;
        this.openingSetReconciliacaoModal = false;
      })
      .onDeny(() => { this.loadingModal = false; this.recMov = null; this.openingSetReconciliacaoModal = false;});
  }

  restoreFinishReconciliacaoModal() {
    // HANLDE DATA DE RECONCILIACAO
    this.reconciliacaoDate = new Date(this.reconciliacaoEmCurso.data_reconciliacao);
    this.reconciliacaoAnexo = null;

    this.sendEmails = false;

    this.valorConferido = false;
    this.openedAnexo = false;
  }



  toRemoveReconciliacaoMovId = null;
  deleteReconc: { data, title, msg } = null;
  async presentRemoveReconciliacaoModal(mov: movimentoList) {
    if (!this.lastReconciliacao) return;
    let msg = '';
    let title = 'Deseja remover a última reconciliação efetuada?';
    let data = null;

    //Check if it's last movement
    if (!mov || this.lastReconciliacao.id_last_mov === mov.id) {
      if (this.reconciliacoesBancarias.length <= 1) {
        msg = 'Não existem mais reconciliações referentes à conta selecionada, pelo que a conta <b>ficará sem reconciliações bancárias</b>.';
      } else {
        msg = 'A conta selecionada ficará reconciliada com os seguintes dados:';
        data = JSON.parse(JSON.stringify(this.reconciliacoesBancarias[this.reconciliacoesBancarias.length - 2]));
      }
    } else {
      return;
      //Code to revert reconciliacao to a specific moment in last reconciliacao
      msg = 'Os movimentos posteriores ao selecionado <b>serão reabertos</b>';
      if (this.reconciliacoesBancarias.length <= 1) {
        msg += ' e a conta selecionada <b>ficará reconciliada</b> com os <b>seguintes dados</b>:'
        data = JSON.parse(JSON.stringify(this.reconciliacoesBancarias[this.reconciliacoesBancarias.length - 2]));
      } else {
        msg += ' e a conta selecionada ficará sem reconciliações bancárias.'
      }
    }
    
    this.deleteReconc = {
      data: data,
      title: title,
      msg: msg,
    }
    
    this.toRemoveReconciliacaoMovId = mov ? mov.id : null;
    let res = await this.canRevertReconciliacao();
    if (!res) return;

    // OPEN DELETE RECONCILIACAO CONFIRMATION MODAL
    this.invalidDate = false;
    this.deleteRecModalRef = this.modalService
      .open(this.deleteRecAlertConfig)
      .onApprove(() => { this.loadingModal = false; this.recMov = null; })
      .onDeny(() => { this.loadingModal = false; this.recMov = null; });
  }

  invalidDate = false;
  checkLimits() {
    let reconciliacaoDate = new Date((new Date(this.reconciliacaoDate)).setHours(0, 0, 0));
    let minDate = new Date((new Date(this.minDate)).setHours(0, 0, 0));
    this.invalidDate = false;
    if (!(minDate.getTime() <= reconciliacaoDate.getTime())){
      this.invalidDate = true;
    } else if (!!this.maxDate) {
      let maxDate = new Date((new Date(this.maxDate)).setHours(0, 0, 0));
      if (reconciliacaoDate.getTime() > maxDate.getTime()) {
        this.invalidDate = true;
      }
    } 
  }


  @ViewChild('enviarRecibosEmail', { static: false }) enviarRecibosEmail: EnviarRecibosEmailComponent;
  @ViewChild('sendEmailsByCondominio', { static: false }) sendEmailsByCondominio: SendEmailsByCondominioComponent;
  async saveReconciliacao() {
    if (this.invalidDate || !this.reconciliacaoDate || !this.reconciliacaoEmCurso) {
      return;
    }
    if (!this.reconciliacaoAnexo) {
      this.toastr.error(this.appConfig.errMsg.uploadFile.required.msg, this.appConfig.errMsg.uploadFile.required.title);
      return;
    }

    // API CALL AND DISMISS MODAL
    this.loadingModal = true;
    this.api.setReconciliacao(this.reconciliacaoEmCurso.id, this.reconciliacaoDate, this.reconciliacaoAnexo.base64File, this.reconciliacaoAnexo.fileExt).subscribe(async res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (res.recibosPorEnviar.length) {
          await this.enviarRecibosEmail.open(res.recibosPorEnviar);
        }
        if (this.reconciliacaoModalRef) this.reconciliacaoModalRef.approve();
        if (this.contaSelected && this.contaSelected.hasOwnProperty('conta_principal') && this.contaSelected.conta_principal === '1') {
          this.emailConfigs.reconciliacaoDate = this.reconciliacaoDate;
          await this.noticePayments(this.reconciliacaoEmCurso.id);
        }
        this.getMovimentos();
      } else {
        this.getMovimentos();
        this.utils.apiErrorMsg(res);
        this.loadingModal = false;
      }
    }, err => {
      this.getMovimentos();
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModal = false;
    });
  }

  

  @ViewChild('recDateDontMatchAlertRef', { static: false }) recDateDontMatchAlertRef;
  recDateDontMatchModalRef = null;
  recDateDontMatchAlertConfig: any = null;
  presentRecDateDontMatchModal(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.recDateDontMatchModalRef = this.modalService
      .open(this.recDateDontMatchAlertConfig)
      .onApprove((confirm) => { resolve(!!confirm); })
      .onDeny(() => { reject(false); });
    })
  }

  canRevertReconciliacao():Promise<boolean> {
    return new Promise((resolve) => {
      let cod_conta = (this.contaSelected.hasOwnProperty('cod')) ? this.contaSelected.cod : this.contaSelected.value.cod;
      // API CALL AND DISMISS MODAL
      this.api.canRevertReconciliacao(cod_conta, this.toRemoveReconciliacaoMovId).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }
  
  revertReconciliacao() {
    // API CALL AND DISMISS MODAL
    this.loadingModal = true;
    let cod_conta = (this.contaSelected.hasOwnProperty('cod')) ? this.contaSelected.cod : this.contaSelected.value.cod;
    this.api.revertReconciliacao(cod_conta, this.toRemoveReconciliacaoMovId).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.getMovimentos();
        if (this.deleteRecModalRef) this.deleteRecModalRef.approve();
      } else {
        this.utils.apiErrorMsg(res);
        this.loadingModal = false;
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModal = false;
    });
  }

  setCodCondominio() {
    if (this.condominioSelected.hasOwnProperty('cod') && this.condominioSelected.cod) {
      this.codCondominio = this.condominioSelected.cod;
      this.nomeCondominio = this.condominioSelected.nome;
    } else if (this.condominioSelected.hasOwnProperty('value') && this.condominioSelected.value && this.condominioSelected.value.hasOwnProperty('cod') && this.condominioSelected.value.cod ) {
      this.codCondominio = this.condominioSelected.value.cod;
      this.nomeCondominio = this.condominioSelected.value.nome;
    }
  }

  //Reconciliação Functions
  loadReconciliacao() {
    
    this.movimentosListOrig.forEach((el, i) => {
      el.reconciliado = null;
      el.beingReconciliadoPor = null;
      el.reconciliacaoSequence = false;
      el.id_file_reconciliacao = null;

      let movimentoReconciliado = this.getMovimentoReconciliado(el.id);
      if (movimentoReconciliado) {
        el.reconciliado = { id_user: movimentoReconciliado.id_user, nome_iniciais: this.utils.getNomeUtilizadorIniciais(movimentoReconciliado.nome_user), nome_user: movimentoReconciliado.nome_user };
      }
    });

    this.reconciliacoesBancarias.forEach(rec => {
      let mov = this.movimentosListOrig.find(el => el.id === rec.id_last_mov);
      if (mov) {
        mov.id_file_reconciliacao = rec.id_file;
      }
    });

    this.loadCurrentReconciliacao();
  }

  getMovimentoReconciliado(id_mov):ReconciliacaoBancariaMovimento {
    var BreakException = {};
    let recBancariaMovimento: ReconciliacaoBancariaMovimento = null;
    try {
      this.reconciliacoesBancarias.forEach(rec => {
        let index = rec.movimentos_reconciliacao.findIndex(mov => mov.id_mov === id_mov);
        if (index !== -1) {
          recBancariaMovimento = rec.movimentos_reconciliacao[index];
          throw BreakException;
        }
      });
    } catch (err) { 
      if (err !== BreakException) throw err;
    }
    return recBancariaMovimento;
  } 

  loadCurrentReconciliacao(current:Array<{id_mov, id_user, nome_user}> = null) {
    if (!current) {
      if (!this.reconciliacaoEmCurso) return;
      current = this.reconciliacaoEmCurso.movimentos_reconciliacao;
    }
    // Load Current Reconciliacao; 
    // Stops when meets a reconciled movement
    this.movimentosListOrig.forEach(el => {
      if (!el.reconciliado) {
        let movimentoASerReconciliado = current.find(curr => curr.id_mov == el.id);
        if (movimentoASerReconciliado) {
          el.beingReconciliadoPor = { id_user: movimentoASerReconciliado.id_user, nome_iniciais: this.utils.getNomeUtilizadorIniciais(movimentoASerReconciliado.nome_user), nome_user: movimentoASerReconciliado.nome_user };
        } else {
          el.beingReconciliadoPor = null;
        }
      }
    });

    this.loadSequenceUIReconciliacao();
  }

  /**
   * Loads page UI feedback to which movements are available to reconcile.
   */
  loadSequenceUIReconciliacao() {
    let lastReconciliadoIndex = this.lastReconciliacao? this.movimentosListOrig.findIndex(el => el.id === this.lastReconciliacao.id_last_mov) : -1;
    let breakedSequence = false;
    for (let i = lastReconciliadoIndex - 1; i >= 0; i--) {
      if (this.movimentosListOrig[i].beingReconciliadoPor && !breakedSequence) {
        this.movimentosListOrig[i].reconciliacaoSequence = true;
      } else {
        breakedSequence = true;
        this.movimentosListOrig[i].reconciliacaoSequence = false;
      }
    }
  }


  // User Access -----------------------------------------
  canCreateReconciliacao = false;
  canReadReconciliacoes = false;
  canReadCurrentReconciliacao = false;
  canViewReconciliacaoRegAtividade = false;
  loadReconciliacaoPermissions() {
    return new Promise((resolve) => {
      let promises = [
        this.businessLogic.checkUserAccess('RECONCILIACOES_BANCARIAS', 'create', true),
        this.businessLogic.checkUserAccess('RECONCILIACOES_BANCARIAS', 'read', true),
        this.businessLogic.checkUserAccess('RECONCILIACOES_BANCARIAS_VIEW_CURRENT', 'allow', true),
        this.businessLogic.checkUserAccess('RECONCILIACOES_BANCARIAS_VIEW_REG_ATIVIDADE', 'allow', true),
      ]
      Promise.all(promises).then(resArr => {
        this.canCreateReconciliacao = resArr[0];
        this.canReadReconciliacoes = resArr[1];
        this.canReadCurrentReconciliacao = resArr[2];
        this.canViewReconciliacaoRegAtividade = resArr[3];
        resolve(true);
      }).catch(err => {
        this.canCreateReconciliacao = false;
        this.canReadReconciliacoes = false;
        this.canReadCurrentReconciliacao = false;
        this.canViewReconciliacaoRegAtividade = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }
  canCancelReconciliacao(): boolean {
    return !!this.reconciliacaoEmCurso;
  }
  canChangeReconciliacaoView(): boolean {
    return !!this.canReadReconciliacoes;
  }
  // -----------------------------------------
  


  // View Buttons Functions

  // Start Reconciliacao
  //Reconciliação Functions
  
  @ViewChild('modalDefault', { static: false }) modalDefault: ModalDefaultComponent;
  @ViewChild('startRecAlertRef', { static: false }) startRecAlertRef;
  startRecModalRef = null;
  startRecAlertConfig: any = null;
  newReconciliacao = { saldo: null, data:null };
  invalidNewReconciliacao = false;
  async startReconciliacaoBtn() {
    if ((!this.reconciliacaoEmCurso || !this.userIsDoingReconciliacao) && !(await this.businessLogic.checkUserAccess('RECONCILIACOES_BANCARIAS', 'create'))) return;
    if (this.reconciliacaoEmCurso && !(await this.businessLogic.checkUserAccess('RECONCILIACOES_BANCARIAS_OTHER_USER_CONTINUE', 'allow'))) return;

    let res = await this.presentStartReconciliacaoModal();
    if (!res) return;
    
    let cod_conta = (this.contaSelected.hasOwnProperty('cod')) ? this.contaSelected.cod : this.contaSelected.value.cod;
    this.startReconciliacao(cod_conta);
    
  }

  approveStartRecModal() {
    if (!this.newReconciliacao.data || (this.minDate && this.utils.compareDayDates(this.minDate, this.newReconciliacao.data) > 0) || (this.maxDate && this.utils.compareDayDates(this.maxDate, this.newReconciliacao.data) < 0) || !this.newReconciliacao.saldo || this.newReconciliacao.saldo.trim() === '') {
      this.invalidNewReconciliacao = true;
      setTimeout(() => {
        this.invalidNewReconciliacao = false;
      }, 4000);
      return;
    }
    this.startRecModalRef.approve();
  }

  presentStartReconciliacaoModal(): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.minDate = this.lastReconciliacao? new Date(this.lastReconciliacao.data_reconciliacao) : null;
      if (this.minDate) {
        this.minDate.setDate(this.minDate.getDate() + 1);
        this.minDate.setHours(0,0,0,0);
      }
      this.maxDate = new Date();
      this.maxDate.setHours(0,0,0,0);
      this.maxDate.setDate(this.maxDate.getDate() - 1);
      if (this.minDate && this.utils.compareDayDates(this.minDate, this.maxDate) > 0) {
        this.utils.apiErrorMsg({status: 'INFO_REC_001', success: false, data: false});
        return;
      }
      this.newReconciliacao = { saldo: null, data:null };
      if (this.reconciliacaoEmCurso) {
        this.newReconciliacao.data = this.reconciliacaoEmCurso.data_reconciliacao;
        this.newReconciliacao.saldo = this.reconciliacaoEmCurso.saldo.toString();
      }

      this.startRecModalRef = this.modalService
      .open(this.startRecAlertConfig)
      .onApprove(() => { resolve(true) })
      .onDeny(() => { resolve(false) });
    });
  }

  startReconciliacao(cod_conta): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.api.startReconciliacaoBancaria(cod_conta, this.newReconciliacao.data, this.newReconciliacao.saldo).subscribe(res => {
        if (res.success) {
          this.reconciliacaoEmCurso = this.businessLogic.convertReconciliacaoBancariaType(res.data) as ReconciliacaoBancaria_Detailed;
          this.watchingReconciliacoesDetails = true;
          this.userIsDoingReconciliacao = true;
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  // Cancel Reconciliacao
  actionAfterCancelReconciliacao:'CONFIRM'|'DELETE' = null;
  cancellingReconciliacao = false;
  
  @ViewChild('deleteReconciliacaoRecibosAlertRef', { static: false }) deleteReconciliacaoRecibosAlertRef;
  deleteReconciliacaoRecibosModalRef = null;
  deleteReconciliacaoRecibosAlertConfig: any = null;
  deleteReconciliacaoRecibosRazao:string = null;
  submittingDelRecRecibos = false;
  approveDeleteReconciliacaoReciboModal() {
    if (!this.deleteReconciliacaoRecibosRazao || this.deleteReconciliacaoRecibosRazao.trim() === '') {
      this.submittingDelRecRecibos = true;
      setTimeout(() => {
        this.submittingDelRecRecibos = false;
      }, 4000);
      return;
    }
    this.deleteReconciliacaoRecibosModalRef.approve();
  }
  presentDeleteReconciliacaoRecibosModal(): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.submittingFormRecibo = false;
      this.deleteReconciliacaoRecibosRazao = 'Cancelamento da Reconciliação Bancária.'
      this.deleteReconciliacaoRecibosModalRef = this.modalService
      .open(this.deleteReconciliacaoRecibosAlertConfig)
      .onApprove(() => {
        resolve(true);
      })
      .onDeny(() => { 
        this.submittingFormRecibo = false;
        this.deleteReconciliacaoRecibosRazao = null;
        resolve(false);
      });
    });
  }

  deletingReconciliacaoRecibos = false;
  confirmingReconciliacaoRecibos = false;
  deleteReconciliacaoRecibos: () => Promise<boolean> = () => {
    return new Promise(async (resolve) => {
      let deleteRecibos = await this.presentDeleteReconciliacaoRecibosModal();
      if (deleteRecibos) {
        this.deletingReconciliacaoRecibos = true;
        let res = await this.cancelReconciliacao();
        this.deletingReconciliacaoRecibos = false;
        if (!res) {
          resolve(false);
          return;
        } else {
          resolve(true);
          return;
        }
      }
      resolve(false);
    });
  }

  
  recibosPorEnviarModal:Array<ReciboDetailed & ReconciliacaoBancariaEnvioRecibos> = [];
  confirmSendReconciliacaoRecibos: () => Promise<boolean> = () => {
    return new Promise(async (resolve) => {
      this.confirmingReconciliacaoRecibos = true;
      let success = await this.enviarRecibosEmail.open(this.recibosPorEnviarModal);
      this.confirmingReconciliacaoRecibos = false;
      if (!success) {
        resolve(false);
        return;
      }
      success = await this.cancelReconciliacao();
      if (!success) {
        resolve(false);
        return;
      }
      resolve(true);
    });
  }


  presentCancelRecModal(): Promise<boolean> {
    return new Promise((resolve) => {
      this.cancelRecModalRef = this.modalService
        .open(this.cancelRecAlertConfig)
        .onApprove(() => { resolve(true)})
        .onDeny(() => { resolve(false)});
    })
  }

  cancelReconc: { data, title, msg } = null;
  async cancelReconciliacaoBtn() {
    if (!this.canCancelReconciliacao() || this.cancellingReconciliacao) return;
    
    this.cancellingReconciliacao = true;
    let title = !this.userIsDoingReconciliacao? 'Deseja cancelar a reconciliação bancária em curso iniciada por ' + this.reconciliacaoEmCurso.nome_user + '?' : 'Deseja cancelar a reconciliação bancária em curso?';
    let data = this.lastReconciliacao ? this.lastReconciliacao : null;
    this.cancelReconc = {
      data: data,
      title: title,
      msg: this.lastReconciliacao? 'A conta selecionada ficará reconciliada com os dados anteriores ao início da reconciliação:' : null ,
    }

    let res = await this.presentCancelRecModal();
    if (!res) {
      this.cancellingReconciliacao = false;
      return;
    }

    this.actionAfterCancelReconciliacao = null;
    this.razaoReciboDelete = null;
    try {
      this.recibosPorEnviarModal = await this.reconciliacoesService.getRecibosPorEnviar(this.reconciliacaoEmCurso.id);
      if (this.recibosPorEnviarModal.length) {
        let res = await this.modalDefault.open('Confirmação', 'Existem <b>comunicações pendentes</b> dos <b>recibos emitidos</b> durante a reconciliação bancária.', 'O que pretende realizar?', 'Confirmar e Enviar', 'Anular Recibos', 'tiny', this.deleteReconciliacaoRecibos, this.confirmSendReconciliacaoRecibos, false);
        if (!res || res.action) {
          this.cancellingReconciliacao = false;
          this.actionAfterCancelReconciliacao = null;
          return;
        }
        this.actionAfterCancelReconciliacao = res.action;
      } else {
        this.cancelReconciliacao();
      }
    } catch (err) {
      this.actionAfterCancelReconciliacao = null;
      this.cancellingReconciliacao = false;
      return;
    }
  }

  cancelReconciliacao(): Promise<boolean> {
    return new Promise(async (resolve) => {
      let cod_conta = (this.contaSelected.hasOwnProperty('cod')) ? this.contaSelected.cod : this.contaSelected.value.cod;
      if (this.deleteReconciliacaoRecibosRazao) {
        this.deletingReconciliacaoRecibos = true;
      }
      this.api.cancelReconciliacaoBancaria(cod_conta, this.deleteReconciliacaoRecibosRazao).subscribe(res => {
        if (res.success) {
          this.reconciliacaoEmCurso = null;
          this.watchingReconciliacoesDetails = false;
          this.userIsDoingReconciliacao = false;

          this.cancellingReconciliacao = false;
          this.actionAfterCancelReconciliacao = null;
          this.getMovimentos();
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          this.cancellingReconciliacao = false;
          this.actionAfterCancelReconciliacao = null;
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.cancellingReconciliacao = false;
        this.actionAfterCancelReconciliacao = null;
        resolve(false);
      });
    })
  }

  // Change View
  async changeReconciliacaoViewBtn() {
    if (!this.canChangeReconciliacaoView()) { 
      this.watchingReconciliacoesDetails = false;
      return;
    }
    this.watchingReconciliacoesDetails = !this.watchingReconciliacoesDetails;
  }

  // Watch reconciliacao Reg Atividade
  async watchReconciliacaoRegAtividadeBtn() {
    if (!(await this.businessLogic.checkUserAccess('RECONCILIACOES_BANCARIAS_VIEW_REG_ATIVIDADE', 'allow'))) return;
    this.openReconciliacaoRegAtividadeModal();
  }

  async openReconciliacaoRegAtividadeModal() {
    let cod_conta = (this.contaSelected.hasOwnProperty('cod')) ? this.contaSelected.cod : this.contaSelected.value.cod;
    this.viewReconciliacoesModal.open(cod_conta);
  }

  // Set Reconciliacao
  openingSetReconciliacaoModal = false;
  async setReconciliacaoBtn(id_mov=null) {
    if (this.openingSetReconciliacaoModal) return;
    if (this.contaSelected.hasOwnProperty('cod')) {
      this.recConta = this.contaSelected.banco;
    } else {
      this.recConta = this.contaSelected.value.banco;
    }

    if (this.recConta === 'CAIXA') return;

    let mov = id_mov? this.movimentosListOrig.find(el => el.id === id_mov) : null;
    if (!this.userIsDoingReconciliacao || (mov && mov.reconciliado)) {
        this.presentRemoveReconciliacaoModal(mov);
        return;
    }

    this.openingSetReconciliacaoModal = true;
    let res = await this.getReconciliacaoEmCursoFinalData();
    if (!res) {
      this.openingSetReconciliacaoModal = false;
      return;
    }

    this.setReconciliacao();
  }


  presentReviewInitialDataModal(): Promise<boolean> {
    return new Promise(async (resolve) => {
      await this.modalDefault.open('Informação', 
              'A data e/ou valor que pretende reconciliar são <b>diferentes dos valores</b> definidos no <b>ínicio</b> da mesma.', 
              '<b>Reveja a reconciliação bancária</b> ou os <b>dados iniciais</b> da mesma, de forma a poder efetuar o seu fecho.',
              'OK', null, 'small', null, null, false);
      resolve(true);
    });
  }

  getReconciliacaoEmCursoFinalData() {
    return new Promise((resolve) => {
      if (!this.reconciliacaoEmCurso) {
        resolve(false);
        return;
      }
      this.api.getReconciliacaoEmCursoFinalData(this.reconciliacaoEmCurso.id).subscribe(res => {
        if (res.success && res.data) {
          this.recSaldo = res.data.saldo_reconciliacao;
          this.recMov = res.data.movimento_reconciliacao ? this.businessLogic.convertMovimentoType(res.data.movimento_reconciliacao) : null;
          if (this.recMov) {
            this.minDate = this.utils.getDate(this.recMov.dt_mov);
            this.minDate.setHours(0,0,0,0);
          } else {
            this.minDate = new Date(this.lastReconciliacao.data_reconciliacao);
            this.minDate.setDate(this.minDate.getDate() + 1);
            this.minDate.setHours(0,0,0,0);
          }
          this.maxDate = res.data.data_maxima_reconciliacao? this.utils.getDate(res.data.data_maxima_reconciliacao) : null;
          resolve(true);
        } else {
          if (res.status === 'ERR_REC_011') {
            this.presentReviewInitialDataModal();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.recSaldo = null;
          this.recMov = null;
          this.minDate = null;
          this.maxDate = null;
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg,this.appConfig.errMsg.apiCall.title);
        this.recSaldo = null;
        this.recMov = null;
        this.minDate = null;
        this.maxDate = null;
        resolve(false);
      });
    })
  }

  isReconciliacaoSequentiallyChecked(movIndex): boolean {
    let lastReconciliadoIndex = this.lastReconciliacao? this.movimentosListOrig.findIndex(el => el.id === this.lastReconciliacao.id_last_mov) : -1;
    if (lastReconciliadoIndex === -1) lastReconciliadoIndex = this.movimentosListOrig.length - 1; 
    //Têm de estar em sequencia
    for (let i = movIndex; i < lastReconciliadoIndex; i++) {
      if (!this.movimentosListOrig[i].beingReconciliadoPor) return false;
    }

    //Não pode haver um movimento picado para além da sequência
    for (let i = 0; i < movIndex; i++) {
      if (this.movimentosListOrig[i].beingReconciliadoPor) return false;
    }

    return true;
  }

  // List Buttons Events
  checkMovimentoReconciliacaoBtn(movimento: movimentoList) {
    if (!movimento.hasOwnProperty('beingReconciliadoPor') || !this.userIsDoingReconciliacao) return;
    movimento.beingReconciliadoPor = movimento.beingReconciliadoPor? null : { id_user: this.userSession.getUserId(), nome_iniciais: this.userSession.getUserFullNameAbrv(true), nome_user: this.userSession.getUserFullName() }; 
    
    let current:Array<{id_mov, id_user, nome_user}> = this.movimentosListOrig.filter(el => !!el.beingReconciliadoPor).map(el => { return { id_mov: el.id, id_user: el.beingReconciliadoPor.id_user, nome_user: el.beingReconciliadoPor.nome_user }});
    this.loadCurrentReconciliacao(current);
    let cod_conta = (this.contaSelected.hasOwnProperty('cod')) ? this.contaSelected.cod : this.contaSelected.value.cod;
    let id = movimento.id
    let newValue = !!movimento.beingReconciliadoPor;
    this.checkMovimentoReconciliacao(cod_conta, id, newValue);
  }
  checkMovimentoReconciliacao(cod_conta, id_mov, newValue:boolean) {
    return new Promise(async (resolve) => {
      this.api.checkMovimentoReconciliacao(cod_conta, id_mov, newValue).subscribe(res => {
        if (res.success) {
          this.reconciliacaoEmCurso.movimentos_reconciliacao = this.businessLogic.convertReconciliacaoBancariaType(res.data).movimentos_reconciliacao;
          this.loadCurrentReconciliacao();
          resolve(true);
        } else {
          this.loadCurrentReconciliacao();
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.loadCurrentReconciliacao();
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  //File Upload
  @ViewChild('uploadModalDefault', { static: false }) uploadModalDefault: UploadModalDefaultComponent;
  reconciliacaoAnexo: AnexoUpload = null;
  async openReconciliacaoAnexoModal() {
    if (this.invalidDate) return;
    let anexos = await this.uploadModalDefault.open(true, false);
    if (!anexos.length) return;
    if (anexos.length != 1) {
      this.toastr.error(this.appConfig.errMsg.uploadFile.justOneAttachment.msg,this.appConfig.errMsg.uploadFile.justOneAttachment.title)
      return;
    }
    if (this.invalidDate) return;
    let confirmed = await this.openReconciliacaoConference(anexos[0]);
    if (!confirmed) return;
    
    this.valorConferido = true;
    this.reconciliacaoAnexo = anexos[0];
  }

  downloadingAnexo = false;
  previewReconciliacaoAnexo() {
    this.downloadingAnexo = true;
    let contentType = this.utils.getBase64Prefix(this.reconciliacaoAnexo.fileExt);
    this.utils.downloadFile(contentType + this.reconciliacaoAnexo.base64File, this.reconciliacaoAnexo.name)
    this.downloadingAnexo = false;
  }

  @ViewChild('conferencenciaAlertRef', { static: false }) conferencenciaAlertRef;
  conferencenciaModalRef = null;
  conferencenciaAlertConfig: any = null;
  openedAnexo = false;
  valorConferido = false;
  confirmedDate = false;
  conferenciaAnexo:AnexoUpload = null;
  openReconciliacaoConference(anexo:AnexoUpload): Promise<boolean> {
    return new Promise((resolve) => {
      this.conferenciaAnexo = anexo;
      if (!anexo) {
        this.toastr.error(this.appConfig.errMsg.uploadFile.required.msg, this.appConfig.errMsg.uploadFile.required.title);
        resolve(false);
        return;
      }
      this.openedAnexo = false;
      this.conferencenciaModalRef = this.modalService
        .open(this.conferencenciaAlertConfig)
        .onApprove(() => { 
          resolve(true);
        })
        .onDeny(() => { 
          resolve(false);
        });
    });
  }

  confirmReconciliacaoConference() {
    if (!this.openedAnexo) return;
    this.conferencenciaModalRef.approve(); 
  }

  checkReconciliacaoAnexo() {
    this.utils.downloadFile(this.conferenciaAnexo.src, this.conferenciaAnexo.name);
    this.openedAnexo = true;
  }


  @ViewChild('noticePaymentsModal', { static: false }) noticePaymentsModal: NoticePaymentsComponent;
  noticePayments(id_reconciliacao=null): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!this.contaSelected || this.contaSelected.conta_principal !== '1') {
        resolve(false);
        return;
      }
      if (!id_reconciliacao) {
        if (!this.canSendReconciliacaoCommunications) {
          resolve(false);
          return;
        }
        id_reconciliacao = this.lastReconciliacao.id;
      }
      let success = await this.noticePaymentsModal.open(this.contaSelected.cod_condominio, id_reconciliacao);
      resolve(success);
    })
  }

  async noticePaymentsBtn() {
    let res = await this.noticePayments();
    if (res && this.lastReconciliacao) {
      this.lastReconciliacao.comunicacoes_enviadas = '1';
      this.canSendReconciliacaoCommunications = false;
    }
  }
}
