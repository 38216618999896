import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { AppStateService } from '../app-state.service';
import { CommunicationsService } from '../business-logic-services/communications.service';
import { BusinessLogicService } from '../business-logic.service';
import { IContext } from '../business-model-interfaces/application';
import { TipoCorreioFilter } from '../business-model-interfaces/carta';
import { TipoEntidade } from '../business-model-interfaces/comunicacoes';
import { MessageService } from '../message.service';
import { UtilitiesService } from '../utilities.service';
import { ViewSentEmailModalComponent } from '../view-sent-email-modal/view-sent-email-modal.component';

type MetodoComunicacaoFilter = 'TODOS' | 'CARTA' | 'EMAIL'


@Component({
  selector: '[app-registo-comunicacoes]',
  templateUrl: './registo-comunicacoes.component.html',
  styleUrls: ['./registo-comunicacoes.component.scss']
})
export class RegistoComunicacoesComponent implements OnInit {

  @Input('location') location:'ENTITY' = null;
  @Input('entityData') entityData:{cod_entidade:string, tipo_entidade:TipoEntidade} = null;

  // Data Variables
  comunicacoesListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column col-edit-width col-align-center' },  // 'ASC', 'DESC'
    { key: 'metodo', name: 'Método', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'nome_utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left' },
    { key: 'data', name: 'Data', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'nome_condominio', name: 'Condomínio', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-25' },
    { key: 'nome_condomino', name: 'Entidade(s)', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-25' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-25' },
  ];
  comunicacoesList:Array<any> = [];

  page = 1;
  limit = 20;

  // Filter Variables
  avisoRececao = false;
  tipoCorreio: TipoCorreioFilter = 'TODOS';
  metodoComunicacao: MetodoComunicacaoFilter = 'TODOS';
  keyword = null;
  endDate: Date = new Date();
  startDate: Date = new Date(this.endDate.getFullYear() - 1, 0, 1);
  
  avisoRececaoDisabled = true;


  // API Variables
  noMoreData = false;
  apiSub = null;
  fetching = false;


  // Comp State
  comp = "COMP";
  prevState = null;

  constructor(
    public message: MessageService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public toastr: ToastrService,
    public api: ApiService,
    public appState: AppStateService,
    public businessLogic: BusinessLogicService,
    public router: Router,
    public modalService: SuiModalService,
    public communicationsService: CommunicationsService,
  ) { }

  ngOnInit() {
    this.comp = "REGISTO-COMUNICACAO-" + this.location;
    // this.setPrevState();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getRegistoComunicacoes();
  }

  @ViewChild('searchRef', { static: false }) searchRef: ElementRef;
  ngAfterViewInit() {
    fromEvent(this.searchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.keyword = val['target']['value'].toLowerCase().trim();
      this.getRegistoComunicacoes();
    });
    
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    // this.setState();

    if (this.apiSub) this.apiSub.unsubscribe();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
  }

  //Call on parent component ngafterviewinit
  setPrevState() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);

    if (this.prevState) {
      // SET ACTIVE TAB
      if (this.prevState.state.hasOwnProperty('activeTab') && !!this.prevState.state.activeTab) {
      }
      this.comunicacoesList = this.prevState.state.hasOwnProperty('comunicacoesList')? JSON.parse(this.prevState.state.comunicacoesList) : null;
      this.page = this.prevState.state.hasOwnProperty('page')? this.prevState.state.page : null;
      this.avisoRececao = this.prevState.state.hasOwnProperty('avisoRececao')? this.prevState.state.avisoRececao : null;
      this.tipoCorreio = this.prevState.state.hasOwnProperty('tipoCorreio')? this.prevState.state.tipoCorreio : null;
      this.metodoComunicacao = this.prevState.state.hasOwnProperty('metodoComunicacao')? this.prevState.state.metodoComunicacao : null;
      this.keyword = this.prevState.state.hasOwnProperty('keyword')? this.prevState.state.keyword : null;
      this.startDate = this.prevState.state.hasOwnProperty('startDate')? this.prevState.state.startDate : null;
      this.endDate = this.prevState.state.hasOwnProperty('endDate')? this.prevState.state.endDate : null;

      this.appState.clearPrevState(this.comp);
    }
  }


  onScroll() {
    if (this.noMoreData || !this.apiSub.closed) return;
    this.page += 1;
    this.getRegistoComunicacoes(false);
  }


  getRegistoComunicacoes(init=true) {
    if (this.location === 'ENTITY' && !this.entityData) return;

    if (init) {
      this.noMoreData = false;
      this.page = 1;
      this.comunicacoesList = [];
    }

    if (this.metodoComunicacao === 'EMAIL') {
      this.tipoCorreio = 'TODOS';
      this.avisoRececao = false;
    }


    let tipoCorreio:any = this.tipoCorreio;
    if (this.avisoRececao) tipoCorreio = 'REGISTADO_AR';
    if (this.apiSub) this.apiSub.unsubscribe();
    this.apiSub = this.api.getRegistoComunicacoes(this.metodoComunicacao, tipoCorreio, this.keyword, this.page, this.limit, this.startDate, this.endDate, this.entityData).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (!res.data.reg_com.length) this.noMoreData = true;
        this.generateRegistoCttListObj(res.data.reg_com);
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }


  generateRegistoCttListObj(data: Array<any>) {
    data.forEach(el => {
      let nome_condominos = el.nome_condominos ? el.nome_condominos : null;
      if (el.nome_fornecedores) {
        nome_condominos = nome_condominos ? nome_condominos + ', ' + el.nome_fornecedores : el.nome_fornecedores;
      }
      
      let aux = {
        checked: false,
        id: el.id,
        id_despesa: el.id_despesa,
        n_despesa: el.n_despesa,
        nome_utilizador: this.utils.getNomeUtilizadorIniciais(el.nome_utilizador),
        data: el.data,
        nome_condominio: (el.nome_condominio) ? el.nome_condominio : null,
        nome_condomino: nome_condominos,
        cod_condominio: (el.cod_condominio) ? el.cod_condominio : null,
        descricao: el.descricao,
  
        conta_simples: (el.conta_simples === '1'),
        registado: (el.registado === '1'),
        correiro_normal: (el.correiro_normal === '1'),
        aviso_recepcao: (el.aviso_recepcao === '1'),
  
        obj: el.obj,
        tipo: el.tipo,
        assunto: el.assunto,
        corpo: el.corpo,
        id_file: el.id_file,
        valor: el.valor,
      }
      aux['tipo_correio'] = null;
      if (aux['conta_simples']) {
        aux['tipo_correio'] = 'Simples';
      } else if (aux['correiro_normal']) {
        aux['tipo_correio'] = 'Normal';
      } else if (aux['registado']) {
        if (aux['aviso_recepcao']) {
          aux['tipo_correio'] = 'Registado (AR)';
        } else {
          aux['tipo_correio'] = 'Registado';
        }
      }
  
      this.comunicacoesList.push(aux);
    })

    this.updateFiltersLogic();
  }


  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.comunicacoesList.map(el => el.checked = true ) : this.comunicacoesList.map(el => el.checked = false );
  }


  @ViewChild('viewSentEmailAlertRef', { static: false }) viewSentEmailAlertRef: ViewSentEmailModalComponent;
  goToDetails(registoCtt) {
    if (registoCtt.tipo === 'EMAIL') {
      this.viewSentEmailAlertRef.open(registoCtt.id);
    } else {
      this.setState();

      this.router.navigate(['lancamentos/despesa', registoCtt.id_despesa]);

      // Emit signal to breadcrumb component
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `DESPESA / ${registoCtt.n_despesa}` });
    }
  }

  downloadCarta(row) {
    if (!row.id_file) {
      this.toastr.info('Não é possível visualizar a carta relativa a esta comunicação.','Informação');
      return;
    }
    this.businessLogic.getAndDownloadFile(row.id_file);
  }


  setState() {
    let state = {
      comunicacoesList: JSON.stringify(this.comunicacoesList),
      page: this.page,
      avisoRececao: this.avisoRececao,
      tipoCorreio: this.tipoCorreio,
      metodoComunicacao: this.metodoComunicacao,
      keyword: this.keyword,
      startDate: this.startDate,
      endDate: this.endDate,
    }
    this.appState.setPrevState(this.comp, state);
  }

  updateFiltersLogic() {
    this.avisoRececaoDisabled = this.tipoCorreio !== 'REGISTADO';
  }

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  deleteModalRef = null;
  deleteAlertConfig: any = null;
  
  toDelete = [];
  presentDeleteModal() {
    this.toDelete = this.comunicacoesList.filter(el => el.checked);
    if (this.toDelete.length > 0) {
      
      this.deleteModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => { })
      .onDeny(() => { });

    } else {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
    }
  }
  
  deleting = false;
  async del() {
    this.deleting = true;
    let res = await this.communicationsService.deleteCommunications(this.toDelete);
    this.deleting = false;

    let carta = this.toDelete.filter(el => el.tipo === 'CARTA');
    let email = this.toDelete.filter(el => el.tipo === 'EMAIL');
    
    if (res.carta) {
      this.comunicacoesList = this.comunicacoesList.filter(el => el.tipo !== 'CARTA' || !carta.find(del => del.id === el.id));
    }
    
    if (res.email) {
      this.comunicacoesList = this.comunicacoesList.filter(el => el.tipo !== 'EMAIL' || !email.find(del => del.id === el.id));
    }

    if (this.deleteModalRef) res.carta && res.email? this.deleteModalRef.approve() : this.deleteModalRef.deny();
  }
}
