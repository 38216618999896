import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ToastrService } from 'ngx-toastr';
import { forkJoin, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';


@Component({
  selector: 'app-registo-processos',
  templateUrl: './registo-processos.component.html',
  styleUrls: ['./registo-processos.component.scss']
})
export class RegistoProcessosComponent implements OnInit {

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('detailsProcessAlertRef', { static: false }) detailsProcessAlertRef;
  detailsProcessModalRef = null;
  detailsProcessAlertConfig: any = null;

  @ViewChild('launchProcessAlertRef', { static: false }) launchProcessAlertRef;
  launchProcessModalRef = null;
  launchProcessAlertConfig: any = null;

  processoOpts = [
    { name: '--- Limpar seleção ---', value: '-1' },
    { name: 'Pag. de despesas de agendamento', value: 'PAGAR_DESPESAS_AGENDAMENTO' },
  ];
  selectedProcess = null;

  transitionController = new TransitionController();

  // FILTER SECTION VARIABLES
  searching = false;
  keyword = null;
  tableSearchSubs = null;
  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;

  filterEndDate = new Date();
  filterStartDate = new Date(this.filterEndDate.getTime() - (30 * 24 * 60 * 60 * 1000));  // PREV 30 DAYS

  // TABLE SECTION VARIABLES
  listHeader = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'nome_processo', name: 'Nome do processo', type: 'text', sort: null, searchable: true, centered: false, class: '' },
    { key: 'data_inicio', name: 'Data Início', type: 'date', sort: null, searchable: true, centered: false, class: 'col-centered date-col' },
    { key: 'data_fim', name: 'Data Fim', type: 'date', sort: null, searchable: true, centered: false, class: 'col-centered date-col' },
    { key: 'estado', name: 'Estado', type: 'text', sort: null, searchable: true, centered: false, class: 'col-centered status-col' },
  ];
  list = [];
  req1Subs = null;
  req2Subs = null;

  loading = false;

  constructor(public api: ApiService,
              public cdRef:ChangeDetectorRef,
              public modalService: SuiModalService,
              public message: MessageService,
              public appConfig: AppConfigService,
              public toastr: ToastrService) { }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngOnInit() {
    // this.api.setDespAgendamentoMaintenance().subscribe(
    //   res => { console.log(res); },
    //   err => { console.log(err); },
    // );
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngAfterViewInit() {
    this.tableSearchSubs = fromEvent(this.tableSearchRef.nativeElement, 'keyup').pipe(debounceTime(500)).subscribe(async val => {
      this.keyword = val['target']['value'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();

      this.searching = true;
      await this.getList();
      this.searching = false;
    });

    this.launchProcessAlertConfig = new TemplateModalConfig<IContext, string, string>(this.launchProcessAlertRef);
    this.launchProcessAlertConfig.closeResult = "closed";
    this.launchProcessAlertConfig.isClosable = false;
    this.launchProcessAlertConfig.size = 'mini';
    this.launchProcessAlertConfig.transition = 'fade';
    this.launchProcessAlertConfig.transitionDuration = 250;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.isClosable = false;
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.detailsProcessAlertConfig = new TemplateModalConfig<IContext, string, string>(this.detailsProcessAlertRef);
    this.detailsProcessAlertConfig.closeResult = "closed";
    this.detailsProcessAlertConfig.isClosable = false;
    this.detailsProcessAlertConfig.size = 'normal';
    this.detailsProcessAlertConfig.transition = 'fade';
    this.detailsProcessAlertConfig.transitionDuration = 250;

    // GET INITIAL DATA
    this.getList();
  }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    if (this.tableSearchSubs) this.tableSearchSubs.unsubscribe();
    if (this.req1Subs) this.req1Subs.unsubscribe();
    if (this.req2Subs) this.req2Subs.unsubscribe();
  }

  getList() {
    return new Promise(resolve => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      if (this.req1Subs) this.req1Subs.unsubscribe(); 
      this.req1Subs = this.api.getRegistoProcessos(this.filterStartDate, this.filterEndDate, this.keyword).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {

          this.list = res.data.map(el => {
            el.data_inicio = (el.data_inicio) ? new Date(el.data_inicio) : null;
            el.data_fim = (el.data_fim) ? new Date(el.data_fim) : null;
            try {
              el.obj = (el.obj) ? JSON.parse(el.obj) : null;
            } catch(err) {
              el.obj = [];
            }
            el['checked'] = false;

            return el;
          });

          this.searching = false;
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(true);
      }, err => {
        this.searching = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.list.map(el => el.checked = true ) : this.list.map(el => el.checked = false );
  }

  presentAlert(type) {
    return new Promise((resolve, reject) => {
      switch (type) {
        case 'DELETE': 
          this.alertModalRef = this.modalService
            .open(this.deleteAlertConfig)
            .onApprove(() => resolve(true))
            .onDeny(() => resolve(false));
          break
        case 'LUNCH_PROCESS':
          this.selectedProcess = null;

          this.launchProcessModalRef = this.modalService
            .open(this.launchProcessAlertConfig)
            .onApprove(() => {
              this.newLog();
              this.submittingForm = false;
              resolve(true);
            })
            .onDeny(() => {
              this.submittingForm = false;
              resolve(false); 
            });
          break
      }
    });
  }

  submittingForm = false;
  launchProcess() {
    this.submittingForm = true;
    if (!this.selectedProcess) {
      this.toastr.error('Nenhum processo selecionado.', 'Alerta', { timeOut: 4000 });
      return;
    }

    this.launchProcessModalRef.approve();
  }

  newLog() {
    this.loading = true;

    let selection = this.processoOpts.find(el => el.value === this.selectedProcess);
    if (selection) {
      // LAUNCH PROCESS API CALL
      this.api.launchProcesso(selection.name, selection.value).subscribe(async res => {
        if (res.hasOwnProperty('success') && res.success) {
          await this.getList();

          setTimeout(() => {
            this.checkStatus(res.inserted, false);
          }, 5000);

          this.loading = false;
          if (this.launchProcessModalRef) this.launchProcessModalRef.approve();
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  }

  async deleteLogs() {
    // CHECK IF ENTRIES SELECTED
    let toDelete = this.list.filter(el => el.checked);
    if (toDelete.length === 0) {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
      return;
    }

    // PRESENT DELETE ALERT
    let res = await this.presentAlert('DELETE');
    if (!res) return;

    // DELETE API CALL
    this.api.delRegistoProcessos(toDelete).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.list = this.list.filter(el => !el.checked);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  processSelected() {
    if (this.selectedProcess === '-1') this.selectedProcess = null;
  }

  checkStatus(item, progressBarEnabled=true) {
    return new Promise(resolve => {
      if (item.estado !== 'RUNNING') return;

      if (progressBarEnabled) this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      if (this.req2Subs) this.req2Subs.unsubscribe(); 
      this.req1Subs = this.api.getProcesso(item.id).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          let aux = this.list.find(el => el.id === res.item.id);
          if (aux) {
            aux['estado'] = res.item.estado;
            aux['data_fim'] = (res.item.data_fim) ? new Date(res.item.data_fim) : null;
          }
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(true);
      }, err => {
        this.searching = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  detailsList = [];
  openDetails(row) {
    if (row.hasOwnProperty('obj') && row.obj) {

      // CONSTRUCT DETAILS OBJECT
      let condominios = [ ...new Set(row.obj.map(item => item.cod_condominio)) ];
      let aux = null;
      this.detailsList = [];
      condominios.forEach((el, i) => {
        aux = {
          cod_condominio: el,
          nome_condominio: null,
          despesas: [],
        }

        row.obj.filter(it => it.cod_condominio === el).forEach(it => {
          aux.nome_condominio = it.nome_condominio;
          aux.despesas.push(it);
        })

        this.detailsList.push(aux);
      });

      // OPEN DETAILS MODAL
      this.detailsProcessModalRef = this.modalService.open(this.detailsProcessAlertConfig);
    } else {
      switch (row.label_processo) {
        case 'PAGAR_DESPESAS_AGENDAMENTO':
          this.toastr.info('Nenhuma despesa afectada durante a execução deste processo.', 'Informação', { timeOut: 4000 });
          break;
      }
    }
  }

  getTotalDespesas(item=null) {
    if (item) {
      return (item.obj) ? item.obj.length : null;
    } else {
      return this.detailsList.map(el => el.despesas.length).reduce((a, b) => a + b, 0);
    }
  }

}
