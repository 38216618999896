import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TransitionController, Transition, TransitionDirection, SuiActiveModal } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { Minuta, NewMinuta } from '../business-model-interfaces/minuta';
import { ConfigAvisosCobrancasComponent } from '../config-avisos-cobrancas/config-avisos-cobrancas.component';
import { IContext } from '../business-model-interfaces/application';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigurationNavigation, OrdemTrabalhoConfig } from '../configurations';


@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {

  transitionController = new TransitionController();

  tabsObjDef = [
    { active: true, key: 'rubricas', name: 'Rubricas', singularName: 'Rubrica', url: 'rubricas' },
    { active: false, key: 'textos', name: 'Textos', singularName: 'Texto', url: 'textos' },
    // { active: false, key: 'servicos', name: 'Serviço' },
    // { active: false, key: 'ocorrencias', name: 'Ocorrência' },
    // { active: false, key: 'intervencoes', name: 'Intervenção' },
    // { active: false, key: 'accoes', name: 'Acção' },
    // { active: false, key: 'equipamentos', name: 'Equipamento' },
    { active: false, key: 'bancos', name: 'Bancos', singularName: 'Banco', url: 'bancos' },
    { active: false, key: 'contas', name: 'Contas', singularName: 'Conta', url: 'contas' },
    { active: false, key: 'assembleias-docs', name: 'Doc. Assembleias', singularName: 'Doc. Assembleia', url: 'assembleias-docs' },
    { active: false, key: 'assembleias-ordem-trab', name: 'Ordem Trabalhos', singularName: 'Ordem Trabalho', url: 'assembleias-ordem-trab' },
    { active: false, key: 'anexos', name: 'Anexos Ata', singularName: 'Anexo Ata', url: 'anexos' },
    { active: false, key: 'email-config', name: 'Emails', singularName: 'Email', url: 'email-config' },
    { active: false, key: 'minutas', name: 'Minutas', singularName: 'Minuta', url: 'minutas' },
    { active: false, key: 'avisos-cobranca', name: 'Avisos e Cobranças', singularName: 'Avisos e Cobrança', url: 'avisos-cobranca' },
  ];

  selTabKey=  null;
  selTab = { key: null, name: null };

  submittingForm: boolean = false;
  
  // -------------- RUBRICAS VARIABLES - START
  // NEW RUBRICAS FORM
  rubricaNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
    despesa: new FormControl(null),
    receita: new FormControl(null),
    val_fixo: new FormControl(null),
  });

  // UPDATE RUBRICAS FORM
  rubricaUpdateForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
    despesa: new FormControl(null),
    receita: new FormControl(null),
    val_fixo: new FormControl(null),
  });

  @ViewChild('tableSearchInput', { static: false }) tableSearchInput: ElementRef;
  searching = false;

  @ViewChild('rubricasTableSearch', { static: false }) rubricasTableSearch: ElementRef;
  rubricasSearching = false;

  rubricasListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'despesa', name: 'Despesa', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'receita', name: 'Receita', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'valor_fixo', name: 'Valor Fixo', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'n_ordem', name: 'Ordem', type: 'text', sort: null, searchable: false, centered: true },
  ];
  rubricasList: Array<any> = [];
  rubricasListOrig: Array<any> = [];
  rubricasListPrevState: Array<any> = [];  
  rubricasListLength: number = null;
  rubricasItemPerPAge: number = 20;
  rubricasSelectedPage: number = 1;
  // -------------- RUBRICAS VARIABLES - END


  // -------------- TEXTOS VARIABLES - START
  // NEW TEXTOS FORM
  textoNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    tipo: new FormControl(null),
    assunto: new FormControl(null, { validators: Validators.required}),
    text_inicial: new FormControl(null),
    texto_final: new FormControl(null),
  });

  // UPDATE TEXTOS FORM
  textoUpdateForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    tipo: new FormControl(null),
    assunto: new FormControl(null, { validators: Validators.required}),
    text_inicial: new FormControl(null),
    texto_final: new FormControl(null),
  });

  @ViewChild('textosTableSearch', { static: false }) textosTableSearch: ElementRef;
  textosSearching = false;

  textosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered one wide' },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false, class: '' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered two wide' },
  ];
  textosList: Array<any> = [];
  textosListOrig: Array<any> = [];
  textosListPrevState: Array<any> = [];  
  textosListLength: number = null;
  textosItemPerPAge: number = 20;
  textosSelectedPage: number = 1;
  // -------------- TEXTOS VARIABLES - END


  // -------------- SERVICOS/TIPOS VARIABLES - START
  // NEW SERVICOS FORM
  servicoNewForm = new FormGroup({
    descricao: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  // UPDATE SERVICOS FORM
  servicoUpdateForm = new FormGroup({
    id: new FormControl(null),
    descricao: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  @ViewChild('servicosTableSearch', { static: false }) servicosTableSearch: ElementRef;
  servicosSearching = false;

  servicosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'descricao', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'n_ordem', name: 'Ordem', type: 'text', sort: null, searchable: false, centered: true },
  ];
  servicosList: Array<any> = [];
  servicosListOrig: Array<any> = [];
  servicosListPrevState: Array<any> = [];  
  servicosListLength: number = null;
  servicosItemPerPAge: number = 20;
  servicosSelectedPage: number = 1;
  // -------------- SERVICOS VARIABLES - END


  // -------------- OCORRENCIAS VARIABLES - START
  // NEW OCORRENCIAS FORM
  ocorrenciaNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  // UPDATE OCORRENCIAS FORM
  ocorrenciaUpdateForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  @ViewChild('ocorrenciasTableSearch', { static: false }) ocorrenciasTableSearch: ElementRef;
  ocorrenciasSearching = false;

  ocorrenciasListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'n_ordem', name: 'Ordem', type: 'text', sort: null, searchable: false, centered: true },
  ];
  ocorrenciasList: Array<any> = [];
  ocorrenciasListOrig: Array<any> = [];
  ocorrenciasListPrevState: Array<any> = [];  
  ocorrenciasListLength: number = null;
  ocorrenciasItemPerPAge: number = 20;
  ocorrenciasSelectedPage: number = 1;
  // -------------- OCORRENCIAS VARIABLES - END


  // -------------- INTERVENCOES VARIABLES - START
  // NEW INTERVENCOES FORM
  intervencaoNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  // UPDATE INTERVENCOES FORM
  intervencaoUpdateForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  @ViewChild('intervencoesTableSearch', { static: false }) intervencoesTableSearch: ElementRef;
  intervencoesSearching = false;

  intervencoesListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'n_ordem', name: 'Ordem', type: 'text', sort: null, searchable: false, centered: true },
  ];
  intervencoesList: Array<any> = [];
  intervencoesListOrig: Array<any> = [];
  intervencoesListPrevState: Array<any> = [];  
  intervencoesListLength: number = null;
  intervencoesItemPerPAge: number = 20;
  intervencoesSelectedPage: number = 1;
  // -------------- INTERVENCOES VARIABLES - END


  // -------------- ACCOES VARIABLES - START
  // NEW ACCOES FORM
  accaoNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  // UPDATE ACCOES FORM
  accaoUpdateForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  @ViewChild('accoesTableSearch', { static: false }) accoesTableSearch: ElementRef;
  accoesSearching = false;

  accoesListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'n_ordem', name: 'Ordem', type: 'text', sort: null, searchable: false, centered: true },
  ];
  accoesList: Array<any> = [];
  accoesListOrig: Array<any> = [];
  accoesListPrevState: Array<any> = [];  
  accoesListLength: number = null;
  accoesItemPerPAge: number = 20;
  accoesSelectedPage: number = 1;
  // -------------- ACCOES VARIABLES - END


  // -------------- EQUIPAMENTOS VARIABLES - START
  // NEW EQUIPAMENTOS FORM
  equipamentoNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  // UPDATE EQUIPAMENTOS FORM
  equipamentoUpdateForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  @ViewChild('equipamentosTableSearch', { static: false }) equipamentosTableSearch: ElementRef;
  equipamentosSearching = false;

  equipamentosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'n_ordem', name: 'Ordem', type: 'text', sort: null, searchable: false, centered: true },
  ];
  equipamentosList: Array<any> = [];
  equipamentosListOrig: Array<any> = [];
  equipamentosListPrevState: Array<any> = [];  
  equipamentosListLength: number = null;
  equipamentosItemPerPAge: number = 20;
  equipamentosSelectedPage: number = 1;
  // -------------- EQUIPAMENTOS VARIABLES - END


  // -------------- BANCOS VARIABLES - START
  // NEW BANCOS FORM
  bancoNewForm = new FormGroup({
    banco: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    sigla: new FormControl(null),
  });

  // UPDATE BANCOS FORM
  bancoUpdateForm = new FormGroup({
    id: new FormControl(null),
    banco: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    sigla: new FormControl(null),
  });

  @ViewChild('bancosTableSearch', { static: false }) bancosTableSearch: ElementRef;
  bancosSearching = false;

  bancosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'banco', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'sigla', name: 'Sigla', type: 'text', sort: null, searchable: true, centered: true },
  ];
  bancosList: Array<any> = [];
  bancosListOrig: Array<any> = [];
  bancosListPrevState: Array<any> = [];  
  bancosListLength: number = null;
  bancosItemPerPAge: number = 20;
  bancosSelectedPage: number = 1;
  // -------------- BANCOS VARIABLES - END


  // -------------- CONTAS VARIABLES - START
  // NEW CONTAS FORM
  contaNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  // UPDATE CONTAS FORM
  contaUpdateForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_ordem: new FormControl(null),
  });

  @ViewChild('contasTableSearch', { static: false }) contasTableSearch: ElementRef;
  contasSearching = false;

  contasListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'n_ordem', name: 'Ordem', type: 'text', sort: null, searchable: false, centered: true },
  ];
  contasList: Array<any> = [];
  contasListOrig: Array<any> = [];
  contasListPrevState: Array<any> = [];  
  contasListLength: number = null;
  contasItemPerPAge: number = 20;
  contasSelectedPage: number = 1;
  // -------------- CONTAS VARIABLES - END


  // -------------- ANEXOS ATAS VARIABLES - START
  // NEW CONTAS FORM
  anexosNewForm = new FormGroup({
    name: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    ordem: new FormControl(null),
  });

  // UPDATE CONTAS FORM
  anexosUpdateForm = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    ordem: new FormControl(null),
  });

  @ViewChild('anexosTableSearch', { static: false }) anexosTableSearch: ElementRef;
  anexosSearching = false;

  anexosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'name', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'ordem', name: 'Ordem', type: 'text', sort: null, searchable: false, centered: true },
  ];
  anexosList: Array<any> = [];
  anexosListOrig: Array<any> = [];
  anexosListPrevState: Array<any> = [];  
  anexosListLength: number = null;
  anexosItemPerPAge: number = 20;
  anexosSelectedPage: number = 1;
  // -------------- ANEXOS ATAS VARIABLES - END


  // UPDATE ASSEMBLEIAS DOCS FORM
  assembDocsUpdateForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    text_inicial: new FormControl(null),
    texto_final: new FormControl(null),
  });
  
  @ViewChild('assembDocsTableSearch', { static: false }) assembDocsTableSearch: ElementRef;
  assembDocsSearching = false;
  
  assembDocsListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered one wide' },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false, class: '' },
  ];
  assembDocsList: Array<any> = [];
  assembDocsListOrig: Array<any> = [];
  assembDocsListPrevState: Array<any> = [];  
  assembDocsListLength: number = null;
  assembDocsItemPerPAge: number = 20;
  assembDocsSelectedPage: number = 1;
  // -------------- ASSEMBLEIAS DOCS VARIABLES - END
  
  // UPDATE ASSEMBLEIAS REG TRABALHO FORM
  assembRegTrabUpdateForm = new FormGroup({
    id: new FormControl(null),
    descricao: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    ordem: new FormControl(null),
    ordinarias_predefinido: new FormControl(null),
    extras_predefinido: new FormControl(null),
    ordinarias_diversos_predefinido: new FormControl(null),
    extras_diversos_predefinido: new FormControl(null),
    is_ordem_trabalho: new FormControl(null),
    is_assunto_diverso: new FormControl(null),
    is_multipla_entrada: new FormControl(null),
    textoInicial: new FormControl(null),
    textoFinal: new FormControl(null),
    has_anexo: new FormControl(null),
    tipo_anexo_id: new FormControl(null),
    hasEmail: new FormControl(null),
    emailId: new FormControl(null),
    hasCarta: new FormControl(null),
    cartaId: new FormControl(null),
  });

  predefinidoOrdinarias = [];
  predefinidoExtraordinarias = [];
  
  @ViewChild('assembRegTrabTableSearch', { static: false }) assembRegTrabTableSearch: ElementRef;
  assembRegTrabSearching = false;
  
  assembRegTrabListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'ordinaria', name: 'Ordinária', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'extra', name: 'Extra', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'ordem', name: 'Ordem', type: 'text', sort: null, searchable: false, centered: true },
  ];
  assembRegTrabList: Array<any> = [];
  assembRegTrabListOrig: Array<any> = [];
  assembRegTrabListPrevState: Array<any> = [];  
  assembRegTrabListLength: number = null;
  assembRegTrabItemPerPAge: number = 20;
  assembRegTrabSelectedPage: number = 1;

  ordemTrabalhosDiversosOpts: Array<{name: string, value: OrdemTrabalhoConfig}> = [];
  // -------------- ASSEMBLEIAS REG TRAB VARIABLES - END
  
  // UPDATE ASSEMBLEIAS DOCS FORM
  emailConfig = {
    id: null,
    legenda: null,
    assunto: null,
    corpo: null,
  }
  
  @ViewChild('emailConfigTableSearch', { static: false }) emailConfigTableSearch: ElementRef;
  emailConfigSearching = false;
  
  emailConfigListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered one wide' },  // 'ASC', 'DESC'
    { key: 'legenda', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: false, class: '' },
  ];
  emailConfigList: Array<any> = [];
  emailConfigListOrig: Array<any> = [];
  emailConfigListPrevState: Array<any> = [];  
  emailConfigListLength: number = null;
  emailConfigItemPerPAge: number = 20;
  emailConfigSelectedPage: number = 1;
  // -------------- ASSEMBLEIAS DOCS VARIABLES - END
  
  // -------------- MINUTAS VARIABLES - START
  @ViewChild('minutasTableSearch', { static: false }) minutasTableSearch: ElementRef;
  minutasSearching = false;

  minutasListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'assunto', name: 'Assunto', type: 'text', sort: null, searchable: true, centered: false },
  ];

  minutasListOrig: Array<Minuta & {checked}> = [];
  minutasList: Array<Minuta & {checked}> = [];
  minutasSelectedPage: number = 1;
  minutasItemPerPage: number = 20;
  minutasListLength: number = null;

  minutaNewForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required}),
    assunto: new FormControl(null, { validators: Validators.required}),
    texto: new FormControl(null, { validators: Validators.required}),
  });
  
  minutasUpdateForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, { validators: Validators.required}),
    assunto: new FormControl(null, { validators: Validators.required}),
    texto: new FormControl(null, { validators: Validators.required}),
  });
  // -------------- MINUTAS DOCS VARIABLES - END
  
  @ViewChild('addEntRef', { static: false }) addEntRef;
  addModalRef = null;
  addModalConfig: any = null;

  @ViewChild('updateEntRef', { static: false }) updateEntRef;
  updateModalRef = null;
  updateModalConfig: any = null;

  @ViewChild('updateRespostaRef', { static: false }) updateRespostaRef;
  updateRespostaModalRef = null;
  updateRespostaModalConfig: any = null;

  @ViewChild('updateEmailRef', { static: false }) updateEmailRef;
  updateEmailModalRef = null;
  updateEmailModalConfig: any = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  anexosOpts = [];

  searchKeyword = null;
  constructor(public api: ApiService,
              public utils: UtilitiesService,
              public toastr: ToastrService,
              public modalService: SuiModalService,
              public router: Router,
              public route: ActivatedRoute,
              public location: Location,
              public appConfig: AppConfigService) { 
    let currState = this.router.getCurrentNavigation();
    if ('state' in currState.extras) {
      this.navState = currState.extras.state;
    }
  }

  navState: ConfigurationNavigation = null;

  ngOnInit() {
    this.animate();

    this.getDetails();
  }

  ngAfterViewInit() {
    // INPUT SEARCH
    fromEvent(this.tableSearchInput.nativeElement, 'keyup').pipe(debounceTime(200)).subscribe(val => {
      this.searchKeyword = val['target']['value'];
      this.tableSearch(val['target']['value'], this.selTabKey);
    });

    this.addModalConfig = new TemplateModalConfig<IContext, string, string>(this.addEntRef);
    this.addModalConfig.closeResult = "closed";
    this.addModalConfig.size = 'small';
    this.addModalConfig.transition = 'fade up';
    this.addModalConfig.transitionDuration = 400;

    this.updateModalConfig = new TemplateModalConfig<IContext, string, string>(this.updateEntRef);
    this.updateModalConfig.closeResult = "closed";
    this.updateModalConfig.size = 'small';
    this.updateModalConfig.transition = 'fade up';
    this.updateModalConfig.transitionDuration = 400;

    this.updateRespostaModalConfig = new TemplateModalConfig<IContext, string, string>(this.updateRespostaRef);
    this.updateRespostaModalConfig.closeResult = "closed";
    this.updateRespostaModalConfig.size = 'small';
    this.updateRespostaModalConfig.transition = 'fade up';
    this.updateRespostaModalConfig.transitionDuration = 400;

    this.updateEmailModalConfig = new TemplateModalConfig<IContext, string, string>(this.updateEmailRef);
    this.updateEmailModalConfig.closeResult = "closed";
    this.updateEmailModalConfig.size = 'small';
    this.updateEmailModalConfig.transition = 'fade up';
    this.updateEmailModalConfig.transitionDuration = 400;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    let module = this.route.snapshot.paramMap.get('module');
    let tab = module? this.tabsObjDef.find(el => el.url === module) : null;
    let tabKey = tab? tab.key : this.tabsObjDef[0].key;
    this.selectTab(tabKey, !tab);
    this.loadNavigationAction();
  }

  loadNavigationAction() {
    if (!this.navState || !this.navState.action) return;
    switch (this.navState.action) {
      case 'CREATE_NEW_TEXT':
        this.tableAction('add', 'textos');
        break;
    
      default:
        break;
    }
    return;
  }
  executeNavigationAction(id=null) {
    if (!this.navState || !this.navState.redirectAfterTo) return;
    let navState: ConfigurationNavigation = null;
    switch (this.navState.redirectAfterTo.action) {
      case 'CONFIG_OPEN_NEW_AC_SET_TEXT':
        navState = {
          data: {
            ...this.navState.redirectAfterTo.data,
            id_texto: id,
          },
          action: 'CONFIG_OPEN_NEW_AC_SET_TEXT'
        }
        break;
        default:
          break;
        }
        
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(this.navState.redirectAfterTo.url, { state: navState });
    return;
  }


  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  @ViewChild('configAvisosCobranca', { static: false }) configAvisosCobranca: ConfigAvisosCobrancasComponent;
  // setTab(targetTab) {
  //   this.selTab = this.tabsObjDef.find(el => (el.key === targetTab));
  //   switch (targetTab) {
  //     case 'avisos-cobranca':
  //       this.configAvisosCobranca.loadData();
  //       break;
    
  //     default:
  //       break;
  //   }
  // }

  async selectTab(selTab:string=null, navigate=true) {
    if (selTab) this.selTabKey = selTab;
    let tab = this.tabsObjDef.find(el => el.key === this.selTabKey);
    this.selTab = tab;
    switch (this.selTabKey) {
      case 'avisos-cobranca':
        await this.configAvisosCobranca.loadData();
        break;
        
      default:
        break;
      }
          
    // if (navigate) this.router.navigateByUrl('/configuracoes');
    if (navigate) this.location.replaceState('/configuracoes/' + tab.url);
  }

  tableSearch(keyword, targetList) {
    keyword = keyword.toLowerCase().trim();

    switch (targetList) {
      case 'rubricas':
        if (keyword) {
          this.rubricasList = this.utils.tableSearch(keyword, this.rubricasListCol, this.rubricasListOrig);
        } else {
          this.rubricasList = this.rubricasListOrig;
        }
        break;
      case 'textos':
        if (keyword) {
          this.textosList = this.utils.tableSearch(keyword, this.textosListCol, this.textosListOrig);
        } else {
          this.textosList = this.textosListOrig;
        }
        break;
      case 'servicos':
        if (keyword) {
          this.servicosList = this.utils.tableSearch(keyword, this.servicosListCol, this.servicosListOrig);
        } else {
          this.servicosList = this.servicosListOrig;
        }
        break;
      case 'ocorrencias':
        if (keyword) {
          this.ocorrenciasList = this.utils.tableSearch(keyword, this.ocorrenciasListCol, this.ocorrenciasListOrig);
        } else {
          this.ocorrenciasList = this.ocorrenciasListOrig;
        }
        break;
      case 'intervencoes':
        if (keyword) {
          this.intervencoesList = this.utils.tableSearch(keyword, this.intervencoesListCol, this.intervencoesListOrig);
        } else {
          this.intervencoesList = this.intervencoesListOrig;
        }
        break;
      case 'accoes':
        if (keyword) {
          this.accoesList = this.utils.tableSearch(keyword, this.accoesListCol, this.accoesListOrig);
        } else {
          this.accoesList = this.accoesListOrig;
        }
        break;
      case 'equipamentos':
        if (keyword) {
          this.equipamentosList = this.utils.tableSearch(keyword, this.equipamentosListCol, this.equipamentosListOrig);
        } else {
          this.equipamentosList = this.equipamentosListOrig;
        }
        break;
      case 'bancos':
        if (keyword) {
          this.bancosList = this.utils.tableSearch(keyword, this.bancosListCol, this.bancosListOrig);
        } else {
          this.bancosList = this.bancosListOrig;
        }
        break;
      case 'contas':
        if (keyword) {
          this.contasList = this.utils.tableSearch(keyword, this.contasListCol, this.contasListOrig);
        } else {
          this.contasList = this.contasListOrig;
        }
        break;
      case 'anexos':
        if (keyword) {
          this.anexosList = this.utils.tableSearch(keyword, this.anexosListCol, this.anexosListOrig);
        } else {
          this.anexosList = this.anexosListOrig;
        }
        break;
      case 'assembleias-docs':
        if (keyword) {
          this.assembDocsList = this.utils.tableSearch(keyword, this.assembDocsListCol, this.assembDocsListOrig);
        } else {
          this.assembDocsList = this.assembDocsListOrig;
        }
        break;
      case 'assembleias-ordem-trab':
        if (keyword) {
          this.assembRegTrabList = this.utils.tableSearch(keyword, this.assembRegTrabListCol, this.assembRegTrabListOrig);
        } else {
          this.assembRegTrabList = this.assembRegTrabListOrig;
        }
        break;
      case 'email-config':
        if (keyword) {
          this.emailConfigList = this.utils.tableSearch(keyword, this.emailConfigListCol, this.emailConfigListOrig);
        } else {
          this.emailConfigList = this.emailConfigListOrig;
        }
        break;
      case 'minutas':
        if (keyword) {
          this.minutasList = this.utils.tableSearch(keyword, this.minutasListCol, this.minutasListOrig);
        } else {
          this.minutasList = this.minutasListOrig;
        }
        break;
      case 'avisos-cobranca':
        this.configAvisosCobranca.searchList(keyword);
        break;
    }
  }

  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'rubricas':
        (ev.target.checked) ? this.rubricasList.map(el => el.checked = true ) : this.rubricasList.map(el => el.checked = false );
        break;
      case 'textos':
        (ev.target.checked) ? this.textosList.map(el => el.checked = true ) : this.textosList.map(el => el.checked = false );
        break;
      case 'servicos':
        (ev.target.checked) ? this.servicosList.map(el => el.checked = true ) : this.servicosList.map(el => el.checked = false );
        break;
      case 'ocorrencias':
        (ev.target.checked) ? this.ocorrenciasList.map(el => el.checked = true ) : this.ocorrenciasList.map(el => el.checked = false );
        break;
      case 'intervencoes':
        (ev.target.checked) ? this.intervencoesList.map(el => el.checked = true ) : this.intervencoesList.map(el => el.checked = false );
        break;
      case 'accoes':
        (ev.target.checked) ? this.accoesList.map(el => el.checked = true ) : this.accoesList.map(el => el.checked = false );
        break;
      case 'equipamentos':
        (ev.target.checked) ? this.equipamentosList.map(el => el.checked = true ) : this.equipamentosList.map(el => el.checked = false );
        break;
      case 'bancos':
        (ev.target.checked) ? this.bancosList.map(el => el.checked = true ) : this.bancosList.map(el => el.checked = false );
        break;
      case 'contas':
        (ev.target.checked) ? this.contasList.map(el => el.checked = true ) : this.contasList.map(el => el.checked = false );
        break;
      case 'anexos':
        (ev.target.checked) ? this.anexosList.map(el => el.checked = true ) : this.anexosList.map(el => el.checked = false );
        break;
      case 'assembleias-docs':
        (ev.target.checked) ? this.assembDocsList.map(el => el.checked = true ) : this.assembDocsList.map(el => el.checked = false );
        break;
      case 'assembleias-ordem-trab':
        (ev.target.checked) ? this.assembRegTrabList.map(el => el.checked = true ) : this.assembRegTrabList.map(el => el.checked = false );
        break;
      case 'email-config':
        (ev.target.checked) ? this.emailConfigList.map(el => el.checked = true ) : this.emailConfigList.map(el => el.checked = false );
        break;
      case 'minutas':
        (ev.target.checked) ? this.minutasList.map(el => el.checked = true ) : this.minutasList.map(el => el.checked = false );
        break;
    }
  }

  async tableAction(action, targetList) {
    switch (targetList) {
      case 'rubricas':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.rubricasList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'rubricas');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'textos':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.textosList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'textos');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'servicos':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.servicosList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'servicos');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'ocorrencias':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.ocorrenciasList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'ocorrencias');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'intervencoes':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.intervencoesList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'intervencoes');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'accoes':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.accoesList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'accoes');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'equipamentos':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.equipamentosList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'equipamentos');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'bancos':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.bancosList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'bancos');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'contas':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.contasList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'contas');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'anexos':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.anexosList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'anexos');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'assembleias-docs':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.assembDocsList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'assembleias-docs');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'assembleias-ordem-trab':
        switch (action) {
          case 'add': this.goToDetails(null, 'assembleias-ordem-trab'); break;
          case 'delete':
            let toDelete = this.assembRegTrabList.filter(el => (el.checked && el.can_delete == null));
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'assembleias-ordem-trab');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'email-config':
        switch (action) {
          case 'add': this.goToDetails(null, 'email-config'); break;
          case 'delete':
            let toDelete = this.emailConfigList.filter(el => (el.checked && el.can_delete == '1'));
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'email-config');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'minutas':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.minutasList.filter(el => (el.checked));
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'minutas');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'avisos-cobranca':
        switch (action) {
          case 'add': this.configAvisosCobranca.open(); break;
          case 'delete': this.configAvisosCobranca.del(); break;
        }
        break;
    }
  }

  getDetails() {
    this.api.getRubricas().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.rubricasList = this.utils.getListPagination(res.data, this.rubricasSelectedPage, this.rubricasItemPerPAge);

        // CONVERT 1/0 TO true/false
        this.rubricasListOrig = res.data.map(el => {
          el['despesa'] = (el['despesa'] == '1');
          el['receita'] = (el['receita'] == '1');
          el['val_fixo'] = (el['val_fixo'] == '1');
          return el;
        });
        this.rubricasListLength = res.data.length;

        // GET TEXTOS
        this.api.getTextos().subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            res.data.forEach(el => {
              el.text_inicial = el.text_inicial.replace(/NEW_LINE/g, '\n');
              el.texto_final = el.texto_final.replace(/NEW_LINE/g, '\n');
            });

            this.textosList = this.utils.getListPagination(res.data, this.textosSelectedPage, this.textosItemPerPAge);
            this.textosListOrig = res.data;
            this.textosListLength = res.data.length;
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {});

        // // GET SERVICOS
        // this.api.getServicos().subscribe(res => {
        //   if (res.hasOwnProperty('success') && res.success) {
        //     this.servicosList = this.utils.getListPagination(res.data, this.servicosSelectedPage, this.servicosItemPerPAge);
        //     this.servicosListOrig = res.data;
        //     this.servicosListLength = res.data.length;
        //   } else {
        //     this.utils.apiErrorMsg(res);
        //   }
        // }, err => {});

        // // GET OCORRENCIAS
        // this.api.getTipoOcorrencias().subscribe(res => {
        //   if (res.hasOwnProperty('success') && res.success) {
        //     this.ocorrenciasList = this.utils.getListPagination(res.data, this.ocorrenciasSelectedPage, this.ocorrenciasItemPerPAge);
        //     this.ocorrenciasListOrig = res.data;
        //     this.ocorrenciasListLength = res.data.length;
        //   } else {
        //     this.utils.apiErrorMsg(res);
        //   }
        // }, err => {});

        // // GET INTERVENCAO
        // this.api.getTipoIntervencoes().subscribe(res => {
        //   if (res.hasOwnProperty('success') && res.success) {
        //     this.intervencoesList = this.utils.getListPagination(res.data, this.intervencoesSelectedPage, this.intervencoesItemPerPAge);
        //     this.intervencoesListOrig = res.data;
        //     this.intervencoesListLength = res.data.length;
        //   } else {
        //     this.utils.apiErrorMsg(res);
        //   }
        // }, err => {});

        // // GET ACCOES
        // this.api.getAccoes().subscribe(res => {
        //   if (res.hasOwnProperty('success') && res.success) {
        //     this.accoesList = this.utils.getListPagination(res.data, this.accoesSelectedPage, this.accoesItemPerPAge);
        //     this.accoesListOrig = res.data;
        //     this.accoesListLength = res.data.length;
        //   } else {
        //     this.utils.apiErrorMsg(res);
        //   }
        // }, err => {});

        // // GET EQUIPAMENTOS
        // this.api.getTipoEquipamentos().subscribe(res => {
        //   if (res.hasOwnProperty('success') && res.success) {
        //     this.equipamentosList = this.utils.getListPagination(res.data, this.equipamentosSelectedPage, this.equipamentosItemPerPAge);
        //     this.equipamentosListOrig = res.data;
        //     this.equipamentosListLength = res.data.length;
        //   } else {
        //     this.utils.apiErrorMsg(res);
        //   }
        // }, err => {});

        // GET BANCOS
        this.api.getBancos().subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.bancosList = this.utils.getListPagination(res.data, this.bancosSelectedPage, this.bancosItemPerPAge);
            this.bancosListOrig = res.data;
            this.bancosListLength = res.data.length;
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {});

        // GET CONTAS
        this.api.getTipoContas().subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.contasList = this.utils.getListPagination(res.data, this.contasSelectedPage, this.contasItemPerPAge);
            this.contasListOrig = res.data;
            this.contasListLength = res.data.length;
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {});

        // GET ANEXOS
        this.api.getTiposAnexos().subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            res.data = res.data.filter(it => it.id != '0');
            this.anexosList = this.utils.getListPagination(res.data, this.anexosSelectedPage, this.anexosItemPerPAge);
            this.anexosListOrig = res.data;
            this.anexosListLength = res.data.length;

            this.anexosOpts = [];
            this.anexosListOrig.forEach(el => {
              this.anexosOpts.push({
                id: parseInt(el.id),
                name: el.name,
              });
            });

          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {});

        this.getEmailTemplates();
        this.getCartaTemplates();

        // GET DOCS ASSEMBLEIAS
        this.api.getAssembDocs().subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.assembDocsList = this.utils.getListPagination(res.data, this.assembDocsSelectedPage, this.assembDocsItemPerPAge);
            this.assembDocsListOrig = res.data;
            this.assembDocsListLength = res.data.length;
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {});

        // GET REG TRABALHOS ASSEMBLEIAS
        this.api.getAssembRegTrab().subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            res.data.sort((a,b) => {
              if ((a.label == null) === (b.label == null)) {
                return a.ordem - b.ordem
              } else {
                if (a.label == null) return 1;
                return -1;
              }

            });
            this.assembRegTrabList = this.utils.getListPagination(res.data, this.assembRegTrabSelectedPage, this.assembRegTrabItemPerPAge);

            this.assembRegTrabList.forEach(el => {

              if (Array.isArray(el['resp'])) {
                el.resp.forEach(item => {
                  item.has_anexo = (item.has_anexo == 1);
                  item.hasEmail = (item.hasEmail == 1);
                  item.hasCarta = (item.hasCarta == 1);
                  item.default = (item.hasOwnProperty('default') && item.default == 1) ? true : false;
                });
              }

            });

            this.assembRegTrabListOrig = res.data.map(el => { 
              el.ordinarias_predefinido = (el.ordinarias_predefinido == 1);
              el.extras_predefinido = (el.extras_predefinido == 1);
              el.ordinarias_diversos_predefinido = (el.ordinarias_diversos_predefinido == 1);
              el.extras_diversos_predefinido = (el.extras_diversos_predefinido == 1);
              el.is_ordem_trabalho = (el.is_ordem_trabalho == 1);
              el.is_assunto_diverso = (el.is_assunto_diverso == 1);
              el.is_multipla_entrada = (el.is_multipla_entrada == 1);
              el.has_anexo = (el.has_anexo == 1);

              return el;
            });
            this.ordemTrabalhosDiversosOpts = this.assembRegTrabListOrig.filter(el => el.label !== 'ASSUNTOS_DIVERSOS' && el.is_assunto_diverso == 1).map(el => { return { name: el.descricao, value: el }})
            this.assembRegTrabListLength = this.assembRegTrabListOrig.length;
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {});

        // GET EMAIL CONFIGS
        this.api.getEmailConfigs().subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.emailConfigList = this.utils.getListPagination(res.data, this.emailConfigSelectedPage, this.emailConfigItemPerPAge);
            this.emailConfigListOrig = res.data;
            this.emailConfigListLength = res.data.length;
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {});
        
        //GET MINUTAS
        this.api.getAllMinutas().subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.utils.sort('minutas', res.data);
            this.minutasList = this.utils.getListPagination(res.data, this.minutasSelectedPage, this.minutasItemPerPage);
            this.minutasListOrig = res.data.map(minuta => ({... minuta, checked: false }));
            this.minutasListLength = res.data.length;
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {});

      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }
  selDefaultResponse(item) {
    if (item.default) {
      // REMOVE SELECTION FROM OTHER ENTRIES
      this.respostas.filter(it => it.id != item.id).forEach(it => it.default = false);
    }
  }

  requiredFieldsFeedback = false;
  newEntSubmit(targetForm) {
    let data = null;

    this.requiredFieldsFeedback = true;
    setTimeout(_ => this.requiredFieldsFeedback = false, 4000);
   

    switch (targetForm) {
      case 'rubricas':
        if (!this.rubricaNewForm.valid) return;

        this.submittingForm = true;

        data = this.rubricaNewForm.getRawValue();
        data['despesa'] = this.utils.boolToStr(data['despesa']);
        data['receita'] = this.utils.boolToStr(data['receita']);
        data['val_fixo'] = this.utils.boolToStr(data['val_fixo']);

        this.api.addRubrica(data.nome, data.n_ordem, data.despesa, data.receita, data.val_fixo).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            res['data']['despesa'] = (res['data']['despesa'] == '1');
            res['data']['receita'] = (res['data']['receita'] == '1');
            res['data']['val_fixo'] = (res['data']['val_fixo'] == '1');
            this.rubricasListOrig = [res['data']].concat(this.rubricasListOrig);
            this.pageChange(this.rubricasSelectedPage, targetForm);

            this.rubricasListLength ++;

            this.addModalRef.approve();
            this.clearForm('rubricas-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'textos':

        if (!this.textoNewForm.valid) return;
        
        this.submittingForm = true;
        data = this.textoNewForm.getRawValue();

        this.api.addTexto(data.nome, data.tipo, data.assunto, data.text_inicial, data.texto_final).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.textosListOrig = [res['data']].concat(this.textosListOrig);
            this.pageChange(this.textosSelectedPage, targetForm);

            this.textosListLength ++;

            this.addModalRef.approve(res.data? res.data.id : null);
            this.clearForm('textos-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'servicos':
        if (!this.servicoNewForm.valid) return;

        this.submittingForm = true;

        data = this.servicoNewForm.getRawValue();

        this.api.addServicos(data.descricao, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.servicosListOrig = [res['data']].concat(this.servicosListOrig);
            this.pageChange(this.servicosSelectedPage, targetForm);

            this.rubricasListLength ++;

            this.addModalRef.approve();
            this.clearForm('servicos-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'ocorrencias':
        if (!this.ocorrenciaNewForm.valid) return;

        this.submittingForm = true;

        data = this.ocorrenciaNewForm.getRawValue();

        this.api.addTipoOcorrencia(data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.ocorrenciasListOrig = [res['data']].concat(this.ocorrenciasListOrig);
            this.pageChange(this.ocorrenciasSelectedPage, targetForm);

            this.ocorrenciasListLength ++;

            this.addModalRef.approve();
            this.clearForm('ocorrencias-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'intervencoes':
        if (!this.intervencaoNewForm.valid) return;

        this.submittingForm = true;

        data = this.intervencaoNewForm.getRawValue();

        this.api.addTipoIntervencao(data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.intervencoesListOrig = [res['data']].concat(this.intervencoesListOrig);
            this.pageChange(this.intervencoesSelectedPage, targetForm);

            this.intervencoesListLength ++;

            this.addModalRef.approve();
            this.clearForm('intervencoes-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'accoes':
        if (!this.accaoNewForm.valid) return;

        this.submittingForm = true;

        data = this.accaoNewForm.getRawValue();

        this.api.addAccao(data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.accoesListOrig = [res['data']].concat(this.accoesListOrig);
            this.pageChange(this.rubricasSelectedPage, targetForm);

            this.accoesListLength ++;

            this.addModalRef.approve();
            this.clearForm('accoes-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'equipamentos':
        if (!this.equipamentoNewForm.valid) return;

        this.submittingForm = true;

        data = this.equipamentoNewForm.getRawValue();

        this.api.addTipoEquipamento(data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.equipamentosListOrig = [res['data']].concat(this.equipamentosListOrig);
            this.pageChange(this.equipamentosSelectedPage, targetForm);

            this.equipamentosListLength ++;

            this.addModalRef.approve();
            this.clearForm('equipamentos-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'bancos':
        if (!this.bancoNewForm.valid) return;

        this.submittingForm = true;

        data = this.bancoNewForm.getRawValue();

        this.api.addBanco(data.banco, data.sigla).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.bancosListOrig = [res['data']].concat(this.bancosListOrig);
            this.pageChange(this.bancosSelectedPage, targetForm);

            this.bancosListLength ++;

            this.addModalRef.approve();
            this.clearForm('bancos-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'contas':
        if (!this.contaNewForm.valid) return;

        this.submittingForm = true;

        data = this.contaNewForm.getRawValue();

        this.api.addTipoContas(data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.contasListOrig = [res['data']].concat(this.contasListOrig);
            this.pageChange(this.contasSelectedPage, targetForm);

            this.contasListLength ++;

            this.addModalRef.approve();
            this.clearForm('contas-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'anexos':
        if (!this.anexosNewForm.valid) return;

        this.submittingForm = true;

        data = this.anexosNewForm.getRawValue();

        this.api.addTiposAnexos(data.name, data.ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.anexosListOrig = [res['data']].concat(this.anexosListOrig).sort((a, b) => {
              if (Number(a.ordem) > Number(b.ordem)) {
                return 1;
              } else {
                return -1;
              }
            });
            this.pageChange(this.anexosSelectedPage, targetForm);

            this.anexosListLength ++;

            this.addModalRef.approve();
            this.clearForm('anexos-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'assembleias-docs':
        // if (!this.contaNewForm.valid) return;

        // this.submittingForm = true;

        // data = this.contaNewForm.getRawValue();

        // this.api.addTipoContas(data.nome, data.n_ordem).subscribe(res => {
        //   if (res.hasOwnProperty('success') && res['success']) {     
        //     // API RETURN SUCCESS
        //     this.contasListOrig = [res['data']].concat(this.contasListOrig);
        //     this.pageChange(this.contasSelectedPage, targetForm);

        //     this.contasListLength ++;

        //     this.addModalRef.approve();
        //     this.clearForm('contas-add');
        //   } else {
        //     this.utils.apiErrorMsg(res);
        //   }
        //   this.submittingForm = false;
        // }, err => {
        //   this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        //   this.submittingForm = false;
        // }); 
        break;
      case 'assembleias-ordem-trab':
        this.updateEntSubmit('assembleias-ordem-trab');
        break;
      case 'email-config':
        this.updateEntSubmit('email-config');
        break;
      case 'minutas':
        if (!this.minutaNewForm.valid) return;

        this.submittingForm = true;

        let minuta:NewMinuta = this.minutaNewForm.getRawValue();

        this.api.addMinuta(minuta).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            this.minutasListOrig = [{...res.data, checked: false}].concat(this.minutasListOrig);
            this.pageChange(this.minutasSelectedPage, targetForm);

            this.minutasListLength ++;

            this.addModalRef.approve();
            this.clearForm('minutas-add');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 

        break;
    }
  }

  updateEntSubmit(targetForm) {
    let data = null;

    this.requiredFieldsFeedback = true;
    setTimeout(() => this.requiredFieldsFeedback = false, 4000);

    switch (targetForm) {
      case 'rubricas':
        if (!this.rubricaUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.rubricaUpdateForm.getRawValue();


        this.api.updateRubrica(data.id, data.nome, data.n_ordem, this.utils.boolToStr(data.despesa), this.utils.boolToStr(data.receita), this.utils.boolToStr(data.val_fixo)).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.rubricasListOrig.findIndex(el => (el.id === data.id));
            this.rubricasListOrig[i] = data;

            this.pageChange(this.rubricasSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('rubricas-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'textos':
        if (!this.textoUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.textoUpdateForm.getRawValue();

        this.api.updateTexto(data.id, data.nome, data.tipo, data.assunto, data.text_inicial, data.texto_final).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.textosListOrig.findIndex(el => (el.id === data.id));
            this.textosListOrig[i] = data;

            this.pageChange(this.textosSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('textos-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'servicos':
        if (!this.servicoUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.servicoUpdateForm.getRawValue();

        this.api.updateServicos(data.id, data.descricao, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.servicosListOrig.findIndex(el => (el.id === data.id));
            this.servicosListOrig[i] = data;

            this.pageChange(this.servicosSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('servicos-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'ocorrencias':
        if (!this.ocorrenciaUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.ocorrenciaUpdateForm.getRawValue();

        this.api.updateTipoOcorrencia(data.id, data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.ocorrenciasListOrig.findIndex(el => (el.id === data.id));
            this.ocorrenciasListOrig[i] = data;

            this.pageChange(this.ocorrenciasSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('ocorrencias-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'intervencoes':
        if (!this.intervencaoUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.intervencaoUpdateForm.getRawValue();

        this.api.updateTipoIntervencao(data.id, data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.intervencoesListOrig.findIndex(el => (el.id === data.id));
            this.intervencoesListOrig[i] = data;

            this.pageChange(this.intervencoesSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('intervencoes-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'accoes':
        if (!this.accaoUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.accaoUpdateForm.getRawValue();

        this.api.updateAccao(data.id, data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.accoesListOrig.findIndex(el => (el.id === data.id));
            this.accoesListOrig[i] = data;

            this.pageChange(this.accoesSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('accoes-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'equipamentos':
        if (!this.equipamentoUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.equipamentoUpdateForm.getRawValue();

        this.api.updateTipoEquipamento(data.id, data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.equipamentosListOrig.findIndex(el => (el.id === data.id));
            this.equipamentosListOrig[i] = data;

            this.pageChange(this.equipamentosSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('equipamentos-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'bancos':
        if (!this.bancoUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.bancoUpdateForm.getRawValue();

        this.api.updateBanco(data.id, data.banco, data.sigla).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.bancosListOrig.findIndex(el => (el.id === data.id));
            this.bancosListOrig[i] = data;

            this.pageChange(this.bancosSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('bancos-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'contas':
        if (!this.contaUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.contaUpdateForm.getRawValue();

        this.api.updateTipoContas(data.id, data.nome, data.n_ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.contasListOrig.findIndex(el => (el.id === data.id));
            this.contasListOrig[i] = data;

            this.pageChange(this.contasSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('contas-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'anexos':
        if (!this.anexosUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.anexosUpdateForm.getRawValue();

        this.api.updateTiposAnexos(data.id, data.name, data.ordem).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.anexosListOrig.findIndex(el => (el.id === data.id));
            this.anexosListOrig[i] = data;

            this.pageChange(this.anexosSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('anexos-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'assembleias-docs':
        if (!this.assembDocsUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.assembDocsUpdateForm.getRawValue();

        // VALIDATE TEXTO INICIAL
        let markers = JSON.parse(this.assembDocsList.find(el => (el.id === data.id)).validadores);

        let missingMarkersInit = '';
        markers.texto_inicial.forEach((el, i) => {
          if (data.text_inicial.indexOf('[[ ' + el + ' ]]') === -1) {
              missingMarkersInit += ('[[ ' + el  + ' ]]' + ', ');
          }
        });
        if (missingMarkersInit[missingMarkersInit.length - 2] == ',') missingMarkersInit = missingMarkersInit.substring(0, missingMarkersInit.length - 2);
        if (missingMarkersInit !== '') {
          this.toastr.error('Não é possível submeter as alterações efetuadas. Os marcadores: ' + missingMarkersInit + ' estão em falta no campo destinado ao texto inicial.', 'Alerta', { disableTimeOut: true });
          return;
        }

        let missingMarkersFinal = '';
        markers.texto_final.forEach(el => {
          if (data.texto_final.indexOf('[[ ' + el + ' ]]') === -1) {
            missingMarkersFinal += ('[[ ' + el + ' ]]' + ', ');
          }
        });
        if (missingMarkersFinal[missingMarkersFinal.length - 2] == ',') missingMarkersFinal = missingMarkersFinal.substring(0, missingMarkersFinal.length - 2);
        if (missingMarkersFinal !== '') {
          this.toastr.error('Não é possível submeter as alterações efetuadas. Os marcadores: ' + missingMarkersFinal + ' estão em falta no campo destinado ao texto final.', 'Alerta', { disableTimeOut: true });
          return;
        }

        this.api.updateAssembDocsModels(data.id, data.nome, data.text_inicial, data.texto_final).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.assembDocsListOrig.findIndex(el => (el.id === data.id));
            this.assembDocsListOrig[i]['nome'] = data.nome;
            this.assembDocsListOrig[i]['texto_inicial'] = data.text_inicial;
            this.assembDocsListOrig[i]['texto_final'] = data.texto_final;

            this.pageChange(this.assembDocsSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('assembleias-docs-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;

      case 'assembleias-ordem-trab':
        if (!this.assembRegTrabUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.assembRegTrabUpdateForm.getRawValue();

        let respostas = JSON.parse(JSON.stringify(this.respostas));
        let predefinidoOrdinarias = [];
        let predefinidoExtraordinarias = [];
        let ot = this.assembRegTrabListOrig.find(el => el.id === data.id);
        if (ot && ot.label === 'ASSUNTOS_DIVERSOS') {
          predefinidoOrdinarias =  this.predefinidoOrdinarias.map(el => el.value.id);
          predefinidoExtraordinarias = this.predefinidoExtraordinarias.map(el => el.value.id);
        }
        this.api.updateOrdemTrabalho(data.id, data.descricao, data.ordem, data.ordinarias_predefinido, data.extras_predefinido, data.ordinarias_diversos_predefinido, data.extras_diversos_predefinido, data.is_ordem_trabalho, data.is_assunto_diverso, data.is_multipla_entrada, data.textoInicial, data.textoFinal, null, null, respostas, predefinidoOrdinarias, predefinidoExtraordinarias).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS

            let auxEntry = res.data;
            auxEntry['ordinarias_predefinido'] = (res.data['ordinarias_predefinido'] == 1);
            auxEntry['extras_predefinido'] = (res.data['extras_predefinido'] == 1);
            auxEntry['ordinarias_diversos_predefinido'] = (res.data['ordinarias_diversos_predefinido'] == 1);
            auxEntry['extras_diversos_predefinido'] = (res.data['extras_diversos_predefinido'] == 1);
            auxEntry['is_ordem_trabalho'] = (res.data['is_ordem_trabalho'] == 1);
            auxEntry['is_assunto_diverso'] = (res.data['is_assunto_diverso'] == 1);
            auxEntry['is_multipla_entrada'] = (res.data['is_multipla_entrada'] == 1);
            auxEntry['has_anexo'] = (res.data['has_anexo'] == 1);
            auxEntry['hasEmail'] = (res.data['hasEmail'] == 1);
            auxEntry['hasCarta'] = (res.data['hasCarta'] == 1);

            let i = this.assembRegTrabListOrig.findIndex(el => (el.id === data.id));
            if (i === -1) {
              this.assembRegTrabListOrig.push(auxEntry);
            } else {
              this.assembRegTrabListOrig[i] = auxEntry;
            }

            if (ot && ot.label === 'ASSUNTOS_DIVERSOS') {
              this.assembRegTrabListOrig.forEach(el => {
                el.ordinarias_diversos_predefinido = !!predefinidoOrdinarias.find(ord => ord === el.id);
                el.extras_diversos_predefinido = !!predefinidoExtraordinarias.find(ord => ord === el.id);
              })
            }
            
            this.ordemTrabalhosDiversosOpts = this.assembRegTrabListOrig.filter(el => el.label !== 'ASSUNTOS_DIVERSOS' && el.is_assunto_diverso == 1).map(el => { return { name: el.descricao, value: el }})

            this.pageChange(this.assembRegTrabSelectedPage, targetForm);

            this.updateModalRef.approve();
            setTimeout(() => {
              this.clearForm('assembleias-ordem-trab-update');
            }, 250);
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;

      case 'email-config':
        this.submittingForm = true;

        this.api.updateEmailConfigs(this.emailConfig.id, this.emailConfig.legenda, this.emailConfig.assunto, this.emailConfig.corpo).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.emailConfigListOrig.findIndex(el => (el.id === this.emailConfig.id));
            this.emailConfigListOrig[i]['legenda'] = this.emailConfig.legenda;
            this.emailConfigListOrig[i]['assunto'] = this.emailConfig.assunto;
            this.emailConfigListOrig[i]['corpo'] = this.emailConfig.corpo;
      
            this.pageChange(this.emailConfigSelectedPage, targetForm);

            this.updateEmailModalRef.approve();
            this.clearForm('email-config');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
      case 'minutas':
        if (!this.minutasUpdateForm.valid) return;

        this.submittingForm = true;

        let minuta: Minuta = this.minutasUpdateForm.getRawValue();

        this.api.updateMinuta(minuta).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.minutasListOrig.findIndex(el => (el.id === minuta.id));
            this.minutasListOrig[i] = {... minuta, checked:false};

            this.pageChange(this.minutasSelectedPage, targetForm);

            this.updateModalRef.approve();
            this.clearForm('minutas-update');
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
    }
  }

  clearForm(targetForm) {
    switch (targetForm) {
      case 'rubricas-add':
        this.rubricaNewForm.reset();
        break;
      case 'rubricas-update':
        this.rubricaUpdateForm.reset();
        break;

      case 'textos-add':
        this.textoNewForm.reset();
        break;
      case 'textos-update':
        this.textoUpdateForm.reset();
        break;

      case 'servicos-add':
        this.servicoNewForm.reset();
        break;
      case 'servicos-update':
        this.servicoUpdateForm.reset();
        break;

      case 'ocorrencias-add':
        this.ocorrenciaNewForm.reset();
        break;
      case 'ocorrencias-update':
        this.ocorrenciaUpdateForm.reset();
        break;

      case 'intervencoes-add':
        this.intervencaoNewForm.reset();
        break;
      case 'intervencoes-update':
        this.intervencaoUpdateForm.reset();
        break;

      case 'accoes-add':
        this.accaoNewForm.reset();
        break;
      case 'accoes-update':
        this.accaoUpdateForm.reset();
        break;

      case 'equipamentos-add':
        this.equipamentoNewForm.reset();
        break;
      case 'equipamentos-update':
        this.equipamentoUpdateForm.reset();
        break;

      case 'bancos-add':
        this.bancoNewForm.reset();
        break;
      case 'bancos-update':
        this.bancoUpdateForm.reset();
        break;

      case 'contas-add':
        this.contaNewForm.reset();
        break;
      case 'contas-update':
        this.contaUpdateForm.reset();
        break;

      case 'anexos-add':
        this.anexosNewForm.reset();
        break;
      case 'anexos-update':
        this.anexosUpdateForm.reset();
        break;

      case 'assembleias-docs-update':
        this.assembDocsUpdateForm.reset();
        break;
      case 'assembleias-ordem-trab-update':
        this.assembRegTrabUpdateForm.reset();
        break;
        
      case 'email-config':
        this.emailConfig = {
          id: null,
          legenda: null,
          assunto: null,
          corpo: null,
        }
        break;
      case 'minutas-add':
        this.minutaNewForm.reset();
        break;
      case 'minutas-update':
        this.minutasUpdateForm.reset();
        break;
    }
  }

  addEntity() {
    this.addModalRef = this.modalService.open(this.addModalConfig).onApprove((id?) => this.executeNavigationAction(id)).onDeny(() => {this.requiredFieldsFeedback = false;this.executeNavigationAction()});
  }
  
  isAssuntosDiversos = false;
  goToDetails(ent, targetList) {
    switch (targetList) {
      case 'rubricas':
        this.rubricaUpdateForm.patchValue({
          id: ent.id,
          nome: ent.nome,
          n_ordem: ent.n_ordem,
          despesa: this.utils.strToBool(ent.despesa),
          receita: this.utils.strToBool(ent.receita),
          val_fixo: this.utils.strToBool(ent.val_fixo),
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'textos':
        this.textoUpdateForm.patchValue({
          id: ent.id,
          nome: ent.nome,
          tipo: ent.tipo,
          assunto: ent.assunto,
          text_inicial: ent.text_inicial,
          texto_final: ent.texto_final
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'servicos':
        this.servicoUpdateForm.patchValue({
          id: ent.id,
          descricao: ent.descricao,
          n_ordem: ent.n_ordem,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'ocorrencias':
        this.ocorrenciaUpdateForm.patchValue({
          id: ent.id,
          nome: ent.nome,
          n_ordem: ent.n_ordem,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'intervencoes':
        this.intervencaoUpdateForm.patchValue({
          id: ent.id,
          nome: ent.nome,
          n_ordem: ent.n_ordem,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'accoes':
        this.accaoUpdateForm.patchValue({
          id: ent.id,
          nome: ent.nome,
          n_ordem: ent.n_ordem,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'equipamentos':
        this.equipamentoUpdateForm.patchValue({
          id: ent.id,
          nome: ent.nome,
          n_ordem: ent.n_ordem,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'bancos':
        this.bancoUpdateForm.patchValue({
          id: ent.id,
          banco: ent.banco,
          sigla: ent.sigla,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'contas':
        this.contaUpdateForm.patchValue({
          id: ent.id,
          nome: ent.nome,
          n_ordem: ent.n_ordem,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'anexos':
        this.anexosUpdateForm.patchValue({
          id: ent.id,
          name: ent.name,
          ordem: ent.ordem,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'assembleias-docs':
        this.assembDocsUpdateForm.patchValue({
          id: ent.id,
          nome: ent.nome,
          text_inicial: ent.texto_inicial,
          texto_final: ent.texto_final
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'assembleias-ordem-trab':

        if (ent) {
          this.assembRegTrabUpdateForm.patchValue({
            id: ent.id,
            descricao: ent.descricao,
            ordem: ent.ordem,
            ordinarias_predefinido: ent.ordinarias_predefinido,
            extras_predefinido: ent.extras_predefinido,
            ordinarias_diversos_predefinido: ent.ordinarias_diversos_predefinido,
            extras_diversos_predefinido: ent.extras_diversos_predefinido,
            is_ordem_trabalho: ent.is_ordem_trabalho,
            is_assunto_diverso: ent.is_assunto_diverso,
            is_multipla_entrada: ent.is_multipla_entrada,
            has_anexo: ent.has_anexo,
            tipo_anexo_id: ent.tipo_anexo_id,
            hasEmail: ent.hasEmail,
            emailId: ent.emailId,
            hasCarta: ent.hasCarta,
            cartaId: ent.cartaId,
            textoInicial: ent.texto_inicial,
            textoFinal: ent.texto_final,
          });
          this.ordTrabalho = ent;
          this.respostas = (ent.resp) ? ent.resp : [];
        } else {
          let auxEnt = {
            id: null,
            descricao: null,
            ordem: null,
            ordinarias_predefinido: false,
            extras_predefinido: false,
            ordinarias_diversos_predefinido: false,
            extras_diversos_predefinido: false,
            is_ordem_trabalho: true,
            is_assunto_diverso: true,
            is_multipla_entrada: false,
            has_anexo: false,
            tipo_anexo_id: null,
            hasEmail: false,
            emailId: null,
            hasCarta: false,
            cartaId: null,
            resp: [],
            textoInicial: null,
            textoFinal: null,
          }
          this.assembRegTrabUpdateForm.patchValue(auxEnt);
          this.ordTrabalho = auxEnt;
          this.respostas = [];
        }

        this.respostas.forEach(el => { el['checked'] = false; });

        this.isAssuntosDiversos = (ent && ent.hasOwnProperty('label') && ent.label == 'ASSUNTOS_DIVERSOS');

        if (this.isAssuntosDiversos) {
          this.predefinidoOrdinarias = [];
          this.assembRegTrabListOrig.filter(el => el.ordinarias_diversos_predefinido).map(el => {
            let optValue = this.ordemTrabalhosDiversosOpts.find(opt => opt.value.id === el.id);
            if (optValue) this.predefinidoOrdinarias.push(optValue);
          });
          this.predefinidoExtraordinarias = [];
          this.assembRegTrabListOrig.filter(el => el.extras_diversos_predefinido).map(el => {
            let optValue = this.ordemTrabalhosDiversosOpts.find(opt => opt.value.id === el.id);
            if (optValue) this.predefinidoExtraordinarias.push(optValue);
          });
        }

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
      case 'email-config':
        if (!ent) {
          this.toastr.error('Não é possível adicionar emails.', 'Informação');
          return;
        }
        this.emailConfig['id'] = ent.id;
        this.emailConfig['legenda'] = ent.legenda;
        this.emailConfig['assunto'] = ent.assunto;
        this.emailConfig['corpo'] = ent.corpo;

        this.updateEmailModalRef = this.modalService.open(this.updateEmailModalConfig);
        break;
      case 'minutas':
        this.minutasUpdateForm.patchValue({
          id: ent.id,
          nome: ent.nome,
          assunto: ent.assunto,
          texto: ent.texto,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
    }

    this.updateModalRef.onDeny(() => {this.requiredFieldsFeedback = false;});
  }

  ordTrabalho = null;
  respostas = [];
  resp = null;
  editResposta(resposta) {
    this.resp = resposta;
    if (!this.resp.hasOwnProperty('hasEmail') || !this.resp.hasOwnProperty('emailId') || !this.resp.hasOwnProperty('hasCarta') || !this.resp.hasOwnProperty('cartaId')) {
      this.resp['hasEmail'] = null;
      this.resp['emailId'] = null;
      this.resp['hasCarta'] = null;
      this.resp['cartaId'] = null;
    }
    this.updateRespostaModalRef = this.modalService.open(this.updateRespostaModalConfig);
  }

  addResposta() {
    this.resp = {
      id: null,
      id_ordem_trabalho: this.assembRegTrabUpdateForm.get('id').value,
      descricao: null,
      modelo_texto: null,
    };

    this.updateRespostaModalRef = this.modalService.open(this.updateRespostaModalConfig);
  }

  delRespostas() {
    let toDelete = this.respostas.filter(el => el.checked);
    if (toDelete.length > 0) {
      this.presentAlert().then(res => {
        if (res) {
          this.api.delOrdemTrabRespostas(toDelete).subscribe(res => {
            if (res.hasOwnProperty('success') && res.success) {
              this.respostas = this.respostas.filter(el => (!el.checked));
              this.ordTrabalho.resp = this.respostas;
            } else {
              this.utils.apiErrorMsg(res);
            }
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          });
        }
      });
    } else {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
    }
  }

  saveResposta() {
    // TODO: INPUT VALIDATION
    // if (!this.assembRegTrabUpdateForm.valid) return;

    // VALIDATE TEXTO INICIAL
    let markers = [];
    try {
      markers = JSON.parse(this.resp.validadores);
    } catch (e) {
      markers = [];
    }

    if (markers !== null) {
      let missingMarkers = '';
      markers.forEach((el, i) => {
        if (this.resp.modelo_texto.indexOf('[[ ' + el + ' ]]') === -1) {
            missingMarkers += ('[[ ' + el  + ' ]]' + ', ');
        }
      });
      if (missingMarkers[missingMarkers.length - 2] == ',') missingMarkers = missingMarkers.substring(0, missingMarkers.length - 2);
      if (missingMarkers !== '') {
        this.toastr.error('Não é possível submeter as alterações efetuadas. Os marcadores: ' + missingMarkers + ' estão em falta no campo destinado à resposta.', 'Alerta', { disableTimeOut: true });
        return;
      }
    }


    this.submittingForm = true;
    this.api.updateOrdemTrabalhoResp(this.resp.id, this.resp.id_ordem_trabalho, this.resp.descricao, this.resp.modelo_texto, this.resp.has_anexo, this.resp.tipo_anexo_id, null, this.resp.hasEmail, this.resp.emailId, this.resp.hasCarta, this.resp.cartaId).subscribe(res => {
      if (res.hasOwnProperty('success') && res['success']) { 

        if (!this.resp.id) {
          this.resp.id = res.data.id;
         
          this.respostas.push(this.resp);

          this.ordTrabalho.resp = this.respostas;
        } 

        this.updateRespostaModalRef.approve();
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.submittingForm = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.submittingForm = false;
    }); 
  }

  delEntity(toDelete, targetList) {
    switch (targetList) {
      case 'rubricas':
        this.api.delRubricas(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.rubricasListOrig = this.rubricasListOrig.filter(el => !el.checked);
            this.pageChange(this.rubricasSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'textos':
        this.api.delTextos(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.textosListOrig = this.textosListOrig.filter(el => !el.checked);
            this.pageChange(this.textosSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'servicos':
        this.api.delServicos(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.servicosListOrig = this.servicosListOrig.filter(el => !el.checked);
            this.pageChange(this.servicosSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'ocorrencias':
        this.api.delTipoOcorrencias(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.ocorrenciasListOrig = this.ocorrenciasListOrig.filter(el => !el.checked);
            this.pageChange(this.ocorrenciasSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'intervencoes':
        this.api.delTipoIntervencoes(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.intervencoesListOrig = this.intervencoesListOrig.filter(el => !el.checked);
            this.pageChange(this.intervencoesSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'accoes':
        this.api.delAccoes(toDelete).subscribe(res => {
          if (res.success) {
            this.accoesListOrig = this.accoesListOrig.filter(el => !el.checked);
            this.pageChange(this.accoesSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'equipamentos':
        this.api.delTipoEquipamentos(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.equipamentosListOrig = this.equipamentosListOrig.filter(el => !el.checked);
            this.pageChange(this.equipamentosSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'bancos':
        this.api.delBancos(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.bancosListOrig = this.bancosListOrig.filter(el => !el.checked);
            this.pageChange(this.bancosSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'contas':
        this.api.delTipoContas(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.contasListOrig = this.contasListOrig.filter(el => !el.checked);
            this.pageChange(this.contasSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'anexos':
        this.api.delTiposAnexos(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.anexosListOrig = this.anexosListOrig.filter(el => !el.checked);
            this.anexosOpts = [];
            this.anexosListOrig.forEach(el => {
              this.anexosOpts.push({
                id: parseInt(el.id),
                name: el.name,
              });
            });
            this.pageChange(this.anexosSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'assembleias-ordem-trab':
        this.api.delOrdemTrabalho(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.assembRegTrabListOrig = this.assembRegTrabListOrig.filter(el => !el.checked || el.label != null);
            this.pageChange(this.assembRegTrabSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'minutas':
        this.api.deleteMinutas(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.minutasListOrig = this.minutasListOrig.filter(el => !el.checked);
            this.pageChange(this.minutasSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
    }
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }

  pageChange(ev, targetList) {
    switch (targetList) {
      case 'rubricas':
        this.rubricasSelectedPage = ev;
        this.rubricasList = this.utils.getListPagination(this.rubricasListOrig, this.rubricasSelectedPage, this.rubricasItemPerPAge);
        break;
      case 'textos':
        this.textosSelectedPage = ev;
        this.textosList = this.utils.getListPagination(this.textosListOrig, this.textosSelectedPage, this.textosItemPerPAge);
        break;
      case 'servicos':
        this.servicosSelectedPage = ev;
        this.servicosList = this.utils.getListPagination(this.servicosListOrig, this.servicosSelectedPage, this.servicosItemPerPAge);
        break;
      case 'ocorrencias':
        this.ocorrenciasSelectedPage = ev;
        this.ocorrenciasList = this.utils.getListPagination(this.ocorrenciasListOrig, this.ocorrenciasSelectedPage, this.ocorrenciasItemPerPAge);
        break;
      case 'intervencoes':
        this.intervencoesSelectedPage = ev;
        this.intervencoesList = this.utils.getListPagination(this.intervencoesListOrig, this.intervencoesSelectedPage, this.intervencoesItemPerPAge);
        break;
      case 'accoes':
        this.accoesSelectedPage = ev;
        this.accoesList = this.utils.getListPagination(this.accoesListOrig, this.accoesSelectedPage, this.accoesItemPerPAge);
        break;
      case 'equipamentos':
        this.equipamentosSelectedPage = ev;
        this.equipamentosList = this.utils.getListPagination(this.equipamentosListOrig, this.equipamentosSelectedPage, this.equipamentosItemPerPAge);
        break;
      case 'bancos':
        this.bancosSelectedPage = ev;
        this.bancosList = this.utils.getListPagination(this.bancosListOrig, this.bancosSelectedPage, this.bancosItemPerPAge);
        break;
      case 'contas':
        this.contasSelectedPage = ev;
        this.contasList = this.utils.getListPagination(this.contasListOrig, this.contasSelectedPage, this.contasItemPerPAge);
        break;
      case 'anexos':
        this.anexosSelectedPage = ev;
        this.anexosList = this.utils.getListPagination(this.anexosListOrig, this.anexosSelectedPage, this.anexosItemPerPAge);
        break;
      case 'assembleias-docs':
        this.assembDocsSelectedPage = ev;
        this.assembDocsList = this.utils.getListPagination(this.assembDocsListOrig, this.assembDocsSelectedPage, this.assembDocsItemPerPAge);
        break;
      case 'assembleias-ordem-trab':
        this.assembRegTrabSelectedPage = ev;
        this.assembRegTrabList = this.utils.getListPagination(this.assembRegTrabListOrig, this.assembRegTrabSelectedPage, this.assembRegTrabItemPerPAge);
        break;
      case 'email-config':
        this.emailConfigSelectedPage = ev;
        this.emailConfigList = this.utils.getListPagination(this.emailConfigListOrig, this.emailConfigSelectedPage, this.emailConfigItemPerPAge);
        break;
      case 'minutas':
        this.minutasSelectedPage = ev;
        this.minutasList = this.utils.getListPagination(this.minutasListOrig, this.minutasSelectedPage, this.minutasItemPerPage);
        break;
    }
  }

  tableSort(key, targetList) {
    switch (targetList) {
      case 'rubricas':
        this.utils.tableSort(this.rubricasListCol, this.rubricasListOrig, key);
        this.pageChange(this.rubricasSelectedPage, targetList);
        break;
      case 'textos':
        this.utils.tableSort(this.textosListCol, this.textosListOrig, key);
        this.pageChange(this.textosSelectedPage, targetList);
        break;
      case 'servicos':
        this.utils.tableSort(this.servicosListCol, this.servicosListOrig, key);
        this.pageChange(this.servicosSelectedPage, targetList);
        break;
      case 'ocorrencias':
        this.utils.tableSort(this.ocorrenciasListCol, this.ocorrenciasListOrig, key);
        this.pageChange(this.ocorrenciasSelectedPage, targetList);
        break;
      case 'intervencoes':
        this.utils.tableSort(this.intervencoesListCol, this.intervencoesListOrig, key);
        this.pageChange(this.intervencoesSelectedPage, targetList);
        break;
      case 'accoes':
        this.utils.tableSort(this.accoesListCol, this.accoesListOrig, key);
        this.pageChange(this.accoesSelectedPage, targetList);
        break;
      case 'equipamentos':
        this.utils.tableSort(this.equipamentosListCol, this.equipamentosListOrig, key);
        this.pageChange(this.equipamentosSelectedPage, targetList);
        break;
      case 'bancos':
        this.utils.tableSort(this.bancosListCol, this.bancosListOrig, key);
        this.pageChange(this.bancosSelectedPage, targetList);
        break;
      case 'contas':
        this.utils.tableSort(this.contasListCol, this.contasListOrig, key);
        this.pageChange(this.contasSelectedPage, targetList);
        break;
      case 'anexos':
        this.utils.tableSort(this.anexosListCol, this.anexosListOrig, key);
        this.pageChange(this.anexosSelectedPage, targetList);
        break;
      case 'assembleias-docs':
        this.utils.tableSort(this.assembDocsListCol, this.assembDocsListOrig, key);
        this.pageChange(this.assembDocsSelectedPage, targetList);
        break;
      case 'assembleias-ordem-trab':
        this.utils.tableSort(this.assembRegTrabListCol, this.assembRegTrabListOrig, key);
        this.pageChange(this.assembRegTrabSelectedPage, targetList);
        break;
        case 'email-config':
          this.utils.tableSort(this.emailConfigListCol, this.emailConfigListOrig, key);
          this.pageChange(this.emailConfigSelectedPage, targetList);
          break;
        case 'minutas':
          this.utils.tableSort(this.minutasListCol, this.minutasListOrig, key);
          this.pageChange(this.minutasSelectedPage, targetList);
          break;
    }
  }

  canBeMultipleEntrada():boolean {
    return this.ordTrabalho.label == null;
  }

  canBeAssuntoDiverso():boolean {
    return this.ordTrabalho.label == null;
  }

  // START - 'ORDEM DE TRABALHO' ASSOCIATION WITH EMAIL AND LETTERS VARIABLES AND METHODS ---------
  emailOpts = [];
  getEmailTemplates() {
    return new Promise((resolve, reject) => {
      this.api.getEmailOpts().subscribe(res => {
        if (res.success) {
          this.emailOpts = res.data.map(el => {
            return { name: el.legenda, value: el.id };
          });
          resolve(true);
        }
      }, err => {
        reject();
      });
    });
  }

  cartaOpts = [];
  getCartaTemplates() {
    return new Promise((resolve, reject) => {
      this.api.getCartaOpts().subscribe(res => {
        if (res.success) {
          this.cartaOpts = res.data.map(el => {
            return { name: el.nome, value: el.id };
          });
          resolve(true);
        }
      }, err => {
        reject();
      });
    });
  }
  // END - 'ORDEM DE TRABALHO' ASSOCIATION WITH EMAIL AND LETTERS VARIABLES AND METHODS -----------
}
