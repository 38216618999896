import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
interface IContext {
  data:string;
}
import { Location, formatDate } from '@angular/common';
import { exportPDF, Group } from '@progress/kendo-drawing';

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';
import { isNull } from 'util';


@Component({
  selector: 'app-creditos-details',
  templateUrl: './creditos-details.component.html',
  styleUrls: ['./creditos-details.component.scss']
})
export class CreditosDetailsComponent implements OnInit {

  transitionController = new TransitionController();
  submittingForm = false;
  descricaoLivreEmpty = true;
  loading = false;
  searchable: boolean = true;
  isCreate: boolean = false;
  creditoDetails = null;
  creditoId = null;
  creditoCod = null;

  // CREDITO FORM
  creditoForm = new FormGroup({
    n_credito: new FormControl(null),
    cod_condominio: new FormControl(null, { validators: Validators.required }),
    cod_fraccao: new FormControl(null, { validators: Validators.required }),
    cod_pagador: new FormControl(null, { validators: Validators.required }),
    credito: new FormControl(null),
    tipo_doc: new FormControl('C'),
    dt_valor: new FormControl(null, { validators: Validators.required }),
    data: new FormControl(null, { validators: Validators.required }),
    descricao: new FormControl(null, { validators: Validators.required }),
    banco: new FormControl(null),
    conta_bancaria: new FormControl(null),
    valor: new FormControl(null, { validators: Validators.required }),
    n_doc: new FormControl(null),
    cod_conta_bancaria: new FormControl(null, { validators: Validators.required }),
    form_pagam: new FormControl(null, { validators: Validators.required }),
  });

  condominioSel = null;

  fraccaoOpts = [];
  condominoOpts = [];
  condominoFracOpts = [];
  processametoOpts = [];
  descricaoOpts = [];

  descricaoLivre = null;
  descricaoSel = null
  fraccaoSel = null;

  contaEnabled = true;
  contaOptsOrig = [];
  contaOpts = [];

  form_pagam = null;

  comp = 'credito';
  initState = null;
  prevState = null;

  @ViewChild('changeCVAlertRef', { static: false }) changeCVAlertRef;
  changeCVModalRef = null;
  changeCVAlertConfig: any = null;

  @ViewChild('pdf', { static: false }) pdfController;

  now = new Date();
  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  @ViewChild('emailAlertRef', { static: false }) emailAlertRef;
  emailModalRef = null;
  emailAlertConfig: any = null;

  constructor(public api: ApiService,
              public userSession: UserSessionService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public router: Router,
              public message: MessageService,
              public modalService: SuiModalService,
              public location: Location,
              public cdRef:ChangeDetectorRef,
              public appState: AppStateService,
              public appConfig: AppConfigService) {
  }

  ngOnInit() {
    this.setSubscribes();
    // HANDLE APPLICATION STATE
    this.initState = this.appState.getInitState(this.comp);
    this.descricaoOpts = this.appConfig.descricaoOpts;

    if (this.initState) {
      this.condominioSel = { name: this.initState.state.condominioSelected.cod + ' - ' + this.initState.state.condominioSelected.nome, value: this.initState.state.condominioSelected };

      this.getFraccaoList();
      this.getContaList();
      this.appState.clearInitState(this.comp);
    }

    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.isCreate = true;
    } else {
      this.creditoId = this.route.snapshot.params.id;
    }

    this.init();
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngAfterViewInit() {
    this.emailAlertConfig = new TemplateModalConfig<IContext, string, string>(this.emailAlertRef);
    this.emailAlertConfig.closeResult = "closed";
    this.emailAlertConfig.size = 'mini';
    this.emailAlertConfig.transition = 'fade up';
    this.emailAlertConfig.transitionDuration = 400;

    this.changeCVAlertConfig = new TemplateModalConfig<IContext, string, string>(this.changeCVAlertRef);
    this.changeCVAlertConfig.closeResult = "closed";
    this.changeCVAlertConfig.size = 'small';
    this.changeCVAlertConfig.transition = 'fade';
    this.changeCVAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() { 
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: null });
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }


  setSubscribes() {
    this.creditoForm.get('dt_valor').valueChanges.subscribe(newValue => {
      this.creditoForm.patchValue({
        data: newValue
      });
    });
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.condominioSel); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el.cod }; }));
                } else {
                  return resolve([]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el.cod }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  init() {
    if (this.isCreate) {

    } else {
      this.getDetails();
    }
  }

  valorOrig = null;
  fetchDone = false;
  semMovimento = false;

  isRemoved = false;
  removedObj = {
    how: null,
    when: null,
    obs: null,
  }
  creditoMovDetails = null;
  creditoIdCV = null;
  isDeleted = false;

  prevCodContaBancaria = null;

  form_pagam_orig = null;
  getDetails() {
    this.api.getCreditoDetails(this.creditoId).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.creditoId = res.data.id;
        this.creditoCod = res.data.cod;
        this.creditoDetails = res.data;
        this.creditoMovDetails = { conta: res.conta, mov: res.movimento };
        this.form_pagam_orig = null;
        if (this.creditoMovDetails.mov) {
          let method = this.utils.getMovimentoMetodo(this.creditoMovDetails.mov.descricao);
          if (method === 'NUMERARIO') {
            this.form_pagam_orig = '1';
          } else if (method === 'TRANSFERENCIA') {
            this.form_pagam_orig = '2';
          }
        }
        this.creditoIdCV = res.hasOwnProperty('movimento') && res.movimento? res.movimento.id_caixa_vertis : null;

        this.isDeleted = (this.creditoDetails.active === '0');

        if (this.creditoDetails.active === '0') {
          this.isRemoved = true;

          this.removedObj.how = this.creditoDetails.alterado_por;
          this.removedObj.when = (this.creditoDetails.alterado_em) ? new Date(this.creditoDetails.alterado_em) : null;
          this.removedObj.obs = this.creditoDetails.del_razao;
        }

        if (this.creditoDetails.pag_anterior === '1') this.semMovimento = true;

        this.creditoDetails.valor = Number(Number(this.creditoDetails.valor).toFixed(2));
        this.valorOrig = this.creditoDetails.valor;
        this.creditoDetails.credito = Number(Number(this.creditoDetails.credito).toFixed(2));

        this.creditoDetails.dt_valor = (this.creditoDetails.dt_valor) ? this.utils.getDate(this.creditoDetails.dt_valor) : null;
        this.creditoDetails.data = (this.creditoDetails.data) ? this.utils.getDate(this.creditoDetails.data) : null;

        if (this.creditoDetails.cod_condominio) this.condominioSel = { name: this.creditoDetails.cod_condominio + ' - ' + this.creditoDetails.nome_condominio, value: this.creditoDetails.cod_condominio };

        this.getFraccaoList();
        this.getContaList();

        this.restoreForm();

        this.handleMoradas();

        this.isCreate = false;

        this.fetchDone = true;
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  handleMoradas() {
    let condMoradaAux = this.creditoDetails.cond_morada;
    this.creditoDetails.pag_morada_fact = this.utils.formatEmptyMoradaObject(this.creditoDetails.pag_morada_fact);
    let pagMoradaAux = (this.creditoDetails.pag_morada_fact && this.creditoDetails.pag_morada_fact !== '' && this.creditoDetails.pag_morada_fact !== '{"address":null,"postalCode":null,"locality":null}') ? this.creditoDetails.pag_morada_fact : this.creditoDetails.pag_morada;
  
    // HANDLE OBJECT MORADA - CONDOMINIO
    try {
      let aux = '';
      let addressObj = JSON.parse(this.creditoDetails.cond_morada);
      Object.keys(addressObj).forEach((key, i) => {
        if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
      });
  
      condMoradaAux = aux;
    } catch(e) {}
  
    // HANDLE OBJECT MORADA - CONDOMINIO
    try {
      let aux = '';
      let addressObj = JSON.parse(pagMoradaAux);
      Object.keys(addressObj).forEach((key, i) => {
        if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
      });
  
      pagMoradaAux = aux;
    } catch(e) {}
  
    this.creditoDetails.cond_morada_pdf = (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [];
    this.creditoDetails.pag_morada_fact_pdf = (pagMoradaAux) ? pagMoradaAux.split(/NEW_LINE/g) : [];
  }

  getFraccaoList() {
    if (!this.condominioSel) return;

    this.resetFormOnCondominioSelected();

    let cod = (!this.condominioSel.hasOwnProperty('value')) ? this.condominioSel : this.condominioSel.value;
    if (cod.hasOwnProperty('cod')) cod = cod.cod;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getCondFraccoesDetails(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.fraccaoOpts = [];
        res.data.forEach(fraccao => {
          this.fraccaoOpts.push({ name: fraccao.cod + ' - ' + fraccao.nome + ' (' + fraccao.condomino_nome + ')', value: fraccao.cod });
        });

        this.condominoOpts = [];
        res.data.forEach(fraccao => {
          this.condominoOpts.push({ name: fraccao.condomino_nome, cod_proprietario: fraccao.cod_proprietario, value: fraccao.proprietarios, cod_fraccao: fraccao.cod })
        });

        if (this.creditoDetails) {
          this.fraccaoSel = this.creditoDetails.cod_fraccao;
          this.fraccaoSelected();
        }
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  resetFormOnCondominioSelected() {
    if (this.isCreate) {
      this.creditoForm.patchValue({
        cod_pagador: null,
        cod_conta_bancaria: null,
        form_pagam: null,
        banco: null,
        conta_bancaria: null,
      });
  
      this.condominoFracOpts = [];
      this.contaOpts = [];
  
      this.fraccaoSel = null;
      this.contaEnabled = true;
    }
  }

  getContaList() {
    if (!this.condominioSel) return;

    let cod = (!this.condominioSel.hasOwnProperty('value')) ? this.condominioSel : this.condominioSel.value;
    if (cod.hasOwnProperty('cod')) cod = cod.cod;
    
    this.api.getCondContasDetails(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.contaOptsOrig = res.data.map(el => {
          return { name: el.banco, value: el.cod, conta_principal: el.conta_principal, saldo: Number(el.saldo), details: el };
        });
        this.contaOpts = JSON.parse(JSON.stringify(this.contaOptsOrig));

        if (this.creditoDetails) {
          if (this.creditoDetails.banco !== null) {
            if (this.creditoDetails.banco === 'CAIXA') {
              this.form_pagam = '1';
            } else {
              this.form_pagam = '2';
            }
          } else if (this.creditoMovDetails) {
            if (this.creditoMovDetails.conta) {
              if (this.creditoMovDetails.conta.banco === 'CAIXA') {
                this.form_pagam = '1';
              } else {
                this.form_pagam = '2';
              }
            }
          }

          this.paymentOptChanged();

          if (!this.prevCodContaBancaria) this.prevCodContaBancaria = this.contaSeleted;
        } else {
          // SELECT AND BLOCK EDITION IF USER LOGGED IN IN CAIXA VERTIS
          if (this.userSession.isCaixaVertisLoggedIn()) {
            this.form_pagam = '1';
            setTimeout(() => { this.paymentOptChanged(); }, 1);
          }
        }
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  fraccaoSelected() {
    this.condominoFracOpts = []
    let fraccao = this.condominoOpts.filter(el => (el.cod_fraccao === this.fraccaoSel));

    if (fraccao.length === 1) {
      try {
        let proprietarios = JSON.parse(fraccao[0].value);
        proprietarios.forEach(el => {
          this.condominoFracOpts.push ({ name: el.nome, value:el.cod });
        });
        if (this.isCreate) {
          this.creditoForm.patchValue({
            cod_pagador: this.condominoFracOpts[0].value,
          });
        }
      } catch (e) {
        this.condominoFracOpts.push({ name: fraccao[0].name, value: fraccao[0].cod_proprietario })
        this.creditoForm.patchValue({
          cod_pagador: this.condominoFracOpts[0].value,
        });
      } 
    }
  }

  restoreForm() {
    this.creditoForm.reset();

    if (this.creditoDetails) {
      this.creditoForm.patchValue({
        n_credito: this.creditoDetails.n_credito,
        cod_condominio: this.creditoDetails.cod_condominio,
        cod_fraccao: this.creditoDetails.cod_fraccao,
        cod_pagador: this.creditoDetails.cod_pagador,
        credito: this.creditoDetails.credito,
        tipo_doc: this.creditoDetails.tipo_doc,
        n_doc: this.creditoDetails.n_doc,
        descricao:  this.creditoDetails.descricao,
        dt_valor: this.creditoDetails.dt_valor,
        data: this.creditoDetails.data,
        banco:this.creditoDetails.banco,
        conta_bancaria: this.creditoDetails.conta_bancaria,
        valor: this.creditoDetails.valor,
        cod_conta_bancaria: this.creditoDetails.cod_conta_bancaria,
      });
      this.condominioSel = { name: this.creditoDetails.cod_condominio + ' - ' + this.creditoDetails.nome_condominio, value: this.creditoDetails.cod_condominio };

      let descricao =  this.creditoDetails.descricao;
      // Se é descrição livre
      if(!this.descricaoOpts.includes(descricao)) {
        this.descricaoLivre = descricao;
        this.descricaoSel = this.descricaoOpts[this.descricaoOpts.length - 1];
      } else {
        this.descricaoSel = descricao;
        this.descricaoLivre = null;
      }
    }
  }

  saving = false;
  prevValor = null;
  formSubmitted(confirm: boolean = false) {
    if (this.saving) return;

    if (!this.canEdit()) {
      this.toastr.error('Não tem permissões para aceder ao recurso/funcionalidade pretendida.', 'Permissão Negada', { timeOut: 4000 });
      return;
    }
    

    this.submittingForm = true;
    setTimeout(() => {this.submittingForm = false;}, 4000);


    if (this.descricaoSel == this.descricaoOpts[this.descricaoOpts.length - 1]) {
      this.descricaoLivreEmpty = (this.descricaoLivre) ? (this.descricaoLivre.trim().length == 0) : true;
      if (this.descricaoLivreEmpty) return;
    }

    if (!this.creditoForm.valid) return;

    if (!confirm && this.inRegistoCV()) {
      this.changeCVModalRef = this.modalService.open(this.changeCVAlertConfig)
      .onApprove(() => {
        this.formSubmitted(true);
      })
      .onDeny(() =>  {
      });
      return;
    }

 



    this.loading = true;

    let data = this.creditoForm.getRawValue();

    let cod = (!this.condominioSel.hasOwnProperty('value')) ? this.condominioSel : this.condominioSel.value;
    if (cod.hasOwnProperty('cod')) cod = cod.cod;

    if (this.creditoId && Number(this.valorOrig) !== Number(data.valor)) {
      let diff = Number(data.valor) - Number(this.valorOrig);
      data.credito = Math.round((Number(data.credito) + diff) * 100) / 100;
    }

    let nomeCondomino = '';
    let aux = this.condominoFracOpts.find(el => (el.value === data.cod_pagador));
    if (aux) nomeCondomino = aux.name;

    this.saving = true;
    if (this.creditoDetails && this.creditoDetails.valor) this.prevValor = Number(this.creditoDetails.valor);
    let descricao = this.descricaoLivre == null ? this.descricaoSel : this.descricaoLivre; 
    this.api.saveCredito(this.creditoId,data.n_credito,cod,data.cod_fraccao,data.cod_pagador,data.credito,data.tipo_doc, data.n_doc, descricao,data.dt_valor, data.data,data.banco,data.conta_bancaria,data.valor, data.cod_conta_bancaria, this.prevValor, nomeCondomino, this.prevCodContaBancaria).subscribe(res => {
      if (res.hasOwnProperty('success') && res['success'] === true) {
        // REGISTO DE ACTIVIDADE
        let condominio = this.condominioSel
        let descricao = 'Condomínio: ' + ((condominio.hasOwnProperty('cod')) ? (condominio.cod + ' - ' + condominio.nome) : (condominio.name));
        if (!this.creditoId) {  // CREATE
          this.createActivityLog(condominio.cod, 'CREDITO', res.data.n_credito, 'Crédito Criado', descricao, res.data.id, res.data.cod) ;
        } else {  // UPDATE
          this.createActivityLog(condominio.cod, 'CREDITO', this.creditoDetails.n_credito, 'Crédito Actualizado', descricao, this.creditoDetails.id, this.creditoDetails.cod);
        }
        this.location.back();

      } else {
        if (res.status === 'PAYMENT_ACCESS_DENIED') {
          this.creditoForm.patchValue({
            valor: this.creditoDetails.valor,
            cod_conta_bancaria: this.creditoDetails.cod_conta_bancaria,
          });

          if (this.creditoDetails.banco !== null) {
            if (this.creditoDetails.banco === 'CAIXA') {
              this.form_pagam = '1';
            } else {
              this.form_pagam = '2';
            }
          } else if (this.creditoMovDetails) {
            if (this.creditoMovDetails.conta) {
              if (this.creditoMovDetails.conta.banco === 'CAIXA') {
                this.form_pagam = '1';
              } else {
                this.form_pagam = '2';
              }
            }
          }

          this.paymentOptChanged();
        }

        this.utils.apiErrorMsg(res);
      }
        this.saving = false;
        this.submittingForm = false;
        this.loading = false;
      }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.submittingForm = false;
      this.loading = false;
      this.saving = false;
    });
  }

  canEdit():boolean {
    if (this.inRegistoCV()) return this.userSession.isSuperAdmin();
    if (this.creditoIdCV) return this.userSession.isAdmin() || this.userSession.isSuperAdmin();
    return true;
  }

  inRegistoCV(): boolean {
    return this.creditoIdCV && this.utils.compareDayDates(this.creditoDetails.data, new Date()) < 0;
  }

  caixaSelectedDate = null;
  dataDisabled = false;
  
  updateInputs() {
    // Está a criar, em numerário e logado em CV
    // ou Já existe, em numerário e (mudou de método e está logado, ou já está em CV)
    this.dataDisabled = this.form_pagam === '1' && (
      (!this.creditoDetails && this.userSession.isCaixaVertisLoggedIn())
      ||
      (this.creditoDetails && ((this.form_pagam_orig !== '1' && this.userSession.isCaixaVertisLoggedIn()) || this.creditoIdCV))
    );
    if (this.dataDisabled) {
      let dt_valor: Date = null;
      let data: Date = null;
      if (!this.creditoDetails || this.form_pagam_orig !== '1') {
        dt_valor = new Date();
        data = new Date();
      } else {
        dt_valor = this.utils.getDate(this.creditoDetails.dt_valor);
        data = this.utils.getDate(this.creditoDetails.data);
      }
      dt_valor.setHours(0,0,0,0);
      data.setHours(0,0,0,0);
      this.creditoForm.patchValue({
        dt_valor: dt_valor,
        data: data,
      });
    }
  }
  
  paymentOptChanged() {
    if (this.form_pagam === null){
      this.updateInputs();
      return;
    }

    if (this.form_pagam === '1' || this.form_pagam === '6') {
      if (this.form_pagam === '1') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      }

      if (this.form_pagam === '6') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name.indexOf('CX ADM') !== -1 || el.name.indexOf('CAIXA ADM') !== -1));
      }
      
      this.contaEnabled = false;

      // GET CREDITO DATE
      let date = null;
      if (this.creditoForm.get('data') && this.creditoForm.get('data').value && this.userSession.isCaixaVertisLoggedIn()) {
        date = this.creditoForm.get('data').value;
        if (!this.caixaSelectedDate) this.caixaSelectedDate = date;
      } else {
        if (this.creditoForm.get('data') && this.creditoForm.get('data').value) {
          date = this.creditoForm.get('data').value;
        } else {
          date = new Date();
        }
      }

      if (this.userSession.isCaixaVertisLoggedIn() && this.caixaSelectedDate) date = this.caixaSelectedDate;

      if (this.contaOpts.length === 1) {
        this.creditoForm.patchValue({
          cod_conta_bancaria: this.contaOpts[0].value,
          banco: this.contaOpts[0].name,
          conta_bancaria: null,
          data: date,
        });
      } else {
        this.creditoForm.patchValue({
          cod_conta_bancaria: null,
          banco: null,
          conta_bancaria: null,
          data: date,
        });
      }
    } else {
      this.contaOpts = this.contaOptsOrig.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1));
      this.contaEnabled = true;

      if (this.creditoDetails) {
        let aux = this.contaOptsOrig.find(el => (el.name === this.creditoDetails.banco));
        
        if (typeof aux === 'undefined') {
          let aux1 = this.contaOpts.find(el => (el.details && this.creditoMovDetails.mov && el.details.cod === this.creditoMovDetails.mov.nid_conta));
          this.creditoForm.patchValue({
            cod_conta_bancaria: (aux1) ? aux1.details.cod : null,
            banco: (aux1) ? aux1.details.banco : null,
            conta_bancaria: null,
          });

        } else {
          this.creditoForm.patchValue({
            cod_conta_bancaria: (aux) ? aux.details.cod : null,
            banco: (aux) ? aux.details.banco : null,
            conta_bancaria: (aux) ? this.creditoDetails.conta_bancaria : null,
          });
        }
      } else {
        let aux = this.contaOpts.find(el => (el.conta_principal === '1' && el.name !== 'CAIXA'));
        this.creditoForm.patchValue({
          cod_conta_bancaria: (aux) ? aux.details.cod : null,
          banco: (aux) ? aux.details.banco : null,
          conta_bancaria: null,
        });
      }
      if (this.contaOpts.length === 1) {
        let aux = this.contaOpts[0];
        this.creditoForm.patchValue({
          cod_conta_bancaria: (aux) ? aux.details.cod : null,
          banco: (aux) ? aux.details.banco : null,
          conta_bancaria: null,
        });
      }
    }
    this.updateInputs();
  }

  contaSeleted = null;
  contaOptChanged() {
    let conta = this.contaOptsOrig.find(el => (el.value === this.contaSeleted));
    this.creditoForm.patchValue({
      banco: (conta) ? conta.details.banco : null,
      conta_bancaria: (conta) ? conta.details.n_conta : null,
    });
  }

  exportPDF() {
    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';
    
    let filename = this.creditoDetails.nome_condominio.replace(/ /g, '_') + '_' + this.creditoDetails.n_recibo + '_' + formatDate(this.now, this.format, this.locale);
    filename = filename.replace(/,/g, '');
    
    this.pdfController.saveAs(filename + '.pdf');
  }

  emailInvalid = false;
  hasEmailAddr() {
    if (!this.utils.validateEmail(this.emailAddr)) {
      this.emailInvalid = true;
      this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
    }

    this.submittingFormEmail = true;
    if (!this.emailAddr || this.emailInvalid) {
      setTimeout(() => { this.submittingFormEmail = false; this.emailInvalid = false; }, 4000);
      return;
    }

    this.emailModalRef.approve();
  }

  submittingFormEmail = false;
  sendingEmail = false;
  emailAddr = null;
  emailName = null;
  sendEmail() {
    if (this.sendingEmail) return;

    this.emailAddr = this.creditoDetails.email;
    this.emailModalRef = this.modalService.open(this.emailAlertConfig)
      .onApprove(() => {
          this.submittingFormEmail = false; 

          this.sendingEmail = true;
          this.pdfController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {        
            let base64  = dataUri.replace('data:application/pdf;base64,', '');
            base64  = base64.replace(' ', '+');
      
            let from = this.appConfig.company.email;;
            let to = this.emailAddr;
            let subjectMsg = 'Envio de comprovativo crédito nº ' + this.creditoDetails.n_credito;
            let bodyMsg = this.getEmailBody();
            let attachment = base64;
            let fromName = 'VERTIS - Gestão Condomínios';
            let toName = (this.emailName) ? this.emailName : null;
            
            let filename = 'credito_' + formatDate(new Date(), this.format, this.locale) + '.pdf';

            let emailsToSend = [{
              from: from,
              to: to,
              subject: subjectMsg,
              body: bodyMsg,
              attachment: attachment,
              filename: filename,
              fromName: fromName,
              toName: toName,
            }];
            this.api.sendEmailV2(emailsToSend).subscribe(res => {
              this.toastr.success('Comprovativo de crédito enviado com sucesso.', 'Email Enviado', { timeOut: 4000 });
      
              this.emailAddr = null;
              this.sendingEmail = false;
            }, err => { this.sendingEmail = false; });

          });
      })
      .onDeny(() => { 
        this.emailAddr = null;
        this.submittingFormEmail = false;
      });
  }

  getEmailBody() {
    let htmlEmail = '';

    // TEXTO INICIAL
    htmlEmail += '<div style="margin-bottom: 35px;">';
    this.appConfig.creditoBody.forEach(line => {
      if (line) {
        htmlEmail += '<span>' + line + '</span>';
      } else {
        htmlEmail += '<span><br><br></span>';
      }
    });
    htmlEmail += '</div>';

    htmlEmail += this.utils.getEmailFooter();

    return this.utils.setEmailTemplate(htmlEmail);
  }

  goToCondominio() {
    if ( !(this.creditoDetails && this.creditoDetails.hasOwnProperty('classificacao')) ) return;

    let classificacao = this.creditoDetails.classificacao;
    let cod = this.creditoDetails.cod_condominio;
    let id = this.creditoDetails.id_condominio;
    let nome = this.creditoDetails.nome_condominio;

    this.router.navigate(['condominios/condominio/geral', id]);

    // Emit signal to breadcrumb component
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${classificacao} - ${cod} - ${nome}` });
  }

  createActivityLog(codCondominio, tipoDoc, nDoc, titulo, descricao, link, cod, obs=null) {
    let obj = { link: link, cod: cod };

    this.api.saveRegistoActividade(codCondominio, tipoDoc, nDoc, titulo, descricao, obj, obs).subscribe(res => {}, err => { });
  }

  troco = null;
  aReceber = null;
  valor = null;
  aReceberChanged() {
    this.troco = (this.aReceber && this.creditoForm.get('valor').value) ? Number(this.aReceber) - Number(this.creditoForm.get('valor').value) : null;

  }

  updateDescricaoSel() {
    this.creditoForm.patchValue({
      descricao : this.descricaoSel
    });
  }
  
  updateDescricaoLivre() {
    this.creditoForm.patchValue({
      descricao : this.descricaoLivre
    });

    if (this.submittingForm) {
        this.descricaoLivreEmpty = (this.descricaoLivre) ? (this.descricaoLivre.trim().length == 0) : true;
    }
  }

  clearDescricao() {
    this.descricaoLivre = null;
    this.descricaoSel = null;
    this.creditoForm.patchValue({
      descricao : null
    });
  }
}
