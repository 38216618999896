import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { fromEvent, forkJoin, Subscriber } from 'rxjs';
import { Location, formatDate } from '@angular/common';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { saveAs } from '@progress/kendo-file-saver';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { AppStateService } from '../app-state.service';
import { UtilitiesService } from '../utilities.service';
import { UserSessionService } from '../user-session.service';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-assembleias',
  templateUrl: './assembleias.component.html',
  styleUrls: ['./assembleias.component.scss']
})
export class AssembleiasComponent implements OnInit {
  
  apiSub = null;
  noMoreData: boolean = false;
  transitionController = new TransitionController();
  loadingModal = false;
  searchable = true;
  clearEntry = { name: '-- limpar selecção --', value: '-1' };
  allCond = { name: '-- Todos os condomínios --', value: '-2' };

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('editMapaReuniaoAlertRef', { static: false }) editMapaReuniaoAlertRef;
  editMapaReuniaoModalRef = null;
  editMapaReuniaoConfig: any = null;

  // ASSEMBLEIAS TABLE VARIABLES
  mapaReunioesListColPDF = [
    { key: 'condominio', name: 'Condomínio', type: 'text', sort: null, searchable: false, center: false, class: 'col-align-left' },
    { key: 'data_reuniao', name: 'Assembleia', type: 'text', sort: null, searchable: false, center: false, class: 'col-centered' },
    { key: 'utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: true, center: false, class: 'col-align-left date-report-col' },
    { key: 'data_convocatoria', name: 'Data Convocatória', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered date-report-col' },
    { key: 'data_limite', name: 'Data Limite', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered date-report-col' },
    { key: 'data_ata', name: 'Elaboração Ata', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered date-report-col' },
    { key: 'entrega_assinatura', name: 'Entrega p/ Assinatura', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered date-report-col' },
    { key: 'rececao', name: 'Receção', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered date-report-col' },
    { key: 'copia_ata', name: 'Cópia Ata', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered date-report-col' },
    { key: 'arquivo_digital', name: 'Arquivo', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered date-report-col' },
  ];
  mapaReuniaoListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: false, class: 'col-1-sticky grey z-index-on-top', filter: '-1' },  // 'ASC', 'DESC'
    { key: 'condominio', name: 'Condomínio', type: 'text', sort: null, searchable: false, center: false, class: 'condominio-col col-align-left col-2-sticky grey z-index-on-top', filter: '-1' },
    { key: 'data_reuniao', name: 'Assembleia', type: 'text', sort: 'DESC', searchable: false, center: false, class: 'col-centered assembleia-col', filter: '-1' },
    { key: 'utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered', filter: '-1' },
    { key: 'reuniao_realizada', name: 'Realizada', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered', filter: '-1' },
    { key: 'data_convocatoria', name: 'Data Convocatória', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered', filter: '-1' },
    { key: 'data_limite', name: 'Data Limite', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered', filter: '0' },
    { key: 'data_ata', name: 'Elaboração Ata', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered', filter: '-1' },
    { key: 'entrega_assinatura', name: 'Entrega p/ Assinatura', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered', filter: '-1' },
    { key: 'rececao', name: 'Receção', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered', filter: '-1' },
    { key: 'copia_ata', name: 'Cópia Ata', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered', filter: '-1' },
  ];
  mapaReuniaoListOrig = [];
  mapaReuniaoList = [];

  // ASSEMBLEIAS TABLE VARIABLES
  assembleiasListColPDF = [
    { key: 'condominio', name: 'Condomínio', type: 'text', sort: null, searchable: false, center: false, class: 'col-align-left' },
    { key: 'utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered col-sm' },
    { key: 'extraordinaria', name: 'Tipo', type: 'text', sort: null, searchable: false, center: false, class: 'col-centered col-md' },
    { key: 'n_ata', name: 'Nº Ata', type: 'number', sort: null, searchable: false, center: false, class: 'col-centered col-s' },
    { key: 'dt', name: '1ª Convocatória', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered col-xl' },
    { key: 'status', name: 'Estado', type: 'text', sort: null, searchable: true, center: false, class: 'col-align-left status-col' },
  ];
  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: false, class: 'table-checkbox-column'},  // 'ASC', 'DESC'
    { key: 'nome_condominio', name: 'Condomínio', type: 'text', sort: null, searchable: false, center: false, class: 'col-align-left' },
    { key: 'utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered col-sm' },
    { key: 'extraordinaria', name: 'Tipo', type: 'text', sort: null, searchable: false, center: false, class: 'col-centered col-md'},
    { key: 'n_ata', name: 'Nº Ata', type: 'number', sort: null, searchable: false, center: false, class: 'col-centered col-s'  },
    { key: 'dt', name: '1ª Convocatória', type: 'text', sort: 'DESC', searchable: true, center: false, class: 'col-centered col-xl'  },
    { key: 'status', name: 'Estado', type: 'text', sort: null, searchable: true, center: false, class: 'col-centered status-col' },
  ];
  list: Array<any> = [];
  toDelete: Array<any> = [];
  listLength: number = null;
  page: number = 1;
  itemPerPage: number = 20;
  sortParam: string = null;
  sortDirection: string = null;
  loadingAssembleias:boolean = false;

  selCondominio = null;
  exercicio = (new Date()).getFullYear().toString();
  exercicioOpts = [{ name: this.exercicio, value: this.exercicio }];
  searching: boolean = false;

  assembleiasList = [];
  assembleiasSelList = [];

  comp = 'assembleias';
  initState = null;
  prevState = null;

  format = 'dd-MM-yyyy HH:mm';
  locale = 'pt-PT';

  utilizadoresOpts = [{ name: 'Todos', value: null }];
  utilizadorSelected = null;

  today = new Date();
  endDate = new Date(this.today.getFullYear(), 11, 31);
  startDate = new Date(this.today.getFullYear(), 0, 1);

  endDateAssembleias = new Date(this.today.getFullYear(), 11, 31);
  startDateAssembleias = new Date(this.today.getFullYear(), 0, 1);

  filterEnabled = false;
  dtElabFilter = false;
  dtEntregaAssFilter = false;
  dtRececaoFilter = false;
  dtCopiaAtaFilter = false;
  dtArquivoFilter = false;

  @ViewChild('tableSearch', { static: false }) tableSearch: ElementRef;
  searchKeyword: string = null;

  angariacao = false;

  assembleiasItensPerLoad = 20;

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public router: Router,
              public userSession: UserSessionService,
              public api: ApiService,
              public location: Location,
              public appState: AppStateService,
              public appConfig: AppConfigService,
              public utils: UtilitiesService,
              public message: MessageService) {
    // GET GLOBAL STATE
    let globalState = this.appState.getGlobalState('global');
    if (globalState && globalState.hasOwnProperty('selCondominio')) {
      this.selCondominio = (globalState.selCondominio) ? { name: globalState.selCondominio.cod + ' - ' + globalState.selCondominio.nome, value: globalState.selCondominio, cod: globalState.selCondominio.cod, nome: globalState.selCondominio.nome } : null;
    }
  }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    setTimeout(() => { if (this.apiSub) this.apiSub.unsubscribe(); }, 1);
  }

  @ViewChild('tableContainer', {static: false}) tableContainer: any;
  scrollToBottom() { setTimeout(() => { this.tableContainer.nativeElement.scrollTop = this.tableContainer.nativeElement.scrollHeight; }, 1); }

  showDeleted = false;
  filterMapaReunioesList(column=null) {
    if (this.searchKeyword) {
      this.mapaReuniaoList = this.mapaReuniaoListOrig.filter(el => ( (el.first_name + ' ' + el.last_name).toLowerCase().indexOf(this.searchKeyword) !== -1 || (el.nome.toLowerCase().indexOf(this.searchKeyword) !== -1) || (el.cod_condominio + ' ' + el.nome).toLowerCase().indexOf(this.searchKeyword) !== -1));
    } else {
      this.mapaReuniaoList = JSON.parse(JSON.stringify(this.mapaReuniaoListOrig));
    }

    if (column) {
      this.mapaReuniaoListCol.forEach(col => {
        if (col.hasOwnProperty('filter') && col.filter !== '-1') {
          if (col.key === 'reuniao_realizada') {
            if (col.filter === '1') {
              this.mapaReuniaoList = this.mapaReuniaoList.filter(el => el.reuniao_realizada);
            }
  
            if (col.filter === '0') {
              this.mapaReuniaoList = this.mapaReuniaoList.filter(el => !el.reuniao_realizada);
            }
          } else if (col.key === 'data_limite') {
            if (col.filter === '1') {
              this.mapaReuniaoList = this.mapaReuniaoList.filter(el => ((el.data_reuniao && el.data_convocatoria && el.data_limite && el.data_ata && el.entrega_assinatura && el.rececao && el.copia_ata)));
            }
  
            if (col.filter === '0') {
              this.mapaReuniaoList = this.mapaReuniaoList.filter(el => (!(el.data_reuniao && el.data_convocatoria && el.data_limite && el.data_ata && el.entrega_assinatura && el.rececao && el.copia_ata)));
            }
          } else {
            if (col.filter === '1') {
              this.mapaReuniaoList = this.mapaReuniaoList.filter(el => (el[col.key] !== null && el[col.key] !== ''));
            }
  
            if (col.filter === '0') {
              this.mapaReuniaoList = this.mapaReuniaoList.filter(el => (el[col.key] === null || el[col.key] === ''));
            }
          }
        }
      });
    }

    // SORT MAPA DE REUNIOES
    let sortCol = this.mapaReuniaoListCol.find(el => (el.hasOwnProperty('sort') && el.sort !== null));
    if (sortCol) {
      this.mapaReuniaoList = this.utils.tableSort(JSON.parse(JSON.stringify(this.mapaReuniaoListCol)), this.mapaReuniaoList, sortCol.key);
    }

    if (this.angariacao) {
      this.mapaReuniaoList = this.mapaReuniaoList.filter(el => (el.estado === '4'));
    }

    // FILTER DELETED ASSEMBLEIAS
    if (!this.showDeleted) this.mapaReuniaoList = this.mapaReuniaoList.filter(el => !el.cancelada);
  }

  saveGlobalState() {
    if (this.selCondominio && this.selCondominio !== '-1' && this.selCondominio && this.selCondominio !== '-2') {
      this.appState.saveGlobalState('global', { 
        selCondominio: { id: this.selCondominio.id, cod: this.selCondominio.cod, nome: this.selCondominio.nome, exercicio: this.selCondominio.exercicio },
        selUtilizador: this.selUtilizador,
      });
    } else {
      this.appState.clearGlobalState();
    }
  }

  ngOnInit() {

    this.setPrevState();

    this.api.getUtilizadores(0, 500).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        res.data.forEach(el => {
          this.utilizadoresOpts.push({ name: el.first_name + ' ' + el.last_name, value: el.id });
          this.utilizadoresOptsAssembleiasList.push({ name: el.first_name + ' ' + el.last_name, value: el.id });
        });
        this.utilizadoresOpts = JSON.parse(JSON.stringify(this.utilizadoresOpts));
        this.utilizadoresOptsAssembleiasList = JSON.parse(JSON.stringify(this.utilizadoresOptsAssembleiasList));
      }
    }, err => { });

    this.animate();

    if (!this.list.length) {
      this.ignored = this.shouldIgnore;
      this.getList();
    }

    this.getAgendamentosList();

    if (this.router.url.indexOf('mapa-reunioes') !== -1) {
      this.setTab('mapa-reunioes');
    } else if (this.router.url.indexOf('agendamentos') !== -1) {
      this.setTab('agendamentos');
    } else if (this.router.url.indexOf('assembleias') !== -1) {
      this.setTab('assembleias');
    } else {
      this.setTab('mapa-reunioes');
    }
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngAfterViewInit() {
    // Condominiums input search
    fromEvent(this.tableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.searchKeyword = val['target']['value'];
      if (this.searchKeyword) this.searchKeyword.toLowerCase().trim();

      this.filterMapaReunioesList();
    });

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.editMapaReuniaoConfig = new TemplateModalConfig<IContext, string, string>(this.editMapaReuniaoAlertRef);
    this.editMapaReuniaoConfig.closeResult = "closed";
    this.editMapaReuniaoConfig.size = 'small';
    this.editMapaReuniaoConfig.transition = 'fade';
    this.editMapaReuniaoConfig.transitionDuration = 250;

    this.cancelAssembAlertConfig = new TemplateModalConfig<IContext, string, string>(this.cancelAssembAlertRef);
    this.cancelAssembAlertConfig.isClosable = false;
    this.cancelAssembAlertConfig.closeResult = "closed";
    this.cancelAssembAlertConfig.size = 'small';
    this.cancelAssembAlertConfig.transition = 'fade';
    this.cancelAssembAlertConfig.transitionDuration = 250;
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selCondominio); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve([ this.clearEntry ].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
                } else {
                  return resolve([this.clearEntry]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
            } else {
              return resolve([this.clearEntry]);
            }
          });
        }
    });
  };

  selTab = null;
  firstClick = true;
  setTab(targetTab) {
    this.selTab = targetTab;

    switch (targetTab) {
      case 'assembleias': this.location.replaceState('/assembleias/assembleias'); break;
      case 'agendamentos': this.location.replaceState('/assembleias/agendamentos'); break;
      case 'mapa-reunioes':
        if (this.firstClick) {
          // this.scrollToBottom();
          this.firstClick = false;
        }

        this.location.replaceState('/assembleias/mapa-reunioes');
        break;
    }
  }

  mapaReuniaoForm = {
    id: null,
    id_assembleia: null,
    data_reuniao: null,
    utilizador_id: null,
    data_convocatoria: null,
    data_limite: null,
    data_ata: null,
    entrega_assinatura: null,
    rececao: null,
    copia_ata: null,
    arquivo_digital: null,
    obs: null,
    reuniao_realizada: null,
  }
  reuniaoSelected(item) {
    this.mapaReuniaoForm = {
      id: item.id,
      id_assembleia: item.id_assembleia,
      data_reuniao: (item.data_reuniao) ? new Date(item.data_reuniao): null,
      utilizador_id: item.utilizador_id,
      data_convocatoria: (item.data_convocatoria) ? new Date(item.data_convocatoria): null,
      data_limite: (item.data_limite) ? new Date(item.data_limite): null,
      data_ata: (item.data_ata) ? new Date(item.data_ata): null,
      entrega_assinatura: (item.entrega_assinatura) ? new Date(item.entrega_assinatura): null,
      rececao: (item.rececao) ? new Date(item.rececao): null,
      copia_ata: (item.copia_ata) ? new Date(item.copia_ata): null,
      arquivo_digital: (item.arquivo_digital) ? new Date(item.arquivo_digital): null,
      obs: item.obs,
      reuniao_realizada: item.reuniao_realizada,
    }

    this.editMapaReuniaoModalRef = this.modalService
      .open(this.editMapaReuniaoConfig)
      .onApprove(() => {
        
      })
      .onDeny(() => {
        this.mapaReuniaoForm = {
          id: null,
          id_assembleia: null,
          data_reuniao: null,
          utilizador_id: null,
          data_convocatoria: null,
          data_limite: null,
          data_ata: null,
          entrega_assinatura: null,
          rececao: null,
          copia_ata: null,
          arquivo_digital: null,
          obs: null,
          reuniao_realizada: null,
        }
      });
  }

  selStatus = '0';

  saveMapaReuniao() {
    this.loadingModal = true;
    this.api.saveMapaReuniao(this.mapaReuniaoForm.id, this.mapaReuniaoForm.data_reuniao, this.mapaReuniaoForm.utilizador_id, this.mapaReuniaoForm.data_convocatoria, this.mapaReuniaoForm.data_limite, this.mapaReuniaoForm.data_ata, this.mapaReuniaoForm.entrega_assinatura, this.mapaReuniaoForm.rececao, this.mapaReuniaoForm.copia_ata, this.mapaReuniaoForm.arquivo_digital, this.mapaReuniaoForm.obs, this.mapaReuniaoForm.id_assembleia, this.mapaReuniaoForm.reuniao_realizada).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.getList();
        this.getMapaReunioesList();

      } else {
        this.utils.apiErrorMsg(res);
      }
      this.editMapaReuniaoModalRef.approve();
      this.loadingModal = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModal = false;
    });
  }

  updateListData() {

  }

  ignored = 0;
  shouldIgnore = 2; 
  getList(init: boolean = true, force=false, page:number=null, itemPerPage:number=null) {
    if(!force && this.ignored < this.shouldIgnore) {
      this.ignored++;
      return;
    }

    let cod = null;
    if (this.selCondominio === '-1') {
      this.selCondominio = null;
    } else {
      if (this.selCondominio) { cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod; }
    }

    let req = []

    if (init) {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      this.counters = {
        total: 0,
        agendamento: 0,
        convocatoria: 0,
        emElaboracao: 0,
        concluida: 0,
        envParaAssinatura: 0,
        ataRececionada: 0,
        envAssinada: 0,
        porRealizar: 0,
        cancelada: 0,
      }
      this.list = [];
      this.page = 1;
      this.noMoreData = false;

      req = [this.api.getAssembleias(cod, this.startDateAssembleias, this.endDateAssembleias, this.page, this.itemPerPage, null, this.sortParam, this.sortDirection, this.selStatus, this.selAssembleiaTipo, this.selUtilizador),
      this.api.getAssembleiasResults(cod, this.startDateAssembleias, this.endDateAssembleias, null, this.selStatus, this.selAssembleiaTipo, this.selUtilizador)]
    } else {
      req = [this.api.getAssembleias(cod, this.startDateAssembleias, this.endDateAssembleias, page? page : this.page, itemPerPage ? itemPerPage : this.itemPerPage, null, this.sortParam, this.sortDirection, this.selStatus, this.selAssembleiaTipo, this.selUtilizador)]
    }

    this.loadingAssembleias = true;
    if (this.apiSub) {
      this.fetchingAssembleias = false;
      this.apiSub.unsubscribe();
    }
    this.apiSub = forkJoin(req).subscribe(res => {
      if (!res.find(el => !el.hasOwnProperty('success') || (el.hasOwnProperty('success') && !el.success))) {

        if (!res[0].data.length) {
          this.noMoreData = true;
        }
        // FILTER ASSEMBLEIA LIST
        res[0].data = res[0].data.map(el => {
          if (el.extraordinaria === '1') {
            el.extraordinaria = 'Extra';
            el['tipo'] = 'EXTRA';
          } else {
            el.extraordinaria = 'Ordinária';
            el['tipo'] = 'ORDINARIA';
          }
          el.dt = this.utils.getDate(el.dt, el.hora);
          el.dt_alter = this.utils.getDate(el.dt_alter, el.hora_alter);

          if (el.localidade && el.cod_postal) {
            el.morada += (', ' + el.cod_postal + ', ' + el.localidade);
          }

          el['status'] = 'AGENDAMENTO';
          if (el.conv_enviada === '1' || el.data_convocatoria != null) el['status'] = 'CONVOCATORIA';
          if (el.hasPresencas == '1' || el.reuniao_realizada === '1') el['status'] = 'EM_ELABORACAO';
          if (el.ata_concluida === '1') el['status'] = 'CONCLUIDA';
          if (el.entrega_assinatura != null) el['status'] = 'ENV_PARA_ASSINATURA';
          if (el.rececao != null) el['status'] = 'RECEPCIONADO';
          if (el.copia_ata != null) el['status'] = 'ENV_ASSINADA';
          if (el.cancelada === '1') el['status'] = 'CANCELADA';

          el['utilizador'] = null;
          if (el.first_name) {
            el['utilizador'] = el.first_name[0];
            if (el.last_name) el['utilizador'] += el.last_name[0];
          }

          return el;
        });

        if (init) {
          this.counters = {
            total: res[1].data.total,
            agendamento: res[1].data.agendamento,
            convocatoria: res[1].data.convocatoria,
            porRealizar: res[1].data.porRealizar,
            emElaboracao: res[1].data.emElaboracao,
            concluida: res[1].data.concluida,
            envParaAssinatura: res[1].data.envParaAssinatura,
            ataRececionada: res[1].data.ataRececionada,
            envAssinada: res[1].data.envAssinada,
            cancelada: res[1].data.cancelada
          }
          this.list = res[0].data.map(it => {
            it['has_ata_ficheiro'] = (it['has_ata_ficheiro'] === '1');
                        
            return it;
          });
        } else {
          let listToAdd:Array<any> = res[0].data.map(it => {
            it['has_ata_ficheiro'] = (it['has_ata_ficheiro'] === '1');
            return it;
          });
          this.list = this.list.map(el => {
            let newData = listToAdd.find(newEl => newEl.id === el.id);
            if (!!newData) el = newData;
            return el;
          });
          this.list = this.list.concat(listToAdd.filter(newEl => !this.list.find(el => newEl.id === el.id)));
        }
        
      } else {
        this.utils.apiErrorMsg(res[0]);
      }
      this.loadingAssembleias = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingAssembleias = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  getAllList() {
    return new Promise((resolve, reject) => {
      if (this.apiSub) {
        this.fetchingAssembleias = false;
        this.apiSub.unsubscribe();
      }

      let cod = null;
      if (this.selCondominio === '-1') {
        this.selCondominio = null;
      } else {
        if (this.selCondominio) { cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod; }
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      this.api.getAssembleias(cod, this.startDateAssembleias, this.endDateAssembleias, null, null, null, this.sortParam, this.sortDirection, this.selStatus, this.selAssembleiaTipo, this.selUtilizador).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
            // FILTER ASSEMBLEIA LIST
            res.data = res.data.map(el => {
              if (el.extraordinaria === '1') {
                el.extraordinaria = 'Extra';
                el['tipo'] = 'EXTRA';
              } else {
                el.extraordinaria = 'Ordinária';
                el['tipo'] = 'ORDINARIA';
              }
              el.dt = this.utils.getDate(el.dt, el.hora);
              el.dt_alter = this.utils.getDate(el.dt_alter, el.hora_alter);

              if (el.localidade && el.cod_postal) {
                el.morada += (', ' + el.cod_postal + ', ' + el.localidade);
              }

              el['status'] = 'AGENDAMENTO';
              if (el.conv_enviada === '1' || el.data_convocatoria != null) el['status'] = 'CONVOCATORIA';
              if (el.hasPresencas == '1' || el.reuniao_realizada === '1') el['status'] = 'EM_ELABORACAO';
              if (el.ata_concluida === '1') el['status'] = 'CONCLUIDA';
              if (el.entrega_assinatura != null) el['status'] = 'ENV_PARA_ASSINATURA';
              if (el.rececao != null) el['status'] = 'RECEPCIONADO';
              if (el.copia_ata != null) el['status'] = 'ENV_ASSINADA';
              if (el.cancelada === '1') el['status'] = 'CANCELADA';

              el['utilizador'] = null;
              if (el.first_name) {
                el['utilizador'] = el.first_name[0];
                if (el.last_name) el['utilizador'] += el.last_name[0];
              }

              return el;
            });

            this.list = res.data.map(it => {
              it['has_ata_ficheiro'] = (it['has_ata_ficheiro'] === '1');
                          
              return it;
            });
            resolve(true);
          } else {
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
            this.utils.apiErrorMsg(res[0]);
            reject(false);
          }
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          reject(err);
        });
      });
  }


  isLate(item) {
    let now = (new Date()).getTime();
    let dataLimite = (new Date(item.data_limite)).getTime();

    if (now > dataLimite && !(item.data_reuniao && item.data_convocatoria && item.data_limite && item.data_ata && item.entrega_assinatura && item.rececao && item.copia_ata)) {
      return true;
    }

    return false;
  }

  listOrig = [];
  selAssembleiaTipo = 'TODOS';

  selUtilizador = 'TODOS';
  utilizadoresOptsAssembleiasList = [{ name: 'Todos', value: 'TODOS' }];
  filterAssembleiasList() {
    switch (this.selStatus) {
      case '0': this.list = JSON.parse(JSON.stringify(this.listOrig.filter(el => (el.status !== 'CANCELADA')))); break;
      case '1': this.list = this.listOrig.filter(el => (el.status === 'AGENDAMENTO')); break;
      case '2': this.list = this.listOrig.filter(el => (el.status === 'CONVOCATORIA')); break;
      case '3': this.list = this.listOrig.filter(el => (el.status === 'EM_ELABORACAO')); break;
      case '4': this.list = this.listOrig.filter(el => (el.status === 'CONCLUIDA')); break;
      case '5': this.list = this.listOrig.filter(el => (el.status === 'ENV_PARA_ASSINATURA')); break;
      case '6': this.list = this.listOrig.filter(el => (el.status === 'ENV_ASSINADA')); break;
      case '7': this.list = this.listOrig.filter(el => (el.has_ata_ficheiro)); break;
      case '8': this.list = this.listOrig.filter(el => (el.status === 'RECEPCIONADO')); break;  // POR ENVIAR ASSINADA
      case '9': this.list = this.listOrig.filter(el => (el.status === 'CONVOCATORIA' || el.status === 'AGENDAMENTO')); break;
      case '10': this.list = this.listOrig.filter(el => (el.status === 'CANCELADA')); break;
    }

    // FILTER BY USER
    let selUtilizador = null;
    if (this.selUtilizador !== 'TODOS') {
      selUtilizador = this.utilizadoresOptsAssembleiasList.find(el => el.value === this.selUtilizador);
      if (selUtilizador) this.list = this.list.filter(el => `${el.first_name} ${el.last_name}` === selUtilizador.name);
    }

    // FILTER BT TYPE
    if (this.selAssembleiaTipo !== 'TODOS') {
      this.list = this.list.filter(el => el.tipo === this.selAssembleiaTipo);
    }

    // COMPUTE ASSEMBLEIAS COUNTERS
    this.counters.total = (Array.isArray(this.listOrig)) ? this.listOrig.length : 0;

    this.counters.agendamento = (Array.isArray(this.listOrig)) ? this.listOrig.filter(el => (el.status === 'AGENDAMENTO')).filter(el => this.selAssembleiaTipo === 'TODOS' || (this.selAssembleiaTipo !== 'TODOS' && el.tipo === this.selAssembleiaTipo)).filter(el => this.selUtilizador === 'TODOS' || (this.selUtilizador !== 'TODOS' && `${el.first_name} ${el.last_name}` === selUtilizador.name)).length : 0;
    this.counters.convocatoria = (Array.isArray(this.listOrig)) ? this.listOrig.filter(el => (el.status === 'CONVOCATORIA')).filter(el => this.selAssembleiaTipo === 'TODOS' || (this.selAssembleiaTipo !== 'TODOS' && el.tipo === this.selAssembleiaTipo)).filter(el => this.selUtilizador === 'TODOS' || (this.selUtilizador !== 'TODOS' && `${el.first_name} ${el.last_name}` === selUtilizador.name)).length : 0;
    this.counters.porRealizar = (Array.isArray(this.listOrig)) ? this.listOrig.filter(el => (el.status === 'CONVOCATORIA' || el.status === 'AGENDAMENTO')).filter(el => this.selAssembleiaTipo === 'TODOS' || (this.selAssembleiaTipo !== 'TODOS' && el.tipo === this.selAssembleiaTipo)).filter(el => this.selUtilizador === 'TODOS' || (this.selUtilizador !== 'TODOS' && `${el.first_name} ${el.last_name}` === selUtilizador.name)).length : 0;
    this.counters.emElaboracao = (Array.isArray(this.listOrig)) ? this.listOrig.filter(el => (el.status === 'EM_ELABORACAO')).filter(el => this.selAssembleiaTipo === 'TODOS' || (this.selAssembleiaTipo !== 'TODOS' && el.tipo === this.selAssembleiaTipo)).filter(el => this.selUtilizador === 'TODOS' || (this.selUtilizador !== 'TODOS' && `${el.first_name} ${el.last_name}` === selUtilizador.name)).length : 0;
    this.counters.concluida = (Array.isArray(this.listOrig)) ? this.listOrig.filter(el => (el.status === 'CONCLUIDA')).filter(el => this.selAssembleiaTipo === 'TODOS' || (this.selAssembleiaTipo !== 'TODOS' && el.tipo === this.selAssembleiaTipo)).filter(el => this.selUtilizador === 'TODOS' || (this.selUtilizador !== 'TODOS' && `${el.first_name} ${el.last_name}` === selUtilizador.name)).length : 0;
    this.counters.envParaAssinatura = (Array.isArray(this.listOrig)) ? this.listOrig.filter(el => (el.status === 'ENV_PARA_ASSINATURA')).filter(el => this.selAssembleiaTipo === 'TODOS' || (this.selAssembleiaTipo !== 'TODOS' && el.tipo === this.selAssembleiaTipo)).filter(el => this.selUtilizador === 'TODOS' || (this.selUtilizador !== 'TODOS' && `${el.first_name} ${el.last_name}` === selUtilizador.name)).length : 0;
    this.counters.envAssinada = (Array.isArray(this.listOrig)) ? this.listOrig.filter(el => (el.status === 'ENV_ASSINADA')).filter(el => this.selAssembleiaTipo === 'TODOS' || (this.selAssembleiaTipo !== 'TODOS' && el.tipo === this.selAssembleiaTipo)).filter(el => this.selUtilizador === 'TODOS' || (this.selUtilizador !== 'TODOS' && `${el.first_name} ${el.last_name}` === selUtilizador.name)).length : 0;
    this.counters.cancelada = (Array.isArray(this.listOrig)) ? this.listOrig.filter(el => (el.status === 'CANCELADA')).filter(el => this.selAssembleiaTipo === 'TODOS' || (this.selAssembleiaTipo !== 'TODOS' && el.tipo === this.selAssembleiaTipo)).filter(el => this.selUtilizador === 'TODOS' || (this.selUtilizador !== 'TODOS' && `${el.first_name} ${el.last_name}` === selUtilizador.name)).length : 0;
  }

  counters = {
    total: 0,
    agendamento: 0,
    convocatoria: 0,
    emElaboracao: 0,
    concluida: 0,
    envParaAssinatura: 0,
    ataRececionada: 0,
    envAssinada: 0,
    porRealizar: 0,
    cancelada: 0,
  }

  add() {
    if (this.selCondominio) {
      this.appState.setInitState('assembleia', {
        condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
      });
    }

    if (this.selTab === 'mapa-reunioes') {
      this.appState.clearInitState('assembleia');
    }

    this.router.navigate(['assembleias/assembleias', 'criar']);

    // BREADCRUMB SIGNAL
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVA ASSEMBLEIA' });
  }

  presentAlert() {
    this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => { this.loadingModal = false; this.toDelete = []; })
      .onDeny(() => { this.loadingModal = false; this.toDelete = []; });
  }

  del() {
    this.loadingModal = true;

    switch (this.selTab) {
      case 'agendamentos':
        this.api.delAssembleiaAgendamentos(this.toDelete).subscribe(res => {
          if (res.success) {
            // this.agendamentos = this.agendamentos.filter(el => !el.checked);

            // let req = [];
            // this.toDelete.forEach(el => {
            //   let titulo = null;
            //   let descricao = `Condomínio: ${el.cod_condominio} - ${el.nome_condominio}, Data convocatória:  ${this.utils.getFormatedDate(el.dt)} ${el.hora}, Tipo: ${el.extraordinaria}.`;
            //     // REGISTO ACTIVIDADES - DELETE REGISTO PRESENCAS
            //     titulo = 'Assembleia Removida';
            //     req.push(this.api.saveRegistoActividade(el.cod_condominio, null, null, titulo, descricao));
            // });
            // forkJoin(req).subscribe(res => {}, err => { });

            this.getAgendamentosList();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });

        break;
      case 'assembleias':

        this.api.delAssembleias(this.toDelete).subscribe(res => {
          if (res.success) {
            this.list = this.list.filter(el => !el.checked);

            let req = [];
            this.toDelete.forEach(el => {
              let titulo = null;
              let descricao = `Condomínio: ${el.cod_condominio} - ${el.nome_condominio}, Data convocatória:  ${this.utils.getFormatedDate(el.dt)} ${el.hora}, Tipo: ${el.extraordinaria}.`;
                // REGISTO ACTIVIDADES - DELETE REGISTO PRESENCAS
                titulo = 'Assembleia Removida';
                req.push(this.api.saveRegistoActividade(el.cod_condominio, null, null, titulo, descricao));
            });
            forkJoin(req).subscribe(res => {}, err => { });

            this.getList();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });

        break;
      case 'mapa-reunioes':

        this.api.delMapaReuniao(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.mapaReuniaoList.filter(el => el.checked).forEach(el => {
              this.mapaReuniaoListOrig = this.mapaReuniaoListOrig.filter(it => (it.id !== el.id));
            });
            this.mapaReuniaoList = JSON.parse(JSON.stringify(this.mapaReuniaoListOrig));

            let req = [];
            this.toDelete.forEach(el => {
              let titulo = null;
              let descricao = `Condomínio: ${el.cod_condominio} - ${el.nome}, Data convocatória:  ${formatDate(new Date(el.data_reuniao), this.format, this.locale)}, Tipo: ${el.extraordinaria}.`;
                // REGISTO ACTIVIDADES - DELETE REGISTO PRESENCAS
                titulo = 'Assembleia Removida';
                req.push(this.api.saveRegistoActividade(el.cod_condominio, null, null, titulo, descricao));
            });
            forkJoin(req).subscribe(res => {}, err => { });

            this.getMapaReunioesList();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });

        break;
    }
  }

  pageChange(ev) {
    this.page = ev;
    //this.getList();
  }

  rowSelectionToggle(ev, target) {
    switch (target) {
      case 'assembleias': (ev.target.checked) ? this.list.map(el => el.checked = true ) : this.list.map(el => el.checked = false ); break;
      case 'mapa-reunioes': (ev.target.checked) ? this.mapaReuniaoList.map(el => el.checked = true ) : this.mapaReuniaoList.map(el => el.checked = false ); break;
      case 'agendamentos': (ev.target.checked) ? this.agendamentos.map(el => el.checked = true ) : this.agendamentos.map(el => el.checked = false ); break;
    }
  }

  newAgendamento() {
    this.router.navigate(['assembleias/agendamento-assembleias', 'criar']);

    // BREADCRUMB SIGNAL
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVO AGENDAMENTO' });
  }

  async tableAction(action, target=null) {
    if (target === 'assembleias-agendamento') {
      switch (action) {
        case 'add': this.newAgendamento(); break;
        case 'delete':
          this.toDelete = this.agendamentos.filter(el => el.checked);
          if (this.toDelete.length > 0) {
            this.presentAlert();
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }

    if (target === 'assembleias') {
      switch (action) {
        case 'csv': this.exportExcel(); break;
        case 'print': this.exportPDF(); break;
        case 'print': break;
        case 'add': this.add(); break;
        case 'delete':
          this.toDelete = this.list.filter(el => el.checked);
          if (this.toDelete.length > 0) {
            this.presentAlert();
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }

    if (target === 'mapa-reuniao') {
      switch (action) {
        case 'csv': this.exportExcel(); break;
        case 'print': this.exportPDF(); break;
        case 'print': break;
        case 'add': break;
        case 'delete':
          this.toDelete = this.mapaReuniaoList.filter(el => el.checked);
          if (this.toDelete.length > 0) {
            this.presentAlert();
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }
  }


  pdfReport = {
    title: null,
    reportType: null,
    startDate: null,
    endDate: null,
    now: new Date(),
  }
  pdfListCol = [];
  pdfList = [];
  @ViewChild('pdf', { static: false }) pdfController;

  exportPDF() {
    
    // CHECK IF THERE EXISTE DATA TO PRESENT
    if ((this.selTab === 'assembleias' && this.list.length === 0) || (this.selTab === 'mapa-reunioes' && this.mapaReuniaoList.length === 0)) {
      this.toastr.error('Nenhum resultado disponível para exportação.', 'Alerta', { timeOut: 4000 });
      return;
    }
    switch (this.selTab) {
      case 'assembleias':
          this.getAllList().then(_ => {
            this.pdfReport['title'] = 'Lista de Assembleias';
            this.pdfReport['startDate'] = this.startDate;
            this.pdfReport['endDate'] = this.endDate;
            this.pdfList = [].concat(this.list);
            setTimeout(() => {
              this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
              this.pdfController.forceProxy = true;
              this.pdfController.proxyTarget = '_blank';
          
              let filename = this.pdfReport.title.replace(/ /g, '_') + '_'
                + ((this.pdfReport.startDate) ? formatDate(this.pdfReport.startDate, this.format, this.locale) : '') + '_'
                + formatDate(this.pdfReport.endDate, this.format, this.locale) + '_'
                + this.pdfReport['title'].replace(/ /g, '_');
              filename = filename.replace(/,/g, '');
              
              setTimeout(() => { this.pdfController.saveAs(filename + '.pdf'); }, 1);
            }, 50);
          });
          break;
        case 'mapa-reunioes':
          this.pdfReport['title'] = 'Mapa de Reuniões';
          this.pdfReport['startDate'] = this.startDate;
          this.pdfReport['endDate'] = this.endDate;
  
          this.pdfList = [].concat(this.mapaReuniaoList);
          setTimeout(() => {
            this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
            this.pdfController.forceProxy = true;
            this.pdfController.proxyTarget = '_blank';
        
            let filename = this.pdfReport.title.replace(/ /g, '_') + '_'
              + ((this.pdfReport.startDate) ? formatDate(this.pdfReport.startDate, this.format, this.locale) : '') + '_'
              + formatDate(this.pdfReport.endDate, this.format, this.locale) + '_'
              + this.pdfReport['title'].replace(/ /g, '_');
            filename = filename.replace(/,/g, '');
            
            setTimeout(() => { this.pdfController.saveAs(filename + '.pdf'); }, 1);
          }, 50);
          break;
      }
  
  }

  exportExcel() {
    // CHECK IF THERE EXISTE DATA TO PRESENT
    if ((this.selTab === 'assembleias' && this.list.length === 0) || (this.selTab === 'mapa-reunioes' && this.mapaReuniaoList.length === 0)) {
      this.toastr.error('Nenhum resultado disponível para exportação.', 'Alerta', { timeOut: 4000 });
      return;
    }

    let filename = '';
    let excelData = [];
    let worksheet: XLSX.WorkSheet = null;
    let aux = null;

    let workbook: XLSX.WorkBook = null;
    let excelBuffer: any = null;
    let data: Blob = null;

    switch (this.selTab) {
      case 'assembleias':
        this.getAllList().then(_ => {
          this.pdfReport['title'] = 'Lista de Assembleias';
          this.pdfReport['startDate'] = this.startDate;
          this.pdfReport['endDate'] = this.endDate;
        
          filename = this.pdfReport.title.replace(/ /g, '_') + '_'
            + ((this.pdfReport.startDate) ? formatDate(this.pdfReport.startDate, this.format, this.locale) : '') + '_'
            + formatDate(this.pdfReport.endDate, this.format, this.locale) + '_'
            + this.pdfReport['title'].replace(/ /g, '_');
          filename = filename.replace(/,/g, '');

          this.pdfList = [].concat(this.list);

          // ADD HEADER
          excelData = [{
            nome_condominio: 'Condomínio',
            utilizador: 'Utilizador',
            extraordinaria: 'Tipo',
            n_ata: 'Nº de Ata',
            dt: '1ª Convocatória',
            dt_alter: '2ª Convocatória',
            estado: 'Estado',
            motivo_cancelamento: 'Motivo Cancelamento',
          }];

          this.pdfList.forEach(el => {
            let auxValue = null;
            switch (el.status) {
              case 'AGENDAMENTO': auxValue = '1'; break;
              case 'CONVOCATORIA': auxValue = '2'; break;
              case 'EM_ELABORACAO': auxValue = '3'; break;
              case 'CONCLUIDA': auxValue = '4'; break;
              case 'ENV_PARA_ASSINATURA': auxValue = '5'; break;
              case 'ENV_ASSINADA': auxValue = '6'; break;
              case 'RECEPCIONADO': auxValue = '8'; break;
              case 'CANCELADA': auxValue = '10'; break;
            }
            let status = this.appConfig.statusOpts.find(it => it.value === auxValue);

            aux = {
              nome_condominio: el.nome_condominio,
              utilizador: el.first_name + ((el.last_name) ? ' ' + el.last_name : null),
              extraordinaria: el.extraordinaria,
              n_ata: el.n_ata,
              dt: this.utils.getFormatedDate(el.dt, 'dd-MM-yyyy HH:mm'),
              dt_alter: this.utils.getFormatedDate(el.dt_alter, 'dd-MM-yyyy HH:mm'),
              estado: (status) ? status.name : null,
              motivo_cancelamento: el.motivo_cancelamento,
            }
            excelData.push(aux);
          });

          worksheet = XLSX.utils.json_to_sheet(excelData, { header:['nome_condominio', 'utilizador', 'extraordinaria', 'n_ata', 'dt', 'dt_alter', 'estado', 'motivo_cancelamento'], skipHeader: true });

          workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          worksheet['!cols'] = this.utils.fitToColumn(excelData);


          excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          data = new Blob([excelBuffer], {type: EXCEL_TYPE});

          FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
        });

        break;
      case 'mapa-reunioes':
        this.pdfReport['title'] = 'Mapa de Reuniões';
        this.pdfReport['startDate'] = this.startDate;
        this.pdfReport['endDate'] = this.endDate;

        filename = this.pdfReport.title.replace(/ /g, '_') + '_'
          + ((this.pdfReport.startDate) ? formatDate(this.pdfReport.startDate, this.format, this.locale) : '') + '_'
          + formatDate(this.pdfReport.endDate, this.format, this.locale) + '_'
          + this.pdfReport['title'].replace(/ /g, '_');
        filename = filename.replace(/,/g, '');

        this.pdfList = [].concat(this.mapaReuniaoList);

        // ADD HEADER
        excelData = [{ nome_condominio: 'Condomínio', data_reuniao: 'Assembleia', utilizador: 'Utilizador', data_convocatoria: 'Data Convocatória', data_limite: 'Data Limite', data_ata: 'Elaboração Ata', entrega_assinatura: 'Entrega p/ Assinatura', rececao: 'Receção', copia_ata: 'Cópia Ata', arquivo_digital: 'Arquivo' }];

        this.pdfList.forEach(el => {
          aux = {
            nome_condominio: el.cod_condominio + ' - ' + el.nome,
            data_reuniao: this.utils.getFormatedDate(el.data_reuniao, 'EEEE, dd-MM-yyyy HH:mm'),
            utilizador: el.utilizador,
            data_convocatoria: this.utils.getFormatedDate(el.data_convocatoria),
            data_limite: this.utils.getFormatedDate(el.data_limite),
            data_ata: this.utils.getFormatedDate(el.data_ata),
            entrega_assinatura: this.utils.getFormatedDate(el.entrega_assinatura),
            rececao: this.utils.getFormatedDate(el.rececao),
            copia_ata: this.utils.getFormatedDate(el.copia_ata),
            arquivo_digital: this.utils.getFormatedDate(el.arquivo_digital),
          }
          excelData.push(aux);
        });

        worksheet = XLSX.utils.json_to_sheet(excelData, { header:['nome_condominio', 'data_reuniao', 'utilizador', 'data_convocatoria', 'data_limite', 'data_ata', 'entrega_assinatura', 'rececao', 'copia_ata', 'arquivo_digital'], skipHeader: true });

        workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        data = new Blob([excelBuffer], {type: EXCEL_TYPE});

        FileSaver.saveAs(data, filename + EXCEL_EXTENSION);

        break;
    }
  }

  selectAssembleiaToDelete(row) {
    if (row.checked && !this.userSession.isSuperAdmin() && row['status'] !== 'AGENDAMENTO') {
      setTimeout(() => {
        row.checked = false;
      }, 1);
      this.toastr.error('Assembleia com convocatória enviada. Não tem permissões para eliminar a assembleia selecionada.', 'Alerta', { timeOut: 4000 });
    }
  }

  fetching = false;
  firstCall = true;
  getMapaReunioesList() {
    if (this.fetching) return;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.mapaReuniaoListOrig = [];
    this.fetching = true;
    this.api.getMapaReuniao(null, this.startDate, this.endDate).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        res.data.forEach(el => {
          if (el.first_name) {
            el['utilizador'] = el.first_name[0];
            if (el.last_name) el['utilizador'] += el.last_name[0];
          }

          el['condominio'] = el.cod_condominio + ' - ' + el.nome;
          el['reuniao_realizada'] = (el.reuniao_realizada === '1');
          el['cancelada'] = (el.cancelada === '1');

          this.mapaReuniaoListOrig.push(el);
        });
        this.mapaReuniaoList = JSON.parse(JSON.stringify(this.mapaReuniaoListOrig));

        this.filterMapaReunioesList();

        if (this.firstCall) {
          this.tableSort('dt', 'mapa-reunioes');
          this.firstCall = false;
        }

      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetching = false;
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetching = false;
    });
  }

  tableSort(key, target=null) {
    if ((target && target === 'assembleias') || (this.selTab === 'assembleias' && target === null)) {
      this.listCol.forEach(el => {
        if (el.key === key) {
  
          if (el.sort === 'DESC') {
            el.sort = 'ASC';
          } else if (el.sort === 'ASC') {
            el.sort = 'DESC';
          } else if (el.sort === null) {
            el.sort = 'ASC';
          }
  
          this.sortParam = key;
          this.sortDirection = el.sort;
        } else {
          el.sort = null;
        }
      });
  
      this.getList();
    } 

    if ((target && target === 'mapa-reunioes') || (this.selTab === 'mapa-reunioes' && target === null)) {

      this.mapaReuniaoListCol.forEach(el => {
        if (el.key === key) {
  
          if (el.sort === 'DESC') {
            el.sort = 'ASC';
          } else if (el.sort === 'ASC') {
            el.sort = 'DESC';
          } else if (el.sort === null) {
            el.sort = 'ASC';
          }
  
          this.sortParam = key;
          this.sortDirection = el.sort;
        } else {
          el.sort = null;
        }
      });

      this.mapaReuniaoList = this.utils.tableSort(JSON.parse(JSON.stringify(this.mapaReuniaoListCol)), this.mapaReuniaoList, key);
    }

  }

  setPrevState() {
    this.prevState = this.appState.getPrevState(this.comp);
    if (this.prevState) {

      if (!this.selCondominio || (this.prevState.state.selCondominio && this.prevState.state.selCondominio.value.cod === this.selCondominio.cod)) {
        this.startDateAssembleias = (this.prevState.state.startDateAssembleias) ? this.prevState.state.startDateAssembleias : null;
        this.endDateAssembleias = (this.prevState.state.endDateAssembleias) ? this.prevState.state.endDateAssembleias : null;
  
        this.selCondominio = (this.prevState.state.selCondominio) ? this.prevState.state.selCondominio : null;
        this.selUtilizador = (this.prevState.state.selUtilizador) ? this.prevState.state.selUtilizador : 'TODOS';
  
        this.selStatus = (this.prevState.state.selStatus) ? this.prevState.state.selStatus : null;
        this.page = (this.prevState.state.page) ? this.prevState.state.page : 1;
  
        this.searchKeyword = (this.prevState.state.searchKeyword) ? this.prevState.state.searchKeyword : null;
        this.angariacao = (this.prevState.state.angariacao) ? this.prevState.state.angariacao : null;
        
        this.list = (this.prevState.state.list) ? this.prevState.state.list : null;
        this.counters = (this.prevState.state.counters) ? this.prevState.state.counters : null;
  
        if (this.prevState.state.scrollPosition != null) {
          setTimeout(() => {
            let scrollableDiv = document.getElementById('list-scrollable');
            if (scrollableDiv) {
              scrollableDiv.scrollTop = this.prevState.state.scrollPosition;
            }
            this.getList(false, true, 1, this.list.length);
          });
        }
      }

      this.appState.clearPrevState(this.comp);
    }
  }

  setState() {
    let scrollableDiv = document.getElementById('list-scrollable');
    let selCondominio = this.selCondominio? 
                          (this.selCondominio.hasOwnProperty('value')? 
                            { name: this.selCondominio.value.cod + ' - ' + this.selCondominio.value.nome, value: this.selCondominio.value } 
                            : 
                            { name: this.selCondominio.cod + ' - ' + this.selCondominio.nome, value: this.selCondominio })
                          : null;
    this.appState.setPrevState(this.comp, {
      startDateAssembleias: this.startDateAssembleias,
      endDateAssembleias: this.endDateAssembleias,

      selCondominio: selCondominio,
      selUtilizador: this.selUtilizador,

      selStatus: this.selStatus,
      page: this.page,
      searchKeyword: this.searchKeyword,
      angariacao: this.angariacao,

      list: this.list,
      counters: this.counters,

      scrollPosition: scrollableDiv? scrollableDiv.scrollTop : 0
    });
  }

  goToDetails(item, formWhere=null) {
    // SAVE THIS STATE
    this.setState();

    if (formWhere === 'mapa-reunioes') {
      this.editMapaReuniaoModalRef.deny();

      this.router.navigate(['assembleias/assembleias', item.id_assembleia]);

      // BREADCRUMB SIGNAL
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: item.id_assembleia });
    } else {
    this.router.navigate(['assembleias/assembleias', item.id]);

    // BREADCRUMB SIGNAL
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: item.nome_condominio });
    }
  }

  downloadAtaAssinada(row) {
    if (!row.has_ata_ficheiro) return;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getAtaAssinada(row.id).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (res.data.ata_ficheiro) {
          this.utils.downloadFile('data:application/pdf;base64,' + res.data.ata_ficheiro, 'Ata Assinada.pdf');
          // saveAs('data:application/pdf;base64,' + res.data.ata_ficheiro, 'Ata Assinada.pdf',{
          //   proxyURL: this.appConfig.fileProxyUrl,
          //   forceProxy: true,
          //   proxyTarget: '_blank',
          // })
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          this.fetching = false;
        } else if (res.data.id_ata_ficheiro) {
          this.api.getFile(res.data.id_ata_ficheiro).subscribe(res => {
            if (res && res.success) {
              this.utils.downloadFile('data:application/pdf;base64,' + res.data.file, res.data.filename);
            } else {
              this.utils.apiErrorMsg(res);
            }
            this.fetching = false;
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          }, err=> {
            this.fetching = false;
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          });
        } else {
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          this.fetching = false;
        }
      }

    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetching = false;
    });
  }

  dataReuniaoChanged() {
    let today = new Date(this.mapaReuniaoForm.data_reuniao);
    today.setDate(today.getDate() + 30);
    this.mapaReuniaoForm.data_limite = new Date(today);
  }


  // AGENDAMENTOS METHODS AND VARIABLES
  agendamentos = [];
  agendamentosOrig = [];
  agendamentosCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, center: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'id', name: 'Nº', type: 'text', sort: null, searchable: false, center: false, class: 'n-col' },
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: false, center: false, class: '' },
    { key: 'n_assembleias', name: 'Nº Assembleias', type: 'text', sort: null, searchable: false, center: false, class: 'data-col' },
    { key: 'start_date', name: 'Primeira Data', type: 'text', sort: null, searchable: true, center: false, class: 'data-col' },
    { key: 'end_date', name: 'Última Data', type: 'text', sort: null, searchable: true, center: false, class: 'data-col' },
    { key: 'estado', name: 'Estado', type: 'text', sort: null, searchable: true, center: false, class: 'col-align-left status-col' },
  ];

  endDateAgendamento = new Date(this.today.getFullYear(), 11, 31);
  startDateAgendamento = new Date(this.today.getFullYear(), 0, 1);
  fetchingAssembleias = false;
  getAgendamentosList() {
    if (this.fetchingAssembleias) return;

    this.fetchingAssembleias = true;
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.apiSub = this.api.getAssembleiasAgendamentos(this.startDateAgendamento, this.endDateAgendamento).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        // FILTER ASSEMBLEIA LIST
        this.agendamentosOrig = res.data.map(el => {
          el.start_date = (el.start_date) ? new Date(el.start_date) : null;
          el.end_date = (el.end_date) ? new Date(el.end_date) : null;
          el['checked'] = false;

          return el;
        });
        this.agendamentos = JSON.parse(JSON.stringify(this.agendamentosOrig));
        this.filterAgendamento();

        this.fetchingAssembleias = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      } else {
        this.fetchingAssembleias = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.fetchingAssembleias = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  goToAgendamento(item) {
    this.router.navigate(['assembleias/agendamento-assembleias', item.id]);

    // BREADCRUMB SIGNAL
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'Nº ' + item.id });
  }

  selAgendamentoStatus = '1';
  filterAgendamento() {
    switch (this.selAgendamentoStatus) {
      case '0': this.agendamentos = JSON.parse(JSON.stringify(this.agendamentosOrig)); break;
      case '1': this.agendamentos = this.agendamentosOrig.filter(el => (el.estado === '0')); break;
      case '2': this.agendamentos = this.agendamentosOrig.filter(el => (el.estado === '1')); break;
    }
  }


  realizarAssembleias() {
    // console.log('REALIZAR');
  }

  @ViewChild('cancelAssembAlertRef', { static: false }) cancelAssembAlertRef;
  cancelAssembModalRef = null;
  cancelAssembAlertConfig: any = null;
  motivoCancelAssemb = null;
  selectedAssembleias = [];

  cancelarAssembleias() {
    this.selectedAssembleias = this.list.filter(el => el.checked);
    if (this.selectedAssembleias.length > 0) {
      if (!this.userSession.isAdmin()) {
        this.toastr.error('Não tem permissões para cancelar as assembleias selecionadas.', 'Alerta', { timeOut: 4000 });
        return;
      }

      this.loadingModal = false;

      this.cancelAssembModalRef = this.modalService
        .open(this.cancelAssembAlertConfig)
        .onApprove(() => {
          this.motivoCancelAssemb = null;
          this.loadingModal = false;
          this.selectedAssembleias = [];
        })
        .onDeny(() => {
          this.motivoCancelAssemb = null;
          this.loadingModal = false;
          this.selectedAssembleias = [];
        });

    } else {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
    }
  }

  cancelAssembleias() {
    let req = [];
    this.selectedAssembleias.forEach(el => {
      req.push(this.api.cancelarAssembleia(el.id, true));
    });

    this.loadingModal = true;
    forkJoin(req).subscribe(async res => {
      for (let i = 0; i < this.selectedAssembleias.length; i++) {
        if (res[i].success) {
          this.selectedAssembleias[i].cancelada = '1';
          this.selectedAssembleias[i].status = 'CANCELADA';

          await this.saveRegistoActividade(this.selectedAssembleias[i].id, this.selectedAssembleias[i].cod_condominio);        
        }
        this.selectedAssembleias[i].checked = false;
      }
      this.selectedAssembleias.forEach((el, i) => {
      });
      this.list = [...this.list];
      this.loadingModal = false;
      this.cancelAssembModalRef.approve();
    }, err => {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
      this.loadingModal = false;
    });
  }

  saveRegistoActividade(id, cod_condominio) {
    return new Promise(resolve => {
      // SAVE REGISTO DE ACTIVIDADES
      this.api.saveRegistoComunicacaoAssembleias('ASSEMBLEIAS', id, cod_condominio, 'Assembleia Cancelada', JSON.stringify([]),this.userSession.getUserId(), new Date(), this.motivoCancelAssemb).subscribe(res => {
        resolve(true);
      }, err => { resolve(false); });
    });
  }

  onScroll() {
    if (this.noMoreData || !this.apiSub.closed) return;
    this.page += 1;
    this.getList(false);
  }
}
