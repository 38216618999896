import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { ReciboDetailed } from '../business-model-interfaces/recibo';
import { ReconciliacaoBancariaEnvioRecibos } from '../business-model-interfaces/reconciliacoes-bancarias';
import { UtilitiesService } from '../utilities.service';
@Injectable({
  providedIn: 'root'
})
export class ReconciliacoesBancariasService {

  constructor(
    public api: ApiService,
    public utils: UtilitiesService,
    public toastr: ToastrService,
    public appConfig: AppConfigService,
  ) { }


  
  getRecibosPorEnviar(id_reconciliacao): Promise<Array<ReciboDetailed & ReconciliacaoBancariaEnvioRecibos>> {
    return new Promise((resolve, reject) => {
      this.api.getRecibosPorEnviar(id_reconciliacao).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject([]);
      });
    })
  }

  getRecibosPorEnviarCartaDetails(id_reconciliacao): Promise<Array<ReciboDetailed & ReconciliacaoBancariaEnvioRecibos>> {
    return new Promise((resolve, reject) => {
      this.api.getRecibosPorEnviarCartaDetails(id_reconciliacao).subscribe(res => {
        if (res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject([]);
      });
    })
  }

  communicateReconciliacao(id_reconciliacao): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.communicateAvisosECobrancasReconciliacao(id_reconciliacao).subscribe(res => {
        if (res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }
}
