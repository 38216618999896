import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { ConfigAvisosCobrancaHypothesis, ConfigAvisosCobrancaHypothesisDetails, savehypothesisBodyAPI } from './configurations';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {

  constructor(
    public api: ApiService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public toastr: ToastrService
    ) { }


  getAvisosCobrancaHypotheses(): Promise<Array<ConfigAvisosCobrancaHypothesis>> {
    return new Promise((resolve) => {
      this.api.getAvisosCobrancaHypotheses().subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve([]);
      });
    });
  }

  getConfigAvisosCobrancaDetails(id_hypo): Promise<ConfigAvisosCobrancaHypothesisDetails> {
    return new Promise((resolve) => {
      this.api.getConfigAvisosCobrancaHypothesisDetails(id_hypo).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  saveAvisosCobrancaHypothesis(body:savehypothesisBodyAPI): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.saveAvisosCobrancaHypothesisDetails(body).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  deleteAvisosCobrancaHypotheses(list:Array<string>): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.deleteAvisosCobrancaHypotheses(list).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }
}
