import { Component, Input, Output, OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sui-select-holder',
  templateUrl: './sui-select-holder.component.html',
  styleUrls: ['./sui-select-holder.component.scss']
})
export class SuiSelectHolderComponent implements OnInit {

  @Input('borderError') borderError: boolean;
  @Input('selValue') selValue = null;
  @Output() selValueChange = new EventEmitter<any>();
  @Input('disabled') disabled: boolean = false;
  @Input('selOptions') selOptions: Array<any> = [];
  @Input('placeholder') placeholder:string= " ";
  @Input('class') class:string= "";
  @Input('propag') propag:boolean= true;
  

  constructor(public cdRef:ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewChecked() { 
    this.cdRef.detectChanges(); 
  }

  selValueChanged() {
    this.selValueChange.emit(this.selValue);
  }

}
