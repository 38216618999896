import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { AppStateService } from '../app-state.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { FraccoesNavState, NavigationState } from '../navigation';
import { FraccoesService } from '../business-logic-services/fraccoes.service';
import { CondominosService } from '../business-logic-services/condominos.service';
import { CondominoFraccao, EmailOficial, estadoContencioso } from '../condominos';
import { ContenciosoModalComponent } from '../contencioso-modal/contencioso-modal.component';
import { RegistoComunicacoesComponent } from '../registo-comunicacoes/registo-comunicacoes.component';
import { ComunicarComponent, LetterToSend } from '../comunicar/comunicar.component';
import { CartaOptions } from '../carta-comunicacao/carta-comunicacao.component';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';

interface CondominoFraccaoList extends CondominoFraccao {
  checked: boolean,
}
interface EstadoContenciosoList extends estadoContencioso {
  checked: boolean,
}


@Component({
  selector: 'app-entity-details',
  templateUrl: './entity-details.component.html',
  styleUrls: ['./entity-details.component.scss']
})
export class EntityDetailsComponent implements OnInit {

  transitionController = new TransitionController();
  submittingForm = false;
  loading = false;
  searchable: boolean = true;
  selectedEntity = null;
  isCreate: boolean = false;
  entityType = null;
  entityDetails = null;
  entityEmailsOficiais: EmailOficial[] = [];
  entityId = null;
  entityCod = null;
  prev_name:string = null;

  // TABS DEFINITION ----------------------------------------------------------
  tabsObjDef = [
    { key: 'geral', name: 'Geral', active: true, disabled: false },
    { key: 'obs', name: 'Observações', active: false, disabled: false },
  ];

  fornTabsObjDef = [
    { key: 'geral', name: 'Geral', active: true, disabled: false },
    { key: 'comunicacoes', name: 'Comunicações', active: false, disabled: false },
    { key: 'obs', name: 'Observações', active: false, disabled: false },
  ];
  selTab: any = null;

  propTabsObjDef = [
    { key: 'geral', name: 'Geral', active: true, disabled: false },
    { key: 'moradas', name: 'Moradas', active: false, disabled: false },
    { key: 'contencioso', name: 'Contencioso', active: false, disabled: false },
    { key: 'dados-bancarios', name: 'Dados Bancários e Envios', active: false, disabled: false },
    { key: 'comunicacoes', name: 'Comunicações', active: false, disabled: false },
    { key: 'obs', name: 'Observações', active: false, disabled: false },
  ];
  inquilinoTabsObjDef = [
    { key: 'geral', name: 'Geral', active: true, disabled: false },
    { key: 'moradas', name: 'Moradas', active: false, disabled: false },
    { key: 'dados-bancarios', name: 'Dados Bancários e Envios', active: false, disabled: false },
    { key: 'comunicacoes', name: 'Comunicações', active: false, disabled: false },
    { key: 'obs', name: 'Observações', active: false, disabled: false },
  ];
  geralSelTab: any = null;

  // CONDOMINO FORM
  condominoForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_contribuinte: new FormControl(null),
    telefone_1: new FormControl(null),
    telefone_2: new FormControl(null),
    obs: new FormControl(null),
    limite_credito: new FormControl(null),
    dt_nascimento: new FormControl(null),
    profissao: new FormControl(null),
    email_1: new FormControl(null),
    email_2: new FormControl(null),
    other_emails: new FormControl(null),
    banco: new FormControl(null),
    n_conta: new FormControl(null),
    nib: new FormControl(null),
    referencia: new FormControl(null),
    bi_condomino: new FormControl(null),
    matriculas: new FormControl(null),
    conjuge: new FormControl(null),
    bi_conjuge: new FormControl(null),
    dt_conjuge: new FormControl(null),
    n_contribuinte_conjuge: new FormControl(null),
    estado_civil: new FormControl(null),
    forma_tratamento: new FormControl(null),
    tipo_documento: new FormControl(null),
    id_procurador: new FormControl(null),
    id_tipo: new FormControl(null),

    morada: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),

    morada_fact: new FormControl(null),
    postalCode_fact: new FormControl(null),
    locality_fact: new FormControl(null),

    tipo_pagamento: new FormControl(null),
    
    com_geral: new FormGroup({
      email: new FormControl(false),
      carta: new FormControl(true),
    }),
    com_avisos: new FormGroup({
      email: new FormControl(false),
      carta: new FormControl(true),
    }),
    com_cartas_cobranca: new FormGroup({
      email: new FormControl(false),
      carta: new FormControl(true),
    }),

    telefone_1_obs: new FormControl(null),
    telefone_2_obs: new FormControl(null),
    email_1_obs: new FormControl(null),
    email_2_obs: new FormControl(null),
  });

  // INQUILINO FORM
  inquilinoForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_contribuinte: new FormControl(null),
    telefone_1: new FormControl(null),
    telefone_2: new FormControl(null),
    obs: new FormControl(null),
    limite_credito: new FormControl(null),
    dt_nascimento: new FormControl(null),
    profissao: new FormControl(null),
    email_1: new FormControl(null),
    email_2: new FormControl(null),
    banco: new FormControl(null),
    n_conta: new FormControl(null),
    nib: new FormControl(null),
    referencia: new FormControl(null),
    bi_condomino: new FormControl(null),
    matriculas: new FormControl(null),
    conjuge: new FormControl(null),
    bi_conjuge: new FormControl(null),
    dt_conjuge: new FormControl(null),
    n_contribuinte_conjuge: new FormControl(null),
    estado_civil: new FormControl(null),
    forma_tratamento: new FormControl(null),
    tipo_documento: new FormControl(null),
    id_procurador: new FormControl(null),
    id_tipo: new FormControl(null),

    morada: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),

    morada_fact: new FormControl(null),
    postalCode_fact: new FormControl(null),
    locality_fact: new FormControl(null),

    tipo_pagamento: new FormControl(null),

    com_geral: new FormGroup({
      email: new FormControl(false),
      carta: new FormControl(true),
    }),
    com_avisos: new FormGroup({
      email: new FormControl(false),
      carta: new FormControl(true),
    }),
    com_cartas_cobranca: new FormGroup({
      email: new FormControl(false),
      carta: new FormControl(true),
    }),

    telefone_1_obs: new FormControl(null),
    telefone_2_obs: new FormControl(null),
    email_1_obs: new FormControl(null),
    email_2_obs: new FormControl(null),
  });


  // FORNECEDOR FORM
  fornecedorForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_contribuinte: new FormControl(null),
    telefone_1: new FormControl(null),
    telefone_2: new FormControl(null),
    comissao: new FormControl(null),
    obs: new FormControl(null),
    email_1: new FormControl(null),
    email_2: new FormControl(null),
    forma_tratamento: new FormControl(null),
    nib: new FormControl(null),
    id_tipo: new FormControl(null),

    morada: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),

    obj: new FormControl(null),

    telefone_1_obs: new FormControl(null),
    telefone_2_obs: new FormControl(null),
    email_1_obs: new FormControl(null),
    email_2_obs: new FormControl(null),
  });
  tipoSelectedOpt = '1';
  tipoSelectOpts = [];

  // PROCURADOR FORM
  procuradorForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_contribuinte: new FormControl(null),
    telefone_1: new FormControl(null),
    telefone_2: new FormControl(null),
    obs: new FormControl(null),
    email_1: new FormControl(null),
    email_2: new FormControl(null),
    forma_tratamento: new FormControl(null),

    morada: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),

    obj: new FormControl(null),

    telefone_1_obs: new FormControl(null),
    telefone_2_obs: new FormControl(null),
    email_1_obs: new FormControl(null),
    email_2_obs: new FormControl(null),
  });

  addressObj_1 = {
    address: null,
    postalCode: null,
    locality: null,
  }

  addressObj_2 = {
    address: null,
    postalCode: null,
    locality: null,
  }

  fraccaoState = null;

  moradaSugerida = null;

  fraccoesContenciosoCol = [
    { key: 'checked', name: '', type: 'checkbox', sort: null, searchable: true, centered: true, class: 'table-checkbox-column' },
    { key: 'condominio', name: 'Condomínio', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'cod_fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'estado_contencioso', name: 'Estado', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: false, centered: false, class: 'date-width col-centered' },
    { key: 'estado', name: 'Estado', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered' },
  ];
  fraccaoList:Array<CondominoFraccaoList> = [];
  fraccoesOpts:Array<{name, value, data:CondominoFraccaoList}> = [];
  fraccoesContenciosoOrig:Array<EstadoContenciosoList> = [];
  fraccoesContencioso:Array<EstadoContenciosoList> = [];
  
  // OUTRAS ENTIDADES FORM ----------------------------------------------------
  outraEntidadeForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_contribuinte: new FormControl(null),
    telefone_1: new FormControl(null),
    telefone_2: new FormControl(null),
    comissao: new FormControl(null),
    obs: new FormControl(null),
    email_1: new FormControl(null),
    email_2: new FormControl(null),
    forma_tratamento: new FormControl(null),
    nib: new FormControl(null),
    id_tipo: new FormControl(null),

    morada: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),

    obj: new FormControl(null),

    telefone_1_obs: new FormControl(null),
    telefone_2_obs: new FormControl(null),
    email_1_obs: new FormControl(null),
    email_2_obs: new FormControl(null),
  });
  tipoEntidadeSelectedOpt = null;

  navState: NavigationState = null;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public router: Router,
              public message: MessageService,
              public modalService: SuiModalService,
              public location: Location,
              public cdRef:ChangeDetectorRef,
              public fb:FormBuilder,
              public appState: AppStateService,
              public fraccoesService: FraccoesService,
              public condominosService: CondominosService,
              public appConfig: AppConfigService) {
                let currState = this.router.getCurrentNavigation()
                if ('state' in currState.extras) {
                  this.navState = currState.extras.state;
                }
  }

  moradaAux = null;
  descAux = null;
  ngOnInit() {

    this.setEntityType();
    this.selTab = this.tabsObjDef[0];
    this.geralSelTab = this.propTabsObjDef[0];

    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.isCreate = true;
      this.fraccaoState = this.appState.getPrevState('fraccoes');

      let moradaAux = this.appState.getMoradaCondominio();
      this.moradaAux = moradaAux;

      let descAux = this.appState.getDescFraccao();
      this.descAux = descAux;

      if (moradaAux && moradaAux.morada) {
        this.moradaSugerida = moradaAux;

        if (descAux) this.moradaSugerida.morada += ', ' + descAux;
      }
    } else {
      if (this.router.url.indexOf('/proprietario/') !== -1 || this.router.url.indexOf('/inquilino/') !== -1 || this.router.url.indexOf('/entidade/') !== -1) {
        this.entityCod = this.route.snapshot.params.id;
      } else {
        this.entityId = this.route.snapshot.params.id;
      }
    }

    this.api.getAllTipos().subscribe(res => {
      if (res.hasOwnProperty('success') &&  res.success) {
        this.tipoSelectOpts = res.data.map(el => { return { name: el.descricao, value: el.id }; });
      }   
    }, err => {});


    this.init();

  }

  ngOnDestroy() {
    if (this.route.snapshot.params.id === 'criar' && this.moradaAux && this.moradaAux.hasOwnProperty('morada')) {
      this.moradaAux.morada = this.moradaAux.morada.replace(', ' + this.descAux, '');
      this.appState.setMoradaCondominio(this.moradaAux);
    }
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: null });
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngAfterViewInit() {
    this.deleteContenciosoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteContenciosoAlertRef);
    this.deleteContenciosoAlertConfig.closeResult = "closed";
    this.deleteContenciosoAlertConfig.size = 'small';
    this.deleteContenciosoAlertConfig.transition = 'fade';
    this.deleteContenciosoAlertConfig.transitionDuration = 250;
    
    this.printDocumentsConfigRef = new TemplateModalConfig<IContext, string, string>(this.printDocumentsRef);
    this.printDocumentsConfigRef.closeResult = "closed";
    this.printDocumentsConfigRef.size = 'tiny';
    this.printDocumentsConfigRef.transition = 'fade';
    this.printDocumentsConfigRef.transitionDuration = 250;
    
    this.emailOficialFracoesConfig = new TemplateModalConfig<IContext, string, string>(this.emailOficialFracoesAlertRef);
    this.emailOficialFracoesConfig.closeResult = "closed";
    this.emailOficialFracoesConfig.size = 'small';
    this.emailOficialFracoesConfig.transition = 'fade';
    this.emailOficialFracoesConfig.transitionDuration = 250;
  }
  
  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  procuradorTimer = null;
  procuradorSelected= null;
  procuradorSelOptsLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.procuradorSelected); });
    }

    clearTimeout(this.procuradorTimer);
    return new Promise(resolve => {
        if (query) {
          this.procuradorTimer = setTimeout(() => {
            // PROPRIETARIOS/INGUILINOS LIST
            this.api.getAllProcuradores(query).subscribe(res => {
              if (res.success) {
                // return resolve([{ name: '--- Criar procurador ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; })));
                return resolve(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
              } else {
                return resolve([]);
              }
            });
          }, 400);
        } else {
          this.api.getAllProcuradores('NULL').subscribe(res => {
            if (res.success) {
              // return resolve([{ name: '--- Criar procurador ---', value: '0' }].concat(res.data.map(el => { return { name: el.nome, value: el.cod }; })));
              return resolve(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  bancoTimer = null;
  bancoSelected = null;
  bancoSelOptsLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.bancoSelected); });
    }

    clearTimeout(this.procuradorTimer);
    return new Promise(resolve => {
        if (query) {
          this.bancoTimer = setTimeout(() => {
            // PROPRIETARIOS/INGUILINOS LIST
            this.api.getAllBancos(query).subscribe(res => {
              if (res.success) {
                // return resolve([{ name: '--- Criar banco ---', value: '0' }].concat(res.data.map(el => { return { name: el.sigla + ' - ' + el.banco, value: el.sigla + ' - ' + el.banco }; })));
                return resolve(res.data.map(el => { return { name: el.sigla + ' - ' + el.banco, value: el.sigla + ' - ' + el.banco }; }));
              } else {
                // return resolve([{ name: '--- Criar banco ---', value: '0' }]);
                return resolve([]);
              }
            });
          }, 400);
        } else {
          this.api.getAllBancos('NULL').subscribe(res => {
            if (res.success) {
              // return resolve([{ name: '--- Criar banco ---', value: '0' }].concat(res.data.map(el => { return { name: el.sigla + ' - ' + el.banco, value: el.sigla + ' - ' + el.banco }; })));
              return resolve(res.data.map(el => { return { name: el.sigla + ' - ' + el.banco, value: el.sigla + ' - ' + el.banco }; }));
            } else {
              // return resolve([{ name: '--- Criar banco ---', value: '0' }]);
              return resolve([]);
            }
          });
        }
    });
  };

  setEntityType() {
    if (this.router.url.indexOf('proprietario') !== -1) this.entityType = 'proprietarios';
    if (this.router.url.indexOf('inquilino') !== -1) this.entityType = 'inquilinos';
    if (this.router.url.indexOf('fornecedor') !== -1) this.entityType = 'fornecedores';
    if (this.router.url.indexOf('procurador') !== -1) this.entityType = 'procuradores';
    if (this.router.url.indexOf('/entidade/') !== -1) this.entityType = 'outras-entidades';
  }

  clearForm(targetForm) {
    switch (targetForm) {
      case 'proprietarios':
        if (this.isCreate) {
          this.condominoForm.reset();

          if (this.moradaSugerida) {
            this.condominoForm.patchValue({  
              morada: this.moradaSugerida.morada,
              postalCode: this.moradaSugerida.postalCode,
              locality: this.moradaSugerida.locality,
            });
          }

        } else {
          this.restoreForm();
        }
        break;
      case 'inquilinos':
        if (this.isCreate) {
          this.inquilinoForm.reset();

          if (this.moradaSugerida) {
            this.inquilinoForm.patchValue({  
              morada: this.moradaSugerida.morada,
              postalCode: this.moradaSugerida.postalCode,
              locality: this.moradaSugerida.locality,
            });
          }

        } else {
          this.restoreForm();
        }
        break;
      case 'fornecedores':
        if (this.isCreate) {
          this.fornecedorForm.reset();
        } else {
          this.restoreForm();
        }
        break;
      case 'procuradores':
        if (this.isCreate) {
          this.procuradorForm.reset();
        } else {
          this.restoreForm();
        }
        break;
      case 'outras-entidades':
        if (this.isCreate) {
          this.outraEntidadeForm.reset();
        } else {
          this.restoreForm();
        }
        break;
    }
  }

  init() {
    if (this.isCreate) {
      if (this.moradaSugerida) {
        this.condominoForm.patchValue({  
          morada: this.moradaSugerida.morada,
          postalCode: this.moradaSugerida.postalCode,
          locality: this.moradaSugerida.locality,
        });

        this.inquilinoForm.patchValue({  
          morada: this.moradaSugerida.morada,
          postalCode: this.moradaSugerida.postalCode,
          locality: this.moradaSugerida.locality,
        });
      }
    } else {
      this.getEntityDetails();
    }
  }

  goToDetails(row: CondominoFraccaoList) {
    this.router.navigate(['condominios/condominio/geral', row.id_condominio]);
  }
  
  registoComunicacaoInput = null;
  getEntityDetails() {
    switch(this.entityType) {
      case 'proprietarios':
        this.api.getCondominoDetails(this.entityCod).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            this.entityEmailsOficiais = res.emailsOficiais;
            this.entityCod = res.data.cod;
            this.entityId = res.data.id;
            this.entityDetails = res.data;
            this.prev_name = res.data.nome;
         
            this.entityDetails.telefone_1 = (this.entityDetails.telefone_1) ? this.entityDetails.telefone_1.replace(/NEW_LINE/g, ', ') : null;
            this.entityDetails.telefone_2 = (this.entityDetails.telefone_2) ? this.entityDetails.telefone_2.replace(/NEW_LINE/g, ', ') : null;

            this.entityDetails.obs = (this.entityDetails.obs) ? this.entityDetails.obs.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada = (this.entityDetails.morada) ? this.entityDetails.morada.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada_fact = (this.entityDetails.morada_fact) ? this.entityDetails.morada_fact.replace(/NEW_LINE/g, '\n') : null;

            if (this.entityDetails.id_procurador) this.procuradorSelected = { name: this.entityDetails.procurador_nome, value: this.entityDetails.id_procurador };
            if (this.entityDetails.banco) this.bancoSelected = { name: this.entityDetails.banco, value: this.entityDetails.banco };

            this.fraccaoList = res.fraccoes.map(el => { return {...this.condominosService.convertCondominoFraccaoType(el), checked:false}});
            this.fraccoesOpts = this.fraccaoList.map(el => { return { name: el.nome_condominio + ' (' + el.nome_fracao + ')', value: el.id_fracao, data: el}});
            this.fraccoesContenciosoOrig = res.estados_contenciosos.map(el => { return {...this.condominosService.convertCondominoEstadoContenciosoType(el), checked:false}});
            this.fraccoesContencioso = this.fraccoesContenciosoOrig.slice();


            this.registoComunicacaoInput = {
              cod_entidade: this.entityCod,
              tipo_entidade: 'PROPRIETARIO',
            } 

            this.sortContenciosoList();

            this.restoreForm();

            this.executeNavigationAction();
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'inquilinos':
        this.api.getCondominoDetails(this.entityCod).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.entityCod = res.data.cod;
            this.entityId = res.data.id;
            this.entityDetails = res.data;
            this.prev_name = res.data.nome;
            this.entityEmailsOficiais = res.emailsOficiais;

            this.entityDetails.telefone_1 = (this.entityDetails.telefone_1) ? this.entityDetails.telefone_1.replace(/NEW_LINE/g, ', ') : null;
            this.entityDetails.telefone_2 = (this.entityDetails.telefone_2) ? this.entityDetails.telefone_2.replace(/NEW_LINE/g, ', ') : null;

            this.entityDetails.obs = (this.entityDetails.obs) ? this.entityDetails.obs.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada = (this.entityDetails.morada) ? this.entityDetails.morada.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada_fact = (this.entityDetails.morada_fact) ? this.entityDetails.morada_fact.replace(/NEW_LINE/g, '\n') : null;

            if (this.entityDetails.id_procurador) this.procuradorSelected = { name: this.entityDetails.procurador_nome, value: this.entityDetails.id_procurador };
            if (this.entityDetails.banco) this.bancoSelected = { name: this.entityDetails.banco, value: this.entityDetails.banco };

            this.fraccaoList = res.fraccoes.map(el => { return {...this.condominosService.convertCondominoFraccaoType(el), checked:false}});

            this.registoComunicacaoInput = {
              cod_entidade: this.entityCod,
              tipo_entidade: 'PROPRIETARIO',
            } 

            this.restoreForm();
            
            this.executeNavigationAction();
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'fornecedores':
        this.api.getFornecedorDetails(this.entityId).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.entityCod = res.data.cod;
            this.entityId = res.data.id;
            this.entityDetails = res.data;
            this.prev_name = res.data.nome;

            this.entityDetails.telefone_1 = (this.entityDetails.telefone_1) ? this.entityDetails.telefone_1.replace(/NEW_LINE/g, ', ') : null;
            this.entityDetails.telefone_2 = (this.entityDetails.telefone_2) ? this.entityDetails.telefone_2.replace(/NEW_LINE/g, ', ') : null;

            this.entityDetails.obs = (this.entityDetails.obs) ? this.entityDetails.obs.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada = (this.entityDetails.morada) ? this.entityDetails.morada.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada_fact = (this.entityDetails.morada_fact) ? this.entityDetails.morada_fact.replace(/NEW_LINE/g, '\n') : null;

            this.registoComunicacaoInput = {
              cod_entidade: this.entityCod,
              tipo_entidade: 'FORNECEDOR',
            } 

            this.restoreForm();

            this.executeNavigationAction();
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'procuradores':
        this.api.getProcuradorDetails(this.entityId).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.entityCod = res.data.cod;
            this.entityId = res.data.id;
            this.entityDetails = res.data;
            this.prev_name = res.data.nome;

            this.entityDetails.telefone_1 = (this.entityDetails.telefone_1) ? this.entityDetails.telefone_1.replace(/NEW_LINE/g, ', ') : null;
            this.entityDetails.telefone_2 = (this.entityDetails.telefone_2) ? this.entityDetails.telefone_2.replace(/NEW_LINE/g, ', ') : null;

            this.entityDetails.obs = (this.entityDetails.obs) ? this.entityDetails.obs.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada = (this.entityDetails.morada) ? this.entityDetails.morada.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada_fact = (this.entityDetails.morada_fact) ? this.entityDetails.morada_fact.replace(/NEW_LINE/g, '\n') : null;

            this.restoreForm();

            this.executeNavigationAction();
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'outras-entidades':
        this.api.getCondominoDetails(this.entityCod).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.entityCod = res.data.cod;
            this.entityId = res.data.id;
            this.entityDetails = res.data;
            this.prev_name = res.data.nome;
            this.entityEmailsOficiais = res.emailsOficiais;

            this.entityDetails.telefone_1 = (this.entityDetails.telefone_1) ? this.entityDetails.telefone_1.replace(/NEW_LINE/g, ', ') : null;
            this.entityDetails.telefone_2 = (this.entityDetails.telefone_2) ? this.entityDetails.telefone_2.replace(/NEW_LINE/g, ', ') : null;

            this.entityDetails.obs = (this.entityDetails.obs) ? this.entityDetails.obs.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada = (this.entityDetails.morada) ? this.entityDetails.morada.replace(/NEW_LINE/g, '\n') : null;
            this.entityDetails.morada_fact = (this.entityDetails.morada_fact) ? this.entityDetails.morada_fact.replace(/NEW_LINE/g, '\n') : null;
            
            this.restoreForm();

            this.executeNavigationAction();
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
    }
  }

  executeNavigationAction() {
    if (!this.navState || !this.navState.action) return;
    switch (this.navState.action) {
      case 'OPEN_CONTENCIOSO':
        let id = this.navState.data? this.navState.data.id : null;
        if (!id) return;
        this.propTabsObjDef.find(el => el.key === 'contencioso').active = true;
        let contencioso = this.fraccoesContenciosoOrig.find(el => el.id === id);
        if (!contencioso) return;
        this.openContenciosoModal(contencioso); 
        break;
    
      default:
        break;
    }
  }

  @ViewChild('registoComunicacoes', { static: false }) registoComunicacoes: RegistoComunicacoesComponent;
  changedTab(tabKey) {
    if (tabKey === 'comunicacoes') {
      if (this.registoComunicacoes) this.registoComunicacoes.getRegistoComunicacoes(true);
    }
  }

  emailInvalid1 = false;
  emailInvalid2 = false;
  otherEmailsInvalid = false;

  isNifValid1 = true;
  isNifValid2 = true;
  isPhoneValid1 = true;
  isPhoneValid2 = true;

  nibNotOk = false;
  formSubmitted() {
    let data = null;
    let geral = null;
    let avisos = null;
    let cartas_cobranca = null;
    let alterado = '0';
    let recepcaoDocumentos = null;

    switch(this.entityType) {
      case 'proprietarios':
        this.submittingForm = true;

        this.isNifValid1 = true;
        this.isNifValid2 = true;
        this.isPhoneValid1 = true;
        this.isPhoneValid2 = true;

        if (!this.condominoForm.valid) return;
    
        data = this.condominoForm.getRawValue();
        data.morada = JSON.stringify({ address: data.morada, postalCode: data.postalCode, locality: data.locality });
        data.morada_fact = JSON.stringify({ address: data.morada_fact, postalCode: data.postalCode_fact, locality: data.locality_fact });

        data.obj = JSON.stringify({
          tipo_pagamento: data.tipo_pagamento,
        });

        geral = data.com_geral;
        avisos = data.com_avisos;
        cartas_cobranca = data.com_cartas_cobranca;

        recepcaoDocumentos = {
          geral: geral.email && geral.carta? 'A' : (geral.email? 'E' : (geral.carta? 'C' : null)),
          avisos:  avisos.email && avisos.carta? 'A' : (avisos.email? 'E' : (avisos.carta? 'C' : null)),
          cartas_cobranca:  cartas_cobranca.email && cartas_cobranca.carta? 'A' : (cartas_cobranca.email? 'E' : (cartas_cobranca.carta? 'C' : null)),
        }

        // CHECK Nº CONTRIBUINTE (n_contribuinte) - 9 digits
        if (data.n_contribuinte && (!this.utils.isNumber(data.n_contribuinte.replace(/\s/g, '')) || data.n_contribuinte.replace(/\s/g, '').length !== 9)) {
          this.isNifValid1 = false;
          this.toastr.error('Por favor, verifique o numero de contribuinte introduzido.', 'Nº de Contribuinte Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK PHONE NUMBER (telefone_1) - 9 digits
        if (data.telefone_1 && (!this.utils.isNumber(data.telefone_1.replace(/\s/g, '').replace('+', '')) || (data.telefone_1.replace(/\s/g, '').length !== 9 && data.telefone_1.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid1 = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK PHONE NUMBER (telefone_2) - 9 digits
        if (data.telefone_2 && (!this.utils.isNumber(data.telefone_2.replace(/\s/g, '').replace('+', '')) || (data.telefone_2.replace(/\s/g, '').length !== 9 && data.telefone_2.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid2 = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK IF IBAN IS OK
        if (data.nib && (data.nib.indexOf('PT50') === -1 || data.nib.replace(/\s/g, '').length !== 25)) {
          this.nibNotOk = true;
    
          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error("O campo IBAN tem obrigatóriamente de ser composto pelo prefixo 'PT50' seguido de 21 digitos numéricos.", 'Alerta', { timeOut: 4000 });
          return;
        }

        // CHECK EMAIL ADDRESSES
        if (data.email_1 && !this.utils.validateEmail(data.email_1)) {
          this.emailInvalid1 = true;
          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
          return;
        } else {
          this.emailInvalid1 = false;
        }
        
        if (data.email_2 && !this.utils.validateEmail(data.email_2)) {
          this.emailInvalid2 = true;
          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
          return;
        } else {
          this.emailInvalid2 = false;
        }
        
        if (data.other_emails) {
          let emails: [] = data.other_emails.split(';');
          if (emails.length) {
            if (emails[emails.length - 1] === '') emails.pop();
            for (let i = 0; i < emails.length; i++) {
              if (!this.utils.validateEmail(emails[i])) {
                this.otherEmailsInvalid = true;
                setTimeout(() => { this.submittingForm = false; }, 4000);
                this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
                return;
              }
            }
          }
          this.otherEmailsInvalid = false;
        }

        // CHECK Nº CONTRIBUINTE (n_contribuinte_conjuge) - 9 digits
        if (data.n_contribuinte_conjuge && (!this.utils.isNumber(data.n_contribuinte_conjuge.replace(/\s/g, '')) || data.n_contribuinte_conjuge.replace(/\s/g, '').length !== 9)) {
          this.isNifValid2 = false;
          this.toastr.error('Por favor, verifique o numero de contribuinte introduzido.', 'Nº de Contribuinte Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        this.loading = true;
        if (this.prev_name !== data.nome && !this.isCreate) {
          alterado = '1';
        }

        this.api.saveCondomino(this.entityId, this.entityCod, data.nome, data.morada, data.morada_fact, data.n_contribuinte, data.telefone_1, data.telefone_2, data.obs, data.limite_credito, data.dt_nascimento, data.profissao, data.email_1, data.email_2, data.other_emails, data.banco, data.n_conta, data.nib, data.referencia, data.bi_condomino, data.matriculas, data.conjuge, data.bi_conjuge, data.dt_conjuge, data.n_contribuinte_conjuge, data.estado_civil, data.forma_tratamento, data.tipo_documento, 'proprietarios', data.id_procurador, data.id_tipo, data.obj, recepcaoDocumentos, data.telefone_1_obs, data.telefone_2_obs, data.email_1_obs, data.email_2_obs, alterado).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {
            this.entityDetails = res.data;
            this.prev_name = data.nome
            if (this.fraccaoState && this.fraccaoState.state.hasOwnProperty('cod_proprietario')) {
              this.fraccaoState.state['cod_proprietario'] = res.data.cod;
              this.fraccaoState.state['nome_proprietario'] = res.data.nome;
              this.appState.setPrevState('fraccoes', this.fraccaoState);
            } 

            if (this.entityId) {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: this.entityCod, cod: data.nome };
              let titulo = 'Proprietário Actualizado';
              let descricao = 'Condómino: ' + data.nome;
              this.api.saveRegistoActividade(null, 'PROPRIETARIO', null, titulo, descricao, obj).subscribe(res => {}, err => { });
            } else {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: res.data.cod, cod: data.nome };
              let titulo = 'Proprietário Criado';
              let descricao = 'Condómino: ' + data.nome;
              this.api.saveRegistoActividade(null, 'PROPRIETARIO', null, titulo, descricao, obj).subscribe(res => {}, err => { });
            }


            if (this.navState && this.navState.redirectAfterTo) {
              this.redirectByNavState();
            } else {
              this.location.back();
            }

            } else {
              this.utils.apiErrorMsg(res);
            }
            this.submittingForm = false;
            this.loading = false;
          }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
          this.loading = false;
        }); 
        break;
      case 'inquilinos':
        this.submittingForm = true;

        this.isNifValid1 = true;
        this.isNifValid2 = true;
        this.isPhoneValid1 = true;
        this.isPhoneValid2 = true;

        if (!this.inquilinoForm.valid) return;

        data = this.inquilinoForm.getRawValue();
        data.morada = JSON.stringify({ address: data.morada, postalCode: data.postalCode, locality: data.locality });
        data.morada_fact = JSON.stringify({ address: data.morada_fact, postalCode: data.postalCode_fact, locality: data.locality_fact });

        data.obj = JSON.stringify({
          tipo_pagamento: data.tipo_pagamento
        });

        geral = data.com_geral;
        avisos = data.com_avisos;
        cartas_cobranca = data.com_cartas_cobranca;
        
        recepcaoDocumentos = {
          geral: geral.email && geral.carta? 'A' : (geral.email? 'E' : (geral.carta? 'C' : null)),
          avisos:  avisos.email && avisos.carta? 'A' : (avisos.email? 'E' : (avisos.carta? 'C' : null)),
          cartas_cobranca:  cartas_cobranca.email && cartas_cobranca.carta? 'A' : (cartas_cobranca.email? 'E' : (cartas_cobranca.carta? 'C' : null)),
        }

        // CHECK Nº CONTRIBUINTE (n_contribuinte) - 9 digits
        if (data.n_contribuinte && (!this.utils.isNumber(data.n_contribuinte.replace(/\s/g, '')) || data.n_contribuinte.replace(/\s/g, '').length !== 9)) {
          this.isNifValid1 = false;
          this.toastr.error('Por favor, verifique o numero de contribuinte introduzido.', 'Nº de Contribuinte Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK PHONE NUMBER (telefone_1) - 9 digits
        if (data.telefone_1 && (!this.utils.isNumber(data.telefone_1.replace(/\s/g, '').replace('+', '')) || (data.telefone_1.replace(/\s/g, '').length !== 9 && data.telefone_1.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid1 = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK PHONE NUMBER (telefone_2) - 9 digits
        if (data.telefone_2 && (!this.utils.isNumber(data.telefone_2.replace(/\s/g, '').replace('+', '')) || (data.telefone_2.replace(/\s/g, '').length !== 9 && data.telefone_2.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid2 = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK IF IBAN IS OK
        if (data.nib && (data.nib.indexOf('PT50') === -1 || data.nib.replace(/\s/g, '').length !== 25)) {
          this.nibNotOk = true;
    
          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error("O campo IBAN tem obrigatóriamente de ser composto pelo prefixo 'PT50' seguido de 21 digitos numéricos.", 'Alerta', { timeOut: 4000 });
          return;
        }

        // CHECK EMAIL ADDRESSES
        if (data.email_1 && !this.utils.validateEmail(data.email_1)) {
          this.emailInvalid1 = true;

          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Alerta', { timeOut: 4000 });
          return;
        }
        if (data.email_2 && !this.utils.validateEmail(data.email_2)) {
          this.emailInvalid2 = true;

          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Alerta', { timeOut: 4000 });
          return;
        }

        // CHECK Nº CONTRIBUINTE (n_contribuinte_conjuge) - 9 digits
        if (data.n_contribuinte_conjuge && (!this.utils.isNumber(data.n_contribuinte_conjuge.replace(/\s/g, '')) || data.n_contribuinte_conjuge.replace(/\s/g, '').length !== 9)) {
          this.isNifValid2 = false;
          this.toastr.error('Por favor, verifique o numero de contribuinte introduzido.', 'Nº de Contribuinte Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        this.loading = true;

        if (this.prev_name !== data.nome && !this.isCreate) {
          alterado = '1';
        }
        
        this.api.saveCondomino(this.entityId, this.entityCod, data.nome, data.morada, data.morada_fact, data.n_contribuinte, data.telefone_1, data.telefone_2, data.obs, data.limite_credito, data.dt_nascimento, data.profissao, data.email_1, data.email_2, data.other_emails, data.banco, data.n_conta, data.nib, data.referencia, data.bi_condomino, data.matriculas, data.conjuge, data.bi_conjuge, data.dt_conjuge, data.n_contribuinte_conjuge, data.estado_civil, data.forma_tratamento, data.tipo_documento, 'inquilinos', data.id_procurador, data.id_tipo, data.obj, recepcaoDocumentos, data.telefone_1_obs, data.telefone_2_obs, data.email_1_obs, data.email_2_obs, alterado).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {
            this.prev_name = data.nome
            if (this.fraccaoState &&  this.fraccaoState.state.hasOwnProperty('cod_inquilino')) {
              this.fraccaoState.state['cod_inquilino'] = res.data.cod;
              this.fraccaoState.state['nome_inquilino'] = res.data.nome;
              this.appState.setPrevState('fraccoes', this.fraccaoState);
            } 

            if (this.entityId) {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: this.entityCod, cod: data.nome };
              let titulo = 'Inquilino Actualizado';
              let descricao = 'Inquilino: ' + data.nome;
              this.api.saveRegistoActividade(null, 'INQUILINO', null, titulo, descricao, obj).subscribe(res => {}, err => { });
            } else {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: res.data.cod, cod: data.nome };
              let titulo = 'Inquilino Criado';
              let descricao = 'Inquilino: ' + data.nome;
              this.api.saveRegistoActividade(null, 'INQUILINO', null, titulo, descricao, obj).subscribe(res => {}, err => { });
            }

            this.location.back();
            } else {
              this.utils.apiErrorMsg(res);
            }
            this.submittingForm = false;
            this.loading = false;
          }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
          this.loading = false;
        }); 
        break;
      case 'fornecedores':
        this.submittingForm = true;

        this.isNifValid1 = true;
        this.isPhoneValid1 = true;
        this.isPhoneValid2 = true;

        if (!this.fornecedorForm.valid) return;
    
        data = this.fornecedorForm.getRawValue();
        data.morada = JSON.stringify({ address: data.morada, postalCode: data.postalCode, locality: data.locality });

        if (data.nib && (data.nib.indexOf('PT50') === -1 || data.nib.replace(/\s/g, '').length !== 25)) {
          this.nibNotOk = true;
    
          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error("O campo IBAN tem obrigatóriamente de ser composto pelo prefixo 'PT50' seguido de 21 digitos numéricos.", 'Alerta', { timeOut: 4000 });
          return;
        }

        // CHECK Nº CONTRIBUINTE (n_contribuinte) - 9 digits
        if (data.n_contribuinte && (!this.utils.isNumber(data.n_contribuinte.replace(/\s/g, '')) || data.n_contribuinte.replace(/\s/g, '').length !== 9)) {
          this.isNifValid1 = false;
          this.toastr.error('Por favor, verifique o numero de contribuinte introduzido.', 'Nº de Contribuinte Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK PHONE NUMBER (telefone_1) - 9 digits
        if (data.telefone_1 && (!this.utils.isNumber(data.telefone_1.replace(/\s/g, '').replace('+', '')) || (data.telefone_1.replace(/\s/g, '').length !== 9 && data.telefone_1.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid1 = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK PHONE NUMBER (telefone_2) - 9 digits
        if (data.telefone_2 && (!this.utils.isNumber(data.telefone_2.replace(/\s/g, '').replace('+', '')) || (data.telefone_2.replace(/\s/g, '').length !== 9 && data.telefone_2.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid2 = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK EMAIL ADDRESSES
        if (data.email_1 && !this.utils.validateEmail(data.email_1)) {
          this.emailInvalid1 = true;

          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Alerta', { timeOut: 4000 });
          return;
        }
        if (data.email_2 && !this.utils.validateEmail(data.email_2)) {
          this.emailInvalid2 = true;

          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Alerta', { timeOut: 4000 });
          return;
        }

        this.loading = true;

        this.api.saveFornecedor(this.entityId, data.nome, data.morada, data.n_contribuinte, data.telefone_1, data.telefone_2, data.comissao, data.obs, data.email_1, data.email_2, data.forma_tratamento, data.nib, data.id_tipo, data.telefone_1_obs, data.telefone_2_obs, data.email_1_obs, data.email_2_obs).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {

          if (this.entityId) {
            // REGISTO ACTIVIDADES API CALL
            let obj = { link: this.entityId, cod: data.nome };
            let titulo = 'Fornecedor Actualizado';
            let descricao = 'Fornecedor: ' + data.nome;
            this.api.saveRegistoActividade(null, 'FORNECEDOR', null, titulo, descricao, obj).subscribe(res => {}, err => { });
          } else {
            // REGISTO ACTIVIDADES API CALL
            let obj = { link: res.data.id, cod: data.nome };
            let titulo = 'Fornecedor Criado';
            let descricao = 'Fornecedor: ' + data.nome;
            this.api.saveRegistoActividade(null, 'FORNECEDOR', null, titulo, descricao, obj).subscribe(res => {}, err => { });
          }

            this.location.back();
            } else {
              this.utils.apiErrorMsg(res);
            }
            this.submittingForm = false;
            this.loading = false;
          }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
          this.loading = false;
        }); 
        break;
      case 'procuradores':
        this.submittingForm = true;

        this.isNifValid1 = true;
        this.isPhoneValid1 = true;
        this.isPhoneValid2 = true;

        if (!this.procuradorForm.valid) return;

        data = this.procuradorForm.getRawValue();
        data.morada = JSON.stringify({ address: data.morada, postalCode: data.postalCode, locality: data.locality });

        // CHECK Nº CONTRIBUINTE (n_contribuinte) - 9 digits
        if (data.n_contribuinte && (!this.utils.isNumber(data.n_contribuinte.replace(/\s/g, '')) || data.n_contribuinte.replace(/\s/g, '').length !== 9)) {
          this.isNifValid1 = false;
          this.toastr.error('Por favor, verifique o numero de contribuinte introduzido.', 'Nº de Contribuinte Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK PHONE NUMBER (telefone_1) - 9 digits
        if (data.telefone_1 && (!this.utils.isNumber(data.telefone_1.replace(/\s/g, '').replace('+', '')) || (data.telefone_1.replace(/\s/g, '').length !== 9 && data.telefone_1.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid1 = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK PHONE NUMBER (telefone_2) - 9 digits
        if (data.telefone_2 && (!this.utils.isNumber(data.telefone_2.replace(/\s/g, '').replace('+', '')) || (data.telefone_2.replace(/\s/g, '').length !== 9 && data.telefone_2.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid2 = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK EMAIL ADDRESSES
        if (data.email_1 && !this.utils.validateEmail(data.email_1)) {
          this.emailInvalid1 = true;

          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Alerta', { timeOut: 4000 });
          return;
        }
        if (data.email_2 && !this.utils.validateEmail(data.email_2)) {
          this.emailInvalid2 = true;

          setTimeout(() => { this.submittingForm = false; }, 4000);
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Alerta', { timeOut: 4000 });
          return;
        }

        this.loading = true;

        this.api.saveProcurador(this.entityId, data.nome, data.morada, data.n_contribuinte, data.telefone_1, data.telefone_2, data.obs, data.email_1, data.email_2, data.forma_tratamento, data.telefone_1_obs, data.telefone_2_obs, data.email_1_obs, data.email_2_obs).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {

            if (this.entityId) {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: this.entityId, cod: data.nome };
              let titulo = 'Produrador Actualizado';
              let descricao = 'Produrador: ' + data.nome;
              this.api.saveRegistoActividade(null, 'PROCURADOR', null, titulo, descricao, obj).subscribe(res => {}, err => { });
            } else {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: res.data.id, cod: data.nome };
              let titulo = 'Produrador Criado';
              let descricao = 'Produrador: ' + data.nome;
              this.api.saveRegistoActividade(null, 'PROCURADOR', null, titulo, descricao, obj).subscribe(res => {}, err => { });
            }

            this.location.back();

            } else {
              this.utils.apiErrorMsg(res);
            }
            this.submittingForm = false;
            this.loading = false;
          }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
          this.loading = false;
        }); 
        break;
      case 'outras-entidades':
        // this.submittingForm = true;

        // this.isNifValid1 = true;
        // this.isPhoneValid1 = true;
        // this.isPhoneValid2 = true;

        // if (!this.outraEntidadeForm.valid) return;
    
        // data = this.outraEntidadeForm.getRawValue();
        // data.morada = JSON.stringify({ address: data.morada, postalCode: data.postalCode, locality: data.locality });

        // if (data.nib && (data.nib.indexOf('PT50') === -1 || data.nib.replace(/\s/g, '').length !== 25)) {
        //   this.nibNotOk = true;
    
        //   setTimeout(() => { this.submittingForm = false; }, 4000);
        //   this.toastr.error("O campo IBAN tem obrigatóriamente de ser composto pelo prefixo 'PT50' seguido de 21 digitos numéricos.", 'Alerta', { timeOut: 4000 });
        //   return;
        // }

        // // CHECK Nº CONTRIBUINTE (n_contribuinte) - 9 digits
        // if (data.n_contribuinte && (!this.utils.isNumber(data.n_contribuinte.replace(/\s/g, '')) || data.n_contribuinte.replace(/\s/g, '').length !== 9)) {
        //   this.isNifValid1 = false;
        //   this.toastr.error('Por favor, verifique o numero de contribuinte introduzido.', 'Nº de Contribuinte Inválido', { timeOut: 4000 });
        //   setTimeout(() => { this.submittingForm = false; }, 4000);
        //   return;
        // }

        // // CHECK PHONE NUMBER (telefone_1) - 9 digits
        // if (data.telefone_1 && (!this.utils.isNumber(data.telefone_1.replace(/\s/g, '')) || data.telefone_1.replace(/\s/g, '').length !== 9)) {
        //   this.isPhoneValid1 = false;
        //   this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
        //   setTimeout(() => { this.submittingForm = false; }, 4000);
        //   return;
        // }

        // // CHECK PHONE NUMBER (telefone_2) - 9 digits
        // if (data.telefone_2 && (!this.utils.isNumber(data.telefone_2.replace(/\s/g, '')) || data.telefone_2.replace(/\s/g, '').length !== 9)) {
        //   this.isPhoneValid2 = false;
        //   this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
        //   setTimeout(() => { this.submittingForm = false; }, 4000);
        //   return;
        // }

        // // CHECK EMAIL ADDRESSES
        // if (data.email_1 && !this.utils.validateEmail(data.email_1)) {
        //   this.emailInvalid1 = true;

        //   setTimeout(() => { this.submittingForm = false; }, 4000);
        //   this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Alerta', { timeOut: 4000 });
        //   return;
        // }
        // if (data.email_2 && !this.utils.validateEmail(data.email_2)) {
        //   this.emailInvalid2 = true;

        //   setTimeout(() => { this.submittingForm = false; }, 4000);
        //   this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Alerta', { timeOut: 4000 });
        //   return;
        // }

        // this.loading = true;

        //let new_name = this.prev_name !== data.nome && !this.isCreate? '1' : '0';


        // this.api.saveCondomino(this.entityId, data.nome, data.morada, data.morada_fact, data.n_contribuinte, data.telefone_1, data.telefone_2, data.obs, data.limite_credito, data.dt_nascimento, data.profissao, data.email_1, data.email_2, data.banco, data.n_conta, data.nib, data.referencia, data.bi_condomino, data.matriculas, data.conjuge, data.bi_conjuge, data.dt_conjuge, data.n_contribuinte_conjuge, data.estado_civil, data.forma_tratamento, data.tipo_documento, 'outras-entidades', data.id_procurador, data.id_tipo, data.obj, data.recep_carta, data.recep_email, data.telefone_1_obs, data.telefone_2_obs, data.email_1_obs, data.email_2_obs).subscribe(res => {
        //   if (res.hasOwnProperty('success') && res['success']) {

        //   if (this.entityId) {
        //     // REGISTO ACTIVIDADES API CALL
        //     let obj = { link: this.entityId, cod: data.nome };
        //     let titulo = 'Entidade Actualizada';
        //     let descricao = 'Nome: ' + data.nome;
        //     this.api.saveRegistoActividade(null, 'OUTRA_ENTIDADE', null, titulo, descricao, obj).subscribe(res => {}, err => { });
        //   } else {
        //     // REGISTO ACTIVIDADES API CALL
        //     let obj = { link: res.data.id, cod: data.nome };
        //     let titulo = 'Entidade Criada';
        //     let descricao = 'Nome: ' + data.nome;
        //     this.api.saveRegistoActividade(null, 'OUTRA_ENTIDADE', null, titulo, descricao, obj).subscribe(res => {}, err => { });
        //   }

        //     this.location.back();
        //     } else {
        //       this.utils.apiErrorMsg(res);
        //     }
        //     this.submittingForm = false;
        //     this.loading = false;
        //   }, err => {
        //   this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        //   this.submittingForm = false;
        //   this.loading = false;
        // }); 
        break;
    }
  }

  redirectByNavState() {
    if (!this.navState || !this.navState.redirectAfterTo || !this.navState.redirectAfterTo.url) return;
    let state: NavigationState = null;

    switch (this.navState.redirectAfterTo.action) {
      case 'OPEN_NEW_PROP_MODAL':
        let newState: FraccoesNavState = {
          action: this.navState.redirectAfterTo.action,
          data: {
            cod_proprietario: this.entityDetails ? this.entityDetails.cod : null,
            nome_proprietario: this.entityDetails ? this.entityDetails.nome : null,
          }
        };
        
        state = newState;
        break;
    
      default:
        break;
    }
    this.router.navigateByUrl(this.navState.redirectAfterTo.url, { state: state });
  }
  

  restoreForm() {
    switch(this.entityType) {
      case 'proprietarios':
        this.condominoForm.reset();

        try {
          this.entityDetails.obj = JSON.parse(this.entityDetails.obj);
        } catch {
          this.entityDetails.obj = {
            tipo_pagamento: (this.entityDetails.obj && this.entityDetails.obj.hasOwnProperty('tipo_pagamento')) ? this.entityDetails.obj.tipo_pagamento : null
          };
        }

        try {
          let addr = JSON.parse(this.entityDetails.morada);

          this.entityDetails['morada'] = (addr.hasOwnProperty('address')) ? addr.address : this.entityDetails.morada;
          this.entityDetails['postalCode'] = (addr.hasOwnProperty('address')) ? addr.postalCode : this.entityDetails.postalCode;
          this.entityDetails['locality'] = (addr.hasOwnProperty('locality')) ? addr.locality : this.entityDetails.locality;
        } catch {
          this.entityDetails['postalCode'] = null;
          this.entityDetails['locality'] = null;
        }

        try {
          let addr = JSON.parse(this.entityDetails.morada_fact);
          this.entityDetails['morada_fact'] = (addr.hasOwnProperty('address')) ? addr.address : this.entityDetails.morada_fact;
          this.entityDetails['postalCode_fact'] = (addr.hasOwnProperty('address')) ? addr.postalCode : this.entityDetails.postalCode_fact;
          this.entityDetails['locality_fact'] = (addr.hasOwnProperty('locality')) ? addr.locality : this.entityDetails.locality_fact;
        } catch {
          this.entityDetails['postalCode_fact'] = null;
          this.entityDetails['locality_fact'] = null;
        }
        
        this.condominoForm.get('com_geral').patchValue({
          carta: this.entityDetails.com_geral === 'C' || this.entityDetails.com_geral === 'A',
          email: this.entityDetails.com_geral === 'E' || this.entityDetails.com_geral === 'A',
        });
        this.condominoForm.get('com_avisos').patchValue({
          carta: this.entityDetails.com_avisos === 'C' || this.entityDetails.com_avisos === 'A',
          email: this.entityDetails.com_avisos === 'E' || this.entityDetails.com_avisos === 'A',
        });
        this.condominoForm.get('com_cartas_cobranca').patchValue({
          carta: this.entityDetails.com_cartas_cobranca === 'C' || this.entityDetails.com_cartas_cobranca === 'A',
          email: this.entityDetails.com_cartas_cobranca === 'E' || this.entityDetails.com_cartas_cobranca === 'A',
        });

        this.condominoForm.patchValue({
          nome: this.entityDetails.nome,

          morada: this.entityDetails.morada,
          postalCode: this.entityDetails.postalCode,
          locality: this.entityDetails.locality,

          morada_fact: this.entityDetails.morada_fact,
          postalCode_fact: this.entityDetails.postalCode_fact,
          locality_fact: this.entityDetails.locality_fact,

          n_contribuinte: this.entityDetails.n_contribuinte,
          telefone_1: this.entityDetails.telefone_1,
          telefone_2: this.entityDetails.telefone_2,
          obs: this.entityDetails.obs,
          limite_credito: (this.entityDetails.limite_credito) ? this.entityDetails.limite_credito : 0,
          dt_nascimento: this.entityDetails.dt_nascimento,
          profissao: this.entityDetails.profissao,
          email_1: this.entityDetails.email_1,
          email_2: this.entityDetails.email_2,
          other_emails: this.entityDetails.other_emails,
          banco: this.entityDetails.banco,
          n_conta: this.entityDetails.n_conta,
          nib: (this.entityDetails.nib && this.entityDetails.nib.indexOf('PT50') !== -1) ? this.entityDetails.nib : ((this.entityDetails.nib !== null && this.entityDetails.nib !== '') ? 'PT50' : '') + (this.entityDetails.nib ? this.entityDetails.nib : ''),
          referencia: this.entityDetails.referencia,
          bi_condomino: this.entityDetails.bi_condomino,
          matriculas: this.entityDetails.matriculas,
          conjuge: this.entityDetails.conjuge,
          bi_conjuge: this.entityDetails.bi_conjuge,
          dt_conjuge: this.entityDetails.dt_conjuge,
          n_contribuinte_conjuge: this.entityDetails.n_contribuinte_conjuge,
          estado_civil: this.entityDetails.estado_civil,
          forma_tratamento: this.entityDetails.forma_tratamento,
          tipo_documento: this.entityDetails.tipo_documento,
          id_procurador: this.entityDetails.id_procurador,
          id_tipo: this.entityDetails.id_tipo,
          tipo_pagamento: (this.entityDetails.obj) ? this.entityDetails.obj.tipo_pagamento : null,

          telefone_1_obs: this.entityDetails.telefone_1_obs,
          telefone_2_obs: this.entityDetails.telefone_2_obs,
          email_1_obs: this.entityDetails.email_1_obs,
          email_2_obs: this.entityDetails.email_2_obs,
        });

        if (this.condominoForm.get('email_1').value && this.entityEmailsOficiais.findIndex(el => el.email === this.condominoForm.get('email_1').value) !== -1) {
          this.condominoForm.get('email_1').disable();
        }
        if (this.condominoForm.get('email_2').value && this.entityEmailsOficiais.findIndex(el => el.email === this.condominoForm.get('email_2').value) !== -1) {
          this.condominoForm.get('email_2').disable();
        }

        break;
      case 'inquilinos':
        this.inquilinoForm.reset();

        try {
          this.entityDetails.obj = JSON.parse(this.entityDetails.obj);
        } catch {
          this.entityDetails.obj = {
            tipo_pagamento: (this.entityDetails.obj.hasOwnProperty('tipo_pagamento')) ? this.entityDetails.obj.tipo_pagamento : null
          };
        }

        try {
          let addr = JSON.parse(this.entityDetails.morada);

          this.entityDetails['morada'] = (addr.hasOwnProperty('address')) ? addr.address : this.entityDetails.morada;
          this.entityDetails['postalCode'] = (addr.hasOwnProperty('address')) ? addr.postalCode : this.entityDetails.postalCode;
          this.entityDetails['locality'] = (addr.hasOwnProperty('locality')) ? addr.locality : this.entityDetails.locality;
        } catch {
          this.entityDetails['postalCode'] = null;
          this.entityDetails['locality'] = null;
        }

        try {
          let addr = JSON.parse(this.entityDetails.morada_fact);
          this.entityDetails['morada_fact'] = (addr.hasOwnProperty('address')) ? addr.address : this.entityDetails.morada_fact;
          this.entityDetails['postalCode_fact'] = (addr.hasOwnProperty('address')) ? addr.postalCode : this.entityDetails.postalCode_fact;
          this.entityDetails['locality_fact'] = (addr.hasOwnProperty('locality')) ? addr.locality : this.entityDetails.locality_fact;
        } catch {
          this.entityDetails['postalCode_fact'] = null;
          this.entityDetails['locality_fact'] = null;
        }

        this.inquilinoForm.get('com_geral').patchValue({
          carta: this.entityDetails.com_geral === 'C' || this.entityDetails.com_geral === 'A',
          email: this.entityDetails.com_geral === 'E' || this.entityDetails.com_geral === 'A',
        });
        this.inquilinoForm.get('com_avisos').patchValue({
          carta: this.entityDetails.com_avisos === 'C' || this.entityDetails.com_avisos === 'A',
          email: this.entityDetails.com_avisos === 'E' || this.entityDetails.com_avisos === 'A',
        });
        this.inquilinoForm.get('com_cartas_cobranca').patchValue({
          carta: this.entityDetails.com_cartas_cobranca === 'C' || this.entityDetails.com_cartas_cobranca === 'A',
          email: this.entityDetails.com_cartas_cobranca === 'E' || this.entityDetails.com_cartas_cobranca === 'A',
        });


        this.inquilinoForm.patchValue({
          nome: this.entityDetails.nome,

          morada: this.entityDetails.morada,
          postalCode: this.entityDetails.postalCode,
          locality: this.entityDetails.locality,

          morada_fact: this.entityDetails.morada_fact,
          postalCode_fact: this.entityDetails.postalCode,
          locality_fact: this.entityDetails.locality,
          
          n_contribuinte: this.entityDetails.n_contribuinte,
          telefone_1: this.entityDetails.telefone_1,
          telefone_2: this.entityDetails.telefone_2,
          obs: this.entityDetails.obs,
          limite_credito: (this.entityDetails.limite_credito) ? this.entityDetails.limite_credito : 0,
          dt_nascimento: this.entityDetails.dt_nascimento,
          profissao: this.entityDetails.profissao,
          email_1: this.entityDetails.email_1,
          email_2: this.entityDetails.email_2,
          banco: this.entityDetails.banco,
          n_conta: this.entityDetails.n_conta,
          nib: (this.entityDetails.nib && this.entityDetails.nib.indexOf('PT50') !== -1) ? this.entityDetails.nib : ((this.entityDetails.nib !== null && this.entityDetails.nib !== '') ? 'PT50' : '') + (this.entityDetails.nib ? this.entityDetails.nib : ''),
          referencia: this.entityDetails.referencia,
          bi_condomino: this.entityDetails.bi_condomino,
          matriculas: this.entityDetails.matriculas,
          conjuge: this.entityDetails.conjuge,
          bi_conjuge: this.entityDetails.bi_conjuge,
          dt_conjuge: this.entityDetails.dt_conjuge,
          n_contribuinte_conjuge: this.entityDetails.n_contribuinte_conjuge,
          estado_civil: this.entityDetails.estado_civil,
          forma_tratamento: this.entityDetails.forma_tratamento,
          tipo_documento: this.entityDetails.tipo_documento,
          id_procurador: this.entityDetails.id_procurador,
          id_tipo: this.entityDetails.id_tipo,
          tipo_pagamento: (this.entityDetails.obj) ? this.entityDetails.obj.tipo_pagamento : null,

          telefone_1_obs: this.entityDetails.telefone_1_obs,
          telefone_2_obs: this.entityDetails.telefone_2_obs,
          email_1_obs: this.entityDetails.email_1_obs,
          email_2_obs: this.entityDetails.email_2_obs,
        });
        break;
      case 'fornecedores':
        this.fornecedorForm.reset();

        try {
          let addr = JSON.parse(this.entityDetails.morada);

          this.entityDetails['morada'] = (addr.hasOwnProperty('address')) ? addr.address : this.entityDetails.morada;
          this.entityDetails['postalCode'] = (addr.hasOwnProperty('address')) ? addr.postalCode : this.entityDetails.postalCode;
          this.entityDetails['locality'] = (addr.hasOwnProperty('locality')) ? addr.locality : this.entityDetails.locality;
        } catch {
          this.entityDetails['postalCode'] = null;
          this.entityDetails['locality'] = null;
        }

        this.fornecedorForm.patchValue({
          nome: this.entityDetails.nome,

          morada: this.entityDetails.morada,
          postalCode: this.entityDetails.postalCode,
          locality: this.entityDetails.locality,

          n_contribuinte: this.entityDetails.n_contribuinte,
          telefone_1: this.entityDetails.telefone_1,
          telefone_2: this.entityDetails.telefone_2,
          comissao: this.entityDetails.comissao,
          obs: this.entityDetails.obs,
          email_1: this.entityDetails.email_1,
          email_2: this.entityDetails.email_2,
          forma_tratamento: this.entityDetails.forma_tratamento,
          nib: (this.entityDetails.nib && this.entityDetails.nib.indexOf('PT50') !== -1) ? this.entityDetails.nib : ((this.entityDetails.nib !== null && this.entityDetails.nib !== '') ? 'PT50' : '') + (this.entityDetails.nib ? this.entityDetails.nib : ''),
          id_tipo: this.entityDetails.id_tipo,
          obj: this.entityDetails.obj,

          telefone_1_obs: this.entityDetails.telefone_1_obs,
          telefone_2_obs: this.entityDetails.telefone_2_obs,
          email_1_obs: this.entityDetails.email_1_obs,
          email_2_obs: this.entityDetails.email_2_obs,
        });
        break;
      case 'procuradores':
        this.procuradorForm.reset();

        try {
          let addr = JSON.parse(this.entityDetails.morada);

          this.entityDetails['morada'] = (addr.hasOwnProperty('address')) ? addr.address : this.entityDetails.morada;
          this.entityDetails['postalCode'] = (addr.hasOwnProperty('address')) ? addr.postalCode : this.entityDetails.postalCode;
          this.entityDetails['locality'] = (addr.hasOwnProperty('locality')) ? addr.locality : this.entityDetails.locality;
        } catch {
          this.entityDetails['postalCode'] = null;
          this.entityDetails['locality'] = null;
        }

        this.procuradorForm.patchValue({
          nome: this.entityDetails.nome,
          n_contribuinte: this.entityDetails.n_contribuinte,
          telefone_1: this.entityDetails.telefone_1,
          telefone_2: this.entityDetails.telefone_2,
          comissao: this.entityDetails.comissao,
          obs: this.entityDetails.obs,
          email_1: this.entityDetails.email_1,
          email_2: this.entityDetails.email_2,
          forma_tratamento: this.entityDetails.forma_tratamento,
          nib: (this.entityDetails.nib && this.entityDetails.nib.indexOf('PT50') !== -1) ? this.entityDetails.nib : ((this.entityDetails.nib !== null && this.entityDetails.nib !== '') ? 'PT50' : '') + (this.entityDetails.nib ? this.entityDetails.nib : ''),
          id_tipo: this.entityDetails.id_tipo,
          morada: this.entityDetails.morada,
          postalCode: this.entityDetails.postalCode,
          locality: this.entityDetails.locality,
          obj: this.entityDetails.obj,

          telefone_1_obs: this.entityDetails.telefone_1_obs,
          telefone_2_obs: this.entityDetails.telefone_2_obs,
          email_1_obs: this.entityDetails.email_1_obs,
          email_2_obs: this.entityDetails.email_2_obs,
        });
        break;
      case 'outras-entidades':
        this.outraEntidadeForm.reset();

        try {
          let addr = JSON.parse(this.entityDetails.morada);

          this.entityDetails['morada'] = (addr.hasOwnProperty('address')) ? addr.address : this.entityDetails.morada;
          this.entityDetails['postalCode'] = (addr.hasOwnProperty('address')) ? addr.postalCode : this.entityDetails.postalCode;
          this.entityDetails['locality'] = (addr.hasOwnProperty('locality')) ? addr.locality : this.entityDetails.locality;
        } catch {
          this.entityDetails['postalCode'] = null;
          this.entityDetails['locality'] = null;
        }

        this.outraEntidadeForm.patchValue({
          nome: this.entityDetails.nome,

          morada: this.entityDetails.morada,
          postalCode: this.entityDetails.postalCode,
          locality: this.entityDetails.locality,

          n_contribuinte: this.entityDetails.n_contribuinte,
          telefone_1: this.entityDetails.telefone_1,
          telefone_2: this.entityDetails.telefone_2,
          comissao: this.entityDetails.comissao,
          obs: this.entityDetails.obs,
          email_1: this.entityDetails.email_1,
          email_2: this.entityDetails.email_2,
          forma_tratamento: this.entityDetails.forma_tratamento,
          nib: (this.entityDetails.nib && this.entityDetails.nib.indexOf('PT50') !== -1) ? this.entityDetails.nib : ((this.entityDetails.nib !== null && this.entityDetails.nib !== '') ? 'PT50' : '') + (this.entityDetails.nib ? this.entityDetails.nib : ''),
          id_tipo: this.entityDetails.id_tipo,
          obj: this.entityDetails.obj,

          telefone_1_obs: this.entityDetails.telefone_1_obs,
          telefone_2_obs: this.entityDetails.telefone_2_obs,
          email_1_obs: this.entityDetails.email_1_obs,
          email_2_obs: this.entityDetails.email_2_obs,
        });
        break;
    }
  }


  @ViewChild('emailOficialFracoesRef', { static: false }) emailOficialFracoesAlertRef;
  emailOficialFracoesModalRef = null;
  emailOficialFracoesConfig = null
  emailOficialFraccoes: EmailOficial[] = [];
  openEmailOficialFracoes(email: string) {
    this.emailOficialFraccoes = this.entityEmailsOficiais.filter(el => el.email === email);

    this.emailOficialFracoesModalRef = this.modalService
    .open(this.emailOficialFracoesConfig)
    .onApprove(() => { })
    .onDeny(() => { });
  }

  // Contencioso
  @ViewChild('deleteContenciosoAlertRef', { static: false }) deleteContenciosoAlertRef;
  deleteContenciosoModalRef = null;
  deleteContenciosoAlertConfig = null
  deletingModalDefault = false;

  deleteContenciosoForm = new FormGroup({
    delContenciosoRazao: new FormControl(null, [Validators.required, Validators.minLength(1)]),
  });  
  get delContenciosoRazao() { return this.deleteContenciosoForm.get('delContenciosoRazao'); }
  
  

  filterContenciosos = {
    showInativos: false,
    showRemovidos: false
  }

  sortContenciosoList() {
    this.fraccoesContencioso = this.fraccoesContenciosoOrig.filter(el => {
      if (el.active === '1' && el.estado === '1') return true;
      if (el.active === '0') return this.filterContenciosos.showRemovidos;
      if (el.estado === '0') return this.filterContenciosos.showInativos;
    });
    this.fraccoesContencioso.sort((a,b) => {
      let diff = this.utils.compareDayDates(a.dt_inicio, b.dt_inicio);
      if (diff === 0) diff = parseInt(a.cod_condominio) - parseInt(b.cod_condominio);
      if (diff === 0) diff = a.cod_fracao > b.cod_fracao ? 1 : -1;

      if (a.active === '0' || b.active === '0') {
        if (a.active === '0' && b.active === '0') return diff;
        if (a.active === '0') return 1;
        return -1;
      }

      if (a.estado === '0' || b.estado === '0') {
        if (a.estado === '0' && b.estado === '0') return diff;
        if (a.estado === '0') return 1;
        return -1;
      }
      
      return diff;
    });
  }

  checkContencioso(row:EstadoContenciosoList) {
    setTimeout(() => {
      if (row.active === '0') {
        row.checked = false;
        return;
      }
      let newValue = !row.checked;
      this.fraccoesContencioso.forEach(el => el.checked = false);
      row.checked = newValue;
    });
  }

  async deleteContenciosoAction() {
    let toDelete = this.fraccoesContencioso.find(el => el.checked);
    if (!toDelete) {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
      return;
    }
    this.fraccoesContencioso.forEach(el => el.checked = toDelete.id === el.id);

    let res = await this.presentDeleteContenciosoModal();
    if (!res) return;
    
    this.fraccoesContencioso = this.fraccoesContencioso.filter(el => el.id !== toDelete.id); 
    this.sortContenciosoList();
  }

  presentDeleteContenciosoModal(): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.deleteContenciosoModalRef = this.modalService
        .open(this.deleteContenciosoAlertConfig)
        .onApprove(() => { resolve(true) })
        .onDeny(() => { resolve(false) });
    });
  }

  deleteContencioso() {
    return new Promise(async (resolve) => {
      this.deleteContenciosoForm.markAllAsTouched();
      if (!this.deleteContenciosoForm.valid || this.delContenciosoRazao.value.trim() === '') {
        resolve(false);
        return;
      }
      let toDelete = this.fraccoesContencioso.find(el => el.checked);
      if (!toDelete) {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
        resolve(false);
        return;
      }
      this.fraccoesContenciosoOrig.forEach(el => el.checked = toDelete.id === el.id);
      
      this.deletingModalDefault = true;
      let success = await this.condominosService.deleteEstadoContencioso(toDelete.id, this.delContenciosoRazao.value);
      this.deletingModalDefault = false;

      if (!success) {
        resolve(false);
        return;
      }

      toDelete.active = '0';

      this.deleteContenciosoModalRef.approve();
      resolve(true);
    })
  }


  
  @ViewChild('contenciosoModal', { static: false }) contenciosoModal: ContenciosoModalComponent;
  async openContenciosoModal(row:EstadoContenciosoList) {
    let data = await this.contenciosoModal.open(this.entityCod, row? row.id : null);
    if (!data) return;

    let prevRowIndex = this.fraccoesContenciosoOrig.findIndex(el => el.id === data.id);
    if (prevRowIndex !== -1) {
      this.fraccoesContenciosoOrig[prevRowIndex] = {...data, checked:false};
    } else {
      let newRow: EstadoContenciosoList = {...data, 
        checked: false,
        cod_estado: data.cod_estado,
        nome_estado: data.nome_estado,
        dt_inicio: new Date(data.dt_inicio),
        obs: data.obs,
      };
      this.fraccoesContenciosoOrig.push(newRow);
    }
    this.sortContenciosoList();
  }

  @ViewChild('printDocumentsRef', { static: false }) printDocumentsRef;
  printDocumentsModalRef = null;
  printDocumentsConfigRef = null;
  printDocumentsModalOpened = false;
  printDocumentsData:{doc, id_fracao, data: Date, min_date: Date, max_date: Date, cod_fracao:string, feedback} = {
    doc: null,
    id_fracao: null,
    data: null,
    min_date: null,
    max_date: null,
    cod_fracao: null,
    feedback: {
      show: false,
      timeout: null,
    }
  };

  @ViewChild('modalDefault', { static: false }) modalDefault: ModalDefaultComponent;
  printDocumentsBtn() {
    this.printDocumentsData = {
      doc: null,
      id_fracao: null,
      data: null,
      min_date: null,
      max_date: new Date(),
      cod_fracao: null,
      feedback: {
        show: false,
        timeout: null,
      }
    };
    if (this.printDocumentsModalOpened) return;
    this.printDocumentsModalOpened = true;
    this.printDocumentsModalRef = this.modalService
    .open(this.printDocumentsConfigRef)
    .onApprove(() => {
      this.printDocumentsModalOpened = false 
      this.loadingPrintInfo = false;
    })
    .onDeny(() => { 
      this.printDocumentsModalOpened = false 
      this.loadingPrintInfo = false;
    });
  }

  isPrintDocumentsFormValueValid(key: string) {
    switch (key) {
      case 'doc':
        return !!this.printDocumentsData.doc;
      case 'data':
        if (!this.printDocumentsData.data) return false;
        if (this.printDocumentsData.min_date && this.utils.compareDayDates(this.printDocumentsData.min_date, this.printDocumentsData.data) > 0) return false;
        if (this.printDocumentsData.max_date && this.utils.compareDayDates(this.printDocumentsData.max_date, this.printDocumentsData.data) < 0) return false;
        return true;
      case 'id_fracao':
        return !!this.printDocumentsData.id_fracao
      case 'cod_fracao':
        return !!this.printDocumentsData.cod_fracao
      default:
        break;
    }
  }
  isPrintDocumentsFormValid(): boolean {
    if (!this.isPrintDocumentsFormValueValid('doc')) return false;
    switch (this.printDocumentsData.doc) {
      case '1':
        if (!this.isPrintDocumentsFormValueValid('id_fracao')) return false;
        if (!this.isPrintDocumentsFormValueValid('data')) return false;
        if (!this.isPrintDocumentsFormValueValid('cod_fracao')) return false;
        return true;
      default:
        break;
    }
    return false;
  }

  // @ViewChild('declaracaoDividaPDF', { static: false }) declaracaoDividaPDF: DeclaracaoDividaPdfComponent;
  @ViewChild('comunicar', { static: false }) comunicar: ComunicarComponent;
  async printDocuments() {
    this.utils.setFormFeedback(this.printDocumentsData.feedback);
    this.toastr.clear();
    if (!this.isPrintDocumentsFormValid()) {
      this.toastr.error(this.appConfig.errMsg.incorrectForm.msg, this.appConfig.errMsg.incorrectForm.title);
      return;
    }
    let success = false;
    switch (this.printDocumentsData.doc) {
      case '1':
        if (!this.printDocumentsData.id_fracao || !this.printDocumentsData.data) return;
        success = await this.printConfissaoDivida(this.printDocumentsData.id_fracao, this.printDocumentsData.data, this.printDocumentsData.cod_fracao);
        break;
    
      default:
        break;
    }
    if (success) this.printDocumentsModalRef.approve();
  }

  async printDocumentsChangedDoc() {
    let doc = this.printDocumentsData.doc ? this.condominosService.documentsToPrintOpts.find(el => el.value === this.printDocumentsData.doc) : null;
    if (!doc) return;
    if (doc.value === '1') {
      await this.modalDefault.open('Atenção', 'Verifique que todo os créditos se encontram liquidados e que não existem quotas extraordinárias aprovadas em ata, por lançar.',null, 'OK', null, 'small', null, null,false);
    }
  }

  printDocumentsChangedFracao() {
    let fraccaoChosen = this.fraccoesOpts.find(el => el.value === this.printDocumentsData.id_fracao);
    if (fraccaoChosen) {
      this.printDocumentsData.min_date = this.utils.getDate(fraccaoChosen.data.data_inicio, undefined, undefined, true);
      this.printDocumentsData.max_date = this.utils.getDate(fraccaoChosen.data.data_fim, undefined, undefined, true);
      if (!this.printDocumentsData.max_date) this.printDocumentsData.max_date = this.utils.getDate(new Date(), undefined, undefined, true);
      this.printDocumentsData.cod_fracao = fraccaoChosen.data.nome_fracao.split(' - ')[0];
    } else {
      this.printDocumentsData.min_date = null;
      this.printDocumentsData.max_date = this.utils.getDate(new Date(), undefined, undefined, true);
      this.printDocumentsData.cod_fracao = null;

    }
  }

  loadingPrintInfo = false;
  printConfissaoDivida(id_fracao, data_conteudo_comunicacao, cod_fracao): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.loadingPrintInfo = true;
      let confissaoDivida = await this.condominosService.getDeclaracaoDividaPDFInfo(this.entityCod, id_fracao, data_conteudo_comunicacao, cod_fracao);
      this.loadingPrintInfo = false;
      if (!confissaoDivida) {
        resolve(false);
        return;
      }
      resolve(true);
      let condominoInfo = confissaoDivida.condominoNoticeInfo;
      let letter: LetterToSend = {
        morada_condominio: condominoInfo.morada_condominio,
        morada_entidade: condominoInfo.morada_entidade,
        morada_fact_entidade: condominoInfo.morada_fact_entidade,
        nome_condominio: condominoInfo.nome_condominio,
        cod_condominio: condominoInfo.cod_condominio,
        nome_pagador: condominoInfo.nome_entidade,
        subject: confissaoDivida.subject,
        body: confissaoDivida.body,
        nif_condominio: condominoInfo.nif_condominio,
        cod_pagador: condominoInfo.cod_entidade,
        cod_fraccao: condominoInfo.cod_fraccao, 
        nome_fraccao: condominoInfo.nome_fraccao,
        id_texto_predefinido: undefined,
        attachments: [],
        data_divida: undefined,
        id_reconciliacao: undefined
      }
      let cartasToSend:Array<LetterToSend> = [letter];
      let cartaOptions: CartaOptions = {
        includeGreeting: false
      }
      let success = await this.comunicar.communicate('ENTITIES', cartasToSend, [], cartaOptions, {genDespesa: false, idRegAtividadeAction: '16'});
    })
  }

}