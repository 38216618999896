import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { BusinessLogicService } from '../business-logic.service';
import { RegistoAtividade, RegistoAtividadeSave } from '../business-model-interfaces/registo-atividade';
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})
export class RegistoAtividadeService {

  constructor(
    public api: ApiService,
    public utils: UtilitiesService,
    public toastr: ToastrService,
    public appConfig: AppConfigService
  ) { }


  saveRegistoAtividade(reg: RegistoAtividadeSave): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.saveRegistoAtividadeNew(reg).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }
}
