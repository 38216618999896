import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { ApiService } from '../api.service';
import { formatDate } from '@angular/common';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { UtilitiesService } from '../utilities.service';
import { getRelatorioReconciliacaoBancaria } from '../api-requests';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-reconciliacao-bancaria-relatorio',
  templateUrl: './reconciliacao-bancaria-relatorio.component.html',
  styleUrls: ['./reconciliacao-bancaria-relatorio.component.scss']
})
export class ReconciliacaoBancariaRelatorioComponent implements OnInit {



  @ViewChild('pdf', { static: false }) pdfController;

  @Input() data = {
    endDate: null,
    filterCriterio: null,
    filterOrdem: null
  };

  reconciliacaoData: Array<getRelatorioReconciliacaoBancaria['data']> = [];

  now: Date = null;
  filename: string = 'Reconciliacao_Bancaria';
  title: string = 'Reconciliação Bancária';
  format = 'dd-MM-yyyy';
  locale = 'pt-PT';
  

  constructor(public appConfig: AppConfigService,
              public cdRef: ChangeDetectorRef,
              public utils: UtilitiesService,
              public api: ApiService) { }

  ngOnInit() {
  }

  generatePdf() {
    return new Promise((resolve, reject) => {
      let datePT = formatDate(this.data.endDate, this.format, this.locale);
      let filename = this.filename + "_" + datePT;
  
      this.now = new Date();
      this.api.getRelReconcBancaria(this.data.endDate).subscribe(res => {
        if (res.success && res.data.length) {

          this.reconciliacaoData = res.data.reduce((r, a, i) => {
            a.data_rec = a.data_rec ? this.utils.getDate(a.data_rec) : null;
            if (!i || r[r.length - 1][0].cod_condominio !== a.cod_condominio) {
                return r.concat([[a]]);
            }
            r[r.length - 1].push(a);
            return r;
          }, []);

          if (this.data.filterCriterio === "PREDIO" && this.data.filterOrdem === "DESC") this.reconciliacaoData = this.reconciliacaoData.reverse();

          if (this.data.filterCriterio == "DATA") {
            if (this.data.filterOrdem == "ASC") {
              this.reconciliacaoData.forEach(cond => cond.sort((a, b) => {
                if (!a.data_rec)
                  return !b.data_rec ? 0 : 1;

                if (!b.data_rec)
                  return !a.data_rec ? 0 : -1;  
                
                return this.utils.compareDayDates(a.data_rec, b.data_rec);
              }));
              this.reconciliacaoData.sort((a, b) => {
                let a_principal = a.find(el => el.conta_principal === '1');
                let b_principal = b.find(el => el.conta_principal === '1');
                let aHasRec = !!a_principal && !!a_principal.data_rec;
                let bHasRec = !!b_principal && !!b_principal.data_rec;

                if (!aHasRec) {
                  return (!bHasRec) ? 0 : 1;
                }

                if (!bHasRec) {
                  return (!aHasRec) ? 0 : -1;  
                }

                return this.utils.compareDayDates(a_principal.data_rec, b_principal.data_rec);
              });

            } else {
              this.reconciliacaoData.forEach(cond => cond.sort((a, b) => {
                if (!a.data_rec)
                return !b.data_rec ? 0 : 1;

              if (!b.data_rec)
                return !a.data_rec ? 0 : -1;  

                return this.utils.compareDayDates(b.data_rec, a.data_rec);
              }));

              this.reconciliacaoData.sort((a, b) => {
                let a_principal = a.find(el => el.conta_principal === '1');
                let b_principal = b.find(el => el.conta_principal === '1');
                let aHasRec = !!a_principal && !!a_principal.data_rec;
                let bHasRec = !!b_principal && !!b_principal.data_rec;
                if (!aHasRec)
                  return !bHasRec ? 0 : 1;

                if (!bHasRec)
                  return !aHasRec ? 0 : -1;  

                return this.utils.compareDayDates(b_principal.data_rec, a_principal.data_rec);
              });
            }
          }
        }

        this.cdRef.detectChanges();

        this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
        this.pdfController.forceProxy = true;
        this.pdfController.proxyTarget = '_blank';
        this.pdfController.saveAs(filename + '.pdf');
        resolve(true)
      }, err => {
        reject(err)
      });
    });
  }

  generateXLS() {
    return new Promise((resolve, reject) => {
      let datePT = formatDate(this.data.endDate, this.format, this.locale);
      let filename = this.filename + "_" + datePT;
      
      let excelHeader = [{predio: 'Reconciliação Bancária', conta_bancaria: '', data_rec: '', valor_rec: ''},
                        {predio: 'Até ' + datePT, conta_bancaria: '', data_rec: '', valor_rec: ''},
                        {predio: 'Condomínio', conta_bancaria: 'Conta bancária', data_rec: 'Data Reconciliação', valor_rec: 'Valor reconciliado'}];
      let worksheet: XLSX.WorkSheet = null;

      this.api.getRelReconcBancaria(this.data.endDate).subscribe(res => {
        let excelContent = [];
        if (res.success && res.data.length) {
          let data = res.data.map(a => {
            a.data_rec = a.data_rec? this.utils.getDate(a.data_rec) : null;
            return a;
          });


          data.forEach(cond => {
            excelContent.push({predio: cond.cod_condominio + '-' + cond.nome_condominio, conta_bancaria: cond.banco? cond.banco : '', data_rec: cond.data_rec? cond.data_rec : '', valor_rec: cond.valor_rec? cond.valor_rec : ''});
          });

          if (this.data.filterCriterio === "PREDIO" && this.data.filterOrdem === "DESC") excelContent = excelContent.reverse();

          if (this.data.filterCriterio === "DATA") {
            if (this.data.filterOrdem === "ASC") {
              excelContent.sort((a,b) => {
                if (a.data_rec == '')
                  return b.data_rec == '' ? 0 : 1;

                if (b.data_rec == '')
                  return a.data_rec == '' ? 0 : -1;  
                let res = a.data_rec.getTime() - b.data_rec.getTime();
                return res;
              });
            } else {
              excelContent.sort((a,b) => {
                if (a.data_rec == '')
                  return b.data_rec == '' ? 0 : 1;

                if (b.data_rec == '')
                  return a.data_rec == '' ? 0 : -1;  
                let res = b.data_rec.getTime() - a.data_rec.getTime();
                return res;
              });
            }
          }
          
          excelContent = excelContent.map(el => {
            if (el.data_rec) {
              el.data_rec = formatDate(el.data_rec, this.format, this.locale);
            }
            return el
          });
        } 

        let excelData = excelHeader.concat(excelContent);
        
        worksheet = XLSX.utils.json_to_sheet(excelData, { header:['predio', 'conta_bancaria', 'data_rec', 'valor_rec'], skipHeader: true });
        let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let dataBlob: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});

        FileSaver.saveAs(dataBlob, filename + EXCEL_EXTENSION);
        resolve(true)
      }, err => {
        reject(err)
      });
    });
  }

}
