import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { ApiService } from '../api.service';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-recibos-anulados-relatorio',
  templateUrl: './recibos-anulados-relatorio.component.html',
  styleUrls: ['./recibos-anulados-relatorio.component.scss']
})
export class RecibosAnuladosRelatorioComponent implements OnInit {

  @Input() data = null;

  now: Date = null;
  filename: string = 'Recibos_Anulados';
  title: string = 'Recibos Anulados';
  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  recibosAnuladosData = [];

  @ViewChild('pdf', { static: false }) pdfController;

  constructor(public appConfig: AppConfigService,
    public api: ApiService) { }

  ngOnInit() {
  }

  clearData(timeout:number = 1500) {
    setTimeout(() => this.recibosAnuladosData = [], timeout);
  }

  updateData() {
    return new Promise((resolve, reject) => {
      this.api.getRecibosAnulados(this.data).subscribe(res => {
        if (res.success && res.data.length) {
          this.recibosAnuladosData = res.data;
          this.recibosAnuladosData = this.recibosAnuladosData.map(recibo => {
            let criado_por = (recibo.criado_por as string).split(" ");
            let removido_por = (recibo.removido_por as string).split(" ");
            recibo['criado_por'] = criado_por[0].charAt(0).toUpperCase() + criado_por[1].charAt(0).toUpperCase(), 
            recibo['removido_por'] = removido_por[0].charAt(0).toUpperCase() + removido_por[1].charAt(0).toUpperCase(), 
            recibo['tipo'] = recibo.banco? recibo.banco : 'CAIXA';
            recibo['cond'] = recibo.cod_condominio + " - " + recibo.nome_condominio
            return recibo;
          });
        }
        setTimeout(() =>{resolve(true)}, 500);
      }, err => {
        reject(err);
      });
    });
  }

  generatePdf() {
    return new Promise((resolve, reject) => {
      this.updateData().then(_ => {
        let datePT = formatDate(this.data, this.format, this.locale);
        let filename = this.filename + "_" + datePT;
        this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
        this.pdfController.forceProxy = true;
        this.pdfController.proxyTarget = '_blank';
        this.pdfController.saveAs(filename + '.pdf');
        resolve(true);
      }).catch(err => reject(err));
    })
  }

  export() {
    return this.pdfController.export();
  }
}
