import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../api.service';
import { UserSessionService } from '../user-session.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilitiesService } from '../utilities.service';

import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    username: new FormControl(null, { validators: Validators.required }),
    password: new FormControl(null, { validators: Validators.required }),
  });
  loginFormSubmitted: boolean = false;
  waitingRes: boolean = false; 
  unauthorized: boolean = false;
  unauthorizedSchedule: boolean = false;
  redirectUrl: string = ''; 
  
  constructor(public api: ApiService,
              public router: Router,
              public route: ActivatedRoute,
              public utils: UtilitiesService,
              public appConfig: AppConfigService,
              public message: MessageService,
              public cdRef: ChangeDetectorRef,
              public userSession: UserSessionService) {}

  ngOnInit() {
    this.redirectUrl = this.route.snapshot.queryParams['redirectUrl'] || '';

    if (this.userSession.getUserId()) { this.router.navigateByUrl(this.redirectUrl); }

  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode == 13) {
      this.login();
    }
  }

  loginDone(res) {
    this.api.setAuthHeader(res['data']['id']);
    this.userSession.setSession(res['data']);
    this.router.navigateByUrl(this.redirectUrl);
  }

  logginIn = false;
  login() {
    if (this.logginIn) return;

    this.loginFormSubmitted = true;
    this.unauthorized = false;
    this.unauthorizedSchedule = false;

    if (!this.loginForm.valid) return;

    let data = this.loginForm.getRawValue();
    this.waitingRes = true;

    this.logginIn = true;
    this.api.login(data.username, data.password).subscribe(res => {
      if (res.hasOwnProperty('success') && res['success']) {
        
        if (res.data.hasOwnProperty('utilizador_balcao') && res.data.utilizador_balcao === '1') {

          let resLogin = res;
          this.loginDone(resLogin);

          // LOGIN CAIXA VERTIS
          this.api.loginCaixa(data.username, data.password, '1').subscribe(res => {
            if (res['success'] && res.success) {
              this.userSession.setCaixaVertisState('login');

              // this.loginDone(resLogin);

              // REGISTO ACTIVIDADES API CALL
              let descricao = 'Login Caixa Vertis efectuado.';
              this.api.saveRegistoActividade(null, null, null, null, descricao).subscribe(res => {}, err => { });
            } else {
              this.utils.apiErrorMsg(res);
            }
          }, err => {});

        } else {
          this.loginDone(res);
        }

        this.checkIfHasAccessToMenuEntry();


        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'LOGIN_DONE' });

        // REGISTO ACTIVIDADES API CALL
        let descricao = 'Login efectuado.';
        this.api.saveRegistoActividade(null, null, null, null, descricao).subscribe(res => {}, err => { });

      } else {
        if (res.hasOwnProperty('status') && res.status === 'SCHEDULE_UNAUTHORIZED') {
          this.unauthorizedSchedule = true;
        } else {
          this.unauthorized = true;
        }
      }

      this.waitingRes = false;
      this.logginIn = false;
    }, err => {
      // Present login error message
      this.clear();
      this.waitingRes = false;
      this.logginIn = false;
    });
  }

  checkIfHasAccessToMenuEntry() {
    this.api.checkIfHasAccessToMenuEntry().subscribe(res => {
      if (res.success) {
        let sideMenuEntries = this.appConfig.getSideMenuEntries();
        res.data.forEach(el => {
          let module = sideMenuEntries.find(sideEntry => sideEntry.key === el.module);
          if (module) {
            module.canAccess = el.allowed;
          }
        });
      }
    }, err => {
    })
  }

  clear() {
    this.loginForm.reset();
    this.unauthorized = false;
    this.unauthorizedSchedule = false;
    this.loginFormSubmitted = false;
  }
}
