import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { CommunicationsService } from '../business-logic-services/communications.service';
import { RegistoAtividadeService } from '../business-logic-services/registo-atividade.service';
import { BusinessLogicService } from '../business-logic.service';
import { CartaContent } from '../business-model-interfaces/carta';
import { CommunicationsOrigin, RegistoComunicacaoSendAndSave } from '../business-model-interfaces/comunicacoes';
import { Anexo, Email } from '../business-model-interfaces/email';
import { RegistoAtividadeSave, TipoDoc } from '../business-model-interfaces/registo-atividade';
import { CartaComunicacaoComponent, CartaOptions } from '../carta-comunicacao/carta-comunicacao.component';
import { MetodoComunicacao } from '../condominos';
import { DespesasCttModalComponent, EntityDespesasCTTModalInput } from '../despesas-ctt-modal/despesas-ctt-modal.component';
import { MessageService } from '../message.service';
import { UserSessionService } from '../user-session.service';
import { UtilitiesService } from '../utilities.service';

export interface CommunicationOptions {
  genDespesa?:boolean,
  idRegAtividadeAction?: string
}

export interface LetterToSend {
  morada_condominio: string,
  morada_entidade: string,
  morada_fact_entidade: string,
  nome_condominio: string,
  cod_condominio
  nome_pagador
  subject
  body
  nif_condominio
  cod_pagador
  cod_fraccao
  nome_fraccao
  id_texto_predefinido,
  attachments: Array<Anexo>
  data_divida: Date
  id_reconciliacao
}

export interface EmailToSend {
  email:Email,
  cod_condominio: string,
  id_texto_predefinido: string,
  data_divida: string,
  email_list:RegistoComunicacaoSendAndSave['email_list']
}

@Component({
  selector: 'app-comunicar',
  templateUrl: './comunicar.component.html',
  styleUrls: ['./comunicar.component.scss']
})
export class ComunicarComponent implements OnInit {

  origin: CommunicationsOrigin;
  options: CommunicationOptions = null;

  constructor(public utils: UtilitiesService,
    public api: ApiService,
    public appConfig: AppConfigService,
    public message: MessageService,
    public cdRef: ChangeDetectorRef,
    public toastr: ToastrService,
    public businessLogic: BusinessLogicService,
    public userSession: UserSessionService,
    public communications: CommunicationsService,
    public regAtividade: RegistoAtividadeService,
  ) { }

  ngOnInit() {
  }


  communicate(origin: CommunicationsOrigin, cartas: Array<LetterToSend>, emails: Array<EmailToSend>, cartaOptions: CartaOptions = null, communicateOptions: CommunicationOptions = null): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.origin = origin;
      this.options = communicateOptions;
      if (!this.options) {
        this.options = {
          genDespesa: true, 
          idRegAtividadeAction: null
        }
      }
      let success = true;
      if (cartas) {
        success = await this.processCartas(cartas, cartaOptions);
        if (!success) {
          resolve(false);
          return;
        }
      }

      if (emails) {
        success = await this.processEmails(emails);
        if (!success) {
          resolve(false);
          return;
        }
      }
      resolve(true);
    });
  }

  async processCartas(byCarta: Array<LetterToSend>, cartaOptions: CartaOptions): Promise<boolean> {
    return new Promise(async (resolve) => {

      if (!byCarta.length) {
        resolve(true);
        return;
      }
      let temp:{
          status?: "OPENED";
          cttList?: any[];
          id_registo_ctt?: any[];
        } = null;
      if (!!this.options.genDespesa) {
          temp = await this.openDespesasCTTModal(byCarta);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
          if (!temp || (temp.hasOwnProperty('status') && temp['status'] === 'OPENED') || !temp.hasOwnProperty('cttList') || !temp.hasOwnProperty('id_registo_ctt')) {
            this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
            resolve(false);
            return;
        }
      }
  
      this.cartaComunicacaoInput = byCarta.map((el, i) => {
        let moradaCondominio = el.morada_condominio ? this.utils.moradaStrToList(el.morada_condominio) : [];
        let moradaEntidade = el.morada_fact_entidade ? this.utils.moradaStrToList(el.morada_fact_entidade) : [];
        if (!moradaEntidade.length) {
          moradaEntidade = this.utils.moradaStrToList(el.morada_entidade);
        }

        let carta: CartaContent = {
          condominioNome: el.nome_condominio,
          cod_condominio: el.cod_condominio,
          moradaCondominio: moradaCondominio,
          nomeEntidade: el.nome_pagador,
          moradaEntidade: moradaEntidade,
          tipo_correio: !!temp ? temp.cttList[i] : 'NORMAL',
          subjectMsg: el.subject,
          bodyMsg: el.body,
          n_contribuinte: el.nif_condominio
        }
        return carta;
      })
      
      this.cdRef.detectChanges();
      this.toastr.info(this.appConfig.infMsg.exportingPDFs.msg, this.appConfig.infMsg.exportingPDFs.title);
      this.cartaComunicacao.generatePdf(temp ? temp.id_registo_ctt : [], cartaOptions);

      let reg:RegistoAtividadeSave = {
        id_action: this.options.idRegAtividadeAction,
        cod_condominio: byCarta[0].cod_condominio,
        n_doc: byCarta[0].cod_pagador,
        descricao: 'Nome: ' + byCarta[0].nome_pagador,
      }
      this.regAtividade.saveRegistoAtividade(reg);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      resolve(true);
    });
  }

  @ViewChild('despesasCttModal', { static: false }) despesasCttModal: DespesasCttModalComponent;
  openDespesasCTTModal(entities: Array<LetterToSend>): Promise<{ status?: 'OPENED', cttList?: Array<any>, id_registo_ctt?: Array<any> }> {
    return new Promise(async (resolve) => {
      let base64 = await this.getLetterBase64Format(entities[0]);
      let despesasCTT: Array<EntityDespesasCTTModalInput> = await Promise.all(entities.map(async el => {
        let aux: EntityDespesasCTTModalInput = {
          cod: el.cod_pagador,
          nomeEntidade: el.nome_pagador,
          cod_condominio: el.cod_condominio,
          condominioNome: el.nome_condominio,
          cod_fraccao: el.cod_fraccao,
          nome_fraccao: el.nome_fraccao,
          tipo_entidade: 'PROPRIETARIO',
          base64FileArr: el.attachments.map(anexo => anexo.base64),
          id_texto_predefinido: el.id_texto_predefinido,
          data_divida: el.data_divida,
          id_reconciliacao: el.id_reconciliacao,
        }
        return aux;
      }));
      if (!despesasCTT.length) {
        resolve(null);
        return;
      }
      let despCttTitle = 'Envio Comunicação';
      switch (this.origin) {
        case 'CHANGE_CONTENCIOSO':
          despCttTitle = 'Envio Comunicação - Alteração Contencioso';
          break;
      
        default:
          break;
      }
      let res = await this.despesasCttModal.open(despesasCTT, despesasCTT[0].cod_condominio, despCttTitle, base64);
      resolve(res);
    })
  }
  @ViewChild('cartaComunicacao', { static: false }) cartaComunicacao: CartaComunicacaoComponent;
  cartaComunicacaoInput: Array<CartaContent> = [];
  getLetterBase64Format(el: LetterToSend, base64Anexos: Array<string> = []): Promise<string> {
    return new Promise(async resolve => {

      // HANDLE MORADA ENTIDADE
      let moradaCondominio = el.morada_condominio ? this.utils.moradaStrToList(el.morada_condominio) : [];
      let moradaEntidade = el.morada_entidade ? this.utils.moradaStrToList(el.morada_entidade) : [];
      let aux: CartaContent = {
        condominioNome: el.nome_condominio,
        cod_condominio: el.cod_condominio,
        moradaCondominio: moradaCondominio,
        nomeEntidade: el.nome_pagador,
        moradaEntidade: moradaEntidade,
        tipo_correio: 'SIMPLES',
        subjectMsg: el.subject,
        bodyMsg: el.body,
        n_contribuinte: el.nif_condominio,
      }
      this.cartaComunicacaoInput = [aux];
      this.cdRef.detectChanges();

      let base64PDF = await this.cartaComunicacao.getBase64();
      if (base64Anexos.length) {
        base64PDF = this.utils.cleanBase64Prefix(base64PDF);
        base64PDF = await this.businessLogic.mergePdf('Comunicar_Component_Merge', [base64PDF].concat(base64Anexos));
      }
      let base64 = this.utils.cleanBase64Prefix(base64PDF);
      resolve(base64);
    });
  }

  processEmails(byEmail: Array<EmailToSend>): Promise<boolean> {
    return new Promise(async (resolve) => {
      let registoComunicacaoAPI: Array<RegistoComunicacaoSendAndSave> = [];

      byEmail.forEach(el => {
        let email:Email = el.email

        let aux: RegistoComunicacaoSendAndSave = {
          email: email,
          cod_condominio: el.cod_condominio,
          assunto: email.subject,
          id_texto_predefinido: el.id_texto_predefinido,
          origem: this.origin,
          id_entidade: null,
          data_divida: el.data_divida,
          corpo: email.body,

          email_list: el.email_list,

          attachments: el.email.attachment,
        }

        registoComunicacaoAPI.push(aux);
      })

      let success = this.communications.sendAndSaveEmail(registoComunicacaoAPI);
      resolve(success);
    });
  }

}
