import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';


@Component({
  selector: 'app-contactos-details',
  templateUrl: './contactos-details.component.html',
  styleUrls: ['./contactos-details.component.scss']
})
export class ContactosDetailsComponent implements OnInit {

  transitionController = new TransitionController();
  submittingForm = false;
  loading = false;
  isCreate = false;
  searchable = true;

  selTab: string = null;

  condominioCod = null;
  contactoId = null;
  contactoCod = null;

  // GERAL FORM
  geralForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    email_1: new FormControl(null),
    email_2: new FormControl(null),
    email_3: new FormControl(null),
    forma_tratamento: new FormControl(null),
    morada: new FormControl(null),
    n_contribuinte: new FormControl(null),
    telefone_1: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    tipo_contacto: new FormControl(null),
    obs: new FormControl(null),
  });
  tipoContactoOpts = [];
  
  details: any = null;

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public router: Router,
              public route: ActivatedRoute,
              public api: ApiService,              
              public location: Location,
              public cdRef:ChangeDetectorRef,
              public appConfig: AppConfigService,
              public utils: UtilitiesService,
              public message: MessageService) { }

  ngOnInit() {
    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.isCreate = true;
    } else {
      this.contactoId = this.route.snapshot.params.id;
    }

    this.init();
  }

  init() {
    this.submittingForm = false;
    this.loading = false;
    this.condominioCod = this.route.snapshot.params.cond_id;

    if (this.isCreate) {

    } else {
      this.getDetails();
    }
    this.setTab('geral');
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  getDetails() {
    this.api.getContactoDetails(this.route.snapshot.params.id).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.details = res.data;
        this.contactoCod = res.data.cod;

        this.restoreForm();
      } else {}
    }, err => {});
  }

  setTab(targetTab) {
    this.selTab = targetTab;
  }

  restoreForm() {
    if (this.isCreate) {
      this.geralForm.reset();
    } else {
      this.geralForm.patchValue({
        nome: this.details.nome,
        email_1: this.details.email_1,
        email_2: this.details.email_2,
        email_3: this.details.email_3,
        forma_tratamento: this.details.forma_tratamento,
        morada: (this.details.morada) ? this.details.morada.replace(/NEW_LINE/g, '\n') : null,
        n_contribuinte: this.details.n_contribuinte,
        telefone_1: this.details.telefone_1,
        tipo_contacto: this.details.tipo_contacto,
        obs: (this.details.obs) ? this.details.obs.replace(/NEW_LINE/g, '\n') : null,
      });
    }
  }


  isNifValid = true;
  isPhoneValid = true;
  isEmailValid = true;
  formSubmitted() {
    this.submittingForm = true;

    this.isNifValid = true;
    this.isPhoneValid = true;
    this.isEmailValid = true;

    if (!this.geralForm.valid) return;

    let data = this.geralForm.getRawValue();

// CHECK Nº CONTRIBUINTE (n_contribuinte) - 9 digits
if (data.n_contribuinte && (!this.utils.isNumber(data.n_contribuinte.replace(/\s/g, '')) || data.n_contribuinte.replace(/\s/g, '').length !== 9)) {
  this.isNifValid = false;
  this.toastr.error('Por favor, verifique o numero de contribuinte introduzido.', 'Nº de Contribuinte Inválido', { timeOut: 4000 });
  setTimeout(() => { this.submittingForm = false; }, 4000);
  return;
}

// CHECK PHONE NUMBER (telefone_1) - 9 digits
if (data.telefone_1 && (!this.utils.isNumber(data.telefone_1.replace(/\s/g, '').replace('+', '')) || (data.telefone_1.replace(/\s/g, '').length !== 9 && data.telefone_1.replace(/\s/g, '').length !== 13))) {
  this.isPhoneValid = false;
  this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
  setTimeout(() => { this.submittingForm = false; }, 4000);
  return;
}

// CHECK EMAIL ADDRESS (email_1) - email pattern
if (data.email_1 && !this.utils.validateEmail(data.email_1)) {
  this.isEmailValid = false;
  this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
  setTimeout(() => { this.submittingForm = false; }, 4000);
  return;
}

    this.loading = true;
    this.api.saveContacto(this.contactoId, this.contactoCod, this.condominioCod, data.nome,data.email_1,data.email_2,data.email_3,data.forma_tratamento,data.morada,data.n_contribuinte,data.telefone_1,data.tipo_contacto,data.obs).subscribe(res => {
      if (res.hasOwnProperty('success') && res['success']) {
        this.location.back();
        } else {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        }
        this.submittingForm = false;
        this.loading = false;
      }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.submittingForm = false;
      this.loading = false;
    }); 
  }

}
