import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { AppStateService } from '../app-state.service';
import { ApiService } from '../api.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { UserSessionService } from '../user-session.service';
import { Router } from '@angular/router';

interface IContext {
  data:string;
}

@Component({
  selector: 'app-propostas',
  templateUrl: './propostas.component.html',
  styleUrls: ['./propostas.component.scss']
})
export class PropostasComponent implements OnInit {
  transitionController = new TransitionController();

  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'morada', name: 'Morada', type: null, sort: null, searchable: false, class:'' },
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: false, class:'' },
    { key: 'email', name: 'Email', type: 'number', sort: null, searchable: false, class:'' },
    { key: 'telefone', name: 'Telefone', type: 'text', sort: null, searchable: true, class:'' },
    { key: 'features', name: 'Carateristicas', type: 'text', sort: null, searchable: true, class:'' },
    { key: 'created_at', name: 'Data', type: 'date', sort: null, searchable: false, class:'col-centered' },
    { key: 'estado', name: '', type: 'text', sort: null, searchable: false, class:'col-centered' },
  ];
  list = [];
  listTotalLength = null;

  page = 1;
  selectedPage = 1;
  itemPerPage = 500;
  allSelected = false;
  keyword = null;

  apiSub = null;
  searching = false;
  fetchingData = false;
  deletingData = false;

  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('detailsAlertRef', { static: false }) detailsAlertRef;
  detailsModalRef = null;
  detailsAlertConfig: any = null;

  today = new Date();
  endDate = new Date(this.today.getFullYear(), 11, 31);
  startDate = new Date(this.today.getFullYear(), 0, 1);

  stateOpts = [
    { name: 'Não Lido', value: 'INIT' },
    { name: 'Lido', value: 'READ' },
    { name: 'Feito', value: 'DONE' },
    { name: 'Enviado', value: 'SENT' },
  ];

  constructor(public router: Router,
              public api: ApiService,
              public appState: AppStateService,
              public message: MessageService,
              public modalService: SuiModalService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public userSession: UserSessionService,
              public utils: UtilitiesService) {
  }

  ngOnInit() {
    this.animate();
    this.getPropostasList();
  }

  ngAfterViewInit() {
    fromEvent(this.tableSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.keyword = (val['target']['value']) ? val['target']['value'].toLowerCase().trim() : null;

      this.searching = true;
      this.getPropostasList();
    });

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closable = false;
    this.deleteAlertConfig.closeResult = 'closed';
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.detailsAlertConfig = new TemplateModalConfig<IContext, string, string>(this.detailsAlertRef);
    this.detailsAlertConfig.closable = false;
    this.detailsAlertConfig.closeResult = 'closed';
    this.detailsAlertConfig.size = 'small';
    this.detailsAlertConfig.transition = 'fade';
    this.detailsAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    if (this.apiSub) this.apiSub.unsubscribe();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    this.toastr.clear();
  }

  animate(transitionName:string='fade up') {
    this.transitionController.animate(new Transition(transitionName, 400, TransitionDirection.In));
  }

  getPropostasList() {
    return new Promise(resolve => {
      if (this.fetchingData && this.apiSub) this.apiSub.unsubscribe();
      this.fetchingData = true;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      this.apiSub = this.api.getPropostas(this.keyword, this.page, this.itemPerPage, this.startDate, this.endDate).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.list = res.data.proposals.map(el => {

            if (el.nFraccoes) el['features'] = el.nFraccoes + ' F, ';
            if (el.nLojas) el['features'] += el.nLojas + ' L, ';
            if (el.nGaragens) el['features'] += el.nGaragens + ' G, ';
            if (el.nElevadores) el['features'] += el.nElevadores + ' E, ';
            
            el.features = el.features.trim().substring(0, el.features.length - 2);

            el['checked'] = false;
            el['created_at'] = new Date(el.created_at);
    
            return el;
          });

          this.listTotalLength = res.data.total;
        } else {
          this.utils.apiErrorMsg(res);
          this.listTotalLength = null;
          this.list = [];
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.fetchingData = false;
        this.searching = false;

        resolve(true);
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.fetchingData = false;
        this.searching = false;

        resolve(false);
      });
    });
  }

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.list.map(el => el.checked = true ) : this.list.map(el => el.checked = false );
  }

  anoConstrucao = null;
  codPostal = null;
  email = null;
  estado = null;
  id = null;
  localidade = null;
  morada = null;
  nElevadores = null;
  nFraccoes = null;
  nGaragens = null;
  nLojas = null;
  nPisos = null;
  nome = null;
  obs = null;
  telefone = null;
  goToDetails(item) {
    this.anoConstrucao = item.anoConstrucao;
    this.codPostal = item.codPostal;
    this.email = item.email;
    this.estado = item.estado;
    this.id = item.id;
    this.localidade = item.localidade;
    this.morada = item.morada;
    this.nElevadores = item.nElevadores;
    this.nFraccoes = item.nFraccoes;
    this.nGaragens = item.nGaragens;
    this.nLojas = item.nLojas;
    this.nPisos = item.nPisos;
    this.nome = item.nome;
    this.obs = item.obs;
    this.telefone = item.telefone;

    this.detailsModalRef = this.modalService
      .open(this.detailsAlertConfig)
      .onApprove(() => {})
      .onDeny(() => {});
  }

  pageChange(ev) {
    this.page = ev;
    this.getPropostasList();
  }

  new() {
    this.router.navigate(['propostas/proposta', 'criar']);
  }

  async delete(submit=false) {
    if (submit) {
      let toDelete = this.list.filter(el => el.checked).map(el => { return { id: el.id }; });

      this.deletingData = true;
      this.api.deletePropostas(toDelete).subscribe(async res => {
        if (res.hasOwnProperty('success') && res.success) {
          let temp = await this.getPropostasList();
          if (temp) {
            this.allSelected = false;
            this.alertModalRef.approve();
          }
        } else {
          this.utils.apiErrorMsg(res);
          this.alertModalRef.deny();
        }
        this.deletingData = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        this.deletingData = false;
      });
    } else {
      let toDelete = this.list.filter(el => el.checked);
      if (toDelete.length > 0) {
        this.alertModalRef = this.modalService
          .open(this.deleteAlertConfig)
          .onApprove(() => {})
          .onDeny(() => {});
      } else {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
      }
    }
  }

  savingProposta = false;
  saveProposta() {
    this.savingProposta = true;

    let body = {
      anoConstrucao: this.anoConstrucao,
      codPostal: this.codPostal,
      email: this.email,
      estado: this.estado,
      id: this.id,
      localidade: this.localidade,
      morada: this.morada,
      nElevadores: this.nElevadores,
      nFraccoes: this.nFraccoes,
      nGaragens: this.nGaragens,
      nLojas: this.nLojas,
      nPisos: this.nPisos,
      nome: this.nome,
      obs: this.obs,
      telefone: this.telefone,
    }
    this.api.saveProposta(body).subscribe(res => {
      if (res.success) {
        let temp = this.list.find(el => el.id === this.id);
        if (temp) {
          temp.anoConstrucao = body.anoConstrucao;
          temp.codPostal = body.codPostal;
          temp.email = body.email;
          temp.estado = body.estado;
          temp.id = body.id;
          temp.localidade = body.localidade;
          temp.morada = body.morada;
          temp.nElevadores = body.nElevadores;
          temp.nFraccoes = body.nFraccoes;
          temp.nGaragens = body.nGaragens;
          temp.nLojas = body.nLojas;
          temp.nPisos = body.nPisos;
          temp.nome = body.nome;
          temp.obs = body.obs;
          temp.telefone = body.telefone;
        }
  
        this.detailsModalRef.approve();
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.savingProposta = false;
    }, err => {
      this.savingProposta = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

}


