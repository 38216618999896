import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent, forkJoin } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, FormsModule } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { exportPDF, Group } from '@progress/kendo-drawing';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';
import { agendamentoListApi } from '../api-requests';
import { BusinessLogicService } from '../business-logic.service';
import { CreditosService } from '../business-logic-services/creditos.service';
import { ModalDefaultComponent } from '../modal-default/modal-default.component';
import { PaymentForm, PaymentModalComponent, PaymentModalInput } from '../payment-modal/payment-modal.component';
import { Credito } from '../business-model-interfaces/creditos';
import { bit } from '../business-model-interfaces/application';
import { ProcessamentosService } from '../business-logic-services/processamentos.service';
import { Recibo } from '../business-model-interfaces/recibo';



export interface agendamentoList extends agendamentoListApi {
  checked:boolean,
  valor,
  metodo,
  byFornecedor?
  isInactive,
}


@Component({
  selector: 'app-lancamentos',
  templateUrl: './lancamentos.component.html',
  styleUrls: ['./lancamentos.component.scss']
})
export class LancamentosComponent implements OnInit {

  @ViewChild('avProcTableSearch', { static: false }) avProcTableSearch: ElementRef;
  avProcKeyword = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('alertRegComRef', { static: false }) alertRegComRef;
  alertRegComModalRef = null;
  alertRegComConfig: any = null;

  @ViewChild('deleteReciboAlertRef', { static: false }) deleteReciboAlertRef;
  alertReciboModalRef = null;
  deleteReciboAlertConfig: any = null;

  @ViewChild('contenciosoAlertRef', { static: false }) contenciosoAlertRef;
  contenciosoModalRef = null;
  contenciosoAlertConfig: any = null;

  @ViewChild('avisosFilterAlertRef', { static: false }) avisosFilterAlertRef;
  avisosFilterModalRef = null;
  avisosFilterAlertConfig: any = null;

  @ViewChild('creditoAlertRef', { static: false }) creditoAlertRef;
  creditoModalRef = null;
  creditoAlertConfig: any = null;

  @ViewChild('searchAlertRef', { static: false }) searchAlertRef;
  searchModalRef = null;
  searchAlertConfig: any = null;

  @ViewChild('advancedSearchAlertRef', { static: false }) advancedSearchAlertRef;
  advancedSearchModalRef = null;
  advancedSearchAlertConfig: any = null;

  // TABS DEFINITION ----------------------------------------------------------
  tabsObjDef: any = [
    { key: 'despesas', name: 'Despesas', url: 'despesa', active: true, disabled: false },
    { key: 'pag-quotas', name: 'Recebimentos', url: 'pag-quotas', active: false, disabled: false },
    { key: 'creditos', name: 'Créditos', url: 'creditos', active: false, disabled: false },
    { key: 'receitas-extra', name: 'Receitas Extra', url: 'receitas-extra', active: false, disabled: false },
    { key: 'recibos', name: 'Recibos', url: 'recibos', active: false, disabled: false },
    { key: 'avisos', name: 'Avisos', url: 'aviso', active: false, disabled: false },
    { key: 'processamentos', name: 'Processamentos', url: 'processamento', active: false, disabled: false },
    { key: 'agendamentos', name: 'Agendamentos', url: 'agendamento', active: false, disabled: false },
  ];
  selTabKey = 'despesas';

  menuDespesaHidden = false;
  menuQuotaPagHidden = true;
  menuCreditoHidden = true;
  menuReceitaHidden = true;
  menuRecibosHidden = true;
  menuAvProcHidden = true;

  // GLOBAL VARIABLES ----------------------------------------------------------
  toDelete = [];
  transitionController = new TransitionController();
  tapTransitionController = new TransitionController();
  loading = false;
  loadingModal = false;
  searchable = true;

  // FILTER VARIABLES ----------------------------------------------------------
  selCondominio = null;
  exercicio = null;
  exercicioOpts = [];

  // PROCESSAMENTOS TABLE VARIABLES
  processamentosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'data', name: 'Emissão', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0 two wide' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0 two wide' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'z-index-0' },
    { key: 'valor', name: 'Valor', type: 'text', sort: null, searchable: false, centered: true, class: 'z-index-0 col-align-right' },
  ];
  processamentosList: Array<any> = [];
  processamentosListLength: number = 0;
  processamentosPage: number = 1;
  processamentosItemPerPage: number = 20;
  processamentosKeyword: string = null;
  processamentosSortParam: string = null;
  processamentosSortDirection: string = null;
  processamentosSearching: boolean = false;

  // AVISOS TABLE VARIABLES
  avisosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'n_aviso', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0' },
    { key: 'dt_emissao', name: 'Data Emissão', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'cod_fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'cod_pagador', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'valor', name: 'Valor', type: 'text', sort: null, searchable: false, centered: true, class: 'z-index-0 col-align-right' },
    { key: 'debito', name: 'Liquidado', type: 'text', sort: null, searchable: false, centered: true, class: 'z-index-0 col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'z-index-0 col-align-right' },
  ];
  avisosList: Array<any> = [];
  avisosListLength: number = 0;
  avisosPage: number = 1;
  avisosItemPerPage: number = 20;
  avisosKeyword: string = null;
  avisosSortParam: string = null;
  avisosSortDirection: string = null;
  avisosSearching: boolean = false;

  // PROCESSAMENTO UPDATE FORM
  processamentoUpdateForm = new FormGroup({
    id: new FormControl(null),
  });

  // AVISO UPDATE FORM
  avisoUpdateForm = new FormGroup({
    id: new FormControl(null),
  });

  comp = 'lancamentos';
  initState = null;
  prevState = null;

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  // GLOBAL FILTER VARIABLES --------------------------------------------------
  endDate = new Date();
  startDate = new Date(this.endDate.getFullYear() - 1, 0, 1);
  // --------------------------------------------------------------------------

  // AGENDAMENTO FILTER VARIABLES --------------------------------------------------
  nowAgend = new Date();
  endDateAgend = new Date(this.nowAgend.getFullYear(), 11, 31);
  startDateAgend = new Date(this.endDateAgend.getFullYear() - 1, 0, 1);
  estadoAgendamentoFilter:'-1'|'0'|'1' = '1';
  // --------------------------------------------------------------------------

  // DESPESAS VARIABLES -------------------------------------------------------
  estadoSelected = this.appConfig.despesasEstadoOpts[0].value;
  keyword = null;
  fornecedoresOtps = [];
  fornecedorSelected = null;
  fornecedorSelectedAll = null;

  // DESPESAS TABLE VARIABLES
  despesasListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'n_despesa', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true },
    { key: 'nome_fornecedor', name: 'Fornecedor', type: 'text', sort: null, searchable: false, centered: false },
    { key: 'nome_rubrica', name: 'Rubrica', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'ref_interna', name: 'Ref.', type: 'text', sort: null, searchable: true, centered: false },
    { key: 'n_documento', name: 'Nº Doc.', type: 'text', sort: null, searchable: true, centered: true },
    { key: 'dt_desp', name: 'Data', type: 'text', sort: null, searchable: true, centered: true },
    { key: 'a_liquidar', name: 'A Liquidar', type: 'text', sort: null, searchable: false, centered: true, class: 'z-index-0 col-align-right' },
    { key: 'dt_pag', name: 'Pagamento', type: 'text', sort: null, searchable: false, centered: true, class: 'z-index-0' },
    { key: 'valor', name: 'Despesa', type: 'text', sort: null, searchable: false, centered: true, class: 'z-index-0 col-align-right' },

  ];
  despesaFornecedorCol = { key: 'nome_fornecedor', name: 'Fornecedor', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' };
  despesaCondominioCol = { key: 'nome_condominio', name: 'Condominio', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' };
  codCondominioCol = { key: 'cod_condominio', name: 'Condominio', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' };
  despesasList: Array<any> = [];
  despesasListOrig: Array<any> = [];
  despesasListLength = null;
  checkedHeader = null;

  valorTotal = 0;
  valorParcialTotal = 0;
  // -------------------------------------------------------------------------- 

  // DESPESAS VARIABLES -------------------------------------------------------
  // estadoSelected = this.appConfig.despesasEstadoOpts[0].value;
  // keyword = null;
  // fornecedoresOtps = [];
  // fornecedorSelected = null;
  // fornecedorSelectedAll = null;

  // DESPESAS AGENDAMENTO TABLE VARIABLES
  despesasAgendListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'n_agendamento', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0' },
    { key: 'nome_fornecedor', name: 'Fornecedor', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'nome_rubrica', name: 'Rubrica', type: 'text', sort: null, searchable: true, centered: false, class: 'z-index-0' },
    { key: 'start_date', name: 'Data Início', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered z-index-0' },
    { key: 'end_date', name: 'Data Fim', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered z-index-0' },
    { key: 'metodo', name: 'Método', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered z-index-0' },
    { key: 'periodicidade', name: 'Periodicidade', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered z-index-0' },
    { key: 'num_despesas_active', name: 'Ativas', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered z-index-0' },
    { key: 'valor', name: 'Despesa', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right z-index-0' },
  ];
  // despesaFornecedorCol = { key: 'nome_fornecedor', name: 'Fornecedor', type: 'text', sort: null, searchable: false, centered: false };
  // despesaCondominioCol = { key: 'nome_condominio', name: 'Condominio', type: 'text', sort: null, searchable: false, centered: false };
  despesasAgendList: Array<agendamentoList> = [];
  despesasAgendListOrig: Array<agendamentoList> = [];
  // despesasListLength = null;

  // valorTotal = 0;
  // valorParcialTotal = 0;
  // -------------------------------------------------------------------------- 

  // PAG. QUOTAS VARIABLES ----------------------------------------------------
  @ViewChild('condominioSelectDespesas', { static: false }) condominioSelectDespesas;


  allQuotas = true;
  filterDate = null;

  // AVISOS TABLE VARIABLES
  quotasListCol = [
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered z-index-0' },
    { key: 'n_aviso', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered z-index-0' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: false, centered: false, class: 'four wide z-index-0' },
    { key: 'cod_pagador', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: 'three wide z-index-0' },
    { key: 'dt_emissao', name: 'Emissão', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered z-index-0' },
    { key: 'dt_vencimento', name: 'Vencimento', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered z-index-0' },
    { key: 'valor', name: 'Por Liquidar', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right z-index-0' },
    { key: 'debito', name: 'A Liquidar', type: 'text', sort: null, searchable: false, centered: true, class: 'one wide col-align-right z-index-0' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide table-checkbox-column' },  // 'ASC', 'DESC'
  ];
  quotasList: Array<any> = [];
  quotasListOrig: Array<any> = [];

  fraccaoOpts = [];
  fraccaoSelected = null;

  // condominoOpts = [];

  contaOpts = [];
  contaOptsOrig = [];

  valorQuotasTotal = null;
  aLiquidarTotalToDate = null;
  aLiquidarTotal = null;
  aPagarTotal = null;

  quotaActual = null;
  // -------------------------------------------------------------------------- 

  // RECIBOS VARIABLES --------------------------------------------------------
  recibosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'n_recibo', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered  z-index-0' },
    { key: 'cod_fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'two wide col-centered z-index-0' },
    { key: 'nome_condomino', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered  z-index-0' },
    { key: 'criado_em', name: 'Data Criação', type: 'text', sort: null, searchable: true, centered: true, class: 'two wide col-centered  z-index-0' },
    { key: 'valor', name: 'Liquidado', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-centered  z-index-0' },
  ];

  recibosList: Array<any> = [];
  recibosListOrig: Array<any> = [];

  condominoOpts: Array<any> = [];
  selCondomino = null;

  recibosValorTotal = null;
  // --------------------------------------------------------------------------

  // CREDITOS VARIABLES --------------------------------------------------------
  creditosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'one wide table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'n_credito', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered z-index-0' },
    { key: 'cod_fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'one wide col-centered z-index-0' },
    { key: 'nome_condomino', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: ' z-index-0' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: false, centered: false, class: ' z-index-0' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'one wide col-centered z-index-0' },
    { key: 'valor', name: 'Valor', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right z-index-0' },
    { key: 'credito', name: 'Em Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'two wide col-align-right z-index-0' },
  ];

  creditosList: Array<any> = [];
  creditosListOrig: Array<any> = [];

  creditosCondominoOpts: Array<any> = [];
  creditosSelCondomino = null;

  creditosSelEstado = this.appConfig.creditosEstadoOpts[0].value;

  creditosValorTotal = null;
  creditosEmCreditoTotal = null;
  // --------------------------------------------------------------------------

  // RECEITAS VARIABLES -------------------------------------------------------
  receitasEstadoSelected = this.appConfig.despesasEstadoOpts[0].value;
  receitasFornecedoresOpts = [];
  receitasFornecedorSelected = null;

  // RECEITAS TABLE VARIABLES
  receitasListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column' },  // 'ASC', 'DESC'
    { key: 'n_receita', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0' },
    { key: 'nome_fornecedor', name: 'Fornecedor', type: 'text', sort: null, searchable: false, centered: false, class: 'z-index-0' },
    { key: 'nome_rubrica', name: 'Rubrica', type: 'text', sort: null, searchable: true, centered: false, class: 'z-index-0' },
    { key: 'ref_interna', name: 'Ref.', type: 'text', sort: null, searchable: true, centered: false, class: 'z-index-0' },
    { key: 'n_documento', name: 'Nº Doc.', type: 'text', sort: null, searchable: true, centered: true, class: 'z-index-0' },
    { key: 'dt_desp', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered z-index-0' },
    { key: 'a_liquidar', name: 'A Receber', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right z-index-0' },
    { key: 'dt_pag', name: 'Pagamento', type: 'text', sort: null, searchable: false, centered: true, class: 'col-centered z-index-0' },
    { key: 'valor', name: 'Receita', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right z-index-0' },
  ];

  receitasList: Array<any> = [];
  receitasListOrig: Array<any> = [];

  receitasValorTotal = 0;
  receitasValorParcialTotal = 0;
  // -------------------------------------------------------------------------- 

  clearEntry = { name: '-- limpar selecção --', value: '-1' };
  allCondo = { name: '-- Todos os condomínios --', value: '-2' };

  ASSearchEnabled = false;
  ASSearchBy = 'CONDOMINO';
  ASSelCondomino = null;
  ASSearch = null;
  ASSelCondominio = null;
  ASCondominiosOpts = [];
  ASCondominiosOptsOrig = [];
  loadingASSearch = false;

  estadoContOpts = [];
  estadoContencioso = {
    estado: null,
    data: null,
    obs: null,
  }

  // REPORT / RECIBO PDF VARIABLE
  @ViewChild('pdf', { static: false }) pdfController;
  @ViewChild('pdfEmailRecibo', { static: false }) pdfEmailReciboController;

  subsMsg = null;
  isZIndexZero = false;
  enabled = true;

  despesasListColPDF = [
    { key: 'n_despesa', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'nome_fornecedor', name: 'Fornecedor', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left col-xl' },
    { key: 'nome_rubrica', name: 'Rubrica', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-xl' },
    { key: 'dt_desp', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'a_liquidar', name: 'A Liquidar', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'dt_pag', name: 'Pagamento', type: 'text', sort: null, searchable: false, centered: true, class: 'col-centered col-sm' },
    { key: 'valor', name: 'Despesa', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];
  creditosListColPDF = [
    { key: 'n_credito', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'cod_fraccao', name: 'Fracção', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered' },
    { key: 'nome_condomino', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left col-xl' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left col-xl' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'valor', name: 'Valor', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'credito', name: 'Em Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];
  receitasListColPDF = [
    { key: 'n_receita', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'nome_fornecedor', name: 'Fornecedor', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left col-xl' },
    { key: 'nome_rubrica', name: 'Rubrica', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-xl' },
    { key: 'dt_desp', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'a_liquidar', name: 'A Receber', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'dt_pag', name: 'Pagamento', type: 'text', sort: null, searchable: false, centered: true, class: 'col-centered col-sm' },
    { key: 'valor', name: 'Receita', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];
  recibosListColPDF = [
    { key: 'n_recibo', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'nome_condomino', name: 'Condómino', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'criado_em', name: 'Data Criação', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'valor', name: 'Liquidado', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public route: ActivatedRoute,
              public router: Router,
              public userSession: UserSessionService,
              public api: ApiService,
              public cdRef:ChangeDetectorRef,
              public location: Location,
              public utils: UtilitiesService,
              public appState: AppStateService,
              public appConfig: AppConfigService,
              public businessLogic: BusinessLogicService,
              public processamentos: ProcessamentosService,
              public creditos: CreditosService,
              public message: MessageService) {

    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'CAIXA_VERTIS') {
        switch (msg.cmd) {
          case 'HIDE_INPUT':
            this.isZIndexZero = true;
            break;
          case 'SHOW_INPUT':
            this.isZIndexZero = false;
            break;
        }
      }
    });

    // GET GLOBAL STATE
    let globalState = this.appState.getGlobalState('global');
    if (globalState && globalState.hasOwnProperty('selCondominio')) {
      this.selCondominio = (globalState.selCondominio) ? { name: globalState.selCondominio.cod + ' - ' + globalState.selCondominio.nome, value: globalState.selCondominio } : null;

      this.setCodCondominio();
    }

    this.api.getEstadosContencioso().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.estadoContOpts = res.data.map(el => { return { name: el.nome, value: el.cod }; });
      } else {}
    }, err => {});
  }

  saveGlobalState() {
    if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) return;

    if (this.selCondominio && this.selCondominio !== '-1') {
      this.appState.saveGlobalState('global', { 
        selCondominio: { id: this.selCondominio.id, cod: this.selCondominio.cod, nome: this.selCondominio.nome, exercicio: this.selCondominio.exercicio },
      });
    } else {
      this.appState.clearGlobalState();
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.setPrevState();

    setTimeout(() => { 
      this.setTab(this.tabsObjDef.find(el => (el.active)).key);
    }, 1);

    this.animate();
  }

  ngAfterViewInit() {
    // MOVIMENTOS INPUT SEARCH
    fromEvent(this.avProcTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.getList(val['target']['value']);
    });
    
    this.contenciosoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.contenciosoAlertRef);
    this.contenciosoAlertConfig.closeResult = "closed";
    this.contenciosoAlertConfig.size = 'small';
    this.contenciosoAlertConfig.transition = 'fade';
    this.contenciosoAlertConfig.transitionDuration = 250;

    this.avisosFilterAlertConfig = new TemplateModalConfig<IContext, string, string>(this.avisosFilterAlertRef);
    this.avisosFilterAlertConfig.isClosable = false;
    this.avisosFilterAlertConfig.closeResult = "closed";
    this.avisosFilterAlertConfig.size = 'mini';
    this.avisosFilterAlertConfig.transition = 'fade';
    this.avisosFilterAlertConfig.transitionDuration = 250;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.alertRegComConfig = new TemplateModalConfig<IContext, string, string>(this.alertRegComRef);
    this.alertRegComConfig.closeResult = "closed";
    this.alertRegComConfig.size = 'small';
    this.alertRegComConfig.transition = 'fade';
    this.alertRegComConfig.transitionDuration = 250;

    this.deleteReciboAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteReciboAlertRef);
    this.deleteReciboAlertConfig.closeResult = "closed";
    this.deleteReciboAlertConfig.size = 'tiny';
    this.deleteReciboAlertConfig.transition = 'fade';
    this.deleteReciboAlertConfig.transitionDuration = 250;

    this.creditoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.creditoAlertRef);
    this.creditoAlertConfig.closeResult = "closed";
    this.creditoAlertConfig.size = 'tiny';
    this.creditoAlertConfig.transition = 'fade';
    this.creditoAlertConfig.transitionDuration = 250;

    this.searchAlertConfig = new TemplateModalConfig<IContext, string, string>(this.searchAlertRef);
    this.searchAlertConfig.closeResult = "closed";
    this.searchAlertConfig.size = 'small';
    this.searchAlertConfig.transition = 'fade';
    this.searchAlertConfig.transitionDuration = 250;

    this.advancedSearchAlertConfig = new TemplateModalConfig<IContext, string, string>(this.advancedSearchAlertRef);
    this.advancedSearchAlertConfig.isClosable = false;
    this.advancedSearchAlertConfig.closeResult = "closed";
    this.advancedSearchAlertConfig.size = 'small';
    this.advancedSearchAlertConfig.transition = 'fade';
    this.advancedSearchAlertConfig.transitionDuration = 250;

    this.loginCaixaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.loginCaixaAlertRef);
    this.loginCaixaAlertConfig.closeResult = "closed";
    this.loginCaixaAlertConfig.size = 'mini';
    this.loginCaixaAlertConfig.transition = 'fade';
    this.loginCaixaAlertConfig.transitionDuration = 250;

    this.alredyLoggedAlertConfig = new TemplateModalConfig<IContext, string, string>(this.alredyLoggedAlertRef);
    this.alredyLoggedAlertConfig.closeResult = "closed";
    this.alredyLoggedAlertConfig.size = 'mini';
    this.alredyLoggedAlertConfig.transition = 'fade';
    this.alredyLoggedAlertConfig.transitionDuration = 250;

    this.changeCVAlertConfig = new TemplateModalConfig<IContext, string, string>(this.changeCVAlertRef);
    this.changeCVAlertConfig.closeResult = "closed";
    this.changeCVAlertConfig.size = 'small';
    this.changeCVAlertConfig.transition = 'fade';
    this.changeCVAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });

    if (this.avisosFilterModalRef) this.avisosFilterModalRef.deny();
    if (this.despesasSubs) this.despesasSubs.unsubscribe();
    if (this.subsMsg) this.subsMsg.unsubscribe();
    if (this.searchSubs) this.searchSubs.unsubscribe();

    if (this.router.url.indexOf('movimentosbancarios') !== -1) {
      this.fraccaoSelected = null;

      this.setState('contas-bancarias');
      this.setState();
    }
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {

    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selCondominio); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
                } else {
                  return resolve([this.clearEntry]);
                }
              });
          }, 400);
        } else {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios('NULL').subscribe(res => {
              if (res.success) {
                return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
              } else {
                return resolve([this.clearEntry]);
              }
            });
          }, 400);
        }
    });
  };

  fornecedorTimer = null;
  fornecedoresLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.fornecedorSelectedAll); });
    }

    clearTimeout(this.fornecedorTimer);
    return new Promise(resolve => {
        if (query) {
          this.fornecedorTimer = setTimeout(() => {
            // FORNECEDOR LIST
            this.api.getAllFornecedores(query).subscribe(res => {
              if (res.success) {
                return resolve(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
              } else {
                return resolve([]);
              }
            });
          }, 400);
        } else {
          this.api.getAllFornecedores('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  condominosTimer = null;
  condominosLookup = async (query: string, initial?) => {
    clearTimeout(this.condominosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominosTimer = setTimeout(() => {
            // PROPRIETARIOS/INGUILINOS LIST
            this.api.getAllCondominos(query, 'proprietarios').subscribe(res => {
              if (res.success) {
                return resolve(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
              } else {
                return resolve([]);
              }
            });
          }, 400);
        } else {
          this.api.getAllCondominos('NULL', 'proprietarios').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: el.cod }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  condominioSelected() {
    this.fornecedorSelectedAll = null;

    if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) {
      this.selAllCondominio = true;
      return;
    } else {
      this.selAllCondominio = false;
    }

    if (this.selCondominio === '-1') {
      // RESET CONDOMINIO SELECTION
      this.selCondominio = null;

      // RESET PREVIOUS LISTS
      this.despesasList = [];
      this.quotasList = [];
      this.creditosList = [];
      this.receitasList = [];
      this.recibosList = [];
      this.avisosList = [];
      this.processamentosList = [];
      this.despesasAgendList = [];


      // RESET TO DEFAULT COLUM LIST (SELECT ALL FEATURE)
      this.despesasAgendListCol = this.despesasAgendListCol.filter(el => (el.key !== 'cod_condominio'));
      this.despesasListCol = this.despesasListCol.filter(el => (el.key !== 'cod_condominio'));
      this.creditosListCol = this.creditosListCol.filter(el => (el.key !== 'cod_condominio'));
      this.recibosListCol = this.recibosListCol.filter(el => (el.key !== 'cod_condominio'));
      this.receitasListCol = this.receitasListCol.filter(el => (el.key !== 'cod_condominio'));

      // RESET FRACCOES
      this.fraccaoSelected = null;
      this.fraccaoOpts = [];
    } else {
      this.fraccaoSelected = null;
      this.fraccaoOpts = [];
    }
  }

  searchResultList = [];
  searchSubs = null;
  searchSubmitted = false;
  getCondominiosASList() {
    this.searchSubmitted = true;
    if (!this.searchKeyword) return;

    if (this.searchKeyword.length < 4) {
      this.toastr.error('Introduza por favor um termo de pesquisa com um numero de caracteres superior a 3.', 'Alerta', { timeOut: 4000 });
      return;
    }
    
    if (this.ASSearchBy === 'CONDOMINO') {
      let cod = null;
      if (this.selCondominio && this.selCondominio.hasOwnProperty('cod') && this.selCondominio.cod) {
        cod = this.selCondominio.cod;
      } else if (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value.hasOwnProperty('cod') && this.selCondominio.value.cod) {
        cod = this.selCondominio.value.cod;
      }
      
      this.loadingASSearch = true;
      this.searchSubs = this.api.searchCondAndFraccao(this.searchKeyword, cod).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
  
          let aux = null;
          let condominios = [ ...new Set(res.data.map(item => item.cod_condominio)) ];
          let auxCondominios = [];
          this.searchResultList = [];
          condominios.forEach(el => {
            auxCondominios = res.data.filter(it => it.cod_condominio === el);
  
            aux = {
              cod_condominio: auxCondominios[0].cod_condominio,
              nome_condominio: auxCondominios[0].nome_condominio,
              fraccoes: [],
            }
  
            auxCondominios.forEach(it => {
              aux.fraccoes.push({
                cod_condomino: it.cod_condomino,
                cod_fraccao: it.cod_fraccao,
                nome_condomino: it.nome_condomino,
                nome_fraccao: it.nome_fraccao,
                id_condominio: it.id_condominio,
                cod_condominio: it.cod_condominio,
                exercicio_condominio: it.exercicio_condominio,
                nome_condominio: it.nome_condominio,
                checked: false,
              });
            });
  
            this.searchResultList.push(aux);
  
          });
          if (this.searchResultList.length === 1 && this.searchResultList[0].fraccoes.length === 1) this.searchResultList[0].fraccoes[0].checked = true;
        } else {
          this.searchResultList = [];
        }
        this.loadingASSearch = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loadingASSearch = false;
      });
    } else {
      this.loadingASSearch = true;
      this.api.getAllCondominiosBy(this.ASSearchBy, this.searchKeyword).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.searchResultList = [];
          res.data.forEach(el => {
            this.searchResultList.push({
              id_condominio: el.id_condominio,
              cod_condominio: el.cod,
              nome_condominio: el.nome,
              exercicio_condominio: el.exercicio_condominio,
              checked: false,
              nib: this.utils.getIBANFormatted(el.nib),
            });
          });
          if (this.searchResultList.length === 1) this.searchResultList[0].checked = true;
        } else {
          this.searchResultList = [];
        }
        this.loadingASSearch = false;
      }, err => { this.loadingASSearch = false; });
    }
  }

  searchCondominioSelected(item) {
    if (item.checked) {
      this.searchResultList.filter(el => el !== item).forEach(el => el.checked = false);
    }
  }

  AScondominioSelected() {
    if (this.ASSelCondominio === '-1') {
      // RESET ADVANCED SEARCH
      this.ASSearchEnabled = false;
      this.ASSearchBy = 'CONDOMINO';
      this.ASSelCondominio = null;
      this.ASSearch = null;
      this.ASSelCondominio = null;
      this.ASCondominiosOpts = [];

      // RESET FRACCOES SELECT
      this.fraccaoSelected = null;
      this.fraccaoOpts = [];
    } else {
      this.getList();
    }
  }

  searchKeyword = null;
  searchSelection = null;
  advancedSearch(targetList) {
    switch (targetList) {
      case 'recebimentos':
        this.searchKeyword = null;
        this.loadingASSearch = false;
        this.searchSelection = null;
        this.ASSearchBy = 'CONDOMINO';
        this.advancedSearchModalRef = this.modalService
          .open(this.advancedSearchAlertConfig)
          .onApprove(selection => {
            // SET CONDOMIDIO AND FRACCAO
            if (selection['cod_condominio'] && selection['nome_condominio']) {
              this.selCondominio = null;
              this.cdRef.detectChanges();
              // this.selCondominio = { name: selection['cod_condominio'] + ' - ' + selection['nome_condominio'], value: { cod: selection['cod_condominio'], nome: selection['nome_condominio'] } };
              if (this.ASSearchBy === 'CONDOMINO') {
                this.searchSelection = selection;
              } else {
                this.searchSelection = null;                
              }
              
              this.selCondominio = {
                id: selection['id_condominio'],
                cod: selection['cod_condominio'],
                nome: selection['nome_condominio'],
                exercicio: selection['exercicio_condominio'],
              }
              this.selCondominio = {...this.selCondominio, name: this.selCondominio.cod + ' - ' +  this.selCondominio.nome, value: this.selCondominio };
              this.saveGlobalState();
              this.getList();
            }
            

            this.searchKeyword = null;
            this.searchSubmitted = false;
            this.searchResultList = [];
          })
          .onDeny(() => {
            this.searchKeyword = null;
            this.searchSubmitted = false;
            this.searchResultList = [];
            if (this.searchSubs) this.searchSubs.unsubscribe();
          });
        break;
    }
  }

  clearPrevASResults() {
    this.searchResultList = [];
  }

  acceptSearchResult() {
    let selection = null;

    if (this.ASSearchBy === 'CONDOMINO') {
      for (let i = 0; i < this.searchResultList.length; i++) {
        selection = this.searchResultList[i].fraccoes.find(el => el.checked);
        if (selection) {
          selection['id_condominio'] = this.searchResultList[i]['id_condominio'];
          selection['cod_condominio'] = this.searchResultList[i]['cod_condominio'];
          selection['nome_condominio'] = this.searchResultList[i]['nome_condominio'];
          selection['exercicio_condominio'] = this.searchResultList[i]['exercicio_condominio'];
          break;
        }
      }
      if (!selection) {
        this.toastr.error('Nenhum resultado de pesquisa selecionado.', 'Alerta', { timeOut: 4000 });
        return;
      }
    } else {
      selection = this.searchResultList.find(el => el.checked);
      if (!selection) {
        this.toastr.error('Nenhum resultado de pesquisa selecionado.', 'Alerta', { timeOut: 4000 });
        return;
      }

    }

    this.advancedSearchModalRef.approve(selection);
  }

  getList(val=null) {
    switch (this.selTabKey) {
      case 'processamentos':
        if (val !== null) {
          this.processamentosKeyword = val;
          this.avProcKeyword = this.processamentosKeyword;
        }
        this.getProcessamentosList();
        break;
      case 'avisos':
        if (val !== null) {
          this.avisosKeyword = val;
          this.avProcKeyword = this.avisosKeyword;
        }
        this.getAvisosList();
        break;
      case 'despesas':
        if (this.fornecedorSelectedAll) {
          this.getDespesasByFornecedor();
        } else {
          this.getDespesas();
        }
        break;
      case 'pag-quotas':
        this.quotasList = [];

        this.quotasApiRequests.forEach(el => {
          el.unsubscribe();
        });
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.fetchingQuotas = false;
        this.quotasFetched = false;
        this.getQuotasList();
        this.getFraccaoList();
        this.getContasList();
        break;
      case 'creditos':
        this.getCreditosList();
        break;
      case 'receitas-extra':
        this.getReceitas();
        break;
      case 'recibos':
        this.getRecibosList();
        break;
      case 'agendamentos':
        if (this.fornecedorSelectedAll) {
          this.getDespesasAgendByFornecedor();
        } else {
          this.getAgendDespesas();
        }
        break;
    }
  }

  fetchingProcessamentos = false;
  isLancadoFilter:bit | -1 = -1;
  lancadoFilterOpts = [
    {name: 'Todos', value: -1},
    {name: 'Simulações', value: 0},
    {name: 'Lançados', value: 1},
  ];
  getProcessamentosList() {
    if (this.fetchingProcessamentos || !this.selCondominio) return;

    let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;
    
    if (!this.exercicio) this.exercicio = (new Date()).getFullYear().toString();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.fetchingProcessamentos = true;
    let lancadoFilter = this.isLancadoFilter == -1 ? null : this.isLancadoFilter;
    this.api.getProcessamentos(cod, this.exercicio, this.processamentosPage, this.processamentosItemPerPage, this.processamentosKeyword, this.processamentosSortParam, this.processamentosSortDirection, { isLancado: lancadoFilter }).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.exercicioOpts = [];
        res.exercicios.forEach(exercicio => {
          if (exercicio.ano !== null && exercicio.ano !== '0')
          this.exercicioOpts.push({ name: exercicio.ano, value: exercicio.ano });
        });
        if (!this.exercicioOpts.length) {
          this.exercicio = null;
        } else {
          if (!this.exercicioOpts.find(el => el.value == this.exercicio)) {
            this.exercicio = null;
          }
        }

        // PROCESSAMENTOS
        this.processamentosList = res.data;
        this.processamentosList.forEach(el => {
          el['data'] = (el['data']) ? this.utils.getDate(el['data']) : null;
          el['data_venc'] = (el['data_venc']) ? this.utils.getDate(el['data_venc']) : null;
          el['checked'] = false;
        });
        this.processamentosListLength = res.total;
        this.processamentosSearching = false;        
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.fetchingProcessamentos = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.fetchingProcessamentos = false;
      this.processamentosSearching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  fetchingAvisos = false;
  getAvisosList() {
    if (this.fetchingAvisos || !this.selCondominio) return;

    let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;

    if (!this.exercicio) this.exercicio = (new Date()).getFullYear().toString();
    
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });    
    this.fetchingAvisos = true;
    this.api.getAvisos(cod, this.exercicio, this.avisosPage, this.avisosItemPerPage, this.avisosKeyword, this.avisosSortParam, this.avisosSortDirection).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.exercicioOpts = [];
        res.exercicios.forEach(exercicio => {
          if (exercicio.ano !== null && exercicio.ano !== '0')
          this.exercicioOpts.push({ name: exercicio.ano, value: exercicio.ano });
        });        
        if (!this.exercicioOpts.length) {
          this.exercicio = null;
        } else {
          if (!this.exercicioOpts.find(el => el.value == this.exercicio)) {
            this.exercicio = null;
          }
        }

        // AVISOS
        this.avisosList = res.data;
        this.avisosList.forEach(el => {
          // el['saldo'] = Number(el['valor']) - Number(el['debito']);

          el['debito'] = Number(el['valor']) - Number(el['debito']);
          el['saldo'] = Number(el['valor']) - el['debito'];

          el['fraccao'] = el['cod_fraccao'] + ' - ' + el['fraccao_nome'];
          el['dt_emissao'] = (el['dt_emissao']) ? this.utils.getDate(el['dt_emissao']) : null;
          el['dt_vencimento'] = (el['dt_vencimento']) ? this.utils.getDate(el['dt_vencimento']) : null;
          el['checked'] = false;
        });
        this.avisosListLength = res.total;
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.fetchingAvisos = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.fetchingAvisos = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  setPrevState() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);

    if (this.prevState) {
      // this.selCondominio = (this.prevState.state.selCondominio) ? { name: this.prevState.state.selCondominio.cod + ' - ' + this.prevState.state.selCondominio.nome, value: this.prevState.state.selCondominio } : null;
      this.exercicio = (this.prevState.state.exercicio) ? this.prevState.state.exercicio : null;

      this.avisosPage = (this.prevState.state.avisosPage) ? this.prevState.state.avisosPage : null;
      this.avisosListLength = 1000000;
      this.avisosSortParam = (this.prevState.state.avisosSortParam) ? this.prevState.state.avisosSortParam : null;
      this.avisosSortDirection = (this.prevState.state.avisosSortDirection) ? this.prevState.state.avisosSortDirection : null;
      this.avisosKeyword = (this.prevState.state.avisosKeyword) ? this.prevState.state.avisosKeyword : null;

      this.processamentosPage = (this.prevState.state.processamentosPage) ? this.prevState.state.processamentosPage : null;
      this.processamentosListLength = 1000000;
      this.processamentosSortParam = (this.prevState.state.processamentosSortParam) ? this.prevState.state.processamentosSortParam : null;
      this.processamentosSortDirection = (this.prevState.state.processamentosSortDirection) ? this.prevState.state.processamentosSortDirection : null;
      this.processamentosKeyword = (this.prevState.state.processamentosKeyword) ? this.prevState.state.processamentosKeyword : null;

      this.fornecedorSelected = (this.prevState.state.fornecedorSelected) ? this.prevState.state.fornecedorSelected : null;
      this.startDate = (this.prevState.state.startDate) ? this.prevState.state.startDate : null;
      this.endDate = (this.prevState.state.endDate) ? this.prevState.state.endDate : new Date();
      this.estadoSelected = (this.prevState.state.estadoSelected) ? this.prevState.state.estadoSelected : this.appConfig.despesasEstadoOpts[0].value;
      this.fraccaoSelected = (this.prevState.state.fraccaoSelected) ? this.prevState.state.fraccaoSelected : null;

      this.creditosSelCondomino = (this.prevState.state.creditosSelCondomino) ? this.prevState.state.creditosSelCondomino : null;
      this.creditosSelEstado = (this.prevState.state.creditosSelEstado) ? this.prevState.state.creditosSelEstado : this.appConfig.creditosEstadoOpts[0].value;

      this.selCondomino = (this.prevState.state.selCondomino) ? this.prevState.state.selCondomino : null;
      this.allCondominios = (this.prevState.state.allCondominios) ? this.prevState.state.allCondominios : false;
      this.filterByCreatedAt = (this.prevState.state.filterByCreatedAt) ? this.prevState.state.filterByCreatedAt : false;
      this.verRemovidos = (this.prevState.state.verRemovidos) ? this.prevState.state.verRemovidos : false;
      this.verCreditosRemovidos = (this.prevState.state.verCreditosRemovidos) ? this.prevState.state.verCreditosRemovidos : false;

      this.fornecedorSelectedAll = (this.prevState.state.fornecedorSelectedAll) ? this.prevState.state.fornecedorSelectedAll : this.fornecedorSelectedAll;

      // SET ACTIVE TAB
      if (this.prevState.state.hasOwnProperty('activeTab') && this.prevState.state.activeTab !== null) {
        this.tabsObjDef.forEach(tab => {
          if (tab.key === this.prevState.state.activeTab.key) {
            tab.active = true;
            this.selTabKey = tab.key;
          } else {
            tab.active = false;
          }
        });
      }

      // this.prevState = this.appState.getPrevState(this.comp);
      this.appState.clearPrevState(this.comp);
    }
  }

  allCondominios = false;
  setState(comp=null) {
    if (!this.selCondominio) return;

    if (this.selCondominio || this.fornecedorSelectedAll) {
      if (comp === 'contas-bancarias') {
        this.appState.setPrevState(comp, { 
          condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
        });
      } else {
        this.appState.setPrevState(this.comp, {          
          activeTab: this.tabsObjDef.find(el => (el.key === this.selTabKey)),
          fornecedorSelectedAll: this.fornecedorSelectedAll,

          selCondominio: (this.selCondominio && this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
          exercicio: this.exercicio,
  
          processamentosPage: this.processamentosPage,
          processamentosSortParam: this.processamentosSortParam,
          processamentosSortDirection: this.processamentosSortDirection,
          processamentosKeyword: this.processamentosKeyword,
  
          avisosPage: this.avisosPage,
          avisosSortParam: this.avisosSortParam,
          avisosSortDirection: this.avisosSortDirection,
          avisosKeyword: this.avisosKeyword,
  
          // DESPESAS
          fornecedorSelected: this.fornecedorSelected,
          startDate: this.startDate,
          endDate: this.endDate,
          estadoSelected: this.estadoSelected,
  
          // RECEBIMENTOS
          fraccaoSelected: this.fraccaoSelected,
  
          // CREDITOS
          creditosSelCondomino: this.creditosSelCondomino,
          creditosSelEstado: this.creditosSelEstado,
  
          // RECIBOS
          selCondomino: this.selCondomino,
          allCondominios: this.allCondominios,
          filterByCreatedAt: this.filterByCreatedAt,
          verRemovidos: this.verRemovidos,
          verCreditosRemovidos: this.verCreditosRemovidos,
        });
      }
    }
  }

  goToDetails(entry, targetList, flag=null) {
    let obj = null;
    let descricao = null;

    switch (targetList) {
      case 'despesas':
        this.setState();

        this.router.navigate(['lancamentos/despesa', entry.id]);
    
        obj = { link: entry.id, cod: null };
        descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
        this.api.saveRegistoActividade(entry.cod_condominio, 'DESPESA', entry.n_despesa, 'Despesa Visualizada', descricao, obj).subscribe(res => {}, err => {});

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `DESPESA / ${entry.n_despesa}` });
        this.appState.openPaymentModal = (flag === 'A_LIQUIDAR' && entry.a_liquidar > 0);
        this.appState.goToMovimentos = (flag === 'MOVIMENTOS' && entry.dt_pag);
        break;
    case 'agendamentos':
      this.setState();

      this.router.navigate(['lancamentos/agendamento',entry.cod_condominio , entry.n_agendamento]);
  
      obj = { link: entry.n_agendamento, cod: null, cod_condominio: entry.cod_condominio };
      descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
      this.api.saveRegistoActividade(entry.cod_condominio, 'AGENDAMENTO', entry.n_agendamento, 'Agendamento Visualizado', descricao, obj).subscribe(res => {}, err => {});

      // Emit signal to breadcrumb component
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `AGENDAMENTO / ${entry.n_agendamento}` });
      break;
      case 'creditos':
        // SAVE THIS STATE
        this.setState();

        this.router.navigate(['lancamentos/credito', entry.id]);

        obj = { link: entry.id, cod: null };
        descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
        this.api.saveRegistoActividade(entry.cod_condominio, 'CREDITO', entry.n_credito, 'Crédito Visualizado', descricao, obj).subscribe(res => {}, err => {});

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `CRÉDITO / ${entry.cod}` });
        break;

      case 'receitas':
        // SAVE THIS STATE
        this.setState();

        this.router.navigate(['lancamentos/receita', entry.id]);

        obj = { link: entry.id, cod: null };
        descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
        this.api.saveRegistoActividade(entry.cod_condominio, 'RECEITA', entry.n_receita, 'Receita Visualizada', descricao, obj).subscribe(res => {}, err => {});

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RECEITA / ${entry.cod}` });
        break;

      case 'recibos':
        // SAVE THIS STATE
        this.setState();

        this.router.navigate(['lancamentos/recibo', entry.id]);

        obj = { link: entry.id, cod: null };
        descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
        this.api.saveRegistoActividade(entry.cod_condominio, 'RECIBO', entry.n_recibo, 'Recibo Visualizado', descricao, obj).subscribe(res => {}, err => {});

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RECIBO / ${entry.n_recibo}` });
        break;

      case 'processamentos':
        // SAVE THIS STATE
        this.setState();

        this.router.navigate(['lancamentos/processamento', entry.id]);

        obj = { link: entry.id, cod: null };
        descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
        this.api.saveRegistoActividade(entry.cod_condominio, 'PROCESSAMENTO', entry.cod, 'Processamento Visualizado', descricao, obj).subscribe(res => {}, err => {});

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROCESSAMENTO / ${entry.cod}` });
        break;
      case 'avisos':
        // SAVE THIS STATE
        this.setState();

        this.router.navigate(['lancamentos/aviso', entry.id]);

        obj = { link: entry.id, cod: null };
        descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
        this.api.saveRegistoActividade(entry.cod_condominio, 'PROCESSAMENTO', entry.n_aviso, 'Aviso Visualizado', descricao, obj).subscribe(res => {}, err => {});

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `AVISO / ${entry.n_aviso}` });
        break;
    }
  }

  filterList() {
    this.getProcessamentosList();
    this.getAvisosList();
  }

  pageChange(ev, targetList) {
    switch (targetList) {
      case 'processamentos':
        this.processamentosPage = ev;
        this.getProcessamentosList();
        break;
      case 'avisos':
        this.avisosPage = ev;
        this.getAvisosList();
        break;
    }
  }

  recibosToDelete: Array<Recibo> = [];
  presentAlert(recibos:Array<Recibo> = []) {
    this.recibosToDelete = recibos;
    if (this.selTabKey === 'recibos' || this.selTabKey === 'creditos') {
      this.alertReciboModalRef = this.modalService
      .open(this.deleteReciboAlertConfig)
      .onApprove(() => { this.loadingModal = false; this.toDelete = []; this.razaoReciboDelete = null; this.submittingFormRecibo = false; this.recibosToDelete = [] })
      .onDeny(() => { this.loadingModal = false; this.toDelete = []; this.razaoReciboDelete = null; this.submittingFormRecibo = false; this.recibosToDelete = [] });
    } else {
      this.deleteAlertConfig.size = (this.recibosToDelete.length > 0) ? 'normal' : 'mini';
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => { this.loadingModal = false; this.toDelete = [];this.toDeleteCttDespesas = []; this.delCriterio = 'DESPESA'; this.recibosToDelete = [] })
        .onDeny(() => { this.loadingModal = false; this.toDelete = [];this.toDeleteCttDespesas = []; this.delCriterio = 'DESPESA'; this.recibosToDelete = [] });
    }
  }

  presentDeleteRegComAlert():Promise<boolean> {
    return new Promise((resolve) => {
      this.alertRegComModalRef = this.modalService
      .open(this.alertRegComConfig)
      .onApprove(() => { 
        resolve(true);
      })
      .onDeny(() => {
        resolve(false);
      });
    });
  }

  submittingFormRecibo = false;
  razaoReciboDelete = null;
  delCriterio:'DESPESA'|'BOTH' = 'DESPESA';
  async del() {
    if (this.selTabKey !== 'recibos' && this.selTabKey !== 'creditos') this.loadingModal = true;

    switch (this.selTabKey) {
      case 'processamentos':
        let success = await this.processamentos.delete(this.toDelete.map(el => el.id));
        this.loadingModal = false;

        if (success) {
          // REGISTO DE ACTIVIDADE
          this.createActivityLog('PROCESSAMENTO', this.toDelete);

          this.processamentosList = this.processamentosList.filter(el => !el.checked);

          this.getProcessamentosList();
          this.alertModalRef.approve();
        }
        break;
      case 'avisos':
        this.api.delAvisos(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            // REGISTO DE ACTIVIDADE
            this.createActivityLog('AVISO', this.toDelete);

            this.avisosList = this.avisosList.filter(el => !el.checked);

            this.getAvisosList();

            // let req = [];
            // this.toDelete.forEach(el => {
            //   // REGISTO ACTIVIDADES API CALL
            //   let cod = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
            //   let nome = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;

            //   let titulo = 'Aviso Removido'
            //   let descricao = 'Condomínio: ' + cod + ' - ' + nome + ', Descrição: ' + el.descricao;
            //   req.push(this.api.saveRegistoActividade(cod, null, null, titulo, descricao));
            // });
            // forkJoin(req).subscribe(res => {}, err => { });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'despesas':

        let res = await this.areDespesasCtt(this.toDelete);
        if (!res) {
          this.alertModalRef.deny();
          return;
        }

        if (this.toDeleteCttDespesas.length) {
          if (!(await this.presentDeleteRegComAlert())) {
            this.alertModalRef.deny();
            return;
          }
        }


        this.toDelete.forEach(el => {
          if (Number(el.valor_liquidado) === 0) {
            el['estado'] = 'N_PAGO';
          } else if (Number(el.valor_liquidado) === Number(el.valor)) {
            el['estado'] = 'PAGO';
          } else {
            el['estado'] = 'PARCIAL';
          }
        });

        this.api.delDespesas(this.toDelete, 'DESPESAS', this.delCriterio).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            // REGISTO DE ACTIVIDADE
            this.createActivityLog('DESPESA', this.toDelete);

            this.toDelete.forEach(del => {
              this.despesasList = this.despesasList.filter(el => (el.id !== del.id));
              this.despesasListOrig = this.despesasListOrig.filter(el => (el.id !== del.id));
            });
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });

        break;
      case 'agendamentos':
        this.api.delAgendamentos(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {

            // REGISTO DE ACTIVIDADE
            this.createActivityLog('AGENDAMENTO', this.toDelete);

            let nome_condominio = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.nome : this.selCondominio.nome;

            // let req = [];
            // this.toDelete.forEach(el => {

            //   // REGISTO DE ACTIVIDADE - CREATE
            //   let titulo = 'Agendamento Removido';
            //   let descricao = `Condomínio: ${el.cod_condominio} - ${nome_condominio}, Nº agendamento:  ${el.n_agendamento}, Fornecedor: ${el.nome_fornecedor}.`;
            //   this.api.saveRegistoActividade(el.cod_condominio, null, null, titulo, descricao).subscribe(res => {}, err => { });  

            //   req.push(this.api.saveRegistoActividade(el.cod_condominio, null, null, titulo, descricao));
            // });
            // forkJoin(req).subscribe(res => {}, err => { });

            res.deleted.forEach(del => {
              this.despesasAgendList = this.despesasAgendList.filter(el => (el.id !== del));
              this.despesasAgendListOrig = this.despesasAgendListOrig.filter(el => (el.id !== del));
            });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });

        break;
      case 'recibos':

        this.submittingFormRecibo = true;
        setTimeout(() => {
          this.submittingFormRecibo = false;
        }, 4000);

        if (!this.razaoReciboDelete || this.razaoReciboDelete.trim() === '' ) return;

        this.loadingModal = true;
        this.toDelete.forEach(el => {
          el['del_razao'] = this.razaoReciboDelete;
          el['alterado_por'] = this.userSession.getUserFullName();
          el['alterado_em'] = new Date();
        });
        this.api.delRecibos(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.recibosList = this.recibosList.filter(el => !el.checked);

            // REGISTO DE ACTIVIDADE
            this.createActivityLog('RECIBO', this.toDelete);

            this.getList();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertReciboModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'creditos':
        this.submittingFormRecibo = true;
        setTimeout(() => {
          this.submittingFormRecibo = false;
        }, 4000);

        if (!this.razaoReciboDelete || this.razaoReciboDelete.trim() === '' ) return;

        this.loadingModal = true;
        this.toDelete.forEach(el => {
          el['del_razao'] = this.razaoReciboDelete;
          el['alterado_por'] = this.userSession.getUserFullName();
          el['alterado_em'] = new Date();
        });
        this.api.delCreditos(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.creditosList = this.creditosList.filter(el => !el.checked);

            // REGISTO DE ACTIVIDADE
            this.createActivityLog('CREDITO', this.toDelete);

            this.getList();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertReciboModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'receitas-extra':
        this.api.delReceitas(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.receitasList = this.receitasList.filter(el => !el.checked);

            // REGISTO DE ACTIVIDADE
            this.createActivityLog('RECEITA', this.toDelete);

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
    }
  }

  createActivityLog(type, deleteList) {
    let req = [];

    switch (type) {
      case 'AGENDAMENTO':
        deleteList.forEach(it => {
          let obj = { link: it.n_agendamento, cod: it.cod, cod_condominio: it.cod_condominio };
          let descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
          this.api.saveRegistoActividade(it.cod_condominio, 'AGENDAMENTO', it.n_agendamento, 'Agendamento Removido', descricao, obj).subscribe(res => {}, err => {});
        });
        break;
      case 'AVISO':
        deleteList.forEach(it => {
          let obj = { link: it.id, cod: null };
          let descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
          this.api.saveRegistoActividade(it.cod_condominio, 'AVISO', it.n_aviso, 'Aviso Removido', descricao, obj).subscribe(res => {}, err => {});
        });
        break;
      case 'PROCESSAMENTO':
        deleteList.forEach(it => {
          let obj = { link: it.id, cod: it.cod };
          let descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
          this.api.saveRegistoActividade(it.cod_condominio, 'PROCESSAMENTO', it.cod, 'Processamento Removido', descricao, obj).subscribe(res => {}, err => {});
        });
        break;
      case 'CREDITO':
        deleteList.forEach(it => {
          let obj = { link: it.id, cod: it.cod };
          let descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
          this.api.saveRegistoActividade(it.cod_condominio, 'CREDITO', it.n_credito, 'Crédito Removido', descricao, obj).subscribe(res => {}, err => {});
        });
        break;
      case 'DESPESA':
        deleteList.forEach(it => {
          let obj = { link: it.id, cod: it.cod };
          let descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
          this.api.saveRegistoActividade(it.cod_condominio, 'DESPESA', it.n_despesa, 'Despesa Removida', descricao, obj).subscribe(res => {}, err => {});
        });
        break;
      case 'RECEITA':
        deleteList.forEach(it => {
          let obj = { link: it.id, cod: it.cod };
          let descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
          this.api.saveRegistoActividade(it.cod_condominio, 'RECEITA', it.n_receita, 'Receita Extra Removida', descricao, obj).subscribe(res => {}, err => {});
        });
        break;
      case 'RECIBO':
        deleteList.forEach(it => {
          let obj = { link: it.id, cod: it.cod };
          let descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
          this.api.saveRegistoActividade(it.cod_condominio, 'RECIBO', it.n_recibo, 'Recibo Removido', descricao, obj).subscribe(res => {}, err => {});
        });
        break;
    }

    forkJoin(req).subscribe(res => {}, err => {});
  }

  handleVisibleMenu(targetList) {
    switch (targetList) {
      case 'processamentos':
        this.menuAvProcHidden = false;
        this.menuDespesaHidden = true;
        this.menuQuotaPagHidden = true;
        this.menuRecibosHidden = true;
        this.menuCreditoHidden = true;
        this.menuReceitaHidden = true;
        break;
      case 'avisos':
        this.menuAvProcHidden = false;
        this.menuDespesaHidden = true;
        this.menuQuotaPagHidden = true;
        this.menuRecibosHidden = true;
        this.menuCreditoHidden = true;
        this.menuReceitaHidden = true;
        break;
      case 'agendamentos':
      case 'despesas':
        this.menuAvProcHidden = true;
        this.menuDespesaHidden = false;
        this.menuQuotaPagHidden = true;
        this.menuRecibosHidden = true;
        this.menuCreditoHidden = true;
        this.menuReceitaHidden = true;
        break;
      case 'pag-quotas':
        this.menuAvProcHidden = true;
        this.menuDespesaHidden = true;
        this.menuQuotaPagHidden = false;
        this.menuRecibosHidden = true;
        this.menuCreditoHidden = true;
        this.menuReceitaHidden = true;
        break;
      case 'creditos':
        this.menuAvProcHidden = true;
        this.menuDespesaHidden = true;
        this.menuQuotaPagHidden = true;
        this.menuRecibosHidden = true;
        this.menuCreditoHidden = false;
        this.menuReceitaHidden = true;
        break;
      case 'receitas-extra':
        this.menuAvProcHidden = true;
        this.menuDespesaHidden = true;
        this.menuQuotaPagHidden = true;
        this.menuRecibosHidden = true;
        this.menuCreditoHidden = true;
        this.menuReceitaHidden = false;
        break;
      case 'recibos':
        this.menuAvProcHidden = true;
        this.menuDespesaHidden = true;
        this.menuQuotaPagHidden = true;
        this.menuRecibosHidden = false;
        this.menuCreditoHidden = true;
        this.menuReceitaHidden = true;
        break;
    }
  }

  selAllCondominio = false;
  setTab(targetList) {
    this.selTabKey = targetList;

    // this.selAllCondominio = (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2'));

    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'GET_TOP_BAR_INFO' });

    switch (targetList) {
      case 'processamentos':
        if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) this.selCondominio = null;

        this.avProcKeyword = this.processamentosKeyword;

        this.location.replaceState('/lancamentos/processamento/');
        break;
      case 'avisos':
        if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) this.selCondominio = null;

        this.avProcKeyword = this.avisosKeyword;

        this.location.replaceState('/lancamentos/aviso/');
        break;
      case 'despesas':
        // if (this.selAllCondominio) this.selCondominio = this.allCondo;
        if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) this.selCondominio = null;

        this.location.replaceState('/lancamentos/despesa/');
        break;
      case 'pag-quotas':
        if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) this.selCondominio = null;

        this.location.replaceState('/lancamentos/pag-quotas/');
        break;
      case 'creditos':
        // if (this.selAllCondominio) this.selCondominio = this.allCondo;
        if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) this.selCondominio = null;

        this.location.replaceState('/lancamentos/creditos/');
        break;
      case 'receitas-extra':
        // if (this.selAllCondominio) this.selCondominio = this.allCondo;
        if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) this.selCondominio = null;

        this.location.replaceState('/lancamentos/receitas-extra/');
        break;
      case 'recibos':
        // if (this.selAllCondominio) this.selCondominio = this.allCondo;
        if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) this.selCondominio = null;

        this.location.replaceState('/lancamentos/recibos/');
        break;
      case 'agendamentos':
        if (this.selAllCondominio) this.selCondominio = this.allCondo;

        this.location.replaceState('/lancamentos/agendamentos/');
        break;
    }

    this.fraccaoSelected = null;
    this.quotasList = [];

    if (this.ASSearchEnabled) {
      this.ASSelCondominio = null;
      this.ASCondominiosOpts = [];
      this.ASSearchEnabled = false;

      this.selCondominio = '-1';
      this.condominioSelected();
    }

    this.handleVisibleMenu(targetList);
    if (this.selCondominio) this.getList();
  }

  toDeleteCttDespesas = [];
  areDespesasCtt(despesas:Array<any>): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.areDespesasCtt(despesas).subscribe(res => {
        if (res.success && res.data) {
          this.toDeleteCttDespesas = res.data
          resolve(true)
        } else {
          this.utils.apiErrorMsg(res);
          reject(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(false);
      });
    });
  }

  async tableAction(action) {
    if (this.selTabKey === 'despesas') {
      switch (action) {
        case 'csv': break;
        case 'pdf': break;
        case 'print': break;
        case 'add':
          // SAVE THIS STATE
          this.setState();
  
          // FORWARD INIT STATE FOR NEXT COMPONENT
          if (this.selCondominio) {
            this.appState.setInitState('despesa', {
              condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
            });
          }
  
          this.router.navigate(['lancamentos/despesa', 'criar']);
  
          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVA DESPESA` });
          break;
        case 'add-despesa-lote':
          this.router.navigate(['lancamentos/despesa', 'criar-lote']);
  
          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVO LOTE DE DESPESAS` });
          break;
  
        case 'add-despesa-agendamento':
          // SAVE THIS STATE
          this.setState();
  
          // FORWARD INIT STATE FOR NEXT COMPONENT
          if (this.selCondominio) {
            this.appState.setInitState('despesa', {
              condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
            });
          }
  
          this.router.navigate(['lancamentos/despesa', 'criar-agendamento']);
  
          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVO AGENDAMENTO DE DESPESAS` });
          break;
        case 'add-despesa-correspondencia':
          // SAVE THIS STATE
          this.setState();

          // FORWARD INIT STATE FOR NEXT COMPONENT
          if (this.selCondominio) {
            this.appState.setInitState('despesa', {
              condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
            });
          }

          this.router.navigate(['lancamentos/despesa', 'criar-correspondencia']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVA DESPESA DE CORRESPONDÊNCIA` });
          break;
        case 'delete':
          this.toDelete = this.despesasList.filter(el => el.checked);

          if (this.toDelete.length > 0) {
            this.presentAlert();
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }

    if (this.selTabKey === 'agendamentos') {
      switch (action) {
        case 'csv': break;
        case 'pdf': break;
        case 'print': break;
        case 'add':
          // SAVE THIS STATE
          this.setState();

          // FORWARD INIT STATE FOR NEXT COMPONENT
          if (this.selCondominio) {
            this.appState.setInitState('despesa', {
              condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
            });
          }
  
          this.router.navigate(['lancamentos/despesa', 'criar-agendamento']);
  
          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVO AGENDAMENTO DE DESPESAS` });
          break;
        case 'delete':
          this.toDelete = this.despesasAgendList.filter(el => el.checked);
          if (this.toDelete.length > 0) {
            this.presentAlert();
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }

    if (this.selTabKey === 'creditos') {
      switch (action) {
        case 'csv': break;
        case 'pdf': break;
        case 'print': break;
        case 'add':
          // SAVE THIS STATE
          this.setState();
  
          // FORWARD INIT STATE FOR NEXT COMPONENT
          if (this.selCondominio) {
            this.appState.setInitState('credito', {
              condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
            });
          }
  
          this.router.navigate(['lancamentos/credito', 'criar']);
  
          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVO CREDITO` });
          break;
        case 'delete':
          this.toDelete = this.creditosList.filter(el => el.checked);
          if (this.toDelete.length > 0) {
            this.presentAlert();
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }

    if (this.selTabKey === 'receitas-extra') {
      switch (action) {
        case 'csv': break;
        case 'pdf': break;
        case 'print': break;
        case 'add':
          // SAVE THIS STATE
          this.setState();
  
          // FORWARD INIT STATE FOR NEXT COMPONENT
          if (this.selCondominio) {
            this.appState.setInitState('receita', {
              condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
            });
          }
  
          this.router.navigate(['lancamentos/receita', 'criar']);
  
          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `NOVA RECEITA EXTRAORDINÁRIA` });
          break;
        case 'delete':
          this.toDelete = this.receitasList.filter(el => el.checked);
          if (this.toDelete.length > 0) {
            this.presentAlert();
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }

    if (this.selTabKey === 'recibos') {
      switch (action) {
        case 'csv': break;
        case 'pdf': break;
        case 'print': break;
        case 'add':
          this.router.navigate(['lancamentos/processamento', 'criar']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.tabsObjDef.find(el => (el.active)).name.toUpperCase()} / NOVO PROCESSAMENTO` });
          break;
        case 'delete':
          this.toDelete = this.recibosList.filter(el => el.checked);
          if (this.toDelete.length > 0) {
            this.presentAlert();
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }

    if (this.selTabKey === 'processamentos') {
      switch (action) {
        case 'csv': break;
        case 'pdf': break;
        case 'print': break;
        case 'add-processamento-pontual':
          // SAVE THIS STATE
          this.setState();
  
          // FORWARD INIT STATE FOR NEXT COMPONENT
          if (this.selCondominio) {
            this.appState.setInitState('processamento', {
              condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
            });
          }

          this.router.navigate(['lancamentos/processamento', 'criar']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.tabsObjDef.find(el => (el.active)).name.toUpperCase()} / NOVO PROCESSAMENTO` });
          break;
        case 'add-processamento-penalizacoes':

          // let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;
          // this.api.setProcessamentosPenalizacoes(cod).subscribe(res => {
          //   console.log('RESPONSE');
          //   console.log(res);
          // }, err => {
          //   console.log('ERROR');
          //   console.log(err);
          // });

          break;
        case 'delete':
          this.toDelete = this.processamentosList.filter(el => el.checked);
          if (this.toDelete.length > 0) {
            try {
              let recibos = await this.processamentos.getRecibos(this.toDelete.map(el => el.cod));
              this.presentAlert(recibos);
            } catch (err) {
              break;
            }
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }

    if (this.selTabKey === 'avisos') {
      switch (action) {
        case 'csv': break;
        case 'pdf': break;
        case 'print': break;
        case 'add':
          // SAVE THIS STATE
          this.setState();
  
          // FORWARD INIT STATE FOR NEXT COMPONENT
          if (this.selCondominio) {
            this.appState.setInitState('aviso', {
              condominioSelected: (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value : this.selCondominio,
            });
          }

          this.router.navigate(['lancamentos/aviso', 'criar']);

          // Emit signal to breadcrumb component
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.tabsObjDef.find(el => (el.active)).name.toUpperCase()} / NOVO AVISO` });
          break;
        case 'delete':
          this.toDelete = this.avisosList.filter(el => el.checked);
          if (this.toDelete.length > 0) {
            this.presentAlert();
          } else {
            this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
          }
          break;
      }
    }
  }

  rowSelectionToggle(ev, targetList) {
    if (targetList === 'despesas') {
      (ev.target.checked) ? this.despesasList.map(el => el.checked = true ) : this.despesasList.map(el => el.checked = false );
    }
    if (targetList === 'pag-quotas') {
      if (ev.target.checked) {
        this.quotasList.map(aviso => {
          aviso.checked = true;
          aviso.debito = aviso.valor;
        });
      } else {
        this.quotasList.map(aviso => {
          aviso.checked = false; 
          aviso.debito = 0.00;
        });
      }
  
      this.computeQuotasTotals();
    }

    if (targetList === 'recibos') {
      (ev.target.checked) ? this.recibosList.map(el => el.checked = true ) : this.recibosList.map(el => el.checked = false );

      this.computeTotalsRecibos();
    }

    if (targetList === 'creditos') {
      (ev.target.checked) ? this.creditosList.map(el => el.checked = true ) : this.creditosList.map(el => el.checked = false );

      this.computeTotalsCreditos();
    }

    if (targetList === 'receitas') {
      (ev.target.checked) ? this.receitasList.map(el => el.checked = true ) : this.receitasList.map(el => el.checked = false );
    
      this.computeTotalsReceitas();
    }

    if (targetList === 'processamentos') {
      (ev.target.checked) ? this.processamentosList.map(el => el.checked = true ) : this.processamentosList.map(el => el.checked = false );
    }

    if (targetList === 'avisos') {
      (ev.target.checked) ? this.avisosList.map(el => el.checked = true ) : this.avisosList.map(el => el.checked = false );
    }

    if (targetList === 'agendamentos') {
      (ev.target.checked) ? this.despesasAgendList.map(el => el.checked = true ) : this.despesasAgendList.map(el => el.checked = false );
    }

  }



  // DESPESAS METHODS ---------------------------------------------------------
  fetchingDespesas = false;
  despesasSubs = null;
  getDespesas() {
    if (this.despesasSubs) this.despesasSubs.unsubscribe(); 

    // if (!this.selCondominio || (!this.startDate && !this.endDate)) return;
    if (!this.selCondominio) return;

    let startDate = (this.startDate) ? formatDate(this.startDate, this.format, this.locale) : 'NULL';
    let endDate = (this.endDate) ? formatDate(this.endDate, this.format, this.locale) : 'NULL';

    let cod = null;
    if (this.selCondominio !== '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value !== '-2')) cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;

    let ind = this.despesasListCol.findIndex(el => (el.key === 'nome_condominio'));
    if (ind) {
      this.despesasListCol[ind] = this.despesaFornecedorCol;
      this.despesasListCol = JSON.parse(JSON.stringify(this.despesasListCol));
    }

    // ADD COLUMN FOR COD CONDOMINIO
    if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) {
      if (!this.despesasListCol.find(el => el.key === 'cod_condominio')) this.despesasListCol.splice(1, 0, this.codCondominioCol);
    } else {
      // REMOVE CONDOMINIO COLUMN IF NEEDED
      this.despesasListCol = this.despesasListCol.filter(el => (el.key !== 'cod_condominio'));
    }

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    
    this.fetchingDespesas = true;
    this.despesasSubs = this.api.getDespesas(cod, startDate, endDate).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        // DESPESAS
        this.despesasListOrig = res.data;
        // // TODO: REVIEW WITH DESPESAS AGENDAMENTO
        // this.despesasListOrig = res.data.filter(el => (
        //   el.n_agendamento === null || (el.n_agendamento && this.utils.getDate(el['dt_desp']) && this.utils.getDate(el['dt_desp']).getTime() <= (new Date()).getTime())
        // ));

        this.fornecedoresOtps = [{ name: 'Todos', value: false }];
        let seenFornecedores = [];
        this.despesasListOrig.forEach(el => {
          el['n_despesa'] = Number(el['n_despesa']);
          el['dt_desp'] = (el['dt_desp']) ? this.utils.getDate(el['dt_desp']) : null;
          el['dt_pag'] = (el['dt_pag']) ? this.utils.getDate(el['dt_pag']) : null;
          el['valor'] = (el['valor']) ? Number(el['valor']) : null;
          el['checked'] = false;

          el['a_liquidar'] = (el['from_gecond'] === '1' && el['dt_pag']) ? 0 : el['valor'] - el['valor_liquidado'];
          // el['a_liquidar'] = el['valor'] - el['valor_liquidado'];
          el['status'] = (el['a_liquidar'] === 0) ? true : false;
          el['val_fixo'] = (el['val_fixo'] === '1');

          // SET LISTA DE FORNECEDORES
          if (el.cod_fornecedor != null && seenFornecedores.findIndex(cod => (cod === el.cod_fornecedor)) === -1) {
            // ADD RUBRICA TO SEEN STRING
            seenFornecedores.push(el.cod_fornecedor);

            this.fornecedoresOtps.push({ name: el.nome_fornecedor, value: el.cod_fornecedor });
          }
        });

        if (this.fornecedorSelected && !this.fornecedoresOtps.find(el => el.value === this.fornecedorSelected)) this.fornecedorSelected = null;

        this.despesasListLength = res.total;
        this.despesasList = JSON.parse(JSON.stringify(this.despesasListOrig));

        this.computeTotals();

        // if (this.prevState) { 
          this.filterListDespesas();
        // }
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingDespesas = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingDespesas = false;
    });
  }

  getDespesasByFornecedor() {
    if (!this.startDate && !this.endDate) return;

    let startDate = (this.startDate) ? formatDate(this.startDate, this.format, this.locale) : 'NULL';
    let endDate = (this.endDate) ? formatDate(this.endDate, this.format, this.locale) : 'NULL';

    let ind = this.despesasListCol.findIndex(el => (el.key === 'nome_fornecedor'));
    if (ind) {
      this.despesasListCol[ind] = this.despesaCondominioCol;
      this.despesasListCol = JSON.parse(JSON.stringify(this.despesasListCol));
    }

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.despesasSubs = this.api.getDespesasByFornecedor(this.fornecedorSelectedAll, startDate, endDate).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        // DESPESAS
        this.despesasListOrig = res.data;

        this.despesasListOrig.forEach(el => {
          el['dt_desp'] = (el['dt_desp']) ? this.utils.getDate(el['dt_desp']) : null;
          el['dt_pag'] = (el['dt_pag']) ? this.utils.getDate(el['dt_pag']) : null;
          el['valor'] = (el['valor']) ? Number(el['valor']) : null;
          el['checked'] = false;

          el['a_liquidar'] = (el['from_gecond'] === '1' && el['dt_pag']) ? 0 : el['valor'] - el['valor_liquidado'];
          el['status'] = (el['a_liquidar'] === 0) ? true : false;
          el['val_fixo'] = (el['val_fixo'] === '1');
        });

        this.despesasListLength = res.total;
        this.despesasList = JSON.parse(JSON.stringify(this.despesasListOrig));

        this.filterListDespesas();
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
    });
  }

  getDespesasAgendByFornecedor() {
    if (this.fetchingAgendDespesas) return;

    if ((!this.startDateAgend && !this.endDateAgend)) return;

    let startDate = (this.startDateAgend) ? formatDate(this.startDateAgend, this.format, this.locale) : 'NULL';
    let endDate = (this.endDateAgend) ? formatDate(this.endDateAgend, this.format, this.locale) : 'NULL';

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    
    let ind = this.despesasAgendListCol.findIndex(el => (el.key === 'nome_fornecedor'));
    if (ind) {
      this.despesasAgendListCol[ind] = this.despesaCondominioCol;
      this.despesasAgendListCol = JSON.parse(JSON.stringify(this.despesasAgendListCol));
    }

    this.fetchingAgendDespesas = true;
    this.api.getDespesasAgendamentoByFornecedor(this.fornecedorSelectedAll, startDate, endDate, this.estadoAgendamentoFilter).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        // DESPESAS
        if (res.data) {
          this.despesasAgendListOrig = res.data.map(el => { 
            return {...el, 
              checked: false, 
              valor:el.valor, 
              byFornecedor:true, 
              metodo:el.metodo, 
              isInactive: el.estado === '0' && this.utils.compareDayDates(this.utils.getDate(el.dt_estado), this.now) <= 0,
              periodicidade: this.appConfig.periodicityOpts.find(p => p.value === el.periodicidade)? this.appConfig.periodicityOpts.find(p => p.value === el.periodicidade).name : null,
            } 
          });
        } else {
          this.despesasAgendListOrig = [];
        }

        this.fornecedoresOtps = [{ name: 'Todos', value: false }];
        let seenFornecedores = [];

        //Convert types
        this.despesasAgendListOrig.forEach(el => {
          el.datas_valor = JSON.parse(el.datas_valor);
          el.datas_lancamento = JSON.parse(el.datas_lancamento);
          el.n_agendamento = Number(el.n_agendamento);
          el.num_despesas_active = Number(el.num_despesas_active);
          el.start_date = (el.start_date) ? this.utils.getDate(el.start_date) : null;
          el.end_date = (el.end_date) ? this.utils.getDate(el.end_date) : null;

          if (seenFornecedores.findIndex(cod => (cod === el.cod_fornecedor)) === -1) {
            // ADD RUBRICA TO SEEN STRING
            seenFornecedores.push(el.cod_fornecedor);

            this.fornecedoresOtps.push({ name: el.nome_fornecedor, value: el.cod_fornecedor });
          }
        });

        this.despesasAgendList = JSON.parse(JSON.stringify(this.despesasAgendListOrig));
        this.filterListDespesas();
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingAgendDespesas = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingAgendDespesas = false;
    });
  }

  despesaSelected(despesa) {

    setTimeout(() => {
      if (!despesa.checked) return;

      // CHECK IF THIS IS DESPESA AGENDAMENTO
      if (despesa.n_agendamento) {
        this.toastr.warning('Despesa associada ao agendamento nº ' + despesa.n_agendamento + '. Remoção da despesa pode ser realizada na página de agendamento associada.', 'Alerta', { timeOut: 4000 });
        setTimeout(() => { despesa.checked = false; }, 1);
        return;
      }
  
      let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;

      this.api.hasCaixaVertisMovimento(cod, 'D', despesa.n_despesa).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.status && res.dates) {
            let dates = [];
            res.dates.forEach(el => {
              dates.push(this.utils.getDate(el));
            });
            this.handleDeletePermissions(despesa, dates, 'Despesa',
              'Por favor contacte um administrador para eliminar a despesa selecionada.',
              'Por favor contacte o administrador de sistema para eliminar a despesa selecionada.');
          }
        }
      }, err => {});
    }, 1);

// if (!despesa.checked || this.userSession.isSuperAdmin()) return;

// let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;

// this.api.hasCaixaVertisMovimento(cod, 'D', despesa.n_despesa).subscribe(res => {
//   if (res.hasOwnProperty('success') && res.success) {
//     if (res.status) {
//       this.toastr.warning('Despesa registada em Caixa Vertis. Por favor contacte o administrador de sistema para eliminar a despesa selecionada.', 'Alerta', { timeOut: 4000 });
//       despesa.checked = false;
//     }
//   }
// }, err => {});
  }

  filterListDespesas(byFornecedor=false) {
    if (byFornecedor) {

      switch (this.selTabKey) {
        case 'despesas': this.getDespesasByFornecedor(); break;
        case 'agendamentos': this.getDespesasAgendByFornecedor(); break;
      }

      this.selCondominio = null;
      return;
    }

    switch (this.selTabKey) {
      case 'despesas':
        if (this.estadoSelected === '0' || !this.estadoSelected) {  // TODAS
          this.despesasList = this.despesasListOrig;
        }
    
        if (this.estadoSelected === '1') {  // LIQUIDADAS
          this.despesasList = this.despesasListOrig.filter(el => (el.status));
        }
    
        if (this.estadoSelected === '2') {  // POR LIQUIDAR
          this.despesasList = this.despesasListOrig.filter(el => (!el.status));
        }
    
        if (this.fornecedorSelected) {
          this.despesasList = this.despesasList.filter(el => (el.cod_fornecedor === this.fornecedorSelected));
        }
    
        this.computeTotals();
        break;
      case 'agendamentos':

        if (this.estadoSelected === '0' || !this.estadoSelected) {  // TODAS
          this.despesasAgendList = this.despesasAgendListOrig;
        }
    
        if (this.estadoSelected === '1') {  // LIQUIDADAS
          this.despesasAgendList = this.despesasAgendListOrig.filter(el => (el.liquidado === '1'));
        }
    
        if (this.estadoSelected === '2') {  // POR LIQUIDAR
          this.despesasAgendList = this.despesasAgendListOrig.filter(el => (el.liquidado === '0'));
        }
    
        if (this.fornecedorSelected) {
          this.despesasAgendList = this.despesasAgendList.filter(el => (el.cod_fornecedor === this.fornecedorSelected));
        }
        
        break;
    }
  }

  computeTotals() {
    this.valorTotal = 0;
    this.valorParcialTotal = 0;
    this.despesasList.forEach(el => {
      this.valorTotal += (el.valor) ? el.valor : 0;
      this.valorParcialTotal += (el.a_liquidar) ? el.a_liquidar : 0;
    });
  }

  tableSort(key, target) {
    switch (target) {
      case 'despesas':
        this.utils.tableSort(this.despesasListCol, this.despesasList, key);
        break;
      case 'creditos':
        this.utils.tableSort(this.creditosListCol, this.creditosList, key);
        break;
      case 'receitas':
        this.utils.tableSort(this.receitasListCol, this.receitasList, key);
        break;
      case 'recibos':
        this.utils.tableSort(this.recibosListCol, this.recibosList, key);
        break;
      case 'agendamentos':
        this.utils.tableSort(this.despesasAgendListCol, this.despesasAgendList, key);
        break;
      case 'avisos':
        this.avisosListCol.forEach(el => {
          if (el.key === key) {
    
            if (el.sort === 'DESC') {
              el.sort = 'ASC';
            } else if (el.sort === 'ASC') {
              el.sort = 'DESC';
            } else if (el.sort === null) {
              el.sort = 'ASC';
            }

            this.avisosSortParam = key;
            this.avisosSortDirection = el.sort;
            this.getList();
          } else {
            el.sort = null;
          }
        });
        break;
      case 'processamentos':
        this.processamentosListCol.forEach(el => {
          if (el.key === key) {
    
            if (el.sort === 'DESC') {
              el.sort = 'ASC';
            } else if (el.sort === 'ASC') {
              el.sort = 'DESC';
            } else if (el.sort === null) {
              el.sort = 'ASC';
            }

            this.processamentosSortParam = key;
            this.processamentosSortDirection = el.sort;
            this.getList();
          } else {
            el.sort = null;
          }
        });
        break;
    }
  }
  // --------------------------------------------------------------------------

  // DESPESAS AGENDAMENTOS METHODS --------------------------------------------
  fetchingAgendDespesas = false;
  getAgendDespesas() {
    if (this.fetchingAgendDespesas) return;

    // if (!this.selCondominio || (!this.startDateAgend && !this.endDateAgend)) return;
    if (!this.selCondominio) return;

    let startDate = (this.startDateAgend) ? formatDate(this.startDateAgend, this.format, this.locale) : 'NULL';
    let endDate = (this.endDateAgend) ? formatDate(this.endDateAgend, this.format, this.locale) : 'NULL';

    let cod = null;
    if (this.selCondominio !== '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;

    // ADD COLUMN FOR
    let ind = this.despesasAgendListCol.findIndex(el => (el.key === 'nome_condominio'));
    if (ind) {
      this.despesasAgendListCol[ind] = this.despesaFornecedorCol;
      this.despesasAgendListCol = JSON.parse(JSON.stringify(this.despesasAgendListCol));
    }

    // ADD COLUMN FOR COD CONDOMINIO
    if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) {
      if (!this.despesasAgendListCol.find(el => el.key === 'cod_condominio')) this.despesasAgendListCol.splice(1, 0, this.codCondominioCol);
    } else {
      // REMOVE CONDOMINIO COLUMN IF NEEDED
      this.despesasAgendListCol = this.despesasAgendListCol.filter(el => (el.key !== 'cod_condominio'));
    }

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    
    this.fetchingAgendDespesas = true;
    this.api.getDespesasAgendamento(cod, startDate, endDate, this.estadoAgendamentoFilter).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        // DESPESAS
        if (res.data) {
          this.despesasAgendListOrig = res.data.map(el => { 
            return {...el, 
              checked: false, 
              valor:el.valor, 
              metodo:el.metodo,
              isInactive: el.estado === '0' && this.utils.compareDayDates(this.utils.getDate(el.dt_estado), this.now) <= 0,
              periodicidade: this.appConfig.periodicityOpts.find(p => p.value === el.periodicidade)? this.appConfig.periodicityOpts.find(p => p.value === el.periodicidade).name : null,
            } 
          });
        } else {
          this.despesasAgendListOrig = [];
        }

        this.fornecedoresOtps = [{ name: 'Todos', value: false }];
        let seenFornecedores = [];
        this.despesasAgendListOrig.forEach(el => {
          el.datas_valor = JSON.parse(el.datas_valor);
          el.datas_lancamento = JSON.parse(el.datas_lancamento);
          el.n_agendamento = Number(el.n_agendamento);
          el.num_despesas_active = Number(el.num_despesas_active);
          el.start_date = (el.start_date) ? this.utils.getDate(el.start_date) : null;
          el.end_date = (el.end_date) ? this.utils.getDate(el.end_date) : null;

          if (seenFornecedores.findIndex(cod => (cod === el.cod_fornecedor)) === -1) {
            // ADD RUBRICA TO SEEN STRING
            seenFornecedores.push(el.cod_fornecedor);

            this.fornecedoresOtps.push({ name: el.nome_fornecedor, value: el.cod_fornecedor });
          }
        });

        this.despesasAgendList = JSON.parse(JSON.stringify(this.despesasAgendListOrig));

        this.filterListDespesas();
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingAgendDespesas = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingAgendDespesas = false;
    });
  }

  agendamentoSelected(agendamento) {
    if (agendamento.checked && agendamento.can_delete == '0') {
      setTimeout(() => {
        agendamento.checked = false;
      });
      this.toastr.info('O agendamento selecionado contém despesas com movimentos bancários associados. Se pretender remover o agendamento, terá de remover os movimentos previamente.','Informação', {timeOut: 8000 });
    }
  }
  // PAG. QUOTAS METHODS ------------------------------------------------------
  lastOrcamentoLancado = null;
  fetchingQuotas = false;  
  quotasFetched = false;  
  quotasApiRequests = [];
  getQuotasList() {
    if (this.fetchingQuotas) return;

    let cod = null;
    if (this.ASSearchEnabled) {
      cod = this.ASSelCondominio.cod;
    } else {
      cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : ((this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value) ? this.selCondominio.value.cod : null);
    }

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.fetchingQuotas = true;
    this.quotasFetched = false;
    this.quotasList = [];
    this.quotasListOrig = [];

    let req = [
      this.api.getAvisosRecebimentos(cod),
      this.api.getOrcamentoByPeriodoAndAprovado(cod, true),
      this.api.getFraccoes(cod),
    ];

    let apiReq = forkJoin(req).subscribe(res => {
      if (res[0].hasOwnProperty('success') && res[0].success) {
        
        // ADD NOME FRACCAO TO AVISOS
        let fraccaoAux = null;
        res[0].data.forEach(el => {
          fraccaoAux = res[2].data.find(it => it.cod === el.cod_fraccao);

          el['fraccao_nome'] = (fraccaoAux) ? fraccaoAux.nome : null;
        });

        this.valorQuotasTotal = 0;
        
        let creditos = res[0].creditos.map(el => {
          el['tipo'] = 'C';
          el['valor'] = Number(parseFloat(el['credito']).toFixed(2));
          el['debito'] = 0;
          el['dt_emissao'] = (el['data']) ? this.utils.getDate(el['data']) : null;
          el['dt_vencimento'] = null;
          el['n_aviso'] = el['n_credito'];
          el['checked'] = false;

          return el;
        });

        let penalizacoes = res[0].penalizacoes.map(el => {
          el['tipo'] = 'A';

          // HANLE PAGAMENTO PARCIAL DE QUOTAS
          if (el['debito'] > 0) {
            el['prev_debito'] = Number(parseFloat(el['valor']).toFixed(2)) - Number(parseFloat(el['debito']).toFixed(2));
            el['valor'] = Number(parseFloat(el['debito']).toFixed(2));
            el['debito'] = 0;
          }      

          el['dt_emissao'] = (el['dt_emissao']) ? this.utils.getDate(el['dt_emissao']) : null;
          el['dt_vencimento'] = (el['dt_vencimento']) ? this.utils.getDate(el['dt_vencimento']) : null;
          el['checked'] = false;

          return el;
        });

        let avisos = res[0].data.map(el => {
          el['tipo'] = 'A';

          // HANLE PAGAMENTO PARCIAL DE QUOTAS
          if (el['debito'] > 0) {
            el['prev_debito'] = Number(parseFloat(el['valor']).toFixed(2)) - Number(parseFloat(el['debito']).toFixed(2));
            el['valor'] = Number(parseFloat(el['debito']).toFixed(2));
            el['debito'] = 0;
          }      

          el['dt_emissao'] = (el['dt_emissao']) ? this.utils.getDate(el['dt_emissao']) : null;
          el['dt_vencimento'] = (el['dt_vencimento']) ? this.utils.getDate(el['dt_vencimento']) : null;
          el['checked'] = false;

          return el;
        });

        this.quotasListOrig = creditos.concat(penalizacoes).concat(avisos);

        this.filterListPagQuotas();

        this.lastOrcamentoLancado = (res[1].hasOwnProperty('data') && res[1].data.orcamento) ? res[1].data.orcamento : null;
        this.quotasFetched = true;  
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.fetchingQuotas = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
    }, err => {
      this.fetchingQuotas = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
    });
    this.quotasApiRequests.push(apiReq);
  }

  fraccoesOrig = [];
  getFraccaoList() {
    let cod = null;

    if (this.ASSearchEnabled) {
      cod = this.ASSelCondominio.cod;
    } else {
      if (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) {
        cod = this.selCondominio.cod;
      } else if (this.selCondominio && this.selCondominio.hasOwnProperty('value')) {
        cod = this.selCondominio.value.cod;
      } else {
        return;
      }
    }

    let apiReq = this.api.getCondFraccoesRecebimentos(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.fraccaoSelected = null;
        this.fraccaoOpts = [];
        this.fraccoesOrig = res.data;

        res.data.forEach(fraccao => {
          this.fraccaoOpts.push({ name: fraccao.cod + ' - ' + fraccao.nome + ' (' + fraccao.condomino_nome + ')', value: fraccao.cod, cod_condomino: fraccao.cod_proprietario, quotaTotal: null, porEmail: fraccao.por_email, porCarta: fraccao.por_carta });
        });

        if (this.searchSelection) {
          let aux2 = this.fraccaoOpts.find(el => (el.cod_condomino === this.searchSelection.cod_condomino));
          this.fraccaoSelected = (aux2 && aux2.hasOwnProperty('value')) ? aux2.value : null;
          this.searchSelection = null;
        }

        // GET QUOTAS DO ORCAMENTO
        this.api.getCondFraccoesQuota(cod, this.fraccaoOpts.map(el => { return { cod: el.value }; })).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            let aux = null;
            res.data.forEach(el => {
              aux = this.fraccaoOpts.find(it => it.value === el.cod);
              if (aux) aux.quotaTotal = Number(el.quota_actual);
            });
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
    this.quotasApiRequests.push(apiReq);
  }

  getContasList() {
    let cod = null;
    if (this.ASSearchEnabled) {
      cod = this.ASSelCondominio.cod;
    } else {
      cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : ((this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value && this.selCondominio.value.cod) ? this.selCondominio.value.cod : null)
    }

    if (!cod) return;
    
    let apiReq = this.api.getCondContasDetails(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.contaOptsOrig = [];
        this.contaOpts = [];
        res.data.forEach(el => {
          this.contaOptsOrig.push({ name: el.banco, value: el.cod, conta_principal: el.conta_principal, saldo: Number(el.saldo), details: el });
        });
        this.contaOpts = [].concat(this.contaOptsOrig);
      } else {
        // this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
    this.quotasApiRequests.push(apiReq);
  }

  avisosFilterCondominosOpts = [];
  avisosFilterCondominoSel = null;
  checkAvisosFilterOptions(quotasList) {
    return new Promise(resolve => {
      let auxSet = [...new Set(quotasList.map(el => { return JSON.stringify({ cod_pagador: el.cod_pagador, condomino_nome: el.condomino_nome }); }))];

      if (auxSet.length > 1) {
        this.avisosFilterCondominosOpts = [];

        auxSet.forEach(el => {
          el = JSON.parse(el.toString());
          if (el['condomino_nome'] && el['cod_pagador'] !== '0') this.avisosFilterCondominosOpts.push({ name: el['condomino_nome'], value: el['cod_pagador'] });
        });

        this.avisosFilterModalRef = this.modalService
          .open(this.avisosFilterAlertConfig)
          .onApprove(() => {
            let codPagador = this.avisosFilterCondominoSel;

            this.avisosFilterCondominosOpts = [];
            this.avisosFilterCondominoSel = null;
            resolve({ codPagador: codPagador });
          })
          .onDeny(() => { 
            this.avisosFilterCondominosOpts = [];
            this.avisosFilterCondominoSel = null;
            resolve(false);
          });
      } else {
        this.avisosFilterCondominosOpts = [];
        this.avisosFilterCondominoSel = null;
        resolve(true);
      }
    });
  }

  submittingFilterAvisos = false;
  filterAvisos() {
    if (this.avisosFilterModalRef) {
      this.submittingFilterAvisos = true;
      setTimeout(() => { this.submittingFilterAvisos = false; }, 4000);

      if (!this.avisosFilterCondominoSel) return;

      this.avisosFilterModalRef.approve();
    }
  }

  async filterListPagQuotas() {
    this.checkedHeader = false;
    this.quotasListOrig.forEach(el => {
      el.checked = false;
      el.debito = 0;
    });
    if (this.fraccaoSelected) {
      let quotasList = this.quotasListOrig.filter(el => (el.cod_fraccao === this.fraccaoSelected));
 
      // CHECK IF THERE IS MORE THAN ONE CONDOMINO
      let resAux: any = await this.checkAvisosFilterOptions(quotasList);
      if (!resAux) {
        this.quotasList = [];
        this.fraccaoSelected = null;
        return;
      }

      let cod_pagador = null;
      if (resAux && resAux.hasOwnProperty('codPagador')) {
        // FILTER LIST BASED ON resAxux
        this.quotasList = quotasList.filter(el => el.cod_pagador === resAux.codPagador);
        cod_pagador = resAux.codPagador;
      } else {
        this.quotasList = quotasList;
      }
      
      let aux = this.fraccaoOpts.find(el => (el.value === this.fraccaoSelected));
      if (!cod_pagador && aux) {
        cod_pagador = aux.cod_condomino;
      }

      this.checkCondominioHasMoreFraccoes(cod_pagador);
      

      let auxContencioso = this.fraccoesOrig.find(el => (el.cod === this.fraccaoSelected));
      if (auxContencioso.cod_estado_contenc && auxContencioso.dt_inicio_contenc) {
        let estado = this.estadoContOpts.find(el => (el.value === auxContencioso.cod_estado_contenc)); 

        if (estado && estado.hasOwnProperty('name')) {
          this.estadoContencioso.estado = estado.name;
          this.estadoContencioso.data = auxContencioso.dt_inicio_contenc;
          this.estadoContencioso.obs = auxContencioso.obs_contenc;

          if (!this.contenciosoModalRef) {
            this.contenciosoModalRef = this.modalService
            .open(this.contenciosoAlertConfig)
            .onApprove(() => { this.contenciosoModalRef = null; })
            .onDeny(() => { this.contenciosoModalRef = null; });
          }

        }
      }

      this.quotaActual = (aux) ? aux.quotaTotal : null;
    }
    this.totalEnabled = false;
    setTimeout(() => { this.totalEnabled = true; }, 500);

    this.computeQuotasTotals();
  }

  checkCondominioHasMoreFraccoes(cod_proprietario): boolean {
    let n_fraccoes = this.fraccoesOrig.reduce((n, el) => {
      if (el.cod_proprietario == cod_proprietario) n++;
      return n;
    }, 0);
    if (n_fraccoes > 1) {
      this.toastr.warning(this.appConfig.toastr.ownerOfMultipleFracoes.msg, this.appConfig.toastr.ownerOfMultipleFracoes.title, { tapToDismiss: true});
      return true;
    }

    var hasAvisosFromMultipleFracoes = {};
    try {
      let cod_fraccao = null;
      this.quotasListOrig.forEach(aviso => {
        if (aviso.cod_pagador === cod_proprietario) {
          if (!!cod_fraccao && cod_fraccao !== aviso.cod_fraccao) throw hasAvisosFromMultipleFracoes;
          cod_fraccao = aviso.cod_fraccao;
        }
      });
    } catch (err) {
      if (err !== hasAvisosFromMultipleFracoes) throw err;
      this.toastr.warning(this.appConfig.toastr.hasAvisosFromMultipleFracoes.msg, this.appConfig.toastr.hasAvisosFromMultipleFracoes.title, { tapToDismiss: true});
      return true;
    }

    return false;
  }

  useAllCreditos() {
    this.creditoModalRef.approve();
  }

  formSubmitted() {
    return new Promise(async (resolve) => {
      if (!this.selCondominio || !this.fraccaoSelected) {
        this.toastr.error('Nenhum condominio e/ou fracção selecionado.', 'Ups...!', { timeOut: 4000 });
        resolve(false);
        return;
      }
  
      if ((this.aPagarTotal === null || Number(this.aPagarTotal) === 0) && !this.quotasList.find(el => (el.tipo === 'C' && el.checked))) {
        this.toastr.error('Nenhum valor para pagamento introduzido.', 'Ups...!', { timeOut: 4000 });
        resolve(false);
        return;
      }
  
      if (Number(this.aPagarTotal) < 0) {
        this.toastr.error('Valor de pagamento não pode ser negativo.', 'Ups...!', { timeOut: 4000 });
        resolve(false);
        return;
      }
  
      if (!this.quotasList.find(el => (el.tipo !== 'C'))) {
        this.toastr.error('Nenhum aviso para pagamento disponível.', 'Ups...!', { timeOut: 4000 });
        resolve(false);
        return;
      }
      this.quotasList.filter(el => !!el.checked).forEach(el => {
        el.debito = this.utils.cleanDecimalDigits(el.debito);
        el.valor = this.utils.cleanDecimalDigits(el.valor);
      });
  
      if (this.quotasList.find(el => (el.checked && el.debito > el.valor))) {
        this.toastr.error('Valor para pagamento de aviso superior ao valor em dívida do mesmo. Por favor, verifique o aviso assinalado.', 'Ups...!', { timeOut: 4000 });
        resolve(false);
        return;
      }
  
      // TODO RE-DO, get condómino email instead of fraccao proprietario
      let aux = this.fraccoesOrig.find(el => (el.cod === this.fraccaoSelected));
      let email = (aux && aux.email) ? aux.email : null;
      
      // TODO Verificar se é necessário obter outra vez (através do inspector)
      let recibo_email = aux.por_email === '1';
      // TODO Se estiver logado em caixa fica sempre picado?
      let recibo_print = aux.por_carta === '1' && (!email || email.trim() === '' && recibo_email);
      
      
      let form_pagam = this.userSession.isCaixaVertisLoggedIn()? '1' : null;

      let dt = new Date();
      dt.setHours(0,0,0,0);

      //TODO Verificar se recibo_email_msg está a ser utilizado
      let form: PaymentForm = {
        form_pagam: form_pagam,
        cod_conta_bancaria: null,
        dt_pag_valor: new Date(dt),
        dt_pag: new Date(dt),
        n_doc_pagam: '',
        valor_pago: this.utils.cleanDecimalDigits(this.aPagarTotal).toString(),
        recibo_print: recibo_print,
        recibo_email: recibo_email,
        email: email,
        recibo_email_msg: '',
        troco: null,
        aReceber: null,
      }
  
      let input: PaymentModalInput = {
        form: form,
        maxPagamento: this.aPagarTotal,
        formPagamDisabled: false,
        dateDisabled: false,
        dt_default: null,
        valorPlaceholder: this.aPagarTotal + ' € a receber',
        showNDoc: true,
        showSaldo: true,
        minDate: null,
        type: 'RECEBIMENTOS',
        payFunction: this.setPayment,
        inCaixaVertis: false
      }
  
      let res = await this.paymentModal.open(input);
      resolve(res);
    })
  }

  getPaymentType(banc) {
    if (banc === 'CAIXA') {
      return 'Numerário';
    } else {
      return 'Transferência';
    }
  }
  
  details = null;
  linhasRecibo = [];
  now = new Date();
  getReciboDetails(reciboId, print=false, sendEmail=false, email:string, credito:Credito): Promise<boolean> {
    return new Promise((resolve) => {
      if (!reciboId) {
        resolve(false);
        return;
      }
  
  
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });    
      this.api.getRecibosDetails(reciboId).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.details = res.data;
  
          let condMoradaAux = this.details.cond_morada;
          this.details.pag_morada_fact = this.utils.formatEmptyMoradaObject(this.details.pag_morada_fact);
          let pagMoradaAux = (this.details.pag_morada_fact && this.details.pag_morada_fact !== '' && this.details.pag_morada_fact !== '{"address":null,"postalCode":null,"locality":null}') ? this.details.pag_morada_fact : this.details.pag_morada;
  
          // HANDLE OBJECT MORADA - CONDOMINIO
          try {
            let aux = '';
            let addressObj = JSON.parse(this.details.cond_morada);
            Object.keys(addressObj).forEach((key, i) => {
              if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
            });
  
            condMoradaAux = aux;
            this.details.cond_morada = (aux) ? aux.replace(/NEW_LINE/g, ', ') : null;
          } catch(e) {}
  
          // HANDLE OBJECT MORADA - CONDOMINIO
          try {
            let aux = '';
            let addressObj = JSON.parse(pagMoradaAux);
            Object.keys(addressObj).forEach((key, i) => {
              if (addressObj[key]) aux += (i === 0) ? addressObj[key] : 'NEW_LINE' + addressObj[key]; 
            });
  
            pagMoradaAux = aux;
          } catch(e) {}
  
          this.details.cond_morada_pdf = (condMoradaAux) ? condMoradaAux.split(/NEW_LINE/g) : [];
          this.details.pag_morada_fact_pdf = (pagMoradaAux) ? pagMoradaAux.split(/NEW_LINE/g) : [];
  
          this.details.criado_em = (this.details.criado_em) ? new Date(this.details.criado_em) : null;
          this.details.data = (this.details.data) ? this.details.data : null;
          this.details.banco = (this.details.banco) ? this.getPaymentType(this.details.banco) : null;
          this.details.utilizador = this.details.first_name + ' ' + this.details.last_name;
  
          this.details['tipo_pagamento'] = null;
          if (this.details.conta) {
            this.details['tipo_pagamento'] = (this.details.conta === 'CAIXA') ? 'N' : 'T';
          }
  
          this.valorTotal = 0;
          this.linhasRecibo = [];
          res.linhas_recibo.filter(el => ((el.n_aviso && el.data) || el.tipo_doc === 'C')).forEach(el => {
            let aux = {
              n_aviso: el.tipo_doc + ' - ' + el.n_aviso,
              data: (el.data) ? this.utils.getDate(el.data) : null,
              fraccao: (el.cod_fraccao && el.nome_fraccao) ? el.cod_fraccao + ' - ' + el.nome_fraccao : null,
              descricao: el.descricao,
              valor: (el.tipo_doc === 'C') ? -1 * Number(el.valor) : Number(el.valor),
            }
            this.linhasRecibo.push(aux);
  
            (el.tipo_doc === 'C') ? this.valorTotal -= Number(el.valor) : this.valorTotal += Number(el.valor);
          });
  
          if (print) this.exportPDF(credito, 'pag-quotas');
          if (sendEmail) {
              setTimeout(() => { this.sendEmail(email); }, 500);
          }
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
  
      }, err => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }

  sendingEmail = false;
  sendEmail(email) {
    if (this.sendingEmail) return;

    // CHECK IF EMAIL IS SET
    if (email === null || email === '') {
      this.toastr.error('Não é possível proceder ao envio do email. Não foi introduzido nenhum endereço de email.', 'Ups...!', { timeOut: 4000 });
      return;
    }

    this.sendingEmail = true;
    this.pdfEmailReciboController.export().then((group: Group) => exportPDF(group)).then((dataUri) => {        
      let base64  = dataUri.replace('data:application/pdf;base64,', '');
      base64  = base64.replace(' ', '+');

      // CHECK IF EMAIL IS SET
      if (!base64 || base64.length < 1500) {
        this.toastr.error('Ficheiro PDF - Recibo Nº ' + this.details.n_recibo + ' em branco!', 'Erro');
        this.sendingEmail = false;
        return;
      }

      let from = this.appConfig.company.email;;
      let to = (email) ? email : '';
      let subjectMsg = 'Envio de recibo nº ' + this.details.n_recibo;
      let bodyMsg = this.getEmailBody();
      let attachment = base64;
      let fromName = 'VERTIS - Gestão Condomínios';
      let toName = (this.details.pag_nome) ? this.details.pag_nome : '';
      
      let filename = 'recibo_' + formatDate(new Date(), this.format, this.locale) + '.pdf';

      let emailsToSend = [{
        from: from,
        to: to,
        subject: subjectMsg,
        body: bodyMsg,
        attachment: attachment,
        filename: filename,
        fromName: fromName,
        toName: toName,
      }];
      this.api.sendEmailV2(emailsToSend).subscribe(res => {
        this.toastr.success('Recibo enviado com sucesso.', 'Email Enviado', { timeOut: 4000 });
        this.sendingEmail = false;
      }, err => { this.sendingEmail = false; });
    });
  }

  getEmailBody() {
    let htmlEmail = '';

        // TEXTO INICIAL
        htmlEmail += '<div style="margin-bottom: 35px;">';
        this.appConfig.reciboBody.forEach(line => {
          if (line) {
            htmlEmail += '<span>' + line + '</span>';
          } else {
            htmlEmail += '<span><br><br></span>';
          }
        });
        htmlEmail += '</div>';

        htmlEmail += this.utils.getEmailFooter();

      return this.utils.setEmailTemplate(htmlEmail);
  }

  creditoLine = null;
  pdfToGenerate = null;
  exportPDF(credito=null, selTabKey) {
    if (credito) this.creditoLine = credito; 
    
    let filename = this.details.cond_nome.replace(/ /g, '_') + '_' + this.details.n_recibo + '_' + formatDate(this.now, this.format, this.locale);
    filename = filename.replace(/,/g, '');

    this.pdfToGenerate = selTabKey;
    
    setTimeout(() => {
      this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
      this.pdfController.forceProxy = true;
      this.pdfController.proxyTarget = '_blank';
      this.pdfController.saveAs(filename + '.pdf');
    }, 10);

    setTimeout(() => { this.creditoLine = null; }, 4000);
  }

  pdfList = [];
  pdfReport = {
    title: null,
    reportType: null,
    startDate: null,
    endDate: null,
    now: null,
  };
  exportPDFList() {
    this.pdfList = [];

    let condominio = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod + ' - ' + this.selCondominio.nome : this.selCondominio.value.cod + ' - ' + this.selCondominio.value.nome;

    
    this.pdfReport['title'] = condominio;
    this.pdfReport['startDate'] = this.startDate;
    this.pdfReport['endDate'] = this.endDate;
    this.pdfReport['now'] = new Date();

    let filename = null;

    switch (this.selTabKey) {
      case 'despesas':
        this.pdfReport['reportType'] = 'Listagem de Despesas';

        filename = condominio.replace(/ /g, '_').replace(/ - /g, '_') + '_' + 'despesas' + '_' + formatDate(this.now, this.format, this.locale);
        filename = filename.replace(/,/g, '');

        // TODO: SET DESPESAS LIST
        this.pdfList = this.despesasList;
        break;
      case 'creditos':
        this.pdfReport['reportType'] = 'Listagem de Créditos';

        filename = condominio.replace(/ /g, '_').replace(/ - /g, '_') + '_' + 'creditos' + '_' + formatDate(this.now, this.format, this.locale);
        filename = filename.replace(/,/g, '');

        // TODO: SET CREDITOS LIST
        this.pdfList = this.creditosList;
        break;
      case 'receitas-extra':
        this.pdfReport['reportType'] = 'Listagem de Receitas';

        filename = condominio.replace(/ /g, '_').replace(/ - /g, '_') + '_' + 'receitas' + '_' + formatDate(this.now, this.format, this.locale);
        filename = filename.replace(/,/g, '');

        // TODO: SET RECEITAS LIST
        this.pdfList = this.receitasList;
        break;
      case 'recibos':
        this.pdfReport['reportType'] = 'Listagem de Recibos';

        filename = condominio.replace(/ /g, '_').replace(/ - /g, '_') + '_' + 'recibos' + '_' + formatDate(this.now, this.format, this.locale);
        filename = filename.replace(/,/g, '');

        // TODO: SET RECIBOS LIST
        this.pdfList = this.recibosList;
        break;
    }

    this.pdfToGenerate=this.selTabKey;

    setTimeout(() => { 
      this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
      this.pdfController.forceProxy = true;
      this.pdfController.proxyTarget = '_blank';
      this.pdfController.saveAs(filename + '.pdf'); 
    }, 10);
  }

  excelList = [];
  excelListCol = [];
  exportExcelList() {
    this.excelList = [];
    this.excelListCol = [];

    let condominio = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod + ' - ' + this.selCondominio.nome : this.selCondominio.value.cod + ' - ' + this.selCondominio.value.nome;

    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';

    let filename = null;

    let worksheet: XLSX.WorkSheet = null;
    let aux = null;

    switch (this.selTabKey) {
      case 'despesas':
        if (this.despesasList.length === 0) return;

        filename = condominio.replace(/ /g, '_').replace(/ - /g, '_') + '_' + 'despesas' + '_' + formatDate(this.now, this.format, this.locale);
        filename = filename.replace(/,/g, '');

        this.excelList = [{ n_despesa: 'Nº', nome_fornecedor: 'Fornecedor', nome_rubrica: 'Rubrica', dt_desp: 'Data despesa', a_liquidar: 'A liquidar [€]', dt_pag: 'Data pag.', valor: 'Valor [€]' }];
        this.despesasList.forEach(el => {
          aux = {
            n_despesa: el.n_despesa,
            nome_fornecedor: el.nome_fornecedor,
            nome_rubrica: el.nome_rubrica,
            dt_desp: (el.dt_desp) ? this.utils.getFormatedDate(el.dt_desp) : '',
            a_liquidar: (Math.round(Number(el['a_liquidar']) * 100) / 100).toString().replace('.', ','),
            dt_pag: (el.dt_pag) ? this.utils.getFormatedDate(el.dt_pag) : '',
            valor: (Math.round(Number(el['valor']) * 100) / 100).toString().replace('.', ','),
          }
          this.excelList.push(aux);
        });
        // this.excelList.push({ dt_mov: null, descricao: null, valor: null });
        // this.excelList.push({ dt_mov: 'Total', descricao: null, valor: (Math.round(this.valorTotalList * 100) / 100).toString().replace('.', ',') });

        worksheet = XLSX.utils.json_to_sheet(this.excelList, { header:['n_despesa', 'nome_fornecedor', 'nome_rubrica', 'dt_desp', 'a_liquidar', 'dt_pag', 'valor'], skipHeader: true });
        break;
      case 'creditos':
        if (this.creditosList.length === 0) return;

        filename = condominio.replace(/ /g, '_').replace(/ - /g, '_') + '_' + 'creditos' + '_' + formatDate(this.now, this.format, this.locale);
        filename = filename.replace(/,/g, '');

        this.excelList = [{ n_credito: 'Nº', cod_fraccao: 'Fracção', nome_condomino: 'Condómino', descricao: 'Descrição', data: 'Data', valor: 'Valor [€]', credito: 'Em crédito [€]' }];
        this.creditosList.forEach(el => {
          aux = {
            n_credito: el.n_credito,
            cod_fraccao: el.cod_fraccao,
            nome_condomino: el.nome_condomino,
            descricao: el.descricao,
            data: (el.data) ? this.utils.getFormatedDate(el.data) : '',
            valor: (Math.round(Number(el['valor']) * 100) / 100).toString().replace('.', ','),
            credito: (Math.round(Number(el['credito']) * 100) / 100).toString().replace('.', ','),
          }
          this.excelList.push(aux);
        });
        // this.excelList.push({ dt_mov: null, descricao: null, valor: null });
        // this.excelList.push({ dt_mov: 'Total', descricao: null, valor: (Math.round(this.valorTotalList * 100) / 100).toString().replace('.', ',') });

        worksheet = XLSX.utils.json_to_sheet(this.excelList, { header:['n_credito', 'cod_fraccao', 'nome_condomino', 'descricao', 'data', 'valor', 'credito'], skipHeader: true });
        break;
      case 'receitas-extra':
        if (this.receitasList.length === 0) return;

        filename = condominio.replace(/ /g, '_').replace(/ - /g, '_') + '_' + 'receitas' + '_' + formatDate(this.now, this.format, this.locale);
        filename = filename.replace(/,/g, '');

        this.excelList = [{ n_receita: 'Nº', nome_fornecedor: 'Fornecedor', nome_rubrica: 'Rubrica', dt_desp: 'Data despesa', a_liquidar: 'A receber [€]', dt_pag: 'Data pag.', valor: 'Valor [€]' }];
        this.receitasList.forEach(el => {
          aux = {
            n_receita: el.n_receita,
            nome_fornecedor: el.nome_fornecedor,
            nome_rubrica: el.nome_rubrica,
            dt_desp: (el.dt_desp) ? this.utils.getFormatedDate(el.dt_desp) : '',
            a_liquidar: (Math.round(Number(el['a_liquidar']) * 100) / 100).toString().replace('.', ','),
            dt_pag: (el.dt_pag) ? this.utils.getFormatedDate(el.dt_pag) : '',
            valor: (Math.round(Number(el['valor']) * 100) / 100).toString().replace('.', ','),
          }
          this.excelList.push(aux);
        });
        // this.excelList.push({ dt_mov: null, descricao: null, valor: null });
        // this.excelList.push({ dt_mov: 'Total', descricao: null, valor: (Math.round(this.valorTotalList * 100) / 100).toString().replace('.', ',') });

        worksheet = XLSX.utils.json_to_sheet(this.excelList, { header:['n_receita', 'nome_fornecedor', 'nome_rubrica', 'dt_desp', 'a_liquidar', 'dt_pag', 'valor'], skipHeader: true });
        break;
      case 'recibos':
        if (this.recibosList.length === 0) return;

        filename = condominio.replace(/ /g, '_').replace(/ - /g, '_') + '_' + 'recibos' + '_' + formatDate(this.now, this.format, this.locale);
        filename = filename.replace(/,/g, '');

        this.excelList = [{ n_recibo: 'Nº', nome_condomino: 'Condómino', data: 'Data', criado_em: 'Data Criação', valor: 'Valor [€]' }];
        this.recibosList.forEach(el => {
          aux = {
            n_recibo: el.n_recibo,
            nome_condomino: el.nome_condomino,
            data: (el.data) ? this.utils.getFormatedDate(el.data) : '',
            criado_em: (el.criado_em) ? this.utils.getFormatedDate(el.criado_em) : '',
            valor: (Math.round(Number(el['valor']) * 100) / 100).toString().replace('.', ','),
          }
          this.excelList.push(aux);
        });
        // this.excelList.push({ dt_mov: null, descricao: null, valor: null });
        // this.excelList.push({ dt_mov: 'Total', descricao: null, valor: (Math.round(this.valorTotalList * 100) / 100).toString().replace('.', ',') });

        worksheet = XLSX.utils.json_to_sheet(this.excelList, { header:['n_recibo', 'nome_condomino', 'data', 'criado_em', 'valor'], skipHeader: true });
        break;
    }

    let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    let data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});

    FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
  }

  submittingFormCredito = false;
  descricaoCredito = null;

  createCredito() {
    this.submittingFormCredito = true;
    setTimeout(() => {
      this.submittingFormCredito = false;
    }, 4000);

    if (!this.descricaoCredito) return;

    this.creditoModalRef.approve();
  }

  setPayment: (data:PaymentForm) => Promise<boolean> = (data) => {
    return new Promise(async (resolve) => {
      // CHECK IF NEED CREATE CREDITO
      let createCredito = this.utils.cleanDecimalDigits(this.valorQuotasTotal) < this.utils.cleanDecimalDigits(data.valor_pago);
      if (createCredito) {
        this.descricaoCredito = null;
        this.submittingFormCredito = false; 
        this.creditoModalRef = this.modalService
          .open(this.creditoAlertConfig)
          .onApprove(async () => { 
            this.submittingFormCredito = false; 
            let res = await this.savePagamento(data, true); 
            resolve(res);
          })
          .onDeny(() => { this.submittingFormCredito = false; this.descricaoCredito = null; resolve(false)});
      } else {
        let res = await this.savePagamento(data, false);
        resolve(res);
      }
    })
  }

  savingPayment = false;
  @ViewChild('defaultModal', { static: false }) defaultModal: ModalDefaultComponent;
  @ViewChild('paymentModal', { static: false }) paymentModal: PaymentModalComponent;
  async savePagamento(data:PaymentForm, createCredito): Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        let reconciliacao_id_user = await this.businessLogic.isMovementInReconciliacaoEmCurso(data.cod_conta_bancaria, null, this.utils.getFormatedDate(data.dt_pag));
        if (reconciliacao_id_user && reconciliacao_id_user !== this.userSession.getUserId()) {
          let res = await this.defaultModal.open('Alerta', 'Existe uma reconciliação em curso para a data definida.', 'Deseja continuar?', 'Sim', null, 'tiny', null, null);
          if (!res) {
            resolve(false);
            return;
          }
        }
      } catch(err) {
        resolve(false);
        return;
      }
  
      this.loading = true;
      let conta = this.contaOptsOrig.find(el => el.value === data.cod_conta_bancaria);
      let tipoPag = (conta.name === 'CAIXA') ? ' -N' : ' -T';
  
      let valorRecibo = 0;
      this.quotasList.filter(el => (el.checked)).forEach(aviso => {
        if (aviso.tipo === 'C') {
          valorRecibo -= Number(aviso.debito);
        } else {
          valorRecibo += Number(aviso.debito);
        }
      });
  
      let nomeCondomino = null;
      let movimentos = [];
      
      this.quotasList.filter(el => (el.checked)).forEach(aviso => {
        if (nomeCondomino === null) nomeCondomino = aviso.condomino_nome;
  
        let movimento =  {
          dt_valor: this.utils.getFormatedDate(data.dt_pag_valor),
          dt_mov: this.utils.getFormatedDate(data.dt_pag),
          valor: Number(aviso.debito),
          nid_rubrica: null,
          nid_conta: data.cod_conta_bancaria,
          descricao: aviso.condomino_nome + tipoPag,
          condomino_nome: aviso.condomino_nome,
          tipo_movimento: 'QUO_ORC',
          obj: null,
  
          // AVISOS
          aviso_id: aviso.id,
          aviso_cod: aviso.cod,
          aviso_debito: Number(aviso.valor) - Number(aviso.debito),
  
          aviso_cod_fraccao: aviso.cod_fraccao,
          aviso_descricao: aviso.descricao,
          aviso_dt_emissao: this.utils.getFormatedDate(aviso.dt_emissao),
          aviso_n_aviso: aviso.n_aviso,
  
          ano_aviso: aviso.ano,
          tipo_proc: aviso.tipo_proc,
  
          // RECIBO
          genRecibo: true,
          recibo_valor: valorRecibo,
          recibo_cod_condominio: conta.details.cod_condominio,
          recibo_cod_pagador: aviso.cod_pagador,
          rebibo_tipo_doc: 'O',
          recibo_banco: conta.details.banco,
          recibo_conta_bancaria: conta.details.n_conta,
          recibo_n_documento: data.n_doc_pagam,
          recibo_tipo_pagador: 'C',
          recibo_obs: data.recibo_email_msg && data.recibo_email_msg.trim() !== '' ? data.recibo_email_msg : null,
          recibo_criado_por: this.userSession.getUserId(),
          recibo_criado_em: new Date(),
          recibo_nome_condomino: aviso.condomino_nome,
          recibo_fraccao_cod: aviso.cod_fraccao,
          recibo_fraccao_descricao: aviso.fraccao_nome,
          recibo_envio_carta: data.recibo_print ? '1' : '0',
          recibo_envio_email: data.recibo_email ? data.email : null,
          linhas_recibo_tipo_doc: (aviso.tipo !== 'C') ? 'A' : 'C',
        };
        movimentos.push(movimento);
      });
  
      let movAux = movimentos[0];
  
      if (this.savingPayment) {
        resolve(false);
        return;
      }
      this.savingPayment = true;
  
      this.api.saveMovimentosList('RECEBIMENTOS', movimentos).subscribe(async res => {
        if (res.hasOwnProperty('success') && res['success']) {
  
          let isReciboPendente = res.isPendente;
          if (isReciboPendente && (data.recibo_print || data.recibo_email)) {
            this.toastr.info(this.appConfig.errMsg.reconciliacaoBancaria.onlyCommunicateAfterReconciliacao.msg, this.appConfig.errMsg.reconciliacaoBancaria.onlyCommunicateAfterReconciliacao.title,{ timeOut: 8000 });
          }
          
          // REGISTO DE ACTIVIDADE
          if (res.hasOwnProperty('data') && Array.isArray(res['data']) && res.data[0].hasOwnProperty('obj') && res.data[0]['obj']) {
            let aux = JSON.parse(res.data[0]['obj']);
            let obj = { link: aux.recibo_id, cod: aux.recibo_cod };
            let descricao = 'Condomínio: ' + ((this.selCondominio.hasOwnProperty('cod')) ? (this.selCondominio.cod + ' - ' + this.selCondominio.nome) : (this.selCondominio.name));
            let codCondominio = (this.selCondominio.hasOwnProperty('value')) ? this.selCondominio.value.cod : this.selCondominio.cod;
            this.api.saveRegistoActividade(codCondominio, 'RECIBO', aux.n_recibo, 'Recibo Criado', descricao, obj).subscribe(res => {}, err => {});  
          }
  
          let toDeleteAux = this.quotasList.filter(el => ( !(!el.checked || (el.checked && el.tipo ==='C' && Number(el.debito) !== Number(el.valor)) || Number(el.debito) !== Number(el.valor)) ));
          
          this.quotasList = this.quotasList.filter(el => (!el.checked || (el.checked && el.tipo ==='C' && Number(el.debito) !== Number(el.valor)) || Number(el.debito) !== Number(el.valor)));
          this.quotasList.forEach(el => {
            if (Number(el.debito) !== 0) {
              el.valor = Number(el.valor) - Number(el.debito);
              el.debito = 0;
              el.checked = false;
            }
          });
  
          // REMOVE AVISOS/CREDITOS LIQUIDADOS FROM ORIG LIST
          toDeleteAux.forEach(el => {
            this.quotasListOrig = this.quotasListOrig.filter(el1 => (el1.id !== el.id));
          });
  
          let credito = null;
          if (createCredito) {
            let creditoValor = Number(this.aPagarTotal) - Number(this.valorQuotasTotal); 
            credito = await this.creditos.saveCredito(null,null,movAux.recibo_cod_condominio,movAux.aviso_cod_fraccao,movAux.recibo_cod_pagador,null,'C', null, this.descricaoCredito,data.dt_pag_valor, data.dt_pag,conta.details.banco,conta.details.n_conta,creditoValor,conta.details.cod, null, nomeCondomino, null);
            if (!!credito) {
              this.toastr.success('Crédito criado no valor de ' + this.utils.cleanDecimalDigits(credito.valor) + ' €.', 'Alerta', { timeOut: 4000 });
            }
          }
          // GET RECIBO INFO
          if ((data.recibo_print || data.recibo_email) && res.hasOwnProperty('recibo_id')) {
            await this.getReciboDetails(res.recibo_id, !isReciboPendente && data.recibo_print, !isReciboPendente && data.recibo_email, data.email, credito);
          }
          this.computeQuotasTotals();
  
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
        }
        
        this.loading = false;
        this.savingPayment = false;
        resolve(false);  
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loading = false;
        this.savingPayment = false;
        resolve(false);  
      });
    })
    
  }

  useCredito(aviso, creditoToDebit=null) {
    creditoToDebit = Number(creditoToDebit);

    if (isNaN(creditoToDebit)) return;
    creditoToDebit = this.utils.cleanDecimalDigits(creditoToDebit);

    let quotas = creditoToDebit > 0 ? this.quotasList : this.quotasList.slice().reverse();

    var BreakException = {};
    try {
      quotas.forEach((quota, i) => {
        if (quota.tipo === 'A') {
  
          if (creditoToDebit) {
            // if (creditoToDebit <= 0) { 
            //   let diff = creditoToDebit + Number(quota.debito);
            //   if (diff)
  
            //   if (quota.debito > 0) {
            //     quota.debito = 0;
            //     quota.checked = true;
            //   }
            //   continue;
            // }
  
            let diff = creditoToDebit > 0 ? Number(quota.valor) - Number(quota.debito) : -1 * Number(quota.debito);
            diff = this.utils.cleanDecimalDigits(diff);
            
            if ((creditoToDebit > 0 && creditoToDebit >= diff) || (creditoToDebit <= 0 && creditoToDebit < diff)) {
  
              quota.debito += diff;
              quota.debito = this.utils.cleanDecimalDigits(quota.debito);
              quota.checked = quota.debito > 0;
              creditoToDebit = this.utils.cleanDecimalDigits(creditoToDebit - diff);
  
            } else {
              quota.debito += creditoToDebit;
              quota.debito = this.utils.cleanDecimalDigits(quota.debito);
              quota.checked = quota.debito > 0;
              creditoToDebit = 0;
              throw BreakException;
            }
          }
        }
      });
    } catch (err) {
      if (err !== BreakException) throw err;
    }

    if (creditoToDebit > 0) {
      aviso.debito -= creditoToDebit; 
    }
    aviso.debito = this.utils.cleanDecimalDigits(aviso.debito);
  }
  selectAviso(aviso, event) {
    if (event) event.preventDefault();
    setTimeout(() => {
      aviso.checked = !aviso.checked;
      this.filterDate = null;
      aviso.debito = aviso.checked? aviso.valor : 0.00;
      this.debitoChanges(aviso);
    });
  }

  debitoChanges(aviso, aPagarUpdate=true) {
    aviso.debito = this.utils.cleanDecimalDigits(aviso.debito);
    setTimeout(() => {

      // RESET AVISOS
      if (aviso.tipo === 'C') {
        let creditoInUse = 0;
        let totalQuotas = 0;
        this.quotasList.filter(el => !!el.checked).forEach(el => {
          if (el.tipo === 'C') creditoInUse += el.debito;
          if (el.tipo === 'A') totalQuotas += el.debito;
        });
        aviso.checked = aviso.debito !== '' && aviso.debito[0] !== '0' && aviso.debito !== 0;
        let toPay = this.utils.cleanDecimalDigits(creditoInUse - totalQuotas);
        toPay = this.utils.cleanDecimalDigits(toPay);
        if (aviso.checked && toPay > 0) {
          if (toPay > aviso.debito) {
            toPay = this.utils.cleanDecimalDigits(aviso.debito);
          }
          this.useCredito(aviso, toPay);
        } else if (!aviso.checked && toPay < 0) {
          if (Math.abs(toPay) > aviso.valor) {
            toPay = this.utils.cleanDecimalDigits(-1 * aviso.valor);
          }
          this.useCredito(aviso, toPay);
        }
        // if (toPay > 0 && toPay > aviso.debito) {
        //   toPay = aviso.debito;
        // } else if (toPay < 0 && Math.abs(toPay) > aviso.valor) {
        //   toPay = -1 * aviso.valor;
        // }
        
        
        
        // aviso.checked = aviso.debito !== '' && aviso.debito[0] !== '0' && aviso.debito !== 0;
        // this.quotasList.filter(el => el.checked && el.tipo === 'C').forEach(el => {
        // });
      }

      aviso.checked = aviso.debito !== '' && aviso.debito[0] !== '0' && aviso.debito !== 0;

      this.computeQuotasTotals(aPagarUpdate);
    });
  }

  computeQuotasTotals(aPagarUpdate=true) {
    let today = new Date();

    this.valorQuotasTotal = 0;
    this.aLiquidarTotal = 0;
    if (aPagarUpdate) this.aPagarTotal = 0;
    this.aLiquidarTotalToDate = 0;

    this.quotasList.forEach((el, i) => {
      el.valor = Number(el.valor);
      el.debito = Number(el.debito);

      this.valorQuotasTotal += (el.tipo === 'C') ? 0 : el.valor;
      this.aLiquidarTotal += (el.tipo === 'C') ? 0 : el.debito;

      if (aPagarUpdate) this.aPagarTotal += (el.tipo === 'C') ? -1 * el.debito : el.debito;

      if (aPagarUpdate) this.aPagarTotal = Math.round(this.aPagarTotal * 100) / 100;

      if (el.dt_emissao <= today) this.aLiquidarTotalToDate += (el.tipo === 'C') ? 0 : el.valor;

      if (this.valorQuotasTotal < 0) this.valorQuotasTotal = 0;
    });
  }

  aPagarChanged(ev) {
    let aPagarTotal = ev;
    aPagarTotal = Number(aPagarTotal);
    
    
    let payedWithCreditos = this.quotasList.filter(aviso => !!aviso.checked && aviso.tipo === 'C').reduce((acc, el) => acc + el.debito, 0);
    aPagarTotal += payedWithCreditos;
    aPagarTotal = this.utils.cleanDecimalDigits(aPagarTotal);

    let totalToPay = 0;
      
    this.quotasList.filter(aviso => aviso.tipo !== 'C').forEach(aviso => {
      let diff = this.utils.cleanDecimalDigits(aPagarTotal - totalToPay);
      if (diff > 0) {
        aviso.checked = true;
        if (diff > aviso.valor) {
          aviso.debito = this.utils.cleanDecimalDigits(aviso.valor);
          totalToPay += aviso.valor;
        } else {
          aviso.debito = this.utils.cleanDecimalDigits(diff);
          totalToPay = aPagarTotal;
        }
      } else {
        aviso.debito = 0;
        aviso.checked = false;
      }
    });
    totalToPay = this.utils.cleanDecimalDigits(totalToPay);
    // let totalCreditos = 0;
    // this.quotasList.filter(aviso => aviso.tipo === 'C').forEach(aviso => {
    //   let diff = totalToPay - totalCreditos;
    //   if (diff > 0) {
    //     aviso.checked = true;
    //     aviso.debito = aviso.valor < diff ? aviso.valor : diff;
    //     aviso.debito = this.utils.cleanDecimalDigits(aviso.debito);
    //     totalCreditos = this.utils.cleanDecimalDigits(totalCreditos + aviso.debito);
    //   } else {
    //     aviso.checked = false;
    //     aviso.debito = 0;
    //   }
    // });


    // if (aPagarTotal || aPagarTotal === 0) {
    //   aPagarTotalAux = aPagarTotal;

    //   let credito = this.quotasList.find(el => (el.checked && el.tipo === 'C'));
    //   if (credito) {
    //     this.debitoChanges(credito, false);
    //   }

    //   for (let i = 0; i < this.quotasList.length; i++) {
    //     aPagarTotal = Math.round(aPagarTotal * 100) / 100;

    //     this.quotasList[i].valor = Number(this.quotasList[i].valor);
    //     this.quotasList[i].debito = Number(this.quotasList[i].debito);

    //     if (this.quotasList[i].tipo === 'C') continue;

    //     if (aPagarTotal >= 0.01) {
    //       if (this.quotasList.find(el => (el.checked && el.tipo === 'C'))) {

    //         if (this.quotasList[i].valor === this.quotasList[i].debito) continue;

    //         if (this.quotasList[i].debito > 0 && aPagarTotal >= this.quotasList[i].valor - this.quotasList[i].debito) {
  
    //           this.quotasList[i].debito += (this.quotasList[i].valor - this.quotasList[i].debito);
    //           aPagarTotal = aPagarTotal - this.quotasList[i].valor - this.quotasList[i].debito;

    //           this.quotasList[i].checked = ( this.quotasList[i].debito > 0 );
              
    //           this.quotasList[i].debito = Math.round(this.quotasList[i].debito * 100) / 100;
    //           continue;
    //         } else if (this.quotasList[i].debito > 0.01) {

    //           this.quotasList[i].debito += aPagarTotal;
    //           aPagarTotal = 0;
    //           this.quotasList[i].debito = Math.round(this.quotasList[i].debito * 100) / 100;
    //           this.quotasList[i].checked = ( this.quotasList[i].debito > 0 );

    //           continue;
    //         } else {
    //           if (aPagarTotal >= this.quotasList[i].valor) {
    //             this.quotasList[i].debito = this.quotasList[i].valor;
    //             aPagarTotal = aPagarTotal - this.quotasList[i].valor;
    //             this.quotasList[i].checked = ( this.quotasList[i].debito > 0 );
    //           } else {
    //             this.quotasList[i].debito = aPagarTotal;
    //             this.quotasList[i].checked = ( this.quotasList[i].debito > 0 );
    //             aPagarTotal = 0;
    //           }
    //         }
    //       } else {
    //         if (aPagarTotal >= this.quotasList[i].valor) {
    //           this.quotasList[i].debito = this.quotasList[i].valor;
    //           aPagarTotal = aPagarTotal - this.quotasList[i].valor;
    //           this.quotasList[i].checked = ( this.quotasList[i].debito > 0 );
    //         } else {
    //           this.quotasList[i].debito = aPagarTotal;
    //           this.quotasList[i].checked = ( this.quotasList[i].debito > 0 );
    //           aPagarTotal = 0;
    //         }
    //       }

    //     } else {
    //       this.quotasList[i].debito = 0;
    //       this.quotasList[i].checked = false;
    //     }

    //     this.quotasList[i].debito = Math.round(this.quotasList[i].debito * 100) / 100;
    //   }

    //   if (aPagarTotalAux === 0) {

    //     let creditoInUse = this.quotasList.filter(el => (el.checked && el.tipo === 'C'));

    //     let aux = 0;
    //     if (creditoInUse.length > 0) {
    //       creditoInUse.forEach(el => {
    //         aux += Number(el.debito);

    //         this.useCredito(el, Number(aux));
    //       });
    //     }
    //   }

    // } else {
    //   if (!this.quotasList.find(el => (el.checked && el.tipo === 'C'))) this.quotasList.forEach(el => { el.debito = 0; el.checked = false; });
    // }
  }

  resetPage() {
    this.valorTotal = 0;
    this.aLiquidarTotal = 0;
    this.aPagarTotal = 0;
    this.filterDate = null;

    this.quotasList = [];
    this.quotasListOrig = [];
  
    this.fraccaoOpts = [];
    this.fraccaoSelected = null;
  }


  totalEnabled = false;
  filterDateSelected() {
    if (this.filterDate) {
      let totalToPay = 0;
      
      this.quotasList.filter(aviso => aviso.tipo !== 'C').forEach(aviso => {
        if (this.utils.compareDayDates(aviso.dt_emissao, this.filterDate) <= 0) {
          aviso.checked = true;
          aviso.debito = aviso.valor;
          totalToPay += aviso.valor;
        } else {
          aviso.debito = 0;
          aviso.checked = false;
        }
      });
      totalToPay = this.utils.cleanDecimalDigits(totalToPay);
      let totalCreditos = 0;
      this.quotasList.filter(aviso => aviso.tipo === 'C').forEach(aviso => {
        let diff = totalToPay - totalCreditos;
        if (diff > 0) {
          aviso.checked = true;
          aviso.debito = aviso.valor < diff ? aviso.valor : diff;
          aviso.debito = this.utils.cleanDecimalDigits(aviso.debito);
          totalCreditos = this.utils.cleanDecimalDigits(totalCreditos + aviso.debito);
        } else {
          aviso.checked = false;
          aviso.debito = 0;
        }
      });

    }
    if (this.totalEnabled) this.computeQuotasTotals();
  }
  // --------------------------------------------------------------------------

  // RECIBOS METHODS ----------------------------------------------------------
  getLinhasRecibos(allRecibos) {
    return new Promise(resolve => {
      this.api.getLinhasRecibos(allRecibos).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });      
    });
  }

  filterByCreatedAt = false;
  verRemovidos = false;
  recibosQuotas = false;  
  getRecibosList() {
    if (this.recibosQuotas) return;

    if ((!this.selCondominio && !this.allCondominios)) return;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });    

    let startDate = (this.startDate) ? formatDate(this.startDate, this.format, this.locale) : 'NULL';
    let endDate = (this.endDate) ? formatDate(this.endDate, this.format, this.locale) : 'NULL';

    let cod = 'ALL';
    if (!this.allCondominios) {
      cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;
    }

    let filterByCreatedAt = (this.filterByCreatedAt) ? 1 : 0;

    this.recibosQuotas = true;
    this.api.getRecibos(cod, startDate, endDate, filterByCreatedAt).subscribe(async res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.recibosListOrig = res.data;

        let allRecibos = this.recibosListOrig.map(el => el.cod).reduce((a, b) => a + ',' + b, '');
        let lRecibos: any = await this.getLinhasRecibos(allRecibos.substring(1));
        if (!lRecibos) {
          this.computeTotalsRecibos();
          this.filterListRecibos();
          this.recibosQuotas = false;
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
          return;
        }

        this.condominoOpts = [];
        let aux = null;
        this.recibosListOrig.forEach(el => {
          el['n_recibo'] = Number(el['n_recibo']);
          el['dt_valor'] = (el['dt_valor']) ? this.utils.getDate(el['dt_valor']) : null;
          el['data'] = (el['data']) ? this.utils.getDate(el['data']) : null;
          let auxDate = this.utils.getDate(el['criado_em']);
          if (auxDate.toString() === 'Invalid Date') {
            el['criado_em'] = new Date(el['criado_em']);
          } else {
            el['criado_em'] = auxDate;
          }
          el['valor'] = (el['valor']) ? Number(el['valor']) : null;
          el['checked'] = false;

          aux = lRecibos.find(it => it.cod === el.cod);
          el['cod_fraccao'] = (aux) ? aux.cod_fraccao : null;
          el['nome_fraccao'] = (aux) ? aux.nome : null;
          el['diffDates'] = el.data && el.dt_valor ? this.utils.compareDayDates(el.data, el.dt_valor) !== 0 : false;
        });

        let condominosFraccaoSet = [ ...new Set(this.recibosListOrig.map(el => JSON.stringify({ codPagador: el.cod_pagador, codFraccao: el.cod_fraccao }))) ];
        condominosFraccaoSet.forEach(el => {
          el = JSON.parse(el);
          aux = this.recibosListOrig.find(it => it.cod_fraccao === el['codFraccao'] && it.cod_pagador === el['codPagador']);

          if (aux) this.condominoOpts.push({ name: aux.cod_fraccao + ' - ' + aux.nome_fraccao + ' - ' + aux.nome_condomino, value: aux.cod_pagador + '-' + aux.cod_fraccao, codFraccao: aux.cod_fraccao });
        });
        if (this.condominoOpts.length) {
          this.condominoOpts = [{ name: 'Todos', value: false }].concat(
            this.condominoOpts.sort((a, b) => {
              return a.codFraccao.length - b.codFraccao.length || a.codFraccao.localeCompare(b.codFraccao);
            })
          );
        }

        this.recibosList = JSON.parse(JSON.stringify(this.recibosListOrig));

        this.computeTotalsRecibos();

        this.filterListRecibos();
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.recibosQuotas = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.recibosQuotas = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  filterListRecibos() {
    if (this.selCondomino) {
      let codPagador = this.selCondomino.split('-')[0];
      let codFraccao = this.selCondomino.split('-')[1];

      if (!this.verRemovidos) {
        this.recibosList = this.recibosListOrig.filter(el => (el.cod_pagador === codPagador && el.cod_fraccao === codFraccao && el.active === '1'));
      } else {
        this.recibosList = this.recibosListOrig.filter(el => (el.cod_pagador === codPagador && el.cod_fraccao === codFraccao));
      }
    } else {
      if (!this.verRemovidos) {
        this.recibosList = this.recibosListOrig.filter(el => (el.active === '1'));
      } else {
        this.recibosList = JSON.parse(JSON.stringify(this.recibosListOrig));
      }
    }

    this.computeTotalsRecibos();
  }

  computeTotalsRecibos() {
    this.recibosValorTotal = 0;
    this.recibosList.forEach(el => {
      this.recibosValorTotal += (el.valor) ? el.valor : 0;
    });
  }
  // --------------------------------------------------------------------------

  // CREDITOS METHODS ---------------------------------------------------------
  creditoSelected(credito) {
    setTimeout(() => {

      if (!credito.checked) return;

      if (Number(credito.valor) !== Number(credito.credito)) { // CREDITO UTILIZADO (TEM RECIBO EMITIDO)
        this.toastr.warning('Crédito utilizado. Não é possivel eliminar o crédito selecionado.', 'Alerta', { timeOut: 4000 });
        setTimeout(() => { credito.checked = false; }, 100);
      } else {
  
        let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;
    
        this.api.hasCaixaVertisMovimento(cod, 'C', credito.n_credito).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            if (res.status && res.dates) {
              let dates = [];
              res.dates.forEach(el => {
                dates.push(this.utils.getDate(el));
              });
              this.handleDeletePermissions(credito, dates, 'Crédito',
              'Por favor contacte um administrador para eliminar o crédito selecionado.',
              'Por favor contacte o administrador de sistema para eliminar o crédito selecionado.');
            }
          }
        }, err => {});

        // DISABLE OTHER SELECTED ENTRIES
        this.creditosList.filter(el => (el.id !== credito.id)).forEach(el => { el.checked = false; });
      }

    }, 1);
  }

  avisoSelected(aviso) {
    if (!aviso.checked) return;

    if (Number(aviso.debito) > 0) { // CREDITO UTILIZADO (TEM RECIBO EMITIDO)
      this.toastr.warning('Aviso com valores em débito. Não é possivel eliminar o aviso selecionado.', 'Alerta', { timeOut: 4000 });
      setTimeout(() => { aviso.checked = false; }, 1000);
    }
  }

  procSelected(processamento) {
    if (!processamento.checked) return;

    if (this.userSession.isSuperAdmin()) return;

    this.api.getAvisosByProcessamento(processamento.cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (res.data.length > 0) {
          let fraccoesList = res.data.map(el => el.cod_fraccao).reduce((a, b) => a + ((a) ? ', ' : '') + b, '');          

          this.toastr.warning('Processamento associado a Avisos com valores em débito. Não é possivel eliminar o processamento selecionado (Fracções: ' + fraccoesList + ').', 'Alerta', { timeOut: 4000 });
          processamento.checked = false;
        }
      }
    }, err => {});
  }

  selEntryDate = null;
  reciboSelected(recibo) {

    if (!recibo.checked) return;

    this.selEntryDate = this.utils.getDate(recibo.data);

    let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;

    this.api.hasCaixaVertisMovimento(cod, 'R', recibo.n_recibo).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (res.status && res.dates) {
          let dates = [];
          res.dates.forEach(el => {
            dates.push(this.utils.getDate(el));
          });
          this.handleDeletePermissions(recibo, dates, 'Recibo',
          'Por favor contacte um administrador para eliminar o recibo selecionado.',
          'Por favor contacte o administrador de sistema para eliminar o recibo selecionado.');
        }
      }
    }, err => {});


    // DISABLE OTHER SELECTED ENTRIES
    this.recibosList.filter(el => (el.id !== recibo.id)).forEach(el => { el.checked = false; });

  }

  handleDeletePermissions(entry, dates: Date[], type:string, adminText: string, superAdminText: string): void {

    this.selEntryDate = this.utils.handleDeleteCVPermissions(entry, dates, type, adminText, superAdminText);

    if (this.selEntryDate) {
      this.changeCVModalRef = this.modalService.open(this.changeCVAlertConfig)
      .onApprove(() => {
        this.selEntryDate = null;
      })
      .onDeny(() =>  {
        entry.checked = false;
        this.selEntryDate = null;
      });
      return;
    }
    this.selEntryDate = null;
    return;
  }

  verCreditosRemovidos = false;
  creditosQuotas = false;  
  getCreditosList() {
    if (this.creditosQuotas) return;

    if (!this.selCondominio) return;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });    

    let startDate = (this.startDate) ? formatDate(this.startDate, this.format, this.locale) : 'NULL';
    let endDate = (this.endDate) ? formatDate(this.endDate, this.format, this.locale) : 'NULL';

    let cod = null;
    if (this.selCondominio !== '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value !== '-2')) cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;

    // ADD COLUMN FOR COD CONDOMINIO
    if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) {
      if (!this.creditosListCol.find(el => el.key === 'cod_condominio')) this.creditosListCol.splice(1, 0, this.codCondominioCol);
    } else {
      // REMOVE CONDOMINIO COLUMN IF NEEDED
      this.creditosListCol = this.creditosListCol.filter(el => (el.key !== 'cod_condominio'));
    }

    this.creditosQuotas = true;
    this.api.getCreditos(cod, startDate, endDate).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.creditosListOrig = res.data;

        // this.creditosCondominoOpts = [{ name: 'Todos', value: false }];
        this.creditosCondominoOpts = [];
        let seenCondominos = [];

        this.creditosListOrig.forEach(el => {
          el['n_credito'] = Number(el['n_credito']);
          el['data'] = (el['data']) ? this.utils.getDate(el['data']) : null;
          el['valor'] = (el['valor']) ? Number(el['valor']) : null;
          el['credito'] = (el['credito']) ? Number(el['credito']) : null;
          el['checked'] = false;

          // SET LISTA DE CONDOMINOS
          if (seenCondominos.findIndex(cod => (cod === el.cod_pagador)) === -1) {
            seenCondominos.push(el.cod_pagador);

            this.creditosCondominoOpts.push({ name: el.nome_condomino + ' (' + el.cod_fraccao + ' - ' + el.nome_fraccao +')', value: el.cod_pagador });
          }
        });

        this.creditosCondominoOpts = [{ name: 'Todos', value: false }].concat(this.creditosCondominoOpts.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } if (a.name < b.name) {
            return -1;
          } else {
            return 0;
          }
        }));

        // this.creditosList = JSON.parse(JSON.stringify(this.creditosListOrig));
        this.creditosList = this.creditosListOrig;

        this.computeTotalsCreditos();

        // if (this.prevState) { 
          this.filterListCreditos();
        // }
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.creditosQuotas = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.creditosQuotas = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
    });
  }

  filterListCreditos() {
    if (this.creditosSelCondomino) {
      if (!this.verCreditosRemovidos) {
        this.creditosList = this.creditosListOrig.filter(el => (el.cod_pagador === this.creditosSelCondomino && el.active === '1'));
      } else {
        this.creditosList = this.creditosListOrig.filter(el => (el.cod_pagador === this.creditosSelCondomino));
      }
    } else {
      if (!this.verCreditosRemovidos) {
        this.creditosList = this.creditosListOrig.filter(el => (el.active === '1'));
      } else {
        this.creditosList = JSON.parse(JSON.stringify(this.creditosListOrig));
      }
    }

    if (this.creditosSelEstado && this.creditosSelEstado === '1') {
      this.creditosList = this.creditosList.filter(el => (el.credito !== 0));
    }

    this.computeTotalsCreditos();
  }

  computeTotalsCreditos() {
    this.creditosValorTotal = 0;
    this.creditosEmCreditoTotal = 0;

    this.creditosList.forEach(el => {
      this.creditosValorTotal += (el.valor) ? el.valor : 0;
      this.creditosEmCreditoTotal += (el.credito) ? el.credito : 0;
    });
  }
  // --------------------------------------------------------------------------

  // RECEITAS METHODS ---------------------------------------------------------
  receitasQuotas = false;  
  getReceitas() {
    if (this.receitasQuotas) return;

    // if (!this.selCondominio || (!this.startDate && !this.endDate)) return;
    if (!this.selCondominio) return;

    let startDate = (this.startDate) ? formatDate(this.startDate, this.format, this.locale) : 'NULL';
    let endDate = (this.endDate) ? formatDate(this.endDate, this.format, this.locale) : 'NULL';

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });    

    let cod = null;
    if (this.selCondominio !== '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value !== '-2')) cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;

    // ADD COLUMN FOR COD CONDOMINIO
    if (this.selCondominio === '-2' || (this.selCondominio && this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === '-2')) {
      if (!this.receitasListCol.find(el => el.key === 'cod_condominio')) this.receitasListCol.splice(1, 0, this.codCondominioCol);
    } else {
      // REMOVE CONDOMINIO COLUMN IF NEEDED
      this.receitasListCol = this.receitasListCol.filter(el => (el.key !== 'cod_condominio'));
    }

    this.receitasQuotas = true;
    this.api.getReceitas(cod, startDate, endDate).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        // RECEITAS
        this.receitasListOrig = res.data;

        // this.receitasFornecedorSelected = [{ name: 'Todos', value: false }];
        this.receitasFornecedoresOpts = [{ name: 'Todos', value: false }];
        let seenFornecedores = [];
        this.receitasListOrig.forEach(el => {
          el['n_receita'] = Number(el['n_receita']);
          el['dt_desp'] = (el['dt_desp']) ? this.utils.getDate(el['dt_desp']) : null;
          el['dt_pag'] = (el['dt_pag']) ? this.utils.getDate(el['dt_pag']) : null;
          el['valor'] = (el['valor']) ? Number(el['valor']) : null;
          el['checked'] = false;

// el['status'] = (el['dt_pag']) ? true : false;
// el['pag_parcial'] = (el['pag_parcial']) ? Number(el['pag_parcial']) : null;
// el['a_liquidar'] = el['valor'] - el['pag_parcial'];

          el['a_liquidar'] = (el['from_gecond'] === '1' && el['dt_pag']) ? 0 : el['valor'] - el['valor_liquidado'];
          el['status'] = (el['a_liquidar'] === 0) ? true : false;

          // SET LISTA DE FORNECEDORES
          if (seenFornecedores.findIndex(cod => (cod === el.cod_fornecedor)) === -1) {
            // ADD FORNECEDOR TO SEEN STRING
            seenFornecedores.push(el.cod_fornecedor);

            this.receitasFornecedoresOpts.push({ name: el.nome_fornecedor, value: el.cod_fornecedor });
          }
        });

        // this.receitasList = JSON.parse(JSON.stringify(this.receitasListOrig));

        // this.computeTotalsReceitas();

        // if (this.prevState) { 
          this.filterListReceitas();
        // }
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.receitasQuotas = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
    }, err => {
      this.receitasQuotas = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });    
    });
  }

  filterListReceitas() {
    if (this.receitasEstadoSelected === '0' || !this.receitasEstadoSelected) {  // TODAS
      this.receitasList = JSON.parse(JSON.stringify(this.receitasListOrig));
    }

    if (this.receitasEstadoSelected === '1') {  // LIQUIDADAS
      this.receitasList = this.receitasListOrig.filter(el => (el.status));
    }

    if (this.receitasEstadoSelected === '2') {  // POR LIQUIDAR
      this.receitasList = this.receitasListOrig.filter(el => (el.status));
    }

    if (this.receitasFornecedorSelected) {
      this.receitasList = this.receitasList.filter(el => (el.cod_fornecedor === this.receitasFornecedorSelected));
    }

    this.computeTotalsReceitas();
  }

  computeTotalsReceitas() {
    this.receitasValorTotal = 0;
    this.receitasValorParcialTotal = 0;
    this.receitasList.forEach(el => {
      this.receitasValorTotal += (el.valor) ? el.valor : 0;
      this.receitasValorParcialTotal += (el.a_liquidar) ? el.a_liquidar : 0;
    });
  }

  receitaSelected(receita) {
    setTimeout(() => {

      if (!receita.checked) return;

      let cod = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : this.selCondominio.value.cod;
  
      this.api.hasCaixaVertisMovimento(cod, 'RE', receita.n_receita).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.status) {
  
            if (this.userSession.isSuperAdmin()) {
              this.toastr.warning('Receita registada em Caixa Vertis.', 'Atenção', { timeOut: 4000 });
            } else {
              this.toastr.warning('Receita registada em Caixa Vertis. Por favor contacte o administrador de sistema para eliminar a receita selecionada.', 'Alerta', { timeOut: 4000 });
              receita.checked = false;
            }
          }
        }
      }, err => {});

    }, 1);
  }
  // --------------------------------------------------------------------------

  // CAIXA VERTIS FUNTIONS/VARIABLES SECTION ----------------------------------
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode == 13) {
      if (this.loginModalOpened) { this.loginCaixa(); }
    }
  }

  @ViewChild('loginCaixaAlertRef', { static: false }) loginCaixaAlertRef;
  loginCaixaModalRef = null;
  loginCaixaAlertConfig: any = null;

  @ViewChild('alredyLoggedAlertRef', { static: false }) alredyLoggedAlertRef;
  alredyLoggedModalRef = null;
  alredyLoggedAlertConfig: any = null;
  
  @ViewChild('changeCVAlertRef', { static: false }) changeCVAlertRef;
  changeCVModalRef = null;
  changeCVAlertConfig: any = null;

  loggedUser = null;
  unauthorized = false;

  username = null;
  password = null;

  loginModalOpened = false;

  loginCaixa(force='0') {
    if (!this.username && !this.password) return;

    this.loadingModal = true;
    this.api.loginCaixa(this.username, this.password, force).subscribe(res => {
      if (res['success'] && res.success) {
        if (res.hasOwnProperty('data') && res.data.hasOwnProperty('id')) {
          this.loggedUser = res.data.first_name + ' ' + res.data.last_name;

          this.loginCaixaModalRef.approve();

          this.alredyLoggedModalRef = this.modalService
            .open(this.alredyLoggedAlertConfig)
            .onApprove(() => { this.loginCaixa('1'); this.loadingModal = false; })
            .onDeny(() => { this.loadingModal = false; });

        } else {
          this.userSession.setCaixaVertisState('login');

          this.loginCaixaModalRef.approve();
          this.toastr.success('Login efectuado com sucesso.', 'Caixa Vertis', { timeOut: 4000 });

          this.username = null;
          this.password = null;
        }

        // REGISTO ACTIVIDADES API CALL
        let descricao = 'Login Caixa Vertis efectuado.';
        this.api.saveRegistoActividade(null, null, null, null, descricao).subscribe(res => {}, err => { });

      } else {
        this.utils.apiErrorMsg(res);

        this.unauthorized = true;
        this.password = null;
        setTimeout(() => { this.unauthorized = false; }, 4000);
      }
      this.loadingModal = false;
    }, err => {
      this.loadingModal = false;
    });
  }
  // --------------------------------------------------------------------------

  // EMAIL MODULE VARIABLES AND METHODS .......................................
  emailConfigs = {
    startDate: null,
    endDate: null,
  };

  setEmailsConfigs() {
    this.emailConfigs = {
      startDate: this.startDate,
      endDate: this.endDate,
    };      
  }

  codCondominio = null;
  nomeCondominio = null;
  setCodCondominio() {
    if (!this.selCondominio) {
      this.codCondominio = null;
      this.nomeCondominio = null;
      return;          
    }

    if (this.selCondominio.hasOwnProperty('cod') && this.selCondominio.cod) {
      this.codCondominio = this.selCondominio.cod;
      this.nomeCondominio = this.selCondominio.nome;
    } else if (this.selCondominio.hasOwnProperty('value') && this.selCondominio.value && this.selCondominio.value.hasOwnProperty('cod') && this.selCondominio.value.cod ) {
      this.codCondominio = this.selCondominio.value.cod;
      this.nomeCondominio = this.selCondominio.value.nome;
    }
  }
}
