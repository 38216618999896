import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { UserSessionService } from '../user-session.service';
import { UtilitiesService } from '../utilities.service';

@Injectable({
  providedIn: 'root'
})
export class MovimentosService {

  constructor(
    public api:ApiService,
    public toastr:ToastrService,
    public appConfig: AppConfigService,
    public utils:UtilitiesService,
    public userSession:UserSessionService,
  ) { }


  canChangeMovimento(id_mov):Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.canChangeMovimento(id_mov).subscribe(res => {
        if (res.success) {
          if (!!res.data) {
            let toastMsg = res.data !== this.userSession.getUserId() ? this.appConfig.warningCodes['WARN_REC_001'] : this.appConfig.warningCodes['WARN_REC_002'];
            this.toastr.warning(toastMsg.msg, toastMsg.title);
          }
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        resolve(false);
      })
    });
  }
}
