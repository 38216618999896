import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { EmailSent } from '../business-model-interfaces/email';

@Component({
  selector: 'app-view-sent-email-modal',
  templateUrl: './view-sent-email-modal.component.html',
  styleUrls: ['./view-sent-email-modal.component.scss']
})
export class ViewSentEmailModalComponent implements OnInit {

  email:EmailSent = null;
  footer:string = null;
  selDestinatario:{cod_fracao,cod, id_texto, data_divida} = null;
  destinatariosOpts:Array<{name,value}> = [];
  
  @ViewChild('emailAlertRef', { static: false }) emailAlertRef;
  emailModalRef = null;
  emailAlertConfig: any = null;
  
  
  constructor(public modalService: SuiModalService,
    public api: ApiService,
    public utils: UtilitiesService,
    public cdRef:ChangeDetectorRef,
    public toastr: ToastrService,
    public appConfig: AppConfigService,
    public message: MessageService,

    ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.emailModalRef) this.emailModalRef.deny();
  }

  ngAfterViewInit() {
    this.emailAlertConfig = new TemplateModalConfig<IContext, string, string>(this.emailAlertRef);
    this.emailAlertConfig.closeResult = "closed";
    this.emailAlertConfig.size = 'normal';
    this.emailAlertConfig.transition = 'fade';
    this.emailAlertConfig.transitionDuration = 250;
  }

  loadingModal = false;
  async open(id:number) {
    this.email = null;
    this.selDestinatario = null;
    this.destinatariosOpts = [];
    this.loadingModal = true;
    this.emailModalRef = this.modalService.open(this.emailAlertConfig).onApprove(() => {}).onDeny(() => {});
    let res = await this.getEmailData(id);
    this.cdRef.detectChanges();
    this.loadingModal = false;
    if (!res) {
      this.emailModalRef.deny();
      return;
    }
  }

  getEmailData(id:number):Promise<boolean> {
    return new Promise((resolve) => {
      this.api.getEmailData(id).subscribe(async res => {
        if (res.success) {
          this.footer = this.utils.getEmailFooterSimple(null, { name: res.data.nome_utilizador, email: res.data.email_utilizador });

          let anexos = [];
          res.data.anexos.forEach(el => {
            anexos.push({...el, loading: false});
          });
          this.email = {
            ...res.data,
            data_dados: this.utils.getDate(res.data.data_dados, undefined, undefined, true),
            anexos: anexos
          }
          if (this.email.text_inicial && this.email.texto_final) {
            if (this.email.text_inicial.indexOf('NEW_LINE') != -1) {
              this.email.text_inicial = (this.email.text_inicial as string).split('NEW_LINE');
            }
            if (this.email.text_inicial.indexOf('\n') != -1) {
              this.email.text_inicial = (this.email.text_inicial as string).split('\n');
            }
            if (!Array.isArray(this.email.text_inicial)) this.email.text_inicial = [this.email.text_inicial]; 

            if (this.email.texto_final.indexOf('NEW_LINE') != -1) {
              this.email.texto_final = (this.email.texto_final as string).split('NEW_LINE');
            } else if (this.email.texto_final.indexOf('\n') != -1) {
              this.email.texto_final = (this.email.texto_final as string).split('\n');
            } 
            if (!Array.isArray(this.email.texto_final)) this.email.texto_final = [this.email.texto_final]; 
            
            this.email.destinatarios.forEach(dest => {
              this.destinatariosOpts.push({name: dest.nome_entidade, value: {cod_fracao: dest.cod_fracao, cod:dest.cod_entidade, id_texto: dest.id_texto, data_divida: this.utils.getDate(dest.data_divida, undefined, undefined, true)}});
            });
            this.selDestinatario = this.destinatariosOpts[0].value;
            await this.getContaCorrente();
            resolve(true);
          }
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false)
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  getContaCorrente():Promise<any> {
    return new Promise((resolve) => {
      if (!this.selDestinatario) return;
      let cod_fracao = this.selDestinatario.cod_fracao;
      let cod_condomino = this.selDestinatario.cod;
      let reconciliacao_date = this.selDestinatario.data_divida;
      this.api.getContaCorrente('fraccoes', 1, this.email.cod_condominio, [cod_fracao], null, this.email.data_dados,1, this.email.data).subscribe(res => {
        if (res.success && res.data.length) {
          res.data = res.data.filter(el => el.condomino_cod == cod_condomino && el.saldo != 0 && el.tipo_doc === 'A');
          res.data.forEach(el => {
            el['fraccao'] = el.cod_fraccao + ' - ' + el.fraccao_nome;
            el['n_aviso'] = el.n_doc;
            el['dt_emissao'] = el.data_emis;
            el['dt_vencimento'] = el.data_venc;
            el['divida'] = el.saldo;
          });
          if (this.selDestinatario.id_texto !== '8') res.data = res.data.filter(av => (av.tipo_proc === 'A' || this.utils.compareDayDates(this.utils.getDate(av['dt_vencimento']), this.email.data_dados) <= 0));
          let nome = res.data.length ? res.data[0].condomino_nome : null;
          this.email.corpo = this.utils.getAvisosBody(this.email.text_inicial as Array<string>, this.email.texto_final as Array<string>, {nif: this.email.nif_condominio, nib: this.email.nib_condominio, nome: nome, avisos: res.data as any}, {reconciliation_date: reconciliacao_date});
          this.email.corpo += this.footer;
          this.cdRef.detectChanges();
        }
        resolve(true);
      }, err => {
        resolve(false);
      });
    });
  }

  getAnexoBase64(idAnexo): Promise<string> {
    return new Promise((resolve) => {
      let index = this.email.anexos.findIndex(anexo => anexo.id == idAnexo);
      this.email.anexos[index].loading = true;
      this.api.getFile(idAnexo).subscribe(res => {
        if (res.success) {
          resolve(res.data.file);
        } else {
          this.toastr.error('Não foi possível obter os anexos do email.', 'Ups...!');
        }
        this.email.anexos[index].loading = false;
        resolve(null);
      }, err => {
        this.toastr.error('Não foi possível obter os anexos do email.', 'Ups...!');
        this.email.anexos[index].loading = false;
        resolve(null);
      });
    });
  }

  async downloadAnexo(anexo) {
    let contentType = 'data:';
    switch (anexo.ext) {
      case '.pdf':
        contentType += 'application/pdf;';
        break;
      case '.jpeg':
        contentType += 'image/jpeg;';
        break;
      case '.jpg':
        contentType += 'image/jpg;';
        break;
      case '.png':
        contentType += 'image/png;';
        break;
      default:
        break;
    }
    contentType += 'base64,';
    let base64 = await this.getAnexoBase64(anexo.id);
    if (!base64) return;

    this.utils.downloadFile(contentType + base64, anexo.filename);
    
  }
}