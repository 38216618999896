import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {

  previousUrl: string;
  currentUrl: string;

  constructor(public router: Router) {

    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });

  }

  getPreviousUrl() {
    return this.previousUrl;
  }

}
