import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
interface IContext {
  data: string;
}
import { ToastrService } from 'ngx-toastr';
import { forkJoin, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { UserSessionService } from '../user-session.service';
import { UtilitiesService } from '../utilities.service';
import { SaveAssembleiaOrdemTrabalho, SelAno } from '../business-model-interfaces/assembleias';
import { OrdemTrabalhoConfig } from '../configurations';
import { CondominiosService } from '../business-logic-services/condominios.service';
import { AgendamentoAssembleiasDetails, SaveAgendamentoAssembleia } from '../business-model-interfaces/agendamento-assembleias';
import { AnoSemContasAprovadas } from '../api-requests';
import { bit } from '../business-model-interfaces/application';
import { AssembleiaOtSelectYearsComponent, SelAnoOpt } from '../assembleia-ot-select-years/assembleia-ot-select-years.component';

interface CondominioRow {
  checked: boolean,
  cod: string,
  id: string,
  morada: string,
  nome: string,
  nomeCondominio: string,
}

interface OrdemTrabalhoOpt {
  name: string,
  value: OrdemTrabalhoConfig,
  checked: boolean,
  sufix: string,
  selAnos: SelAno[]
}

interface AssembleiaRow {
  cod: number,
  nomeCondominio: string,
  morada: string
  id: number,
  id_agendamento_assembleias_assembleia: number,
  extraordinaria: string
  data: Date
  ordensTrabalho: OrdemTrabalhoOpt[],
  apresentacaoContas: AnoSemContasAprovadas[],
  orcamentosAprovados: number[], // Anos
  utilizador: string,
  nomeUtilizador: string
  ordered: boolean,
  anosOTError: boolean,
  visible
  edit
  slideUp
}

interface AssembleiaRowFiltered extends AssembleiaRow {
  orig_index: number
}

@Component({
  selector: 'app-assembleias-agendamento',
  templateUrl: './assembleias-agendamento.component.html',
  styleUrls: ['./assembleias-agendamento.component.scss']
})
export class AssembleiasAgendamentoComponent implements OnInit {

  now = new Date();

  transitionController = new TransitionController();

  condominiosListCol = [
    { key: 'nomeCondominio', name: 'Nome', type: 'text', searchable: true, class: '' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class: 'col-align-center' },
  ];
  condominiosListOrig: CondominioRow[] = [];
  condominiosList: CondominioRow[] = [];

  ordemTrabOptsOrig: OrdemTrabalhoOpt[] = [];
  ordemTrabOpts: OrdemTrabalhoOpt[] = [];
  itemOrdemTrabOpts: OrdemTrabalhoOpt[] = [];

  extraordinaria = '0';

  utilizadoresOpts = [];

  assembleiasList: AssembleiaRow[] = [];

  loading = false;
  searching = false;

  @ViewChild('condominiosAlertRef', { static: false }) condominiosAlertRef;
  condominiosModalRef = null;
  condominiosAlertConfig: any = null;

  condominiosSearchSubs = null;
  @ViewChild('condominiosSearchRef', { static: false }) condominiosSearchRef: ElementRef;

  @ViewChild('assembleiaParamsAlertRef', { static: false }) assembleiaParamsAlertRef;
  assembleiaParamsModalRef = null;
  assembleiaParamsAlertConfig: any = null;

  @ViewChild('ordensTrabalhoAlertRef', { static: false }) ordensTrabalhoAlertRef;
  ordensTrabalhoModalRef = null;
  ordensTrabalhoAlertConfig: any = null;

  @ViewChild('lancarAlertRef', { static: false }) lancarAlertRef;
  lancarModalRef = null;
  lancarAlertConfig: any = null;

  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('scrollContainerFiltered', { static: false }) scrollContainerFiltered: ElementRef;

  selUtilizador = null;
  startDateAgendamento = null;
  endDateAgendamento = null;
  filterMode = false;
  assembleiasListFiltered: AssembleiaRowFiltered[] = [];

  keyword = null;
  assembleiasSubs = null;
  @ViewChild('assembleiasSearchRef', { static: false }) assembleiasSearchRef: ElementRef;

  agendId = null;
  estado: bit = null;
  name = null;
  ordensTrabalho: AgendamentoAssembleiasDetails['ordens_trabalho'] = [];

  onlyUtilizadores = false;

  constructor(private elem: ElementRef,
    public modalService: SuiModalService,
    public route: ActivatedRoute,
    public toastr: ToastrService,
    public api: ApiService,
    public userSession: UserSessionService,
    public appConfig: AppConfigService,
    public condominios: CondominiosService,
    public utils: UtilitiesService,
    public message: MessageService) { }

  public animate(transitionName: string = "fade up") {
    this.transitionController.animate(new Transition(transitionName, 400, TransitionDirection.In));
  }

  searchAllWords(str, keywords) {
    for (let i = 0; i < keywords.length; i++) {
      if (str.indexOf(keywords[i]) === -1) return false;
    }
    return true;
  }

  ngOnInit() {
    this.now = new Date();

    this.animate();

    if (this.route.snapshot.params.id !== 'criar') {
      this.agendId = this.route.snapshot.params.id;
    }

    // GET INITIAL DATA
    this.init();
  }

  ngAfterViewInit() {
    this.assembleiasSubs = fromEvent(this.assembleiasSearchRef.nativeElement, 'keyup').pipe(debounceTime(500)).subscribe(async val => {
      this.keyword = val['target']['value'].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();

      this.searching = true;
      await this.filterAgendamento();
      this.searching = false;
    });

    this.condominiosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.condominiosAlertRef);
    this.condominiosAlertConfig.closeResult = "closed";
    this.condominiosAlertConfig.isClosable = false;
    this.condominiosAlertConfig.size = 'normal';
    this.condominiosAlertConfig.transition = 'fade';
    this.condominiosAlertConfig.transitionDuration = 250;

    this.assembleiaParamsAlertConfig = new TemplateModalConfig<IContext, string, string>(this.assembleiaParamsAlertRef);
    this.assembleiaParamsAlertConfig.closeResult = "closed";
    this.assembleiaParamsAlertConfig.isClosable = false;
    this.assembleiaParamsAlertConfig.size = 'small';
    this.assembleiaParamsAlertConfig.transition = 'fade';
    this.assembleiaParamsAlertConfig.transitionDuration = 250;

    this.ordensTrabalhoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.ordensTrabalhoAlertRef);
    this.ordensTrabalhoAlertConfig.closeResult = "closed";
    this.ordensTrabalhoAlertConfig.isClosable = false;
    this.ordensTrabalhoAlertConfig.size = 'small';
    this.ordensTrabalhoAlertConfig.transition = 'fade';
    this.ordensTrabalhoAlertConfig.transitionDuration = 250;

    this.lancarAlertConfig = new TemplateModalConfig<IContext, string, string>(this.lancarAlertRef);
    this.lancarAlertConfig.closeResult = "closed";
    this.lancarAlertConfig.isClosable = false;
    this.lancarAlertConfig.size = 'mini';
    this.lancarAlertConfig.transition = 'fade';
    this.lancarAlertConfig.transitionDuration = 250;
    this.ordensTrabalhoAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    if (this.condominiosSearchSubs) this.condominiosSearchSubs.unsubscribe();
    if (this.assembleiasSubs) this.assembleiasSubs.unsubscribe();

    if (this.condominiosModalRef) this.condominiosModalRef.deny();
    if (this.assembleiaParamsModalRef) this.assembleiaParamsModalRef.deny();
    if (this.ordensTrabalhoModalRef) this.ordensTrabalhoModalRef.deny();
    if (this.lancarModalRef) this.lancarModalRef.deny();
  }

  itemSize = 120;

  init() {
    // FETCH ALL INITIAL DATA
    let req = [
      this.api.getAllActiveCondominios(),
      this.api.getOrdemTrabalhos(),
      this.api.getUtilizadores(0, 500),
    ];

    if (this.agendId) {
      req.push(this.api.getAssembleiaAgendamento(this.agendId));
    }
    forkJoin(req).subscribe(
      res => {
        // CONDOMINIOS
        if (res[0].hasOwnProperty('success') && res[0].success) {
          this.condominiosListOrig = res[0].data.map(el => {
            el['nomeCondominio'] = `${el.cod} - ${el.nome}`;
            el['checked'] = false;

            return el;
          });
        }

        // ORDENS DE TRABALHO
        if (res[1].hasOwnProperty('success') && res[1].success) {
          this.ordemTrabOptsOrig = res[1].data.map(el => { return { name: el.descricao, value: el, checked: false }; });

          // SET DEFAULT ORDENS DE TRABALHO
          this.ordemTrabOpts = this.ordemTrabOptsOrig.map(el => {
            el.checked = (el.value.ordinarias_predefinido == 1);

            return el;
          });
        }

        // UTILIZADORES
        if (res[2].hasOwnProperty('success') && res[2].success) {
          this.utilizadoresOpts = [{ name: '--- Limpar seleção ---', value: '-1' }].concat(res[2].data.map(el => { return { name: el.first_name + ' ' + el.last_name, value: el.id }; }));
        }

        if (res.length > 3 && res[3].hasOwnProperty('success') && res[3].success) {
          let agendDetails: AgendamentoAssembleiasDetails = res[3].data;
          this.estado = agendDetails.estado;
          this.name = agendDetails.nome;
          this.ordensTrabalho = agendDetails.ordens_trabalho;

          let assembleiasList: AssembleiaRow[] = [];
          agendDetails.assembleias.forEach((assemb, i) => {
            let ordensTrabalhos: OrdemTrabalhoOpt[] = [];

            assemb.ordens_trabalho.forEach(ot => {
              let otConfig = this.ordemTrabOptsOrig.find(otOpt => otOpt.value.id == ot.id);
              if (otConfig !== undefined) {
                let clone: OrdemTrabalhoOpt = JSON.parse(JSON.stringify(otConfig))
                clone.selAnos = ot.selAnos ? JSON.parse(JSON.stringify(ot.selAnos)) : [];
                clone.sufix = this.getOTSufix(clone.selAnos);
                ordensTrabalhos.push(clone);
              }
            })

            let data = null;
            let hour = null;
            if (assemb.data_convocatoria) {
              let data_conv_arr = assemb.data_convocatoria.split(' ');
              data = data_conv_arr[0];
              hour = data_conv_arr.length > 1? data_conv_arr[1] : null;
              if (hour.length > 5) hour = hour.substring(0, 5);
            }
            let assembDetails: AssembleiaRow = {
              cod: assemb.cod_condominio,
              nomeCondominio: assemb.nome_condominio_ui,
              morada: assemb.morada,
              id: assemb.id_condominio,
              id_agendamento_assembleias_assembleia: assemb.id,
              extraordinaria: assemb.extraordinaria.toString(),
              data: assemb.data_convocatoria ? this.utils.getDate(data, hour, 'YYYY-MM-DD') : null,
              ordensTrabalho: ordensTrabalhos,
              apresentacaoContas: [],
              orcamentosAprovados: [],
              utilizador: assemb.id_utilizador != null ? assemb.id_utilizador.toString() : null,
              nomeUtilizador: assemb.nome_utilizador,
              anosOTError: false,
              ordered: true,
              visible: true,
              edit: false,
              slideUp: false
            }
            assembleiasList.push(assembDetails);
          });

          this.assembleiasList = assembleiasList;

          this.getAvailableAnosByCondominio(false);

          setTimeout(() => { if (this.scrollContainer) this.scrollContainer.nativeElement.scrollTop = 0; }, 1);
          this.checkCorrectOrder();
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      },
      err => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }
    );
  }

  blockAutoEditFlag = false;
  toggleEdit(item, i, target = null) {
    this.blockAutoEditFlag = true;
    setTimeout(() => { this.blockAutoEditFlag = false; }, 200);

    if (target === 'FILTERED') {
      if (item.edit) {
        item.edit = false;
        return;
      }

      // CHECK IF SELECT IS UPWARD
      let inds = [];
      this.elem.nativeElement.querySelectorAll('.assembleia-item.sn-viewport--in').forEach(el => {
        inds.push(Number(el.id.replace('item-filtered-', '')));
      });
      if (inds.length > 2) {
        item['slideUp'] = (inds.findIndex(el => el === i) > 1);
      } else {
        item['slideUp'] = false;
      }

      item.edit = true;
      this.assembleiasListFiltered.filter(el => el.id !== item.id).forEach(el => el.edit = false);
    } else {
      if (item.edit) {
        item.edit = false;
        return;
      }

      // CHECK IF SELECT IS UPWARD
      let inds = [];
      this.elem.nativeElement.querySelectorAll('.assembleia-item.sn-viewport--in').forEach(el => {
        inds.push(Number(el.id.replace('item-', '')));
      });
      if (inds.length > 2) {
        item['slideUp'] = (inds.findIndex(el => el === i) > 1);
      } else {
        item['slideUp'] = false;
      }

      item.edit = true;
      this.assembleiasList.filter(el => el.id !== item.id).forEach(el => el.edit = false);
    }
  }

  
  fetchingAllAnos = false;
  getAvailableAnosByCondominio(selectAuto: boolean): Promise<boolean> {
    return new Promise(async (resolve) => {

      this.toastr.info(this.appConfig.infMsg.fetchingData.msg, this.appConfig.infMsg.fetchingData.title);

      this.fetchingAllAnos = true;
      let cods = this.assembleiasList.map(el => el.cod.toString());
      let res = await this.condominios.getAssembleiaAvailableYears(cods);
      if (!res) {
        resolve(false);
        return;
      }

      this.assembleiasList.forEach(a => {
        let apContas = res.apresentacaoContas.find(ap => ap.cod_condominio === a.cod);
        a.apresentacaoContas = apContas !== undefined ? apContas.data : [];

        let orcamentos = res.orcamentosAprovados.find(orc => orc.cod_condominio === a.cod);
        a.orcamentosAprovados = orcamentos !== undefined ? orcamentos.data : [];
        this.updateSelAnos(a, selectAuto);
      })
      
      this.fetchingAllAnos = false;
    });
  }


  cloneAssembleias(assembleias: AssembleiaRow[]): AssembleiaRow[] {
    return assembleias.map(a => this.cloneAssembleia(a));
  }

  cloneAssembleia(a: AssembleiaRow): AssembleiaRow {
    return {
      cod: a.cod,
      nomeCondominio: a.nomeCondominio,
      morada: a.morada,
      id: a.id,
      id_agendamento_assembleias_assembleia: a.id_agendamento_assembleias_assembleia,
      extraordinaria: a.extraordinaria,
      data: this.utils.getDate(a.data),
      ordensTrabalho: a.ordensTrabalho ? JSON.parse(JSON.stringify(a.ordensTrabalho)) : [],
      apresentacaoContas: a.apresentacaoContas ? JSON.parse(JSON.stringify(a.apresentacaoContas)) : [],
      orcamentosAprovados: a.orcamentosAprovados ? JSON.parse(JSON.stringify(a.orcamentosAprovados)) : [],
      utilizador: a.utilizador,
      nomeUtilizador: a.nomeUtilizador,
      ordered: a.ordered,
      anosOTError: a.anosOTError,
      visible: a.visible,
      edit: a.edit,
      slideUp: a.slideUp,
    };
  }

  filterAgendamento(scrollTop=true) {
    return new Promise(resolve => {
      let filterMode = false;
      let assembleiasListFiltered: AssembleiaRowFiltered[] = [];

      let startDate = (this.startDateAgendamento) ? (new Date(this.startDateAgendamento)).getTime() : null;
      let endDate = (this.endDateAgendamento) ? (new Date(this.endDateAgendamento)).setHours(23, 59, 59) : null;

      // GENERATE FIRST FILTERED LIST
      if (this.assembleiasListFiltered.length === 0) {
        assembleiasListFiltered = this.cloneAssembleias(this.assembleiasList).map((el, i) => { return {...el, orig_index: i}});
        assembleiasListFiltered.forEach(el => el.data = (el.data) ? new Date(el.data) : null);
        if (startDate || endDate || this.keyword) {
          if (startDate) {
            assembleiasListFiltered = assembleiasListFiltered.filter(el => (el.data && (new Date(el.data)).getTime() >= startDate));

            filterMode = true;
          }
          if (endDate) {
            assembleiasListFiltered = assembleiasListFiltered.filter(el => (el.data && (new Date(el.data)).getTime() <= endDate));

            filterMode = true;
          }
          if (this.keyword) {

            let keywords = this.keyword.split(/\s/).filter(el => !!el);
            if (this.onlyUtilizadores) {
              assembleiasListFiltered = assembleiasListFiltered.filter(el => (el.nomeUtilizador && this.searchAllWords(el.nomeUtilizador.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''), keywords)));
            } else {
              assembleiasListFiltered = assembleiasListFiltered.filter(el => ((el.nomeUtilizador && this.searchAllWords(el.nomeUtilizador.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''), keywords)) || (el.nomeCondominio && this.searchAllWords(el.nomeCondominio.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''), keywords))));
            }

            filterMode = true;
          }
        } else {
          // EMPTY FILTER
          filterMode = false;
          assembleiasListFiltered = [];
        }
      } else if (this.assembleiasListFiltered.length > 0) {
        // UPDATE AND ORDER ORIGINAL LIST BASED ON FILTERED LIST
        let aux = null;
        this.assembleiasListFiltered.forEach(el => {
          aux = this.assembleiasList.find(it => it.id === el.id);
          if (aux) {
            aux.data = el.data;
            aux.utilizador = el.utilizador;
            aux.nomeUtilizador = el.nomeUtilizador;
            aux.ordensTrabalho = el.ordensTrabalho;
          }
        });
        this.sortAssembleia();
        this.checkCorrectOrder();

        assembleiasListFiltered = this.cloneAssembleias(this.assembleiasList).map((el, i) => { return {...el, orig_index: i}});;
        assembleiasListFiltered.forEach(el => el.data = (el.data) ? new Date(el.data) : null);
        if (startDate || endDate || this.keyword) {
          if (startDate) {
            assembleiasListFiltered = assembleiasListFiltered.filter(el => (el.data && (new Date(el.data)).getTime() >= startDate));

            filterMode = true;
          }
          if (endDate) {
            assembleiasListFiltered = assembleiasListFiltered.filter(el => (el.data && (new Date(el.data)).getTime() <= endDate));

            filterMode = true;
          }
          if (this.keyword) {

            let keywords = this.keyword.split(/\s/).filter(el => !!el);
            if (this.onlyUtilizadores) {
              assembleiasListFiltered = assembleiasListFiltered.filter(el => (el.nomeUtilizador && this.searchAllWords(el.nomeUtilizador.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''), keywords)));
            } else {
              assembleiasListFiltered = assembleiasListFiltered.filter(el => ((el.nomeUtilizador && this.searchAllWords(el.nomeUtilizador.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''), keywords)) || (el.nomeCondominio && this.searchAllWords(el.nomeCondominio.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''), keywords))));
            }

            filterMode = true;
          }
        } else {
          // EMPTY FILTER
          filterMode = false;
          assembleiasListFiltered = [];
        }
      }

      if (filterMode) {
        if (scrollTop) setTimeout(() => { if (this.scrollContainerFiltered) this.scrollContainerFiltered.nativeElement.scrollTop = 0; }, 10);
        this.assembleiasListFiltered = [... assembleiasListFiltered];
      } else {
        if (scrollTop) setTimeout(() => { if (this.scrollContainer) this.scrollContainer.nativeElement.scrollTop = 0; }, 10);
      }

      this.filterMode = filterMode;
      resolve(true);
    });
  }

  addCondominios(selectAuto = false) {
    this.assembleiasList.forEach(el => {
      let aux = this.condominiosListOrig.find(it => it.id.toString() === el.id.toString());
      if (aux) aux.checked = true;
    });

    this.condominiosList = JSON.parse(JSON.stringify(this.condominiosListOrig));

    // SUBSCRIBE TO SEARCH EVENT
    setTimeout(() => {
      this.condominiosSearchSubs = fromEvent(this.condominiosSearchRef.nativeElement, 'keyup').pipe(debounceTime(500)).subscribe(val => {
        let keyword = val['target']['value'].toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        this.searching = true;
        if (keyword) {
          this.condominiosList = this.condominiosListOrig.filter(el => (el.nomeCondominio.toLowerCase().indexOf(keyword) !== -1))
        } else {
          this.condominiosList = JSON.parse(JSON.stringify(this.condominiosListOrig));
        }
        this.searching = false;
      });
    }, 1);

    this.condominiosModalRef = this.modalService
      .open(this.condominiosAlertConfig)
      .onApprove(() => {
        let assembleiasList = this.cloneAssembleias(this.assembleiasList);

        this.condominiosListOrig.forEach(el => {
          if (el.checked) {
            let item: AssembleiaRow = this.assembleiasList.find(it => it.id.toString() === el.id.toString());
            if (item === undefined) {
              item = {
                cod: parseInt(el.cod),
                nomeCondominio: el.nomeCondominio,
                morada: el.morada,
                id: parseInt(el.id),
                id_agendamento_assembleias_assembleia: null,
                extraordinaria: (this.extraordinaria === '1') ? '1' : '0',
                data: null,
                ordensTrabalho: JSON.parse(JSON.stringify(this.ordemTrabOpts.filter(it => it.checked))),
                apresentacaoContas: [],
                orcamentosAprovados: [],
                utilizador: null,
                nomeUtilizador: null,
                anosOTError: false,
                ordered: true,
                visible: true,
                edit: false,
                slideUp: false,
              }
              assembleiasList.push(item);
            }

          } else {
            assembleiasList = assembleiasList.filter(it => it.id.toString() !== el.id.toString());
          }
        });

        setTimeout(() => { if (this.scrollContainer) this.scrollContainer.nativeElement.scrollTop = 0; }, 10);
        this.assembleiasList = [...assembleiasList];
        this.getAvailableAnosByCondominio(selectAuto);
        this.checkCorrectOrder();

        this.condominiosListOrig.forEach(el => el.checked = false);
        if (this.condominiosSearchSubs) this.condominiosSearchSubs.unsubscribe();
      })
      .onDeny(() => {
        this.condominiosListOrig.forEach(el => el.checked = false);
        if (this.condominiosSearchSubs) this.condominiosSearchSubs.unsubscribe();
      });
  }

  selectCondominio(item) {
    item.checked = !item.checked;

    let auxItem = this.condominiosListOrig.find(el => el.id == item.id);
    if (auxItem) auxItem.checked = item.checked;
  }

  rowSelectionToggle(ev, target) {
    switch (target) {
      case 'condominios':
        (ev.target.checked) ? this.condominiosList.map(el => el.checked = true) : this.condominiosList.map(el => el.checked = false);
        this.condominiosList.forEach(el => {
          let auxItem = this.condominiosListOrig.find(it => it.id == el.id);
          if (auxItem) auxItem.checked = el.checked;
        });
        break;
    }
  }

  selectOrdemTrabalho(item, target = 'global') {
    switch (target) {
      case 'global':
        item.checked = !item.checked;

        let auxItem = this.ordemTrabOptsOrig.find(el => el.value.id == item.value.id);
        if (auxItem) auxItem.checked = item.checked;
        break;
      case 'item':
        item.checked = !item.checked;
        break;
    }
  }

  setCondominios() {
    this.condominiosModalRef.approve();
  }

  addAssembleiasParams() {

    this.assembleiaParamsModalRef = this.modalService
      .open(this.assembleiaParamsAlertConfig)
      .onApprove(() => {

        if (this.assembleiasList.length === 0) {
          this.addCondominios(true);
        } else {
          this.assembleiasList.forEach(el => {
            el.extraordinaria = (this.extraordinaria == '1') ? '1' : '0';
            el.ordensTrabalho = JSON.parse(JSON.stringify(this.ordemTrabOpts.filter(it => it.checked)));
          });
          this.getAvailableAnosByCondominio(true);
        }

      })
      .onDeny(() => { });
  }

  assembleiaEditting: AssembleiaRow = null;
  editOrdensTrabalho(assembleia: AssembleiaRow) {
    this.assembleiaEditting = assembleia;
    this.itemOrdemTrabOpts = this.ordemTrabOptsOrig.map(el => {
      let clone = JSON.parse(JSON.stringify(el));
      let prevOrdemTrabalho = assembleia.ordensTrabalho.find(it => it.value.id === clone.value.id);
      clone.checked = prevOrdemTrabalho !== undefined;
      clone.selAnos = prevOrdemTrabalho !== undefined ? prevOrdemTrabalho.selAnos : [];
      clone.sufix = this.getOTSufix(clone.selAnos);
      return clone;
    });

    this.ordensTrabalhoModalRef = this.modalService
      .open(this.ordensTrabalhoAlertConfig)
      .onApprove(async () => {
        assembleia['ordensTrabalho'] = JSON.parse(JSON.stringify(this.itemOrdemTrabOpts.filter(it => it.checked)));
      })
      .onDeny(() => { });
  }

  setItemOrdensTrabalho() {
    this.ordensTrabalhoModalRef.approve();
  }

  typeChanged() {
    if (this.extraordinaria === '0') {
      this.ordemTrabOpts = this.ordemTrabOptsOrig.map(el => {
        el.checked = (el.value.ordinarias_predefinido == 1);

        return el;
      });
    } else {
      this.ordemTrabOpts.forEach(el => el.checked = false);
    }
  }

  removeAssembleia(item, target = null) {
    if (target === 'FILTERED') {
      this.assembleiasListFiltered = this.assembleiasListFiltered.filter(it => it.id !== item.id);

      let lastIndex = this.utils.findLastIndex(this.assembleiasListFiltered, 'visible', true);
      if (lastIndex >= 0) {
        if (lastIndex === this.assembleiasListFiltered.length - 1) {
          lastIndex = this.utils.findLastIndex(this.assembleiasListFiltered, 'visible', false);
          if (lastIndex >= 0) this.assembleiasListFiltered[lastIndex]['visible'] = true;
        } else {
          this.assembleiasListFiltered[lastIndex + 1]['visible'] = true;
        }
      }
    }

    this.assembleiasList = this.assembleiasList.filter(it => it.id !== item.id);

    let lastIndex = this.utils.findLastIndex(this.assembleiasList, 'visible', true);
    if (lastIndex >= 0) {
      if (lastIndex === this.assembleiasList.length - 1) {
        lastIndex = this.utils.findLastIndex(this.assembleiasList, 'visible', false);
        if (lastIndex >= 0) this.assembleiasList[lastIndex]['visible'] = true;
      } else {
        this.assembleiasList[lastIndex + 1]['visible'] = true;
      }
    }
  }

  setAssembleiasParams() {
    this.assembleiaParamsModalRef.approve();
  }

  saveAgendamento(estado: bit = 0, loadingDisabled = false) {
    return new Promise(resolve => {
      if (!loadingDisabled && this.loading) {
        resolve(true);
        return;
      }

      if (this.estado === 1) {
        this.toastr.error('O presente agendamento já se encontra lançado.', 'Alerta');
        resolve(false);
        return;
      }

      if (this.assembleiasList.length === 0) {
        this.toastr.error('Nenhum agendamento definido para guardar', 'Alerta');
        resolve(false);
        return;
      }

      if (this.filterMode) {
        let aux = null;
        this.assembleiasListFiltered.forEach(el => {
          aux = this.assembleiasList.find(it => it.id === el.id);
          if (aux) {
            aux.utilizador = el.utilizador;
            aux.nomeUtilizador = el.nomeUtilizador;
            aux.data = el.data;
          }
        });

        let withDate = this.assembleiasList.filter(el => el.data).sort((a, b) => {
          return a.data.getTime() - b.data.getTime();
        });
        let withoutDate = this.assembleiasList.filter(el => !el.data && el.utilizador);
        let withoutDateUser = this.assembleiasList.filter(el => !el.data && !el.utilizador);
        this.assembleiasList = withDate.concat(withoutDate).concat(withoutDateUser);
      }

      let min = Math.min.apply(null, this.assembleiasList.filter(el => el.data).map(el => el.data));
      let max = Math.max.apply(null, this.assembleiasList.filter(el => el.data).map(el => el.data));

      let startDate = (min) ? new Date(min) : null;
      let endDate = (max) ? new Date(max) : null;

      let assembleiasToSave: SaveAgendamentoAssembleia['assembleias'] = [];
      this.assembleiasList.forEach((a, i) => {
        assembleiasToSave.push({
          id: a.id_agendamento_assembleias_assembleia,
          cod_condominio: a.cod,
          ordens_trabalho: a.ordensTrabalho.map(ot => {
            return {
              id: ot.value.id,
              selAnos: ot.selAnos ? ot.selAnos : [],
            }
          }),
          extraordinaria: a.extraordinaria === '1' ? 1 : 0,
          data_convocatoria: a.data ? this.utils.getFormatedDate(a.data, 'yyyy-MM-dd HH:mm:ss') : null,
          id_utilizador: a.utilizador ? parseInt(a.utilizador) : null,
          ordem: i
        });
      })

      let saveAgendamento: SaveAgendamentoAssembleia = {
        id: this.agendId,
        nome: this.name,
        start_date: this.utils.getFormatedDate(startDate, 'yyyy-MM-ddTHH:mm:ss'),
        end_date: this.utils.getFormatedDate(endDate, 'yyyy-MM-ddTHH:mm:ss'),
        ordens_trabalho: this.ordensTrabalho,
        estado: estado,
        assembleias: assembleiasToSave,
      }


      if (!loadingDisabled) this.loading = true;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      this.api.saveAssembleiasAgendamentos(saveAgendamento).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.agendId = res.id;
          if (estado === 0) this.estado = 0;
        } else {
          this.utils.apiErrorMsg(res);
        }

        this.loading = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.assembleiasList.forEach(el => { el.edit = false; });
        resolve(true);
      },
        err => {
          this.loading = false;
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(false)
        }
      );
    });
  }

  createAssembleia(item: AssembleiaRow) {
    return new Promise(resolve => {

      let codCondominio = item.cod;
      let data = item.data;
      let dataAlter = new Date(item.data.getTime() + 15 * 60000);
      let extraordinaria = item.extraordinaria;

      let morada = null;
      let postalCode = null;
      let localidade = null;
      try {
        let aux = JSON.parse(item.morada);

        morada = aux.address;
        postalCode = aux.postalCode;
        localidade = aux.locality;
      } catch (e) {
        morada = item.morada;
      }

      let ordensTrabalhos = item.ordensTrabalho.map((el, i) => {
        let aux: SaveAssembleiaOrdemTrabalho = {
          id: null,
          id_ordem_trabalho: el.value.id,
          is_diverso: 0,
          ordem: i,
          resps: [],
          selAnos: el.selAnos
        };
        return aux;
      });

      let utilizadorId = item.utilizador;

      let auxDate = new Date(item.data);
      auxDate.setDate(auxDate.getDate() + 30);
      let dataLimite = new Date(auxDate);

      this.api.saveAssembleiaGeral(null, codCondominio, ordensTrabalhos, data, dataAlter, null, extraordinaria, null, morada, postalCode, localidade, 1, null, utilizadorId, dataLimite).subscribe(
        res => {
          if (res.hasOwnProperty('success') && res['success']) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, err => {
          resolve(false);
        }
      );

    });
  }

  resetFilters() { 
    return new Promise((resolve) => {
      setTimeout(() => {
        this.keyword = null;
        this.assembleiasSearchRef.nativeElement.value = null;
        this.startDateAgendamento = null;
        this.endDateAgendamento = null;
        this.filterAgendamento(false);
        resolve(true);
      }, 1);
    })
  }

  loadingLancamento = false;
  async issueAgendamento(fromModal = false) {
    if (fromModal) {
      if (this.loadingLancamento) return;

      this.loadingLancamento = true;

      let estado: bit = 1;
      // CREATE ASSEMBLEAIS
      let createAssembleiaStatus: any = true;
      for (let i = 0; i < this.assembleiasList.length; i++) {
        createAssembleiaStatus = await this.createAssembleia(this.assembleiasList[i]);
        if (!createAssembleiaStatus && estado === 1) estado = 0;
      }

      // SAVE AGENDAMENTO
      let saveAgendamentoStatus = await this.saveAgendamento(estado, true);
      if (saveAgendamentoStatus) {
        // ALL SAVED CORRECTLY
        this.loadingLancamento = false;
        this.lancarModalRef.approve();
      } else {
        this.loadingLancamento = false;
        this.lancarModalRef.deny();
      }
    } else {
      // CHECK IF ASSEMBLEIAS IS VALID
      if (this.estado === 1) {
        this.toastr.error('O presente agendamento já se encontra lançado.', 'Alerta');
        return;
      }

      if (this.assembleiasList.length === 0) {
        this.toastr.error('Nenhum agendamento definido para efectuar o lançamento.', 'Alerta');
        return;
      }

      await this.resetFilters();

      if (!this.isAllAssembleiasValid()) {
        this.toastr.error('Não é possível lançar o presente agendamento. Por favor, verifique todos os campos assinalados.', 'Alerta');
        return;
      }

      this.lancarModalRef = this.modalService
        .open(this.lancarAlertConfig)
        .onApprove(() => {
          // EXIT PAGE
          window.history.back();
        })
        .onDeny(() => { });
    }
  }

  scrollToAssembleia(index:number) {
    setTimeout(() => {
      let assembItem = document.getElementById('item-' + index);
      if (assembItem != null) {
        this.scrollContainer.nativeElement.scrollTop = (this.itemSize * index);
      }
    }, 200);
  }

  isAllAssembleiasValid() {
    let firstWithErrorIndex = -1;
    for (let i = 0; i < this.assembleiasList.length; i++) {
      const el = this.assembleiasList[i];
      if (!el.data || !el.utilizador || el.extraordinaria == null || el.ordensTrabalho.length == 0) {
        firstWithErrorIndex = i;
        break;
      }
    }

    if (firstWithErrorIndex !== -1) {
      this.scrollToAssembleia(firstWithErrorIndex);
      return false;
    }


    for (let i = 0; i < this.assembleiasList.length; i++) {
      const el = this.assembleiasList[i];
      el.anosOTError = false;
      if (el.ordensTrabalho.find(ot => (ot.value.label === 'APRESENTACAO_CONTAS' || ot.value.label === 'ORCAMENTO') && (!ot.selAnos || !ot.selAnos.length)) !== undefined) {
        if (firstWithErrorIndex === -1) firstWithErrorIndex = i;
        el.anosOTError = true;
        setTimeout(() => {
          el.anosOTError = false;
        }, 4000);
      }
    }
    if (firstWithErrorIndex !== -1) {
      this.scrollToAssembleia(firstWithErrorIndex);
      return false;
    }

    return true;
  }

  drop(event: CdkDragDrop<string[]>, target = null) {
    if (target === 'FILTERED') {
      let orig_assembleias = event.previousIndex < this.assembleiasListFiltered.length ? this.assembleiasListFiltered[event.previousIndex].orig_index : null;
      let newPos = event.currentIndex < this.assembleiasListFiltered.length ? this.assembleiasListFiltered[event.currentIndex].orig_index : null;

      moveItemInArray(this.assembleiasListFiltered, event.previousIndex, event.currentIndex);

      if (orig_assembleias != null && newPos != null) {
        moveItemInArray(this.assembleiasList, orig_assembleias, newPos);
        this.assembleiasListFiltered.forEach(el => {
          let index = this.assembleiasList.findIndex(orig => orig.cod === el.cod);
          if (index !== -1) el.orig_index = index;
        })
      }
      this.assembleiasListFiltered[event.currentIndex].visible = true;
    } else {
      moveItemInArray(this.assembleiasList, event.previousIndex, event.currentIndex);
      this.assembleiasList[event.currentIndex].visible = true;
    }
    this.assembleiasList = [... this.assembleiasList];

    this.checkCorrectOrder(null, target);
  }

  sortAssembleia(item = null, target = null) {
    if (target === 'FILTERED') {
      if (!item) {
        let withDate = this.assembleiasListFiltered.filter(el => el.data).sort((a, b) => {
          return a.data.getTime() - b.data.getTime();
        });
        let withoutDate = this.assembleiasListFiltered.filter(el => !el.data && el.utilizador);
        let withoutDateUser = this.assembleiasListFiltered.filter(el => !el.data && !el.utilizador);
        this.assembleiasListFiltered = withDate.concat(withoutDate).concat(withoutDateUser);
      } else {
        if (!item.data) return;

        let withDate = this.assembleiasListFiltered.filter(el => el.data).sort((a, b) => {
          return a.data.getTime() - b.data.getTime();
        });
        let withoutDate = this.assembleiasListFiltered.filter(el => !el.data && el.utilizador);
        let withoutDateUser = this.assembleiasListFiltered.filter(el => !el.data && !el.utilizador);
        this.assembleiasListFiltered = withDate.concat(withoutDate).concat(withoutDateUser);
      }
    } else {
      if (!item) {
        let withDate = this.assembleiasList.filter(el => el.data).sort((a, b) => {
          return a.data.getTime() - b.data.getTime();
        });
        let withoutDate = this.assembleiasList.filter(el => !el.data && el.utilizador);
        let withoutDateUser = this.assembleiasList.filter(el => !el.data && !el.utilizador);
        this.assembleiasList = withDate.concat(withoutDate).concat(withoutDateUser);
      } else {
        if (!item.data) return;

        let withDate = this.assembleiasList.filter(el => el.data).sort((a, b) => {
          return a.data.getTime() - b.data.getTime();
        });
        let withoutDate = this.assembleiasList.filter(el => !el.data && el.utilizador);
        let withoutDateUser = this.assembleiasList.filter(el => !el.data && !el.utilizador);
        this.assembleiasList = withDate.concat(withoutDate).concat(withoutDateUser);
      }
    }
  }

  dataAssembleiaFocusOut(item, target = null) {
    if (target === 'FILTERED') {
      if (!item.data) {
        let withDate = this.assembleiasListFiltered.filter(el => el.data);
        let withoutDate = this.assembleiasListFiltered.filter(el => !el.data && el.utilizador);
        let withoutDateUser = this.assembleiasListFiltered.filter(el => !el.data && !el.utilizador);
        this.assembleiasListFiltered = withDate.concat(withoutDate).concat(withoutDateUser);
      }
    } else {
      if (!item.data) {
        let withDate = this.assembleiasList.filter(el => el.data);
        let withoutDate = this.assembleiasList.filter(el => !el.data && el.utilizador);
        let withoutDateUser = this.assembleiasList.filter(el => !el.data && !el.utilizador);
        this.assembleiasList = withDate.concat(withoutDate).concat(withoutDateUser);
      }
    }
  }

  dataSelected(item: AssembleiaRow, event: Date, listType: 'FILTERED' = null) {
    // if ((item.data == null) === (event == null) && (item.data == null || item.data.getTime() === event.getTime())) return;

    let changedYear = (item.data == null) != (event == null) || (item.data != null && item.data.getFullYear() != event.getFullYear());
    item.data = event;
    if (!this.blockAutoEditFlag && item.edit && item.data && item.utilizador) item.edit = false;
    if (changedYear) {
      this.updateSelAnos(item, true);
    } else if (item.data != null) {
      let apContasOT = item.ordensTrabalho.find(ot => ot.value.label === 'APRESENTACAO_CONTAS');
      if (apContasOT !== undefined) {
        let yearSelected = item.data.getFullYear().toString()
        let currAno = apContasOT.selAnos.find(a => a.ano === yearSelected);
        if (currAno !== undefined) {
          currAno.data_fim = this.utils.getFormatedDate(item.data);
        }
      }
    }

    this.sortAssembleia(item, listType);
    this.checkCorrectOrder(item, listType);
  }

  updateSelAnos(item: AssembleiaRow, selectAuto: boolean) {

    let yearSelected = item.data ? item.data.getFullYear() : null;


    let apContas = item.ordensTrabalho.find(el => el.value.label === 'APRESENTACAO_CONTAS');
    if (apContas !== undefined) {
      if (selectAuto || !apContas.selAnos || !apContas.selAnos.length) {
        apContas.selAnos = [];
        if (item.data) {
          item.apresentacaoContas.forEach(ano => {
            let startDate = this.utils.getDate(ano.data_inicio);
            if (startDate.getFullYear() < yearSelected) {
              apContas.selAnos.push(JSON.parse(JSON.stringify(ano)));
            } else if (startDate.getFullYear() == yearSelected && this.utils.compareDayDates(item.data, this.utils.getDate(ano.data_fim)) <= 0) {
              let data_fim = this.utils.getDate(item.data);
              apContas.selAnos.push({
                ano: ano.ano,
                data_inicio: this.utils.getFormatedDate(ano.data_inicio),
                data_fim: this.utils.getFormatedDate(data_fim),
                id_link: null
              });
            }
          });

          this.appendAvailableApresentacaoContasAnos(apContas.selAnos, item.data);
        }
      } else {
        apContas.selAnos = apContas.selAnos.filter(selAno => parseInt(selAno.ano) >= this.now.getFullYear() ||  item.apresentacaoContas.find(ap => ap.ano === selAno.ano) !== undefined);
      }

      apContas.sufix = this.getOTSufix(apContas.selAnos);
    }

    let orcamento = item.ordensTrabalho.find(el => el.value.label === 'ORCAMENTO');
    if (orcamento !== undefined) {
      if (selectAuto || !orcamento.selAnos || !orcamento.selAnos.length) {
        orcamento.selAnos = [];
        if (item.data) {
          let orcamentoYear = yearSelected;
          let found = true;
          while (found) {
            found = item.orcamentosAprovados.find(o => o == orcamentoYear) !== undefined;
            if (found) {
              orcamentoYear++;
            }
          }
          orcamento.selAnos = [{
            ano: orcamentoYear.toString(),
            data_inicio: '01-01-' + orcamentoYear,
            data_fim: '31-12-' + orcamentoYear,
            id_link: null
          }]
        }
      } else {
        orcamento.selAnos = orcamento.selAnos.filter(selAno => item.orcamentosAprovados.find(ap => ap.toString() === selAno.ano) === undefined);
      }
      orcamento.sufix = this.getOTSufix(orcamento.selAnos);
    }
  }

  utilizadorSelected(item: AssembleiaRow, target = null) {

    if (target === 'FILTERED') {
      if (item.utilizador === '-1') {
        item.utilizador = null;
        item.nomeUtilizador = null;
      } else {
        item.nomeUtilizador = this.utilizadoresOpts.find(el => el.value === item.utilizador).name;
      }

      let assembleiasListFiltered = this.assembleiasListFiltered.filter(el => ((el.nomeUtilizador && el.nomeUtilizador.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.keyword) !== -1) || (el.nomeCondominio && el.nomeCondominio.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(this.keyword) !== -1)));
      if (assembleiasListFiltered.length < this.assembleiasListFiltered.length) {
        let aux = this.assembleiasList.find(el => el.id === item.id);
        if (aux) {
          aux.data = (item.data) ? new Date(item.data) : null;
          aux.utilizador = item.utilizador;
          aux.nomeUtilizador = item.nomeUtilizador;
          aux.ordensTrabalho = item.ordensTrabalho;
        }
      }
      this.assembleiasListFiltered = assembleiasListFiltered;

      if (item.edit && item.data && item.utilizador) item.edit = false;
      this.sortAssembleia(null, target);
    } else {
      if (item.utilizador === '-1') {
        item.utilizador = null;
        item.nomeUtilizador = null;
      } else {
        item.nomeUtilizador = this.utilizadoresOpts.find(el => el.value === item.utilizador).name;
      }

      if (item.edit && item.data && item.utilizador) item.edit = false;
      this.sortAssembleia();
    }
  }

  checkCorrectOrder(item = null, target = null) {
    if (target === 'FILTERED') {
      if (item) {
        if (!item.data) return;

        let i = this.assembleiasListFiltered.findIndex(el => el.id === item.id);
        if (i > 0) {
          let n = null;
          for (let j = (i - 1); j >= 0; j--) {
            if (this.assembleiasListFiltered[j]['data']) {
              n = j;
              break;
            }
          }

          this.assembleiasListFiltered[i]['ordered'] = (n) ? (this.assembleiasListFiltered[i - n]['data'] && this.assembleiasListFiltered[i]['data'] && this.assembleiasListFiltered[i - n]['data'].getTime() <= this.assembleiasListFiltered[i]['data'].getTime()) : true;
        }
      } else {
        let aux = this.assembleiasListFiltered.filter(el => el.data);
        for (let i = 0; i < aux.length; i++) {
          if (i > 0) {
            aux[i]['ordered'] = (aux[i - 1]['data'] && aux[i]['data'] && aux[i - 1]['data'].getTime() <= aux[i]['data'].getTime());
          } else {
            aux[i]['ordered'] = true;
          }
        }
      }
      this.assembleiasListFiltered.filter(el => !el.data).forEach(el => el.ordered = true);
    } else {
      if (item) {
        if (!item.data) return;

        let i = this.assembleiasList.findIndex(el => el.id === item.id);
        if (i > 0) {
          let n = null;
          for (let j = (i - 1); j >= 0; j--) {
            if (this.assembleiasList[j]['data']) {
              n = j;
              break;
            }
          }

          this.assembleiasList[i]['ordered'] = (n) ? (this.assembleiasList[i - n]['data'] && this.assembleiasList[i]['data'] && this.assembleiasList[i - n]['data'].getTime() <= this.assembleiasList[i]['data'].getTime()) : true;
        }
      } else {
        let aux = this.assembleiasList.filter(el => el.data);
        for (let i = 0; i < aux.length; i++) {
          if (i > 0) {
            aux[i]['ordered'] = (aux[i - 1]['data'] && aux[i]['data'] && aux[i - 1]['data'].getTime() <= aux[i]['data'].getTime());
          } else {
            aux[i]['ordered'] = true;
          }
        }
      }
      this.assembleiasList.filter(el => !el.data).forEach(el => el.ordered = true);
    }
  }

  // TODO: CHECK WHY THIS METHODS IS NEEDED!!
  warnBeforeLeave() { return false; }

  getOTSufix(selAnos: SelAno[]): string {
    let sufix = '';
    selAnos.forEach((ano, i) => {
      if (i !== 0) {
        sufix += (i < selAnos.length - 1) ? ', ' : ' e ';
      }
      sufix += ano.ano;
    });
    return sufix;
  }


  @ViewChild('assembleiaOTSelectYears', { static: false }) assembleiaOTSelectYears: AssembleiaOtSelectYearsComponent;
  editOTAnos(ot: OrdemTrabalhoOpt) {
    if (this.assembleiaEditting.data == null) {
      this.toastr.info('É necessário selecionar a data da convocatória da assembleia previamente.', 'Informação')
      return;
    }
    if (ot.value.label !== 'APRESENTACAO_CONTAS' && ot.value.label !== 'ORCAMENTO') return;
    
    let selAnos: SelAnoOpt[] = [];
    let showPeriodo = false;
    if (ot.value.label === 'APRESENTACAO_CONTAS') {
      showPeriodo = true;
      this.assembleiaEditting.apresentacaoContas.forEach(el => {
        selAnos.push({
          ano: el.ano,
          id_link: null,
          data_inicio: el.data_inicio,
          data_fim: el.data_fim,
          checked: false,
        });
      })
      this.appendAvailableApresentacaoContasAnos(selAnos, this.assembleiaEditting.data);
      selAnos.forEach(ano => {
        ano.checked = ot.selAnos.find(a => a.ano === ano.ano) !== undefined;
      })
    } else {
      let yearToAppend = this.assembleiaEditting.data.getFullYear();
      while(selAnos.length <= 1) {
        let yearAlreadyApproved = this.assembleiaEditting.orcamentosAprovados.find(orc => orc === yearToAppend) !== undefined
        if (!yearAlreadyApproved) {
          selAnos.push({
            ano: yearToAppend.toString(),
            id_link: null,
            data_inicio: '01-01-' + yearToAppend,
            data_fim: '31-12-' + yearToAppend,
            checked: ot.selAnos.find(a => a.ano === yearToAppend.toString()) !== undefined
          });
        }
        yearToAppend++;
      }
    }

    this.assembleiaOTSelectYears.open(selAnos, showPeriodo)
      .then(selAnos => {
        ot.selAnos = selAnos;
        ot.sufix = this.getOTSufix(ot.selAnos);
        ot.checked = true;
      })
      .catch(err => {});
  }

  appendAvailableApresentacaoContasAnos(selAnos: SelAno[], maxDate:Date): void {
    let maxYear = maxDate.getFullYear();
    let currentYear = this.now.getFullYear();
    for (let year = currentYear; year <= maxYear; year++) {
      if (selAnos.find(a => a.ano === year.toString()) === undefined) {
        selAnos.push({
          ano: year.toString(),
          data_inicio: '01-01-' + year,
          data_fim: year === maxYear ? this.utils.getFormatedDate(maxDate) : '31-12-' + year,
          id_link: null
        });
      }
    }
  }
}
