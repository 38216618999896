import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { AppConfigService } from '../app-config.service';
import { ToastrService } from 'ngx-toastr';
interface IContext {
  data: string;
}

import { UserSessionService } from '../user-session.service';
import { MessageService } from '../message.service';
import { AdministradorSave, CondominioEntidadesList, TitularBancarioSave } from '../business-model-interfaces/condominios';
import { UtilitiesService } from '../utilities.service';
import { CondominiosService } from '../business-logic-services/condominios.service';
import { BusinessLogicService } from '../business-logic.service';
import { SelectEntidadesList, SelectEntitiesModalComponent } from '../select-entities-modal/select-entities-modal.component';
import { CondominioEntidadesRegAtividadeModalComponent } from '../condominio-entidades-reg-atividade-modal/condominio-entidades-reg-atividade-modal.component';


type FilterEstado = 'BOTH' | 'EFECTIVE' | 'ELECTED';
type FilterTipoEntidade = 'BOTH' | 'TITULARES_BANCARIOS' | 'ADMINISTRADORES';
type FilterValidade = 'BOTH' | 'ACTIVE' | 'EXPIRED';


@Component({
  selector: '[app-registo-titulares-bancarios]',
  templateUrl: './registo-titulares-bancarios.component.html',
  styleUrls: ['./registo-titulares-bancarios.component.scss']
})
export class RegistoTitularesBancariosComponent implements OnInit, OnDestroy {

  @Input() codCondominio = null;
  @Input() nomeCondominio = null;
  @Input() bancList: Array<any> = null;

  listOrig: Array<CondominioEntidadesList> = [];
  list: Array<CondominioEntidadesList> = [];

  addModalType: CondominioEntidadesList['type'] = null;


  toDeleteEntityAssocId: number = null;
  deleteModalType: CondominioEntidadesList['type'] = null;

  submittingNewEntity = { show: false, timeout: null };


  filterOpts: {
    contas: Array<{ name: string, value: { cod } }>,
    estado: Array<{ name: string, value: FilterEstado }>,
    tipoEntidade: Array<{ name: string, value: FilterTipoEntidade }>,
    validade: Array<{ name: string, value: FilterValidade }>,
  } = {
      contas: [],
      estado: [
        { name: 'Todos', value: 'BOTH' },
        { name: 'Em Funções', value: 'EFECTIVE' },
        { name: 'Eleito', value: 'ELECTED' },
      ],
      tipoEntidade: [
        { name: 'Todos', value: 'BOTH' },
        { name: 'Titulares Bancários', value: 'TITULARES_BANCARIOS' },
        { name: 'Administradores', value: 'ADMINISTRADORES' },
      ],
      validade: [
        { name: 'Todos', value: 'BOTH' },
        { name: 'Ativo', value: 'ACTIVE' },
        { name: 'Expirado', value: 'EXPIRED' },
      ]
    }
  canAddTitular = false;
  canAddAdmin = false;


  filters: { conta: { cod }, estado: FilterEstado, tipoEntidade: FilterTipoEntidade, validade: FilterValidade } = {
    conta: null,
    estado: this.filterOpts.estado[0].value,
    tipoEntidade: this.filterOpts.tipoEntidade[0].value,
    validade: this.filterOpts.validade[1].value,
  }

  constructor(public modalService: SuiModalService,
    public toastr: ToastrService,
    public appConfigs: AppConfigService,
    public api: ApiService,
    public message: MessageService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public router: Router,
    public condominios: CondominiosService,
    public businessLogic: BusinessLogicService,
    public userSession: UserSessionService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() { }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty('codCondominio') && changes.codCondominio.currentValue !== changes.codCondominio.previousValue) {
      if (this.codCondominio) this.getData(this.codCondominio);
    }

    if (changes.hasOwnProperty('bancList') && changes.bancList.currentValue !== changes.bancList.previousValue) {
      this.updateListState();
      let contasOpts = [];
      this.bancList.filter(conta => conta.banco !== 'CAIXA' && conta.banco.indexOf('CX VERTIS') === -1).forEach(conta => {
        contasOpts.push({
          name: conta.banco,
          value: conta
        });
      });
      this.filterOpts.contas = contasOpts;
    }
  }

  @ViewChild('deleteModalRef', { static: false }) deleteModalRef;
  deleteModal = null;
  deleteModalConfigs = null;
  deleteModalLoading = false;

  @ViewChild('addModalRef', { static: false }) addModalRef;
  addModal = null;
  addModalConfigs = null;
  addModalLoading = false;
  ngAfterViewInit() {
    this.addModalConfigs = new TemplateModalConfig<IContext, string, string>(this.addModalRef);
    this.addModalConfigs.isClosable = false;
    this.addModalConfigs.closeResult = 'closed';
    this.addModalConfigs.size = 'mini';
    this.addModalConfigs.transition = 'fade';
    this.addModalConfigs.transitionDuration = 250;


    this.deleteModalConfigs = new TemplateModalConfig<IContext, string, string>(this.deleteModalRef);
    this.deleteModalConfigs.isClosable = false;
    this.deleteModalConfigs.closeResult = 'closed';
    this.deleteModalConfigs.size = 'mini';
    this.deleteModalConfigs.transition = 'fade';
    this.deleteModalConfigs.transitionDuration = 250;
  }

  // START - ENTITY TABLE METHODS AND VARIABLES ---------------------------------------------------
  fetchingData = false;
  getData(codCondominio) {
    return new Promise(async (resolve) => {
      this.fetchingData = true;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      this.api.getEntidadesAssociadas(codCondominio).subscribe(res => {
        if (res.success) {
          this.listOrig = [];
          res.data.administradores.forEach(el => {
            let stateName = this.filterOpts.estado.find(estado => estado.value === el.state);
            this.listOrig.push({
              data_comunicacao: this.utils.getDate(el.data_comunicacao),
              data_assembleia: this.utils.getDate(el.data_assembleia),
              type: 'ADMINISTRADOR',
              operacao: null,
              cod_conta: null,
              id_conta: null,
              nome_conta: null,
              num_assinaturas: null,
              id: el.id,
              cod_condominio: el.cod_condominio,
              id_assembleia: el.id_assembleia,
              num_ata: el.num_ata,
              id_ata_ficheiro: el.id_ata_ficheiro,
              validade: el.validade,
              state: el.state,
              stateName: stateName ? stateName.name : null,
              active: el.active,
              entidades: el.entidades,
            });
          });
          res.data.titulares.forEach(el => {
            let stateName = this.filterOpts.estado.find(estado => estado.value === el.state);
            this.listOrig.push({
              data_comunicacao: this.utils.getDate(el.data_comunicacao),
              data_assembleia: this.utils.getDate(el.data_assembleia),
              type: 'TITULAR_BANCARIO',
              operacao: el.operacao,
              cod_conta: el.cod_conta,
              id_conta: el.id_conta,
              nome_conta: el.nome_conta,
              num_assinaturas: el.num_assinaturas,
              id: el.id,
              cod_condominio: el.cod_condominio,
              id_assembleia: el.id_assembleia,
              num_ata: el.num_ata,
              id_ata_ficheiro: el.id_ata_ficheiro,
              validade: el.validade,
              state: el.state,
              stateName: stateName ? stateName.name : null,
              active: el.active,
              entidades: el.entidades,
            });
          });

          this.listOrig.sort((a, b) => {
            if (a.state !== b.state) return a.state === 'EFECTIVE' ? 1 : -1;

            if (a.data_assembleia == null || b.data_assembleia == null) {
              if (a.data_assembleia == null && b.data_assembleia == null) return 0;
              if (a.data_assembleia == null) return 1;
              return -1;
            }
            let daysDiff = this.utils.compareDayDates(b.data_assembleia, a.data_assembleia);
            if (daysDiff !== 0) return daysDiff
            return b.num_ata - a.num_ata;
          });


          this.updateListState();
          this.filterChange();
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
        this.fetchingData = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.fetchingData = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(false);
      });
    });
  }
  // END - ENTITY TABLE METHODS AND VARIABLES -----------------------------------------------------

  entityStateOptsOrig = [
    { name: 'Eleito', value: 'ELECTED' },
    { name: 'Em Funções', value: 'EFECTIVE' },
  ];
  entityStateOpts = [];
  stateSel = null;
  contaSel = null;
  contasOpts: Array<{ name, value }> = [];
  numAssinaturas = null;
  numTitulares = null;

  openAddModal(type: CondominioEntidadesList['type']) {
    return new Promise(async resolve => {
    
      let canCreate = await this.businessLogic.checkUserAccess('CONDOMINIOS_ENTIDADES', 'create');
      if (!canCreate) {
        resolve(false);
        return;
      }

      this.addModalType = type;
      this.resetAddModal();

      let validEntities = this.listOrig.filter(ent => ent.active == 1 && ent.validade == 1 && ent.type == type);
      switch (type) {
        case 'ADMINISTRADOR':
          this.entityStateOpts = this.entityStateOptsOrig.filter(state => !!validEntities.find(ent => ent.state == state.value));
          break;
        case 'TITULAR_BANCARIO':
          for (let i = 0; i < this.bancList.length; i++) {
            const el = this.bancList[i];
            if (el.banco === 'CAIXA' || el.banco.indexOf('CX VERTIS') !== -1) continue;

            let titulares = this.listOrig.filter(ent => ent.type === 'TITULAR_BANCARIO' && ent.cod_conta == el.cod && ent.active == 1 && ent.validade == 1);
            if (titulares.find(el => el.id_assembleia != null)) continue;

            let titularesAvailableStates = this.entityStateOptsOrig.map(el => el.value);
            for (let index = 0; index < titulares.length && titularesAvailableStates.length !== 0; index++) {
              const tit = titulares[index];
              titularesAvailableStates = titularesAvailableStates.filter(state => state != tit.state);

            }
            if (titularesAvailableStates.length > 0) {
              this.contasOpts.push({
                name: el.banco,
                value: {
                  ...el, stateOpts: titularesAvailableStates.map(state => {
                    return {
                      name: this.entityStateOptsOrig.find(stateOrig => stateOrig.value === state).name,
                      value: state
                    }
                  })
                }
              })
            }
          }

          // this.entityStateOpts = this.entityStateOptsOrig.filter(state => !!validEntities.find(ent => ent.state == state.value));
          break;

        default:
          break;
      }

      this.addModal = this.modalService
        .open(this.addModalConfigs)
        .onApprove(() => { resolve(true); })
        .onDeny(() => { resolve(false); });
    });
  }

  resetAddModal() {
    this.stateSel = null;
    this.contaSel = null;
    this.numAssinaturas = null;
    this.numTitulares = null;
    this.contasOpts = [];
    this.submittingNewEntity = { show: false, timeout: null }
  }

  updateListState() {

    let canAddTitular = false;
    for (let i = 0; i < this.bancList.length; i++) {
      const el = this.bancList[i];
      if (el.banco === 'CAIXA' || el.banco.indexOf('CX VERTIS') !== -1) continue;

      let titulares = this.listOrig.filter(ent => ent.type === 'TITULAR_BANCARIO' && ent.cod_conta == el.cod && ent.active == 1 && ent.validade == 1);
      if (titulares.find(el => el.id_assembleia != null)) continue;

      let titularesAvailableStates = this.entityStateOptsOrig.map(el => el.value);
      for (let index = 0; index < titulares.length && titularesAvailableStates.length !== 0; index++) {
        const tit = titulares[index];
        titularesAvailableStates = titularesAvailableStates.filter(state => state != tit.state);

      }
      if (titularesAvailableStates.length > 0) {
        canAddTitular = true;
        break;
      }
    }

    //Só é possível inserir se houver pelo menos uma conta que não seja proveniente de uma assembleia e que tenha pelo menos um estdo não inserido.
    this.canAddTitular = canAddTitular;



    let adminStatesAvailable = this.entityStateOptsOrig.map(el => el.value);
    for (let index = 0; index < this.listOrig.length && adminStatesAvailable.length !== 0; index++) {
      const el = this.listOrig[index];
      if (el.type === 'ADMINISTRADOR' && el.active == 1 && el.validade == 1) {
        if (el.id_assembleia != null) {
          adminStatesAvailable = [];
          break;
        }
        adminStatesAvailable = adminStatesAvailable.filter(state => state !== el.state);
      }
    }
    //Só é possível inserir enquanto não houver nenhum inserido através de uma assembleia, ou caso ainda não tenham sido inseridos manualmente todos os estados
    this.canAddAdmin = adminStatesAvailable.length > 0;
  }

  @ViewChild('selectEntitiesModal', { static: false }) selectEntitiesModal: SelectEntitiesModalComponent;
  async add() {
    this.utils.setFormFeedback(this.submittingNewEntity);
    if (this.stateSel == null) return;

    switch (this.addModalType) {
      case 'ADMINISTRADOR':
        break;
      case 'TITULAR_BANCARIO':
        if (!this.contaSel || !this.numTitulares || !this.numAssinaturas || this.numTitulares < this.numAssinaturas) return;
        break;

      default:
        break;
    }

    let entidades = await this.selectEntitiesModal.open({
      nSelection: this.addModalType === 'TITULAR_BANCARIO' ? this.numTitulares : null,
      cod_condominio: this.codCondominio,
    });
    if (entidades === null) {
      return;
    }

    this.addModalLoading = true;
    let success = false;
    switch (this.addModalType) {
      case 'ADMINISTRADOR':
        success = await this.saveAdministrador({
          cod_condominio: this.codCondominio,
          id_assembleia: null,
          validade: 1,
          state: this.stateSel,
          entidades: entidades.map(el => {
            return {
              id: null,
              cod_entidade: el.cod,
              tipo_entidade: el.type,
            }
          }),
        });
        break;
      case 'TITULAR_BANCARIO':
        success = await this.saveTitularBancario({
          cod_condominio: this.codCondominio,
          id_assembleia: null,
          validade: 1,
          state: this.stateSel,
          num_assinaturas: this.numAssinaturas,
          cod_conta: this.contaSel.cod,
          operacao: 'BANCO_MANUAL',
          entidades: entidades.map(el => {
            return {
              id: null,
              cod_entidade: el.cod,
              tipo_entidade: el.type,
            }
          }),
        });
        break;

      default:
        break;
    }
    this.addModalLoading = false;
    if (!success) return;

    this.getData(this.codCondominio);
    this.addModal.approve();
  }

  @ViewChild('condominioEntidadesRegAtividadeModal', { static: false }) condominioEntidadesRegAtividadeModal: CondominioEntidadesRegAtividadeModalComponent;
  openRegAtividadeModal() {
    this.condominioEntidadesRegAtividadeModal.open(this.codCondominio);
  }

  filterChange() {
    if (this.filters.tipoEntidade !== 'TITULARES_BANCARIOS') this.filters.conta = null;
    this.list = this.listOrig.filter(el => {
      if (this.filters.tipoEntidade === 'TITULARES_BANCARIOS' && el.type !== 'TITULAR_BANCARIO') return false;
      if (this.filters.tipoEntidade === 'ADMINISTRADORES' && el.type !== 'ADMINISTRADOR') return false;

      if (this.filters.estado === 'EFECTIVE' && el.state !== 'EFECTIVE') return false;
      if (this.filters.estado === 'ELECTED' && el.state !== 'ELECTED') return false;

      if (this.filters.validade === 'ACTIVE' && el.validade !== 1) return false;
      if (this.filters.validade === 'EXPIRED' && el.validade !== 0) return false;

      if (this.filters.conta != null && el.cod_conta != this.filters.conta.cod) return false;

      return true;
    }).map(el => {

      let newEl: CondominioEntidadesList = {
        data_comunicacao: this.utils.getDate(el.data_comunicacao),
        data_assembleia: this.utils.getDate(el.data_assembleia),
        type: el.type,
        operacao: el.operacao,
        cod_conta: el.cod_conta,
        id_conta: el.id_conta,
        nome_conta: el.nome_conta,
        num_assinaturas: el.num_assinaturas,
        id: el.id,
        cod_condominio: el.cod_condominio,
        id_assembleia: el.id_assembleia,
        num_ata: el.num_ata,
        id_ata_ficheiro: el.id_ata_ficheiro,
        validade: el.validade,
        state: el.state,
        stateName: el.stateName,
        active: el.active,
        entidades: el.entidades.map(ent => {
          let newEnt: CondominioEntidadesList['entidades'][0] = {
            id: ent.id,
            cod_entidade: ent.cod_entidade,
            tipo_entidade: ent.tipo_entidade,
            nome_entidade: ent.nome_entidade,
            email: ent.email,
            telefone: ent.telefone
          }
          return newEnt
        }),
      }
      return newEl;
    })
  }

  saveTitularBancario(item: TitularBancarioSave): Promise<boolean> {
    return new Promise(resolve => {
      this.api.saveTitularBancario(item).subscribe(res => {
        if (res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  saveAdministrador(item: AdministradorSave): Promise<boolean> {
    return new Promise(resolve => {
      this.api.saveAdministrador(item).subscribe(res => {
        if (res.success) {
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  entitiesTimer = null;
  entitiesLookup = async (query: string, initial?) => {
    // if (initial != undefined) {
    //   return new Promise(resolve => { return resolve(this.selCondominio); });
    // }

    clearTimeout(this.entitiesTimer);
    return new Promise(resolve => {
      if (query) {
        this.entitiesTimer = setTimeout(() => {
          this.api.getAllCondominos(query, 'proprietarios').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }, 400);
      } else {
        this.api.getAllCondominos('NULL', 'proprietarios').subscribe(res => {
          if (res.success) {
            return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
          } else {
            return resolve([]);
          }
        });
      }
    });
  };
  // START - DELETE MODAL METHODS AND VARIABLES ---------------------------------------------------


  openDeleteModal(item: CondominioEntidadesList) {
    this.deleteModalType = item.type;
    this.toDeleteEntityAssocId = item.id;

    return new Promise(resolve => {
      this.deleteModal = this.modalService
        .open(this.deleteModalConfigs)
        .onApprove(() => { resolve(true); })
        .onDeny(() => { resolve(false); });
    });
  }

  async delete() {
    this.deleteModalLoading = true;
    let success = false;
    switch (this.deleteModalType) {
      case 'ADMINISTRADOR':
        success = await this.saveAdministrador({ id: this.toDeleteEntityAssocId, active: 0 });
        break;
      case 'TITULAR_BANCARIO':
        success = await this.saveTitularBancario({ id: this.toDeleteEntityAssocId, active: 0 });
        break;

      default:
        break;
    }
    this.deleteModalLoading = false;
    if (success) {
      this.deleteModal.approve();
      this.listOrig = this.listOrig.filter(el => el.id !== this.toDeleteEntityAssocId);
      this.filterChange();
      this.updateListState();
    } else {
    }
  }

  // END - DELETE MODAL METHODS AND VARIABLES -----------------------------------------------------
  canCheckItem(item: CondominioEntidadesList): boolean {
    return item.validade == 1 && (item.type === 'TITULAR_BANCARIO' || item.id_assembleia == null);
  }


  async checkEntity(item: CondominioEntidadesList) {
    if (!this.canCheckItem(item)) return;
    let success = false;
    switch (item.type) {
      case 'TITULAR_BANCARIO':
        success = await this.saveTitularBancario({ id: item.id, state: (item.state === 'EFECTIVE' ? 'ELECTED' : 'EFECTIVE') });
        break;
      case 'ADMINISTRADOR':
        success = await this.saveAdministrador({ id: item.id, state: (item.state === 'EFECTIVE' ? 'ELECTED' : 'EFECTIVE') });
        break;

      default:
        break;
    }

    if (success && this.codCondominio) await this.getData(this.codCondominio);
  }

  goToEntity(item: CondominioEntidadesList['entidades'][0]) {
    switch (item.tipo_entidade) {
      case 'CONDOMINO':
        this.router.navigate(['entidades/proprietario', item.cod_entidade]);
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROPRIETÁRIOS / ${item.nome_entidade}` });
        break;
      case 'PROCURADOR':
        this.router.navigate(['entidades/procurador', item.cod_entidade]);
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROCURADORES / ${item.nome_entidade}` });

        break;

      default:
        break;
    }
  }

  goToAssembleia(item: CondominioEntidadesList) {
    this.router.navigate(['assembleias/assembleias', item.id_assembleia]);
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: this.nomeCondominio });
  }

  goToConta(item: CondominioEntidadesList) {
    this.router.navigate(['contas', item.cod_condominio, item.id_conta]);
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: this.nomeCondominio });
  }

  downloadAssembleiaAta(id_ata_ficheiro) {
    if (id_ata_ficheiro == null) return;
    this.businessLogic.getAndDownloadFile(id_ata_ficheiro);
  }

}
