import { Component, OnInit, OnDestroy, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent, forkJoin } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
interface IContext {
  data:string;
}
import { exportPDF, Group } from '@progress/kendo-drawing';

import { Subscription } from 'rxjs';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';
import { EditEntregaFaturaCttModalComponent } from '../edit-entrega-fatura-ctt-modal/edit-entrega-fatura-ctt-modal.component';
import { custosComunicacoes } from '../business-model-interfaces/comunicacoes';
import { EntregasFaturasComponent } from '../entregas-faturas/entregas-faturas.component';


@Component({
  selector: 'app-caixa-vertis',
  templateUrl: './caixa-vertis.component.html',
  styleUrls: ['./caixa-vertis.component.scss']
})
export class CaixaVertisComponent implements OnInit {

  @ViewChild('descInput', { static: false }) descInput;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('deleteAlertCaixaRef', { static: false }) deleteAlertCaixaRef;
  alertModalCaixaRef = null;
  deleteAlertCaixaConfig: any = null;

  @ViewChild('deleteReciboAlertRef', { static: false }) deleteReciboAlertRef;
  alertReciboModalRef = null;
  deleteReciboAlertConfig: any = null;

  @ViewChild('loginCaixaAlertRef', { static: false }) loginCaixaAlertRef;
  loginCaixaModalRef = null;
  loginCaixaAlertConfig: any = null;

  @ViewChild('alredyLoggedAlertRef', { static: false }) alredyLoggedAlertRef;
  alredyLoggedModalRef = null;
  alredyLoggedAlertConfig: any = null;

  @ViewChild('openCVAlertRef', { static: false }) openCVAlertRef;
  openCVModalRef = null;
  openCVAlertConfig: any = null;

  @ViewChild('closeCVAlertRef', { static: false }) closeCVAlertRef;
  closeCVModalRef = null;
  closeCVAlertConfig: any = null;

  @ViewChild('editAlertRef', { static: false }) editAlertRef;
  editModalRef = null;
  editAlertConfig: any = null;

  @ViewChild('recebimentoValoresAlertRef', { static: false }) recebimentoValoresAlertRef;
  recebimentoValoresModalRef = null;
  recebimentoValoresAlertConfig: any = null;

  @ViewChild('addNameAlertRef', { static: false }) addNameAlertRef;
  addNameModalRef = null;
  addNameAlertConfig: any = null;

  @ViewChild('addEntityAlertRef', { static: false }) addEntityAlertRef;
  addEntityModalRef = null;
  addEntityAlertConfig: any = null;

  @ViewChild('addUserNameAlertRef', { static: false }) addUserNameAlertRef;
  addUserNameModalRef = null;
  addUserNameAlertConfig: any = null;

  @ViewChild('movConfirmAlertRef', { static: false }) movConfirmAlertRef;
  movConfirmModalRef = null;
  movConfirmAlertConfig: any = null;

  @ViewChild('facturaCttAlertRef', { static: false }) facturaCttAlertRef;
  facturaCttModalRef = null;
  facturaCttAlertConfig: any = null;

  @ViewChild('editFaturaCtt', { static: false }) editFaturaCtt: EditEntregaFaturaCttModalComponent;


  transitionController = new TransitionController();
  submittingForm = false;
  submittingAdd = false;
  loading = false;
  loadingModal = false;
  searchable: boolean = true;
  clearEntry = { name: '-- limpar selecção --', value: '-1' };
  toDelete = [];

  comp = 'caixa-vertis';
  initState = null;
  prevState = null;

  // TABS DEFINITION ----------------------------------------------------------
  tabsObjDef: any = [
    { key: 'registo-diario', name: 'Registo Diário', url: 'registo-diario', active: true, disabled: false },
    { key: 'relatorio', name: 'Relatório', url: 'relatorio', active: false, disabled: false },
  ];
  selTabKey = 'registo-diario';

  // CAIXA VERTIS TABLE VARIABLES
  caixaVertisListCol = [
    { key: 'nome_utilizador', name: 'Utilizador', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-center z-index-0 col-80px' },
    { key: 'dt_mov', name: 'Hora', type: 'date', sort: null, searchable: true, centered: true, class: 'col-align-left z-index-0 col-60px' },
    { key: 'nome_condominio', name: 'Condomínio', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left z-index-0 col-400px' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-xxl z-index-0' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-100px z-index-0' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right z-index-0 col-smd' },
    { key: 'saldo', name: 'Saldo', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right z-index-0 col-smd' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'table-checkbox-column col-edit-width col-align-center z-index-0' },  // 'ASC', 'DESC'
  ];
  caixaVertisList: Array<any> = [];
  caixaVertisListOrig: Array<any> = [];
  @ViewChild('caixaVertisTableSearch', { static: false }) caixaVertisTableSearch: ElementRef;
  caixaVertisKeyword: string = null;
  caixaVertisSearching: boolean = false;

  valorTotal = null;
  saldoTotal = null;

  // LOGIN CAIXA VARIABLES
  unauthorized = false;
  username = null;
  password = null;

  hasCaixaOpened = false;
  nCaixa = null;
  now = new Date();

  selCondominio = null;

  newMov = {
    dt_mov: new Date(),
    nome_condominio: null,
    cod_condominio: null,
    descricao: null,
    tipo: null,
    valor: null,
    nome_utilizador: null,
  }

  copyMov = {
    id: null,
    dt_mov: null,
    nome_condominio: null,
    cod_condominio: null,
    descricao: null,
    tipo: null,
    valor: null,
    nome_utilizador: null,
    obj: null
  }

  loginModalOpened = false;

  // CAIXA VERTIS TABLE VARIABLES
  caixaVertisReportListCol = [
    { key: 'nome_utilizador', name: 'Utilizador', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-left z-index-0 col-80px' },
    { key: 'dt_mov', name: 'Hora', type: 'date', sort: null, searchable: true, centered: true, class: 'col-align-left z-index-0 col-60px' },
    { key: 'nome_condominio', name: 'Condomínio', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left z-index-0 col-350px' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-xxl z-index-0' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-50px z-index-0' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right z-index-0 col-smd' },
    { key: 'saldo', name: 'Saldo', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right z-index-0 col-smd' },
  ];
  caixaVertisReportListOrig = [];
  caixaVertisReportList = [];

  date = new Date();
  startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  endDate = new Date();

  saldoTotalReport = 0;

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  @ViewChild('pdf', { static: false }) pdfController;
  @ViewChild('pdfDeclaracao', { static: false }) pdfDeclaracaoController;
  @ViewChild('recibosAnuladosPdf', { static: false }) recibosAnuladosPdf;


  //Entregas e Faturas
  @ViewChild('entregasFaturasRef', { static: false }) entregasFaturasRef: EntregasFaturasComponent;

  // DAILLY TABLE
  caixaVertisListColPDF = [
    { key: 'nome_utilizador', name: 'Utilizador', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-left col-utilizador-pdf' },
    { key: 'dt_mov', name: 'Hora', type: 'date', sort: null, searchable: true, centered: true, class: 'col-align-left col-data-pdf' },
    { key: 'nome_condominio', name: 'Condomínio', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left col-condominio-pdf' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-descricao-pdf' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-tipo-pdf' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right col-saldo-pdf' },
    { key: 'saldo', name: 'Saldo', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right col-saldo-pdf' },
  ];

  // REPORT TABLE
  caixaVertisReportListColPDF = [
    { key: 'nome_utilizador', name: 'Utilizador', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-left col-utilizador-pdf' },
    { key: 'dt_mov', name: 'Hora', type: 'date', sort: null, searchable: true, centered: true, class: 'col-align-left col-data-pdf' },
    { key: 'nome_condominio', name: 'Condomínio', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left col-condominio-pdf' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-descricao-pdf' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-tipo-pdf' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right col-valor-pdf' },
    { key: 'saldo', name: 'Saldo', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right col-saldo-pdf' },
  ];

  // REPORT TABLE
  caixaVertisReportListColValesLimpezaPDF = [
    { key: 'nome_utilizador', name: 'Utilizador', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-left col-utilizador-pdf' },
    { key: 'dt_mov', name: 'Hora', type: 'date', sort: null, searchable: true, centered: true, class: 'col-align-left col-big-data-pdf' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left col-descricao-pdf' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right col-valor-pdf' },
  ];

  pdfReport = {
    title: null,
    reportType: null,
    startDate: null,
    endDate: null,
    now: new Date(),
  }

  newMovInputDesc = false;

  pdfEnabled = false;

  condominioSelectDisabled = false;
  tipoSelectDisabled = false;

  funcionariosOpts = [];
  selFuncionario = null;

  isZIndexZero = false;

  subsMsg: Subscription = null;

  adminEmailAddrs = [];

  // MOVIMENTOS TABLE VARIABLES
  movimentosListCol = [
    // { key: 'utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered two wide' },  // 'ASC', 'DESC'
    { key: 'tipo_doc', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left two wide' },
    { key: 'dt_mov', name: 'Data', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered two wide' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left eight wide' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right three wide' },
    { key: 'em_caixa', name: 'Em Caixa', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-right one wide' },
  ];
  movimentosListColPDF = [
    // { key: 'utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: false, centered: false, class: 'col-centered two wide' },  // 'ASC', 'DESC'
    { key: 'tipo_doc', name: 'Tipo', type: 'text', sort: null, searchable: true, centered: true, class: 'col-align-left col-80px' },
    { key: 'dt_mov', name: 'Data', type: 'date', sort: null, searchable: true, centered: true, class: 'col-centered col-80px' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-left' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-align-right col-100px' },
    { key: 'em_caixa', name: 'Em Caixa', type: 'text', sort: null, searchable: true, centered: false, class: 'col-align-right col-80px' },
  ];
  movimentosList: Array<any> = [];

  loggedUser = null;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public router: Router,
              public message: MessageService,
              public modalService: SuiModalService,
              public location: Location,
              public userSession: UserSessionService,
              public cdRef:ChangeDetectorRef,
              public appConfig: AppConfigService,
              public appState: AppStateService) {
    setInterval(() => { this.newMov.dt_mov = new Date(); }, 10000);

    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'CAIXA_VERTIS') {
        switch (msg.cmd) {
          case 'HIDE_INPUT':
            this.isZIndexZero = true;
            break;
          case 'SHOW_INPUT':
            this.isZIndexZero = false;
            break;
          case 'LOGGED_USER':
            this.loggedUser = msg.username;

            this.checkEntregasEFaturasTab();

            if (msg.hasOwnProperty('refresh') && msg.refresh) {
              this.getCaixaVertis();
              this.getCaixaVertisReport();
            }
            break;
        }
      }
    });

    this.newMov['nome_utilizador'] = this.userSession.getUserFullNameAbrv(true);
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode == 13) {
      if (!this.loginModalOpened) { 
        this.tableAction('add');
      }

      if (this.loginModalOpened) { this.loginCaixa(); }
    }
  }

  ngOnInit() {
    this.checkEntregasEFaturasTab();

    this.setPrevState();

    setTimeout(() => { 
      this.setTab(this.tabsObjDef.find(el => (el.active)).key);
    }, 1);

    this.init();

    this.animate();
  }

  usersOpts = [];
  init() {
    // GET ADMIN EMAIL ADDRESSES
    this.api.getAdminEmailList().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.adminEmailAddrs = [res.data[0]];
      }
    });

    let req = [
      this.api.getAllFuncionarios(),
      this.api.getUtilizadores(0, 500),
    ];
    forkJoin(req).subscribe(res => {
      if (res[0].hasOwnProperty('success') && res[0].success) {
        // FUNCIONARIOS OPTS ARRAY
        this.funcionariosOpts = res[0].data.map(el => { el['type'] = 'FUNCIONARIO'; return { name: el.first_name + ' ' + el.last_name, value: el }; });

        // USER OPTS ARRAY
        this.usersOpts = res[1].data.map(el => {
          el['type'] = 'USER';
          return { name: el['first_name'] + ' ' + el['last_name'], value: el };
        });
        this.usersOpts = this.usersOpts.filter(el => (el.id !== this.userSession.getUserId()));

        // ENTITY ARRAY
        this.entityOpts = this.usersOpts.concat( this.funcionariosOpts );
      }
    }, err => {});

    if (this.userSession.getUserId() !== null) {
      this.api.isCaixaVertisLoggedIn().subscribe(res => {
        if (res.user_logged_in.caixa_vertis_logged_in !== '-1') this.loggedUser = res.user_logged_in.first_name + ' ' + res.user_logged_in.last_name;
      }, err => {});
    }

    this.getCaixaVertis();

    this.api.getCurrentsCustosComunicacoes().subscribe(res => {
      if (res.success) {
        this.currentsCustosComunicacoes = res.data;
      }
    });
  }

  ngOnDestroy() {
    this.setState();

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    if (this.subsMsg) this.subsMsg.unsubscribe();
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  checkEntregasEFaturasTab(reload=false) {
    if (this.userSession.isSuperAdmin() || this.userSession.isCaixaVertisLoggedIn()) {
      if (!this.tabsObjDef.find(el => el.key === 'entregas-faturas')) {
        this.tabsObjDef.splice(1,0, { key: 'entregas-faturas', name: 'Entregas e Faturas', url: 'entregas-faturas', active: false, disabled: false },);
      }
    } else {
      let index = this.tabsObjDef.findIndex(el => el.key === 'entregas-faturas');
      if (index !== -1) {
        this.tabsObjDef.splice(index, 1);
        this.setTab(this.tabsObjDef[0].key);
      }
    }
    if (reload) {
      this.router.navigateByUrl('caixavertis/registo-diario', { skipLocationChange: true }).then(() => {
        this.router.navigate(['caixavertis/registo-diario']);
      }); 
    }
  }


  fetchingCaixaVertis = false;
  isCaixaClosed = false;
  getCaixaVertis() {
    if (this.fetchingCaixaVertis) return;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.fetchingCaixaVertis = true;
    this.api.hasCaixaVertisOpened().subscribe(res => {

      if (res.hasOwnProperty('success') && res.success) {

        if (res.hasOwnProperty('data') && res.data.length > 0) {
          if (res.data[0]['dt_fecho']) {
            this.isCaixaClosed = true;
            this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_CAIXA_VERTIS_STATE', value: this.isCaixaClosed });
          } else {
            this.isCaixaClosed = false;
          }

          this.nCaixa = res.data[0].n_caixa;
        }

        this.caixaVertisListOrig = res.data;
        this.caixaVertisList = [];

        this.saldoTotal = 0;

        let aux = {};
        let isDisabled = false;
        res.data.forEach(el => {

          if (el.obj) {
            try {
              el.obj = JSON.parse(el.obj);
              // isDisabled = (el.obj && el.obj.hasOwnProperty('tipoDoc') && (el.obj.tipoDoc === 'DESPESA' || el.obj.tipoDoc === 'RECIBO' || el.obj.tipoDoc === 'SALDO_INICIAL'));
              isDisabled = (el.obj && el.obj.hasOwnProperty('tipoDoc') && (el.obj.tipoDoc === 'SALDO_INICIAL' || el.obj.tipoDoc === 'SALDO_FINAL'));
            } catch (e) {}
          } else {
            isDisabled = false;
          }

          aux = {
            checked: false,
            disabled: isDisabled,
            edit: false,
            id: el.id,
            id_user: el.id_user,
            id_funcionario: el.id_funcionario,
            id_utilizador: el.id_utilizador,
            dt_mov: el.dt_mov? this.utils.getDate(el.dt_mov) : null,
            nome_condominio: (el.nome_condominio) ? el.nome_condominio : null,
            cod_condominio: (el.cod_condominio) ? el.cod_condominio : null,
            descricao: el.descricao,
            tipo: el.tipo,
            valor: Number(el.valor),
            saldo: null,
            nome_utilizador: (el.last_name) ? el.first_name[0] + el.last_name[0] : el.first_name[0],
            obj: el.obj,
          }
          this.caixaVertisList.push(aux);

          this.saldoTotal += (aux['descricao'] !== 'Saldo Final') ? aux['valor'] : 0;
        });

        this.computeSaldoCVDiario();

        this.hasCaixaOpened = (res.hasOwnProperty('data') && res.data.length > 0);
      }

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingCaixaVertis = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingCaixaVertis = false;
    });

    this.api.getCurrentsCustosComunicacoes().subscribe(res => {
      if (res.success) {
        this.currentsCustosComunicacoes = res.data;
      }
    });
    
  }

  computeSaldoCVDiario() {
    // COMPUTE SALDO - LATERAL
    this.caixaVertisList.reverse().forEach((el, i) => {
      if (i === 0) {
        el.saldo = Number(el.valor);
      } else {
        if (el.descricao === 'Saldo Final') {
          el.saldo = Number(el.valor);
        } else {
          el.saldo = this.caixaVertisList[i - 1].saldo + Number(el.valor);
        }
      }
    });
    this.caixaVertisList.reverse();
    this.caixaVertisList = JSON.parse(JSON.stringify(this.caixaVertisList));
  }

  fetchingCaixaVertisReport = false;
  getCaixaVertisReport() {
    if (this.fetchingCaixaVertisReport) return;

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.pdfEnabled = false;
    this.fetchingCaixaVertisReport = true;

    if (this.keyword) this.searching = true; 

    this.api.getCaixaVertisReport(this.startDate, this.endDate, this.keyword).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.caixaVertisReportListOrig = res.data;

        this.generateReportListObj(res.data);
      } else {
        this.utils.apiErrorMsg(res);
      }

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingCaixaVertisReport = false;
      this.searching = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingCaixaVertisReport = false;
      this.searching = false;
    });
  }

  generateReportListObj(data) {
    let caixaVertisReportList = [];

    let saldo_total_caixa = 0;
    let prevDate = null;
    let prevSaldo = 0;

    let auxDate = null;

    data.reverse().forEach((el, i) => {

      auxDate = this.utils.getFormatedDate(el['dt_mov']);

      if (Number(el.saldo) > 0) {
        prevSaldo = Number(el.saldo);
      } else {
        prevSaldo += Number(el.valor);
      }

      if (el.obj) {
        try {
          el.obj = JSON.parse(el.obj)
        } catch (e) {}
      } 

      let aux = {
        separator: false,
        total: false,

        checked: false,
        edit: false,
        id: el.id,
        dt_mov: el.dt_mov,
        nome_condominio: (el.nome_condominio) ? el.nome_condominio : null,
        cod_condominio: (el.cod_condominio) ? el.cod_condominio : null,
        descricao: el.descricao,
        tipo: el.tipo,
        n_caixa: el._caixa,
        valor: Number(el.valor),
        nome_utilizador: (el.last_name) ? el.first_name[0] + el.last_name[0] : el.first_name[0],
        saldo: prevSaldo,
        obj: (el.obj) ? el.obj : null,
      }

      // TABLE ROW TOTAL AND SEPARATOR
      if (prevDate !== auxDate) {
        // if (i > 0) {
        //   this.caixaVertisReportList.push({ label: 'Total em Caixa', saldo: saldo_total_caixa, separator: false, total: true });
        // }

        // CAIXA SEPARATOR
        // let date = this.utils.getFormatedDate(el['dt_abertura']);
        let date = this.utils.getFormatedDate(el['dt_mov']);
        caixaVertisReportList.push({ label: `Caixa do dia: ${date}`, separator: true, total: false });
        saldo_total_caixa = 0;
      }
      prevDate = auxDate;

      saldo_total_caixa += aux.valor;

      caixaVertisReportList.push(aux);
    });
    // if (this.caixaVertisReportList.length > 0) {
    //   this.caixaVertisReportList.push({ label: 'Total em Caixa', saldo: saldo_total_caixa, separator: false, total: true });
    // }

    caixaVertisReportList = caixaVertisReportList.reverse();

    let auxSep = caixaVertisReportList.filter(el => el.hasOwnProperty('separator') && el.separator);
    let y = 0;

    this.caixaVertisReportList = [];
    caixaVertisReportList.forEach((el, i) => {
      if (i === 0) {
        this.caixaVertisReportList.push(auxSep[y]);
        y++;
      }

      if (el.hasOwnProperty('separator') && !el.separator) {
        this.caixaVertisReportList.push(el);
      } else {
        if (y < auxSep.length) this.caixaVertisReportList.push(auxSep[y]);
        y++;
      }
    });
  }

  searching = false;
  keyword = null;
  @ViewChild('searchRef', { static: false }) searchRef: ElementRef;
  ngAfterViewInit() {
    fromEvent(this.searchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.keyword = val['target']['value'].toLowerCase().trim();

      this.getCaixaVertisReport();
    });

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.deleteAlertCaixaConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertCaixaRef);
    this.deleteAlertCaixaConfig.closeResult = "closed";
    this.deleteAlertCaixaConfig.size = 'mini';
    this.deleteAlertCaixaConfig.transition = 'fade';
    this.deleteAlertCaixaConfig.transitionDuration = 250;

    this.openCVAlertConfig = new TemplateModalConfig<IContext, string, string>(this.openCVAlertRef);
    this.openCVAlertConfig.closeResult = "closed";
    this.openCVAlertConfig.size = 'mini';
    this.openCVAlertConfig.transition = 'fade';
    this.openCVAlertConfig.transitionDuration = 250;

    this.closeCVAlertConfig = new TemplateModalConfig<IContext, string, string>(this.closeCVAlertRef);
    this.closeCVAlertConfig.closeResult = "closed";
    this.closeCVAlertConfig.size = 'mini';
    this.closeCVAlertConfig.transition = 'fade';
    this.closeCVAlertConfig.transitionDuration = 250;

    this.loginCaixaAlertConfig = new TemplateModalConfig<IContext, string, string>(this.loginCaixaAlertRef);
    this.loginCaixaAlertConfig.closeResult = "closed";
    this.loginCaixaAlertConfig.size = 'mini';
    this.loginCaixaAlertConfig.transition = 'fade';
    this.loginCaixaAlertConfig.transitionDuration = 250;

    this.alredyLoggedAlertConfig = new TemplateModalConfig<IContext, string, string>(this.alredyLoggedAlertRef);
    this.alredyLoggedAlertConfig.closeResult = "closed";
    this.alredyLoggedAlertConfig.size = 'mini';
    this.alredyLoggedAlertConfig.transition = 'fade';
    this.alredyLoggedAlertConfig.transitionDuration = 250;

    this.editAlertConfig = new TemplateModalConfig<IContext, string, string>(this.editAlertRef);
    this.editAlertConfig.closeResult = "closed";
    this.editAlertConfig.size = 'small';
    this.editAlertConfig.transition = 'fade';
    this.editAlertConfig.transitionDuration = 250;

    this.recebimentoValoresAlertConfig = new TemplateModalConfig<IContext, string, string>(this.recebimentoValoresAlertRef);
    this.recebimentoValoresAlertConfig.closeResult = "closed";
    this.recebimentoValoresAlertConfig.size = 'tiny';
    this.recebimentoValoresAlertConfig.transition = 'fade';
    this.recebimentoValoresAlertConfig.transitionDuration = 250;

    this.addNameAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addNameAlertRef);
    this.addNameAlertConfig.closeResult = "closed";
    this.addNameAlertConfig.size = 'mini';
    this.addNameAlertConfig.transition = 'fade';
    this.addNameAlertConfig.transitionDuration = 250;

    this.addEntityAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addEntityAlertRef);
    this.addEntityAlertConfig.closeResult = "closed";
    this.addEntityAlertConfig.size = 'mini';
    this.addEntityAlertConfig.transition = 'fade';
    this.addEntityAlertConfig.transitionDuration = 250;

    this.addUserNameAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addUserNameAlertRef);
    this.addUserNameAlertConfig.closeResult = "closed";
    this.addUserNameAlertConfig.size = 'mini';
    this.addUserNameAlertConfig.transition = 'fade';
    this.addUserNameAlertConfig.transitionDuration = 250;

    this.movConfirmAlertConfig = new TemplateModalConfig<IContext, string, string>(this.movConfirmAlertRef);
    this.movConfirmAlertConfig.closeResult = "closed";
    this.movConfirmAlertConfig.size = 'normal';
    this.movConfirmAlertConfig.transition = 'fade up';
    this.movConfirmAlertConfig.transitionDuration = 400;

    this.facturaCttAlertConfig = new TemplateModalConfig<IContext, string, string>(this.facturaCttAlertRef);
    this.facturaCttAlertConfig.closeResult = "closed";
    this.facturaCttAlertConfig.size = 'small';
    this.facturaCttAlertConfig.transition = 'fade up';
    this.facturaCttAlertConfig.transitionDuration = 400;

    this.deleteReciboAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteReciboAlertRef);
    this.deleteReciboAlertConfig.closeResult = "closed";
    this.deleteReciboAlertConfig.size = 'tiny';
    this.deleteReciboAlertConfig.transition = 'fade';
    this.deleteReciboAlertConfig.transitionDuration = 250;
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selCondominio); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve([{ name: '-- Novo condomínio --', value: { cod: '-1' }}].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: { cod: el.cod } }; })));
                } else {
                  return resolve([]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve([{ name: '-- Novo condomínio --', value: { cod: '-1' }}].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: { cod: el.cod } }; })));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  condominiosTimerModal = null;
  condominiosLookupModal = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selCondominio); });
    }

    clearTimeout(this.condominiosTimerModal);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimerModal = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: { cod: el.cod } }; }));
                } else {
                  return resolve([]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: { cod: el.cod } }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  condoSelected() {
    if (this.newMov.cod_condominio.hasOwnProperty('cod') && this.newMov.cod_condominio.cod === '-1') {
      this.router.navigate(['condominios/condominio', 'criar']);

      // BREADCRUMB SIGNAL
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVO CONDOMÍNIO' });
    }
  }

  loadingModalDelete = false;
  del(delMovimentos=false, target=null) {
    if (target === 'RECIBO') {
      this.submittingFormRecibo = true;
      setTimeout(() => {
        this.submittingFormRecibo = false;
      }, 4000);

      if (!this.razaoReciboDelete) return;
    }

    this.loadingModal = true;

    if (target !== 'RECIBO' && this.alertModalCaixaRef === null && !delMovimentos && this.toDelete.find(el => (el.obj && el.obj.hasOwnProperty('tipoDoc')))) {
      this.loadingModal = false;

      this.alertModalCaixaRef = this.modalService
        .open(this.deleteAlertCaixaConfig)
        .onApprove(() => { this.alertModalCaixaRef = null; })
        .onDeny(() => { this.alertModalCaixaRef = null; });

        return;
    }

    let toDeleteDespesas = [];
    let toDeleteRecibos = [];
    let toDeleteReceitas = [];
    let toDeleteCreditos = [];
    let toDeleteRest = [];

    this.toDelete.forEach(mov => {

      if ( mov.obj && mov.obj.hasOwnProperty('tipoDoc') ) {
        switch (mov.obj.tipoDoc) {
          case 'DESPESA':
              toDeleteDespesas.push({ id: mov.obj.id, cod_condominio: mov.obj.cod_condominio, n_despesa: mov.obj.n_despesa, valor: mov.obj.valor, action: 'REMOVE_MOV', id_mov: (mov.hasOwnProperty('obj') && mov.obj.id_mov) ? mov.obj.id_mov.toString() : null, nid_conta: mov.obj.nid_conta });
            break;
          case 'RECEITA':
            toDeleteReceitas.push({ id: mov.obj.id, cod_condominio: mov.obj.cod_condominio, n_receita: mov.obj.n_receita, valor: mov.obj.valor, action: 'REMOVE_MOV', id_mov: (mov.hasOwnProperty('obj') && mov.obj.id_mov) ? mov.obj.id_mov.toString() : null, nid_conta: mov.obj.nid_conta });
            break;
          case 'RECIBO':
            toDeleteRecibos.push({ id: mov.obj.id, cod_condominio: mov.obj.cod_condominio, n_recibo: mov.obj.n_recibo, cod: 'GET_COD', del_razao: this.razaoReciboDelete, alterado_por: this.userSession.getUserFullName(), alterado_em: new Date() });
            break;
          case 'CREDITO':
              toDeleteCreditos.push({ id: mov.obj.id, cod_condominio: mov.obj.cod_condominio, n_credito: mov.obj.n_credito, action: 'REMOVE_MOV_BLOCKED' });
            break;
        }
      } else {
        
      }

      toDeleteRest.push(mov);
    });


    let req = [];

    if (toDeleteDespesas.length > 0) req.push(this.api.delDespesas(toDeleteDespesas, 'CAIXA_VERTIS'));
    
    if (toDeleteRecibos.length > 0) req.push(this.api.delRecibos(toDeleteRecibos, 'CAIXA_VERTIS'));
    
    if (toDeleteReceitas.length > 0) req.push(this.api.delReceitas(toDeleteReceitas, 'CAIXA_VERTIS'));
    
    if (toDeleteCreditos.length > 0) req.push(this.api.delCreditos(toDeleteCreditos, 'CAIXA_VERTIS'));
    
    if (toDeleteRest.length > 0) req.push(this.api.delCaixaVertisEntry(toDeleteRest));

    forkJoin(req).subscribe(res => {
      if (res[0].hasOwnProperty('success') && res[0].success) {

        this.toDelete.forEach(del => {
          this.caixaVertisList = this.caixaVertisList.filter(el => (el.id !== del.id));
          this.caixaVertisListOrig = this.caixaVertisListOrig.filter(el => (el.id !== del.id));
        });

        let req = [];
        this.toDelete.forEach(el => {
          // REGISTO ACTIVIDADES API CALL
          let titulo = 'Movimento Caixa Vertis Removido';
          let descricao = null;

          if (el.cod_condominio && el.nome_condominio) {
            descricao = 'Condomínio: ' + el.cod_condominio + ' - ' + el.nome_condominio + ', Descrição: ' + el.descricao;
          } else {
            descricao = 'Descrição: ' + el.descricao;
          }

          req.push(this.api.saveRegistoActividade(null, null, null, titulo, descricao));
        });
        forkJoin(req).subscribe(res => {}, err => { });

        this.getCaixaVertis();
        this.getCaixaVertisReport();

        this.computeSaldoTotal();
        this.api.getCurrentsCustosComunicacoes().subscribe(res => {
          if (res.success) {
            this.currentsCustosComunicacoes = res.data;
          }
        });

      } else {
        this.utils.apiErrorMsg(res[0]);
      }
      if (this.alertModalRef) this.alertModalRef.approve();
      if (this.alertReciboModalRef) this.alertReciboModalRef.approve();
      if (this.alertModalCaixaRef) this.alertModalCaixaRef.approve();
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.caixaVertisList.map(el => { if (el.edit) el.checked = true; } ) : this.caixaVertisList.map(el => el.checked = false );
  }

  async tableAction(action, reportType=null) {
    switch (action) {
      case 'csv':
        this.exportExcel();
        break;
      case 'pdf': break;
      case 'print':
        this.exportPdf(null, reportType);
        break;
      case 'add':
        if (this.newMov.descricao === 'RECEBIMENTO_VALORES') {

          if (this.recebimentoValoresModalRef !== null) return;

          this.submittingAdd = true;
          if (!this.isEntryValid()) {
            setTimeout(() => { this.submittingAdd = false; }, 3000);
            return;
          }

          this.recValores = { nome: null, obs: null, now: null };
          this.submittingRecValores = false;
          this.recebimentoValoresModalRef = this.modalService
            .open(this.recebimentoValoresAlertConfig)
            .onApprove(() => { 
              this.addMovimentoCaixaVertis('DECLARACOES');

              this.loadingModal = false;
              this.recebimentoValoresModalRef = null;
            })
            .onDeny(() => { 
              this.loadingModal = false;
              this.recebimentoValoresModalRef = null;
            });
        } else {
          this.addMovimentoCaixaVertis();
        }
        break;
      case 'delete':
        this.toDelete = this.caixaVertisList.filter(el => el.checked);
        if (this.toDelete.length > 0) {
          this.presentDeleteAlert();
        } else {
          this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
        }
        break;
    }
  }

  submittingFormRecibo = false;
  razaoReciboDelete = null;
  presentDeleteAlert() {
    let descricao = this.toDelete[0].descricao;

    if (descricao.match(/^R \d{1,}/g) !== null) {
      this.alertReciboModalRef = this.modalService
        .open(this.deleteReciboAlertConfig)
        .onApprove(() => { this.loadingModal = false; this.toDelete = []; this.razaoReciboDelete = null; this.submittingFormRecibo = false; })
        .onDeny(() => { this.loadingModal = false; this.toDelete = []; this.razaoReciboDelete = null; this.submittingFormRecibo = false; });
    } else {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => { this.loadingModal = false; this.toDelete = []; })
        .onDeny(() => { this.loadingModal = false; this.toDelete = []; });
    }
  }


  formSubmittedEntity = false;
  selEntity = null;
  entityOpts = [];
  async addEntity() {
    this.formSubmittedEntity = true;
    if (!this.selEntity) {
      setTimeout(() => { this.formSubmittedEntity = false; }, 4000);
      return;
    }
    if (this.selectedType === 'CTT') {
      let res = await this.getNewEntregaValues();
      if (!res) return;
    }
    this.addEntityModalRef.approve();
  }

  newEntregaValues:{custo_vertis, custo_ctt, valor} = null;
  getNewEntregaValues():Promise<boolean> {
    return new Promise((resolve) => {
      this.api.getNewEntregaCustosComunicacoes().subscribe(res => {
        if (res.success) {
          this.newEntregaValues = res.data
          this.newMov.valor = parseFloat(this.newEntregaValues.custo_ctt).toFixed(2);
          if (this.newMov.valor == 0) {
            this.toastr.info('Não existe correio por entregar.', 'Ups...!', { timeOut: 4000 });
            this.resetNewEntry();
          }
        }
        resolve(true);
      }, err => {
        this.newMov.valor = null;
        this.newEntregaValues = null;
        this.toastr.error('Não foi possível obter os valores da nova entrega. Por favor tente novamente.', 'Ups...!', { timeOut: 4000 });
        this.resetNewEntry();
        resolve(false);
      });
    });
  }


  formSubmittedReforco = false;
  addUserName() {
    this.formSubmittedReforco = true;
    if (!this.selUtilizadorReforco) {
      setTimeout(() => { this.formSubmittedReforco = false; }, 4000);
      return;
    }

    this.addUserNameModalRef.approve();
  }

  formSubmittedFuncionario = false;
  addFuncionario() {
    this.formSubmittedFuncionario = true;
    if (!this.selFuncionario) {
      setTimeout(() => { this.formSubmittedFuncionario = false; }, 4000);
      return;
    }

    this.addNameModalRef.approve()
  }

  descricaoChanged(ev) {
    if (!ev || ev === '') {
      // NEW FORM
      this.newMov.descricao = null;
      this.newMovInputDesc = false;

      // COPY FORM
      this.copyMov.descricao = null;
      this.copyMovInputDesc = false;
    }
  }

  factCttValor = null;
  factCttTroco = null;
  factCttDataExpedicao = new Date();
  registoCttSeparator = null;

  factCRPValor = null;
  factCRPTroco = null;

  selUtilizadorReforco = null;
  factModalMode = null;
  selectedType = null;
  valorDisabled = false;
  descricaoSelected(copy=false) {
    if (!copy) {
      if (!this.newMov.descricao) return;
      this.caixaVertisList.forEach(el => el.checked = false);
      this.valorDisabled = false;
      this.selFuncionario = null;
      this.selUtilizadorReforco = null;
      this.idEntregaValores = null;
      this.entregaValoresOpts = [];
      this.newEntregaValues = null;
      this.factModalMode = null;
      
      let aux = this.appConfig.caixaVertisDescricaoOtps.find(el => (el.value === this.newMov.descricao));
      if (aux) {
        this.newMov.tipo = aux.tipoMov;
        this.tipoSelectDisabled = !(aux.value === 'INPUT_TEXT');
        this.condominioSelectDisabled = !(aux.value === 'INPUT_TEXT' || aux.value === 'RECEBIMENTO_VALORES');
        if (this.condominioSelectDisabled) this.newMov.cod_condominio = null;

        if (aux.value === 'CTT' || aux.value === 'REFORCO_CAIXA' || aux.value === 'ENTREGA_VALORES' || aux.value === 'ENTREGA_VALORES_COFRE' || aux.value === 'ENTREGA_VALORES_CAIXA' || aux.value === 'CONSERVATORIA') {
          this.selUtilizadorReforco = null;

          this.selectedType = aux.value;


          this.addEntityModalRef = this.modalService
            .open(this.addEntityAlertConfig)
            .onApprove(() => {
              this.selectedType = null;
              this.loadingModal = false;
              this.formSubmittedReforco = false;
              this.formSubmittedEntity = false;
            })
            .onDeny(() => { 
              this.resetNewEntry();

              this.selectedType = null;
              this.loadingModal = false;
              this.selUtilizadorReforco = null;
              this.formSubmittedReforco = false;
              this.formSubmittedEntity = false;
            });
        }

        if (aux.value === 'VALE_LIMPEZA') {
          this.selFuncionario = null;

          this.addNameModalRef = this.modalService
            .open(this.addNameAlertConfig)
            .onApprove(() => {
              this.loadingModal = false;
              this.formSubmittedFuncionario = false;
            })
            .onDeny(() => {
              this.resetNewEntry();

              this.loadingModal = false;
              this.selFuncionario = null;
              this.formSubmittedFuncionario = false;
            });
        }

        if (aux.value === 'CTT') {
          this.registoCttSeparator = {
            id: null,
            cod_condominio: 'ENTREGA_VALORES_CTT',
            valor_lancado: null,
            obj: null,
            data: new Date(),
          }
        } else if (aux.value === 'FACTURA_CTT' || aux.value === 'FACTURA_CONSERVATORIA') {
          if (this.facturaCttModalRef !== null) return;

          this.factModalMode = aux.value; 

          this.entregaValoresOpts = this.currentsCustosComunicacoes.map(el => {
            let name = parseFloat(el.valor).toFixed(2) + '€ - Entregue a ' + el.entregue_a_nome + ' (' + this.utils.getFormatedDate(el.data) + ')';
            let aux = {name: name, value: el.id}
            return aux;
          });

          //TODO Is permission
          if (this.userSession.isSuperAdmin()) {
            this.entregaValoresOpts = [this.semEntregaOpt].concat(this.entregaValoresOpts);
          }

          if (this.entregaValoresOpts.length === 2) this.idEntregaValores = this.entregaValoresOpts[1].value;

          // OPEN MODAL FACTURA CTT (TROCO E VALOR DA FACTURA)
          this.facturaCttModalRef = this.modalService
            .open(this.facturaCttAlertConfig)
            .onApprove(() => {

              if (this.factModalMode === 'FACTURA_CTT') {
                this.registoCttSeparator = {
                  id: null,
                  cod_condominio: 'FACTURA_CTT',
                  valor_lancado: Number(this.factCttTroco),
                  obj: { valor_factura_ctt: Number(this.factCttValor), valor_troco_ctt: Number(this.factCttTroco) },
                  data: new Date(),
                  factCttDataExpedicao: this.factCttDataExpedicao,
                }
                this.valorDisabled = true;
                this.newMov.valor = Number(this.factCttTroco);
                this.newMov.tipo = this.newMov.valor > 0 ? 'C' : 'D';
                this.newMov.valor = Math.abs(this.newMov.valor);
              }

              if (this.factModalMode === 'FACTURA_CONSERVATORIA') {
                this.newMov.valor = Number(this.factCRPTroco);
              }

              this.loadingModal = false;
              this.facturaCttModalRef = null;
            })
            .onDeny(() => {
              this.factModalMode = null;

              this.factCttValor = null;
              this.factCttTroco = null;
              this.factCttDataExpedicao = new Date();

              this.factCRPValor = null;
              this.factCRPTroco = null;

              this.loadingModal = false;
              this.facturaCttModalRef = null;

              this.resetNewEntry();
            });

        } else {
          this.registoCttSeparator = null;
        }
      }

      // ENABLE FREE INPUT TEXT ON DESCRIPTION
      if (aux && aux.value === 'INPUT_TEXT') {
        this.newMov.descricao = null;
        this.newMovInputDesc = true;
        setTimeout(() => {this.descInput.nativeElement.focus(); }, 1);
      }
    } else {  // EDIT MODE
      if (!this.copyMov.descricao) return;

      this.valorDisabled = false;

      let aux = this.appConfig.caixaVertisDescricaoOtps.find(el => (el.value === this.copyMov.descricao));
      if (aux) {
        // this.copyMov.tipo = aux.tipoMov;
        this.tipoSelectDisabled = !(aux.value === 'INPUT_TEXT');
        this.condominioSelectDisabled = (aux.value !== 'INPUT_TEXT');
        if (this.condominioSelectDisabled) this.copyMov.cod_condominio = null;
      }

      // ENABLE FREE INPUT TEXT ON DESCRIPTION
      if (aux && aux.value === 'INPUT_TEXT') {
        this.copyMov.descricao = null;
        this.copyMovInputDesc = true;
        setTimeout(() => {this.descInput.nativeElement.focus(); }, 1);
      }
    }
  }

  presentLoginCaixaModal() {
    if (this.userSession.isCaixaVertisLoggedIn()) {
      this.toastr.success('Já tem sessão iniciada.', 'Caixa Vertis', { timeOut: 4000 });
      return;
    }

    this.username = this.userSession.getUsername();

    this.loginModalOpened = true;
    this.loginCaixaModalRef = this.modalService
      .open(this.loginCaixaAlertConfig)
      .onApprove(() => { 
        this.loadingModal = false; 
        this.loginModalOpened = false;
      })
      .onDeny(() => { this.loadingModal = false; this.username = null; this.password = null; this.loginModalOpened = false; });
  }

  toastEnabled = true;
  loginCaixa(force='0') {

    if (!this.username && !this.password) return;

    this.loadingModal = true;
    this.api.loginCaixa(this.username, this.password, force).subscribe(res => {
      if (res['success'] && res.success) {
        if (res.hasOwnProperty('data') && res.data.hasOwnProperty('id')) {
          this.loggedUser = res.data.first_name + ' ' + res.data.last_name;

          this.loginCaixaModalRef.approve();

          this.alredyLoggedModalRef = this.modalService
            .open(this.alredyLoggedAlertConfig)
            .onApprove(() => { this.loginCaixa('1'); this.loadingModal = false; })
            .onDeny(() => { this.loadingModal = false; });

        } else {
          this.userSession.setCaixaVertisState('login');

          this.loginCaixaModalRef.approve();
          if (this.toastEnabled) {
            this.toastr.success('Login efectuado com sucesso.', 'Caixa Vertis', { timeOut: 4000 });
            setTimeout(() => { this.toastEnabled = true; }, 3500);
          }

          this.username = null;
          this.password = null;
        }

        // REGISTO ACTIVIDADES API CALL
        let descricao = 'Login Caixa Vertis efectuado.';
        this.api.saveRegistoActividade(null, null, null, null, descricao).subscribe(res => {}, err => { });
        
      } else {
        this.utils.apiErrorMsg(res);

        this.unauthorized = true;
        this.password = null;
        setTimeout(() => { this.unauthorized = false; }, 4000);
      }
      this.checkEntregasEFaturasTab(true);
      this.loadingModal = false;
    }, err => {
      this.loadingModal = false;
    });
  }

  setTab(targetList) {
    this.selTabKey = targetList;
    this.tabsObjDef.forEach(el => {
      if (el.key === targetList) el.active = true;
    });

    switch (targetList) {
      case 'registo-diario':
        this.location.replaceState('/caixavertis/registo-diario/');

        this.getCaixaVertis();
        break;
      case 'relatorio':
        // if (this.caixaVertisReportList.length === 0) { this.getCaixaVertisReport(); }  // GET REPORT ON TAB SWITCH 
        this.getCaixaVertisReport();

        this.location.replaceState('/caixavertis/relatorio/');
        break;
      case 'entregas-faturas':
        this.entregasFaturasRef.getEntregasFaturas();
        this.location.replaceState('/caixavertis/entregas-faturas/');
        break;
    }
  }

  submittingFormEmail = false;
  sendingEmail = false;
  emailAddr = null;
  emailName = null;
  sendEmail() {
    if (this.sendingEmail) return;

    this.sendingEmail = true;

    this.pdfReport.title = 'Relatório de Caixa Vertis';
    this.pdfReport.startDate = new Date();
    this.pdfReport.endDate = this.endDate;
    this.pdfReport.now = new Date();

    this.pdfEnabled = true;

    this.movConfirmModalRef = null;

    this.caixaVertisListPDF = [].concat(this.caixaVertisList).reverse();

    setTimeout(() => {
      let emailsToSend = [];

      this.recibosAnuladosPdf.updateData().then(_ => {
        let promises = 
        [this.pdfController.export(),
        this.recibosAnuladosPdf.export()]
  
        Promise.all(promises).then( async groups => {
         
            const rootGroup = new Group({ pdf: { multiPage: true } });
            groups.forEach((group) => {
                rootGroup.append(...group.children);
            });
          return exportPDF(rootGroup, { paperSize: 'A4' });
        }).then((dataUri) => {        
          let base64  = dataUri.replace('data:application/pdf;base64,', '');
          base64  = base64.replace(' ', '+');
    
          //this.recibosAnuladosPdf.clearData();
  
          this.adminEmailAddrs.forEach(el => {
  
            this.emailAddr = el.email;
            this.emailName = '';
            if (el.first_name) this.emailName += el.first_name;
            if (el.last_name) this.emailName += (' ' + el.last_name);
  
            if (this.emailAddr) {
              let from = this.appConfig.company.email;
              let to = this.emailAddr;
              let subjectMsg = 'Relatório Fecho Caixa Vertis  - ' + formatDate(new Date(), this.format, this.locale);
              let bodyMsg = this.getEmailBody();
              let attachment = base64;
              let fromName = 'VERTIS - Gestão Condomínios';
              let toName = (this.emailName) ? this.emailName : null;
        
              let filename = 'relatorio_caixa_vertis_' + formatDate(new Date(), this.format, this.locale) + '.pdf';
  
              emailsToSend.push({
                from: from,
                to: to,
                subject: subjectMsg,
                body: bodyMsg,
                attachment: attachment,
                filename: filename,
                fromName: fromName,
                toName: toName,
              });
  
              this.emailAddr = null;
            }
          });
  
          this.api.sendEmailV2(emailsToSend).subscribe(res => {
            this.toastr.success('Relatório de Caixa Vertis diário enviado com sucesso.', 'Email Enviado', { timeOut: 4000 });
    
            this.emailAddr = null;
            this.sendingEmail = false;
          }, err => { this.sendingEmail = false; });
        });
      });

      

    },200);
  }

  getEmailBody() {
    let htmlEmail = '';

        // TEXTO INICIAL
        htmlEmail += '<div style="margin-bottom: 35px;">';
        this.appConfig.caixaVertisBody.forEach(line => {
          if (line) {
            htmlEmail += '<span>' + line + '</span>';
          } else {
            htmlEmail += '<span><br><br></span>';
          }
        });
        htmlEmail += '</div>';

        htmlEmail += this.utils.getEmailFooter();

      return this.utils.setEmailTemplate(htmlEmail);
  }

  closingCV = false;
  closeCV() {
    if (this.closingCV) return;

    // CLOSE CAIXA VERTIS
    this.loadingModal = true;

    let idLastEntry = this.caixaVertisList[0].id;

    this.closingCV = true;
    this.api.closeCaixaVertis(idLastEntry, this.nCaixa, Number(this.saldoTotal)).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        if (res.status) {
          this.hasCaixaOpened = false;
          this.isCaixaClosed = true;
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_CAIXA_VERTIS_STATE', value: this.isCaixaClosed });

          // RESET COMPONENT
          this.resetNewEntry();
          if (this.movConfirmModalRef) this.movConfirmModalRef.approve();

          // SEND DAILY REPORT TO ADMINISTRATOR
          this.sendEmail();

          // GET CAIXA VERTIS DATASET
          this.getCaixaVertis();
        } else {
          this.toastr.error('Não tem sessão iniciada na Caixa Vertis.', 'Ups...!', { timeOut: 4000 });
          if (this.movConfirmModalRef) this.movConfirmModalRef.deny();
        }
        this.loadingModal = false;
      } else {
        this.utils.apiErrorMsg(res);
        if (this.movConfirmModalRef) this.movConfirmModalRef.deny();
        this.loadingModal = false;
      }
      this.closingCV = false;
    }, err => {
      if (this.movConfirmModalRef) this.movConfirmModalRef.deny();
      this.loadingModal = false;
      this.closingCV = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  closingCaixa = false;
  setCaixaState(action) {
    switch (action) {
      case 'OPEN':
        this.openCVModalRef = this.modalService
          .open(this.openCVAlertConfig)
          .onApprove(() => { this.loadingModal = false; })
          .onDeny(() => { this.loadingModal = false; });
        break;
      case 'CLOSE':
        if (this.closingCaixa) return;

        // GET MOVIMENTOS DO DIA EM CAIXA
        this.closingCaixa = true;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
        this.api.getMovimentosEmCaixa().subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
1
            this.movimentosList = res.data.map(el => {
              el['utilizador'] = null;  // <-- TODO: ADD USER FIRST AND LAST NAME.
              el['valor'] = Number(parseFloat(el['valor']).toFixed(2));
              el['dt_mov'] = (el['dt_mov']) ? this.utils.getDate(el['dt_mov']) : null;
              el['tipo_doc'] = (el['tipo_doc']) ? this.utils.getTipoMovimento(el['tipo_doc']) : this.utils.getTipoMovimento(el['tipo_movimento']);

              el['em_caixa'] = false;
              this.caixaVertisList.forEach(it => {
                let auxDescr = it.descricao.split('/');
                if (auxDescr.length > 0 && el.descricao.indexOf(auxDescr[0].trim()) !== -1) {
                  el['em_caixa'] = true;
                }
              });

              try {
                el['obj'] = JSON.parse(el['obj']);
              } catch {
                el['obj'] = null;
              }

              return el;
            });

            if (this.movimentosList.length > 0) {
              // PRESENT CONFIRMATION MODAL
              this.movConfirmModalRef = this.modalService
                .open(this.movConfirmAlertConfig)
                .onApprove(() => { this.loadingModal = false; this.movConfirmModalRef = null; this.closingCaixa = false; })
                .onDeny(() => { this.loadingModal = false; this.movConfirmModalRef = null; this.closingCaixa = false; });
            } else {
              this.movConfirmModalRef = null;
              this.closingCaixa = false;

              this.closeCV();
            }

          } else {
            this.utils.apiErrorMsg(res);
            this.closingCaixa = false;
          }
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        }, err => {
          this.closingCaixa = false;

          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        });
        break;
    }
  }

  descricao = null;
  addMovimentoCaixaVertis(from='') {
    this.descricao = this.newMov.descricao;
    this.submittingAdd = true;

    if (!this.isEntryValid() && from !== 'DECLARACOES') {
      setTimeout(() => { this.submittingAdd = false; }, 3000);
      return;
    }

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    let aux = this.appConfig.caixaVertisDescricaoOtps.find(el => (el.value === this.newMov.descricao));
    if (aux && aux.hasOwnProperty('name')) {
      this.newMov.descricao = aux.name;
    }

    if (this.factModalMode === 'FACTURA_CTT') {

      if (this.idEntregaValores == '-1') {
        this.newMov.descricao += ' / ' + this.selEntity['first_name'] + ' ' + this.selEntity['last_name'];
      } else {
        let entrega = this.currentsCustosComunicacoes.find(el => el.id === this.idEntregaValores);
        this.newMov.descricao += ' / ' + entrega.entregue_a_nome;
      }

    } else {
      if (this.selEntity && this.selEntity.hasOwnProperty('type') && this.selEntity.type === 'FUNCIONARIO') {
        this.selFuncionario = this.selEntity;
      }
  
      if (this.selEntity && this.selEntity.hasOwnProperty('type') && this.selEntity.type === 'USER') {
        this.selUtilizadorReforco = this.selEntity;
      }
  
      if (this.selFuncionario !== null) {
        this.newMov.descricao += ' / ' + this.selFuncionario.first_name + ' ' + this.selFuncionario.last_name;
      }
  
      if (this.selUtilizadorReforco !== null) {
        this.newMov.descricao += ' / ' + this.selUtilizadorReforco.first_name + ' ' + this.selUtilizadorReforco.last_name;
      }
    }


    let valor = (this.newMov.tipo === 'C') ? Number(this.newMov.valor) : -1 * Number(this.newMov.valor);

    let obj = null;
    let nomeDeclaracao = null;
    if (from === 'DECLARACOES') {
      this.recValores.now = new Date();
      nomeDeclaracao = this.recValores.nome;
      obj = this.recValores;
    }

    // OBJECT FOR FACTURA CTT
    if (this.newMov['descricao'] === 'Fatura Ctt') {
      obj = {
        valor: Number(this.factCttValor),
        troco: Number(this.factCttTroco),
        data_expedicao: this.factCttDataExpedicao,
        tipo: 'FACTURA_CTT',
      }
    }

    // OBJECT FOR FACTURA CRP
    if (this.newMov['descricao'] === 'Fatura CRP') {
      obj = {
        valor: Number(this.factCRPValor),
        troco: Number(this.factCRPTroco),
        tipo: 'FACTURA_CONSERVATORIA',
      }
    }

    let idFuncionario = (this.selFuncionario) ? this.selFuncionario.id : null;
    let idUtilizador = (this.selUtilizadorReforco) ? this.selUtilizadorReforco.id : null;
    let cttObj = null;
    let assunto = null;
    if (this.newMov.descricao.includes('Entrega Valores Ctt')) {
      assunto = 'CTT';
      cttObj = {
        custo_ctt: this.newEntregaValues ? this.newEntregaValues.custo_ctt : null,
        custo_vertis: this.newEntregaValues ? this.newEntregaValues.custo_vertis : null,
        valor: this.newEntregaValues ? this.newEntregaValues.valor : null,
        troco: null,
        id_entrega: null,
        tipo: 'ENTREGA'
      }
    } else if (this.newMov.descricao.includes('Fatura Ctt')) {
      assunto = 'CTT';
      cttObj = {
        custo_ctt: null,
        custo_vertis: null,
        valor: Number(this.factCttValor),
        troco: Number(this.factCttTroco),
        data_expedicao: this.factCttDataExpedicao,
        id_entrega: this.idEntregaValores != '-1' ? this.idEntregaValores : null,
        tipo: 'FATURA'
      }

      
      if (this.idEntregaValores != '-1') {
        let entrega = this.currentsCustosComunicacoes.find(el => el.id === this.idEntregaValores);
        if (entrega.entregue_a_funcionario) idFuncionario = entrega.entregue_a_funcionario;
        if (entrega.entregue_a_utilizador) idUtilizador = entrega.entregue_a_utilizador;
      } else {


        if (this.selEntity['type'] == 'FUNCIONARIO') {
          idFuncionario =  this.selEntity.id;
          idUtilizador = null;
        } else if (this.selEntity['type'] == 'USER') {
          idFuncionario =  null;
          idUtilizador = this.selEntity.id;
        } else {
          this.toastr.error('Não é possível guardar a fatura. Por favor selecione o utilizador.', 'Alerta');
          return;
        }
      }

    }
     
    this.api.addMovimentoCaixaVertis(this.caixaVertisListOrig[this.caixaVertisListOrig.length - 1].dt_abertura, this.nCaixa, this.newMov.dt_mov, (this.newMov.cod_condominio && this.newMov.cod_condominio.hasOwnProperty('cod')) ? this.newMov.cod_condominio.cod : null, this.newMov.descricao, this.newMov.tipo, valor, idFuncionario, idUtilizador, obj, nomeDeclaracao, assunto, cttObj).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        // GENERATE DECLARACAO
        if (from === 'DECLARACOES') {
          // this.getDeclaracaoPDF();
        }

        if (res.status) {
          this.caixaVertisList = [{
            checked: false,
            edit: false,
            id: res.data.id,
            descricao: res.data.descricao,
            dt_mov: res.data.dt_mov,
            nome_condominio: res.data.nome_condominio,
            cod_condominio: res.data.cod_condominio,
            nome_utilizador: (res.data.last_name) ? res.data.first_name[0] + res.data.last_name[0] : res.data.first_name[0],
            tipo: res.data.tipo,
            valor: res.data.valor,
            saldo: this.caixaVertisList[0].saldo + Number(res.data.valor),
            id_user: res.data.id_user,
            id_funcionario: res.data.id_funcionario,
            id_utilizador: res.data.id_utilizador,
            obj: (res.data.obj) ? JSON.parse(res.data.obj) : null,
          }].concat(this.caixaVertisList);

          if (cttObj) {
            if (cttObj.id_entrega) {
              this.currentsCustosComunicacoes = this.currentsCustosComunicacoes.filter(el => el.id !== cttObj.id_entrega);
            } else {
              this.api.getCurrentsCustosComunicacoes().subscribe(res => {
                if (res.success) {
                  this.currentsCustosComunicacoes = res.data;
                }
              });
            }
          }

          this.saldoTotal += (res.data['descricao'] !== 'Saldo Final') ? Number(res.data.valor) : 0;
          this.isCaixaClosed = false;
          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_CAIXA_VERTIS_STATE', value: this.isCaixaClosed });
        } else {
          this.toastr.error('Não tem sessão iniciada na Caixa Vertis.', 'Ups...!', { timeOut: 4000 });
        }
        this.submittingAdd = false;
        this.resetNewEntry();

        // UPDATE REPORT LIST
        this.getCaixaVertisReport();

        // CLEAR MODAL SELECTION
        this.selUtilizadorReforco = null;
        this.selFuncionario = null;
        this.selEntity = null;

        this.factCttTroco = null;
        this.factCttValor = null;
        this.factCttDataExpedicao = new Date();
        this.factCRPTroco = null;
        this.factCRPValor = null;

        
        this.idEntregaValores = null;
        this.entregaValoresOpts = [];
        this.newEntregaValues = null;

        // REGISTO ACTIVIDADES API
        let titulo = 'Movimento Caixa Vertis Criado';
        let descricao = null;
        if (res.hasOwnProperty('data') && res.data) {
          if (res.data.cod_condominio && res.data.nome_condominio) {
            descricao = 'Condomínio: ' + res.data.cod_condominio + ' - ' + res.data.nome_condominio + ', Descrição: ' + res.data.descricao + ', Valor: ' + Number(res.data.valor).toFixed(2) + '€';
          } else {
            descricao = 'Descrição: ' + res.data.descricao + ', Valor: ' + Number(res.data.valor).toFixed(2) + '€';
          }
          this.api.saveRegistoActividade(null, null, null, titulo, descricao).subscribe(res => {}, err => { });  
        }

      } else {
        this.utils.apiErrorMsg(res);
      }

      this.submittingAdd = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

      this.submittingAdd = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  isEntryValid() {
    if ( (this.newMovInputDesc && (this.newMov.dt_mov && this.newMov.descricao && this.newMov.tipo && this.newMov.valor) ) || (this.newMov.dt_mov && (this.newMov.cod_condominio || this.condominioSelectDisabled) && this.newMov.descricao && this.newMov.tipo && this.newMov.valor) ) return true;

    if ((this.newMov.descricao === 'FACTURA_CONSERVATORIA' || this.newMov.descricao === 'FACTURA_CTT') && this.newMov.tipo && Number(this.newMov.valor) >= 0) return true;

    return false;
  }

  isCopyEntryValid() {
    if (this.copyMov.dt_mov && (this.copyMov.cod_condominio || this.condominioSelectDisabled) && this.copyMov.descricao && this.copyMov.tipo && this.copyMov.valor) return true; 

    return false;
  }

  resetNewEntry() {
    this.newMov = {
      dt_mov: new Date(),
      nome_condominio: null,
      cod_condominio: null,
      descricao: null,
      tipo: null,
      valor: null,
      nome_utilizador: this.userSession.getUserFullNameAbrv(true),
    }
    this.newMovInputDesc = false;

    this.condominioSelectDisabled = false;
    this.tipoSelectDisabled = false;
    this.valorDisabled = false;
    this.factModalMode = null;

  }

  resetCopyEntry() {
    this.copyMov = {
      id: null,
      dt_mov: null,
      nome_condominio: null,
      cod_condominio: null,
      descricao: null,
      tipo: null,
      valor: null,
      nome_utilizador: null,
      obj: null,
    }
    this.copyMovInputDesc = false;this.caixaVertisReportList

    this.selFuncionario = null;
    this.selUtilizadorReforco = null;
    this.selEntity = null;
    this.condominioSelectDisabled = false;
    this.tipoSelectDisabled = false;
    this.valorDisabled = false;
  }

  goToDetails(entry) {
    if (entry.descricao && entry.descricao.indexOf('Recebimentos de Valores') !== -1) {
      this.editEntry(entry);
    } else {
      if (entry.obj) {
        switch (entry.obj.tipoDoc) {
          case 'SALDO_INICIAL': return;
          case 'RECIBO':
            this.setState(true);
  
            this.router.navigate(['lancamentos/recibo', entry.obj.idDoc]);
            return;
          case 'RECEITA':
            this.setState(true);
  
            this.router.navigate(['lancamentos/receita', entry.obj.idDoc]);
            return;
          case 'DESPESA':
            this.setState(true);
  
            this.router.navigate(['lancamentos/despesa', entry.obj.idDoc]);
            return;
          case 'CREDITO':
            this.router.navigate(['lancamentos/credito', entry.obj.idDoc]);
            return;
        }
      }
    }
  }

  copyMovInputDesc = false;
  entregaValoresOpts: Array<{name, value}> = [];
  idEntregaValores = null;
  editEntry(entry) {
    if (entry.obj) {
      switch (entry.obj.tipoDoc) {
        case 'SALDO_INICIAL': return;
        case 'SALDO_FINAL': return;
        case 'RECIBO':
          this.router.navigate(['lancamentos/recibo', entry.obj.idDoc]);
          return;
        case 'RECEITA':
          this.setState(true);

          this.router.navigate(['lancamentos/receita', entry.obj.idDoc]);
          return;
        case 'DESPESA':
          this.router.navigate(['lancamentos/despesa', entry.obj.idDoc]);
          return;
        case 'CREDITO':
          this.router.navigate(['lancamentos/credito', entry.obj.idDoc]);
          return;
      }
    }

    if (!this.userSession.isCaixaVertisLoggedIn() && !this.userSession.isAdmin() && !this.userSession.isSuperAdmin()) {
      this.toastr.error('Não tem sessão iniciada na Caixa Vertis.', 'Ups...!', { timeOut: 4000 });
      return;
    }

    if (entry['descricao'].includes('Fatura Ctt') || entry['descricao'].includes('Entrega Valores Ctt')) {
      this.editFaturaCtt.open(null, null,entry.id).then(res => {
        if (res) {
          this.getCaixaVertis();
        }
      });
      return;
    }

    if (entry['descricao'] === 'Fatura CRP') {
      this.submittingFatura = false;

      if (entry['descricao'] === 'Fatura CRP' && entry.hasOwnProperty('obj') && entry.obj) {
        this.factModalMode = 'FACTURA_CONSERVATORIA';
        this.factCRPValor = entry.obj.valor;
        this.factCRPTroco = entry.obj.troco;
      }



      // OPEN MODAL FACTURA CTT (TROCO E VALOR DA FACTURA)
      this.facturaCttModalRef = this.modalService
        .open(this.facturaCttAlertConfig)
        .onApprove(() => {
          this.saveEdit(entry);

          this.loadingModal = false;
          this.facturaCttModalRef = null;
        })
        .onDeny(() => {
          this.factModalMode = null;
          this.factCttValor = null;
          this.factCttTroco = null;
          this.factCttDataExpedicao = new Date();
          this.factCRPValor = null;
          this.factCRPTroco = null;
          this.loadingModal = false;
          this.facturaCttModalRef = null;

          this.resetNewEntry();
        });
    } else {

      let aux = this.appConfig.caixaVertisDescricaoOtps.find(el => (el.name === entry.descricao));
      if (!aux) {
        if (!entry.id_funcionario && !entry.id_utilizador) {
          this.copyMovInputDesc = true;
        }
        this.condominioSelectDisabled = false;
      } else {
        this.condominioSelectDisabled = true;
      }

      let auxDesc = (aux && aux.hasOwnProperty('value')) ? aux.value : entry.descricao;
      if (entry.id_funcionario && entry.descricao.indexOf('Vales Limpeza') !== -1) {

        if (entry.descricao.indexOf('Vales Limpeza') !== -1) {
          auxDesc = 'VALE_LIMPEZA';
        }

        this.selFuncionario = this.funcionariosOpts.find(el => (el.value.id === entry.id_funcionario)).value;
        this.selUtilizadorReforco = null;
        this.selEntity = null;
        this.condominioSelectDisabled = true;
      }

      if ((entry.id_funcionario || entry.id_utilizador) && (entry.descricao.indexOf('Reforço Caixa') !== -1 || entry.descricao.indexOf('Entrega Valores') !== -1 || entry.descricao.indexOf('Entrega Fecho de Caixa') !== -1)) {

        if (entry.descricao.indexOf('Entrega Valores') !== -1 && entry.descricao.indexOf('Entrega Valores para Cofre') === -1 && entry.descricao.indexOf('Entrega Valores CRP') === -1) {
          auxDesc = 'ENTREGA_VALORES';
        } else if (entry.descricao.indexOf('Entrega Valores para Cofre') !== -1) {
          auxDesc = 'ENTREGA_VALORES_COFRE';
        } else if (entry.descricao.indexOf('Entrega Valores CRP') !== -1) {
          auxDesc = 'CONSERVATORIA';
        } if (entry.descricao.indexOf('Entrega Fecho de Caixa') !== -1) {
          auxDesc = 'ENTREGA_VALORES_CAIXA';
        } else if (entry.descricao.indexOf('Reforço Caixa') !== -1) {
          auxDesc = 'REFORCO_CAIXA';
        }

        if (entry.hasOwnProperty('id_funcionario') && entry.id_funcionario) {
          this.selEntity = this.entityOpts.find(el => (el.value.id === entry.id_funcionario && el.value.type === 'FUNCIONARIO')).value;
        }

        if (entry.hasOwnProperty('id_utilizador') && entry.id_utilizador) {
          this.selEntity = this.entityOpts.find(el => (el.value.id === entry.id_utilizador && el.value.type === 'USER')).value;
        }

        this.selFuncionario = null;
        this.selUtilizadorReforco = null;
        this.condominioSelectDisabled = true;
      }

      this.copyMov = {
        id: entry.id,
        dt_mov: entry.dt_mov,
        nome_condominio: entry.nome_condominio,
        cod_condominio: { name: entry.cod_condominio + ' - ' + entry.nome_condominio, value: entry.cod_condominio },
        descricao: auxDesc,
        tipo: entry.tipo,
        valor: (entry.valor < 0) ? -1 * Number(entry.valor): Number(entry.valor),
        nome_utilizador: entry.nome_utilizador,
        obj: entry.obj,
      }
  
      if (entry.descricao.indexOf('Recebimentos de Valores') !== -1) {
        this.copyMov['descricao'] = 'RECEBIMENTO_VALORES';
        this.copyMov['nome'] = (entry.obj) ? entry.obj.nome : null;
        this.copyMov['obs'] = (entry.obj) ? entry.obj.obs : null;
        this.copyMovInputDesc = false;
  
        // SET DATA / DECLARACAO
        if (entry.obj) this.recValores = entry.obj;
      }
  
      this.selCondominio = this.copyMov.cod_condominio;
      if (this.selCondominio.hasOwnProperty('value') && this.selCondominio.value === null) this.selCondominio = null;

      this.descricaoSelected(true);

      this.editModalRef = this.modalService
        .open(this.editAlertConfig)
        .onApprove(() => { this.loadingModal = false; this.resetCopyEntry(); this.copyMovInputDesc = false; this.selCondominio = null; })
        .onDeny(() => { this.loadingModal = false; this.resetCopyEntry(); this.copyMovInputDesc = false; this.selCondominio = null; });
    }

  }


  submittingEdit = false;
  saveEdit(entry=null) {
      this.submittingEdit = true;
  
      if (!this.isCopyEntryValid() && entry === null) {
        setTimeout(() => { this.submittingEdit = false; }, 3000);
        return;
      }
  
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
  
      let aux = this.appConfig.caixaVertisDescricaoOtps.find(el => (el.value === this.copyMov.descricao));
      if (aux && aux.hasOwnProperty('name')) {
        this.copyMov.descricao = aux.name;
      }
   
      if (this.selEntity && this.selEntity.hasOwnProperty('type') && this.selEntity.type === 'FUNCIONARIO') {
        this.copyMov.descricao += ' / ' + this.selEntity.first_name + ' ' + this.selEntity.last_name;
      }

      if (this.selEntity && this.selEntity.hasOwnProperty('type') && this.selEntity.type === 'USER') {
        this.copyMov.descricao += ' / ' + this.selEntity.first_name + ' ' + this.selEntity.last_name;
      }

      let valor = (this.copyMov.tipo === 'C') ? Number(this.copyMov.valor) : -1 * Number(this.copyMov.valor);

      this.loadingModal = true;

      let codCondominio = (this.selCondominio && this.selCondominio.hasOwnProperty('cod')) ? this.selCondominio.cod : null;
      if (codCondominio === null) {
        if (this.selCondominio && this.selCondominio.hasOwnProperty('value')) {
          codCondominio = this.selCondominio.value;
        }
      }

      // HANDLE FACTURA CTT AND FACTURA CRP
      let obj = null;
      let objRegCtt = null;
      if (entry !== null) {
        this.copyMov.id = entry.id;
        codCondominio = entry.cod_condominio;
        this.copyMov.descricao = entry.descricao;
        this.copyMov.tipo = entry.tipo;
        // selFuncionario = null;
        
        if (entry.descricao === 'Fatura Ctt') {
          valor = Number(this.factCttTroco);
          obj = { valor: Number(this.factCttValor), troco: Number(this.factCttTroco), tipo: 'FACTURA_CTT' };
          objRegCtt = (this.registoCttSeparator && this.registoCttSeparator.hasOwnProperty('obj') && this.registoCttSeparator.obj) ? this.registoCttSeparator.obj : null;
        }

        if (entry.descricao === 'Fatura CRP') {
          valor = Number(this.factCRPTroco);
          obj = { valor: Number(this.factCRPValor), troco: Number(this.factCRPTroco), tipo: 'FACTURA_CONSERVATORIA' };
        }
      }

      let idFuncionario = (this.selEntity && this.selEntity.hasOwnProperty('type') && this.selEntity.type === 'FUNCIONARIO') ? this.selEntity.id : null;
      let idUtilizador = (this.selEntity && this.selEntity.hasOwnProperty('type') && this.selEntity.type === 'USER') ? this.selEntity.id : null;
      this.api.updateMovimentoCaixaVertis(this.copyMov.id, codCondominio, this.copyMov.descricao, this.copyMov.tipo, valor, idFuncionario, idUtilizador, obj, objRegCtt).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          if (res.status) {

            let aux = this.caixaVertisList.find(el => (el.id === res.data.id));

            // UPDATE EDITED ENTRY
            aux.checked = false;
            aux.edit = false;
            aux.descricao = res.data.descricao;
            aux.nome_condominio = res.data.nome_condominio;
            aux.cod_condominio = res.data.cod_condominio;
            aux.nome_utilizador = (res.data.last_name) ? res.data.first_name[0] + res.data.last_name[0] : res.data.first_name[0],
            aux.tipo = res.data.tipo;
            aux.valor = res.data.valor;
            aux.id_funcionario = res.data.id_funcionario;
            aux.id_utilizador = res.data.id_utilizador;
            aux.obj = (res.data.obj) ? JSON.parse(res.data.obj) : null;

            this.computeSaldoCVDiario();

            if (this.editModalRef) this.editModalRef.approve();
          } else {
            this.toastr.error('Não tem sessão iniciada na Caixa Vertis.', 'Ups...!', { timeOut: 4000 });
            if (this.editModalRef) this.editModalRef.deny();
          }
          this.submittingEdit = false;

          // REGISTO ACTIVIDADES API CALL
          let titulo = 'Movimento Caixa Vertis Actualizado';
          let descricao = null;
          if (res.data.hasOwnProperty('cod_condominio') && res.data.cod_condominio && res.data.nome_condominio) {
            descricao = 'Condomínio: ' + res.data.cod_condominio + ' - ' + res.data.nome_condominio + ', Descrição: ' + res.data.descricao + ', Valor: ' + Number(res.data.valor).toFixed(2) + '€';
          } else {
            descricao = 'Descrição: ' + res.data.descricao + ', Valor: ' + Number(res.data.valor).toFixed(2) + '€';
          }
          this.api.saveRegistoActividade(null, null, null, titulo, descricao).subscribe(res => {}, err => { });

          this.selFuncionario = null;
          this.selUtilizadorReforco = null;
          this.selEntity = null;

        } else {
          this.utils.apiErrorMsg(res);
        }

        this.computeSaldoTotal();

        this.submittingEdit = false;
        this.loadingModal = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loadingModal = false;
        this.submittingEdit = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      });
  }

  cancelEdit(entry) {
    let aux = this.appConfig.caixaVertisDescricaoOtps.find(el => (el.name === entry.descricao));

    entry.id = this.copyMov.id,
    entry.dt_mov = this.copyMov.dt_mov,
    entry.nome_condominio = this.copyMov.nome_condominio,
    entry.cod_condominio = null,
    entry.descricao = (aux && aux.hasOwnProperty('value')) ? aux.value : entry.descricao,
    entry.tipo = this.copyMov.tipo,
    entry.valor = (this.copyMov.tipo === 'C') ? Number(this.copyMov.valor) : Number(-1 * this.copyMov.valor),
    entry.nome_utilizador = this.copyMov.nome_utilizador,

    entry.edit = false;
  }

  computeSaldoTotal() {
    this.saldoTotal = 0;
    this.caixaVertisList.forEach(el => {
      this.saldoTotal += (el['descricao'] !== 'Saldo Final') ? Number(el.valor) : 0;
    });
  }

  reportType = null;
  caixaVertisListPDF = [];
  caixaVertisReportListPDF = [];
  reportTotal = 0;
  exportPdf(target=null, reportType=null) {
    this.reportType = reportType;

    if (this.pdfController === null) return;

    this.pdfEnabled = true;

    let filename = null;

    if (target) {
      switch (target) {
        case 'caixa-vertis-confirm':
          filename = 'Relatório_Movimentos_Numerário_'
            + formatDate(new Date(), this.format, this.locale);

          this.pdfReport.title = 'Relatório de Movimentos em Numerário';
          this.pdfReport.startDate = new Date();
          this.pdfReport.now = new Date();
          break;
      }
    } else {
      // CHECK TAB SELECTED
      switch (this.selTabKey) {
        case 'registo-diario':
          // HANDLE REPORT LIST
          this.caixaVertisListPDF = [].concat(this.caixaVertisList).reverse();

          filename = 'Relatório_Caixa_Vertis_Diária_'
            + formatDate(new Date(), this.format, this.locale);

          this.pdfReport.title = 'Relatório de Caixa Vertis Diário';
          this.pdfReport.startDate = new Date();
          this.pdfReport.now = new Date();
          break;
        case 'relatorio':

          // GENERAL REPORT
          if (reportType === 'geral') {
            let auxDate = null;
            let prevDate = null;
  
            let prevSaldo = 0;
            let saldo_total_caixa = 0;
  
            this.caixaVertisReportListPDF = [];
            this.caixaVertisReportListOrig.forEach((el, i) => {
  
              auxDate = this.utils.getFormatedDate(el['dt_mov']);
  
              if (Number(el.saldo) > 0) {
                prevSaldo = Number(el.saldo);
              } else {
                prevSaldo += Number(el.valor);
              }
  
              if (el.obj) {
                try {
                  el.obj = JSON.parse(el.obj)
                } catch (e) {}
              } 
  
              let aux = {
                separator: false,
                total: false,
  
                checked: false,
                edit: false,
                id: el.id,
                dt_mov: el.dt_mov,
                nome_condominio: (el.nome_condominio) ? el.nome_condominio : null,
                cod_condominio: (el.cod_condominio) ? el.cod_condominio : null,
                descricao: el.descricao,
                tipo: el.tipo,
                n_caixa: el._caixa,
                valor: Number(el.valor),
                nome_utilizador: (el.last_name) ? el.first_name[0] + el.last_name[0] : el.first_name[0],
                saldo: prevSaldo,
                obj: (el.obj) ? el.obj : null,
              }
  
              // TABLE ROW TOTAL AND SEPARATOR
              if (prevDate !== auxDate) {
                let date = this.utils.getFormatedDate(el['dt_mov']);
                this.caixaVertisReportListPDF.push({ label: `Caixa do dia: ${date}`, separator: true, total: false });
                saldo_total_caixa = 0;
              }
              prevDate = auxDate;
  
              saldo_total_caixa += aux.valor;
  
              this.caixaVertisReportListPDF.push(aux);
            });
  
            filename = 'Relatório_Caixa_Vertis_'
              + formatDate(this.startDate, this.format, this.locale) + '_'
              + formatDate(this.endDate, this.format, this.locale);
  
            this.pdfReport.title = 'Relatório de Caixa Vertis';
            this.pdfReport.startDate = this.startDate;
            this.pdfReport.endDate = this.endDate;
            this.pdfReport.now = new Date();
          }

          // VALES LIMPEZA REPORT
          if (reportType === 'vales-limpeza') {

            let prevSaldo = 0;
            this.reportTotal = 0;
  
            this.caixaVertisReportListPDF = [];
            this.caixaVertisReportListOrig.filter(el => (el.descricao.indexOf('Vales Limpeza / ') !== -1)).forEach((el, i) => {
  
              let aux = {
                separator: false,
                total: false,
  
                checked: false,
                edit: false,
                id: el.id,
                dt_mov: el.dt_mov,
                nome_condominio: (el.nome_condominio) ? el.nome_condominio : null,
                cod_condominio: (el.cod_condominio) ? el.cod_condominio : null,
                descricao: el.descricao,
                tipo: el.tipo,
                n_caixa: el._caixa,
                valor: Number(el.valor),
                nome_utilizador: (el.last_name) ? el.first_name[0] + el.last_name[0] : el.first_name[0],
                saldo: prevSaldo,
                obj: (el.obj) ? el.obj : null,
              }
  
              this.reportTotal += aux.valor;
  
              this.caixaVertisReportListPDF.push(aux);
            });
  
            filename = 'Relatório_Vales_Limpeza_'
              + formatDate(this.startDate, this.format, this.locale) + '_'
              + formatDate(this.endDate, this.format, this.locale);
  
            this.pdfReport.title = 'Vales de Limpeza';
            this.pdfReport.startDate = this.startDate;
            this.pdfReport.endDate = this.endDate;
            this.pdfReport.now = new Date();
          }
          break;
      }
    }

    setTimeout(() => {
      this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
      this.pdfController.forceProxy = true;
      this.pdfController.proxyTarget = '_blank';
      this.pdfController.saveAs(filename + '.pdf');
    }, 1);
  }

  getDeclaracaoPDF() {
    if (!this.userSession.isCaixaVertisLoggedIn()) return;

    let filename = 'Declaração_recebimento_valores_'
      + formatDate(new Date(), this.format, this.locale);

    this.pdfReport.title = 'Declaração';

    setTimeout(() => {
      this.pdfDeclaracaoController.proxyURL = this.appConfig.fileProxyUrl;
      this.pdfDeclaracaoController.forceProxy = true;
      this.pdfDeclaracaoController.proxyTarget = '_blank';
      this.pdfDeclaracaoController.saveAs(filename + '.pdf');
    }, 1);
  }

  exportingExcel = false;
  exportExcel() {
    if (this.exportingExcel) return;
    this.exportingExcel = true;

    let filename = null;
    let excelData = [];
    let worksheet = null;
    let workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    let data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});

    // CHECK TAB SELECTED
    if (this.selTabKey === 'registo-diario') {
        filename = 'Relatório_Caixa_Vertis_Diária_'
          + formatDate(new Date(), this.format, this.locale);
        
        let auxArr = [].concat(this.caixaVertisList);

        excelData = [{ nome_utilizador: 'Utilizador', dt_mov: 'Data', nome_condominio: 'Condomínio', descricao: 'Descrição', tipo: 'Tipo', valor: 'Valor [€]', saldo: 'Saldo [€]' }];
        auxArr.reverse().forEach(el => {
  
          if (!el.separator && !el.total) {
            excelData.push({
              nome_utilizador: el.nome_utilizador,
              dt_mov: this.utils.getFormatedDate(el.dt_mov, 'dd-MM-yyyy HH:mm'),
              nome_condominio: el.nome_condominio,
              descricao: el.descricao,
              tipo: el.tipo,
              valor: el.valor.toFixed(2).replace('.', ','),
              saldo: el.saldo.toFixed(2).replace('.', ','),
            });
          }
  
        });
        excelData.push({ nome_utilizador: null, dt_mov: null, nome_condominio: null, descricao: null, tipo: null, valor: null, saldo: null });
  
        worksheet = XLSX.utils.json_to_sheet(excelData, { header:['nome_utilizador', 'dt_mov', 'nome_condominio', 'descricao', 'tipo', 'valor', 'saldo'], skipHeader: true });
  
        let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});
  
        FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
        this.exportingExcel = false;
      }

    if (this.selTabKey === 'relatorio') {
        filename = 'Relatório_Caixa_Vertis_'
          + formatDate(this.startDate, this.format, this.locale) + '_'
          + formatDate(this.endDate, this.format, this.locale);
  
        let auxArr = [].concat(this.caixaVertisReportList);

        excelData = [{ nome_utilizador: 'Utilizador', dt_mov: 'Data', nome_condominio: 'Condomínio', descricao: 'Descrição', tipo: 'Tipo', valor: 'Valor [€]', saldo: 'Saldo [€]' }];
        auxArr.reverse().forEach(el => {
  
          if (!el.separator && !el.total) {
            excelData.push({
              nome_utilizador: el.nome_utilizador,
              dt_mov: this.utils.getFormatedDate(el.dt_mov, 'dd-MM-yyyy HH:mm'),
              nome_condominio: el.nome_condominio,
              descricao: el.descricao,
              tipo: el.tipo,
              valor: el.valor.toFixed(2).replace('.', ','),
              saldo: el.saldo.toFixed(2).replace('.', ','),
            });
          }
  
        });
        excelData.push({ nome_utilizador: null, dt_mov: null, nome_condominio: null, descricao: null, tipo: null, valor: null, saldo: null });

        worksheet = XLSX.utils.json_to_sheet(excelData, { header:['nome_utilizador', 'dt_mov', 'nome_condominio', 'descricao', 'tipo', 'valor', 'saldo'], skipHeader: true });
  
        let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let data: Blob = new Blob([excelBuffer], {type: EXCEL_TYPE});
  
        FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
        this.exportingExcel = false;
    }
  }

  setPrevState() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);

    if (this.prevState) {
      if (this.prevState.state.hasOwnProperty('startDate')) this.startDate = this.prevState.state.startDate;
      if (this.prevState.state.hasOwnProperty('endDate')) this.endDate = this.prevState.state.endDate;
      if (this.prevState.state.hasOwnProperty('keyword')) this.keyword = this.prevState.state.keyword;

      // SET ACTIVE TAB
      if (this.prevState.state.hasOwnProperty('activeTab') && this.prevState.state.activeTab !== null) {
        this.tabsObjDef.forEach(tab => {
          if (tab.key === this.prevState.state.activeTab.key) {
            tab.active = true;
            this.selTabKey = tab.key;
          } else {
            tab.active = false;
          }
        });
      }

      this.appState.clearPrevState(this.comp);
    }
  }

  setState(saveAll=false) {
    let state = {          
      activeTab: this.tabsObjDef.find(el => (el.key === this.selTabKey)),
    }
    if (saveAll) {
      state['startDate'] = this.startDate;
      state['endDate'] = this.endDate;
      state['keyword'] = this.keyword;
    }

    this.appState.setPrevState(this.comp, state);
  }

  movSelected(mov) {
    setTimeout(async () => {
      if (!mov.checked) return;
      // DISABLE OTHER SELECTED ENTRIES
      this.caixaVertisList.filter(el => (el.id !== mov.id)).forEach(el => { el.checked = false; });

      let tipo = null;
      if (mov.descricao.includes('Fatura Ctt')) tipo = '1';
      if (mov.descricao.includes('Entrega Valores Ctt')) tipo = '0';
      if (tipo == '0') {
        if (await this.checkIfEntregaHasFatura(mov.id)) {
          this.toastr.error('Não é possível eliminar uma entrega que tenha uma fatura associada.', 'Ups...!', { timeOut: 4000 });
          mov.checked = false;
          return;
        }
      }
      if (tipo && !this.utils.checkCustosComunicacoesPermissions({tipo:tipo, data:mov.dt_mov, entregue_por:mov.id_user})) mov.checked = false;

    }, 1);
  }

  checkIfEntregaHasFatura(id_cv): Promise<boolean> {
    return new Promise(resolve => {
      this.api.getFaturaByEntrega('CAIXA_VERTIS', id_cv).subscribe(res => {
        if (res.success) {
          resolve(res.data != null);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      })
    })
  }

  submittingRecValores = false;
  recValores = { nome: null, obs: null, now: null };
  saveRecValores() {
    this.submittingRecValores = true;
    setTimeout(() => { this.submittingRecValores = true; }, 3000);

    if (this.recValores.nome === null) return;

    if (this.recebimentoValoresModalRef) this.recebimentoValoresModalRef.approve();
  }

  //Entrega / Fatura Ctt
  semEntregaOpt = {name: '-- Sem Entrega --',value: '-1'};
  currentsCustosComunicacoes: Array<custosComunicacoes> = [];
  openCurrentCustosComunicacoes() {
    if (!this.tabsObjDef.find(el => el.key === 'entregas-faturas')) {
      this.toastr.error(this.appConfig.errMsg.unAuth.msg, this.appConfig.errMsg.unAuth.title);
      return;
    }
    this.tabsObjDef.forEach(el => {
      if(el.key == 'entregas-faturas') el.active = true;
    });
    this.setTab('entregas-faturas');
  }


  getEntregaSelectedDate(): Date {
    if (this.idEntregaValores == '-1') {
      return null;
    }
    let entrega = this.currentsCustosComunicacoes.find(el => el.id === this.idEntregaValores);
    if (!entrega || !entrega.data) return null;
    let data = new Date(entrega.data);
    data.setHours(0,0,0,0)
    return data;
  }
  getTroco(): number {
    return Math.abs(parseFloat(this.factCttTroco));
  }

  submittingFatura = false;
  submitFatura() {
    this.submittingFatura = true;
    setTimeout(() => { this.submittingFatura = false; }, 4000);
    if (this.factModalMode === 'FACTURA_CTT') {
      if (!this.idEntregaValores) return; 
      if (!this.validValor() || !this.validDate() || !this.validEntrega())  {
        this.toastr.error('Não é possível guardar a fatura. Por favor, verifique todos os campos assinalados.', 'Alerta');
        return;
      }
    }
    this.facturaCttModalRef.approve();
  }

  updateTrocoFatura() {
    if (this.idEntregaValores == '-1') {
      if (!this.factCttValor || this.factCttValor.trim() === '') {
        this.factCttTroco = null
        return;
      }
      this.factCttTroco = (-1 * parseFloat(this.factCttValor)).toFixed(2);
      return;
    }
    let entrega = this.currentsCustosComunicacoes.find(el => el.id === this.idEntregaValores);
    this.factCttTroco = entrega && entrega.valor? (this.factCttValor? (parseFloat(entrega.valor) - parseFloat(this.factCttValor)).toFixed(2) : parseFloat(entrega.valor).toFixed(2)) : 0;
  }
  validDate(): boolean {
    if (this.idEntregaValores == '-1') {
      return this.factCttDataExpedicao && this.utils.compareDayDates(this.factCttDataExpedicao, this.now) <= 0
    }
    let entrega = this.currentsCustosComunicacoes.find(el => el.id === this.idEntregaValores)
    return this.factCttDataExpedicao && this.factCttDataExpedicao && this.utils.compareDayDates(this.factCttDataExpedicao, new Date(entrega.data)) >= 0 && this.utils.compareDayDates(this.factCttDataExpedicao, this.now) <= 0
  }

  validValor(): boolean {
    return this.factCttValor && Number(this.factCttValor) > 0;
  }

  validEntrega(): boolean {
    return this.idEntregaValores && (this.idEntregaValores != '-1' || this.selEntity);
  }
}
