import { Component, OnInit, Input, ViewChild,QueryList, ViewChildren, ChangeDetectorRef  } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';

import { CartaContent , RegistoCttAR } from '../business-model-interfaces/carta';
import { CartaCttAvisoRececaoComponent } from '../carta-ctt-aviso-rececao/carta-ctt-aviso-rececao.component';
import { UtilitiesService } from '../utilities.service';
import { CommunicationsOrigin } from '../business-model-interfaces/comunicacoes';

export interface AnexoCarta {
  base64:string, 
  ext: 'application/pdf'|'image/jpeg'|'image/png'|'image/jpg', 
  filename:string
}
export interface CartaOptions {
  includeGreeting: boolean
}
@Component({
  selector: 'app-carta-comunicacao',
  templateUrl: './carta-comunicacao.component.html',
  styleUrls: ['./carta-comunicacao.component.scss']
})
export class CartaComunicacaoComponent implements OnInit {

  @Input() data:Array<CartaContent>;
  @Input() origin:CommunicationsOrigin;
  @Input() anexos:Array<AnexoCarta> = [];
  @Input() filename:string = 'titulares_bancarios';

  @ViewChild('pdfRef', { static: false }) pdfController;
  @ViewChildren(CartaCttAvisoRececaoComponent) cttForms: QueryList<CartaCttAvisoRececaoComponent>;
  
  now = null;
  docLocal = 'Amora';
  docData = new Date();
  cartaOptions:CartaOptions = {
    includeGreeting: true
  };
  correioRegistadoList:Array<RegistoCttAR> = [];
  
  constructor(public appConfig: AppConfigService,
    public toastr: ToastrService,
    public utils: UtilitiesService,
    public cdRef: ChangeDetectorRef,
    public api: ApiService,
    ) {}

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  resetOptions() {
    this.cartaOptions = {
      includeGreeting: true
    }
  }

  setCartaOptions(options: CartaOptions) {
    this.resetOptions();
    if (!options) return;
    if (options.hasOwnProperty('includeGreeting')) this.cartaOptions.includeGreeting = options.includeGreeting; 
  }

  updateFileName() {
    switch (this.origin) {
      case 'ASSEMBLEIAS_COMUNICACOES':
        this.filename = 'Comunicações_Assembleias';
        break;
      case 'CORRESPONDENCIA_COM':
        this.filename = this.data.length? this.utils.getFileNameFormatted(this.data[0].subjectMsg) : 'Comunicação';
        break;
      case 'CORRESPONDENCIA_AC':
      case 'NOTICE_AVISOS':
        this.filename = this.data.length? this.utils.getFileNameFormatted(this.data[0].subjectMsg) : 'Avisos_e_Cobranças';
        break;
      case 'ENTITIES':
        this.filename = this.data.length? 'Declaração_Divida_' + this.data[0].nomeEntidade : 'Declaração_Divida';
        this.filename = this.utils.getFileNameFormatted(this.filename);
        break;
    
      default:
        break;
    }
  }

  async generatePdf(ids_registo_ctt:Array<string>, options: CartaOptions=null) {
    this.updateFileName();
    this.setCartaOptions(options);
    this.data.forEach(carta => {
      carta.bodyMsg = carta.bodyMsg.replace(/\n/g, '<br>');
    });
    this.correioRegistadoList = this.data.filter(el => el.tipo_correio && (el.tipo_correio === 'REGISTADO_AR' || el.tipo_correio === 'REGISTADO')).reduce((newArr, el) => {
      let entryIndex = newArr? newArr.findIndex(el2 => el2.n_contribuinte == el.n_contribuinte) : null;
      if (entryIndex == null || entryIndex === -1) {
        newArr.push({
          n_contribuinte: el.n_contribuinte, 
          entities: [el]
        });
        return newArr;
      }
      newArr[entryIndex].entities.push(el);
      return newArr;
    },[] as Array<RegistoCttAR>);
    this.cdRef.detectChanges();
    this.now = new Date();
    if (!this.anexos || !this.anexos.length) {
      if (this.cttForms.length) {
        setTimeout(() => {
          let promises = [];
          this.cttForms.forEach(formPDF => promises.push(formPDF.generatePdf()));
          promises.push(this.pdfController.export());

          Promise.all(promises).then(async groups => {
            const rootGroup = new Group({
                pdf: {
                    multiPage: true
                }
            });
            groups.forEach((group) => {
                rootGroup.append(...group.children);
            });
        
            return exportPDF(rootGroup, { paperSize: 'A4' });
          }).then(async dataUri => {
            this.utils.downloadFile(dataUri, this.filename);
            // saveAs(dataUri, this.filename + '.pdf', {
            //   proxyURL: this.appConfig.fileProxyUrl,
            //   forceProxy: true,
            //   proxyTarget: '_blank',
            // });
            let base64  = dataUri.replace('data:application/pdf;base64,', '');
            base64  = base64.replace(' ', '+');
            this.savePDFAssociation(ids_registo_ctt, base64);
          });
        }, 1);
      } else {
        let base64:any = await this.getBase64();
        this.savePDFAssociation(ids_registo_ctt, base64);
        setTimeout(() => {
          this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
          this.pdfController.forceProxy = true;
          this.pdfController.proxyTarget = '_blank';
          this.pdfController.saveAs(this.filename + '.pdf');
        }, 1);
      }
    } else {
      let base64Arr = [];
      let promises = [];
      if (this.cttForms.length) {
        this.cttForms.forEach(formPDF => promises.push(formPDF.generatePdf()));
      }
      promises.push(this.pdfController.export());

      await Promise.all(promises).then(async groups => {
        const rootGroup = new Group({
            pdf: {
                multiPage: true
            }
        });
        groups.forEach((group) => {
            rootGroup.append(...group.children);
        });
    
        return exportPDF(rootGroup, { paperSize: 'A4' });
      }).then(async dataUri => {
        base64Arr = [dataUri];
      });

      let nCartas = this.data.length;
      for (let i = 0; i < nCartas; i ++) {
        this.anexos.forEach(anexo => {
          switch (anexo.ext) {
            case 'application/pdf':
                base64Arr.push(anexo.base64);
              break;
            case 'image/jpeg':
            case 'image/png':
            case 'image/jpg':
              break;
          
            default:
              break;
          }
        });
      }
      
      this.mergePdf(base64Arr).then((base64Merged:any)=> {
        this.utils.downloadFile(base64Merged, this.filename);
        // saveAs(base64Merged, this.filename + '.pdf', { 
        //   proxyURL: this.appConfig.fileProxyUrl,
        //   forceProxy: true,
        //   proxyTarget: '_blank',
        // });
        let base64  = base64Merged.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');
        this.savePDFAssociation(ids_registo_ctt, base64);

      }).catch(err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      });
    }
  }

  savePDFAssociation(ids_registo_ctt:Array<string>, base64:string) {
    if (!ids_registo_ctt || !ids_registo_ctt.length) return;


    this.api.saveCartaPDF(ids_registo_ctt, base64, this.origin, this.filename).subscribe(res => {
      if (res.success) {

      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  mergePdf(base64Arr, contentType='data:application/pdf;base64,') {
    return new Promise((resolve,reject) => {
      this.api.mergePdf(this.filename + '.pdf', contentType, base64Arr).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
        
          if (res.data) {
            resolve(contentType + res.data);
          } else {
            reject(false);
          }

        }
      },
      err => {
        reject(false);
      });
    });
  }


  getBase64(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.now = new Date();

      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');

        resolve(base64);
      }).catch(err => {
        resolve(null);
      });
    });
  }

  getCTTBase64():Promise<string> {
    return new Promise((resolve, reject) => {
      this.now = new Date();

      let promises = [];
      this.cttForms.forEach(formPDF => promises.push(formPDF.generatePdf()));

      Promise.all(promises).then(async groups => {
        const rootGroup = new Group({
            pdf: {
                multiPage: true
            }
        });
        groups.forEach((group) => {
            rootGroup.append(...group.children);
        });
    
        return exportPDF(rootGroup, { paperSize: 'A4' });
      }).then(async dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');
        resolve(base64);
      }).catch(err => {
        resolve(null);
      });
    });
  }

}
