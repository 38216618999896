import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { ApiService } from '../api.service';
import { saveAs } from '@progress/kendo-file-saver';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { CondominioContacts } from '../business-model-interfaces/condominios';


@Component({
  selector: 'app-condominio-contacts-pdf',
  templateUrl: './condominio-contacts-pdf.component.html',
  styleUrls: ['./condominio-contacts-pdf.component.scss']
})
export class CondominioContactsPdfComponent implements OnInit {

  @Input() codCondominio = null;  

  @ViewChild('pdfRef', { static: false }) pdfController;

  entidadesCol = [
    { key: 'fraccao', name: 'Fração', type: 'text', class: 'col-align-left col-sm' },
    { key: 'permilagem', name: 'Permilagem', type: 'text', class: 'col-align-right col-sm' },
    { key: 'proprietario', name: 'Proprietário', type: 'text', class: 'col-align-left' },
    { key: 'inquilino', name: 'Inquilino', type: 'text', class: 'col-align-left' },
  ]

  entidades:Array<CondominioContacts> = [];
  nomeCondominio:string = null;  

  filename = 'Condominio_Contactos';
  now = new Date();

  constructor(
    public cdRef:ChangeDetectorRef,
    public api:ApiService,
    public appConfig: AppConfigService,
    public toastr: ToastrService,
    public utils:UtilitiesService
  ) { }

  ngOnInit() {
  }


  exportPdf() {
    this.getCondominioContacts().then(data => {
      this.filename = this.utils.getFileNameFormatted(this.nomeCondominio + ' contactos');
      this.entidades = data;
      this.cdRef.detectChanges();


      this.now = new Date();
      this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
      this.pdfController.forceProxy = true;
      this.pdfController.proxyTarget = '_blank';
      this.pdfController.saveAs(this.filename + '.pdf');

    }).catch(err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
    
  }

  getCondominioContacts():Promise<Array<CondominioContacts>> {
    return new Promise((resolve, reject) => {
      this.api.getCondominioContacts(this.codCondominio).subscribe(res => {
        if (res.success) {
          this.nomeCondominio = res.data.length? res.data[0].nome_condominio : null;

          res.data = res.data.map(el => {
            if (el.proprietario_telefone_1) el.proprietario_telefone_1 = el.proprietario_telefone_1.split('NEW_LINE').join(' ');
            if (el.proprietario_telefone_2) el.proprietario_telefone_2 = el.proprietario_telefone_2.split('NEW_LINE').join(' ');

            if (el.inquilino_telefone_1) el.inquilino_telefone_1 = el.inquilino_telefone_1.split('NEW_LINE').join(' ');
            if (el.inquilino_telefone_2) el.inquilino_telefone_2 = el.inquilino_telefone_2.split('NEW_LINE').join(' ');


            if (el.proprietario_morada) {
              el.proprietario_morada = this.utils.moradaStrToList(el.proprietario_morada as string);
            }
            if (el.proprietario_morada_fact) {
              el.proprietario_morada_fact = this.utils.moradaStrToList(el.proprietario_morada_fact as string);
            }
            if (el.inquilino_morada) {
              el.inquilino_morada = this.utils.moradaStrToList(el.inquilino_morada as string);
            }
            if (el.inquilino_morada_fact) {
              el.inquilino_morada_fact = this.utils.moradaStrToList(el.inquilino_morada_fact as string);
            }
            return el;
          })
          resolve(res.data)
        }
      }, err => {reject(err)});
    });
  }
}
