import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, fromEvent, forkJoin } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll, throttleTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { AppStateService } from '../app-state.service';
import { UserSessionService } from '../user-session.service';


//TODO DELETE IN FUTURE
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-condominiums',
  templateUrl: './condominiums.component.html',
  styleUrls: ['./condominiums.component.scss']
})
export class CondominiumsComponent implements OnInit, OnDestroy {

  transitionController = new TransitionController();
  codAlreadyExiste = false;

  // New condominium form
  newCondForm = new FormGroup({
    code: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    exerc: new FormControl(null),
    name: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    n_zonas: new FormControl(null),
    n_fraccoes: new FormControl(null),

    address: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),
  });

  addressObj = {
    address: null,
    postalCode: null,
    locality: null,
  }

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  // Condominium table variables
  condListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false },  // 'ASC', 'DESC'
    { key: 'classificacao', name: null, type: 'text', sort: null, searchable: false },
    { key: 'cod', name: 'Código', type: 'text', sort: null, searchable: true },
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true },
    { key: 'n_zonas', name: '#Zonas', type: 'text', sort: null, searchable: false },
    { key: 'n_fraccoes', name: '#Fracções', type: 'text', sort: null, searchable: false },
    // { key: 'exercicio', name: 'Exercício', type: 'text', sort: null, searchable: true },
    // { key: 'permilagem', name: 'Permilagem', type: 'text', sort: null, searchable: false },
    { key: 'estado', name: 'Estado', type: 'text', sort: null, searchable: false },
  ];
  condList: Array<any> = [];
  chosenCond:any = null;
  condListLength: number = 0;
  @ViewChild('condTableSearch', { static: false }) condTableSearch: ElementRef;
  condPage: number = 0;
  condSelectedPage: number = null;
  condItemPerPAge: number = 20;
  condKeyword: string = null;
  condSortParam: string = null;
  condSortDirection: string = null;
  condSearching: boolean = false;

  maxCod = null;
  
  comp = 'orcamentos';
  initState = null;
  prevState = null;

  // CLASSFICATION COUNTER VARIABLE
  condCountA = null;
  condCountB = null;
  condCountC = null;

  constructor(public router: Router,
              public modalService: SuiModalService,
              public utils: UtilitiesService,
              public api: ApiService,
              public userSession: UserSessionService,
              public appState: AppStateService,
              public appConfig: AppConfigService,
              public toastr: ToastrService,
              public message: MessageService) {
  }

  saveGlobalState(id, cod, nome, exercicio) {
    this.appState.saveGlobalState('global', { 
      selCondominio: { id: id, cod: cod, nome: nome, exercicio: exercicio },
    });
  }

  idCondominioSelected:string = null;

  ngOnInit() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);
    if (this.prevState) {
      this.condKeyword = (this.prevState.state.condKeyword) ? this.prevState.state.condKeyword : null;
      this.condSelectedPage = (this.prevState.state.condSelectedPage) ? this.prevState.state.condSelectedPage : 1;
      this.activo = (this.prevState.state.hasOwnProperty('activo')) ? this.prevState.state.activo : true;
      this.construcao = (this.prevState.state.hasOwnProperty('construcao')) ? this.prevState.state.construcao : true;
      this.inactivo = (this.prevState.state.hasOwnProperty('inactivo')) ? this.prevState.state.inactivo : false;
      this.angariacao = (this.prevState.state.hasOwnProperty('angariacao')) ? this.prevState.state.angariacao : false;
      this.rescisao = (this.prevState.state.hasOwnProperty('rescisao')) ? this.prevState.state.rescisao : false;

      this.appState.clearPrevState(this.comp);
    }

    this.animate();

    let globalState = this.appState.getGlobalState('global');
    if (globalState && globalState.hasOwnProperty('selCondominio')) {
      this.idCondominioSelected = (globalState.selCondominio) ? globalState.selCondominio.id : null;
    }

    // Get initial data
    this.getCondList();
    this.getFraccoesCount();
    this.getMaxCod();
  }

  ngAfterViewInit() {
    // Condominiums input search
    fromEvent(this.condTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'condominiums');
    });

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  apiSub = null;
  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    setTimeout(() => { if (this.apiSub) this.apiSub.unsubscribe(); }, 1);
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => resolve(true))
      .onDeny(() => resolve(false));
    });
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  addCondominuim() {
    this.router.navigate(['condominios/condominio', 'criar']);

    // BREADCRUMB SIGNAL
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVO CONDOMÍNIO' });
  }

  clearNewCondForm() {
    this.newCondForm.reset();
    this.codAlreadyExiste = false;
  }

  fraccoesCount = null;
  fetchFracCount = false;
  getFraccoesCount() {
    if (this.fetchFracCount) return;

    let activo = (this.activo) ? 1 : 0;
    let construcao = (this.construcao) ? 1 : 0;
    let inactivo = (this.inactivo) ? 1 : 0;
    let angariacao = (this.angariacao) ? 1 : 0;
    let rescisao = (this.rescisao) ? 1 : 0;

    this.fetchFracCount = true;
    this.api.getFraccoesCount(activo, construcao, inactivo, angariacao, rescisao).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.fraccoesCount = Number(res.data['COUNT(f.id)']);

        if (res.hasOwnProperty('class_couter')) {
          this.condCountA = 0; this.condCountB = 0; this.condCountC = 0;

          res.class_couter.forEach(el => {
            switch (el.classificacao) {
              case 'A': this.condCountA = el.count; break;
              case 'B': this.condCountB = el.count; break;
              case 'C': this.condCountC = el.count; break;
            }
          });
        }

      }
      this.fetchFracCount = false;
    }, err => { this.fetchFracCount = false; });
  }

  activo = true;
  inactivo = false;
  construcao = true;
  angariacao = false;
  rescisao = false;
  getCondList() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    let activo = (this.activo) ? 1 : 0;
    let construcao = (this.construcao) ? 1 : 0;
    let inactivo = (this.inactivo) ? 1 : 0;
    let angariacao = (this.angariacao) ? 1 : 0;
    let rescisao = (this.rescisao) ? 1 : 0;

    this.apiSub = this.api.getCondominiums(this.condPage, this.condItemPerPAge, this.condKeyword, this.condSortParam, this.condSortDirection, activo, construcao, inactivo, angariacao, rescisao, this.idCondominioSelected).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.condList = res.data;
        this.condList.forEach(el => {
          el['estado'] = (el['estado']) ? this.appConfig.condStatesSelOpts.find(it => (it.value === el['estado'])).name : null;
          el['checked'] = false;
        });
        this.chosenCond = res.active;
        if (this.chosenCond) {
          this.chosenCond['estado'] = (this.chosenCond['estado']) ? this.appConfig.condStatesSelOpts.find(it => (it.value === this.chosenCond['estado'])).name : null;
          this.chosenCond['checked'] = false;
        }
        this.condListLength = res.total;
        this.condSearching = false;
      } else {
        this.condListLength = 0;
        this.chosenCond = null;
        this.utils.apiErrorMsg(res);
      }

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.condSearching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  getMaxCod() {
    this.api.getCondominioMaxCod().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.maxCod = Number(res.data.max_cod) + 1;
      }
    }, err => {});
  }

  condPageChange(ev) {
    this.condPage = ev;
    this.getCondList();
  }

  delCondominiums(toDelete) {
    this.api.delCondominiums(toDelete).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.condList = this.condList.filter(el => !el.checked);
        
        let req = [];
        toDelete.forEach(el => {
          // REGISTO ACTIVIDADES API CALL
          let titulo = 'Condomínio Removido'
          let descricao = 'Condomínio: ' + el.cod + ' - ' + el.nome;
          req.push(this.api.saveRegistoActividade(el.cod, null, null, titulo, descricao));
        });
        forkJoin(req).subscribe(res => {}, err => { });

      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });

  }

  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'condominiums':
        (ev.target.checked) ? this.condList.map(el => el.checked = true ) : this.condList.map(el => el.checked = false );
        break;
    }
  }

  async tableAction(action, targetList) {
    switch (targetList) {
      case 'condominiums':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            this.addCondominuim();
            break;
          case 'delete':
            let toDelete = this.condList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delCondominiums(toDelete);
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
    }
  }
   
  tableSearch(value, targetList) {
    switch (targetList) {
      case 'condominiums':
        this.condKeyword = value.toLowerCase().trim();
        this.condSearching = true;

        this.getCondList();
        break;
    }
  }

  tableSort(key, targetList) {
    switch (targetList) {
      case 'condominiums':

        this.condListCol.forEach(el => {
          if (el.key === key) {
    
            if (el.sort === 'DESC') {
              el.sort = 'ASC';
            } else if (el.sort === 'ASC') {
              el.sort = 'DESC';
            } else if (el.sort === null) {
              el.sort = 'ASC';
            }

            this.condSortParam = key;
            this.condSortDirection = el.sort;
            this.getCondList();
          } else {
            el.sort = null;
          }
        });
        break;
    }
  }

  onChanged(target) {
    switch (target) {
      case 'cod': this.codAlreadyExiste = false; break;
    }
  }

  setState() {
    this.appState.setPrevState(this.comp, { 
      condKeyword: this.condKeyword,
      condSelectedPage: this.condSelectedPage,
      activo: this.activo,
      construcao: this.construcao,
      inactivo: this.inactivo,
      angariacao: this.angariacao,
      rescisao: this.rescisao,
    });
  }

  goToDetails(cond) {
    // SAVE THIS STATE
    this.setState();
    this.saveGlobalState(cond.id, cond.cod, cond.nome, cond.exercicio);

    this.router.navigate(['condominios/condominio/geral', cond.id]);

    // Emit signal to breadcrumb component
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${cond.classificacao} - ${cond.cod} - ${cond.nome}` });
  }



  //TODO DELETE IN FUTURE
  getcontenciososList() {
    this.api.getContenciososList().subscribe(res => {
      if (res && res.success) {
        let entidades:Array<{
          condominio,
          fracao,
          nome_entidade,
          is_atual,
          estado_contencioso,
        }> = res.data;

        let filename = 'Estados_Contenciosos_Entidades';
        let excelData = [];
        let worksheet = null;
        excelData = [{ condominio: 'Condomínio', fracao: 'Fração', nome_entidade: 'Condómino', is_atual: 'Atual Proprietário', estado_contencioso: 'Contencioso' }];
        
        entidades.forEach(el => {
          excelData.push({
            condominio: el.condominio,
            fracao: el.fracao,
            nome_entidade: el.nome_entidade,
            is_atual: el.is_atual,
            estado_contencioso: el.estado_contencioso,
          });
        });
        
          
        worksheet = XLSX.utils.json_to_sheet(excelData, { header:['condominio', 'fracao', 'nome_entidade', 'is_atual', 'estado_contencioso'], skipHeader: true });
        worksheet['!cols'] = this.utils.fitToColumn(excelData);
          
        let workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        
        let excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        let data: Blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        FileSaver.saveAs(data, filename + '.xlsx');

      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    })
  }
}
