import { Component } from '@angular/core';
import { SuiLocalizationService} from "ng2-semantic-ui";
import pt from "ng2-semantic-ui/locales/pt";
import { AppConfigService } from './app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'vertis-admin-webapp';
  sideMenuCollapsed = false;

  constructor(
            public localizationService:SuiLocalizationService,
            public appConfig: AppConfigService
            ) {
    localizationService.load('pt', pt);

    localizationService.patch('pt', {
      datepicker: {
        formats: {
            datetime: 'DD-MM-YYYY HH:mm',
            date: 'DD-MM-YYYY',
        },
      },
    });

    localizationService.setLanguage('pt');
  }

  ngOnInit() {
  }

  toogleSideMenu() {
    this.sideMenuCollapsed = !this.sideMenuCollapsed;
  }
}
