import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { forkJoin } from 'rxjs';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { CondominiosService } from '../business-logic-services/condominios.service';
import { CondominosService } from '../business-logic-services/condominos.service';
import { FraccoesService } from '../business-logic-services/fraccoes.service';
import { CondominioPaymentInfo } from '../business-model-interfaces/condominios';
import { Fraccao, FraccaoLight } from '../business-model-interfaces/fraccoes';
import { CondominoSimple } from '../condominos';
import { UtilitiesService } from '../utilities.service';

interface ContaCorrentePDFData {
  title: string
  tipoListagem: '0'|'1'|'2',
  startDate: Date
  endDate: Date
  iban_condominio: string
  list: Array<ContaCorrenteRow>,
  total: {
    debito: number
    credito: number
    saldo: number
  }
}

export interface CCDataSet {
  nib,
  nome,
  data
}

interface ContaCorrenteRow {
  separatorCond: boolean
  separator: boolean
  total: boolean
  doc: string
  data: Date,
  proc: string
  label: string
  tipo_doc: string,
  data_venc: Date
  debito: number
  credito: number
  saldo: number
}

export interface ContaCorrentePDFInput {
  type:'FRACAO'|'CONDOMINO', 
  cod, 
  data: Date, 
  tipoAgrupamento,
  tipoListagem:'0'|'1'|'2',
  fracoes?: Array<{cod:string}>,
  condominos?: Array<{cod_condominio, cod_fraccao}>
  filter_recibo_date?:Date,
}

@Component({
  selector: 'app-conta-corrente-pdf',
  templateUrl: './conta-corrente-pdf.component.html',
  styleUrls: ['./conta-corrente-pdf.component.scss']
})
export class ContaCorrentePdfComponent implements OnInit {


  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-centered width-20' },
    { key: 'doc', name: 'Doc', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'data_venc', name: 'Data Venc.', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered' },
    { key: 'proc', name: 'Processamento', type: 'text', sort: null, searchable: false, centered: false, class: 'width-250' },
    { key: 'debito', name: 'Débito', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-right' },
    { key: 'credito', name: 'Crédito', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
    { key: 'saldo', name: 'Saldo', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right' },
  ];

  now = new Date();

  data:ContaCorrentePDFData = {
    title: '',
    startDate: null,
    endDate: null,
    iban_condominio: null,
    tipoListagem: null,
    list: [],
    total: {
      debito: 0,
      credito: 0,
      saldo: 0
    },
  }



  @ViewChild('pdf', { static: false }) pdfController: PDFExportComponent;
  
  constructor(
    public api: ApiService,
    public condominios: CondominiosService,
    public condominos: CondominosService,
    public fraccoes: FraccoesService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  getBase64(input:ContaCorrentePDFInput, dataSet: CCDataSet=null): Promise<string> {
    return new Promise(async (resolve) => {

      await this.getContaCorrente(input, dataSet);

      this.cdRef.detectChanges();
      
      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');
        resolve(base64);
      }).catch(err => {
        resolve(null);
      });
    })
  }

  generatePDF(input:ContaCorrentePDFInput): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.now = new Date();
      await this.getContaCorrente(input);
      this.cdRef.detectChanges();
      this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
      this.pdfController.forceProxy = this.appConfig.pdf.forceProxy;
      this.pdfController.proxyTarget = this.appConfig.pdf.proxyTarget;
      let filename = this.utils.getFileNameFormatted(input.cod);
      this.pdfController.saveAs(filename + '.pdf');
      resolve(true);
    })
  }

  getContaCorrente(input:ContaCorrentePDFInput, dataSet: CCDataSet=null): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.data = {
        title: '',
        tipoListagem: input.tipoListagem,
        startDate: null,
        endDate: null,
        iban_condominio: null,
        list: [],
        total: {
          debito: 0,
          credito: 0,
          saldo: 0
        }
      }
      
      this.data.endDate = new Date(input.data);
      let fraccoes = [];
      let req = [];

      switch (input.type) {
        case 'FRACAO':
          if (dataSet == null) {
            let condominioInfo = await this.getCondominioInfo(input.cod);
            if (!condominioInfo) resolve(false);
  
            let paymentInfo = condominioInfo.paymentInfo;
            this.data.iban_condominio = paymentInfo.nib;
            this.data.title = input.cod + ' - ' + paymentInfo.nome;
  
            fraccoes = input.fracoes && input.fracoes.length ? input.fracoes : condominioInfo.fracoes;

            this.api.getContaCorrente('fraccoes', input.tipoAgrupamento, input.cod, fraccoes.map(el => el.cod), null, input.data, input.tipoListagem, input.filter_recibo_date? input.filter_recibo_date : undefined).subscribe(res => {
              if (res.hasOwnProperty('success') && res.success) {
                // let dataSet = res.filter(el => el.success).reduce((acc, el) => {
                //   acc = acc.concat(el.data);
                //   return acc;
                // }, []);
                this.generateContaCorrenteList_FracaoDetailed(res.data);
                resolve(true);
              } else { resolve(false); }
            });
          } else {
            this.data.iban_condominio = dataSet.nib;
            this.data.title = input.cod + ' - ' + dataSet.nome;
            this.generateContaCorrenteList_FracaoDetailed(dataSet.data);
            resolve(true);
          }
          // fraccoes.forEach(fraccao => {
          // });
          // if (fraccoes.length) {
          //   req.push(this.api.getContaCorrente('fraccoes', input.tipoAgrupamento, input.cod, fraccoes.map(el => el.cod), null, input.data, input.tipoListagem, input.filter_recibo_date? input.filter_recibo_date : undefined));
          // }
          break;
        case 'CONDOMINO':
          if (dataSet == null) {
            let condominoInfo = await this.getCondominoInfo(input.cod);
            if (!condominoInfo || !condominoInfo.info) resolve(false);

            this.data.title = input.cod + ' - ' + condominoInfo.info.nome;

            fraccoes = input.condominos && input.condominos.length ? input.condominos : condominoInfo.fracoes;
            // fraccoes.forEach(fraccao => {
            //   req.push(this.api.getContaCorrenteCondomino(input.cod, [fraccao], null, input.data, input.tipoListagem));
            // });

            this.api.getContaCorrenteCondomino(input.cod, fraccoes, null, input.data, input.tipoListagem).subscribe(res => {
              if (res.hasOwnProperty('success') && res.success) {
                // let dataSet = res.filter(el => el.success).reduce((acc, el) => {
                //   acc = acc.concat(el.data);
                //   return acc;
                // }, []);
                this.generateContaCorrenteList_CondominoDetailed(res.data);
                resolve(true);
              } else { resolve(false); }
            });
          } else {
            this.data.iban_condominio = dataSet.nib;
            this.data.title = input.cod + ' - ' + dataSet.nome;
            this.generateContaCorrenteList_CondominoDetailed(dataSet.data);
            resolve(true);

          }
          break;
        default:
          break;
      }
    })
  }

  getCondominoInfo(cod_proprietario): Promise<{fracoes:Array<{cod_condominio, cod_fraccao}>, info: CondominoSimple}> {
    return new Promise(async (resolve) => {
      let req:any = [
        this.fraccoes.getFraccoesByProprietario(cod_proprietario),
        this.condominos.getDetailsSimple(cod_proprietario),
      ]
      Promise.all(req).then(res => {
        let fraccoes = (res[0] as Array<Fraccao>).map(el => { return { cod_condominio: el.cod_condominio, cod_fraccao: el.cod}});
        resolve({
          fracoes: fraccoes,
          info: (res[1] as CondominoSimple)
        })
      }).catch(err => {
        resolve(null);
      });
    })
  }
  getCondominioInfo(cod_condominio): Promise<{fracoes:Array<FraccaoLight>, paymentInfo: CondominioPaymentInfo}> {
    return new Promise(async (resolve) => {
      let req:any = [
        this.condominios.getFraccoesLight(cod_condominio),
        this.condominios.getPaymentInfo(cod_condominio),
      ]
      Promise.all(req).then(res => {
        resolve({
          fracoes: res[0] as Array<FraccaoLight>,
          paymentInfo: res[1] as CondominioPaymentInfo
        })
      }).catch(err => {
        resolve(null);
      });
    })
  }


  generateContaCorrenteList_FracaoDetailed(data: Array<any>) {
    
    let dataOrig = data;
    data = data.filter(el => Number(el.saldo) > 0);

    let list: Array<ContaCorrenteRow> = [];

    let fraccoes = [...new Set(data.map(el => el.cod_fraccao))];
    fraccoes = fraccoes.sort((a, b) => { return (a > b || a['length'] > b['length']) ? 1 : -1; });

    let perFraccaoArr = [];
    let condominos = [];
    let perCondominoArr = [];

    let debito_total_condominio = 0;
    let credito_total_condominio = 0;
    let saldo_total_condominio = 0;

    let debito_total = 0;
    let credito_total = 0;
    let saldo_total = 0;

    let totalDebito= 0;
    let totalCredito= 0;
    let totalSaldo= 0;

    fraccoes.forEach(fraccao => {

      debito_total = 0;
      credito_total = 0;
      saldo_total = 0;

      perFraccaoArr = data.filter(it => it.cod_fraccao === fraccao).sort((a, b) => {
        if (a.data_emis === b.data_emis) {
          return Number(a.n_doc) - Number(b.n_doc);
        } else {
          return this.utils.getDate(a.data_emis).getTime() - this.utils.getDate(b.data_emis).getTime();
        }
      });

      if (perFraccaoArr.length) { perFraccaoArr[0]['zona_nome']
        let zonaNome = dataOrig.find(it => it.cod_fraccao === perFraccaoArr[0]['cod_fraccao'] && it.zona_nome);
        let aux: any = { label: `${(zonaNome) ? zonaNome.zona_nome : null} / ${perFraccaoArr[0]['cod_fraccao']} - ${perFraccaoArr[0]['fraccao_nome']}`, separator: true, separatorCond: false, total: false }
        list.push(aux);
      }

      condominos = [...new Set(perFraccaoArr.map(it => it.condomino_cod))]
      condominos.forEach(condomino => {

        debito_total_condominio = 0;
        credito_total_condominio = 0;
        saldo_total_condominio = 0;

        perCondominoArr = perFraccaoArr.filter(it => it.condomino_cod === condomino);

        if (perCondominoArr.length) {
          let aux:any = {
            label: `${perCondominoArr[0]['condomino_nome']}`, separator: true, separatorCond: true, total: false
          }
          list.push(aux);
        }

        perCondominoArr.forEach(avisoCredito => {

          let aux: ContaCorrenteRow = {
            separator: false,
            separatorCond: false,
            total: false,
            tipo_doc: (avisoCredito['tipo_doc']) ? avisoCredito['tipo_doc'] : null,
            doc: (avisoCredito['n_doc']) ? avisoCredito['tipo_doc'] + ' ' + avisoCredito['n_doc'] : null,
            data: (avisoCredito['data_emis']) ? this.utils.getDate(avisoCredito['data_emis']) : null,
            data_venc: (avisoCredito['data_venc']) ? this.utils.getDate(avisoCredito['data_venc']) : null,
            proc: (avisoCredito['descricao']) ? avisoCredito['descricao'] : null,
            debito: null,
            credito: null,
            saldo: null,
            label: null
          }
          if (avisoCredito['tipo_doc'] === 'C') {
            aux.debito = 0;
            aux.credito = (avisoCredito['saldo']) ? Math.round(Number(avisoCredito['saldo']) * 100) / 100 : null;
            aux.saldo = aux.debito - aux.credito;
          } else {
            aux.debito = (avisoCredito['debito']) ? Math.round(Number(avisoCredito['debito']) * 100) / 100 : null;
            aux.saldo = (avisoCredito['saldo']) ? Math.round(Number(avisoCredito['saldo']) * 100) / 100 : null;
            aux.credito = aux.debito - aux.saldo;
          }

          debito_total_condominio += aux.debito;
          credito_total_condominio += aux.credito;
          saldo_total_condominio += aux.saldo;

          debito_total += aux.debito;
          credito_total += aux.credito;
          saldo_total += aux.saldo;

          totalDebito += aux.debito;
          totalCredito += aux.credito;
          totalSaldo += aux.saldo;

          list.push(aux);
        });

        if (perCondominoArr.length) {
          let aux:any = { label: 'Total do condómino', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true };
          list.push(aux);
        }
      });

      if (perFraccaoArr.length) {
        let aux: any = { label: 'Total da fracção', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true }
        list.push(aux);
      } 
    });


    this.data.list = list;
    this.data.total = {
      debito: totalDebito,
      credito: totalCredito,
      saldo: totalSaldo
    };
  }

  generateContaCorrenteList_CondominoDetailed(data) {
    let dataOrig = data;
    data = data.filter(el => Number(el.saldo) > 0); 

    let list: Array<ContaCorrenteRow> = [];

    let fraccoes = [...new Set(data.map(el => el.cod_fraccao))];
    fraccoes = fraccoes.sort((a, b) => { return (a > b || a['length'] > b['length']) ? 1 : -1; });

    let prevCondominio = null;
    let prevFrac = null;

    let debito_total_condominio = 0;
    let credito_total_condominio = 0;
    let saldo_total_condominio = 0;

    let debito_total = 0;
    let credito_total = 0;
    let saldo_total = 0;

    let totalDebito= 0;
    let totalCredito= 0;
    let totalSaldo= 0;

    data.forEach((el, i) => {

      let aux: ContaCorrenteRow = {
        separator: false,
        separatorCond: false,
        total: false,
        tipo_doc: (el['tipo_doc']) ? el['tipo_doc'] : null,
        doc: (el['n_doc']) ? el['tipo_doc'] + ' ' + el['n_doc'] : null,
        data: (el['data_emis']) ? this.utils.getDate(el['data_emis']) : null,
        data_venc: (el['data_venc']) ? this.utils.getDate(el['data_venc']) : null,
        proc: (el['descricao']) ? el['descricao'] : null,
        debito: null,
        credito: null,
        saldo: null,
        label: null,
      }
      if (el['tipo_doc'] === 'C') {
        aux['debito'] = 0;
        aux['credito'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
        aux['saldo'] = aux.debito - aux.credito;
      } else {
        aux['debito'] = (el['debito']) ? Math.round(Number(el['debito']) * 100) / 100 : null;
        aux['saldo'] = (el['saldo']) ? Math.round(Number(el['saldo']) * 100) / 100 : null;
        aux['credito'] = aux.debito - aux.saldo;
      }

        // TABLE ROW TOTAL AND SEPARATOR
        if ((prevCondominio !== el['cod_condominio'] || prevFrac !== el['cod_fraccao']) && el['tipo_doc'] !== 'C') {
          if (i > 0) {
            let aux1: any = { label: 'Total da fracção', debito: debito_total_condominio, credito: credito_total_condominio, saldo: saldo_total_condominio, separator: false, separatorCond: true, total: true };
            let aux2: any = { label: 'Total do condomínio', debito: debito_total, credito: credito_total, saldo: saldo_total, separator: false, separatorCond: false, total: true };
            list.push(aux1);
            list.push(aux2);
          }

          // ZONA AND FRACCAO SEPARATOR
          if (prevCondominio !== el['cod_condominio']) {
            let aux1:any = { label: `${el['cod_condominio']} - ${el['nome_condominio']}`, separator: true, separatorCond: false, total: false };
            list.push(aux1);
            debito_total = 0;
            credito_total = 0;
            saldo_total = 0;
          }

          // ENTITY SEPARATOR
          let aux1:any = { label: `${el['cod_fraccao']} - ${el['fraccao_nome']}`, separator: true, separatorCond: true, total: false };
          list.push(aux1);
          debito_total_condominio = 0;
          credito_total_condominio = 0;
          saldo_total_condominio = 0;
        }
        if (el['tipo_doc'] !== 'C') prevCondominio = el['cod_condominio'];
        if (el['tipo_doc'] !== 'C') prevFrac = el['cod_fraccao'];

        debito_total += aux.debito;
        credito_total += aux.credito;
        saldo_total += aux.saldo;

        debito_total_condominio += aux.debito;
        credito_total_condominio += aux.credito;
        saldo_total_condominio += aux.saldo;

        list.push(aux);

        totalDebito += aux.debito;
        totalCredito += aux.credito;
        totalSaldo += aux.saldo;
    });

    this.data.list = list;
    this.data.total = {
      debito: totalDebito,
      credito: totalCredito,
      saldo: totalSaldo
    };
  }

}
