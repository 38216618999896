import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { BusinessLogicService } from '../business-logic.service';
import { ContaCorrenteRow } from '../business-model-interfaces/contas-correntes';
import { Orcamento } from '../business-model-interfaces/orcamentos';
import { RegistoAtividadeBD } from '../business-model-interfaces/registo-atividade';
import { CondominoFraccao, CondominoFraccaoBD, CondominoNoticeInfo, CondominoSimple, Contencioso, ContenciosoRegAtividade, estadoContencioso, estadoContenciosoBD, FraccaoWithoutContencioso, FraccaoWithoutContenciosoBD, getContenciosoAcordoPDFInfo, saveEstadoContencioso } from '../condominos';
import { UtilitiesService } from '../utilities.service';
import { OrcamentosService } from './orcamentos.service';

@Injectable({
  providedIn: 'root'
})
export class CondominosService {


  documentsToPrintOpts = [
    {name: 'Declaração de Dívida', value: '1'},
  ];

  constructor(
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public businessLogic: BusinessLogicService,
    public api: ApiService,
    public toastr: ToastrService,
    public router: Router,
  ) { }



  getContenciososNotConfiguredOptions(): Promise<Array<Contencioso>> {
    return new Promise((resolve) => {
      this.api.getContenciososNotConfiguredOptions().subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve([]);
      });
    });
  }


  getDetailsSimple(cod_proprietario): Promise<CondominoSimple> {
    return new Promise((resolve, reject) => {
      this.api.getCondominoDetailsSimple(cod_proprietario).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    });
  }

  getCondominoNoticeInfo(cod_proprietario, id_fracao, data_conteudo_comunicacao:Date=null): Promise<CondominoNoticeInfo> {
    return new Promise((resolve, reject) => {
      this.api.getCondominoNoticeInfo(cod_proprietario, id_fracao, data_conteudo_comunicacao).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }
  
  getDeclaracaoDividaPDFInfo(cod_proprietario, id_fracao, data_conteudo_comunicacao:Date=null, cod_fracao): Promise<{condominoNoticeInfo:CondominoNoticeInfo, body:string, subject:string}> {
    return new Promise((resolve, reject) => {
      this.getCondominoNoticeInfo(cod_proprietario, id_fracao, data_conteudo_comunicacao).then(data => {
        if (data) {
          let req:Array<any> = [
            this.api.getContaCorrente('fraccoes', 'fraccao', data.cod_condominio, [data.cod_fraccao], null, data_conteudo_comunicacao, '0'),
            this.api.getFracaoQuota(data.cod_condominio, data.cod_fraccao, data_conteudo_comunicacao),
            this.api.getFracaoFutureQuotaExtra(data.cod_condominio, data.cod_fraccao, data_conteudo_comunicacao)
          ];
          forkJoin(req).subscribe(resArr => {
            if (!resArr.find(el => !el.success)) {
              let cod_fracao_str = data.cod_fraccao;
              if (cod_fracao != null) cod_fracao_str = cod_fracao; //TEMP SOLUTION (TASK #764)
              
              let data_str = this.utils.getDataPorExtenso(data_conteudo_comunicacao);
              let subject = 'Declaração para efeitos da alienação da fração autónoma designada pela letra ' + cod_fracao_str + ', correspondente ao ' + data.nome_fraccao + '.';

              let morada_condominio = this.utils.formatMoradaJSON(this.utils.moradaStrToJSON({},data.morada_condominio));
              let body = "Para efeitos de alienação da fração autónoma designada pela letra " + cod_fracao_str +", correspondente ao " + data.nome_fraccao + 
                          ", propriedade de " + data.nome_entidade + " , do prédio sito " + morada_condominio + ", com o NIPC " + data.nif_condominio + ", nos termos do art.º 1424-A do código civil, declara-se o seguinte:\n\n";

              let cc = (resArr[0].data as Array<ContaCorrenteRow>);
              cc = cc.filter(el => el.condomino_cod === cod_proprietario && el.tipo_doc === 'A');
              cc = cc.map(el => {
                return {
                  ...el,
                  data_emis: this.utils.getDate(el.data_emis, undefined, undefined, true),
                  saldo: parseFloat(el.saldo),
                }
              })
              let quotasOrdinarias = cc.filter(el => el.tipo_proc !== 'E');
              let quotasExtras = cc.filter(el => el.tipo_proc === 'E');
              let dividaTotal = 0;

              let lastAviso = quotasOrdinarias.reduce((acc, el) => {
                if (!acc || this.utils.compareDayDates(acc, el.data_emis) < 0) {
                  acc = el.data_emis;
                }
                return acc;
              }, null as Date);
              let lastAvisoYear = lastAviso ? lastAviso.getFullYear() : null;
              let declarationYear = data_conteudo_comunicacao.getFullYear();
            

              let allBudgetsIncluded = true;
              if (!quotasOrdinarias.find(el => el.saldo > 0) && lastAvisoYear >= declarationYear) {
                body += "1. As quotas ordinárias desta fração até ao dia " + data_str +", encontram-se todas liquidadas;";
              } else {
                let dividaMeses:Array<{inicio:Date, fim:Date, divida: number}> = [];
                quotasOrdinarias.filter(el => el.saldo > 0).forEach((el, i) => {
                  if (!dividaMeses.length) {
                    dividaMeses.push({inicio: el.data_emis, fim: el.data_emis, divida: el.saldo});
                  } else {
                    let monthDiff = this.utils.compareMonthDates(el.data_emis, dividaMeses[dividaMeses.length - 1].fim);
                    let yearDiff = el.data_emis.getFullYear() - dividaMeses[dividaMeses.length - 1].fim.getFullYear();
                    if (yearDiff !== 0 || (monthDiff !== 0 && monthDiff !== 1)) {
                      dividaMeses.push({inicio: el.data_emis, fim: el.data_emis, divida: el.saldo});
                    } else {
                      dividaMeses[dividaMeses.length - 1].divida += el.saldo;
                      if (monthDiff === 1) dividaMeses[dividaMeses.length - 1].fim = el.data_emis;
                    }
                  }
                });

                body += "1. As quotas ordinárias desta fração até ao dia " + data_str + ", encontram-se por liquidar. O condómio";
                dividaMeses.forEach((el, i) => {
                  if (this.utils.compareDayDates(el.inicio, el.fim) === 0) el.fim = null;

                  let orc = quotasOrdinarias.find(quotaOrd => quotaOrd.tipo_proc === 'O' && this.utils.compareMonthDates(quotaOrd.data_emis, el.inicio) === 0);
                  let fcr = quotasOrdinarias.find(quotaOrd => quotaOrd.tipo_proc === 'F' && this.utils.compareMonthDates(quotaOrd.data_emis, el.inicio) === 0);
                  let seg = quotasOrdinarias.find(quotaOrd => quotaOrd.tipo_proc === 'S' && this.utils.compareMonthDates(quotaOrd.data_emis, el.inicio) === 0);
                  let quota = 0;
                  if (orc) quota += (parseFloat(orc.debito));
                  if (fcr) quota += (parseFloat(fcr.debito));
                  if (seg) quota += (parseFloat(seg.debito));
                  quota = this.utils.cleanDecimalDigits(quota);
                  el.divida = this.utils.cleanDecimalDigits(el.divida);

                  dividaTotal += el.divida;
                  if (i !== 0) {
                    body += ". Deve ";
                  } else {
                    body += " deve ";
                  }

                  if (el.inicio && el.fim) {
                    body += " de " + this.utils.getMonthPorExtenso(el.inicio) + " de " + el.inicio.getFullYear() + " a " + this.utils.getMonthPorExtenso(el.fim) + " de " + el.fim.getFullYear() 
                  } else {
                    body += " o mês de " + this.utils.getMonthPorExtenso(el.inicio) + " de " + el.inicio.getFullYear();
                  }

                  body += ", " + this.utils.getNumberFormatted(el.divida) + this.appConfig.currencySymbol + " (" + this.utils.getNumberPorExtenso(el.divida) + ")";
                  body += ", sendo que o valor da quota era de " + this.utils.getNumberFormatted(quota) + this.appConfig.currencySymbol + " (" + this.utils.getNumberPorExtenso(quota) + ")"
                })

                if (lastAvisoYear < declarationYear) {
                  allBudgetsIncluded = false;
                  if (dividaMeses.length) {
                    body += " Deve igualmente ";
                  } else {
                    body += " deve ";
                  }

                  let firstAvisoNotLancado = new Date(lastAvisoYear + 1,0,1);
                  if (this.utils.compareMonthDates(firstAvisoNotLancado, data_conteudo_comunicacao) !== 0) {
                    body += `de ${this.utils.getDataPorExtenso(firstAvisoNotLancado, false)} a ${this.utils.getDataPorExtenso(data_conteudo_comunicacao, false)}`
                  } else {
                    body += `o mês de ${this.utils.getDataPorExtenso(data_conteudo_comunicacao, false)}`
                  }

                  body += ", sendo que o orçamento ainda não se encontra lançado"


                }
                body += ";"
              }
              
              body += "\n\n";
              
              if (!quotasExtras.find(el => el.saldo > 0)) {
                body += "2. As quotas extraordinárias desta fração até ao dia " + data_str + ", encontram-se todas liquidadas;";
              } else {
                let extraFeesGroupped:Array<{descricao:string, divida:number}> = [];
                quotasExtras.filter(el => el.saldo > 0).forEach(el => {
                  let descriptionToSearch = el.descricao;
                  if (el.descricao.indexOf('ª Prestação - ') !== -1) {
                    descriptionToSearch = el.descricao.substring(el.descricao.indexOf('ª Prestação - ') + 14);
                  }
                  descriptionToSearch = descriptionToSearch.toLowerCase();

                  let index = extraFeesGroupped.findIndex(group => group.descricao === descriptionToSearch);
                  if (index === -1) {
                    extraFeesGroupped.push({descricao: descriptionToSearch, divida: el.saldo})
                  } else {
                    extraFeesGroupped[index].divida = this.utils.cleanDecimalDigits(extraFeesGroupped[index].divida + el.saldo);
                  }
                })
                body += "2. As quotas extraordinárias desta fração que se encontram por liquidar até ao dia " + data_str + " são as seguintes:";
                extraFeesGroupped.forEach((el, i) => {
                  dividaTotal += el.divida;
                  // if (i !== 0) {
                  //   body += ". Deve ";
                  // } else {
                  //   body += " deve ";
                  // }
                  let desc = el.descricao.charAt(0).toUpperCase() + el.descricao.toLowerCase().substring(1).toLowerCase();
                  body += " " + desc + ", no valor de " + this.utils.getNumberFormatted(el.divida) + this.appConfig.currencySymbol + " (" + this.utils.getNumberPorExtenso(el.divida) + ");";
                });
              }

              dividaTotal = this.utils.cleanDecimalDigits(dividaTotal);
              
              //Valor Quota Ordinária
              body += "\n\n"
              let periodicidade = "mensal";
              let diaVenc = "1";
              let diaPag = "8";
              if (resArr[1].data == null || resArr[2].data == null) {
                this.toastr.error(this.appConfig.errorCodes.ERR_RELATORIO_CONF_DIVIDA_001.msg, this.appConfig.errorCodes.ERR_RELATORIO_CONF_DIVIDA_001.title);
                resolve(null);
                return;
              } else {
                let valorQuota = parseFloat(resArr[1].data);
                body += "3. Os encargos do condomínio da fração " + cod_fracao_str + ", ao dia " + data_str + ", são constituídos por quotas ordinárias com pagamento " + periodicidade + 
                          ", cada uma, no valor de " + this.utils.getNumberFormatted(valorQuota) + this.appConfig.currencySymbol + " (" + this.utils.getNumberPorExtenso(valorQuota) + ")" 
                          + ", as quais se vencem no primeiro dia do mês a que digam respeito e que deverão ser pagas até ao dia 8 desse mesmo mês.";
                if (resArr[2].data.length > 0) {
                  // const firstChar = 'a'.charCodeAt(0);
                  // const lastChar = 'z'.charCodeAt(0);
                  // const charRange = lastChar - firstChar + 1;
                  let quotaExtra: {ajuste:number, valorPrestacao: number, valorEmFalta: number, nPrestacoesEmFalta:number, data_inicio: string, data_fim: string, descricao:string}[] = resArr[2].data;
                  body += " A fração possui ainda os seguintes encargos referentes a quotas extraordinárias:";
                  body += '<ul class="list-lower-letter">';
                  quotaExtra.forEach((quota, quotaIndex) => {
                    let quotaDescription = ` Quota extraordinária designada por "${quota.descricao}",`;

                    let dataInicio = this.utils.getDate(quota.data_inicio);
                    let dataFim = this.utils.getDate(quota.data_fim);

                    if (quota.nPrestacoesEmFalta == 1) {
                      quotaDescription += ` no valor de ${this.utils.getNumberFormatted(quota.valorEmFalta, true) + this.appConfig.currencySymbol} (${this.utils.getNumberPorExtenso(quota.valorEmFalta, true)}), de prestação única,`;
                      quotaDescription += ` a qual será cobrada em ${this.utils.getFormatedDate(quota.data_inicio)}`;
                    } else {
                      let isInVigor = this.utils.compareDayDates(dataInicio, data_conteudo_comunicacao) <= 0;
                      quotaDescription += ` com ${quota.nPrestacoesEmFalta} prestações${isInVigor? ' vincendas ': ' '}de ${this.utils.getNumberFormatted(quota.valorPrestacao, true) + this.appConfig.currencySymbol} (${this.utils.getNumberPorExtenso(quota.valorPrestacao, true)})`;
                      
                      if (isInVigor) {
                        quotaDescription += `, com término em ${this.utils.getDataPorExtenso(dataFim)}`;
                      } else {
                        quotaDescription += `, compreendidas entre ${this.utils.getDataPorExtenso(dataInicio)} e ${this.utils.getDataPorExtenso(dataFim)}`;
                      }

                      if (quota.ajuste) {
                        quotaDescription += `, sendo que a última prestação sofrerá um ajuste de ${this.utils.getNumberFormatted(quota.ajuste, true) + this.appConfig.currencySymbol} (${this.utils.getNumberPorExtenso(quota.ajuste, true)})`;
                      } 

                      quotaDescription += `. As prestações vincendas perfazem um valor total por liquidar de ${this.utils.getNumberFormatted(quota.valorEmFalta, true) + this.appConfig.currencySymbol} (${this.utils.getNumberPorExtenso(quota.valorEmFalta, true)})`;
                    }
                    quotaDescription += ';';


                    // const subHeading = String.fromCharCode(firstChar + (quotaIndex % charRange));
                    body += '<li>' + quotaDescription + '</li>';
                  })
                  body += "</ul>";
                } else {
                  body += " A fração não possui encargos referentes a quotas extraordinárias;";
                }
              }
              
              //Valor global Dívida
              body += "\n\n"
              let feesNotCreatedText = null;
              if (!allBudgetsIncluded) {
                let firstAvisoNotLancado = new Date(lastAvisoYear + 1,0,1);
                if (this.utils.compareMonthDates(firstAvisoNotLancado, data_conteudo_comunicacao) !== 0) {
                  feesNotCreatedText = `de ${this.utils.getDataPorExtenso(firstAvisoNotLancado, false)} a ${this.utils.getDataPorExtenso(data_conteudo_comunicacao, false)}`
                } else {
                  feesNotCreatedText = `do mês de ${this.utils.getDataPorExtenso(data_conteudo_comunicacao, false)}`
                }
              }


              if (dividaTotal === 0) {
                body += "4. Ao dia " + data_str +", não existem quaisquer dívidas ao condomínio, por parte da fração " + cod_fracao_str 
                + (!feesNotCreatedText?'.':`, no entanto é da responsabilidade do condómino o pagamento das quotas ${feesNotCreatedText}, sendo que o(s) orçamento(s) ainda não se encontra(m) lançado(s).`);
              } else {
                body += "4. Ao dia " + data_str +", a dívida da fração " + cod_fracao_str + " perante o condomínio, perfaz um total de " 
                    + this.utils.getNumberFormatted(dividaTotal) + this.appConfig.currencySymbol + " (" + this.utils.getNumberPorExtenso(dividaTotal) + ")" 
                    + (!feesNotCreatedText? '.':`, sendo que a este valor será acrescido as quotas ${feesNotCreatedText}, visto o(s) orçamento(s) ainda não se encontrar(em) lançado(s).`);
              }
              body += "\n\n"
              body += "\n\n"
              body += "Por ser verdade," 
                + "\nA gestão de condomínio"

              resolve({condominoNoticeInfo: data, body: body, subject: subject});
            } else {
              this.utils.apiErrorMsg(resArr);
              resolve(null);
            }
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            resolve(null);
          });
        } else {
          resolve(null);
        }
      }).catch(err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }


  saveEstadoContencioso(body: saveEstadoContencioso): Promise<estadoContencioso> {
    return new Promise((resolve, reject) => {
      this.api.saveEstadoContencioso(body).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(this.convertCondominoEstadoContenciosoType(res.data));
        } else {
          this.utils.apiErrorMsg(res);
          reject(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject(null);
      });
    });
  }

  getEstadoContencioso(id): Promise<{ contencioso: estadoContencioso, hasCreditos: boolean, atividade: Array<ContenciosoRegAtividade> }> {
    return new Promise((resolve) => {
      this.api.getEstadoContencioso(id).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve({ contencioso: this.convertCondominoEstadoContenciosoType(res.data), hasCreditos: res.hasCreditos, atividade: res.atividade.map(el => this.convertContenciosoRegistoAtividadeType(el)) });
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    });
  }

  getFracoesWithoutContencioso(cod_proprietario): Promise<Array<FraccaoWithoutContencioso>> {
    return new Promise((resolve, reject) => {
      this.api.getCondominoFracoesWithoutContencioso(cod_proprietario).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data.map(el => this.convertFracaoWithoutContenciosoType(el)));
        } else {
          this.utils.apiErrorMsg(res);
          reject([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        reject([]);
      });
    });
  }

  deleteEstadoContencioso(id_registo, del_razao): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.deleteEstadoContencioso(id_registo, del_razao).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }
  changeEstadoContencioso(id_registo, motivo, action: 'ACTIVATE' | 'DISABLE'): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.changeEstadoContencioso(id_registo, motivo, action).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }

  getContenciosoPDFInfo(id_contencioso, cod_entidade): Promise<getContenciosoAcordoPDFInfo["data"]> {
    return new Promise((resolve) => {
      this.api.getContenciosoPDFInfo(id_contencioso, cod_entidade).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  getDivida(cod_proprietario: any, dt_inicio: Date, id_fracao): Promise<number> {
    return new Promise((resolve) => {
      if (!cod_proprietario || !dt_inicio || !id_fracao) {
        resolve(null);
        return;
      }
      this.api.getCondominoDivida(cod_proprietario, dt_inicio, id_fracao).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(parseFloat(res.data));
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  getProprietarioByContencioso(id_contencioso): Promise<any> {
    return new Promise((resolve) => {
      if (!id_contencioso) {
        resolve(null);
        return;
      }
      this.api.getProprietarioByContencioso(id_contencioso).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(null);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  getContenciososByFracao(id_fracao): Promise<Array<estadoContencioso>> {
    return new Promise((resolve) => {
      this.api.getContenciososByFracao(id_fracao).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          resolve(res.data.map(el => this.convertCondominoEstadoContenciosoType(el)));
        } else {
          this.utils.apiErrorMsg(res);
          resolve([]);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(null);
      });
    })
  }

  // Navigation Utilities
  goToContencioso(id_contencioso): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!id_contencioso) {
        resolve(false);
        return;
      }
      let prop = null;
      try {
        prop = await this.getProprietarioByContencioso(id_contencioso);
        if (!prop) {
          resolve(false);
          return;
        }
      } catch (err) {
        resolve(false);
        return;
      }
      this.router.navigate(['entidades/proprietario', prop.cod], {
        state: {
          action: 'OPEN_CONTENCIOSO',
          data: {
            id: id_contencioso
          }
        }
      });
      resolve(true);
    })
  }


  //Type Conversions
  convertCondominoFraccaoType(condomino: CondominoFraccaoBD): CondominoFraccao {
    return {
      ...condomino,
      data_inicio: condomino.data_inicio ? this.utils.getDate(condomino.data_inicio) : null,
      data_fim: condomino.data_fim ? this.utils.getDate(condomino.data_fim) : null,
    }
  }

  convertCondominoEstadoContenciosoType(contencioso: estadoContenciosoBD): estadoContencioso {
    return {
      ...contencioso,
      dt_inicio: contencioso.dt_inicio ? this.utils.getDate(contencioso.dt_inicio, undefined, undefined, true) : null,
      primeira_prestacao: contencioso.primeira_prestacao ? this.utils.getDate(contencioso.primeira_prestacao, undefined, undefined, true) : null,
      penalizacao: contencioso.penalizacao ? parseFloat(contencioso.penalizacao) : null,
      prestacoes_adicionais: contencioso.prestacoes_adicionais ? JSON.parse(contencioso.prestacoes_adicionais).map(el => {
        return {
          data: this.utils.getDate(el.data, undefined, undefined, true),
          valor: el.valor,
        }
      }) : [],
    }
  }
  convertFracaoWithoutContenciosoType(fraccao: FraccaoWithoutContenciosoBD): FraccaoWithoutContencioso {
    return {
      ...fraccao,
      data_inicio_proprietario: fraccao.data_inicio_proprietario ? this.utils.getDate(fraccao.data_inicio_proprietario) : null,
      hasCreditos: fraccao.hasCreditos == '1' ? true : false,
    }
  }

  convertContenciosoRegistoAtividadeType(atividade: RegistoAtividadeBD): ContenciosoRegAtividade {
    let registo = this.businessLogic.convertRegistoAtividadeType(atividade);
    let obj: any = registo.obj;
    let prestacoes_adicionais: Array<{
      data: Date;
      valor: string;
    }> = null;
    try {
      prestacoes_adicionais = JSON.parse(obj.prestacoes_adicionais);
    } catch (err) {
      prestacoes_adicionais = null;
    }
    if (prestacoes_adicionais) {
      if (Array.isArray(prestacoes_adicionais)) {
        prestacoes_adicionais = prestacoes_adicionais.map(el => {
          return {
            ...el,
            data: el.data ? this.utils.getDate(el.data) : null,
          }
        });
      } else {
        prestacoes_adicionais = [];
      }
    }
    return {
      ...registo,
      obj: !obj ? null : {
        ...obj,
        dt_inicio: obj.dt_inicio ? this.utils.getDate(obj.dt_inicio) : null,
        primeira_prestacao: obj.primeira_prestacao ? this.utils.getDate(obj.primeira_prestacao) : null,
        prestacoes_adicionais: prestacoes_adicionais
      }
    }
  }

}