import { Component, OnInit, ViewChildren, QueryList, Input, ViewChild, ChangeDetectorRef, HostListener, ElementRef, Directive  } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent, forkJoin } from 'rxjs';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

interface IContext {
  data:string;
}

import { SuiSelectHolderComponent } from '../sui-select-holder/sui-select-holder.component';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { UserSessionService } from '../user-session.service';
import * as moment from 'moment';
import { reconciliacaoBancariaBDApi } from '../api-requests';


export interface editAgendDate {
  aberta: boolean,
  checked: boolean, 
  changeFeedback: boolean,
  contaOpts: Array<any>,
  cod_conta_bancaria,
  expanded: boolean,
  dateChanged: boolean,
  dt_pag: Date, 
  dt_pag_agendamento: Date, 
  dt_desp: any, 
  edittingDtDesp: boolean,
  fornecedor: {name:string, value:string},
  fornecedorChanged: boolean,
  form_pagam: string, 
  id: string,
  obj:string,
  n_despesa:number,
  reconciliada: boolean,
  valor: string,
  valor_por_liquidar: number,
  valor_liquidado: number,
  valor_pago: number,
  movimentos:Array<editMovimento>,
}
interface editMovimento {
  id:number,
  cod_conta_bancaria: number,
  contaOpts: Array<any>,
  data: Date,
  data_reconciliacao: Date,
  form_pagam: string,
  valor: string
  id_caixa_vertis: number,
}

@Component({
  selector: 'app-edit-pay-agendamento',
  templateUrl: './edit-pay-agendamento.component.html',
  styleUrls: ['./edit-pay-agendamento.component.scss']
})
export class EditPayAgendamentoComponent implements OnInit {

  @Input('tipo_proc') tipo_proc:string = null;
  @Input('cod_fornecedor') cod_fornecedor = null;
  @Input('contaOptsOrig') contaOptsOrig = null;
    
  @ViewChild('editAgendAlertRef', { static: false }) editAgendAlertRef;
  editAgendModalRef = null;
  editAgendAlertConfig: TemplateModalConfig<IContext, string, string> = null;
  @ViewChild('changeDatesAlertRef', { static: false }) changeDatesAlertRef;
  changeDatesModalRef = null;
  changeDatesAlertConfig: TemplateModalConfig<IContext, string, string> = null;

  @ViewChild('cantEditReconcAlertRef', { static: false }) cantEditReconcAlertRef;
  cantEditReconcModalRef = null;
  cantEditReconcAlertConfig: TemplateModalConfig<IContext, string, string> = null;

  agendDates = [];
  submittingForm = false;
  loadingModal = false;
  loading = false;

  // EDIT AGENDAMENTOS VARIABLES
  @ViewChildren(SuiSelectHolderComponent) suiSelectHolders: QueryList<SuiSelectHolderComponent>;
  sameForAll: boolean = false;
  contaOpts = [];
  editAgendDates: Array<editAgendDate> = [];
  editAgendListCol = [
    { key: 'icon', name: '', type: 'icon', sort: null, searchable: false, class: "col-centered icon-col" },
    { key: 'n_despesa', name: 'Nº', type: 'number', sort: null, searchable: false, class: "col-align-right col-perc-xxs" },
    { key: 'dt_desp', name: 'Data Despesa', type: 'date', sort: null, searchable: false, class: "col-perc-s" },
    { key: 'fornecedor', name: 'Fornecedor', type: 'text', sort: null, searchable: false, class: "col-align-left col-perc-m-small" },
    { key: 'form_pagam', name: 'Forma Pagamento', type: 'select', sort: null, searchable: false, class: "col-align-left col-perc-m-small" },
    { key: 'cod_conta_bancaria', name: 'Conta', type: 'select', sort: null, searchable: false, class: "col-align-left  col-perc-s-big" },
    { key: 'dt_pag_agend', name: 'Data Pag. Agend.', type: 'date', sort: null, searchable: false, class: "col-centered col-perc-s" },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: false, class: "col-align-right col-perc-xs" },
    { key: 'valor_por_liquidar', name: 'A liquidar', type: 'number', sort: null, searchable: false, class: "col-align-right col-perc-xs" },
    { key: 'dt_pag', name: 'Data Pagamento', type: 'date', sort: null, searchable: false, class: "col-centered col-perc-s" },
    { key: 'valor_pago', name: 'Pagar', type: 'number', sort: null, searchable: false, class: "col-align-right col-perc-xs" },
  ];

  cod_condominio = null;
  n_agendamento = null;
  fornecedorOpts = [];
  datesToChange: Array<number> = [];
  agendMovimentos = [];

  exercicio = null;
  registoAct = [];
  now = new Date();
  editAll: boolean = false;
  
  editAgendForm = new FormGroup({
    sameForAll: new FormControl(false),
    form_pagam: new FormControl('1', { validators: Validators.required }),
    cod_conta_bancaria: new FormControl(null, { validators: Validators.required })
  });

  constructor(public modalService: SuiModalService,
            public api: ApiService,
            public appConfig: AppConfigService,
            public route: ActivatedRoute,
            public toastr: ToastrService,
            public utils: UtilitiesService,
            public cdRef:ChangeDetectorRef,
            public userSession: UserSessionService,
            ) { }

  ngOnInit() {
    this.cod_condominio = this.route.snapshot.params.cod_condominio;

    this.n_agendamento = this.route.snapshot.params.n_agendamento;
  }

  ngAfterViewInit() {
    this.editAgendAlertConfig = new TemplateModalConfig<IContext, string, string>(this.editAgendAlertRef);
    this.editAgendAlertConfig.isClosable = false;
    this.editAgendAlertConfig.closeResult = "closed";
    this.editAgendAlertConfig.size = 'large';
    this.editAgendAlertConfig.transition = 'fade up';
    this.editAgendAlertConfig.transitionDuration = 400;

    this.changeDatesAlertConfig = new TemplateModalConfig<IContext, string, string>(this.changeDatesAlertRef);
    this.changeDatesAlertConfig.closeResult = "closed";
    this.changeDatesAlertConfig.size = 'small';
    this.changeDatesAlertConfig.transition = 'fade up';
    this.changeDatesAlertConfig.transitionDuration = 400;

    this.cantEditReconcAlertConfig = new TemplateModalConfig<IContext, string, string>(this.cantEditReconcAlertRef);
    this.cantEditReconcAlertConfig.isClosable = false;
    this.cantEditReconcAlertConfig.size = 'small';
    this.cantEditReconcAlertConfig.transition = 'fade up';
    this.cantEditReconcAlertConfig.transitionDuration = 400;

    this.editAgendForm.controls['form_pagam'].valueChanges.subscribe(value => {
      this.updateEachAgendFormPagam();
      this.editAgendContaChanged();
    });
    this.editAgendForm.controls['cod_conta_bancaria'].valueChanges.subscribe(value => {
      this.updateEachAgendConta();
    });
  }

  ngAfterViewChecked() { 
    this.cdRef.detectChanges(); 
  }

  editDespDate(row: editAgendDate): void {
    let name = 'dt_desp-' + row.id;
    let el: HTMLElement = (document.getElementsByClassName(name)[0] as HTMLElement);
    setTimeout(() => el.focus(), 0);
    el.addEventListener('blur', (event) => {
      if (row.dt_desp) row.edittingDtDesp = false;
    });
  }

  changedDespDate(row: editAgendDate): void {
    let name = 'dt_desp-' + row.id;
    let a: HTMLElement = (document.getElementsByClassName(name)[0] as HTMLElement);
    if ((document.activeElement == a) && row.dt_desp) {
      let prevState = this.agendDates.find(el => el.id == row.id);
      if (!this.utils.sameDateDay(prevState.dt_desp, row.dt_desp)) {
        row.dateChanged = true;
      } else {
        row.dateChanged = false;
      }
      this.checkSort(row);
      row.edittingDtDesp = false;
    }
  }

  checkSort(row: editAgendDate) {
    let index = this.editAgendDates.findIndex(el => el.id == row.id);
    this.sortList();
    let newIndex = this.editAgendDates.findIndex(el => el.id == row.id);
    if (index != newIndex) this.setFeedback(row);
  }

  setFeedback(row: editAgendDate) {
    this.editAgendDates.forEach(el => el.changeFeedback = false);
    row.changeFeedback = true;
    row.expanded = false;
    setTimeout(() => row.changeFeedback = false, 1500);
  }

  fornecedorTimer = null;
  fornecedoresLookup = async (query: string, initial?) => {
    if (initial != undefined) {
        return new Promise(resolve => { return resolve({name: this.cod_fornecedor.name, value: {name: this.cod_fornecedor.value.nome, value: this.cod_fornecedor.value.cod }})});
    }

    clearTimeout(this.fornecedorTimer);
    return new Promise(resolve => {
        if (query) {
          this.fornecedorTimer = setTimeout(() => {
            // FORNECEDOR LIST
            this.api.getAllFornecedores(query).subscribe(res => {
              if (res.success) {
                return resolve(res.data.map(el => { return { name: el.nome, value: {name: el.nome, value: el.cod} }; }));
              } else {
                return resolve([]);
              }
            });
          }, 400);
        } else {
          this.api.getAllFornecedores('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: {name: el.nome, value: el.cod} }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  restore(fieldToRestore: string, row: editAgendDate) {
    let prevState = this.agendDates.find(el => el.id == row.id);
    switch (fieldToRestore) {
      case 'dt_desp':
        if (prevState) {
          row.dt_desp = prevState.dt_desp;
          row.dateChanged = false;
          this.sortList();
        }
        break;
      case 'fornecedor':
        if (prevState) {
          row.fornecedor = prevState.fornecedor;
          row.fornecedorChanged = false;
        }
        break;
    
      default:
        break;
    }

  }

  getUltimaReconciliacao(): Promise<boolean> {
    return new Promise((resolve) => {
      let req = [];
      this.contaOptsOrig.forEach(conta => {
        req.push(this.api.getUltimaReconciliacao(conta.value));
      });
      forkJoin(req).subscribe(resArr => {
        if (!resArr.find(el => !el.hasOwnProperty('success'))) {
          resArr.forEach((res:reconciliacaoBancariaBDApi, index) => {
            if (res.success) {
              this.contaOptsOrig[index]['id_reconciliado'] = res.data.id;
              this.contaOptsOrig[index]['data_reconciliado'] = res.data.data_reconciliacao? this.utils.getDate(res.data.data_reconciliacao) : null;
            } else {
              this.contaOptsOrig[index]['id_reconciliado'] = null;
              this.contaOptsOrig[index]['data_reconciliado'] = null;
            }
          });

          this.editAgendDates.forEach(desp => {
            desp.movimentos.forEach(mov => {
              let conta = this.contaOptsOrig.find(el => el.value === mov.cod_conta_bancaria);
                if (conta 
                      && conta.name !== 'CAIXA' 
                      && conta.id_reconciliado && conta.data_reconciliado 
                      && ( this.utils.compareDayDates(mov.data, conta.data_reconciliado) < 0 
                          || 
                          ( this.utils.compareDayDates(mov.data, conta.data_reconciliado) == 0 
                            && 
                            mov.id <= conta.id_reconciliado))
                      ) {
                   mov.data_reconciliacao = conta.data_reconciliado;
                }
            });
          });
          resolve(true);
        } else {
          this.utils.apiErrorMsg(resArr);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  //Call this function after parent comp has cod_condominio set 
  getContas(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let cod = (this.cod_condominio.hasOwnProperty('cod')) ? this.cod_condominio.cod : this.cod_condominio.value.cod;
      this.api.getCondContasDetails(cod).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.contaOptsOrig =  res.data.map(el => {
            return { name: el.banco , value: el.cod, conta_principal: el.conta_principal, saldo: Number(el.saldo), details: el };
          });
          resolve(true);
        } else {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title, { timeOut: 4000 });
          reject(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title, { timeOut: 4000 });
        reject(false);
      });
    });
  }

  movDataSelected(row: editAgendDate, mov: editMovimento=null) {
    this.checkSort(row);
    if (!mov) {
      let conta = this.contaOptsOrig.find(conta => conta.value === row.cod_conta_bancaria);
      if (!conta || !conta.data_reconciliacao) return;
      if (row.dt_pag && this.utils.compareDayDates(row.dt_pag, conta.data_reconciliacao) <= 0) {
        setTimeout(() =>{ row.dt_pag = null;this.checkSort(row);});
        this.toastr.error('A conta bancária tem reconciliação bancária efetuada no dia ' + this.utils.getFormatedDate(conta.data_reconciliacao) + '. Por favor escolha uma data de pagamento posterior.','Alerta');
      }
    } else {
      let conta = this.contaOptsOrig.find(conta => conta.value === mov.cod_conta_bancaria);
      if (!conta || !conta.data_reconciliacao) return;
      if (mov.data && this.utils.compareDayDates(mov.data, conta.data_reconciliacao) <= 0) {
        setTimeout(() => {mov.data = null;this.checkSort(row);});
        this.toastr.error('A conta bancária tem reconciliação bancária efetuada no dia ' + this.utils.getFormatedDate(conta.data_reconciliacao) + '. Por favor escolha uma data de pagamento posterior.','Alerta');
      }
    }
  }

  sortList() {
    this.editAgendDates.forEach(el => {
      el.movimentos.sort((a,b) => {
        if (!a.data || !b.data) {
          if (!a.data && !b.data) return 0;
          if (!a.data) return -1;
          return 1;
        }
        return b.data.getTime() - a.data.getTime();
      });
    });

    this.editAgendDates.sort((a, b) => {
      let A_pagField = a.aberta? a.dt_pag : (a.movimentos.length ? a.movimentos[0].data : null);
      let B_pagField = b.aberta? b.dt_pag : (b.movimentos.length ? b.movimentos[0].data : null);
      if (A_pagField || B_pagField) {
        if (A_pagField && B_pagField) {
          let ret = B_pagField.getTime() - A_pagField.getTime();
          return ret != 0? ret : b.dt_desp.getTime() - a.dt_desp.getTime();
        }
        if (A_pagField) return 1;
        return -1;
      }
      return b.dt_desp.getTime() - a.dt_desp.getTime();
    });
  }

  contasNameChanged = false;
  async openEditAgendamentos(agendDates:Array<any>) {
    return new Promise(async (resolve) => {
      this.showTable = true;
      this.loading = false;

    if (!this.contaOptsOrig) await this.getContas();

    if (!this.contasNameChanged) {
      this.contaOptsOrig.filter(el => el.name.indexOf('CAIXA') == -1 && el.name.indexOf('CX VERTIS') == -1).forEach(conta => {
        if (conta.conta_principal == '1') {
          conta.name += ' (Principal)';
        }
      });
      this.contasNameChanged = true;
    }
    
    this.contaOpts = JSON.parse(JSON.stringify(this.contaOptsOrig));


    this.editAgendForm.patchValue({
      form_pagam: '1'
    });

    this.agendDates = agendDates;

    this.editAgendDates = this.agendDates.map((el,i) => {
      let form_pagam = el.aberta? el.form_pagam_agendamento : el.form_pagam;
      let cod_conta_bancaria = el.aberta? (el.form_pagam_agendamento? el.cod_conta_bancaria_agendamento : null) : el.cod_conta_bancaria;
      let movimentos:Array<editMovimento> = el.movimentos.map(mov =>  {
        let contaOpts = [];
        let form_pagam = null;
        if (mov.banco === 'CAIXA' || mov.banco.indexOf('CX VERTIS') !== -1) {
          form_pagam = '1';
          contaOpts = this.contaOptsOrig.filter(c => (c.name === 'CAIXA' || c.name.indexOf('CX VERTIS') !== -1));
        } else if (mov.banco.indexOf('CX ADM') !== -1 || mov.banco.indexOf('CAIXA ADM') !== -1) {
          form_pagam = '6';
          contaOpts = this.contaOptsOrig.filter(c => (c.name.indexOf('CX ADM') !== -1 || c.name.indexOf('CAIXA ADM') !== -1))
        } else {
          form_pagam = '2';
          contaOpts = this.contaOptsOrig.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1));
        }
        return {
          id: mov.id,
          cod_conta_bancaria: mov.nid_conta,
          contaOpts: contaOpts,
          data: this.utils.getDate(mov.dt_mov),
          data_reconciliacao: this.utils.getDate(mov.data_reconciliacao),
          form_pagam: form_pagam,
          valor: Number(mov.valor),
          id_caixa_vertis: mov.id_caixa_vertis,
        };
      });
      let row = {
        n_despesa: el.n_despesa,
        checked: false,
        id: el.id, 
        aberta: el.aberta,
        changeFeedback: false,
        contaOpts: [], 
        cod_conta_bancaria: cod_conta_bancaria, 
        expanded: false,
        dateChanged: false,
        dt_pag_agendamento: form_pagam == '1'? el.dt_pag_agendamento : null, 
        dt_pag: null, 
        dt_desp: el.dt_desp, 
        fornecedor: el.fornecedor, 
        fornecedorChanged: false,
        form_pagam: form_pagam, 
        movimentos: movimentos,
        valor: el.valor,
        valor_pago: 0,
        valor_por_liquidar: Math.round((Number(el.valor) - Number(el.valor_liquidado)) * 100) / 100,
        valor_liquidado: el.valor_liquidado,
        reconciliada: true,
        edittingDtDesp: false,
        obj: null,
      };
      return row;
    });

    this.editAgendDates.forEach(el => this.rowPaymentChanged(el, true));

    this.sortList();

    let res = await this.getUltimaReconciliacao();
    if (!res) {
      this.editAgendDates = [];
      this.sameForAll = false;
      this.submittingForm = false;
      resolve(false);
    }

    this.editAgendModalRef = this.modalService.open(this.editAgendAlertConfig)
      .onApprove(() => {
        this.editAgendDates = [];
        this.sameForAll = false;
        this.submittingForm = false;
        this.editAll = false;
        
        resolve(true);
      })
      .onDeny(() => { 
        this.editAgendDates = [];
        this.sameForAll = false;
        this.submittingForm = false;
        this.editAll = false;
        resolve(false);
      });
    });
  }

  collapse(row: editAgendDate): void {
    if (this.isCollapsible(row)) {
      row.expanded = !row.expanded;
    }
  }

  isCollapsible(row: editAgendDate): boolean {
    return row.movimentos.length > 0;
  }

  isCaixa(row: editAgendDate): boolean {
    if (!row.cod_conta_bancaria) return false;
    let optSelected = row.contaOpts.find(el => el.value == row.cod_conta_bancaria);
    if (!optSelected) return false;
    return (optSelected.name.indexOf('CAIXA') !== -1 || optSelected.name.indexOf('CX VERTIS') !== -1);
  }

  isEditable(element: (editAgendDate|editMovimento)): boolean {
    //is editAgendDate
    if ('aberta' in element) {
      return element.aberta || this.editAll;
    } else {
      //is editMovimento
      return this.editAll && !element.data_reconciliacao && !element.id_caixa_vertis;
    }
  }
  getMovsValue(row: editAgendDate): number {
    return row.movimentos.reduce((acc, mov) => acc + parseFloat(mov.valor), 0) + (row.aberta && this.isEmptyValorPago(row.valor_pago)? row.valor_pago : 0);
  }
  isEmptyValorPago(valor_pago: number) {
    return (valor_pago == null || valor_pago.toString() == "" || valor_pago == 0);
  }
  
  isValidRow(key:string, row:editAgendDate): boolean {
    switch (key) {
      case 'desp_val':
        return Number(row.valor) > 0 && Number(row.valor) >= row.valor_liquidado && this.getMovsValue(row) <= Number(row.valor);
      case 'payment':
        //Both null or both filled
        return (row.dt_pag == null) === this.isEmptyValorPago(row.valor_pago);
      case 'payment_val':
        //Both null or both filled
        return row.valor_pago > 0? Number(row.valor_pago) <= row.valor_por_liquidar : this.isEmptyValorPago(row.valor_pago);
      case 'payment_method':
        //Both null or both filled
        return (row.form_pagam == null) === (row.cod_conta_bancaria == null) && (row.dt_pag == null || row.form_pagam != null);
      default:
        return false
    }
  }

  isValidMovs(el: editAgendDate): boolean {
    var BreakException = {};
    try {
      el.movimentos.forEach(mov => {
        if (!this.isValidMov('method',mov)) throw BreakException;
        if (!this.isValidMov('data',mov)) throw BreakException;
        if (!this.isValidMov('valor',mov)) throw BreakException;
      });
    } catch(e) {
      if (e !== BreakException) throw e;
      return false;
    }
    return true;
  }

  isValidMov(key:'method'|'data'|'valor', mov:editMovimento):boolean {
    switch (key) {
      case 'method':
        return mov.form_pagam != null && mov.cod_conta_bancaria != null;
      case 'data':
        return mov.data != null;
      case 'valor':
        return mov.valor != null || parseFloat(mov.valor) > 0;
      default:
        return false;
    }
  }

  isInputValid(confirmedDates: boolean): boolean {
    if (this.sameForAll && !this.editAgendForm.valid) return false;

    var BreakException = {};
    try {
      this.editAgendDates.forEach(el => {

        //If its not aberta, row entry is a copy of last mov
        if (el.edittingDtDesp) {
          this.toastr.error('Por favor confirme os campos que foram alterados.', 'Campos Inválidos', { timeOut: 4000 });
          throw BreakException;
        } 
        if (el.dt_desp === null) throw BreakException;
        if (el.fornecedor === null) throw BreakException;
        if (!this.isValidMovs(el)) throw BreakException;

        if (!this.isValidRow('desp_val', el)) {
          if (Number(el.valor) > 0 && Number(el.valor) >= el.valor_liquidado && this.getMovsValue(el) > Number(el.valor)) {
            this.toastr.error('O valor total dos movimentos é superior ao valor da despesa.', 'Campos Inválidos', { timeOut: 4000 });
          }
          throw BreakException;
        }

        if (el.aberta) {
          if (!this.isValidRow('payment', el)) throw BreakException;
          if (!this.isValidRow('payment_val', el)) throw BreakException;
          if (!this.isValidRow('payment_method', el)) throw BreakException;
        }
        
      });
    } catch(e) {
      if (e !== BreakException) throw e;
      return false;
    }

    if (!confirmedDates) {
      this.datesToChange = [];
      this.editAgendDates.forEach(el => {
        if (el.dt_pag && this.utils.compareDayDates(el.dt_desp,el.dt_pag) > 0) {
          this.datesToChange.push(el.n_despesa);
          return;
        }
        let minDate = el.movimentos.reduce((min, mov) => {
          if (min === null) return mov.data;

          if (this.utils.compareDayDates(min, mov.data) > 0) min = mov.data;

          return min;
        },null as Date);
        if (minDate && this.utils.compareDayDates(el.dt_desp, minDate) > 0) {
          this.datesToChange.push(el.n_despesa);
        }
      });
      if (this.datesToChange.length) {
        this.changeDatesModalRef = this.modalService
        .open(this.changeDatesAlertConfig)
        .onApprove(() => {
          this.datesToChange = [];
          this.loadingModal = false;
          this.loading = false;
        })
        .onDeny(() => {
          this.datesToChange = [];
          this.loadingModal = false;
          this.loading = false;
        });
        return false;
      } 
    }

    return true;
  }

  agendMethodChanged(prevState, currState: editAgendDate): boolean {
    if (currState.aberta) return (prevState.form_pagam_agendamento != currState.form_pagam) || (prevState.cod_conta_bancaria_agendamento != currState.cod_conta_bancaria);
   
    for (let i = 0; i < currState.movimentos.length; i++) {
      if ((prevState.form_pagam != currState.form_pagam) || (prevState.cod_conta_bancaria != currState.cod_conta_bancaria)) return true;
    }

    return false;
  }

  exists(row: editAgendDate): boolean {
    let prevState = this.agendDates.find(el => el.id == row.id);
    return prevState.dt_desp ? prevState.dt_desp.getTime() <= this.now.getTime() : false;
  }

  async formSubmitted(confirmedDates= false) {

    this.submittingForm = true;
    if (!this.isInputValid(confirmedDates)) {
      this.loading = false;
      this.loadingModal = false;
      setTimeout(() => this.submittingForm = false, 4000);
      return;
    }
    this.loading = true;
    if (confirmedDates) this.loadingModal = true;

    this.exercicio = this.agendDates[0].dt_desp.getFullYear();

    // REGISTO DE ACTIVIDADES ---------------------------------------
    this.editAgendDates.forEach(el => {

      let despPrevState = this.agendDates.find(prev => prev.id == el.id);
      let registoAct = JSON.parse(despPrevState.obj);
      let newRegistoAct = [];

      let isPaying:boolean = !this.isEmptyValorPago(el.valor_pago) && el.dt_pag != null;

      if (el.valor != despPrevState.valor || !this.utils.sameDateDay(el.dt_desp, despPrevState.dt_desp)) {
        let activity = {
          msg: 'Despesa actualizada. Data de lançamento: ' + this.utils.getFormatedDate(el.dt_desp) + ', Conta: ' + this.getContaName(el) + ', Valor: ' + Number(el.valor).toFixed(2) + ' €.',
          activity: 'Actualizado por ',
          user: this.userSession.getUserFullName(),
          date: new Date(),
        };
        newRegistoAct = [activity].concat(newRegistoAct);
        registoAct = [activity].concat(registoAct);
      }
      if (el.fornecedor.value != despPrevState.fornecedor.value) {
        let activity = {
          msg: 'Despesa actualizada. Fornecedor: ' + el.fornecedor.name + '.',
          activity: 'Actualizado por ',
          user: this.userSession.getUserFullName(),
          date: new Date(),
        };
        newRegistoAct = [activity].concat(newRegistoAct);
        registoAct = [activity].concat(registoAct);
      }
      
      if (el.aberta) {

        newRegistoAct = this.getRegAtividadeAgendamentoMethod(newRegistoAct, el, despPrevState);
        registoAct = this.getRegAtividadeAgendamentoMethod(registoAct, el, despPrevState);
        
        if (isPaying) {
          let activity = {
            msg: (el.valor_pago != Number(el.valor)) ? 'Pagamento parcial efectuado: '  + Number(el.valor_pago).toFixed(2) + ' €, ': 'Pagamento efectuado: '  + Number(el.valor).toFixed(2) + ' €, ',
            activity: 'Actualizado por ',
            user: this.userSession.getUserFullName(),
            date: new Date(),
          };
          activity.msg += 'Conta: ' + this.getContaName(el) + '.';
          registoAct = [activity].concat(registoAct);
        }
      }


      newRegistoAct = this.getRegAtividadeMovimentos(newRegistoAct, el, despPrevState);
      registoAct = this.getRegAtividadeMovimentos(registoAct, el, despPrevState);

      el['obj'] = JSON.stringify(registoAct);
      this.registoAct.push(registoAct);

        // ----------------------------------------------------------------
    });

    let agendDatesAPICall = this.editAgendDates.reduce((acc, el) => {
      //Just clone variables that are shown in view
      let clone = {... el, 
        'dt_pag_agendamento': el.dt_pag_agendamento? this.utils.getFormatedDate(el.dt_pag_agendamento) : null,
        'dt_pag': el.dt_pag? this.utils.getFormatedDate(el.dt_pag) : null,
        'dt_desp': el.dt_desp? this.utils.getFormatedDate(el.dt_desp) : null,
        'movimentos': [],
      };
      el.movimentos.forEach(mov => {
        clone.movimentos.push({... mov, 
          data: mov.data? this.utils.getFormatedDate(mov.data) : null 
        });
      })
      acc.push(clone);
      return acc;
    }, [] as Array<editAgendDate>)
    

    this.api.saveAndPayDespesaAgendamentoDetails(agendDatesAPICall, this.cod_condominio, this.n_agendamento).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.editAgendModalRef.approve();
      } else {
        this.utils.apiErrorMsg(res);
      }
      if (confirmedDates) {
        this.changeDatesModalRef.approve();
      }
      this.submittingForm = false;
      this.loadingModal = false;
      this.loading = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.submittingForm = false;
      this.loadingModal = false;
      this.loading = false;
      if (confirmedDates) {
        this.changeDatesModalRef.approve();
      }
    })
  }

  isAgendado(el: editAgendDate): boolean {
    return !!el.form_pagam && !!el.cod_conta_bancaria;
  }

  wasAgendado(despPrevState): boolean {
    return !!despPrevState.form_pagam_agendamento && !!despPrevState.cod_conta_bancaria_agendamento;
  }
  
  getRegAtividadeAgendamentoMethod(registoAct:Array<any>, el: editAgendDate, despPrevState) {
    let contaCaixa = this.contaOpts.find(el => el.name === 'CAIXA');
    let autoPay = el.form_pagam == '1' && contaCaixa && el.cod_conta_bancaria === contaCaixa.value && !!el.dt_pag_agendamento;
    let isAgendado = this.isAgendado(el);
    let dataChanged = (el.form_pagam != despPrevState.form_pagam_agendamento 
      || el.cod_conta_bancaria != despPrevState.cod_conta_bancaria_agendamento) 
      || (el.cod_conta_bancaria != despPrevState.cod_conta_bancaria_agendamento)
      || (el.dt_pag_agendamento != despPrevState.dt_pag_agendamento);


    // Se os dados alteraram mas está agendado
    if (dataChanged) {
      if (isAgendado) {
        let activity = {
          msg: "Agendamento de pagamento atualizado. Forma Pagamento: " + this.getFormaPagName(el) + ", Conta: " + this.getContaName(el) + (el.dt_pag_agendamento? ', Data: ' + this.utils.getFormatedDate(el.dt_pag_agendamento) : '') + (autoPay ? ' (Pag. Automático).' : ''),
          activity: 'Actualizado por ',
          user: this.userSession.getUserFullName(),
          date: new Date(),
        };
        registoAct = [activity].concat(registoAct);
      } else {
        //Se os dados alteraram mas não está agendado
        let activity = {
          msg: "Agendamento de pagamento cancelado.",
          activity: 'Actualizado por ',
          user: this.userSession.getUserFullName(),
          date: new Date(),
        };
        registoAct = [activity].concat(registoAct);
      }
    }
    return registoAct;
  }
  
  getRegAtividadeMovimentos(registoAct:Array<any>, el: editAgendDate, despPrevState) {
    el.movimentos.forEach(mov => {
      let prevMov = despPrevState.movimentos.find(prevMov => prevMov.id === mov.id);
      let newConta = this.contaOptsOrig.find(conta => conta.value === mov.cod_conta_bancaria);
      if ((newConta.name !== prevMov.banco) 
          || (mov.cod_conta_bancaria !== prevMov.nid_conta) 
          || (this.utils.compareDayDates(mov.data, this.utils.getDate(prevMov.dt_mov)) !== 0)
          || (Number(mov.valor) !== prevMov.valor)) {
        let activity = {
          msg: 'Movimento actualizado. Data de pagamento: ' + this.utils.getFormatedDate(mov.data) + ', Conta: ' + newConta.name + ', Valor: ' + mov.valor + ' €.',
          activity: 'Actualizado por ',
          user: this.userSession.getUserFullName(),
          date: new Date(),
        };
        registoAct = [activity].concat(registoAct);
      }
    });
    return registoAct;
  }

  registoActividades(cod_condominio, nome_condominio, n_agendamento, nome_fornecedor) {
        let titulo = 'Agendamento Actualizado';

        let descricao = `Condomínio: ${cod_condominio} - ${nome_condominio}, Nº agendamento:  ${n_agendamento}, Fornecedor: ${nome_fornecedor}.`;
        this.api.saveRegistoActividade(cod_condominio, null, null, titulo, descricao).subscribe(res => {}, err => { });  
  }

  valorChanged(row: editAgendDate): void {
    row.valor_por_liquidar = Math.round((Number(row.valor) - row.valor_liquidado) * 100) / 100;
  }

  rowPaymentChanged(row: (editAgendDate|editMovimento), start=false): void {
    //Setting options
    if (row.form_pagam === null) {
      row.contaOpts = [];
    } else if (row.form_pagam === '1' || row.form_pagam === '6') {
      if (row.form_pagam === '1') {
        row.contaOpts = this.contaOptsOrig.filter(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      }
      if (row.form_pagam === '6') {
        row.contaOpts = this.contaOptsOrig.filter(el => (el.name.indexOf('CX ADM') !== -1 || el.name.indexOf('CAIXA ADM') !== -1));
      }
    } else {
      row.contaOpts = this.contaOptsOrig.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1));
    }

   
    if (row.contaOpts.length === 1) {
      row.cod_conta_bancaria = row.contaOpts[0].value;
    } else if (this.sameForAll) {
      row.cod_conta_bancaria = this.editAgendForm.get('cod_conta_bancaria').value;
    } else {
      if (start) return;
      let aux = row.contaOpts.find(el => (el.details.conta_principal === '1' && el.name !== 'CAIXA'));
      row.cod_conta_bancaria = aux? aux.value : null;
    } 
    this.showTable = false;
      setTimeout(_ => {this.showTable = true}, 0);
  }
  contaPaymentChanged = (row:(editAgendDate|editMovimento), newValue:string) => {
    row.cod_conta_bancaria = newValue;
  }

  showTable = true;
  sameForAllChecked(ev): void {
    if (ev.target.checked) {
      this.sameForAll = true;
      this.updateEachAgendFormPagam();
      this.updateEachAgendConta();
      this.showTable = false;
      setTimeout(_ => {this.showTable = true}, 0);
    } else {
      this.sameForAll = false;
    }
  }


  updateEachAgendFormPagam() {
    let valueToUpdate = this.editAgendForm.get('form_pagam').value;
    this.editAgendDates.filter(el => el.aberta).forEach((el,i) => {
      el.form_pagam = valueToUpdate;
      this.rowPaymentChanged(el);
    });
  }

  updateEachAgendConta() {
    let valueToUpdate = this.editAgendForm.get('cod_conta_bancaria').value;
    this.editAgendDates.filter(el => el.aberta).forEach((el,i) => {
      el.cod_conta_bancaria = valueToUpdate;
      this.movDataSelected(el);
    });
  }

  editAllDespesas():void {
    if (!this.editAll) {
      this.cantEditReconcModalRef = this.modalService
      .open(this.cantEditReconcAlertConfig)
      .onApprove(() => {
        this.editAll = true;
      })
      .onDeny(() => {
        this.editAll = true;
      });
    } else {
      this.editAll = false;
    }
  }

  editAgendContaChanged(): void {
    let form_pagam = this.editAgendForm.get('form_pagam').value;
    
    if (form_pagam === null) {
      this.contaOpts = [];
      return;
    } 

    if (form_pagam === '1' || form_pagam === '6') {
      if (form_pagam === '1') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      }

      if (form_pagam === '6') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name.indexOf('CX ADM') !== -1 || el.name.indexOf('CAIXA ADM') !== -1));
      }

      if (this.contaOpts.length === 1) {
        this.editAgendForm.patchValue({
          cod_conta_bancaria: this.contaOpts[0].value,
        });
      } else {
        this.editAgendForm.patchValue({
          cod_conta_bancaria: null,
        });
      }
    } else {
      this.contaOpts = this.contaOptsOrig.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1));
      let aux = this.contaOpts.find(el => (el.details.conta_principal === '1' && el.name !== 'CAIXA'));
      this.editAgendForm.patchValue({
        cod_conta_bancaria: (aux) ? aux.value : null,
      });
    }
  }

  /*REQUIRES PARAM IS CLOSED*/ 
  regexPrincipal = / \(Principal\)/ig;
  getContaName(param:(editAgendDate|editMovimento)):string {
    let cod_conta_bancaria = param.cod_conta_bancaria;
    let contaOpt = this.contaOptsOrig.find(el => el.value == cod_conta_bancaria);
    return contaOpt? contaOpt.name.replaceAll(this.regexPrincipal, '') : null;
  }

  /*REQUIRES PARAM IS CLOSED*/ 
  getFormaPagName(param:(editAgendDate|editMovimento)):string {
    let form_pagam = param.form_pagam;
    let payOpt = this.appConfig.pagamentoOtps.find(el => el.value == form_pagam);
    return payOpt? payOpt.name : null;
  }

  getDtPag(param: editAgendDate) {
    if (!param.movimentos.length) return null;
    return param.movimentos[0].data;
  }

  closeModal() {
    this.editAgendModalRef.deny();
  }

}
