import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { exportPDF, Group } from '@progress/kendo-drawing';


@Component({
  selector: 'app-comunicacao-administradores-pdf',
  templateUrl: './comunicacao-administradores-pdf.component.html',
  styleUrls: ['./comunicacao-administradores-pdf.component.scss']
})
export class ComunicacaoAdministradoresPdfComponent implements OnInit, OnDestroy {

  @Input() data;

  @ViewChild('pdfRef', { static: false }) pdfController;

  now = null;
  filename = 'default_filename';

  constructor(public appConfig: AppConfigService) {}

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  generatePdf() {
    this.now = new Date();

    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';
    this.pdfController.saveAs(this.filename + '.pdf');
  }

  getBase64() {
    return new Promise((resolve, reject) => {
      this.now = new Date();

      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');

        resolve(base64);
      }).catch(err => {
        reject(err);
      });
    });
  }

}
