import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { DeactivateGuardService } from './deactivate-guard.service';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { CondominiumsComponent } from './condominiums/condominiums.component';
import { CondominiumDetailsComponent } from './condominium-details/condominium-details.component';
import { EntitiesComponent } from './entities/entities.component';

import { ConfigurationsComponent } from './configurations/configurations.component';
import { ActivitiesComponent } from './activities/activities.component';
import { RegistoProcessosComponent } from './registo-processos/registo-processos.component';
import { OrcamentosComponent } from './orcamentos/orcamentos.component';
import { OrcamentoDetailsComponent } from './orcamento-details/orcamento-details.component';
import { LancamentosComponent } from './lancamentos/lancamentos.component';
import { FraccoesDetailsComponent } from './fraccoes-details/fraccoes-details.component';
import { ContactosDetailsComponent } from './contactos-details/contactos-details.component';
import { ContasDetailsComponent } from './contas-details/contas-details.component';
import { AssembleiasDetailsComponent } from './assembleias-details/assembleias-details.component';
import { AssembleiasAgendamentoComponent } from './assembleias-agendamento/assembleias-agendamento.component';
import { UtilizadoresComponent } from './utilizadores/utilizadores.component';
import { UtilizadorDetailsComponent } from './utilizador-details/utilizador-details.component';

import { FuncionariosComponent } from './funcionarios/funcionarios.component';

import { EntityDetailsComponent } from './entity-details/entity-details.component';
import { ProcessamentoComponent } from './processamento/processamento.component';
import { AvisoComponent } from './aviso/aviso.component';
import { DespesaV2Component } from './despesa-v2/despesa-v2.component';
import { ContasBancariasComponent } from './contas-bancarias/contas-bancarias.component';
import { MovimentosDetailsComponent } from './movimentos-details/movimentos-details.component';
import { ContasCorrentesComponent } from './contas-correntes/contas-correntes.component';

import { CreditosDetailsComponent } from './creditos-details/creditos-details.component';
import { ReceitasDetailsComponent } from './receitas-details/receitas-details.component';
import { RecibosDetailsComponent } from './recibos-details/recibos-details.component';

import { CaixaVertisComponent } from './caixa-vertis/caixa-vertis.component';
import { CorrespondenciaComponent } from './correspondencia/correspondencia.component';

import { AssembleiasComponent } from './assembleias/assembleias.component';
import { ContaCorrenteRelatorioComponent } from './conta-corrente-relatorio/conta-corrente-relatorio.component';

import { RelatorioExercicioComponent } from './relatorio-exercicio/relatorio-exercicio.component';
import { PropostasComponent } from './propostas/propostas.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: MainComponent, canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'condominios', pathMatch: 'full' },

      // HEADER MENU
      { path: 'configuracoes', component: ConfigurationsComponent},
      { path: 'configuracoes/:module', component: ConfigurationsComponent},

      { path: 'utilizadores', component: UtilizadoresComponent },
      { path: 'utilizadores/utilizador/:id', component: UtilizadorDetailsComponent },

      { path: 'funcionarios', component: FuncionariosComponent },

      { path: 'actividades', component: ActivitiesComponent },
      { path: 'registo-processos', component: RegistoProcessosComponent },

      { path: 'condominios', component: CondominiumsComponent },
      { path: 'condominios/condominio/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },

      { path: 'condominios/condominio/geral/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/zonas/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/fraccoes/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/saldosiniciais/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/contactos/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/assembleias/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/seguro/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/contas/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/documentos/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/observacoes/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      { path: 'condominios/condominio/entidades/:id', component: CondominiumDetailsComponent, canDeactivate: [DeactivateGuardService] },
      
      { path: 'entidades/proprietario', component: EntitiesComponent },
      { path: 'entidades/inquilino', component: EntitiesComponent },
      { path: 'entidades/fornecedor', component: EntitiesComponent },
      { path: 'entidades/procurador', component: EntitiesComponent },
      { path: 'entidades/entidade', component: EntitiesComponent },

      { path: 'entidades/proprietario/:id', component: EntityDetailsComponent },
      { path: 'entidades/inquilino/:id', component: EntityDetailsComponent },
      { path: 'entidades/fornecedor/:id', component: EntityDetailsComponent },
      { path: 'entidades/procurador/:id', component: EntityDetailsComponent },
      { path: 'entidades/entidade/:id', component: EntityDetailsComponent },

      { path: 'fraccoes/:cond_id/:id', component: FraccoesDetailsComponent },

      { path: 'fraccoes/geral/:cond_id/:id', component: FraccoesDetailsComponent },
      { path: 'fraccoes/contencioso/:cond_id/:id', component: FraccoesDetailsComponent },
      { path: 'fraccoes/quotas/:cond_id/:id', component: FraccoesDetailsComponent },
      { path: 'fraccoes/reg-proprietarios/:cond_id/:id', component: FraccoesDetailsComponent },
      { path: 'fraccoes/observacoes/:cond_id/:id', component: FraccoesDetailsComponent },

      { path: 'contactos/:cond_id/:id', component: ContactosDetailsComponent },
      { path: 'contas/:cond_id/:id', component: ContasDetailsComponent },

      { path: 'orcamentos', component: OrcamentosComponent },
      { path: 'orcamentos/orcamento/:id', component: OrcamentoDetailsComponent },

      { path: 'lancamentos', component: LancamentosComponent },
      { path: 'lancamentos/processamento', component: LancamentosComponent },
      { path: 'lancamentos/aviso', component: LancamentosComponent },
      { path: 'lancamentos/despesa', component: LancamentosComponent },
      { path: 'lancamentos/pag-quotas', component: LancamentosComponent },
      { path: 'lancamentos/creditos', component: LancamentosComponent },
      { path: 'lancamentos/receitas-extra', component: LancamentosComponent },
      { path: 'lancamentos/recibos', component: LancamentosComponent },
      { path: 'lancamentos/agendamentos', component: LancamentosComponent },

      { path: 'lancamentos/processamento/:id', component: ProcessamentoComponent },
      { path: 'lancamentos/aviso/:id', component: AvisoComponent },
      { path: 'lancamentos/despesa/:id', component: DespesaV2Component },
      { path: 'lancamentos/agendamento/:cod_condominio/:n_agendamento', component: DespesaV2Component, canDeactivate: [AuthGuardService]  },

      { path: 'lancamentos/credito/:id', component: CreditosDetailsComponent },
      { path: 'lancamentos/receita/:id', component: ReceitasDetailsComponent },
      { path: 'lancamentos/recibo/:id', component: RecibosDetailsComponent },

      { path: 'contascorrentes', component: ContasCorrentesComponent },
      { path: 'contascorrentes/fraccoes', component: ContasCorrentesComponent },
      { path: 'contascorrentes/condominos', component: ContasCorrentesComponent },
      { path: 'contascorrentes/fornecedores', component: ContasCorrentesComponent },
      { path: 'contascorrentes/relatorio-detalhado', component: ContaCorrenteRelatorioComponent },
      { path: 'contascorrentes/relatorio-resumido', component: ContaCorrenteRelatorioComponent },

      { path: 'relatorios', component: RelatorioExercicioComponent },

      { path: 'caixavertis', component: CaixaVertisComponent },
      { path: 'caixavertis/registo-diario', component: CaixaVertisComponent },
      { path: 'caixavertis/relatorio', component: CaixaVertisComponent },
      { path: 'caixavertis/entregas-faturas', component: CaixaVertisComponent },
      
      { path: 'correspondencia', component: CorrespondenciaComponent, canDeactivate: [AuthGuardService] },
      { path: 'correspondencia/cartascobranca', component: CorrespondenciaComponent, canDeactivate: [AuthGuardService] },
      // { path: 'correspondencia/circulares', component: CorrespondenciaComponent },
      { path: 'correspondencia/comunicacoes', component: CorrespondenciaComponent, canDeactivate: [AuthGuardService]  },
      { path: 'correspondencia/registo-comunicacoes', component: CorrespondenciaComponent, canDeactivate: [AuthGuardService] },
      { path: 'correspondencia/custos-comunicacoes', component: CorrespondenciaComponent, canDeactivate: [AuthGuardService] },

      { path: 'assembleias', component: AssembleiasComponent },
      { path: 'assembleias/assembleias', component: AssembleiasComponent },
      { path: 'assembleias/mapa-reunioes', component: AssembleiasComponent },
      { path: 'assembleias/agendamentos', component: AssembleiasComponent },
      { path: 'assembleias/assembleias/:id', component: AssembleiasDetailsComponent, canDeactivate: [AuthGuardService] },
      { path: 'assembleias/agendamento-assembleias/:id', component: AssembleiasAgendamentoComponent },

      { path: 'movimentosbancarios', component: ContasBancariasComponent },
      { path: 'movimentosbancarios/:id', component: MovimentosDetailsComponent },

      { path: 'propostas', component: PropostasComponent , canActivate: [AuthGuardService] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
