import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { AppConfigService } from '../app-config.service';
import { EntitiesToNotice, NoticeInfo, CommunicationsOrigin, Texto, RegistoComunicacaoSendAndSave, saveEntityWithoutDebt } from '../business-model-interfaces/comunicacoes';
import { CommunicationsService } from '../business-logic-services/communications.service';
import { EmailCartaComunicacaoModalComponent, EntityEmailCartaInput } from '../email-carta-comunicacao-modal/email-carta-comunicacao-modal.component';
import { UtilitiesService } from '../utilities.service';
import { Anexo, Email } from '../business-model-interfaces/email';
import { UserSessionService } from '../user-session.service';
import { ToastrService } from 'ngx-toastr';
import { DespesasCttModalComponent, EntityDespesasCTTModalInput } from '../despesas-ctt-modal/despesas-ctt-modal.component';
import { CartaContent } from '../business-model-interfaces/carta';
import { MessageService } from '../message.service';
import { AnexoCarta, CartaComunicacaoComponent } from '../carta-comunicacao/carta-comunicacao.component';
import { ReconciliacoesBancariasService } from '../business-logic-services/reconciliacoes-bancarias.service';
import { MapaQuotasPdfComponent } from '../mapa-quotas-pdf/mapa-quotas-pdf.component';
import { BusinessLogicService } from '../business-logic.service';
import { resolve } from 'url';
interface IContext {
  data:string;
}

interface CommunicationToSend extends EntitiesToNotice {
  bodyMsg:string,
  subjectMsg:string,
}

@Component({
  selector: 'app-notice-payments',
  templateUrl: './notice-payments.component.html',
  styleUrls: ['./notice-payments.component.scss']
})
export class NoticePaymentsComponent implements OnInit {

  listCol = [
    {name: 'Fração', class: 'col-align-left'},
    {name: 'Condómino', class: 'col-align-left'},
    {name: 'Vencimento Anterior', class: 'col-align-right'},
    {name: 'Dívida Anterior', class: 'col-align-right'},
    {name: 'Vencimento Corrente', class: 'col-align-right'},
    {name: 'Dívida Corrente', class: 'col-align-right'},
    {name: 'Vencimento Extra', class: 'col-align-right'},
    {name: 'Dívida Extra', class: 'col-align-right'},
    {name: 'Última', class: 'col-align-right'},
    {name: 'Comunicação', class: 'col-align-left'},
  ]

  origin: CommunicationsOrigin = 'NOTICE_AVISOS';

  loadingModal = false;

  @ViewChild('noticePaymentsAlertRef', { static: false }) noticePaymentsAlertRef;
  noticePaymentsModalRef = null;
  noticePaymentsAlertConfig = null;
  
  id_reconciliacao = null;
  list:Array<EntitiesToNotice> = [];
  textosOpts:Array<Texto> = [];


  @ViewChild('mapaQuotasPDF', { static: false }) mapaQuotasPDF: MapaQuotasPdfComponent;


  constructor(
    public communications: CommunicationsService,
    public appConfig: AppConfigService,
    public utils: UtilitiesService,
    public cdRef: ChangeDetectorRef,
    public userSession: UserSessionService,
    public businessLogic: BusinessLogicService,
    public message: MessageService,
    public reconciliacoesBancarias: ReconciliacoesBancariasService,
    public modalService: SuiModalService,
    public toastr: ToastrService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.noticePaymentsAlertConfig = new TemplateModalConfig<IContext, string, string>(this.noticePaymentsAlertRef);
    this.noticePaymentsAlertConfig.closeResult = "closed";
    this.noticePaymentsAlertConfig.size = 'small';
    this.noticePaymentsAlertConfig.isClosable = false;
    this.noticePaymentsAlertConfig.transition = 'fade';
    this.noticePaymentsAlertConfig.transitionDuration = 250;
  }

  noticeInfo:NoticeInfo = {
    data: null,
    data_ultima_comunicacao: null,
    periodicidade: null,
    nome_condominio: null,
    morada_condominio: null,
    cod_condominio: null,
    nib_condominio: null,
    nif_condominio: null,
  }
  defaultMapaQuotasText: {
    subject,
    body
  }
  open(cod_condominio, id_reconciliacao): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.loadingModal = true;
      this.sendingCommunications = false;
      
      this.noticePaymentsModalRef = this.modalService
      .open(this.noticePaymentsAlertConfig)
      .onApprove(() => { resolve(true) })
      .onDeny(() => { resolve(false) });
      this.textosOpts = await this.communications.getTextos();
      let res = await this.communications.getEntitiesNoticeSuggestion(cod_condominio, id_reconciliacao);
      if (!res) {
        this.noticePaymentsModalRef.deny();
        this.loadingModal = false;
        return;
      }

      this.id_reconciliacao = id_reconciliacao;

      this.list = res.list;
      this.noticeInfo = res.noticeInfo;
      this.defaultMapaQuotasText = res.defaultMapaQuotasText;
      this.loadingModal = false;
    });
  }

  @ViewChild('despesasCttModal', { static: false }) despesasCttModal: DespesasCttModalComponent;
  sendingCommunications = false;
  cartaAnexos: Array<AnexoCarta> = [];
  async notice() {
    this.sendingCommunications = true;
    let condominosWithoutDebt:Array<saveEntityWithoutDebt> = [];
    let communicationsToSend = this.list.map(el => {
      let bodyMsg = null;
      let assunto = null;

      if (el.notice_method) {
        let texto = el.notice_method ? this.textosOpts.find(com => com.id === el.notice_method) : null;
        let entityParam:{ nib: any; nif: any; nome: any; avisos: Array<{ divida: any; dt_emissao: any; descricao: any; dt_vencimento: any; fraccao: any; n_aviso: any; }> } = {
          nib: this.noticeInfo.nib_condominio,
          nif: this.noticeInfo.nif_condominio,
          nome: el.nome_pagador,
          avisos: el.avisos
        }
        assunto = texto.assunto;
        bodyMsg = this.utils.getAvisosBody(texto.text_inicial, texto.texto_final, entityParam, { reconciliation_date: this.noticeInfo.data});
      } else {
        assunto = this.defaultMapaQuotasText.subject;
        bodyMsg = this.defaultMapaQuotasText.body;
        if (el.addNoticePaymentRecord) {
          condominosWithoutDebt.push({
            cod: el.cod_pagador,
            id_fracao: el.id_fracao,
            data_divida: this.utils.getFormatedDate(this.noticeInfo.data),
          });
        }
      }
      
      let aux: CommunicationToSend = {
        ...el,
        bodyMsg: bodyMsg,
        subjectMsg: assunto,
      };
      return aux;
    });
    
    this.toastr.info(this.appConfig.infMsg.exportingPDFs.msg, this.appConfig.infMsg.exportingPDFs.title);
    let base64Obj = await this.getAnexosBase64();
    if (!base64Obj) {
      this.sendingCommunications = false;
      return;
    }

    let mq_filename = this.utils.getFileNameFormatted('Mapa Quotas ' + this.utils.getFormatedDate(this.noticeInfo.data) + ' ' + this.noticeInfo.nome_condominio) + '.pdf';
    let cc_filename = this.utils.getFileNameFormatted('Conta Corrente ' + this.utils.getFormatedDate(this.noticeInfo.data) + ' ' + this.noticeInfo.nome_condominio) + '.pdf';

    let emailAttachments: Array<Anexo> = [
      {
        ext: '.pdf',
        base64: base64Obj.original,
        filename: mq_filename
      },
      {
        ext: '.pdf',
        base64: base64Obj.contaCorrente,
        filename: cc_filename
      }
    ];

    this.cartaAnexos = [
      {
        ext: 'application/pdf',
        base64: base64Obj.economico,
        filename: mq_filename
      },
      {
        ext: 'application/pdf',
        base64: base64Obj.contaCorrente,
        filename: cc_filename
      }
    ]
    this.cdRef.detectChanges();
    
    let emailsToSend:Array<RegistoComunicacaoSendAndSave> = communicationsToSend.filter(el => el.sendBy === 'A' || el.sendBy === 'E').map(el => {
      let bodyMsg = el.bodyMsg + this.utils.getEmailFooterSimple(false);
        let email:Email = {
          from: this.appConfig.company.email,
          fromName: this.appConfig.company.nome,
          to: [{
            name: el.nome_pagador,
            email: el.email
          }],
          bcc: [],
          cc: [],
          id_entidade: null,
          subject: el.subjectMsg,
          body: bodyMsg,
          attachment: emailAttachments,
          filename: '',
          origin: 'NOTICE_AVISOS',
          userId: this.userSession.getUserId(),
          id_comunicacao: null
        }
        let row: RegistoComunicacaoSendAndSave = {
          email: email,
          cod_condominio: this.noticeInfo.cod_condominio,
          assunto: el.subjectMsg,
          id_texto_predefinido: el.notice_method,
          origem: 'NOTICE_AVISOS',
          id_entidade: null,
          data_divida: this.utils.getFormatedDate(this.noticeInfo.data),
          corpo: bodyMsg,
          email_list: [
            {
              nomeEntidade: el.nome_pagador, 
              cod_fraccao: el.cod_fraccao,
              tipo: 'PROPRIETARIO', 
              cod: el.cod_pagador,
              emails: email.to.map(el => el.email)
            }
          ],
          attachments: email.attachment
        }
        return row;
    })

    this.processCartas(communicationsToSend.filter(el => el.sendBy === 'A' || el.sendBy === 'C')).then(res => {
      if (this.noticePaymentsModalRef) this.noticePaymentsModalRef.approve();
      this.sendingCommunications = false;
    });

    this.processEmails(emailsToSend, condominosWithoutDebt);
    
  }
  
  getAnexosBase64(): Promise<{economico: string, original: string, contaCorrente:string}> {
    return new Promise((resolve) => {
      this.mapaQuotasPDF.getBase64(this.noticeInfo.cod_condominio, this.noticeInfo.data, 'A').then(async res => {
        resolve({economico: res.economico, original: res.original, contaCorrente: res.contaCorrente});
      }). catch(err => {
        resolve(null);
      });
    })
  }


  processEmails(emailsToSend: Array<RegistoComunicacaoSendAndSave>, condominosWithoutDebt:Array<saveEntityWithoutDebt>) {
    if (!emailsToSend.length && !condominosWithoutDebt.length) return;
    this.communications.sendPaymentsNoticesEmail(emailsToSend, condominosWithoutDebt, this.id_reconciliacao).then(res => {}).catch(err => {});
  }

  async processCartas(entities: Array<CommunicationToSend>): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!entities.length) {
        resolve(true);
        return;
      }
      let temp = await this.openDespesasCTTModal(entities);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      if (!temp || (temp.hasOwnProperty('status') && temp['status'] === 'OPENED') || !temp.hasOwnProperty('cttList') || !temp.hasOwnProperty('id_registo_ctt')) {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        resolve(false);
        return;
      } 
  
      this.toastr.info(this.appConfig.infMsg.exportingPDFs.msg, this.appConfig.infMsg.exportingPDFs.title);
      this.cartaComunicacaoInput = entities.map((el, i) => {
        let moradaCondominio = this.noticeInfo.morada_condominio ? this.utils.moradaStrToList(this.noticeInfo.morada_condominio) : [];
        let moradaEntidade = el.morada_entidade ? this.utils.moradaStrToList(el.morada_entidade) : [];
        let carta: CartaContent = {
          condominioNome: this.noticeInfo.nome_condominio,
          cod_condominio: this.noticeInfo.cod_condominio,
          moradaCondominio: moradaCondominio,
          nomeEntidade: el.nome_pagador,
          moradaEntidade: moradaEntidade,
          tipo_correio: temp.cttList[i],
          subjectMsg: el.subjectMsg,
          bodyMsg: el.bodyMsg,
          n_contribuinte: this.noticeInfo.nif_condominio
        }
         return carta;
      })
  
      this.cdRef.detectChanges();
  
      if (this.id_reconciliacao) {
        let success = await this.reconciliacoesBancarias.communicateReconciliacao(this.id_reconciliacao);
        if (!success){
          resolve(false);
          return;
        }
      }
  
      this.cartaComunicacao.generatePdf(temp.id_registo_ctt, { includeGreeting: false });

      this.toastr.info(this.appConfig.infMsg.exportingPDFs.msg, this.appConfig.infMsg.exportingPDFs.title);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      resolve(true);
    });
    
  }

  openDespesasCTTModal(entities:Array<CommunicationToSend>): Promise<{status?: 'OPENED', cttList?: Array<any>, id_registo_ctt?: Array<any>}> {
    return new Promise(async (resolve) => {
      let base64 = await this.getLetterBase64Format(entities[0]);
      let despesasCTT:Array<EntityDespesasCTTModalInput> = await Promise.all(entities.map(async el => {
        let aux:EntityDespesasCTTModalInput = {
          cod: el.cod_pagador,
          nomeEntidade: el.nome_pagador,
          cod_condominio: this.noticeInfo.cod_condominio,
          condominioNome: this.noticeInfo.nome_condominio,
          cod_fraccao: el.cod_fraccao,
          nome_fraccao: el.nome_fraccao,
          tipo_entidade: 'PROPRIETARIO',
          base64FileArr: [],
          id_texto_predefinido: el.notice_method,
          data_divida: this.noticeInfo.data,
          id_reconciliacao: this.id_reconciliacao,
        }
        return aux;
      }));
      let res = await this.despesasCttModal.open(despesasCTT, this.noticeInfo.cod_condominio, 'Envio de Avisos e Cobranças', base64);
      resolve(res);
    })
  }


  @ViewChild('cartaComunicacao', { static: false }) cartaComunicacao: CartaComunicacaoComponent;
  cartaComunicacaoInput: Array<CartaContent> = [];
  getLetterBase64Format(el: CommunicationToSend,): Promise<string> {
    return new Promise(async resolve => {

      // HANDLE MORADA ENTIDADE
      let moradaCondominio = this.noticeInfo.morada_condominio ? this.utils.moradaStrToList(this.noticeInfo.morada_condominio) : [];
      let moradaEntidade = el.morada_entidade ? this.utils.moradaStrToList(el.morada_entidade) : [];
      let aux: CartaContent = {
        condominioNome: this.noticeInfo.nome_condominio,
        cod_condominio: this.noticeInfo.cod_condominio,
        moradaCondominio: moradaCondominio,
        nomeEntidade: el.nome_pagador,
        moradaEntidade: moradaEntidade,
        tipo_correio: 'SIMPLES',
        subjectMsg: el.subjectMsg,
        bodyMsg: el.bodyMsg,
        n_contribuinte: this.noticeInfo.nif_condominio,
      }
      this.cartaComunicacaoInput = [aux];
      let base64PDF = await this.getComunicacaoTotalBase64()
      let base64 = this.utils.cleanBase64Prefix(base64PDF);
      resolve(base64);
    });
  }

  getComunicacaoTotalBase64(): Promise<string> {
    return new Promise(async resolve => {
      this.cartaComunicacao.getBase64().then(res => {
        let base64Carta = res as string;
        let base64Arr = [base64Carta].concat(this.cartaAnexos.map(el => el.base64));
        this.businessLogic.mergePdf('Mapa_Quotas', base64Arr).then(res => {
          resolve(res);
        }).catch(err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          resolve(null);
        })
      }).catch(err => {
        resolve(null);
      });
    });
  }
}
