import { Component, OnInit, ViewChild } from '@angular/core';
import { UserSessionService } from '../user-session.service';
import { Router } from '@angular/router';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MessageService } from '../message.service';
import { ApiService } from '../api.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}

import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { NavigationService } from '../navigation.service';
import { AppConfigRequestsService } from '../app-config-requests.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  sideMenuCollapsed = false;
  username: string = null;

  subsMsg: Subscription = null;

  @ViewChild(NgProgressComponent, { static: false }) progressBar: NgProgressComponent;

  // loopCVTimeout = 20 * 1000;  // 20s
  loopCVTimeout = 15 * 1000;  // 20s

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('modalCaixaVertisAlertRef', { static: false }) modalCaixaVertisAlertRef;
  modalCaixaVertisModalRef = null;
  modalCaixaVertisAlertConfig: any = null;

  constructor(public userSession: UserSessionService,
              public message: MessageService,
              public modalService: SuiModalService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public appConfigRequests: AppConfigRequestsService,
              public utils: UtilitiesService,
              public navigation: NavigationService,
              public api: ApiService,
              public router: Router) {
    // SEND TRIGGER TO BACKEND BACKGROUND PROCESSES
    this.api.setProcessamentosPenalizacoes().subscribe(res => {}, err => {});

    this.getEmailConfigs();

    // START CHECK CAIXA VERTIS LOOP
    this.checkIfCaixaVertisLoggedIn();


    // console.log('CHECK AVISOS LIQUIDADOS SEM RECIBOS');
    // this.api.getAllActiveCondominios().subscribe(async res => {
    //   for (let i = 0; i < res.data.length; i++) {
    //     console.log(res.data[i].cod + ' - ' + res.data[i].nome);
    //     let resp: any = await this.getAvisosPagosSemRecibos(res.data[i].cod);
    //     if (resp) {
    //       console.log(JSON.parse(JSON.stringify(resp.data)));
    //     }
    //   }
    // });
  }

  getEmailConfigs() {
    this.api.getEmailConfigs().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        res.data.forEach(el => {
          if (el) {
            switch (el.tipo) {
              case 'ATA_POR_ASSINAR':
                this.appConfig.ataAnexoSubject = el.assunto;
                this.appConfig.ataAnexoBody = el.corpo.split('\n');
                break;
              case 'AVISO_DE_PAGAMENTO':
                this.appConfig.avisoDePagamentoEmailSubject = el.assunto;
                this.appConfig.avisoDePagamentoEmailBody = el.corpo.split('\n');
                break;
              case 'MAPA_DE_QUOTAS':
                this.appConfig.mapaDeQuotasEmailSubject = el.assunto;
                this.appConfig.mapaDeQuotasEmailAnexoBody = el.corpo.split('\n');
                break;
              case 'CONTA_CORRENTE':
                this.appConfig.contaCorrenteEmailSubject = el.assunto;
                this.appConfig.contaCorrenteEmailBody = el.corpo.split('\n');
                break;
              // case 'RECIBOS':
              //   this.appConfig.recibosEmailSubject = el.assunto;
              //   this.appConfig.recibosEmailBody = el.corpo.split('\n');
              //   break;
              // case 'CREDITOS':
              //   this.appConfig.creditosEmailSubject = el.assunto;
              //   this.appConfig.creditosEmailBody = el.corpo.split('\n');
              //   break;
              case 'RECIBOS_CREDITOS':
                this.appConfig.recibosCreditosEmailSubject = el.assunto;
                this.appConfig.recibosCreditosEmailBody = el.corpo.split('\n');
                break;
              case 'ATA_ENTREGUE_PRESENCIALMENTE':
                this.appConfig.ataEntreguePresencialmenteSubject = el.assunto;
                this.appConfig.ataEntreguePresencialmenteBody = el.corpo.split('\n');
                break;
            }
          }
        });
      }
    }, err => { console.log(err); });
  }

  // CHECK IF I'M LOGGED AS CAIXA VERTIS
  loggedCVUser = { first_name: null, last_name: null };
  checkIfCaixaVertisLoggedIn() {
    if (this.userSession.getUserId() !== null) {
      this.api.isCaixaVertisLoggedIn().subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {

          if (res.hasOwnProperty('user_logged_in')) {
            this.loggedCVUser.first_name = res.user_logged_in.first_name;
            this.loggedCVUser.last_name = res.user_logged_in.last_name;
          }

          // PRESENT ALERT IF NEEDED
          if (!this.userSession.isCaixaVertisLoggedIn() && res.user_logged_in.caixa_vertis_next_user === this.userSession.getUserId() && this.modalCaixaVertisModalRef === null) {
            this.modalCaixaVertisModalRef = this.modalService
            .open(this.modalCaixaVertisAlertConfig)
            .onApprove(() => { 
              // API REQUEST TO ACCEPT SESSON
              this.api.acceptCaixaVertisSession().subscribe(res => {
                if (res.hasOwnProperty('success') && res.success) {
                  this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'CAIXA_VERTIS_LOGGED_IN' });

                  setTimeout(() => { localStorage.setItem('isCaixaVertisLoggedIn', '1'); }, 250);
                } else {
                  this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
                }
              }, err => {
                this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
              });

              this.modalCaixaVertisModalRef = null;
              this.message.sendMessage({ dest: 'CAIXA_VERTIS', cmd: 'LOGGED_USER', username: this.userSession.getUserFullName(), refresh: true });
            })
            .onDeny(() => {
              this.api.denyCaixaVertisSession().subscribe(res => {}, er => {});

              this.modalCaixaVertisModalRef = null;
            });
          } else if (res.user_logged_in.caixa_vertis_logged_in !== this.userSession.getUserId()) {
            this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'CAIXA_VERTIS_LOGGED_OUT' });

            setTimeout(() => { localStorage.setItem('isCaixaVertisLoggedIn', '0'); }, 250);
          }

          setTimeout(() => { this.checkIfCaixaVertisLoggedIn(); }, this.loopCVTimeout);
        }

        if (res.user_logged_in.caixa_vertis_logged_in !== '-1') this.message.sendMessage({ dest: 'CAIXA_VERTIS', cmd: 'LOGGED_USER', username: res.user_logged_in.first_name + ' ' + res.user_logged_in.last_name });

      }, err => { setTimeout(() => { this.checkIfCaixaVertisLoggedIn(); }, this.loopCVTimeout); });
    }
  }

  ngAfterViewInit() {
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.modalCaixaVertisAlertConfig = new TemplateModalConfig<IContext, string, string>(this.modalCaixaVertisAlertRef);
    this.modalCaixaVertisAlertConfig.closeResult = "closed";
    this.modalCaixaVertisAlertConfig.size = 'mini';
    this.modalCaixaVertisAlertConfig.transition = 'fade';
    this.modalCaixaVertisAlertConfig.transitionDuration = 250;

    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'MAIN_COMP') {
        switch (msg.cmd) {
          case 'GET_EMAILS_CONFIG':
            this.getEmailConfigs();
            break;
          case 'START_PROGRESS_BAR':
            this.progressBar.start();
            break;
          case 'STOP_PROGRESS_BAR':
            this.progressBar.complete();
            break;
          case 'LOGIN_DONE':
            // this.fromLogin = true;
            // this.getUserMapaReunioes();
            break;
          }
      }
    });
  }

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem('user_data'));

    if (userData) {
      this.getUserData(userData['id']).then(_ => {
        this.checkIfHasAccessToPropostas();
      });
    }
    this.navigation.init();
  }

  ngOnDestroy() {
    if (this.subsMsg) this.subsMsg.unsubscribe();
  }

  toogleSideMenu() {
    this.sideMenuCollapsed = !this.sideMenuCollapsed;
  }

  checkIfHasAccessToPropostas() {
    let sideMenuEntries = this.appConfig.getSideMenuEntries();
    let propostas = sideMenuEntries.find(sideEntry => sideEntry.key === 'PROPOSTAS');
    if (!propostas) return;

    this.api.checkUserAccess('PROPOSTAS','read').subscribe(res => {
      if (res.success) {
        if (propostas) {
          propostas.canAccess = res.data;
        }
      } else {
        propostas.canAccess = false;
      }
    }, err => {
      propostas.canAccess = false;
    })
  }

  
  getUserData(userId):Promise<any> {
    return new Promise((resolve) => {
      this.api.setAuthHeader(userId);
      this.api.getUtilizadorDetails(userId).subscribe(res => {
        if (res.success) {
          let aux = {
            id: res['data']['id'],
            username: res['data']['username'],
            first_name: res['data']['first_name'],
            last_name: res['data']['last_name'],
            is_admin: res['data']['is_admin'],
            super_admin: res['data']['super_admin'],
            utilizador_caixa: res['data']['utilizador_caixa'],
            email: res['data']['email'],
            phone: res['data']['phone'],
            utilizador_balcao: res['data']['utilizador_balcao'],
            permissions: res['data']['permissions'],
          } 
          this.userSession.setSession(aux);
  
          if (!res['data']['last_name']) {
            this.username = `${res['data']['first_name']}`;
          } else {
            this.username = `${res['data']['first_name']} ${res['data']['last_name'][0]}.`;
          }
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => {
        resolve(false);
      });
    });
    
  }

}
