import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { exportPDF, Group } from '@progress/kendo-drawing';


@Component({
  selector: 'app-pagamentos-nao-identificados-pdf',
  templateUrl: './pagamentos-nao-identificados-pdf.component.html',
  styleUrls: ['./pagamentos-nao-identificados-pdf.component.scss']
})
export class PagamentosNaoIdentificadosPdfComponent implements OnInit {

  tableColDef = [
    { key: 'n_receita', name: 'Nº', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: false, centered: false, class: 'col-align-left' },
    { key: 'dt_desp', name: 'Emissão', type: 'text', sort: null, searchable: true, centered: true, class: 'col-centered col-sm' },
    { key: 'a_liquidar', name: 'A Receber', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right col-sm' },
    { key: 'dt_pag', name: 'Pagamento', type: 'text', sort: null, searchable: false, centered: true, class: 'col-centered col-sm' },
    { key: 'valor', name: 'Receita', type: 'text', sort: null, searchable: false, centered: true, class: 'col-align-right col-sm' },
  ];

  @Input() data = {
    codCondominio: '',
    nomeCondominio: '',
    endDate: '',
    tableData: [],
    totalParcial: null,
    total: null,
  };

  @ViewChild('pdfRef', { static: false }) pdfController;

  now = null;
  filename = 'default_filename';

  constructor(public appConfig: AppConfigService) { }

  ngOnInit() {}

  ngOnDestroy() {}

  generatePdf() {
    this.now = new Date();

    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';
    this.pdfController.saveAs(this.filename + '.pdf');
  }

  getBase64() {
    return new Promise((resolve, reject) => {
      this.now = new Date();

      this.pdfController.export().then((group: Group) => exportPDF(group)).then(dataUri => {
        let base64  = dataUri.replace('data:application/pdf;base64,', '');
        base64  = base64.replace(' ', '+');

        resolve(base64);
      }).catch(err => {
        reject(err);
      });
    });
  }

  export() {
    this.now = new Date();

    return this.pdfController.export()
  }

}
