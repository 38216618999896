import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from '../app-config.service';

interface CartaHeader {
  condominioNome, 
  moradaCondominio:Array<string>,
  n_contribuinte:string,
  nomeEntidade:string,
  moradaEntidade: Array<string>,
  includeCompany: boolean
}

@Component({
  selector: 'app-carta-header',
  templateUrl: './carta-header.component.html',
  styleUrls: ['./carta-header.component.scss']
})
export class CartaHeaderComponent implements OnInit {


  constructor(public appConfig: AppConfigService) { }

  @Input() carta:CartaHeader = null;

  ngOnInit() {
  }

}
