import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

interface IContext {
  data:string;
}


import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { custosComunicacoes } from '../business-model-interfaces/comunicacoes';
import { MessageService } from '../message.service';
import { UserSessionService } from '../user-session.service';
import { UtilitiesService } from '../utilities.service';

@Component({
  selector: 'app-edit-entrega-fatura-ctt-modal',
  templateUrl: './edit-entrega-fatura-ctt-modal.component.html',
  styleUrls: ['./edit-entrega-fatura-ctt-modal.component.scss']
})
export class EditEntregaFaturaCttModalComponent implements OnInit {

  idEntregaValores = null;
  entregaValoresOpts = null;

  semEntregaOpt = {name: '-- Sem Entrega --',value: '-1'};
  funcionariosOpts = [];
  usersOpts = [];
  entityOpts = [];
  selEntity = null;

  currentsCustosComunicacoes:Array<custosComunicacoes> = [];

  submitting = false;
  loading = false;
  fatura: custosComunicacoes;

  type:'FATURA'|'ENTREGA' = null;

  now = new Date();

  
  @ViewChild('facturaCttAlertRef', { static: false }) facturaCttAlertRef;
  facturaCttModalRef = null;
  facturaCttAlertConfig: any = null;

  constructor(public api: ApiService,
              public userSession: UserSessionService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public utils: UtilitiesService,
              public message: MessageService,
              public modalService: SuiModalService,
              public cdRef:ChangeDetectorRef,
  ) { }

  ngOnInit() {
    let req = [
      this.api.getAllFuncionarios(),
      this.api.getUtilizadores(0, 500),
    ];
    forkJoin(req).subscribe(res => {
      if (res[0].hasOwnProperty('success') && res[0].success) {
        // FUNCIONARIOS OPTS ARRAY
        this.funcionariosOpts = res[0].data.map(el => { el['type'] = 'FUNCIONARIO';return { name: el.first_name + ' ' + el.last_name, value: el }; });

        // USER OPTS ARRAY
        this.usersOpts = res[1].data.map(el => {
          el['type'] = 'USER';
          return { name: el['first_name'] + ' ' + el['last_name'], value: el };
        });
        this.usersOpts = this.usersOpts.filter(el => (el.id !== this.userSession.getUserId()));

        // ENTITY ARRAY
        this.entityOpts = this.usersOpts.concat( this.funcionariosOpts );
      }
    }, err => {});
  }
  ngAfterViewInit() {
    this.facturaCttAlertConfig = new TemplateModalConfig<IContext, string, string>(this.facturaCttAlertRef);
    this.facturaCttAlertConfig.closeResult = "closed";
    this.facturaCttAlertConfig.size = 'tiny';
    this.facturaCttAlertConfig.transition = 'fade up';
    this.facturaCttAlertConfig.transitionDuration = 400;
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  getCurrentCustos():Promise<boolean> {
    return new Promise(resolve => {
      let req = [this.api.getCurrentsCustosComunicacoes(),
        this.api.getEntregaByFatura(this.fatura.id)]

      forkJoin(req).subscribe(res => {
        if (!res.find(el => !el.success)) {
          this.currentsCustosComunicacoes = res[0].data;
          if (res[1].data && res[1].data.hasOwnProperty('id') && res[1].data.id != this.fatura.id) {
            this.currentsCustosComunicacoes.push(res[1].data);
          }

          this.entregaValoresOpts = this.currentsCustosComunicacoes.map(el => {
            let name = parseFloat(el.valor).toFixed(2) + '€ - Entregue a ' + el.entregue_a_nome + ' (' + this.utils.getFormatedDate(el.data) + ')';
            let aux = {name: name, value: el.id}
            return aux;
          });

          //TODO Is permission
          if (this.userSession.isSuperAdmin()) {
            this.entregaValoresOpts = [this.semEntregaOpt].concat(this.entregaValoresOpts);
          }

          if (res[1].data && res[1].data.hasOwnProperty('id') && res[1].data.id != this.fatura.id) {
            this.idEntregaValores = res[1].data.id;
          } else {
            this.idEntregaValores = '-1';
            if (this.fatura.entregue_a_funcionario) {
              this.selEntity = this.entityOpts.find(el => el.value['type'] == 'FUNCIONARIO' && el.value.id == this.fatura.entregue_a_funcionario)['value'];
            } else {
              this.selEntity = this.entityOpts.find(el => el.value['type'] == 'USER' && el.value.id == this.fatura.entregue_a_utilizador)['value'];
            }
          }
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          resolve(false);
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    })
  }

  getEntregaSelectedDate(): Date {
    if (this.idEntregaValores == '-1') {
      return null;
    }
    let entrega = this.currentsCustosComunicacoes.find(el => el.id === this.idEntregaValores);
    if (!entrega || !entrega.data) return null;
    let data = new Date(entrega.data);
    data.setHours(0,0,0,0)
    return data;
  }

  async open(fatura: custosComunicacoes, type:'FATURA'|'ENTREGA', id_cv=null):Promise<boolean> {
    return new Promise(async resolve => {
      this.reset();
      if (id_cv) {
        let res = await this.getCustoComunicacaoByCV(id_cv);
        if (!res) {
          resolve(false);
          return;
        }
      } else {
        this.fatura = Object.assign({}, fatura);
        this.type = type;
      }

      if (!this.utils.checkCustosComunicacoesPermissions(this.fatura)) {
        resolve(false);
        return;
      }

      
      this.fatura.valor = parseFloat(this.fatura.valor).toFixed(2);
      if (this.type === 'FATURA') {
        this.fatura.troco = parseFloat(this.fatura.troco).toFixed(2);
        if (this.fatura.data_expedicao) {
          this.fatura.data_expedicao = new Date(this.fatura.data_expedicao);
        }
      }
      await this.getCurrentCustos();
  
      this.facturaCttModalRef = this.modalService
        .open(this.facturaCttAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => {
          resolve(false);
        });
    })
  }

  getCustoComunicacaoByCV(id_cv):Promise<boolean> {
    return new Promise(resolve => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      this.api.getCustoComunicacaoByCV(id_cv).subscribe(res => {
        if (res.success) {
          this.fatura = Object.assign({}, res.data);
          this.type = res.data.tipo == '0' ? 'ENTREGA' : 'FATURA';
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(true);
        } else {
          this.utils.apiErrorMsg(res);
          this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
          resolve(false);
        }
      }, err => {
        this.loading = false;
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        resolve(false);
      });
    });
  }

  reset() {
    this.idEntregaValores = null;
    this.entregaValoresOpts = null;
    this.currentsCustosComunicacoes = [];
    this.submitting = false
    this.loading = false
    this.fatura = null;
    this.selEntity = null;
  }

  submitFatura() {
    this.submitting = true;
    setTimeout(() => { this.submitting = false; }, 4000);
    if (!this.idEntregaValores) return; 
    if (!this.validValor() || !this.validDate() || !this.validEntrega())  {
      this.toastr.error('Não é possível guardar a fatura. Por favor, verifique todos os campos assinalados.', 'Alerta');
      return;
    }
    
    if (this.idEntregaValores != '-1') {
      let entrega = this.currentsCustosComunicacoes.find(el => el.id === this.idEntregaValores)
      this.fatura.entregue_a_nome = entrega.entregue_a_nome;
      this.fatura.entregue_a_funcionario = entrega.entregue_a_funcionario;
      this.fatura.entregue_a_utilizador = entrega.entregue_a_utilizador;
      this.fatura['id_entrega'] = entrega.id;
    } else {
      if (this.selEntity['type'] == 'FUNCIONARIO') {
        this.fatura.entregue_a_nome = this.selEntity['first_name'] + ' ' + this.selEntity['last_name'];
        this.fatura.entregue_a_funcionario =  this.selEntity.id;
        this.fatura.entregue_a_utilizador = null;
        this.fatura.id_entrega = null;
      } else if (this.selEntity['type'] == 'USER') {
        this.fatura.entregue_a_nome = this.selEntity['first_name'] + ' ' + this.selEntity['last_name'];
        this.fatura.entregue_a_funcionario =  null;
        this.fatura.entregue_a_utilizador = this.selEntity.id;
        this.fatura.id_entrega = null;
      } else {
        this.toastr.error('Não é possível guardar a fatura. Por favor selecione o utilizador.', 'Alerta');
        return;
      }
    }

    this.submit();
  }

  submitEntrega() {
    this.submitting = true;
    setTimeout(() => { this.submitting = false; }, 4000);

    if (!this.fatura.valor || Number(this.fatura.valor) < 0) return; 

    this.submit();
  }

  submit() {
    this.loading = true;
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.saveCustosComunicacao(this.fatura).subscribe(res => {
      if (res.success) {
        this.facturaCttModalRef.approve();
      } else {
        this.utils.apiErrorMsg(res);
      }
      this.loading = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.loading = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  updateTrocoFatura() {
    if (this.idEntregaValores == '-1') {
      if (!this.fatura.valor || this.fatura.valor.trim() === '') {
        this.fatura.troco = null
        return;
      }
      this.fatura.troco = (-1 * parseFloat(this.fatura.valor)).toFixed(2);
      return;
    }
    let entrega = this.currentsCustosComunicacoes.find(el => el.id === this.idEntregaValores);
    this.fatura.troco = entrega && entrega.valor? (this.fatura.valor? (parseFloat(entrega.valor) - parseFloat(this.fatura.valor)).toFixed(2) : parseFloat(entrega.valor).toFixed(2)) : '0';
  }

  //Validade Input
  validDate(): boolean {
    if (this.idEntregaValores == '-1') {
      return this.fatura && this.fatura.data_expedicao && this.utils.compareDayDates(this.fatura.data_expedicao, this.now) <= 0
    }
    let entrega = this.currentsCustosComunicacoes.find(el => el.id === this.idEntregaValores);
    return this.fatura && this.fatura.data_expedicao && this.utils.compareDayDates(this.fatura.data_expedicao, new Date(entrega.data)) >= 0 && this.utils.compareDayDates(this.fatura.data_expedicao, this.now) <= 0
  }

  validValor(): boolean {
    return this.fatura.valor && Number(this.fatura.valor) > 0;
  }

  validEntrega(): boolean {
    return this.idEntregaValores && (this.idEntregaValores != '-1' || this.selEntity);
  }

  getTroco(): number {
    return Math.abs(parseFloat(this.fatura.troco));
  }

}
