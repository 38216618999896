import { Component, OnInit, ViewChild, ElementRef, ApplicationRef, NgZone } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { fromEvent, forkJoin } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { AppStateService } from '../app-state.service';
import { UtilitiesService } from '../utilities.service';
import { SuiSelect } from 'ng2-semantic-ui/dist';


@Component({
  selector: 'app-orcamentos',
  templateUrl: './orcamentos.component.html',
  styleUrls: ['./orcamentos.component.scss']
})
export class OrcamentosComponent implements OnInit {

  transitionController = new TransitionController();
  loadingModal = false;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('deleteAvisosAlertRef', { static: false }) deleteAvisosAlertRef;
  alertAvisosModalRef = null;
  deleteAvisosAlertConfig: any = null;

  // ORCAMENTOS TABLE VARIABLES
  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false,class:'col-sm table-checkbox-column' },  // 'ASC', 'DESC', null, false
    { key: 'cod', name: 'Código', type: 'text', sort: null, searchable: true, class: 'col-centered' },
    { key: 'nome', name: 'Condomínio', type: 'text', sort: null, searchable: true, class:'' },
    { key: 'periodo', name: 'Exercício', type: 'text', sort: null, searchable: true, class:'col-sm col-centered' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, class:'' },
    { key: 'val_lancado', name: 'Tipo', type: 'text', sort: null, searchable: false, centered: true, class:'col-centered' },
  ];
  list: Array<any> = [];
  toDelete: Array<any> = [];
  listLength: number = 1000000;
  page: number = 1;
  itemPerPage: number = 20;
  keyword: string = null;
  sortParam: string = null;
  sortDirection: string = null;

  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;
  startYear: Date = null;
  endYear: Date = new Date();
  searching: boolean = false;

  tipoSel:'1'|'2'|'3' = '1';

  condominiosList = [];
  condominiosSelList = [];

  comp = 'orcamentos';
  initState = null;
  prevState = null;

  hasOrcValoresLancados = false;

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public router: Router,
              public utils: UtilitiesService,
              public api: ApiService,
              public appState: AppStateService,
              public zone: NgZone,
              public appConfig: AppConfigService,
              public appRef: ApplicationRef,
              public message: MessageService) { }

  saveGlobalState(id, cod, nome, exercicio) {
    this.appState.saveGlobalState('global', { 
      selCondominio: { id: id, cod: cod, nome: nome, exercicio: exercicio },
    });
  }

  apiSub = null;
  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    setTimeout(() => { if (this.apiSub) this.apiSub.unsubscribe(); }, 1);
  }

  ngOnInit() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);
    if (this.prevState) {
      this.keyword = (this.prevState.state.keyword) ? this.prevState.state.keyword : null;
      this.startYear = (this.prevState.state.startYear) ? this.prevState.state.startYear : null;
      this.endYear = (this.prevState.state.endYear) ? this.prevState.state.endYear : new Date();
      this.page = (this.prevState.state.page) ? this.prevState.state.page : 1;

      this.appState.clearPrevState(this.comp);
    }

    // GET GLOBAL STATE
    let globalState = this.appState.getGlobalState('global');
    if (globalState && globalState.hasOwnProperty('selCondominio')) {
      this.condominioSelected = (globalState.selCondominio) ? { name: globalState.selCondominio.cod + ' - ' + globalState.selCondominio.nome, value: globalState.selCondominio, cod: globalState.selCondominio.cod, nome: globalState.selCondominio.nome } : null;
    }

    this.animate();
    this.getList();
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  condominiosTimer = null;
  condominioSelected = null;
  clearEntry = { name: '-- limpar selecção --', value: '-1' };
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.condominioSelected); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
                } else {
                  return resolve([this.clearEntry]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; })));
            } else {
              return resolve([this.clearEntry]);
            }
          });
        }
    });
  };

  @ViewChild('condominioSelect', { static: false }) condominioSelect: SuiSelect<{name,value},any>;
  checkCleanCondominio() {
    if (this.condominioSelected && this.condominioSelected === '-1') {
      this.condominioSelect.selectedOption = null;
      this.condominioSelected = null;
      this.appState.clearGlobalState();
    } else {
      this.saveGlobalState(this.condominioSelected.id, this.condominioSelected.cod, this.condominioSelected.nome, this.condominioSelected.exercicio);
    }
  }

  ngAfterViewInit() {
    // SEARCH INPUT
    fromEvent(this.tableSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value']);
    });

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.deleteAvisosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAvisosAlertRef);
    this.deleteAvisosAlertConfig.closeResult = "closed";
    this.deleteAvisosAlertConfig.size = 'mini';
    this.deleteAvisosAlertConfig.transition = 'fade';
    this.deleteAvisosAlertConfig.transitionDuration = 250;
  }

  getList() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });


    let cod = this.condominioSelected? this.condominioSelected.cod : null;
    // this.apiSub = this.api.getFilteredOrcamentos(this.exercicio, this.page, this.itemPerPage, this.keyword, this.sortParam, this.sortDirection).subscribe(res => {
    this.apiSub = this.api.getFilteredOrcamentosREVIEW(cod, this.startYear, this.endYear, this.tipoSel, this.page, this.itemPerPage, this.keyword, this.sortParam, this.sortDirection).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        // CONVERT 1/0 TO true/false
        this.list = res.data.map(el => {
          el['val_lancado'] = (el['val_lancado'] === '1');
          el['nome'] = el['cod_condominio'] + ' - ' + el['nome'];
          return el;
        });

        //TODO Remove from backend too if it won't be used
        // this.exercicioOpts = res.exercicios.map(el => {
        //   return { name: el.periodo, value: el.periodo };
        // });

        this.list.forEach(el => { el['checked'] = false; });
        this.listLength = res.total;
        this.searching = false;
      } else {
        this.listLength = 0;
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.searching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  add() {
    this.router.navigate(['orcamentos/orcamento', 'criar']);

    // BREADCRUMB SIGNAL
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVO ORÇAMENTO' });
  }

  presentAlert() {
    if (this.toDelete.find(el => (el.val_lancado))) this.hasOrcValoresLancados = true;

    this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => {
        if (!this.hasOrcValoresLancados) {
          this.toDelete = [];
          this.hasOrcValoresLancados = false;
        }
        this.loadingModal = false; 
      })
      .onDeny(() => { this.loadingModal = false; this.toDelete = []; this.hasOrcValoresLancados = false; });
  }

  del() {
    if (this.hasOrcValoresLancados) {
      this.alertModalRef.approve();

      this.alertAvisosModalRef = this.modalService
        .open(this.deleteAvisosAlertConfig)
        .onApprove(() => {
            this.hasOrcValoresLancados = false;
            this.del();
        })
        .onDeny(() => { this.loadingModal = false; this.toDelete = []; this.hasOrcValoresLancados = false; });

    } else {
      this.loadingModal = true;
      
      this.api.delOrcamentos(this.toDelete).subscribe(res => {
        if (res.hasOwnProperty('success') & res.success) {
          this.list = this.list.filter(el => !el.checked);

          this.getList();          
        } else {
          this.utils.apiErrorMsg(res);
        }
        this.alertModalRef.approve();
        this.loadingModal = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      });
    }
  }

  pageChange(ev) {
    this.page = ev;
    this.getList();
  }

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.list.map(el => el.checked = true ) : this.list.map(el => el.checked = false );
  }

  async tableAction(action) {
    switch (action) {
      case 'csv': break;
      case 'pdf': break;
      case 'print': break;
      case 'add': this.add(); break;
      case 'delete':
        this.toDelete = this.list.filter(el => el.checked);
        if (this.toDelete.length > 0) {
          this.presentAlert();
        } else {
          this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
        }
        break;
    }
  }

  tableSearch(value) {
    this.page = 1;

    this.keyword = value.toLowerCase().trim();
    this.searching = true;

    this.getList();
  }

  tableSort(key) {
    this.page = 1;

    this.listCol.forEach(el => {
      if (el.key === key) {

        if (el.sort === 'DESC') {
          el.sort = 'ASC';
        } else if (el.sort === 'ASC') {
          el.sort = 'DESC';
        } else if (el.sort === null) {
          el.sort = 'ASC';
        }

        this.sortParam = key;
        this.sortDirection = el.sort;
        this.getList();
      } else {
        el.sort = null;
      }
    });
  }

  setState() {
    this.appState.setPrevState(this.comp, { 
      keyword: this.keyword,
      startYear: this.startYear,
      endYear: this.endYear,
      page: this.page,
    });
  }

  goToDetails(item) {
    // SAVE THIS STATE
    this.setState();
    this.saveGlobalState(item.id_condominio, item.cod_condominio, item.nome.split('-')[1].trim(), item.exercicio_condominio);

    // REGISTO ACTIVIDADES API CALL
    let obj = { link: item.id, cod: item.nome };
    let titulo = 'Orçamento Visualizado';
    let descricao = 'Condomínio: ' + item.nome + ', Exercício: ' + item.periodo;
    this.api.saveRegistoActividade(item.cod, 'ORCAMENTO', item.cod, titulo, descricao, obj).subscribe(res => {}, err => { });

    this.router.navigate(['orcamentos/orcamento', item.id]);

    // BREADCRUMB SIGNAL
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: item.nome });
  }

}
