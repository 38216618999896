import { Component, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { CondominiosService } from '../business-logic-services/condominios.service';
import { IContext } from '../business-model-interfaces/application';
import { CondominioEntidadesRegAtividade } from '../business-model-interfaces/condominios';
import { UtilitiesService } from '../utilities.service';

@Component({
  selector: 'app-condominio-entidades-reg-atividade-modal',
  templateUrl: './condominio-entidades-reg-atividade-modal.component.html',
  styleUrls: ['./condominio-entidades-reg-atividade-modal.component.scss']
})
export class CondominioEntidadesRegAtividadeModalComponent implements OnInit {

  @ViewChild('modalAlertRef', { static: false }) modalAlertRef;
  modalRef = null;
  modalConfig = null;

  cod_condominio = null;
  regAtividade:Array<CondominioEntidadesRegAtividade> = [];

  constructor(
    public utils: UtilitiesService,
    public condominios: CondominiosService,
    public modalService: SuiModalService,
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.modalConfig = new TemplateModalConfig<IContext, string, string>(this.modalAlertRef);
    this.modalConfig.isClosable = false;
    this.modalConfig.closeResult = 'closed';
    this.modalConfig.size = 'normal';
    this.modalConfig.transition = 'fade';
    this.modalConfig.transitionDuration = 250;
  }


  open(cod_condominio): Promise<boolean> {
    return new Promise(resolve => {
      this.cod_condominio = cod_condominio;
      this.regAtividade = [];
      this.fetchData();
      this.modalRef = this.modalService
      .open(this.modalConfig)
      .onApprove(() => { resolve(true); })
      .onDeny(() => { resolve(false); });
    });
  }

  fetchingData = false;
  async fetchData() {
    this.fetchingData = true;
    let reg = await this.condominios.getCondEntidadesRegAtividade(this.cod_condominio);
    this.fetchingData = false;
    if (reg == null) return;

    this.regAtividade = reg;
  }

  getStateName(state:string): string {
    switch (state) {
      case 'ELECTED':
        return 'Eleito';
      case 'EFECTIVE':
        return 'Em Funções';
      default:
        return null;
    }
  }

}
