import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent, forkJoin } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { AppStateService } from '../app-state.service';


@Component({
  selector: 'app-movimentos-details',
  templateUrl: './movimentos-details.component.html',
  styleUrls: ['./movimentos-details.component.scss']
})
export class MovimentosDetailsComponent implements OnInit {

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  transitionController = new TransitionController();
  loading = false;
  loadingModal = false;
  toDelete = [];
  searchable: boolean = true;
  isCreate = false;
  entityType = null;
  submittingForm = false;
  submittingAdd = false;

  // ROW INPUT VARIABLES 
  condominioSelected = null;
  contaOpts = [];
  contaOptsOrig = [];
  contaOrigemOpts = [];
  contaOrigemSelected = null;
  contaDestinoOpts = [];
  contaDestinoSelected = null;
  movDate = null;
  valor = null;
  descricao = null;
  newDescricao = null;
  caixa = null;

  valorTotal = null;

  movimentosListColDep = [
    { key: 'condominio', name: 'Condominio', type: 'text', sort: null, searchable: true, centered: true, class: 'col-40' },
    { key: 'conta_destino', name: 'A creditar', type: 'text', sort: null, searchable: true, centered: false, class: 'col-20' },
    { key: 'dt_mov', name: 'Data', type: 'date', sort: null, searchable: true, centered: false, class: 'col-15' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-15 col-align-right' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-10 col-centered' },  // 'ASC', 'DESC'
  ];
  movimentosListColLev = [
    { key: 'condominio', name: 'Condominio', type: 'text', sort: null, searchable: true, centered: true, class: 'col-40' },
    { key: 'conta_origem', name: 'A debitar', type: 'text', sort: null, searchable: true, centered: true, class: 'col-20' },
    { key: 'dt_mov', name: 'Data', type: 'date', sort: null, searchable: true, centered: false, class: 'col-15' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-15 col-align-right' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-10 col-centered' },  // 'ASC', 'DESC'
  ];
  movimentosListColTrans = [
    { key: 'condominio', name: 'Condominio', type: 'text', sort: null, searchable: true, centered: true, class: 'col-35' },
    { key: 'conta_origem', name: 'A debitar', type: 'text', sort: null, searchable: true, centered: true, class: 'col-15' },
    { key: 'conta_destino', name: 'A creditar', type: 'text', sort: null, searchable: true, centered: false, class: 'col-15' },
    { key: 'dt_mov', name: 'Data', type: 'date', sort: null, searchable: true, centered: false, class: 'col-15' },
    { key: 'valor', name: 'Valor', type: 'number', sort: null, searchable: true, centered: false, class: 'col-10 col-align-right' },
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-10 col-centered' },  // 'ASC', 'DESC'
  ];
  movimentosListCol = [];
  movimentosList: Array<any> = [];
  movimentosListOrig: Array<any> = [];
  
  comp = 'movimentos-details';
  initState = null;
  prevState = null;

  // EDIT MOVIMENTO VARIABLES
  movimentoDetails = null;
  movimentoId = null;
  movimentoNid = null;

  movimentoForm = new FormGroup({
    descricao: new FormControl(null),
    dt_mov: new FormControl(null),
    nid_conta: new FormControl(null),
    nid_documento: new FormControl(null),
    nid_rubrica: new FormControl(null),
    tipo_doc: new FormControl(null),
    valor: new FormControl(null),
  });

  details = {
    condominio: null,
    conta_origem: null,
    conta_destino: null,
    tipo_movimento: null,
  }

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public route: ActivatedRoute,
              public router: Router,
              public location: Location,
              public api: ApiService,
              public utils: UtilitiesService,
              public appConfig: AppConfigService,
              public appState: AppStateService,
              public cdRef:ChangeDetectorRef,
              public message: MessageService) { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode == 13) {
      if (this.condominioSelected) {
        this.tableAction('add');
      } else {
        this.formSubmitted();
      }
    }
  }

  ngOnInit() {
    // HANDLE APPLICATION STATE
    this.initState = this.appState.getInitState(this.comp);
    if (this.initState) {
      this.condominioSelected = { name: this.initState.state.condominioSelected.cod + ' - ' + this.initState.state.condominioSelected.nome, value: this.initState.state.condominioSelected };
      this.appState.clearInitState(this.comp);
    }

    this.animate();

    if (this.route.snapshot.params.id.indexOf('criar') !== -1) {
      this.isCreate = true;

      if (this.router.url.indexOf('deposito') !== -1) {
        this.movimentosListCol = this.movimentosListColDep;
        this.entityType = 'depositos';
      }
      if (this.router.url.indexOf('levantamento') !== -1) {
        this.movimentosListCol = this.movimentosListColLev;
        this.entityType = 'levantamentos';
      }
      if (this.router.url.indexOf('transferencia') !== -1) {
        this.movimentosListCol = this.movimentosListColTrans;
        this.entityType = 'transferencias';
      }

    } else {
      this.movimentoId = this.route.snapshot.params.id;

      this.getMovimentoDetails();
    }

    if (this.initState) {
      this.getContas();
    }
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngAfterViewInit() {
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.condominioSelected); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
                } else {
                  return resolve([]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  async tableAction(action) {
    switch (action) {
      case 'csv': break;
      case 'pdf': break;
      case 'print': break;
      case 'add':
        if (!this.condominioSelected) return;

        let cod = (this.condominioSelected.hasOwnProperty('cod')) ? this.condominioSelected.cod : this.condominioSelected.value.cod
        let nome = (this.condominioSelected.hasOwnProperty('nome')) ? this.condominioSelected.nome : this.condominioSelected.value.nome

        let newMovimento = {
          descricao: this.descricao,
          condominio: (this.condominioSelected) ? nome : null,
          cod_condominio: (this.condominioSelected) ? cod  : null,
          dt_mov: this.movDate,
          valor: this.valor,
          checked: false,
        }

        if (this.entityType === 'depositos') {
          let other = this.contaOpts.find(el => (el.value === this.contaDestinoSelected));

          this.contaOrigemSelected = (this.caixa) ? this.caixa.value : null;
          newMovimento['nid_conta_origem'] = (this.caixa) ? this.caixa['value'] : null;
          newMovimento['conta_origem'] = (this.caixa) ? this.caixa['name'] : null;
          newMovimento['nid_conta_destino'] = (other) ? other['value'] : null;
          newMovimento['conta_destino'] = (other) ? other['name'] : null;
        }

        if (this.entityType === 'levantamentos') {
          let other = this.contaOpts.find(el => (el.value === this.contaOrigemSelected));

          this.contaDestinoSelected = (this.caixa) ? this.caixa.value : null;
          newMovimento['nid_conta_origem'] = (other) ? other['value'] : null;
          newMovimento['conta_origem'] = (other) ? other['name'] : null;
          newMovimento['nid_conta_destino'] = (this.caixa) ? this.caixa['value'] : null;
          newMovimento['conta_destino'] = (this.caixa) ? this.caixa['name'] : null;
        }

        if (this.entityType === 'transferencias') {
          this.contaOpts = this.contaOpts.filter(el => (el.name !== 'CAIXA'));
          this.contaOrigemOpts = JSON.parse(JSON.stringify(this.contaOpts));
          this.contaDestinoOpts = JSON.parse(JSON.stringify(this.contaOpts));

          let orig = this.contaOpts.find(el => (el.value === this.contaOrigemSelected));
          let dest = this.contaOpts.find(el => (el.value === this.contaDestinoSelected));

          newMovimento['nid_conta_origem'] = (orig) ? orig['value'] : null;
          newMovimento['conta_origem'] = (orig) ? orig['name'] : null;
          newMovimento['nid_conta_destino'] = (dest) ? dest['value'] : null;
          newMovimento['conta_destino'] = (dest) ? dest['name'] : null;
        }

        if (!this.condominioSelected || !this.movDate || !this.valor || !this.contaOrigemSelected || !this.contaDestinoSelected) {
          this.submittingAdd = true; setTimeout(() => { this.submittingAdd = false; }, 4000);
          this.toastr.error('Movimento a adicionar encontra-se incompleto', 'Ups...!', { timeOut: 4000 });
          return;
        }

        this.movimentosList = [newMovimento].concat(this.movimentosList);

        this.clearInputEntry();
        this.computeTotal();
        break;
      case 'delete':
        this.toDelete = this.movimentosList.filter(el => el.checked);
        if (this.toDelete.length > 0) {
          this.presentAlert();
        } else {
          this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
        }
        break;
    }
  }

  presentAlert() {
    this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => { this.loadingModal = false; this.toDelete = []; this.computeTotal() })
      .onDeny(() => { this.loadingModal = false; this.toDelete = []; });
  }

  del() {
    this.loadingModal = true;
    this.movimentosList = this.movimentosList.filter(el => (!el.checked));
    this.alertModalRef.approve();
  }

  tableSort(key) {
    this.utils.tableSort(this.movimentosListCol, this.movimentosList, key);
  }

  getContas() {
    let cod = (this.condominioSelected.hasOwnProperty('cod')) ? this.condominioSelected.cod : this.condominioSelected.value.cod
    this.api.getCondContasDetails(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.contaOptsOrig =  res.data.map(el => {
          return { name: el.banco, value: el.cod };
        });
        this.contaOpts = JSON.parse(JSON.stringify(this.contaOptsOrig));

        this.handleContasOpts();
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  handleContasOpts() {
    if (this.entityType === 'transferencias') {
      this.contaOpts = this.contaOpts.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1));
      this.contaOrigemOpts = JSON.parse(JSON.stringify(this.contaOpts));
      this.contaDestinoOpts = JSON.parse(JSON.stringify(this.contaOpts));

      if (this.contaOpts.length === 0) {
        this.toastr.error('Condomínio selecionado não tem conta bancária atribuída', 'Ups...!', { timeOut: 4000 });
        setTimeout(() => { this.condominioSelected = null; }, 4000);
      }
    } else {
      if (this.entityType === 'depositos') {
        this.caixa = this.contaOpts.find(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
        this.contaOpts = this.contaOpts.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1));
        this.contaDestinoOpts = JSON.parse(JSON.stringify(this.contaOpts));
      }
      if (this.entityType === 'levantamentos') {
        this.caixa = this.contaOpts.find(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
        this.contaOpts = this.contaOpts.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1));
        this.contaOrigemOpts = JSON.parse(JSON.stringify(this.contaOpts));
      }

      if (!this.caixa) {
        this.toastr.error('Condomínio selecionado não tem CAIXA atribuída', 'Ups...!', { timeOut: 4000 });
        setTimeout(() => { this.condominioSelected = null; }, 4000);
      } else if ((this.contaOrigemOpts.length === 0 && this.entityType === 'levantamentos') || (this.contaDestinoOpts.length === 0 && this.entityType === 'depositos')) {
        this.toastr.error('Condomínio selecionado não tem conta bancária atribuída', 'Ups...!', { timeOut: 4000 });
        setTimeout(() => { this.condominioSelected = null; }, 4000);
      }
    } 
  }

  formSubmitted() {
    this.submittingForm = true;

    if (this.movimentosList.length === 0) {
      this.toastr.error('Nenhum movimento adicionado para submissão.', 'Ups...!', { timeOut: 4000 });
      this.submittingForm = false;
      return;
    } else {
      this.loading = true;

      let tipo = null;
      if (this.entityType === 'depositos') { tipo = 'DEP'; }
      if (this.entityType === 'levantamentos') { tipo = 'LEV'; }
      if (this.entityType === 'transferencias') { tipo = 'TRAN'; }

      let movimentosToSave = [];
      let req = [];
      this.movimentosList.forEach(mov => {

        let obj = {
          conta_origem: (this.contaOptsOrig.find(el => (el.value === mov.nid_conta_origem))) ? this.contaOptsOrig.find(el => (el.value === mov.nid_conta_origem)).name : null,
          conta_destino: (this.contaOptsOrig.find(el => (el.value === mov.nid_conta_destino))) ? this.contaOptsOrig.find(el => (el.value === mov.nid_conta_destino)).name: null,

          nid_conta_origem: mov.nid_conta_origem,
          nid_conta_destino: mov.nid_conta_destino,
        }

        let movimentoOrig =  {
          dt_mov: this.utils.getFormatedDate(mov.dt_mov),
          valor: -1 * mov.valor,
          dt_valor: this.utils.getFormatedDate(mov.dt_valor),
          nid_rubrica: null,
          nid_conta: mov.nid_conta_origem,
          descricao: this.getDescricao(mov),
          tipo_movimento: tipo,
          obj: JSON.stringify(obj),
        };

        let movimentoDest =  {
          dt_mov: this.utils.getFormatedDate(mov.dt_mov),
          valor: mov.valor,
          dt_valor: this.utils.getFormatedDate(mov.dt_valor),
          nid_rubrica: null,
          nid_conta: mov.nid_conta_destino,
          descricao: this.getDescricao(mov),
          tipo_movimento: tipo,
          obj: JSON.stringify(obj),
        };

        movimentosToSave.push(movimentoOrig);
        movimentosToSave.push(movimentoDest);

        // REGISTO ACTIVIDADES API CALL
        let titulo = null;
        let descricao = null;

        switch (this.entityType) {
          case 'depositos':
            titulo = 'Depósito Criado';
            break;
          case 'levantamentos':
            titulo = 'Levantamento Criado';
            break;
          case 'transferencias':
            titulo = 'Transferência Criada';
            break;
        }
        descricao = 'Condomínio: ' + mov.cod_condominio + ' - ' + mov.condominio + ', ' + mov.conta_origem + ' -> ' + mov.conta_destino + ', Valor: ' + Number(mov.valor).toFixed(2) + '€';
        req.push(this.api.saveRegistoActividade(mov.cod_condominio, null, null, titulo, descricao));

      });

      this.api.saveMovimentosList('MOVIMENTOS_BANCARIOS', movimentosToSave).subscribe(res => {
        if (res.hasOwnProperty('success') && res['success']) {
          this.location.back();

            // REGISTO ACTIVIDADES API CALL
            forkJoin(req).subscribe(res => {}, err => { });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
          this.loading = false;
        }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.submittingForm = false;
        this.loading = false;
      }); 
    }
  }

  getDescricao(movmiento) {
    if (this.entityType === 'depositos') {
      return 'Reforço de conta.';
    }
    if (this.entityType === 'levantamentos') {
      return 'Reforço de caixa.';
    }
    if (this.entityType === 'transferencias') {
      return 'Transferência entre contas.';
    }
  }

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.movimentosList.map(el => el.checked = true ) : this.movimentosList.map(el => el.checked = false );
  }

  contaOrigemChanged() {
    if (this.entityType === 'transferencias') {
      this.contaDestinoOpts = JSON.parse(JSON.stringify(this.contaOpts)).filter(el => (el.value !== this.contaOrigemSelected));
    }
  }

  clearInputEntry() {
    this.condominioSelected = null;
    this.contaOpts = [];
    this.contaOrigemOpts = [];
    this.contaOrigemSelected = null;
    this.contaDestinoOpts = [];
    this.contaDestinoSelected = null;
    this.movDate = null;
    this.valor = null;
    this.descricao = null;
    this.caixa = null;
  }

  computeTotal() {
    this.valorTotal = 0;
    this.movimentosList.forEach(el => { this.valorTotal += Number(el.valor); });
  }

  getMovimentoDetails() {
    this.api.getMovimentoDetails(this.movimentoId).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.movimentoNid = res.data.nid;
        this.movimentoDetails = res.data;

        this.movimentoDetails['dt_mov'] = (this.movimentoDetails['dt_mov']) ? this.utils.getDate(this.movimentoDetails['dt_mov']) : null;
        this.movimentoDetails['valor'] = Number(this.movimentoDetails['valor']).toFixed(2);

        this.details.condominio = this.movimentoDetails.cod_condominio + ' - ' + this.movimentoDetails.nome_condominio;
        this.details.tipo_movimento = this.utils.getTipoMovimento(this.movimentoDetails.tipo_movimento);

        if (this.movimentoDetails.obj) {
          try {
            let obj = JSON.parse(this.movimentoDetails.obj);
  
            this.details.conta_origem = obj.conta_origem;
            this.details.conta_destino = obj.conta_destino;
  
          } catch {}
        }

        this.restoreForm();
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  restoreForm() {
    this.movimentoForm.setValue({
      descricao: this.movimentoDetails.descricao,
      dt_mov: this.movimentoDetails.dt_mov,
      nid_conta: this.movimentoDetails.nid_conta,
      nid_documento: this.movimentoDetails.nid_documento,
      nid_rubrica: this.movimentoDetails.nid_rubrica,
      tipo_doc: this.movimentoDetails.tipo_doc,
      valor: this.movimentoDetails.valor,
    });
  }

  editFormSubmitted() {
    this.submittingForm = true;
    this.loading = true;

    let data = this.movimentoForm.getRawValue();

    this.api.saveMovimento(this.movimentoId, data.descricao).subscribe(res => {
      if (res.hasOwnProperty('success') && res['success']) {
        this.location.back();

        // // REGISTO ACTIVIDADES API CALL
        // let titulo = (targetFlag === 'lancar-valores') ? 'Orçamento Lançado' : 'Orçamento Actualizado'
        // let descricao = 'Condomínio: ' + this.orcamento.nome_condominio + ', Exercício: ' + data.periodo;
        // this.api.saveRegistoActividade(data.cod, null, null, titulo, descricao).subscribe(res => {}, err => { });

        } else {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        }
        this.submittingForm = false;
        this.loading = false;
      }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.submittingForm = false;
      this.loading = false;
    });
  }

}
