import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
interface IContext {
  data:string;
}

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  @Input() deleteFunction: () => Promise<boolean>;
  question:string = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  loading = false;

  constructor(public modalService: SuiModalService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  open(question=null):void {
    this.question = question;
    this.alertModalRef = this.modalService
    .open(this.deleteAlertConfig)
    .onApprove(async () => { 
      this.loading = true;
      try {
        await this.deleteFunction();
      } catch (err) {
      }
      this.loading = false;
    })
    .onDeny(() => { 
    });
  }
}
