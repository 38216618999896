import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { resolve } from 'url';
import { AppConfigService } from '../app-config.service';
import { AppStateService } from '../app-state.service';
import { ProcessamentosService } from '../business-logic-services/processamentos.service';
import { bit, IContext } from '../business-model-interfaces/application';
import { GetProcessamentoListQuery, Processamento } from '../business-model-interfaces/processamentos';
import { MessageService } from '../message.service';
import { AssembleiaQuotaExtraNavState } from '../navigation';
import { ProcessamentoDeleteRecibosComponent } from '../processamento-delete-recibos/processamento-delete-recibos.component';
import { ProcessamentoDuplicationModalComponent } from '../processamento-duplication-modal/processamento-duplication-modal.component';
import { ProcessamentoPdfComponent } from '../processamento-pdf/processamento-pdf.component';
import { UtilitiesService } from '../utilities.service';

interface QuotaExtraRow {
  checked: boolean,
  disabled: boolean,
  id: number,
  cod: number,
  descricao: string,
  dt_inicio: Date,
  dt_fim: Date,
  data_venc: Date,
  estado: string,
  nPrestacoes: number,
  tipoReparticao: Processamento['tipo_reparticao'],
  mesesCobranca: Processamento['meses_cobranca'],
  valorTotal: number,
  is_lancado: bit,
}

@Component({
  selector: 'app-assembleias-pick-quota-extra',
  templateUrl: './assembleias-pick-quota-extra.component.html',
  styleUrls: ['./assembleias-pick-quota-extra.component.scss']
})
export class AssembleiasPickQuotaExtraComponent implements OnInit {

  @Input() id_assembleia = null;


  @ViewChild('alertRef', { static: false }) alertRef;
  modalRef = null;
  modalConfig: any = null;

  list: Array<QuotaExtraRow> = [];

  cod_condominio: { cod, id, nome } = null;

  filterForm = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
  });
  get startDateCtrl() { return this.filterForm.get('startDate'); }
  get startDate(): Date { return this.filterForm.get('startDate').value; }
  get endDateCtrl() { return this.filterForm.get('endDate'); }
  get endDate(): Date { return this.filterForm.get('endDate').value; }
  respIndex: number = null;

  constructor(
    public modalService: SuiModalService,
    public message: MessageService,
    public utils: UtilitiesService,
    public appConfig: AppConfigService,
    public router: Router,
    public route: ActivatedRoute,
    public processamentos: ProcessamentosService,
    public appState: AppStateService,
    public toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.setFormSubscribes();
  }

  setFormSubscribes() {
    this.startDateCtrl.valueChanges.subscribe(_ => this.fetchData());
    this.endDateCtrl.valueChanges.subscribe(_ => this.fetchData());
  }

  ngAfterViewInit() {
    this.modalConfig = new TemplateModalConfig<IContext, string, string>(this.alertRef);
    this.modalConfig.closeResult = "closed";
    this.modalConfig.size = 'normal';
    this.modalConfig.transition = 'fade up';
    this.modalConfig.transitionDuration = 400;
  }

  async ngOnDestroy() {
    if (this.modalRef) this.modalRef.deny();
  }

  fetchingData = false
  fetchingInitialData = false;
  prevProcSelectedId: number = null;
  procsAlreadyAdded:Array<string> = [];
  open(cod_condominio: { cod, id, nome }, respIndex: number, procsAlreadyAdded:Array<string>, prevState: { id_proc_select: string, endYear: string, startYear: string, prevProcId: string }): Promise<QuotaExtraRow> {
    return new Promise(async (resolve) => {
      if (!cod_condominio) {
        resolve(null);
        return;
      }
      this.procsAlreadyAdded = procsAlreadyAdded;
      this.fetchingInitialData = true;
      this.prevProcSelectedId = null;
      this.cod_condominio = cod_condominio;
      this.respIndex = respIndex;
      let startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      this.startDateCtrl.patchValue(new Date(startDate), { emitEvent: false });
      
      let endDate = new Date();
      endDate.setFullYear(endDate.getFullYear() + 1);
      this.endDateCtrl.patchValue(new Date(endDate), { emitEvent: false });


      let id_proc_select: number = null;
      if (prevState) {
        if ('startYear' in prevState) {
          if (prevState.startYear == null) {
            this.startDateCtrl.patchValue(null, { emitEvent: false });
          } else {
            startDate = this.startDate;
            startDate.setFullYear(parseInt(prevState.startYear));
  
            this.startDateCtrl.patchValue(new Date(startDate), { emitEvent: false });
          }
        }
        if ('endYear' in prevState) {
          if (prevState.endYear == null) {
            this.endDateCtrl.patchValue(null, { emitEvent: false });
          } else {
            endDate = this.endDate;
            endDate.setFullYear(parseInt(prevState.endYear));
            this.endDateCtrl.patchValue(new Date(endDate), { emitEvent: false });
          }
        }
        if ('id_proc_select' in prevState && prevState.id_proc_select != null) {
          id_proc_select = parseInt(prevState.id_proc_select);
        }
        if ('prevProcId' in prevState && prevState.prevProcId != null) {
          this.prevProcSelectedId = parseInt(prevState.prevProcId);
        }
      }


      this.fetchData(id_proc_select);
      
      this.modalRef = this.modalService
        .open(this.modalConfig)
        .onApprove(async (chosenProcessamento: QuotaExtraRow) => {
          resolve(chosenProcessamento);
        })
        .onDeny(res => {
          resolve(null);
        });
    })
  }


  async fetchData(id_proc_select: number = null): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.fetchingData = true;

      let queryParams: GetProcessamentoListQuery = {
        tipo_proc: 'E',
      };

      if (this.startDate) {
        queryParams.startYear = this.startDate.getFullYear();
      }
      if (this.endDate) {
        queryParams.endYear = this.endDate.getFullYear()
      }
      
      let processamentos = await this.processamentos.getList(this.cod_condominio.cod, null, queryParams);
      this.fetchingInitialData = false;
      if (processamentos == null) {
        resolve(false);
        return;
      }
      this.list = processamentos.map(p => {
        let mes_fim_index = null;
        let dt_inicio = this.utils.getDate(p.data);
        let data_fim: Date = null;
        if (p.tipo_reparticao === 'REPARTIDO') {
          let procMonths = p.meses_cobranca;
          mes_fim_index = procMonths.lastIndexOf('S');
          if (mes_fim_index !== -1) {
            data_fim = new Date(dt_inicio);
            data_fim.setMonth(mes_fim_index);
            data_fim.setHours(0, 0, 0, 0);
          }
        }

        let nPrestacoes = p.tipo_reparticao === 'REPARTIDO' ? p.meses_cobranca.match(/S/g).length : 1;

        return {
          disabled: this.procsAlreadyAdded.findIndex(el => el == p.id.toString()) !== -1,
          checked: id_proc_select == p.id,
          id: p.id,
          cod: p.cod,
          descricao: p.descricao,
          dt_inicio: dt_inicio,
          data_venc: this.utils.getDate(p.data_venc),
          dt_fim: data_fim,
          estado: p.is_lancado == 0 ? 'Simulação' : 'Lançado',
          is_lancado: p.is_lancado,
          nPrestacoes: nPrestacoes,
          tipoReparticao: p.tipo_reparticao,
          mesesCobranca: p.meses_cobranca,
          valorTotal: p.valor,
        }
      })
      this.fetchingData = false;
      resolve(true);
    });
  }

  checkRow(row: QuotaExtraRow) {
    if (row.disabled) return;

    let newValue = !row.checked;
    this.list.forEach(el => el.checked = false);
    row.checked = newValue;
  }

  goTo(id: number) {
    let queryParams:any = {}

    queryParams['startYear'] = this.startDate ? this.startDate.getFullYear() : null;
    queryParams['endYear'] = this.endDate ? this.endDate.getFullYear() : null;

    if (id != null) {
      queryParams['id_processamento'] = id;
    }
    this.router.navigate([], {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    setTimeout(() => {
      let navState: AssembleiaQuotaExtraNavState = {
        redirectAfterTo: {
          url: this.router.url,
          action: 'SELECT_QUOTA_EXTRA',
          prevData: queryParams,
        },
        data: {
          cod_condominio: this.cod_condominio
        }
      }
      this.router.navigate(['lancamentos/processamento', id != null ? id : 'criar'], { state: navState });
    });
  }


  @ViewChild('processamentoPDF', { static: false }) processamentoPDF: ProcessamentoPdfComponent;
  async generatePDF(id) {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    await this.processamentoPDF.exportPDF(null, id);
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
  }

  @ViewChild('recibosToDelete', { static: false }) recibosToDelete: ProcessamentoDeleteRecibosComponent;
  presentDeleteAlert(row: QuotaExtraRow): Promise<boolean> {
    return new Promise(async (resolve) => {
      let success = await this.recibosToDelete.open([{ id: row.id, cod: row.cod }]);
      if (success) {
        await this.fetchData();
      }
      resolve(success);
    });
  }
  createBtn(type: 'NEW' | 'COPY') {
    switch (type) {
      case 'COPY':
        let selected = this.list.find(el => !!el.checked);
        if (selected === undefined) {
          this.toastr.error('É necessário selecionar primeiro o processamento que pretende copiar.', 'Selecione um processamento');
          return;
        }
        this.processamentos.copyProcessamento(selected.id).then(_ => this.fetchData()).catch(_ => { });
        break;

      case 'NEW':
        this.goTo(null);
        break;
      default:
        break;
    }
  }

  @ViewChild('processamentoDuplication', { static: false }) processamentoDuplication: ProcessamentoDuplicationModalComponent;
  async confirmSelection() {
    let selected = this.list.find(el => el.checked);
    if (!selected) {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title)
      return;
    }

    if (this.prevProcSelectedId != null && this.prevProcSelectedId != selected.id) {
      try {
        let prevProc = await this.processamentos.getProcessamento(this.prevProcSelectedId);
        if (prevProc.is_lancado == 1 && prevProc.active == 1 && prevProc.id_assembleia_lancamento == this.id_assembleia) {
          let success = await this.recibosToDelete.open(
                [{id: prevProc.id, cod: prevProc.cod}], 
                'REVERT', 
                '<b>Deseja alterar o processamento selecionado?</b>. Todos os avisos e créditos provenientes do processamento lançado através da presente assembleia serão eliminados e o processamento será convertido de novo para simulação.',
                false);
          if (!success) {
            return;
          }
        }
      } catch (err) {
        return;
      }
    }


    if (selected.is_lancado == 0) {
      let proceed = await this.processamentoDuplication.open( { id_processamento: selected.id.toString() });
      if (!proceed) {
        selected.checked = false;
        return;
      }
    }

    this.modalRef.approve(selected);
  }

}
