import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { AppStateService } from '../app-state.service';


@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['./aviso.component.scss']
})
export class AvisoComponent implements OnInit {

  transitionController = new TransitionController();
  submittingForm = false;
  loading = false;
  searchable: boolean = true;
  isCreate: boolean = false;
  avisoDetails = null;
  avisoId = null;
  avisoCod = null;

  // AVISO FORM
  avisoForm = new FormGroup({
    n_aviso: new FormControl(null),
    cod_condominio: new FormControl(null, { validators: Validators.required }),
    cod_fraccao: new FormControl(null, { validators: Validators.required }),
    cod_pagador: new FormControl(null, { validators: Validators.required }),
    cod_proc: new FormControl(null, { validators: Validators.required }),
    cod_zona: new FormControl(null),
    debito: new FormControl(null),
    descricao: new FormControl(null, { validators: Validators.required }),
    dt_emissao: new FormControl(null, { validators: Validators.required }),
    dt_vencimento: new FormControl(null, { validators: Validators.required }),
    mes: new FormControl(null),
    tipo_proc: new FormControl(null),
    valor: new FormControl(null, { validators: Validators.required }),
    ano: new FormControl(null),
  });

  condominioSelected = null;

  fraccaoOpts = [];
  condominoOpts = [];
  processametoOpts = [];

  comp = 'aviso';
  initState = null;
  prevState = null;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public router: Router,
              public message: MessageService,
              public appState: AppStateService,
              public modalService: SuiModalService,
              public location: Location,
              public cdRef:ChangeDetectorRef,
              public appConfig: AppConfigService) {
    this.avisoForm.patchValue({
      dt_emissao : new Date((new Date()).setDate(1)),
      dt_vencimento: new Date((new Date()).setDate(7)), 
    });
  }

  ngOnInit() {
    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.isCreate = true;
    } else {
      this.avisoId = this.route.snapshot.params.id;
    }

    this.init();
  }

  ngAfterViewChecked() { this.cdRef.detectChanges(); }

  ngOnDestroy() {
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: null });
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  condominiosTimer = null;
  condominiosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.condominioSelected); });
    }

    clearTimeout(this.condominiosTimer);
    return new Promise(resolve => {
        if (query) {
          this.condominiosTimer = setTimeout(() => {
            this.api.getAllCondominios(query).subscribe(res => {
                if (res.success) {
                  return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
                } else {
                  return resolve([]);
                }
              });
          }, 400);
        } else {
          this.api.getAllCondominios('NULL').subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.cod + ' - ' + el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }
    });
  };

  init() {
    // HANDLE APPLICATION STATE
    this.initState = this.appState.getInitState(this.comp);
    if (this.initState) {
      this.condominioSelected = { name: this.initState.state.condominioSelected.cod + ' - ' + this.initState.state.condominioSelected.nome, value: this.initState.state.condominioSelected };
      this.appState.clearInitState(this.comp);
    }

    if (this.isCreate) {

    } else {
      this.getDetails();
    }
  }

  isDeleted = false;
  getDetails() {
    this.api.getAvisoDetails(this.avisoId).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.avisoId = res.data.id;
        this.avisoCod = res.data.cod;
        this.avisoDetails = res.data;

        this.isDeleted = (this.avisoDetails.active === '0');

        this.avisoDetails.valor = Number(Number(this.avisoDetails.valor).toFixed(2));
        this.avisoDetails.debito = Number(Number(this.avisoDetails.debito).toFixed(2));

        this.avisoDetails.dt_emissao = (this.avisoDetails.dt_emissao) ? this.utils.getDate(this.avisoDetails.dt_emissao) : null;
        this.avisoDetails.dt_vencimento = (this.avisoDetails.dt_vencimento) ? this.utils.getDate(this.avisoDetails.dt_vencimento) : null;

        if (this.avisoDetails.cod_condominio) this.condominioSelected = { name: this.avisoDetails.cod_condominio + ' - ' + this.avisoDetails.nome_condominio, value: this.avisoDetails.cod_condominio };

        this.getFraccaoList();
        this.getProcessamentosList();

        this.restoreForm();
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  getFraccaoList() {
    let cod = (this.isCreate) ? ((this.avisoForm.getRawValue().hasOwnProperty('cod_condominio')) ? this.avisoForm.getRawValue()['cod_condominio']['cod'] : null) : ((this.avisoDetails && this.avisoDetails.hasOwnProperty('cod_condominio')) ? this.avisoDetails.cod_condominio : null);

    if (!cod) return;

    this.api.getCondFraccoesDetails(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.fraccaoOpts = [];
        res.data.forEach(fraccao => {
          this.fraccaoOpts.push({ name: fraccao.cod + ' - ' + fraccao.nome, value: fraccao.cod });
        });

        this.condominoOpts = [];
        res.data.forEach(fraccao => {
          this.condominoOpts.push({ name: fraccao.condomino_nome, value: fraccao.cod_proprietario });
        });
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  getProcessamentosList() {
    let cod = null;
    let ano = (new Date()).getFullYear();
    if (this.isCreate) {
      cod = this.avisoForm.getRawValue()['cod_condominio']['cod'];
      ano = this.avisoForm.getRawValue()['cod_condominio']['exercicio'];
    } else if (this.avisoDetails) {
      cod = this.avisoDetails.cod_condominio;
      ano = this.avisoDetails.ano;
    }

    if (!cod) return;

    this.api.getProcessamentos(cod, ano, 1, 500, null, null, null, { isLancado: 1 }).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.processametoOpts = [];
        res.data.forEach(proc => {
          this.processametoOpts.push({ name: proc.descricao, value: proc.cod });
        });

      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  restoreForm() {
    this.avisoForm.reset();

    this.fraccaoOpts = [];
    this.condominoOpts = [];
    this.processametoOpts = [];

    this.avisoForm.patchValue({
      n_aviso: this.avisoDetails.n_aviso,
      cod_condominio: this.avisoDetails.cod_condominio,
      cod_fraccao: this.avisoDetails.cod_fraccao,
      cod_pagador: this.avisoDetails.cod_pagador,
      cod_proc: this.avisoDetails.cod_proc,
      cod_zona: this.avisoDetails.cod_zona,
      debito: this.avisoDetails.debito,
      descricao: this.avisoDetails.descricao,
      dt_emissao: this.avisoDetails.dt_emissao,
      dt_vencimento: this.avisoDetails.dt_vencimento,
      mes: this.avisoDetails.mes,
      tipo_proc: this.avisoDetails.tipo_proc,
      valor: this.avisoDetails.valor,
      ano: this.avisoDetails.ano,
    });
  }

  formSubmitted() {
    this.submittingForm = true;

    if (!this.avisoForm.valid) {
      setTimeout(() => { this.submittingForm = false; }, 4000);
      return;
    }

    this.loading = true;

    let data = this.avisoForm.getRawValue();

    data.ano = (new Date(data.dt_emissao)).getFullYear();
    data.mes = (new Date(data.dt_emissao)).getMonth() + 1;

    this.api.saveAviso(this.avisoId, this.avisoCod,data.n_aviso,(data.cod_condominio && data.cod_condominio.hasOwnProperty('cod')) ? data.cod_condominio.cod : data.cod_condominio,data.cod_fraccao,data.cod_pagador,data.cod_proc,data.cod_zona,data.valor,data.descricao,data.dt_emissao,data.dt_vencimento,data.mes,data.tipo_proc,data.valor,data.ano).subscribe(res => {
      if (res.hasOwnProperty('success') && res['success']) {

        // REGISTO DE ACTIVIDADE
        if (!this.avisoId) {  // CREATE
          let descricao = 'Condomínio: ' + data.cod_condominio.cod + ' - ' + data.cod_condominio.nome;
          this.createActivityLog(data.cod_condominio.cod, 'AVISO', res.data.n_aviso, 'Aviso Criado', descricao, res.data.id, res.data.cod) ;
        } else {  // UPDATE
          let condominio = this.condominioSelected;
          let descricao = 'Condomínio: ' + ((condominio.hasOwnProperty('cod')) ? (condominio.cod + ' - ' + condominio.nome) : (condominio.name));
          this.createActivityLog(condominio.cod, 'AVISO', this.avisoDetails.n_aviso, 'Aviso Actualizado', descricao, this.avisoDetails.id, this.avisoDetails.cod);
        }

        this.location.back();
        } else {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        }
        this.submittingForm = false;
        this.loading = false;
      }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.submittingForm = false;
      this.loading = false;
    }); 
  }

  createActivityLog(codCondominio, tipoDoc, nDoc, titulo, descricao, link, cod, obs=null) {
    let obj = { link: link, cod: cod };

    this.api.saveRegistoActividade(codCondominio, tipoDoc, nDoc, titulo, descricao, obj, obs).subscribe(res => {}, err => { });
  }

}
