import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subscription, fromEvent, forkJoin } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ChangeDetectorRef } from '@angular/core';
interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { AppStateService } from '../app-state.service';
import { UtilitiesService } from '../utilities.service';


@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss']
})
export class EntitiesComponent implements OnInit {

  transitionController = new TransitionController();
  tapTransitionController = new TransitionController();
  loading = false;
  loadingModal = false;
  toDelete = [];

  // New Condomínio form
  newConForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    telefone_1: new FormControl(null),
    email_1: new FormControl(null),
    obs: new FormControl(null),
    forma_tratamento: new FormControl(null),
  });
  newConFormSubmitted = false;

  // New Fornecedor form
  newForForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    tipo: new FormControl(null),
    telefone_1: new FormControl(null),
    email_1: new FormControl(null),
    obs: new FormControl(null),
    forma_tratamento: new FormControl(null),
  });
  newForFormSubmitted = false;
  tipoSelectedOpt = '1';
  tipoSelectOpts = [];

  // New Procurador form
  newProcForm = new FormGroup({
    nome: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    telefone_1: new FormControl(null),
    email_1: new FormControl(null),
    obs: new FormControl(null),
    forma_tratamento: new FormControl(null),
  });
  newProcFormSubmitted = false;

  @ViewChild('addEntRef', { static: false }) addEntRef;
  addModalRef = null;
  addModalConfig: any = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  // PROPRIETARIOS TABLE VARIABLES
  proprietariosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true },
    { key: 'telefone_1', name: 'Telefone', type: 'text', sort: null, searchable: true },
    { key: 'email_1', name: 'Email', type: 'text', sort: null, searchable: true },
    { key: 'obs', name: 'Observações', type: 'text', sort: null, searchable: false },
  ];
  proprietariosList: Array<any> = [];
  proprietariosListLength: number = 1000000;
  @ViewChild('proprietariosTableSearch', { static: false }) proprietariosTableSearch: ElementRef;
  proprietariosPage: number = 1;
  proprietariosItemPerPAge: number = 20;
  proprietariosKeyword: string = null;
  proprietariosSortParam: string = null;
  proprietariosSortDirection: string = null;
  proprietariosSearching: boolean = false;


  // INQUILINOS TABLE VARIABLES
  inquilinosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true },
    { key: 'telefone_1', name: 'Telefone', type: 'text', sort: null, searchable: true },
    { key: 'email_1', name: 'Email', type: 'text', sort: null, searchable: true },
    { key: 'obs', name: 'Observações', type: 'text', sort: null, searchable: false },
  ];
  inquilinosList: Array<any> = [];
  inquilinosListLength: number = 1000000;
  @ViewChild('inquilinosTableSearch', { static: false }) inquilinosTableSearch: ElementRef;
  inquilinosPage: number = 1;
  inquilinosItemPerPAge: number = 20;
  inquilinosKeyword: string = null;
  inquilinosSortParam: string = null;
  inquilinosSortDirection: string = null;
  inquilinosSearching: boolean = false;


  // Fornecedores table variables
  forListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: false },
    { key: 'telefone_1', name: 'Telefone', type: 'text', sort: null, searchable: true },
    { key: 'email_1', name: 'Email', type: 'text', sort: null, searchable: true },
    { key: 'obs', name: 'Observações', type: 'text', sort: null, searchable: false },
  ];
  forList: Array<any> = [];
  forListLength: number = 1000000;
  @ViewChild('forTableSearch', { static: false }) forTableSearch: ElementRef;
  forPage: number = 1;
  forItemPerPAge: number = 20;
  forKeyword: string = null;
  forSortParam: string = null;
  forSortDirection: string = null;
  forSearching: boolean = false;

  // Procuradores table variables
  procListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true },
    { key: 'telefone_1', name: 'Telefone', type: 'text', sort: null, searchable: true },
    { key: 'email_1', name: 'Email', type: 'text', sort: null, searchable: true },
    { key: 'obs', name: 'Observações', type: 'text', sort: null, searchable: false },
  ];
  procList: Array<any> = [];
  procListLength: number = 1000000;
  @ViewChild('procTableSearch', { static: false }) procTableSearch: ElementRef;
  procPage: number = 1;
  procItemPerPAge: number = 20;
  procKeyword: string = null;
  procSortParam: string = null;
  procSortDirection: string = null;
  procSearching: boolean = false;

  tabsObjDef: any = [
    { key: 'proprietarios', url: 'proprietario', name: 'Proprietários', active: false },
    { key: 'inquilinos', url: 'inquilino', name: 'Inquilinos', active: false },
    { key: 'fornecedores', url: 'fornecedor', name: 'Fornecedores', active: false },
    { key: 'procuradores', url: 'procurador', name: 'Procuradores', active: false },
    { key: 'outras-entidades', url: 'outra-entidade', name: 'Outras Entidades', active: false },
  ];
  selTab: any = { key: null, name: null };

  searchable: boolean = true;

  comp = 'entities';
  initState = null;
  prevState = null;


  // OUTRAS ENTIDADES VARIABLES
  outrasEntidadesListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false },  // 'ASC', 'DESC'
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: true },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true },
    { key: 'telefone_1', name: 'Telefone', type: 'text', sort: null, searchable: true },
    { key: 'email_1', name: 'Email', type: 'text', sort: null, searchable: true },
    { key: 'obs', name: 'Observações', type: 'text', sort: null, searchable: false },
  ];
  outrasEntidadesList: Array<any> = [];
  outrasEntidadesListLength: number = null;
  @ViewChild('outrasEntidadesTableSearch', { static: false }) outrasEntidadesTableSearch: ElementRef;
  outrasEntidadesPage: number = 1;
  outrasEntidadesItemPerPAge: number = 20;
  outrasEntidadesKeyword: string = null;
  outrasEntidadesSortParam: string = null;
  outrasEntidadesSortDirection: string = null;
  outrasEntidadesSearching: boolean = false;


  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public router: Router,
              public utils: UtilitiesService,
              public location: Location,
              public api: ApiService,
              public appConfig: AppConfigService,
              public cdRef:ChangeDetectorRef,
              public appState: AppStateService,
              public message: MessageService) {
    this.appState.clearMoradaCondominio();
    this.appState.clearDescFraccao();

    this.api.getAllTipos().subscribe(res => {
      if (res.hasOwnProperty('success') &&  res.success) {
        this.tipoSelectOpts = [].concat(res.data);
      }   
    }, err => {});  
  }

  apiSub = null;
  ngOnDestroy() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    setTimeout(() => { if (this.apiSub) this.apiSub.unsubscribe(); }, 1);
  }

  ngAfterViewChecked() {
    this.tabsObjDef.forEach(tab => {
      if (this.router.url.indexOf(tab.url) !== -1) {
        tab.active = true;
        this.selTab = tab;
      } else {
        tab.active = false;
      }
    });

    this.cdRef.detectChanges();
  }

  ngOnInit() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);
    if (this.prevState) {
      this.proprietariosPage = (this.prevState.state.proprietariosPage) ? this.prevState.state.proprietariosPage : 1;
      this.proprietariosKeyword = (this.prevState.state.proprietariosKeyword) ? this.prevState.state.proprietariosKeyword : null;

      this.inquilinosPage = (this.prevState.state.inquilinosPage) ? this.prevState.state.inquilinosPage : 1;
      this.inquilinosKeyword = (this.prevState.state.inquilinosKeyword) ? this.prevState.state.inquilinosKeyword : null;

      this.forPage = (this.prevState.state.forPage) ? this.prevState.state.forPage : 1;
      this.forKeyword = (this.prevState.state.forKeyword) ? this.prevState.state.forKeyword : null;

      this.procPage = (this.prevState.state.procPage) ? this.prevState.state.procPage : 1;
      this.procKeyword = (this.prevState.state.procKeyword) ? this.prevState.state.procKeyword : null;

      this.outrasEntidadesPage = (this.prevState.state.outrasEntidadesPage) ? this.prevState.state.outrasEntidadesPage : 1;
      this.outrasEntidadesKeyword = (this.prevState.state.outrasEntidadesKeyword) ? this.prevState.state.outrasEntidadesKeyword : null;

      this.appState.clearPrevState(this.comp);
    }

    this.animate();

    // Get initial data
    this.getPropList();
  }

  ngAfterViewInit() {
    // Proprietarios input search
    fromEvent(this.proprietariosTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'proprietarios');
    });

    // Inquilinos input search
    fromEvent(this.inquilinosTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'inquilinos');
    });

    // Fornecedores input search
    fromEvent(this.forTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'fornecedores');
    });

    // Procuradores input search
    fromEvent(this.procTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'procuradores');
    });

    // OUTRAS ENTIDADES INPUT SEARCH
    fromEvent(this.outrasEntidadesTableSearch.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'outras-entidades');
    });

    this.addModalConfig = new TemplateModalConfig<IContext, string, string>(this.addEntRef);
    this.addModalConfig.closeResult = "closed";
    this.addModalConfig.size = 'small';
    this.addModalConfig.transition = 'fade up';
    this.addModalConfig.transitionDuration = 400;
  
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  newEntSubmit(targetList) {
    let data = null;
  }

  getPropList() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.apiSub = this.api.getCondominos('proprietarios', this.proprietariosPage, this.proprietariosItemPerPAge, this.proprietariosKeyword, this.proprietariosSortParam, this.proprietariosSortDirection).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        // PROPRIETARIOS
        this.proprietariosList = res.data;
        this.proprietariosList.forEach(el => { el['checked'] = false; });
        this.proprietariosListLength = res.total;
        this.proprietariosSearching = false;

        this.getInqList(false);
        this.getProcList(false);
        this.getForList(false);
      } else {
        this.proprietariosListLength = 0;
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.proprietariosSearching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  getInqList(startProgressBar=true) {
    if (startProgressBar) this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.apiSub = this.api.getCondominos('inquilinos', this.inquilinosPage, this.inquilinosItemPerPAge, this.inquilinosKeyword, this.inquilinosSortParam, this.inquilinosSortDirection).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        // INQUILINOS
        this.inquilinosList = res.data;
        this.inquilinosList.forEach(el => { el['checked'] = false; });
        this.inquilinosListLength = res.total;
        this.inquilinosSearching = false;
      } else {
        this.utils.apiErrorMsg(res);
        this.inquilinosListLength = 0;
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.inquilinosSearching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  getForList(startProgressBar=true) {
    if (startProgressBar) this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.apiSub = this.api.getFornecedores(this.forPage, this.forItemPerPAge, this.forKeyword, this.forSortParam, this.forSortDirection).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.forList = res.data;
        this.forList.forEach(el => { el['checked'] = false; });
        this.forListLength = res.total;
        this.forSearching = false;
      } else {
        this.utils.apiErrorMsg(res);
        this.forListLength = 0;
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.forSearching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  getProcList(startProgressBar=true) {
    if (startProgressBar) this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.apiSub = this.api.getProcuradores(this.procPage, this.procItemPerPAge, this.procKeyword, this.procSortParam, this.procSortDirection).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.procList = res.data;
        this.procList.forEach(el => { el['checked'] = false; });
        this.procListLength = res.total;
        this.procSearching = false;
      } else {
        this.procListLength = 0;
        this.utils.apiErrorMsg(res);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.procSearching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  getOutrasEntidadesList(startProgressBar=true) {
    if (startProgressBar) this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.apiSub = this.api.getCondominos('outras-entidades', this.outrasEntidadesPage, this.outrasEntidadesItemPerPAge, this.outrasEntidadesKeyword, this.outrasEntidadesSortParam, this.outrasEntidadesSortDirection).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        // OUTRAS ENTIDADES
        this.outrasEntidadesList = res.data;
        this.outrasEntidadesList.forEach(el => { el['checked'] = false; });
        this.outrasEntidadesListLength = res.total;
        this.outrasEntidadesSearching = false;
      } else {
        this.utils.apiErrorMsg(res);
        this.outrasEntidadesListLength = 0;
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.outrasEntidadesSearching = false;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  async tableAction(action, targetList) {
    switch (targetList) {
      case 'proprietarios':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            this.addEntity('proprietarios');
            break;
          case 'delete':
            this.toDelete = this.proprietariosList.filter(el => el.checked);
            if (this.toDelete.length > 0) {
              this.presentAlert();
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'inquilinos':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            this.addEntity('inquilinos');
            break;
          case 'delete':
            this.toDelete = this.inquilinosList.filter(el => el.checked);
            if (this.toDelete.length > 0) {
              this.presentAlert();
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'fornecedores':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            this.addEntity('fornecedores');
            break;
          case 'delete':
            this.toDelete = this.forList.filter(el => el.checked);
            if (this.toDelete.length > 0) {
              this.presentAlert();
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'procuradores':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            this.addEntity('procuradores');
            break;
          case 'delete':
            this.toDelete = this.procList.filter(el => el.checked);
            if (this.toDelete.length > 0) {
              this.presentAlert();
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
      case 'outras-entidades':
        switch (action) {
          case 'csv': break;
          case 'pdf': break;
          case 'print': break;
          case 'add':
            this.addEntity('outras-entidades');
            break;
          case 'delete':
            this.toDelete = this.outrasEntidadesList.filter(el => el.checked);
            if (this.toDelete.length > 0) {
              this.presentAlert();
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
    }
  }

  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'proprietarios':
        (ev.target.checked) ? this.proprietariosList.map(el => el.checked = true ) : this.proprietariosList.map(el => el.checked = false );
        break;
      case 'inquilinos':
          (ev.target.checked) ? this.inquilinosList.map(el => el.checked = true ) : this.inquilinosList.map(el => el.checked = false );
          break;
      case 'fornecedores':
        (ev.target.checked) ? this.forList.map(el => el.checked = true ) : this.forList.map(el => el.checked = false );
        break;
      case 'procuradores':
        (ev.target.checked) ? this.procList.map(el => el.checked = true ) : this.procList.map(el => el.checked = false );
        break;
      case 'outras-entidades':
        (ev.target.checked) ? this.outrasEntidadesList.map(el => el.checked = true ) : this.procList.map(el => el.checked = false );
        break;
    }
  }

  pageChange(ev, targetList) {
    switch (targetList) {
      case 'proprietarios':
        this.proprietariosPage = ev;
        this.getPropList();
        break;
      case 'inquilinos':
        this.inquilinosPage = ev;
        this.getInqList();
        break;
      case 'fornecedores':
        this.forPage = ev;
        this.getForList();
        break;
      case 'procuradores':
        this.procPage = ev;
        this.getProcList();
        break;
      case 'outras-entidades':
        this.outrasEntidadesPage = ev;
        this.getOutrasEntidadesList();
        break;
    }
  }

  tableSearch(value, targetList) {
    switch (targetList) {
      case 'proprietarios':
        this.proprietariosKeyword = value.toLowerCase().trim();
        this.proprietariosSearching = true;

        this.getPropList();
        break;
      case 'inquilinos':
          this.inquilinosKeyword = value.toLowerCase().trim();
          this.inquilinosSearching = true;
  
          this.getInqList();
          break;
      case 'fornecedores':
        this.forKeyword = value.toLowerCase().trim();
        this.forSearching = true;

        this.getForList();
        break;
      case 'procuradores':
        this.procKeyword = value.toLowerCase().trim();
        this.procSearching = true;

        this.getProcList();
        break;
      case 'outras-entidades':
        this.outrasEntidadesKeyword = value.toLowerCase().trim();
        this.outrasEntidadesSearching = true;

        this.getOutrasEntidadesList();
        break;
    }
  }

  tableSort(key, targetList) {
    switch (targetList) {
      case 'proprietarios':
        this.proprietariosListCol.forEach(el => {
          if (el.key === key) {
    
            if (el.sort === 'DESC') {
              el.sort = 'ASC';
            } else if (el.sort === 'ASC') {
              el.sort = 'DESC';
            } else if (el.sort === null) {
              el.sort = 'ASC';
            }

            this.proprietariosSortParam = key;
            this.proprietariosSortDirection = el.sort;
            this.getPropList();
          } else {
            el.sort = null;
          }
        });
        break;
      case 'inquilinos':
        this.inquilinosListCol.forEach(el => {
          if (el.key === key) {
    
            if (el.sort === 'DESC') {
              el.sort = 'ASC';
            } else if (el.sort === 'ASC') {
              el.sort = 'DESC';
            } else if (el.sort === null) {
              el.sort = 'ASC';
            }

            this.inquilinosSortParam = key;
            this.inquilinosSortDirection = el.sort;
            this.getInqList();
          } else {
            el.sort = null;
          }
        });
        break;
      case 'fornecedores':
        this.forListCol.forEach(el => {
          if (el.key === key) {
    
            if (el.sort === 'DESC') {
              el.sort = 'ASC';
            } else if (el.sort === 'ASC') {
              el.sort = 'DESC';
            } else if (el.sort === null) {
              el.sort = 'ASC';
            }

            this.forSortParam = key;
            this.forSortDirection = el.sort;
            this.getForList();
          } else {
            el.sort = null;
          }
        });
        break;
      case 'procuradores':
        this.procListCol.forEach(el => {
          if (el.key === key) {
    
            if (el.sort === 'DESC') {
              el.sort = 'ASC';
            } else if (el.sort === 'ASC') {
              el.sort = 'DESC';
            } else if (el.sort === null) {
              el.sort = 'ASC';
            }

            this.procSortParam = key;
            this.procSortDirection = el.sort;
            this.getProcList();
          } else {
            el.sort = null;
          }
        });
        break;
      case 'outras-entidades':
        this.outrasEntidadesListCol.forEach(el => {
          if (el.key === key) {
    
            if (el.sort === 'DESC') {
              el.sort = 'ASC';
            } else if (el.sort === 'ASC') {
              el.sort = 'DESC';
            } else if (el.sort === null) {
              el.sort = 'ASC';
            }

            this.outrasEntidadesSortParam = key;
            this.outrasEntidadesSortDirection = el.sort;
            this.getOutrasEntidadesList();
          } else {
            el.sort = null;
          }
        });
        break;
    }
  }

  setState() {
    this.appState.setPrevState(this.comp, { 
      proprietariosPage: this.proprietariosPage,
      proprietariosKeyword: this.proprietariosKeyword,

      inquilinosPage: this.inquilinosPage,
      inquilinosKeyword: this.inquilinosKeyword,

      forPage: this.forPage,
      forKeyword: this.forKeyword,

      procPage: this.procPage,
      procKeyword: this.procKeyword,

      outrasEntidadesPage: this.outrasEntidadesPage,
      outrasEntidadesKeyword: this.outrasEntidadesKeyword,
    });
  }

  goToDetails(ent, targetList) {
    let obj = null;
    let descricao = null;

    switch (targetList) {
      case 'proprietarios':
        // SAVE THIS STATE
        this.setState();

        obj = { link: ent.cod, cod: ent.nome };
        descricao = 'Nome: ' + ent.nome;
        this.api.saveRegistoActividade(null, 'PROPRIETARIO', null, 'Proprietário Visualizado', descricao, obj).subscribe(res => {}, err => {});

        this.router.navigate(['entidades/proprietario', ent.cod]);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROPRIETÁRIOS / ${ent.nome}` });
        break;
      case 'inquilinos':
        // SAVE THIS STATE
        this.setState();

        obj = { link: ent.cod, cod: ent.nome };
        descricao = 'Nome: ' + ent.nome;
        this.api.saveRegistoActividade(null, 'INQUILINO', null, 'Inquilino Visualizado', descricao, obj).subscribe(res => {}, err => {});

        this.router.navigate(['entidades/inquilino', ent.cod]);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `INQUILINOS / ${ent.nome}` });
        break;
      case 'fornecedores':
        // SAVE THIS STATE
        this.setState();

        obj = { link: ent.id, cod: ent.nome };
        descricao = 'Nome: ' + ent.nome;
        this.api.saveRegistoActividade(null, 'FORNECEDOR', null, 'Fornecedor Visualizado', descricao, obj).subscribe(res => {}, err => {});

        this.router.navigate(['entidades/fornecedor', ent.id]);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `FORNECEDORES / ${ent.nome}` });
        break;
      case 'procuradores':
        // SAVE THIS STATE
        this.setState();

        obj = { link: ent.id, cod: ent.nome };
        descricao = 'Nome: ' + ent.nome;
        this.api.saveRegistoActividade(null, 'PROCURADOR', null, 'Procurador Visualizado', descricao, obj).subscribe(res => {}, err => {});

        this.router.navigate(['entidades/procurador', ent.id]);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROCURADORES / ${ent.nome}` });
        break;
      case 'outras-entidades':
        this.setState();

        obj = { link: ent.cod, cod: ent.nome };
        descricao = 'Nome: ' + ent.nome;
        this.api.saveRegistoActividade(null, 'OUTRA_ENTIDADE', null, 'Outra Entidade Visualizado', descricao, obj).subscribe(res => {}, err => {});

        this.router.navigate(['entidades/entidade', ent.id]);

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `OUTRAS ENTIDADES / ${ent.nome}` });
        break;
    }
  }

  presentAlert() {
    this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => { this.loadingModal = false; this.toDelete = []; })
      .onDeny(() => { this.loadingModal = false; this.toDelete = []; });
  }

  delEntity(targetList) {
    this.loadingModal = true;

    switch (targetList) {
      case 'proprietarios':
        this.api.delCondominos(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.getPropList();

            let req = [];
            this.toDelete.forEach(el => {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: el.cod, cod: el.nome };
              let titulo = 'Proprietário Removido';
              let descricao = 'Nome: ' + el.nome;
              req.push(this.api.saveRegistoActividade(null, 'PROPRIETARIO', null, titulo, descricao, obj));
            });
            forkJoin(req).subscribe(res => {}, err => { });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'inquilinos':
        this.api.delCondominos(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.getInqList();

            let req = [];
            this.toDelete.forEach(el => {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: el.cod, cod: el.nome };
              let titulo = 'Inquilino Removido';
              let descricao = 'Nome: ' + el.nome;
              req.push(this.api.saveRegistoActividade(null, 'INQUILINO', null, titulo, descricao, obj));
            });
            forkJoin(req).subscribe(res => {}, err => { });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'fornecedores':
        this.api.delFornecedores(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.getForList();

            let req = [];
            this.toDelete.forEach(el => {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: el.id, cod: el.nome };
              let titulo = 'Fornecedor Removido';
              let descricao = 'Nome: ' + el.nome;
              req.push(this.api.saveRegistoActividade(null, 'FORNECEDOR', null, titulo, descricao, obj));
            });
            forkJoin(req).subscribe(res => {}, err => { });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'procuradores':
        this.api.delProcuradores(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.getProcList();

            let req = [];
            this.toDelete.forEach(el => {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: el.id, cod: el.nome };
              let titulo = 'Procurador Removido';
              let descricao = 'Nome: ' + el.nome;
              req.push(this.api.saveRegistoActividade(null, 'PROCURADOR', null, titulo, descricao, obj));
            });
            forkJoin(req).subscribe(res => {}, err => { });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
      case 'outras-entidades':
        this.api.delCondominos(this.toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.getInqList();

            let req = [];
            this.toDelete.forEach(el => {
              // REGISTO ACTIVIDADES API CALL
              let obj = { link: el.cod, cod: el.nome };
              let titulo = 'Outra Entidade Removida';
              let descricao = 'Nome: ' + el.nome;
              req.push(this.api.saveRegistoActividade(null, 'OUTRA_ENTIDADE', null, titulo, descricao, obj));
            });
            forkJoin(req).subscribe(res => {}, err => { });

          } else {
            this.utils.apiErrorMsg(res);
          }
          this.alertModalRef.approve();
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
    }
  }

  addEntity(targetList) {
    switch (targetList) {
      case 'proprietarios':
        this.router.navigate(['entidades/proprietario', 'criar']);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.selTab.name.toUpperCase()} / NOVO PROPRIETÁRIO` });
        break;
      case 'inquilinos':
        this.router.navigate(['entidades/inquilino', 'criar']);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.selTab.name.toUpperCase()} / NOVO INQUILINO` });
        break;
      case 'fornecedores':
        this.router.navigate(['entidades/fornecedor', 'criar']);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.selTab.name.toUpperCase()} / NOVO FORNECEDOR` });
        break;
      case 'procuradores':
        this.router.navigate(['entidades/procurador', 'criar']);

        // Emit signal to breadcrumb component
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.selTab.name.toUpperCase()} / NOVO PROCURADOR` });
        break;
      case 'outras-entidades':
        this.router.navigate(['entidades/entidade', 'criar']);

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${this.selTab.name.toUpperCase()} / NOVA ENTIDADE` });
        break;
    }
  }

  setTab(targetList) {
    this.selTab = this.tabsObjDef.find(el => (el.key === targetList));

    switch (targetList) {
      case 'proprietarios':
        this.location.replaceState('/entidades/proprietario/');
        break;
      case 'inquilinos':
        this.location.replaceState('/entidades/inquilino/');
        break;
      case 'fornecedores':
        this.location.replaceState('/entidades/fornecedor/');
        break;
      case 'procuradores':
        this.location.replaceState('/entidades/procurador/');
        break;
      case 'outras-entidades':
        this.location.replaceState('/entidades/entidade/');
        break;
    }
  }

}
