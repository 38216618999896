import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { UserSessionService } from '../user-session.service';
import { UtilitiesService } from '../utilities.service';

interface IContext {
  data:string;
}

type PaymentModalType = 'PAY_DESPESA'|'EDIT_MOVEMENT'|'RECEBIMENTOS'|'LOTE'|'RECEITA_EXTRA'

export interface PaymentForm extends Partial<RecebimentoForm> {
  form_pagam: string,
  dt_pag_valor: Date
  dt_pag: Date
  cod_conta_bancaria: string,
  n_doc_pagam: string,
  valor_pago: string,
  
}

interface RecebimentoForm {
  recibo_print: boolean
  recibo_email: boolean
  recibo_email_msg: string,
  email:string,
  troco:string,
  aReceber:string,
}

export interface PaymentModalInput {
  form: PaymentForm, 
  maxPagamento:number, 
  formPagamDisabled:boolean,
  dateDisabled:boolean,
  dt_default:Date,
  inCaixaVertis:boolean,
  valorPlaceholder:string,
  showNDoc: boolean,
  showSaldo: boolean,
  minDate: Date,
  type: PaymentModalType
  payFunction: (data:PaymentForm) => Promise<boolean>,
}


@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit {

  @Input() cod_condominio = null;

  
  // ---------- UI (START)
  
  // Show
  showSaldo = true;
  showNDoc = true;

  // Disabled
  valorDisabled = false;
  formPagamDisabled = false;
  dataDisabled = false;

  //Text
  submitText = 'Pagar';
  title = 'Pagamento';
  valorPlaceHolder = '';
  
  // ---------- UI (END)
  // Variables
  saldo = null;

  contaDisabled = false;
  contaOptsOrig = [];
  contaOpts = [];
  maxValor:number = 0;
  isLote=false;
  dt_default:Date=null;
  now = new Date();
  loadingModal = false;
  maxDate:Date = null;
  minDate:Date = null;

  // Functions
  payFunction: (data:PaymentForm) => Promise<boolean> = null;


  // ----------Form (START)
  recebimentoForm = new FormGroup({
    recibo_print: new FormControl(null),
    recibo_email: new FormControl(null),
    recibo_email_msg: new FormControl(null),
    email: new FormControl(null),
    troco: new FormControl(null),
    aReceber: new FormControl(null),
    form_pagam: new FormControl(null, { validators: Validators.required }),
    dt_pag: new FormControl(null, { validators: Validators.required }),
    dt_pag_valor: new FormControl(null, { validators: Validators.required }),
    cod_conta_bancaria: new FormControl(null, { validators: Validators.required }),
    n_doc_pagam: new FormControl(null),
    valor_pago: new FormControl(null, { validators: Validators.required }),
  });
  form_pagam:AbstractControl = null;
  cod_conta_bancaria:AbstractControl = null;
  dt_pag:AbstractControl = null;
  dt_pag_valor:AbstractControl = null;
  valor_pago:AbstractControl = null;
  aReceber:AbstractControl = null;
  email:AbstractControl = null;
  recibo_email:AbstractControl = null;
  // ----------Form (END)

  @ViewChild('alertRef', { static: false }) alertRef;
  modalRef = null;
  alertConfig: any = null;

  submittingForm = false;
  valorPagoError = false;

  input: PaymentModalInput = null;

  constructor(
      public api: ApiService,
      public toastr: ToastrService,
      public utils: UtilitiesService,
      public message: MessageService,
      public modalService: SuiModalService,
      public cdRef:ChangeDetectorRef,
      public appConfig: AppConfigService,
      public userSession: UserSessionService,
      ) { 
        this.form_pagam = this.recebimentoForm.controls['form_pagam'];
        this.cod_conta_bancaria = this.recebimentoForm.controls['cod_conta_bancaria'];
        this.dt_pag = this.recebimentoForm.controls['dt_pag'];
        this.dt_pag_valor = this.recebimentoForm.controls['dt_pag_valor'];
        this.valor_pago = this.recebimentoForm.controls['valor_pago'];
        this.aReceber = this.recebimentoForm.controls['aReceber'];
        this.email = this.recebimentoForm.controls['email'];
        this.recibo_email = this.recebimentoForm.controls['recibo_email'];
      }

  ngOnInit() {
    this.setSubscribes();
  }

  ngOnChanges(changes:SimpleChanges) {
    if (changes['cod_condominio'] && changes['cod_condominio'].currentValue !== changes['cod_condominio'].previousValue) {
      this.getContas();
    }
  }

  ngAfterViewInit() {
    this.alertConfig = new TemplateModalConfig<IContext, string, string>(this.alertRef);
    this.alertConfig.closeResult = "closed";
    this.alertConfig.size = 'small';
    this.alertConfig.transition = 'fade up';
    this.alertConfig.transitionDuration = 400;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  fetchingContas = false;
  private getContas():Promise<boolean> {
    return new Promise((resolve) => {
      this.contaOptsOrig = [];
      this.contaOpts = [];
      let cod = this.cod_condominio && this.cod_condominio.cod? this.cod_condominio.cod : this.cod_condominio && this.cod_condominio.value && this.cod_condominio.value.cod;
      if (!cod) {
        resolve(true);
        return;
      }

      this.fetchingContas = true;
      this.api.getCondContasDetails(cod).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.contaOptsOrig =  res.data.map(el => {
            return { name: el.banco, value: el.cod, conta_principal: el.conta_principal, saldo: Number(el.saldo), details: el };
          });
          this.fetchingContas = false;
          this.paymentOptChanged();
        } else {
          this.fetchingContas = false;
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        }
      }, err => {
        this.fetchingContas = false;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      });
    });
  }

  private setSubscribes() {
    this.dt_pag_valor.valueChanges.subscribe(newValue => {
      this.recebimentoForm.patchValue({
        dt_pag: newValue
      });
    });
    this.form_pagam.valueChanges.subscribe(newValue => {
      this.paymentOptChanged();
    });
    this.cod_conta_bancaria.valueChanges.subscribe(newValue => {
      this.contaOptChanged();
    });
    this.aReceber.valueChanges.subscribe(newValue => {
      this.updateTroco();
    });
  }


  open(input: PaymentModalInput): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.modalRef) {
        resolve(false);
        return;
      }
      this.input = input;

      this.loadInfo();

      this.modalRef = this.modalService
      .open(this.alertConfig)
      .onApprove(_ => {
        this.modalRef = null;
        this.input = null;
        resolve(true);
      })
      .onDeny(_ => { 
        this.modalRef = null;
        this.input = null;
        resolve(false);
      });
    });
  }

  private resetComp() {
    this.recebimentoForm.reset();
    this.saldo = null;
    this.isLote = false;
    this.maxDate = new Date();
    this.maxDate.setHours(0,0,0,0);
    this.valorPlaceHolder = '';
    this.dt_default = null;
    this.submitText = 'Pagar';
    this.title = 'Pagamento';
    this.showNDoc = true;
    this.showSaldo = true;
    this.payFunction = null;
    this.minDate = null;
    this.valorDisabled = false;
    this.formPagamDisabled = false;
    this.dataDisabled = false;
    this.maxValor = null;

  }

  private loadInfo() {
    this.resetComp();
    //Independent from input
    if (this.input) {
      switch (this.input.type) {
        case 'EDIT_MOVEMENT':
          this.submitText = 'Submeter';
          this.title = 'Editar Movimento';
          break;
        case 'LOTE':
        case 'PAY_DESPESA':
          this.submitText = 'Pagar';
          this.title = 'Pagamento';
          break;
        case 'RECEBIMENTOS':
          this.submitText = 'Efetuar Pagamento';
          this.title = 'Recibo';
          break;
        case 'RECEITA_EXTRA':
          this.submitText = 'Efetuar Recebimento';
          this.title = 'Recebimento';
          break;
        default:
          break;
      }

      this.valorPlaceHolder = this.input.valorPlaceholder;
      this.isLote = this.input.type === 'LOTE';
      this.dt_default = this.input.dt_default? new Date(this.input.dt_default) : null;
      this.showNDoc = this.input.showNDoc;
      this.showSaldo = this.input.showSaldo
      this.payFunction = this.input.payFunction;
      this.minDate = this.input.minDate ? new Date(this.input.minDate) : null;
      if (this.minDate) this.minDate.setHours(0,0,0,0);
      this.valorDisabled = this.isLote || this.input.type === 'RECEBIMENTOS';
      this.formPagamDisabled = this.input.inCaixaVertis || this.input.type === 'LOTE';
      this.dataDisabled = this.input.dateDisabled || this.input.inCaixaVertis;
      this.maxValor = this.utils.cleanDecimalDigits(this.input.maxPagamento);

      this.recebimentoForm.patchValue({
        recibo_print: this.input.form.recibo_print,
        recibo_email: this.input.form.recibo_email,
        recibo_email_msg: this.input.form.recibo_email_msg,
        email: this.input.form.email,
        form_pagam: this.input.form.form_pagam,
        dt_pag: this.input.form.dt_pag,
        dt_pag_valor: this.input.form.dt_pag_valor,
        cod_conta_bancaria: this.input.form.cod_conta_bancaria,
        n_doc_pagam: this.input.form.n_doc_pagam,
        valor_pago: this.input.form.valor_pago,
      }, { emitEvent: false });
    }

    this.paymentOptChanged(!this.input.form.cod_conta_bancaria);
    this.contaOptChanged();
  }

  updateDisabledInputs() {
    this.dataDisabled = this.input && (this.input.inCaixaVertis || (this.userSession.isCaixaVertisLoggedIn() && !this.isLote && this.form_pagam.value === '1' && (this.input.type !== 'EDIT_MOVEMENT' || this.input.form.form_pagam !== '1')));
    this.contaDisabled = this.contaOpts.length === 1 || !this.form_pagam.value;
  }

  private paymentOptChanged(autoSelectConta=true) {
    if (this.isLote) {

      this.contaOpts = [{ name: 'CAIXA', value: '0' }];

      let dt = new Date(this.dt_default);
      dt.setHours(0,0,0,0);

      if (this.form_pagam.value !== '1') this.form_pagam.patchValue('1');
      if (this.cod_conta_bancaria.value !== '0') this.cod_conta_bancaria.patchValue('0');
      if (!this.dt_pag.value || this.utils.compareDayDates(this.dt_pag.value, dt) !== 0) this.dt_pag.patchValue(dt);
      if (!this.dt_pag_valor.value || this.utils.compareDayDates(this.dt_pag_valor.value, dt) !== 0) this.dt_pag_valor.patchValue(new Date(dt));

      this.updateDisabledInputs();
    } else {

      if (!this.form_pagam.value) {
        this.updateDisabledInputs();
        return;
      }

      if (this.form_pagam.value === '-1') {
        this.recebimentoForm.patchValue({
          form_pagam: null,
          cod_conta_bancaria: null,
        });
        this.updateDisabledInputs();
        return;
      }

      let msg = null;
      if (!this.cod_condominio) {
        msg = 'Para configurar o método de pagamento tem de selecionar o condomínio previamente.';
        if (!this.toastr.findDuplicate(msg, true, false)) {
          this.toastr.info(msg,'Informação');
        }
        this.updateDisabledInputs();
        return;
      }

      if (this.fetchingContas) {
        msg = 'Por favor aguarde. As contas associadas ao condomínio selecionado estão a ser obtidas.';
        if (!this.toastr.findDuplicate(msg, true, false)) {
          this.toastr.info(msg,'Informação');
        }
        this.updateDisabledInputs();
        return;
      }
      
      if (!this.contaOptsOrig.length) {
        msg = 'Não é possível configurar o método de pagamento. O condomínio selecionado não tem contas associadas.';
        if (!this.toastr.findDuplicate(msg, true, false)) {
          this.toastr.info(msg,'Informação');
        }
        this.recebimentoForm.patchValue({
          form_pagam: null,
          cod_conta_bancaria: null,
        });
        this.updateDisabledInputs();
        return;
      }
    
      //Set available contas options
      if (this.form_pagam.value === '1') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name === 'CAIXA' || el.name.indexOf('CX VERTIS') !== -1));
      } else if (this.form_pagam.value === '6') {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name.indexOf('CX ADM') !== -1 || el.name.indexOf('CAIXA ADM') !== -1));
      } else {
        this.contaOpts = this.contaOptsOrig.filter(el => (el.name !== 'CAIXA' && el.name.indexOf('CX VERTIS') === -1 && el.name.indexOf('CX ADM') === -1 && el.name.indexOf('CAIXA ADM') === -1));
      }


      if (!this.contaOpts.length) {
        msg = 'O condomínio selecionado não tem contas definidas para a forma de pagamento selecionada.';
        if (!this.toastr.findDuplicate(msg, true, false)) {
          this.toastr.info(msg,'Informação');
        }
        this.recebimentoForm.patchValue({
          form_pagam:  null,
          cod_conta_bancaria: null,
        });
        this.updateDisabledInputs();
        return;
      }

      if (this.contaOpts.length === 1) {
        this.recebimentoForm.patchValue({
          cod_conta_bancaria:  this.contaOpts[0].value,
        });
      } else if (autoSelectConta) {
        let aux = this.contaOpts.find(el => (el.details.conta_principal === '1' && el.name !== 'CAIXA'));
        this.cod_conta_bancaria.patchValue(aux ? aux.value : null);
        this.contaDisabled = false;
      }

      if (!this.isLote && this.form_pagam.value === '1' && this.userSession.isCaixaVertisLoggedIn()) {
        //Se está a alterar para Numerário
        if (this.input && this.input.form.form_pagam !== '1') {
          //Sets CV 
          let now = new Date();
          now.setHours(0,0,0,0);
          this.recebimentoForm.patchValue({
            dt_pag_valor: new Date(now),
            dt_pag: new Date(now),
          });
        } else {
          //Se era a predefinição original, carrega-a
          this.recebimentoForm.patchValue({
            dt_pag_valor: this.input && this.input.form.dt_pag_valor? new Date(this.input.form.dt_pag_valor) : null,
            dt_pag: this.input && this.input.form.dt_pag? new Date(this.input.form.dt_pag) : null,
          });
        }
      }
    }
    this.updateDisabledInputs();
  }

  private contaOptChanged() {
    let conta = this.contaOptsOrig.find(el => (el.value === this.cod_conta_bancaria.value));
    this.saldo = conta ? conta.saldo : null;
  }

  
  // RECEBIMENTOS FORM
  updateTroco() {
    this.recebimentoForm.patchValue({
      troco: (this.aReceber.value && this.valor_pago.value) ? Number(this.aReceber.value) - Number(this.valor_pago.value) : null,
    });
  }

  // Submit Functions
  private async submit() {
    if (this.loadingModal) return;
    
    if (!this.isValidInput()) {
      this.submittingForm = true;
      setTimeout(() => {
        this.submittingForm = false;
      }, 2000);
      return;
    }

    if (!this.payFunction) return;

    let data = this.recebimentoForm.getRawValue();
    
    this.loadingModal = true;
    let success = await this.payFunction(data);
    this.loadingModal = false;
    if (success) {
      this.modalRef.approve();
    }
  }

  isValidInput(): boolean {
    if (!this.isValid('form_pagam')) return false;
    if (!this.isValid('cod_conta_bancaria')) return false;
    if (!this.isValid('dt_pag_valor')) return false;
    if (!this.isValid('dt_pag')) return false;
    if (!this.isValid('valor_pago')) return false;
    if (!this.isValid('email')) return false;
    return true;
  }

  isValid(field):boolean {
    switch (field) {
      case 'form_pagam':
        return !!this.form_pagam.value;
      case 'cod_conta_bancaria':
        return !!this.cod_conta_bancaria;
      case 'dt_pag_valor':
        return this.dt_pag_valor.value && (!this.minDate || this.utils.compareDayDates(this.dt_pag_valor.value, this.minDate) >= 0) && (!this.maxDate || this.utils.compareDayDates(this.dt_pag_valor.value, this.maxDate) <= 0) 
      case 'dt_pag':
        return this.dt_pag.value && this.dt_pag_valor.value && this.utils.compareDayDates(this.dt_pag.value, this.dt_pag_valor.value) >= 0 && (!this.maxDate || this.utils.compareDayDates(this.dt_pag.value, this.maxDate) <= 0) 
      case 'valor_pago':
        return this.isLote? 
              parseFloat(this.valor_pago.value) === this.maxValor 
              : this.valor_pago.value && this.valor_pago.value.trim() !== '' 
                && (parseFloat(this.valor_pago.value) > 0 || (parseFloat(this.valor_pago.value) === 0 && this.input.type === 'RECEBIMENTOS')) 
                && parseFloat(this.valor_pago.value) <= this.maxValor
      case 'email':
        return !this.recibo_email.value || (this.email.value && this.utils.validateEmail(this.email.value));
      default:
        return false;
    }
  }

}
