import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui'
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { AnexoUpload, contentType, fileExt } from '../business-model-interfaces/anexo';
import { UtilitiesService } from '../utilities.service';
interface IContext {
  data:string;
}

@Component({
  selector: 'app-upload-modal-default',
  templateUrl: './upload-modal-default.component.html',
  styleUrls: ['./upload-modal-default.component.scss']
})
export class UploadModalDefaultComponent implements OnInit {

  multiple = false;
  maxSize = 2097152;

  @ViewChild('addAnexosAlertRef', { static: false }) addAnexosAlertRef;
  addAnexosModalRef = null;
  addAnexosAlertConfig: any = null;

  constructor(
    public toastr: ToastrService,
    public modalService: SuiModalService,
    public appConfig: AppConfigService,
    public utils: UtilitiesService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.addAnexosAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addAnexosAlertRef);
    this.addAnexosAlertConfig.closeResult = "closed";
    this.addAnexosAlertConfig.size = 'tiny';
    this.addAnexosAlertConfig.transition = 'fade';
    this.addAnexosAlertConfig.transitionDuration = 250;
  }    



  open(open:boolean, multiple:boolean, maxSize=2097152): Promise<Array<AnexoUpload>> {
    return new Promise(async (resolve) => {
      this.multiple = multiple;
      this.maxSize = maxSize;
      this.filesToUpload = [];
  
      this.addAnexosModalRef = this.modalService.open(this.addAnexosAlertConfig)
        .onApprove(() => {
          resolve(this.filesToUpload)
        })
        .onDeny(() => {
          resolve([]);
        });

        if (open) {
          let openeded = false;
          let i = 0;
          while (!openeded || i >= 10) {
            i++;
            if (this.fileInputRef) {
              this.fileInputRef.nativeElement.click();
              openeded = true;
            } else {
              await this.utils.sleep(100);
            }
          }
        }
    })
  }

  @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;
  filename = 'Nenhum ficheiro selecionado...';
  filesToUpload:Array<AnexoUpload> = [];
  handleFileInput(files: FileList) {

    for (let i = 0; i < files.length; i++) {
      let fileToUpload = files[i];
      let filename = fileToUpload['name'].toLowerCase();
      this.filename = (this.filename === 'Nenhum ficheiro selecionado...' || !this.filename) ? filename : '; ' + filename;

      if (filename.indexOf('.pdf') === -1 && filename.indexOf('.png') === -1 && filename.indexOf('.jpeg') === -1 && filename.indexOf('.jpg') === -1) {
        this.toastr.error('O ficheiro selecionado não é suportado. Por favor, submeta um ficheiro com extensão uma das seguintes extensões: .pdf, .png, .jpeg, .jpg.' , 'Ups...!', { timeOut: 4000 });
        this.fileInputRef.nativeElement.value = '';
        this.filename = 'Nenhum ficheiro selecionado...';
        return;
      }
  
      if (fileToUpload.size > 8388608) {
        this.toastr.error('O ficheiro selecionado é superior ao tamanho máximo suportado (8Mb)' , 'Ups...!', { timeOut: 4000 });
        this.fileInputRef.nativeElement.value = '';
        this.filename = 'Nenhum ficheiro selecionado...';
        return;
      }
      let ext = fileToUpload.name.substring(fileToUpload.name.lastIndexOf('.'));
      let contentType: contentType = null;
  
      switch (ext) {
        case '.pdf':
          contentType = 'application/pdf';
          break;
        case '.png':
          contentType = 'image/png';
          break;
        case '.jpeg':
          contentType = 'image/jpeg';
          break;
        case '.jpg':
          contentType = 'image/jpg';
          break;
        default:
          this.toastr.error(this.appConfig.errMsg.uploadFile.notPossible.msg,this.appConfig.errMsg.uploadFile.notPossible.title);
          return;
      }
      const reader = new FileReader();
      reader.onload = (ev) => {
        let binaryString = ev.target['result'];
  
        let base64File = binaryString;
        let src = base64File as string;
  
        switch (contentType) {
          case 'application/pdf': base64File  = (base64File as string).replace('data:application/pdf;base64,', ''); break
          case 'image/png': base64File = (base64File as string).replace('data:image/png;base64,', ''); break
          case 'image/jpeg': base64File = (base64File as string).replace('data:image/jpeg;base64,', ''); break
          case 'image/jpg': base64File = (base64File as string).replace('data:image/jpg;base64,', ''); break
        }
        if (this.filesToUpload.find(anexo => anexo.base64File === base64File)) {
          return;
        }

        
        if (this.multiple) {
          this.filesToUpload.push({name: fileToUpload.name, fileExt: ext as fileExt, base64File: base64File as string, size: fileToUpload.size, src: src });
        } else {
          this.filesToUpload = [{name: fileToUpload.name, fileExt: ext as fileExt, base64File: base64File as string, size: fileToUpload.size, src: src}]
        }
      };
      reader.readAsDataURL(fileToUpload);
    }

  }

}
