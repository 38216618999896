import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[appThreeDigitDecimalNumber]'
})
export class ThreeDigitDecimalNumberDirective {
  // Allow decimal numbers and negative values
  // public regex: RegExp = new RegExp(/^\d+[.,]?\d{0,2}$/g);
  // public regex: RegExp = new RegExp(/^\d+[.]?\d{0,2}$/g); 
  public regex: RegExp = new RegExp(/^[-]?\d+[.]?\d{0,3}$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  public specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Enter', 'Control'];

  constructor(public el: ElementRef, public control: NgControl) {
  }

  @HostListener('paste',['$event']) onEvent(event: ClipboardEvent) {
    event.preventDefault();
    let data = event.clipboardData.getData('text');
    this.pasteText(data);
  }

  @HostListener('keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1 || !!event.ctrlKey) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const startPosition = this.el.nativeElement.selectionStart;
    const endPosition = this.el.nativeElement.selectionEnd;
    let next: string = [current.slice(0, startPosition), event.key == 'Decimal' ? '.' : event.key, current.slice(endPosition)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  getFormattedValue(value:string): string {
    return value? value.replace(/,/g, '.') : value;
  }

  pasteText(text) {
    let current: string = this.el.nativeElement.value;
    const startPosition = this.el.nativeElement.selectionStart;
    const endPosition = this.el.nativeElement.selectionEnd;
    let next: string = [current.slice(0, startPosition), text, current.slice(endPosition)].join('');
    next = this.getFormattedValue(next);
    if (next && !String(next).match(this.regex)) {
      return;
    }
    this.control.control.setValue(next);
  }
}