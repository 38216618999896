import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { SuiModalService, TemplateModalConfig, TransitionController, Transition, TransitionDirection } from 'ng2-semantic-ui';
import { FraccoesService } from '../business-logic-services/fraccoes.service';
import { ContenciosoRegAtividade, estadoContencioso, FraccaoWithoutContencioso, saveEstadoContencioso } from '../condominos';
import { CondominosService } from '../business-logic-services/condominos.service';
import { AppConfigService } from '../app-config.service';
import { ToastrService } from 'ngx-toastr';
import { AnexoOpt, ListagemAnexosModalComponent } from '../listagem-anexos-modal/listagem-anexos-modal.component';
import { ModalDefaultComponent, ModalDefaultOptions } from '../modal-default/modal-default.component';
import { BusinessLogicService } from '../business-logic.service';
import { debounceTime, pairwise } from 'rxjs/operators';
import { acordoInfoInput, ConfissaoDividaPdfComponent } from '../confissao-divida-pdf/confissao-divida-pdf.component';
import { CommunicationsService } from '../business-logic-services/communications.service';
import { UserSessionService } from '../user-session.service';
import { ComunicarComponent, EmailToSend, LetterToSend } from '../comunicar/comunicar.component';
interface IContext {
  data: string;
}

@Component({
  selector: 'app-contencioso-modal',
  templateUrl: './contencioso-modal.component.html',
  styleUrls: ['./contencioso-modal.component.scss']
})
export class ContenciosoModalComponent implements OnInit {

  tabsObjDef = [
    { key: 'informacao', name: 'Informação', url: 'informacao', active: true, disabled: false },
    { key: 'atividade', name: 'Atividade', url: 'atividade', active: false, disabled: true },
  ];

  //Contencioso
  @ViewChild('estadoContenciosoAlertRef', { static: false }) estadoContenciosoAlertRef;
  estadoContenciosoModalRef = null;
  estadoContenciosoAlertConfig: any = null;


  estadosContenciosoOptsOrig: Array<{ name, value, id_texto_alteracao_estado }> = [];
  estadosContenciosoOpts: Array<{ name, value, id_texto_alteracao_estado }> = [];

  minContenciosoDate: Date = null;

  formDisabled = false;
  contenciosoForm = new FormGroup({
    type: new FormControl(null),
    id_fraccao: new FormControl(null),
    nome_fracao: new FormControl(null),
    dt_inicio: new FormControl(null, { validators: Validators.required }),
    cod_estado: new FormControl(null, { validators: Validators.required }),
    obs: new FormControl(null, { validators: Validators.required }),
    divida: new FormControl(null),
    periodicidade: new FormControl(null),
    reparticao: new FormControl(null),
    reparticaoValor: new FormControl(null),
    primeiraPrestacao: new FormControl(null),
    ajuste: new FormControl(null),
    ajustePrestacao: new FormControl(null),
    penalizacao: new FormControl([0]),
    id_file: new FormControl(null),
    estado: new FormControl(null),
    active: new FormControl(null),
    saving: new FormControl(false),
    prestacoesAdicionais: new FormArray([]),
  });
  get type() { return this.contenciosoForm.get('type'); }
  get id_fraccao() { return this.contenciosoForm.get('id_fraccao'); }
  get nome_fracao() { return this.contenciosoForm.get('nome_fracao'); }
  get dt_inicio() { return this.contenciosoForm.get('dt_inicio'); }
  get cod_estado() { return this.contenciosoForm.get('cod_estado'); }
  get obs() { return this.contenciosoForm.get('obs'); }
  get saving() { return this.contenciosoForm.get('saving'); }
  get divida() { return this.contenciosoForm.get('divida'); }
  get periodicidade() { return this.contenciosoForm.get('periodicidade'); }
  get reparticao() { return this.contenciosoForm.get('reparticao'); }
  get reparticaoValor() { return this.contenciosoForm.get('reparticaoValor'); }
  get primeiraPrestacao() { return this.contenciosoForm.get('primeiraPrestacao'); }
  get ajuste() { return this.contenciosoForm.get('ajuste'); }
  get ajustePrestacao() { return this.contenciosoForm.get('ajustePrestacao'); }
  get penalizacao() { return this.contenciosoForm.get('penalizacao'); }
  get prestacoesAdicionais(): FormArray { return this.contenciosoForm.get('prestacoesAdicionais') as FormArray; }
  get id_file() { return this.contenciosoForm.get('id_file'); }
  get estado() { return this.contenciosoForm.get('estado'); }
  get active() { return this.contenciosoForm.get('active'); }


  contencioso: estadoContencioso = null;
  contenciosoAtividade: Array<ContenciosoRegAtividade> = [];

  divisionMethodOpts = [
    { name: 'Número Prestações', value: 'M' },
    { name: 'Valor Prestação', value: 'P' }
  ];
  ajusteMethodOpts = [
    { name: 'Primeira Prestação', value: 'P' },
    { name: 'Última Prestação', value: 'U' }
  ];

  constructor(
    public utils: UtilitiesService,
    public api: ApiService,
    public modalService: SuiModalService,
    public condominosService: CondominosService,
    public communications: CommunicationsService,
    public fb: FormBuilder,
    public fraccoesService: FraccoesService,
    public appConfig: AppConfigService,
    public businessLogic: BusinessLogicService,
    public cdRef: ChangeDetectorRef,
    public toastr: ToastrService,
    public userSession: UserSessionService,
  ) { }

  ngOnInit() {
    this.getEstadosContencioso();
    this.setFormSubscribes();
  }


  ngAfterViewInit() {
    this.estadoContenciosoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.estadoContenciosoAlertRef);
    this.estadoContenciosoAlertConfig.closeResult = "closed";
    this.estadoContenciosoAlertConfig.size = 'small';
    this.estadoContenciosoAlertConfig.isClosable = false;
    this.estadoContenciosoAlertConfig.transition = 'fade';
    this.estadoContenciosoAlertConfig.transitionDuration = 250;

    this.changeEstadoContenciosoAlertConfig = new TemplateModalConfig<IContext, string, string>(this.changeEstadoContenciosoAlertRef);
    this.changeEstadoContenciosoAlertConfig.closeResult = "closed";
    this.changeEstadoContenciosoAlertConfig.size = 'tiny';
    this.changeEstadoContenciosoAlertConfig.transition = 'fade';
    this.changeEstadoContenciosoAlertConfig.transitionDuration = 250;

    this.validateFileAlertConfig = new TemplateModalConfig<IContext, string, string>(this.validateFileAlertRef);
    this.validateFileAlertConfig.closeResult = "closed";
    this.validateFileAlertConfig.isClosable = false;
    this.validateFileAlertConfig.size = 'small';
    this.validateFileAlertConfig.transition = 'fade';
    this.validateFileAlertConfig.transitionDuration = 250;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }


  transitionController = new TransitionController();
  animate(transitionName: string = "fade up") {
    this.transitionController.animate(
      new Transition(transitionName, 400, TransitionDirection.In));
  }

  setValidators() {
    this.divida.clearValidators();
    this.periodicidade.clearValidators();
    this.reparticao.clearValidators();
    this.reparticaoValor.clearValidators();
    this.primeiraPrestacao.clearValidators();
    this.prestacoesAdicionais.clearValidators();
    this.ajustePrestacao.clearValidators();
    this.penalizacao.clearValidators();

    if (this.isContencioso('ACORDO')) {
      this.divida.setValidators([Validators.required, Validators.min(0.01)]);
      this.periodicidade.setValidators(Validators.required);
      this.penalizacao.setValidators([Validators.required, Validators.min(0)]);

      if (this.periodicidade.value !== '0') {
        this.primeiraPrestacao.setValidators(Validators.required);
        this.reparticao.setValidators(Validators.required);
        this.reparticaoValor.setValidators([Validators.required, Validators.min(0)]);
      }
    } else if (this.isContencioso('DECIDIDO_AE') || this.isContencioso('EM_EXECUCAO')) {
      this.divida.setValidators([Validators.required, Validators.min(0.01)]);
    }
  }

  isDataInicioDisabled() {
    return this.isSameEstadoContencioAsPrev() && !this.isContencioso('ACORDO'); 
  }

  setFormSubscribes() {
    this.id_fraccao.valueChanges.subscribe(newSel => {
      let fracao = newSel ? this.fraccaoOpts.find(el => el.value === newSel) : null;

      this.minContenciosoDate = fracao && fracao.fracao ? fracao.fracao.data_inicio_proprietario : null;
      this.hasCreditos = fracao && fracao.fracao ? fracao.fracao.hasCreditos : false;
      this.getDivida();
    });
    this.cod_estado.valueChanges.subscribe(el => {
      this.setValidators();

      this.divida.reset();
      this.periodicidade.reset();
      this.reparticao.reset();
      this.reparticaoValor.reset();
      this.ajustePrestacao.reset();
      this.primeiraPrestacao.reset();
      this.ajuste.reset();
      this.prestacoesAdicionais.reset();
      this.penalizacao.reset(0);

      if (this.contencioso && this.isContencioso('DECIDIDO_AE',this.contencioso.cod_estado) && this.isContencioso('DECIDIDO_AE', this.cod_estado.value)) {
        this.dt_inicio.patchValue(this.contencioso.dt_inicio);
      }

      this.getDivida();
    });
    this.reparticaoValor.valueChanges.subscribe(el => {
      this.generateEmAcordoResume();
    });
    this.periodicidade.valueChanges.subscribe(el => {
      this.setValidators();

      this.reparticaoValor.reset();
      this.reparticao.reset();
      this.primeiraPrestacao.reset();
      this.sortPrestacoesAdicionais();
      this.generateEmAcordoResume();
    });
    this.divida.valueChanges.subscribe(el => {
      this.generateEmAcordoResume();
    });
    this.reparticao.valueChanges.subscribe(el => {
      this.generateEmAcordoResume();
    });
    this.primeiraPrestacao.valueChanges.subscribe(el => {
      this.generateEmAcordoResume();
    });
    this.ajustePrestacao.valueChanges.subscribe(el => {
      this.generateEmAcordoResume();
    });
    this.dt_inicio.valueChanges.subscribe(el => {
      if (!el || (this.primeiraPrestacao.value && this.utils.compareDayDates(this.primeiraPrestacao.value, el) < 0)) {
        this.primeiraPrestacao.patchValue(null);
      }
      this.getDivida();
    });
    this.prestacoesAdicionais.valueChanges.pipe(pairwise()).subscribe(([prev, a]) => {
      if (prev.length > a.length) {
        this.generateEmAcordoResume();
      }
    })
    this.penalizacao.valueChanges.subscribe(el => {
      this.generateEmAcordoResume();
    });
  }

  estadoHasDataDividaLabel() {
    return ['4','5','7','8'].find(el => el === this.cod_estado.value);
  }

  actualDividaValue = 0;
  async getDivida() {
    if (this.contencioso && (this.contencioso.active == '0' || this.contencioso.estado == '0')) return;
    if ((!this.isContencioso('ACORDO') && !this.isContencioso('DECIDIDO_AE') && !this.isContencioso('EM_EXECUCAO')) || (!this.id_fraccao.value || !this.dt_inicio.value)) return;
    if (this.isContencioso('ACORDO') && !this.canEditAcordo()) return;

    let divida = await this.condominosService.getDivida(this.cod_proprietario, this.dt_inicio.value, this.id_fraccao.value);
    if (divida != null) {
      this.actualDividaValue = divida;
      if (this.isSameEstadoContencioAsPrev() && !this.isContencioso('ACORDO') && this.contencioso.divida != null) {
        this.divida.patchValue(this.contencioso.divida);
      } else {
        this.divida.patchValue(divida);
      }
    }
    if (divida <= 0) {
      this.toastr.info('O proprietário não possui dívida até à data selecionada.', 'Informação');
    }
  }

  isSameEstadoContencioAsPrev(): boolean {
    return this.contencioso && 
            (
              this.isContencioso('DECIDIDO_AE') && this.isContencioso('DECIDIDO_AE', this.contencioso.cod_estado) 
              ||
              this.isContencioso('EM_EXECUCAO') && this.isContencioso('EM_EXECUCAO', this.contencioso.cod_estado) 
              ||
              this.isContencioso('ACORDO') && this.isContencioso('ACORDO', this.contencioso.cod_estado) 
            )
  }

  presentDifferentValorDivida(): boolean {
    return this.isSameEstadoContencioAsPrev() && this.contencioso.divida != null && parseFloat(this.contencioso.divida) != this.actualDividaValue
  }

  // ---------------------- Select Options ----------------------
  async getEstadosContencioso() {
    this.estadosContenciosoOptsOrig = (await this.fraccoesService.getEstadosContencioso()).map(el => {
      return {
        name: el.nome,
        value: el.cod,
        id_texto_alteracao_estado: el.id_texto_alteracao_estado
      }
    });
    this.estadosContenciosoOpts = JSON.parse(JSON.stringify(this.estadosContenciosoOptsOrig));
  }

  fraccaoOpts: Array<{ name, value, fracao: FraccaoWithoutContencioso }> = [];
  getFracoesWithoutContencioso(cod_proprietario): Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        let fraccoes = await this.condominosService.getFracoesWithoutContencioso(cod_proprietario);
        this.fraccaoOpts = fraccoes.map(el => { return { name: el.cod_condominio + ' - ' + el.nome_condominio + ' (' + el.cod + ' - ' + el.nome + ')', value: el.id, fracao: el } })
      } catch (error) {
        resolve(false);
      }
      resolve(true);
    });
  }

  clearEntry = {name: '-- Limpar --', value: { id:-1, nome: '-- Limpar --' }};
  advogadosTimer = null;
  selAdvogado: { id, nome?} = null;
  advogadosLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => {
        let id = this.selAdvogado ? this.selAdvogado.id : null;
        this.api.getFornecedorDetails(id).subscribe(res => {
          if (res.success) {
            return resolve({ name: res.data.nome, value: res.data });
          } else {
            return resolve([]);
          }
        });
      });
    }

    clearTimeout(this.advogadosTimer);
    return new Promise(resolve => {
      if (query) {
        this.advogadosTimer = setTimeout(() => {
          this.api.getAdvogados(query).subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }, 400);
      } else {
        this.api.getAdvogados().subscribe(res => {
          if (res.success) {
            return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
          } else {
            return resolve([]);
          }
        });
      }
    });
  };

  changedAdvogado() {
    setTimeout(() => {
      if (this.selAdvogado && this.selAdvogado.id === -1) {
        this.selAdvogado = null;
      }
    });
  }
  changedAgenteExecucao() {
    setTimeout(() => {
      if (this.selAgenteExecucao && this.selAgenteExecucao.id === -1) {
        this.selAgenteExecucao = null;
      }
    });
  }
  
  agenteExecucaoTimer = null;
  selAgenteExecucao: { id, nome?} = null;
  agentesExecucaoLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => {
        let id = this.selAgenteExecucao ? this.selAgenteExecucao.id : null;
        this.api.getFornecedorDetails(id).subscribe(res => {
          if (res.success) {
            return resolve({ name: res.data.nome, value: res.data });
          } else {
            return resolve([]);
          }
        });
      });
    }

    clearTimeout(this.agenteExecucaoTimer);
    return new Promise(resolve => {
      if (query) {
        this.agenteExecucaoTimer = setTimeout(() => {
          this.api.getAgentesExecucao(query).subscribe(res => {
            if (res.success) {
              return resolve(res.data.map(el => { return { name: el.nome, value: el }; }));
            } else {
              return resolve([]);
            }
          });
        }, 400);
      } else {
        this.api.getAgentesExecucao().subscribe(res => {
          if (res.success) {
            return resolve([this.clearEntry].concat(res.data.map(el => { return { name: el.nome, value: el }; })));
          } else {
            return resolve([]);
          }
        });
      }
    });
  };


  id_contencioso = null;
  cod_proprietario = null;
  loadingModal = false;
  open(cod_proprietario, id_contencioso): Promise<estadoContencioso> {
    return new Promise(async (resolve) => {
      this.contencioso = null;
      this.cod_proprietario = cod_proprietario;
      this.id_contencioso = id_contencioso;
      this.contenciosoForm.get('id_fraccao').clearValidators();
      this.contenciosoForm.reset();
      this.prestacoesAdicionais.clear();


      if (!id_contencioso) {
        let success = await this.getFracoesWithoutContencioso(cod_proprietario);
        if (!success) {
          resolve(null);
          return;
        }
        if (!this.fraccaoOpts.length) {
          this.toastr.info(this.appConfig.infMsg.entity.noFraccoesWithoutContencioso.msg, this.appConfig.infMsg.entity.noFraccoesWithoutContencioso.title);
          resolve(null);
          return;
        }
      }

      this.loadingModal = true;
      this.estadoContenciosoModalRef = this.modalService
        .open(this.estadoContenciosoAlertConfig)
        .onApprove(() => {
          resolve(this.contencioso);
        })
        .onDeny(() => {
          resolve(this.contencioso);
        });

      if (!id_contencioso) {
        this.contenciosoForm.get('id_fraccao').setValidators(Validators.required);
        this.contenciosoForm.patchValue({
          type: 'NEW'
        });
        this.formDisabled = false;
        this.estadosContenciosoOpts = JSON.parse(JSON.stringify(this.estadosContenciosoOptsOrig)).filter(el => el.value !== '7');
      } else {
        await this.getContenciosoInfo();
      }
      this.loadingModal = false;
    })
  }

  getPrestacoesAdicionaisValor(): number {
    return this.prestacoesAdicionais.controls.reduce((acc, el) => {
      return acc + parseFloat(el.get('valor').value);
    }, 0)
  }

  getPrestacoesTotalValor(): number {
    let total = this.getPrestacoesAdicionaisValor();
    if (this.periodicidade.value !== '0') {
      let acordo = this.getAcordoInfo();
      let valorPrestacoesRecorrentes = acordo.nPrestacoes * acordo.valorPrestacao + acordo.ajuste;
      total += valorPrestacoesRecorrentes;
    }
    return this.utils.cleanDecimalDigits(total);
  }

  isContencioso(type: 'ACORDO' | 'EM_EXECUCAO' | 'DECIDIDO_AE', cod_estado=this.cod_estado.value) {
    switch (type) {
      case 'ACORDO':
        return cod_estado === '5' || cod_estado === '7';
      case 'EM_EXECUCAO':
        return cod_estado === '4';
      case 'DECIDIDO_AE':
        return cod_estado === '8';
      default:
        break;
    }
    return false;
  }

  isAcordoPagamentoValid(): boolean {
    if (!this.isContencioso('ACORDO')) return true;

    if (!this.dt_inicio.value || !this.primeiraPrestacao.value || this.utils.compareDayDates(this.dt_inicio.value, this.primeiraPrestacao.value) > 0) return false;

    let totalPrestacoesPontuais = this.getPrestacoesAdicionaisValor();
    totalPrestacoesPontuais = this.utils.cleanDecimalDigits(totalPrestacoesPontuais);
    if (this.periodicidade.value === '0') {
      if (this.divida.value !== totalPrestacoesPontuais) {
        let msg = 'Valor das prestações diferente do valor da dívida. Por favor, ajuste o valor das prestações';
        if (!this.toastr.findDuplicate(msg, true, false)) {
          this.toastr.info(msg, 'Informação');
        }
        return false;
      }
      return true;
    }

    let acordo = this.getAcordoInfo();
    if (acordo.valorPrestacao === 0) {
      let msg = 'O valor das prestações pontuais perfaz o valor da dívida, pelo que não é possível definir pagamentos recorrentes.';
      if (!this.toastr.findDuplicate(msg, true, false)) {
        this.toastr.info(msg, 'Informação');
      }
      this.periodicidade.patchValue('0');
      return false;
    }

    if (acordo.nPrestacoes === 0) {
      let msg = 'O valor das prestações ultrapassa o valor da dívida. Por favor, ajuste o valor das prestações.';
      if (!this.toastr.findDuplicate(msg, true, false)) {
        this.toastr.info(msg, 'Informação');
      }
      return false;
    }

    if (acordo.nPrestacoes === 1) {
      let msg = 'Não é possível definir pagamentos recorrentes em 1 só prestação. Por favor, adicione uma prestação pontual para esse efeito.';
      if (!this.toastr.findDuplicate(msg, true, false)) {
        this.toastr.info(msg, 'Informação');
      }
      return false;
    }
    return true;
  }


  isFormValid(): boolean {
    this.contenciosoForm.markAllAsTouched();
    if (!this.contenciosoForm.valid) return false;
    if (!this.isAcordoPagamentoValid()) return false;
    if (this.prestacoesAdicionais.controls.find(el => !el.valid)) return false;
    if (this.isContencioso('ACORDO')) {
      let totalPrestacoes = this.getPrestacoesTotalValor();

      if (totalPrestacoes != this.divida.value) {
        this.toastr.error('Valor das prestações diferente do valor da dívida. Por favor, ajuste o valor das prestações.', 'Ups...!');
        return false;
      }
    }
    return true;
  }


  @ViewChild('modalDefault', { static: false }) modalDefault: ModalDefaultComponent;
  @ViewChild('listagemAnexosModal', { static: false }) listagemAnexosModal: ListagemAnexosModalComponent;

  @ViewChild('validateFileAlertRef', { static: false }) validateFileAlertRef;
  validateFileModalRef = null;
  validateFileAlertConfig: any = null;


  fileToUpload = {
    file: null,
    fileExt: null,
    filename: null,
    motivo: null,
  };
  previewFileUpload = {
    file: null,
    fileExt: null,
    filename: null
  };
  canUploadAcordo: (file, fileExt, filename) => Promise<boolean> = (file, fileExt, filename) => {
    return new Promise(async (resolve) => {
      this.previewFileUpload = {
        file: file,
        fileExt: fileExt,
        filename: filename,
      };
      let success = await this.presentValidateFileModal();
      this.previewFileUpload = {
        file: null,
        fileExt: null,
        filename: null
      };
      if (!success) {
        resolve(false);
        return;
      }
      resolve(true);
    });
  }

  acordoAnexos: Array<AnexoOpt> = [
    {
      type: 'CONTENCIOSO_ACORDO',
      label: 'Acordo Assinado',
      filename: 'Acordo_Assinado',
      ext: null,
      base64: null,
      automatic: false,
      isUploaded: false,
      fileId: null,
      changedContent: false,
      maxSize: 10485760,
      uploadFunction: this.canUploadAcordo,
    },
  ];

  @ViewChild('confissaoDividaPDF', { static: false }) confissaoDividaPDF: ConfissaoDividaPdfComponent;
  async printAcordo() {
    this.contenciosoForm.markAllAsTouched();
    if (!this.needPrintAcordo() || !this.canEditAcordo() || !this.isPaymentDataFilled()) return

    let acordo = this.getAcordoInfo();

    let ultimaPrestacao = this.getUltimaPrestacao();

    let fracao = this.fraccaoOpts.find(el => el.value === this.id_fraccao.value);
    let cod_condominio = null;
    let cod_fracao = null;
    if (this.contencioso) {
      cod_condominio = this.contencioso.cod_condominio;
      cod_fracao = this.contencioso.cod_fracao;
    } else if (fracao) {
      cod_condominio = fracao.fracao.cod_condominio;
      cod_fracao = fracao.fracao.cod;
    }
    if (cod_condominio == null || cod_fracao == null) return;

    let input: acordoInfoInput = {
      divida: this.divida.value,
      penalizacao: this.penalizacao.value,
      periodicidade: this.periodicidade.value,
      n_prestacoes: acordo.nPrestacoes,
      ajuste: acordo.ajuste,
      valorPrestacao: acordo.valorPrestacao,
      data_divida: this.dt_inicio.value,
      primeiraPrestacao: this.primeiraPrestacao.value,
      ultimaPrestacao: ultimaPrestacao,
      ajustePrestacao: this.ajustePrestacao.value,
      prestacoesAdicionais: this.prestacoesAdicionais.value,
      cod_entidade: this.cod_proprietario,
      id_fracao: this.id_fraccao.value,
      cod_condominio: cod_condominio,
      cod_fracao: cod_fracao
    }
    let toast = this.toastr.info(this.appConfig.infMsg.exportingPDF.msg, this.appConfig.infMsg.exportingPDF.title);
    await this.confissaoDividaPDF.open(input);
    this.toastr.clear(toast.toastId);
  }


  openedAcordo = false;
  async openAcordoFiles() {
    if (!this.needFolderContencioso() || !this.isFormValid()) return;

    //TODO Atualizar nome do ficheiro e variáveis relativas a se o ficheiro está uploaded
    let anexosOpts = this.getAcordoAnexos();

    let canChangePDF = await this.businessLogic.checkUserAccess('ENTIDADES_CONTENCIOSO_CHANGE_FILE', 'allow', true);
    let anexos = await this.listagemAnexosModal.openModal('Ficheiros', anexosOpts, true, this.canEditAcordo() || (canChangePDF && this.estado.value === '1' && this.active.value === '1')) as Array<AnexoOpt>;
    if (!anexos) return;

    let anexoAcordo = anexos.find(el => el.type === 'CONTENCIOSO_ACORDO');
    this.fileToUpload = {
      file: null,
      fileExt: null,
      filename: null,
      motivo: null,
    };
    if (anexoAcordo.changedContent) {
      let motivo = null;
      if (this.id_file.value != null) {
        let type: 'UPDATE' | 'DELETE' = anexoAcordo.base64 ? 'UPDATE' : 'DELETE';
        motivo = await this.askChangeAcordoFileReason(type);
        if (!motivo) return;
      }
      this.fileToUpload = {
        file: anexoAcordo.base64,
        fileExt: anexoAcordo.ext,
        filename: anexoAcordo.filename,
        motivo: motivo
      };
      await this.submitContencioso(true);
      this.fileToUpload = {
        file: null,
        fileExt: null,
        filename: null,
        motivo: null,
      };
    }
  }

  askChangeAcordoFileReason(type: 'DELETE' | 'UPDATE'): Promise<string> {
    return new Promise(async (resolve) => {
      let msg = 'Deseja ';
      if (type === 'DELETE') {
        msg += 'remover';
      } else {
        msg += 'substituir';
      }
      msg += ' o acordo assinado?';
      let res = await this.modalDefault.open('Atenção', msg, null, null, 'Sim', 'tiny', null, null, true, true);
      if (!res) {
        resolve(null);
        return;
      }
      resolve(res.motivo);
    });
  }

  getAcordoAnexos(): Array<AnexoOpt> {
    let acordo = this.acordoAnexos.find(el => el.type === 'CONTENCIOSO_ACORDO');
    let filename = 'Acordo_Assinado_' + this.utils.getFormatedDate(this.dt_inicio.value);
    if (this.contencioso) {
      let fraccao = this.contencioso.cod_fracao + '-' + this.contencioso.nome_fracao;
      let condominio = this.contencioso.cod_condominio + '-' + this.contencioso.nome_condominio;
      filename = 'Acordo_Assinado_' + this.contencioso.nome_proprietario + '_' + condominio + '_' + fraccao;
    }
    acordo = {
      ...acordo,
      isUploaded: this.id_file.value != null,
      fileId: this.id_file.value,
      filename: this.utils.getFileNameFormatted(filename),
    }
    return [acordo]
  }

  presentValidateFileModal(): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.openedAcordo = false;
      this.validateFileModalRef = this.modalService
        .open(this.validateFileAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => { resolve(false) });
    });
  }

  openAcordoToCheck() {
    let contentType = this.utils.getBase64Prefix(this.previewFileUpload.fileExt);
    this.utils.downloadFile(contentType + this.previewFileUpload.file, this.previewFileUpload.filename);
    this.openedAcordo = true;
  }

  confirmAcordoAssinadoFile() {
    if (this.openedAcordo) {
      this.validateFileModalRef.approve();
    }
  }

  formFeedback = {
    show: false,
    timeout: null,
  }

  submitContencioso(uploadFile = false): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.utils.setFormFeedback(this.formFeedback);
      if ((!uploadFile && !this.canEditAcordo()) || !this.isFormValid()) {
        resolve(false);
        return;
      }

      let data: saveEstadoContencioso = {
        id: this.id_contencioso,
        id_fracao: this.id_fraccao.value,
        cod_proprietario: this.cod_proprietario,
        cod_estado: this.cod_estado.value,
        obs: this.obs.value,
        dt_inicio: new Date(this.dt_inicio.value),
        estado: '1',
      }

      if (this.isContencioso('ACORDO')) {
        data = {
          ...data,
          periodicidade: this.periodicidade.value,
          reparticao: this.reparticao.value,
          valor_reparticao: this.reparticaoValor.value,
          primeira_prestacao: this.primeiraPrestacao.value,
          ajuste_prestacao: this.ajustePrestacao.value,
          penalizacao: this.penalizacao.value,
          prestacoes_adicionais: this.prestacoesAdicionais.value.map(el => {
            return {
              data: new Date(el.data),
              valor: el.valor,
            }
          }),
        }
        if (uploadFile) {
          data.acordoAssinado = {
            file: this.fileToUpload.file,
            fileExt: this.fileToUpload.fileExt,
            filename: this.fileToUpload.filename,
            motivo: this.fileToUpload.motivo,
          }
        }
      } else if (this.isContencioso('EM_EXECUCAO')) {
        data = {
          ...data,
          id_advogado: this.selAdvogado ? this.selAdvogado.id : null,
          id_agente_execucao: this.selAgenteExecucao ? this.selAgenteExecucao.id : null,
        }
      }

      let isChangingEstado = (!this.isContencioso('ACORDO') && (!this.contencioso ||data.cod_estado !== this.contencioso.cod_estado)   
                              || 
                              (!!data.acordoAssinado && data.acordoAssinado.file != null && this.contencioso.id_file == null));

      this.saving.patchValue(true);
      try {
        let estado = await this.condominosService.saveEstadoContencioso(data);
        this.id_contencioso = estado.id;

        let estadoContencioso = this.estadosContenciosoOpts.find(el => el.value === data.cod_estado);
        if (isChangingEstado && estadoContencioso.id_texto_alteracao_estado != null) {

          let options: ModalDefaultOptions = {
            cancelText: 'Não'
          }
          let res = await this.modalDefault.open('Envio Comunicação', 'O estado contencioso irá ser <b>atualizado</b>.', 'Deseja <b>enviar</b> uma <b>comunicação</b> ao condómino a informá-lo relativamente ao seu novo estado contencioso?', 'Enviar', null, 'small', null, null, true, false, options)
          if (res && res.action === 'CONFIRM') {
            await this.communicateContenciosoChange(estadoContencioso.id_texto_alteracao_estado);
            // if (!success) {
            //   resolve(true);
            //   return;
            // }
          }
        }
        if (!uploadFile) {
          this.printAcordo();
        }
        await this.getContenciosoInfo();
      } catch (error) {
        this.saving.patchValue(false);
        resolve(false);
        return;
      }
      this.saving.patchValue(false);
      resolve(true);
    });
  }


  @ViewChild('comunicar', { static: false }) comunicar: ComunicarComponent;
  communicateContenciosoChange(id_texto): Promise<boolean> {
    return new Promise(async (resolve) => {
      // let advogadoObj = this.api.fornece;
      // let nomeAdvogado = this.selAdvogado && this.selAdvogado.hasOwnProperty('nome')? this.selAdvogado.nome : null;
      // let nomeAgenteExecucao = this.selAgenteExecucao && this.selAgenteExecucao.hasOwnProperty('nome')? this.selAgenteExecucao.nome : null;
      // let fillingParams:any = {};
      // if (!!nomeAdvogado) fillingParams['advogado'] = nomeAdvogado;
      // if (!!nomeAgenteExecucao) fillingParams['agente_execucao'] = nomeAgenteExecucao;
      let info = await this.communications.getTextoFilledToCommunicate(id_texto, this.cod_proprietario, this.id_fraccao.value);
      if (!info) {
        resolve(false);
        return;
      }
      let cartas: Array<LetterToSend> = [];
      let emails: Array<EmailToSend> = [];

      // Process here cartas
      if (info.emailToSendInfo.communicationMethod !== 'E') {
        let carta: LetterToSend = {
          morada_condominio: info.emailToSendInfo.morada_condominio,
          morada_entidade: info.emailToSendInfo.morada_proprietario,
          morada_fact_entidade: info.emailToSendInfo.morada_fact_proprietario,
          nome_condominio: info.emailToSendInfo.nome_condominio,
          cod_condominio: info.emailToSendInfo.cod_condominio,
          nome_pagador: info.emailToSendInfo.nome_proprietario,
          subject: info.texto.assunto,
          body: info.texto.bodyMsg,
          nif_condominio: info.emailToSendInfo.nif_condominio,
          cod_pagador: info.emailToSendInfo.cod_proprietario,
          cod_fraccao: info.emailToSendInfo.cod_fraccao,
          nome_fraccao: info.emailToSendInfo.nome_fraccao,
          id_texto_predefinido: info.texto.id,
          attachments: [],
          data_divida: this.utils.getDate(info.emailToSendInfo.data_divida, undefined, undefined, true),
          id_reconciliacao: null,
        };
        cartas.push(carta);
      }

      if (info.emailToSendInfo.communicationMethod !== 'C') {
        let email: EmailToSend['email'] = {
          from: this.appConfig.company.email,
          fromName: this.appConfig.company.emailFromName,
          to: [{ name: info.emailToSendInfo.nome_proprietario, email: info.emailToSendInfo.email }],
          bcc: [],
          cc: [],
          subject: info.texto.assunto,
          body: info.texto.bodyMsg,
          attachment: [],
          filename: '',
          origin: 'CHANGE_CONTENCIOSO',
          userId: this.userSession.getUserId(),
          id_comunicacao: null,
        }

        let email_list: EmailToSend['email_list'] = [{
          nomeEntidade: info.emailToSendInfo.nome_proprietario,
          cod_fraccao: info.emailToSendInfo.cod_fraccao,
          tipo: 'PROPRIETARIO',
          cod: info.emailToSendInfo.cod_proprietario,
          emails: [info.emailToSendInfo.email]
        }]

        let emailToSend: EmailToSend = {
          email: email,
          cod_condominio: info.emailToSendInfo.cod_condominio,
          id_texto_predefinido: info.texto.id,
          data_divida: info.emailToSendInfo.data_divida,
          email_list: email_list
        };
        emails.push(emailToSend);
      }

      let success = await this.comunicar.communicate('CHANGE_CONTENCIOSO', cartas, emails);
      resolve(success);
    });
  }

  // openDespesasCTTModal(entities:Array<CommunicationToSend>): Promise<{status?: 'OPENED', cttList?: Array<any>, id_registo_ctt?: Array<any>}> {
  //   return new Promise(async (resolve) => {
  //     let base64 = await this.getLetterBase64Format(entities[0]);
  //     let despesasCTT:Array<EntityDespesasCTTModalInput> = await Promise.all(entities.map(async el => {
  //       let aux:EntityDespesasCTTModalInput = {
  //         cod: el.cod_pagador,
  //         nomeEntidade: el.nome_pagador,
  //         cod_condominio: this.noticeInfo.cod_condominio,
  //         condominioNome: this.noticeInfo.nome_condominio,
  //         cod_fraccao: el.cod_fraccao,
  //         nome_fraccao: el.nome_fraccao,
  //         tipo_entidade: 'PROPRIETARIO',
  //         base64FileArr: [],
  //         id_texto_predefinido: el.notice_method,
  //         data_divida: this.noticeInfo.data,
  //         id_reconciliacao: this.id_reconciliacao,
  //       }
  //       return aux;
  //     }));
  //     let res = await this.despesasCttModal.open(despesasCTT, this.noticeInfo.cod_condominio, 'Envio de Avisos e Cobranças', base64);
  //     resolve(res);
  //   })
  // }

  isPaymentDataFilled(): boolean {
    return this.periodicidade.valid && this.divida.valid && this.reparticao.valid && this.reparticaoValor.valid && this.primeiraPrestacao.valid && this.dt_inicio.valid && !this.prestacoesAdicionais.controls.find(el => el.invalid) && this.ajustePrestacao.valid && this.ajuste.valid && this.penalizacao.valid && this.isAcordoPagamentoValid()
  }

  contenciosoSummary = null;
  contenciosoFormError = null;
  generateEmAcordoResume() {
    this.contenciosoFormError = null;
    this.contenciosoSummary = null;
    this.ajuste.patchValue(0);
    this.ajustePrestacao.clearValidators();

    if (!this.isPaymentDataFilled()) return;

    let periodicidade = this.appConfig.periodicityOpts.find(el => el.value === this.periodicidade.value);
    if (!periodicidade) return;

    let contenciosoSummary = '<p class="no-margin">';

    contenciosoSummary += "Será criado um acordo no valor de <b class='white-space-nowrap'>" + Number(this.divida.value).toFixed(2) + " €</b>, dívida existente ao dia <b class='white-space-nowrap'>" + this.utils.getFormatedDate(this.dt_inicio.value) + '</b>';

    if (this.reparticao.value !== 'M' && this.reparticao.value !== 'P') {
      contenciosoSummary += ", com as seguintes prestações:";
    } else {
      let acordo = this.getAcordoInfo();

      let nPrestacoes = acordo.nPrestacoes;
      let valorPrestacao = acordo.valorPrestacao;
      let ajuste = acordo.ajuste;

      this.ajuste.patchValue(ajuste);
      if (!this.ajustePrestacao.value) this.ajustePrestacao.patchValue('U');

      contenciosoSummary += ", com periodicidade <b class='white-space-nowrap'>" + periodicidade.name + "</b>, repartido em <b class='white-space-nowrap'>" + nPrestacoes + " prestações</b> no valor de <b class='white-space-nowrap'>" + Number(valorPrestacao).toFixed(2) + " €</b>";
      if (this.ajuste.value > 0) {
        contenciosoSummary += " com um ajuste de <b class='white-space-nowrap'>" + Number(this.ajuste.value).toFixed(2) + " €</b>";
        if (this.ajustePrestacao.value === 'P') contenciosoSummary += ' que será cobrado na primeira prestação';
        if (this.ajustePrestacao.value === 'U') contenciosoSummary += ' que será cobrado na última prestação';
      }
      contenciosoSummary += '.';

      let lastDate = this.getUltimaPrestacao();
      contenciosoSummary += "<br>A primeira prestação terá início em <b class='white-space-nowrap'>" + this.utils.getFormatedDate(this.primeiraPrestacao.value) + '</b>';
      contenciosoSummary += ", sendo a última prestação cobrada em <b class='white-space-nowrap'>" + this.utils.getFormatedDate(lastDate) + '</b>.';

      if (this.prestacoesAdicionais.length) {
        contenciosoSummary += '<br>Serão ainda cobradas as seguintes prestações:';
      }
      if (this.ajuste.value > 0) {
        this.ajustePrestacao.setValidators(Validators.required);
      }
    }

    contenciosoSummary += '</p>';

    if (this.prestacoesAdicionais.length) {
      contenciosoSummary += '<ul class="margin-top-5 margin-btm-5">';
      for (let i = 0; i < this.prestacoesAdicionais.length; i++) {
        const element = this.prestacoesAdicionais.at(i);
        contenciosoSummary += '<li>Data: ' + this.utils.getFormatedDate(element.get('data').value) + ', Valor: ' + element.get('valor').value + ' ' + this.appConfig.currencySymbol + '</li>';
      }
      contenciosoSummary += '</ul>';
    }

    if (this.penalizacao.value > 0) {
      contenciosoSummary += '<p class="no-margin">';
      contenciosoSummary += "O incumprimento do acordo resultará numa penalização de <b class='white-space-nowrap'>" + this.penalizacao.value + " %</b> sobre a restante dívida.";
      contenciosoSummary += '</p>';
    }

    this.contenciosoSummary = contenciosoSummary;
  }

  getAcordoInfo(): { nPrestacoes: number, valorPrestacao: number, ajuste: number } {
    let totalPrestacoesPontuais = this.getPrestacoesAdicionaisValor();
    totalPrestacoesPontuais = this.utils.cleanDecimalDigits(totalPrestacoesPontuais);

    let nPrestacoes = null;
    let valorPrestacao = null;
    let divida = this.divida.value - totalPrestacoesPontuais;
    if (divida < 0) divida = 0;
    if (this.reparticao.value === 'M') {
      nPrestacoes = parseInt(this.reparticaoValor.value);
      valorPrestacao = Math.floor((divida / nPrestacoes) * 100) / 100;
    } else {
      valorPrestacao = this.utils.cleanDecimalDigits(this.reparticaoValor.value);
      nPrestacoes = Math.floor(divida / valorPrestacao);
    }
    let ajuste = this.utils.cleanDecimalDigits(divida - nPrestacoes * valorPrestacao);

    return {
      nPrestacoes: nPrestacoes,
      valorPrestacao: valorPrestacao,
      ajuste: ajuste,
    }
  }

  sortPrestacoesAdicionais() {
    this.prestacoesAdicionais.controls.sort((a, b) => {
      let date1 = a.get('data').value;
      let date2 = b.get('data').value;
      if (!date1 && !date2) return 0;
      if (!date1) return 1;
      if (!date2) return -1;
      return this.utils.compareDayDates(date1, date2);
    })
    this.prestacoesAdicionais.value.sort((a, b) => {
      let date1 = a.data;
      let date2 = b.data;
      if (!date1 && !date2) return 0;
      if (!date1) return 1;
      if (!date2) return -1;
      return this.utils.compareDayDates(date1, date2);
    })

    // let prestacoes:Array<{data:Date,valor}> = this.prestacoesAdicionais.value.map(el => {
    //   return {
    //     data: el.data? this.utils.getDate(el.data) : null,
    //     valor: el.valor
    //   }
    // })
    // prestacoes.sort((a,b) => {
    //   let date1 = a.data;
    //   let date2 = b.data;
    //   if (!date1 && !date2) return 0;
    //   if (!date1) return 1;
    //   if (!date2) return -1;
    //   return this.utils.compareDayDates(date1, date2);
    // })
    // this.prestacoesAdicionais.setValue(prestacoes);

    if (this.periodicidade.value === '0') {
      let firstDate = this.prestacoesAdicionais.controls.find(el => !!el.get('data').value);
      let minDate = firstDate ? new Date(firstDate.get('data').value) : null;
      this.primeiraPrestacao.patchValue(minDate);
    }
  }

  getUltimaPrestacao(): Date {
    if (!this.isPaymentDataFilled()) return null;
    if (this.periodicidade.value === 'M') {
      return this.prestacoesAdicionais.controls.reduce((maxDate, el) => {
        if (!maxDate || this.utils.compareDayDates(el.get('data').value, maxDate) > 0) {
          maxDate = new Date(el.get('data').value);
        }
        return maxDate;
      }, null as Date);
    }
    let lastDate = new Date(this.primeiraPrestacao.value);
    let periodicidade = this.appConfig.periodicityOpts.find(el => el.value === this.periodicidade.value);
    let incByPrestacao = periodicidade.monthInc;
    let acordo = this.getAcordoInfo();
    let nPrestacoes = acordo.nPrestacoes - 1;
    lastDate.setMonth(lastDate.getMonth() + (incByPrestacao * nPrestacoes));
    return lastDate;
  }


  addPrestacaoAdicional() {
    let group = this.createPrestacaoAdicionalControl(null, null);
    this.prestacoesAdicionais.push(group);
  }

  deletePrestacaoAdicional(index) {
    (this.prestacoesAdicionais as FormArray).removeAt(index);
    this.sortPrestacoesAdicionais();
  }


  // Contencioso Actions
  @ViewChild('changeEstadoContenciosoAlertRef', { static: false }) changeEstadoContenciosoAlertRef;
  changeEstadoContenciosoModalRef = null;
  changeEstadoContenciosoAlertConfig = null
  disablingModalDefault = false;

  changeEstadoContenciosoData: { type: 'ACTIVATE' | 'DISABLE' } = {
    type: null
  }
  disableContenciosoForm = new FormGroup({
    disableContenciosoRazao: new FormControl(null, [Validators.required, Validators.minLength(1)]),
  });
  get disableContenciosoRazao() { return this.disableContenciosoForm.get('disableContenciosoRazao'); }

  presentChangeEstadoContenciosoModal(type: 'ACTIVATE' | 'DISABLE'): Promise<boolean> {
    return new Promise(async (resolve) => {
      this.disableContenciosoForm.reset();
      this.changeEstadoContenciosoData.type = type;
      this.changeEstadoContenciosoModalRef = this.modalService
        .open(this.changeEstadoContenciosoAlertConfig)
        .onApprove(() => { resolve(true) })
        .onDeny(() => { resolve(false) });
    });
  }

  changeEstadoContencioso() {
    return new Promise(async (resolve) => {
      this.disableContenciosoForm.markAllAsTouched();
      if (!this.disableContenciosoForm.valid || this.disableContenciosoRazao.value.trim() === '') {
        resolve(false);
        return;
      }
      if (!this.id_contencioso) return;

      this.disablingModalDefault = true;
      let success = await this.condominosService.changeEstadoContencioso(this.id_contencioso, this.disableContenciosoRazao.value, this.changeEstadoContenciosoData.type);
      this.disablingModalDefault = false;

      if (!success) {
        this.changeEstadoContenciosoModalRef.deny();
        resolve(false);
        return;
      }

      //Ver se fecho o modal
      this.changeEstadoContenciosoModalRef.approve();
      this.contencioso.estado = this.changeEstadoContenciosoData.type === 'ACTIVATE' ? '1' : '0';
      this.estadoContenciosoModalRef.approve();
      resolve(true);
    })
  }

  needFolderContencioso() {
    return this.id_contencioso && this.isContencioso('ACORDO');
  }

  needPrintAcordo() {
    return this.isContencioso('ACORDO') && this.id_file.value == null && this.active.value === '1' && this.estado.value === '1';
  }

  needDisableContencioso() {
    return this.id_contencioso && this.active.value === '1' && this.estado.value === '1';
  }

  needActivateContencioso() {
    return this.id_contencioso && this.active.value === '1' && this.estado.value === '0';
  }

  canEditAcordo(): boolean {
    return (!this.isContencioso('ACORDO') || this.id_file.value === null) && (this.id_contencioso == null || (this.estado.value === '1' && this.active.value === '1'));
  }


  hasCreditos = false;
  async getContenciosoInfo(): Promise<boolean> {
    return new Promise(async (resolve) => {
      // this.contenciosoForm.reset();
      this.contenciosoForm.clearValidators();
      if (!this.id_contencioso) {
        resolve(false);
        return;
      }
      let res = await this.condominosService.getEstadoContencioso(this.id_contencioso);
      if (!res || !res.contencioso) {
        resolve(false);
        return;
      }
      this.tabsObjDef.find(el => el.key === 'atividade').disabled = false;
      this.contencioso = res.contencioso;
      this.contenciosoAtividade = res.atividade;
      this.hasCreditos = res.hasCreditos;
      this.contenciosoForm.patchValue({
        type: 'EDIT',
        estado: this.contencioso.estado,
        active: this.contencioso.active,
        id_file: this.contencioso.id_file,
        id_fraccao: this.contencioso.id_fracao,
        cod_estado: this.contencioso.cod_estado,
        divida: this.utils.cleanDecimalDigits(this.contencioso.divida),
        obs: this.contencioso.obs,
        dt_inicio: this.contencioso.dt_inicio,
        periodicidade: this.contencioso.periodicidade,
        reparticao: this.contencioso.reparticao,
        reparticaoValor: this.contencioso.valor_reparticao,
        primeiraPrestacao: this.contencioso.primeira_prestacao,
        penalizacao: this.contencioso.penalizacao,
        ajustePrestacao: this.contencioso.ajuste_prestacao,
        nome_fracao: this.contencioso.cod_condominio + ' - ' + this.contencioso.nome_condominio + ' (' + this.contencioso.cod_fracao + ' - ' + this.contencioso.nome_fracao + ')',
      }, {
        emitEvent: false
      });

      this.selAdvogado = this.contencioso.id_advogado ? { id: this.contencioso.id_advogado } : null;
      this.selAgenteExecucao = this.contencioso.id_agente_execucao ? { id: this.contencioso.id_agente_execucao } : null;


      let prestacoesAdicionais = this.fb.array([]);
      //Adicionar prestacoes adicionais
      this.contencioso.prestacoes_adicionais.forEach(el => {
        let group = this.createPrestacaoAdicionalControl(el.data, el.valor);
        prestacoesAdicionais.push(group);
      });
      this.contenciosoForm.setControl('prestacoesAdicionais', prestacoesAdicionais);

      this.formDisabled = !this.canEditAcordo();

      this.setValidators();
      this.generateEmAcordoResume();

      if (this.contencioso.cod_estado === '7') {
        this.estadosContenciosoOpts = JSON.parse(JSON.stringify(this.estadosContenciosoOptsOrig));
      } else {
        this.estadosContenciosoOpts = JSON.parse(JSON.stringify(this.estadosContenciosoOptsOrig)).filter(el => el.value !== '7');
      }
      resolve(true);
    });
  }

  createPrestacaoAdicionalControl(data, valor) {
    let group = this.fb.group({ data: [data, Validators.required], valor: [valor, [Validators.required, Validators.min(0.01)]] })
    group.valueChanges.pipe(debounceTime(400)).subscribe(el => {
      if (el.valor && el.valor.trim() !== '' && el.data) {
        this.sortPrestacoesAdicionais();
        this.generateEmAcordoResume();
      }
    });
    return group;
  }
}
