import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { ApiService } from '../api.service';
import { forkJoin, Observable } from 'rxjs';
import * as moment from 'moment';
import { UtilitiesService } from '../utilities.service';

@Component({
  selector: 'app-listagem-despesas-utilizador',
  templateUrl: './listagem-despesas-utilizador.component.html',
  styleUrls: ['./listagem-despesas-utilizador.component.scss']
})
export class ListagemDespesasUtilizadorComponent implements OnInit {

  @Input() data = {
    utilizadores: null,
    startDate: null,
    endDate: null
  }

  @ViewChild('pdf', { static: false }) pdfController;


  now: Date = null;
  filename: string = 'Listagem_Despesas';
  title: string = 'Listagem de Despesas';

  despesas = [];



  constructor(public appConfig: AppConfigService,
    public api: ApiService,
    public utils: UtilitiesService) { }

  ngOnInit() {
  }

  clearData(timeout:number = 1500) {
    setTimeout(() => this.despesas = [], timeout);
  }

  updateData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.now = new Date();

      let req = []

      if (this.data.utilizadores.find(el => el === 'all')) this.data.utilizadores = ['all'];

      this.data.utilizadores.forEach(u => {
        req.push(this.api.getListagemDespesas(this.data.startDate, this.data.endDate, u));
      });

      forkJoin(req).subscribe(resArr => {
        if (!resArr.find(el => !el.hasOwnProperty('success') || (el.hasOwnProperty('success') && !el.success))) {
          this.despesas = [];
          resArr.forEach(res => {
            res.data.forEach(d => {
              let nome = (d.utilizador as string).split(" ");
              this.despesas.push({ 
                utilizador: nome[0].charAt(0).toUpperCase() + nome[1].charAt(0).toUpperCase(), 
                numero: d.n_despesa,
                condominio: d.cod_condominio + " - " + d.nome_condominio,
                valor: d.valor,
                rubrica: d.rubrica + " - " + d.descricao,
                dt_pag: d.dt_pag,
                details: d.details
              });
            });
          });
        }
        this.despesas.sort(function(a, b){
          var A = moment(a.dt_pag, "DD-MM-YYYY").toDate();
          var B = moment(b.dt_pag, "DD-MM-YYYY").toDate();
          return A.valueOf() - B.valueOf();
        });
        resolve(true);
      }, err => {
        reject(err);
      });
    });
  }

  export() {
    return this.pdfController.export();
  }

}
